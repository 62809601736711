<app-pagefullheader></app-pagefullheader>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card p-3">

		<div class="card mb-3">
			<div class="card-body">
				<h5 class="card-title">Search</h5>

				<form [formGroup]="orgSearchForm" (ngSubmit)="searchOrganizations()">
					<div class="row align-items-center">
						<div class="col-md-3">
							<div class="form-floating">
								<select class="form-select" formControlName="organizationId">
									<option [ngValue]="null" selected>Select all Organizations</option>
									<option *ngFor="let org of allOrganizations" [ngValue]="org.organizationId">
										{{ org.name }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-floating">
								<input type="text" id="siteName" class="form-control"
									formControlName="siteName" placeholder="Site Name">
								<label for="siteName">Site Name</label>
							</div>
						</div>
					</div>
					<div class="row mt-2">
						<div class="col-auto">
							<button class="btn btn-primary" [disabled]="loading">Search</button>
						</div>
						<div *ngIf="filtered" class="col-auto">
							<button class="btn btn-danger" (click)="resetFilter()">Reset Filter</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="table-responsive" *ngIf="organizations.length > 0">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Organization ID</th>
						<th>Organization Name</th>
						<th>Sites</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let org of organizations">
						<td>
							{{ org.organizationId }}
						</td>
						<td>
							{{ org.name }}
						</td>
						<td>
							<div *ngFor="let site of org.sites">
								<p>
									<a [routerLink]="['/sites', site.siteId, 'dashboard']">{{ site.name }}</a>
								</p>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</app-spinner-with-overlay>
