import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ProductDesignType } from '@taradel/admin-api-client';

@Component({
  selector: 'app-design-type',
  templateUrl: './design-type.component.html',
  styleUrls: ['./design-type.component.scss']
})
export class DesignTypeComponent {

	@Input() designType?: ProductDesignType;
	@Input() array: ProductDesignType[] = [];
	@Output() selectedMethod = new EventEmitter<number>();

	@ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;
	focus$ = new Subject<string>();
	click$ = new Subject<string>();

	search: OperatorFunction<string, readonly ProductDesignType[]> = (text$: Observable<string>) => {
	  const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
	  const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
	  const inputFocus$ = this.focus$;

	  return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
		map(term =>
		  term === '' ? this.array
		  : this.array.filter(v => v.name!.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
		)
	  );
	};

	formatter = (x: {name: string}) => x.name;

	select(event: any) {
	  this.selectedMethod.emit(event.item.productDesignTypeId);
	}

}
