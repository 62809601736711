import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { PrintMethod, ProductOption, ProductOptionCategory, ProductPrintMethodOption } from '@taradel/web-api-client';
import { ProductsService } from 'services/products.service';
import { ToastService } from 'services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

export interface PrintMethodSelection {
	printMethod: PrintMethod;
	selected: boolean;
}

const baseDeleteClass = 'delete-section';
const onDragOverDeleteClass = 'dragover-delete-style';
@Component({
	selector: 'app-product-options',
	templateUrl: './product-options.component.html',
	styleUrls: ['./product-options.component.scss']
})
export class ProductOptionsComponent implements OnInit {

	productId = 0;
	productPrintMethodOptions: ProductPrintMethodOption[] = [];
	allProductOptionCategories: ProductOptionCategory[] = [];
	printMethods: PrintMethod[] = [];
	loading: boolean = false;
	deleteSectionClass = baseDeleteClass;
	modalRef: any;
	optionToAdd?: ProductOption;
	printMethodSelectionList: PrintMethodSelection[] = [];

	constructor(
		private route: ActivatedRoute,
		public productsService: ProductsService,
		private toastService: ToastService,
		private modalService: NgbModal
	) { }

	ngOnInit() {
		this.route.paramMap.pipe(
			take(1)
		).subscribe(async params => {
			this.productId = parseInt(params.get('productId') ?? '0', 10);
			await this.initializeComponent();
		});
	}

	async initializeComponent() {
		this.loading = true;
		try {
			this.printMethods = await this.productsService.getPrintMethods();
			this.allProductOptionCategories = await this.productsService.getAllProductOptions();
			this.productPrintMethodOptions = await this.productsService.getPrintMethodOptions(this.productId);
		}
		catch {
			this.toastService.showError('There was an error loading the product options');
		}
		finally {
			this.loading = false;
		}
	}

	getProductPrintMethodOptions(printMethodId: number): ProductPrintMethodOption[] {
		const data = this.productPrintMethodOptions.filter(x => x.printMethodId === printMethodId);
		data.sort((a, b) => {
			const aCategoryName = this.allProductOptionCategories.find(y => y.optCatId === a.option?.optCatId)?.name!;
			const bCategoryName = this.allProductOptionCategories.find(y => y.optCatId === b.option?.optCatId)?.name!;
			if (aCategoryName > bCategoryName) {
				return 1;
			}
			else {
				return -1;
			}
		});
		return data;
	}

	getOptionName(ppmo: ProductPrintMethodOption) {
		return `${this.allProductOptionCategories.find(x => x.optCatId === ppmo.option?.optCatId)?.name} - ${ppmo.option?.name}`;
	}

	addDrag(ev: any, productOption: ProductOption) {
		ev.dataTransfer.setData('po', JSON.stringify(productOption));
	}

	removeDrag(ev: any, ppmo: ProductPrintMethodOption) {
		ev.dataTransfer.setData('po', JSON.stringify(ppmo));
		this.deleteSectionClass = onDragOverDeleteClass;
	}

	allowDrop(ev: Event) {
		ev.preventDefault();
	}

	async handleOptionDropEvent(ev: any, printMethodId: number) {
		ev.preventDefault();
		this.deleteSectionClass = baseDeleteClass;
		const productOption: ProductOption = Object.assign(new ProductOption(), JSON.parse(ev.dataTransfer.getData('po')));
		if (!this.productPrintMethodOptions.some(o => o.optionId === productOption.optionId)) {
			await this.addOption(productOption, printMethodId);
		}
	}

	async addOption(productOption: ProductOption, printMethodId: number) {
		let success = true;
		this.loading = true;
		let ppmo = this.productPrintMethodOptions.find(x => x.printMethodId === printMethodId && x.optionId === productOption.optionId);
		ppmo = new ProductPrintMethodOption({
			deleted: false,
			optionId: productOption.optionId,
			option: productOption,
			pmproductOptionId: 0,
			printMethodId,
			productId: this.productId
		});

		await (this.productsService.addOptionToProduct(ppmo)).catch((error: HttpErrorResponse) => {
			success = false;
			if (error.status === 400) {
				this.toastService.showError(`Product option ${ppmo?.option?.name} is already associated with print method ${this.printMethods.find(p => p.printMethodId === ppmo?.printMethodId)?.name}`);
			}
			else {
				this.toastService.showError(error.message);
			}
		});
		this.loading = false;
		if (success) {
			this.initializeComponent();
		}
	}

	async removeOption(ev: any) {
		ev.preventDefault();
		let success = true;
		const ppmo: ProductPrintMethodOption = JSON.parse(ev.dataTransfer.getData('po'));

		this.loading = true;
		await (this.productsService.removeOptionFromProduct(ppmo)).catch((error: HttpErrorResponse) => {
			success = false;
			this.loading = false;
			this.toastService.showError(error.message);
		});
		if (success) {
			if (ppmo.pmproductOptionId !== 0) {
				this.productPrintMethodOptions = this.productPrintMethodOptions.filter(x => x.pmproductOptionId !== ppmo.pmproductOptionId);
			}
			else {
				this.productPrintMethodOptions.splice(this.productPrintMethodOptions.findIndex(x => x.printMethodId === ppmo.printMethodId && x.optionId === ppmo.optionId), 1);
			}
			this.loading = false;
		}
		this.deleteSectionClass = baseDeleteClass;
	}

	openModal(option: any, modal: any) {
		this.printMethodSelectionList = [];
		this.optionToAdd = option;
		this.printMethods.forEach(method => {
			const printMethodSelection = {
				printMethod: method,
				selected: false
			} as PrintMethodSelection;
			this.printMethodSelectionList.push(printMethodSelection);
		});
		this.modalRef = this.modalService.open(modal);
	}

	async handleAddingOptionToMethods() {
		let printMethodIdList: number[] = [];
		this.printMethodSelectionList.forEach(pm => {
			if (pm.selected) {
				printMethodIdList.push(pm.printMethod.printMethodId);
			}
		});

		for (let i = 0; i < printMethodIdList.length; i++) {
			await this.addOption(this.optionToAdd!, printMethodIdList[i]);
		}
		this.closeModal();
	}

	closeModal() {
		this.printMethodSelectionList = [];
		this.optionToAdd = undefined;
		this.modalService.dismissAll();
	}

	disableButton(): boolean {
		return this.printMethodSelectionList.every(m => m.selected === false);
	}
}
