<app-pagefullheader></app-pagefullheader>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col">
						<div class="input-group mb-3">
							<span class="input-group-text">Filter</span>
							<input id="searchSitesBox" class="form-control" (keyup)="filterKeys($event)" [(ngModel)]="keyFilterValue" placeholder="Filter by Key">
							<button class="btn btn-outline-secondary" type="button" (click)="keyFilterValue='';filterKeys($event)" *ngIf="keyFilterValue !== ''"><i class="far fa-times"></i></button>
						</div>
					</div>
					<div *ngIf="showNoContentFilter" class="col-auto">
						<div class="input-group">
							<div class="form-check">
								<input type="checkbox" class="form-check-input" id="filterForNoContent" name="filterForNoContent" [ngModel]="filterForNoContent" (change)="filterKeysWithNoContent()">
								<label for="filterForNoContent" class="form-check-label">Show Content Keys With No Content</label>
							  </div>
						</div>
					</div>
				</div>

				<table class="table table-hover">
					<thead>
						<tr>
							<th>Key</th>
							<th>Content Type</th>
							<th>Use Count</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let key of filteredContentKeys">
							<td>
								<span>{{key.contentKey}}</span>
							</td>
							<td>
								<span>
									<div class="row">
										<div class="col-md-9">
											<select class="form-select" [(ngModel)]="key.contentType">
												<option *ngFor="let type of contentTypes" [value]="type[0]">
													{{type[1]}}</option>
											</select>
										</div>
										<div class="col-md-3">
											<button (ngModel)="key" button class="btn btn-info btn-sm" type="button"
											data-toggle="tooltip" data-placement="top" title="Edit"
											(click)="showEditSettingsModal(key, editContent)"><i class="fa fa-save mx-2"></i></button>
										</div>
									</div>
								</span>
							</td>
							<td>
								<span>{{key.useCount}}</span>
							</td>
							<td>
								<ul class="list-inline p-0 m-0">
									<li class="list-inline-item">
										<a [routerLink]="['/sites/content-keys/', key.contentKey]"
											class="btn btn-default btn-sm" data-toggle="tooltip"
											data-placement="top" title="View Content Key for All Sites"><i class="fa fa-eye"></i></a>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>

<!-- Edit Modal -->
<ng-template #editContent let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit Setting '{{ contentKeyTypeToEdit?.contentKey }}'</h4>
		<button type="button" class="close fas fa-times" aria-describedby="modal-title"
			(click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">Are you sure you want to change the content key <span class="text-primary">"{{ contentKeyTypeToEdit?.contentKey
				}}"</span> type from {{ oldContentKeyTypeValue }} to {{ newContentKeyTypeValue }}?</p>
		<p class="lead text-danger">This operation can not be undone.</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link" (click)="modal.dismiss('cancelEdit')">Cancel</button>
		<button type="button" class="btn btn-primary btn-lg" (click)="modal.close('edit')">Save Content Key</button>
	</div>
</ng-template>
<!-- End Edit Modal -->
