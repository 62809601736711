import { HttpWithAuth } from 'app/http-with-auth';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import { ReportsClient, DashboardData, SalesIndicators, McNallyQuery, EddmStatusReport, DashboardQuery, AttributionClient, AttributionReportQuery, CompanyAttribution, KpiDashboardReport } from '@taradel/admin-api-client';

@Injectable({
	providedIn: 'root'
})

export class ReportsService {
	reportsClient: ReportsClient;
	attributionClient: AttributionClient;

	constructor(http: HttpWithAuth) {
		this.reportsClient = new ReportsClient(environment.adminApiUrl, http);
		this.attributionClient = new AttributionClient(environment.adminApiUrl, http);
	}

	getDashboardData(startDate: Date, endDate: Date): Promise<DashboardData> {
		return this.reportsClient.getDashboardMetrics(new DashboardQuery({
			startDate,
			endDate
		}));
	}

	getSalesIndicators(sitesToExclude: number[]): Promise<SalesIndicators> {
		return this.reportsClient.getSalesIndicators(sitesToExclude);
	}

	async getMcNallyReport(query: McNallyQuery): Promise<void> {
		const fileResponse = await this.reportsClient.getMcNallyReport(query);
		const downloadURL = window.URL.createObjectURL(fileResponse?.data!);
		const link = document.createElement('a');
		link.href = downloadURL;
		let date = new Date;
		link.download = `McNallyReport-${date.toLocaleDateString()}.csv`;
		link.click();
	}

	async getCompanyAttributionReport(query: AttributionReportQuery): Promise<CompanyAttribution[]> {
		return await this.attributionClient.getCompanyAttribution(query);
	}

	async downloadCompanyAttributionReport(query: AttributionReportQuery): Promise<void> {
		const fileResponse = await this.attributionClient.getCompanyAttributionExportCSV(query);
		if (!fileResponse) {
			throw new Error('There was an error generating the Attribution Report');
		}
		const downloadURL = window.URL.createObjectURL(fileResponse.data!);
		const link = document.createElement('a');
		link.href = downloadURL;
		let date = new Date;
		//TODO: pull file name from headers
		link.download = `AttributionReport-${date.toLocaleDateString()}.csv`;
		link.click();
	}

	getEddmStatus(reportType: string): Promise<EddmStatusReport[]> {
		return this.reportsClient.getEddmStatusReport(reportType);
	}

	async getKPIDashboard(startDate: Date, endDate: Date): Promise<KpiDashboardReport[]> {
		return await this.reportsClient.getKpiDashboard(startDate, endDate);
	}

}
