<ng-container *ngIf="!!bundle">
	<div class="row">
		<div class="col">
			<div class="card">
				<div class="card-body">
					<ng-container *ngIf="bundle.deleted">
						<app-alert-box alertMode="danger">
							This bundle has been deleted
						</app-alert-box>
					</ng-container>
					<ng-container *ngIf="inReviewMode">
						<app-alert-box alertMode="info">
							Review the bundle's details and items below before saving it
						</app-alert-box>
					</ng-container>
					<div *ngIf="siteId <= 0 && isSalesAdmin" class="row d-flex justify-content-end my-3">
						<div *ngIf="!inReviewMode; else saveBundleBtn" class="col-auto">
							<button class="btn btn-danger me-2" (click)="openModal(deleteBundleModal)">Delete Bundle</button>
							<button class="btn btn-primary" [disabled]="" (click)="goToEditBundle()">Edit Bundle</button>
						</div>
						<ng-template #saveBundleBtn>
							<div class="col-auto">
								<button class="btn btn-link text-danger" (click)="goToEditBundle()">Go Back To Edit Bundle</button>
							</div>
							<div class="col-auto">
								<button class="btn btn-success" (click)="emitSaveBundle()">Save Bundle</button>
							</div>
						</ng-template>
					</div>
					<div class="row mt-3 gx-5">
						<div class="col-md-6 col-12">
							<h5>Bundle Details</h5>
							<div class="row mt-3">
								<div class="col">
									<strong>Name: </strong>{{ bundle.name }}
								</div>
							</div>
							<div class="row mt-3">
								<div class="col">
									<strong>Description: </strong>{{ bundle.description }}
								</div>
							</div>
							<div class="row mt-5">
								<div class="col">
									<h5>Bundle Content</h5>
									<div class="row mt-2">
										<div class="col">
											<app-bundle-content [bundle]="bundle" [bundleContent]="bundleCmsContent"></app-bundle-content>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-12 mt-md-0 mt-3">
							<h5>Bundle Items</h5>
							<ng-container *ngIf="!!bundle.bundleItemDisplays && bundle.bundleItemDisplays.length > 0">
								<div *ngFor="let item of bundle.bundleItemDisplays">
									<div class="row mb-3">
										<div class="col-6">
											<strong>Products:</strong>
											<ul>
												<li *ngFor="let product of item.products">{{ product.name }}</li>
											</ul>
										</div>
										<div class="col-6">
											<span><strong>Required: </strong></span>&nbsp;<span>{{ item.required }}</span>
										</div>
									</div>
									<div class="row mb-3">
										<div class="col-6">
											<span><strong>Quantity: </strong></span>&nbsp;<span>{{ item.quantity | number }}</span>
										</div>
										<div class="col-6">
											<span><strong>Daily Spend: </strong></span>&nbsp;<span>{{ item.dailySpend | currency }}</span>
										</div>
									</div>
									<div class="row mb-3">
										<div class="col-6">
											<span><strong>Minimum Cost: </strong></span>&nbsp;<span>{{ item.minimumCost | currency }}</span>
										</div>
										<div class="col-6">
											<div *ngIf="item.discountType === BundleDiscountType.Dollar; else percentTemplate">
												<span><strong>Discount Amount: </strong></span>&nbsp;<span>{{ item.discountAmount | currency }}</span>
											</div>
											<ng-template #percentTemplate>
												<span><strong>Discount Amount: </strong></span>&nbsp;<span>{{ item.discountAmount }}%</span>
											</ng-template>
										</div>
									</div>
									<hr />
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #deleteBundleModal let-modal>
	<div class="modal-header">
		Delete Bundle
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		Are you sure you want to delete this bundle?
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link text-danger" (click)="closeModal()">Cancel</button>
		<button type="button" class="btn btn-success btn-lg" (click)="emitDeleteBundle()">Confirm</button>
	</div>
</ng-template>
