<app-spinner-with-overlay [showSpinner]='loading'>
	<div class="card p-3 mb-3">
		<div class="ms-auto mb-3">
			<button class="btn btn-success btn-rounded px-4 rounded-pill" (click)="showAddNewUrlWindow()"><i
					class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add New Url</button>
		</div>

		<div *ngIf="showAddNewUrl">
			<form (ngSubmit)="submitNewSiteUrl()" #newUrlForm="ngForm">
				<div class="row mb-3">
					<div class="col-md-4">
						<div class="form-floating">
							<input type="text" class="form-control" id="hostName" name="hostName" #hostName="ngModel"
								required [(ngModel)]="addNewSiteUrlModel.url" placeholder="Host Name">
							<label for="configVal">Host Name</label>
						</div>
						<div [hidden]="hostName.valid || hostName.pristine" class="alert alert-danger"> Host Name is
							required
						</div>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-md-4">
						<div class="form-check">
							<input type="checkbox" id="active" [(ngModel)]="addNewSiteUrlModel.active" name="active" class="form-check-input">
							<label for="active" class="form-check-label">Active?</label>
						</div>
					</div>
				</div>

				<div class="row mb-3">
					<div class="col-md-4">
						<div class="form-check">
							<input type="checkbox" id="forceSsl" [(ngModel)]="addNewSiteUrlModel.forceSsl" name="forceSsl" class="form-check-input">
							<label for="forceSsl" class="form-check-label">Force SSL?</label>
						</div>
					</div>
				</div>
				<p>
					<button type="submit" class="btn btn-primary btn-lg" [disabled]="!newUrlForm.valid">Save New
						Url</button>
					<button type="reset" class="btn btn-link" (click)="showAddNewUrlWindow()">Cancel</button>
				</p>
			</form>
		</div>


		<div class="table-responsive" *ngIf=" siteUrls.length > 0 && !showAddNewUrl">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Host Name</th>
						<th>Active?</th>
						<th width="25%">Force SSL?</th>
						<th width="15%">&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let siteUrl of siteUrls; let i=index; trackBy: trackRow;" class="urlRow">
						<td>
							<a href="https://{{siteUrl.url}}" target="_blank" *ngIf="inputBoxToShow !== siteUrl.urlId">{{ siteUrl.url }}</a>
							<span *ngIf="inputBoxToShow === siteUrl.urlId">
								<div class="input-group">
									<input type="text" class="form-control" aria-label="hostName"
										aria-describedby="Host Name" [(ngModel)]="siteUrl.url">
								</div>
							</span>
						</td>
						<td>
							<span *ngIf="inputBoxToShow !== siteUrl.urlId">{{ siteUrl.active ? "Yes" : "No" }}</span>
							<span *ngIf="inputBoxToShow === siteUrl.urlId">
								<div class="form-check">
									<input type="checkbox" id="active" class="form-check-input" [(ngModel)]="siteUrl.active">
								</div>
							</span>
						</td>
						<td>
							<span *ngIf="inputBoxToShow !== siteUrl.urlId">{{ siteUrl.forceSsl ? "Yes" : "No" }}</span>
							<span *ngIf="inputBoxToShow === siteUrl.urlId">
								<div class="form-check">
									<input type="checkbox" id="forceSsl" class="form-check-input" [(ngModel)]="siteUrl.forceSsl">
								</div>
							</span>
						</td>
						<td>
							<span *ngIf="inputBoxToShow === siteUrl.urlId">
								<button type="submit" (ngModel)="siteUrl" (click)="showEditUrlModal(siteUrl, editContent)" class="btn btn-primary"><i
									class="fa fa-save mx-2" data-toggle="tooltip" data-placement="top"
									title="Save"></i></button>
								<button (ngModel)="siteUrl" class="btn btn-warning mx-2" type="button"
									data-toggle="tooltip" data-placement="top" title="Cancel" (click)="cancelEdit()"><i
										class="fa fa-times"></i></button>
							</span>
							<span *ngIf="inputBoxToShow !== siteUrl.urlId">
								<ul class="list-inline p-0 m-0">
									<li class="list-inline-item">
										<button (ngModel)="siteUrl" button class="btn btn-info btn-sm" type="button"
											data-toggle="tooltip" data-placement="top" title="Edit"
											(click)="editUrl(siteUrl)"><i class="fa fa-edit"></i></button>
									</li>
									<li class="list-inline-item">
										<button (ngModel)="siteUrl" class="btn btn-danger btn-sm" type="button"
											data-toggle="tooltip" data-placement="top" title="Delete"
											(click)="showDeleteUrlModal(siteUrl, deleteContent)"><i
												class="fa fa-trash"></i></button>
									</li>
								</ul>
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</app-spinner-with-overlay>

<!-- Delete Modal -->
<ng-template #deleteContent let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Delete Url '{{ currentUrl.url }}'</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
			(click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">Are you sure you want to delete the url <span class="text-danger">"{{ currentUrl.url
				}}"</span>?</p>
		<p class="lead text-danger"><strong>This operation can not be undone.</strong></p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-text" (click)="modal.dismiss('cancel click')">Cancel</button>
		<button type="button" class="btn btn-primary btn-lg" (click)="modal.close('deleteUrl')">Delete
			Url</button>
	</div>
</ng-template>
<!-- End Delte Modal -->

<!-- Edit Modal -->
<ng-template #editContent let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit Url '{{ currentUrl.url }}'</h4>
		<button type="button" class="close" aria-describedby="modal-title"
			(click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">Are you sure you want to change the Url <span class="text-primary">"{{ currentUrl.url
				}}"</span>?</p>
		<p class="lead text-danger">This operation can not be undone.</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link" (click)="modal.dismiss('cancelEditUrl')">Cancel</button>
		<button type="button" class="btn btn-primary btn-lg" (click)="modal.close('editUrl')">Save Setting</button>
	</div>
</ng-template>
<!-- End Edit Modal -->
