import { Component } from '@angular/core';
import { OrderlayoutService } from 'services/orderlayout.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Drop } from '@taradel/web-api-client';
import { ToastService } from 'services/toast.service';
import { AuthenticationService } from 'services/authentication.service';

@Component({
  selector: 'app-pns-delivery-drops',
  templateUrl: './pns-delivery-drops.component.html',
  styleUrls: ['./pns-delivery-drops.component.scss']
})
export class PnsDeliveryDropsComponent {
	loading: boolean = false;
	numberOfDrops = 1;
	today = new Date();
	allowEarlierDate = false;
	todaysDate = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
	earliestDate = { year: 2000, month: this.today.getMonth() + 1, day: this.today.getDate() };

	constructor(public orderLayoutService: OrderlayoutService,
		public authService: AuthenticationService,
		private toastService: ToastService) { }

	setStartDate(drop: Drop, date: NgbDate): void {
		drop.date = new Date(date.year, date.month - 1, date.day);
		this.orderLayoutService.selectedOrderItemDropDateMap.set(drop.number, date);
    }

	async updateDropDate() {
		const drops = this.orderLayoutService.selectedOrderItemAsCartProduct?.drops;
		const drop = drops?.orderDrops![0]!;
		const missingDropDates = drop.date === undefined || isNaN(drop.date.getDate());
		if (missingDropDates) {
			this.toastService.showError('Specify drop dates for your drop(s)');
			return;
		}

		try {
			this.loading = true;
			await this.orderLayoutService.updateOrderItemDrops(drop.number);
			this.loading = false;
		}
		catch (error) {

		}
		finally {
			this.loading = false;
		}
	}
}
