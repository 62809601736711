<div class="card p-3 mb-3">
	<app-spinner-with-overlay [showSpinner]="loading">
        <div class="row">
            <div class="col-12 text-end">
                <button class="btn btn-danger mx-1" (click)="deleteUSelectFromSite()" type="button">
                    Remove from Site
                </button>
                <button class="btn btn-primary mx-1" (click)="updateUSelectDesigns()" type="button">
                    Update USelect
                </button>
            </div>
        </div>

		<div *ngIf="siteId !== 0 && defaultUSelectDesigns.length > 0" class="row">
            <div class="col-12">
                Default Design for <b>{{uSelectName}}</b> as per Site 0 configuration is/are <b>{{defaultUSelectDesigns}}</b>
            </div>
        </div>

		<div class="card border-0 p-3">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th width="20%">USelect ID</th>
						<th>USelect Name</th>
						<th>Design Type</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let designOption of detailedUSelectDesignOption; let i = index;">
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="uSelectId" min="1" class="form-control"
								[(ngModel)]="designOption.uSelectId" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="string" id="uSelectName" class="form-control"
									[(ngModel)]="designOption.uSelectName" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<select type="select" class="form-select" [(ngModel)]="designOption.productDesignTypeId">
									<option *ngFor="let x of designTypes" value="{{x}}">
										{{x}}
									</option>
								</select>
							</div>
						</td>
                        <td>
                            <li class="list-inline-item">
                                <button class="btn btn-danger btn-sm" (click)="removeSelectedDesignOption(i)" data-toggle="tooltip"
                                    data-placement="top" Title="Delete">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </li>
                        </td>
					</tr>
                        <tr>
                            <td width="20%">
                                <div class="form-floating mb-3">
                                    <input type="number" id="uSelectId" min="1" class="form-control"
                                    [(ngModel)]="newUSelectDesignOption!.uSelectId" disabled>
                                </div>
                            </td>
                            <td>
                                <div class="form-floating mb-3">
									<input type="text" id="uSelectId" class="form-control"
									    [(ngModel)]="newUSelectDesignOption!.uSelectName" disabled>
                                </div>
                            </td>

							<td>
								<div class="form-floating mb-3">
									<select type="select" class="form-select" (change)="disableAddBtn = false"
										[(ngModel)]="newUSelectDesignOption!.productDesignTypeId">
										<option *ngFor="let x of designTypes" value="{{x}}">
											{{x}}
										</option>
									</select>
								</div>
							</td>
                            <td>
                                <ul class="list-inline">
                                    <li class="list-inline-item">
                                        <button class="btn btn-success" (click)="addNewUSelectDesign()" [disabled]="disableAddBtn">Add</button>
                                    </li>
                                </ul>
                            </td>
                        </tr>
				</tbody>
			</table>
		</div>
	</app-spinner-with-overlay>
</div>
