import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ProductOption, ProductOptionCategory } from '@taradel/web-api-client';

export interface UpdatedProdOptCats extends ProductOptionCategory {
	hasSelectedOption: boolean;
	selectedOption?: ProductOption;
}

@Component({
  selector: 'app-pcog-options',
  templateUrl: './pcog-options.component.html',
  styleUrls: ['./pcog-options.component.scss']
})
export class PcogOptionsComponent implements OnInit {
	updatedProdOptCats: UpdatedProdOptCats[] = [];
	selectedOptionsIds: number[] = [];
	loading = false;

	@Input() pcogOptions!: string;
	@Input() prodOptCats!: ProductOptionCategory[];

	@Output() optionSelected = new EventEmitter<string>();

	ngOnInit(): void {
		this.loading = true;
		const pcogOptionsArray = this.pcogOptions.split(',');
		this.prodOptCats.forEach(poc => {
			let updatedPOC = {
				optCatId: poc.optCatId,
				name: poc.name,
				description: poc.description,
				sortOrder: poc.sortOrder,
				displayType: poc.displayType,
				helpPage: poc.helpPage,
				options: poc.options,
				hasSelectedOption: false,
				selectedOption: undefined
			} as UpdatedProdOptCats;
			const foundOpt = poc.options?.find(opt => pcogOptionsArray.some(o => parseInt(o) === opt.optionId));
			if (!!foundOpt) {
				updatedPOC.hasSelectedOption = true;
				updatedPOC.selectedOption = foundOpt;
				this.selectedOptionsIds.push(foundOpt.optionId);
			}
			this.updatedProdOptCats.push(updatedPOC);
		});
		this.loading = false;
	}

	handleOptionSelected(option: ProductOption) {
		const selectedCat = this.updatedProdOptCats.find(optCat => optCat.optCatId === option.optCatId);
		if (!selectedCat?.hasSelectedOption) {
			selectedCat!.hasSelectedOption = true;
			selectedCat!.selectedOption = option;
			this.selectedOptionsIds.push(option.optionId);
		}
		else {
			const indexToRemove = this.selectedOptionsIds.findIndex(i => i === selectedCat.selectedOption?.optionId);
			this.selectedOptionsIds.splice(indexToRemove, 1);
		}

		const optIdsString = this.selectedOptionsIds.join();
		this.optionSelected.emit(optIdsString);
	}

	handleRemovingOption(option: ProductOption) {
		const selectedCat = this.updatedProdOptCats.find(optCat => optCat.optCatId === option.optCatId);
		selectedCat!.hasSelectedOption = false;
		selectedCat!.selectedOption = undefined;
		const indexToRemove = this.selectedOptionsIds.findIndex(i => i === option.optionId);
		this.selectedOptionsIds.splice(indexToRemove, 1);

		const optIdsString = this.selectedOptionsIds.join();
		this.optionSelected.emit(optIdsString);
	}
}
