import { Component } from '@angular/core';
import { EquifaxSelectionService } from 'services/equifax-selection.service';

@Component({
	selector: 'app-geography',
	templateUrl: './geography.component.html',
	styleUrls: ['./geography.component.scss']
})
export class GeographyComponent {
	constructor(public equifaxSelectionService: EquifaxSelectionService) { }
}
