<div class="accordion-item">
    <h2 class="accordion-header" id="primaryContactHeading">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#primaryContactCollapse" 
            aria-expanded="true" aria-controls="primaryContactCollapse">
            Primary Contact
        </button>
    </h2>
    <div id="primaryContactCollapse" class="accordion-collapse collapse show" aria-labelledby="primaryContactHeading" 
        data-bs-parent="#businessAccordion">
        <div class="accordion-body">
            <app-select [selectionService]="equifaxSelectionService" [searchable]="true" title="Job Title" queryField="primaryContactTitle" placeholder="Type here to search"></app-select>
            <app-select [selectionService]="equifaxSelectionService" title="Gender" queryField="primaryContactGender"></app-select>
            <app-select [selectionService]="equifaxSelectionService" title="Ethnicity" queryField="primaryContactEthnicity"></app-select>
            <div class="row">
                <div class="col-sm-8">
                    <app-toggle [selectionService]="equifaxSelectionService"
                        name="Record includes phone number" queryField="phoneNotNull"></app-toggle>
                    <app-toggle [selectionService]="equifaxSelectionService"
                        name="Record includes email" queryField="emailNotNull"></app-toggle>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="numberEmployeesHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#numberEmployeesCollapse" 
            aria-expanded="false" aria-controls="numberEmployeesCollapse">
            Number of Employees
        </button>
    </h2>
    <div id="numberEmployeesCollapse" class="accordion-collapse collapse" aria-labelledby="numberEmployeesHeading" 
        data-bs-parent="#businessAccordion">
        <div class="accordion-body">
            <app-select [selectionService]="equifaxSelectionService" title="Business Size" queryField="businessSize"></app-select>
            <app-select [selectionService]="equifaxSelectionService" title="Total Employees" queryField="corporateEmployeeCodes"></app-select>
            <app-select [selectionService]="equifaxSelectionService" title="Location Employees" queryField="locationEmployeeCodes"></app-select>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="businessTypeHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#businessTypeCollapse" 
            aria-expanded="false" aria-controls="businessTypeCollapse">
            Business Legal Status
        </button>
    </h2>
    <div id="businessTypeCollapse" class="accordion-collapse collapse" aria-labelledby="businessTypeHeading" 
        data-bs-parent="#businessAccordion">
        <div class="accordion-body">
            <app-select [selectionService]="equifaxSelectionService" queryField="businessLegalStatus"></app-select>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="stockMarketHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#stockMarketCollapse" 
            aria-expanded="false" aria-controls="stockMarketCollapse">
            Stock Exchange
        </button>
    </h2>
    <div id="stockMarketCollapse" class="accordion-collapse collapse" aria-labelledby="stockMarketHeading" 
        data-bs-parent="#businessAccordion">
        <div class="accordion-body">
            <app-select [selectionService]="equifaxSelectionService" title="Publicly Held" queryField="publicPrivateStatus"></app-select>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="ownershipAndControlHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ownershipAndControlCollapse" 
            aria-expanded="false" aria-controls="ownershipAndControlCollapse">
            Ownership and Control
        </button>
    </h2>
    <div id="ownershipAndControlCollapse" class="accordion-collapse collapse" aria-labelledby="ownershipAndControlHeading" 
        data-bs-parent="#businessAccordion">
        <div class="accordion-body">
            <app-toggle [selectionService]="equifaxSelectionService" name="Minority Owned" queryField="minorityBusinessEnterprise"></app-toggle>
            <app-toggle [selectionService]="equifaxSelectionService" name="Woman Owned" queryField="womanBusinessEnterprise"></app-toggle>
            <app-toggle [selectionService]="equifaxSelectionService" name="Veteran Owned" queryField="veteranBusiness"></app-toggle>
            <app-toggle [selectionService]="equifaxSelectionService" name="Foreign Owned" queryField="foreignOwned"></app-toggle>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="marketabilityHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#marketabilityCollapse" 
            aria-expanded="false" aria-controls="marketabilityCollapse">
            Marketability
        </button>
    </h2>
    <div id="marketabilityCollapse" class="accordion-collapse collapse" aria-labelledby="marketabilityHeading" 
        data-bs-parent="#businessAccordion">
        <div class="accordion-body">
            <app-select [selectionService]="equifaxSelectionService" title="Overall Marketability Score" queryField="marketabilityScores"></app-select>
        </div>
    </div>
</div>