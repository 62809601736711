import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-customer-links',
	templateUrl: './customer-links.component.html',
	styleUrls: ['./customer-links.component.scss']
})
export class CustomerLinksComponent {

	@Input() customerId!: number;
	constructor() { }

}
