import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiException, ProductDesignType, USelectDesignTurnaround, USelectMethod } from '@taradel/admin-api-client';
import { DeliveryService } from 'services/delivery.service';
import { ProductsService } from 'services/products.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';
import { DesignTypeComponent } from '../design-type/design-type.component';

@Component({
  selector: 'app-design-turnaround',
  templateUrl: './design-turnaround.component.html',
  styleUrls: ['./design-turnaround.component.scss']
})
export class DesignTurnaroundComponent implements OnInit {
	loading = true;
	productUSelectId = 0;
	designTypes: ProductDesignType[] = [];
	uSelectMethods: USelectMethod[] = [];
	designTurnarounds: USelectDesignTurnaround[] = [];
	newDesignTurnaround?: USelectDesignTurnaround;
	@ViewChild('designTypeListInput') designTypeInput!: DesignTypeComponent;

	constructor(
		private deliveryService: DeliveryService,
		private productService: ProductsService,
		private route: ActivatedRoute,
		private sitesService: SitesService,
		private toastService: ToastService
	) {
		this.productUSelectId = parseInt(route.snapshot.paramMap.get('uselectId') ?? '0', 10);
	}

	async ngOnInit() {
		try {
			this.uSelectMethods = await this.sitesService.getAllUSelect();
			this.designTypes = await this.productService.getProductDesignTypes();
			this.designTurnarounds = await this.deliveryService.getUSelectDesignTurnaround(this.productUSelectId);
		}
		catch (error) {
			this.toastService.showError('There was an error getting the uselect design turnaround');
		}
		finally {
			this.loading = false;
		}
		this.initializeNewDesignTurnaround();
	}

	initializeNewDesignTurnaround() {
		this.newDesignTurnaround = new USelectDesignTurnaround();
		this.newDesignTurnaround.uSelectId = this.productUSelectId;
		if (this.designTypeInput) {
			this.designTypeInput.designType = undefined;
		}
	}

	getUSelectMethod(uSelectId: number) {
		return this.uSelectMethods.find(t => t.uSelectId === uSelectId);
	}

	getUSelectName(uSelectId?: number) {
		const temp = this.uSelectMethods.find(t => t.uSelectId === uSelectId);
		return temp?.name;
	}

	async updateAllDesignTurnaround() {
		try {
			this.loading = true;
			await this.deliveryService.updateUSelectDesignTurnaround(this.productUSelectId, this.designTurnarounds);
			this.initializeNewDesignTurnaround();
			this.toastService.showSuccess('Design Turnaround successfully saved');
		}
		catch {
			this.toastService.showError('There was an error saving design turnaround');
		}
		finally {
			this.loading = false;
		}
	}

	addNewDesignTurnaround() {
		this.designTurnarounds.push(this.newDesignTurnaround!);
		this.initializeNewDesignTurnaround();
	}

	removeSelectedDesignTurnaround(index: number) {
		this.designTurnarounds.splice(index, 1);
	}

	async deleteAllDesignTurnaround() {
		this.loading = true;
		try {
			await this.deliveryService.updateUSelectDesignTurnaround(this.productUSelectId, []);
			this.designTurnarounds = [];
			this.toastService.showSuccess('Design Turnaround deleted successfully');
		}
		catch (error: any) {
			if (ApiException.isApiException(error)) {
				this.toastService.showError(error.message);
			}
		}
		finally {
			this.loading = false;
		}
	}

	getDesignType(designTyeId?: number) {
		return this.designTypes.find(t => t.productDesignTypeId === designTyeId);
	}

	getUpdateMethod(id: number, i: number) {
		const matchingDesign = this.designTurnarounds.find(x => x.designType === id);
		if (matchingDesign) {
			this.designTurnarounds[i].designType = 0;
			this.designTypeInput.designType = undefined;
			this.toastService.showError('Design Turnaround has already has been added');
			return;
		}
		this.designTurnarounds[i].designType = id;
	}

	getMethod(id: number) {
		const matchingDesign = this.designTurnarounds.find(x => x.designType === id);
		if (matchingDesign) {
			this.designTypeInput.designType = undefined;
			this.toastService.showError('Design Turnaround has already has been added');
			return;
		}
		this.newDesignTurnaround!.designType = id;
	}
}
