<app-pagefullheader></app-pagefullheader>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card p-3 mb-3">
		<!-- Site Config Table-->
		<div class="table-responsive" *ngIf="settings.length !== 0" >
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Config Cat</th>
						<th>Config Name</th>
						<th>Use Count</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let eachConfig of settings" class="settingRow">
						<td>{{ eachConfig.configCat }}</td>
						<td>{{ eachConfig.configName }}</td>
						<td>{{ eachConfig.useCount }}</td>
						<td>
							<a [routerLink]="['/sites/config/', eachConfig.configCat, eachConfig.configName]" class="btn btn-primary btn-sm">View All</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</app-spinner-with-overlay>




