import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { OrderlayoutService } from 'services/orderlayout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DistributionsService } from 'services/distributions.service';
import { Distribution, GetCustomerDistributionsQuery, PaginationQuery, USelectType } from '@taradel/admin-api-client';
import { ToastService } from 'services/toast.service';
import { OrderService } from 'services/order.service';

@Component({
	selector: 'app-change-distribution',
	templateUrl: './change-distribution.component.html',
	styleUrls: ['./change-distribution.component.scss']
})
export class ChangeDistributionComponent {
	@Input() buttonClass = '';
	@Input() isMultiple = false;
	@Input() disableChangeDistribution = false;
	@Input() dropNumber = 1;
	@Output() updateDistribution = new EventEmitter<number>();
	@ViewChild('changeDistribution') public changeDistribution!: TemplateRef<any>;
	Math: any = Math;
	changeDistMsg: string | undefined;
	loading: boolean = false;
	distributions: Distribution[] | undefined = undefined;
	selectedDistribution: Distribution | undefined = undefined;

	constructor(
		public orderService: OrderService,
		public orderLayoutService: OrderlayoutService,
		private distributionsService: DistributionsService,
		private modalService: NgbModal,
		private toastService: ToastService
	) { }

	async changeDist(): Promise<void> {
		try {
			this.loading = true;
			const distributionId = this.orderLayoutService.getDistributionId(this.dropNumber);
			const uSelectId = this.orderLayoutService.getDistributionUSelect(this.dropNumber);
			if (uSelectId) {
				const query = new GetCustomerDistributionsQuery({
					customerId: this.orderLayoutService.selectedCustomer?.customerID!,
					pagination: new PaginationQuery({
						pageNo: 1,
						pageSize: 50
					}),
					showDeleted: false,
					uSelect: uSelectId as unknown as USelectType
				});

				// load other distributions with this same type
				const dists = await this.distributionsService.getAllDistributions(query);
				this.distributions = dists.filter(d => d.distributionId !== distributionId);
				this.modalService.open(this.changeDistribution, { scrollable: true, size: 'lg'});
			}
			else {
				this.toastService.showError('Unable to determine USelectId for replacement distribution');
			}
		}
		catch {
			this.toastService.showError('Unable to get customer distributions');
		}
		finally {
			this.loading = false;
		}
	}

	setDistribution(dist: Distribution) {
		this.selectedDistribution = dist;
	}

	selectDistribution(): void {
		if (!this.selectedDistribution) {
			this.toastService.showError('Cannot find selected distribution to apply');
		}
		else {
			this.updateDistribution.emit(this.selectedDistribution.distributionId);
			this.selectedDistribution = undefined;
			this.modalService.dismissAll();
		}
	}
}
