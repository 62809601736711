import { Injectable, EventEmitter, Output } from '@angular/core';
import { Router, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import themeSettings from 'app/config/theme-settings';

@Injectable({
	providedIn: 'root'
  })

export class ThemeSettingsService {
	@Output() appSidebarMinifiedToggledEvent = new EventEmitter<boolean>();
	@Output() appSidebarMobileToggledEvent = new EventEmitter<boolean>();

	@Output() settings: { [key: string]: boolean };

	constructor(private router: Router) {
		this.settings = themeSettings;

		this.router.events.subscribe((ev: NavigationEvent) => {
			if (ev instanceof NavigationEnd) {
				// close any mobile nav that is open after nav
				this.settings.appTopMenuMobileToggled = false;
			}
		});
	}

	appSidebarMinifiedToggled(triggered: boolean) {
		this.appSidebarMinifiedToggledEvent.emit(triggered);
	}

	appSidebarMobileToggled(triggered: boolean) {
		this.appSidebarMobileToggledEvent.emit(triggered);
	}
}
