import { AddressTradeArea } from "@taradel/admin-api-client";

export class GooglePlaceResult implements google.maps.places.PlaceResult {
    address_components?: google.maps.GeocoderAddressComponent[];
    adr_address?: string;
    aspects?: google.maps.places.PlaceAspectRating[];
    business_status?: google.maps.places.BusinessStatus;
    formatted_address?: string;
    formatted_phone_number?: string;
    geometry?: google.maps.places.PlaceGeometry;
    html_attributions?: string[];
    icon?: string;
    icon_background_color?: string;
    icon_mask_base_uri?: string;
    international_phone_number?: string;
    name?: string;
    opening_hours?: google.maps.places.PlaceOpeningHours;
    permanently_closed?: boolean;
    photos?: google.maps.places.PlacePhoto[];
    place_id?: string;
    plus_code?: google.maps.places.PlacePlusCode;
    price_level?: number;
    rating?: number;
    reviews?: google.maps.places.PlaceReview[];
    types?: string[];
    url?: string;
    user_ratings_total?: number;
    utc_offset?: number;
    utc_offset_minutes?: number;
    vicinity?: string;
    website?: string;
}

export function mapAddressTradeAreaToGooglePlaceAddress(x: AddressTradeArea): GooglePlaceResult {
	const address = new GooglePlaceResult();
	address.formatted_address = `${x.address!}, ${x.zipCode!}`;
	return address;
}
