<ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>
<div class="input-group mb-3">
    <label for="productsInput" class="input-group-text">Product</label>
    <input id="productsInput" type="text" class="form-control" [ngbTypeahead]="search" (focus)="focus$.next($any($event).target.value)" 
        (selectItem)="select($event)" #instance="ngbTypeahead" #input [resultTemplate]="rt" [inputFormatter]="formatter" placeholder="Search by product name" />
    <button class="btn btn-default" type="button" (click)="resetInput()"
        *ngIf="valueSelected"><i class="far fa-times-circle"></i></button>
</div>
