<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row mb-3">
		<div class="col">
			<div class="card mb-3"
				*ngFor="let drop of orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops">
				<div class="card-header"><b>Shipping Date</b></div>
				<div class="card-body">
					<div class="row">
						<div class="col-xl-6 col-lg-12">
							<div class="form-floating style-dates">
								<input class="form-control" (dateSelect)="setStartDate(drop, $event)" [firstDayOfWeek]="7"
									[ngModel]="orderLayoutService.selectedOrderItemDropDateMap.get(drop.number)"
									[minDate]="allowEarlierDate ? earliestDate : todaysDate"
									name="dp" ngbDatepicker #sd="ngbDatepicker" readonly>
								<label [for]="'drop' + drop.number + 'startDate'">Shipping Date</label>
								<div class="d-flex justify-content-end" *ngIf="authService.organizationId === 1">
									<span class="fa fa-calendar" (click)="sd.toggle()"></span>
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-3" *ngIf="authService.organizationId === 1">
						<div class="col">
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" name="allowEarlierDate" [(ngModel)]="allowEarlierDate">
								<label class="form-check-label">Allow Earlier Date Selection</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="authService.organizationId === 1">
				<div class="col-md-4">
					<button class="btn btn-primary btn-sm ms-auto me-1" (click)="updateDropDate()">Update Shipping Date</button>
				</div>
				<div class="col-md-4"></div>
				<div class="col-md-4"></div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
