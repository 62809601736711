import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductPricingResponse, ShoppingCart, WLProduct, CartProduct } from '@taradel/web-api-client';
import { PricingService } from 'services/pricing.service';
import { USelect } from 'services/distributions.service';

@Component({
  selector: 'app-extra-copies-customization',
  templateUrl: './extra-copies-customization.component.html',
  styleUrls: ['./extra-copies-customization.component.scss']
})
export class ExtraCopiesCustomizationComponent implements OnInit {
	extraCopiesSiteProduct?: WLProduct;
	extraCopiesPricing?: ProductPricingResponse[];
	selectedQuantity?: string;
	extraCopiesProducts?: WLProduct[];

	@Input() countryCode: 'US' | 'Canada' = 'US';
	@Input() siteId?: number;
	@Input() baseProductId?: number;
	@Input() siteProducts?: WLProduct[];
	@Input() cartData?: CartProduct[];
	@Input() onlyExtraCopies?: boolean = false;
	@Input() submitted: boolean = false;
	@Input() messaging = '';

	@Output() extraCopiesSelected = new EventEmitter<{ productSelected: number | undefined, quantitySelected: number | undefined } | undefined>();

	constructor(private pricingService: PricingService) { }

	async ngOnInit(): Promise<void> {
		if (!!this.baseProductId && (!!this.siteProducts && this.siteProducts.length)) {
			if (this.siteProducts.some(sp => sp.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies)) {
				this.extraCopiesProducts = this.siteProducts.filter(sp => sp.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies);
			}
			if (!!this.extraCopiesProducts) {
				const baseProduct = this.siteProducts.find(sp => sp.baseProductId === this.baseProductId)?.baseProduct;
				if (!baseProduct) {
					this.extraCopiesSelected.emit({ productSelected: undefined, quantitySelected: 0 });
				}
				else {
					this.extraCopiesSiteProduct = this.extraCopiesProducts.find(ecp => ecp.baseProduct?.paperHeight === baseProduct.paperHeight && ecp.baseProduct?.paperWidth === baseProduct.paperWidth);
					this.extraCopiesPricing = await this.pricingService.getProductPricing(this.siteId!, 0, this.extraCopiesSiteProduct?.baseProductId!);
					if (!!this.extraCopiesSiteProduct && !!this.cartData) {
						const extraCopiesCartProduct = this.cartData?.find(cd => cd.uSelectId === USelect.extraCopies && cd.baseProductId === this.extraCopiesSiteProduct?.baseProductId);
						if (!!extraCopiesCartProduct) {
							this.selectedQuantity = extraCopiesCartProduct.quantity.toLocaleString();
							this.extraCopiesSelected.emit({ productSelected: extraCopiesCartProduct.baseProductId, quantitySelected: parseInt(this.selectedQuantity) });
						}
						else if (this.cartData?.some(cd => cd.baseProductId === this.baseProductId)) {
							this.selectedQuantity = '0';
							this.extraCopiesSelected.emit({ productSelected: this.extraCopiesSiteProduct.baseProductId, quantitySelected: parseInt(this.selectedQuantity) });
						}
						else {
							this.extraCopiesSelected.emit({ productSelected: this.extraCopiesSiteProduct.baseProductId, quantitySelected: undefined });
						}
					}
				}
			}
		}
	}

	getPriceForQuantity(price: number, quantity: number) {
		return (price * quantity).toLocaleString(this.countryCode === 'US' ? 'en-US' : 'en-CA', {
			style: 'currency',
			currency: this.countryCode === 'US' ? 'USD' : 'CAD'
		});
	}

	handlePricingSelected() {
		if (!!this.selectedQuantity) {
			this.extraCopiesSelected.emit({ productSelected: this.extraCopiesSiteProduct?.baseProductId!, quantitySelected: parseInt(this.selectedQuantity) });
		}
		else {
			this.extraCopiesSelected.emit(undefined);
		}
	}
}


