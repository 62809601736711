import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CmsContent, CmsContentKey, CmsContentRequest, CmsContentType, FilterCmsContentRequest, SiteLanguage  } from '@taradel/admin-api-client';
import { ContentService } from 'services/content.service';
import { SitesService } from 'services/sites.service';
import { AuthenticationService } from 'services/authentication.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContentTreeViewData, LanguageContent, } from './content-treeview-data';
import { ToastService } from 'services/toast.service';
import saveAs from 'file-saver';

@Component({
	selector: 'app-site-content',
	templateUrl: './site-content.component.html',
	styleUrls: ['./site-content.component.scss']
})

export class SiteContentComponent implements OnInit {
	@ViewChild('contentKeyInput') contentKeyInput!: ElementRef;

	siteId: number | undefined;
	isSystemAdmin!: boolean;
	supportedLanguages: SiteLanguage[] =[];
	allKeys: CmsContentKey[] | undefined;
	baseContent: CmsContent[] = [];
	siteContent: CmsContent[] = [];
	contentValueFilter = '';
	treeNodes: ContentTreeViewData[] = [];
	enableAddContentKey: boolean = false;
	contentTypeValues: string[];
	addContentKeyForm: UntypedFormGroup;
	editSiteContentForm: UntypedFormGroup;
	submitted: boolean = false;
	loading: boolean = false;
	nodes: ContentTreeViewData[] = [];
	selectedNode: ContentTreeViewData | undefined;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private auth: AuthenticationService,
		private contentService: ContentService,
		private sitesService: SitesService,
		private toastService: ToastService
	) {
		this.contentTypeValues = Object.values(CmsContentType);

		this.addContentKeyForm = formBuilder.group({
			contentKey: ['', Validators.required],
			contentType: ['', Validators.required]
		});
		this.editSiteContentForm = formBuilder.group({
			languageContent: formBuilder.array([ ])

		});
	}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		this.isSystemAdmin = await this.auth.hasRole('SystemAdmin');
		this.sitesService.currentSite.subscribe(async site => {
			if (site) {
				try {
					this.siteId = site.siteId;
					this.supportedLanguages = await this.contentService.getSupportedLanguages(this.siteId);
					this.supportedLanguages.forEach(l => {
						const languageContentForm = this.formBuilder.group({
							content: ['', Validators.required]
						});
						this.languageContent.push(languageContentForm);
					});
					await this.getSiteContent(site.siteId);

				}
				catch {
					this.toastService.showError('There was an error loading the site content');
				}
				finally {
					this.loading = false;
				}
			}
		});
	}

	get languageContent() {
		return this.editSiteContentForm.controls.languageContent as UntypedFormArray;
	}

	getLanguageContent(content: LanguageContent[], language: string): LanguageContent | undefined {
		return content?.find(x => x.language?.includes(language));
	}

	async getSiteContent(siteId: number) {
		// get base content from site 0
		this.allKeys = await this.contentService.getAllKeys();
		this.baseContent = await this.contentService.filterSiteContent(new FilterCmsContentRequest({
			siteId: 0,
			content: this.contentValueFilter
		}));
		this.siteContent = await this.contentService.filterSiteContent(new FilterCmsContentRequest({
			siteId: siteId,
			content: this.contentValueFilter
		}));
		this.treeNodes = this.processSiteContent();
		this.nodes = this.getPreparedData(this.treeNodes);
	}

	async filterSiteContent(siteId: number) {
		try {
			this.loading = true;
			// get base content from site 0
			this.baseContent = await this.contentService.filterSiteContent(new FilterCmsContentRequest({
				siteId: 0,
				content: ''
			}));
			this.siteContent = await this.contentService.filterSiteContent(new FilterCmsContentRequest({
				siteId: siteId,
				content: this.contentValueFilter
			}));
			let filteredBaseContent: CmsContent[] = [];
			this.siteContent.forEach(c => filteredBaseContent.push(...this.baseContent.filter(x => x.contentKey?.contentKey?.includes(c.contentKey?.contentKey!))));
			this.baseContent = filteredBaseContent;
			this.allKeys = [];
			this.baseContent.map(x => {
				if (!this.allKeys?.includes(x.contentKey!)) {
					this.allKeys?.push(x.contentKey!);
				}
			});
			this.treeNodes = this.processSiteContent();
			this.nodes = this.getPreparedData(this.treeNodes);
		}
		catch (err) {
			this.toastService.showError('Could not filter content');
		}
		finally {
			this.loading = false;
		}
	}

	async handleFileInput(event: any) {
		if (event.files.length > 0) {
			await this.importSiteContent(event.files[0]);
		}
	}

	async exportContentSettings (): Promise<void> {
		this.loading = true;
		try {
			const download = await this.contentService.exportSiteContent(this.siteId!);
			if (download) {
				saveAs(download.data, download.fileName);
			}
		}
		catch (err) {
			this.toastService.showError('Could not export content');
		}
		finally {
			this.loading = false;
		}
	}

	async importSiteContent (file: File): Promise<void> {
		this.loading = true;
		try {
			await this.contentService.importSiteContent(this.siteId!, {
				fileName: file.name,
				data: file
			});
			await this.getSiteContent(this.siteId!);
			this.toastService.showSuccess(`Content imported successfully`);
		}
		catch (err) {
			this.toastService.showError('Could not import content');
		}
		finally {
			this.loading = false;
		}
	}

	processSiteContent(): ContentTreeViewData[] {
		let builtNodes = [] as ContentTreeViewData[];

		// need our master list of content keys
		if (!this.allKeys || this.allKeys.length === 0) {
			return builtNodes;
		}

		for (let contentKey of this.allKeys) {
			if (contentKey === undefined || contentKey.contentKey === undefined || contentKey.contentKey === '') {
				continue;
			}

			let baseNode = this.baseContent.filter(c => c.contentKey?.contentKey === contentKey?.contentKey);
			let siteNode = this.siteContent.filter(c => c.contentKey?.contentKey === contentKey?.contentKey);

			let pathSplit = contentKey.contentKey?.split('.');
			if (pathSplit) {
				let parent = null;
				for (let idx = 0; idx < pathSplit.length; idx++) {
					let curNode = pathSplit[idx];
					let curNodeId = pathSplit.slice(0, idx + 1).join('.');
					let foundNode = builtNodes.find(n => n.id === curNodeId);
					let contentType = null as CmsContentType | null;
					let showContent = false;
					let baseContent: LanguageContent[] = [];
					let siteContent: LanguageContent[] = [];
					baseNode.forEach(n => {
						baseContent.push({content: n.content!, language: n.language!, modifiedBy: n.modifiedBy!, modifiedDate: n.modifiedDate!});
					});
					siteNode.forEach(n => {
						siteContent.push({content: n.content!, language: n.language!, modifiedBy: n.modifiedBy!, modifiedDate: n.modifiedDate!});
					});
					if (idx === pathSplit.length - 1) {
						showContent = true;
						contentType = contentKey?.contentType!;
					}

					if (foundNode === undefined) {
						let treeNode: ContentTreeViewData = {
							id: curNodeId,
							name: curNode,
							parent: parent,
							children: null,
							collapsed: true,
							showContent: showContent,
							contentType: contentType,
							baseContent: baseContent,
							siteContent: siteContent
						};
						builtNodes.push(treeNode);
					}
					parent = curNodeId;
				}
			}
		}

		return builtNodes;
	}

	private getPreparedData(list: any[]): ContentTreeViewData[] {
		let tree = [] as ContentTreeViewData[], lookup = {} as any;
		for (let i = 0; i < list.length; i++) {
			lookup[list[i].id] = list[i];
			list[i].children = [];
			list[i].collapsed = list[i].collapsed;
		}
		for (let i = 0; i < list.length; i++) {
			if (list[i].parent) {
				lookup[list[i].parent].children.push(list[i]);
			}
			else {
				tree.push(list[i]);
			}
		}
		return tree;
	};

	async addContentKey() {
		this.submitted = true;
		if (this.addContentKeyForm.invalid) {
		  return;
		}

		try {
			this.loading = true;
			let newContentKey = new CmsContentKey();
			newContentKey.contentKey = this.addContentKeyForm.controls.contentKey.value;
			newContentKey.contentType = this.addContentKeyForm.controls.contentType.value;
			await this.contentService.addContentKey(newContentKey);
			this.toastService.showSuccess('Content key added succesfully.');

			// we are not going to reset the content key add form after use, but rather
			// try to make it easier to add another value to the same section,
			// so 1. keep the same content type value
			// and 2. take the last path value from the input and remove it
			// and 3. focus the content key input for another round of data entry
			const lastContentKey = newContentKey.contentKey!;
			const lastCkIdx = lastContentKey.lastIndexOf('.');
			const newStartContentKey = lastContentKey.slice(0, lastCkIdx+1);
			this.addContentKeyForm.patchValue({
				contentKey: newStartContentKey
			});
			this.contentKeyInput.nativeElement.focus();

			// need to get the key added to the correct place in the nodes list
			let pathSplit = newContentKey.contentKey?.split('.');
			let parentList = this.nodes;
			let parentId: string | null = null;
			if (pathSplit && pathSplit.length > 0) {
				for (let idx = 0; idx < pathSplit.length; idx++) {
					let curNode = pathSplit[idx];
					let curNodeId = pathSplit.slice(0, idx + 1).join('.');
					let foundNode = parentList.find(n => n.id === curNodeId);
					let contentType = null as CmsContentType | null;
					let showContent = false;
					let baseContent: LanguageContent[] = [];
					let siteContent: LanguageContent[] = [];
					if (idx === pathSplit.length - 1) {
						showContent = true;
						contentType = newContentKey.contentType;
					}
					this.supportedLanguages.forEach(l => {
						baseContent.push({
							content: '',
							language: l.language!,
							modifiedBy: '',
							modifiedDate: null
						});
						siteContent.push({
							content: '',
							language: l.language!,
							modifiedBy: '',
							modifiedDate: null
						});
					});

					if (foundNode === undefined) {
						let treeNode: ContentTreeViewData = {
							id: curNodeId,
							name: curNode,
							parent: parentId,
							children: [] as ContentTreeViewData[],
							collapsed: true,
							showContent: showContent,
							contentType: contentType,
							baseContent: baseContent,
							siteContent: siteContent
						};
						parentList.push(treeNode);
						foundNode = treeNode;
					}
					parentList = foundNode.children!;
					parentId = curNodeId;
				}
			}
		}
		catch (error) {
			this.toastService.showError('There was an error adding the content key. Check your network tab for more info.');
			console.log(error);
		}
		finally {
			this.loading = false;
			this.submitted = false;
		}
	}

	async saveContent(content: ContentTreeViewData, index: number, language: string) {
		this.submitted = true;
		if (this.languageContent.at(index).invalid) {
			return;
		}

		try {

			this.loading = true;

			const siteContent = this.languageContent.at(index).get('content')?.value;

			let newContent = Object.assign(new CmsContentRequest(), {
				siteId: this.siteId,
				language,
				contentKey: content.id.trim(),
				content: siteContent.trim()
			});
			await this.contentService.saveSiteContent(newContent);
			this.toastService.showSuccess('Site content saved succesfully.');

			// we want to update our value on the node, so that we are in sync
			content.siteContent?.map(x => {
				if (x.language === language) {
					x.content = siteContent;
				}
			});
		}
		catch (error) {
			this.toastService.showError('There was an error saving the site content. Check your network tab for more info.');
		}
		finally {
			this.loading = false;
			this.submitted = false;
		}
	}

	async deleteContent(content: ContentTreeViewData) {
		this.submitted = true;
		this.loading = true;
		try {
			await this.contentService.deleteSiteContent(this.siteId!, content.id);
			this.toastService.showSuccess('Site content deleted succesfully.');
		}
		 catch (error) {
			this.toastService.showError('There was an error deleting the site content. Check your network tab for more info.');
			console.log(error);
		}
		 finally {
			this.loading = false;
			this.submitted = false;
		}
	}

	nodeClicked(node: ContentTreeViewData) {
		if (node) {
			node.collapsed = !node.collapsed;
			this.selectedNode = node;
			this.supportedLanguages.forEach((l, index) => {
				const languageContent = node.siteContent?.find(x => x.language?.includes(l.language!));
				if (languageContent) {
					this.languageContent.at(index).get('content')?.setValue(languageContent.content);
				}
				else {
					this.languageContent.at(index).get('content')?.setValue('');
				}
			});
		}
	}
}
