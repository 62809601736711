import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ThemeSettingsService } from 'services/theme-settings.service';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit {

	constructor(private titleService: Title,
		public themeSettings: ThemeSettingsService) {
	}

	ngOnInit(): void {
		this.themeSettings.appSidebarMinifiedToggledEvent.subscribe(val =>
			this.themeSettings.settings.appSidebarMinified = !this.themeSettings.settings.appSidebarMinified);

		this.themeSettings.appSidebarMobileToggledEvent.subscribe(val =>
			this.themeSettings.settings.appTopMenuMobileToggled = !this.themeSettings.settings.appTopMenuMobileToggled);
	}
}
