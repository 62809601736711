<app-customer-header [customerId]="customerId"></app-customer-header>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="card">
			<form (ngSubmit)="search()">
				<div class="card-body">
					<h5 class="card-title">Search</h5>
					<div class="row">
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="number" id="customerPaymentAttemptId" name="customerPaymentAttemptId" class="form-control"
									[(ngModel)]="paymentAttemptsQuery.customerPaymentAttemptId">
								<label for="customerPaymentAttemptId">Customer Payment Attempt Id</label>
							</div>
							<div class="form-floating mb-3">
								<input type="text" id="paymentRef" name="paymentRef" class="form-control"
									[(ngModel)]="paymentAttemptsQuery.paymentRef">
								<label for="paymentRef">Payment Reference (Transaction Id)</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="text" id="maskedPaymentData" name="maskedPaymentData" class="form-control"
									[(ngModel)]="paymentAttemptsQuery.maskedPaymentData">
								<label for="maskedPaymentData">Masked Payment Data</label>
							</div>
							<div class="form-floating mb-3">
								<app-email-input
									[(ngModel)]="paymentAttemptsQuery.billingEmailAddress"
									name="billingEmailAddress"
									label="Billing Email"
									placeholder="Billing E-mail"></app-email-input>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<select class="form-select" name="transactionType" [(ngModel)]="paymentAttemptsQuery.transactionType">
									<option value="Charge">Charge</option>
									<option value="Refund">Refund</option>
								</select>
								<label for="transactionType">Transaction Type</label>
							</div>
							<div class="form-floating mb-3">
								<select class="form-select" name="status" [(ngModel)]="paymentAttemptsQuery.status">
									<option value="Pending">Pending</option>
									<option value="Declined">Declined</option>
									<option value="Success">Success</option>
									<option value="Failed">Failed</option>
								</select>
								<label for="status">Status</label>
							</div>
						</div>
					</div>
					<button class="btn btn-primary" [disabled]="loading" type="submit">Search</button>
				</div>
			</form>
		</div>
		<div *ngIf="isSearchEnabled">
			<div class="d-flex justify-content-between align-items-end p-2">
				<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
					[pageSize]="paymentAttemptsQuery.pagination!.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
					(pageChange)="pageChanged()"></ngb-pagination>
				<div class="form-floating">
					<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
						(change)="pageChanged()" aria-label="Items per page">
						<option [ngValue]="20">20 items per page</option>
						<option [ngValue]="50">50 items per page</option>
						<option [ngValue]="100">100 items per page</option>
					</select>
					<label for="pageSize">Items per page</label>
				</div>
			</div>
		</div>
		<div class="table-responsive" *ngIf="paymentAttempts.length > 0">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Attempt Id</th>
						<th>Gateway Id</th>
						<th>Created</th>
						<th>Created By</th>
						<th>Amount</th>
						<th>Transaction Type</th>
						<th>Status</th>
						<th>Payment Ref</th>
						<th>Masked Payment Data</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let paymentAttempt of paymentAttempts">
						<td>
							{{ paymentAttempt.customerPaymentAttemptId }}
						</td>
						<td>
							{{ paymentAttempt.paymentGatewayId }}
						</td>
						<td>
							{{ paymentAttempt.createdDate | date:'short' }}
						</td>
						<td>
							{{ paymentAttempt.createdBy }}
						</td>
						<td>
							{{ paymentAttempt.amount | currency }}
						</td>
						<td>
							{{ paymentAttempt.transactionType }}
						</td>
						<td>
							{{ paymentAttempt.status }}
						</td>
						<td>
							{{ paymentAttempt.paymentRef }}
						</td>
						<td>
							{{ paymentAttempt.maskedPaymentData }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="isSearchEnabled">
			<div class="d-flex justify-content-between align-items-end p-2">
				<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
					[pageSize]="paymentAttemptsQuery.pagination!.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
					(pageChange)="pageChanged()"></ngb-pagination>
				<div class="form-floating">
					<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
						(change)="pageChanged()" aria-label="Items per page">
						<option [ngValue]="20">20 items per page</option>
						<option [ngValue]="50">50 items per page</option>
						<option [ngValue]="100">100 items per page</option>
					</select>
					<label for="pageSize">Items per page</label>
				</div>
			</div>
		</div>
		<app-alert-box *ngIf="paymentAttempts.length === 0 && !loading" alertMode="info">
			No payment attempts found.
		</app-alert-box>


	</div>
</app-spinner-with-overlay>
