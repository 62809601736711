import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { USelectMethod, WLProduct, WLProductLanguages, SiteLanguage } from '@taradel/web-api-client';
import { USelect } from 'services/distributions.service';
import { ProductsService } from 'services/products.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';
import { AuthenticationService } from 'services/authentication.service';
import { Site, SiteFilterRequest, UpdateSiteProductNameRequest } from '@taradel/admin-api-client';

@Component({
	selector: 'app-site-products',
	templateUrl: './site-products.component.html',
	styleUrls: ['./site-products.component.scss']
})
export class SiteProductsComponent implements OnInit {
	loading: boolean = false;
	uSelectMethods: USelectMethod[] = [];
	allSites: Site[] = [];
	allProducts: WLProduct[] = [];
	eddmProducts: WLProduct[] = [];
	pmProducts: WLProduct[] = [];
	politicalProducts: WLProduct[] = [];
	b2bProducts: WLProduct[] = [];
	equifaxProducts: WLProduct[] = [];
	emailProducts: WLProduct[] = [];
	facebookProducts: WLProduct[] = [];
	displayAdProducts: WLProduct[] = [];
	linkedAdProducts: WLProduct[] = [];
	groupedProducts: Map<string | USelect, WLProduct[]> = new Map<string | USelect, WLProduct[]>();
	editProductNameForm: UntypedFormGroup;
	nameToEditId = 0;
	disableEditName = false;
	modalRef: any;
	wlProductToDelete!: WLProduct;
	wlProductTranslations: WLProductLanguages[] = [];
	siteId = 0;
	languages: SiteLanguage[] = [];
	countryCode?: 'US' | 'Canada';

	constructor(
		public authService: AuthenticationService,
		public productsService: ProductsService,
		public sitesService: SitesService,
		private modalService: NgbModal,
		private toastService: ToastService,
		private route: ActivatedRoute,
		formBuilder: FormBuilder
	) {
		this.editProductNameForm = formBuilder.group({
			newProductName: new FormControl<string>('', Validators.required),
			wlProduct: new FormControl<WLProduct | undefined>(undefined, Validators.required)
		});
	}

	get editControls() { return this.editProductNameForm.controls; }

	async ngOnInit() {
		this.sitesService.currentSite.subscribe(async site => {
			if (site) {
				this.siteId = site.siteId;
				this.languages = site.languages ? site.languages : [];
				await this.getAllSiteProducts(site.siteId);
			}
		});

		this.allSites = await this.sitesService.getSitesForAdmin(new SiteFilterRequest({
			adminCheckout: false,
			showInactive: false
		}));
	}

	async getAllSiteProducts(siteId: number) {
		try {
			this.loading = true;
			[this.uSelectMethods, this.allProducts, this.wlProductTranslations] = await Promise.all([
				this.sitesService.getAllUSelect(),
				this.sitesService.getSiteProducts(siteId),
				this.sitesService.getSiteWLProductLanguages(siteId),
			]);
			this.updateUselectProducts();
		}
		catch {
			this.toastService.showError('There was an error getting the site products');
		}
		finally {
			this.loading = false;
		}
	}

	updateUselectProducts() {
		this.groupedProducts = new Map<string, WLProduct[]>();
		for (let method of this.uSelectMethods) {
			let products = this.allProducts.filter(p => p.baseProduct?.uSelectConfigurations?.some(u => u.uSelectId === method.uSelectId));
			if (products && products.length > 0) {
				this.groupedProducts.set(method.name ?? method.uSelectId.toString(), products);
			}
		}
	}

	async changeCurrentSite(siteId: number) {
		await this.sitesService.setCurrentSiteId(siteId);
	}

	async deleteWlProduct(): Promise<void> {
		try {
			this.loading = true;
			this.modalRef.close();
			this.allProducts = this.allProducts.filter(x => x.productId !== this.wlProductToDelete.productId);
			this.updateUselectProducts();
			await this.sitesService.deleteSiteProduct(this.wlProductToDelete.siteId, this.wlProductToDelete.baseProductId!);
			this.toastService.showSuccess('WLProduct deleted successfully');
		}
		catch {
			this.toastService.showError('There was an error deleted the WLProduct');
		}
		finally {
			this.loading = false;
		}
	}

	setupDelete(product: WLProduct, modal: any) {
		this.wlProductToDelete = product;
		this.openModal(modal);
	}
	openModal(modal: any) {
		this.modalRef = this.modalService.open(modal);
	}

	closeModal() {
		if (this.nameToEditId > 0) {
			this.cancelEdit();
		}
		this.modalService.dismissAll();
		this.modalRef.close();
	}

	initiateEditProductName(wlProduct: WLProduct) {
		this.disableEditName = true;
		this.nameToEditId = wlProduct.productId;
		this.editControls.wlProduct.setValue(wlProduct);
	}

	async editWLProductName() {
		const wlProductToEdit = this.editControls.wlProduct.value;
		const siteId = wlProductToEdit.siteId;
		wlProductToEdit.name = this.editControls.newProductName.value;
		let success = true;
		this.loading = true;
		try {
			await this.sitesService.updateSiteProductName(new UpdateSiteProductNameRequest({
                baseProductId: wlProductToEdit.baseProductId,
                siteId,
                name: this.editControls.newProductName.value
            }));
		}
		catch (error: any) {
			success = false;
			console.log(error);
			this.toastService.showError(`Product could not be edited: ${error.error}`);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.closeModal();
			await this.getAllSiteProducts(siteId);
		}
	}

	cancelEdit() {
		this.disableEditName = false;
		this.nameToEditId = 0;
		this.resetForm(this.editProductNameForm);
	}

	resetForm(form: UntypedFormGroup) {
		form.reset();
	}
}
