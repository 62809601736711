<app-pagefullheader [subTitle]="currentSite?.name + (!currentSite?.active ? ' (inactive)' : '')"
[subTitleClass]="!currentSite?.active ? 'text-danger' : undefined"
*ngIf="!hideContainer && siteService.currentSiteId !== -1 && allSites.length"></app-pagefullheader>
<div class="row">
	<div class="col-6">
		<div class="form-floating" *ngIf="allSites.length">
			<app-sites-list [startingSiteId]="currentSite?.siteId ?? -1" (siteSelected)="changeCurrentSite($event.siteId)" [sites]="allSites"></app-sites-list>
		</div>
	</div>
</div>

<router-outlet></router-outlet>
