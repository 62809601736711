<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col-4">
			<form [formGroup]="categoryForm">
				<div class="card p-3 mb-3">
					<div class="card-body">
						<h5 class="card-title">Print And Ship Category Information</h5>
						<div class="row mb-3">
							<div class="col">
								<div class="form-floating">
									<input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
									<label for="name">Category Name </label>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col">
								<div class="form-floating">
									<input type="text" formControlName="channelPrefix" class="form-control" min="1" max="99999"
									[ngClass]="{ 'is-invalid': submitted && f.channelPrefix.errors }">
									<label for="paperWidth">Channel Prefix</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

		<div class="col-8">
			<div class="card p-3 mb-3">
				<div class="card-body">
					<h5 class="card-title">Print And Ship Category Products</h5>

					<div class="row">
						<div class="col-sm-5">
							<h5 class="my-2">Select Product</h5>
							<input type="text" class="form-control m-b-3" placeholder="Search for Product name"
							[(ngModel)]="filterKey" (ngModelChange)="filterProducts()">
							<select [(ngModel)]="selectedProducts" name="" id="" #c class="form-select" multiple size="10">
								<option *ngFor="let product of listOfProducts" [value]="product">
									({{ product.productId}}) {{ product.name }}
								</option>
							</select>
						</div>
						<div class="col-sm-2 py-4 m-auto">
							<div class="row my-1">
								<button id="add" class="btn btn-small btn-default btn-block" (click)="selectProductsFromList()">
									<small>
										Add&nbsp;
										<span class="fa fa-arrow-right text-success"></span>
									</small>
								</button>
							</div>
							<div class="row my-1">
								<button id="remove" class="btn btn-small btn-default btn-block" (click)="removeProductsFromList()">
									<small>
										<span class="fa fa-arrow-left text-danger"></span>
										&nbsp;Remove</small>
								</button>
							</div>
							&nbsp;
						</div>
						<div class="col-sm-5">
							<h5 class="my-2">Selected Products</h5>
							<select [(ngModel)]="selectedProducts" name="" id="" #d class="form-select" multiple size="10">
								<option *ngFor="let product of listOfSelectedProducts" [value]="product">
									{{ product.name }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card-body">
		<button class="btn btn-primary btn-lg" (click)="saveCategory()">Update Category</button>
	</div>
</app-spinner-with-overlay>

