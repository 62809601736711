<div *ngIf="error">
	<p>There was an error logging in.</p>
</div>
<div *ngIf="!error">
	<div class="flex">
		<div *ngFor="let claim of claims"
			 class="row">
			<div class="col-sm-2">{{claim.key}}</div>
			<div class="col-sm-10">{{claim.value}}</div>
		</div>
	</div>
</div>
