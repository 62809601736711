<div>
	<div class="gmap-button-overlay" [ngClass]="{'column': panel() !== 'table', 'row': panel() === 'table'}">
		<button class="gmap-button align-middle" type="button" (click)="setPanel('hybrid')" [ngClass]="{'active': panel() === 'hybrid'}">
			<i class="fas fa-globe-americas"></i> Hybrid
		</button>
		<button class="gmap-button align-middle" type="button" (click)="setPanel('map')" [ngClass]="{'active': panel() === 'map'}">
			<i class="fas fa-map"></i> Map
		</button>
		<button class="gmap-button align-middle" type="button" (click)="setPanel('table')" [ngClass]="{'active': panel() === 'table'}">
			<i class="fas fa-table"></i> Table
		</button>
	</div>
	<div *ngIf="tableLoading || panel() === 'table'" class="table-panel">
		<table *ngIf="panel() === 'table'" class="table table-striped table-hover table-responsive table-sm">
			<thead>
				<tr>
					<th class="text-center" (click)="setSortKey('selected')">
						<span>Select</span>
						<span *ngIf="tableSortKey === 'selected'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th (click)="setSortKey('geocoderef')">
						<span>Route</span>
						<span *ngIf="tableSortKey === 'geocoderef'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th *ngIf="heatmapData.length" (click)="setSortKey('routeInfoPercentMatch')">
						<span>Demo. Match%</span>
						<span *ngIf="tableSortKey === 'routeInfoPercentMatch'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th (click)="setSortKey('city')">
						<span>City</span>
						<span *ngIf="tableSortKey === 'city'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th (click)="setSortKey('state')">
						<span>St.</span>
						<span *ngIf="tableSortKey === 'state'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th (click)="setSortKey('zipcode')">
						<span>Zip</span>
						<span *ngIf="tableSortKey === 'zipcode'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th class="text-end" (click)="setSortKey('ressfdu')">
						<span>Res.</span>
						<span *ngIf="tableSortKey === 'ressfdu'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th class="text-end" (click)="setSortKey('resmfdu')">
						<span>Apt.</span>
						<span *ngIf="tableSortKey === 'resmfdu'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th class="text-end" (click)="setSortKey('bizcount')">
						<span>Bus.</span>
						<span *ngIf="tableSortKey === 'bizcount'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th class="text-end" (click)="setSortKey('boxcount')">
						<span>PO.</span>
						<span *ngIf="tableSortKey === 'boxcount'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th class="text-end" (click)="setSortKey('singleFamilyRatio')">
						<span>SF</span>
						<span *ngIf="tableSortKey === 'singleFamilyRatio'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th class="text-end" (click)="setSortKey('medianincomecode')">
						<span>Income</span>
						<span *ngIf="tableSortKey === 'medianincomecode'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th class="text-end" (click)="setSortKey('medianage')">
						<span>Age</span>
						<span *ngIf="tableSortKey === 'medianage'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th class="text-end" (click)="setSortKey('medianlor')">
						<span>LoR</span>
						<span *ngIf="tableSortKey === 'medianlor'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th class="text-end" (click)="setSortKey('homeownerpercentage')">
						<span>Home Owner %</span>
						<span *ngIf="tableSortKey === 'homeownerpercentage'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
					<th class="text-end" (click)="setSortKey('distanceFromCenter')">
						<span>Dist.</span>
						<span *ngIf="tableSortKey === 'distanceFromCenter'">
							<span *ngIf="tableSortDirection === 'desc'"><i class="fas fa-chevron-down"></i></span>
							<span *ngIf="tableSortDirection === 'asc'"><i class="fas fa-chevron-up"></i></span>
						</span>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of sortedTableData" class="heatmap" [ngClass]="{
					'table-active table-success': row.selected,
					'bg-pale-yellow': row.routeInfoPercentMatch < 25,
					'bg-yellow': row.routeInfoPercentMatch >= 25 && row.routeInfoPercentMatch < 50,
					'bg-orange': row.routeInfoPercentMatch >= 50 && row.routeInfoPercentMatch < 75,
					'bg-red': row.routeInfoPercentMatch >= 75,

				}" (click)="toggleRowSelected(row)">
					<td class="text-center"><input type="checkbox" class="form-check-input" [(ngModel)]="row.selected" /></td>
					<td>{{ row.geocoderef }}</td>
					<td *ngIf="heatmapData.length" class="text-end">{{ row.routeInfoPercentMatch / 100 | percent }}</td>
					<td>{{ row.city }}</td>
					<td>{{ row.state }}</td>
					<td>{{ row.zipcode }}</td>
					<td class="text-end">{{ row.ressfdu | number:'1.0' }}</td>
					<td class="text-end">{{ row.resmfdu | number:'1.0' }}</td>
					<td class="text-end">{{ row.bizcount | number:'1.0' }}</td>
					<td class="text-end">{{ row.boxcount | number:'1.0' }}</td>
					<td class="text-end">{{ row.singleFamilyRatio | percent }}</td>
					<td class="text-end">{{ row.medianincomecode | number:'1.0' }}</td>
					<td class="text-end">{{ row.medianage | number:'1.0' }}</td>
					<td class="text-end">{{ row.medianlor | number:'1.0' }}</td>
					<td class="text-end">{{ row.homeownerpercentage/100 | percent }}</td>
					<td class="text-end">{{ row.distanceFromCenter | number:'1.1-1' }} mi</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
