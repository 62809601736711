<div class="card p-3 mb-3">
	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="row">
			<div class="col-sm-5">
				<div class="input-group mb-3">
					<select #uSelectIdDropdown class="form-select" aria-label="Select a USelect Id to filter by" (change)="routeToEditUSelect($any($event.target).value)">
						<option selected>Filter by USelect ID</option>
						<option *ngFor="let uSelect of uSelectIdsList | sort: 'asc' : 'id'" value="{{uSelect.id}}">{{uSelect.name}}</option>
					</select>
				</div>
			</div>
			<div class="col-sm-7" *ngIf="!loading && this.siteId === detailedUSelectAddOns[0].siteId">
				Filter by a USelect in order to start editing or <span><a [routerLink]="['/sites', siteId, 'uselect-addons', 'add']">Add a new USelect</a></span>
			</div>
			<div class="col-sm-7" *ngIf="!loading && this.siteId !== detailedUSelectAddOns[0].siteId">
				<span><a [routerLink]="['/sites', siteId, 'uselect-addons', 'add']">Start a new USelect Configuration</a></span>
			</div>
		</div>

		<div class="card border-0 p-3">
			<p *ngIf="!loading && this.siteId !== detailedUSelectAddOns[0].siteId">
				These are site 0 default configurations. Click on the link above to start a new configuration for this site.
			</p>
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th width="10%">USelect Add On ID</th>
						<th>USelect Add On Name</th>
						<th width="10%">USelect ID</th>
						<th>USelect Name</th>
						<th>Required</th>
						<th>Sort Order</th>
					</tr>
				</thead>
				<tbody *ngFor="let uSelectGroup of uSelectGroups" class="mb-5">
					<td class="fw-bold" colspan="3">
						<h4>{{uSelectGroup.uSelectedAddons[0].uSelectName}}</h4>
					</td>
					<tr *ngFor="let addOn of uSelectGroup.uSelectedAddons">
						<td width="10%">
							{{addOn.addOnUSelectId}}
						</td>
						<td>
							{{addOn.addOnUSelectName}}
						</td>
						<td width="10%">
							{{addOn.uSelectId}}
						</td>
						<td>
							{{addOn.uSelectName}}
						</td>
						<td>
							<div class="input-group">
								<div class="form-check">
									<input type="checkbox" role="button" class="form-check-input" [ngModel]="addOn.required" disabled>
								</div>
							</div>
						</td>
						<td>
							{{addOn.sortOrder}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-spinner-with-overlay>
</div>
