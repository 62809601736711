import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-alert-box',
	templateUrl: './alert-box.component.html',
	styleUrls: ['./alert-box.component.scss']
})

export class AlertBoxComponent {
	@Input() alertMode: string = 'default';
	@Input() alertType?: string;
	@Input() alertIcon?: string;

	colorSet = new Map([
		['default', 'primary'],
		['secondary', 'secondary'],
		['light', 'light'],
		['dark', 'dark'],
		['success', 'success'],
		['info', 'info'],
		['warning', 'warning'],
		['danger', 'danger']
	]);

	iconSet = new Map([
		['default', 'info-circle'],
		['success', 'check-circle'],
		['info', 'info-circle'],
		['warning', 'exclamation-triangle'],
		['danger', 'exclamation-circle']
	]);

	constructor() { }

	getAlertType(): string {
		let m = this.alertMode.toLowerCase();
		let t = this.colorSet.get(m);
		if (this.alertType && this.alertType !== '') {
			t = this.alertType;
		}
		return this.processAlertType(t);
	}

	processAlertType(t?: string): string {
		if (!t || t === '') {
			t = 'primary';
		}
		t = t.toLowerCase();
		if (t.startsWith('alert-')) {
			return t;
		}
		return 'alert-' + t;
	}

	getAlertIcon(): string {
		let m = this.alertMode.toLowerCase();
		let t = this.iconSet.get(m);
		if (this.alertIcon && this.alertIcon !== '') {
			t = this.alertIcon;
		}
		return this.processIconType(t);
	}

	processIconType(t?: string): string {
		if (!t || t === '') {
			t = 'info-circle';
		}
		t = t.toLowerCase();
		if (t.startsWith('fa-')) {
			return t;
		}
		return 'fa-' + t;
	}

}
