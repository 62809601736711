import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PurchaseOrderClient, RecommendedPrinter, PrinterPO, FileResponse, CreatePORequest, UpdatePORequest, POStatusResponse, UpdateVendorReferenceRequest } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchaseordersService {

	private readonly purchaseOrderClient: PurchaseOrderClient;
	constructor(http: HttpClient) {
	  this.purchaseOrderClient = new PurchaseOrderClient(http, environment.adminApiUrl);
	}

	recommendPrinters(orderItemId: number): Promise<RecommendedPrinter[]> {
		return lastValueFrom(this.purchaseOrderClient.recommendPrinters(orderItemId));
	}

	createPurchaseOrder(po: CreatePORequest): Promise<number> {
		return lastValueFrom(this.purchaseOrderClient.createPrinterPO(po));
	}

	updatePurchaseOrder(po: UpdatePORequest): Promise<number> {
		return lastValueFrom(this.purchaseOrderClient.updatePrinterPO(po));
	}

	cancelPurchaseOrder(purchaseOrderId: number): Promise<POStatusResponse> {
		return lastValueFrom(this.purchaseOrderClient.cancelPrinterPO(purchaseOrderId));
	}

	updateVendorReference(purchaseOrderId: number, vendorReference: string): Promise<number> {
		return lastValueFrom(this.purchaseOrderClient.upsertVendorReference(new UpdateVendorReferenceRequest({
			purchaseOrderId,
			vendorReference
		})));
	}

	downloadPurchaseOrder(purchaseOrderId: number): Promise<FileResponse | null> {
		return lastValueFrom(this.purchaseOrderClient.downloadPurchaseOrderDoc(purchaseOrderId));
	}

	transmitPurchaseOrderToVendor(purchaseOrderId: number): Promise<boolean> {
		return lastValueFrom(this.purchaseOrderClient.transmitPurchaseOrderToVendor(purchaseOrderId));
	}

	sendSnapAdmailRoutesDoc(purchaseOrderId: number, dropNumber: number): Promise<boolean> {
		return lastValueFrom(this.purchaseOrderClient.sendSnapAdmailRoutesDoc(purchaseOrderId, dropNumber));
	}

	getPurchaseOrderForOrderItem(orderItemId: number): Promise<PrinterPO> {
		return lastValueFrom(this.purchaseOrderClient.getPurchaseOrderForOrderItem(orderItemId));
	}

	getPurchaseOrdersForOrder(orderId: number): Promise<PrinterPO[]> {
		return lastValueFrom(this.purchaseOrderClient.getPurchaseOrdersForOrder(orderId));
	}

	getPurchaseOrderStatus(purchaseOrderId: number): Promise<POStatusResponse> {
		return lastValueFrom(this.purchaseOrderClient.getPurchaseOrderStatus(purchaseOrderId));
	}
}
