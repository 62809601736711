import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IdentityService } from 'services/identity.service';
import { ToastService } from 'services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiException, IdentityCustomer, IdentityUserRecord } from '@taradel/admin-api-client';
import { createEmailValidator } from '@taradel/white-label-common';

@Component({
	selector: 'app-debug-user-login',
	templateUrl: './debug-user-login.component.html',
	styleUrls: ['./debug-user-login.component.scss']
})
export class DebugUserLoginComponent {
	loading = false;
	customers: IdentityCustomer[] = [];
	users: IdentityUserRecord[] = [];
	searchForm: UntypedFormGroup;
	emailAddressForm: UntypedFormGroup;
	createCustomerLoginForm: UntypedFormGroup;
	filtered = false;
	customerToUpdate?: IdentityCustomer;
	modalRef: any;
	errorMessage = '';

	constructor(formBuilder: UntypedFormBuilder,
		private identityService: IdentityService,
		private toastService: ToastService,
		private modalService: NgbModal) {
		this.searchForm = formBuilder.group({
			username: new UntypedFormControl('', Validators.required)
		});

		this.emailAddressForm = formBuilder.group({
			customerId: { value: 0 },
			currentUsername: [{ value: '', disabled: true }],
			currentEmailAddress: [{ value: '', disabled: true }],
			newEmailAddress: ['', Validators.compose([
				Validators.required,
				createEmailValidator()
			])]
		});

		this.createCustomerLoginForm = formBuilder.group({
			customerId: { value: 0 },
			username: [{ value: '', disabled: true }]
		});
	}

	get s() { return this.searchForm.controls; }
	get ea() { return this.emailAddressForm.controls; }
	get cl() { return this.createCustomerLoginForm.controls; }

	async search() {
		this.loading = true;
		if (!(!!this.s.username.value) || this.s.username.value === '' || this.searchForm.invalid) {
			return;
		}
		this.filtered = true;
		const username = this.s.username.value;
		this.customers = await this.identityService.searchForCustomersByUsername(username);
		this.users = await this.identityService.searchForUsersByUsername(username);
		this.errorMessage = '';
		this.loading = false;
	}

	resetFilter() {
		this.filtered = false;
		this.customers = [];
		this.users = [];
		this.searchForm.reset();
	}

	openUpdateEmailAddressModal(customer: IdentityCustomer, modal: any) {
		this.ea.customerId.setValue(customer.customerId);
		this.ea.currentUsername.setValue(customer.username);
		this.ea.currentEmailAddress.setValue(customer.emailAddress);
		this.openModal(modal);
	}

	openCreateLoginModal(customer: IdentityCustomer, modal: any) {
		this.cl.customerId.setValue(customer.customerId);
		this.cl.username.setValue(customer.username);
		this.openModal(modal);
	}

	openModal(modal: any) {
		this.modalRef = this.modalService.open(modal, { beforeDismiss: () => {
			this.emailAddressForm.reset();
			this.createCustomerLoginForm.reset();
			this.errorMessage = '';
			return true;
		} });
	}

	closeModal() {
		this.modalService.dismissAll();
	}

	async updateEmailAddress() {
		let success = true;
		this.errorMessage = '';
		const customerId = this.ea.customerId.value;
		const emailAddress = this.ea.newEmailAddress.value;
		this.loading = true;
		try {
			await this.identityService.updateUsernameAndEmailAddress(customerId, emailAddress);
		}
		catch (error: any) {
			success = false;
			let errorMessage: string;
			if (ApiException.isApiException(error)) {
				const response = error.message;
				errorMessage = response;
			}
			else {
				errorMessage = 'Could not update username';
			}
			this.toastService.showError(errorMessage);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.toastService.showSuccess('Email Address successfully updated');
			this.s.username.setValue(emailAddress);
			this.closeModal();
			this.search();
		}
	}

	async createCustomerLogin() {
		let success = true;
		this.errorMessage = '';
		const customerId = this.cl.customerId.value;
		const username = this.cl.username.value;
		this.loading = true;
		try {
			await this.identityService.createAndLinkIdentityUser(customerId);
		}
		catch (error: any) {
			success = false;
			const response = error.error;
			this.toastService.showError(response);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.toastService.showSuccess('Customer Login successfully created and linked');
			this.s.username.setValue(username);
			this.closeModal();
			this.search();
		}
	}
}
