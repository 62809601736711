import { Component, EventEmitter, Output } from '@angular/core';
import { PmSelectionService } from 'services/pm-selection.service';

@Component({
  selector: 'app-zipcodes-address',
  templateUrl: './zipcodes-address.component.html',
  styleUrls: ['./zipcodes-address.component.scss']
})
export class ZipcodesAddressComponent {
	zipcodeEnabled = true;
	addressEnabled = true;
	@Output() updateLocation = new EventEmitter<boolean>();
	constructor(public pmSelectionService: PmSelectionService) { }

	updateAddress() {
		if (this.pmSelectionService.addresses?.length) {
			this.zipcodeEnabled = false;
			this.addressEnabled = false;
			this.updateLocation.emit(true);
		}
		else {
			this.zipcodeEnabled = true;
			this.addressEnabled = true;
			if (this.hasZipCodes()) {
				this.updateLocation.emit(true);
			}
			else {
				this.updateLocation.emit(false);
			}
		}
	}

	updateZipcode() {
		if (this.hasZipCodes()) {
			this.addressEnabled = false;
			this.updateLocation.emit(true);
		}
		else {
			this.addressEnabled = true;
			if (this.pmSelectionService.addresses?.length) {
				this.updateLocation.emit(true);
			}
			else {
				this.updateLocation.emit(false);
			}
		}
	}

	hasZipCodes(): boolean {
		return (this.pmSelectionService.getSelections('zipCodes')?.length ?? 0) > 0;
	}

}
