<div class="btn-group">
	<a class="btn btn-primary" routerLink="/customers/{{ customerId }}">Edit</a>
	<button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
		aria-expanded="false">
		<span class="visually-hidden">Toggle Dropdown</span>
	</button>
	<ul class="dropdown-menu" role="menu">
		<li role="menuitem"><a routerLink="/customers/{{ customerId }}" class="dropdown-item">Details</a></li>
		<li role="menuitem"><a routerLink="/customers/{{ customerId }}/distributions" class="dropdown-item">Distribution</a></li>
		<li role="menuitem"><a routerLink="/customers/{{ customerId }}/designs" class="dropdown-item">Designs</a></li>
		<li role="menuitem"><a routerLink="/customers/{{ customerId }}/orders" class="dropdown-item">Orders</a></li>
		<li role="menuitem"><a routerLink="/customers/{{ customerId }}/payments" class="dropdown-item">Payments</a></li>
		<li role="menuitem"><a routerLink="/customers/{{ customerId }}/payments-attempts" class="dropdown-item">Payment Attempts</a></li>
		<li role="menuitem"><a routerLink="/customers/{{ customerId }}/shop/carts" class="dropdown-item">Carts</a></li>
		<li role="menuitem"><a routerLink="/customers/{{ customerId }}/addresses" class="dropdown-item">Addresses</a></li>
		<li role="menuitem"><a routerLink="/customers/{{ customerId }}/map" class="dropdown-item">New Distribution</a></li>
		<li role="menuitem"><a routerLink="/customers/{{ customerId }}/shop/quotes" class="dropdown-item">Shopping Quotes</a></li>
	</ul>
</div>
