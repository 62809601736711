import { Component, OnInit } from '@angular/core';
import { CategorySearch, PrintAndShipCategory } from '@taradel/admin-api-client';
import { PrintAndShipService } from 'services/print-and-ship.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
	pageNo = 1;
	pageSize = 100;
	isSearchEnabled = false;
	loading = false;
	total = 0;
	categorySearch: CategorySearch = new CategorySearch();
	categories: PrintAndShipCategory[] = [];

	constructor(
		public printAndShipService: PrintAndShipService,
		public sitesService: SitesService,
		private toastService: ToastService
	) { }

	async ngOnInit(): Promise<void> {
		this.categorySearch.name = '';
		await this.search();
	}

	async search() {
		try {
			this.loading = true;
			this.categorySearch.pageNo = this.pageNo = 1;
			this.categorySearch.pageSize = this.pageSize;
			this.total = await this.printAndShipService.getCategoriesCount(this.categorySearch);
			this.categories = await this.printAndShipService.getCategories(this.categorySearch);
			if (this.total > 0) {
				this.isSearchEnabled = true;
			}
			else {
				this.isSearchEnabled = false;
			}
		}
		catch {
			this.toastService.showError('There was an error loading the categories');
		}
		finally {
			this.loading = false;
		}
	}

	async pageChanged() {
		try {
			this.loading = true;
			this.categorySearch.pageNo = this.pageNo;
			this.categorySearch.pageSize = this.pageSize;
			this.categories = await this.printAndShipService.getCategories(this.categorySearch);
		}
		catch {
			this.toastService.showError('There was an error loading the categories');
		}
		finally {
			this.loading = false;
		}
	}
}
