<div class="accordion-item">
    <h2 class="accordion-header" id="creditAndRiskHeading">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#creditAndRiskCollapse" 
            aria-expanded="true" aria-controls="creditAndRiskCollapse">
            Credit and Risk
        </button>
    </h2>
    <div id="creditAndRiskCollapse" class="accordion-collapse collapse show" aria-labelledby="creditAndRiskHeading" 
        data-bs-parent="#financeAccordion">
        <div class="accordion-body">
			<div class="row px-4">
				<div class="col-sm-6 custom-slider">
					<h4 class="text-black">Credit Score</h4>
					<app-slider
						[selectionService]="equifaxSelectionService"
						[sliderOptions]='creditScoreOptions'
						queryField='creditRiskScore'
						[sliderMin]='creditScoreMin'
						[sliderMax]='creditScoreMax'>
					</app-slider>
				</div>
			</div>
			<app-select [selectionService]="equifaxSelectionService"
				title="Business Credit Risk Class" queryField="creditRiskClass"></app-select>
			<app-select [selectionService]="equifaxSelectionService"
				title="Business Failure Risk Rating" queryField="failureRating"></app-select>
			<app-select [selectionService]="equifaxSelectionService"
				title="Supporting Reasons for Failure Risk Rating" queryField="failureReasons"></app-select>
			<div class="row">
				<div class="col-sm-8">
					<app-toggle [selectionService]="equifaxSelectionService"
						name="Current or Historical Bankruptcy" queryField="bankruptcy"></app-toggle>
					<app-toggle [selectionService]="equifaxSelectionService"
						name="Out of Business" queryField="dead"></app-toggle>
				</div>
			</div>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="corporateIncomeHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#corporateIncomeCollapse" 
            aria-expanded="false" aria-controls="corporateIncomeCollapse">
            Corporate
        </button>
    </h2>
    <div id="corporateIncomeCollapse" class="accordion-collapse collapse" aria-labelledby="corporateIncomeHeading" 
        data-bs-parent="#financeAccordion">
        <div class="accordion-body">
			<app-select [selectionService]="equifaxSelectionService"
				title="Total Revenue" queryField="corporateAmountCodes">
            </app-select>
			<app-select [selectionService]="equifaxSelectionService"
				title="Type of Amount Reported" queryField="corporateAmountType">
            </app-select>
			<app-select [selectionService]="equifaxSelectionService"
				title="Precision of Amount Reported" queryField="corporateAmountPrecision">
            </app-select>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="locationIncomeHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#locationIncomeCollapse" 
            aria-expanded="false" aria-controls="locationIncomeCollapse">
            Location
        </button>
    </h2>
    <div id="locationIncomeCollapse" class="accordion-collapse collapse" aria-labelledby="locationIncomeHeading" 
        data-bs-parent="#financeAccordion">
        <div class="accordion-body">
			<app-select [selectionService]="equifaxSelectionService"
				title="Local Revenue" queryField="locationAmountCodes">
            </app-select>
			<app-select [selectionService]="equifaxSelectionService"
				title="Type of Amount Reported" queryField="locationAmountType">
            </app-select>
			<app-select [selectionService]="equifaxSelectionService"
				title="Precision of Amount Reported" queryField="locationAmountPrecision">
            </app-select>
        </div>
    </div>
</div>
