import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerDrop, GetCustomerDropsQuery, OrderItemDropToUpdate, PaginationQuery, UpdateCustomerDropsQuery } from '@taradel/admin-api-client';
import { OrderService } from 'services/order.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-customer-drops',
  templateUrl: './customer-drops.component.html',
  styleUrl: './customer-drops.component.scss'
})
export class CustomerDropsComponent implements OnInit {
	loading = true;
	customerId = 0;
	total = 0;
	public customerDrops: CustomerDrop[] = [];
	includePastDrops = false;
	daysToPushDropDateBy?: string;
	daysToPushEndDateBy?: string;
	dropDate?: string;
	endDate?: string;
	selectAll = false;
	selectedDrops: CustomerDrop[] = [];
	hasEndDate = false;
	paginationQuery = new PaginationQuery({
		pageNo: 1,
		pageSize: 20
	});
	showError = false;
	@ViewChild('changeDropsModal') changeDropsModal!: ElementRef;
	
	constructor(
		route: ActivatedRoute,
		public orderService: OrderService,
		private toastService: ToastService,
		public modalService: NgbModal,
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
	}
	
	async ngOnInit() {
		await this.getCustomerDrops();
	}

	async filterCustomerDrops() {
	    this.paginationQuery.pageNo = 1;
		await this.getCustomerDrops();
	}
	async getCustomerDrops() {
		try {
			this.loading = true;
			[this.total, this.customerDrops] = await Promise.all([
				await this.orderService.getCustomerDropsCount(new GetCustomerDropsQuery ({
					customerId: this.customerId,
					includePastDrops: this.includePastDrops
				})),
				await this.orderService.getCustomerDrops(new GetCustomerDropsQuery ({
					customerId: this.customerId,
					includePastDrops: this.includePastDrops,
					paginationQuery: this.paginationQuery
				}))
			]);
		}
		catch(error) {
			this.toastService.showError('There was an error getting customer drops');
		}
		finally {
			this.loading = false;
		}
	}

	async pageChanged() {
		await this.getCustomerDrops();
	}

	openChangeDropsModal() {
		this.hasEndDate = this.selectedDrops.some(x => !!x.endDate);
		this.modalService.open(this.changeDropsModal, { size: 'xl' });
	}

	async updateCustomerDrops() {
		try {
			if (this.selectedDrops.length === 0) {
				return;
			}
			if (this.dropDate === undefined && (this.daysToPushDropDateBy === undefined || parseInt(this.daysToPushDropDateBy) === 0) && 
				this.endDate === undefined && (this.daysToPushEndDateBy === undefined || parseInt(this.daysToPushEndDateBy) === 0)) {
				this.showError = true;
				return;
			}
			this.loading = true;
			this.closeModal();
			const dropsToUpdate = this.selectedDrops.map(x => new OrderItemDropToUpdate({orderItemId: x.orderItemId, dropNumber: x.dropNumber}));
			await this.orderService.updateCustomerDrops(new UpdateCustomerDropsQuery ({
				daysToPushDropDateBy: this.daysToPushDropDateBy !== undefined ? parseInt(this.daysToPushDropDateBy) : 0,
				daysToPushEndDateBy: this.daysToPushEndDateBy !== undefined ? parseInt(this.daysToPushEndDateBy) : 0,
				dropDate: this.dropDate !== undefined ? this.getDate(this.dropDate) : undefined,
				endDate: this.endDate !== undefined ? this.getDate(this.endDate) : undefined,
				dropsToUpdate
			}));			
			this.reset();
			await this.getCustomerDrops();
			this.toastService.showSuccess('Selected drops were updated successfully');
		}
		catch(error) {
			this.toastService.showError('There was an error updating customer drops');
		}
		finally {
			this.loading = false;
		}
	}

	getDate(value: string) {
		const [year, month, day] = value.split('-');
		return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
	}

	addDaysToDate(date: Date | undefined, days?: string) {
		if (!date || !days) {
			return;
		}
		const newDate = new Date(date);
		return newDate.setDate(newDate.getDate() + parseInt(days));
	}

	closeModal() {
		this.showError = false;
		this.modalService.dismissAll();
	}

	selectAllDrops() {
		this.selectAll = !this.selectAll;
		if (!this.selectAll) {
			this.selectedDrops = [];
		}
		else {
			this.customerDrops.forEach(d => {
				if (!this.selectedDrops.includes(d)) {
					this.selectedDrops.push(d);
				}
			});
		}		
	}

	selectDrop(drop: CustomerDrop) {
		if (this.selectedDrops.includes(drop)) {
			this.selectedDrops = this.selectedDrops.filter(d => !(d.orderItemId === drop.orderItemId && d.dropNumber === drop.dropNumber));
		}
		else {
			this.selectedDrops.push(drop);
		}
	}

	isSelected(drop: CustomerDrop) {
		return this.selectedDrops.some(d => d.orderItemId === drop.orderItemId && d.dropNumber === drop.dropNumber);
	}
	
	reset() {
		this.dropDate = undefined;
		this.daysToPushDropDateBy = undefined;

		this.endDate = undefined;
		this.daysToPushEndDateBy = undefined;
	}
}
