import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DesignHuddleClient as WebDesignHuddleClient, DownloadAssetResponse, DownloadProjectAssetsRequest, FileResponse, ListProjectsResponse } from '@taradel/web-api-client';
import { DesignHuddleClient, GetCustomerProjectRequest, ReExportProjectRequest, UpdateOrderProjectIdRequest } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})


export class DesignHuddleService {

	private readonly designHuddleClient: DesignHuddleClient;
	private readonly webDesignHuddleClient: WebDesignHuddleClient;

	constructor(http: HttpClient) {
		this.designHuddleClient = new DesignHuddleClient(http, environment.adminApiUrl);
		this.webDesignHuddleClient = new WebDesignHuddleClient(http, environment.webApiUrl);
	}

	getUserToken(siteId: number): Promise<string> {
		return lastValueFrom(this.webDesignHuddleClient.getUserToken(siteId));
	}

	getCustomerUserToken(customerId: number): Promise<string> {
		return lastValueFrom(this.designHuddleClient.getCustomerUserToken(customerId));
	}

	getCustomerProjects(request: GetCustomerProjectRequest): Promise<ListProjectsResponse> {
		return lastValueFrom(this.designHuddleClient.listCustomerProjects(request));
	}

	getCustomerProject(request: GetCustomerProjectRequest): Promise<ListProjectsResponse> {
		return lastValueFrom(this.designHuddleClient.getCustomerProject(request));
	}

	reExportCustomerProject(request: ReExportProjectRequest) {
		lastValueFrom(this.designHuddleClient.reExportProject(request));
	}

	updateProjectIdForReExport(request: UpdateOrderProjectIdRequest) {
		lastValueFrom(this.designHuddleClient.updateProjectIdForReExport(request));
	}

	getAppAccessToken(): Promise<string> {
		return lastValueFrom(this.designHuddleClient.getAppAccessToken());
	}

	getProjectAssets(request: DownloadProjectAssetsRequest): Promise<DownloadAssetResponse[]> {
		return lastValueFrom(this.webDesignHuddleClient.downloadProjectAsset(request));
	}

	getProjectAssetsAsZip(request: DownloadProjectAssetsRequest): Promise<FileResponse | null> {
		return lastValueFrom(this.designHuddleClient.downloadProjectAssetFileZip(request));
	}
}
