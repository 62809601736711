import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductsClient as WebProductsClient } from '@taradel/web-api-client';
import {
	PrintMethod, Product, ProductDesignOption, ProductOption, ProductOptionCategory, ProductPrintMethodOption, ProductsClient,
	USelectProductConfiguration, ProductDesignOptionClient, ProductsSearch, Category, DesignTemplate, ProductDesignType, ProductPowerUp,
} from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

export enum ProductCategories {
	Stock = 1,
	Ink = 2,
	Bleed = 3,
	Fold = 4,
	PaperStock = 6,
	PaperOption = 7,
	PostageClass = 14,
	CallTrackingNumberType = 17,
	Coating = 18
}

export enum ProductCategoryOptions {
	StandardMail = 77,
	FirstClass = 78,
    NonProfit = 116,
	LocalAreaCode = 89,
	TollFree = 90
}

@Injectable({
	providedIn: 'root'
})
export class ProductsService {

	private readonly productsClient: ProductsClient;
	private readonly productDesignOptionClient: ProductDesignOptionClient;

	private readonly webProductsClient: WebProductsClient;
	constructor(http: HttpClient) {
		this.productsClient = new ProductsClient(http, environment.adminApiUrl);
		this.productDesignOptionClient = new ProductDesignOptionClient(http, environment.adminApiUrl);

		this.webProductsClient = new WebProductsClient(http, environment.webApiUrl);
	}

	getProductsCount(productsSearch: ProductsSearch): Promise<number> {
		return lastValueFrom(this.productsClient.getAllProductsCount(productsSearch));
	}

	getAllProducts(productsSearch: ProductsSearch): Promise<Product[]> {
		return lastValueFrom(this.productsClient.getAllProducts(productsSearch));
	}

	getAllUSelectProducts(uSelectId: number): Promise<Product[]> {
		return lastValueFrom(this.productsClient.getAllUSelectProducts(uSelectId));
	}

	getAllPrintAndShipProducts(): Promise<Category[]> {
		return lastValueFrom(this.productsClient.getAllPrintAndShipProducts());
	}

	getDesignTemplates(): Promise<DesignTemplate[]> {
		return lastValueFrom(this.productsClient.getDesignTemplates());
	}

	getProduct(productId: number): Promise<Product> {
		return lastValueFrom(this.webProductsClient.getBaseProduct(productId));
	}

	addProduct(product: Product): Promise<number> {
		return lastValueFrom(this.productsClient.addBaseProduct(product));
	}

	updateProduct(product: Product): Promise<number> {
		return lastValueFrom(this.productsClient.updateBaseProduct(product));
	}

	getProductUSelect(baseProductId: number): Promise<USelectProductConfiguration> {
		return lastValueFrom(this.productsClient.getBaseProductUSelect(baseProductId));
	}
	addProductUSelect(productUselect: USelectProductConfiguration): Promise<number> {
		return lastValueFrom(this.productsClient.addBaseProductUSelect(productUselect));
	}
	updateProductUSelect(productUselect: USelectProductConfiguration): Promise<number> {
		return lastValueFrom(this.productsClient.updateBaseProductUSelect(productUselect));
	}

	getAllProductOptions(): Promise<ProductOptionCategory[]> {
		return lastValueFrom(this.productsClient.getAllProductOptions());
	}

	getAllProductOptionCategories(): Promise<ProductOptionCategory[]> {
		return lastValueFrom(this.productsClient.getAllProductOptionCategories());
	}

	getProductOptionCategory(categoryId: number): Promise<ProductOptionCategory> {
		return lastValueFrom(this.productsClient.getProductOptionCategory(categoryId));
	}

	createProductOptionCategory(newCategory: ProductOptionCategory): Promise<number> {
		return lastValueFrom(this.productsClient.createProductOptionCategory(newCategory));
	}

	getProductOptionsByCategory(productId: number): Promise<ProductOptionCategory[]> {
		return lastValueFrom(this.webProductsClient.getProductOptionsByCategory(productId));
	}

	updateProductOptionCategory(category: ProductOptionCategory): Promise<number> {
		return lastValueFrom(this.productsClient.updateProductOptionCategory(category));
	}

	getProductOption(optionId: number): Promise<ProductOption> {
		return lastValueFrom(this.webProductsClient.getProductOption(optionId));
	}

	createProductOption(option: ProductOption): Promise<number> {
		return lastValueFrom(this.productsClient.createProductOption(option));
	}

	deleteProductOption(optionId: number): Promise<number> {
		return lastValueFrom(this.productsClient.deleteProductOption(optionId));
	}

	updateProductOption(option: ProductOption): Promise<number> {
		return lastValueFrom(this.productsClient.updateProductOption(option));
	}

	getPrintMethods(): Promise<PrintMethod[]> {
		return lastValueFrom(this.productsClient.getPrintMethods());
	}

	getPrintMethodOptions(productId: number): Promise<ProductPrintMethodOption[]> {
		return lastValueFrom(this.webProductsClient.getPrintMethodOptions(productId));
	}

	addOptionToProduct(option: ProductPrintMethodOption): Promise<number> {
		return lastValueFrom(this.productsClient.addOptionToProduct(option));
	}

	removeOptionFromProduct(option: ProductPrintMethodOption): Promise<number> {
		return lastValueFrom(this.productsClient.removeOptionFromProduct(option));
	}

	getProductDesignTypes(): Promise<ProductDesignType[]> {
		return lastValueFrom(this.productDesignOptionClient.getProductDesignTypes());
	}

	getProductDesignOption(siteId: number, productId: number): Promise<ProductDesignOption[]> {
		return lastValueFrom(this.productDesignOptionClient.getProductDesignOptions(siteId, productId));
	}

	updateProductDesignOptions(productDesignOptions: ProductDesignOption[], siteId: number, productId: number): Promise<number> {
		return lastValueFrom(this.productDesignOptionClient.updateProductDesignOptions(productDesignOptions, siteId, productId));
	}

	getProductPowerUps(productUSelectId: number): Promise<ProductPowerUp[]> {
		return lastValueFrom(this.productsClient.getProductPowerUps(productUSelectId));
	}

	updateProductPowerUps(productUSelectId: number, productDesignOptions: ProductPowerUp[]): Promise<number> {
		return lastValueFrom(this.productsClient.updateProductPowerUps(productUSelectId, productDesignOptions));
	}
}
