import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { PrintersService } from 'services/printers.service';
import { PrinterZone } from '@taradel/web-api-client';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-printer-zones',
  templateUrl: './printer-zones.component.html',
  styleUrls: ['./printer-zones.component.scss']
})
export class PrinterZonesComponent implements OnInit {

  printerId: number = 0;
  selectedMinZip3: number = 0;
  selectedZone: number = 0;
  zoneArray: number[] = [];
  printerZones: PrinterZone[] = [];
  newPrinterZone = new PrinterZone();
  minZipSearch = new Subject<string>();
  maxZipSearch = new Subject<string>();
  loading: boolean = false;

  @ViewChild('minZipInput') minZipInput!: ElementRef;
  @ViewChild('maxZipInput') maxZipInput!: ElementRef;
  @ViewChild('zoneDropdown') zoneDropdown!: ElementRef;

  constructor(private route: ActivatedRoute,
    private printersService: PrintersService,
    private toastService: ToastService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async params => {
		try {
			this.loading = true;
			this.printerId = parseInt(params.get('printerId') ?? '0', 10);
			this.newPrinterZone.printerId = this.printerId;
		  	await this.getZonesByPrinter();
		}
		catch {
			this.toastService.showError('Error loading zones for printer');
		}
		finally {
			this.loading = false;
		}

    });

    this.minZipSearch.pipe(
			debounceTime(300),
			distinctUntilChanged()
		).subscribe(async (value: string) => {
			if (value === '') {
				value = '0';
			}
			await this.getZonesByPrinterAndMinZip(parseInt(value));
		});
  }

  async getZonesByPrinter() {
    this.selectedMinZip3 = 0;
    this.selectedZone = 0;
    this.zoneArray = [];
    this.printerZones = await this.printersService.getAllZonesByPrinter(this.printerId, this.selectedMinZip3, this.selectedZone);

    this.getArrayOfDistinctZones(this.printerZones);
  }

  getArrayOfDistinctZones(printerZones: PrinterZone[]) {
    printerZones.forEach(pz => {
      this.zoneArray.push(pz.zone);
    });

    this.zoneArray = this.zoneArray.filter((v, i, a) => a.indexOf(v) === i);
    this.zoneArray = this.zoneArray.sort();
  }

  async getZonesByPrinterAndMinZip(minZip3: number) {
    this.loading = true;
    this.selectedMinZip3 = minZip3;
    this.printerZones = await this.printersService.getAllZonesByPrinter(this.printerId, this.selectedMinZip3, this.selectedZone);
    this.loading = false;
  }

  async getZonesByPrinterAndZone(zone: number) {
    this.loading = true;
    this.selectedZone = zone;
    this.printerZones = await this.printersService.getAllZonesByPrinter(this.printerId, this.selectedMinZip3, this.selectedZone);
    this.loading = false;
  }

  async addNewPrinterZone() {
    if (!this.newPrinterZone.minZip3 || !this.newPrinterZone.maxZip3 || !this.newPrinterZone.zone) {
      this.toastService.showWarning('At least one field is empty');
      return;
    }
	try {
		this.loading = true;
		await this.printersService.createPrinterZone(this.newPrinterZone!, this.newPrinterZone.printerId.toString());
		await this.getZonesByPrinter();
		this.newPrinterZone = new PrinterZone();
		this.newPrinterZone.printerId = this.printerId;
		this.toastService.showSuccess('New zone was successfully added');
	}
	catch {
		this.toastService.showError('Adding new zonefailed');
	}
	finally {
		this.loading = false;
	}
  }

  async updatePrinterZone(zone: PrinterZone) {
    if (!zone.zone) {
      this.toastService.showWarning('Zone was not input correctly');
      return;
    }
	try {
		this.loading = true;
		await this.printersService.updatePrinterZone(zone, zone.printerId.toString());
		await this.getZonesByPrinter();
		this.toastService.showSuccess('Zone was successfully updated', 'Success', 3000);
	}
	catch {
		this.toastService.showError('There was an error updating the Zone');
	}
	finally {
		this.loading = false;
	}
  }

  async removePrinterZone(zone: PrinterZone) {
	try {
		this.loading = true;
		await this.printersService.removePrinterZone(this.printerId, zone.minZip3, zone.maxZip3, zone.zone);
		await this.getZonesByPrinter();
		this.toastService.showSuccess('Printer zone removed successfully');
	}
	catch {
		this.toastService.showError('There was an error removing the printer zone');
	}
	finally {
		this.loading = false;
	}
}

  async clearMinZipFilter() {
	try {
		this.loading = true;
		this.minZipInput.nativeElement.value = '';
		await this.getZonesByPrinterAndMinZip(0);
	}
	catch {
		this.toastService.showError('New zone was successfully added');
	}
	finally {
		this.loading = false;
	}
  }

  async clearFilter() {
	try {
		this.loading = true;
		this.minZipInput.nativeElement.value = '';
		this.zoneDropdown.nativeElement.value = 'Filter by zone';
		await this.getZonesByPrinter();
	}
	catch {
		this.toastService.showError('There was an error clearing the filters');
	}
	finally {
		this.loading = false;
	}

  }
}
