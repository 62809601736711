import { Component, ViewChild, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef, OnInit, ElementRef } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import {
	CreateSnapAdMailDistributionQuery, Customer, Demographics,
	CaSpatialResponse, PostageType, Site, SnapAdMailDistribution, WLProduct,
	LetterCarrierWalk
} from '@taradel/admin-api-client';
import { CaHeatmapQuery, DriveTimeQuery, HeatmapItem, PriceCalculatorRequest, ProductPricingResponse } from '@taradel/web-api-client';
import { Feature } from 'geojson';
import { MapDataService } from 'services/map-data.service';
import { SitesService } from 'services/sites.service';
import { PricingService } from 'services/pricing.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerService } from 'services/customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'services/toast.service';
import { NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';
import { GooglePlaceResult } from 'app/lib/google-maps';
import { SiteConfigService } from 'services/site-config.service';

const deselectedColor = '#FFFFB2';
const selectedColor = '#32CF07';
const hoveringColor = 'cornflowerblue';

class Color {
	r: number;
	g: number;
	b: number;

	constructor(r: number, g: number, b: number) {
		this.r = r;
		this.g = g;
		this.b = b;
	}

	toString() {
		return '#' +
			Math.round(this.r).toString(16).padStart(2, '0') +
			Math.round(this.g).toString(16).padStart(2, '0') +
			Math.round(this.b).toString(16).padStart(2, '0');
	}
}

export class ColoredHeatmapItem extends HeatmapItem {
	color: string = '#000000';
	constructor(item: HeatmapItem) {
		super();
		Object.assign(this, item);
	}
}

export interface DemographicValues {
	key: string;
	value: boolean;
}

@Component({
	selector: 'app-ca-maps',
	templateUrl: './ca-maps.component.html',
	styleUrls: ['./ca-maps.component.scss']
})
export class CaMapsComponent implements AfterViewInit, OnInit {

	@ViewChild(GoogleMap) map!: GoogleMap;
	@ViewChild('placesInput') placesInput!: ElementRef;
	center = { lat: 43.647271, lng: -79.37963 };
	loading = false;
	estimateLoading = false;
	zoom = 12;
	showSearchCustomer: boolean = false;
	mapOptions: google.maps.MapOptions = {
		scrollwheel: true
	};
	mapLoaded: boolean = false;
	heatmapData: ColoredHeatmapItem[] = [];
	address: string = ''; //"181 Bay Street, Suite 1800, Toronto, Ontario, Canada";
	zipCode: string = ''; //"M5J 2T9";
	postalCode: string = '';
	city: string = '';
	province: string = '';

	mapAddress: string = '';
	mapZipCode: string = '';

	latitude: number = 0;
	longitude: number = 0;
	pins: google.maps.Marker[] = [];
	marker!: google.maps.Marker;
	radiusCircle!: google.maps.Circle;
	driveTimePolygon!: google.maps.Polygon;
	prevPolygons: google.maps.Polygon[] = [];
	showSavedSpatials = false;
	previousSelectedFeatures: CaSpatialResponse[] = [];
	routesAreaRadius = 40;
	useBusiness: boolean = true;
	useFarm: boolean = true;
	useHouse: boolean = true;
	useApartment: boolean = true;
	totalPrintSelections: number = 0;
	totalPreviousPrintSelections: number = 0;
	totalApartmentsSelected: number = 0;
	totalHousesSelected: number = 0;
	totalBusinessesSelected: number = 0;
	totalFarmsSelected: number = 0;
	ages: string[] = [];
	hhIncomeSel: string[] = [];
	hhIncome: string[] = [];
	radiusActive: boolean = false;
	radiusDtVal = 0;
	radiusDtSelected: string = 'radius';
	demographicsSelected: boolean = false;
	hovering: boolean = false;
	hoveringFeature?: Feature;
	hoveringGeocode: string = '';
	hoveringRoutePercentMatch: number | undefined;
	distribution?: SnapAdMailDistribution;
	distributionId: number = 0;
	customerId: number = 0;
	selectedTab: string = 'map';
	mapName: string = '';
	pasteResidentialChecked: boolean = true;
	pasteBusinessesChecked: boolean = false;
	zipCodesAndRoutesString: string = '';
	customer?: Customer;
	selectAllDemosFloor: number = 0;
	underMinimumSelections: boolean = false;
	snapMinimum: number = 0;

	householdIncome: DemographicValues[] = [
		{ key: '<20K', value: false },
		{ key: '20K-39,999', value: false },
		{ key: '40K-59,999', value: false },
		{ key: '60K-79,999', value: false },
		{ key: '80K-99,999', value: false },
		{ key: '100K+', value: false }
		/*{ key: '100K-124,999', value: false },
		{ key: '125K-149,999', value: false },
		{ key: '150K-199,999', value: false },
		{ key: '200K-299,999', value: false },
	{ key: '300k-500,000+', value: false }*/];
	hhIncomeMap = new Map<string, string[]>([
		['<20K', ['A']],
		['20K-39,999', ['B']],
		['40K-59,999', ['C']],
		['60K-79,999', ['D']],
		['80K-99,999', ['E']],
		['100K+', ['F']],
		/*['100K-124,999', ['F']],
		['125K-149,999', ['G']],
		['150K-199,000', ['H']],
		['200K-299,000', ['I']],
		['300k-500,000+', ['J']]*/
	]);
	medianAge: DemographicValues[] = [
		{ key: '0', value: false },
		{ key: '5', value: false },
		{ key: '10', value: false },
		{ key: '15', value: false },
		{ key: '20', value: false },
		{ key: '25', value: false },
		{ key: '30', value: false },
		{ key: '35', value: false },
		{ key: '40', value: false },
		{ key: '45', value: false },
		{ key: '50', value: false },
		{ key: '55', value: false },
		{ key: '60', value: false },
		{ key: '65', value: false },
		{ key: '70', value: false },
		{ key: '75', value: false },
		{ key: '80', value: false },
		{ key: '85', value: false }
	];
	ageMap = new Map<string, string>([
		['0', 'A'],
		['5', 'B'],
		['10', 'C'],
		['15', 'D'],
		['20', 'E'],
		['25', 'F'],
		['30', 'G'],
		['35', 'H'],
		['40', 'I'],
		['45', 'J'],
		['50', 'K'],
		['55', 'L'],
		['60', 'M'],
		['65', 'N'],
		['70', 'O'],
		['75', 'P'],
		['80', 'Q'],
		['85', 'R']
	]);
	homeOwnership: string = '';
	children: string = '';
	gender: string = '';
	activeSites: Site[] = [];
	snapProducts: WLProduct[] = [];
	basicPrice: number = 0;
	premiumPrice: number = 0;
	printCost: number = 0;
	googleCost: number = 0;
	facebookCost: number = 0;
	emailCost: number = 0;
	selectedProductPriceMatrix: ProductPricingResponse[] = [];
	googlePriceMatrix: ProductPricingResponse[] = [];
	facebookPriceMatrix: ProductPricingResponse[] = [];
	emailPriceMatrix: ProductPricingResponse[] = [];
	selectedProductId: number = 0;
	selectedSiteId: number = 0;

	private _features = new Map<string, Feature>();
	private _googleMapFeatures: google.maps.Data.Feature[] = [];
	currentAreaRoutes: string[] = [];
	postalCodePrefix = '';
	options!: NgxGpAutocompleteOptions;

	@Input() set features(features: Feature[]) {
		this._features.clear();

		this._googleMapFeatures.forEach(feature => this.map.data.remove(feature));
		this._googleMapFeatures = [];

		features.forEach(feature => {
			let featureId = feature.properties!.geocoderef;
			this._features.set(featureId, feature);
			this._googleMapFeatures.push(...this.map.data.addGeoJson(feature));
		});
	}

	@Output() selectedFeatures = new Map<string, Feature>();

	@Output() featureHovering = new EventEmitter<Feature>();
	@Output() featureSelected = new EventEmitter<Feature>();
	@Output() featureUnselected = new EventEmitter<Feature>();


	get match(): number | false {
		let restotal = 0;
		let resmatch = 0;
		for (const feature of this.selectedFeatures.values()) {
			const houseCount = feature.properties?.houseCount ?? 0;
			restotal += houseCount;
			const heatmapItem = this.heatmapData.find(item => item.geocodeRef === feature.properties?.geocoderef);
			resmatch += houseCount * (heatmapItem?.percentMatch ?? 0);
		}

		return Math.round(resmatch / restotal);
	}

	constructor(
		private mapdataApiService: MapDataService,
		private changeDetection: ChangeDetectorRef,
		private siteConfigService: SiteConfigService,
		private sitesService: SitesService,
		private pricingService: PricingService,
		private router: Router,
		private route: ActivatedRoute,
		private customerService: CustomerService,
		private modalService: NgbModal,
		private toastService: ToastService
	) {

	}

	ngOnInit() {
		this.route.paramMap.subscribe(async params => {
			try {
				this.loading = true;
				this.customerId = parseInt(params.get('customerId') ?? '0', 10);
				this.distributionId = parseInt(params.get('distributionId') ?? '0', 10);
				let sites = await this.sitesService.getAllSites();
				this.activeSites = sites.filter(e => e.active);
				if (this.distributionId > 0) {
					// let's load this existing map for editing, shall we?
					await this.loadDistribution();
				}

				this.customer = await this.customerService.getCustomer(this.customerId);
				this.selectedSiteId = this.customer?.affiliateID;
				if (this.selectedSiteId && this.selectedSiteId > 0) {
					this.loadSiteProducts(this.selectedSiteId);
				}
			}
			catch {
				this.toastService.showError("There was a problem loading the component", 'Load Error');
			}
			finally {
				this.loading = false;
			}
		});
		this.options = { componentRestrictions: { country: "ca" } } as NgxGpAutocompleteOptions;
	}

	async ngAfterViewInit(): Promise<void> {
		const userAgent = navigator.userAgent;
		let snapMinimumSetting = await this.siteConfigService.getNumber(this.selectedSiteId, 'Site', 'SnapAdmailMinimum');
		if (snapMinimumSetting > 0) {
			this.snapMinimum = snapMinimumSetting;
		}

		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
			this.mapOptions = {
				disableDoubleClickZoom: true,
				zoomControl: false,
				mapTypeControl: false,
				streetViewControl: false,
				fullscreenControl: false,
			};
			this.map.data.addListener('click', (event: google.maps.Data.MouseEvent) => {
				const featureId: string = event.feature.getProperty('geocoderef') as string;
				const feature = this._features.get(featureId);
				this.featureHovering.emit(feature);
			});
		}
		else {
			this.mapOptions = {
				fullscreenControl: false,
				streetViewControl: false,
				mapTypeControlOptions: {
					mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
				},
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				streetViewControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				}
			};
			this.map.data.addListener('mouseover', (event: google.maps.Data.MouseEvent) => {
				const featureId: string = event.feature.getProperty('geocoderef') as string;
				if (featureId !== undefined) {
					this.map.data.overrideStyle(event.feature, { fillColor: hoveringColor, strokeWeight: 2 });
				}

				const feature = this._features.get(event.feature.getProperty('geocoderef') as string);
				this.featureHovering.emit(feature);
				this.onFeatureHovering(feature);

			});

			this.map.data.addListener('mouseout', (event: google.maps.Data.MouseEvent) => {
				const featureId: string = event.feature.getProperty('geocoderef') as string;
				if (this.selectedFeatures.has(featureId)) {
					this.map.data.overrideStyle(event.feature, { fillColor: selectedColor });
				}
				else {
					if (this.heatmapData.length > 0) {
						const color = this.heatmapData.find(y => y.geocodeRef === event.feature.getProperty('geocoderef'))!.color!;
						this.map.data.overrideStyle(event.feature, { fillColor: color, strokeWeight: 1 });
					}
					else {
						this.map.data.overrideStyle(event.feature, { fillColor: deselectedColor, strokeWeight: 1 });
					}
				}


				this.featureHovering.emit(undefined);
				this.onFeatureHovering(undefined);

			});

			this.map.data.addListener('click', (event: google.maps.Data.MouseEvent) => {
				const featureId: string = event.feature.getProperty('geocoderef') as string;
				this.clickEvent(featureId);
			});
		}
		this.map.data.setStyle({
			fillColor: deselectedColor,
			clickable: true,
			fillOpacity: 0.5,
			strokeWeight: 1,
			strokeOpacity: 0.75
		});

		await this.loadRoutes();
	}

	async loadDistribution(): Promise<void> {
		this.distribution = await this.mapdataApiService.getSnapAdMailDistribution(this.customerId, this.distributionId);
		if (!this.distribution) {
			return;
		}

		this.address = this.distribution.startAddress ?? '';
		this.postalCode = this.distribution.startZipCode ?? '';
		this.useBusiness = this.distribution.useBusiness;
		this.useApartment = this.distribution.useApartment;
		this.useFarm = this.distribution.useFarm;
		this.useHouse = this.distribution.useHouse;
		this.totalPreviousPrintSelections = 0;
		await this.selectRoutesBasedOnInput('loadRoute');
		for (let area of this.distribution.letterCarrierWalks!) {
			this.selectRoute(area.name!);
			this.totalPreviousPrintSelections += area.total;
		}
		// apply demographics back to the map, too
		if (this.distribution.demographics) {
			if (this.distribution.demographics.hhIncome) {
				for (let income of this.distribution.demographics.hhIncome) {
					let selIncomeMap = '';
					for (let [key, value] of this.hhIncomeMap.entries()) {
						if (value.indexOf(income) >= 0) {
							selIncomeMap = key;
							break;
						}
					}
					let selIncome = this.householdIncome.find(f => f.key === selIncomeMap);
					if (selIncome) {
						selIncome.value = true;
					}
				}
			}

			if (this.distribution.demographics.ages) {
				for (let age of this.distribution.demographics.ages) {
					let selAgeMap = '';
					for (let [key, value] of this.ageMap.entries()) {
						if (value === age) {
							selAgeMap = key;
							break;
						}
					}
					let selAge = this.medianAge.find(f => f.key === selAgeMap);
					if (selAge) {
						selAge.value = true;
					}
				}
			}

			if (this.distribution.demographics.homeOwnership && this.distribution.demographics.homeOwnership.length > 0) {
				this.homeOwnership = this.distribution.demographics.homeOwnership[0];
			}

			if (this.distribution.demographics.children && this.distribution.demographics.children.length > 0) {
				this.children = this.distribution.demographics.children[0];
			}

			if (this.distribution.demographics.gender && this.distribution.demographics.gender.length > 0) {
				this.gender = this.distribution.demographics.gender[0];
			}

			await this.selectRoutesBasedOnInput('demographics');
		}
	}

	reloadRoute(): void {
		const currentUrl = this.router.url;
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this.router.navigate([currentUrl]);
		});
	}

	clearAllSelections(): void {
		for (let [key, geom] of this.selectedFeatures.entries()) {
			this.deselectRoute(key);
		}
	}

	selectRoute(geocodeRef: string): void {
		const feature = this._features.get(geocodeRef);
		if (feature !== undefined) {
			this.map.data.overrideStyle(this._googleMapFeatures.find(x => x.getProperty('geocoderef') === geocodeRef)!, { fillColor: selectedColor });

			this.featureSelected.emit(feature);
			this.onFeatureSelected(feature);
		}
	}

	deselectRoute(geocodeRef: string): void {
		const feature = this._features.get(geocodeRef);
		if (feature !== undefined) {
			this.featureUnselected.emit(feature);
			this.onFeatureUnselected(feature);
			if (this.heatmapData.length > 0) {
				const color = this.heatmapData.find(y => y.geocodeRef === geocodeRef)!.color!;
				this.map.data.overrideStyle(this._googleMapFeatures.find(x => x.getProperty('geocoderef') === geocodeRef)!, { fillColor: color });
			}
			else {
				this.map.data.overrideStyle(this._googleMapFeatures.find(x => x.getProperty('geocoderef') === geocodeRef)!, { fillColor: deselectedColor });
			}
		}
	}

	highlight(featureId: string) {
		const feature = this._googleMapFeatures.find(x => x.getProperty('geocoderef') === featureId)!;
		this.map.data.overrideStyle(feature, { fillColor: hoveringColor });

		this.featureHovering.emit(this._features.get(feature.getProperty('geocoderef') as string));
		this.onFeatureHovering(this._features.get(feature.getProperty('geocoderef') as string)!);
	}

	unhighlight(featureId: string) {
		const feature = this._googleMapFeatures.find(x => x.getProperty('geocoderef') === featureId)!;
		if (this.selectedFeatures.has(featureId)) {
			this.map.data.overrideStyle(feature, { fillColor: selectedColor });
		}
		else {
			if (this.heatmapData.length > 0) {
				const color = this.heatmapData.find(y => y.geocodeRef === featureId)!.color!;
				this.map.data.overrideStyle(feature, { fillColor: color });
			}
			else {
				this.map.data.overrideStyle(feature, { fillColor: deselectedColor });
			}
		}

		this.featureHovering.emit(undefined);
	}

	clickEvent(featureId: string): void {
		const feature = this._features.get(featureId);
		const googleMapFeature = this._googleMapFeatures.find(x => x.getProperty('geocoderef') === featureId)!;
		if (this.selectedFeatures.has(featureId)) {
			this.featureUnselected.emit(feature);
			this.onFeatureUnselected(feature!);
			if (this.heatmapData.length > 0) {
				const color = this.heatmapData.find(y => y.geocodeRef === featureId)!.color!;
				this.map.data.overrideStyle(googleMapFeature, { fillColor: color, strokeWeight: 1 });
			}
			else {
				this.map.data.overrideStyle(googleMapFeature, { fillColor: deselectedColor, strokeWeight: 1 });
			}
		}
		else {
			this.featureSelected.emit(feature);
			this.onFeatureSelected(feature!);
			this.map.data.overrideStyle(googleMapFeature, { fillColor: selectedColor, strokeWeight: 2 });
		}
	}

	recenter(latitude: number, longitude: number): void {
		this.map.panTo({ lat: latitude, lng: longitude });
		this.zoom = 12;
	}

	async dropPin(): Promise<void> {
		if (this.address.length > 0 || this.postalCode.length > 0) {
			this.loading = true;
			const result = await this.mapdataApiService.getCaRouteIdsInArea(this.address, this.city, this.province, this.postalCode, 'radius', 25, this.latitude, this.longitude);

			if (result) {
				const newPin = new google.maps.Marker({
					position: { lat: result.latitude, lng: result.longitude },
					map: this.map.googleMap!,
					clickable: true,
					title: (this.address.length > 0 ? this.address : this.postalCode) + ', ' + this.postalCode
				});
				let contentString = '<strong>' + (this.address.length > 0 ? this.address : this.postalCode) + '</strong>, ' + this.postalCode;
				let infowindow = new google.maps.InfoWindow({
					content: contentString
				});
				newPin.addListener("click", () => {
					infowindow.open(this.map.googleMap, newPin);
				});
				newPin.setMap(this.map.googleMap!);

				this.pins.push(newPin);

				this.address = '';
				this.postalCode = '';
				const input = this.placesInput.nativeElement as HTMLInputElement;
				input.value = '';
				this.recenter(result.latitude, result.longitude);
			}
			this.loading = false;
		}
	}

	removePin(pin: google.maps.Marker): void {
		let foundPinIdx = this.pins.findIndex(p => p.getLabel() === pin.getLabel());
		if (foundPinIdx > -1) {
			pin.setMap(null);
			const label = pin.getLabel();
			for (let i = 0; i < this.pins.length; i++) {
				let p = this.pins[i];
				if (p.getLabel() === label) this.pins.splice(i, 1);
				break;
			}
		}
	}



	async loadRoutes(): Promise<void> {
		if (!this.distribution) {
			if (!this.address && !this.postalCode && !this.postalCodePrefix) {
				return;
			}
		}

		this.mapLoaded = false;
		if (this.marker !== undefined) {
			this.marker.setMap(null);
		}
		if (this.radiusCircle !== undefined) {
			this.radiusCircle.setMap(null);
		}
		if (this.driveTimePolygon !== undefined) {
			this.driveTimePolygon.setMap(null);
		}
		if (!this.distribution) {
			const routesInfo = await this.mapdataApiService.getCaRouteIdsInArea(this.address, this.city, this.province, this.postalCode, 'radius', 25, this.latitude, this.longitude);
			this.currentAreaRoutes = routesInfo.routeIds!;
			this.latitude = routesInfo.latitude;
			this.longitude = routesInfo.longitude;
		}
		else {
			const surroundingRoutes = await this.mapdataApiService.getSurroundingRoutesCa(this.distribution.distributionId);
			this.latitude = surroundingRoutes.latitude ?? 0;
			this.longitude = surroundingRoutes.longitude ?? 0;

			this.currentAreaRoutes = surroundingRoutes.surroundingRouteIds!;
			// load previously saved spatial data from s3
			try {
				this.previousSelectedFeatures = await this.mapdataApiService.caSavedSpatials(this.distributionId);
			}
			catch (err: any) {
				// spatials file is not in s3
			}
		}

		let possibleFeatures = await this.mapdataApiService.fetchCaFeatures(this.currentAreaRoutes);
		this.features = possibleFeatures.filter(e => e.properties!.houseCount > 0 || e.properties!.businessCount > 0 || e.properties!.apartmentCount > 0 || e.properties!.farmCount > 0);
		this.recenter(this.latitude, this.longitude);

		this.marker = new google.maps.Marker({
			position: { lat: this.latitude, lng: this.longitude },
			map: this.map.googleMap!,
			clickable: true
		});
		if (this.address.length > 0 || this.postalCode.length > 0) {
			let contentString = "<strong>" + (this.address.length > 0 ? this.address : this.postalCode) + "</strong>" + ", " + this.postalCode;

			let infowindow = new google.maps.InfoWindow({
				content: contentString
			});
			this.marker.addListener("click", () => {
				infowindow.open(this.map.googleMap, this.marker);
			});
		}
		this.radiusCircle = new google.maps.Circle({
			center: { lat: this.latitude, lng: this.longitude },
			strokeOpacity: 1,
			strokeWeight: 6,
			strokeColor: '#000000',
			fillColor: '#ffffff',
			fillOpacity: 0.01,
			clickable: false
		});
		this.driveTimePolygon = new google.maps.Polygon({
			strokeOpacity: 1,
			strokeWeight: 6,
			strokeColor: '#000000',
			fillColor: '#ffffff',
			fillOpacity: 0.01,
			clickable: false
		});

		this.mapAddress = this.address;
		this.mapZipCode = this.postalCode;
		this.address = '';
		this.postalCode = '';
		const input = this.placesInput.nativeElement as HTMLInputElement;
		input.value = '';

		this.mapLoaded = true;
	}

	loadSavedSpatials() {
		if (!this.showSavedSpatials) {
			this.prevPolygons.map(x => x.setMap(null));
			this.prevPolygons = [];
			return;
		}
		this.prevPolygons = [];
		this.previousSelectedFeatures.map(x => {
			const geom = JSON.parse(x.geom!);
			if (geom?.coordinates) {
				if (isNaN(geom.coordinates[0][0][0])) {
					geom.coordinates[0].forEach((coordinate: any, index: number) => {
						const prevPolygon = new google.maps.Polygon({
							strokeOpacity: 1,
							strokeWeight: 4,
							strokeColor: '#dc3545',
							fillColor: '#dc3545',
							fillOpacity: 0.5,
							clickable: false
						});

						let bounds = new google.maps.LatLngBounds();
						const paths: google.maps.LatLng[] = [];
						coordinate.forEach((z: number[]) => {
							let latlng = new google.maps.LatLng(z[1], z[0]);
							paths.push(latlng);
							bounds.extend(latlng);
						});
						prevPolygon.setPaths(paths);
						prevPolygon.setOptions({ zIndex: 99999 });
						this.prevPolygons.push(prevPolygon);
					});
				}
				else {
					const prevPolygon = new google.maps.Polygon({
						strokeOpacity: 1,
						strokeWeight: 4,
						strokeColor: '#dc3545',
						fillColor: '#dc3545',
						fillOpacity: 0.5,
						clickable: false
					});
					let bounds = new google.maps.LatLngBounds();
					const paths: google.maps.LatLng[] = [];
					geom.coordinates[0].forEach((coordinate: any) => {
						let latlng = new google.maps.LatLng(coordinate[1], coordinate[0]);
						paths.push(latlng);
						bounds.extend(latlng);
					});
					prevPolygon.setPaths(paths);
					prevPolygon.setOptions({ zIndex: 99999 });
					this.prevPolygons.push(prevPolygon);
				}
			}
		});
		this.prevPolygons.map(x => x.setMap(this.map.googleMap!));
	}

	handleAddressChange(address: GooglePlaceResult) {
		let streetNumber = '';
		let streetName = '';
		let postalCode = '';
		let postalCodePrefix = '';
		this.latitude = address.geometry!.location!.lat();
		this.longitude = address.geometry!.location!.lng();
		address.address_components?.forEach(x => {
			switch (x.types[0]) {
				case 'street_number': {
					streetNumber = x.long_name;
					break;
				}
				case 'route': {
					streetName = ' ' + x.long_name;
					break;
				}
				case 'locality': {
					this.city = x.long_name;
					break;
				}
				case 'administrative_area_level_1': {
					this.province = x.short_name;
					break;
				}
				case 'postal_code': {
					postalCode = x.long_name;
					break;
				}
				case 'postal_code_prefix': {
					postalCodePrefix = x.long_name;
				}
			}
		});
		this.address = (streetNumber + ' ' + streetName).trim();
        this.address = this.address.length > 0 ? this.address : address.formatted_address!;
		this.postalCode = postalCode;
		this.postalCodePrefix = postalCodePrefix;
	}

	clearHeatmap(): void {
		this.householdIncome.forEach(e => e.value = false);
		this.medianAge.forEach(e => e.value = false);
		this.homeOwnership = '';
		this.heatmapData = [];
		this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].clear();
		this._googleMapFeatures.forEach(x => {
			const isRouteSelected = this.selectedFeatures.has(x.getProperty('geocoderef') as string);
			if (!isRouteSelected) {
				this.map.data.overrideStyle(x, { fillColor: deselectedColor });
			}
		});
		this.demographicsSelected = false;
	}

	useHouseAdresses(): void {
		this.useHouse = !this.useHouse;
		this.updatetotalPrintSelections();
	}

	useApartmentAddresses(): void {
		this.useApartment = !this.useApartment;
		this.updatetotalPrintSelections();
	}

	useFarmAdresses(): void {
		this.useFarm = !this.useFarm;
		this.updatetotalPrintSelections();
	}

	useBusinessAdresses(): void {
		this.useBusiness = !this.useBusiness;
		this.updatetotalPrintSelections();
	}

	updatetotalPrintSelections() {
		this.totalPrintSelections = (this.useHouse && this.totalHousesSelected ? this.totalHousesSelected : 0) + (this.useApartment && this.totalApartmentsSelected ? this.totalApartmentsSelected : 0) +
			(this.useFarm && this.totalFarmsSelected ? this.totalFarmsSelected : 0) + (this.useBusiness && this.totalBusinessesSelected ? this.totalBusinessesSelected : 0);
	}

	onFeatureSelected(feature: Feature): void {
		if (this.selectedFeatures.has(feature.properties!.geocoderef)) {
			return;
		}
		this.selectedFeatures.set(feature.properties!.geocoderef, feature);
		const homesCount = feature.properties!.houseCount;
		const apartmentsCount = feature.properties!.apartmentCount;
		const farmCount = feature.properties!.farmCount;
		const businessCount = feature.properties!.businessCount;
		this.totalHousesSelected += homesCount;
		this.totalApartmentsSelected += apartmentsCount;
		this.totalBusinessesSelected += businessCount;
		this.totalFarmsSelected += farmCount;
		this.updatetotalPrintSelections();
		this.calculatePriceEstimates();
		this.changeDetection.detectChanges();
	}

	onFeatureUnselected(feature: Feature): void {
		if (!this.selectedFeatures.has(feature.properties!.geocoderef)) {
			return;
		}
		this.selectedFeatures.delete(feature.properties!.geocoderef);
		const homesCount = feature.properties!.houseCount;
		const apartmentsCount = feature.properties!.apartmentCount;
		const farmCount = feature.properties!.farmCount;
		const businessCount = feature.properties!.businessCount;
		this.totalHousesSelected -= homesCount;
		this.totalApartmentsSelected -= apartmentsCount;
		this.totalBusinessesSelected -= businessCount;
		this.totalFarmsSelected += farmCount;
		this.updatetotalPrintSelections();
		this.calculatePriceEstimates();
		this.changeDetection.detectChanges();
	}

	onFeatureHovering(feature?: Feature): void {
		if (!feature) {
			this.hovering = false;
			this.hoveringGeocode = '';
			this.hoveringFeature = undefined;
			this.hoveringRoutePercentMatch = undefined;
			return;
		}

		this.hovering = true;
		this.hoveringGeocode = this.splitPostalCode(feature.properties!.geocoderef);
		this.hoveringFeature = feature;

		if (this.heatmapData.length > 0) {
			const routeHeatmap = this.heatmapData.find(y => y.geocodeRef === feature.properties!.geocoderef)!;
			this.hoveringRoutePercentMatch = routeHeatmap.percentMatch / 100;
		}

		this.changeDetection.detectChanges();
	}

	clearAllSelectedFeatures(): void {
		this.selectedFeatures.forEach(feature => {
			this.deselectRoute(feature.properties!.geocoderef);
		});
		this.updatetotalPrintSelections();
	}

	async selectRoutesBasedOnInput(slide: string): Promise<void> {
		if (slide === 'loadRoute') {
			this.loading = true;
			try {
				this.clearAllSelectedFeatures();
				await this.loadRoutes();
			}
			catch (error) {
				this.toastService.showError('There was a problem selecting routes', 'Load Error');
				console.log(error);
			}
			finally {
				this.loading = false;
			}
		}
		else if (slide === 'droppin') {
			await this.dropPin();
		}
		else if (slide === 'demographics') {
			await this.getHeatMap();
		}
		else if (slide === 'drivetime') {
			this.radiusCircle.setMap(null);
			await this.drawDrivetime();
		}
		else if (slide === 'radius') {
			this.driveTimePolygon.setMap(null);
			this.drawRadius();
		}
		else if (slide === 'selectWithin') {
			await this.selectRoutesInRadius();
		}
		else if (slide === 'selectDemographics') {
			await this.selectRoutesFromDemographics();
		}
		else if (slide === 'all') {
			this.clearAllSelectedFeatures();
			await this.loadRoutes();
			await this.getHeatMap();
			await this.drawDrivetime();
			this.drawRadius();
		}
	}

	getDemographics(): Demographics {
		let demoHomeOwnership = this.homeOwnership !== '' ? [this.homeOwnership] : [];
		let demoGender = this.gender !== '' ? [this.gender] : [];
		let demoChildren = this.children !== '' ? [this.children] : [];
		let demoIncome: string[] = [];
		let demoAges: string[] = [];
		this.medianAge.forEach(e => {
			if (e.value === true) {
				let foundAge = this.ageMap.get(e.key.toString());
				if (foundAge) {
					demoAges.push(foundAge);
				}
			}
		});
		this.householdIncome.forEach(e => {
			if (e.value === true) {
				let foundIncome = this.hhIncomeMap.get(e.key.toString());
				if (foundIncome) {
					demoIncome.push.apply(demoIncome, foundIncome);
				}
			}
		});


		return new Demographics({
			ages: demoAges,
			hhIncome: demoIncome,
			homeOwnership: demoHomeOwnership,
			gender: demoGender,
			children: demoChildren
		});
	}

	hasDemographicsSelections(demographics: Demographics): boolean {
		if (!demographics) return false;
		if (demographics.hhIncome && demographics.hhIncome.length > 0) {
			return true;
		}
		if (demographics.ages && demographics.ages.length > 0) {
			return true;
		}
		if (demographics.children && demographics.children.length > 0) {
			return true;
		}
		if (demographics.homeOwnership && demographics.homeOwnership.length > 0) {
			return true;
		}
		if (demographics.gender && demographics.gender.length > 0) {
			return true;
		}

		return false;
	}

	async getHeatMap(): Promise<void> {
		this.loading = true;
		try {
			let demographics = this.getDemographics();

			if (this.hasDemographicsSelections(demographics)) {
				this.demographicsSelected = true;
				const heatmapData = await this.mapdataApiService.caHeatmap(new CaHeatmapQuery({
					routes: this.currentAreaRoutes.join(','),
					ages: demographics.ages?.join(','),
					children: demographics.children?.join(','),
					gender: demographics.gender?.join(','),
					householdIncome: demographics.hhIncome?.join(','),
					homeOwnership: demographics.homeOwnership?.join(',')
				}));
				this.heatmapData = heatmapData.map(item => new ColoredHeatmapItem(item));
				this.fixColors();
				this.setHeatmap();
			}
			else {
				this.demographicsSelected = false;
				this.heatmapData = [];
				this.clearHeatmap();
			}
		}
		catch (error) {
			this.toastService.showError('There was a problem getting the heat map.', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	fixColors() {
		const colors = [
			{ r: 255, g: 255, b: 178 }, //yellow
			{ r: 255, g: 116, b: 23 }, //orange
			{ r: 218, g: 37, b: 38 }, //red
			{ r: 0xBD, g: 0x00, b: 0x26 },
		];
		this.heatmapData.forEach(heatmapItem => {
			if (heatmapItem.percentMatch < 33) {
				heatmapItem.color = (new Color(colors[0].r, colors[0].g, colors[0].b)).toString();
			}
			else if (heatmapItem.percentMatch < 66) {
				heatmapItem.color = (new Color(colors[1].r, colors[1].g, colors[1].b)).toString();
			}
			else {
				heatmapItem.color = (new Color(colors[2].r, colors[2].g, colors[2].b)).toString();
			}
		});

	}

	setHeatmap(): void {
		this._googleMapFeatures.forEach(x => {
			const routeInfo = this.heatmapData.find(y => y.geocodeRef === x.getProperty('geocoderef'))!;
			if (!routeInfo) {
				return;
			}
			const isRouteSelected = this.selectedFeatures.has(routeInfo.geocodeRef!);
			if (!isRouteSelected) {
				this.map.data.overrideStyle(x, { fillColor: routeInfo.color!, fillOpacity: 0.5 });
			}
		});
		const div = document.createElement('div');
		div.innerHTML = '<div class="row text-center" style="margin-right:10px;width:300px;font-size:10px"><p style="margin-bottom:0px;"> Demographic Heatmap Legend</p> <br /><div class="col-4" style="background-color:#FFFFB2"><span class="t4">0-33%</span></div><div class="col-4" style="background-color:#FF7417"><span class="t3">33-66%</span></div><div class="col-4" style="background-color:#DA3B20"><span class="t2">66-100%</span></div></div>';
		this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].clear();
		this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(div);
	}

	async drawDrivetime(): Promise<void> {
		this.radiusCircle.setMap(null);
		this.driveTimePolygon.setMap(null);
		let bounds = new google.maps.LatLngBounds();
		const paths: google.maps.LatLng[] = [];
		if (this.radiusDtVal !== undefined && this.radiusDtVal > 0) {
			const response = await this.mapdataApiService.caDriveTime(new DriveTimeQuery({
				latitude: this.latitude,
				longitude: this.longitude,
				minutes: this.radiusDtVal
			}));

			response.coordinates![0].forEach((coordinate: number[]) => {
				let latlng = new google.maps.LatLng(coordinate[1], coordinate[0]);
				paths.push(latlng);
				bounds.extend(latlng);
			});
		}
		if (this.radiusDtVal !== 0) {
			this.map.fitBounds(bounds);
			this.driveTimePolygon.setPaths(paths);
			this.driveTimePolygon.setOptions({ zIndex: 99999 });
			this.driveTimePolygon.setMap(this.map.googleMap!);
			this.radiusActive = true;
		}
	}

	drawRadius() {
		this.driveTimePolygon.setMap(null);
		this.radiusCircle.setMap(null);
		if (this.radiusDtVal > 0) {
			let bounds = new google.maps.LatLngBounds();
			this.radiusCircle.setRadius(this.radiusDtVal * 1000);
			let latlng = bounds.union(this.radiusCircle.getBounds()!);
			this.map.fitBounds(latlng);
			this.radiusCircle.setOptions({ zIndex: 999999 });
			this.radiusCircle.setMap(this.map.googleMap!);
			this.radiusActive = true;
		}
	}

	async selectRoutesInRadius(): Promise<void> {
		if (!this.radiusActive) {
			return;
		}

		try {
			this.loading = true;
			let routeIds = await this.mapdataApiService.routesByDriveTimeRadiusCa(this.latitude, this.longitude, this.radiusDtVal, this.radiusDtSelected);
			if (routeIds) {
				for (let routeId of routeIds) {
					this.selectRoute(routeId);
				}
			}
		}
		catch (error) {
			this.toastService.showError('There was a problem selecting routes', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	async selectRoutesFromDemographics() {
		this.loading = true;
		if (!this.demographicsSelected) {
			return;
		}

		if (this.radiusActive) {
			let routeIds = await this.mapdataApiService.routesByDriveTimeRadiusCa(this.latitude, this.longitude, this.radiusDtVal, this.radiusDtSelected);
			if (routeIds) {
				for (let routeId of routeIds) {
					const routeHm = this.heatmapData.find(e => e.geocodeRef! === routeId);
					if ((routeHm?.percentMatch ?? 0) >= this.selectAllDemosFloor) {
						this.selectRoute(routeId);
					}
				}
			}
		}
		else {
			this.heatmapData.forEach(e => {
				if (e.percentMatch >= this.selectAllDemosFloor) {
					this.selectRoute(e.geocodeRef!);
				}
			});
		}
		this.loading = false;
	}

	async loadSiteProducts(siteId: number) {
		this.selectedSiteId = siteId;
		this.snapProducts = await this.sitesService.getSiteProductsByUselect(this.selectedSiteId, 2);
	}

	async loadProductDetails(productId: string) {
		if (productId === '') {
			return;
		}
		this.selectedProductId = parseInt(productId, 10);
		if (this.selectedProductId > 0) {
			this.estimateLoading = true;

			this.selectedProductPriceMatrix = await this.pricingService.getProductPricing(this.selectedSiteId, this.customerId, this.selectedProductId);
			this.googlePriceMatrix = await this.pricingService.getProductPricing(this.selectedSiteId, this.customerId, 260);
			this.emailPriceMatrix = await this.pricingService.getProductPricing(this.selectedSiteId, this.customerId, 236);
			this.facebookPriceMatrix = await this.pricingService.getProductPricing(this.selectedSiteId, this.customerId, 272);
			this.calculatePriceEstimates();
		}
		else {
			this.basicPrice = 0;
			this.premiumPrice = 0;
		}
	}

	async calculatePriceEstimates() {
		if (this.selectedProductId <= 0) {
			return;
		}

		this.estimateLoading = true;

		let selectedProductPrice;
		await this.pricingService.getPriceCalculator(new PriceCalculatorRequest({
			baseProductId: this.selectedProductId,
			customerId: this.customerId,
			postageType: PostageType.StandardMail,
			quantity: this.totalPrintSelections,
			siteId: this.selectedSiteId
		})).then(priceCalculator => {
			selectedProductPrice = priceCalculator;
			if (this.totalPrintSelections > 1000) {
				this.basicPrice = Math.round(selectedProductPrice.total);
				this.premiumPrice = Math.round(selectedProductPrice.total * 1.35);
			}
		});
		let printMatrix = this.selectedProductPriceMatrix.find(e => e.minimumQuantity < this.totalPrintSelections && e.maximumQuantity! > this.totalPrintSelections);

		if (printMatrix) {
			this.underMinimumSelections = false;
			this.printCost = printMatrix !== undefined ? Math.floor(printMatrix.pricePerThousand! * this.totalPrintSelections / 1000) : 0;
		}
		else {
			this.underMinimumSelections = true;
		}

		this.estimateLoading = false;
	}

	splitPostalCode(postalCode: string): string {
		if (postalCode !== undefined) {
			if (postalCode.length > 5) {
				return postalCode.substring(0, 5) + ' - ' + postalCode.substring(5);
			}
			else {
				return postalCode;
			}
		}
		else {
			return postalCode;
		}
	}

	openModal(content: any): void {
		this.modalService.open(content);
	}

	convertToNumber(num: string) {
		return parseInt(num) ?? 0;
	}

	async saveMap(): Promise<void> {
		this.loading = true;
		let areas: LetterCarrierWalk[] = [];
		this.selectedFeatures.forEach(feature => {
			if (feature.properties) {
				const houseCount = this.useHouse ? (feature.properties.houseCount ?? 0) : 0;
				const apartmentsCount = this.useApartment ? (feature.properties.apartmentCount ?? 0) : 0;
				const farmCount = this.useFarm ? (feature.properties.farmCount ?? 0) : 0;
				const businessCount = this.useBusiness ? (feature.properties.businessCount ?? 0) : 0;
				let areaTotal = houseCount + apartmentsCount + farmCount + businessCount;

				let area = new LetterCarrierWalk({
					name: feature.properties.geocoderef,
					friendlyName: feature.properties.friendlyName,
					houses: houseCount,
					apartments: apartmentsCount,
					farms: farmCount,
					business: businessCount,
					total: areaTotal
				});
				areas.push(area);
			}
		});
		let demographics = this.getDemographics();

		const query = new CreateSnapAdMailDistributionQuery({
			name: this.mapName,
			startAddress: this.mapAddress,
			startZipCode: this.mapZipCode,
			useHouse: this.useHouse,
			useApartment: this.useApartment,
			useFarm: this.useFarm,
			useBusiness: this.useBusiness,
			selections: areas,
			demographics: demographics,
            latitude: this.latitude,
            longitude: this.longitude
		});
		try {
			let mapId = await this.mapdataApiService.createSnapAdMailDistribution(this.customerId, query);
			if (mapId && mapId > 0) {
				this.loading = false;
				this.modalService.dismissAll();
				// i guess we should redirect them to the saved map, eh?
				this.router.navigate(['/customers', this.customerId, 'distributions', mapId]);
			}
			else {
				this.loading = false;
				this.toastService.showError('There was a problem saving the map');
			}
		}
		catch (error) {
			this.toastService.showError('There was a problem saving the map', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}
}
