import { Component, Input, OnInit } from '@angular/core';
import { Customer } from '@taradel/web-api-client';
import { CustomerService } from 'services/customer.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-customer-info',
	templateUrl: './customer-info.component.html',
	styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {
	@Input() customerId!: number;
	customer?: Customer;
	constructor(
		private customerService: CustomerService,
		private toastService: ToastService
		) { }

  async ngOnInit(): Promise<void> {
	try {
		// get customer data
		this.customer = await this.customerService.getCustomer(this.customerId);
	}
	catch {
		this.toastService.showError('There was an error getting the customer');
	}
  }

}
