<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row mt-3">
		<div class="col-12">
			<form [formGroup]="couponForm">
				<div class="card">
					<div class="card-header">
						<h5>Order Coupon</h5>
					</div>
					<div class="card-body">
						<ng-container *ngIf="c.originalCouponCode.value.length > 0">
							<div *ngIf="c.markOriginalCouponAsDeleted.value === false; else originalCouponRemoved" class="d-flex px-md-5 px-2 justify-content-between">
								<p>
									{{ c.originalCouponCode.value }}
									&nbsp;
									<span *ngIf="couponDiscountAmt">
										({{ couponDiscountAmt | currency }})
									</span>
								</p>
								<button class="btn btn-sm btn-danger me-2" data-toggle="tooltip" data-placement="top" title="Mark As Deleted" (click)="updateOriginalCoupon()">
									<i class="fa fa-trash"></i>
								</button>
							</div>
							<ng-template #originalCouponRemoved>
								<div class="d-flex mb-3 px-md-5 px-2 justify-content-between">
									<s>
										{{ c.originalCouponCode.value }}
									</s>
									<button class="btn btn-sm btn-primary" data-toggle="tooltip" data-placement="top" title="Unmark As Deleted" (click)="updateOriginalCoupon()">
										<i class="fa fa-undo"></i>
									</button>
								</div>
							</ng-template>
						</ng-container>
						<ng-container *ngIf="c.newCouponCode.value.length > 0">
							<div class="d-flex mb-3 px-md-5 px-2 justify-content-between">
								<p class="text-success">
									{{ c.newCouponCode.value }}
									&nbsp;
									<span *ngIf="couponDiscountAmt" class="text-success">
										({{ couponDiscountAmt | currency }})
									</span>
								</p>
								<button class="btn btn-sm btn-danger me-2" data-toggle="tooltip" data-placement="top" title="Mark As Deleted" (click)="clearNewCouponCode()">
									<i class="fa fa-trash"></i>
								</button>
							</div>
						</ng-container>
						<div class="px-md-5 px-2 mt-3">
							<div class="input-group mb-3">
								<div class="form-floating">
									<input type="text" class="form-control" id="couponCode" formControlName="couponCodeToValidate" placeholder="Coupon Code" [ngClass]="{'is-invalid': (submitted && c.couponCodeToValidate?.errors) || (submitted && !coupon?.couponDiscount?.isValid)}" />
									<label form="couponCode">Coupon Code</label>
								</div>
								<button class="btn btn-primary px-4" type="button" (click)="validateCoupon()">Validate Coupon</button>
							</div>
							<div *ngIf="submitted && c.couponCodeToValidate.errors" class="input-error">
								Coupon code needs to be entered
							</div>
							<div *ngIf="!coupon?.couponDiscount?.isValid; else clickSaveBtn">
								<p class="input-error">
									{{ coupon?.couponDiscount?.couponMessage }}
								</p>
							</div>
							<ng-template #clickSaveBtn>
								<p *ngIf="c.newCouponCode.value.length > 0" class="text-success">
									{{ coupon?.couponDiscount?.couponMessage }}
									<br />
									Click save button to save this coupon to the order
								</p>
							</ng-template>
						</div>

						<div class="row mt-3">
							<div class="col-12">
								<div class="row d-flex justify-content-end">
									<div class="col-auto">
										<button class="btn btn-sm btn-success" data-toggle="tooltip" data-placement="top" title="Save Changes"
										(click)="saveChanges()">
											Save Changes
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</app-spinner-with-overlay>
