import { Component, OnInit, Input, EventEmitter, ViewChild, Output, ElementRef } from '@angular/core';
import { Observable, OperatorFunction, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, filter, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CustomerAddress, AddressSearchQuery, AddressTypes, Customer } from '@taradel/web-api-client';
import { CustomerAddressService } from 'services/customer-address.service';
import { NewAddressComponent } from '../new-address/new-address.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'services/customer.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-customer-addresses',
	templateUrl: './customer-addresses.component.html',
	styleUrls: ['./customer-addresses.component.scss']
})
export class CustomerAddressesComponent implements OnInit {
	AddressTypes = AddressTypes;
	addresses: CustomerAddress[] = [];
	loading = false;
	modalRef?: any;
	selectedAddress: CustomerAddress | undefined;
	addressPlaceholder = '';
	focused = false;
	addressToPrefill?: CustomerAddress;
	addressIsInvalid = false;
	customer?: Customer;

	focus$ = new Subject<string>();
	click$ = new Subject<string>();

	@ViewChild(NewAddressComponent) newAddressComponent!: NewAddressComponent;
	@ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;
	@ViewChild('newAddressModal') newAddressModal!: ElementRef;

	@Output() addressSelected = new EventEmitter<number>();

	@Input() submitted = false;
	@Input() customerId!: number;
	@Input() addressType!: string;
	@Input() cartAddressId?: number | undefined;
	@Input() allowCopyEdit: boolean = false;
	@Input() disableChangeAddress: boolean = false;

	constructor(private customerAddressService: CustomerAddressService,
		private customerService: CustomerService,
		private modalService: NgbModal,
		private toastService: ToastService
	) { }

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;
			this.addresses = await this.getCustomerAddresses('');
			await this.getDefaultAddress();
			this.getPlaceholder();
		}
		catch {
			this.toastService.showError('There was an error loading customer addresses');
		}
		finally {
			this.loading = false;
		}
	}

	async getCustomerAddresses(term: string): Promise<CustomerAddress[]> {
		let query = {
			customerId: this.customerId,
			address: term,
			getDefaultAddresses: false,
			pageNo: 1,
			pageSize: 50
		} as AddressSearchQuery;
		return await this.customerAddressService.searchCustomerAddresses(query);
	}

	async resetAddressField() {
		this.selectedAddress = undefined;
		this.addressSelected.emit(0);
		this.addresses = await this.getCustomerAddresses('');
	}

	async getDefaultAddress() {
		if (!!this.cartAddressId) {
			const address = await this.customerAddressService.getCustomerAddress(this.customerId, this.cartAddressId);
			// don't auto-popup new address modal in OrderItemDetailsComponent
			if (!this.allowCopyEdit) {
				const isAddressValid = this.validateSelectedAddress(address);
				if (!isAddressValid) {
					this.handleInvalidAddress(address);
					return;
				}
			}
			this.selectedAddress = address;
			this.addressSelected.emit(this.cartAddressId);
			return;
		}

		this.customer = await this.customerService.getCustomer(this.customerId);
		if (!this.customer) {
			return;
		}
		switch (this.addressType) {
			case AddressTypes.Billing:
				if (this.customer.defaultBillingAddressId) {
					this.selectedAddress = await this.customerAddressService.getCustomerAddress(this.customerId, this.customer.defaultBillingAddressId);
				}
				break;
			case AddressTypes.Return:
				if (this.customer.defaultReturnAddressId) {
					this.selectedAddress = await this.customerAddressService.getCustomerAddress(this.customerId, this.customer.defaultReturnAddressId);

				}
				break;
			case AddressTypes.Mailing:
				if (this.customer.defaultMailingAddressId) {
					this.selectedAddress = await this.customerAddressService.getCustomerAddress(this.customerId, this.customer.defaultMailingAddressId);
				}
				break;
			case AddressTypes.Shipping:
				if (this.customer.defaultShippingAddressId) {
					this.selectedAddress = await this.customerAddressService.getCustomerAddress(this.customerId, this.customer.defaultShippingAddressId);
				}
				break;
			default:
				this.selectedAddress = undefined;
		}
		if (!!this.selectedAddress) {
			this.addressSelected.emit(this.selectedAddress.addressId);
		}
	}

	validateSelectedAddress(address: CustomerAddress): boolean {
		switch (this.addressType) {
			case AddressTypes.Return:
				return address.company !== '' ? true : false;
			case AddressTypes.Shipping:
				return address.phoneNumber
					&& ((address.firstName !== ''
					&& address.lastName !== '')
					|| address.company !== '')
					? true : false;
			case AddressTypes.Mailing:
				return (address.firstName !== ''
					&& address.lastName !== '')
					|| address.company !== ''
					? true : false;
			case AddressTypes.Billing:
				return address.firstName !== ''
					&& address.lastName !== ''
					&& address.phoneNumber !== ''
					&& address.emailAddress !== ''
					? true : false;
			default:
				return address.firstName !== ''
					&& address.lastName !== ''
					&& address.phoneNumber !== ''
					&& address.emailAddress !== ''
					&& address.company !== ''
					? true : false;
		}
	}

	handleInvalidAddress(address: CustomerAddress) {
		this.addressIsInvalid = true;
		this.prefillWithSelectedAddress(address);
	}

	handleCopyEditAddress() {
		this.prefillWithSelectedAddress(this.selectedAddress!);
	}

	prefillWithSelectedAddress(address: CustomerAddress) {
		this.addressToPrefill = address;
		this.openModal(this.newAddressModal);
	}

	getPlaceholder() {
		switch (this.addressType) {
			case AddressTypes.Billing:
				this.addressPlaceholder = '';
				break;
			case AddressTypes.Return:
				this.addressPlaceholder = 'Printed back of postcard';
				break;
			case AddressTypes.Mailing:
				this.addressPlaceholder = '';
				break;
			case AddressTypes.Shipping:
				this.addressPlaceholder = '';
				break;
			default:
				this.addressPlaceholder = '';
		}
	}

	addressesFormatter = (x: { address1: string }) => x.address1;

	searchAddresses: OperatorFunction<string, readonly CustomerAddress[]> = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
		const inputFocus$ = this.focus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			switchMap(async (term: string) => this.addresses = await this.getCustomerAddresses(term))
		);
	};

	openModal(modal: any) {
		this.modalRef = this.modalService.open(modal, { windowClass: '', size: 'xl modal-dialog-scrollable' });
	}

	closeModal() {
		this.addressIsInvalid = false;
		this.addressToPrefill = undefined;
		this.modalService.dismissAll();
		this.modalRef.close();
	}

	getAddressId(response: any) {
		const address = this.addresses.find(x => x.addressId === response.item.addressId)!;
		// don't auto-popup new address modal in OrderItemDetailsComponent
		if (!this.cartAddressId || (!!this.cartAddressId && this.cartAddressId !== address.addressId)) {
			const isAddressValid = this.validateSelectedAddress(address);
			if (!isAddressValid) {
				this.handleInvalidAddress(address);
				return;
			}
		}
		this.selectedAddress = address;
		this.addressSelected.emit(response.item.addressId);
	}

	async setAddressId(addressId: number) {
		this.addressToPrefill = undefined;
		this.addresses = await this.customerAddressService.getCustomerAddresses(this.customerId);
		const address = this.addresses.find(x => x.addressId === addressId)!;
		// don't auto-popup new address modal in OrderItemDetailsComponent
		if (!this.allowCopyEdit) {
			const isAddressValid = this.validateSelectedAddress(address);
			if (!isAddressValid) {
				this.handleInvalidAddress(address);
				return;
			}
		}
		this.selectedAddress = address;
		this.addressSelected.emit(addressId);
	}

}
