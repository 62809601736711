import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerPaymentDisplay, CustomerPaymentsQuery, PaginationQuery } from '@taradel/admin-api-client';
import { AuthenticationService } from 'services/authentication.service';
import { PaymentsService } from 'services/payments.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-customer-payments',
  templateUrl: './customer-payments.component.html',
  styleUrls: ['./customer-payments.component.scss']
})
export class CustomerPaymentsComponent implements OnInit {
	loading = false;
	isSearchEnabled = true;
	pageNo = 1;
	pageSize = 20;
	total = 0;
	totalBalance = 0;
	customerId = 0;
	customerPaymentsQuery = new CustomerPaymentsQuery();
	customerPayments: CustomerPaymentDisplay[] = [];

	constructor(
		public authService: AuthenticationService,
		private paymentService: PaymentsService,
		private route: ActivatedRoute,
		private toastService: ToastService
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		this.customerPaymentsQuery.customerId = this.customerId === 0 ? undefined : this.customerId;
		this.customerPaymentsQuery.pagination = new PaginationQuery({
			pageNo: this.pageNo,
			pageSize: this.pageSize
		});
	}

	async ngOnInit() {

		try {
			this.loading = true;
			if (this.customerId > 0) {
				const customerBalance = await this.paymentService.getCustomerBalance(this.customerId);
				this.totalBalance = customerBalance.reduce((total, item) => total + item.balance, 0);
			}

			await this.search();
		}
		catch {
			this.toastService.showError('There was an error loading customer payment detais');
		}
		finally {
			this.loading = false;
		}

	}
	async search() {
		this.loading = true;
		this.pageNo = 1;
		this.customerPaymentsQuery.pagination = new PaginationQuery({
			pageNo: this.pageNo,
			pageSize: this.pageSize
		});

		try {
			this.total = await this.paymentService.getCustomerPaymentsCount(this.customerPaymentsQuery);
			this.customerPayments = await this.paymentService.getCustomerPayments(this.customerPaymentsQuery);

			if (this.customerPayments.length > 0) {
				this.isSearchEnabled = true;
			}
			else {
				this.isSearchEnabled = false;
			}
		}
		catch (err: any) {
			this.toastService.showError(err.toString());
		}
		this.loading = false;
	}


	async pageChanged() {
		try {
			this.loading = true;
			this.customerPaymentsQuery.pagination = new PaginationQuery({
				pageNo: this.pageNo,
				pageSize: this.pageSize
			});
			this.total = await this.paymentService.getCustomerPaymentsCount(this.customerPaymentsQuery);
			this.customerPayments = await this.paymentService.getCustomerPayments(this.customerPaymentsQuery);
		}
		catch {
			this.toastService.showError('Unable to load orders');
		}
		finally {
			this.loading = false;
		}

	}

	parseDate(dateString: string, isFromDate: boolean): void {
		if (isFromDate) {
			this.customerPaymentsQuery.fromDate = new Date(dateString);
		}
		else {
			this.customerPaymentsQuery.toDate = new Date(dateString);
		}
	}
}

