import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProductOption } from '@taradel/web-api-client';

@Component({
  selector: 'app-pcog-options-dropdown',
  templateUrl: './pcog-options-dropdown.component.html',
  styleUrls: ['./pcog-options-dropdown.component.scss']
})
export class PcogOptionsDropdownComponent {
	selectedOption?: ProductOption;

	@Input() productOptions!: ProductOption[];
	@Output() optionSelected = new EventEmitter<ProductOption>();

	handleOptionSelected(value: any) {
		const id = parseInt(value);
		const selectedOption = this.productOptions.find(o => o.optionId === id);
		this.optionSelected.emit(selectedOption);
	}
}
