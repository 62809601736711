<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="card col-lg-6 col-12 p-3 mb-3">
			<div class="card-header">
				<ul class="nav nav-tabs card-header-tabs" role="tabList">
					<li class="nav-item">
						<button class="nav-link active" aria-current="true" data-bs-toggle="tab" data-bs-target="#addProductView">
							Add White Label Product
						</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" aria-current="false" data-bs-toggle="tab" data-bs-target="#bulkAddProductsView" [disabled]="!site">
							Bulk Add WL Products
						</button>
					</li>
				</ul>
			</div>
			<div class="tab-content">
				<div class="card-body tab-pane fade show active" id="addProductView">
					<form [formGroup]="wlProductForm">
						<div class="mb-3 form-floating">
							<select type="select" formControlName="baseProduct" class="form-select">
								<option value="null" disabled></option>
								<option *ngFor="let x of baseProducts" value="{{x.productId}}">
									{{x.productId}} - {{x.name!}}
								</option>
							</select>
							<label for="designTemplate">Base Product</label>
							<div *ngIf="submitted && f.baseProduct.errors" class="input-error">
								<div *ngIf="f.baseProduct.errors.required">Base Product is required</div>
							</div>
						</div>
						<div class="mb-3 form-floating">
							<input type="text" formControlName="name" class="form-control" [maxlength]="100"
								   [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Name">
							<label for="name">Name </label>
							<div *ngIf="submitted && f.name.errors" class="invalid-feedback">
								<div *ngIf="f.name.errors.required">Name is required</div>
							</div>
						</div>

						<hr />

						<div class="form-floating mb-3">
							<select type="select" formControlName="markupType" class="form-select">
								<option value="" disabled>Select a markup type</option>
								<option value="Percent">Percent</option>
								<option value="Dollars">Dollars</option>
							</select>
							<label for="markupType">Markup Type</label>
							<div *ngIf="submitted && f.markupType.errors" class="input-error">
								<div *ngIf="f.markupType.errors.required">Markup Type is required</div>
							</div>
						</div>

						<div class="mb-3">
							<label for="markup" class="form-label">Markup Amount</label>
							<div class="input-group">
								<span class="input-group-text"
									  *ngIf="wlProductForm.get('markupType')?.value === 'Dollars'">$</span>
								<input type="number" formControlName="markup" class="form-control"
									   [ngClass]="{ 'is-invalid': submitted && f.markup.errors }">
								<span class="input-group-text"
									  *ngIf="wlProductForm.get('markupType')?.value === 'Percent'">%</span>
							</div>
							<div *ngIf="submitted && f.markup.errors" class="input-error">
								Enter valid markup
							</div>
						</div>

						<div class="mb-3">
							<label for="designFee" class="form-label">Design Fee</label>
							<div class="input-group">
								<input type="number" formControlName="designFee" class="form-control">
							</div>
						</div>

						<div class="mb-3">
							<label for="creativeSetupFee" class="form-label">Creative Setup Fee</label>
							<div class="input-group">
								<input type="number" formControlName="creativeSetupFee" class="form-control">
							</div>
						</div>

						<div class="mb-3">
							<label for="repOnly" class="form-label">Rep Only Visible</label>
							<div class="input-group">
								<input type="checkbox" formControlName="repOnly" class="form-check-input">
							</div>
						</div>
						<p>
							<button class="btn btn-primary" (click)="addWLProduct(false)">Add</button>
						</p>

					</form>
				</div>
				<div class="card-body tab-pane fade show" id="bulkAddProductsView">
					<app-bulk-add-products-to-site [allProducts]="baseProducts" [site]="site"></app-bulk-add-products-to-site>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
