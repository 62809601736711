import { Component, OnInit } from '@angular/core';
import { SitesService } from 'services/sites.service';
import { Site, SiteUrl } from '@taradel/web-api-client';
import { ToastService } from 'services/toast.service';
import { SiteFilterRequest } from '@taradel/admin-api-client';


@Component({
	selector: 'app-site-main',
	templateUrl: './site-main.component.html',
	styleUrls: ['./site-main.component.scss']
})
export class SiteMainComponent implements OnInit {

	shownSites: Site[] = [];
	permSitesList: Site[] = [];
	hideInactiveSites = true;
	sitesSearch = '';
	loading = false;

	constructor(private sitesService: SitesService, private toastService: ToastService) {
	}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		try {
			this.permSitesList = await this.sitesService.getSitesForAdmin({ showInactive: true, adminCheckout: false } as SiteFilterRequest);
			this.shownSites = this.permSitesList;
		}
		catch (error) {
			this.toastService.showError('There was a problem loading the sites', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	returnURL(urlList: SiteUrl[]): SiteUrl[] {
		return urlList.filter(url => url.active);
	}

	filterSites($event: Event) {
		if (this.sitesSearch.trim() !== '') {
			let tempSites = this.permSitesList.filter(site => site.name?.toLowerCase().includes(this.sitesSearch.toLowerCase()));
			this.permSitesList.filter(site => site.urls!.forEach(siteUrl => {
				if (siteUrl.url?.includes(this.sitesSearch.toLowerCase())) {
					if (!tempSites.includes(site) && siteUrl.active === true) {
						tempSites.push(site);
					}
				}
			}));
			this.shownSites = tempSites;
		}
		else {
			this.shownSites = this.permSitesList;
		}
	}

}
