<app-pagefullheader></app-pagefullheader>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="input-group mb-3">
			<span class="input-group-text">Filter</span>
			<input id="searchSitesBox" class="form-control" (keyup)="filterSites($event)" [(ngModel)]="sitesSearch" placeholder="Filter by Site Name">
			<div class="input-group-text">
				<div class="form-check">
					<input type="checkbox" class="form-check-input" id="toggleActiveSites" name="toggleActiveSites" [(ngModel)]="hideInactiveSites">
					<label for="toggleActiveSites" class="form-check-label">Hide Inactive Sites</label>
				  </div>
			</div>
			<a class="btn btn-success btn-rounded px-4 ms-2 rounded-pill" [routerLink]="['add']">
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
				Add New Site
			</a>
		</div>

		<div class="table-responsive">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Id</th>
						<th>Name</th>
						<th>URLs</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let site of shownSites" [ngClass]="{'table-secondary text-dark': !site.active}">
						<ng-container *ngIf="(site.active && hideInactiveSites) || !hideInactiveSites">
							<td>{{ site.siteId }}</td>
							<td>
								<a [routerLink]="['/sites', site.siteId, 'dashboard']" class="btn btn-link p-0">{{ site.name }}</a>
							</td>
							<td>
								<ul class="list-unstyled">
									<li *ngFor="let url of returnURL(site.urls!)">
										<a target="_blank" href="https://{{ url.url }}">{{ url.url }}</a>
									</li>
								</ul>
							</td>
							<td>
								<app-site-options-dropdown [siteId]="site.siteId"></app-site-options-dropdown>
							</td>
						</ng-container>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</app-spinner-with-overlay>
