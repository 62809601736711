import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product, ProductsSearch, USelectLanguages, USelectMethod } from '@taradel/admin-api-client';
import { ProductsService } from 'services/products.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-uselects',
  templateUrl: './uselects.component.html',
  styleUrls: ['./uselects.component.scss']
})
export class UselectsComponent implements OnInit {
	loading: boolean = false;
	uSelectMethods: USelectMethod[] = [];
	baseProducts: Product[] = [];
	uSelectLanguages: USelectLanguages[] = [];
	countryCode?: 'US' | 'Canada';

	constructor(
		private productService: ProductsService,
		public sitesService: SitesService,
		private toastService: ToastService,
		private route: ActivatedRoute
	) {
		this.route.data.pipe(
			filter((data) => !!data),
			take(1)
		).subscribe(data => this.countryCode = data.countryCode);
	}

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;
			[this.uSelectMethods, this.baseProducts, this.uSelectLanguages] = await Promise.all([
				this.sitesService.getAllUSelect(),
				this.productService.getAllProducts(new ProductsSearch({
					pageNo: 1,
					pageSize: 1000,
					uSelectId: 0,
					name: '',
					productId: 0
				})),
				this.sitesService.getUSelectLanguages()
			]);
		}
		catch {
			this.toastService.showError('There was an error loading the uselects');
		}
		finally {
			this.loading = false;
		}
	}

	getProductName(productId?: number) {
		if (productId) {
			const product = this.baseProducts.find(x => x.productId === productId)!;
			return `${productId} - ${product.name}`;
		}
		else {
			return '';
		}
	}
}
