import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressTypes, CustomerAddress, DefaultAddressQuery } from '@taradel/web-api-client';
import { AsYouType } from 'libphonenumber-js';
import { CustomerAddressService } from 'services/customer-address.service';
import { ToastService } from 'services/toast.service';
import { createEmailValidator } from '@taradel/white-label-common';
import { filter, take } from 'rxjs/operators';
import { zipCodeValidator } from 'app/utils/zip-code-validator';
import { formatCAPostalCode } from 'app/utils/format-ca-postal-code';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';
import { GooglePlaceResult } from 'app/lib/google-maps';

@Component({
	selector: 'app-address-details',
	templateUrl: './address-details.component.html',
	styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent {
	loading = false;
	customerId = 0;
	addressForm: UntypedFormGroup;
	submitted = false;
	state = '';
	countryCode: 'US' | 'Canada' = 'US';
	options!: NgxGpAutocompleteOptions;

	@ViewChild('placesRef') placesRef!: NgxGpAutocompleteDirective;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private customerAddressService: CustomerAddressService,
		private toastService: ToastService
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		this.route.data.pipe(
			filter((data) => !!data),
			take(1)
		).subscribe(data => this.countryCode = data.countryCode);

		this.addressForm = formBuilder.group({
			company: new UntypedFormControl('', Validators.maxLength(100)),
			firstName: new UntypedFormControl(''),
			lastName: new UntypedFormControl(''),
			googleAddress: new UntypedFormControl(''),
			address1: new UntypedFormControl('', Validators.required),
			address2: new UntypedFormControl(''),
			state: new UntypedFormControl('', Validators.required),
			city: new UntypedFormControl('', Validators.required),
			zipCode: new UntypedFormControl('', Validators.compose([
				Validators.required,
				zipCodeValidator()
			])),
			country: new UntypedFormControl(''),
			phoneNumber: new UntypedFormControl(''),
			emailAddress: new UntypedFormControl('', createEmailValidator({'required': false})),
			liftgate: new UntypedFormControl(false),
			setAsDefault: new UntypedFormControl(false)
		});
		if (this.countryCode === 'Canada') {
			this.options = { componentRestrictions: { country: 'ca' }};
		}
	}

	get controls() { return this.addressForm.controls; }

	stateSelected(value: string) {
		this.state = value;
		this.controls.state.setValue(this.state);
	}

	async saveAddress() {
		this.submitted = true;
		const formFieldsValid = this.validateAddressFormFields();
		if (!formFieldsValid) {
			return;
		}
		if (!this.addressForm.valid) {
			return;
		}
		this.loading = true;
		let address = new CustomerAddress();
		address.customerId = this.customerId;
		address.company = this.controls.company.value;
		address.firstName = this.controls.firstName.value;
		address.lastName = this.controls.lastName.value;
		address.address1 = this.controls.address1.value;
		address.address2 = this.controls.address2.value;
		address.city = this.controls.city.value;
		address.state = this.controls.state.value;
		address.zipCode = this.countryCode === 'US' ? this.controls.zipCode.value : formatCAPostalCode(this.controls.zipCode.value);
		address.country = (this.controls.country.value !== '' && !!this.controls.country.value) ? this.controls.country.value : (this.countryCode === 'US' ? 'US' : 'CA');
		address.emailAddress = this.controls.emailAddress.value;
		address.phoneNumber = this.controls.phoneNumber.value;
		address.liftgate = this.controls.liftgate.value;
		try {
			const newAddressId = await this.customerAddressService.addCustomerAddress(address);
			if (newAddressId > 0 && this.controls.setAsDefault.value === true) {
				const query = {
					addressId: newAddressId,
					addressType: AddressTypes.Shipping,
				} as DefaultAddressQuery;

				try {
					await this.customerAddressService.updateCustomerDefaultAddress(query, this.customerId);
				}
				catch (err: any) {
					this.toastService.showError("Something went wrong while setting this address as the customer's default Shipping address");
					console.log(err);
				}
				finally {
					this.loading = false;
				}
			}
			this.toastService.showSuccess('Shipping Address successfully created');
			this.router.navigateByUrl(`customers/${this.customerId}/addresses`);
		}
		catch (err: any) {
			this.toastService.showError('Something went wrong while creating this address');
			console.log(err);
		}
		finally {
			this.loading = false;
		}
	}

	formatPhoneNumber(control: string) {
		const inputValue = this.addressForm.controls[control].value;
		const phoneNumber = new AsYouType('US').input(inputValue.substring(0, 14));
		if (phoneNumber !== inputValue + ')') {
			this.addressForm.controls[control].setValue(phoneNumber);
		}
	}

	handleAddressChange(address: GooglePlaceResult) {
		let tempAddress = '';
		address.address_components?.forEach(x => {
			switch (x.types[0]) {
				case 'street_number': {
					tempAddress = x.long_name;
					break;
				}
				case 'route': {
					tempAddress = tempAddress + " " + x.long_name;
					this.controls.address1.setValue(tempAddress);
					break;
				}
				case 'locality': {
					this.controls.city.setValue(x.long_name);
					break;
				}
				case 'administrative_area_level_1': {
					this.state = x.short_name;
					this.controls.state.setValue(x.short_name);
					break;
				}
				case 'postal_code': {
					this.controls.zipCode.setValue(x.long_name);
					break;
				}
				case 'country': {
					this.controls.country.setValue(x.short_name);
					break;
				}
			}
		});
		this.controls.googleAddress.setValue(tempAddress);
	}

	validateAddressFormFields(): boolean {
		if (this.controls.firstName.value === '' && this.controls.lastName.value === '' && this.controls.company.value === '') {
			this.controls.firstName.setValidators([Validators.required]);
			this.controls.firstName.updateValueAndValidity();
			this.controls.lastName.setValidators([Validators.required]);
			this.controls.lastName.updateValueAndValidity();
			this.controls.company.setValidators([Validators.required, Validators.maxLength(100)]);
			this.controls.company.updateValueAndValidity();
			return false;
		}
		if ((this.controls.firstName.value === '' && this.controls.lastName.value !== '') || (this.controls.firstName.value !== '' && this.controls.lastName.value === '')) {
			this.controls.firstName.setValidators([Validators.required]);
			this.controls.firstName.updateValueAndValidity();
			this.controls.lastName.setValidators([Validators.required]);
			this.controls.lastName.updateValueAndValidity();

			this.controls.company.clearValidators();
			this.controls.company.updateValueAndValidity();
			return false;
		}
		if (this.controls.company.value !== '') {
			this.controls.company.setValidators([Validators.required, Validators.maxLength(100)]);
			this.controls.company.updateValueAndValidity();

			this.controls.firstName.clearValidators();
			this.controls.firstName.updateValueAndValidity();
			this.controls.lastName.clearValidators();
			this.controls.lastName.updateValueAndValidity();
		}
		return true;
	}
}
