<app-customer-header [customerId]="customerId"></app-customer-header>

<app-spinner-with-overlay [showSpinner]="showSpinner">
	<div class="row">
		<!-- left pane -->
		<div class="col-md-3">
			<ng-container *ngIf="(customerId > 0 && !!customer) && (!!siteId && siteId > 0)">
				<app-info-panel [customer]="customer" [site]="site" [distribution]="distribution"></app-info-panel>
			</ng-container>
		</div>

		<div class="col-md-9">
			<div class="row">
				<div class="col">
					<div class="card">
						<div class="card-body">
							<h2 *ngIf="bundle">{{ bundle.name }} ({{ bundle.bundleId }})</h2>
							<!-- bundle creation -->
							<form [formGroup]="bundleForm">
								<div class="form-floating mb-3">
									<select type="select" formControlName="productId" (change)="productSelected()"
									class="form-select" [ngClass]="{ 'is-invalid': submitted && bundleForm.get('productId')?.errors}">
										<option [ngValue]="null" disabled>Select a product</option>
										<option *ngFor="let x of allowedProducts" value="{{x.baseProductId}}">
											{{getProductNameWithOrderDate(x)}}
										</option>
									</select>
									<label for="productId">Choose Product</label>
								</div>
								<div *ngIf="productAdded && getProductOptions().length > 0">
									<div><b>Product Options</b></div>
									<div class="row">
										<div class="col" formGroupName="options" *ngFor="let option of getProductOptions(); let j=index">
											<div class="form-floating mb-3">
												<select type="select" [formControlName]="option" class="form-select" (change)="optionSelected(option)" [ngClass]="{ 'is-invalid': submitted && bundleForm.get('options')?.get(option)?.errors}">
													<option *ngFor="let x of getOptions(option)" [ngValue]="x.option?.optionId">
														{{x.option?.name}}
													</option>
												</select>
												<label>{{option}}</label>
											</div>
										</div>
									</div>
									<div><b>Design Options</b></div>
									<div>
										<div class="form-floating mb-3">
											<select type="select" formControlName="design" (change)="designSelected()" class="form-select" [ngClass]="{ 'is-invalid': submitted && bundleForm.get('design')?.errors }">
												<option value="{{USelectDesignType.Upload}}">Upload</option>
												<option value="{{USelectDesignType.ProfessionalDesign}}">Professional Design</option>
											</select>
											<label for="design">Choose Design Option</label>
										</div>

										<div *ngIf="uploadDesignSelected()">
											<div class="form-group mb-3">
												<label for="front">Front File</label>
												<input type="file" class="form-control" formControlName="front" (change)="handleFrontFileInput($event.target)">
											</div>

											<div class="form-group mb-3">
												<label for="back">Back File</label>
												<input type="file" class="form-control" formControlName="back" (change)="handleBackFileInput($event.target)">
											</div>

											<div class="form-check mb-3">
												<input class="form-check-input" type="checkbox" formControlName="proof">
												<label class="form-check-label" for="proof">Request a PDF proof before
													printing?</label>
											</div>
										</div>
									</div>
									<div class="row" *ngIf="countryCode === 'US' && !showSpinner">
										<div class="col-6">
											<app-customer-addresses [submitted]="submitted" [addressType]="'Return'" [customerId]="customerId" (addressSelected)="selectReturnAddress($event)">
											</app-customer-addresses>
										</div>
									</div>
									<div *ngIf="minDate !== undefined && bundleForm.get('design')?.value">
										<div><b>Delivery Options</b></div>
										<div class="form-floating mb-3">
											<div class="form-inline text-center">
												<div class="input-group">
													<ngb-datepicker #dp formControlName="deliveryWindow" [dayTemplate]="t" required
													[minDate]="minDate" [maxDate]="maxDate" [firstDayOfWeek]="0"
													(dateSelect)="onDateSelection($event)" [markDisabled]="isDisabled" [startDate]="startDate"
													></ngb-datepicker>

													<ng-template #t let-date let-focused="focused" let-disabled="disabled">
														<span
															class="custom-day"
															[class.focused]="focused"
															[class.range]="isRange(date)"
															[class.faded]="isHovered(date) || isInside(date)"
															[class.text-muted]="disabled"
															(mouseenter)="hoveredDate = date"
															(mouseleave)="hoveredDate = undefined"
														>
															{{ date.day }}
														</span>
													</ng-template>
												</div>
											</div>
											<div *ngIf="submitted && bundleForm.get('deliveryWindow')?.errors">
												<p class="input-error">
													Please select delivery date
												</p>
											</div>
										</div>
									</div>

									<div class="row mb-3">
										<div class="col-md-4">
											<div class="form-floating">
												<select type="select" formControlName="impressions" class="form-select" (change)="impressionsChanged()">
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option *ngIf="isUS" value="5">5</option>
													<option *ngIf="isUS" value="6">6</option>
													<option *ngIf="isUS" value="7">7</option>
													<option *ngIf="isUS" value="8">8</option>
													<option *ngIf="isUS" value="9">9</option>
													<option *ngIf="isUS" value="10">10</option>
												</select>
												<label for="impressions">Impressions</label>
											</div>
										</div>
										<div class="col-md-4" *ngIf="f.impressions.value === '1'">
											<div class="form-floating">
												<select type="select" formControlName="drops" class="form-select" (change)="dropsChanged()">
													<option *ngFor="let item of [].constructor(numberOfDrops); let i = index" value="{{i+1}}">
														{{i+1}}
													</option>
												</select>
												<label for="drops">Drops</label>
											</div>
											If a customer needs more than 10 drops, an additional order will need to be placed. This is a limitation of our print vendors.
										</div>
										<div class="col-md-4" *ngIf="bundleForm.get('impressions')?.value !== '1' || bundleForm.get('drops')?.value !== '1'">
											<div class="form-floating">
												<select type="select" formControlName="frequency" class="form-select"
												[ngClass]="{ 'is-invalid': submitted && bundleForm.get('frequency')?.errors }">
													<option value="1">1 Week</option>
													<option value="2">2 Weeks</option>
													<option value="3">3 Weeks</option>
													<option value="4">4 Weeks</option>
												</select>
												<label for="frequency">Frequency</label>
											</div>
										</div>
									</div>
									<div>
										<app-extra-copies-customization [countryCode]="countryCode" [siteId]="siteId" [baseProductId]="baseProductId"
										[messaging]="getLastOrderedExtraCopies()"
                                        [cartData]="cart?.cartData"
										[siteProducts]="siteProducts" [submitted]="submitted" (extraCopiesSelected)="extraCopiesSelected($event)">
										</app-extra-copies-customization>
										<ng-container *ngIf="(bundleForm.get('extraCopiesQuantity')?.value !== '' && bundleForm.get('extraCopiesQuantity')?.value !== 0)">
											<div class="row mb-3">
												<div class="col-6">
													<app-customer-addresses [submitted]="submitted" [addressType]="'Shipping'" [customerId]="customerId" (addressSelected)="selectShippingAddress($event)">
													</app-customer-addresses>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
								<div *ngIf="digitalProducts.length > 0">
									<div><b>Delivery for digital products</b></div>
									<div class="row mb-3" *ngFor="let x of digitalProducts">
										<div class="col-md-3">
											<div class="form-floating">
												<input type="date" [formControlName]="x.productId!.toString() + 'startDate'" [min]="today"
												(change)="startDateSelected(x)" class="form-control" max="9999-12-31"
												[ngClass]="{ 'is-invalid': submitted && bundleForm.get(x.productId!.toString())?.errors }">
												<label for="startDate">Start Date for {{x.name}}</label>
											</div>
										</div>
										<div class="col-md-3" *ngIf="!x.required">
											<div class="form-check mt-3">
												<input type="checkbox" [formControlName]="x.productId!.toString() + 'excluded'" 
												(change)="excludeDigital(x)" class="form-check-input"
												[ngClass]="{ 'is-invalid': submitted && bundleForm.get(x.productId!.toString())?.errors }">
												<label [for]="x.productId!.toString() + 'excluded'" class="form-check-label">Exclude from bundle</label>
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="productAdded && callTrackingBundleItem">
									<div class="mb-3">
										<b>Configure Call Tracking</b>&nbsp;
										<span *ngIf="!callTrackingBundleItem.required">
											<input type="checkbox" formControlName="excludedCallTracking" 
											(change)="excludeCallTracking()" class="form-check-input">
											<label for="excludedCallTracking" class="form-check-label">Exclude from bundle</label>
										</span>
									</div>
									<div class="form-floating mb-3">
										<select type="select" formControlName="callTrackingType" (change)="trackingTypeSelected()" class="form-select" [ngClass]="{ 'is-invalid': submitted && bundleForm.get('callTrackingType')?.errors }">
											<option [value]="89">Local</option>
											<option [value]="90">Toll Free</option>
										</select>
										<label for="design">Choose Call Tracking Type</label>
									</div>
									<div class="row" *ngIf="bundleForm.get('callTrackingType')?.value === '89'">
										<div class="col-6">
											<div class="form-floating mb-3">
												<input type="number" class="form-control" formControlName="areaCode" onKeyPress="if(this.value.length==3) return false;" [ngClass]="{ 'is-invalid': submitted && bundleForm.get('areaCode')?.errors }" min="201" max="999" />
												<label>Area Code</label>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-6">
											<div class="form-floating mb-3">
												<input required type="text" class="form-control" formControlName="forwardingNumber" [minlength]="14" [maxlength]="14" (keyup)="formatPhoneNumber('forwardingNumber')" [ngClass]="{ 'is-invalid': submitted && bundleForm.get('forwardingNumber')?.errors }" />
												<label>Call Forwarding Number</label>
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="productAdded && !!qrCodeBundleItem">
									<div class="mb-3">
										<b>Configure QR Code</b>&nbsp;
										<span *ngIf="!qrCodeBundleItem.required">
											<input type="checkbox" formControlName="excludedQrCode" 
											(change)="excludeQrCode()" class="form-check-input">
											<label for="excludedQrCode" class="form-check-label">Exclude from bundle</label>
										</span>
									</div>
									<div *ngIf="qrCodeBundleItem.productConfiguration![0] === 363" class="row">
										<div class="col-6">
											<div class="form-floating mb-3">
												<input required type="text" class="form-control" formControlName="webLink" pattern="(http(s)?:\/\/)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$" [ngClass]="{ 'is-invalid': submitted && bundleForm.get('webLink')?.errors }" />
												<label>Web Link</label>
											</div>
										</div>
									</div>
									<div *ngIf="qrCodeBundleItem.productConfiguration![0] === 364" class="row">
										<div class="col-6">
											<div class="form-floating mb-3">
												<input required type="text" class="form-control" formControlName="phoneNumber" [minlength]="14" [maxlength]="14" (keyup)="formatPhoneNumber('phoneNumber')" [ngClass]="{ 'is-invalid': submitted && bundleForm.get('phoneNumber')?.errors }" />
												<label>Phone Number</label>
											</div>
										</div>
									</div>
								</div>
								<div class="row mt-2 mb-2">
									<div class="col">
										<div class="hstack">
											<button class="btn btn-primary btn-lg" (click)="buildCart()">Save</button>
											<button class="btn btn-danger ms-auto" (click)="cancelCustomization()">Cancel</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
