<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col-md-7">
			<form [formGroup]="customerForm">
				<div class="card mb-3">
					<div class="card-body">
						<h5 class="card-title">Basic Information</h5>
						<div class="row">
							<div class="col-md-6 mb-3">
								<div class="form-floating">
									<app-sites-list [startingSiteId]="siteId" [showError]="siteId === -1 && submitted" (siteSelected)="siteChanged($event.siteId)" [sites]="sites"></app-sites-list>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-floating mb-3">
									<input type="text" formControlName="companyName" class="form-control"
										[ngClass]="{ 'is-invalid': f.companyName.errors && f.companyName.touched }"
										placeholder="Company Name">
									<label for="companyName">Company Name</label>
									<div *ngIf="f.companyName.errors && f.companyName.touched" class="invalid-feedback">
										<div *ngIf="f.companyName.errors.required">Company Name is required</div>
										<div *ngIf="f.companyName.errors.maxlength">Company Name must be less than 100 characters long</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6 mb-3">
								<div class="form-floating">
									<input type="text" formControlName="firstName" class="form-control"
										[ngClass]="{ 'is-invalid': f.firstName.errors && f.firstName.touched }">
									<label for="firstName">First Name</label>
									<div *ngIf="f.firstName.errors && f.firstName.touched" class="invalid-feedback">
										<div *ngIf="f.firstName.errors.required">First Name is required</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-floating mb-3">
									<input type="text" formControlName="lastName" class="form-control"
										[ngClass]="{ 'is-invalid': f.lastName.errors && f.lastName.touched }" placeholder="Last Name">
									<label for="lastName">Last Name</label>
									<div *ngIf="f.lastName.errors && f.lastName.touched" class="invalid-feedback">
										<div *ngIf="f.lastName.errors.required">Last Name is required</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<div class="mb-3">
									<app-phone-input formControlName="mobile"
										label="Mobile Number"
										[errorMessages]="{'required': 'Enter valid mobile number'}"
										[ngClass]="{ 'is-invalid': f.mobile.errors && f.mobile.touched }" [required]="true"
										[showErrors]="true" label="Mobile Number"
										placeholder="Mobile Number">
									</app-phone-input>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-floating mb-3">
									<app-email-input
										[ngClass]="{ 'is-invalid': f.emailAddress.errors && f.emailAddress.touched }"
										[showErrors]="true"
										[required]="true"
										[errorMessages]="{'required': 'Email is required', 'format': 'Enter valid email address'}"
										formControlName="emailAddress"
										placeholder="Email Address"></app-email-input>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-floating mb-3">
									<input type="password" formControlName="password" class="form-control"
										[ngClass]="{ 'is-invalid': f.password.errors && f.password.touched }" placeholder="Password">
									<label for="password">Password</label>
									<div *ngIf="f.password.errors && f.password.touched" class="invalid-feedback">
									<div *ngIf="f.password.errors.pattern">Must be at least 8 characters
										with one uppercase, one number, and one special character.</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-floating mb-3">
									<input type="password" formControlName="confirmPassword" class="form-control"
										[ngClass]="{ 'is-invalid': customerForm.errors && customerForm.errors.passwordMisMatch && f.password.touched }" placeholder="Confirm Password">
									<label for="confirmPassword">Confirm Password</label>
									<div *ngIf="customerForm.errors && f.password.touched" class="invalid-feedback">
										<div *ngIf="customerForm.errors.passwordMisMatch">
											<div>Confirm Password does not match</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="d-flex justify-content-between">
							<button class="btn btn-primary" [disabled]="customerForm.invalid"
								(click)="saveCustomer()">Create Customer</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</app-spinner-with-overlay>
