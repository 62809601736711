import { Component } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { EquifaxSelectionService } from 'services/equifax-selection.service';

@Component({
	selector: 'app-industry',
	templateUrl: './industry.component.html',
	styleUrls: ['./industry.component.scss']
})

export class IndustryComponent {

	yearEstablishedVal: number = 1900;
	yearEstablishedMin: number = 1900;
	yearEstablishedMax: number = 2020;
	yearEstablishedOptions: Options = {
		floor: 1900,
		ceil: 2020
	};

	constructor(public equifaxSelectionService: EquifaxSelectionService) { }
}
