<app-spinner-with-overlay [showSpinner]="loading">
	<ng-container *ngIf="!loading">
		<div class="row">
			<div class="col-md-10 col-12">
				<div class="shadow rounded p-4">
					<form [formGroup]="bundleItemForm">
						<div class="row mb-5">
							<div class="col-md-6 col-12">
								<div class="form-floating mb-3">
									<input type="text" class="form-control" formControlName="quantity" placeholder="Quantity" />
									<label>Quantity</label>
								</div>
								<div class="form-floating mb-3">
									<input type="text" class="form-control" formControlName="dailySpend" placeholder="Daily Spend" />
									<label>Daily Spend</label>
								</div>
								<div class="form-floating mb-3">
									<input type="text" class="form-control" formControlName="minimumCost" placeholder="Minimum Cost" />
									<label>Minimum Cost</label>
								</div>
								<div class="form-floating mb-3">
									<select class="form-select form-control" formControlName="discountType">
										<option [value]="undefined" disabled>Set Discount Type</option>
										<option [value]="BundleDiscountType[BundleDiscountType.Dollar]">Dollar</option>
										<option [value]="BundleDiscountType[BundleDiscountType.Percent]">Percent</option>
									</select>
								</div>
								<div class="form-floating mb-3">
									<input type="text" class="form-control" formControlName="discountAmount" placeholder="Discount Amount" />
									<label>Discount Amount</label>
								</div>
								<div class="form-check">
									<input type="checkbox" formControlName="required" class="form-check-input" />
									<label for="required" class="form-check-label">Required</label>
								</div>
							</div>
							<div class="col-md-6 col-12">
								<div class="row mb-3">
									<div class="col">
										<strong>Products:</strong>

										<ng-container *ngIf="controls.products.value && controls.products.value.length > 0">
											<ul>
												<li *ngFor="let product of controls.products.value">
													<span>{{ product.name }}</span>
													<button type="button" class="close" aria-label="Close" (click)="removeProduct(product.productId)">
														<span aria-hidden="true">&times;</span>
													</button>
												</li>
											</ul>
										</ng-container>
										<div *ngIf="!!products" class="row mt-3">
											<div class="col">
												<app-bulk-add-products [allProducts]="products" (productsSelected)="bulkAddProducts($event)"></app-bulk-add-products>
											</div>
										</div>
										<ng-container *ngIf="filteredProducts">
											<ng-template #rt let-r="result" let-t="term">
												({{ r.productId}}) {{ r.name }}
											</ng-template>
											<div class="row">
												<div class="col">
													<div class="form-floating">
														<input id="productSearch" type="text" class="form-control product-box" [ngbTypeahead]="searchProducts" (focus)="focus$.next($any($event).target.value); focused = true;"
															(click)="click$.next($any($event).target.value)" #instance="ngbTypeahead" (blur)="focused = false;" [resultTemplate]="rt" [inputFormatter]="productsFormatter" (selectItem)="getProduct($event)"
															/>
															<label>Select Products</label>
													</div>
											</div>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
					</form>
					<div class="row mt-3 d-flex justify-content-center">
						<div class="col-auto mx-auto">
							<button class="btn btn-link text-danger" (click)="emitGoBack()">Cancel</button>
						</div>
						<div class="col-auto mx-auto">
							<button class="btn btn-success" [disabled]="!bundleItemForm.valid" (click)="emitBundleItemUpdated()">Save Changes To This Item</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</app-spinner-with-overlay>
