import { Component } from '@angular/core';
import { OrderlayoutService } from 'services/orderlayout.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrl: './order-history.component.scss'
})
export class OrderHistoryComponent {
    constructor(
        public orderLayoutService: OrderlayoutService
    ) { }

}
