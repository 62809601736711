import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiException, USelectMethod, ProductPowerUp } from '@taradel/admin-api-client';
import { UselectSearchComponent } from 'components/site/site-uselect-addons/uselect-search/uselect-search.component';
import { ProductsService } from 'services/products.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-product-powerups',
  templateUrl: './product-powerups.component.html',
  styleUrls: ['./product-powerups.component.scss']
})
export class ProductPowerupsComponent implements OnInit {

	loading = true;
	productUSelectId = 0;
	uSelectMethods: USelectMethod[] = [];
	productPowerUps: ProductPowerUp[] = [];
	newPowerUp?: ProductPowerUp;
	@ViewChild('uSelectMethodListInput') uselectSearchComponent!: UselectSearchComponent;

	constructor(
		private productService: ProductsService,
		private route: ActivatedRoute,
		private sitesService: SitesService,
		private toastService: ToastService
	) {
		this.productUSelectId = parseInt(route.snapshot.paramMap.get('uselectId') ?? '0', 10);
	}

	async ngOnInit() {
		try {
			this.uSelectMethods = await this.sitesService.getAllUSelect();
			this.productPowerUps = await this.productService.getProductPowerUps(this.productUSelectId);
		}
		catch (error) {
			this.toastService.showError('There was an error getting the uselects');
		}
		finally {
			this.loading = false;
		}
		this.initializeNewPowerUp();
	}

	initializeNewPowerUp() {
		this.newPowerUp = new ProductPowerUp();
		this.newPowerUp.productUSelectId = this.productUSelectId;
		if (this.uselectSearchComponent) {
			this.uselectSearchComponent.uSelect = undefined;
		}
	}

	getUSelectMethod(uSelectId: number) {
		return this.uSelectMethods.find(t => t.uSelectId === uSelectId);
	}

	getUSelectName(uSelectId?: number) {
		const temp = this.uSelectMethods.find(t => t.uSelectId === uSelectId);
		return temp?.name;
	}

	async updateProductPowerUps() {
		try {
			this.loading = true;
			await this.productService.updateProductPowerUps(this.productUSelectId, this.productPowerUps);
			this.initializeNewPowerUp();
			this.toastService.showSuccess('Product Power ups successfully saved');
		}
		catch {
			this.toastService.showError('There was an error saving products powerups');
		}
		finally {
			this.loading = false;
		}
	}

	addNewPowerUp() {
		this.productPowerUps.push(this.newPowerUp!);
		this.initializeNewPowerUp();
	}

	removeSelectedPowerUps(index: number) {
		this.productPowerUps.splice(index, 1);
	}

	async deleteProductPowerUps() {
		this.loading = true;
		try {
			await this.productService.updateProductPowerUps(this.productUSelectId, []);
			this.productPowerUps = [];
			this.toastService.showSuccess('Product Power ups deleted successfully');
		}
		catch (error: any) {
			if (ApiException.isApiException(error)) {
				this.toastService.showError(error.message);
			}
		}
		finally {
			this.loading = false;
		}
	}

	getUpdateMethod(item: { id: number, name: string }, i: number) {
		const matchingUSelect = this.productPowerUps.find(x => x.powerupUSelectId === item.id);
		if (matchingUSelect) {
			this.productPowerUps[i].powerupUSelectId = 0;
			this.toastService.showError('Product Powerup has already has been added');
			return;
		}
		this.productPowerUps[i].powerupUSelectId = item.id;
	}


	getMethod(item: { id: number, name: string }) {
		const matchingUSelect = this.productPowerUps.find(x => x.powerupUSelectId === item.id);
		if (matchingUSelect) {
			this.toastService.showError('uSelect has already has been added');
			return;
		}
		this.newPowerUp!.productUSelectId = this.productUSelectId;
		this.newPowerUp!.powerupUSelectId = item.id;
	}

}
