import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressSearchQuery, CustomerAddress, CustomerAddressClient, DefaultAddressQuery } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CustomerAddressService {
	private readonly customerAddressClient: CustomerAddressClient;
	public readonly countryCode: 'US' | 'Canada' = 'US';

	constructor(http: HttpClient) {
		this.customerAddressClient = new CustomerAddressClient(http, environment.adminApiUrl);
		this.countryCode = environment.instance;
	}

	async getCustomerAddresses(customerId: number): Promise<CustomerAddress[]> {
		return await lastValueFrom(this.customerAddressClient.getCustomerAddresses(customerId));
	}

	async getCustomerAddress(customerId: number, addressId: number): Promise<CustomerAddress> {
		return await lastValueFrom(this.customerAddressClient.getCustomerAddress(customerId, addressId));
	}

	async addCustomerAddress(address: CustomerAddress): Promise<number> {
		return await lastValueFrom(this.customerAddressClient.addCustomerAddress(address));
	}

	async updateDefaultAddress(customerId: number, query: DefaultAddressQuery): Promise<number> {
		return await lastValueFrom(this.customerAddressClient.setCustomerDefaultAddress(query, customerId));
	}

	async searchCustomerAddresses(query: AddressSearchQuery): Promise<CustomerAddress[]> {
		return await lastValueFrom(this.customerAddressClient.searchCustomerAddresses(query));
	}

	async getCustomerAddressCount(query: AddressSearchQuery): Promise<number> {
		return await lastValueFrom(this.customerAddressClient.searchCustomerAddressesCount(query));
	}

	async deleteCustomerAddress(addressId: number, customerId: number): Promise<number> {
		return await lastValueFrom(this.customerAddressClient.deleteCustomerAddress(addressId, customerId));
	}

	async updateCustomerDefaultAddress(query: DefaultAddressQuery, customerId: number): Promise<number> {
		return await lastValueFrom(this.customerAddressClient.setCustomerDefaultAddress(query, customerId));
	}
}
