<app-printer-info [printerId]="printerId"></app-printer-info>

<app-spinner-with-overlay [showSpinner]="loading">
    <div class="row">
        <div class="col-12">
            <div class="card p-3 mb-3">
                <form [formGroup]="printerToUpdateForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-header">
                                    <strong>Printer Information</strong>
                                </div>
                                <div class="card-body">
									<div class="form-floating mb-3">
										<select type="select" formControlName="parentPrinterId" class="form-select"
											placeholder="Parent Printer Id" >
											<option [ngValue]="null">Set parent printer</option>
											<option *ngFor="let x of printers" value="{{x.printerId}}">
												{{x.companyName}} ({{x.printerId}})
											</option>
										</select>
                                        <label for="parentPrinterIdInput">Parent Printer Id</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="companyName" class="form-control" id="companyNameInput" aria-describedby="companyNameHelp"
                                        placeholder="Company Name" [ngClass]="{ 'is-invalid': submitted && controls.companyName.errors }" />
                                        <label for="companyNameInput">Company Name</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="address1" class="form-control" id="address1Input" aria-describedby="address1Help"
                                        placeholder="Address 1" />
                                        <label for="address1Input">Address 1</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="address2" class="form-control" id="address2Input" aria-describedby="address2Help"
                                        placeholder="Address 2" />
                                        <label for="address2Input">Address 2</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="city" class="form-control" id="cityInput" aria-describedby="cityHelp"
                                        placeholder="City" />
                                        <label for="cityInput">City</label>
                                    </div>
									<app-state-list [state]="printerState" [isUS]="countryCode === 'US'" (selected)="printerStateSelected($event)"></app-state-list>
                                    <div class="form-floating my-3">
                                        <input type="text" formControlName="zipCode" class="form-control" id="zipCodeInput" aria-describedby="zipCodeHelp"
                                        placeholder="Zip Code" />
                                        <label for="zipCodeInput">Zip Code</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="primaryContact" class="form-control" id="primaryContactInput" aria-describedby="primaryContactHelp"
                                        placeholder="Primary Contact" />
                                        <label for="primaryContactInput">Primary Contact</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="tel" formControlName="primaryPhone" class="form-control" id="primaryPhoneInput" aria-describedby="primaryPhoneHelp"
                                        placeholder="Primary Phone Number" />
                                        <label for="primaryPhoneInput">Primary Phone Number</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="tel" formControlName="primaryFax" class="form-control" id="primaryFaxInput" aria-describedby="primaryFaxHelp"
                                        placeholder="Primary Fax Number" />
                                        <label for="primaryFaxInput">Primary Fax Number</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="primaryEmail" class="form-control" id="primaryEmailInput" aria-describedby="primaryEmailHelp"
                                        placeholder="Primary Email Address" />
                                        <label for="primaryEmailInput">Primary Email Address</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="secondaryEmail" class="form-control" id="secondaryEmailInput" aria-describedby="secondaryEmailHelp"
                                        placeholder="Secondary Email Address" />
                                        <label for="secondaryEmailInput">Secondary Email Address</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="tertiaryEmail" class="form-control" id="tertiaryEmailInput" aria-describedby="tertiaryEmailHelp"
                                        placeholder="Tertiary Email Address" />
                                        <label for="tertiaryEmailInput">Tertiary Email Address</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="postOfficeName" class="form-control" id="postOfficeNameInput" aria-describedby="postOfficeNameHelp"
                                        placeholder="Post Office Name" />
                                        <label for="postOfficeNameInput">Post Office Name</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="postOfficeCRID" class="form-control" id="postOfficeCRIDInput" aria-describedby="postOfficeCRIDHelp"
                                        placeholder="Post Office CRID" />
                                        <label for="postOfficeCRIDInput">Post Office CRID</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="permitNumber" class="form-control" id="permitNumberInput" aria-describedby="permitNumberHelp"
                                        placeholder="Permit Number" />
                                        <label for="permitNumberInput">Permit Number</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" formControlName="mailerId" class="form-control" id="mailerIdInput" aria-describedby="mailerIdHelp" placeholder="Mailer ID (MID)" />
                                        <label for="mailerIdInput">Mailer ID (MID)</label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" formControlName="enabled" id="printerEnabled" />
                                        <label for="printerEnabled">&nbsp;&nbsp;Enabled</label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" formControlName="isTaradel" id="printerIsTaradel" />
                                        <label for="printerIsTaradel">&nbsp;&nbsp;Is Taradel Printer</label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" formControlName="bundleExtraCopies" id="bundleExtraCopies" />
                                        <label for="bundleExtraCopies">&nbsp;&nbsp;Bundle Extra Copies</label>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" formControlName="bundlePostage" id="bundlePostage" />
                                        <label for="bundlePostage">&nbsp;&nbsp;Bundle Postage</label>
                                    </div>
									<div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" formControlName="excludeNonProfit" id="excludeNonProfit" />
                                        <label for="excludeNonProfit">&nbsp;&nbsp;Exclude Non Profit</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-header">
                                            <strong>Permit Holder Information</strong>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-floating mb-3">
                                                <input type="text" formControlName="permitHolderCompanyName" class="form-control" id="permitHolderCompanyNameInput" aria-describedby="permitHolderCompanyNameHelp"
                                                placeholder="Company Name" />
                                                <label for="permitCompanyNameInput">Company Name</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" formControlName="permitHolderAddress1" class="form-control" id="permitHolderAddress1Input" aria-describedby="permitHolderAddress1Help"
                                                placeholder="Address 1" />
                                                <label for="permitHolderAddress1Input">Address 1</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" formControlName="permitHolderAddress2" class="form-control" id="permitHolderAddress2Input" aria-describedby="permitHolderAddress2Help"
                                                placeholder="Address 2" />
                                                <label for="permitHolderAddress2Input">Address 2</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" formControlName="permitHolderCity" class="form-control" id="permitHolderCityInput" aria-describedby="permitHolderCityHelp"
                                                placeholder="City" />
                                                <label for="permitHolderCityInput">City</label>
                                            </div>
											<app-state-list [state]="permitHolderState" [isUS]="countryCode === 'US'" (selected)="permitHolderprinterStateSelected($event)">
											</app-state-list>
                                            <div class="form-floating my-3">
                                                <input type="text" formControlName="permitHolderZipCode" class="form-control" id="permitHolderZipCodeInput" aria-describedby="permitHolderZipCodeHelp"
                                                placeholder="Zip Code" />
                                                <label for="permitHolderZipCodeInput">Zip Code</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="tel" formControlName="permitHolderPhoneNumber" class="form-control" id="permitHolderPhoneNumberInput" aria-describedby="permitHolderPhoneNumberHelp"
                                                placeholder="Phone Number" />
                                                <label for="permitHolderPhoneNumberInput">Phone Number</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" formControlName="permitHolderCapsNumber" class="form-control" id="permitHolderCapsNumberInput" aria-describedby="permitHolderCapsNumberHelp"
                                                placeholder="Caps Number" />
                                                <label for="permitHolderCapsNumberInput">Caps Number</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" formControlName="permitHolderCRID" class="form-control" id="permitHolderCRIDInput" aria-describedby="permitHolderCRIDHelp"
                                                placeholder="CRID" />
                                                <label for="permitHolderCRIDInput">CRID</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <div class="card">
                                        <div class="card-header">
                                            <strong>FTP Information</strong>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-floating mb-3">
                                                <input type="text" formControlName="fTPServer" class="form-control" id="fTPServerInput" aria-describedby="fTPServerHelp"
                                                placeholder="FTP Server" />
                                                <label for="fTPServerInput">FTP Server</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" formControlName="fTPUsername" class="form-control" id="fTPUsernameInput" aria-describedby="fTPUsernameHelp"
                                                placeholder="FTP Username" />
                                                <label for="fTPUsernameInput">FTP Username</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" formControlName="fTPPassword" class="form-control" id="fTPPasswordInput" aria-describedby="fTPPasswordHelp"
                                                placeholder="FTP Password" />
                                                <label for="fTPPasswordInput">FTP Password</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" formControlName="fTPDirectory" class="form-control" id="fTPDirectoryInput" aria-describedby="fTPDirectoryHelp"
                                                placeholder="FTP Connect Directory" />
                                                <label for="fTPDirectoryInput">FTP Connect Directory</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <button class="btn btn-primary" (click)="update()">Update</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-3">

        </div>
    </div>

</app-spinner-with-overlay>
