import { Component } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { EquifaxSelectionService } from 'services/equifax-selection.service';

@Component({
	selector: 'app-business',
	templateUrl: './business.component.html',
	styleUrls: ['./business.component.scss']
})
export class BusinessComponent {

	valueMap = new Map<number, string>([
		[1, '1-4'],
		[5, '5-9'],
		[10, '10-19'],
		[20, '20-49'],
		[50, '50-99'],
		[100, '100-249'],
		[250, '250-499'],
		[500, '500-999'],
		[1000, '1000-4999'],
		[5000, '5000-9999'],
		[10000, '10000+']
	]);
	totalEmployeesVal: number = 1;
	totalEmployeesMin: number = 1;
	totalEmployeesMax: number = 10000;
	totalEmployeesOptions: Options = {
		floor: 1,
		ceil: 10000,
		stepsArray: [
			{ value: 1 },
			{ value: 5 },
			{ value: 10 },
			{ value: 20 },
			{ value: 50 },
			{ value: 100 },
			{ value: 250 },
			{ value: 500 },
			{ value: 1000 },
			{ value: 5000 },
			{ value: 10000 }
		],
		translate: (value: number): string => {
			return this.valueMap.get(value)!!;
		}
	};

	locationEmployeesVal: number = 1;
	locationEmployeesMin: number = 1;
	locationEmployeesMax: number = 10000;
	locationEmployeesOptions: Options = {
		floor: 1,
		ceil: 10000,
		stepsArray: [
			{ value: 1 },
			{ value: 5 },
			{ value: 10 },
			{ value: 20 },
			{ value: 50 },
			{ value: 100 },
			{ value: 250 },
			{ value: 500 },
			{ value: 1000 },
			{ value: 5000 },
			{ value: 10000 }
		],
		translate: (value: number): string => {
			return this.valueMap.get(value)!!;
		}
	};

	constructor(public equifaxSelectionService: EquifaxSelectionService) { }
}
