<app-customer-header [customerId]="customerId" *ngIf="customerId > 0"></app-customer-header>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col-md-7">
			<div class="row">
				<div class="col-6">
					<div class="card mb-3">
						<div class="card-body">
							<h5 class="card-title">Email / Username</h5>
							<div class="row">
								<div class="col">
									<div class="input-group">
										<input type="text" id="customerUsername" [ngModel]="customer?.username" [ngModelOptions]="{standalone: true}" class="form-control" disabled />
									</div>
								</div>
							</div>
							<div class="row mt-2">
								<div class="col-md-12">
									<strong>
										<label>Created Date: {{customer?.createdDate | date}}</label>
									</strong>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-6">
					<div class="card mb-3">
						<div class="card-body">
							<h5 class="card-title">Login Status: <span [ngClass]="{
								'positive' : isLoginValid,
								'negative' : !isLoginValid }">{{loginStatusMessage}}</span></h5>
							<div class="row">
								<div class="col">
									<div class="row">
										<div class="col">
											<button class="btn btn-outline-primary" [disabled]="!isLoginValid" (click)="sendResetPasswordEmail()">Send Reset Password Email</button>
										</div>
									</div>
								</div>
							</div>
							<div class="row mt-2">
								<div class="col-md-12">
										<label></label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<form [formGroup]="customerForm">
				<div class="card mb-3">
					<div class="card-body">
						<h5 class="card-title">Basic Information</h5>
						<div class="row">
							<div class="col-md-6 mb-3">
								<div class="form-floating">
									<input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': f.firstName.errors }">
									<label for="firstName">First Name</label>
									<div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
										<div *ngIf="f.firstName.errors.required">First Name is required</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-floating mb-3">
									<input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': f.lastName.errors }" placeholder="Last Name">
									<label for="lastName">Last Name</label>
									<div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
										<div *ngIf="f.lastName.errors.required">Last Name is required</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-floating mb-3">
									<input type="text" formControlName="companyName" class="form-control" [ngClass]="{ 'is-invalid': f.companyName.errors }" placeholder="Company Name">
									<label for="companyName">Company Name</label>
									<div *ngIf="f.companyName.errors" class="invalid-feedback">
										Company must be less than 100 characters long
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-12">
								<h5 class="card-title">Contact Information</h5>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6">
								<div class="mb-3">
									<app-phone-input formControlName="mobile" [errorMessages]="{'size': 'Enter valid mobile number'}" [ngClass]="{ 'is-invalid': f.mobile.errors }" [showErrors]="true" label="Mobile Number" placeholder="Mobile Number">
									</app-phone-input>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-8">
										<div class="mb-3">
											<app-phone-input formControlName="phone" [errorMessages]="{'size': 'Enter valid phone number'}" [showErrors]="true" [required]="true" [ngClass]="{ 'is-invalid': f.phone.errors }" placeholder="'Phone Number'" label="Phone Number">
											</app-phone-input>
										</div>
									</div>
									<div class="col-4">
										<div class="form-floating mb-3">
											<input type="text" formControlName="ext" class="form-control" placeholder="Extension">
											<label for="ext">Extension</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="row">
									<div class="col-8">
										<div class="mb-3">
											<app-phone-input formControlName="companyPhone" [ngClass]="{ 'is-invalid': f.companyPhone.errors }" label="Business Phone" placeholder="Business Phone" [errorMessages]="{'size': 'Enter valid business phone number'}" [showErrors]="true">
											</app-phone-input>
										</div>
									</div>
									<div class="col-4">
										<div class="form-floating mb-3">
											<input type="text" formControlName="companyExt" class="form-control" placeholder="Extension">
											<label for="companyExt">Extension</label>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="mb-3">
									<app-phone-input formControlName="fax" [ngClass]="{ 'is-invalid': customerForm.controls.fax.errors }" label="Fax Number" placeholder="Fax Number" [errorMessages]="{'size': 'Enter valid fax number'}" [showErrors]="true">
									</app-phone-input>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="d-flex justify-content-between">
							<button class="btn btn-primary" [disabled]="customerForm.invalid" (click)="saveCustomer()">Save Basic Information</button>
						</div>
					</div>
				</div>
			</form>

            <div class="row">
                <div class="col-6">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h5 class="card-title">Primary Site</h5>
                            <div class="row">
                                <div class="col-12">
                                    <app-sites-list [startingSiteId]="primarySiteId" (siteSelected)="siteChanged($event.siteId)" [sites]="primarySites"></app-sites-list>
                                </div>
                            </div>
                            <div class="row" *ngIf="authService.organizationId === 1">
                                <div class="col-12">
                                    <button class="btn btn-outline-primary col-3" (click)="savePrimarySite()">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card mb-3">
                        <div class="card-body">                            
                            <div class="row">
                                <div class="col-12">
                                    <div class="input-group">
                                        <span class="input-group-text">
                                            <span>Organization Name:</span>
                                        </span>
										<input type="text" id="customerOrganization" [ngModel]="organizationName" class="form-control" disabled />
									</div>
                                    <div class="mt-2" *ngIf="otherOrgCustomers().length > 0">
                                        <h6>Customers with same username from other organizations</h6>
                                        <table class="table table-hover table-panel">
                                            <thead>
                                                <tr>
                                                    <th>CustomerId</th>
                                                    <th>Organization</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let x of otherOrgCustomers()">
                                                    <td><a [routerLink]="['/customers/', x.customerId]" class="btn btn-link">{{ x.customerId }}</a></td>
                                                    <td>{{ getOrgName(x.organizationId) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>

		<div class="col-md-5">
			<form [formGroup]="customerPropertiesForm">
				<div class="card mb-3">
					<div class="card-body">
						<h5 class="card-title">Properties</h5>
						<div class="form-floating mb-3">
							<select class="form-select" name="language" formControlName="language">
								<option *ngFor="let cmsLanguage of cmsLanguages" [value]="cmsLanguage.language">{{cmsLanguage.description}}</option>
							</select>
							<label for="language">Language</label>
						</div>
						<div class="form-floating mb-3">
							<input type="text" formControlName="agentcode" class="form-control">
							<label for="agentcode">Agent Code</label>
						</div>
						<div *ngIf="isCanada" class="form-floating mb-3">
							<input type="text" formControlName="agreement_number" [maxlength]="10" pattern="^\d{10}$"
							[ngClass]="{ 'is-invalid': cp.agreement_number.errors }" class="form-control">
							<label for="agreementNumber">Agreement Number</label>
						</div>
						<div *ngIf="isCanada" class="form-floating mb-3">
							<input type="text" formControlName="ventureone_number" [minlength]="7" [maxlength]="20"
							[ngClass]="{ 'is-invalid': cp.ventureone_number.errors }" class="form-control">
							<label for="ventureOne">Venture One</label>
						</div>
						<div class="form-floating mb-3">
							<input type="text" formControlName="googleadsaccountid" id="googleadsaccountid" class="form-control">
							<label for="googleadsaccountid">Google Ads Account ID</label>
						</div>
						<div class="form-floating mb-3">
							<input type="text" formControlName="nonprofitauthorizationnumber" class="form-control">
							<label for="nonprofitauthorizationnumber">Non Profit Authorization Number</label>
						</div>
						<div *ngIf="isSalesAdmin" class="input-group mb-3">
							<div class="form-floating">
								<input type="date" formControlName="taxexemptexpirationdate" class="form-control"
                                [min]="today | date:'yyyy-MM-dd'"
								[ngClass]="{ 'is-invalid': submitted && !isValidExemption() }">
								<label for="taxexemptexpirationdate">Tax Exempt Expiration Date</label>
							</div>
							<div class="form-floating mx-3">
								<strong>Upload Tax Exemption doc to S3</strong>
								<input type="file" formControlName="taxexemptdoc"
								id="fileDropRef" accept=".pdf"	(change)="fileHandler($event.target)" />
								<button *ngIf="!file && isTaxExempt" type="button" class="btn btn-default btn-xs"
									(click)="downloadExemptionCertificate()"><i class="far fa fa-download">{{ customerId+'-CustomerExemption.pdf'}}</i></button>
							</div>
						</div>
						<div class="form-floating mb-3">
							<input type="text" formControlName="postalcrid" class="form-control"
							[ngClass]="{ 'is-invalid': customerPropertiesForm.controls.postalcrid.errors }">
							<label for="postalcrid">Postal CRID</label>
							<div *ngIf="submitted && customerPropertiesForm.controls.postalcrid.errors">
								<p class="input-error">
									Postal CRID is required when providing non profit authorization number
								</p>
							</div>
						</div>
						<div class="form-floating mb-3">
							<input type="text" formControlName="referralsource" id="referralsource" class="form-control">
							<label for="referralsource">Referral Source</label>
						</div>
						<div class="form-floating mb-3">
							<input type="text" formControlName="uspsrepresentative" id="uspsrepresentative" class="form-control">
							<label for="uspsrepresentative">USPS Representative</label>
						</div>
						<div class="mb-3">
							<div class="form-check">
								<input type="checkbox" formControlName="enablepo" id="enablepo" class="form-check-input">
								<label for="enablepo" class="form-check-label">Enable Purchase Order Payment
									Method</label>
							</div>
						</div>
					</div>
					<div class="card-body" *ngIf="authService.organizationId === 1">
						<button class="btn btn-primary" [disabled]="customerPropertiesForm.invalid" (click)="saveCustomerProperties()">Save Properties</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</app-spinner-with-overlay>
