<ng-container *ngIf="customerId">
	<app-pagefullheader></app-pagefullheader>
	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="row">
			<div class="col-md-8">
				<div class="card p-3">
					<ng-container>
						<div *ngIf="refundOrders.length > 0 else justSetRefundAmount">
							<div class="card">
								<div class="card-header">
									<strong>Select Order(s) To Include In Refund and Set Associated Refund Amount(s)</strong>
								</div>
								<div class="card-body">
									<ng-container *ngIf="!loading">
										<div class="form-check form-switch" *ngIf="refundPayments.length > 0 || unappliedCustomerPaymentAmount > 0">
											<input class="form-check-input" type="checkbox" role="switch" id="checkAllBoxes" [ngModel]="selectAllRefunds" (ngModelChange)="handleSelectAllRefunds()" />
											<label class="form-check-label" for="checkAllBoxes"><strong>Select All Orders</strong></label>
										</div>
										<table class="table table-hover table-panel">
											<thead>
												<tr>
													<th>Include</th>
													<th>Apply To</th>
													<th>Order Balance</th>
													<th>Max Refund Amount</th>
													<th>Refund Amount</th>
													<th>Surcharge Amount</th>
													<th>Total Refund</th>
												</tr>
											</thead>
											<tbody [formGroup]="refundForm">
												<ng-container formArrayName="refundPayments">
													<ng-container *ngFor="let refundPayment of refundPayments; let i = index">
														<tr [formGroup]="refundPayment">
															<td>
																<div class="form-check">
																	<input type="checkbox" id="'includeOrder' + balance.orderId" class="form-check-input" formControlName="selected" [checked]="refundPayment.get('selected')?.value" (change)="disableRefundAmountInput(i)" />
																</div>
															</td>
															<td>
																{{ refundPayment.get('orderId')?.value }}
															</td>
															<td>
																{{ refundPayment.get('orderBalance')?.value | currency }}
															</td>
															<td>
																{{ refundPayment.get('paymentAmount')?.value | currency }}
															</td>
															<td>
																<div class="form-floating">
																	<input type="number" class="form-control" formControlName="refundAmount"
																	(keyup)="updateSurchargeAmount(i)"
																	[ngClass]="{ 'is-invalid': refundPayments[i].controls.refundAmount.errors }"
																	[readonly]="refundPayment.get('selected')?.value === false" />
																	<label>Set amount for refund</label>
																</div>
															</td>
															<td>
																{{ refundPayment.get('surchargeAmount')?.value | currency }}
															</td>
															<td>
																{{ refundPayment.get('totalRefund')?.value | currency }}
															</td>
														</tr>
													</ng-container>
													<tr [formGroup]="refundForm" *ngIf="unappliedCustomerPaymentAmount > 0">
														<td>
															<div class="form-check">
																<input type="checkbox" id="includeFromCustomerPayment" class="form-check-input" formControlName="selected" [checked]="refundForm.controls.includeRefundFromCustomerPayment.value" (change)="toggleRefundFromCustomerPayment()" />
															</div>
														</td>
														<td>
															Unapplied
														</td>
														<td>
														</td>
														<td>
															{{ unappliedCustomerPaymentAmount | currency }}
														</td>
														<td>
															<div class="form-floating">
																<input type="number" class="form-control" formControlName="refundFromCustomerPayment"
																(keyup)="updateUnAppliedSurchargeAmount()"
																[ngClass]="{ 'is-invalid': refundForm.controls.refundFromCustomerPayment.errors }"
																[readonly]="refundForm.controls.includeRefundFromCustomerPayment.value === false" />
																<label>Set amount for refund</label>
															</div>
														</td>
														<td>
															{{ getUnappliedSurchargeAmount() | currency }}
														</td>
														<td>
															{{ getUnappliedTotalRefund() | currency }}
														</td>
													</tr>
												</ng-container>
											</tbody>
											<tfoot>
												<tr>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td>
														<strong>Total Refund: {{ getTotalRefundAmount() | currency }}</strong>
													</td>
													<td>
														<strong>Total Surcharge: {{ getTotalSurchargeAmount() | currency }}</strong>
													</td>
												</tr>
											</tfoot>
										</table>
									</ng-container>
								</div>
							</div>
						</div>
						<ng-template #justSetRefundAmount>
							<div class="card">
								<div class="card-header">
									<strong>Set Refund Amount</strong>
								</div>
								<div class="card-body">
									<form [formGroup]="refundForm">
										<div class="form-floating">
											<input type="number" class="form-control" [max]="customerPayment?.paymentAmount ?? 0"
											[ngClass]="{ 'is-invalid': control.refundTotal.errors }"
											formControlName="refundTotal" />
											<label>Set amount for refund</label>
										</div>
									</form>
								</div>
							</div>
						</ng-template>
					</ng-container>
					<div class="row mt-3 d-flex">
						<div class="col col-auto">
							<a [routerLink]="['/customers/', customerId, 'payments', customerPaymentId]" type="button">Go back to customer payment</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card">
					<div class="card-header">
						<strong>Select Payment Type</strong>
					</div>
					<div class="card-body">
						<strong>Refund Total: {{ getTotalRefundAmountWithSurcharge() | currency }}</strong>
						<div class="row mt-3">
							<div class="col-md-6 col">
								<ng-container *ngIf="isSalesAdmin">
									<div class="form-check mt-2" *ngIf="customerPayment?.paymentType === PaymentType.CreditCard">
										<input class="form-check-input" checked type="radio" id="payWithCC" (click)='selectPaymentType(PaymentType.CreditCard)'/>
										<label for="payWithCC" class="form-check-label">
											Refund to Customer's Card
										</label>
									</div>
									<div class="form-check mt-2" *ngIf="customerPayment?.paymentType === PaymentType.ACH">
										<input class="form-check-input" checked type="radio" id="payWithACH" (click)='selectPaymentType(PaymentType.ACH)'/>
										<label for="payWithACH" class="form-check-label">
											Refund to Customer's Account (ACH)
										</label>
									</div>
									<div class="form-check mt-2">
										<input class="form-check-input" [disabled]="customerPayment?.paymentType === PaymentType.CreditCard || customerPayment?.paymentType === PaymentType.ACH"
											[checked]="control.paymentType.value === PaymentType.Check" type="radio"
											id="payWithCheck" (click)='selectPaymentType(PaymentType.Check)' />
										<label for="payWithCheck" class="form-check-label">
											Pay with check
										</label>
									</div>
									<div class="form-check mt-2">
										<input class="form-check-input" [disabled]="customerPayment?.paymentType === PaymentType.CreditCard || customerPayment?.paymentType === PaymentType.ACH"
											[checked]="control.paymentType.value === PaymentType.Wire" type="radio"
											id="payWithWire" (click)='selectPaymentType(PaymentType.Wire)' />
										<label for="payWithWire" class="form-check-label">
											Pay with wire
										</label>
									</div>
									<div class="form-check mt-2">
										<input class="form-check-input" [disabled]="customerPayment?.paymentType === PaymentType.CreditCard || customerPayment?.paymentType === PaymentType.ACH"
											[checked]="control.paymentType.value === PaymentType.Chargeback" type="radio"
											id="chargeback" (click)='selectPaymentType(PaymentType.Chargeback)' />
										<label for="chargeback" class="form-check-label">
											Chargeback
										</label>
									</div>
								</ng-container>
							</div>
						</div>
						<div *ngIf="control.paymentType.value !== 'CreditCard' && control.paymentType.value !== 'ACH'" class="row mt-3">
							<div class="col-md-8 col mx-auto">
								<form [formGroup]="paymentReferenceForm">
									<div class="form-floating">
										<input type="text" class="form-control" formControlName="paymentRef" placeholder="Enter {{ paymentRefPlaceholder }}" />
										<label>{{ paymentRefPlaceholder }}</label>
										<div *ngIf="paymentRefControl.paymentRef.errors && paymentRefControl.paymentRef.touched"
											class="input-error">
											<div *ngIf="paymentRefControl.paymentRef.errors.required">
												{{ paymentRefPlaceholder }} is required
											</div>
										</div>
									</div>
								</form>
								<div class="row mt-3">
									<form [formGroup]="refundForm">
										<div class="form-floating">
											<input type="text" class="form-control" formControlName="note" maxlength="255" placeholder="Include a note" />
											<label>Include a note</label>
										</div>
										<div *ngIf="control.note.errors" class="input-error">
											Note should not exceed 255 characters
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-3 d-flex">
					<div class="col col-auto">
						<button class="btn btn-primary" (click)="openModal(confirmRefundModal)" [disabled]="getTotalRefundAmount() <= 0">
							Initiate Refund
						</button>
					</div>
				</div>
			</div>
		</div>
	</app-spinner-with-overlay>

	<ng-template #confirmRefundModal>
		<app-confirm-modal title="Refund confirmation" confirmText="refund" (confirmAction)="refund()"
			(closeModal)="closeModal()">
			<p>Are you sure you want to issue this refund?</p>
			<div>
				<ol class="list-group list-group-numbered">
					<li class="list-group-item d-flex" *ngFor="let refund of getSelectedRefunds()">
						<div class="ms-2 me-auto">
							<div *ngIf="refund.orderId">Refund Order #{{ refund.orderId }}</div>
							<div *ngIf="refund.orderId === 0">Direct Refund</div>
							Amount: {{ refund.refundAmount | currency }}
						</div>
					</li>
					<li class="list-group-item d-flex" *ngIf="getUnappliedRefundAmount()">
						<div class="ms-2 me-auto">
							<div>Refund of Unapplied Payment</div>
							Amount: {{ getUnappliedRefundAmount() | currency }}
						</div>
					</li>
				</ol>
			</div>
		</app-confirm-modal>
	</ng-template>
</ng-container>

