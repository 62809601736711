<app-spinner-with-overlay [showSpinner]='loading'>
	<div class="row mb-3">
		<div class="col-md-7">
			<div class="card p-3">
				<div class="card-body">
					<h5 class="card-title">Site Information</h5>
					<div class="form-floating mb-3">
						<input [ngModel]="site.name" (ngModelChange)="site.name = $event" class="form-control form-control-lg" id="siteName" placeholder="Site Name">
						<label for="siteName">Site Name</label>
					</div>
					<div class="row mb-3">
						<div class="col-md-4">
							<div class="form-check">
								<input type="checkbox" [ngModel]="site.active" (ngModelChange)="site.active = $event" class="form-check-input">
								<label class="form-check-label">Active</label>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-check">
								<input type="checkbox" [ngModel]="site.enableSignin" (ngModelChange)="site.enableSignin = $event" class="form-check-input">
								<label class="form-check-label">Enable Sign In</label>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-check">
								<input type="checkbox" [ngModel]="site.enableSignup" (ngModelChange)="site.enableSignup = $event" class="form-check-input">
								<label class="form-check-label">Enable Sign Up</label>
							</div>
						</div>
					</div>
					<fieldset>
						<legend>Contact Information</legend>
						<div class="mb-3">
							<app-email-input [ngModel]="site.emailAddress" (ngModelChange)="site.emailAddress = $event" label="Email Address" placeholder="Email Address"></app-email-input>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-floating mb-3">
									<app-phone-input [(ngModel)]="site.phoneNumber" [showErrors]="false" label="Phone Number" placeholder="Phone Number">
									</app-phone-input>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-floating mb-3">
									<app-phone-input [(ngModel)]="site.tollFreeNumber" label="Toll Free Number" placeholder="Toll Free Number" [showErrors]="false">
									</app-phone-input>
								</div>
							</div>
						</div>
					</fieldset>
					<fieldset>
						<legend>Address Information</legend>
						<div class="row">
							<div class="col-md-6">
								<div class="form-floating mb-3">
									<input [ngModel]="site.address1" (ngModelChange)="site.address1 = $event" class="form-control" id="siteAddress1" placeholder="Address 1">
									<label for="siteAddress1">Address 1</label>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-floating mb-3">
									<input [ngModel]="site.address2" (ngModelChange)="site.address2 = $event" class="form-control" id="siteAddress2" placeholder="Address 2">
									<label for="siteAddress2">Address 2</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-4">
								<div class="form-floating mb-3">
									<input [ngModel]="site.city" (ngModelChange)="site.city = $event" class="form-control" id="siteCity" placeholder="City">
									<label for="siteCity">City</label>
								</div>
							</div>
							<div class="col-md-4">
								<app-state-list [state]="site.state!" [isUS]="countryCode === 'US'" (selected)="stateSelected($event)">
								</app-state-list>
							</div>
							<div class="col-md-4">
								<div class="form-floating mb-3">
									<input [ngModel]="site.zipCode" (ngModelChange)="site.zipCode = $event" class="form-control" id="siteZip" placeholder="Zip Code">
									<label for="siteZip">Zip Code</label>
								</div>
							</div>
						</div>
					</fieldset>
				</div>
				<div class="card-body">
					<button class="btn btn-primary btn-lg" (click)="updateSite()">Save</button>
				</div>
			</div>
			<!-- Set Up information -->
			<div class="card p-3">
				<div class="card-body">
					<h5 class="card-title">Site Setup Guide</h5>
				</div>
				<ol>
					<li>
						<div class="hstack my-1">
							<div class="channel-name align-middle">Add Site Urls</div>
							<div class="align-middle ms-auto" *ngIf="siteUrls.length">
								<span class="fas fa-check text-success"></span>
							</div>
							<div class="align-middle ms-auto" *ngIf="!siteUrls.length">
								<span class="fas fa-times text-danger fa-2x"></span>
							</div>
						</div>
					</li>
					<li>
						<div class="hstack my-1">
							<div class="channel-name align-middle">Add Site products</div>
							<div class="align-middle ms-auto" *ngIf="productGroups.size">
								<span class="fas fa-check text-success"></span>
							</div>
							<div class="align-middle ms-auto" *ngIf="!productGroups.size">
								<span class="fas fa-times text-danger fa-2x"></span>
							</div>
						</div>
					</li>
					<li>
						<div class="hstack my-1">
							<div class="channel-name align-middle">Add USelect Add-Ons</div>
							<div class="align-middle ms-auto" *ngIf="siteAttributes.hasUSelectAddOns">
								<span class="fas fa-check text-success"></span>
							</div>
							<div class="align-middle ms-auto" *ngIf="!siteAttributes.hasUSelectAddOns">
								<span class="fas fa-times text-danger fa-2x"></span>
							</div>
						</div>
					</li>
					<li>
						<div class="hstack my-1">
							<div class="channel-name align-middle">Add USelect Design Types</div>
							<div class="align-middle ms-auto" *ngIf="siteAttributes.hasUSelectDesigns">
								<span class="fas fa-check text-success"></span>
							</div>
							<div class="align-middle ms-auto" *ngIf="!siteAttributes.hasUSelectDesigns">
								<span class="fas fa-times text-danger fa-2x"></span>
							</div>
						</div>
					</li>
					<li>
						<div class="hstack my-1">
							<div class="channel-name align-middle">Add Site Power Ups</div>
							<div class="align-middle ms-auto" *ngIf="siteAttributes.hasPowerUps">
								<span class="fas fa-check text-success"></span>
							</div>
							<div class="align-middle ms-auto" *ngIf="!siteAttributes.hasPowerUps">
								<span class="fas fa-times text-danger fa-2x"></span>
							</div>
						</div>
					</li>
					<li>
						<div class="hstack my-1">
							<div class="channel-name align-middle">Homepage Background Logo</div>
							<div class="align-middle ms-auto" *ngIf="siteAttributes.hasImages.hasBackground">
								<span class="fas fa-check text-success"></span>
							</div>
							<div class="align-middle ms-auto" *ngIf="!siteAttributes.hasImages.hasBackground">
								<span class="fas fa-times text-danger fa-2x"></span>
							</div>
						</div>
					</li>
					<li>
						<div class="hstack my-1">
							<div class="channel-name align-middle">Nav Logo</div>
							<div class="align-middle ms-auto" *ngIf="siteAttributes.hasImages.hasNav">
								<span class="fas fa-check text-success"></span>
							</div>
							<div class="align-middle ms-auto" *ngIf="!siteAttributes.hasImages.hasNav">
								<span class="fas fa-times text-danger fa-2x"></span>
							</div>
						</div>
					</li>
					<li>
						<div class="hstack my-1">
							<div class="channel-name align-middle">Receipt Logo</div>
							<div class="align-middle ms-auto" *ngIf="siteAttributes.hasImages.hasReceipt">
								<span class="fas fa-check text-success"></span>
							</div>
							<div class="align-middle ms-auto" *ngIf="!siteAttributes.hasImages.hasReceipt">
								<span class="fas fa-times text-danger fa-2x"></span>
							</div>
						</div>
					</li>
					<li>
						<div class="hstack my-1">
							<div class="channel-name align-middle">Footer Logo</div>
							<div class="align-middle ms-auto" *ngIf="siteAttributes.hasImages.hasFooter">
								<span class="fas fa-check text-success"></span>
							</div>
							<div class="align-middle ms-auto" *ngIf="!siteAttributes.hasImages.hasFooter">
								<span class="fas fa-times text-danger fa-2x"></span>
							</div>
						</div>
					</li>
					<li>
						<div class="hstack my-1">
							<div class="channel-name align-middle">Add themes via Asset Server</div>
							<div class="align-middle ms-auto" *ngIf="siteAttributes.hasCSS">
								<span class="fas fa-check text-success"></span>
							</div>
							<div class="align-middle ms-auto" *ngIf="!siteAttributes.hasCSS">
								<span class="fas fa-times text-danger fa-2x"></span>
							</div>
						</div>
					</li>
				</ol>
			</div>
		</div>
		<div class="col-md-5">
			<div class="card mb-3" *ngFor="let uSelect of productGroups | keyvalue">
				<div class="card-header">{{ uSelect.key }}</div>
				<ul class="list-group list-group-flush">
					<li class="list-group-item" *ngFor="let product of uSelect.value">
						<a [routerLink]="['/sites', site.siteId, 'products', product.baseProductId]">{{ product.name }}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
