<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card mb-3">
		<div *ngIf="couponId > 0" class="card-header">Edit Coupon Details</div>
		<div *ngIf="couponId === 0" class="card-header">Add Coupon Details</div>
		<form [formGroup]="couponForm">
			<div class="card-body">
				<div class="row mb-3">
					<div class="col-md-6">
						<div class="form-floating">
							<input type="text" formControlName="couponCode" class="form-control"
								[ngClass]="{ 'is-invalid': submitted && f.couponCode.errors }">
							<label for="couponCode">Coupon Code</label>
							<div *ngIf="submitted && f.couponCode.errors" class="invalid-feedback">
								<div *ngIf="f.couponCode.errors.required">Coupon Code is required</div>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-floating">
							<input type="date" class="form-control" formControlName="startDate"
								(change)="startDateSelected($any($event).target.value)"
								[ngModel]="coupon.startDate | date:'yyyy-MM-dd'"
								max="9999-12-31" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }">
							<label for="startDate">Start Date:</label>
						</div>
						<div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
							<div *ngIf="f.startDate.errors.required">Start Date is required</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-floating">
							<input type="date" formControlName="endDate"
								[ngModel]="coupon.endDate | date:'yyyy-MM-dd'"
								(change)="endDateSelected($any($event).target.value)" [min]="endDateStart" max="9999-12-31"
								class="form-control" [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }">
							<label for="endDate">End Date:</label>
							<div *ngIf="submitted && f.endDate.errors" class="invalid-feedback">
								<div *ngIf="f.endDate.errors.required">End Date is required</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-md-6">
						<div class="row mb-3">
							<div class="col">
								<div class="form-floating">
									<input type="text" formControlName="couponDescription" class="form-control">
									<label for="couponDescription">Coupon Description</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-6">
										<div class="form-floating mb-3">
											<input type="number" formControlName="discountAmount" class="form-control"
												[ngClass]="{ 'is-invalid': submitted && f.discountAmount.errors }">
											<label for="discountAmount">Discount Amount</label>
											<div *ngIf="submitted && f.discountAmount.errors" class="invalid-feedback">
												<div *ngIf="f.discountAmount.errors.required">Discount Amount is required</div>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-check">
											<input type="checkbox" formControlName="discountPercent" class="form-check-input">
											<label for="discountPercent" class="form-check-label">Discount As Percent</label>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-floating">
									<select class="form-select" formControlName="applyTo">
										<option disabled>Select One</option>
										<option value="OrderTotal">Order Total</option>
										<option value="ShippingTotal">Shipping Total</option>
										<option value="DesignTotal">Design Total</option>
										<option value="EntireTotal">Entire Total</option>
										<option value="AllowedProductTotal">Allowed Products Total</option>
										<option value="NextPriceTier">Next Price Tier</option>
										<option *ngIf="isCanada" value="Postage">Postage</option>
									</select>
									<label for="applyTo">Apply To</label>
									<div *ngIf="submitted && f.applyTo.errors" class="invalid-feedback">
										<div *ngIf="f.applyTo.errors.required">Apply To is required</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="form-floating">
									<input type="number" formControlName="minimumOrderAmout" class="form-control"
										[ngClass]="{ 'is-invalid': submitted && f.minimumOrderAmout.errors }">
									<label for="minimumOrderAmout">Minimum Order Amount</label>
									<div *ngIf="submitted && f.minimumOrderAmout.errors" class="invalid-feedback">
										<div *ngIf="f.minimumOrderAmout.errors.required">Minimum Order Amout is required</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-check">
									<input type="checkbox" formControlName="singleUse" class="form-check-input">
									<label for="singleUse" class="form-check-label">Single Use</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group" *ngIf="couponForm.controls.applyTo.value === 'AllowedProductTotal'">
							<label for="singleUse">Products:</label>
							<div class="form-floating">
								<select class="form-select" [(ngModel)]="activeUSelect" formControlName="uSelect" *ngIf="productUSelects.length > 0" (change)="filterProductsByUSelect($any($event.target).value)">
									<option disabled>Select Product Type</option>
									<option value="0" selected>All Products</option>
									<option *ngFor="let uSelect of productUSelects" [value]="uSelect.uSelectId">{{getUSelectName(uSelect.uSelectId)}}</option>
								</select>
								<label for="uSelect">Product Type</label>
							</div>
							<select *ngIf="filteredCouponProducts.length > 0" class="form-select mt-2" formControlName="selectedProducts" size="{{filteredCouponProducts.length < 10 ? filteredCouponProducts.length : 10}}" multiple>
								<option *ngFor="let product of filteredCouponProducts" [value]="product.baseProductId">
									{{product.baseProduct?.name ?? product.name}}
								</option>
							</select>
						</div>
						<div *ngIf="couponForm.controls.applyTo.value !== 'AllowedProductTotal'" class="text-warning">
							<h4>If you'd like to select specific products, change the Apply To to be "Allowed Products Total"</h4>
						</div>
					</div>
				</div>

				<div>
					<button *ngIf="couponId > 0 && authService.organizationId === 1" class="btn btn-primary" [disabled]="couponForm.invalid" (click)="saveCoupon()">Update Coupon</button>
					<button *ngIf="couponId === 0 && authService.organizationId === 1" class="btn btn-primary" [disabled]="couponForm.invalid" (click)="createCoupon()">Create</button>
					&nbsp;
					<a class="btn btn-primary" [routerLink]="['/sites', siteService.currentSiteId, 'coupons' ]">Back</a>
				</div>
			</div>
		</form>
	</div>
</app-spinner-with-overlay>
