import { Component, OnInit } from '@angular/core';
import { ShoppingQuoteService } from 'services/shopping-quote.service';
import { Receipt, Customer, QuoteSearchResponse, CustomerAddress, Site, SiteFilterRequest, QuoteSearchQuery, ShoppingQuote, LineTypeEnum } from '@taradel/admin-api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'services/customer.service';
import { ToastService } from 'services/toast.service';
import { SitesService } from 'services/sites.service';
import { CustomerAddressService } from 'services/customer-address.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'services/authentication.service';
import { SalesApiService } from 'services/sales-api.service';

@Component({
	selector: 'app-shopping-quotes',
	templateUrl: './shopping-quotes.component.html',
	styleUrls: ['./shopping-quotes.component.scss']
})
export class ShoppingQuotesComponent implements OnInit {

	customerId = 0;
	customer!: Customer;
	quotes: QuoteSearchResponse[] = [];
	loading = false;
	viewIndex?: number;
	quoteLineItems?: Receipt;
	disableView = false;
	quoteBillingAddress?: CustomerAddress;
	pageNo = 1;
	pageSize = 20;
	total = 0;
	quotesSearch = {
		customerId: 0,
		siteId: 0,
		showExpired: false,
		showOrders: false,
		pageNo: 1,
		pageSize: this.pageSize
	} as QuoteSearchQuery;
	primarySites: Site[] = [];
	primarySiteId = 0;
	showOrders = false;
	quoteToUpdate?: QuoteSearchResponse;
	isSalesAdmin = false;
    selectedQuotes: QuoteSearchResponse[] = [];

	constructor(private quoteService: ShoppingQuoteService,
		private route: ActivatedRoute,
		private router: Router,
		private customerService: CustomerService,
		private customerAddressService: CustomerAddressService,
		private toastService: ToastService,
        private salesApiService: SalesApiService,
		private sitesService: SitesService,
		private authService: AuthenticationService,
		public modalService: NgbModal) {
			this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		}

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;
			this.isSalesAdmin = await this.authService.hasRole('SalesAdmin');
			let request = new SiteFilterRequest({
				adminCheckout: false,
				showInactive: false
			});
			this.primarySites = await this.sitesService.getSitesForAdmin(request);
			this.customer = await this.customerService.getCustomer(this.customerId);
			this.quotesSearch.customerId = this.customerId;
			this.quotes = await this.quoteService.searchCustomerQuotes(this.quotesSearch);
		}
		catch (error) {
			this.toastService.showError("There was a problem retrieving this customer's quotes", 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	async search() {
		this.loading = true;
		try {
			this.quotesSearch.pageNo = this.pageNo = 1;
			this.quotesSearch.pageSize = this.pageSize;
			if (this.quotesSearch.showOrders) {
				this.showOrders = true;
			}
			else {
				this.showOrders = false;
			}
			this.total = await this.quoteService.getCustomerQuotesCount(this.quotesSearch);
			this.quotes = await this.quoteService.searchCustomerQuotes(this.quotesSearch);
		}
		catch (error) {
			this.toastService.showError("There was a problem retrieving this customer's quotes", 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	async pageChanged() {
		this.loading = true;
		try {
			this.quotesSearch.pageNo = this.pageNo;
			this.quotesSearch.pageSize = this.pageSize;
			this.quotes = await this.quoteService.searchCustomerQuotes(this.quotesSearch);
		}
		catch (error) {
			this.toastService.showError('There was a problem changing the page', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	siteChanged(selected: number) {
		this.quotesSearch.siteId = selected;
	}

	checkDate(quote: QuoteSearchResponse) {
		return quote.expirationDate < new Date() ? true : false;
	}

	async viewQuote(quote: QuoteSearchResponse, i: number) {
		try {
			this.loading = true;
			this.closeQuote();
			this.disableView = true;
			this.viewIndex = i;
			this.quoteLineItems = await this.quoteService.getQuoteLineItems(quote.quoteId);
			this.quoteBillingAddress = await this.customerAddressService.getCustomerAddress(this.customerId, quote.billingAddressId);
		}
		catch {
			this.toastService.showError('There was an error getting quote details');
		}
		finally {
			this.loading = false;
		}

	}

	checkoutQuote(quoteId: string) {
		this.router.navigate(['/customers', this.customerId, 'shop', quoteId, 'checkout']);
	}

	closeQuote() {
		this.viewIndex = undefined;
		this.disableView = false;
		this.quoteLineItems = undefined;
	}

	// route to shopping carts to for editing quote data
	async editQuote(quoteId: string) {
	}

	async copyToClipboard(quoteId: string, index: number): Promise<void> {
		this.loading = true;
		let checkoutLink = '';
		try {
			checkoutLink = await this.quoteService.getQuoteCheckoutLink(quoteId);
		}
		catch (err: any) {
			console.log(err);
			this.toastService.showError('Something went wrong when generating quote checkout link');
		}
		finally {
			this.loading = false;
		}
		if (checkoutLink !== '') {
			navigator.clipboard.writeText(checkoutLink).then(() => {
				/* clipboard successfully set */
				this.toastService.showInfo('The checkout link for the selected cart was copied to your clipboard', 'Checkout Link');
			}, (error) => {
				/* clipboard write failed */
				this.toastService.showError('There was an error copying the checkout link to your clipboard', 'Checkout Link');
				console.log(error);
			});
		}
	}
	expirationDateChanged(quote: QuoteSearchResponse, startDate: string) {
		const [year, month, day] = startDate.split('-');
		quote.expirationDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
	}

	async updateExpirationDate() {
		try {
			this.loading = true;
			this.closeModal();
			await this.quoteService.updateShoppingQuoteExpiration(this.quoteToUpdate!.quoteId, this.quoteToUpdate!.expirationDate);
			this.toastService.showSuccess('Expiration date updated successfully');
		}
		catch {
			this.toastService.showError('Expiration date update failed');
		}
		finally {
			this.loading = false;
		}
	}

	isSelected(quote: QuoteSearchResponse): boolean {
		return this.selectedQuotes.find(x => x.quoteId === quote.quoteId) !== undefined;
	}

    isDisabled(siteId: number): boolean {
		return this.selectedQuotes.length > 0 && !this.selectedQuotes.some(x => x.siteId === siteId);
	}

	selectQuote(quote: QuoteSearchResponse, checked: boolean) {
		if (checked) {
			this.selectedQuotes.push(quote);
		}
		else {
			const index = this.selectedQuotes.findIndex(x => x.quoteId === quote.quoteId);
			this.selectedQuotes.splice(index, 1);
		}
	}
    async sendQuotesEmail() {
        try {
            this.loading = true;
            await this.salesApiService.emailQuotes(this.selectedQuotes.map(q => q.quoteId));
            this.toastService.showSuccess('The email will be sent shortly');
        }
        catch {
			this.toastService.showError('There was an error sending the email');
		}
		finally {
			this.loading = false;
		}
    }

	closeModal() {
		this.modalService.dismissAll();
	}
}
