import { Component, Input, OnInit } from '@angular/core';
import { Product } from '@taradel/web-api-client';
import { ProductsService } from 'services/products.service';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {

  @Input() productId!: number;
  product?: Product;
  constructor(public productsService: ProductsService) { }

  async ngOnInit(): Promise<void> {
	this.product = await this.productsService.getProduct(this.productId);
  }
}
