<ng-container *ngIf="!!bundle">
	<div class="row">
		<div class="col">
			<form [formGroup]="contentForm">
				<ng-container formArrayName="contentItems">
					<ng-container *ngFor="let item of items; let i = index">
						<div [formGroup]="item">
							<div class="row mb-3">
								<div class="col">
									<div class="row mb-2">
										<div class="col-auto">
											<strong>Bundle {{ item.get('bundleContentType')?.value }}</strong>
										</div>
									</div>
									<div class="row mb-2">
										<div class="col-md-6 col-12 mb-md-0 mb-2">
											<div class="form-floating">
												<input type="text" class="form-control" formControlName="contentKey" placeholder="Content Key" />
												<label for="contentKey">Content Key</label>
											</div>
										</div>
										<div class="col-md-6 col-12 mb-md-0 mb-2">
											<div class="form-floating">
												<select class="form-select form-control" formControlName="contentType">
													<option [value]="undefined" disabled>Set Content Type</option>
													<option [value]="ContentType[ContentType.Html]">Html</option>
													<option [value]="ContentType[ContentType.Text]">Text</option>
													<option [value]="ContentType[ContentType.Markdown]">Markdown</option>
												</select>
											</div>
										</div>
									</div>
									<div class="row mb-2">
										<div class="col-auto">
											<strong>Content:</strong>
										</div>
									</div>
									<ng-container formArrayName="contentArray">
										<div *ngFor="let contentItem of getContentItems(i); let j = index" [formGroupName]="j" class="row mb-2">
											<div class="col-md-6 col-12">
												<div class="row mb-2">
													<div class="col">
														<app-sites-list [startingSiteId]="contentItem.get('siteId')?.value" [showError]="contentItem.get('siteId')?.value === -1 && submitted" (siteSelected)="setContentSiteId($event.siteId, item.get('bundleContentType')?.value, j)" [sites]="sites" [disabled]="contentItem.get('siteId')?.value === 0"></app-sites-list>
													</div>
												</div>
												<div class="row mb-2">
													<div class="col">
														<div class="form-floating">
															<select class="form-select form-control" formControlName="language" (change)="setContentLanguage(item.get('bundleContentType')?.value, $any($event.target).value, j)">
																<option [value]="undefined" disabled>Set Language</option>
																<option value="en">en</option>
																<option value="fr">fr</option>
															</select>
														</div>
													</div>
												</div>
											</div>
											<div class="col-md-6 col-12">
												<div class="col">
													<div class="form-floating">
														<textarea class="form-control" rows="3" cols="20" formControlName="content" style="height:150px" ></textarea>
														<label>Content</label>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
						<hr />
					</ng-container>
				</ng-container>
				<!--<div class="form-floating mb-3">
					<input type="text" class="form-control" formControlName="nameContentKey" placeholder="NameContentKey" />
					<label for="nameContentKey">NameContentKey</label>
				</div>
				<div class="form-floating mb-3">
					<input type="text" class="form-control" formControlName="descriptionContentKey" placeholder="DescriptionContentKey" />
					<label for="descriptionContentKey">DescriptionContentKey</label>
				</div>
				<div class="form-floating mb-3">
					<input type="text" class="form-control" formControlName="detailsContentKey" placeholder="DetailsContentKey" />
					<label for="detailsContentKey">DetailsContentKey</label>
				</div>-->
				<div class="form-floating mb-3">
					<input type="text" class="form-control" formControlName="bundleColor" placeholder="BundleColor" />
					<label for="bundleColor">BundleColor</label>
				</div>
				<div class="row d-flex justify-content-between">
					<div class="col-auto">
						<button class="btn btn-link text-danger" (click)="emitGoBack()">Go back</button>
					</div>
					<div class="col-auto">
						<button class="btn btn-success" (click)="saveContent()">Save Content</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</ng-container>
