import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SitesService, DetailedUSelectAddOns } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

export interface USelectGroup {
	uSelectId: number;
	uSelectedAddons: DetailedUSelectAddOns[];
}

@Component({
	selector: 'app-site-uselect-addons',
	templateUrl: './site-uselect-addons.component.html',
	styleUrls: ['./site-uselect-addons.component.scss']
})

export class SiteUselectAddonsComponent implements OnInit, OnDestroy {

	detailedUSelectAddOns: DetailedUSelectAddOns[] = [];
	uSelectIdsList: any[] = [];
	siteId: number = 0;
	loading: boolean = false;
	currentSite$ = new Subscription();
	uSelectGroups: USelectGroup[] = [];


	@ViewChild('uSelectIdDropdown') uSelectIdDropdown!: ElementRef;

	constructor(
		private sitesService: SitesService,
		private router: Router,
		private toastService: ToastService
	) { }

	ngOnInit(): void {
		this.currentSite$ = this.sitesService.currentSite.subscribe(async site => {
			if (site) {
				try {
					this.loading = true;
					this.siteId = site!.siteId;
					await this.getSiteUSelectAddons(site!.siteId);
				}
				catch {
					this.toastService.showError('There was error loading site uselect add ons');
				}
				finally {
					this.loading = false;
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.currentSite$.unsubscribe();
	}

	async getSiteUSelectAddons(siteId: number): Promise<void> {
		const addOnsList = await this.sitesService.getSiteUSelectAddOns(siteId);
		this.detailedUSelectAddOns = await this.sitesService.setListOfSiteUSelectAddOns(addOnsList);
		this.getArrayOfUSelectIds();
	}

	getArrayOfUSelectIds() {
		this.uSelectIdsList = [];
		this.uSelectGroups = [];
		this.uSelectIdDropdown.nativeElement.value = 'Filter by USelect ID';
		this.detailedUSelectAddOns.forEach(addOn => {
			this.uSelectIdsList.push({ 'id': addOn.uSelectId, 'name': addOn.uSelectName });
			const idFound = this.uSelectGroups.findIndex(e => e.uSelectId === addOn.uSelectId);
			if (idFound > -1) {
				this.uSelectGroups[idFound].uSelectedAddons.push(addOn);
			}
			else {
				this.uSelectGroups.push({uSelectId: addOn.uSelectId, uSelectedAddons: [addOn] });
			}
		});
		this.uSelectIdsList = this.uSelectIdsList.filter((v, i, s) => i === s.findIndex(t => t.id === v.id && t.name === v.name));
	}

	routeToEditUSelect(uSelectId: number) {
		this.router.navigateByUrl(`sites/${this.siteId}/uselect-addons/${uSelectId}`);
	}

}
