import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Product, ProductsSearch, WLProduct, Site } from '@taradel/admin-api-client';
import { ProductsService } from 'services/products.service';
import { SitesService } from 'services/sites.service';
import { USelect } from 'services/distributions.service';
import { filter } from 'rxjs/operators';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-add-wl-product',
	templateUrl: './add-wl-product.component.html',
	styleUrls: ['./add-wl-product.component.scss']
})

export class AddWlProductComponent implements OnInit {

	submitted = false;
	showMultiSelect: boolean = false;
	wlProductForm: UntypedFormGroup;
	baseProducts: Product[] = [];
	uselects = USelect;
	allProducts: WLProduct[] = [];
	site?: Site;
	loading = false;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		public sitesService: SitesService,
		public productService: ProductsService,
		private toastService: ToastService
	) {
		this.wlProductForm = this.formBuilder.group({
			name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
			baseProduct: ['', Validators.required],
			markup: ['', Validators.min(0)],
			markupType: ['', Validators.required],
			designFee: [],
			creativeSetupFee: [],
			repOnly: []
		});
	}

	ngOnInit(): void {
		this.sitesService.currentSite.pipe(
			filter((site) => !!site)
		).subscribe(async site => {
			try {
				this.loading = true;
				this.site = site;
				this.wlProductForm.reset();
				this.allProducts = await this.sitesService.getSiteProducts(this.sitesService.currentSiteId);
				this.baseProducts = await this.productService.getAllProducts(new ProductsSearch({
					pageNo: 1,
					pageSize: 1000,
					uSelectId: 0,
					name: '',
					productId: 0
				}));
			}
			catch {
				this.toastService.showError('There was an error loading product info');
			}
			finally {
				this.loading = false;
			}
		});
	}

	get f() {
		return this.wlProductForm.controls;
	}

	async addWLProduct(addByUselect: boolean) {
		this.submitted = true;
		if (this.wlProductForm.invalid) {
			return;
		}

		try {
			this.loading = true;
			const markup = (this.wlProductForm.controls.markup.value !== null && this.wlProductForm.controls.markup.value.length > 0) ?
				parseFloat(this.wlProductForm.controls.markup.value) : 0;
			const baseProductId = parseInt(this.wlProductForm.controls.baseProduct.value, 10);
			const wlProduct = new WLProduct({
				siteId: this.sitesService.currentSiteId,
				name: this.wlProductForm.controls.name.value,
				baseProductId: baseProductId,
				markup,
				markupType: this.wlProductForm.controls.markupType.value,
				productId: 0,
				deleted: false,
				designFee: parseFloat(this.wlProductForm.controls.designFee.value),
				creativeSetupFee: parseFloat(this.wlProductForm.controls.creativeSetupFee.value),
				repOnly: this.wlProductForm.controls.repOnly.value ?? false
			});

			await this.sitesService.addSiteProduct(wlProduct);

			if (!addByUselect) {
				this.router.navigate(['/sites', this.sitesService.currentSiteId, 'products', baseProductId]);
			}
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError('There was an error adding the product');
		}
		finally {
			this.submitted = false;
			this.loading = false;
		}
	}
}
