import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { USelectMethod, Product, UpdateUSelectProductsPostageRequest, PriceMatrix } from '@taradel/admin-api-client';
import { Subscription } from 'rxjs';
import { PricingService } from 'services/pricing.service';
import { ProductsService } from 'services/products.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-update-uselect-postage',
  templateUrl: './update-uselect-postage.component.html',
  styleUrls: ['./update-uselect-postage.component.scss']
})
export class UpdateUselectPostageComponent implements OnInit, OnDestroy {
	uSelectId = 0;
	allUSelectProducts: Product[] =[];
	priceMatrices: PriceMatrix[] = [];
	allUSelectMethods: USelectMethod[] = [];
	loading = true;
	updatePostageForm: UntypedFormGroup;
	currentSite$ = new Subscription();

	constructor(private formBuilder: UntypedFormBuilder,
		private productService: ProductsService,
		private sitesService: SitesService,
		private pricingService: PricingService,
		private toastService: ToastService,
		private route: ActivatedRoute
	) {
		this.uSelectId = parseInt(route.snapshot.paramMap.get('uselectId') ?? '0', 10);
		this.updatePostageForm = this.formBuilder.group({
			uSelectId: [{value: this.uSelectId, disabled: true}],
			uSelectName: [{value: '', disabled: true}],
			standardPostage: [''],
			firstClassPostage: ['']
		});
		this.uSelectId = parseInt(route.snapshot.paramMap.get('uselectId') ?? '0', 10);
	}

	async ngOnInit() {
		try {
			await this.initialize();
		}
		catch (error: any) {
			this.toastService.showError('There was an error loading the data');
		}
		finally {
			this.loading = false;
		}
	}

	ngOnDestroy(): void {
		this.currentSite$.unsubscribe();
	}

	getMethod(item: {id: number, name: string}) {
		this.updatePostageForm.controls.uSelectId.setValue(item.id);
		this.updatePostageForm.controls.uSelectName.setValue(item.name);
	}

	async initialize() {
		this.allUSelectMethods = await this.sitesService.getAllUSelect();
		const uselect = this.allUSelectMethods.find(x => x.uSelectId === this.uSelectId);
		this.updatePostageForm.controls.uSelectName.setValue(uselect?.name);
		this.allUSelectProducts = await this.productService.getAllUSelectProducts(this.uSelectId);
		this.priceMatrices = [];
		this.allUSelectProducts.map(x => this.priceMatrices.push(...x.priceMatrix!));
	}
	getProductName(prouctId: number) {
		return this.allUSelectProducts.find(p => p.productId === prouctId)?.name;
	}

	async updatePostage() {
		try {
			this.loading = true;
			const standardPostage = this.updatePostageForm.controls.standardPostage.value?.toString() ?? '';
			const firstClassPostage = this.updatePostageForm.controls.firstClassPostage.value?.toString() ?? '';
			const request = new UpdateUSelectProductsPostageRequest({
				uSelectId: this.updatePostageForm.controls.uSelectId.value,
				firstClassPostage: firstClassPostage.length > 0 ? parseInt(firstClassPostage) : undefined,
				standardPostage: standardPostage.length > 0 ? parseInt(standardPostage) : undefined
			});
			await this.pricingService.updateUSelectProductsPostage(request);
			await this.initialize();
			this.toastService.showSuccess('The USelect products postage values were updated successfully');
		}
		catch {
			this.toastService.showError('There was an error updating the postage values');
		}
		finally {
			this.loading = false;
		}
	}
}

