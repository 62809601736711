<app-customer-header [customerId]="customerId">
	<ng-container altnav>
		<ul class="nav nav-pills justify-content-end">
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'distributions']">Back to Distributions</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'personalized-mail']">Build List</a>
			</li>
			<li class="nav-item">
				<a class="nav-link active" [routerLink]="['/customers', customerId, 'personalized-mail', 'upload']">Upload List</a>
			</li>
		</ul>
	</ng-container>
</app-customer-header>
<app-spinner-with-overlay [showSpinner]="showSpinner">
	<div class="container py-5">
		<div [hidden]="hideUpload" class="row">
			<div class="col">
				<div class="card">
					<div class="upload-container mt-2 card-body">
						<div class="ratio" style="--bs-aspect-ratio: 20%;">
							<div appDragAndDrop class="dropzone text-primary d-flex align-items-center" (fileDropped)="onFileDropped($event)">
								<input type="file" #fileInput id="fileDropRef" accept=".xlsx, .csv"
									(change)="fileHandler($event.target)" />
								<div class="m-auto">
									<div *ngIf="file === undefined">
										<span class="fa fa-2x fa-upload"></span>&nbsp;Drop file or click here to upload
									</div>
									<div *ngIf="file !== undefined">
										<span class="fa fa-file-excel-o"></span>&nbsp;{{file.name}}
									</div>
								</div>
							</div>
						</div>
						<p class="mt-3">
							<button class="btn btn-primary btn-lg" [disabled]="!file" (click)='uploadYourlist()'>Next</button>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div [hidden]="hideIdentify" class="row">
			<div class="row">
				<div class="col-md-12">
					<app-uploaded-list-field-mapping (selectColumn)="columnSelected()"></app-uploaded-list-field-mapping>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<ul *ngIf="disableCertify" class="text-danger">
						<li *ngIf="!validateCertifyDetail.hasNamePair">Select either 1. "Full Name" column, 2. "Company Name" column, or 3. "First Name" and "Last Name" columns</li>
						<li *ngIf="!validateCertifyDetail.hasAddress">Select an "Address" column</li>
						<li *ngIf="!validateCertifyDetail.hasCity">Select a "City" column</li>
						<li *ngIf="!validateCertifyDetail.hasState">Select a "State" column</li>
						<li *ngIf="!validateCertifyDetail.hasZip">Select a "Zip" column</li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<button class="btn btn-primary btn-lg m-2" (click)='goToUploadSlide()'>Previous</button>
					<button class="btn btn-primary btn-lg m-2" [disabled]="disableCertify" (click)='certifyList()'>Next</button>
				</div>
			</div>
		</div>

		<div [hidden]="hideSaveList" class="row">

			<div class="row">
				<div class="col-md-3"></div>
				<div class="col-md-6">
					<div class="upload-container">
						<label for="listName" class="form-label">Enter List Name</label><br>
						<input type="text" id="listName" class="form-control" [(ngModel)]="listName"
							placeholder="List Name" />
					</div>
					<div class="col-md-3"></div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-2"></div>
				<div class="col-md-8">
					<table class="table table-striped table-bordered text-center" width="100%">
						<thead>
							<tr>
								<th scope="col">Result</th>
								<th scope="col">Counts</th>
								<th scope="col">Description</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td scope="row">Detected Addresses</td>
								<td>{{certifyListResponse?.originalCount | number}}</td>
								<td>Addresses detected in your file prior to USPS validation</td>
							</tr>
							<tr>
								<td scope="row" class="fw-bold text-success">Valid Addresses</td>
								<td class="fw-bold text-success">{{certifyListResponse?.validCount | number}}</td>
								<td class="fw-bold text-success">Addresses that we can mail to</td>
							</tr>
							<tr>
								<td scope="row">Invalid Addresses</td>
								<td>{{certifyListResponse?.invalidCount | number}}</td>
								<td>Incomplete or non-mailable addresses</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="col-md-2"></div>
			</div>
			<div class="row">
				<div class="col-md-2"></div>
				<div class="col-md-8">
					<p>
						<button class="btn btn-primary btn-lg m-2" (click)='goToIdentifySlide()'>Previous</button>
						<button class="btn btn-primary btn-lg m-2" [disabled]="listName.length === 0 || certifyListResponse?.validCount === 0" (click)='saveList()'>Save</button>
					</p>
				</div>
				<div class="col-md-2"></div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
