import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportsService } from 'services/reports.service';
import { DashboardData } from '@taradel/admin-api-client';
import { SparkLineData } from 'components/shared/chart-spark-line/spark-line-data';
import { SparkLineOptions } from 'components/shared/chart-spark-line/chart-spark-line.component';
import { ToastService } from 'services/toast.service';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

const displayThisManyDays = 60;
const compareAgainstThisManyDaysAgo = 365;

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	loading: boolean = false;
	dashboardData?: DashboardData;

	today: Date = new Date();
	rangeEnd: Date = new Date(); // yesterday
	rangeStart: Date = new Date(); // rangeEnd - 30 days
	prevStart: Date = new Date(); // rangeStart - 1 day
	prevEnd: Date = new Date(); // prevStart - 30 days

	curAccountsCreated: number = 0;
	oldAccountsCreated: number = 0;
	accountsCreatedPerf: string = '';
	accountsCreatedPctChg: number = 0;

	curDistCreated: number = 0;
	oldDistCreated: number = 0;
	distCreatedPerf: string = '';
	distCreatedPctChg: number = 0;

	curOrderCreated: number = 0;
	oldOrderCreated: number = 0;
	orderCreatedPerf: string = '';
	orderCreatedPctChg: number = 0;
	orderCreatedPctChgAbs: number = 0;

	curSaleAmount: number = 0;
	oldSaleAmount: number = 0;
	saleAmountPerf: string = '';
	saleAmountPctChg: number = 0;
	saleAmountPctChgAbs: number = 0;

	curAov: number = 0;
	oldAov: number = 0;
	aovPerf: string = '';
	aovPctChg: number = 0;
	aovPctChgAbs: number = 0;

	dailyOrderCounts?: Array<[number, number]>;
	dailyOrderTotals?: Array<[number, number]>;
	dailyAccountCreations?: Array<[number, number]>;
	dailyDistributionCreations?: Array<[number, number]>;

	dailyOrderCountsOpts?: Partial<SparkLineOptions>;
	dailyOrderTotalsOpts?: Partial<SparkLineOptions>;
	dailyAccountCreationsOpts?: Partial<SparkLineOptions>;
	dailyDistributionCreationsOpts?: Partial<SparkLineOptions>;
	hoveredDate: NgbDate | null = null;
	fromDate?: NgbDate;
  	toDate: NgbDate | null = null;
	@ViewChild("dp") dateRange!: NgbInputDatepicker;

	constructor(private reportsService: ReportsService, private toastService: ToastService) {
		this.rangeEnd.setDate(this.today.getDate() - 1);
		this.rangeStart.setDate(this.today.getDate() - displayThisManyDays);
		this.prevEnd.setDate(this.today.getDate() - compareAgainstThisManyDaysAgo - 1);
		this.prevStart.setDate(this.today.getDate() - compareAgainstThisManyDaysAgo - displayThisManyDays);
	}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		try {
			this.dashboardData = await this.reportsService.getDashboardData(this.rangeStart, this.rangeEnd);

			if (this.dashboardData) {
				this.curAccountsCreated = this.dashboardData.accountsCreated?.value ?? 0;
				this.oldAccountsCreated = this.dashboardData.accountsCreated?.oldValue ?? 0;
				this.accountsCreatedPerf = this.getChangeTextDescription(this.curAccountsCreated, this.oldAccountsCreated);
				this.accountsCreatedPctChg = this.dashboardData.accountsCreated?.change! / 100 ?? 0;

				this.curDistCreated = this.dashboardData.distributionsCreated?.value ?? 0;
				this.oldDistCreated = this.dashboardData.distributionsCreated?.oldValue ?? 0;
				this.distCreatedPerf = this.getChangeTextDescription(this.curDistCreated, this.oldDistCreated);
				this.distCreatedPctChg = this.dashboardData.distributionsCreated?.change! / 100 ?? 0;

				this.curOrderCreated = this.dashboardData.totalOrderCount?.value ?? 0;
				this.oldOrderCreated = this.dashboardData.totalOrderCount?.oldValue ?? 0;
				this.orderCreatedPerf = this.getChangeTextDescription(this.curOrderCreated, this.oldOrderCreated);
				this.orderCreatedPctChg = this.dashboardData.totalOrderCount?.change! / 100 ?? 0;
				this.orderCreatedPctChgAbs = Math.abs(this.orderCreatedPctChg);

				this.curSaleAmount = this.dashboardData.totalSalesAmount?.value ?? 0;
				this.oldSaleAmount = this.dashboardData.totalSalesAmount?.oldValue ?? 0;
				this.saleAmountPerf = this.getChangeTextDescription(this.curSaleAmount, this.oldSaleAmount);
				this.saleAmountPctChg = this.dashboardData.totalSalesAmount?.change! / 100 ?? 0;
				this.saleAmountPctChgAbs = Math.abs(this.saleAmountPctChg);

				this.curAov = this.dashboardData.averageOrderValue?.value ?? 0;
				this.oldAov = this.dashboardData.averageOrderValue?.oldValue ?? 0;
				this.aovPerf = this.getChangeTextDescription(this.curAov, this.oldAov);
				this.aovPctChg = this.dashboardData.averageOrderValue?.change! / 100 ?? 0;
				this.aovPctChgAbs = Math.abs(this.aovPctChg);

				const dailyCounts = this.dashboardData.dailyOrderCount?.reverse();
				if (dailyCounts && dailyCounts.length > 0) {
					let orderData: Array<[number, number]> = new Array<[number, number]>();
					for (let i = 0; i < dailyCounts.length; i++) {
						let curDate = new Date();
						curDate.setDate(this.rangeStart.getDate() - i);
						let dayData = new SparkLineData(curDate, dailyCounts[i]);
						orderData.push([curDate.getTime(), dailyCounts[i]]);
					}
					this.dailyOrderCounts = orderData;

					this.dailyOrderCountsOpts = {
						series: [
							{
								name: 'daily-order-counts',
								data: this.dailyOrderCounts
							}
						],
					};
				}

				const dailyTotals = this.dashboardData.dailyOrderTotals?.reverse();
				if (dailyTotals && dailyTotals.length > 0) {
					let orderData: Array<[number, number]> = new Array<[number, number]>();
					for (let i = 0; i < dailyTotals.length; i++) {
						let curDate = new Date();
						curDate.setDate(this.rangeStart.getDate() - i);
						let dayData = new SparkLineData(curDate, dailyTotals[i]);
						orderData.push([curDate.getTime(), dailyTotals[i]]);
					}
					this.dailyOrderTotals = orderData;

					this.dailyOrderTotalsOpts = {
						series: [
							{
								name: 'daily-order-totals',
								data: this.dailyOrderTotals
							}
						],
						tooltip: {
							y: {
								formatter: function (value, { series, seriesIdx, dataPtIdx, w }) {
									return value.toLocaleString('en-US', { style: 'currency', currency: 'USD'});
								}
							}
						}
					};
				}

				const customerCounts = this.dashboardData.dailyAccountCreations?.reverse();
				if (customerCounts && customerCounts.length > 0) {
					let customerData: Array<[number, number]> = new Array<[number, number]>();
					for (let i = 0; i < customerCounts.length; i++) {
						let curDate = new Date();
						curDate.setDate(this.rangeStart.getDate() - i);
						let dayData = new SparkLineData(curDate, customerCounts[i]);
						customerData.push([curDate.getTime(), customerCounts[i]]);
					}
					this.dailyAccountCreations = customerData;
					this.dailyAccountCreationsOpts = {
						series: [
							{
								name: 'daily-account-creations',
								data: this.dailyAccountCreations
							}
						]
					};
				}

				const distributionCounts = this.dashboardData.dailyDistributionCreations?.reverse();
				if (distributionCounts && distributionCounts.length > 0) {
					let distributionData: Array<[number, number]> = new Array<[number, number]>();
					for (let i = 0; i < distributionCounts.length; i++) {
						let curDate = new Date();
						curDate.setDate(this.rangeStart.getDate() - i);
						let dayData = new SparkLineData(curDate, distributionCounts[i]);
						distributionData.push([curDate.getTime(), distributionCounts[i]]);
					}
					this.dailyDistributionCreations = distributionData;
					this.dailyDistributionCreationsOpts = {
						series: [
							{
								name: 'daily-distribution-creations',
								data: this.dailyDistributionCreations
							}
						]
					};
				}
			}
		}
		 catch (error) {
			 this.toastService.showError('There was a problem loading the component', 'Load Error');
			 console.log(error);
		}
		 finally {
			 this.loading = false;
		}
	}

	getChangeTextDescription(curValue: number, oldValue: number) {
		if (curValue > oldValue) {
			return 'Better than';
		}
		if (oldValue > curValue) {
			return 'Worse than';
		}
		return 'Same as';
	}



	async onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
		  this.fromDate = date;
		  this.toDate = null;
		  this.rangeStart = new Date(date.year, date.month - 1, date.day);
		}
		else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
		  this.toDate = date;
		  this.rangeEnd = new Date(date.year, date.month - 1, date.day);
		  this.prevEnd = new Date(this.rangeStart.getFullYear(), this.rangeStart.getMonth(), this.rangeStart.getDate());
		  this.prevEnd.setDate(this.rangeStart.getDate() - 1);
		  const diff = Math.abs(this.rangeEnd.getTime() - this.rangeStart.getTime());
		  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
		  this.prevStart = new Date(this.rangeStart.getFullYear(), this.rangeStart.getMonth(), this.rangeStart.getDate());
		  this.prevStart.setDate(this.rangeStart.getDate() - (diffDays + 1));
		  this.dateRange.close();
		  await this.ngOnInit();
		}
		else {
		  this.toDate = null;
		  this.fromDate = date;
		  this.rangeStart = new Date(date.year, date.month - 1, date.day);
		}
	  }

	  isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
	  }

	  isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	  }

	  isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
	  }
}
