import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PriceMatrix, PriceMatrixSiteMod, WLProduct } from '@taradel/web-api-client';
import { PricingService } from 'services/pricing.service';
import { ProductsService } from 'services/products.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';
import { Subscription, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'services/authentication.service';


@Component({
  selector: 'app-wl-product-details',
  templateUrl: './wl-product-details.component.html',
  styleUrls: ['./wl-product-details.component.scss']
})
export class WLProductDetailsComponent implements OnInit, OnDestroy {
	submitted = false;
	wlProductForm: UntypedFormGroup;
	baseProductId = 0;
	wlProduct?: WLProduct;
	tierDiscount: number[] = [];
	loading = false;

	siteSubscription$ = new Subscription();
	unsubscribe$ = new Subject<void>();

	constructor(
		public authService: AuthenticationService,
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		public sitesService: SitesService,
		public pricingService: PricingService,
		public productService: ProductsService,
		private toastService: ToastService,
		private location: Location
	) {
		route.paramMap.subscribe(params => {
			let state: any;

			if (!!location.getState()) {
				state = location.getState();
			}
			else {
				state = undefined;
			}
			if (!!state && !!state.siteId) {
				this.sitesService.setCurrentSiteId(state.siteId);
			}
		});
		this.wlProductForm = this.formBuilder.group({
			name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
			fullName: ['', Validators.maxLength(100)],
			quickDescription: ['', Validators.maxLength(255)],
			shortDescription: ['', Validators.maxLength(1000)],
			fullDescription: [''],
			markup: ['', Validators.min(0)],
			markupType: [''],
			designFee: [],
			useBaseDesignFee: [],
			creativeSetupFee: [],
			nonProfitStandardPostage: [],
			repOnly: []
		});

		if (this.authService.organizationId !== 1) {
			this.wlProductForm.disable();
		}
		this.baseProductId = parseInt(route.snapshot.paramMap.get('baseProductId') ?? '0', 10);
	}

	ngOnInit(): void {
		this.sitesService.currentSite.pipe(
			filter((site) => !!site),
			takeUntil(this.unsubscribe$)
		).subscribe(async site => {
			if (site) {
				this.loading = true;
				try {
					this.wlProduct = await this.sitesService.getWLProduct(this.sitesService.currentSiteId, this.baseProductId);
					this.wlProduct.baseProduct?.priceMatrix?.forEach(x => {
						this.tierDiscount.push(x.siteMods !== undefined && x.siteMods.length > 0 ? x.siteMods[0].modPerThousand : 0);
					});
					this.wlProductForm.controls.name.setValue(this.wlProduct!.name);
					this.wlProductForm.controls.fullName.setValue(this.wlProduct!.fullName);
					this.wlProductForm.controls.quickDescription.setValue(this.wlProduct!.quickDescription);
					this.wlProductForm.controls.shortDescription.setValue(this.wlProduct!.shortDescription);
					this.wlProductForm.controls.fullDescription.setValue(this.wlProduct!.fullDescription);
					this.wlProductForm.controls.markup.setValue(this.wlProduct!.markup);
					this.wlProductForm.controls.markupType.setValue(this.wlProduct!.markupType);
					if (this.wlProduct!.designFee !== undefined || this.wlProduct!.designFee !== null) {
						this.wlProductForm.controls.designFee.setValue(this.wlProduct!.designFee);
					}
					else {
						this.wlProductForm.controls.useBaseDesignFee.setValue(true);
					}
					if (this.wlProduct!.creativeSetupFee) {
						this.wlProductForm.controls.creativeSetupFee.setValue(this.wlProduct!.creativeSetupFee ?? 0);
					}
					if (this.wlProduct!.nonProfitStandardPostage) {
						this.wlProductForm.controls.nonProfitStandardPostage.setValue(this.wlProduct!.nonProfitStandardPostage ?? 0);
					}

					this.wlProductForm.controls.repOnly.setValue(this.wlProduct!.repOnly);
				}
				catch (ex: any) {
					this.toastService.showError('There was an error loading the product');
					console.log(ex);
				}
				finally {
					this.loading = false;
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	getDiscountedPrice(tier: PriceMatrix, tierDiscount: number) {
		let price = tier.pricePerThousand!;
		if (this.wlProduct?.markupType! === 'Dollars') {
			price += this.wlProduct?.markup!;
		}
		if (this.wlProduct?.markupType! === 'Percent') {
			let markupPct = this.wlProduct?.markup!;
			if (markupPct > 1) {
				markupPct = markupPct / 100;
			}
			price += (price * markupPct);
		}

		price -= tierDiscount;
		return price / 1000;
	}

	get f() {
		return this.wlProductForm.controls;
	}

	async updateWLProduct() {
		this.submitted = true;
		if (this.wlProductForm.invalid) {
			return;
		}

		try {
			this.loading = true;
			this.wlProduct = { ...this.wlProduct, ...this.wlProductForm.value };
			await this.sitesService.updateSiteProduct(this.wlProduct!);
			this.f.useBaseDesignFee.setValue(this.f.designFee.value === null || this.f.designFee.value === 0);
			this.toastService.showSuccess('Product updated successfully');
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError('Product could not be updated');
		}
		finally {
			this.loading = false;
		}
	}

	async updateDiscount(tier: PriceMatrix, discount: number) {

		try {
			this.loading = true;
			const tierDiscount = discount === null ? 0 : discount;
			await this.pricingService.updateTierDiscount(new PriceMatrixSiteMod({
				modPerThousand: tierDiscount,
				priceMatrixId: tier.priceMatrixId,
				priceModId: 0,
				siteId: this.wlProduct?.siteId!,
			}));
			this.toastService.showSuccess('Tier discount updated successfully');
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError('Tier discount could not be updated');
		}
		finally {
			this.loading = false;
		}
	}
}
