import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer, DownloadProjectAssetsRequest, GetCustomerProjectRequest, ListProjectsResponse } from '@taradel/admin-api-client';
import { DownloadAssetResponse } from '@taradel/web-api-client';
import { CustomerService } from 'services/customer.service';
import { DesignHuddleService } from 'services/design-huddle.service';
import { saveAs } from 'file-saver';
import { ToastService } from 'services/toast.service';
import { AuthenticationService } from 'services/authentication.service';


export interface Project {
	thumbnail: string;
	title: string;
	isSelected: boolean;
	projectId: string;
	sourceTemplateCode: string;
	height: number;
	width: number;
	createdDate: Date;
	lastEditedDate: Date;
}

export interface PageChange {
	page_id: string;
}

const ProjectsPerPage = 6;
declare const DSHDEditorLib: any;

@Component({
	selector: 'app-customer-designs',
	templateUrl: './customer-designs.component.html',
	styleUrls: ['./customer-designs.component.scss']
})
export class CustomerDesignsComponent implements OnInit {

	projectsList!: ListProjectsResponse;
	customerId = 0;
	customer!: Customer;
	loading = true;
	currentPage: number = 0;
	pageCount: number = 0;
	pageProjects: Project[] = [];
	newProjectsList: Project[] = [];
	searchTerm: string = "";
	token: string = "";
	allowEdit: boolean = false;
	showEditor: boolean = false;
	pageIds: string[] = [];
	currentPageId = '';
	editor: any;
	selectedProjectId: string = '';
	assetIds: string[] = [];
	assets: DownloadAssetResponse[] = [];
	isDesignTeam: boolean = false;

	@ViewChild('assetsModal') assetsModal!: ElementRef;

	constructor(
		private route: ActivatedRoute,
		private customerService: CustomerService,
		private designHuddleService: DesignHuddleService,
		private modalService: NgbModal,
		private toastService: ToastService,
		private authService: AuthenticationService) {

		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		this.selectedProjectId = route.snapshot.paramMap.get('projectId') ?? '';

	}

	async ngOnInit(): Promise<void> {
		try {
			this.isDesignTeam = await this.authService.hasRole('Design');
			this.customer = await this.customerService.getCustomer(this.customerId);
			await this.initializeAllProjects();

			if (this.selectedProjectId) {
				let p = this.newProjectsList.find(e => e.projectId === this.selectedProjectId)!;
				this.selectProject(p);
			}
		}
		catch {
			this.toastService.showError('Unable to initialize design huddle projects');
		}
		finally {
			this.loading = false;
		}
	}

	async getToken() {
		this.token = await this.designHuddleService.getCustomerUserToken(this.customerId);
	}


	async initializeAllProjects() {
		this.newProjectsList = [];
		this.projectsList = await this.designHuddleService.getCustomerProjects(new GetCustomerProjectRequest(
			{
				customerId: this.customerId,
				height: 0,
				width: 0,
				limit: 1000,
				page: 1,
				sourceTemplateId: 0,
				projectId: '',
				siteId: 0
			}
		));

		let projects = this.projectsList.data!.items!;
		projects.forEach(e => {
			this.newProjectsList.push({
				thumbnail: e.thumbnail_url!,
				title: e.project_title!,
				isSelected: false,
				projectId: e.project_id!,
				sourceTemplateCode: e.source_template?.source_template_code!,
				height: e.dimensions?.height!,
				width: e.dimensions?.width!,
				createdDate: e.date_created!,
				lastEditedDate: e.last_edited!
			}
			);
		});
		this.initializePageProjects();
	}

	initializePageProjects() {
		this.loading = true;
		this.pageProjects = [];
		this.pageCount = Math.ceil(this.newProjectsList.length / ProjectsPerPage);
		let start = 0;
		let end = 0;

		start = this.currentPage * ProjectsPerPage;
		end = (this.currentPage + 1) * ProjectsPerPage;
		if (end > this.newProjectsList.length) {
			end = this.newProjectsList.length;
		}
		for (let i = start; i < end; i++) {
			this.pageProjects.push(this.newProjectsList[i]);
		}
		this.loading = false;

	}

	nextPage() {
		this.currentPage++;
		this.initializePageProjects();
	}

	previousPage() {
		this.currentPage--;
		this.initializePageProjects();
	}

	projectClass(project: Project): string {
		return project.isSelected ? 'project-wrapper-selected projectWrapperSelected' : 'projectWrapper';
	}

	selectButtonProjectClass(project: Project): string {
		return project.isSelected ? ' btn-success' : 'btn-primary';
	}

	async selectProject(project: Project) {
		if (this.editor) {
			this.destroyDHFrame();
		}
		this.loading = true;
		await this.getToken();
		this.selectedProjectId = project.projectId;
		this.editor = DSHDEditorLib.insert('designHuddleFrame', {
			domain: 'taradel.designhuddle.com',
			access_token: this.token,
			project_id: project.projectId
		});

		this.pageIds = await this.getProjectData();
		this.currentPageId = this.pageIds[0];
		this.allowEdit = false;
		this.showEditor = true;
		this.loading = false;
	}

	destroyDHFrame() {
		this.editor.remove();
	}


	changePage() {
		let i = this.pageIds.findIndex(e => e === this.currentPageId);
		if (i + 1 >= this.pageIds.length) {
			this.currentPageId = this.pageIds[0];
		}
		else {
			this.currentPageId = this.pageIds[i + 1];
		}

		let cp: PageChange = {
			page_id: this.currentPageId
		};
		this.editor.changePage(cp);
	}

	getProjectData(): Promise<string[]> {
		return new Promise(
			(resolve, reject) => {
				this.editor.getProjectData({}, (err: any, data: any) => {
					if (err) {
						reject(err);
					}
					let page_ids: string[] = [];
					for (let page of data.pages) {
						page_ids.push(page.page_id);
					}
					resolve(page_ids);
				});
			}
		);
	}

	async getProjectAssets() {
		this.loading = true;
		this.assetIds = await this.returnAssets();
		this.assets = await this.designHuddleService.getProjectAssets(new DownloadProjectAssetsRequest({
			projectId: this.selectedProjectId,
			assetIds: this.assetIds
		}));
		this.loading = false;
		this.openAssetsModal();
	}

	returnAssets(): Promise<string[]> {
		return new Promise(
			(resolve, reject) => {
				this.editor.getProjectData({}, (err: any, data: any) => {
					if (err) {
						reject(err);
					}
					let asset_ids: string[] = [];
					for (let page of data.pages) {
						for (let e in page.elements) {
							let element = page.elements[e];
							if (element.asset_id) {
								asset_ids.push(element.asset_id.toString());
							}
						}
					}
					resolve(asset_ids);
				});
			}
		);
	}

	openAssetsModal() {
		this.modalService.open(this.assetsModal, {
			size: 'lg'
		});
	}

	closeModals() {
		this.modalService.dismissAll();
	}

	downloadAsset(asset: DownloadAssetResponse) {
		try {
			saveAs(asset.data?.download_url!, asset.data?.asset_id.toString());
		}
		catch (err) {
			this.toastService.showError(`Could not download file ${asset.data?.asset_id}`);
		}
	}

	async downloadAllAssets() {
		this.loading = true;
		const fileResponse = await this.designHuddleService.getProjectAssetsAsZip(new DownloadProjectAssetsRequest({
			projectId: this.selectedProjectId,
			assetIds: this.assetIds
		}));

		if (fileResponse) {
			const downloadURL = window.URL.createObjectURL(fileResponse?.data);
			const link = document.createElement('a');
			link.href = downloadURL;
			link.download = `${this.selectedProjectId}-assets.zip`;
			link.click();
		}
		this.loading = false;
	}
}
