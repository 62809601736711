import { Component } from '@angular/core';
import { AuthenticationService } from 'services/authentication.service';

@Component({
	selector: 'app-login-test',
	templateUrl: './login-test.component.html',
	styleUrls: ['./login-test.component.scss']
})
export class LoginTestComponent {

	constructor(private authService: AuthenticationService) { }

	login(): Promise<void> {
		return this.authService.login();
	}

}
