<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row mb-3">
		<div class="col">
			<div class="card mb-3"
				*ngFor="let drop of orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops">
				<div class="card-header"><b>Campaign Window {{drop.number }}</b><sup *ngIf="orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops!.length > 1"> of {{orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops!.length}}</sup>
					<div *ngIf="dropToBeFixed(drop.number + 1)">
						<span class="bg-warning">Drop needs to be updated</span>
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-xl-6 col-lg-12">
							<div class="form-floating style-dates">
								<input class="form-control" (dateSelect)="setStartDate(drop, $event)" [firstDayOfWeek]="7"
									[ngModel]="orderLayoutService.selectedOrderItemDropDateMap.get(drop.number)"
									[minDate]="allowEarlierDate ? earliestDate : todaysDate"
									name="dp" ngbDatepicker #sd="ngbDatepicker" readonly>
								<label [for]="'drop' + drop.number + 'startDate'">Start Date</label>
								<div class="d-flex justify-content-end" *ngIf="authService.organizationId === 1">
									<span class="fa fa-calendar" (click)="sd.toggle()"></span>
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-lg-12">
							<div class="form-floating style-dates">
								<input class="form-control" (dateSelect)="setEndDate(drop, $event)" [firstDayOfWeek]="7"
									[ngModel]="orderLayoutService.selectedOrderItemEndDateMap.get(drop.number)"
									[minDate]="allowEarlierDate ? earliestDate : todaysDate"
									name="dp" ngbDatepicker #ed="ngbDatepicker" disabled>
								<label [for]="'drop' + drop.number + 'endDate'">End Date</label>
							</div>
						</div>
					</div>
					<div class="row mb-3" *ngIf="authService.organizationId === 1">
						<div class="col">
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" name="allowEarlierDate" [(ngModel)]="allowEarlierDate">
								<label class="form-check-label">Allow Earlier Date Selection</label>
							</div>
						</div>
					</div>
					<div class="row" *ngIf="(orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId && orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId !== 4) || (orderLayoutService.selectedOrderItemAsCartProduct?.type && orderLayoutService.selectedOrderItemAsCartProduct?.type === 'Targeted Emails') && authService.organizationId === 1">
						<div class="col-md-6">
							<div class="form-floating mb-3">
								<input type="number"  class="form-control"
								[(ngModel)]="orderLayoutService.selectedOrderItemAsCartProduct!.quantity"
								(blur)="adjustEndDate(drop)" disabled/>
								<label>Number of Days</label>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-floating mb-3">
								<input type="text" class="form-control"
								[(ngModel)]="orderLayoutService.selectedOrderItemAsCartProduct!.budget" disabled/>
								<label>Daily Budget</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-floating mb-3">
								<input type="text" [ngModel]="drop.total | number" class="form-control" readonly disabled />
								<label>Estimated Impressions</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops && authService.organizationId === 1" class="row">
				<div class="col-md-4">
					<button class="btn btn-primary btn-sm ms-auto me-1" (click)="updateDropDates()" [disabled]="disableBtn">Update Drop Dates(s)</button>
				</div>
				<div class="col-md-4"></div>
				<div class="col-md-4"></div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
