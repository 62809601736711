import { Component, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { USelectMethod } from '@taradel/web-api-client';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-list-search',
  templateUrl: './custom-list-search.component.html',
  styleUrls: ['./custom-list-search.component.scss']
})
export class CustomListSearchComponent {
  valueSelected = false;

  @Input() array: any[] = [];
  @Output() selected = new EventEmitter<{id: number | undefined, name: string | undefined}>();

  @ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;
  @ViewChild('input') input!: ElementRef;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search: OperatorFunction<string, readonly USelectMethod[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => 
        term === '' ? this.array
        : this.array.filter(v => v.name!.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
      )
    );
  };

  formatter = (x: {name: string}) => x.name;

  select(event: any) {
    const returnedProduct = {
      id: event.item.productId, 
      name: event.item.name
    };
    this.valueSelected = true;
    this.selected.emit(returnedProduct);
  }

	resetInput() {
    this.valueSelected = false;
    this.input.nativeElement.value = '';
    const resetProduct = {
      id: undefined, 
      name: undefined
    };
    this.selected.emit(resetProduct);
	}
}
