<app-pagefullheader></app-pagefullheader>
<div class="card p-3 mb-3">
	<app-spinner-with-overlay [showSpinner]="loading">
        <div class="row">
            <div class="col-12 text-end">
                <button class="btn btn-danger mx-1" (click)="deleteAllInductionTurnaround()" type="button">
                    Remove All Induction Turnaround
                </button>
                <button class="btn btn-primary mx-1" (click)="updateAllInductionTurnaround()" type="button">
                    Update All Induction Turnaround
                </button>
            </div>
        </div>

		<div class="card border-0 p-3">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th width="10%">USelect Id</th>
						<th width="10%">USelect Name</th>
						<th>Standard Mail Turnaround Time</th>
						<th>First Class Turnaround Time</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let inductionTurnaround of inductionTurnarounds; let i = index;">
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="productUSelectId" min="1" class="form-control"
								[(ngModel)]="inductionTurnaround.uSelectId" disabled>
							</div>
						</td>
						<td width="30%">
							<div class="form-floating mb-3">
								<app-uselect-search  [uSelect]="getUSelectMethod(inductionTurnaround.uSelectId)" [array]="uSelectMethods" (selectedMethod)="getUpdateMethod($event, i)"></app-uselect-search>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="number" id="turnaround" class="form-control"
								[(ngModel)]="inductionTurnaround.standardMailTurnaround">
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="number" id="turnaround" class="form-control"
								[(ngModel)]="inductionTurnaround.firstClassTurnaround">
							</div>
						</td>
                        <td>
                            <li class="list-inline-item mt-3">
                                <button class="btn btn-danger btn-sm" (click)="removeSelectedInductionTurnaround(i)" data-toggle="tooltip"
                                    data-placement="top" Title="Delete">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </li>
                        </td>
					</tr>
					<tr *ngIf="newInductionTurnaround !== undefined">
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="productUSelectId" min="1" class="form-control"
								[(ngModel)]="newInductionTurnaround!.uSelectId" disabled>
							</div>
						</td>
						<td width="25%">
							<div class="form-floating mb-3">
								<app-uselect-search  [uSelect]="getUSelectMethod(newInductionTurnaround!.uSelectId)" [array]="uSelectMethods" (selectedMethod)="getMethod($event)"></app-uselect-search>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="number" id="turnaround" class="form-control"
								[(ngModel)]="newInductionTurnaround!.standardMailTurnaround">
							</div>
						</td>

						<td>
							<div class="form-floating mb-3">
								<input type="number" id="turnaround" class="form-control"
								[(ngModel)]="newInductionTurnaround!.firstClassTurnaround">
							</div>
						</td>
						<td>
							<ul class="list-inline">
								<li class="list-inline-item  mt-3">
									<button class="btn btn-success" (click)="addNewInductionTurnaround()"
									[disabled]="(newInductionTurnaround!.uSelectId === 0)">Add</button>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-spinner-with-overlay>
</div>

