<app-customer-header [customerId]="customerId"></app-customer-header>

<app-spinner-with-overlay [showSpinner]="showSpinner">
	<div class="container py-4">
		<div slideContent *ngIf="hasOrders">
			<div class="row">
				<div class="col-lg-5">
					<div class="col-sm-5 col-md-6 pt-2">
						<select class="form-select" (change)="siteChanged($any($event).target.value)">
							<option *ngFor="let organization of organizations" [value]="organization.organizationId">{{organization.organizationId}} - {{organization.name ?? ''}}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-lg-5 py-2">
					<div class="input-group">
						<span class="input-group-text">
							<span class="fas fa-search"></span>
						</span>
						<input type="number" #orderIdInput class="form-control" placeholder="Search orders by order number" aria-label="Search orders by order number" (keyup)="orderIdSearch.next(orderIdInput.value)" />
						<button *ngIf="orderIdInput.value.length > 0" class="btn" (click)="clearFilter()" type="button"><span class="far fa-times-circle"></span></button>
					</div>
				</div>
				<div class="col-12 col-lg-7">
					<div class="row">
						<div class="col-sm-7 col-md-6 pt-2">
							<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="3" [pageSize]="query.pagination!.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged()" class="m-auto m-lg-0">
							</ngb-pagination>
						</div>
						<div class="col-sm-5 col-md-6 pt-2">
							<select class="form-select" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()">
								<option [ngValue]="10">Show 10 items</option>
								<option [ngValue]="20">Show 20 items</option>
								<option [ngValue]="50">Show 50 items</option>
								<option [ngValue]="100">Show 100 items</option>
							</select>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12" *ngFor="let order of pageOrders">
					<div class="card mt-3">
						<div class="card-header d-none d-md-block">
							<div class="row align-items-center">
								<div class="col-auto text-center">
									<div><small>ORDER DATE</small></div>
									<div>{{order.created | date : 'M/d/yyyy'}}</div>
								</div>
								<div class="col-auto text-center">
									<div><small>ORDER AMOUNT</small></div>
									<div>{{order.orderAmt| currency}}</div>
								</div>
								<div class="col-auto text-center">
									<div><small>BALANCE AMOUNT</small></div>
									<div>{{order.balanceAmt| currency}}</div>
								</div>
								<div class="col me-auto">
									<button class="btn btn-default btn-sm" (click)="getPdf(order.orderId)">Order
										Receipt</button>
								</div>
								<div class="col text-center" *ngIf="order.orderItemDetails">
									<div>Distribution Name:</div>
									<div>{{order.orderItemDetails[0].jobName!}}</div>
								</div>
								<div class="col text-end">
									Order # <span class="fw-bold lead">{{ order.orderId }}</span>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="row d-md-none align-items-center border-bottom mb-2">
								<div class="col me-auto">
									<b>Order # {{ order.orderId }}</b>
								</div>
								<div class="col-auto text-right">
									{{order.created | date : 'M/d/yyyy'}}
								</div>
							</div>
							<div class="row" *ngIf="order.orderItemDetails!.length > 0">
								<div class="col">
									<div class="mb-3">
										Order Items:
										<ul>
											<li *ngFor="let item of order.orderItemDetails">
												<div class="row">
													<div class="col">
														{{item.jobName}} - {{item.name}}<span *ngIf="!!item.deliveryDates && item.deliveryDates.length"> {{getDropDate(item.deliveryDates)}}</span>
													</div>
												</div>
											</li>
										</ul>
									</div>

									<div class="d-grid gap-2 d-sm-block">
										<button class="btn btn-primary d-md-none m-0 mb-2 mx-2" (click)="getPdf(order.orderId)"><i class="fas fa-download"></i>
											Order Receipt</button>
										<a class="btn btn-success m-0 mb-2 mx-2" [routerLink]="['/orders', order.orderId, 'attribution-dashboard']" title="Status & Performance">
											<i class="fas fa-chart-bar"></i> Status & Performance</a>
										<a class="btn btn-default m-0 mb-2 mx-2" *ngIf="order.created > reorderAllowedDate" [routerLink]="reOrderLink(order)" title="Reorder">
											<i class="fas fa-shopping-cart"></i> Reorder
										</a>
										<button class="btn btn-default m-0 mb-2 mx-2" *ngIf="order.isPurchasedList" (click)="downloadList(order.orderId)" title="Download List">
											<i class="fas fa-download"></i> Download List</button>
									</div>
								</div>
								<div class="col-2">
									<ngb-carousel [interval]="0" *ngIf="(this.proofs.get(order.orderId)?.length ?? 0) > 0">
										<ng-template ngbSlide *ngFor="let proof of this.proofs.get(order.orderId); let i = index" id="{{i}}">
											<div class="wrapper text-center">
												<a [href]="proof.url" target="_blank">
													<img height="140" [src]="proof.url" alt="{{proof.name}}">
												</a>
											</div>
											<div class="text-center">
												<span>{{proof.name}}</span>
											</div>
										</ng-template>
									</ngb-carousel>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="pageOrders.length === 0" class="alert alert-info row" role="alert">
				<div class="col-auto m-auto">
					You don't have any orders that match that order number
				</div>
			</div>
		</div>

		<div slideContent *ngIf="!showSpinner && !hasOrders" class="alert alert-info row" role="alert">
			<div class="col-auto m-auto">
				You have no completed orders at this time.
			</div>
		</div>

	</div>
</app-spinner-with-overlay>
