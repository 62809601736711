import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from 'services/customer.service';
import { Customer } from '@taradel/web-api-client';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-customer-header',
	templateUrl: './customer-header.component.html',
	styleUrls: ['./customer-header.component.scss']
})
export class CustomerHeaderComponent implements OnInit {
	@Input() customerId!: number;
	customer!: Customer;

	constructor(
		private customerService: CustomerService,
		private toastService: ToastService
		) { }

	async ngOnInit(): Promise<void> {
		try {
			// get customer data
			if (this.customerId > 0) {
				this.customer = await this.customerService.getCustomer(this.customerId);
			}
		}
		catch {
			this.toastService.showError('There was an error getting the customer');
		}
    }
}
