import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'services/reports.service';
import { SalesIndicators } from '@taradel/admin-api-client';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-sales-indicators-report',
	templateUrl: './sales-indicators-report.component.html',
	styleUrls: ['./sales-indicators-report.component.scss']
})
export class SalesIndicatorsReportComponent implements OnInit {
	salesIndicators: SalesIndicators = new SalesIndicators;
	sitesToExclude: number[] = [];
	loading = false;

	constructor(
		private reportsApiService: ReportsService,
		private toastService: ToastService
	) { }

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;
			this.salesIndicators = await this.reportsApiService.getSalesIndicators(this.sitesToExclude);
		}
		catch {
			this.toastService.showError('There was an error loading the Sales Indicators report');
		}
		finally {
			this.loading = false;
		}
	}

}
