
<app-customer-header [customerId]="customerId"></app-customer-header>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row p-2">
		<div class="col">
			<div class="form-check form-switch">
				<input class="form-check-input" type="checkbox" role="switch" id="includePastDrops" [(ngModel)]="includePastDrops" (ngModelChange)="filterCustomerDrops()" />
				<label class="form-check-label" for="includePastDrops"><strong>Show Past Drops</strong></label>
			</div>
		</div>
		<div class="col text-end">
			<button class="btn btn-primary" (click)="openChangeDropsModal()">Update Drops</button>
		</div>
	</div>
	<ngb-pagination [collectionSize]="total" [(page)]="paginationQuery.pageNo" [maxSize]="10"
		[pageSize]="paginationQuery.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
		(pageChange)="getCustomerDrops()"></ngb-pagination>
	<div class="table-responsive" *ngIf="customerDrops.length !== 0" >
		<table class="table table-striped">
			<thead>
				<tr>
					<th>
						<div class="form-check">
							<input type="checkbox" id="selectAll" class="form-check-input" (change)="selectAllDrops()" />
						</div>
					</th>
					<th>Order Id</th>
					<th>Order Created</th>
					<th>OrderItem Id</th>
					<th>Product Name</th>
					<th>Drop number</th>
					<th>Distribution Name</th>
					<th>Drop/Start Date</th>
					<th>End Date</th>
				</tr>
			</thead>
			<tbody>
				
				<tr *ngFor="let drop of customerDrops">
					<td>
						<div class="form-check">
							<input type="checkbox" [id]="drop.orderItemId + '-' + drop.dropNumber" 
							[checked]="isSelected(drop)" class="form-check-input" (change)="selectDrop(drop)" />
						</div>
					</td>
					<td>{{ drop.orderId }}</td>
					<td>{{ drop.created | date }}</td>
					<td>{{ drop.orderItemId }}</td>
					<td>{{ drop.productName }}</td>
					<td>{{ drop.dropNumber }}</td>
					<td>{{ drop.distributionName }}</td>
					<td>{{ drop.dropDate | date }}</td>
					<td>{{ drop.endDate | date }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<ng-container *ngIf="customerDrops.length === 0 && !loading">
		<app-alert-box alertMode="info">
			No customer drops found.
		</app-alert-box>
	</ng-container>
</app-spinner-with-overlay>

<ng-template #changeDropsModal let-modal>
	<app-confirm-modal title="Change Drops Wizard" confirmText="proceed" (confirmAction)="updateCustomerDrops()"
		(closeModal)="closeModal();reset();">
		<ng-container>
			<div class="row">
				<div class="col-3">
					<div class="form-floating">
						<input type="date" name="changeDropToDate" class="form-control"
						[(ngModel)]="dropDate" />
						<label for="changeDropToDate">Push all drop dates to</label>
					</div>
				 
				</div>
				<div class="col-auto"><h4 class="mt-3">OR</h4></div>
				<div class="col-3">
					<div class="form-floating">
						<input type="number" name="pushDropByDays" class="form-control"
						[(ngModel)]="daysToPushDropDateBy" />
						<label for="pushDropByDays">Push all drop dates by (days)</label>
					</div>
				</div>
			</div>
			<div class="row mt-2" *ngIf="hasEndDate">
				<div class="col-3">
					<div class="form-floating">
						<input type="date" name="changeEndDateToDate" class="form-control"
						[(ngModel)]="endDate" />
						<label for="changeEndDateToDate">Push all end dates to</label>
					</div>				  
				</div>
				<div class="col-auto"><h4 class="mt-3">OR</h4></div>
				<div class="col-3">
					<div class="form-floating">
						<input type="number" name="pushEndDateByDays" class="form-control"
						[(ngModel)]="daysToPushEndDateBy" />
						<label for="pushEndDateByDays">Push all end dates by (days)</label>
					</div>
				</div>
			</div>
			<h5 *ngIf="showError" class="input-error mt-2">Please select either the date or specify # of days the drop date needs to be pushed by.</h5>
			<button class="btn btn-primary my-2" (click)="reset()">Reset form</button>
			<app-alert-box *ngIf="selectedDrops.length === 0 && !loading" alertMode="info">
				Please select a drop in the main form to proceed.
			</app-alert-box>
			<div class="mt-2" *ngIf="selectedDrops.length > 0">
				<h3>Following drops have been selected to be updated:</h3>
				<table class="table table-striped">
					<thead>
						<tr>
							<th width="10%"><small>Order Item Id</small></th>
							<th width="10%"><small>Drop Number</small></th>
							<th width="10%"><small>Drop Dt</small></th>
							<th width="10%"><small>New Drop Dt</small></th>
							<th width="10%" *ngIf="hasEndDate"><small>End Dt</small></th>
							<th width="10%" *ngIf="hasEndDate"><small>New End Dt</small></th>
							<th width="40%"><small>Note</small></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let drop of selectedDrops">
							<td>{{drop.orderItemId}}</td>
							<td>{{ drop.dropNumber }}</td>
							<td>{{ drop.dropDate | date }}</td>
							<td>{{ (dropDate !== undefined ? dropDate : addDaysToDate(drop.dropDate, daysToPushDropDateBy) )| date }}</td>
							<td *ngIf="hasEndDate">{{ drop.endDate | date }}</td>
							<td *ngIf="hasEndDate">{{ (endDate !== undefined ? endDate : addDaysToDate(drop.endDate!, daysToPushEndDateBy) )| date }}</td>
							<td><span class="bg-warning">{{(drop.hasPO ? 'Warning: This order item has already been sent to the printer. Kindly communicate these changes with the printer' : '')}}</span></td>
						</tr>
					</tbody>
				</table>
			</div>
		</ng-container>
	</app-confirm-modal>
</ng-template>