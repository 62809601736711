import { Component, OnInit } from '@angular/core';
import { IdentityService } from 'services/identity.service';
import { IdentityRole } from '@taradel/admin-api-client';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-roles',
	templateUrl: './roles.component.html',
	styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

	loading = true;
	roles: IdentityRole[] = [];
	newRoleName = '';

	constructor(
		private identityService: IdentityService,
		private toastService: ToastService) { }

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;
			this.roles = await this.identityService.getRoles();
		}
		catch {
			this.toastService.showError('There was an error loading the roles');
		}
		finally {
			this.loading = false;
		}

	}

	async createRole(): Promise<void> {
		if (this.newRoleName !== '') {
			try {
				this.loading = true;
				await this.identityService.createRole(this.newRoleName);
				this.roles.push(new IdentityRole({ name: this.newRoleName }));
				this.newRoleName = '';
				this.toastService.showSuccess('Role was created successfully');
			}
			catch {
				this.toastService.showError('There was an error creating the role');
			}
			finally {
				this.loading = false;
			}
		}
	}

}
