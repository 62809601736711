import { Provider } from '@angular/core';
import { popperVariation, provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';
import { followCursor } from 'tippy.js';

export namespace TippyIntegration {
	export const tooltipVariationLightTheme = Object.assign({}, tooltipVariation, {
		theme: 'light',
		interactive: false,
	}) as typeof tooltipVariation;

	export const tooltipVariationLightThemeWithArrow = Object.assign({}, tooltipVariationLightTheme, {
		arrow: true,
	}) as typeof tooltipVariation;

	export const popperVariationLargeOffset = Object.assign({}, popperVariation, {
		interactive: false,
		offset: [0, 90],
	}) as typeof tooltipVariation;

	export const popperVariationFollowCursor = Object.assign({}, popperVariation, {
		followCursor: true,
		interactive: false,
		offset: [0, 90],
	}) as typeof tooltipVariation;

	export function getTippyConfig(): Provider {
		return provideTippyConfig({
			defaultVariation: 'tooltip',
			plugins: [
				followCursor,
			],
			variations: {
			  tooltip: TippyIntegration.tooltipVariationLightTheme,
			  tooltipWithArrow: TippyIntegration.tooltipVariationLightThemeWithArrow,
			  tooltipDark: tooltipVariation,
			  popper: popperVariation,
			  popperLargeOffset: popperVariationLargeOffset,
			  popperFollowCursor: popperVariationFollowCursor,
			},
			// moveTransition: 'transform 0.2s ease-out',
		});
	}
}
