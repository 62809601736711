import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QRCodeMetaRequest, UpdateClickThroughURLQuery } from '@taradel/admin-api-client';
import { USelect } from 'services/distributions.service';
import { OrderlayoutService } from 'services/orderlayout.service';
import { OrderService, QRCodePhoneNumber, QRCodeWebLink } from 'services/order.service';
import { ToastService } from 'services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'services/authentication.service';

@Component({
	selector: 'app-order-item-delivery',
	templateUrl: './order-item-delivery.component.html',
	styleUrls: ['./order-item-delivery.component.scss']
})
export class OrderItemDeliveryComponent implements OnInit {
	submitted: boolean = false;
	loading = false;
	digitalType: string = '';
	USelect = USelect;
	urlQuery?: UpdateClickThroughURLQuery;
	qrCodeValue?: string;
	qrCodePlaceholder = '';
	QRCodePhoneNumber = QRCodePhoneNumber;
	QRCodeWebLink = QRCodeWebLink;
	qrCodeInvalidMessage = '';
	orderId?: number;
	orderItemId?: number;
	numberOfDrops = 1;
	weekBetweenDrops = 1;

	@Input() countryCode: 'US' | 'Canada' = 'US';
	@Input() qrCodeMetaInfo?: { qrCodeMetaType: string, qrCodeMetaValue: string };

	constructor(private route: ActivatedRoute,
		public modalService: NgbModal,
		public authService: AuthenticationService,
		private orderService: OrderService,
		private toastService: ToastService,
		public orderLayoutService: OrderlayoutService) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(async params => {
			// clear any poForm values on route change
			this.submitted = false;
			this.orderId = parseInt(params.get('orderId') ?? '0', 10);
			this.orderItemId = parseInt(params.get('orderItemId') ?? '0', 10);
			if (this.orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId === USelect.displayAds) {
				if ((!!this.urlQuery && this.urlQuery.orderId !== this.orderId) || (!!this.urlQuery && this.urlQuery?.orderItemId !== this.orderItemId)) {
					this.urlQuery = undefined;
				}
				await this.getURL();
			}
			if (this.orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId === USelect.qrCode && this.qrCodeMetaInfo) {
				this.qrCodeValue = await this.getQRCodeOrderMeta();
				if (this.qrCodeMetaInfo.qrCodeMetaType === QRCodePhoneNumber) {
					this.qrCodePlaceholder = 'Phone Number';
				}
				else {
					this.qrCodePlaceholder = 'Web Link';
				}
			}
		});
	}

	setReturnAddress(addressId: number): void {
		this.orderLayoutService.selectedOrderItemAsCartProduct!.drops!.returnAddressId = addressId;
	}

	getMailingPrinter(): string {
		return "Not Set";
	}

	isEddmDrops() {
		let ret = false;
		const selectedItem = this.orderLayoutService.selectedOrderItemAsCartProduct;
		const drops = selectedItem?.drops;
		if (drops && (drops.type?.toLowerCase() === 'eddm' || (selectedItem?.uSelectId ?? 0) === USelect.eddm)) {
			const multiple = drops.orderDrops?.filter(d => d.multiple && d.multiple > 1);
			ret = (!multiple || multiple.length === 0);
		}

		return ret;
	}

	isEddmImpressions() {
		let ret = false;

		const selectedItem = this.orderLayoutService.selectedOrderItemAsCartProduct;
		const drops = selectedItem?.drops;
		if (drops && (drops.type?.toLowerCase() === 'eddm' || (selectedItem?.uSelectId ?? 0) === USelect.eddm)) {
			const multiple = drops.orderDrops?.filter(d => d.multiple && d.multiple > 1);
			ret = (multiple !== undefined && multiple.length > 0);
		}

		return ret;
	}
	isSnapDrops() {
		let ret = false;
		const selectedItem = this.orderLayoutService.selectedOrderItemAsCartProduct;
		const drops = selectedItem?.drops;
		if (drops && (drops.type?.toLowerCase() === 'snapadmail' || (selectedItem?.uSelectId ?? 0) === USelect.snapAdmail)) {
			const multiple = drops.orderDrops?.filter(d => d.multiple && d.multiple > 1);
			ret = (!multiple || multiple.length === 0);
		}

		return ret;
	}

	isSnapImpressions() {
		let ret = false;

		const selectedItem = this.orderLayoutService.selectedOrderItemAsCartProduct;
		const drops = selectedItem?.drops;
		if (drops && (drops.type?.toLowerCase() === 'snapadmail' || (selectedItem?.uSelectId ?? 0) === USelect.snapAdmail)) {
			const multiple = drops.orderDrops?.filter(d => d.multiple && d.multiple > 1);
			ret = (multiple !== undefined && multiple.length > 0);
		}

		return ret;
	}

	isDigital() {
		let ret = false;
		const digitalId = new Map([
			['facebook ads', USelect.facebookAds],
			['targeted emails', USelect.targetedEmail],
			['display ads', USelect.displayAds],
			['linkedin ads', USelect.linkedinAds],
			['instagram', USelect.instagram],
			['spotify', USelect.spotify],
			['hulu', USelect.hulu],
			['qr code', USelect.qrCode],
			['tik tok', USelect.tiktok],
			['nextdoor ads', USelect.nextdoorAds]
		]);
		// legacy 'type' handling
		const cartProductType = this.orderLayoutService.selectedOrderItemAsCartProduct?.type?.toLowerCase();
		if (cartProductType) {
			const digitalItem = digitalId.get(cartProductType);
			if (digitalItem) {
				this.digitalType = cartProductType;
				return true;
			}
		}

		// orders 2.0 uselectId handling
		const cartProductUselect = this.orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId as USelect;
		ret = cartProductUselect && USelect.IsDigital(cartProductUselect);
		if (ret) {
			// get the dumb name for backwards stuff
			const foundName = [...digitalId.entries()]
				.find(({ 1: v}) => v === cartProductUselect);
				if (foundName) {
					this.digitalType = foundName[0];
				}
		}

		return ret;
	}

	isPersonalizedMail() {
		let ret = false;
		const addressedId = new Map([
			['addressedlist', USelect.buildYourList],
			['equifaxb2b', USelect.equifaxB2B],
			['b2b', USelect.b2B]
		]);
		// legacy 'type' handling
		const cartProductType = this.orderLayoutService.selectedOrderItemAsCartProduct?.type?.toLowerCase();
		if (cartProductType) {
			const addressedItem = addressedId.get(cartProductType);
			if (addressedItem) {
				return true;
			}
		}
		// orders 2.0 uselectId handling
		const cartProductUselect = this.orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId as USelect;
		return cartProductUselect && USelect.IsAddressedMail(cartProductUselect);
	}

	stateSelected(value: string) {
		this.orderLayoutService.selectedOrderItemAsCartProduct!.drops!.returnState = value;
	}

	async getURL() {
		if (!this.orderId || !this.orderItemId) {
			return;
		}
		this.urlQuery = await this.orderService.getClickThroughURL(this.orderId, this.orderItemId);
	}

	async updateURL() {
		let success = true;
		if (!this.urlQuery || !this.orderId || !this.orderItemId) {
			return;
		}
		this.loading = true;
		try {
			await this.orderService.updateClickThroughURL(this.urlQuery);
		}
		catch (error) {
			success = false;
			console.log(error);
			this.toastService.showError('Display Ad Click-through URL could not be updated');
		}
		finally {
			this.loading = false;
			if (success) {
				this.toastService.showSuccess('Display Ad Click-through URL successfully updated');
				await this.getURL();
			}
		}
	}
	async splitDrops() {
		this.loading = true;
		try {
			this.modalService.dismissAll();
			await this.orderService.splitOrderItem(this.orderLayoutService.selectedCustomer?.customerID!, this.orderItemId!, this.numberOfDrops, this.weekBetweenDrops);
			await this.orderLayoutService.loadNewOrderItemData(this.orderItemId!, true);
			this.toastService.showSuccess('Order Item has been split successfully');
		}
		catch (error: any) {
			if (error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError('Order Item split failed');
			}
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	formatPhoneNumber(inputValue: string) {
		let temp = inputValue.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		if (temp) {
			inputValue = !temp[2] ? temp[1] : '(' + temp[1] + ') ' + temp[2] + (temp[3] ? '-' + temp[3] : '');
			this.qrCodeValue = inputValue;
		}
	}

	urlValid(inputUrl: string): boolean {
		let validUrl;
		try {
			validUrl = new URL(inputUrl);
			this.qrCodeInvalidMessage = '';
			return true;
		}
		catch (error) {
			this.qrCodeInvalidMessage = 'URL is required, and must be valid; ex. https://yourdomain.com/landing-page';
			return false;
		}
	}

	async getQRCodeOrderMeta(): Promise<string> {
		const request = {
			codeValue: '',
			codeType: this.qrCodeMetaInfo?.qrCodeMetaType
		} as QRCodeMetaRequest;
		let value = await this.orderService.getQRCodeOrderMeta(request, this.orderId!, this.orderItemId!);
		if (!(!!value && value !== '')) {
			const codeType = this.qrCodeMetaInfo?.qrCodeMetaType === QRCodeWebLink ? 'WebLink' : 'PhoneNumber';
			value = this.orderLayoutService.selectedOrderItemAsCartProduct!.meta![codeType];
		}
		return value;
	}

	async updateQRCodeOrderMeta() {
		this.qrCodeInvalidMessage = '';
		if (this.qrCodeMetaInfo?.qrCodeMetaType === QRCodePhoneNumber && this.qrCodeValue?.length !== 14) {
			this.qrCodeInvalidMessage = 'Phone number is invalid';
			return;
		}
		else if (this.qrCodeMetaInfo?.qrCodeMetaType === QRCodeWebLink) {
			const validUrl = this.urlValid(this.qrCodeValue!);
			if (!validUrl) {
				return;
			}
		}
		this.loading = true;
		const request = {
			codeValue: this.qrCodeValue,
			codeType: this.qrCodeMetaInfo?.qrCodeMetaType
		} as QRCodeMetaRequest;
		try {
			this.qrCodeValue = await this.orderService.updateQRCodeOrderMeta(request, this.orderId!, this.orderItemId!);
			this.toastService.showSuccess('QR Code value was updated');
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError('QR Code value could not be updated');
		}
		finally {
			this.loading = false;
		}
	}
}
