<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row mb-3">
		<div class="col-md-6">
			<div class="card p-3">
				<div class="card-body">
					<h5 class="card-title">Edit Option Category</h5>
					<form [formGroup]="categoryForm">
						<fieldset>
							<div class="form-floating mb-3">
								<input type="text" formControlName='name' id="name" class="form-control" maxlength="50" required
									[ngClass]="{ 'is-invalid': submitted && categoryControls.name.errors }" />
								<label for="name">Name</label>
							</div>
							<div class="form-floating mb-3">
								<input formControlName='description' class="form-control" id="description">
								<label for="description">Description</label>
							</div>
							<div class="form-floating mb-3">
								<select formControlName='displayType' class="form-select" id="displayType">
									<option value="null" selected>Choose here</option>
									<option value="DropDown">Drop Down</option>
									<option value="HorizontalRadio">Horizontal Radio Group</option>
									<option value="VerticalRadioWPreview">Vertical Radio with Preview Image</option>
									</select>
								<label for="displayType">Display Type</label>
							</div>
						</fieldset>
					</form>
				</div>
				<div class="card-body">
					<button class="btn btn-primary btn-lg" (click)="updateCategory()">Save</button>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
