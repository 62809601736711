<app-pagefullheader></app-pagefullheader>
<div class="card border-0 p-3">
	<div class="card mb-3">
		<div class="row mb-3 ms-auto">
			<a href="#" class="btn btn-success btn-rounded px-4 rounded-pill" [routerLink]="['add']"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Category Option</a>
		</div>
	</div>
	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="table-responsive">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Option Id</th>
						<th>Name</th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let categoryOption of productOptionCategory?.options">
						<td>{{ categoryOption.optionId }}</td>
						<td>{{ categoryOption.name }}</td>
						<td>{{ categoryOption.description }}</td>
						<td>
							<a type="button" class="btn btn-primary" [routerLink]="['/option-categories', categoryOption.optCatId, 'options', categoryOption.optionId]">Edit</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-spinner-with-overlay>
</div>
