<app-spinner-with-overlay [showSpinner]="loading">
	<div *ngFor="let optCat of updatedProdOptCats">
		<strong>
			{{ optCat.name }}
		</strong>
		<ng-container *ngIf="optCat.hasSelectedOption; else showOptionDropdown">
			<app-pcog-option [option]="optCat.selectedOption!" (removeOption)="handleRemovingOption($event)"></app-pcog-option>
		</ng-container>
		<ng-template #showOptionDropdown>
			<app-pcog-options-dropdown [productOptions]="optCat.options!" (optionSelected)="handleOptionSelected($event)"></app-pcog-options-dropdown>
		</ng-template>
	</div>
</app-spinner-with-overlay>
