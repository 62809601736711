import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { USelectDesignType } from '@taradel/web-api-client';
import { Subscription } from 'rxjs';
import { DetailedUSelectDesignOption, SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

export class USelectDetail {
	id!: number;
	name: string | undefined;
}

export class  DesignUSelects {
	uSelect!: USelectDetail;
	siteId!: number;
	designTypes: USelectDesignType[] = [];
}


@Component({
	selector: 'app-site-uselect-designs',
	templateUrl: './site-uselect-designs.component.html',
	styleUrls: ['./site-uselect-designs.component.scss']
})
export class SiteUselectDesignsComponent implements OnInit, OnDestroy {

	detailedUSelectDesignOptions: DetailedUSelectDesignOption[] = [];
	uSelectIdsList: USelectDetail[] = [];
	designUSelects: DesignUSelects[] = [];
	siteId: number = 0;
	loading: boolean = false;
	currentSite$ = new Subscription();

	@ViewChild('uSelectIdDropdown') uSelectIdDropdown!: ElementRef;

	constructor(
		private sitesService: SitesService,
		private router: Router,
		private toastService: ToastService
	) { }

	ngOnInit(): void {
	  this.loading = true;
	  this.currentSite$ = this.sitesService.currentSite.subscribe(async site => {
		if (site) {
			try {
				this.siteId = site!.siteId;
				await this.getSiteUSelectDesigns(site!.siteId);
			}
			catch {
				this.toastService.showError('There was an error getting site USelect designs');
			}
			finally {
				this.loading = false;
			}
		}
	  });
	}

	ngOnDestroy(): void {
	  	this.currentSite$.unsubscribe();
	}

	async getSiteUSelectDesigns(siteId: number): Promise<void> {
		const siteDesignOptions = await this.sitesService.getSiteDesignOptions(siteId);
		this.detailedUSelectDesignOptions = await this.sitesService.setListOfSiteUSelectDesignOptions(siteDesignOptions);
		this.getArrayOfUSelectIds();
	}

	getArrayOfUSelectIds() {
		this.designUSelects = [];
		this.uSelectIdsList = [];
		this.uSelectIdDropdown.nativeElement.value = 'Filter by USelect ID';
		this.detailedUSelectDesignOptions.forEach(designOption => {
			this.uSelectIdsList.push({id: designOption.uSelectId, name: designOption.uSelectName});
		});
		this.uSelectIdsList = this.uSelectIdsList.filter((v, i, s) => i === s.findIndex(t => t.id === v.id && t.name === v.name));
		this.uSelectIdsList = this.uSelectIdsList.sort((a, b) => (a.id < b.id) ? -1 : 1);
		this.uSelectIdsList.forEach(uSelect => {
			const uSelectDesignOptions = this.detailedUSelectDesignOptions.filter(a => a.uSelectId === uSelect.id);
			const designTypes: USelectDesignType[] = [];
			let siteId = 0;
			uSelectDesignOptions.forEach(x => {
				siteId= x.siteId;
				designTypes.push(x.productDesignTypeId);
			});
			this.designUSelects.push({
				uSelect: uSelect,
				siteId: siteId,
				designTypes: designTypes
			});
	  });
	}

	routeToEditUSelect(uSelectId: number) {
	  	this.router.navigateByUrl(`sites/${this.siteId}/uselect-designs/${uSelectId}`);
	}
}
