import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerPaymentAttemptDisplay, PaymentClient, CustomerPaymentDisplay, CustomerPaymentsQuery, PaymentAttemptsQuery,
	AdminPaymentRequest, PartialRefundRequest, RecurringPaymentsRequest, FinancePayment, CustomerBalance,
	RecurringPaymentDisplay, RecurringPaymentStatus, UpdateRecurringPayment, ScheduledPaymentsQuery, ScheduledPaymentDisplay,
	UpdateScheduledPayment, RefundPaymentRequest, FileResponse,
	VoidRefundRequest} from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

	private readonly paymentsClient: PaymentClient;

	constructor(http: HttpClient) {
	  this.paymentsClient = new PaymentClient(http, environment.adminApiUrl);
	}

	getCustomerPaymentsCount(query: CustomerPaymentsQuery): Promise<number> {
		return lastValueFrom(this.paymentsClient.getCustomerPaymentsCount(query));
	}

	getCustomerPayments(query: CustomerPaymentsQuery): Promise<CustomerPaymentDisplay[]> {
		return lastValueFrom(this.paymentsClient.getFilteredCustomerPayments(query));
	}

	getPaymentAttemptsCount(query: PaymentAttemptsQuery): Promise<number> {
		return lastValueFrom(this.paymentsClient.getPaymentAttemptsCount(query));
	}

	getPaymentAttempts(query: PaymentAttemptsQuery): Promise<CustomerPaymentAttemptDisplay[]> {
		return lastValueFrom(this.paymentsClient.getPaymentAttempts(query));
	}

	getCustomerPaymentAttemptsCount(customerId: number, query: PaymentAttemptsQuery): Promise<number> {
		return lastValueFrom(this.paymentsClient.getCustomerPaymentAttemptsCount(customerId, query));
	}

	getCustomerPaymentAttempts(customerId: number, query: PaymentAttemptsQuery): Promise<CustomerPaymentAttemptDisplay[]> {
		return lastValueFrom(this.paymentsClient.getCustomerPaymentAttempts(customerId, query));
	}

	async getCustomerBalance(customerId: number): Promise<CustomerBalance[]> {
		return await lastValueFrom(this.paymentsClient.getCustomerBalance(customerId));
	}

	async getCustomerPayment(customerPaymentId: number): Promise<CustomerPaymentDisplay> {
		return await lastValueFrom(this.paymentsClient.getCustomerPayment(customerPaymentId));
	}

	async getPaymentAttempt(customerPaymentAttemptId: number): Promise<CustomerPaymentAttemptDisplay> {
		return await lastValueFrom(this.paymentsClient.getPaymentAttemptDisplay(customerPaymentAttemptId));
	}

	createCustomerPayment(request: AdminPaymentRequest): Promise<number> {
		return lastValueFrom(this.paymentsClient.createCustomerPayment(request));
	}

	getPaymentSchedule(request: RecurringPaymentsRequest): Promise<FinancePayment[]> {
		return lastValueFrom(this.paymentsClient.generatePaymentSchedule(request));
	}

	createRecurringPayment(request: RecurringPaymentsRequest): Promise<number> {
		return lastValueFrom(this.paymentsClient.createRecurringPayment(request));
	}

	refundCustomerPayment(request: RefundPaymentRequest): Promise<FileResponse | null> {
		return lastValueFrom(this.paymentsClient.refundCustomerPayment(request));
	}

	async voidCustomerPaymentRefund(request: VoidRefundRequest): Promise<FileResponse | null> {
		return await lastValueFrom(this.paymentsClient.voidCustomerPaymentRefund(request));
	}

	async fullRefund(customerPaymentId: number): Promise<void> {
		await lastValueFrom(this.paymentsClient.fullRefund(customerPaymentId));
	}

	async partialRefund(request: PartialRefundRequest): Promise<void> {
		await lastValueFrom(this.paymentsClient.partialRefund(request));
	}

	async getRecurringPayments(customerId: number): Promise<RecurringPaymentDisplay[]> {
		return await lastValueFrom(this.paymentsClient.getRecurringPayments(customerId));
	}

	async getScheduledPaymentsCount(query: ScheduledPaymentsQuery): Promise<number> {
		return await lastValueFrom(this.paymentsClient.getScheduledPaymentsCount(query));
	}

	async getScheduledPayments(query: ScheduledPaymentsQuery): Promise<ScheduledPaymentDisplay[]> {
		return await lastValueFrom(this.paymentsClient.getScheduledPayments(query));
	}

	async updateRecurringPayment(recurringPaymentId: number, paymentProfileId: string | undefined, status: RecurringPaymentStatus): Promise<void> {
		await lastValueFrom(this.paymentsClient.updateRecurringPayment(new UpdateRecurringPayment({
			recurringPaymentId,
			paymentProfileId,
			status
		})));
	}

	async updateScheduledPayment(scheduledPaymentId: number, paymentDueDate: Date): Promise<void> {
		await lastValueFrom(this.paymentsClient.updateScheduledPayment(new UpdateScheduledPayment({
			scheduledPaymentId,
			paymentDueDate
		})));
	}
}
