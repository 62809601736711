<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row mt-2">
		<div class="col">
			<div class="card">
				<div class="card-header">
					<ul class="nav nav-tabs card-header-tabs">
						<li class="nav-item">
							<button class="nav-link" data-bs-toggle="tab" data-bs-target="#customerPaymentsTab" [ngClass]="activeTab === 'customerPaymentsTab' ? 'active' : null">Customer Payments</button>
						</li>
						<li class="nav-item">
							<button class="nav-link" data-bs-toggle="tab" data-bs-target="#paymentAttemptsTab" [ngClass]="activeTab === 'paymentAttemptsTab' ? 'active' : null">Payment Attempts</button>
						</li>
						<li class="nav-item">
							<button class="nav-link" data-bs-toggle="tab" data-bs-target="#scheduledPaymentsTab" [ngClass]="activeTab === 'scheduledPaymentsTab' ? 'active' : null">Scheduled Payments</button>
						</li>
					</ul>
				</div>
				<div class="tab-content">
					<div class="card-body tab-pane" id="customerPaymentsTab" role="tabpanel" [ngClass]="activeTab === 'customerPaymentsTab' ? 'active' : null">
						<div class="accordion" id="customerPaymentsAccordion">
							<app-customer-payments></app-customer-payments>
						</div>
					</div>
					<div class="card-body tab-pane" id="paymentAttemptsTab" role="tabpanel" [ngClass]="activeTab === 'paymentAttemptsTab' ? 'active' : null">
						<div class="accordion" id="paymentAttemptsAccordion">
							<app-payment-attempts></app-payment-attempts>
						</div>
					</div>
					<div class="card-body tab-pane" id="scheduledPaymentsTab" role="tabpanel" [ngClass]="activeTab === 'scheduledPaymentsTab' ? 'active' : null">
						<div class="accordion" id="scheduledPaymentsAccordion">
							<app-scheduled-payments></app-scheduled-payments>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
