import { Component, OnInit } from '@angular/core';
import { ProductOptionCategory } from '@taradel/web-api-client';
import { SortPipe } from 'components/shared/sort/sort.pipe';
import { ProductsService } from 'services/products.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-option-categories',
	templateUrl: './option-categories.component.html',
	styleUrls: ['./option-categories.component.scss']
})
export class OptionCategoriesComponent implements OnInit {

	loading = false;
	allProductOptionCategories: ProductOptionCategory[] = [];

  	constructor(
		private productsService: ProductsService,
		private toastService: ToastService,
		private sortPipe: SortPipe) { }

  	async ngOnInit(): Promise<void> {
		await this.loadOptions();
  	}

  async loadOptions() {
		try {
			this.loading = true;
			this.allProductOptionCategories = await this.productsService.getAllProductOptionCategories();
			this.allProductOptionCategories = this.sortPipe.transform(this.allProductOptionCategories, 'asc', 'optCatId');
		}
		catch {
			this.toastService.showError('There was an error loading the categories');
		}
		finally {
			this.loading = false;
		}
  	}
}
