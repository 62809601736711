<app-pagefullheader subTitle="All sites content key {{ contentKey }}"></app-pagefullheader>

<div class="card p-3 mb-3">
    <!-- Site Config Table-->
	<div class="table-responsive" *ngIf="contentForSites.length !== 0">
		<table class="table table-hover table-panel">
			<thead>
				<tr>
					<th>Site Id</th>
					<th>Site Name</th>
					<th>Language</th>
					<th>Content</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let content of contentForSites" class="settingRow">
					<td>{{ content.siteId }}</td>
					<td>{{ getSiteName(content.siteId) }}</td>
					<td>{{ content.language | uppercase }}</td>
					<td>
						<div class="row">
							<div class="col-md-8">
								<textarea class="form-control" rows="3" style="height:100px" required
								[(ngModel)]="content.content"
									[ngClass]="{ 'is-invalid': content.content?.length === 0}"></textarea>
								<small *ngIf="content.modifiedBy !== ''">Modified by {{ content.modifiedBy }} on {{ content.modifiedDate | date:'medium' }}</small>
							</div>
							<div class="col-md-4">
								<button (ngModel)="content" button class="btn btn-info btn-sm mx-1" type="button"
											data-toggle="tooltip" data-placement="top" title="Edit"
											(click)="showEditSettingsModal(content, editContent)"><i class="fa fa-save mx-2"></i></button>
								<button *ngIf="content.siteId !== 0 || (contentForSites.length <= 1 && content.siteId === 0)" (ngModel)="content" button class="btn btn-sm btn-danger mx-1" type="button"
											data-toggle="tooltip" data-placement="top" title="Delete"
											(click)="showDeleteContentModal(content, deleteContent)"><i class="fa fa-trash"></i></button>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<!-- Edit Modal -->
<ng-template #editContent let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit Setting '{{ contentToEdit?.contentKey?.contentKey }}'</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
			(click)="modal.close('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">Are you sure you want to change the content for key <span class="text-primary">"{{ contentToEdit?.contentKey?.contentKey}}"</span>?</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link" (click)="modal.close('cancelEdit')">Cancel</button>
		<button type="button" class="btn btn-primary btn-lg" (click)="modal.close('edit')">Save Content Key</button>
	</div>
</ng-template>
<!-- End Edit Modal -->

<!-- Delete Modal -->
<ng-template #deleteContent let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Delete content for '{{ contentToDelete?.contentKey?.contentKey }}' on {{ getSiteName(contentToDelete?.siteId!) }}</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
			(click)="modal.close('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">Are you sure you want to delete the content for key <span class="text-primary">"{{ contentToDelete?.contentKey?.contentKey}}"</span> on {{ getSiteName(contentToDelete?.siteId!) }}?</p>
		<p *ngIf="contentForSites.length <= 1" class="lead text-danger">
			Deleting this content will also delete the content key.
		</p>
		<p class="lead text-danger"><strong>This operation can not be undone.</strong></p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link" (click)="modal.close('cancelDelete')">Cancel</button>
		<button type="button" class="btn btn-danger btn-lg" (click)="modal.close('delete')">Delete Content</button>
	</div>
</ng-template>
<!-- End Delete Modal -->




