import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DesignTemplate, PageSize, Product, USelectMethod, USelectProductConfiguration } from '@taradel/admin-api-client';
import { ProductsService } from 'services/products.service';
import { SitesService } from 'services/sites.service';
import { TemplateService } from 'services/template.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-product-details',
	templateUrl: './product-details.component.html',
	styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
	productFormSubmitted = false;
	uSelectFormSubmitted = false;
	productId = 0;
	product?: Product;
	uSelects: USelectMethod[] = [];
	productForm: UntypedFormGroup;
	productConfigurationForm: UntypedFormGroup;
	designTemplates: DesignTemplate[] = [];
	pageSizes: PageSize[] = [];
	uSelectProductConfiguration: USelectProductConfiguration = new USelectProductConfiguration();
	loading = false;
	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		public templateService: TemplateService,
		public productsService: ProductsService,
		public sitesService: SitesService,
		private toastService: ToastService
	) {
		this.productForm = this.formBuilder.group({
			name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
			sku: ['', Validators.maxLength(50)],
			paperWidth: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(99999)])],
			paperHeight: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(99999)])],
			pageCount: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(99999)])],
			designTemplate: [''],
			designFee: [],
			templateSize: [''],
			creativeSetupFee: [],
			shortDescription: ['', Validators.maxLength(260)],
			fullDescription: ['']
		});

		this.productConfigurationForm = this.formBuilder.group({
			uSelect: ['', Validators.compose([])],
			pricePerPiece: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(99)])],
			dropFee: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(999)])],
			postageFee: ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(99)])]
		});
	}

	ngOnInit() {
		this.route.paramMap.subscribe(async params => {
			this.productId = parseInt(params.get('productId') ?? '0', 10);
			if (this.productId > 0) {
				try {
					this.loading = true;
					const uSelect = await this.productsService.getProductUSelect(this.productId);
					this.setUSelectConfigForm(uSelect);
					this.uSelects = await this.sitesService.getAllUSelect();
					this.product = await this.productsService.getProduct(this.productId);
					this.setProductForm(this.product);
				}
				catch {
					this.toastService.showError('There was an error getting the product details');
				}
				finally {
					this.loading = false;
				}
			}
			try {
				this.loading = true;
				this.designTemplates = await this.productsService.getDesignTemplates();
				this.pageSizes = await this.templateService.getPageSizes();
			}
			catch {
				this.toastService.showError('There was an error getting design templates or page sizes');
			}
			finally {
				this.loading = false;
			}
		});
	}

	setUSelectConfigForm(uSelect: USelectProductConfiguration) {
		if (!!uSelect) {
			this.uSelectProductConfiguration = uSelect;

			this.pcf.dropFee.setValue(uSelect.extraDropPrice);
			this.pcf.pricePerPiece.setValue(uSelect.mailPricePerPiece);
			this.pcf.postageFee.setValue(uSelect.postageFee);
			this.pcf.uSelect.setValue(uSelect.uSelectId);
		}
		else {
			this.uSelectProductConfiguration.productId = this.productId;
		}
	}

	setProductForm(product: Product) {
		this.productForm.controls.name.setValue(product!.name);
		this.productForm.controls.pageCount.setValue(product!.pageCount);
		this.productForm.controls.paperHeight.setValue(product!.paperHeight);
		this.productForm.controls.paperWidth.setValue(product!.paperWidth);
		this.productForm.controls.sku.setValue(product!.sku);
		this.productForm.controls.shortDescription.setValue(product!.shortDescription);
		this.productForm.controls.fullDescription.setValue(product!.fullDescription);
		this.productForm.controls.designFee.setValue(product!.designFee);
		if (product?.templateSizeId) {
			this.productForm.controls.templateSize.setValue(product!.templateSizeId);
		}
		if (product?.creativeSetupFee) {
			this.productForm.controls.creativeSetupFee.setValue(product!.creativeSetupFee);
		}
		if ( product.designTemplate) {
			this.productForm.controls.designTemplate.setValue(product!.designTemplate);
		}

	}

	async saveProduct(): Promise<void> {
		if (this.productId > 0) {
			await this.updateProduct();
		}
		else {
			await this.addProduct();
		}
	}

	async updateProduct(): Promise<void> {
		this.productFormSubmitted = true;
		if (this.productForm.invalid) {
			return;
		}
		try {
			this.loading = true;
			this.product!.name = this.productForm.controls.name.value;
			this.product!.pageCount = parseInt(this.productForm.controls.pageCount.value, 10);
			this.product!.paperHeight = parseFloat(this.productForm.controls.paperHeight.value);
			this.product!.paperWidth = parseFloat(this.productForm.controls.paperWidth.value);
			this.product!.designFee = parseInt(this.productForm.controls.designFee.value, 10);
			this.product!.sku = this.productForm.controls.sku.value;
			this.product!.shortDescription = this.productForm.controls.shortDescription.value;
			this.product!.fullDescription = this.productForm.controls.fullDescription.value;
			if (this.productForm.controls.templateSize.value > -1) {
				this.product!.templateSizeId = this.productForm.controls.templateSize.value;
			}
			if (this.productForm.controls.creativeSetupFee.value && this.productForm.controls.creativeSetupFee.value.length > 0) {
				this.product!.creativeSetupFee = parseInt(this.productForm.controls.creativeSetupFee.value);
			}
			else {
				this.product!.creativeSetupFee = undefined;
			}
			if (this.productForm.controls.designTemplate.value !== undefined) {
				this.product!.designTemplate = this.productForm.controls.designTemplate.value;
			}

			await this.productsService.updateProduct(this.product!);
			this.toastService.showSuccess('Product was successfully updated');
			this.setProductForm(this.product!);
		}
		catch {
			this.toastService.showError('There was an error updating the product');
		}
		finally {
			this.loading = false;
		}
	}

	async addProduct(): Promise<void> {
		this.productFormSubmitted = true;
		if (this.productForm.invalid) {
			return;
		}
		try {
			this.loading = true;
			const productId = await this.productsService.addProduct(new Product({
				price: 0,
				designTemplate: this.productForm.controls.designTemplate.value,
				name: this.productForm.controls.name.value,
				pageCount: parseInt(this.productForm.controls.pageCount.value, 10),
				paperHeight: parseFloat(this.productForm.controls.paperHeight.value),
				paperWidth: parseFloat(this.productForm.controls.paperWidth.value),
				designFee: parseInt(this.productForm.controls.designFee.value, 10),
				sku: this.productForm.controls.sku.value,
				templateSizeId: parseInt(this.productForm.controls.templateSize.value, 10),
				creativeSetupFee: parseFloat(this.productForm.controls.creativeSetupFee.value),
				priceMethod: 0,
				productId: 0,
				fullDescription: this.productForm.controls.fullDescription.value,
				shortDescription: this.productForm.controls.shortDescription.value,
			}));
			this.router.navigateByUrl(`products/${productId}`);
		}
		catch (error) {
			this.toastService.showError('There was an error saving the product');
		}
		finally {
			this.loading = false;
		}
	}

	async updateProductConfiguration(): Promise<void> {
		this.uSelectFormSubmitted = true;
		if (this.productConfigurationForm.invalid) {
			return;
		}
		try {
			this.loading = true;
			this.uSelectProductConfiguration!.extraDropPrice = this.pcf.dropFee.value;
			this.uSelectProductConfiguration!.mailPricePerPiece = this.pcf.pricePerPiece.value;
			this.uSelectProductConfiguration!.postageFee = this.pcf.postageFee.value;
			this.uSelectProductConfiguration!.uSelectId = this.pcf.uSelect.value;
			await this.productsService.updateProductUSelect(this.uSelectProductConfiguration!);
			this.toastService.showSuccess('Product USelect Configuration successfully updated');
			this.setUSelectConfigForm(this.uSelectProductConfiguration);
		}
		catch {
			this.toastService.showError('There was an error updaing the product USelect configuration');
		}
		finally {
			this.loading = false;
		}
	}

	get f() {
		return this.productForm.controls;
	}
	get pcf() {
		return this.productConfigurationForm.controls;
	}
}
