<app-customer-header [customerId]="customerId">
	<ng-container altnav>
		<ul class="nav nav-pills justify-content-end">
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'distributions']">Back to Distributions</a>
			</li>
		</ul>
	</ng-container>
</app-customer-header>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="row" *ngIf="distribution">
			<div class="col-md-7">
				<div class="col-md-6">
					<table class="table table-hover">
						<thead>
							<tr>
								<td><strong>Distribution Name:</strong></td>
								<td><strong>{{ distribution.name }}</strong></td>
							</tr>
						</thead>
						<tbody>
							<tr *ngIf="distribution.totalDeliveries">
								<td><strong>Total Deliveries:</strong></td>
								<td>{{ distribution.totalDeliveries }}</td>
							</tr>
							<tr *ngIf="distributionWithRelatedOrders && distributionWithRelatedOrders.orderIds && distributionWithRelatedOrders.orderIds.length > 0">
								<td><strong>Used in Orders:</strong></td>
								<td>
									<ul class="list-inline">
										<li *ngFor="let orderId of distributionWithRelatedOrders.orderIds" class="list-inline-item mb-2"><a
											routerLink="/orders/{{orderId}}/summary"
											class="btn btn-primary btn-sm">{{orderId}}</a></li>
									</ul>
								</td>
							</tr>
							<tr>
								<td><strong>Created on:</strong></td>
								<td>{{ distribution.createdDate | date: 'MM/dd/yyyy HH:mm:ss' }}</td>
							</tr>
						</tbody>
					</table>
				</div>

				<ng-container *ngIf="!!listSuppressionDetails">
					<div class="my-2">
						This list was created by suppression
						<ul>
							<li>
								Original List: <a [routerLink]="['/customers', customerId, 'distributions', listSuppressionDetails.originalList.distributionId]" target="_blank">{{ listSuppressionDetails.originalList.distributionName }} ({{ listSuppressionDetails.originalList.distributionId }})</a>
							</li>
							<li>
								Suppressing List: <a [routerLink]="['/customers', customerId, 'distributions', listSuppressionDetails.suppressingList.distributionId]" target="_blank">{{ listSuppressionDetails.suppressingList.distributionName }} ({{ listSuppressionDetails.suppressingList.distributionId }})</a>
							</li>
						</ul>
					</div>
				</ng-container>

				<div *ngIf="pmSelectionService.query">
					<app-value-selection [selectionService]="pmSelectionService" title="Zip Codes" queryField="zipCodes">
					</app-value-selection>
					<app-address-selection [selectionService]="pmSelectionService" title="Address" queryField="radiusQuery">
					</app-address-selection>
					<app-slider-selection [selectionService]="pmSelectionService" title="Age" queryField="age">
					</app-slider-selection>
					<app-list-selection [selectionService]="pmSelectionService" title="Children"
						queryField="children_Present">
					</app-list-selection>
					<app-list-selection [selectionService]="pmSelectionService" title="Gender" queryField="gender">
					</app-list-selection>
					<app-list-selection [selectionService]="pmSelectionService" title="Homeowner"
						queryField="home_Owner_Renter">
					</app-list-selection>
					<app-list-selection [selectionService]="pmSelectionService" title="Marital Status"
						queryField="marital_Status">
					</app-list-selection>
					<app-list-selection [selectionService]="pmSelectionService" title="Education Individual Status"
						queryField="education_Individual"></app-list-selection>
					<app-slider-selection [selectionService]="pmSelectionService" title="Length Of Residence"
						queryField="length_Of_Residence"></app-slider-selection>
					<app-slider-selection [selectionService]="pmSelectionService" title="Home Built Year"
						queryField="home_Built_Year"></app-slider-selection>
					<app-list-selection [selectionService]="pmSelectionService" title="Dwelling Type"
						queryField="dwelling_Type">
					</app-list-selection>
					<app-slider-selection [selectionService]="pmSelectionService" title="Income" queryField="income_Code">
					</app-slider-selection>
					<app-slider-selection [selectionService]="pmSelectionService" title="Net Worth"
						queryField="networth_Code">
					</app-slider-selection>
					<app-slider-selection [selectionService]="pmSelectionService" title="Estimated Home Value"
						queryField="home_Value_Code"></app-slider-selection>
					<app-toggle-selection [selectionService]="pmSelectionService" title="Credit Card"
						queryFields="credit_Card_User"></app-toggle-selection>
					<app-toggle-selection [selectionService]="pmSelectionService" title="Charity Donor"
						queryFields="donor_Charitable"></app-toggle-selection>
					<app-toggle-selection [selectionService]="pmSelectionService" title="Book Reader"
						queryFields="reading_General">
					</app-toggle-selection>
					<app-toggle-selection [selectionService]="pmSelectionService" title="Technology"
						queryFields="high_Tech_General"></app-toggle-selection>

					<app-toggle-selection [selectionService]="pmSelectionService" title="Home, Garden & Crafts"
						queryFields="gardening,photography,sewing_Knitting_Needlework,woodworking"></app-toggle-selection>

					<app-toggle-selection [selectionService]="pmSelectionService" title="Travel"
						queryFields="travel_Cruises,travel_Domestic,travel_Grouping,travel_International">
					</app-toggle-selection>

					<app-toggle-selection [selectionService]="pmSelectionService" title="Fitness and Health"
						queryFields="exercise_Running_Jogging,exercise_Walking,exercise_Aerobic"></app-toggle-selection>

					<app-toggle-selection [selectionService]="pmSelectionService" title="Outdoor"
						queryFields="outdoor_Enthusiast_General,sports_Skiing,outdoor_Scuba_Diving,outdoor_Hunting_Shooting,outdoor_Fishing">
					</app-toggle-selection>

					<app-toggle-selection [selectionService]="pmSelectionService" title="Food & Cooking"
						queryFields="cooking_Gourmet,food_Wines,cooking_General,foods_Natural"></app-toggle-selection>

					<app-toggle-selection [selectionService]="pmSelectionService" title="Health, Diet & Self-Improvement"
						queryFields="health_Medical,dieting_Weight_Loss,self_Improvement"></app-toggle-selection>

					<app-toggle-selection [selectionService]="pmSelectionService" title="Sports"
						queryFields="spectator_Sports_General,spectator_Sports_Football,spectator_Sports_Baseball,sports_Golf,sports_Tennis,spectator_NASCAR">
					</app-toggle-selection>

					<app-toggle-selection [selectionService]="pmSelectionService" title="Home Improvement"
						queryFields="home_Improvement,home_Improvement_Diy"></app-toggle-selection>
				</div>
				<div *ngIf="distribution.uSelectId === uSelect.equifaxB2B && distribution instanceof AddressedDistribution && !distribution.suppressedByDistributionId">
					<app-list-selection [selectionService]="equifaxSelectionService" title="States" queryField="states">
					</app-list-selection>
					<app-value-selection [selectionService]="equifaxSelectionService" title="Zip Codes"
						queryField="zipCodes">
					</app-value-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Metro Areas"
						queryField="metros">
					</app-list-selection>
					<app-address-selection [selectionService]="equifaxSelectionService" title="Address"
						queryField="radiusQuery">
					</app-address-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="SIC Class" queryField="sicCodes"
						[showKeys]="true"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="NAICS Class"
						queryField="naicsCodes" [showKeys]="true"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Business Legal Status"
						queryField="businessLegalStatus"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Publicly Held"
						queryField="publicPrivateStatus"></app-list-selection>
					<app-toggle-selection [selectionService]="equifaxSelectionService" title="Special Classification"
						queryFields="governmentEntity, federalGovernmentEntity, nonProfitEntity, educationalInstitution">
					</app-toggle-selection>
					<app-toggle-selection [selectionService]="equifaxSelectionService" title="Business Ownership"
						queryFields="minorityBusinessEnterprise, womanBusinessEnterprise, veteranBusiness, foreignOwned">
					</app-toggle-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Primary Contact Title"
						queryField="primaryContactTitle"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Primary Contact Gender"
						queryField="primaryContactGender"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Primary Contact Ethnicity"
						queryField="primaryContactEthnicity"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Business Size"
						queryField="businessSize"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Total Employees"
						queryField="corporateEmployeeCodes"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Location Employees"
						queryField="locationEmployeeCodes"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Overall Marketability Score"
						queryField="marketabilityScores"></app-list-selection>
					<app-slider-selection [selectionService]="equifaxSelectionService" title="Credit Score"
						queryField="creditRiskScore"></app-slider-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Business Credit Risk Class"
						queryField="creditRiskClass"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService" title="Business Failure Risk Rating"
						queryField="failureRating"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService"
						title="Supporting Reasons for Failure Risk Rating" queryField="failureReasons"></app-list-selection>
					<app-toggle-selection [selectionService]="equifaxSelectionService" title="Business Failure"
						queryFields="bankruptcy, dead"></app-toggle-selection>
					<app-list-selection [selectionService]="equifaxSelectionService"
						title="Corporate Revenue, Assets, or Sales" queryField="corporateAmountCodes"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService"
						title="Corporate Type of Amount Reported" queryField="corporateAmountType"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService"
						title="Corporate Precision of Amount Reported" queryField="corporateAmountPrecision">
					</app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService"
						title="Location Revenue, Assets, or Sales" queryField="locationAmountCodes"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService"
						title="Location Type of Amount Reported" queryField="locationAmountType"></app-list-selection>
					<app-list-selection [selectionService]="equifaxSelectionService"
						title="Location Precision of Amount Reported" queryField="locationAmountPrecision">
					</app-list-selection>
				</div>
				<div *ngIf="demographics || caDemographics">
					<div *ngIf="filters.size > 0">
						Filters:
						<div *ngFor="let filter of filters | keyvalue: originalOrder">
							<b>{{filter.key}}:</b> {{filter.value.join(', ')}}
						</div>
					</div>
				</div>

				<div *ngIf="usAreas.length" class="py-3">
					<div><b>Starting Address:</b> {{ eddmSelection?.startAddress }} {{ eddmSelection?.startZipCode }}</div>
					<div><b>Selections included:</b> {{getEddmDeliveryType()}} deliveries</div>

					<div class="table-responsive">
						<table class="table table-striped">
							<thead>
								<tr>
									<th>Name</th>
									<th>City, State</th>
									<th>Residential</th>
									<th *ngIf="eddmSelection?.useBusiness">Business</th>
									<th *ngIf="eddmSelection?.usePOBoxes">PO Box</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let area of usAreas">
									<td>{{ area.name }}</td>
									<td>{{ area.friendlyName }}</td>
									<td>{{ area.residential | number }}</td>
									<td *ngIf="eddmSelection?.useBusiness">{{ area.business | number }}</td>
									<td *ngIf="eddmSelection?.usePOBoxes">{{ area.poBoxes | number }}</td>
									<td>{{ area.total | number }}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="2" class="text-end">
										<div class="lead"><b>Totals:</b></div>
									</td>
									<td>
										<div class="lead"><b>{{ totalResidential | number }}</b></div>
									</td>
									<td *ngIf="eddmSelection?.useBusiness">
										<div class="lead"><b>{{ totalBusiness | number }}</b></div>
									</td>
									<td *ngIf="eddmSelection?.usePOBoxes">
										<div class="lead"><b>{{ totalPoBoxes | number }}</b></div>
									</td>
									<td>
										<div class="lead"><b>{{ totalDistribution | number }}</b></div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
				<div *ngIf="caAreas.length" class="py-3">
					<div><b>Starting Address:</b> {{ snapAdMailSelection?.startAddress }} {{ snapAdMailSelection?.startZipCode }}</div>
					<div><b>Selections included</b> {{getSnapAdMailDeliveryType()}} deliveries</div>

					<div class="table-responsive">
						<table class="table table-striped">
							<thead>
								<tr>
									<th>Name</th>
									<th>City, State</th>
									<th *ngIf="snapAdMailSelection?.useApartment">Apartments</th>
									<th *ngIf="snapAdMailSelection?.useFarm">Farms</th>
									<th *ngIf="snapAdMailSelection?.useHouse">Houses</th>
									<th *ngIf="snapAdMailSelection?.useBusiness">Business</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let area of caAreas">
									<td>{{ area.name }}</td>
									<td>{{ area.friendlyName }}</td>
									<td *ngIf="snapAdMailSelection?.useApartment">{{ area.apartments | number }}</td>
									<td *ngIf="snapAdMailSelection?.useFarm">{{ area.farms | number }}</td>
									<td *ngIf="snapAdMailSelection?.useHouse">{{ area.houses | number }}</td>
									<td *ngIf="snapAdMailSelection?.useBusiness">{{ area.business | number }}</td>
									<td>{{ area.total | number }}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="2" class="text-end">
										<div class="lead"><b>Totals:</b></div>
									</td>
									<td *ngIf="snapAdMailSelection?.useApartment">
										<div class="lead"><b>{{ totalApartment | number }}</b></div>
									</td>
									<td *ngIf="snapAdMailSelection?.useFarm">
										<div class="lead"><b>{{ totalFarm | number }}</b></div>
									</td>
									<td *ngIf="snapAdMailSelection?.useHouse">
										<div class="lead"><b>{{ totalHouse | number }}</b></div>
									</td>
									<td *ngIf="snapAdMailSelection?.useBusiness">
										<div class="lead"><b>{{ totalBusiness | number }}</b></div>
									</td>
									<td>
										<div class="lead"><b>{{ totalDistribution | number }}</b></div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="text-end mb-3">
					<a [routerLink]="snapAdMailSelection ? ['/customers', customerId, 'snapmap', distributionId] : ['/customers', customerId, 'map', distributionId]"
						class="btn btn-success btn-rounded px-4 rounded-pill mx-1" *ngIf="eddmSelection || snapAdMailSelection">
						<i class="fa fa-pencil fa-lg me-2 ms-n2 text-success-900"></i>
						Edit Map</a>

						<a *ngIf="isSalesAdmin && distribution instanceof AddressedDistribution && !distribution.suppressedByDistributionId && (distribution.uSelectId === uSelect.buildYourList || distribution.uSelectId === uSelect.equifaxB2B)"
						class="btn btn-success btn-rounded px-4 rounded-pill mx-1" (click)="downloadList()">
						<i class="fa fa-download fa-lg me-2 ms-n2 text-success-900"></i>
						Download List
					</a>

					<a [routerLink]="['/customers', customerId, 'personalized-mail', distributionId]"
						class="btn btn-success btn-rounded px-4 rounded-pill mx-1"
						*ngIf="distribution.uSelectId === uSelect.buildYourList && distribution instanceof AddressedDistribution && !distribution.suppressedByDistributionId">
						<i class="fa fa-pencil fa-lg me-2 ms-n2 text-success-900"></i>
						Edit List
					</a>

					<a [routerLink]="['/customers', customerId, 'business', distributionId]"
						class="btn btn-success btn-rounded px-4 rounded-pill mx-1"
						*ngIf="distribution.uSelectId === uSelect.equifaxB2B && distribution instanceof AddressedDistribution && !distribution.suppressedByDistributionId">
						<i class="fa fa-pencil fa-lg me-2 ms-n2 text-success-900"></i>
						Edit List
					</a>

					<button class="btn btn-success btn-rounded px-4 rounded-pill mx-1" (click)="openSuppressListModal(suppressListModal)"
						*ngIf="(distribution.uSelectId === uSelect.buildYourList || distribution.uSelectId === uSelect.uploadYourList) && distribution instanceof AddressedDistribution && !distribution.suppressedByDistributionId">
						<i class="fa fa-exchange fa-lg me-2 ms-n2 text-success-900"></i>
						Suppress List
					</button>

					<a  (click)="proceedToSiteSelection(distribution!, siteModal)"
						class="btn btn-success btn-rounded px-4 rounded-pill mx-1" *ngIf="allowOrder">
						<i class="fa fa-shopping-basket fa-lg me-2 ms-n2 text-success-900"></i>
						Start Order
					</a>
				</div>

				<b>Distribution Files</b>
				<ul class="list-group list-group-flush">
					<div class="list-group-item" *ngFor="let file of distFiles">
						<button class="btn btn-link text-decoration-none" (click)="downloadFile(file)">{{file}}</button>
					</div>
					<div class="list-group-item" *ngIf="!distFiles.length">
						There are no files related to this distribution.
					</div>
				</ul>

				<figure class="figure" *ngIf="usAreas.length > 0 || caAreas.length > 0">
					<img [attr.src]="mapImageSrc" class="figure-img img-fluid rounded" alt="Map of Distribution Selections">
					<figcaption class="figure-caption">Map of Distribution Selections.</figcaption>
				</figure>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>

<ng-template #siteModal let-modal>
	<app-confirm-modal title="Select a site to place the order from" confirmText="Proceed" (confirmAction)="startOrder()"
		(closeModal)="closeModal();" [disabled]="selectedSiteId === 0">
		<ng-template #rt let-r="result" let-t="term">
			<ngb-highlight [result]="r.siteId" [term]="t"></ngb-highlight>
		</ng-template>
		<div>
			<label for="site">Select Site</label>
			<app-sites-list [startingSiteId]="customer.affiliateID" (siteSelected)="siteChanged($event.siteId)" [sites]="sites"></app-sites-list>
		</div>

	</app-confirm-modal>
</ng-template>

<ng-template #suppressListModal let-modal>
	<app-confirm-modal title="Select a distribution to suppress with" confirmText="Proceed" (confirmAction)="processListSuppression()"
		(closeModal)="closeModal()" [disabled]="!suppressListResponse">
		<app-suppress-list [customerId]="customerId" [originalDistId]="distributionId" (listSelected)="setSuppressingList($event)"></app-suppress-list>
	</app-confirm-modal>
</ng-template>

