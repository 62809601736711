import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { USelectLanguages } from '@taradel/web-api-client';
import { HttpErrorBody } from 'app/utils/http-error';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';


@Component({
	selector: 'app-uselects-languages',
	templateUrl: './uselects-languages.component.html',
	styleUrls: ['./uselects-languages.component.scss']
})
export class USelectsLanguagesComponent implements OnInit {
	@Input() uSelectId?: number;
	@Input() languagesList?: USelectLanguages[];

	loading = false;
	uSelectLanguage?: USelectLanguages;
	editTranslatedNameForm: UntypedFormGroup;
	allowEdit = false;

	constructor(private sitesService: SitesService,
		private toastService: ToastService,
		formBuilder: FormBuilder) {
		this.editTranslatedNameForm = formBuilder.group({
			uSelectId: new FormControl<number | undefined>(undefined, Validators.required),
			language: new FormControl<string | undefined>(undefined, Validators.required),
			uSelectName: new FormControl<string | undefined>(undefined, Validators.compose([
				Validators.required,
				Validators.maxLength(100)
			])),
		});
	}

	get form() {
		return this.editTranslatedNameForm.controls;
	}

	ngOnInit(): void {
		this.uSelectLanguage = this.languagesList?.find(l => l.uSelectId === this.uSelectId);
		this.initializeForm();
	}

	initializeForm() {
		this.form.uSelectId.setValue(this.uSelectId);
		this.form.language.setValue('fr');
		this.form.uSelectName.setValue(undefined);
	}

	async handleFormSubmission() {
		if (!this.uSelectLanguage) {
			await this.addTranslatedName();
		}
		else {
			await this.updateTranslatedName();
		}
	}

	cancelEdit() {
		this.initializeForm();
		this.allowEdit = false;
	}

	async addTranslatedName() {
		this.loading = true;
		if (!this.editTranslatedNameForm.valid) {
			this.loading = false;
			return;
		}
		const query = {
			uSelectId: this.form.uSelectId.value,
			language: this.form.language.value,
			uSelectName: this.form.uSelectName.value
		} as USelectLanguages;
		try {
			this.uSelectLanguage = await this.sitesService.addUSelectTranslatedName(query);
		}
		catch (err: any) {
			console.log(err);
			const response = JSON.parse(err.response) as HttpErrorBody;
			this.toastService.showError(response.detail);
		}
		finally {
			this.initializeForm();
			this.allowEdit = false;
			this.loading = false;
		}
	}

	async updateTranslatedName() {
		this.loading = true;
		let success = true;
		if (!this.editTranslatedNameForm.valid) {
			this.loading = false;
			return;
		}
		const query = {
			uSelectId: this.form.uSelectId.value,
			language: this.form.language.value,
			uSelectName: this.form.uSelectName.value
		} as USelectLanguages;
		try {
			this.uSelectLanguage = await this.sitesService.updateUSelectTranslatedName(query);
		}
		catch (err: any) {
			success = false;
			console.log(err);
			const response = JSON.parse(err.response) as HttpErrorBody;
			this.toastService.showError(response.detail);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.initializeForm();
			this.allowEdit = false;
		}
	}

	async removeTranslatedName() {
		this.loading = true;
		let success = true;
		try {
			await this.sitesService.removeUSelectLanguagesRecord(this.uSelectLanguage!.uSelectId, this.uSelectLanguage?.language!);
		}
		catch (err: any) {
			success = false;
			console.log(err);
			const response = JSON.parse(err.response) as HttpErrorBody;
			this.toastService.showError(response.detail);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.uSelectLanguage = undefined;
			this.initializeForm();
			this.allowEdit = false;
		}
	}
}
