<div class="modal-content">
	<div class="modal-header">
		<h4 class="modal-title">{{title}}</h4>
		<button type="button" class="btn-close" (click)="closeModal.emit()"></button>
	</div>
	<div class="modal-body">
		<div class="lead">
			<ng-content></ng-content>
		</div>
		<div *ngIf="isDeleteModal">
			<p class="lead text-danger"><strong>This operation can not be undone.</strong></p>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" data-bs-dismiss="modal" [disabled]="disabled"
			(click)="confirmAction.emit()">{{confirmText}}</button>
		<button type="button" class="btn btn-danger" (click)="closeModal.emit()">{{cancelText}}</button>
	</div>
</div>
