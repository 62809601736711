import { Component, OnInit, ViewChild } from '@angular/core';
import { ExportPCOGRequest, FileParameter } from '@taradel/admin-api-client';
import { ProductsService } from 'services/products.service';
import { PrintersService } from 'services/printers.service';
import { Printer, Product, ProductsSearch } from '@taradel/admin-api-client';
import { ToastService } from 'services/toast.service';
import { ProductsListComponent } from './products-list/products-list.component';
import { PrintersListComponent } from './printers-list/printers-list.component';

@Component({
  selector: 'app-pcogs-management',
  templateUrl: './pcogs-management.component.html',
  styleUrls: ['./pcogs-management.component.scss']
})
export class PcogsManagementComponent implements OnInit {
	@ViewChild(ProductsListComponent) productsComponent!: ProductsListComponent;
	@ViewChild(PrintersListComponent) printersComponent!: PrintersListComponent;

	loading = false;
	products?: Product[];
	printers?: Printer[];

	// export
	productIdsSelected: number[] = [];
	printerIdsSelected: number[] = [];
	selectedProducts: Product[] = [];
	selectedPrinters: Printer[] = [];

	//import
	filePath = '';
	file?: File;

	constructor(private productsService: ProductsService,
		private printersService: PrintersService,
		private toastService: ToastService) { }

	async ngOnInit(): Promise<void> {
		this.loading = true;
		await this.getAllProducts();
		await this.getAllPrinters();
		this.loading = false;
	}

	async getAllProducts(): Promise<void> {
		const query = {
			name: '',
			pageNo: 1,
			pageSize: 1000
		} as ProductsSearch;
		this.products = await this.productsService.getAllProducts(query);
	}

	async getAllPrinters(): Promise<void> {
		this.printers = await this.printersService.getAllPrinters();
	}

	//import
	fileAdded(fileInput: any) {
		const reader = new FileReader();
		this.file = <File>fileInput.files[0];
		reader.onload = () => {
			this.filePath = reader.result as string;
		};
		reader.readAsDataURL(this.file);
	}

	setProductIdsList(request: Product[]) {
		this.productIdsSelected = request.length ? request.map(p => p.productId) : [];
	}

	setPrinterIdsList(request: Printer[]) {
		this.printerIdsSelected = request.length? request.map(p => p.printerId) : [];
	}

	async exportPCOGs() {
		this.loading = true;
		let success = true;
		try {
			const request = {
				productIds: this.productIdsSelected,
				printerIds: this.printerIdsSelected
			} as ExportPCOGRequest;
			await this.printersService.exportPCOGs(request);
		}
		catch (err: any) {
			console.log(err);
			success = false;
			this.toastService.showError('Export failed');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.toastService.showSuccess('Export succeeded');
			this.productIdsSelected = [];
			this.printerIdsSelected = [];
			this.selectedProducts = [];
			this.selectedPrinters = [];
			this.productsComponent.resetComponent();
			this.printersComponent.resetComponent();
		}
	}

	async uploadPCOGs() {
		this.loading = true;
		let success = true;
		try {
			let file = { data: this.file, fileName: this.file!.name } as FileParameter;
			await this.printersService.importPCOGs(file);
		}
		catch (err: any) {
			console.log(err);
			success = false;
			this.toastService.showError('Upload failed');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.toastService.showSuccess('Uploaded succeeded');
			this.file = undefined;
			this.filePath = '';
		}
	}
}
