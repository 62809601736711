import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, UntypedFormArray, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { formatDate } from '@angular/common';
import {
	AddItemToCartQuery, BaseDesign, CartProduct, Coupon, Customer, CustomerAddress, Distribution,
	Design, DropsKind, DropsQuery, EddmDistribution, Order, PostageType, GetCustomerDistributionsQuery, PaginationQuery,
	ProductOptionCategory, ProductOptionIdentifier, ProductPrintMethodOption,
	Site, USelectDesignType, USelectMethod, WLProduct, Calendar, USelectInductionTurnaround, GetCaCalendarRequest,
	SnapAdMailDistribution, AddOrderItemQuery, USelectType
} from '@taradel/admin-api-client';
import { PriceCalculatorRequest, ProductPricingResponse, GetCalendarRequest } from '@taradel/web-api-client';
import { CustomerService } from 'services/customer.service';
import { SitesService } from 'services/sites.service';
import { DistributionsService, USelect } from 'services/distributions.service';
import { DigitalProducts, PMProducts, DirectMailProducts, ProductsWithDailyBudget, SalesApiService } from 'services/sales-api.service';
import { ToastService } from 'services/toast.service';
import { OrderService } from 'services/order.service';
import { ChangeOrdersService } from 'services/change-orders.service';
import { ProductCategories, ProductCategoryOptions, ProductsService } from 'services/products.service';
import { PricingService } from 'services/pricing.service';
import { SiteConfigService } from 'services/site-config.service';
import { NgbDate, NgbDateStruct, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { numDaysBetween } from 'app/utils/num-days-between';
import { environment } from 'environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export interface Pricing {
	total: number;
	shippingPrice?: number;
}

export function checkIfEndDateAfterStartDate(c: AbstractControl) {
	if (!c.get('startDate')?.value || !c.get('endDate')?.value) { return null; }

	if (c.get('startDate')?.value <= c.get('endDate')?.value) {
		c.get('endDate')?.setErrors(null);
		return null;
	}
	else {
		c.get('endDate')?.setErrors({ invalidEndDate: true });
		return { invalidEndDate: true };
	}
}

export function totalBudgetValidator(minimumBudget: number): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const value = control.value;
		if (!value) {
			return null;
		}

		let val = value < minimumBudget ? { invalidDate: 'false' } : null;
		return val;
	};
}

@Component({
  selector: 'app-update-order-item',
  templateUrl: './update-order-item.component.html',
  styleUrls: ['./update-order-item.component.scss']
})
export class UpdateOrderItemComponent implements OnInit {
	USelectDesignType = USelectDesignType;
	showSpinner = false;
	isUS = environment.instance === 'US';
	allowAddProducts = false;
	customerId?: number;
	customer?: Customer;
	hoveredDate?: NgbDateStruct = undefined;
	fromDate?: NgbDateStruct = undefined;
	toDate?: NgbDateStruct = undefined;
	minDate!: NgbDateStruct;
	maxDate!: NgbDateStruct;
	startDate!: NgbDateStruct;
	calendar?: Calendar;
	inductionTurnaroundTime?: USelectInductionTurnaround;
	standardMailDeliveryTime = 0;
	firstClassDeliveryTime = 0;
	isDisabled = (ngbDate: NgbDateStruct) => {
		return ngbDate !== this.minDate && ngbDate !== this.maxDate;
	};
	today: string = '';
	endDateStart: string = '';
	siteId?: number;
	minimumDigitalBudget: number = 0;
	site!: Site;
	allUSelects: USelectMethod[] = [];
	distributionId?: number;
	distribution?: Distribution;
	eddmDistribution?: EddmDistribution;
	snapAdmailDistribution?: SnapAdMailDistribution;
	siteProducts: WLProduct[] = [];
	allowedProducts: WLProduct[] = [];
	allProductOptions: ProductOptionCategory[] = [];
	printMethodOptions = new Map<number, ProductPrintMethodOption[]>();
	productOptions = new Map<number, ProductPrintMethodOption[]>();
	productDeliveryDates = new Map<number, Date>();
	numberOfDrops = 1;
	cartForm: UntypedFormGroup;
	submitted = false;
	uploadedFrontFiles = new Map<number, File>();
	uploadedBackFiles = new Map<number, File>();
	customerTableAddress!: CustomerAddress;
	showDesign: boolean = true;
	cartProductId?: string;
	orderItemCartProduct?: CartProduct;
	productPricing: ProductPricingResponse[] = [];
	productAdded = false;
	cartItemToEdit?: AddItemToCartQuery;
	fbAdsProductIds = [
		{ type: 'singleImage', productId: 272 },
		{ type: 'carousel', productId: 273 }
	];
	couponValidationResponse?: Coupon;
	disableSaveButton = false;
	addExtraCopiesOnly = false;
	extraCopiesProducts?: WLProduct[];
	extraCopiesCartProduct?: CartProduct;
	countryCode: 'US' | 'Canada' = 'US';
	selectableDistributions: Distribution[] = [];
	usedAudienceLimiter = false;
	modalRef: any;
	selectedDistModalMessage = '';
	originalDistId?: number;
	newDistId?: number;
	orderDate?: Date;
	cartData?: CartProduct[];
	allCartData?: CartProduct[];

	focus$ = new Subject<string>();
	click$ = new Subject<string>();

	@Input() order?: Order;
	@Input() orderItemId?: number;
	@Input() newOrderItem: boolean = false;
	@Input() hasLines: boolean = false;
	@Output() orderItemProcessed = new EventEmitter<boolean>();
	@Output() cancelUpdate = new EventEmitter<boolean>();
	@ViewChild('instance', { static: true }) instance: NgbTypeahead | undefined;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private customerService: CustomerService,
		private sitesService: SitesService,
		private distributionsService: DistributionsService,
		private toastService: ToastService,
		private orderService: OrderService,
		private changeOrdersService: ChangeOrdersService,
		private salesApiService: SalesApiService,
		private pricingService: PricingService,
		private productsService: ProductsService,
		private siteConfigService: SiteConfigService,
		private modalService: NgbModal) {
		this.cartForm = formBuilder.group({
			products: this.formBuilder.array([])
		});
	}

	async ngOnInit(): Promise<void> {
		if (!!this.order) {
			this.customerId = this.order?.customerId;
			this.siteId = this.order?.affiliateId;
			this.showSpinner = true;
			await this.getOrderCartProducts();
			try {
				this.distributionId = this.orderItemCartProduct?.distributionId ?? this.allCartData?.find(c => c.distributionId !== 0)?.distributionId ?? 0;
				[this.customer, this.site, this.distribution] = await Promise.all([
					this.customerService.getCustomer(this.customerId!),
					this.sitesService.getSite(this.siteId!),
					this.distributionId ? this.distributionsService.getDistribution(this.distributionId) : undefined
				]);
				await this.getCustomerDistributions();
				if (this.distribution && this.orderItemCartProduct) {
					const distUSelect = this.distribution.uSelectId as USelect;
					this.usedAudienceLimiter = (USelect.IsAddressedMail(distUSelect)) && (this.orderItemCartProduct.quantity < this.distribution.totalDeliveries);
				}
				this.allUSelects = await this.sitesService.getAllUSelect();
				this.allProductOptions = await this.productsService.getAllProductOptions();
				this.initializeStartDate();
				await this.loadInitialData();
				if (this.addExtraCopiesOnly) {
					const distDMProducts = this.siteProducts.filter(sp => sp.baseProduct?.uSelectConfigurations![0].uSelectId === this.distribution?.uSelectId);
					this.allowedProducts = this.siteProducts.filter(x => x.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies && distDMProducts.some(dm => dm.baseProduct?.paperHeight === x.baseProduct?.paperHeight && dm.baseProduct?.paperWidth === x.baseProduct?.paperWidth));
				}
				else {
					this.allowedProducts = this.siteProducts.filter(x => x.baseProduct?.uSelectConfigurations![0].uSelectId !== USelect.extraCopies && x.baseProduct?.uSelectConfigurations![0].uSelectId !== USelect.printAndShip);
				}
				this.updateAllowedProducts();
			}
			catch {
				this.toastService.showError('There was a problem loading the component', 'Load Error');
			}
			finally {
				this.showSpinner = false;
			}
			if (this.newOrderItem) {
				this.isDisabled = (ngbDate: NgbDateStruct) => {
					const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
					const isHoliday = this.calendar?.holidays?.find(x => x.toDateString() === date.toDateString()) !== undefined;
					const designType: USelectDesignType = this.products.at(0).get('design')?.value;
					const designDeliveryOption = this.calendar?.designDeliveryOption?.find(x => x.designType === designType)?.standardDeliveryOptions;
					const hasDate = designDeliveryOption?.findIndex(x => x.firstInHomeDate.toISOString() === date.toISOString()) !== -1;
					return !hasDate || isHoliday;
				};
			}
		}
	}

	async getOrderCartProducts() {
		this.cartData = [];
		this.allCartData = [];
		for (let i = 0; i < this.order!.items!.length; i++) {
			const cartProduct = await this.orderService.getOrderItemCartProduct(this.order!.items![i].orderItemId);
			if (this.order!.items![i].orderItemId === this.orderItemId) {
				this.orderItemCartProduct = cartProduct;
				this.distributionId = cartProduct.distributionId;
			}
			if (!this.order!.items![i].deleted) {
				this.cartData.push(cartProduct);
			}
			this.allCartData?.push(cartProduct);
		}
	}

	async getCustomerDistributions() {
		const orderUSelect = this.distribution?.uSelectId as USelect;
		let distUSelect: USelectType | undefined = USelectType.EDDM;
		switch (orderUSelect) {
			case USelect.eddm:
				distUSelect = USelectType.EDDM;
				break;
			case USelect.uploadYourList:
				distUSelect = USelectType.UploadYourList;
				break;
			case USelect.buildYourList:
				distUSelect = USelectType.BuildYourList;
				break;
			case USelect.snapAdmail:
				distUSelect = USelectType.SnapAdMail;
				break;
			case USelect.uploadPoliticalList:
				distUSelect = USelectType.UploadPoliticalList;
				break;
			case USelect.buildPoliticalList:
				distUSelect = USelectType.BuildPoliticalList;
				break;
			case USelect.b2B:
				distUSelect = USelectType.B2B;
				break;
			case USelect.equifaxB2B:
				distUSelect = USelectType.EquifaxB2B;
				break;
			default:
				distUSelect = undefined;
		}
		const query = new GetCustomerDistributionsQuery({
			customerId: this.customerId!,
			name: '',
			uSelect: distUSelect,
			showDeleted: false,
			pagination: new PaginationQuery({
				pageNo: 1,
				pageSize: 200
			})
		});
		this.selectableDistributions = await this.distributionsService.getAllDistributions(query);
	}

	getOrderDistributionId(): number {
		const orderDistributionId = this.cartData?.find(item => item.distributionId > 0)?.distributionId ?? 0;
		return orderDistributionId;
	}

	initializeStartDate() {
		const today = new Date();
		const newDate = today.setDate(today.getDate() + 3);
		this.today = formatDate(newDate, 'yyyy-MM-dd', 'en');
		this.endDateStart = formatDate(newDate, 'yyyy-MM-dd', 'en');
	}

	async loadInitialData() {
		this.siteProducts = await this.sitesService.getSiteProducts(this.siteId!);
		this.siteProducts.map(x => {
			x.baseProduct!.priceMatrix = x.baseProduct?.priceMatrix?.sort((a, b) => a.minQty - b.minQty);
		});
		if (!!this.distribution) {
			await this.initializeFormBasedonUSelect();
		}

		if (this.orderItemId && this.orderItemCartProduct) {
			// gross
			const orderDate = this.order?.created.toLocaleDateString();
			this.orderDate = new Date(Date.parse(orderDate!));

			this.addExtraCopiesOnly = this.orderItemCartProduct.uSelectId === USelect.extraCopies;
			if (this.addExtraCopiesOnly) {
				this.allowedProducts = this.siteProducts.filter(x => x.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies);
			}
			this.cartItemToEdit = await this.populateFields(this.orderItemCartProduct);
			await this.editCartProduct(this.cartItemToEdit);
			// update allowed products
			this.updateAllowedProducts();
		}
		else {
			this.addProduct();
			if (!!this.distribution) {
				this.distributionSelected(0, this.distributionId!);
			}
			else {
				this.distributionSelected(0, 0);
			}
		}
		this.updateAllowedProducts();
	}

	selectReturnAddress(value: any, i: number) {
		const productForm = this.products.at(i) as UntypedFormGroup;
		productForm.controls.returnAddress.setValue(value);
	}

	selectShippingAddress(value: any, i: number) {
		const productForm = this.products.at(i) as UntypedFormGroup;
		productForm.controls.shippingAddress.setValue(value);
	}

	addressFormatter = (x: { address1: string }) => x.address1;

	async initializeFormBasedonUSelect() {
		this.filterProductsBasedOnDistribution();
		this.allowedProducts = this.siteProducts.filter(x => x.baseProduct?.uSelectConfigurations![0].uSelectId !== USelect.printAndShip);
		if (this.distribution!.uSelectId === USelect.eddm) {
			this.eddmDistribution = await this.distributionsService.getEddmDistribution(this.customerId!, this.distributionId!);
			this.numberOfDrops = this.eddmDistribution.carrierRoutes?.length!;
		}
		if (this.distribution!.uSelectId === USelect.snapAdmail) {
			this.snapAdmailDistribution = await this.distributionsService.getSnapAdmailDistribution(this.customerId!, this.distributionId!);
			this.numberOfDrops = this.snapAdmailDistribution.letterCarrierWalks?.length! > 4 ? 4 : this.snapAdmailDistribution.letterCarrierWalks?.length!;
		}
	}

	get f() {
		return this.cartForm.controls;
	}

	get products() {
		return this.cartForm.controls.products as UntypedFormArray;
	}

	get options() {
		const opt = ((this.cartForm.controls.products as UntypedFormArray).get('options') as UntypedFormArray);
		return opt.controls;
	}

	getProductOptions(index: number): string[] {
		const options = (this.cartForm.controls.products as UntypedFormArray).at(index).get('options') as UntypedFormArray;
		if (options !== null) {
			return Object.keys(options.controls);
		}
		else {
			return [];
		}
	}

	filterProductsBasedOnDistribution() {
		if (!!this.distributionId && this.distributionId > 0) {
			const siteDirectMailProducts = this.siteProducts.filter(x => DirectMailProducts.findIndex(a => a === x.baseProduct?.uSelectConfigurations![0].uSelectId) !== -1);
			const directMailProducts: number[] = [];
			const distributionProducts = this.siteProducts.filter(x => x.baseProduct?.uSelectConfigurations![0].uSelectId === this.distribution?.uSelectId ||
				x.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies);
			distributionProducts.map(p => directMailProducts.push(p.baseProductId!));
			const siteNonDirectMailProducts = this.siteProducts.filter(x => DirectMailProducts.findIndex(a => a === x.baseProduct?.uSelectConfigurations![0].uSelectId) === -1);
			const applicableDirectMailProducts = siteDirectMailProducts.filter(x => x.baseProduct?.uSelectConfigurations![0].uSelectId === this.distribution?.uSelectId ||
				x.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.printAndShip || x.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies);
			this.siteProducts = applicableDirectMailProducts.concat(siteNonDirectMailProducts);
		}
	}

	addProduct() {
		const productForm = this.formBuilder.group({
			productId: ['', Validators.required],
			distributionId: ['', Validators.required],
			quantity: [''],
			digitalImpressions: [''],
			budget: [''],
			totalBudget: [''],
			design: [''],
			front: [''],
			back: [''],
			proof: [''],
			deliveryWindow: [''],
			startDate: [''],
			campaignDuration: [''],
			endDate: [''],
			returnAddress: [''],
			impressions: [''],
			drops: [''],
			frequency: [''],
			shippingAddress: [''],
			jobComments: [''],
			areaCode: [''],
			forwardingNumber: [''],
			trackingDuration: [''],
			webLink: [''],
			phoneNumber: [''],
			cartProductId: [''],
			extraCopiesProductId: [''],
			extraCopiesQuantity: [''],
			rushFee: [0]
		}, { validator: checkIfEndDateAfterStartDate });
		this.products.push(productForm);
	}

	deliveryDateSelected(index: number, value: string) {
		this.productDeliveryDates.set(index, new Date(value));
	}

	async populateFields(cartProduct: CartProduct): Promise<AddItemToCartQuery> {
		let quantity = cartProduct.quantity;
		if (cartProduct.uSelectId === USelect.targetedEmail) {
			quantity = quantity / (cartProduct.drops?.orderDrops?.length ?? 1);
		}
		let cartItem: AddItemToCartQuery = new AddItemToCartQuery({
			cartProductId: cartProduct.cartProductId,
			baseProductId: cartProduct.baseProductId,
			distributionId: this.distributionId ?? 0,
			budget: cartProduct.budget,
			quantity,
			jobComments: cartProduct.jobComments,
			meta: cartProduct.meta
		});

		if (cartProduct.attributes !== undefined && cartProduct.attributes.length > 0) {
			cartItem.productOptions = [];

			cartProduct.attributes.forEach(attribute => {
				const optCatId = parseInt(attribute.optCatId!);
				if (optCatId > 0) {
					cartItem.productOptions!.push(new ProductOptionIdentifier({
						optCatId,
						optionId: parseInt(attribute.value!, 10)
					}));
				}
			});
		}
		let postageType = this.getPostageValue(cartItem);
		let impressions = 1;
		if (cartProduct.shippingAddressId) {
			cartItem.shippingAddressId = cartProduct.shippingAddressId;
		}
		if (cartProduct.drops && cartProduct.drops.orderDrops && cartProduct.drops.orderDrops.length) {
			let weeksBetweenDrops = 0;
			if (cartProduct.uSelectId === USelect.targetedEmail) {
				impressions = cartProduct.drops?.orderDrops?.length ?? 1;
			}

			if (cartProduct.drops.orderDrops.length > 1) {
				const firstDrop = cartProduct.drops.orderDrops[0];
				const secondDropDate = cartProduct.drops.orderDrops[1].date!;
				const diffDays = this.getDaysBetweenDrops(firstDrop.date!, secondDropDate);
				weeksBetweenDrops = diffDays / 7;
			}
			if (cartProduct.drops.orderDrops[0].date !== undefined) {
				cartItem.drops = new DropsQuery({
					firstDropDate: cartProduct.drops.orderDrops[0].date,
					weeksBetweenDrops,
					kind: cartProduct.drops.orderDrops.length > 1 && cartProduct.drops.orderDrops[0].total === this.distribution?.totalDeliveries ? DropsKind.MultipleImpressions : DropsKind.SplitDrops,
					numberOfDrops: cartProduct.drops.orderDrops.length,
					endDate: cartProduct.drops.orderDrops[0].endDate ?? null as unknown as Date,
					returnAddressId: cartProduct.drops.returnAddressId
				});
			}
		}

		if (cartProduct.design !== undefined) {
			let selectedDesignOption = USelectDesignType.ProfessionalDesign;
			let frontDesign = cartProduct.design.front ?? new BaseDesign();
			switch (cartProduct.design.uSelectDesign) {
				case USelectDesignType.Upload:
					// set front & back if available
					// proof required
					frontDesign.designSelectionType = selectedDesignOption = USelectDesignType.Upload;
					break;
				case USelectDesignType.Omitted:
					frontDesign.designSelectionType = selectedDesignOption = USelectDesignType.Omitted;
					break;
				case USelectDesignType.ProfessionalDesign:
					frontDesign.designSelectionType = selectedDesignOption = USelectDesignType.ProfessionalDesign;
					break;
				case USelectDesignType.DesignHuddle:
					frontDesign.designSelectionType = selectedDesignOption = USelectDesignType.DesignHuddle;
					break;
				case USelectDesignType.Template:
					frontDesign.designSelectionType = selectedDesignOption = USelectDesignType.Template;
					break;
			}
			cartItem.design = new Design({
				isProofRequired: cartProduct.design.isProofRequired,
				adText: cartProduct.design.adText,
				front: frontDesign,
				back: cartProduct.design.back,
				uSelectDesign: selectedDesignOption
			});
		}

		const pricing: Pricing = {
			total: 0,
			shippingPrice: 0
		};

		const calculator = await this.pricingService.getPriceCalculator(new PriceCalculatorRequest({
			baseProductId: cartItem.baseProductId,
			customerId: this.customerId,
			budget: cartProduct.budget,
			postageType,
			quantity: cartItem.quantity,
			impressions,
			siteId: this.siteId!
		}));
		pricing.total = calculator.total;
		cartItem.rushFee = cartProduct.rushFee ?? 0;
		return cartItem;
	}

	async editCartProduct(addedProduct: AddItemToCartQuery) {
		this.showSpinner = true;
		this.addProduct();
		const product = addedProduct;
		let productForm = this.products.at(0) as UntypedFormGroup;
		productForm.controls.productId.setValue(product.baseProductId);
		await this.productSelected(0);
		productForm = this.products.at(0) as UntypedFormGroup;
		productForm.controls.distributionId.setValue(product.distributionId);
		if (product.budget) {
			productForm.controls.budget.setValue(product.budget);
		}
		else {
			productForm.controls.quantity.setValue(product.quantity);
		}

		if (this.isDailyBudgetProduct(0)) {
			await this.updateImpressions(productForm);
		}
		if (product.productOptions !== undefined) {
			const options = productForm.get('options') as UntypedFormGroup;
			product.productOptions.forEach(z => {
				const productOptionCategory = this.allProductOptions.find(a => a.optCatId === z.optCatId)!;
				let optionName = productOptionCategory.name!;
				options.controls[optionName].setValue(z.optionId);
			});
		}
		if (product.design !== undefined) {
			switch (product.design.uSelectDesign) {
				case USelectDesignType.Upload:
					// set front & back if available
					// proof required
					productForm.controls.design.setValue(USelectDesignType.Upload);
					break;
				case USelectDesignType.Omitted:
					productForm.controls.design.setValue(USelectDesignType.Omitted);
					break;
				case USelectDesignType.ProfessionalDesign:
					// do nothing
					productForm.controls.design.setValue(USelectDesignType.ProfessionalDesign);
					break;
			}
			this.designSelected(0);
		}

		if (product.drops !== undefined && this.isDirectMailProduct(0)) {
			if (product.drops.kind === DropsKind.MultipleImpressions) {
				productForm.controls.impressions.setValue(product.drops.numberOfDrops.toString());
				productForm.controls.drops.setValue('1');
				this.impressionsChanged(0);
			}
			else {
				productForm.controls.impressions.setValue('1');
				this.impressionsChanged(0);

				productForm.controls.drops.setValue(product.drops.numberOfDrops.toString());
				this.dropsChanged(0);
			}
			productForm.controls.frequency.setValue(product.drops.weeksBetweenDrops.toString());
			productForm.controls.deliveryWindow.setValue(product.drops.firstDropDate.toISOString());
			this.deliveryDateSelected(0, product.drops.firstDropDate.toISOString());
			const dropDate = new Date(product.drops.firstDropDate.getFullYear(), product.drops.firstDropDate.getMonth(), product.drops.firstDropDate.getDate());
			const deliveryOptions = this.calendar?.designDeliveryOption?.find(d => d.designType === product.design?.uSelectDesign)?.standardDeliveryOptions;
			const foundDeliveryOption = deliveryOptions?.find(o => {
				const formattedDate = new Date(o.firstAtPostOfficeDate.getFullYear(), o.firstAtPostOfficeDate.getMonth(), o.firstAtPostOfficeDate.getDate());
				if (formattedDate.toISOString() === dropDate.toISOString()) {
					return o;
				}
				else {
					return undefined;
				}
			});
			const date = foundDeliveryOption?.firstInHomeDate!;
			this.onDateSelection(new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate()));
		}
		if (product.drops !== undefined && this.isDigitalProduct(0)) {
			const firstDropDate = new Date(product.drops.firstDropDate.toISOString().slice(0, 10) + "T00:00:00-1000");
			productForm.controls.startDate.setValue(formatDate(firstDropDate, 'yyyy-MM-dd', 'en'));
			if (this.isEmail(0)) {
				productForm.controls.endDate.setValue(formatDate(firstDropDate, 'yyyy-MM-dd', 'en'));
				productForm.controls.impressions.setValue(product.drops.numberOfDrops.toString());
				productForm.controls.drops.setValue('1');
				this.impressionsChanged(0);
				productForm.controls.frequency.setValue(product.drops.weeksBetweenDrops.toString());
			}
			else {
				const endDate = new Date(product.drops.endDate?.toISOString().slice(0, 10) + "T00:00:00-1000");
				productForm.controls.endDate.setValue(formatDate(endDate, 'yyyy-MM-dd', 'en'));
				const diffDays = Math.round(numDaysBetween(endDate, firstDropDate) + 1);
				productForm.controls.campaignDuration.setValue(diffDays);
				productForm.controls.totalBudget.setValue(diffDays * productForm.controls.budget.value);
			}
		}
		if (this.isDirectMailProduct(0) && !this.isSnapAdMailProduct(0)) {
			productForm.controls.returnAddress.setValue(product.drops?.returnAddressId);
		}
		if (this.isDirectMailProduct(0) || this.isExtraCopies(0)) {
			this.extraCopiesCartProduct = this.cartData?.find(data => data.uSelectId === USelect.extraCopies) ?? undefined;
			if (!!this.extraCopiesCartProduct) {
				productForm.controls.extraCopiesProductId.setValue(this.extraCopiesCartProduct.baseProductId);
				productForm.controls.extraCopiesQuantity.setValue(this.extraCopiesCartProduct.quantity);
			}
			else {
				const wlProduct = this.siteProducts.find(sp => sp.baseProductId === product.baseProductId);
				const ecProduct = this.siteProducts.find(sp => sp.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies && (sp.baseProduct.paperHeight === wlProduct?.baseProduct?.paperHeight && sp.baseProduct.paperWidth === wlProduct?.baseProduct?.paperWidth));
				if (!!ecProduct) {
					productForm.controls.extraCopiesProductId.setValue(ecProduct.baseProductId);
					productForm.controls.extraCopiesQuantity.setValue(0);

					productForm.controls.shippingAddress.removeValidators([Validators.required, Validators.min(1)]);
					productForm.controls.shippingAddress.updateValueAndValidity();
				}
			}
		}
		if (this.isCallTrackingProduct(0)) {
			productForm.controls.areaCode.setValue(product.meta!['AreaCode'].toString());
			productForm.controls.forwardingNumber.setValue(product.meta!['ForwardingNumber'].toString());
			productForm.controls.trackingDuration.setValue(product.quantity.toString());
		}
		if (this.isQrCodeProduct(0)) {
			productForm.controls.webLink.setValue(product.meta!['WebLink'].toString());
			productForm.controls.phoneNumber.setValue(product.meta!['PhoneNumber'].toString());
		}
		if (this.isInformedDeliveryProduct(0)) {
			productForm.controls.webLink.setValue(product.meta!['WebLink'].toString());
		}

		productForm.controls.jobComments.setValue(product.jobComments);
		productForm.controls.productId.disable();
		if (this.isDirectMailProduct(0) && !this.isSnapAdMailProduct(0)) {
			productForm.controls.returnAddress.disable();
		}
		if (this.isDirectMailProduct(0) && !this.isPMProduct(0)) {
			productForm.controls.quantity.disable();
		}
		if (this.isDirectMailProduct(0) || this.isDigitalProduct(0)) {
			const rushFee = !!product.rushFee ? (product.rushFee > 0 ? product.rushFee : 0) : 0;
			productForm.controls.rushFee.setValue(rushFee);
			productForm.controls.rushFee.setValidators(Validators.min(0));
			productForm.controls.rushFee.updateValueAndValidity();
		}
		productForm.controls.front.disable();
		productForm.controls.back.disable();
		this.showSpinner = false;
	}

	getDaysBetweenDrops(firstDropDate: Date, secondDropDate: Date): number {
		return Math.round(numDaysBetween(secondDropDate, firstDropDate));
	}

	async addProductToCart(productIndex: number): Promise<AddItemToCartQuery> {
		const productForm = this.products.at(productIndex) as UntypedFormGroup;
		const baseProductId = parseInt(productForm.controls.productId.value);
		const wlProduct = this.siteProducts.find(p => p.baseProductId === baseProductId);

		let query: AddItemToCartQuery = new AddItemToCartQuery();
		switch (wlProduct?.baseProduct?.uSelectConfigurations![0].uSelectId) {
			case USelect.eddm:
			case USelect.snapAdmail:
			case USelect.buildYourList:
			case USelect.uploadYourList:
			case USelect.equifaxB2B:
			case USelect.b2B:
				query = await this.buildPrintProductQuery(productIndex, productForm);
				break;
			case USelect.extraCopies:
				query = await this.buildExtraCopiesProductQuery(productIndex, productForm);
				break;
			case USelect.displayAds:
			case USelect.facebookAds:
			case USelect.linkedinAds:
			case USelect.hulu:
			case USelect.instagram:
			case USelect.spotify:
			case USelect.tiktok:
			case USelect.nextdoorAds:
				query = await this.buildDigitalProductQuery(productIndex, productForm);
				break;

			case USelect.targetedEmail:
				query = await this.buildEmailProduct(productIndex, productForm);
				break;

			case USelect.callTracking:
				query = this.buildCallTrackingProduct(productIndex, productForm);
				break;

			case USelect.qrCode:
				query = this.buildQrCodeProduct(productForm);
				break;

			case USelect.customFee:
				query = this.buildDefaultBudgetProductQuery(productForm);
				break;

			case USelect.informedDelivery:
				query = this.buildInformedDeliveryProduct(productForm);
				break;
			case USelect.purchasedList:
				query = this.buildElectronicDeliveryProductQuery(productIndex, productForm);
				break;
		}
		return query;
	}

	buildProductOptions(productIndex: number): ProductOptionIdentifier[] {
		const productOptions: ProductOptionIdentifier[] = [];
		const selectedOptions = this.products.at(productIndex)?.get('options');
		if (selectedOptions) {
			const options = selectedOptions as UntypedFormArray;
			Object.keys(options.controls).forEach(key => {
				const optionValue = selectedOptions.get(key)?.value;
				const productOptionCategory = this.allProductOptions.find(x => x.options?.findIndex(o => o.optionId === optionValue) !== -1)!;
				productOptions.push(new ProductOptionIdentifier({
					optCatId: productOptionCategory.optCatId,
					optionId: optionValue
				}));
			});
		}
		return productOptions;
	}

	async buildDesign(productIndex: number, productForm: UntypedFormGroup): Promise<Design | undefined> {
		let design: Design | undefined;

		if (productForm.controls.design !== undefined && (productForm.controls.design.value as USelectDesignType).length > 1) {
			switch (productForm.controls.design.value) {
				case USelectDesignType.Upload:
					const frontFile = this.uploadedFrontFiles.get(productIndex) !== undefined ? this.uploadedFrontFiles.get(productIndex) : undefined;
					const backFile = this.uploadedBackFiles.get(productIndex) !== undefined ? this.uploadedBackFiles.get(productIndex) : undefined;
					const maxFileSize = 25 * 1024 * 1024; // 25MB Max file size
					let frontFileServerName = '';
					let backFileServerName = '';
					if (frontFile !== undefined && frontFile.size <= maxFileSize) {
						frontFileServerName = await this.salesApiService.uploadTemporaryFile({
							fileName: frontFile.name,
							data: frontFile
						});
					}
					if (backFile !== undefined && backFile.size <= maxFileSize) {
						backFileServerName = await this.salesApiService.uploadTemporaryFile({
							fileName: backFile.name,
							data: backFile
						});
					}
					design = new Design({
						isProofRequired: true,
						front: new BaseDesign({
							fileName: frontFile ? frontFile.name : (this.cartItemToEdit ? this.cartItemToEdit?.design?.front?.fileName : ''),
							fileType: frontFile ? '.' + frontFile.name.split('.').pop() : (this.cartItemToEdit ? this.cartItemToEdit?.design?.front?.fileType : ''),
							realFileName: frontFileServerName !== '' ? frontFileServerName : (this.cartItemToEdit ? this.cartItemToEdit?.design?.front?.realFileName : ''),
							thumb: this.cartItemToEdit ? this.cartItemToEdit?.design?.front?.thumb : '',
							designSelectionType: USelectDesignType.Upload
						}),
						back: new BaseDesign({
							fileName: backFile ? backFile.name : (this.cartItemToEdit ? this.cartItemToEdit?.design?.back?.fileName : ''),
							fileType: backFile ? '.' + backFile.name.split('.').pop() : (this.cartItemToEdit ? this.cartItemToEdit?.design?.back?.fileType : ''),
							realFileName: backFileServerName !== '' ? backFileServerName : (this.cartItemToEdit ? this.cartItemToEdit?.design?.back?.realFileName : ''),
							thumb: this.cartItemToEdit ? this.cartItemToEdit.design?.back?.thumb : '',
							designSelectionType: USelectDesignType.Upload
						}),
						uSelectDesign: USelectDesignType.Upload
					});
					break;

				case USelectDesignType.Omitted:
					design = new Design({
						isProofRequired: false,
						front: new BaseDesign({
							designSelectionType: USelectDesignType.Omitted
						}),
						uSelectDesign: USelectDesignType.Omitted
					});
					break;
				case USelectDesignType.ProfessionalDesign:
					design = new Design({
						isProofRequired: false,
						front: new BaseDesign({
							designSelectionType: USelectDesignType.ProfessionalDesign
						}),
						uSelectDesign: USelectDesignType.ProfessionalDesign
					});
					break;
				default:
					design = new Design({
						isProofRequired: false,
						front: new BaseDesign({
							designSelectionType: USelectDesignType.ProfessionalDesign
						}),
						uSelectDesign: USelectDesignType.ProfessionalDesign
					});
					break;
			}
		}

		return design;
	}
	async buildPrintProductQuery(productIndex: number, productForm: UntypedFormGroup): Promise<AddItemToCartQuery> {
		const baseProductId = parseInt(productForm.controls.productId.value, 10);

		const selectedDistribution = this.selectableDistributions.find(dist => dist.distributionId === productForm.get('distributionId')?.value);
		const isMultipleImpressions = productForm.controls.impressions.value !== '1';
		let numberOfDrops = 1;
		if (isMultipleImpressions && productForm.controls.impressions.value.length > 0) {
			numberOfDrops = parseInt(productForm.controls.impressions.value, 10);
		}
		else if (!isMultipleImpressions && productForm.controls.drops.value.length > 0) {
			numberOfDrops = parseInt(productForm.controls.drops.value, 10);
		}

		const dropFrequency = productForm.controls.frequency.value === '' ? 0 : parseInt(productForm.controls.frequency.value, 10);
		const wlProduct = this.siteProducts.find(p => p.baseProductId === baseProductId)!;
		const existingCartProduct = this.cartData?.find(x => x.uSelectId === wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId);

		let quantity = parseInt(productForm.controls.quantity.value);
		if (wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.eddm || wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.snapAdmail) {
			quantity = selectedDistribution?.totalDeliveries!;
		}
		else {
			quantity = quantity > 0 ? quantity : selectedDistribution?.totalDeliveries!;
		}

		const rushFee = productForm.controls.rushFee.value;

		const returnAddress = parseInt(productForm.controls.returnAddress?.value ?? '0');
		const printProductQuery: AddItemToCartQuery = new AddItemToCartQuery({
			cartProductId: existingCartProduct ? existingCartProduct.cartProductId : undefined,
			baseProductId,
			quantity,
			distributionId: selectedDistribution?.distributionId ?? 0,
			shippingAddressId: 0,
			design: await this.buildDesign(productIndex, productForm),
			drops: new DropsQuery({
				firstDropDate: this.productDeliveryDates.get(productIndex)!,
				kind: isMultipleImpressions && numberOfDrops > 1 ? DropsKind.MultipleImpressions : DropsKind.SplitDrops,
				numberOfDrops,
				weeksBetweenDrops: dropFrequency,
				endDate: undefined as unknown as Date,
				returnAddressId: returnAddress
			}),
			jobComments: productForm.controls.jobComments.value,
			productOptions: this.buildProductOptions(productIndex),
			rushFee: rushFee
		});

		return printProductQuery;
	}

	async buildExtraCopiesProductQuery(productIndex: number, productForm: UntypedFormGroup): Promise<AddItemToCartQuery> {
		const baseProductId = parseInt(productForm.controls.extraCopiesProductId.value, 10);
		const distribution = this.selectableDistributions.find(dist => dist.distributionId === productForm.controls.distributionId.value);
		let quantity = parseInt(productForm.controls.extraCopiesQuantity.value);
		quantity = quantity > 0 ? quantity : distribution?.totalDeliveries!;
		const existingCartProduct = this.cartData?.find(x => x.uSelectId === USelect.extraCopies);
		const shippingAddressId = productForm.controls.shippingAddress.value;

		const printOnlyProductQuery: AddItemToCartQuery = new AddItemToCartQuery({
			cartProductId: existingCartProduct ? existingCartProduct.cartProductId : undefined,
			baseProductId,
			quantity,
			distributionId: distribution?.distributionId && distribution?.distributionId > 0 ? distribution?.distributionId : 0,
			shippingAddressId: shippingAddressId,
			jobComments: productForm.controls.jobComments.value,
			productOptions: await this.buildExtraCopiesProductOptions(baseProductId),
			design: undefined,
			drops: new DropsQuery({
				firstDropDate: this.productDeliveryDates.get(productIndex)!,
				kind: DropsKind.SplitDrops,
				numberOfDrops: 1,
				weeksBetweenDrops: 0,
				endDate: undefined as unknown as Date
			}),
		});

		return printOnlyProductQuery;
	}

	async buildExtraCopiesProductOptions(baseProductId: number) {
		const wlProduct = this.siteProducts.find(sp => sp.baseProductId === baseProductId);
		let printMethodOptions = await this.productsService.getPrintMethodOptions(baseProductId);
		printMethodOptions = printMethodOptions.filter(x => x.printMethodId !== undefined && x.printMethodId === (wlProduct!.baseProduct?.priceMatrix![0].printMethodId ?? 1));
		this.printMethodOptions.set(baseProductId, printMethodOptions);
		let selectedOptions = new Map<number, ProductPrintMethodOption[]>();
		selectedOptions.set(0, printMethodOptions.filter((value, idx, self) =>
			self.findIndex(a => a.option?.optCatId === value.option?.optCatId) === idx));
		const productOptions: ProductOptionIdentifier[] = [];
		if (selectedOptions) {
			Object.keys(selectedOptions).forEach(key => {
				const productOptionCategory = this.allProductOptions.find(x => x.options?.findIndex(o => o.optionId === parseInt(key)) !== -1)!;
				productOptions.push(new ProductOptionIdentifier({
					optCatId: productOptionCategory.optCatId,
					optionId: parseInt(key)
				}));
			});
		}
		return productOptions;
	}

	buildDefaultBudgetProductQuery(productForm: UntypedFormGroup): AddItemToCartQuery {
		const baseProductId = parseInt(productForm.controls.productId.value, 10);
		const existingCartProduct = this.cartData?.find(x => x.baseProductId === baseProductId);
		const distributionId = productForm.controls.distributionId?.value;
		const query = new AddItemToCartQuery({
			cartProductId: existingCartProduct ? existingCartProduct.cartProductId : undefined,
			budget: parseFloat(productForm.controls.budget.value),
			baseProductId,
			distributionId: distributionId ?? 0,
			jobComments: productForm.controls.jobComments.value,
			quantity: 1
		});
		return query;
	}

	buildElectronicDeliveryProductQuery(productIndex: number, productForm: UntypedFormGroup): AddItemToCartQuery {
		const baseProductId = parseInt(productForm.controls.productId.value, 10);
		const wlProduct = this.siteProducts.find(p => p.baseProductId === baseProductId)!;
		const quantity = parseInt(productForm.controls.quantity.value);
		const distributionId = productForm.controls.distributionId?.value;
		const existingCartProduct = this.cartData?.find(x => x.uSelectId === wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId);
		const drops = new DropsQuery({
			endDate: null as unknown as Date,
			firstDropDate: null as unknown as Date,
			kind: DropsKind.SplitDrops,
			numberOfDrops: 0,
			returnAddressId: 0,
			weeksBetweenDrops: 0
		});
		const query = new AddItemToCartQuery({
			cartProductId: existingCartProduct ? existingCartProduct.cartProductId : undefined,
			baseProductId,
			quantity,
			distributionId: distributionId ?? 0,
			jobComments: productForm.controls.jobComments.value,
			productOptions: this.buildProductOptions(productIndex),
			drops
		});
		return query;
	}

	buildDefaultProductQuery(productForm: UntypedFormGroup): AddItemToCartQuery {
		const baseProductId = parseInt(productForm.controls.productId.value, 10);
		const wlProduct = this.siteProducts.find(p => p.baseProductId === baseProductId)!;
		const distributionId = productForm.controls.distributionId?.value;
		const existingCartProduct = this.cartData?.find(x => x.uSelectId === wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId);
		const query = new AddItemToCartQuery({
			cartProductId: existingCartProduct ? existingCartProduct.cartProductId : undefined,
			baseProductId,
			distributionId: distributionId ?? 0,
			jobComments: productForm.controls.jobComments.value,
			quantity: 1
		});
		return query;
	}

	async buildDigitalProductQuery(productIndex: number, productForm: UntypedFormGroup): Promise<AddItemToCartQuery> {
		const baseProductId = parseInt(productForm.controls.productId.value, 10);
		const wlProduct = this.siteProducts.find(p => p.baseProductId === baseProductId)!;
		const distributionId = productForm.controls.distributionId?.value;
		const existingCartProduct = this.cartData?.find(x => x.uSelectId === wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId);
		let startDate = new Date(productForm.controls.startDate.value);
		let endDate = new Date(productForm.controls.endDate.value);
		const jobComments = productForm.controls.jobComments.value;
		const rushFee = productForm.controls.rushFee.value;

		const digitalProductQuery = new AddItemToCartQuery({
			cartProductId: existingCartProduct ? existingCartProduct.cartProductId : undefined,
			baseProductId,
			budget: parseFloat(productForm.controls.budget.value),
			distributionId: distributionId ?? 0,
			jobComments,
			quantity: 0,
			design: await this.buildDesign(productIndex, productForm),
			drops: new DropsQuery({
				firstDropDate: startDate,
				kind: DropsKind.MultipleImpressions,
				numberOfDrops: 1,
				weeksBetweenDrops: 1,
				endDate
			}),
			rushFee: rushFee
		});

		return digitalProductQuery;
	}

	async buildEmailProduct(productIndex: number, productForm: UntypedFormGroup): Promise<AddItemToCartQuery> {
		const baseProductId = parseInt(productForm.controls.productId.value, 10);
		const wlProduct = this.siteProducts.find(p => p.baseProductId === baseProductId)!;
		const distributionId = productForm.controls.distributionId?.value;
		const existingCartProduct = this.cartData?.find(x => x.uSelectId === wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId);
		const emailDropDate = new Date(productForm.controls.startDate.value);
		let quantity = parseInt(productForm.controls.quantity.value);
		const isMultipleImpressions = productForm.controls.impressions.value !== '1';
		let numberOfDrops = 1;
		if (isMultipleImpressions && productForm.controls.impressions.value.length > 0) {
			numberOfDrops = parseInt(productForm.controls.impressions.value, 10);
		}
		else if (!isMultipleImpressions && productForm.controls.drops.value.length > 0) {
			numberOfDrops = parseInt(productForm.controls.drops.value, 10);
		}
		const dropFrequency = productForm.controls.frequency.value === '' ? 0 : parseInt(productForm.controls.frequency.value, 10);

		const emailProduct = new AddItemToCartQuery({
			cartProductId: existingCartProduct ? existingCartProduct.cartProductId : undefined,
			baseProductId,
			distributionId: distributionId ?? 0,
			jobComments: productForm.controls.jobComments.value,
			quantity,
			design: await this.buildDesign(productIndex, productForm),
			drops: new DropsQuery({
				firstDropDate: emailDropDate,
				kind: DropsKind.MultipleImpressions,
				numberOfDrops,
				weeksBetweenDrops: dropFrequency,
			})
		});
		return emailProduct;
	}

	buildCallTrackingProduct(productIndex: number, productForm: UntypedFormGroup): AddItemToCartQuery {
		const meta = {
			"AreaCode": productForm.controls.areaCode.value.toString(),
			"ForwardingNumber": productForm.controls.forwardingNumber.value
		};
		const baseProductId = parseInt(productForm.controls.productId.value, 10);
		const distributionId = productForm.controls.distributionId?.value;
		const wlProduct = this.siteProducts.find(p => p.baseProductId === baseProductId)!;
		const existingCartProduct = this.cartData?.find(x => x.uSelectId === wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId);
		return new AddItemToCartQuery({
			cartProductId: existingCartProduct ? existingCartProduct.cartProductId : undefined,
			baseProductId,
			distributionId: distributionId ?? 0,
			jobComments: productForm.controls.jobComments.value,
			quantity: productForm.controls.trackingDuration.value,
			meta: meta,
			productOptions: this.buildProductOptions(productIndex)
		});
	}

	buildQrCodeProduct(productForm: UntypedFormGroup): AddItemToCartQuery {
		const meta = {
			"WebLink": productForm.controls.webLink.value.toString(),
			"PhoneNumber": productForm.controls.phoneNumber.value
		};
		const baseProductId = parseInt(productForm.controls.productId.value, 10);
		const wlProduct = this.siteProducts.find(p => p.baseProductId === baseProductId)!;
		const distributionId = productForm.controls.distributionId?.value;
		const existingCartProduct = this.cartData?.find(x => x.uSelectId === wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId);
		return new AddItemToCartQuery({
			cartProductId: existingCartProduct ? existingCartProduct.cartProductId : undefined,
			baseProductId,
			distributionId: distributionId ?? 0,
			jobComments: productForm.controls.jobComments.value,
			quantity: 1,
			meta: meta
		});
	}

	buildInformedDeliveryProduct(productForm: UntypedFormGroup): AddItemToCartQuery {
		const meta = {
			"WebLink": productForm.controls.webLink.value.toString()
		};
		const baseProductId = parseInt(productForm.controls.productId.value, 10);
		const distributionId = productForm.controls.distributionId?.value;
		const wlProduct = this.siteProducts.find(p => p.baseProductId === baseProductId)!;
		const existingCartProduct = this.cartData?.find(x => x.uSelectId === wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId);
		return new AddItemToCartQuery({
			cartProductId: existingCartProduct ? existingCartProduct.cartProductId : undefined,
			baseProductId,
			distributionId: distributionId ?? 0,
			jobComments: productForm.controls.jobComments.value,
			quantity: 1,
			meta: meta
		});
	}

	async saveProduct(productIndex: number) {
		this.showSpinner = true;
		this.submitted = true;
		if ((this.isDirectMailProduct(productIndex) || this.isExtraCopies(productIndex)) && (this.products.at(productIndex) as UntypedFormGroup).controls.extraCopiesQuantity.value === '') {
			this.showSpinner = false;
			return;
		}
		const form = this.products.at(productIndex) as UntypedFormGroup;
		if (!form.valid) {
			this.showSpinner = false;
			return;
		}
		const cartProduct = await this.addProductToCart(productIndex);
		let quantity = cartProduct.quantity;
		let budget: number | undefined;
		let datesChanged = false;

		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		const uselectId = this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId;
		if (uselectId === USelect.snapAdmail && (((cartProduct.drops?.numberOfDrops ?? 0) - 1) * (cartProduct.drops?.weeksBetweenDrops ?? 1) > 6)) {
			this.showSpinner = false;
			this.toastService.showError('Total delivery cannot span more than 6 weeks. Please adjust the # of drops or frequency.');
			return;
		}

		if (this.isDailyBudgetProduct(productIndex)) {
			const selectedEndDate = new Date(cartProduct.drops?.endDate!);
			const tempStartDate = new Date(cartProduct.drops?.firstDropDate!);
			tempStartDate.setDate(tempStartDate.getDate() - 1);
			const differenceInTime = selectedEndDate.getTime() - tempStartDate.getTime();
			quantity = Math.round(differenceInTime / (1000 * 3600 * 24));
			budget = cartProduct.budget;
			const dropDate = new Date(this.cartItemToEdit?.drops?.firstDropDate.toISOString().slice(0, 10) + "T00:00:00-1000").getTime();
			const queryDropDate = new Date(cartProduct.drops?.firstDropDate.toISOString().slice(0, 10) + "T00:00:00-1000").getTime();
			const endDate = new Date(this.cartItemToEdit?.drops?.endDate?.toISOString().slice(0, 10) + "T00:00:00-1000").getTime();
			const queryEndDate = new Date(cartProduct.drops?.endDate?.toISOString().slice(0, 10) + "T00:00:00-1000").getTime();
			datesChanged = !(dropDate === queryDropDate && endDate === queryEndDate);
		}
		const query = {
			orderId: this.order?.orderId,
			orderItemId: this.orderItemId ?? 0,
			addItemToCartQuery: cartProduct
		} as AddOrderItemQuery;
		let success = true;
		this.showSpinner = true;
		try {
				if (query.orderItemId === 0 || (!!query.orderItemId && query.orderItemId > 0 && this.hasLines) || ((!!query.orderItemId && query.orderItemId > 0) &&
					!(query.addItemToCartQuery?.quantity === this.cartItemToEdit?.quantity &&
					query.addItemToCartQuery?.distributionId === this.cartItemToEdit?.distributionId &&
					query.addItemToCartQuery?.design?.uSelectDesign === this.cartItemToEdit?.design?.uSelectDesign &&
					query.addItemToCartQuery?.drops?.numberOfDrops === this.cartItemToEdit?.drops?.numberOfDrops &&
					query.addItemToCartQuery?.drops?.weeksBetweenDrops === this.cartItemToEdit?.drops?.weeksBetweenDrops &&
					(this.isDailyBudgetProduct(productIndex) ? budget === this.cartItemToEdit?.budget && quantity === this.cartItemToEdit?.quantity && !datesChanged : true)))) {
						if (query.orderItemId > 0) {
							await this.changeOrdersService.updateOrderItem(query);
						}
						else {
							await this.changeOrdersService.addOrderItem(query);
						}
						this.toastService.showSuccess('Order item added/updated');
				}
				else {
					success = false;
					this.toastService.showInfo('Order has not been changed');
				}

		}
		catch (ex: any) {
			console.log(ex);
			success = false;
			this.toastService.showError('Order item could not be added/updated');
		}
		finally {
			this.showSpinner = false;
		}
		const productForm = this.products.at(productIndex) as UntypedFormGroup;
		if (this.isDirectMailProduct(productIndex) && productForm.get('extraCopiesQuantity')?.value > 0) {
			success = await this.createAndSaveExtraCopiesProduct(productIndex);
		}
		if (success) {
			this.orderItemProcessed.emit(true);
		}
	}

	async createAndSaveExtraCopiesProduct(productIndex: number): Promise<boolean> {
		const productForm = this.products.at(productIndex) as UntypedFormGroup;
		const ecItem = this.cartData?.find(item => item.uSelectId === USelect.extraCopies);
		if (productForm.get('extraCopiesQuantity')?.value === 0 || productForm.get('extraCopiesQuantity')?.value === ecItem?.quantity) {
			return true;
		}

		const cartProduct = await this.buildExtraCopiesProductQuery(productIndex, productForm);
		const query = {
			orderId: this.order?.orderId,
			orderItemId: 0,
			addItemToCartQuery: cartProduct
		} as AddOrderItemQuery;
		if (!!ecItem) {
			const ecIndex = this.cartData?.findIndex(item => item.uSelectId === USelect.extraCopies);
			if (!!ecIndex && ecIndex > -1) {
				const orderItemId = this.order?.items![ecIndex].orderItemId;
				query.orderItemId = orderItemId!;
			}
		}
		let success = true;
		this.showSpinner = true;
		try {
			if (query.orderItemId > 0) {
				await this.changeOrdersService.updateOrderItem(query);
			}
			else {
				await this.changeOrdersService.addOrderItem(query);
			}
		}
		catch (ex: any) {
			console.log(ex);
			success = false;
			this.toastService.showError('Extra copies item could not be added/updated');
			return success;
		}
		finally {
			this.showSpinner = false;
		}
		if (success) {
			this.toastService.showSuccess('Extra copies item added/updated');
		}
		return success;
	}

	getPostageValue(cartProduct: AddItemToCartQuery): PostageType {
		let postageType = PostageType.StandardMail;
		if (cartProduct.productOptions) {
			const postageOption = cartProduct.productOptions.find(x => x.optCatId === ProductCategories.PostageClass);
			if (postageOption && postageOption.optionId === ProductCategoryOptions.FirstClass) {
				postageType = PostageType.FirstClass;
			}
			else if (postageOption && postageOption.optionId === ProductCategoryOptions.NonProfit) {
				postageType = PostageType.NonProfit;
			}
		}
		return postageType;
	}

	updateAllowedProducts() {
		let hasPMProduct = false;
		if (this.cartData) {
			this.cartData.map(p => {
				const product = this.siteProducts.find(s => s.baseProductId === p.baseProductId);

				if (PMProducts.includes(product?.baseProduct?.uSelectConfigurations![0].uSelectId!)) {
					hasPMProduct = true;
				}

				if (this.orderItemCartProduct === undefined) {
					this.allowedProducts = this.allowedProducts.filter(ap =>
						ap.baseProduct?.uSelectConfigurations![0].uSelectId !== product?.baseProduct?.uSelectConfigurations![0].uSelectId);
				}
			});
		}

		if (!hasPMProduct) {
			this.allowedProducts = this.allowedProducts.filter(ap => ap.baseProduct?.uSelectConfigurations![0].uSelectId !== USelect.informedDelivery);
		}
	}

	async productSelected(index: number) {
		this.showSpinner = true;
		let productForm = this.products.at(index) as UntypedFormGroup;
		const productId = parseInt(productForm.get('productId')!.value, 10);
		let distributionId = productForm.get('distributionId')?.value ?? undefined;
		if (!distributionId) {
			distributionId = this.distributionId;
		}
		this.products.clear();
		this.addProduct();
		productForm = this.products.at(index) as UntypedFormGroup;
		productForm.controls.productId.setValue(productId);
		this.distributionSelected(index, distributionId);
		// show product related options
		this.printMethodOptions.clear();
		this.productOptions.clear();
		this.productPricing = [];
		productForm.removeControl('options');
		productForm.controls.design.setValue('');
		productForm.controls.design.enable();
		const wlProduct = this.siteProducts.find(p => p.baseProductId === productId)!;
		let printMethodOptions = await this.productsService.getPrintMethodOptions(productId);
		printMethodOptions = printMethodOptions.filter(x => x.printMethodId !== undefined && x.printMethodId === (wlProduct.baseProduct?.priceMatrix![0].printMethodId ?? 1));
		const nonProfitVal = this.customer!.customerProperties?.find(x => x.propertyName?.includes('NonProfitAuthorizationNumber'))?.propertyValue;
		const isCustomerNonProfit = !!nonProfitVal && nonProfitVal.length > 0;
		if (isCustomerNonProfit) {
			printMethodOptions = printMethodOptions.filter(x => x.optionId !== ProductCategoryOptions.StandardMail);
		}
		else {
			printMethodOptions = printMethodOptions.filter(x => x.optionId !== ProductCategoryOptions.NonProfit);
		}
		this.printMethodOptions.set(productId, printMethodOptions);
		this.productOptions.set(index, printMethodOptions.filter((value, idx, self) =>
			self.findIndex(a => a.option?.optCatId === value.option?.optCatId) === idx));

		const optionsFB = this.formBuilder.group({});
		this.productOptions.get(index)!.forEach(x => {
			let optionName = this.allProductOptions.find(y => y.optCatId === x.option?.optCatId)!.name!;
			const options = this.getOptions(index, optionName);
			if (options.length === 1) {
				optionsFB.addControl(optionName, new UntypedFormControl({ value: options[0].option?.optionId, disabled: true }, Validators.required));
			}
			else {
				optionsFB.addControl(optionName, new UntypedFormControl({ value: '', disabled: false }, Validators.required));
			}
		});
		productForm.addControl('options', optionsFB);

		if (wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.customFee) {
			productForm.get('budget')!.setValidators([
				Validators.required
			]);
			productForm.get('budget')!.updateValueAndValidity();
		}

		if (this.isDigitalProduct(index)) {
			if (this.isDailyBudgetProduct(index)) {
				const minimumSpend = Math.floor((wlProduct!.baseProduct!.priceMatrix![0].minQty! * (wlProduct!.baseProduct!.priceMatrix![0].pricePerThousand! / 1000)));
				this.minimumDigitalBudget = minimumSpend;
				let minBudgetValue = wlProduct!.baseProduct!.priceMatrix![0].pricePerThousand!;
				productForm.get('budget')!.setValidators([
					Validators.required,
					Validators.min(minBudgetValue)
				]);
				productForm.get('totalBudget')!.setValidators([
					Validators.required,
					totalBudgetValidator(minimumSpend)
				]);
				productForm.get('totalBudget')?.updateValueAndValidity();
				productForm.get('budget')!.setValue(minBudgetValue);
				productForm.get('budget')!.updateValueAndValidity();
				await this.updateImpressions(productForm);

				productForm.get('campaignDuration')!.setValidators([
					Validators.required
				]);
				productForm.get('campaignDuration')!.updateValueAndValidity();
			}
			else {
				let minDigitalQty = 0;
				switch (wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId) {
					case USelect.displayAds:
						minDigitalQty = await this.siteConfigService.getNumber(this.siteId!, 'Product', 'DisplayAdMinQty');
						break;
					case USelect.facebookAds:
					case USelect.linkedinAds:
					case USelect.nextdoorAds:
						minDigitalQty = wlProduct.baseProduct?.priceMatrix![0].minQty;
						break;
					case USelect.targetedEmail:
						switch (this.distribution?.uSelectId) {
							case USelect.eddm:
								const emailCount = this.getResidentialCount();
								minDigitalQty = emailCount;
								break;
							case USelect.snapAdmail:
								const caEmailCount = this.getCaResidentialCount();
								minDigitalQty = caEmailCount;
								break;

							default:
								minDigitalQty = Math.round(this.distribution?.totalDeliveries! * 0.5);
								break;
						}
						break;

				}
				if (minDigitalQty > 0) {
					productForm.get('quantity')!.setValue(minDigitalQty);
					if (!this.isEmail(index)) {
						productForm.get('quantity')!.setValidators([
							Validators.required,
							Validators.max(minDigitalQty)
						]);
					}
					else {
						productForm.get('quantity')!.setValidators([
							Validators.required,
						]);
					}
				}
				else {
					productForm.get('quantity')!.setValue('');
					productForm.get('quantity')!.setValidators([
						Validators.required
					]);
				}
				productForm.get('quantity')!.updateValueAndValidity();
			}

			if (this.isOnlyDigitalProducts()) {
				this.makeDesignOptionUploadForDigital(index);
			}
			else {
				this.hideDesignOptionForDigital(index);
			}

			productForm.get('startDate')!.setValidators([
				Validators.required
			]);
			productForm.get('startDate')!.updateValueAndValidity();
		}
		if (this.isExtraCopies(index)) {
			productForm.get('shippingAddress')!.setValidators([Validators.required, Validators.min(1)]);
			productForm.get('shippingAddress')!.updateValueAndValidity();
		}

		if (this.isDirectMailProduct(index) || this.isDigitalProduct(index)) {
			productForm.get('design')!.setValidators([
				Validators.required
			]);
			productForm.get('design')!.updateValueAndValidity();
		}
		else {
			productForm.removeControl('design');
		}
		if (this.isDirectMailProduct(index)) {
			const quantity = parseInt(productForm.get('quantity')?.value ?? '0');
			let postageType = PostageType.StandardMail;
			if (!!productForm.get('options')?.get('Postage Class')) {
				const selectedPostage = productForm.get('options')?.get('Postage Class')!.value;
				if (selectedPostage === ProductCategoryOptions.FirstClass) {
					postageType = PostageType.FirstClass;
				}
				else if (selectedPostage === ProductCategoryOptions.NonProfit) {
					postageType = PostageType.NonProfit;
				}
			}
			if (this.isUS) {
				this.calendar = await this.salesApiService.getUsDates(new GetCalendarRequest({
					siteId: this.siteId!,
					customerId: this.customerId!,
					productId: wlProduct?.baseProductId!,
					quantity: quantity > 0 ? quantity : this.distribution?.totalDeliveries!,
					orderDate: this.orderDate ?? undefined,
					postageType
				}));
			}
			else {
				this.calendar = await this.salesApiService.getCaDates(this.customerId!, new GetCaCalendarRequest({
					siteId: this.siteId!,
					productId: wlProduct?.baseProductId!,
					quantity: quantity > 0 ? quantity : this.distribution?.totalDeliveries!,
					postageType,
					orderDate: this.orderDate ?? undefined,
					distributionId: this.distributionId ?? 0
				}));
			}

			this.inductionTurnaroundTime = await this.salesApiService.getUSelectInductionTurnaround(wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId!);
			this.standardMailDeliveryTime = await this.siteConfigService.getNumber(this.siteId!, 'Site', 'StandardMailDeliveryTime');
			this.firstClassDeliveryTime = await this.siteConfigService.getNumber(this.siteId!, 'Site', 'FirstClassDeliveryTime');
			productForm.get('deliveryWindow')!.setValidators([
				Validators.required
			]);
			productForm.get('deliveryWindow')!.updateValueAndValidity();
			if (this.isSnapAdMailProduct(index)) {
				productForm.removeControl('returnAddress');
			}
			else {
				productForm.get('returnAddress')!.setValidators([
					Validators.required
				]);
				productForm.get('returnAddress')!.updateValueAndValidity();
			}

			if (!this.isPMProduct(index)) {
				productForm.get('quantity')!.removeValidators([
					Validators.required,
					Validators.min(wlProduct.baseProduct?.priceMatrix![0].minQty!),
					Validators.max(this.distribution?.totalDeliveries!)
				]);
				productForm.get('quantity')!.updateValueAndValidity();

				productForm.get('impressions')!.setValidators([
					Validators.required
				]);
				productForm.get('impressions')!.setValue('1');
				productForm.get('impressions')!.updateValueAndValidity();
				this.impressionsChanged(0);

				productForm.get('drops')!.setValue('1');
				productForm.get('drops')!.updateValueAndValidity();
				this.dropsChanged(0);
			}
			productForm.get('impressions')!.setValidators([
				Validators.required
			]);
			productForm.get('impressions')!.setValue('1');
			productForm.get('impressions')!.updateValueAndValidity();
			this.impressionsChanged(0);

			productForm.get('drops')!.setValue('1');
			productForm.get('drops')!.updateValueAndValidity();
			this.dropsChanged(0);

			productForm.get('startDate')!.removeValidators([
				Validators.required
			]);
			productForm.get('startDate')!.updateValueAndValidity();
			productForm.get('campaignDuration')!.removeValidators([
				Validators.required
			]);
			productForm.get('campaignDuration')!.updateValueAndValidity();
		}
		else {
			productForm.removeControl('returnAddress');
			if (!this.isEmail(index)) {
				productForm.removeControl('impressions');
				productForm.removeControl('drops');
				productForm.removeControl('frequency');
			}
			if (!this.isExtraCopies(index)) {
				productForm.removeControl('shippingAddress');
				productForm.removeControl('extraCopiesProductId');
				productForm.removeControl('extraCopiesQuantity');
			}
		}
		if (this.isEmail(index)) {
			productForm.get('impressions')!.setValidators([
				Validators.required
			]);
			productForm.get('impressions')!.setValue('1');
			productForm.get('impressions')!.updateValueAndValidity();
			this.impressionsChanged(0);

			productForm.get('drops')!.setValue('1');
			productForm.get('drops')!.updateValueAndValidity();
			this.dropsChanged(0);
		}
		if (this.isCallTrackingProduct(index)) {
			productForm.get('trackingDuration')?.setValue(1);
			productForm.updateValueAndValidity();
		}
		if (this.isDirectMailProduct(index)) {
			const hasExtraCopiesProduct = this.siteProducts.some(sp => sp.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies && sp.baseProduct.paperHeight === wlProduct?.baseProduct?.paperHeight && sp.baseProduct.paperWidth === wlProduct?.baseProduct?.paperWidth);
			if (!hasExtraCopiesProduct) {
				this.extraCopiesSelected({ productSelected: 0, quantitySelected: 0 });
			}
			if (this.distribution?.uSelectId !== USelect.buildYourList && this.distribution?.uSelectId !== USelect.equifaxB2B) {
				productForm.get('quantity')?.disable();
			}
		}
		if (!this.newOrderItem) {
			productForm.get('options')?.disable();
			if (!!productForm.get('options')?.get('Postage Class')) {
				productForm.get('options')?.get('Postage Class')?.enable();
			}
		}
		this.productAdded = true;
		this.showSpinner = false;
	}

	async quantityUpdated(index: number) {
		let productForm = this.products.at(index) as UntypedFormGroup;
		const productId = parseInt(productForm.get('productId')!.value, 10);
		const wlProduct = this.siteProducts.find(p => p.baseProductId === productId)!;
		const quantity = parseInt(productForm.get('quantity')?.value ?? '0');
		let postageType = PostageType.StandardMail;
		if (!!productForm.get('options')?.get('Postage Class')) {
			const selectedPostage = productForm.get('options')?.get('Postage Class')!.value;
			if (selectedPostage === ProductCategoryOptions.FirstClass) {
				postageType = PostageType.FirstClass;
			}
			else if (selectedPostage === ProductCategoryOptions.NonProfit) {
				postageType = PostageType.NonProfit;
			}
		}
		if (this.isDirectMailProduct(index)) {
			if (this.isUS) {
				this.calendar = await this.salesApiService.getUsDates(new GetCalendarRequest({
					siteId: this.siteId!,
					customerId: this.customerId!,
					productId: wlProduct?.baseProductId!,
					quantity: quantity > 0 ? quantity : this.distribution?.totalDeliveries!,
					orderDate: this.orderDate ?? undefined,
					postageType
				}));
			}
			else {
				this.calendar = await this.salesApiService.getCaDates(this.customerId!, new GetCaCalendarRequest({
					siteId: this.siteId!,
					productId: wlProduct?.baseProductId!,
					quantity: quantity > 0 ? quantity : this.distribution?.totalDeliveries!,
					postageType,
					orderDate: this.orderDate ?? undefined,
					distributionId: this.distributionId ?? 0
				}));
			}
		}
	}

	openSelectedDistributionModal(index: number, distributionId: string, modal: any) {
		let productForm = this.products.at(0) as UntypedFormGroup;
		const impressions = parseInt(productForm.get('impressions')?.value);
		const originalQuantity = this.orderItemCartProduct?.quantity!;
		const selectedDistribution = this.selectableDistributions.find(dist => dist.distributionId === parseInt(distributionId));
		const totalDeliveries = selectedDistribution?.totalDeliveries!;
		const newQuantity = impressions > 1 ? totalDeliveries * impressions : totalDeliveries;
		if (this.isDirectMailProduct(index) && !(this.isPMProduct(index) && this.usedAudienceLimiter && (this.orderItemCartProduct?.quantity ?? 0) < selectedDistribution?.totalDeliveries!)) {
			if (newQuantity > originalQuantity) {
				this.selectedDistModalMessage = `Changing this item's distribution will increase the order item quantity from ${originalQuantity} to ${newQuantity}`;
			}
			if (newQuantity < originalQuantity) {
				this.selectedDistModalMessage = `Changing this item's distribution will decrease the order item quantity from ${originalQuantity} to ${newQuantity}`;
			}
		}
		else {
			this.selectedDistModalMessage = `Changing this item's distribution will cause no change to the order item quantity`;
		}
		this.newDistId = parseInt(distributionId);
		this.openModal(modal);
	}

	cancelChangeDistribution() {
		let productForm = this.products.at(0) as UntypedFormGroup;
		productForm.get('distributionId')?.setValue(this.orderItemCartProduct?.distributionId);
		this.newDistId = undefined;
		this.closeModal();
	}

	openModal(modal: any) {
		this.modalRef = this.modalService.open(modal);
	}

	closeModal() {
		this.modalService.dismissAll();
	}

	distributionSelected(index: number, distributionId: number) {
		let productForm = this.products.at(index) as UntypedFormGroup;
		const originalDistId = productForm.get('distributionId')?.value;
		if (distributionId === originalDistId) {
			return;
		}
		this.distribution = this.selectableDistributions.find(dist => dist.distributionId === distributionId)!;
		if (this.isDirectMailProduct(0) && productForm.controls.impressions.value > 1) {
			const impressions = parseInt(productForm.controls.impressions.value);
			const quantity = this.distribution?.totalDeliveries * impressions;
			productForm.get('quantity')?.setValue(quantity);
		}
		else if (this.isDirectMailProduct(index) || this.isEmail(index)) {
			productForm.get('quantity')?.setValue(this.distribution?.totalDeliveries);
		}
		else if (this.isPMProduct(index) && this.usedAudienceLimiter && (this.orderItemCartProduct?.quantity ?? 0) < this.distribution?.totalDeliveries!) {
			productForm.get('quantity')?.setValue(this.orderItemCartProduct?.quantity);
		}
		productForm.get('distributionId')?.setValue(distributionId);

		productForm.get('distributionId')!.updateValueAndValidity();
		productForm.get('quantity')!.updateValueAndValidity();
		if (!this.isDailyBudgetProduct(index)) {
			this.dropsChanged(index);
		}
		this.closeModal();
	}

	getResidentialCount(): number {
		let residentialCount = 0;
		this.eddmDistribution?.carrierRoutes?.forEach(s => residentialCount += s.residential);
		return residentialCount;
	}

	getCaResidentialCount(): number {
		let residentialCount = 0;
		this.snapAdmailDistribution?.letterCarrierWalks?.forEach(s => residentialCount += s.apartments + s.farms + s.houses);
		return residentialCount;
	}

	getMinQuantity(index: number): number {
		const productForm = this.products.at(index) as UntypedFormGroup;
		const productId = parseInt(productForm.get('productId')!.value, 10);
		const wlProduct = this.siteProducts.find(p => p.baseProductId === productId)!;
		return wlProduct.baseProduct?.priceMatrix![0].minQty!;
	}

	async optionSelected(index: number, name: string) {
		if (name === 'Postage Class') {
			const product = this.products.at(index);
			const designValue = product.get('design')!.value;
			let postageType = PostageType.StandardMail;
			if (!!product.get('options')!.get('Postage Class')) {
				const selectedPostage = product.get('options')?.get('Postage Class')!.value;
				if (selectedPostage === ProductCategoryOptions.FirstClass) {
					postageType = PostageType.FirstClass;
				}
				else if (selectedPostage === ProductCategoryOptions.NonProfit) {
					postageType = PostageType.NonProfit;
				}
			}
			if (this.isUS) {
				this.calendar = await this.salesApiService.getUsDates(new GetCalendarRequest({
					siteId: this.siteId!,
					customerId: this.customerId!,
					productId: product.get('productId')!.value,
					quantity: product.get('quantity')!.value > 0 ? product.get('quantity')!.value : this.distribution?.totalDeliveries!,
					orderDate: this.orderDate ?? undefined,
					postageType
				}));
			}
			else {
				this.calendar = await this.salesApiService.getCaDates(this.customerId!, new GetCaCalendarRequest({
					siteId: this.siteId!,
					productId: product.get('productId')!.value,
					quantity: product.get('quantity')!.value > 0 ? product.get('quantity')!.value : this.distribution?.totalDeliveries!,
					postageType,
					orderDate: this.orderDate ?? undefined,
					distributionId: this.distributionId ?? 0
				}));
			}
			if (designValue !== undefined && designValue !== '') {
				this.designSelected(index);
			}
		}
		if (name === 'Call Tracking Type') {
			this.trackingTypeSelected(index);
		}
	}

	designSelected(index: number) {
		const product = this.products.at(index);
		if (this.newOrderItem) {
			product.get('front')!.setValue('');
			product.get('back')!.setValue('');
		}
		if (this.isDirectMailProduct(index) || this.isDigitalProduct(index)) {
			const designType = product.get('design')!.value;
			if (this.newOrderItem) {
				product.get('deliveryWindow')!.setValue(undefined);
				if (this.calendar) {
					const designCalendar = this.calendar?.designDeliveryOption?.find(x => x.designType === designType);
					const standardDeliveryOptions = designCalendar?.standardDeliveryOptions;
					this.minDate = {
						day: standardDeliveryOptions![0].firstInHomeDate.getDate()!,
						month: standardDeliveryOptions![0].firstInHomeDate.getMonth()! + 1,
						year: standardDeliveryOptions![0].firstInHomeDate.getFullYear()!
					};
					this.maxDate = {
						day: standardDeliveryOptions![standardDeliveryOptions!.length - 1].firstInHomeDate.getDate()!,
						month: standardDeliveryOptions![standardDeliveryOptions!.length - 1].firstInHomeDate.getMonth()! + 1,
						year: standardDeliveryOptions![standardDeliveryOptions!.length - 1].firstInHomeDate.getFullYear()!
					};
				}
				this.fromDate = undefined;
				this.toDate = undefined;
			}
			this.startDate = this.minDate;
		}
		if (this.isDigitalProduct(index)) {
			product.get('startDate')!.setValidators([
				Validators.required
			]);
			product.get('startDate')!.updateValueAndValidity();
		}
	}

	uploadDesignSelected(index: number): boolean {
		const product = this.products.at(index) as UntypedFormGroup;
		if (!product) {
			return false;
		}
		else {
			if (product.controls.design.value === USelectDesignType.Upload) {
				return true;
			}
			else {
				return false;
			}
		}
	}

	handleFrontFileInput(i: number, event: any) {
		this.uploadedFrontFiles.set(i, event.files[0]);
	}

	handleBackFileInput(i: number, event: any) {
		this.uploadedBackFiles.set(i, event.files[0]);
	}

	impressionsChanged(index: number) {
		const product = this.products.at(index) as UntypedFormGroup;
		if (product.controls.impressions.value === '1') {
			if (this.isPMProduct(index) || this.isEmail(index)) {
				product.controls.drops.setValue('1');
			}
			else {
				product.controls.drops.setValue('');
			}
			product.controls.drops.setValidators([
				Validators.required
			]);
		}
		else {
			product.controls.drops.setValue('1');
			product.controls.drops.setErrors(null);
			product.controls.drops.removeValidators(Validators.required);
			product.controls.frequency.setValidators([
				Validators.required
			]);
			product.controls.drops.updateValueAndValidity();
			product.controls.frequency.updateValueAndValidity();
		}
		if ((this.isDirectMailProduct(0) && !this.isPMProduct(0))) {
			this.quantityChanged(0);
		}
	}

	dropsChanged(index: number) {
		const product = this.products.at(index) as UntypedFormGroup;
		if (product.controls.drops.value === '1') {
			product.controls.frequency.setValue('');
			product.controls.frequency.setErrors(null);
			product.controls.frequency.removeValidators(Validators.required);
		}
		else {
			product.controls.frequency.setValue('');
			product.controls.frequency.setValidators([
				Validators.required
			]);
		}
		product.controls.frequency.updateValueAndValidity();
	}

	quantityChanged(index: number) {
		const product = this.products.at(index) as UntypedFormGroup;
		const impressions = product.controls.impressions.value;
		const quantity = this.distribution?.totalDeliveries!;
		const newQuantity = quantity * parseInt(impressions);

		product.controls.quantity.setValue(newQuantity);
	}

	getProductName(productId: number): string {
		return this.allowedProducts.find(p => p.baseProductId === productId)!.name!;
	}

	getOptions(i: number, name: string): ProductPrintMethodOption[] {
		let options: ProductPrintMethodOption[] = [];
		let productOptionCategory: ProductOptionCategory | undefined;
		const productId = parseInt((this.products.at(i) as UntypedFormGroup).controls.productId.value, 10);
		const printMethodOptions = this.printMethodOptions.get(productId);
		if (this.allProductOptions.length > 0 && printMethodOptions !== undefined && printMethodOptions.length > 0) {
			productOptionCategory = this.allProductOptions.find(x => x.name === name);
			options = printMethodOptions.filter(x => x.option?.optCatId === productOptionCategory?.optCatId);
		}
		return options;
	}

	async updateImpressions(productForm: UntypedFormGroup) {
		const budget = productForm.controls.budget.value;
		const productId = productForm.controls.productId.value;
		if (budget > 0 && productId > 0) {
			let productPricing = await this.pricingService.getProductPricing(this.siteId!, this.customerId!, productForm.controls.productId.value);
			const impressionEstimate = budget! / productPricing![0].pricePerPiece;
			const low = Math.round((impressionEstimate * 0.5) / 100) / 10;
			const high = Math.round((impressionEstimate * 1.5) / 100) / 10;
			productForm.controls.digitalImpressions.setValue(`Estimated ${low}K to ${high}K daily impressions`);
		}
	}
	async updateDigitalForm(index: number, usingTotalBudget: boolean, endDateSelected: boolean = false) {
		if (this.isDailyBudgetProduct(index)) {
			const productForm = this.products.at(index) as UntypedFormGroup;
			await this.updateImpressions(productForm);

			if (productForm.controls.budget.value > 0 && productForm.controls.startDate?.value.length > 0) {
				let budget = parseFloat(productForm.controls.budget.value);
				const startDate = new Date(productForm.controls.startDate.value);
				const endDate = new Date(startDate);
				let campaignDuration = 0;
				if (usingTotalBudget && productForm.controls.totalBudget.value > 0 && !endDateSelected) {
					const totalBudget = parseFloat(productForm.controls.totalBudget.value);
					const selectedEndDate = productForm.controls.endDate.value;
					if (selectedEndDate.length > 0) {
						campaignDuration = parseInt(productForm.controls.campaignDuration.value);
						budget = totalBudget / campaignDuration;
						productForm.controls.budget.setValue(budget);
					}
					else {
						campaignDuration = totalBudget / budget;
						productForm.controls.campaignDuration.setValue(campaignDuration);
					}
				}
				else if (!usingTotalBudget && productForm.controls.campaignDuration.value > 0 && !endDateSelected) {
					campaignDuration = parseInt(productForm.controls.campaignDuration.value);
					productForm.controls.totalBudget.setValue(campaignDuration * budget);
				}
				else if (!usingTotalBudget && productForm.controls.campaignDuration.value > 0 && endDateSelected) {
					const selectedEndDate = new Date(productForm.controls.endDate.value);
					const tempStartDate = new Date(productForm.controls.startDate.value);
					tempStartDate.setDate(tempStartDate.getDate() - 1);
					const differenceInTime = selectedEndDate.getTime() - tempStartDate.getTime();
					const daysBetween = Math.round(differenceInTime / (1000 * 3600 * 24));
					campaignDuration = daysBetween;
					const totalBudget = budget * campaignDuration;
					productForm.controls.campaignDuration.setValue(campaignDuration);
					productForm.controls.totalBudget.setValue(totalBudget);
				}
				if (campaignDuration > 0 && !endDateSelected) {
					endDate.setDate(startDate.getDate() + campaignDuration);
					productForm.controls.endDate.setValue(formatDate(endDate, 'yyyy-MM-dd', 'en'));
				}
			}
		}
	}

	isDirectMailProduct(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			const uselectId = this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId;
			return DirectMailProducts.findIndex(x => x === uselectId) !== -1;
		}
		else {
			return false;
		}
	}

	isSnapAdMailProduct(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			const uselectId = this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId;
			return USelect.snapAdmail === uselectId;
		}
		else {
			return false;
		}
	}

	isDigitalProduct(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			const uselectId = this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId;
			return DigitalProducts.findIndex(x => x === uselectId) !== -1;
		}
		else {
			return false;
		}
	}

	isDailyBudgetProduct(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			const uselectId = this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId;
			return ProductsWithDailyBudget.findIndex(x => x === uselectId) !== -1;
		}
		else {
			return false;
		}
	}

	isCustomFeeProduct(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			const uselectId = this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId;
			return uselectId === USelect.customFee;
		}
		else {
			return false;
		}
	}

	isCallTrackingProduct(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			return this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.callTracking;
		}
		else {
			return false;
		}
	}

	isQrCodeProduct(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			return this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.qrCode;
		}
		else {
			return false;
		}
	}

	isInformedDeliveryProduct(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			return this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.informedDelivery;
		}
		else {
			return false;
		}
	}

	isPMProduct(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			const uselectId = this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId;
			return PMProducts.findIndex(x => x === uselectId) !== -1;
		}
		else {
			return false;
		}
	}

	isEmail(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			return (this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.targetedEmail);
		}
		return false;
	}

	isExtraCopies(productIndex: number): boolean {
		const productId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.productId.value, 10);
		if (productId > 0) {
			const uselectId = this.siteProducts.find(x => x.baseProductId === productId)!.baseProduct?.uSelectConfigurations![0].uSelectId;
			return uselectId === USelect.extraCopies;
		}
		else {
			return false;
		}
	}

	hasDistribution(productIndex: number): boolean {
		const distId = parseInt((this.products.at(productIndex) as UntypedFormGroup).controls.distributionId.value, 10);
		return distId > 0 && !this.isExtraCopies(0);
	}

	formatPhoneNumber(productIndex: number, controlName: string) {
		const productForm = this.products.at(productIndex) as UntypedFormGroup;

		let inputValue = productForm.get(controlName)?.value;;
		let temp = inputValue.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		if (temp) {
			inputValue = !temp[2] ? temp[1] : '(' + temp[1] + ') ' + temp[2] + (temp[3] ? '-' + temp[3] : '');
			productForm.get(controlName)?.setValue(inputValue);
		}
	}

	async startDateSelected(index: number) {
		this.disableSaveButton = true;
		const product = this.products.at(index);
		const startDate = product.get('startDate')?.value;
		if (startDate.length > 0) {
			const year = parseInt(startDate.substr(0, 4), 10);
			const month = parseInt(startDate.substr(5, 2), 10) - 1;
			const day = parseInt(startDate.substr(8, 2), 10);
			this.endDateStart = formatDate(new Date(year, month, day), 'yyyy-MM-dd', 'en');
		}
		if (this.isEmail(0)) {
			product.get('endDate')?.setValue(this.endDateStart);
		}
		await this.updateDigitalForm(index, true);
		this.disableSaveButton = false;
	}

	formatDate(date: Date): string {
		return date.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' });
	}

	getRandomIdentifier(): string {
		const letterVal = String.fromCharCode(65 + Math.floor(Math.random() * 26));
		return letterVal + Date.now();
	}

	makeDesignOptionUploadForDigital(index: number) {
		const productForm = this.products.at(index) as UntypedFormGroup;
		const product = this.products.at(index);
		product.get('front')!.setValue('');
		product.get('back')!.setValue('');
		productForm.controls.design.setValue(USelectDesignType.Upload);
		productForm.controls.design.disable();
		product.get('startDate')!.setValidators([
			Validators.required
		]);
		product.get('startDate')!.updateValueAndValidity();
	}

	hideDesignOptionForDigital(index: number) {
		if (this.isDigitalProduct(index)) {
			if (!!this.cartData && this.cartData.length >= 1) {
				this.showDesign = false;
				this.makeDesignOptionUploadForDigital(index);
			}
			else {
				this.showDesign = true;
			}
		}
		else if (this.isDirectMailProduct(index)) {
			this.showDesign = true;
		}
		else {
			this.showDesign = false;
		}
	}

	isOnlyDigitalProducts() {
		let c = 0;
		if (!!this.cartData && this.cartData.length <= 0) {
			return true;
		}
		else {
			for (let i = 0; i < this.cartData!.length; i++) {
				const uselectId = this.siteProducts.find(x => x.baseProductId === this.cartData![i].baseProductId)!.baseProduct?.uSelectConfigurations![0].uSelectId;
				let isDigital = DigitalProducts.findIndex(x => x === uselectId) !== -1;
				if (isDigital) {
					c++;
				}

				if (this.cartData!.length === c) {
					return true;
				}
			}
			return false;
		}
	}

	trackingTypeSelected(index: number) {
		const productForm = this.products.at(index) as UntypedFormGroup;
		const callTrackingType = productForm.controls.options.get('Call Tracking Type');
		if (callTrackingType?.value && callTrackingType?.value === ProductCategoryOptions.LocalAreaCode) {
			productForm.controls.areaCode.setValidators([
				Validators.required,
				Validators.min(201),
				Validators.max(999)
			]);
		}
		else {
			productForm.controls.areaCode.removeValidators([
				Validators.required,
				Validators.min(201),
				Validators.max(999)
			]);
		}
		productForm.controls.areaCode.setValue('');
		productForm.controls.areaCode.updateValueAndValidity();
	}

	onDateSelection(date: NgbDateStruct) {
		this.fromDate = date;
		const productForm = this.products.at(0) as UntypedFormGroup;
		const selectedDate = new Date(date.year, date.month - 1, date.day);
		const deliveryOptions = this.calendar?.designDeliveryOption?.find(d => d.designType === productForm.get('design')!.value)?.standardDeliveryOptions;
		const foundDeliveryOption = deliveryOptions?.find(o => {
			const formattedDate = new Date(o.firstInHomeDate.getFullYear(), o.firstInHomeDate.getMonth(), o.firstInHomeDate.getDate());
			if (formattedDate.toISOString() === selectedDate.toISOString()) {
				return o;
			}
			else {
				return undefined;
			}
		});
		const dropDate = foundDeliveryOption?.firstAtPostOfficeDate;
		productForm.controls.deliveryWindow.setValue(dropDate!.toISOString());
		this.deliveryDateSelected(0, dropDate!.toISOString());
		this.toDate = new NgbDate(foundDeliveryOption?.lastInHomeDate.getFullYear()!, foundDeliveryOption?.lastInHomeDate.getMonth()! + 1, foundDeliveryOption?.lastInHomeDate.getDate()!);
		this.startDate = date;
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	extraCopiesSelected(value: { productSelected: number | undefined, quantitySelected: number | undefined } | undefined) {
		const productForm = this.products.at(0) as UntypedFormGroup;
		if (!!value) {
			productForm.get('extraCopiesProductId')?.setValue(value.productSelected);
			productForm.get('extraCopiesQuantity')?.setValue(value.quantitySelected);

			if (value.quantitySelected! > 0) {
				const extraCopiesProduct = this.cartData?.find(cd => cd.baseProductId === parseInt(productForm.get('extraCopiesProductId')?.value));
				productForm.get('shippingAddress')!.setValidators([Validators.required, Validators.min(1)]);
				productForm.get('shippingAddress')!.updateValueAndValidity();
				if (!!extraCopiesProduct) {
					this.selectShippingAddress(extraCopiesProduct.shippingAddressId, 0);
				}
			}
			else if (value.quantitySelected === 0) {
				productForm.get('shippingAddress')!.removeValidators([Validators.required, Validators.min(1)]);
				productForm.get('shippingAddress')!.updateValueAndValidity();
			}
			else {
				productForm.get('extraCopiesProductId')?.setValue(value.productSelected);
				productForm.get('extraCopiesQuantity')?.setValue('');
				productForm.get('shippingAddress')!.setValidators([Validators.required, Validators.min(1)]);
				productForm.get('shippingAddress')!.updateValueAndValidity();
				productForm.get('shippingAddress')!.setValue('');
			}
		}
		else {
			productForm.get('extraCopiesProductId')?.setValue('');
			productForm.get('extraCopiesQuantity')?.setValue('');

			productForm.get('shippingAddress')!.removeValidators([Validators.required, Validators.min(1)]);
			productForm.get('shippingAddress')!.updateValueAndValidity();
		}
	}

	emitCancelUpdate() {
		this.cancelUpdate.emit(true);
	}
}
