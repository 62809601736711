import { Component, OnInit } from '@angular/core';
import {
	Customer, Distribution, Demographics,
	EddmDistribution, EquifaxQuery, PersonalizedMailQuery,
	Site, SnapAdMailDistribution, SuppressListQuery,
	CustomerDistributionWithRelatedOrders,
	GetCustomerDistributionsQuery,
	PaginationQuery,
	CarrierRoute,
	LetterCarrierWalk,
	AddressedDistribution,
	BuiltPersonalizedMailDistribution,
	BuiltEquifaxB2BDistribution
} from '@taradel/admin-api-client';
import { DistributionsService, USelect } from 'services/distributions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MapDataService } from 'services/map-data.service';
import { KeyValue } from '@angular/common';
import { PmSelectionService } from 'services/pm-selection.service';
import { EquifaxSelectionService } from 'services/equifax-selection.service';
import { ToastService } from 'services/toast.service';
import { saveAs } from 'file-saver';
import { numDaysBetween } from 'app/utils/num-days-between';
import { CustomerService } from 'services/customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SitesService } from 'services/sites.service';
import { PmDataService } from 'services/pm-data.service';
import { NewDistributionCreatedHubService } from 'hubs/new-distribution-created-hub.service';
import { AuthenticationService } from 'services/authentication.service';
import { OrderService } from 'services/order.service';
import { GooglePlaceResult, mapAddressTradeAreaToGooglePlaceAddress } from 'app/lib/google-maps';

export interface Data {
	key: string;
	value: string;
}

export class EddmDemographicdata {
	name?: string;
	data?: Data[];
}

export class PoliticalMailFilterdata {
	name?: string;
	data?: Data[];
}
export class B2BMailFilterdata {
	name?: string;
	data?: Data[];
}

export interface ListSuppressionDetails {
	originalList: ListDetails;
	suppressingList: ListDetails;
}

export interface ListDetails {
	distributionId: number;
	distributionName: string;
}
@Component({
	selector: 'app-distribution-detail',
	templateUrl: './distribution-detail.component.html',
	styleUrls: ['./distribution-detail.component.scss']
})

export class DistributionDetailComponent implements OnInit {

	sites: Site[] = [];
	selectedSiteId: number = 0;
	selectedDistribution?: Distribution;
	customerId = 0;
	customer!: Customer;
	distributionId = 0;
	distribution?: Distribution;
	distributionWithRelatedOrders?: CustomerDistributionWithRelatedOrders;
	eddmDistribution?: EddmDistribution;
	uSelect = USelect;
	usAreas: CarrierRoute[] = [];
	caAreas: LetterCarrierWalk[] = [];
	totalResidential: number = 0;
	totalApartment: number = 0;
	totalFarm: number = 0;
	totalHouse: number = 0;
	totalBusiness: number = 0;
	totalPoBoxes: number = 0;
	totalDistribution: number = 0;
	demographics?: Demographics;
	caDemographics?: Demographics;
	distFiles: string[] = [];
	loadingDistFiles = false;

	mapImageSrc?: string;
	eddmSelection?: EddmDistribution;
	snapAdMailSelection?: SnapAdMailDistribution;
	filterData?: EquifaxQuery;
	filters: Map<string, string[]> = new Map<string, string[]>();

	allowOrder = false;
	loading = true;

	listSuppressionDetails?: ListSuppressionDetails;
	suppressListResponse?: { suppressingDistId: number, name: string };
	isSalesAdmin = false;

	get isDistributionOutdated(): boolean {
		return numDaysBetween(new Date, this.distribution?.createdDate || new Date) > 30 && this.distribution?.uSelectId === USelect.eddm;
	}

	constructor(
		public modalService: NgbModal,
		private sitesService: SitesService,
		public pmSelectionService: PmSelectionService,
		public equifaxSelectionService: EquifaxSelectionService,
		private customerService: CustomerService,
		private distributionsService: DistributionsService,
		private orderService: OrderService,
		private mapDataService: MapDataService,
		private toastService: ToastService,
		private route: ActivatedRoute,
		private router: Router,
		private pmDataService: PmDataService,
		private authService: AuthenticationService,
		private newDistCreatedHub: NewDistributionCreatedHubService) { 
			this.pmSelectionService.clear();
		}


	ngOnInit() {
		this.route.paramMap.subscribe(async params => {
			try {
				this.customerId = parseInt(params.get('customerId') ?? '0', 10);
				this.distributionId = parseInt(params.get('distributionId') ?? '0', 10);
				[this.isSalesAdmin, this.customer, this.distribution] = await Promise.all([
					await this.authService.hasRole('SalesAdmin'),
					await this.customerService.getCustomer(this.customerId),
					await this.distributionsService.getDistribution(this.distributionId),
					await this.loadDistFiles(), // not destructured, no return value
				]);
                this.sites = await this.sitesService.getSitesForOrganization(this.customer?.organizationId!);
				this.selectedSiteId = this.sites.find(x => x.siteId === this.customer.affiliateID)?.siteId ?? 0;

				if (this.distribution instanceof AddressedDistribution && this.distribution.suppressedByDistributionId && this.distribution.suppressedDistributionId) {
					await this.getListSuppressionDetails();
				}

				const distributionQuery = new GetCustomerDistributionsQuery({
					customerId: this.customerId,
					name: this.distribution.name,
					showDeleted: true,
					pagination: new PaginationQuery({
						pageNo: 1,
						pageSize: 50,
					}),
				});
				const distributions = await this.orderService.getDistributionsWithRelatedOrders(distributionQuery);
				this.distributionWithRelatedOrders = distributions.find((dist) => dist.distributionId === this.distribution?.distributionId);

				switch (this.distribution.uSelectId) {
					case USelect.eddm:
						this.allowOrder = true;
						// get eddm saved selections
						this.eddmSelection = await this.mapDataService.getCustomerDistribution(this.customerId, this.distributionId);

						try {
							const mapImageResponse = await this.mapDataService.getMapImage(this.distributionId);
							if (mapImageResponse) {
								const reader = new FileReader();
								reader.readAsDataURL(mapImageResponse.data);
								this.mapImageSrc = await new Promise<string>(resolve => reader.onloadend = () => resolve(<string>reader.result));
							}
						}
						catch (ex) {
							console.error(ex);
						}

						this.usAreas = this.eddmSelection.carrierRoutes!.sort(this.zipsort);
						this.totalBusiness = this.areaPropSum(this.usAreas, 'business');
						this.totalResidential = this.areaPropSum(this.usAreas, 'residential');
						this.totalPoBoxes = this.areaPropSum(this.usAreas, 'poBoxes');
						this.totalDistribution = this.areaPropSum(this.usAreas, 'total');
						this.demographics = this.eddmSelection.demographics!;
						this.viewDemographicFilters();
						break;
					case USelect.snapAdmail:
						this.allowOrder = true;
						// get eddm saved selections
						this.snapAdMailSelection = await this.mapDataService.getSnapAdMailDistribution(this.customerId, this.distributionId);

						try {
							const mapImageResponse = await this.mapDataService.getCaMapImage(this.distributionId);
							if (mapImageResponse) {
								const reader = new FileReader();
								reader.readAsDataURL(mapImageResponse.data);
								this.mapImageSrc = await new Promise<string>(resolve => reader.onloadend = () => resolve(<string>reader.result));
							}
						}
						catch (ex) {
							console.error(ex);
						}

						this.caAreas = this.snapAdMailSelection.letterCarrierWalks!.sort(this.caZipsort);
						this.totalBusiness = this.areaPropSum(this.caAreas, 'business');
						this.totalApartment = this.areaPropSum(this.caAreas, 'apartments');
						this.totalFarm = this.areaPropSum(this.caAreas, 'farms');
						this.totalHouse = this.areaPropSum(this.caAreas, 'houses');
						this.totalDistribution = this.areaPropSum(this.caAreas, 'total');
						this.caDemographics = this.snapAdMailSelection.demographics!;
						this.viewCaDemographicFilters();
						break;
					case USelect.buildYourList:
						this.allowOrder = true;
						// show personalized mail built list filters
						try {
							const pmDistribution = (this.distribution as BuiltPersonalizedMailDistribution);
							this.viewPersonalizedMailFilters(pmDistribution);
						}
						catch (ex: any) {
							// there are no filters
						}
						break;
					case USelect.uploadYourList:
						this.allowOrder = true;
						break;
					case USelect.equifaxB2B:
						this.allowOrder = true;
						// show equifax list filters
						this.viewEquifaxFilters(this.distribution as BuiltEquifaxB2BDistribution);
						break;
				}
			}
			 catch (error) {
				 this.toastService.showError('There was a problem loading the component', 'Load Error');
				 console.error(error);
			}
			 finally {
				 this.loading = false;
			}
		});
	}

	zipsort(area1: CarrierRoute, area2: CarrierRoute): number {
		let area1Name = area1.name ?? "";
		let area2Name = area2.name ?? "";
		if (area1Name < area2Name) {
			return -1;
		}
		if (area1Name > area2Name) {
			return 1;
		}
		return 0;
	}

	caZipsort(area1: LetterCarrierWalk, area2: LetterCarrierWalk): number {
		let area1Name = area1.name ?? "";
		let area2Name = area2.name ?? "";
		if (area1Name < area2Name) {
			return -1;
		}
		if (area1Name > area2Name) {
			return 1;
		}
		return 0;
	}

	areaPropSum(areas: any[], prop: string): number {
		return areas.reduce((a, b) => {
			let propVal = b[prop as keyof typeof b];
			return a + parseInt(propVal!.toString(), 10);
		}, 0);
	}

	incomeRange(selections: string[]): string {
		const hhIncomeMap = new Map<string, string>([
			['1', '<25k'],
			['5', '25k-50k'],
			['A', '50k-75k'],
			['E', '75k-100k'],
			['F', '100k-150k'],
			['G', '150k-200k'],
			['I', '200k-250k'],
			['J', '250k+']
		]);
		let values: string[] = [];
		selections.map(x => {
			const range = hhIncomeMap.get(x);
			if (range) {
				values.push(range);
			}
		});
		return values.join(', ');
	}

	caIncomeRange(selections: string[]): string {
		const hhIncomeMap = new Map<string, string>([
			['A', '<20k'],
			['B', '20k-40k'],
			['C', '40k-60k'],
			['D', '60k-80k'],
			['E', '80k-100k'],
			['F', '100k+'],
			/*['F', '100k-150k'],
			['G', '150k-200k'],
			['I', '200k-250k'],
			['J', '250k+']*/
		]);
		let values: string[] = [];
		selections.map(x => {
			const range = hhIncomeMap.get(x);
			if (range) {
				values.push(range);
			}
		});
		return values.join(', ');
	}

	ageRange(selections: string[]): string {
		const ageMap = new Map<string, string>([
			['Age18_24', '18'],
			['Age25_34', '25'],
			['Age35_44', '35',],
			['Age45_54', '45'],
			['Age55_64', '55'],
			['Age65_', '65+']
		]);
		return `${ageMap.get(selections[0])!} - ${ageMap.get(selections[selections.length - 1])!}`;
	}

	caAgeRange(selections: string[]): string {
		const ageMap = new Map<string, string>([
			['A', '0'],
			['B', '5'],
			['C', '10'],
			['D', '15'],
			['E', '20'],
			['F', '25'],
			['G', '30'],
			['H', '35'],
			['I', '40'],
			['J', '45'],
			['K', '50'],
			['L', '55'],
			['M', '60'],
			['N', '65'],
			['O', '70'],
			['P', '75'],
			['Q', '80'],
			['R', '85+']
		]);
		return `${ageMap.get(selections[0])!} - ${ageMap.get(selections[selections.length - 1])!}`;
	}

	childrenPresent(selections: string[]): string {
		return (selections[0].toLowerCase() === 'children') ? 'Yes' : 'No';
	}

	selectedGender(selections: string[]): string {
		return (selections[0].toLowerCase() === 'm' || selections[0].toLowerCase() === 'male') ? 'Male' : 'Female';
	}

	homeowner(selections: string[]): string {
		return (selections[0].toLowerCase() === 'o' || selections[0].toLowerCase() === 'owner') ? 'Yes' : 'No';
	}

	getLengthOfResidence(selections: number[]): string {
		return `${selections[0]} - ${selections[selections.length - 1]} years`;
	}

	async getListSuppressionDetails() {
		const addressedDistribution = this.distribution as AddressedDistribution;
		const originalDist = await this.distributionsService.getDistribution(addressedDistribution?.suppressedDistributionId!);
		const suppressingDist = await this.distributionsService.getDistribution(addressedDistribution?.suppressedByDistributionId!);

		const originalList = {
			distributionId: originalDist.distributionId,
			distributionName: originalDist.name
		} as ListDetails;
		const suppressingList = {
			distributionId: suppressingDist.distributionId,
			distributionName: suppressingDist.name
		} as ListDetails;

		this.listSuppressionDetails = {
			originalList,
			suppressingList
		} as ListSuppressionDetails;
	}

	siteChanged(siteId: number) {
		this.selectedSiteId = siteId;
	}

	closeModal() {
		this.selectedSiteId = this.sites.find(x => x.siteId === this.customer.affiliateID)?.siteId ?? 0;
		this.modalService.dismissAll();
	}

	viewDemographicFilters(): void {
		this.filters.clear();
		if (this.demographics?.hhIncome !== undefined && this.demographics?.hhIncome.length > 0) {
			this.filters.set('Household Income', [this.incomeRange(this.demographics?.hhIncome)]);
		}
		if (this.demographics?.ages !== undefined && this.demographics?.ages.length > 0) {
			this.filters.set('Ages', [this.ageRange(this.demographics?.ages)]);
		}
		if (this.demographics?.children !== undefined && this.demographics?.children.length > 0) {
			this.filters.set('Children Present', [this.childrenPresent(this.demographics?.children)]);
		}
		if (this.demographics?.gender !== undefined && this.demographics?.gender.length > 0) {
			this.filters.set('Gender', [this.selectedGender(this.demographics?.gender)]);
		}
		if (this.demographics?.homeOwnership !== undefined && this.demographics?.homeOwnership.length > 0) {
			this.filters.set('Home Ownership', [this.homeowner(this.demographics?.homeOwnership)]);
		}
		if (this.demographics?.lengthOfResidence !== undefined && this.demographics?.lengthOfResidence.length > 0) {
			this.filters.set('Length Of Residence', [this.getLengthOfResidence(this.demographics?.lengthOfResidence)]);
		}
	}
	viewCaDemographicFilters(): void {
		this.filters.clear();
		if (this.caDemographics?.hhIncome !== undefined && this.caDemographics?.hhIncome.length > 0) {
			this.filters.set('Household Income', [this.caIncomeRange(this.caDemographics?.hhIncome)]);
		}
		if (this.caDemographics?.ages !== undefined && this.caDemographics?.ages.length > 0) {
			this.filters.set('Ages', [this.caAgeRange(this.caDemographics?.ages)]);
		}
		if (this.caDemographics?.children !== undefined && this.caDemographics?.children.length > 0) {
			this.filters.set('Children Present', [this.childrenPresent(this.caDemographics?.children)]);
		}
		if (this.caDemographics?.gender !== undefined && this.caDemographics?.gender.length > 0) {
			this.filters.set('Gender', [this.selectedGender(this.caDemographics?.gender)]);
		}
		if (this.caDemographics?.homeOwnership !== undefined && this.caDemographics?.homeOwnership.length > 0) {
			this.filters.set('Home Ownership', [this.homeowner(this.caDemographics?.homeOwnership)]);
		}
	}

	viewPersonalizedMailFilters(distribution: BuiltPersonalizedMailDistribution): void {
		this.pmSelectionService.query = new PersonalizedMailQuery();
		this.pmSelectionService.query.filters = distribution.filters!;
		this.pmSelectionService.query.zipCodes = distribution.startTargetedZipCodes?.split(',') ?? [];
		this.pmSelectionService.viewOnly = true;
		const addresses: GooglePlaceResult[] = this.pmSelectionService.query.radiusQuery?.map(mapAddressTradeAreaToGooglePlaceAddress) ?? [];
		this.pmSelectionService.queryUpdates.next(this.pmSelectionService.query);
		this.pmSelectionService.addressUpdates.next(addresses);
	}

	viewEquifaxFilters(distribution: BuiltEquifaxB2BDistribution) {
		this.equifaxSelectionService.query = Object.assign(new EquifaxQuery(), distribution.filters);
		this.filters.clear();
		this.equifaxSelectionService.viewOnly = true;
		const addresses: GooglePlaceResult[] = this.equifaxSelectionService.query.radiusQuery?.map(mapAddressTradeAreaToGooglePlaceAddress) ?? [];
		this.equifaxSelectionService.queryUpdates.next(this.equifaxSelectionService.query);
		this.equifaxSelectionService.addressUpdates.next(addresses);
	}

	async loadDistFiles() {
		this.loadingDistFiles = true;
		try {
			this.distFiles = await this.distributionsService.listDistFiles(this.distributionId);
		}
		catch (err) {
			// whatevs
		}
		this.loadingDistFiles = false;
	}

	async downloadFile(file: string) {
		const fileName = file.substring(file.lastIndexOf('/') + 1);
		try {
			const download = await this.distributionsService.getDistFile(this.distributionId, file);
			if (download) {
				saveAs(download.data, fileName);
			}
		}
		catch (err) {
			this.toastService.showError(`Could not download file ${fileName}`);
		}
	}

	proceedToSiteSelection(distribution: Distribution, siteModal: any): void {
		this.selectedDistribution = distribution;
		this.modalService.open(siteModal, { windowClass: "updateModalClass" });

	}
	async startOrder(): Promise<void> {
		this.modalService.dismissAll();
		this.loading = true;
		switch (this.selectedDistribution?.uSelectId) {
			case USelect.eddm:
				const isUpdated = await this.mapDataService.isDistributionUpToDate(this.selectedDistribution.distributionId);
				if (isUpdated && isUpdated.status === 200) {
					await this.router.navigate([`/customers/${this.customerId}/shop/${this.selectedSiteId}/${this.selectedDistribution.distributionId}`]);
				} else {
					await this.router.navigate([`/customers/${this.customerId}/map/${this.selectedDistribution.distributionId}`]);
				}
				break;
			default:
				await this.router.navigate([`/customers/${this.customerId}/shop/${this.selectedSiteId}/${this.selectedDistribution?.distributionId}`]);
				break;
		}
	}



	getEddmDeliveryType(): string {
		let deliveryType: string[] = ['residential'];
		if (this.eddmSelection?.useBusiness) {
			deliveryType.push('businesses');
		}
		if (this.eddmSelection?.usePOBoxes) {
			deliveryType.push('po boxes');
		}
		return deliveryType.join(' + ');
	}
	getSnapAdMailDeliveryType(): string {
		let deliveryType: string[] = [];
		if (this.snapAdMailSelection?.useApartment) {
			deliveryType.push('apartment');
		}
		if (this.snapAdMailSelection?.useFarm) {
			deliveryType.push('farms');
		}
		if (this.snapAdMailSelection?.useHouse) {
			deliveryType.push('houses');
		}
		if (this.snapAdMailSelection?.useBusiness) {
			deliveryType.push('businesses');
		}
		return deliveryType.join(' + ');
	}

	originalOrder = (a: KeyValue<string, string[]>, b: KeyValue<string, string[]>): number => 0;

	openSuppressListModal(modal: any): void {
		this.modalService.open(modal, { windowClass: "updateModalClass" });

	}

	setSuppressingList(suppressDistResponse: { suppressingDistId: number, name: string } | undefined) {
		this.suppressListResponse = suppressDistResponse;
	}

	async processListSuppression() {
		let success = true;
		this.loading = true;
		const query = {
			originalDistId: this.distributionId,
			suppressingDistId: this.suppressListResponse?.suppressingDistId,
			customerId: this.customerId,
			name: this.suppressListResponse?.name
		} as SuppressListQuery;
		try {
			await this.pmDataService.processListSuppression(query);
		}
		catch (error: any) {
			success = false;
			console.log(error);
			this.toastService.showError('List suppression failed');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.closeModal();
			this.newDistCreatedHub.newDistributionCreated.subscribe(response => {
				this.toastService.showCustom('newDistFromSuppressionToast', 'information', response, 10000);
			});
			this.toastService.showSuccess('List suppression has started');
		}
	}

	async downloadList() {
		this.loading = true;
		try {
			await this.distributionsService.downloadList(this.distributionId);
		}
		catch (err: any) {
			console.log(err);
			this.toastService.showError('List could not be downloaded');
		}
		finally {
			this.loading = false;
		}
	}
}
