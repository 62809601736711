import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'services/authentication.service';
import { HubService } from './hub.service';

@Injectable({
	providedIn: 'root'
})
export class DesignHuddleHubService extends HubService {

	public projectExported = new Subject<string>();

	constructor(authService: AuthenticationService) {
		super('design-huddle', authService);

		this.connection.on('projectExported', order => this.projectExported.next(order));

		this.connection.start();
	}
}
