<app-confirm-modal title="Search for a customer to transfer this cart to" confirmText="start transfer" (confirmAction)="transfer()"
	(closeModal)="closeModal()" [disabled]="!selectedCustomer">
	<ng-template #rt let-r="result" let-t="term">
		<ngb-highlight [result]="r.username" [term]="t"></ngb-highlight>
	</ng-template>
	<div>
		<label for="customerSearch">Search Customers</label>
		<input id="customerSearch" type="text" class="form-control"
		[ngbTypeahead]="searchCustomers" (focus)="focus$.next($any($event).target.value)"
				#instance="ngbTypeahead" [inputFormatter]="customersFormatter"  [resultTemplate]="rt"
			(selectItem)="setCustomer($event)"
		/>
		<div class="form-check mt-2">
			<input class="form-check-input" type="checkbox" id="deleteCheckbox" [(ngModel)]="deleteAfterTransfer">
			<label class="form-check-label" for="deleteCheckbox">
				{{ deleteMessage }}
			</label>
		</div>
	</div>
</app-confirm-modal>
