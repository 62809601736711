/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { AddressTradeArea, EquifaxQuery } from '@taradel/web-api-client';
import { GooglePlaceResult } from 'app/lib/google-maps';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

// Typescript magic -- how to define a type that is a keyof EquifaxQuery, but where
// we also get to specify the value type.  This allows us to write generic methods
// to manipulate properties of EquifaxQuery instead of repeating ourselves.
// https://stackoverflow.com/questions/49796842/keyof-that-is-also-of-type-t
// https://github.com/microsoft/TypeScript/issues/34992 explains the "-?"
export type KeyWithValueType<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];
export type EquifaxAddressKey = KeyWithValueType<EquifaxQuery, AddressTradeArea[] | undefined>;
export type EquifaxListKey = KeyWithValueType<EquifaxQuery, string[] | undefined>;
export type EquifaxBooleanKey = KeyWithValueType<EquifaxQuery, boolean | undefined>;
export type EquifaxSliderKey = KeyWithValueType<EquifaxQuery, string[] | undefined>;

export const equifaxListValues = {
	zipCodes: [] as { key: string; value: string }[],
	states: [
		{ key: 'AL', value: 'Alabama' },
		{ key: 'AK', value: 'Alaska' },
		{ key: 'AS', value: 'American Samoa' },
		{ key: 'AZ', value: 'Arizona' },
		{ key: 'AR', value: 'Arkansas' },
		{ key: 'CA', value: 'California' },
		{ key: 'CO', value: 'Colorado' },
		{ key: 'CT', value: 'Connecticut' },
		{ key: 'DE', value: 'Delaware' },
		{ key: 'DC', value: 'District of Columbia' },
		{ key: 'FL', value: 'Florida' },
		{ key: 'GA', value: 'Georgia' },
		{ key: 'GU', value: 'Guam' },
		{ key: 'HI', value: 'Hawaii' },
		{ key: 'ID', value: 'Idaho' },
		{ key: 'IL', value: 'Illinois' },
		{ key: 'IN', value: 'Indiana' },
		{ key: 'IA', value: 'Iowa' },
		{ key: 'KS', value: 'Kansas' },
		{ key: 'KT', value: 'Kentucky' },
		{ key: 'LA', value: 'Louisiana' },
		{ key: 'ME', value: 'Maine' },
		{ key: 'MD', value: 'Maryland' },
		{ key: 'MA', value: 'Massachusetts' },
		{ key: 'MI', value: 'Michigan' },
		{ key: 'MN', value: 'Minnesota' },
		{ key: 'MS', value: 'Mississippi' },
		{ key: 'MO', value: 'Missouri' },
		{ key: 'MT', value: 'Montana' },
		{ key: 'NE', value: 'Nebraska' },
		{ key: 'NV', value: 'Nevada' },
		{ key: 'NH', value: 'New Hampshire' },
		{ key: 'NJ', value: 'New Jersey' },
		{ key: 'NM', value: 'New Mexico' },
		{ key: 'NY', value: 'New York' },
		{ key: 'NC', value: 'North Carolina' },
		{ key: 'ND', value: 'North Dakota' },
		{ key: 'MP', value: 'Northern Mariana Islands' },
		{ key: 'OH', value: 'Ohio' },
		{ key: 'OK', value: 'Oklahoma' },
		{ key: 'OR', value: 'Oregon' },
		{ key: 'PA', value: 'Pennsylvania' },
		{ key: 'PR', value: 'Puerto Rico' },
		{ key: 'RI', value: 'Rhode Island' },
		{ key: 'SC', value: 'South Carolina' },
		{ key: 'SD', value: 'South Dakota' },
		{ key: 'TN', value: 'Tennessee' },
		{ key: 'TX', value: 'Texas' },
		{ key: 'UT', value: 'Utah' },
		{ key: 'VT', value: 'Vermont' },
		{ key: 'VI', value: 'Virgin Islands' },
		{ key: 'VA', value: 'Virginia' },
		{ key: 'WA', value: 'Washington' },
		{ key: 'WV', value: 'West Virginia' },
		{ key: 'WI', value: 'Wisconsin' },
		{ key: 'WY', value: 'Wyoming' }
	],
	metros: [
		{ key: '0040', value: 'Abilene, TX' },
		{ key: '0060', value: 'Aguadilla, PR' },
		{ key: '0120', value: 'Albany, GA' },
		{ key: '0160', value: 'Albany-Schenectady-Troy, NY' },
		{ key: '0200', value: 'Albuquerque, NM' },
		{ key: '0220', value: 'Alexandria, LA' },
		{ key: '0240', value: 'Allentown-Bethlehem-Easton, PA' },
		{ key: '0280', value: 'Altoona, PA' },
		{ key: '0320', value: 'Amarillo, TX' },
		{ key: '0380', value: 'Anchorage, AK' },
		{ key: '0450', value: 'Anniston, AL' },
		{ key: '0460', value: 'Appleton-Oshkosh-Neenah, WI' },
		{ key: '0480', value: 'Asheville, NC' },
		{ key: '0500', value: 'Athens, GA' },
		{ key: '0520', value: 'Atlanta, GA' },
		{ key: '0580', value: 'Auburn-Opelika, AL' },
		{ key: '0600', value: 'Augusta-Aiken, GA-SC' },
		{ key: '0640', value: 'Austin-San Marcos, TX' },
		{ key: '0680', value: 'Bakersfield, CA' },
		{ key: '0730', value: 'Bangor, ME' },
		{ key: '0740', value: 'Barnstable-Yarmouth, MA' },
		{ key: '0760', value: 'Baton Rouge, LA' },
		{ key: '0840', value: 'Beaumont-Port Arthur, TX' },
		{ key: '0860', value: 'Bellingham, WA' },
		{ key: '0870', value: 'Benton Harbor, MI' },
		{ key: '0880', value: 'Billings, MT' },
		{ key: '0920', value: 'Biloxi-Gulfport-Pascagoula, MS' },
		{ key: '0960', value: 'Binghamton, NY' },
		{ key: '1000', value: 'Birmingham, AL' },
		{ key: '1010', value: 'Bismarck, ND' },
		{ key: '1020', value: 'Bloomington, IN' },
		{ key: '1040', value: 'Bloomington-Normal, IL' },
		{ key: '1080', value: 'Boise City, ID' },
		{ key: '1122', value: 'Boston-Worcester-Lawrence, MA-NH-ME-CT' },
		{ key: '1240', value: 'Brownsville-Harlingen-San Benito, TX' },
		{ key: '1260', value: 'Bryan-College Station, TX' },
		{ key: '1280', value: 'Buffalo-Niagara Falls, NY' },
		{ key: '1305', value: 'Burlington, VT' },
		{ key: '1320', value: 'Canton-Massillon, OH' },
		{ key: '1350', value: 'Casper, WY' },
		{ key: '1360', value: 'Cedar Rapids, IA' },
		{ key: '1400', value: 'Champaign-Urbana, IL' },
		{ key: '1440', value: 'Charleston-North Charleston, SC' },
		{ key: '1480', value: 'Charleston, WV' },
		{ key: '1520', value: 'Charlotte-Gastonia-Rock Hill, NC-SC' },
		{ key: '1540', value: 'Charlottesville, VA' },
		{ key: '1560', value: 'Chattanooga, TN-GA' },
		{ key: '1580', value: 'Cheyenne, WY' },
		{ key: '1602', value: 'Chicago-Gary-Kenosha, IL-IN-WI' },
		{ key: '1620', value: 'Chico-Paradise, CA' },
		{ key: '1642', value: 'Cincinnati-Hamilton, OH-KY-IN' },
		{ key: '1660', value: 'Clarksville-Hopkinsville, TN-KY' },
		{ key: '1692', value: 'Cleveland-Akron, OH' },
		{ key: '1720', value: 'Colorado Springs, CO' },
		{ key: '1740', value: 'Columbia, MO' },
		{ key: '1760', value: 'Columbia, SC' },
		{ key: '1800', value: 'Columbus, GA-AL' },
		{ key: '1840', value: 'Columbus, OH' },
		{ key: '1880', value: 'Corpus Christi, TX' },
		{ key: '1890', value: 'Corvallis, OR' },
		{ key: '1900', value: 'Cumberland, MD-WV' },
		{ key: '1922', value: 'Dallas-Fort Worth, TX' },
		{ key: '1950', value: 'Danville, VA' },
		{ key: '1960', value: 'Davenport-Moline-Rock Island, IA-IL' },
		{ key: '2000', value: 'Dayton-Springfield, OH' },
		{ key: '2020', value: 'Daytona Beach, FL' },
		{ key: '2030', value: 'Decatur, AL' },
		{ key: '2040', value: 'Decatur, IL' },
		{ key: '2082', value: 'Denver-Boulder-Greeley, CO' },
		{ key: '2120', value: 'Des Moines, IA' },
		{ key: '2162', value: 'Detroit-Ann Arbor-Flint, MI' },
		{ key: '2180', value: 'Dothan, AL' },
		{ key: '2190', value: 'Dover, DE' },
		{ key: '2200', value: 'Dubuque, IA' },
		{ key: '2240', value: 'Duluth-Superior, MN-WI' },
		{ key: '2290', value: 'Eau Claire, WI' },
		{ key: '2320', value: 'El Paso, TX' },
		{ key: '2330', value: 'Elkhart-Goshen, IN' },
		{ key: '2335', value: 'Elmira, NY' },
		{ key: '2340', value: 'Enid, OK' },
		{ key: '2360', value: 'Erie, PA' },
		{ key: '2400', value: 'Eugene-Springfield, OR' },
		{ key: '2440', value: 'Evansville-Henderson, IN-KY' },
		{ key: '2520', value: 'Fargo-Moorhead, ND-MN' },
		{ key: '2560', value: 'Fayetteville, NC' },
		{ key: '2580', value: 'Fayetteville-Springdale-Rogers, AR' },
		{ key: '2620', value: 'Flagstaff, AZ-UT' },
		{ key: '2650', value: 'Florence, AL' },
		{ key: '2655', value: 'Florence, SC' },
		{ key: '2670', value: 'Fort Collins-Loveland, CO' },
		{ key: '2700', value: 'Fort Myers-Cape Coral, FL' },
		{ key: '2710', value: 'Fort Pierce-Port St. Lucie, FL' },
		{ key: '2720', value: 'Fort Smith, AR-OK' },
		{ key: '2750', value: 'Fort Walton Beach, FL' },
		{ key: '2760', value: 'Fort Wayne, IN' },
		{ key: '2840', value: 'Fresno, CA' },
		{ key: '2880', value: 'Gadsden, AL' },
		{ key: '2900', value: 'Gainesville, FL' },
		{ key: '2975', value: 'Glens Falls, NY' },
		{ key: '2980', value: 'Goldsboro, NC' },
		{ key: '2985', value: 'Grand Forks, ND-MN' },
		{ key: '2995', value: 'Grand Junction, CO' },
		{ key: '3000', value: 'Grand Rapids-Muskegon-Holland, MI' },
		{ key: '3040', value: 'Great Falls, MT' },
		{ key: '3080', value: 'Green Bay, WI' },
		{ key: '3120', value: 'Greensboro--Winston-Salem--High Point, NC' },
		{ key: '3150', value: 'Greenville, NC' },
		{ key: '3160', value: 'Greenville-Spartanburg-Anderson, SC' },
		{ key: '3240', value: 'Harrisburg-Lebanon-Carlisle, PA' },
		{ key: '3280', value: 'Hartford, CT' },
		{ key: '3285', value: 'Hattiesburg, MS' },
		{ key: '3290', value: 'Hickory-Morganton-Lenoir, NC' },
		{ key: '3320', value: 'Honolulu, HI' },
		{ key: '3350', value: 'Houma, LA' },
		{ key: '3362', value: 'Houston-Galveston-Brazoria, TX' },
		{ key: '3400', value: 'Huntington-Ashland, WV-KY-OH' },
		{ key: '3440', value: 'Huntsville, AL' },
		{ key: '3480', value: 'Indianapolis, IN' },
		{ key: '3500', value: 'Iowa City, IA' },
		{ key: '3520', value: 'Jackson, MI' },
		{ key: '3560', value: 'Jackson, MS' },
		{ key: '3580', value: 'Jackson, TN' },
		{ key: '3600', value: 'Jacksonville, FL' },
		{ key: '3605', value: 'Jacksonville, NC' },
		{ key: '3610', value: 'Jamestown, NY' },
		{ key: '3620', value: 'Janesville-Beloit, WI' },
		{ key: '3660', value: 'Johnson City-Kingsport-Bristol, TN-VA' },
		{ key: '3680', value: 'Johnstown, PA' },
		{ key: '3700', value: 'Jonesboro, AR' },
		{ key: '3710', value: 'Joplin, MO' },
		{ key: '3720', value: 'Kalamazoo-Battle Creek, MI' },
		{ key: '3760', value: 'Kansas City, MO-KS' },
		{ key: '3810', value: 'Killeen-Temple, TX' },
		{ key: '3840', value: 'Knoxville, TN' },
		{ key: '3850', value: 'Kokomo, IN' },
		{ key: '3870', value: 'La Crosse, WI-MN' },
		{ key: '3880', value: 'Lafayette, LA' },
		{ key: '3920', value: 'Lafayette, IN' },
		{ key: '3960', value: 'Lake Charles, LA' },
		{ key: '3980', value: 'Lakeland-Winter Haven, FL' },
		{ key: '4000', value: 'Lancaster, PA' },
		{ key: '4040', value: 'Lansing-East Lansing, MI' },
		{ key: '4080', value: 'Laredo, TX' },
		{ key: '4100', value: 'Las Cruces, NM' },
		{ key: '4120', value: 'Las Vegas, NV-AZ' },
		{ key: '4150', value: 'Lawrence, KS' },
		{ key: '4200', value: 'Lawton, OK' },
		{ key: '4240', value: 'Lewiston-Auburn, ME' },
		{ key: '4280', value: 'Lexington, KY' },
		{ key: '4320', value: 'Lima, OH' },
		{ key: '4360', value: 'Lincoln, NE' },
		{ key: '4400', value: 'Little Rock-North Little Rock, AR' },
		{ key: '4420', value: 'Longview-Marshall, TX' },
		{ key: '4472', value: 'Los Angeles-Riverside-Orange County, CA' },
		{ key: '4520', value: 'Louisville, KY-IN' },
		{ key: '4600', value: 'Lubbock, TX' },
		{ key: '4640', value: 'Lynchburg, VA' },
		{ key: '4680', value: 'Macon, GA' },
		{ key: '4720', value: 'Madison, WI' },
		{ key: '4800', value: 'Mansfield, OH' },
		{ key: '4840', value: 'Mayaguez, PR' },
		{ key: '4880', value: 'McAllen-Edinburg-Mission, TX' },
		{ key: '4890', value: 'Medford-Ashland, OR' },
		{ key: '4900', value: 'Melbourne-Titusville-Palm Bay, FL' },
		{ key: '4920', value: 'Memphis, TN-AR-MS' },
		{ key: '4940', value: 'Merced, CA' },
		{ key: '4992', value: 'Miami-Fort Lauderdale, FL' },
		{ key: '5082', value: 'Milwaukee-Racine, WI' },
		{ key: '5120', value: 'Minneapolis-St. Paul, MN-WI' },
		{ key: '5140', value: 'Missoula, MT' },
		{ key: '5160', value: 'Mobile, AL' },
		{ key: '5170', value: 'Modesto, CA' },
		{ key: '5200', value: 'Monroe, LA' },
		{ key: '5240', value: 'Montgomery, AL' },
		{ key: '5280', value: 'Muncie, IN' },
		{ key: '5330', value: 'Myrtle Beach, SC' },
		{ key: '5345', value: 'Naples, FL' },
		{ key: '5360', value: 'Nashville, TN' },
		{ key: '5520', value: 'New London-Norwich, CT-RI' },
		{ key: '5560', value: 'New Orleans, LA' },
		{ key: '5602', value: 'New York-Northern New Jersey-Long Island, NY-NJ-CT-PA' },
		{ key: '5720', value: 'Norfolk-Virginia Beach-Newport News, VA-NC' },
		{ key: '5790', value: 'Ocala, FL' },
		{ key: '5800', value: 'Odessa-Midland, TX' },
		{ key: '5880', value: 'Oklahoma City, OK' },
		{ key: '5920', value: 'Omaha, NE-IA' },
		{ key: '5960', value: 'Orlando, FL' },
		{ key: '5990', value: 'Owensboro, KY' },
		{ key: '6015', value: 'Panama City, FL' },
		{ key: '6020', value: 'Parkersburg-Marietta, WV-OH' },
		{ key: '6080', value: 'Pensacola, FL' },
		{ key: '6120', value: 'Peoria-Pekin, IL' },
		{ key: '6162', value: 'Philadelphia-Wilmington-Atlantic City, PA-NJ-DE-MD' },
		{ key: '6200', value: 'Phoenix-Mesa, AZ' },
		{ key: '6240', value: 'Pine Bluff, AR' },
		{ key: '6280', value: 'Pittsburgh, PA' },
		{ key: '6320', value: 'Pittsfield, MA' },
		{ key: '6340', value: 'Pocatello, ID' },
		{ key: '6360', value: 'Ponce, PR' },
		{ key: '6400', value: 'Portland, ME' },
		{ key: '6442', value: 'Portland-Salem, OR-WA' },
		{ key: '6480', value: 'Providence-Fall River-Warwick, RI-MA' },
		{ key: '6520', value: 'Provo-Orem, UT' },
		{ key: '6560', value: 'Pueblo, CO' },
		{ key: '6580', value: 'Punta Gorda, FL' },
		{ key: '6640', value: 'Raleigh-Durham-Chapel Hill, NC' },
		{ key: '6660', value: 'Rapid City, SD' },
		{ key: '6680', value: 'Reading, PA' },
		{ key: '6690', value: 'Redding, CA' },
		{ key: '6720', value: 'Reno, NV' },
		{ key: '6740', value: 'Richland-Kennewick-Pasco, WA' },
		{ key: '6760', value: 'Richmond-Petersburg, VA' },
		{ key: '6800', value: 'Roanoke, VA' },
		{ key: '6820', value: 'Rochester, MN' },
		{ key: '6840', value: 'Rochester, NY' },
		{ key: '6880', value: 'Rockford, IL' },
		{ key: '6895', value: 'Rocky Mount, NC' },
		{ key: '6922', value: 'Sacramento-Yolo, CA' },
		{ key: '6960', value: 'Saginaw-Bay City-Midland, MI' },
		{ key: '6980', value: 'St. Cloud, MN' },
		{ key: '7000', value: 'St. Joseph, MO' },
		{ key: '7040', value: 'St. Louis, MO-IL' },
		{ key: '7120', value: 'Salinas, CA' },
		{ key: '7160', value: 'Salt Lake City-Ogden, UT' },
		{ key: '7200', value: 'San Angelo, TX' },
		{ key: '7240', value: 'San Antonio, TX' },
		{ key: '7320', value: 'San Diego, CA' },
		{ key: '7362', value: 'San Francisco-Oakland-San Jose, CA' },
		{ key: '7442', value: 'San Juan-Caguas-Arecibo, PR' },
		{ key: '7460', value: 'San Luis Obispo-Atascadero-Paso Robles, CA' },
		{ key: '7480', value: 'Santa Barbara-Santa Maria-Lompoc, CA' },
		{ key: '7490', value: 'Santa Fe, NM' },
		{ key: '7510', value: 'Sarasota-Bradenton, FL' },
		{ key: '7520', value: 'Savannah, GA' },
		{ key: '7560', value: 'Scranton--Wilkes-Barre--Hazleton, PA' },
		{ key: '7602', value: 'Seattle-Tacoma-Bremerton, WA' },
		{ key: '7610', value: 'Sharon, PA' },
		{ key: '7620', value: 'Sheboygan, WI' },
		{ key: '7640', value: 'Sherman-Denison, TX' },
		{ key: '7680', value: 'Shreveport-Bossier City, LA' },
		{ key: '7720', value: 'Sioux City, IA-NE' },
		{ key: '7760', value: 'Sioux Falls, SD' },
		{ key: '7800', value: 'South Bend, IN' },
		{ key: '7840', value: 'Spokane, WA' },
		{ key: '7880', value: 'Springfield, IL' },
		{ key: '7920', value: 'Springfield, MO' },
		{ key: '8000', value: 'Springfield, MA' },
		{ key: '8050', value: 'State College, PA' },
		{ key: '8080', value: 'Steubenville-Weirton, OH-WV' },
		{ key: '8120', value: 'Stockton-Lodi, CA' },
		{ key: '8140', value: 'Sumter, SC' },
		{ key: '8160', value: 'Syracuse, NY' },
		{ key: '8240', value: 'Tallahassee, FL' },
		{ key: '8280', value: 'Tampa-St. Petersburg-Clearwater, FL' },
		{ key: '8320', value: 'Terre Haute, IN' },
		{ key: '8360', value: 'Texarkana, TX-Texarkana, AR' },
		{ key: '8400', value: 'Toledo, OH' },
		{ key: '8440', value: 'Topeka, KS' },
		{ key: '8520', value: 'Tucson, AZ' },
		{ key: '8560', value: 'Tulsa, OK' },
		{ key: '8600', value: 'Tuscaloosa, AL' },
		{ key: '8640', value: 'Tyler, TX' },
		{ key: '8680', value: 'Utica-Rome, NY' },
		{ key: '8750', value: 'Victoria, TX' },
		{ key: '8780', value: 'Visalia-Tulare-Porterville, CA' },
		{ key: '8800', value: 'Waco, TX' },
		{ key: '8872', value: 'Washington-Baltimore, DC-MD-VA-WV' },
		{ key: '8920', value: 'Waterloo-Cedar Falls, IA' },
		{ key: '8940', value: 'Wausau, WI' },
		{ key: '8960', value: 'West Palm Beach-Boca Raton, FL' },
		{ key: '9000', value: 'Wheeling, WV-OH' },
		{ key: '9040', value: 'Wichita, KS' },
		{ key: '9080', value: 'Wichita Falls, TX' },
		{ key: '9140', value: 'Williamsport, PA' },
		{ key: '9200', value: 'Wilmington, NC' },
		{ key: '9260', value: 'Yakima, WA' },
		{ key: '9280', value: 'York, PA' },
		{ key: '9320', value: 'Youngstown-Warren, OH' },
		{ key: '9340', value: 'Yuba City, CA' },
		{ key: '9360', value: 'Yuma, AZ' },
	],
	businessLegalStatus: [
		{ key: '1', value: 'Individual/Sole Proprietor' },
		{ key: '2', value: 'Partnership' },
		{ key: '3', value: 'Limited Partnership' },
		{ key: '4', value: 'Corporation' },
		{ key: '5', value: 'Corporation, Subchapter S' },
		{ key: '6', value: 'Limited Liability Company (LLC)' },
		{ key: '7', value: 'Limited Liability Partnership (LLP)' },
		{ key: '8', value: 'Other' },
		{ key: '9', value: 'Corporation, Subchapter C' },
		{ key: '10', value: 'Non-Profit' },
		{ key: '11', value: 'Mutual Company' },
		{ key: '12', value: 'Trust' },
		{ key: '13', value: 'Limited Liability Limited Partnership (LLLP)' }
	],
	yearEstablished: [] as { key: string; value: string }[],
	corporateEmployeeCodes: [
		{ key: 'A', value: '1-4' },
		{ key: 'B', value: '5-9' },
		{ key: 'C', value: '10-19' },
		{ key: 'D', value: '20-49' },
		{ key: 'E', value: '50-99' },
		{ key: 'F', value: '100-249' },
		{ key: 'G', value: '250-499' },
		{ key: 'H', value: '500-999' },
		{ key: 'I', value: '1,000-4,999' },
		{ key: 'J', value: '5,000-9,999' },
		{ key: 'K', value: '10,000+' }
	],
	locationEmployeeCodes: [
		{ key: 'A', value: '1-4' },
		{ key: 'B', value: '5-9' },
		{ key: 'C', value: '10-19' },
		{ key: 'D', value: '20-49' },
		{ key: 'E', value: '50-99' },
		{ key: 'F', value: '100-249' },
		{ key: 'G', value: '250-499' },
		{ key: 'H', value: '500-999' },
		{ key: 'I', value: '1,000-4,999' },
		{ key: 'J', value: '5,000-9,999' },
		{ key: 'K', value: '10,000+' }
	],
	corporateAmountCodes: [
		{ key: 'A', value: '$1-$499' },
		{ key: 'B', value: '$500-$999' },
		{ key: 'C', value: '$1,000-$2,499' },
		{ key: 'D', value: '$2,500-$4,999' },
		{ key: 'E', value: '$5,000-$9,999' },
		{ key: 'F', value: '$10,000-$19,999' },
		{ key: 'G', value: '$20,000-$49,999' },
		{ key: 'H', value: '$50,000-$99,999' },
		{ key: 'I', value: '$100,000-$499,999' },
		{ key: 'J', value: '$500,000-$999,999' },
		{ key: 'K', value: '$1,000,000+' },
	],
	corporateAmountType: [
		{ key: 'Corporate Assets', value: 'Corporate Assets' },
		{ key: 'Corporate Revenue', value: 'Corporate Revenue' },
		{ key: 'Corporate Sales', value: 'Corporate Sales' },
	],
	corporateAmountPrecision: [
		{ key: 'ACTUAL', value: 'Actual' },
		{ key: 'EST', value: 'Estimated' },
		{ key: 'MDL', value: 'Modeled' },
	],
	locationAmountCodes: [
		{ key: 'A', value: '$1-$499' },
		{ key: 'B', value: '$500-$999' },
		{ key: 'C', value: '$1,000-$2,499' },
		{ key: 'D', value: '$2,500-$4,999' },
		{ key: 'E', value: '$5,000-$9,999' },
		{ key: 'F', value: '$10,000-$19,999' },
		{ key: 'G', value: '$20,000-$49,999' },
		{ key: 'H', value: '$50,000-$99,999' },
		{ key: 'I', value: '$100,000-$499,999' },
		{ key: 'J', value: '$500,000-$999,999' },
		{ key: 'K', value: '$1,000,000+' },
	],
	locationAmountType: [
		{ key: 'Location Revenue', value: 'Location Revenue' },
		{ key: 'Location Sales', value: 'Location Sales' },
		{ key: 'Others', value: 'Others' },
	],
	locationAmountPrecision: [
		{ key: 'ACTUAL', value: 'Actual' },
		{ key: 'EST', value: 'Estimated' },
		{ key: 'MDL', value: 'Modeled' },
	],
	businessSize: [
		{ key: 'L', value: 'Large' },
		{ key: 'S', value: 'Small' },
		{ key: 'X', value: 'Unknown' },
	],
	primaryContactTitle: [
		{ key: '1', value: 'Owner' },
		{ key: '2', value: 'President' },
		{ key: '3', value: 'Manager' },
		{ key: '4', value: 'Executive Director' },
		{ key: '5', value: 'Principal' },
		{ key: '6', value: 'Publisher' },
		{ key: '7', value: 'Administrator' },
		{ key: '8', value: 'Religious Leader' },
		{ key: '9', value: 'Partner' },
		{ key: 'A', value: 'Chairman' },
		{ key: 'AA', value: 'Secretary' },
		{ key: 'AB', value: 'Associate' },
		{ key: 'AC', value: 'Assistant' },
		{ key: 'AD', value: 'Attorney' },
		{ key: 'AE', value: 'Customer Service Representative' },
		{ key: 'AF', value: 'Consultant' },
		{ key: 'AG', value: 'Engineer' },
		{ key: 'AH', value: 'Accountant' },
		{ key: 'AI', value: 'Architect' },
		{ key: 'AJ', value: 'Account Executive' },
		{ key: 'AK', value: 'Member' },
		{ key: 'AL', value: 'Shareholder' },
		{ key: 'AM', value: 'Founder' },
		{ key: 'AO', value: 'Chancellor' },
		{ key: 'AP', value: 'Vice President Sales' },
		{ key: 'AQ', value: 'Senior Vice President Sales' },
		{ key: 'AR', value: 'Vice President Marketing' },
		{ key: 'AS', value: 'Senior Vice President Marketing' },
		{ key: 'AT', value: 'Chief Marketing Officer' },
		{ key: 'AU', value: 'VP Purchasing' },
		{ key: 'AV', value: 'Chief Purchasing Officer' },
		{ key: 'AW', value: 'General Manager' },
		{ key: 'AX', value: 'Managing Director' },
		{ key: 'AY', value: 'Professional' },
		{ key: 'AZ', value: 'Chief Information Officer (CIO)' },
		{ key: 'B', value: 'Vice Chairman' },
		{ key: 'BA', value: 'Chief Technical Officer (CTO)' },
		{ key: 'BB', value: 'Chief Risk Officer' },
		{ key: 'BC', value: 'Chief Investment Officer' },
		{ key: 'BD', value: 'Officer' },
		{ key: 'BE', value: 'Doctor' },
		{ key: 'BF', value: 'Medical Professional' },
		{ key: 'BG', value: 'Real Estate Agent' },
		{ key: 'BH', value: 'IT Professional' },
		{ key: 'BI', value: 'Mayor' },
		{ key: 'BJ', value: 'Superintendent' },
		{ key: 'BK', value: 'Educator' },
		{ key: 'BL', value: 'Broker' },
		{ key: 'BM', value: 'Public Relations Professional' },
		{ key: 'BN', value: 'Board Member' },
		{ key: 'BO', value: 'Library Professional' },
		{ key: 'BP', value: 'Government Professional' },
		{ key: 'BQ', value: 'Manufacturing Executive' },
		{ key: 'BR', value: 'Strategic Planning Executive' },
		{ key: 'BS', value: 'Executive' },
		{ key: 'BT', value: 'Analyst' },
		{ key: 'BU', value: 'Loan Officer' },
		{ key: 'BV', value: 'Counselor' },
		{ key: 'BW', value: 'Marketing Professional' },
		{ key: 'BX', value: 'Recruiter' },
		{ key: 'BY', value: 'Scientist' },
		{ key: 'BZ', value: 'Contractor' },
		{ key: 'Bu', value: 'Loan Officer' },
		{ key: 'C', value: 'Chief Executive Officer' },
		{ key: 'D', value: 'Director' },
		{ key: 'E', value: 'Chief Operating Officer (COO)' },
		{ key: 'F', value: 'Chief Financial Officer (CFO)' },
		{ key: 'G', value: 'Treasurer' },
		{ key: 'H', value: 'Controller' },
		{ key: 'I', value: 'Executive Vice President' },
		{ key: 'J', value: 'Senior Vice President' },
		{ key: 'K', value: 'Vice President' },
		{ key: 'L', value: 'Administration Executive' },
		{ key: 'M', value: 'Corporate Communications Executive' },
		{ key: 'N', value: 'Data Processing Executive' },
		{ key: 'O', value: 'Finance Executive' },
		{ key: 'P', value: 'Human Resources Executive' },
		{ key: 'Q', value: 'Telecommunication Executive' },
		{ key: 'R', value: 'Marketing Executive' },
		{ key: 'S', value: 'Operations Executive' },
		{ key: 'T', value: 'Sales Executive' },
		{ key: 'U', value: 'Corporate Secretary' },
		{ key: 'V', value: 'General Counsel' },
		{ key: 'W', value: 'Executive Officer' },
		{ key: 'X', value: 'Plant Manager' },
		{ key: 'Y', value: 'Purchasing Agent' },
		{ key: 'Z', value: 'Auditor' },
	],
	primaryContactEthnicity: [
		{ key: 'African American', value: 'African American' },
		{ key: 'Asian', value: 'Asian' },
		{ key: 'Asian-Indian', value: 'Asian-Indian' },
		{ key: 'Asian-Pacific', value: 'Asian-Pacific' },
		{ key: 'Ethnicity N/A', value: 'Ethnicity N/A' },
		{ key: 'Hispanic', value: 'Hispanic' },
		{ key: 'MBE-Ethnicity N/A', value: 'MBE-Ethnicity N/A' },
		{ key: 'Native American', value: 'Native American' },
		{ key: 'Non-Ethnic', value: 'Non-Ethnic' },
	],
	primaryContactGender: [
		{ key: 'F', value: 'Female' },
		{ key: 'M', value: 'Male' },
		{ key: 'Gender N/A', value: 'Gender N/A' },
	],
	publicPrivateStatus: [
		{ key: '1', value: 'Public'},
		{ key: '2', value: 'Private'}
	],
	sicCodes: [
		{ key: '01', value: '01 AGRICULTURAL PRODUCTION-CROPS' },
		{ key: '011', value: '011 CASH GRAINS' },
		{ key: '0111', value: '0111 WHEAT' },
		{ key: '0112', value: '0112 RICE' },
		{ key: '0115', value: '0115 CORN' },
		{ key: '0116', value: '0116 SOYBEANS' },
		{ key: '0119', value: '0119 CASH GRAINS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '013', value: '013 FIELD CROPS, EXCEPT CASH GRAINS' },
		{ key: '0131', value: '0131 COTTON' },
		{ key: '0132', value: '0132 TOBACCO' },
		{ key: '0133', value: '0133 SUGARCANE AND SUGAR BEETS' },
		{ key: '0134', value: '0134 IRISH POTATOES' },
		{ key: '0139', value: '0139 FIELD CROPS, EXCEPT CASH GRAINS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '016', value: '016 VEGETABLES AND MELONS' },
		{ key: '0161', value: '0161 VEGETABLES AND MELONS' },
		{ key: '017', value: '017 FRUITS AND TREE NUTS' },
		{ key: '0171', value: '0171 BERRY CROPS' },
		{ key: '0172', value: '0172 GRAPES' },
		{ key: '0173', value: '0173 TREE NUTS' },
		{ key: '0174', value: '0174 CITRUS FRUITS' },
		{ key: '0175', value: '0175 DECIDUOUS TREE FRUITS' },
		{ key: '0179', value: '0179 FRUITS AND TREE NUTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '018', value: '018 HORTICULTURAL SPECIALTIES' },
		{ key: '0181', value: '0181 ORNAMENTAL FLORICULTURE AND NURSERY PRODUCTS' },
		{ key: '0182', value: '0182 FOOD CROPS GROWN UNDER COVER' },
		{ key: '019', value: '019 GENERAL FARMS, PRIMARILY CROP' },
		{ key: '0191', value: '0191 GENERAL FARMS, PRIMARILY CROP' },
		{ key: '02', value: '02 AGRICULTURAL PRODUCTION-LIVESTOCK AND ANIMAL SPECIALTIES' },
		{ key: '021', value: '021 LIVESTOCK, EXCEPT DAIRY AND POULTRY' },
		{ key: '0211', value: '0211 BEEF CATTLE FEEDLOTS' },
		{ key: '0212', value: '0212 BEEF CATTLE, EXCEPT FEEDLOTS' },
		{ key: '0213', value: '0213 HOGS' },
		{ key: '0214', value: '0214 SHEEP AND GOATS' },
		{ key: '0219', value: '0219 GENERAL LIVESTOCK, EXCEPT DAIRY AND POULTRY' },
		{ key: '024', value: '024 DAIRY FARMS' },
		{ key: '0241', value: '0241 DAIRY FARMS' },
		{ key: '025', value: '025 POULTRY AND EGGS' },
		{ key: '0251', value: '0251 BROILER, FRYER, AND ROASTER CHICKENS' },
		{ key: '0252', value: '0252 CHICKEN EGGS' },
		{ key: '0253', value: '0253 TURKEYS AND TURKEY EGGS' },
		{ key: '0254', value: '0254 POULTRY HATCHERIES' },
		{ key: '0259', value: '0259 POULTRY AND EGGS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '027', value: '027 ANIMAL SPECIALTIES' },
		{ key: '0271', value: '0271 FUR-BEARING ANIMALS AND RABBITS' },
		{ key: '0272', value: '0272 HORSES AND OTHER EQUINES' },
		{ key: '0273', value: '0273 ANIMAL AQUACULTURE' },
		{ key: '0279', value: '0279 ANIMAL SPECIALTIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '029', value: '029 GENERAL FARMS, PRIMARILY LIVESTOCK AND ANIMAL SPECIALTIES' },
		{ key: '0291', value: '0291 GENERAL FARMS, PRIMARILY LIVESTOCK AND ANIMAL SPECIALTIES' },
		{ key: '07', value: '07 AGRICULTURAL SERVICES' },
		{ key: '071', value: '071 SOIL PREPARATION SERVICES' },
		{ key: '0711', value: '0711 SOIL PREPARATION SERVICES' },
		{ key: '072', value: '072 CROP SERVICES' },
		{ key: '0721', value: '0721 CROP PLANTING, CULTIVATING, AND PROTECTING' },
		{ key: '0722', value: '0722 CROP HARVESTING, PRIMARILY BY MACHINE' },
		{ key: '0723', value: '0723 CROP PREPARATION SERVICES FOR MARKET, EXCEPT COTTON GINNING' },
		{ key: '0724', value: '0724 COTTON GINNING' },
		{ key: '074', value: '074 VETERINARY SERVICES' },
		{ key: '0741', value: '0741 VETERINARY SERVICES FOR LIVESTOCK' },
		{ key: '0742', value: '0742 VETERINARY SERVICES FOR ANIMAL SPECIALTIES' },
		{ key: '075', value: '075 ANIMAL SERVICES, EXCEPT VETERINARY' },
		{ key: '0751', value: '0751 LIVESTOCK SERVICES, EXCEPT VETERINARY' },
		{ key: '0752', value: '0752 ANIMAL SPECIALTY SERVICES, EXCEPT VETERINARY' },
		{ key: '076', value: '076 FARM LABOR AND MANAGEMENT SERVICES' },
		{ key: '0761', value: '0761 FARM LABOR CONTRACTORS AND CREW LEADERS' },
		{ key: '0762', value: '0762 FARM MANAGEMENT SERVICES' },
		{ key: '078', value: '078 LANDSCAPE AND HORTICULTURAL SERVICES' },
		{ key: '0781', value: '0781 LANDSCAPE COUNSELING AND PLANNING' },
		{ key: '0782', value: '0782 LAWN AND GARDEN SERVICES' },
		{ key: '0783', value: '0783 ORNAMENTAL SHRUB AND TREE SERVICES' },
		{ key: '08', value: '08 -FORESTRY' },
		{ key: '081', value: '081 TIMBER TRACTS' },
		{ key: '0811', value: '0811 TIMBER TRACTS' },
		{ key: '083', value: '083 FOREST NURSERIES AND GATHERING OF FOREST PRODUCTS' },
		{ key: '0831', value: '0831 FOREST NURSERIES AND GATHERING OF FOREST PRODUCTS' },
		{ key: '085', value: '085 FORESTRY SERVICES' },
		{ key: '0851', value: '0851 FORESTRY SERVICES' },
		{ key: '09', value: '09 FISHING, HUNTING, AND TRAPPING' },
		{ key: '091', value: '091 COMMERCIAL FISHING' },
		{ key: '0912', value: '0912 FINFISH' },
		{ key: '0913', value: '0913 SHELLFISH' },
		{ key: '0919', value: '0919 MISCELLANEOUS MARINE PRODUCTS' },
		{ key: '092', value: '092 FISH HATCHERIES AND PRESERVES' },
		{ key: '0921', value: '0921 FISH HATCHERIES AND PRESERVES' },
		{ key: '097', value: '097 HUNTING AND TRAPPING, AND GAME PROPAGATION' },
		{ key: '0971', value: '0971 HUNTING AND TRAPPING, AND GAME PROPAGATION' },
		{ key: '10', value: '10 METAL MINING' },
		{ key: '101', value: '101 IRON ORES' },
		{ key: '1011', value: '1011 IRON ORES' },
		{ key: '102', value: '102 COPPER ORES' },
		{ key: '1021', value: '1021 COPPER ORES' },
		{ key: '103', value: '103 LEAD AND ZINC ORES' },
		{ key: '1031', value: '1031 LEAD AND ZINC ORES' },
		{ key: '104', value: '104 GOLD AND SILVER ORES' },
		{ key: '1041', value: '1041 GOLD ORES' },
		{ key: '1044', value: '1044 SILVER ORES' },
		{ key: '106', value: '106 FERROALLOY ORES, EXCEPT VANADIUM' },
		{ key: '1061', value: '1061 FERROALLOY ORES, EXCEPT VANADIUM' },
		{ key: '108', value: '108 METAL MINING SERVICES' },
		{ key: '1081', value: '1081 METAL MINING SERVICES' },
		{ key: '109', value: '109 MISCELLANEOUS METAL ORES' },
		{ key: '1094', value: '1094 URANIUM-RADIUM-VANADIUM ORES' },
		{ key: '1099', value: '1099 MISCELLANEOUS METAL ORES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '12', value: '12 COAL MINING' },
		{ key: '122', value: '122 BITUMINOUS COAL AND LIGNITE MINING' },
		{ key: '1221', value: '1221 BITUMINOUS COAL AND LIGNITE SURFACE MINING' },
		{ key: '1222', value: '1222 BITUMINOUS COAL UNDERGROUND MINING' },
		{ key: '123', value: '123 ANTHRACITE MINING' },
		{ key: '1231', value: '1231 ANTHRACITE MINING' },
		{ key: '124', value: '124 COAL MINING SERVICES' },
		{ key: '1241', value: '1241 COAL MINING SERVICES' },
		{ key: '13', value: '13 OIL AND GAS EXTRACTION' },
		{ key: '131', value: '131 CRUDE PETROLEUM AND NATURAL GAS' },
		{ key: '1311', value: '1311 CRUDE PETROLEUM AND NATURAL GAS' },
		{ key: '132', value: '132 NATURAL GAS LIQUIDS' },
		{ key: '1321', value: '1321 NATURAL GAS LIQUIDS' },
		{ key: '138', value: '138 OIL AND GAS FIELD SERVICES' },
		{ key: '1381', value: '1381 DRILLING OIL AND GAS WELLS' },
		{ key: '1382', value: '1382 OIL AND GAS FIELD EXPLORATION SERVICES' },
		{ key: '1389', value: '1389 OIL AND GAS FIELD SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '14', value: '14 MINING AND QUARRYING OF NONMETALLIC MINERALS, EXCEPT FUELS' },
		{ key: '141', value: '141 DIMENSION STONE' },
		{ key: '1411', value: '1411 DIMENSION STONE' },
		{ key: '142', value: '142 CRUSHED AND BROKEN STONE, INCLUDING RIPRAP' },
		{ key: '1422', value: '1422 CRUSHED AND BROKEN LIMESTONE' },
		{ key: '1423', value: '1423 CRUSHED AND BROKEN GRANITE' },
		{ key: '1429', value: '1429 CRUSHED AND BROKEN STONE, NOT ELSEWHERE CLASSIFIED' },
		{ key: '144', value: '144 SAND AND GRAVEL' },
		{ key: '1442', value: '1442 CONSTRUCTION SAND AND GRAVEL' },
		{ key: '1446', value: '1446 INDUSTRIAL SAND' },
		{ key: '145', value: '145 CLAY, CERAMIC, AND REFRACTORY MINERALS' },
		{ key: '1455', value: '1455 KAOLIN AND BALL CLAY' },
		{ key: '1459', value: '1459 CLAY, CERAMIC, AND REFRACTORY MINERALS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '147', value: '147 CHEMICAL AND FERTILIZER MINERAL MINING' },
		{ key: '1474', value: '1474 POTASH, SODA, AND BORATE MINERALS' },
		{ key: '1475', value: '1475 PHOSPHATE ROCK' },
		{ key: '1479', value: '1479 CHEMICAL AND FERTILIZER MINERAL MINING, NOT ELSEWHERE CLASSIFIED' },
		{ key: '148', value: '148 NONMETALLIC MINERALS SERVICES, EXCEPT FUELS' },
		{ key: '1481', value: '1481 NONMETALLIC MINERALS SERVICES, EXCEPT FUELS' },
		{ key: '149', value: '149 MISCELLANEOUS NONMETALLIC MINERALS, EXCEPT FUELS' },
		{ key: '1499', value: '1499 MISCELLANEOUS NONMETALLIC MINERALS, EXCEPT FUELS' },
		{ key: '15', value: '15 BUILDING CONSTRUCTION-GENERAL CONTRACTORS AND OPERATIVE BUILDERS' },
		{ key: '152', value: '152 GENERAL BUILDING CONTRACTORS-RESIDENTIAL BUILDINGS' },
		{ key: '1521', value: '1521 GENERAL CONTRACTORS-SINGLE-FAMILY HOUSES' },
		{ key: '1522', value: '1522 GENERAL CONTRACTORS-RESIDENTIAL BUILDINGS, OTHER THAN SINGLE-FAMI' },
		{ key: '153', value: '153 OPERATIVE BUILDERS' },
		{ key: '1531', value: '1531 OPERATIVE BUILDERS' },
		{ key: '154', value: '154 GENERAL BUILDING CONTRACTORS-NONRESIDENTIAL BUILDINGS' },
		{ key: '1541', value: '1541 GENERAL CONTRACTORS-INDUSTRIAL BUILDINGS AND WAREHOUSES' },
		{ key: '1542', value: '1542 GENERAL CONTRACTORS-NONRESIDENTIAL BUILDINGS, OTHER THAN INDUSTRI' },
		{ key: '16', value: '16 HEAVY CONSTRUCTION OTHER THAN BUILDING CONSTRUCTION-CONTRACTORS' },
		{ key: '161', value: '161 HIGHWAY AND STREET CONSTRUCTION, EXCEPT ELEVATED HIGHWAYS' },
		{ key: '1611', value: '1611 HIGHWAY AND STREET CONSTRUCTION, EXCEPT ELEVATED HIGHWAYS' },
		{ key: '162', value: '162 HEAVY CONSTRUCTION, EXCEPT HIGHWAY AND STREET CONSTRUCTION' },
		{ key: '1622', value: '1622 BRIDGE, TUNNEL, AND ELEVATED HIGHWAY CONSTRUCTION' },
		{ key: '1623', value: '1623 WATER, SEWER, PIPELINE, AND COMMUNICATIONS AND POWER LINE CONSTRU' },
		{ key: '1629', value: '1629 HEAVY CONSTRUCTION, NOT ELSEWHERE CLASSIFIED' },
		{ key: '17', value: '17 CONSTRUCTION-SPECIAL TRADE CONTRACTORS' },
		{ key: '171', value: '171 PLUMBING, HEATING AND AIR-CONDITIONING' },
		{ key: '1711', value: '1711 PLUMBING, HEATING AND AIR-CONDITIONING' },
		{ key: '172', value: '172 PAINTING AND PAPER HANGING' },
		{ key: '1721', value: '1721 PAINTING AND PAPER HANGING' },
		{ key: '173', value: '173 ELECTRICAL WORK' },
		{ key: '1731', value: '1731 ELECTRICAL WORK' },
		{ key: '174', value: '174 MASONRY, STONEWORK, TILE SETTING, AND PLASTERING' },
		{ key: '1741', value: '1741 MASONRY, STONE SETTING, AND OTHER STONE WORK' },
		{ key: '1742', value: '1742 PLASTERING, DRYWALL, ACOUSTICAL, AND INSULATION WORK' },
		{ key: '1743', value: '1743 TERRAZZO, TILE, MARBLE, AND MOSAIC WORK' },
		{ key: '175', value: '175 CARPENTRY AND FLOOR WORK' },
		{ key: '1751', value: '1751 CARPENTRY WORK' },
		{ key: '1752', value: '1752 FLOOR LAYING AND OTHER FLOOR WORK, NOT ELSEWHERE CLASSIFIED' },
		{ key: '176', value: '176 ROOFING, SIDING, AND SHEET METAL WORK' },
		{ key: '1761', value: '1761 ROOFING, SIDING, AND SHEET METAL WORK' },
		{ key: '177', value: '177 CONCRETE WORK' },
		{ key: '1771', value: '1771 CONCRETE WORK' },
		{ key: '178', value: '178 WATER WELL DRILLING' },
		{ key: '1781', value: '1781 WATER WELL DRILLING' },
		{ key: '179', value: '179 MISCELLANEOUS SPECIAL TRADE CONTRACTORS' },
		{ key: '1791', value: '1791 STRUCTURAL STEEL ERECTION' },
		{ key: '1793', value: '1793 GLASS AND GLAZING WORK' },
		{ key: '1793', value: '1793 GLASS INSTALLATION, EXCEPT AUTOMOTIVE-CONTRACTORS' },
		{ key: '1794', value: '1794 EXCAVATION WORK' },
		{ key: '1795', value: '1795 WRECKING AND DEMOLITION WORK' },
		{ key: '1796', value: '1796 INSTALLATION OR ERECTION OF BUILDING EQUIPMENT, NOT ELSEWHERE CLA' },
		{ key: '1799', value: '1799 SPECIAL TRADE CONTRACTORS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '20', value: '20 FOOD AND KINDRED PRODUCTS' },
		{ key: '201', value: '201 MEAT PRODUCTS' },
		{ key: '2011', value: '2011 MEAT PACKING PLANTS' },
		{ key: '2013', value: '2013 SAUSAGES AND OTHER PREPARED MEAT PRODUCTS' },
		{ key: '2015', value: '2015 POULTRY SLAUGHTERING AND PROCESSING' },
		{ key: '202', value: '202 DAIRY PRODUCTS' },
		{ key: '2021', value: '2021 CREAMERY BUTTER' },
		{ key: '2022', value: '2022 NATURAL, PROCESSED, AND IMITATION CHEESE' },
		{ key: '2023', value: '2023 DRY, CONDENSED, AND EVAPORATED DAIRY PRODUCTS' },
		{ key: '2024', value: '2024 ICE CREAM AND FROZEN DESSERTS' },
		{ key: '2026', value: '2026 FLUID MILK' },
		{ key: '203', value: '203 CANNED, FROZEN, AND PRESERVED FRUITS, VEGETABLES, AND FOOD SPECIAL' },
		{ key: '2032', value: '2032 CANNED SPECIALTIES' },
		{ key: '2033', value: '2033 CANNED FRUITS, VEGETABLES, PRESERVES, JAMS, AND JELLIES' },
		{ key: '2034', value: '2034 DRIED AND DEHYDRATED FRUITS, VEGETABLES, AND SOUP MIXES' },
		{ key: '2035', value: '2035 PICKLED FRUITS AND VEGETABLES, VEGETABLE SAUCES AND SEASONINGS, A' },
		{ key: '2037', value: '2037 FROZEN FRUITS, FRUIT JUICES, AND VEGETABLES' },
		{ key: '2038', value: '2038 FROZEN SPECIALTIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '204', value: '204 GRAIN MILL PRODUCTS' },
		{ key: '2041', value: '2041 FLOUR AND OTHER GRAIN MILL PRODUCTS' },
		{ key: '2043', value: '2043 CEREAL BREAKFAST FOODS' },
		{ key: '2044', value: '2044 RICE MILLING' },
		{ key: '2045', value: '2045 PREPARED FLOUR MIXES AND DOUGHS' },
		{ key: '2046', value: '2046 WET CORN MILLING' },
		{ key: '2047', value: '2047 DOG AND CAT FOOD' },
		{ key: '2048', value: '2048 PREPARED FEEDS AND FEED INGREDIENTS FOR ANIMALS AND FOWLS, EXCEPT' },
		{ key: '205', value: '205 BAKERY PRODUCTS' },
		{ key: '2051', value: '2051 BREAD AND OTHER BAKERY PRODUCTS, EXCEPT COOKIES AND CRACKERS' },
		{ key: '2052', value: '2052 COOKIES AND CRACKERS' },
		{ key: '2053', value: '2053 FROZEN BAKERY PRODUCTS, EXCEPT BREAD' },
		{ key: '206', value: '206 SUGAR AND CONFECTIONERY PRODUCTS' },
		{ key: '2061', value: '2061 CANE SUGAR, EXCEPT REFINING' },
		{ key: '2062', value: '2062 CANE SUGAR REFINING' },
		{ key: '2063', value: '2063 BEET SUGAR' },
		{ key: '2064', value: '2064 CANDY AND OTHER CONFECTIONERY PRODUCTS' },
		{ key: '2066', value: '2066 CHOCOLATE AND COCOA PRODUCTS' },
		{ key: '2067', value: '2067 CHEWING GUM' },
		{ key: '2068', value: '2068 SALTED AND ROASTED NUTS AND SEEDS' },
		{ key: '207', value: '207 FATS AND OILS' },
		{ key: '2074', value: '2074 COTTONSEED OIL MILLS' },
		{ key: '2075', value: '2075 SOYBEAN OIL MILLS' },
		{ key: '2076', value: '2076 VEGETABLE OIL MILLS, EXCEPT CORN, COTTONSEED, AND SOYBEAN' },
		{ key: '2077', value: '2077 ANIMAL AND MARINE FATS AND OILS' },
		{ key: '2079', value: '2079 SHORTENING, TABLE OILS, MARGARINE, AND OTHER EDIBLE FATS AND OILS' },
		{ key: '208', value: '208 BEVERAGES' },
		{ key: '2082', value: '2082 MALT BEVERAGES' },
		{ key: '2083', value: '2083 MALT' },
		{ key: '2084', value: '2084 WINES, BRANDY, AND BRANDY SPIRITS' },
		{ key: '2085', value: '2085 DISTILLED AND BLENDED LIQUORS' },
		{ key: '2086', value: '2086 BOTTLED AND CANNED SOFT DRINKS AND CARBONATED WATERS' },
		{ key: '2087', value: '2087 FLAVORING EXTRACTS AND FLAVORING SYRUPS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '209', value: '209 MISCELLANEOUS FOOD PREPARATIONS AND KINDRED PRODUCTS' },
		{ key: '2091', value: '2091 CANNED AND CURED FISH AND SEAFOODS' },
		{ key: '2092', value: '2092 PREPARED FRESH OR FROZEN FISH AND SEAFOODS' },
		{ key: '2095', value: '2095 ROASTED COFFEE' },
		{ key: '2096', value: '2096 POTATO CHIPS, CORN CHIPS, AND SIMILAR SNACKS' },
		{ key: '2097', value: '2097 MANUFACTURED ICE' },
		{ key: '2098', value: '2098 MACARONI, SPAGHETTI, VERMICELLI, AND NOODLES' },
		{ key: '2099', value: '2099 FOOD PREPARATIONS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '21', value: '21 TOBACCO PRODUCTS' },
		{ key: '211', value: '211 CIGARETTES' },
		{ key: '2111', value: '2111 CIGARETTES' },
		{ key: '212', value: '212 CIGARS' },
		{ key: '2121', value: '2121 CIGARS' },
		{ key: '213', value: '213 CHEWING AND SMOKING TOBACCO AND SNUFF' },
		{ key: '2131', value: '2131 CHEWING AND SMOKING TOBACCO AND SNUFF' },
		{ key: '214', value: '214 TOBACCO STEMMING AND REDRYING' },
		{ key: '2141', value: '2141 TOBACCO STEMMING AND REDRYING' },
		{ key: '22', value: '22 TEXTILE MILL PRODUCTS' },
		{ key: '221', value: '221 BROADWOVEN FABRIC MILLS, COTTON' },
		{ key: '2211', value: '2211 BROADWOVEN FABRIC MILLS, COTTON' },
		{ key: '222', value: '222 BROADWOVEN FABRIC MILLS, MANMADE FIBER AND SILK' },
		{ key: '2221', value: '2221 BROADWOVEN FABRIC MILLS, MANMADE FIBER AND SILK' },
		{ key: '223', value: '223 BROADWOVEN FABRIC MILLS, WOOL (INCLUDING DYEING AND FINISHING)' },
		{ key: '2231', value: '2231 BROADWOVEN FABRIC MILLS, WOOL (INCLUDING DYEING AND FINISHING)' },
		{ key: '224', value: '224 NARROW FABRIC AND OTHER SMALLWARES MILLS: COTTON, WOOL, SILK, AND' },
		{ key: '2241', value: '2241 NARROW FABRIC AND OTHER SMALLWARES MILLS: COTTON, WOOL, SILK, AND' },
		{ key: '225', value: '225 KNITTING MILLS' },
		{ key: '2251', value: '2251 WOMEN\'S FULL-LENGTH AND KNEE-LENGTH HOSIERY, EXCEPT SOCKS' },
		{ key: '2252', value: '2252 HOSIERY, NOT ELSEWHERE CLASSIFIED' },
		{ key: '2253', value: '2253 KNIT OUTERWEAR MILLS' },
		{ key: '2254', value: '2254 KNIT UNDERWEAR AND NIGHTWEAR MILLS' },
		{ key: '2257', value: '2257 WEFT KNIT FABRIC MILLS' },
		{ key: '2258', value: '2258 LACE AND WARP KNIT FABRIC MILLS' },
		{ key: '2259', value: '2259 KNITTING MILLS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '226', value: '226 DYEING AND FINISHING TEXTILES, EXCEPT WOOL FABRICS AND KNIT GOODS' },
		{ key: '2261', value: '2261 FINISHERS OF BROADWOVEN FABRICS OF COTTON' },
		{ key: '2262', value: '2262 FINISHERS OF BROADWOVEN FABRICS OF MANMADE FIBER AND SILK' },
		{ key: '2269', value: '2269 FINISHERS OF TEXTILES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '227', value: '227 CARPETS AND RUGS' },
		{ key: '2273', value: '2273 CARPETS AND RUGS' },
		{ key: '228', value: '228 YARN AND THREAD MILLS' },
		{ key: '2281', value: '2281 YARN SPINNING MILLS' },
		{ key: '2282', value: '2282 YARN TEXTURIZING, THROWING, TWISTING, AND WINDING MILLS' },
		{ key: '2282', value: '2282 ACETATE FILAMENT YARN: THROWING, TWISTING, WINDING, OR SPOOLING' },
		{ key: '2284', value: '2284 THREAD MILLS' },
		{ key: '229', value: '229 MISCELLANEOUS TEXTILE GOODS' },
		{ key: '2295', value: '2295 COATED FABRICS, NOT RUBBERIZED' },
		{ key: '2296', value: '2296 TIRE CORD AND FABRICS' },
		{ key: '2297', value: '2297 NONWOVEN FABRICS' },
		{ key: '2298', value: '2298 CORDAGE AND TWINE' },
		{ key: '2299', value: '2299 TEXTILE GOODS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '23', value: '23 APPAREL AND OTHER FINISHED PRODUCTS MADE FROM FABRICS AND SIMILAR MATERIAL' },
		{ key: '231', value: '231 MEN\'S AND BOYS\' SUITS, COATS, AND OVERCOATS' },
		{ key: '2311', value: '2311 MEN\'S AND BOYS\' SUITS, COATS, AND OVERCOATS' },
		{ key: '232', value: '232 MEN\'S AND BOYS\' FURNISHINGS, WORK CLOTHING, AND ALLIED GARMENTS' },
		{ key: '2321', value: '2321 MEN\'S AND BOYS\' SHIRTS, EXCEPT WORK SHIRTS' },
		{ key: '2322', value: '2322 MEN\'S AND BOYS\' UNDERWEAR AND NIGHTWEAR' },
		{ key: '2323', value: '2323 MEN\'S AND BOYS\' NECKWEAR' },
		{ key: '2325', value: '2325 MEN\'S AND BOYS\' SEPARATE TROUSERS AND SLACKS' },
		{ key: '2326', value: '2326 MEN\'S AND BOYS\' WORK CLOTHING' },
		{ key: '2329', value: '2329 MEN\'S AND BOYS\' CLOTHING, NOT ELSEWHERE CLASSIFIED' },
		{ key: '233', value: '233 WOMEN\'S, MISSES\', AND JUNIORS\' OUTERWEAR' },
		{ key: '2331', value: '2331 WOMEN\'S, MISSES\', AND JUNIORS\' BLOUSES AND SHIRTS' },
		{ key: '2335', value: '2335 WOMEN\'S, MISSES\', AND JUNIORS\' DRESSES' },
		{ key: '2337', value: '2337 WOMEN\'S, MISSES\', AND JUNIORS\' SUITS, SKIRTS, AND COATS' },
		{ key: '2339', value: '2339 WOMEN\'S, MISSES\', AND JUNIORS\' OUTERWEAR, NOT ELSEWHERE CLASSIFIE' },
		{ key: '234', value: '234 WOMEN\'S, MISSES\', CHILDREN\'S, AND INFANTS\' UNDERGARMENTS' },
		{ key: '2341', value: '2341 WOMEN\'S, MISSES\', CHILDREN\'S, AND INFANTS\' UNDERWEAR AND NIGHTWEA' },
		{ key: '2342', value: '2342 BRASSIERES, GIRDLES, AND ALLIED GARMENTS' },
		{ key: '235', value: '235 HATS, CAPS, AND MILLINERY' },
		{ key: '2353', value: '2353 HATS, CAPS, AND MILLINERY' },
		{ key: '236', value: '236 GIRLS\', CHILDREN\'S, AND INFANTS\' OUTERWEAR' },
		{ key: '2361', value: '2361 GIRLS\', CHILDREN\'S, AND INFANTS\' DRESSES, BLOUSES, AND SHIRTS' },
		{ key: '2369', value: '2369 GIRLS\', CHILDREN\'S, AND INFANTS\' OUTERWEAR, NOT ELSEWHERE CLASSIF' },
		{ key: '237', value: '237 FUR GOODS' },
		{ key: '2371', value: '2371 FUR GOODS' },
		{ key: '238', value: '238 MISCELLANEOUS APPAREL AND ACCESSORIES' },
		{ key: '2381', value: '2381 DRESS AND WORK GLOVES, EXCEPT KNIT AND ALL-LEATHER' },
		{ key: '2384', value: '2384 ROBES AND DRESSING GOWNS' },
		{ key: '2385', value: '2385 WATERPROOF OUTERWEAR' },
		{ key: '2386', value: '2386 LEATHER AND SHEEP-LINED CLOTHING' },
		{ key: '2387', value: '2387 APPAREL BELTS' },
		{ key: '2389', value: '2389 APPAREL AND ACCESSORIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '239', value: '239 MISCELLANEOUS FABRICATED TEXTILE PRODUCTS' },
		{ key: '2391', value: '2391 CURTAINS AND DRAPERIES' },
		{ key: '2392', value: '2392 HOUSEFURNISHINGS, EXCEPT CURTAINS AND DRAPERIES' },
		{ key: '2393', value: '2393 TEXTILE BAGS' },
		{ key: '2394', value: '2394 CANVAS AND RELATED PRODUCTS' },
		{ key: '2395', value: '2395 PLEATING, DECORATIVE AND NOVELTY STITCHING, AND TUCKING FOR THE T' },
		{ key: '2396', value: '2396 AUTOMOTIVE TRIMMINGS, APPAREL FINDINGS, AND RELATED PRODUCTS' },
		{ key: '2397', value: '2397 SCHIFFLI MACHINE EMBROIDERIES' },
		{ key: '2399', value: '2399 FABRICATED TEXTILE PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '24', value: '24 LUMBER AND WOOD PRODUCTS, EXCEPT FURNITURE' },
		{ key: '241', value: '241 LOGGING' },
		{ key: '2411', value: '2411 LOGGING' },
		{ key: '242', value: '242 SAWMILLS AND PLANING MILLS' },
		{ key: '2421', value: '2421 SAWMILLS AND PLANING MILLS, GENERAL' },
		{ key: '2426', value: '2426 HARDWOOD DIMENSION AND FLOORING MILLS' },
		{ key: '2429', value: '2429 SPECIAL PRODUCT SAWMILLS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '243', value: '243 MILLWORK, VENEER, PLYWOOD, AND STRUCTURAL WOOD MEMBERS' },
		{ key: '2431', value: '2431 MILLWORK' },
		{ key: '2434', value: '2434 WOOD KITCHEN CABINETS' },
		{ key: '2435', value: '2435 HARDWOOD VENEER AND PLYWOOD' },
		{ key: '2436', value: '2436 SOFTWOOD VENEER AND PLYWOOD' },
		{ key: '2439', value: '2439 STRUCTURAL WOOD MEMBERS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '244', value: '244 WOOD CONTAINERS' },
		{ key: '2441', value: '2441 NAILED AND LOCK CORNER WOOD BOXES AND SHOOK' },
		{ key: '2448', value: '2448 WOOD PALLETS AND SKIDS' },
		{ key: '2449', value: '2449 WOOD CONTAINERS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '245', value: '245 WOOD BUILDINGS AND MOBILE HOMES' },
		{ key: '2451', value: '2451 MOBILE HOMES' },
		{ key: '2452', value: '2452 PREFABRICATED WOOD BUILDINGS AND COMPONENTS' },
		{ key: '249', value: '249 MISCELLANEOUS WOOD PRODUCTS' },
		{ key: '2491', value: '2491 WOOD PRESERVING' },
		{ key: '2493', value: '2493 RECONSTITUTED WOOD PRODUCTS' },
		{ key: '2499', value: '2499 WOOD PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '25', value: '25 FURNITURE AND FIXTURES' },
		{ key: '251', value: '251 HOUSEHOLD FURNITURE' },
		{ key: '2511', value: '2511 WOOD HOUSEHOLD FURNITURE, EXCEPT UPHOLSTERED' },
		{ key: '2512', value: '2512 WOOD HOUSEHOLD FURNITURE, UPHOLSTERED' },
		{ key: '2514', value: '2514 METAL HOUSEHOLD FURNITURE' },
		{ key: '2515', value: '2515 MATTRESSES, FOUNDATIONS, AND CONVERTIBLE BEDS' },
		{ key: '2517', value: '2517 WOOD TELEVISION, RADIO, PHONOGRAPH, AND SEWING MACHINE CABINETS' },
		{ key: '2519', value: '2519 HOUSEHOLD FURNITURE, NOT ELSEWHERE CLASSIFIED' },
		{ key: '252', value: '252 OFFICE FURNITURE' },
		{ key: '2521', value: '2521 WOOD OFFICE FURNITURE' },
		{ key: '2522', value: '2522 OFFICE FURNITURE, EXCEPT WOOD' },
		{ key: '253', value: '253 PUBLIC BUILDING AND RELATED FURNITURE' },
		{ key: '2531', value: '2531 PUBLIC BUILDING AND RELATED FURNITURE' },
		{ key: '254', value: '254 PARTITIONS, SHELVING, LOCKERS, AND OFFICE AND STORE FIXTURES' },
		{ key: '2541', value: '2541 WOOD OFFICE AND STORE FIXTURES, PARTITIONS, SHELVING, AND LOCKERS' },
		{ key: '2542', value: '2542 OFFICE AND STORE FIXTURES, PARTITIONS, SHELVING, AND LOCKERS, EXC' },
		{ key: '259', value: '259 MISCELLANEOUS FURNITURE AND FIXTURES' },
		{ key: '2591', value: '2591 DRAPERY HARDWARE AND WINDOW BLINDS AND SHADES' },
		{ key: '2599', value: '2599 FURNITURE AND FIXTURES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '26', value: '26 PAPER AND ALLIED PRODUCTS' },
		{ key: '261', value: '261 PULP MILLS' },
		{ key: '2611', value: '2611 PULP MILLS' },
		{ key: '262', value: '262 PAPER MILLS' },
		{ key: '2621', value: '2621 PAPER MILLS' },
		{ key: '263', value: '263 PAPERBOARD MILLS' },
		{ key: '2631', value: '2631 PAPERBOARD MILLS' },
		{ key: '265', value: '265 PAPERBOARD CONTAINERS AND BOXES' },
		{ key: '2652', value: '2652 SETUP PAPERBOARD BOXES' },
		{ key: '2653', value: '2653 CORRUGATED AND SOLID FIBER BOXES' },
		{ key: '2655', value: '2655 FIBER CANS, TUBES, DRUMS, AND SIMILAR PRODUCTS' },
		{ key: '2656', value: '2656 SANITARY FOOD CONTAINERS, EXCEPT FOLDING' },
		{ key: '2657', value: '2657 FOLDING PAPERBOARD BOXES, INCLUDING SANITARY' },
		{ key: '267', value: '267 CONVERTED PAPER AND PAPERBOARD PRODUCTS, EXCEPT CONTAINERS AND BOX' },
		{ key: '2671', value: '2671 PACKAGING PAPER AND PLASTICS FILM, COATED AND LAMINATED' },
		{ key: '2672', value: '2672 COATED AND LAMINATED PAPER, NOT ELSEWHERE CLASSIFIED' },
		{ key: '2673', value: '2673 PLASTICS, FOIL, AND COATED PAPER BAGS' },
		{ key: '2674', value: '2674 UNCOATED PAPER AND MULTIWALL BAGS' },
		{ key: '2675', value: '2675 DIE-CUT PAPER AND PAPERBOARD AND CARDBOARD' },
		{ key: '2676', value: '2676 SANITARY PAPER PRODUCTS' },
		{ key: '2677', value: '2677 ENVELOPES' },
		{ key: '2678', value: '2678 STATIONERY, TABLETS, AND RELATED PRODUCTS' },
		{ key: '2679', value: '2679 CONVERTED PAPER AND PAPERBOARD PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '27', value: '27 PRINTING, PUBLISHING, AND ALLIED INDUSTRIES' },
		{ key: '271', value: '271 NEWSPAPERS: PUBLISHING, OR PUBLISHING AND PRINTING' },
		{ key: '2711', value: '2711 NEWSPAPERS: PUBLISHING, OR PUBLISHING AND PRINTING' },
		{ key: '272', value: '272 PERIODICALS: PUBLISHING, OR PUBLISHING AND PRINTING' },
		{ key: '2721', value: '2721 PERIODICALS: PUBLISHING, OR PUBLISHING AND PRINTING' },
		{ key: '273', value: '273 BOOKS' },
		{ key: '2731', value: '2731 BOOKS: PUBLISHING, OR PUBLISHING AND PRINTING' },
		{ key: '2732', value: '2732 BOOK PRINTING' },
		{ key: '274', value: '274 MISCELLANEOUS PUBLISHING' },
		{ key: '2741', value: '2741 MISCELLANEOUS PUBLISHING' },
		{ key: '275', value: '275 COMMERCIAL PRINTING' },
		{ key: '2752', value: '2752 COMMERCIAL PRINTING, LITHOGRAPHIC' },
		{ key: '2754', value: '2754 COMMERCIAL PRINTING, GRAVURE' },
		{ key: '2759', value: '2759 COMMERCIAL PRINTING, NOT ELSEWHERE CLASSIFIED' },
		{ key: '276', value: '276 MANIFOLD BUSINESS FORMS' },
		{ key: '2761', value: '2761 MANIFOLD BUSINESS FORMS' },
		{ key: '277', value: '277 GREETING CARDS' },
		{ key: '2771', value: '2771 GREETING CARDS' },
		{ key: '278', value: '278 BLANKBOOKS, LOOSELEAF BINDERS, AND BOOKBINDING AND RELATED WORK' },
		{ key: '2782', value: '2782 BLANKBOOKS, LOOSELEAF BINDERS AND DEVICES' },
		{ key: '2789', value: '2789 BOOKBINDING AND RELATED WORK' },
		{ key: '279', value: '279 SERVICE INDUSTRIES FOR THE PRINTING TRADE' },
		{ key: '2791', value: '2791 TYPESETTING' },
		{ key: '2796', value: '2796 PLATEMAKING AND RELATED SERVICES' },
		{ key: '28', value: '28 CHEMICALS AND ALLIED PRODUCTS' },
		{ key: '281', value: '281 INDUSTRIAL INORGANIC CHEMICALS' },
		{ key: '2812', value: '2812 ALKALIES AND CHLORINE' },
		{ key: '2813', value: '2813 INDUSTRIAL GASES' },
		{ key: '2816', value: '2816 INORGANIC PIGMENTS' },
		{ key: '2819', value: '2819 INDUSTRIAL INORGANIC CHEMICALS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '282', value: '282 PLASTICS MATERIALS AND SYNTHETIC RESINS, SYNTHETIC RUBBER, CELLULO' },
		{ key: '2821', value: '2821 PLASTICS MATERIALS, SYNTHETIC RESINS, AND NONVULCANIZABLE ELASTOM' },
		{ key: '2822', value: '2822 SYNTHETIC RUBBER (VULCANIZABLE ELASTOMERS)' },
		{ key: '2823', value: '2823 CELLULOSIC MANMADE FIBERS' },
		{ key: '2824', value: '2824 MANMADE ORGANIC FIBERS, EXCEPT CELLULOSIC' },
		{ key: '283', value: '283 DRUGS' },
		{ key: '2833', value: '2833 MEDICINAL CHEMICALS AND BOTANICAL PRODUCTS' },
		{ key: '2834', value: '2834 PHARMACEUTICAL PREPARATIONS' },
		{ key: '2835', value: '2835 IN VITRO AND IN VIVO DIAGNOSTIC SUBSTANCES' },
		{ key: '2836', value: '2836 BIOLOGICAL PRODUCTS, EXCEPT DIAGNOSTIC SUBSTANCES' },
		{ key: '284', value: '284 SOAP, DETERGENTS, AND CLEANING PREPARATIONS; PERFUMES, COSMETICS,' },
		{ key: '2841', value: '2841 SOAP AND OTHER DETERGENTS, EXCEPT SPECIALTY CLEANERS' },
		{ key: '2842', value: '2842 SPECIALTY CLEANING, POLISHING, AND SANITATION PREPARATIONS' },
		{ key: '2843', value: '2843 SURFACE ACTIVE AGENTS, FINISHING AGENTS, SULFONATED OILS, AND ASS' },
		{ key: '2844', value: '2844 PERFUMES, COSMETICS, AND OTHER TOILET PREPARATIONS' },
		{ key: '285', value: '285 PAINTS, VARNISHES, LACQUERS, ENAMELS, AND ALLIED PRODUCTS' },
		{ key: '2851', value: '2851 PAINTS, VARNISHES, LACQUERS, ENAMELS, AND ALLIED PRODUCTS' },
		{ key: '286', value: '286 INDUSTRIAL ORGANIC CHEMICALS' },
		{ key: '2861', value: '2861 GUM AND WOOD CHEMICALS' },
		{ key: '2865', value: '2865 CYCLIC ORGANIC CRUDES AND INTERMEDIATES, AND ORGANIC DYES AND PIG' },
		{ key: '2869', value: '2869 INDUSTRIAL ORGANIC CHEMICALS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '287', value: '287 AGRICULTURAL CHEMICALS' },
		{ key: '2873', value: '2873 NITROGENOUS FERTILIZERS' },
		{ key: '2874', value: '2874 PHOSPHATIC FERTILIZERS' },
		{ key: '2875', value: '2875 FERTILIZERS, MIXING ONLY' },
		{ key: '2879', value: '2879 PESTICIDES AND AGRICULTURAL CHEMICALS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '289', value: '289 MISCELLANEOUS CHEMICAL PRODUCTS' },
		{ key: '2891', value: '2891 ADHESIVES AND SEALANTS' },
		{ key: '2892', value: '2892 EXPLOSIVES' },
		{ key: '2893', value: '2893 PRINTING INK' },
		{ key: '2895', value: '2895 CARBON BLACK' },
		{ key: '2899', value: '2899 CHEMICALS AND CHEMICAL PREPARATIONS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '29', value: '29 PETROLEUM REFINING AND RELATED INDUSTRIES' },
		{ key: '291', value: '291 PETROLEUM REFINING' },
		{ key: '2911', value: '2911 PETROLEUM REFINING' },
		{ key: '295', value: '295 ASPHALT PAVING AND ROOFING MATERIALS' },
		{ key: '2951', value: '2951 ASPHALT PAVING MIXTURES AND BLOCKS' },
		{ key: '2952', value: '2952 ASPHALT FELTS AND COATINGS' },
		{ key: '299', value: '299 MISCELLANEOUS PRODUCTS OF PETROLEUM AND COAL' },
		{ key: '2992', value: '2992 LUBRICATING OILS AND GREASES' },
		{ key: '2999', value: '2999 PRODUCTS OF PETROLEUM AND COAL, NOT ELSEWHERE CLASSIFIED' },
		{ key: '30', value: '30 RUBBER AND MISCELLANEOUS PLASTICS PRODUCTS' },
		{ key: '301', value: '301 TIRES AND INNER TUBES' },
		{ key: '3011', value: '3011 TIRES AND INNER TUBES' },
		{ key: '302', value: '302 RUBBER AND PLASTICS FOOTWEAR' },
		{ key: '3021', value: '3021 RUBBER AND PLASTICS FOOTWEAR' },
		{ key: '305', value: '305 GASKETS, PACKING, AND SEALING DEVICES AND RUBBER AND PLASTICS HOSE' },
		{ key: '3052', value: '3052 RUBBER AND PLASTICS HOSE AND BELTING' },
		{ key: '3053', value: '3053 GASKETS, PACKING, AND SEALING DEVICES' },
		{ key: '306', value: '306 FABRICATED RUBBER PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '3061', value: '3061 MOLDED, EXTRUDED, AND LATHE-CUT MECHANICAL RUBBER GOODS' },
		{ key: '3069', value: '3069 FABRICATED RUBBER PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '308', value: '308 MISCELLANEOUS PLASTICS PRODUCTS' },
		{ key: '3081', value: '3081 UNSUPPORTED PLASTICS FILM AND SHEET' },
		{ key: '3082', value: '3082 UNSUPPORTED PLASTICS PROFILE SHAPES' },
		{ key: '3083', value: '3083 LAMINATED PLASTICS PLATE, SHEET, AND PROFILE SHAPES' },
		{ key: '3084', value: '3084 PLASTICS PIPE' },
		{ key: '3085', value: '3085 PLASTICS BOTTLES' },
		{ key: '3086', value: '3086 PLASTICS FOAM PRODUCTS' },
		{ key: '3087', value: '3087 CUSTOM COMPOUNDING OF PURCHASED PLASTICS RESINS' },
		{ key: '3088', value: '3088 PLASTICS PLUMBING FIXTURES' },
		{ key: '3089', value: '3089 PLASTICS PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '31', value: '31 LEATHER AND LEATHER PRODUCTS' },
		{ key: '311', value: '311 LEATHER TANNING AND FINISHING' },
		{ key: '3111', value: '3111 LEATHER TANNING AND FINISHING' },
		{ key: '313', value: '313 BOOT AND SHOE CUT STOCK AND FINDINGS' },
		{ key: '3131', value: '3131 BOOT AND SHOE CUT STOCK AND FINDINGS' },
		{ key: '314', value: '314 FOOTWEAR, EXCEPT RUBBER' },
		{ key: '3142', value: '3142 HOUSE SLIPPERS' },
		{ key: '3143', value: '3143 MEN\'S FOOTWEAR, EXCEPT ATHLETIC' },
		{ key: '3144', value: '3144 WOMEN\'S FOOTWEAR, EXCEPT ATHLETIC' },
		{ key: '3149', value: '3149 FOOTWEAR, EXCEPT RUBBER, NOT ELSEWHERE CLASSIFIED' },
		{ key: '315', value: '315 LEATHER GLOVES AND MITTENS' },
		{ key: '3151', value: '3151 LEATHER GLOVES AND MITTENS' },
		{ key: '316', value: '316 LUGGAGE' },
		{ key: '3161', value: '3161 LUGGAGE' },
		{ key: '317', value: '317 HANDBAGS AND OTHER PERSONAL LEATHER GOODS' },
		{ key: '3171', value: '3171 WOMEN\'S HANDBAGS AND PURSES' },
		{ key: '3172', value: '3172 PERSONAL LEATHER GOODS, EXCEPT WOMEN\'S HANDBAGS AND PURSES' },
		{ key: '319', value: '319 LEATHER GOODS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '3199', value: '3199 LEATHER GOODS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '32', value: '32 STONE, CLAY, GLASS, AND CONCRETE PRODUCTS' },
		{ key: '321', value: '321 FLAT GLASS' },
		{ key: '3211', value: '3211 FLAT GLASS' },
		{ key: '322', value: '322 GLASS AND GLASSWARE, PRESSED OR BLOWN' },
		{ key: '3221', value: '3221 GLASS CONTAINERS' },
		{ key: '3229', value: '3229 PRESSED AND BLOWN GLASS AND GLASSWARE, NOT ELSEWHERE CLASSIFIED' },
		{ key: '323', value: '323 GLASS PRODUCTS, MADE OF PURCHASED GLASS' },
		{ key: '3231', value: '3231 GLASS PRODUCTS, MADE OF PURCHASED GLASS' },
		{ key: '324', value: '324 CEMENT, HYDRAULIC' },
		{ key: '3241', value: '3241 CEMENT, HYDRAULIC' },
		{ key: '325', value: '325 STRUCTURAL CLAY PRODUCTS' },
		{ key: '3251', value: '3251 BRICK AND STRUCTURAL CLAY TILE' },
		{ key: '3253', value: '3253 CERAMIC WALL AND FLOOR TILE' },
		{ key: '3255', value: '3255 CLAY REFRACTORIES' },
		{ key: '3259', value: '3259 STRUCTURAL CLAY PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '326', value: '326 POTTERY AND RELATED PRODUCTS' },
		{ key: '3261', value: '3261 VITREOUS CHINA PLUMBING FIXTURES AND CHINA AND EARTHENWARE FITTIN' },
		{ key: '3262', value: '3262 VITREOUS CHINA TABLE AND KITCHEN ARTICLES' },
		{ key: '3263', value: '3263 FINE EARTHENWARE (WHITEWARE) TABLE AND KITCHEN ARTICLES' },
		{ key: '3264', value: '3264 PORCELAIN ELECTRICAL SUPPLIES' },
		{ key: '3269', value: '3269 POTTERY PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '327', value: '327 CONCRETE, GYPSUM, AND PLASTER PRODUCTS' },
		{ key: '3271', value: '3271 CONCRETE BLOCK AND BRICK' },
		{ key: '3272', value: '3272 CONCRETE PRODUCTS, EXCEPT BLOCK AND BRICK' },
		{ key: '3273', value: '3273 READY-MIXED CONCRETE' },
		{ key: '3274', value: '3274 LIME' },
		{ key: '3275', value: '3275 GYPSUM PRODUCTS' },
		{ key: '328', value: '328 CUT STONE AND STONE PRODUCTS' },
		{ key: '3281', value: '3281 CUT STONE AND STONE PRODUCTS' },
		{ key: '329', value: '329 ABRASIVE, ASBESTOS, AND MISCELLANEOUS NONMETALLIC MINERAL PRODUCTS' },
		{ key: '3291', value: '3291 ABRASIVE PRODUCTS' },
		{ key: '3292', value: '3292 ASBESTOS PRODUCTS' },
		{ key: '3295', value: '3295 MINERALS AND EARTHS, GROUND OR OTHERWISE TREATED' },
		{ key: '3296', value: '3296 MINERAL WOOL' },
		{ key: '3297', value: '3297 NONCLAY REFRACTORIES' },
		{ key: '3299', value: '3299 NONMETALLIC MINERAL PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '33', value: '33 PRIMARY METAL INDUSTRIES' },
		{ key: '331', value: '331 STEEL WORKS, BLAST FURNACES, AND ROLLING AND FINISHING MILLS' },
		{ key: '3312', value: '3312 STEEL WORKS, BLAST FURNACES (INCLUDING COKE OVENS), AND ROLLING M' },
		{ key: '3313', value: '3313 ELECTROMETALLURGICAL PRODUCTS, EXCEPT STEEL' },
		{ key: '3315', value: '3315 STEEL WIREDRAWING AND STEEL NAILS AND SPIKES' },
		{ key: '3316', value: '3316 COLD-ROLLED STEEL SHEET, STRIP, AND BARS' },
		{ key: '3317', value: '3317 STEEL PIPE AND TUBES' },
		{ key: '332', value: '332 IRON AND STEEL FOUNDRIES' },
		{ key: '3321', value: '3321 GRAY AND DUCTILE IRON FOUNDRIES' },
		{ key: '3322', value: '3322 MALLEABLE IRON FOUNDRIES' },
		{ key: '3324', value: '3324 STEEL INVESTMENT FOUNDRIES' },
		{ key: '3325', value: '3325 STEEL FOUNDRIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '333', value: '333 PRIMARY SMELTING AND REFINING OF NONFERROUS METALS' },
		{ key: '3331', value: '3331 PRIMARY SMELTING AND REFINING OF COPPER' },
		{ key: '3334', value: '3334 PRIMARY PRODUCTION OF ALUMINUM' },
		{ key: '3339', value: '3339 PRIMARY SMELTING AND REFINING OF NONFERROUS METALS, EXCEPT COPPER' },
		{ key: '334', value: '334 SECONDARY SMELTING AND REFINING OF NONFERROUS METALS' },
		{ key: '3341', value: '3341 SECONDARY SMELTING AND REFINING OF NONFERROUS METALS' },
		{ key: '335', value: '335 ROLLING, DRAWING, AND EXTRUDING OF NONFERROUS METALS' },
		{ key: '3351', value: '3351 ROLLING, DRAWING, AND EXTRUDING OF COPPER' },
		{ key: '3353', value: '3353 ALUMINUM SHEET, PLATE, AND FOIL' },
		{ key: '3354', value: '3354 ALUMINUM EXTRUDED PRODUCTS' },
		{ key: '3355', value: '3355 ALUMINUM ROLLING AND DRAWING, NOT ELSEWHERE CLASSIFIED' },
		{ key: '3356', value: '3356 ROLLING, DRAWING, AND EXTRUDING OF NONFERROUS METALS, EXCEPT COPP' },
		{ key: '3357', value: '3357 DRAWING AND INSULATING OF NONFERROUS WIRE' },
		{ key: '336', value: '336 NONFERROUS FOUNDRIES (CASTINGS)' },
		{ key: '3363', value: '3363 ALUMINUM DIE-CASTINGS' },
		{ key: '3364', value: '3364 NONFERROUS DIE-CASTINGS, EXCEPT ALUMINUM' },
		{ key: '3365', value: '3365 ALUMINUM FOUNDRIES' },
		{ key: '3366', value: '3366 COPPER FOUNDRIES' },
		{ key: '3369', value: '3369 NONFERROUS FOUNDRIES, EXCEPT ALUMINUM AND COPPER' },
		{ key: '339', value: '339 MISCELLANEOUS PRIMARY METAL PRODUCTS' },
		{ key: '3398', value: '3398 METAL HEAT TREATING' },
		{ key: '3399', value: '3399 PRIMARY METAL PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '34', value: '34 FABRICATED METAL PRODUCTS, EXCEPT MACHINERY AND TRANSPORTATION EQUIPMENT' },
		{ key: '341', value: '341 METAL CANS AND SHIPPING CONTAINERS' },
		{ key: '3411', value: '3411 METAL CANS' },
		{ key: '3412', value: '3412 METAL SHIPPING BARRELS, DRUMS, KEGS, AND PAILS' },
		{ key: '342', value: '342 CUTLERY, HANDTOOLS, AND GENERAL HARDWARE' },
		{ key: '3421', value: '3421 CUTLERY' },
		{ key: '3423', value: '3423 HAND AND EDGE TOOLS, EXCEPT MACHINE TOOLS AND HANDSAWS' },
		{ key: '3425', value: '3425 SAW BLADES AND HANDSAWS' },
		{ key: '3429', value: '3429 HARDWARE, NOT ELSEWHERE CLASSIFIED' },
		{ key: '343', value: '343 HEATING EQUIPMENT, EXCEPT ELECTRIC AND WARM AIR; AND PLUMBING FIXT' },
		{ key: '3431', value: '3431 ENAMELED IRON AND METAL SANITARY WARE' },
		{ key: '3432', value: '3432 PLUMBING FIXTURE FITTINGS AND TRIM' },
		{ key: '3433', value: '3433 HEATING EQUIPMENT, EXCEPT ELECTRIC AND WARM AIR FURNACES' },
		{ key: '344', value: '344 FABRICATED STRUCTURAL METAL PRODUCTS' },
		{ key: '3441', value: '3441 FABRICATED STRUCTURAL METAL' },
		{ key: '3442', value: '3442 METAL DOORS, SASH, FRAMES, MOLDING, AND TRIM' },
		{ key: '3443', value: '3443 FABRICATED PLATE WORK (BOILER SHOPS)' },
		{ key: '3444', value: '3444 SHEET METALWORK' },
		{ key: '3446', value: '3446 ARCHITECTURAL AND ORNAMENTAL METALWORK' },
		{ key: '3448', value: '3448 PREFABRICATED METAL BUILDINGS AND COMPONENTS' },
		{ key: '3449', value: '3449 MISCELLANEOUS STRUCTURAL METALWORK' },
		{ key: '345', value: '345 SCREW MACHINE PRODUCTS, AND BOLTS, NUTS, SCREWS, RIVETS, AND WASHE' },
		{ key: '3451', value: '3451 SCREW MACHINE PRODUCTS' },
		{ key: '3452', value: '3452 BOLTS, NUTS, SCREWS, RIVETS, AND WASHERS' },
		{ key: '346', value: '346 METAL FORGINGS AND STAMPINGS' },
		{ key: '3462', value: '3462 IRON AND STEEL FORGINGS' },
		{ key: '3463', value: '3463 NONFERROUS FORGINGS' },
		{ key: '3465', value: '3465 AUTOMOTIVE STAMPINGS' },
		{ key: '3466', value: '3466 CROWNS AND CLOSURES' },
		{ key: '3469', value: '3469 METAL STAMPINGS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '347', value: '347 COATING, ENGRAVING, AND ALLIED SERVICES' },
		{ key: '3471', value: '3471 ELECTROPLATING, PLATING, POLISHING, ANODIZING, AND COLORING' },
		{ key: '3479', value: '3479 COATING, ENGRAVING, AND ALLIED SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '348', value: '348 ORDNANCE AND ACCESSORIES, EXCEPT VEHICLES AND GUIDED MISSILES' },
		{ key: '3482', value: '3482 SMALL ARMS AMMUNITION' },
		{ key: '3483', value: '3483 AMMUNITION, EXCEPT FOR SMALL ARMS' },
		{ key: '3484', value: '3484 SMALL ARMS' },
		{ key: '3489', value: '3489 ORDNANCE AND ACCESSORIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '349', value: '349 MISCELLANEOUS FABRICATED METAL PRODUCTS' },
		{ key: '3491', value: '3491 INDUSTRIAL VALVES' },
		{ key: '3492', value: '3492 FLUID POWER VALVES AND HOSE FITTINGS' },
		{ key: '3493', value: '3493 STEEL SPRINGS, EXCEPT WIRE' },
		{ key: '3494', value: '3494 VALVES AND PIPE FITTINGS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '3495', value: '3495 WIRE SPRINGS' },
		{ key: '3496', value: '3496 MISCELLANEOUS FABRICATED WIRE PRODUCTS' },
		{ key: '3497', value: '3497 METAL FOIL AND LEAF' },
		{ key: '3498', value: '3498 FABRICATED PIPE AND PIPE FITTINGS' },
		{ key: '3499', value: '3499 FABRICATED METAL PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '35', value: '35 INDUSTRIAL AND COMMERCIAL MACHINERY AND COMPUTER EQUIPMENT' },
		{ key: '351', value: '351 ENGINES AND TURBINES' },
		{ key: '3511', value: '3511 STEAM, GAS, AND HYDRAULIC TURBINES, AND TURBINE GENERATOR SET UNI' },
		{ key: '3519', value: '3519 INTERNAL COMBUSTION ENGINES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '352', value: '352 FARM AND GARDEN MACHINERY AND EQUIPMENT' },
		{ key: '3523', value: '3523 FARM MACHINERY AND EQUIPMENT' },
		{ key: '3524', value: '3524 LAWN AND GARDEN TRACTORS AND HOME LAWN AND GARDEN EQUIPMENT' },
		{ key: '3524', value: '3524 BLOWERS, RESIDENTIAL LAWN' },
		{ key: '353', value: '353 CONSTRUCTION, MINING, AND MATERIALS HANDLING MACHINERY AND EQUIPME' },
		{ key: '3531', value: '3531 CONSTRUCTION MACHINERY AND EQUIPMENT' },
		{ key: '3532', value: '3532 MINING MACHINERY AND EQUIPMENT, EXCEPT OIL AND GAS FIELD MACHINER' },
		{ key: '3533', value: '3533 OIL AND GAS FIELD MACHINERY AND EQUIPMENT' },
		{ key: '3534', value: '3534 ELEVATORS AND MOVING STAIRWAYS' },
		{ key: '3535', value: '3535 CONVEYORS AND CONVEYING EQUIPMENT' },
		{ key: '3536', value: '3536 OVERHEAD TRAVELING CRANES, HOISTS, AND MONORAIL SYSTEMS' },
		{ key: '3537', value: '3537 INDUSTRIAL TRUCKS, TRACTORS, TRAILERS, AND STACKERS' },
		{ key: '354', value: '354 METALWORKING MACHINERY AND EQUIPMENT' },
		{ key: '3541', value: '3541 MACHINE TOOLS, METAL CUTTING TYPES' },
		{ key: '3542', value: '3542 MACHINE TOOLS, METAL FORMING TYPES' },
		{ key: '3543', value: '3543 INDUSTRIAL PATTERNS' },
		{ key: '3544', value: '3544 SPECIAL DIES AND TOOLS, DIE SETS, JIGS AND FIXTURES, AND INDUSTRI' },
		{ key: '3545', value: '3545 CUTTING TOOLS, MACHINE TOOL ACCESSORIES, AND MACHINISTS\' PRECISIO' },
		{ key: '3546', value: '3546 POWER-DRIVEN HANDTOOLS' },
		{ key: '3547', value: '3547 ROLLING MILL MACHINERY AND EQUIPMENT' },
		{ key: '3548', value: '3548 ELECTRIC AND GAS WELDING AND SOLDERING EQUIPMENT' },
		{ key: '3549', value: '3549 METALWORKING MACHINERY, NOT ELSEWHERE CLASSIFIED' },
		{ key: '355', value: '355 SPECIAL INDUSTRY MACHINERY, EXCEPT METALWORKING MACHINERY' },
		{ key: '3552', value: '3552 TEXTILE MACHINERY' },
		{ key: '3553', value: '3553 WOODWORKING MACHINERY' },
		{ key: '3554', value: '3554 PAPER INDUSTRIES MACHINERY' },
		{ key: '3555', value: '3555 PRINTING TRADES MACHINERY AND EQUIPMENT' },
		{ key: '3556', value: '3556 FOOD PRODUCTS MACHINERY' },
		{ key: '3559', value: '3559 SPECIAL INDUSTRY MACHINERY, NOT ELSEWHERE CLASSIFIED' },
		{ key: '356', value: '356 GENERAL INDUSTRIAL MACHINERY AND EQUIPMENT' },
		{ key: '3561', value: '3561 PUMPS AND PUMPING EQUIPMENT' },
		{ key: '3562', value: '3562 BALL AND ROLLER BEARINGS' },
		{ key: '3563', value: '3563 AIR AND GAS COMPRESSORS' },
		{ key: '3564', value: '3564 INDUSTRIAL AND COMMERCIAL FANS AND BLOWERS AND AIR PURIFICATION E' },
		{ key: '3565', value: '3565 PACKAGING MACHINERY' },
		{ key: '3566', value: '3566 SPEED CHANGERS, INDUSTRIAL HIGH-SPEED DRIVES, AND GEARS' },
		{ key: '3567', value: '3567 INDUSTRIAL PROCESS FURNACES AND OVENS' },
		{ key: '3568', value: '3568 MECHANICAL POWER TRANSMISSION EQUIPMENT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '3569', value: '3569 GENERAL INDUSTRIAL MACHINERY AND EQUIPMENT, NOT ELSEWHERE CLASSIF' },
		{ key: '357', value: '357 COMPUTER AND OFFICE EQUIPMENT' },
		{ key: '3571', value: '3571 ELECTRONIC COMPUTERS' },
		{ key: '3572', value: '3572 COMPUTER STORAGE DEVICES' },
		{ key: '3575', value: '3575 COMPUTER TERMINALS' },
		{ key: '3577', value: '3577 COMPUTER PERIPHERAL EQUIPMENT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '3578', value: '3578 CALCULATING AND ACCOUNTING MACHINES, EXCEPT ELECTRONIC COMPUTERS' },
		{ key: '3579', value: '3579 OFFICE MACHINES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '358', value: '358 REFRIGERATION AND SERVICE INDUSTRY MACHINERY' },
		{ key: '3581', value: '3581 AUTOMATIC VENDING MACHINES' },
		{ key: '3582', value: '3582 COMMERCIAL LAUNDRY, DRYCLEANING, AND PRESSING MACHINES' },
		{ key: '3585', value: '3585 AIR-CONDITIONING AND WARM AIR HEATING EQUIPMENT AND COMMERCIAL AN' },
		{ key: '3586', value: '3586 MEASURING AND DISPENSING PUMPS' },
		{ key: '3589', value: '3589 SERVICE INDUSTRY MACHINERY, NOT ELSEWHERE CLASSIFIED' },
		{ key: '359', value: '359 MISCELLANEOUS INDUSTRIAL AND COMMERCIAL MACHINERY AND EQUIPMENT' },
		{ key: '3592', value: '3592 CARBURETORS, PISTONS, PISTON RINGS, AND VALVES' },
		{ key: '3593', value: '3593 FLUID POWER CYLINDERS AND ACTUATORS' },
		{ key: '3594', value: '3594 FLUID POWER PUMPS AND MOTORS' },
		{ key: '3596', value: '3596 SCALES AND BALANCES, EXCEPT LABORATORY' },
		{ key: '3599', value: '3599 INDUSTRIAL AND COMMERCIAL MACHINERY AND EQUIPMENT, NOT ELSEWHERE' },
		{ key: '36', value: '36 ELECTRONIC AND OTHER ELECTRICAL EQUIPMENT AND COMPONENTS, EXCEPT COMPUTER' },
		{ key: '361', value: '361 ELECTRIC TRANSMISSION AND DISTRIBUTION EQUIPMENT' },
		{ key: '3612', value: '3612 POWER, DISTRIBUTION, AND SPECIALTY TRANSFORMERS' },
		{ key: '3613', value: '3613 SWITCHGEAR AND SWITCHBOARD APPARATUS' },
		{ key: '362', value: '362 ELECTRICAL INDUSTRIAL APPARATUS' },
		{ key: '3621', value: '3621 MOTORS AND GENERATORS' },
		{ key: '3624', value: '3624 CARBON AND GRAPHITE PRODUCTS' },
		{ key: '3625', value: '3625 RELAYS AND INDUSTRIAL CONTROLS' },
		{ key: '3629', value: '3629 ELECTRICAL INDUSTRIAL APPARATUS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '363', value: '363 HOUSEHOLD APPLIANCES' },
		{ key: '3631', value: '3631 HOUSEHOLD COOKING EQUIPMENT' },
		{ key: '3632', value: '3632 HOUSEHOLD REFRIGERATORS AND HOME AND FARM FREEZERS' },
		{ key: '3633', value: '3633 HOUSEHOLD LAUNDRY EQUIPMENT' },
		{ key: '3634', value: '3634 ELECTRIC HOUSEWARES AND FANS' },
		{ key: '3635', value: '3635 HOUSEHOLD VACUUM CLEANERS' },
		{ key: '3639', value: '3639 HOUSEHOLD APPLIANCES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '364', value: '364 ELECTRIC LIGHTING AND WIRING EQUIPMENT' },
		{ key: '3641', value: '3641 ELECTRIC LAMP BULBS AND TUBES' },
		{ key: '3643', value: '3643 CURRENT-CARRYING WIRING DEVICES' },
		{ key: '3644', value: '3644 NONCURRENT-CARRYING WIRING DEVICES' },
		{ key: '3645', value: '3645 RESIDENTIAL ELECTRIC LIGHTING FIXTURES' },
		{ key: '3646', value: '3646 COMMERCIAL, INDUSTRIAL, AND INSTITUTIONAL ELECTRIC LIGHTING FIXTU' },
		{ key: '3647', value: '3647 VEHICULAR LIGHTING EQUIPMENT' },
		{ key: '3648', value: '3648 LIGHTING EQUIPMENT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '365', value: '365 HOUSEHOLD AUDIO AND VIDEO EQUIPMENT, AND AUDIO RECORDINGS' },
		{ key: '3651', value: '3651 HOUSEHOLD AUDIO AND VIDEO EQUIPMENT' },
		{ key: '3652', value: '3652 PHONOGRAPH RECORDS AND PRERECORDED AUDIO TAPES AND DISKS' },
		{ key: '366', value: '366 COMMUNICATIONS EQUIPMENT' },
		{ key: '3661', value: '3661 TELEPHONE AND TELEGRAPH APPARATUS' },
		{ key: '3663', value: '3663 RADIO AND TELEVISION BROADCASTING AND COMMUNICATIONS EQUIPMENT' },
		{ key: '3669', value: '3669 COMMUNICATIONS EQUIPMENT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '367', value: '367 ELECTRONIC COMPONENTS AND ACCESSORIES' },
		{ key: '3671', value: '3671 ELECTRON TUBES' },
		{ key: '3672', value: '3672 PRINTED CIRCUIT BOARDS' },
		{ key: '3674', value: '3674 SEMICONDUCTORS AND RELATED DEVICES' },
		{ key: '3675', value: '3675 ELECTRONIC CAPACITORS' },
		{ key: '3676', value: '3676 ELECTRONIC RESISTORS' },
		{ key: '3677', value: '3677 ELECTRONIC COILS, TRANSFORMERS, AND OTHER INDUCTORS' },
		{ key: '3678', value: '3678 ELECTRONIC CONNECTORS' },
		{ key: '3679', value: '3679 ELECTRONIC COMPONENTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '369', value: '369 MISCELLANEOUS ELECTRICAL MACHINERY, EQUIPMENT, AND SUPPLIES' },
		{ key: '3691', value: '3691 STORAGE BATTERIES' },
		{ key: '3692', value: '3692 PRIMARY BATTERIES, DRY AND WET' },
		{ key: '3694', value: '3694 ELECTRICAL EQUIPMENT FOR INTERNAL COMBUSTION ENGINES' },
		{ key: '3695', value: '3695 MAGNETIC AND OPTICAL RECORDING MEDIA' },
		{ key: '3699', value: '3699 ELECTRICAL MACHINERY, EQUIPMENT, AND SUPPLIES, NOT ELSEWHERE CLAS' },
		{ key: '37', value: '37 TRANSPORTATION EQUIPMENT' },
		{ key: '371', value: '371 MOTOR VEHICLES AND MOTOR VEHICLE EQUIPMENT' },
		{ key: '3711', value: '3711 MOTOR VEHICLES AND PASSENGER CAR BODIES' },
		{ key: '3713', value: '3713 TRUCK AND BUS BODIES' },
		{ key: '3714', value: '3714 MOTOR VEHICLE PARTS AND ACCESSORIES' },
		{ key: '3715', value: '3715 TRUCK TRAILERS' },
		{ key: '3716', value: '3716 MOTOR HOMES' },
		{ key: '372', value: '372 AIRCRAFT AND PARTS' },
		{ key: '3721', value: '3721 AIRCRAFT' },
		{ key: '3724', value: '3724 AIRCRAFT ENGINES AND ENGINE PARTS' },
		{ key: '3728', value: '3728 AIRCRAFT PARTS AND AUXILIARY EQUIPMENT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '373', value: '373 SHIP AND BOAT BUILDING AND REPAIRING' },
		{ key: '3731', value: '3731 SHIP BUILDING AND REPAIRING' },
		{ key: '3732', value: '3732 BOAT BUILDING AND REPAIRING' },
		{ key: '374', value: '374 RAILROAD EQUIPMENT' },
		{ key: '3743', value: '3743 RAILROAD EQUIPMENT' },
		{ key: '375', value: '375 MOTORCYCLES, BICYCLES, AND PARTS' },
		{ key: '3751', value: '3751 MOTORCYCLES, BICYCLES, AND PARTS' },
		{ key: '376', value: '376 GUIDED MISSILES AND SPACE VEHICLES AND PARTS' },
		{ key: '3761', value: '3761 GUIDED MISSILES AND SPACE VEHICLES' },
		{ key: '3764', value: '3764 GUIDED MISSILE AND SPACE VEHICLE PROPULSION UNITS AND PROPULSION' },
		{ key: '3769', value: '3769 GUIDED MISSILE AND SPACE VEHICLE PARTS AND AUXILIARY EQUIPMENT, N' },
		{ key: '379', value: '379 MISCELLANEOUS TRANSPORTATION EQUIPMENT' },
		{ key: '3792', value: '3792 TRAVEL TRAILERS AND CAMPERS' },
		{ key: '3795', value: '3795 TANKS AND TANK COMPONENTS' },
		{ key: '3799', value: '3799 TRANSPORTATION EQUIPMENT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '38', value: '38 MEASURING, ANALYZING AND CONTROLLING INSTRUMENTS; PHOTOGRAPHIC, MEDICAL AN' },
		{ key: '381', value: '381 SEARCH, DETECTION, NAVIGATION, GUIDANCE, AERONAUTICAL, AND NAUTICA' },
		{ key: '3812', value: '3812 SEARCH, DETECTION, NAVIGATION, GUIDANCE, AERONAUTICAL, AND NAUTIC' },
		{ key: '382', value: '382 LABORATORY APPARATUS AND ANALYTICAL, OPTICAL, MEASURING, AND CONTR' },
		{ key: '3821', value: '3821 LABORATORY APPARATUS AND FURNITURE' },
		{ key: '3822', value: '3822 AUTOMATIC CONTROLS FOR REGULATING RESIDENTIAL AND COMMERCIAL ENVI' },
		{ key: '3823', value: '3823 INDUSTRIAL INSTRUMENTS FOR MEASUREMENT, DISPLAY, AND CONTROL OF P' },
		{ key: '3824', value: '3824 TOTALIZING FLUID METERS AND COUNTING DEVICES' },
		{ key: '3825', value: '3825 INSTRUMENTS FOR MEASURING AND TESTING OF ELECTRICITY AND ELECTRIC' },
		{ key: '3826', value: '3826 LABORATORY ANALYTICAL INSTRUMENTS' },
		{ key: '3827', value: '3827 OPTICAL INSTRUMENTS AND LENSES' },
		{ key: '3829', value: '3829 MEASURING AND CONTROLLING DEVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '384', value: '384 SURGICAL, MEDICAL, AND DENTAL INSTRUMENTS AND SUPPLIES' },
		{ key: '3841', value: '3841 SURGICAL AND MEDICAL INSTRUMENTS AND APPARATUS' },
		{ key: '3842', value: '3842 ORTHOPEDIC, PROSTHETIC, AND SURGICAL APPLIANCES AND SUPPLIES' },
		{ key: '3843', value: '3843 DENTAL EQUIPMENT AND SUPPLIES' },
		{ key: '3844', value: '3844 X-RAY APPARATUS AND TUBES AND RELATED IRRADIATION APPARATUS' },
		{ key: '3845', value: '3845 ELECTROMEDICAL AND ELECTROTHERAPEUTIC APPARATUS' },
		{ key: '385', value: '385 OPHTHALMIC GOODS' },
		{ key: '3851', value: '3851 OPHTHALMIC GOODS' },
		{ key: '386', value: '386 PHOTOGRAPHIC EQUIPMENT AND SUPPLIES' },
		{ key: '3861', value: '3861 PHOTOGRAPHIC EQUIPMENT AND SUPPLIES' },
		{ key: '387', value: '387 WATCHES, CLOCKS, CLOCKWORK OPERATED DEVICES, AND PARTS' },
		{ key: '3873', value: '3873 WATCHES, CLOCKS, CLOCKWORK OPERATED DEVICES, AND PARTS' },
		{ key: '39', value: '39 MISCELLANEOUS MANUFACTURING INDUSTRIES' },
		{ key: '391', value: '391 JEWELRY, SILVERWARE, AND PLATED WARE' },
		{ key: '3911', value: '3911 JEWELRY, PRECIOUS METAL' },
		{ key: '3914', value: '3914 SILVERWARE, PLATED WARE, AND STAINLESS STEEL WARE' },
		{ key: '3915', value: '3915 JEWELERS\' FINDINGS AND MATERIALS, AND LAPIDARY WORK' },
		{ key: '393', value: '393 MUSICAL INSTRUMENTS' },
		{ key: '3931', value: '3931 MUSICAL INSTRUMENTS' },
		{ key: '394', value: '394 DOLLS, TOYS, GAMES AND SPORTING AND ATHLETIC GOODS' },
		{ key: '3942', value: '3942 DOLLS AND STUFFED TOYS' },
		{ key: '3944', value: '3944 GAMES, TOYS, AND CHILDREN\'S VEHICLES, EXCEPT DOLLS AND BICYCLES' },
		{ key: '3949', value: '3949 SPORTING AND ATHLETIC GOODS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '395', value: '395 PENS, PENCILS, AND OTHER ARTISTS\' MATERIALS' },
		{ key: '3951', value: '3951 PENS, MECHANICAL PENCILS, AND PARTS' },
		{ key: '3952', value: '3952 LEAD PENCILS, CRAYONS, AND ARTISTS\' MATERIALS' },
		{ key: '3953', value: '3953 MARKING DEVICES' },
		{ key: '3955', value: '3955 CARBON PAPER AND INKED RIBBONS' },
		{ key: '396', value: '396 COSTUME JEWELRY, COSTUME NOVELTIES, BUTTONS, AND MISCELLANEOUS NOT' },
		{ key: '3961', value: '3961 COSTUME JEWELRY AND COSTUME NOVELTIES, EXCEPT PRECIOUS METAL' },
		{ key: '3965', value: '3965 FASTENERS, BUTTONS, NEEDLES, AND PINS' },
		{ key: '399', value: '399 MISCELLANEOUS MANUFACTURING INDUSTRIES' },
		{ key: '3991', value: '3991 BROOMS AND BRUSHES' },
		{ key: '3993', value: '3993 SIGNS AND ADVERTISING SPECIALTIES' },
		{ key: '3995', value: '3995 BURIAL CASKETS' },
		{ key: '3996', value: '3996 LINOLEUM, ASPHALTED-FELT-BASE, AND OTHER HARD SURFACE FLOOR COVER' },
		{ key: '3999', value: '3999 MANUFACTURING INDUSTRIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '40', value: '40 RAILROAD TRANSPORTATION' },
		{ key: '401', value: '401 RAILROADS' },
		{ key: '4011', value: '4011 RAILROADS, LINE-HAUL OPERATING' },
		{ key: '4013', value: '4013 RAILROAD SWITCHING AND TERMINAL ESTABLISHMENTS' },
		{ key: '41', value: '41 LOCAL AND SUBURBAN TRANSIT AND INTERURBAN HIGHWAY PASSENGER TRANSPORTATION' },
		{ key: '411', value: '411 LOCAL AND SUBURBAN PASSENGER TRANSPORTATION' },
		{ key: '4111', value: '4111 LOCAL AND SUBURBAN TRANSIT' },
		{ key: '4119', value: '4119 LOCAL PASSENGER TRANSPORTATION, NOT ELSEWHERE CLASSIFIED' },
		{ key: '412', value: '412 TAXICABS' },
		{ key: '4121', value: '4121 TAXICABS' },
		{ key: '413', value: '413 INTERCITY AND RURAL BUS TRANSPORTATION' },
		{ key: '4131', value: '4131 INTERCITY AND RURAL BUS TRANSPORTATION' },
		{ key: '414', value: '414 BUS CHARTER SERVICE' },
		{ key: '4141', value: '4141 LOCAL BUS CHARTER SERVICE' },
		{ key: '4142', value: '4142 BUS CHARTER SERVICE, EXCEPT LOCAL' },
		{ key: '415', value: '415 SCHOOL BUSES' },
		{ key: '4151', value: '4151 SCHOOL BUSES' },
		{ key: '417', value: '417 TERMINAL AND SERVICE FACILITIES FOR MOTOR VEHICLE PASSENGER TRANSP' },
		{ key: '4173', value: '4173 TERMINAL AND SERVICE FACILITIES FOR MOTOR VEHICLE PASSENGER TRANS' },
		{ key: '42', value: '42 MOTOR FREIGHT TRANSPORTATION AND WAREHOUSING' },
		{ key: '421', value: '421 TRUCKING AND COURIER SERVICES, EXCEPT AIR' },
		{ key: '4212', value: '4212 LOCAL TRUCKING WITHOUT STORAGE' },
		{ key: '4213', value: '4213 TRUCKING, EXCEPT LOCAL' },
		{ key: '4214', value: '4214 LOCAL TRUCKING WITH STORAGE' },
		{ key: '4215', value: '4215 COURIER SERVICES, EXCEPT BY AIR' },
		{ key: '422', value: '422 PUBLIC WAREHOUSING AND STORAGE' },
		{ key: '4221', value: '4221 FARM PRODUCT WAREHOUSING AND STORAGE' },
		{ key: '4222', value: '4222 REFRIGERATED WAREHOUSING AND STORAGE' },
		{ key: '4225', value: '4225 GENERAL WAREHOUSING AND STORAGE' },
		{ key: '4226', value: '4226 SPECIAL WAREHOUSING AND STORAGE, NOT ELSEWHERE CLASSIFIED' },
		{ key: '423', value: '423 TERMINAL AND JOINT TERMINAL MAINTENANCE FACILITIES FOR MOTOR FREIG' },
		{ key: '4231', value: '4231 TERMINAL AND JOINT TERMINAL MAINTENANCE FACILITIES FOR MOTOR FREI' },
		{ key: '43', value: '43 UNITED STATES POSTAL SERVICE' },
		{ key: '431', value: '431 UNITED STATES POSTAL SERVICE' },
		{ key: '4311', value: '4311 UNITED STATES POSTAL SERVICE' },
		{ key: '44', value: '44 WATER TRANSPORTATION' },
		{ key: '441', value: '441 DEEP SEA FOREIGN TRANSPORTATION OF FREIGHT' },
		{ key: '4412', value: '4412 DEEP SEA FOREIGN TRANSPORTATION OF FREIGHT' },
		{ key: '442', value: '442 DEEP SEA DOMESTIC TRANSPORTATION OF FREIGHT' },
		{ key: '4424', value: '4424 DEEP SEA DOMESTIC TRANSPORTATION OF FREIGHT' },
		{ key: '443', value: '443 FREIGHT TRANSPORTATION ON THE GREAT LAKES&die;ST. LAWRENCE SEAWAY' },
		{ key: '4432', value: '4432 FREIGHT TRANSPORTATION ON THE GREAT LAKES&die;ST. LAWRENCE SEAWAY' },
		{ key: '444', value: '444 WATER TRANSPORTATION OF FREIGHT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '4449', value: '4449 WATER TRANSPORTATION OF FREIGHT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '448', value: '448 WATER TRANSPORTATION OF PASSENGERS' },
		{ key: '4481', value: '4481 DEEP SEA TRANSPORTATION OF PASSENGERS, EXCEPT BY FERRY' },
		{ key: '4482', value: '4482 FERRIES' },
		{ key: '4489', value: '4489 WATER TRANSPORTATION OF PASSENGERS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '449', value: '449 SERVICES INCIDENTAL TO WATER TRANSPORTATION' },
		{ key: '4491', value: '4491 MARINE CARGO HANDLING' },
		{ key: '4492', value: '4492 TOWING AND TUGBOAT SERVICES' },
		{ key: '4493', value: '4493 MARINAS' },
		{ key: '4499', value: '4499 WATER TRANSPORTATION SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '45', value: '45 TRANSPORTATION BY AIR' },
		{ key: '451', value: '451 AIR TRANSPORTATION, SCHEDULED, AND AIR COURIER SERVICES' },
		{ key: '4512', value: '4512 AIR TRANSPORTATION, SCHEDULED' },
		{ key: '4513', value: '4513 AIR COURIER SERVICES' },
		{ key: '452', value: '452 AIR TRANSPORTATION, NONSCHEDULED' },
		{ key: '4522', value: '4522 AIR TRANSPORTATION, NONSCHEDULED' },
		{ key: '458', value: '458 AIRPORTS, FLYING FIELDS, AND AIRPORT TERMINAL SERVICES' },
		{ key: '4581', value: '4581 AIRPORTS, FLYING FIELDS, AND AIRPORT TERMINAL SERVICES' },
		{ key: '46', value: '46 PIPELINES, EXCEPT NATURAL GAS' },
		{ key: '461', value: '461 PIPELINES, EXCEPT NATURAL GAS' },
		{ key: '4612', value: '4612 CRUDE PETROLEUM PIPELINES' },
		{ key: '4613', value: '4613 REFINED PETROLEUM PIPELINES' },
		{ key: '4619', value: '4619 PIPELINES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '47', value: '47 TRANSPORTATION SERVICES' },
		{ key: '472', value: '472 ARRANGEMENT OF PASSENGER TRANSPORTATION' },
		{ key: '4724', value: '4724 TRAVEL AGENCIES' },
		{ key: '4725', value: '4725 TOUR OPERATORS' },
		{ key: '4729', value: '4729 ARRANGEMENT OF PASSENGER TRANSPORTATION, NOT ELSEWHERE CLASSIFIED' },
		{ key: '473', value: '473 ARRANGEMENT OF TRANSPORTATION OF FREIGHT AND CARGO' },
		{ key: '4731', value: '4731 ARRANGEMENT OF TRANSPORTATION OF FREIGHT AND CARGO' },
		{ key: '474', value: '474 RENTAL OF RAILROAD CARS' },
		{ key: '4741', value: '4741 RENTAL OF RAILROAD CARS' },
		{ key: '478', value: '478 MISCELLANEOUS SERVICES INCIDENTAL TO TRANSPORTATION' },
		{ key: '4783', value: '4783 PACKING AND CRATING' },
		{ key: '4785', value: '4785 FIXED FACILITIES AND INSPECTION AND WEIGHING SERVICES FOR MOTOR V' },
		{ key: '4785', value: '4785 CARGO CHECKERS AND SURVEYORS, MARINE' },
		{ key: '4789', value: '4789 TRANSPORTATION SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '48', value: '48 COMMUNICATIONS' },
		{ key: '481', value: '481 TELEPHONE COMMUNICATIONS' },
		{ key: '4812', value: '4812 RADIOTELEPHONE COMMUNICATIONS' },
		{ key: '4813', value: '4813 TELEPHONE COMMUNICATIONS, EXCEPT RADIOTELEPHONE' },
		{ key: '482', value: '482 TELEGRAPH AND OTHER MESSAGE COMMUNICATIONS' },
		{ key: '4822', value: '4822 TELEGRAPH AND OTHER MESSAGE COMMUNICATIONS' },
		{ key: '483', value: '483 RADIO AND TELEVISION BROADCASTING STATIONS' },
		{ key: '4832', value: '4832 RADIO BROADCASTING STATIONS' },
		{ key: '4833', value: '4833 TELEVISION BROADCASTING STATIONS' },
		{ key: '484', value: '484 CABLE AND OTHER PAY TELEVISION SERVICES' },
		{ key: '4841', value: '4841 CABLE AND OTHER PAY TELEVISION SERVICES' },
		{ key: '489', value: '489 COMMUNICATIONS SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '4899', value: '4899 COMMUNICATIONS SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '49', value: '49 ELECTRIC, GAS, AND SANITARY SERVICES' },
		{ key: '491', value: '491 ELECTRIC SERVICES' },
		{ key: '4911', value: '4911 ELECTRIC SERVICES' },
		{ key: '492', value: '492 GAS PRODUCTION AND DISTRIBUTION' },
		{ key: '4922', value: '4922 NATURAL GAS TRANSMISSION' },
		{ key: '4923', value: '4923 NATURAL GAS TRANSMISSION AND DISTRIBUTION' },
		{ key: '4924', value: '4924 NATURAL GAS DISTRIBUTION' },
		{ key: '4925', value: '4925 MIXED, MANUFACTURED, OR LIQUEFIED PETROLEUM GAS PRODUCTION AND/OR' },
		{ key: '493', value: '493 COMBINATION ELECTRIC AND GAS, AND OTHER UTILITY SERVICES' },
		{ key: '4931', value: '4931 ELECTRIC AND OTHER SERVICES COMBINED' },
		{ key: '4932', value: '4932 GAS AND OTHER SERVICES COMBINED' },
		{ key: '4939', value: '4939 COMBINATION UTILITIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '494', value: '494 WATER SUPPLY' },
		{ key: '4941', value: '4941 WATER SUPPLY' },
		{ key: '495', value: '495 SANITARY SERVICES' },
		{ key: '4952', value: '4952 SEWERAGE SYSTEMS' },
		{ key: '4953', value: '4953 REFUSE SYSTEMS' },
		{ key: '4959', value: '4959 SANITARY SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '496', value: '496 STEAM AND AIR-CONDITIONING SUPPLY' },
		{ key: '4961', value: '4961 STEAM AND AIR-CONDITIONING SUPPLY' },
		{ key: '497', value: '497 IRRIGATION SYSTEMS' },
		{ key: '4971', value: '4971 IRRIGATION SYSTEMS' },
		{ key: '50', value: '50 WHOLESALE TRADE&die;DURABLE GOODS' },
		{ key: '501', value: '501 MOTOR VEHICLES AND MOTOR VEHICLE PARTS AND SUPPLIES' },
		{ key: '5012', value: '5012 AUTOMOBILES AND OTHER MOTOR VEHICLES' },
		{ key: '5013', value: '5013 MOTOR VEHICLE SUPPLIES AND NEW PARTS' },
		{ key: '5014', value: '5014 TIRES AND TUBES' },
		{ key: '5015', value: '5015 MOTOR VEHICLE PARTS, USED' },
		{ key: '502', value: '502 FURNITURE AND HOMEFURNISHINGS' },
		{ key: '5021', value: '5021 FURNITURE' },
		{ key: '5023', value: '5023 HOMEFURNISHINGS' },
		{ key: '503', value: '503 LUMBER AND OTHER CONSTRUCTION MATERIALS' },
		{ key: '5031', value: '5031 LUMBER, PLYWOOD, MILLWORK, AND WOOD PANELS' },
		{ key: '5032', value: '5032 BRICK, STONE, AND RELATED CONSTRUCTION MATERIALS' },
		{ key: '5033', value: '5033 ROOFING, SIDING, AND INSULATION MATERIALS' },
		{ key: '5039', value: '5039 CONSTRUCTION MATERIALS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '504', value: '504 PROFESSIONAL AND COMMERCIAL EQUIPMENT AND SUPPLIES' },
		{ key: '5043', value: '5043 PHOTOGRAPHIC EQUIPMENT AND SUPPLIES' },
		{ key: '5044', value: '5044 OFFICE EQUIPMENT' },
		{ key: '5045', value: '5045 COMPUTERS AND COMPUTER PERIPHERAL EQUIPMENT AND SOFTWARE' },
		{ key: '5046', value: '5046 COMMERCIAL EQUIPMENT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '5047', value: '5047 MEDICAL, DENTAL, AND HOSPITAL EQUIPMENT AND SUPPLIES' },
		{ key: '5048', value: '5048 OPHTHALMIC GOODS' },
		{ key: '5049', value: '5049 PROFESSIONAL EQUIPMENT AND SUPPLIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '505', value: '505 METALS AND MINERALS, EXCEPT PETROLEUM' },
		{ key: '5051', value: '5051 METALS SERVICE CENTERS AND OFFICES' },
		{ key: '5052', value: '5052 COAL AND OTHER MINERALS AND ORES' },
		{ key: '506', value: '506 ELECTRICAL GOODS' },
		{ key: '5063', value: '5063 ELECTRICAL APPARATUS AND EQUIPMENT, WIRING SUPPLIES, AND CONSTRUC' },
		{ key: '5064', value: '5064 ELECTRICAL APPLIANCES, TELEVISION AND RADIO SETS' },
		{ key: '5065', value: '5065 ELECTRONIC PARTS AND EQUIPMENT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '507', value: '507 HARDWARE, AND PLUMBING AND HEATING EQUIPMENT AND SUPPLIES' },
		{ key: '5072', value: '5072 HARDWARE' },
		{ key: '5074', value: '5074 PLUMBING AND HEATING EQUIPMENT AND SUPPLIES (HYDRONICS)' },
		{ key: '5075', value: '5075 WARM AIR HEATING AND AIR-CONDITIONING EQUIPMENT AND SUPPLIES' },
		{ key: '5078', value: '5078 REFRIGERATION EQUIPMENT AND SUPPLIES' },
		{ key: '508', value: '508 MACHINERY, EQUIPMENT, AND SUPPLIES' },
		{ key: '5082', value: '5082 CONSTRUCTION AND MINING (EXCEPT PETROLEUM) MACHINERY AND EQUIPMEN' },
		{ key: '5083', value: '5083 FARM AND GARDEN MACHINERY AND EQUIPMENT' },
		{ key: '5084', value: '5084 INDUSTRIAL MACHINERY AND EQUIPMENT' },
		{ key: '5085', value: '5085 INDUSTRIAL SUPPLIES' },
		{ key: '5087', value: '5087 SERVICE ESTABLISHMENT EQUIPMENT AND SUPPLIES' },
		{ key: '5088', value: '5088 TRANSPORTATION EQUIPMENT AND SUPPLIES, EXCEPT MOTOR VEHICLES' },
		{ key: '509', value: '509 MISCELLANEOUS DURABLE GOODS' },
		{ key: '5091', value: '5091 SPORTING AND RECREATIONAL GOODS AND SUPPLIES' },
		{ key: '5092', value: '5092 TOYS AND HOBBY GOODS AND SUPPLIES' },
		{ key: '5093', value: '5093 SCRAP AND WASTE MATERIALS' },
		{ key: '5094', value: '5094 JEWELRY, WATCHES, PRECIOUS STONES, AND PRECIOUS METALS' },
		{ key: '5099', value: '5099 DURABLE GOODS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '51', value: '51 WHOLESALE TRADE&die;NONDURABLE GOODS' },
		{ key: '511', value: '511 PAPER AND PAPER PRODUCTS' },
		{ key: '5111', value: '5111 PRINTING AND WRITING PAPER' },
		{ key: '5112', value: '5112 STATIONERY AND OFFICE SUPPLIES' },
		{ key: '5113', value: '5113 INDUSTRIAL AND PERSONAL SERVICE PAPER' },
		{ key: '512', value: '512 DRUGS, DRUG PROPRIETARIES, AND DRUGGISTS\' SUNDRIES' },
		{ key: '5122', value: '5122 DRUGS, DRUG PROPRIETARIES, AND DRUGGISTS\' SUNDRIES' },
		{ key: '513', value: '513 APPAREL, PIECE GOODS, AND NOTIONS' },
		{ key: '5131', value: '5131 PIECE GOODS, NOTIONS, AND OTHER DRY GOODS' },
		{ key: '5136', value: '5136 MEN\'S AND BOYS\' CLOTHING AND FURNISHINGS' },
		{ key: '5137', value: '5137 WOMEN\'S, CHILDREN\'S, AND INFANTS\' CLOTHING AND ACCESSORIES' },
		{ key: '5139', value: '5139 FOOTWEAR' },
		{ key: '514', value: '514 GROCERIES AND RELATED PRODUCTS' },
		{ key: '5141', value: '5141 GROCERIES, GENERAL LINE' },
		{ key: '5142', value: '5142 PACKAGED FROZEN FOODS' },
		{ key: '5143', value: '5143 DAIRY PRODUCTS, EXCEPT DRIED OR CANNED' },
		{ key: '5144', value: '5144 POULTRY AND POULTRY PRODUCTS' },
		{ key: '5145', value: '5145 CONFECTIONERY' },
		{ key: '5146', value: '5146 FISH AND SEAFOODS' },
		{ key: '5147', value: '5147 MEATS AND MEAT PRODUCTS' },
		{ key: '5148', value: '5148 FRESH FRUITS AND VEGETABLES' },
		{ key: '5149', value: '5149 GROCERIES AND RELATED PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '515', value: '515 FARM-PRODUCT RAW MATERIALS' },
		{ key: '5153', value: '5153 GRAIN AND FIELD BEANS' },
		{ key: '5154', value: '5154 LIVESTOCK' },
		{ key: '5159', value: '5159 FARM-PRODUCT RAW MATERIALS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '516', value: '516 CHEMICALS AND ALLIED PRODUCTS' },
		{ key: '5162', value: '5162 PLASTICS MATERIALS AND BASIC FORMS AND SHAPES' },
		{ key: '5169', value: '5169 CHEMICALS AND ALLIED PRODUCTS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '517', value: '517 PETROLEUM AND PETROLEUM PRODUCTS' },
		{ key: '5171', value: '5171 PETROLEUM BULK STATIONS AND TERMINALS' },
		{ key: '5172', value: '5172 PETROLEUM AND PETROLEUM PRODUCTS WHOLESALERS, EXCEPT BULK STATION' },
		{ key: '518', value: '518 BEER, WINE, AND DISTILLED ALCOHOLIC BEVERAGES' },
		{ key: '5181', value: '5181 BEER AND ALE' },
		{ key: '5182', value: '5182 WINE AND DISTILLED ALCOHOLIC BEVERAGES' },
		{ key: '519', value: '519 MISCELLANEOUS NONDURABLE GOODS' },
		{ key: '5191', value: '5191 FARM SUPPLIES' },
		{ key: '5192', value: '5192 BOOKS, PERIODICALS, AND NEWSPAPERS' },
		{ key: '5193', value: '5193 FLOWERS, NURSERY STOCK, AND FLORISTS\' SUPPLIES' },
		{ key: '5194', value: '5194 TOBACCO AND TOBACCO PRODUCTS' },
		{ key: '5198', value: '5198 PAINTS, VARNISHES, AND SUPPLIES' },
		{ key: '5199', value: '5199 NONDURABLE GOODS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '52', value: '52 BUILDING MATERIALS, HARDWARE, GARDEN SUPPLY, AND MOBILE HOME DEALERS' },
		{ key: '521', value: '521 LUMBER AND OTHER BUILDING MATERIALS DEALERS' },
		{ key: '5211', value: '5211 LUMBER AND OTHER BUILDING MATERIALS DEALERS' },
		{ key: '523', value: '523 PAINT, GLASS, AND WALLPAPER STORES' },
		{ key: '5231', value: '5231 PAINT, GLASS, AND WALLPAPER STORES' },
		{ key: '525', value: '525 HARDWARE STORES' },
		{ key: '5251', value: '5251 HARDWARE STORES' },
		{ key: '526', value: '526 RETAIL NURSERIES, LAWN AND GARDEN SUPPLY STORES' },
		{ key: '5261', value: '5261 RETAIL NURSERIES, LAWN AND GARDEN SUPPLY STORES' },
		{ key: '527', value: '527 MOBILE HOME DEALERS' },
		{ key: '5271', value: '5271 MOBILE HOME DEALERS' },
		{ key: '53', value: '53 GENERAL MERCHANDISE STORES' },
		{ key: '531', value: '531 DEPARTMENT STORES' },
		{ key: '5311', value: '5311 DEPARTMENT STORES' },
		{ key: '533', value: '533 VARIETY STORES' },
		{ key: '5331', value: '5331 VARIETY STORES' },
		{ key: '539', value: '539 MISCELLANEOUS GENERAL MERCHANDISE STORES' },
		{ key: '5399', value: '5399 MISCELLANEOUS GENERAL MERCHANDISE STORES' },
		{ key: '54', value: '54 FOOD STORES' },
		{ key: '541', value: '541 GROCERY STORES' },
		{ key: '5411', value: '5411 GROCERY STORES' },
		{ key: '542', value: '542 MEAT AND FISH (SEAFOOD) MARKETS, INCLUDING FREEZER PROVISIONERS' },
		{ key: '5421', value: '5421 MEAT AND FISH (SEAFOOD) MARKETS, INCLUDING FREEZER PROVISIONERS' },
		{ key: '543', value: '543 FRUIT AND VEGETABLE MARKETS' },
		{ key: '5431', value: '5431 FRUIT AND VEGETABLE MARKETS' },
		{ key: '544', value: '544 CANDY, NUT, AND CONFECTIONERY STORES' },
		{ key: '5441', value: '5441 CANDY, NUT, AND CONFECTIONERY STORES' },
		{ key: '545', value: '545 DAIRY PRODUCTS STORES' },
		{ key: '5451', value: '5451 DAIRY PRODUCTS STORES' },
		{ key: '546', value: '546 RETAIL BAKERIES' },
		{ key: '5461', value: '5461 RETAIL BAKERIES' },
		{ key: '549', value: '549 MISCELLANEOUS FOOD STORES' },
		{ key: '5499', value: '5499 MISCELLANEOUS FOOD STORES' },
		{ key: '55', value: '55 AUTOMOTIVE DEALERS AND GASOLINE SERVICE STATIONS' },
		{ key: '551', value: '551 MOTOR VEHICLE DEALERS (NEW AND USED)' },
		{ key: '5511', value: '5511 MOTOR VEHICLE DEALERS (NEW AND USED)' },
		{ key: '552', value: '552 MOTOR VEHICLE DEALERS (USED ONLY)' },
		{ key: '5521', value: '5521 MOTOR VEHICLE DEALERS (USED ONLY)' },
		{ key: '553', value: '553 AUTO AND HOME SUPPLY STORES' },
		{ key: '5531', value: '5531 AUTO AND HOME SUPPLY STORES' },
		{ key: '554', value: '554 GASOLINE SERVICE STATIONS' },
		{ key: '5541', value: '5541 GASOLINE SERVICE STATIONS' },
		{ key: '555', value: '555 BOAT DEALERS' },
		{ key: '5551', value: '5551 BOAT DEALERS' },
		{ key: '556', value: '556 RECREATIONAL VEHICLE DEALERS' },
		{ key: '5561', value: '5561 RECREATIONAL VEHICLE DEALERS' },
		{ key: '557', value: '557 MOTORCYCLE DEALERS' },
		{ key: '5571', value: '5571 MOTORCYCLE DEALERS' },
		{ key: '559', value: '559 AUTOMOTIVE DEALERS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '5599', value: '5599 AUTOMOTIVE DEALERS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '56', value: '56 APPAREL AND ACCESSORY STORES' },
		{ key: '561', value: '561 MEN\'S AND BOYS\' CLOTHING AND ACCESSORY STORES' },
		{ key: '5611', value: '5611 MEN\'S AND BOYS\' CLOTHING AND ACCESSORY STORES' },
		{ key: '562', value: '562 WOMEN\'S CLOTHING STORES' },
		{ key: '5621', value: '5621 WOMEN\'S CLOTHING STORES' },
		{ key: '563', value: '563 WOMEN\'S ACCESSORY AND SPECIALTY STORES' },
		{ key: '5632', value: '5632 WOMEN\'S ACCESSORY AND SPECIALTY STORES' },
		{ key: '564', value: '564 CHILDREN\'S AND INFANTS\' WEAR STORES' },
		{ key: '5641', value: '5641 CHILDREN\'S AND INFANTS\' WEAR STORES' },
		{ key: '565', value: '565 FAMILY CLOTHING STORES' },
		{ key: '5651', value: '5651 FAMILY CLOTHING STORES' },
		{ key: '566', value: '566 SHOE STORES' },
		{ key: '5661', value: '5661 SHOE STORES' },
		{ key: '569', value: '569 MISCELLANEOUS APPAREL AND ACCESSORY STORES' },
		{ key: '5699', value: '5699 MISCELLANEOUS APPAREL AND ACCESSORY STORES' },
		{ key: '57', value: '57 HOME FURNITURE, FURNISHINGS, AND EQUIPMENT STORES' },
		{ key: '571', value: '571 HOME FURNITURE AND FURNISHINGS STORES' },
		{ key: '5712', value: '5712 FURNITURE STORES' },
		{ key: '5713', value: '5713 FLOOR COVERING STORES' },
		{ key: '5714', value: '5714 DRAPERY, CURTAIN, AND UPHOLSTERY STORES' },
		{ key: '5719', value: '5719 MISCELLANEOUS HOMEFURNISHINGS STORES' },
		{ key: '572', value: '572 HOUSEHOLD APPLIANCE STORES' },
		{ key: '5722', value: '5722 HOUSEHOLD APPLIANCE STORES' },
		{ key: '573', value: '573 RADIO, TELEVISION, CONSUMER ELECTRONICS, AND MUSIC STORES' },
		{ key: '5731', value: '5731 RADIO, TELEVISION, AND CONSUMER ELECTRONICS STORES' },
		{ key: '5734', value: '5734 COMPUTER AND COMPUTER SOFTWARE STORES' },
		{ key: '5735', value: '5735 RECORD AND PRERECORDED TAPE STORES' },
		{ key: '5736', value: '5736 MUSICAL INSTRUMENT STORES' },
		{ key: '58', value: '58 EATING AND DRINKING PLACES' },
		{ key: '581', value: '581 EATING AND DRINKING PLACES' },
		{ key: '5812', value: '5812 EATING PLACES' },
		{ key: '5813', value: '5813 DRINKING PLACES (ALCOHOLIC BEVERAGES)' },
		{ key: '59', value: '59 MISCELLANEOUS RETAIL' },
		{ key: '591', value: '591 DRUG STORES AND PROPRIETARY STORES' },
		{ key: '5912', value: '5912 DRUG STORES AND PROPRIETARY STORES' },
		{ key: '592', value: '592 LIQUOR STORES' },
		{ key: '5921', value: '5921 LIQUOR STORES' },
		{ key: '593', value: '593 USED MERCHANDISE STORES' },
		{ key: '5932', value: '5932 USED MERCHANDISE STORES' },
		{ key: '594', value: '594 MISCELLANEOUS SHOPPING GOODS STORES' },
		{ key: '5941', value: '5941 SPORTING GOODS STORES AND BICYCLE SHOPS' },
		{ key: '5942', value: '5942 BOOK STORES' },
		{ key: '5943', value: '5943 STATIONERY STORES' },
		{ key: '5944', value: '5944 JEWELRY STORES' },
		{ key: '5945', value: '5945 HOBBY, TOY, AND GAME SHOPS' },
		{ key: '5946', value: '5946 CAMERA AND PHOTOGRAPHIC SUPPLY STORES' },
		{ key: '5947', value: '5947 GIFT, NOVELTY, AND SOUVENIR SHOPS' },
		{ key: '5948', value: '5948 LUGGAGE AND LEATHER GOODS STORES' },
		{ key: '5949', value: '5949 SEWING, NEEDLEWORK, AND PIECE GOODS STORES' },
		{ key: '596', value: '596 NONSTORE RETAILERS' },
		{ key: '5961', value: '5961 CATALOG AND MAIL-ORDER HOUSES' },
		{ key: '5962', value: '5962 AUTOMATIC MERCHANDISING MACHINE OPERATORS' },
		{ key: '5963', value: '5963 DIRECT SELLING ESTABLISHMENTS' },
		{ key: '598', value: '598 FUEL DEALERS' },
		{ key: '5983', value: '5983 FUEL OIL DEALERS' },
		{ key: '5984', value: '5984 LIQUEFIED PETROLEUM GAS (BOTTLED GAS) DEALERS' },
		{ key: '5989', value: '5989 FUEL DEALERS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '599', value: '599 RETAIL STORES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '5992', value: '5992 FLORISTS' },
		{ key: '5993', value: '5993 TOBACCO STORES AND STANDS' },
		{ key: '5994', value: '5994 NEWS DEALERS AND NEWSSTANDS' },
		{ key: '5995', value: '5995 OPTICAL GOODS STORES' },
		{ key: '5999', value: '5999 MISCELLANEOUS RETAIL STORES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '60', value: '60 DEPOSITORY INSTITUTIONS' },
		{ key: '601', value: '601 CENTRAL RESERVE DEPOSITORY INSTITUTIONS' },
		{ key: '6011', value: '6011 FEDERAL RESERVE BANKS' },
		{ key: '6019', value: '6019 CENTRAL RESERVE DEPOSITORY INSTITUTIONS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '602', value: '602 COMMERCIAL BANKS' },
		{ key: '6021', value: '6021 NATIONAL COMMERCIAL BANKS' },
		{ key: '6022', value: '6022 STATE COMMERCIAL BANKS' },
		{ key: '6029', value: '6029 COMMERCIAL BANKS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '603', value: '603 SAVINGS INSTITUTIONS' },
		{ key: '6035', value: '6035 SAVINGS INSTITUTIONS, FEDERALLY CHARTERED' },
		{ key: '6036', value: '6036 SAVINGS INSTITUTIONS, NOT FEDERALLY CHARTERED' },
		{ key: '606', value: '606 CREDIT UNIONS' },
		{ key: '6061', value: '6061 CREDIT UNIONS, FEDERALLY CHARTERED' },
		{ key: '6062', value: '6062 CREDIT UNIONS, NOT FEDERALLY CHARTERED' },
		{ key: '608', value: '608 FOREIGN BANKING AND BRANCHES AND AGENCIES OF FOREIGN BANKS' },
		{ key: '6081', value: '6081 BRANCHES AND AGENCIES OF FOREIGN BANKS' },
		{ key: '6082', value: '6082 FOREIGN TRADE AND INTERNATIONAL BANKING INSTITUTIONS' },
		{ key: '609', value: '609 FUNCTIONS RELATED TO DEPOSITORY BANKING' },
		{ key: '6091', value: '6091 NONDEPOSIT TRUST FACILITIES' },
		{ key: '6099', value: '6099 FUNCTIONS RELATED TO DEPOSITORY BANKING, NOT ELSEWHERE CLASSIFIED' },
		{ key: '61', value: '61 NONDEPOSITORY CREDIT INSTITUTIONS' },
		{ key: '611', value: '611 FEDERAL AND FEDERALLY-SPONSORED CREDIT AGENCIES' },
		{ key: '6111', value: '6111 FEDERAL AND FEDERALLY-SPONSORED CREDIT AGENCIES' },
		{ key: '614', value: '614 PERSONAL CREDIT INSTITUTIONS' },
		{ key: '6141', value: '6141 PERSONAL CREDIT INSTITUTIONS' },
		{ key: '615', value: '615 BUSINESS CREDIT INSTITUTIONS' },
		{ key: '6153', value: '6153 SHORT-TERM BUSINESS CREDIT INSTITUTIONS, EXCEPT AGRICULTURAL' },
		{ key: '6159', value: '6159 MISCELLANEOUS BUSINESS CREDIT INSTITUTIONS' },
		{ key: '616', value: '616 MORTGAGE BANKERS AND BROKERS' },
		{ key: '6162', value: '6162 MORTGAGE BANKERS AND LOAN CORRESPONDENTS' },
		{ key: '6163', value: '6163 LOAN BROKERS' },
		{ key: '62', value: '62 SECURITY AND COMMODITY BROKERS, DEALERS, EXCHANGES, AND SERVICES' },
		{ key: '621', value: '621 SECURITY BROKERS, DEALERS, AND FLOTATION COMPANIES' },
		{ key: '6211', value: '6211 SECURITY BROKERS, DEALERS, AND FLOTATION COMPANIES' },
		{ key: '622', value: '622 COMMODITY CONTRACTS BROKERS AND DEALERS' },
		{ key: '6221', value: '6221 COMMODITY CONTRACTS BROKERS AND DEALERS' },
		{ key: '623', value: '623 SECURITY AND COMMODITY EXCHANGES' },
		{ key: '6231', value: '6231 SECURITY AND COMMODITY EXCHANGES' },
		{ key: '628', value: '628 SERVICES ALLIED WITH THE EXCHANGE OF SECURITIES OR COMMODITIES' },
		{ key: '6282', value: '6282 INVESTMENT ADVICE' },
		{ key: '6289', value: '6289 SERVICES ALLIED WITH THE EXCHANGE OF SECURITIES OR COMMODITIES, N' },
		{ key: '63', value: '63 INSURANCE CARRIERS' },
		{ key: '631', value: '631 LIFE INSURANCE' },
		{ key: '6311', value: '6311 LIFE INSURANCE' },
		{ key: '632', value: '632 ACCIDENT AND HEALTH INSURANCE AND MEDICAL SERVICE PLANS' },
		{ key: '6321', value: '6321 ACCIDENT AND HEALTH INSURANCE' },
		{ key: '6324', value: '6324 HOSPITAL AND MEDICAL SERVICE PLANS' },
		{ key: '633', value: '633 FIRE, MARINE, AND CASUALTY INSURANCE' },
		{ key: '6331', value: '6331 FIRE, MARINE, AND CASUALTY INSURANCE' },
		{ key: '635', value: '635 SURETY INSURANCE' },
		{ key: '6351', value: '6351 SURETY INSURANCE' },
		{ key: '636', value: '636 TITLE INSURANCE' },
		{ key: '6361', value: '6361 TITLE INSURANCE' },
		{ key: '637', value: '637 PENSION, HEALTH, AND WELFARE FUNDS' },
		{ key: '6371', value: '6371 PENSION, HEALTH, AND WELFARE FUNDS' },
		{ key: '639', value: '639 INSURANCE CARRIERS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '6399', value: '6399 INSURANCE CARRIERS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '64', value: '64 INSURANCE AGENTS, BROKERS, AND SERVICE' },
		{ key: '641', value: '641 INSURANCE AGENTS, BROKERS, AND SERVICE' },
		{ key: '6411', value: '6411 INSURANCE AGENTS, BROKERS, AND SERVICE' },
		{ key: '65', value: '65 REAL ESTATE' },
		{ key: '651', value: '651 REAL ESTATE OPERATORS (EXCEPT DEVELOPERS) AND LESSORS' },
		{ key: '6512', value: '6512 OPERATORS OF NONRESIDENTIAL BUILDINGS' },
		{ key: '6513', value: '6513 OPERATORS OF APARTMENT BUILDINGS' },
		{ key: '6514', value: '6514 OPERATORS OF DWELLINGS OTHER THAN APARTMENT BUILDINGS' },
		{ key: '6515', value: '6515 OPERATORS OF RESIDENTIAL MOBILE HOME SITES' },
		{ key: '6517', value: '6517 LESSORS OF RAILROAD PROPERTY' },
		{ key: '6519', value: '6519 LESSORS OF REAL PROPERTY, NOT ELSEWHERE CLASSIFIED' },
		{ key: '653', value: '653 REAL ESTATE AGENTS AND MANAGERS' },
		{ key: '6531', value: '6531 REAL ESTATE AGENTS AND MANAGERS' },
		{ key: '654', value: '654 TITLE ABSTRACT OFFICES' },
		{ key: '6541', value: '6541 TITLE ABSTRACT OFFICES' },
		{ key: '655', value: '655 LAND SUBDIVIDERS AND DEVELOPERS' },
		{ key: '6552', value: '6552 LAND SUBDIVIDERS AND DEVELOPERS, EXCEPT CEMETERIES' },
		{ key: '6553', value: '6553 CEMETERY SUBDIVIDERS AND DEVELOPERS' },
		{ key: '67', value: '67 HOLDING AND OTHER INVESTMENT OFFICES' },
		{ key: '671', value: '671 HOLDING OFFICES' },
		{ key: '6712', value: '6712 OFFICES OF BANK HOLDING COMPANIES' },
		{ key: '6719', value: '6719 OFFICES OF HOLDING COMPANIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '672', value: '672 INVESTMENT OFFICES' },
		{ key: '6722', value: '6722 MANAGEMENT INVESTMENT OFFICES, OPEN-END' },
		{ key: '6726', value: '6726 UNIT INVESTMENT TRUSTS, FACE-AMOUNT CERTIFICATE OFFICES, AND CLOS' },
		{ key: '673', value: '673 TRUSTS' },
		{ key: '6732', value: '6732 EDUCATIONAL, RELIGIOUS, AND CHARITABLE TRUSTS' },
		{ key: '6733', value: '6733 TRUSTS, EXCEPT EDUCATIONAL, RELIGIOUS, AND CHARITABLE' },
		{ key: '679', value: '679 MISCELLANEOUS INVESTING' },
		{ key: '6792', value: '6792 OIL ROYALTY TRADERS' },
		{ key: '6794', value: '6794 PATENT OWNERS AND LESSORS' },
		{ key: '6798', value: '6798 REAL ESTATE INVESTMENT TRUSTS' },
		{ key: '6799', value: '6799 INVESTORS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '70', value: '70 HOTELS, ROOMING HOUSES, CAMPS, AND OTHER LODGING PLACES' },
		{ key: '701', value: '701 HOTELS AND MOTELS' },
		{ key: '7011', value: '7011 HOTELS AND MOTELS' },
		{ key: '702', value: '702 ROOMING AND BOARDING HOUSES' },
		{ key: '7021', value: '7021 ROOMING AND BOARDING HOUSES' },
		{ key: '703', value: '703 CAMPS AND RECREATIONAL VEHICLE PARKS' },
		{ key: '7032', value: '7032 SPORTING AND RECREATIONAL CAMPS' },
		{ key: '7033', value: '7033 RECREATIONAL VEHICLE PARKS AND CAMPSITES' },
		{ key: '704', value: '704 ORGANIZATION HOTELS AND LODGING HOUSES, ON MEMBERSHIP BASIS' },
		{ key: '7041', value: '7041 ORGANIZATION HOTELS AND LODGING HOUSES, ON MEMBERSHIP BASIS' },
		{ key: '72', value: '72 PERSONAL SERVICES' },
		{ key: '721', value: '721 LAUNDRY, CLEANING, AND GARMENT SERVICES' },
		{ key: '7211', value: '7211 POWER LAUNDRIES, FAMILY AND COMMERCIAL' },
		{ key: '7212', value: '7212 GARMENT PRESSING, AND AGENTS FOR LAUNDRIES AND DRYCLEANERS' },
		{ key: '7213', value: '7213 LINEN SUPPLY' },
		{ key: '7215', value: '7215 COIN-OPERATED LAUNDRIES AND DRYCLEANING' },
		{ key: '7216', value: '7216 DRYCLEANING PLANTS, EXCEPT RUG CLEANING' },
		{ key: '7217', value: '7217 CARPET AND UPHOLSTERY CLEANING' },
		{ key: '7218', value: '7218 INDUSTRIAL LAUNDERERS' },
		{ key: '7219', value: '7219 LAUNDRY AND GARMENT SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '722', value: '722 PHOTOGRAPHIC STUDIOS, PORTRAIT' },
		{ key: '7221', value: '7221 PHOTOGRAPHIC STUDIOS, PORTRAIT' },
		{ key: '723', value: '723 BEAUTY SHOPS' },
		{ key: '7231', value: '7231 BEAUTY SHOPS' },
		{ key: '724', value: '724 BARBER SHOPS' },
		{ key: '7241', value: '7241 BARBER SHOPS' },
		{ key: '725', value: '725 SHOE REPAIR SHOPS AND SHOESHINE PARLORS' },
		{ key: '7251', value: '7251 SHOE REPAIR SHOPS AND SHOESHINE PARLORS' },
		{ key: '726', value: '726 FUNERAL SERVICE AND CREMATORIES' },
		{ key: '7261', value: '7261 FUNERAL SERVICE AND CREMATORIES' },
		{ key: '729', value: '729 MISCELLANEOUS PERSONAL SERVICES' },
		{ key: '7291', value: '7291 TAX RETURN PREPARATION SERVICES' },
		{ key: '7299', value: '7299 MISCELLANEOUS PERSONAL SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '73', value: '73 BUSINESS SERVICES' },
		{ key: '731', value: '731 ADVERTISING' },
		{ key: '7311', value: '7311 ADVERTISING AGENCIES' },
		{ key: '7312', value: '7312 OUTDOOR ADVERTISING SERVICES' },
		{ key: '7313', value: '7313 RADIO, TELEVISION, AND PUBLISHERS\' ADVERTISING REPRESENTATIVES' },
		{ key: '7319', value: '7319 ADVERTISING, NOT ELSEWHERE CLASSIFIED' },
		{ key: '732', value: '732 CONSUMER CREDIT REPORTING AGENCIES, MERCANTILE REPORTING AGENCIES,' },
		{ key: '7322', value: '7322 ADJUSTMENT AND COLLECTION SERVICES' },
		{ key: '7323', value: '7323 CREDIT REPORTING SERVICES' },
		{ key: '733', value: '733 MAILING, REPRODUCTION, COMMERCIAL ART AND PHOTOGRAPHY, AND STENOGR' },
		{ key: '7331', value: '7331 DIRECT MAIL ADVERTISING SERVICES' },
		{ key: '7334', value: '7334 PHOTOCOPYING AND DUPLICATING SERVICES' },
		{ key: '7335', value: '7335 COMMERCIAL PHOTOGRAPHY' },
		{ key: '7336', value: '7336 COMMERCIAL ART AND GRAPHIC DESIGN' },
		{ key: '7338', value: '7338 SECRETARIAL AND COURT REPORTING SERVICES' },
		{ key: '734', value: '734 SERVICES TO DWELLINGS AND OTHER BUILDINGS' },
		{ key: '7342', value: '7342 DISINFECTING AND PEST CONTROL SERVICES' },
		{ key: '7349', value: '7349 BUILDING CLEANING AND MAINTENANCE SERVICES, NOT ELSEWHERE CLASSIF' },
		{ key: '735', value: '735 MISCELLANEOUS EQUIPMENT RENTAL AND LEASING' },
		{ key: '7352', value: '7352 MEDICAL EQUIPMENT RENTAL AND LEASING' },
		{ key: '7353', value: '7353 HEAVY CONSTRUCTION EQUIPMENT RENTAL AND LEASING' },
		{ key: '7359', value: '7359 EQUIPMENT RENTAL AND LEASING, NOT ELSEWHERE CLASSIFIED' },
		{ key: '736', value: '736 PERSONNEL SUPPLY SERVICES' },
		{ key: '7361', value: '7361 EMPLOYMENT AGENCIES' },
		{ key: '7363', value: '7363 HELP SUPPLY SERVICES' },
		{ key: '737', value: '737 COMPUTER PROGRAMMING, DATA PROCESSING, AND OTHER COMPUTER RELATED' },
		{ key: '7371', value: '7371 COMPUTER PROGRAMMING SERVICES' },
		{ key: '7372', value: '7372 PREPACKAGED SOFTWARE' },
		{ key: '7373', value: '7373 COMPUTER INTEGRATED SYSTEMS DESIGN' },
		{ key: '7374', value: '7374 COMPUTER PROCESSING AND DATA PREPARATION AND PROCESSING SERVICES' },
		{ key: '7375', value: '7375 INFORMATION RETRIEVAL SERVICES' },
		{ key: '7376', value: '7376 COMPUTER FACILITIES MANAGEMENT SERVICES' },
		{ key: '7377', value: '7377 COMPUTER RENTAL AND LEASING' },
		{ key: '7378', value: '7378 COMPUTER MAINTENANCE AND REPAIR' },
		{ key: '7379', value: '7379 COMPUTER RELATED SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '738', value: '738 MISCELLANEOUS BUSINESS SERVICES' },
		{ key: '7381', value: '7381 DETECTIVE, GUARD, AND ARMORED CAR SERVICES' },
		{ key: '7382', value: '7382 SECURITY SYSTEMS SERVICES' },
		{ key: '7383', value: '7383 NEWS SYNDICATES' },
		{ key: '7384', value: '7384 PHOTOFINISHING LABORATORIES' },
		{ key: '7389', value: '7389 BUSINESS SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '75', value: '75 AUTOMOTIVE REPAIR, SERVICES, AND PARKING' },
		{ key: '751', value: '751 AUTOMOTIVE RENTAL AND LEASING, WITHOUT DRIVERS' },
		{ key: '7513', value: '7513 TRUCK RENTAL AND LEASING, WITHOUT DRIVERS' },
		{ key: '7514', value: '7514 PASSENGER CAR RENTAL' },
		{ key: '7515', value: '7515 PASSENGER CAR LEASING' },
		{ key: '7519', value: '7519 UTILITY TRAILER AND RECREATIONAL VEHICLE RENTAL' },
		{ key: '752', value: '752 AUTOMOBILE PARKING' },
		{ key: '7521', value: '7521 AUTOMOBILE PARKING' },
		{ key: '753', value: '753 AUTOMOTIVE REPAIR SHOPS' },
		{ key: '7532', value: '7532 TOP, BODY, AND UPHOLSTERY REPAIR SHOPS AND PAINT SHOPS' },
		{ key: '7533', value: '7533 AUTOMOTIVE EXHAUST SYSTEM REPAIR SHOPS' },
		{ key: '7534', value: '7534 TIRE RETREADING AND REPAIR SHOPS' },
		{ key: '7536', value: '7536 AUTOMOTIVE GLASS REPLACEMENT SHOPS' },
		{ key: '7537', value: '7537 AUTOMOTIVE TRANSMISSION REPAIR SHOPS' },
		{ key: '7538', value: '7538 GENERAL AUTOMOTIVE REPAIR SHOPS' },
		{ key: '7539', value: '7539 AUTOMOTIVE REPAIR SHOPS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '754', value: '754 AUTOMOTIVE SERVICES, EXCEPT REPAIR' },
		{ key: '7542', value: '7542 CARWASHES' },
		{ key: '7549', value: '7549 AUTOMOTIVE SERVICES, EXCEPT REPAIR AND CARWASHES' },
		{ key: '76', value: '76 MISCELLANEOUS REPAIR SERVICES' },
		{ key: '762', value: '762 ELECTRICAL REPAIR SHOPS' },
		{ key: '7622', value: '7622 RADIO AND TELEVISION REPAIR SHOPS' },
		{ key: '7623', value: '7623 REFRIGERATION AND AIR-CONDITIONING SERVICE AND REPAIR SHOPS' },
		{ key: '7629', value: '7629 ELECTRICAL AND ELECTRONIC REPAIR SHOPS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '763', value: '763 WATCH, CLOCK, AND JEWELRY REPAIR' },
		{ key: '7631', value: '7631 WATCH, CLOCK, AND JEWELRY REPAIR' },
		{ key: '764', value: '764 REUPHOLSTERY AND FURNITURE REPAIR' },
		{ key: '7641', value: '7641 REUPHOLSTERY AND FURNITURE REPAIR' },
		{ key: '769', value: '769 MISCELLANEOUS REPAIR SHOPS AND RELATED SERVICES' },
		{ key: '7692', value: '7692 WELDING REPAIR' },
		{ key: '7694', value: '7694 ARMATURE REWINDING SHOPS' },
		{ key: '7699', value: '7699 REPAIR SHOPS AND RELATED SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '78', value: '78 MOTION PICTURES' },
		{ key: '781', value: '781 MOTION PICTURE PRODUCTION AND ALLIED SERVICES' },
		{ key: '7812', value: '7812 MOTION PICTURE AND VIDEO TAPE PRODUCTION' },
		{ key: '7819', value: '7819 SERVICES ALLIED TO MOTION PICTURE PRODUCTION' },
		{ key: '782', value: '782 MOTION PICTURE DISTRIBUTION AND ALLIED SERVICES' },
		{ key: '7822', value: '7822 MOTION PICTURE AND VIDEO TAPE DISTRIBUTION' },
		{ key: '7829', value: '7829 SERVICES ALLIED TO MOTION PICTURE DISTRIBUTION' },
		{ key: '783', value: '783 MOTION PICTURE THEATERS' },
		{ key: '7832', value: '7832 MOTION PICTURE THEATERS, EXCEPT DRIVE-IN' },
		{ key: '7833', value: '7833 DRIVE-IN MOTION PICTURE THEATERS' },
		{ key: '784', value: '784 VIDEO TAPE RENTAL' },
		{ key: '7841', value: '7841 VIDEO TAPE RENTAL' },
		{ key: '79', value: '79 AMUSEMENT AND RECREATION SERVICES' },
		{ key: '791', value: '791 DANCE STUDIOS, SCHOOLS, AND HALLS' },
		{ key: '7911', value: '7911 DANCE STUDIOS, SCHOOLS, AND HALLS' },
		{ key: '792', value: '792 THEATRICAL PRODUCERS (EXCEPT MOTION PICTURE), BANDS, ORCHESTRAS, A' },
		{ key: '7922', value: '7922 THEATRICAL PRODUCERS (EXCEPT MOTION PICTURE) AND MISCELLANEOUS TH' },
		{ key: '7929', value: '7929 BANDS, ORCHESTRAS, ACTORS, AND OTHER ENTERTAINERS AND ENTERTAINME' },
		{ key: '793', value: '793 BOWLING CENTERS' },
		{ key: '7933', value: '7933 BOWLING CENTERS' },
		{ key: '794', value: '794 COMMERCIAL SPORTS' },
		{ key: '7941', value: '7941 PROFESSIONAL SPORTS CLUBS AND PROMOTERS' },
		{ key: '7948', value: '7948 RACING, INCLUDING TRACK OPERATION' },
		{ key: '799', value: '799 MISCELLANEOUS AMUSEMENT AND RECREATION SERVICES' },
		{ key: '7991', value: '7991 PHYSICAL FITNESS FACILITIES' },
		{ key: '7992', value: '7992 PUBLIC GOLF COURSES' },
		{ key: '7993', value: '7993 COIN-OPERATED AMUSEMENT DEVICES' },
		{ key: '7996', value: '7996 AMUSEMENT PARKS' },
		{ key: '7997', value: '7997 MEMBERSHIP SPORTS AND RECREATION CLUBS' },
		{ key: '7999', value: '7999 AMUSEMENT AND RECREATION SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '80', value: '80 HEALTH SERVICES' },
		{ key: '801', value: '801 OFFICES AND CLINICS OF DOCTORS OF MEDICINE' },
		{ key: '8011', value: '8011 OFFICES AND CLINICS OF DOCTORS OF MEDICINE' },
		{ key: '802', value: '802 OFFICES AND CLINICS OF DENTISTS' },
		{ key: '8021', value: '8021 OFFICES AND CLINICS OF DENTISTS' },
		{ key: '803', value: '803 OFFICES AND CLINICS OF DOCTORS OF OSTEOPATHY' },
		{ key: '8031', value: '8031 OFFICES AND CLINICS OF DOCTORS OF OSTEOPATHY' },
		{ key: '804', value: '804 OFFICES AND CLINICS OF OTHER HEALTH PRACTITIONERS' },
		{ key: '8041', value: '8041 OFFICES AND CLINICS OF CHIROPRACTORS' },
		{ key: '8042', value: '8042 OFFICES AND CLINICS OF OPTOMETRISTS' },
		{ key: '8043', value: '8043 OFFICES AND CLINICS OF PODIATRISTS' },
		{ key: '8049', value: '8049 OFFICES AND CLINICS OF HEALTH PRACTITIONERS, NOT ELSEWHERE CLASSI' },
		{ key: '805', value: '805 NURSING AND PERSONAL CARE FACILITIES' },
		{ key: '8051', value: '8051 SKILLED NURSING CARE FACILITIES' },
		{ key: '8052', value: '8052 INTERMEDIATE CARE FACILITIES' },
		{ key: '8059', value: '8059 NURSING AND PERSONAL CARE FACILITIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '806', value: '806 HOSPITALS' },
		{ key: '8062', value: '8062 GENERAL MEDICAL AND SURGICAL HOSPITALS' },
		{ key: '8063', value: '8063 PSYCHIATRIC HOSPITALS' },
		{ key: '8069', value: '8069 SPECIALTY HOSPITALS, EXCEPT PSYCHIATRIC' },
		{ key: '807', value: '807 MEDICAL AND DENTAL LABORATORIES' },
		{ key: '8071', value: '8071 MEDICAL LABORATORIES' },
		{ key: '8072', value: '8072 DENTAL LABORATORIES' },
		{ key: '808', value: '808 HOME HEALTH CARE SERVICES' },
		{ key: '8082', value: '8082 HOME HEALTH CARE SERVICES' },
		{ key: '809', value: '809 MISCELLANEOUS HEALTH AND ALLIED SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '8092', value: '8092 KIDNEY DIALYSIS CENTERS' },
		{ key: '8093', value: '8093 SPECIALTY OUTPATIENT FACILITIES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '8099', value: '8099 HEALTH AND ALLIED SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '81', value: '81 LEGAL SERVICES' },
		{ key: '811', value: '811 LEGAL SERVICES' },
		{ key: '8111', value: '8111 LEGAL SERVICES' },
		{ key: '82', value: '82 EDUCATIONAL SERVICES' },
		{ key: '821', value: '821 ELEMENTARY AND SECONDARY SCHOOLS' },
		{ key: '8211', value: '8211 ELEMENTARY AND SECONDARY SCHOOLS' },
		{ key: '822', value: '822 COLLEGES, UNIVERSITIES, PROFESSIONAL SCHOOLS, AND JUNIOR COLLEGES' },
		{ key: '8221', value: '8221 COLLEGES, UNIVERSITIES, AND PROFESSIONAL SCHOOLS' },
		{ key: '8222', value: '8222 JUNIOR COLLEGES AND TECHNICAL INSTITUTES' },
		{ key: '823', value: '823 LIBRARIES' },
		{ key: '8231', value: '8231 LIBRARIES' },
		{ key: '824', value: '824 VOCATIONAL SCHOOLS' },
		{ key: '8243', value: '8243 DATA PROCESSING SCHOOLS' },
		{ key: '8244', value: '8244 BUSINESS AND SECRETARIAL SCHOOLS' },
		{ key: '8249', value: '8249 VOCATIONAL SCHOOLS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '829', value: '829 SCHOOLS AND EDUCATIONAL SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '8299', value: '8299 SCHOOLS AND EDUCATIONAL SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '83', value: '83 SOCIAL SERVICES' },
		{ key: '832', value: '832 INDIVIDUAL AND FAMILY SOCIAL SERVICES' },
		{ key: '8322', value: '8322 INDIVIDUAL AND FAMILY SOCIAL SERVICES' },
		{ key: '833', value: '833 JOB TRAINING AND VOCATIONAL REHABILITATION SERVICES' },
		{ key: '8331', value: '8331 JOB TRAINING AND VOCATIONAL REHABILITATION SERVICES' },
		{ key: '835', value: '835 CHILD DAY CARE SERVICES' },
		{ key: '8351', value: '8351 CHILD DAY CARE SERVICES' },
		{ key: '836', value: '836 RESIDENTIAL CARE' },
		{ key: '8361', value: '8361 RESIDENTIAL CARE' },
		{ key: '839', value: '839 SOCIAL SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '8399', value: '8399 SOCIAL SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '84', value: '84 MUSEUMS, ART GALLERIES, AND BOTANICAL AND ZOOLOGICAL GARDENS' },
		{ key: '841', value: '841 MUSEUMS AND ART GALLERIES' },
		{ key: '8412', value: '8412 MUSEUMS AND ART GALLERIES' },
		{ key: '842', value: '842 ARBORETA AND BOTANICAL OR ZOOLOGICAL GARDENS' },
		{ key: '8422', value: '8422 ARBORETA AND BOTANICAL OR ZOOLOGICAL GARDENS' },
		{ key: '86', value: '86 MEMBERSHIP ORGANIZATIONS' },
		{ key: '861', value: '861 BUSINESS ASSOCIATIONS' },
		{ key: '8611', value: '8611 BUSINESS ASSOCIATIONS' },
		{ key: '862', value: '862 PROFESSIONAL MEMBERSHIP ORGANIZATIONS' },
		{ key: '8621', value: '8621 PROFESSIONAL MEMBERSHIP ORGANIZATIONS' },
		{ key: '863', value: '863 LABOR UNIONS AND SIMILAR LABOR ORGANIZATIONS' },
		{ key: '8631', value: '8631 LABOR UNIONS AND SIMILAR LABOR ORGANIZATIONS' },
		{ key: '864', value: '864 CIVIC, SOCIAL, AND FRATERNAL ASSOCIATIONS' },
		{ key: '8641', value: '8641 CIVIC, SOCIAL, AND FRATERNAL ASSOCIATIONS' },
		{ key: '865', value: '865 POLITICAL ORGANIZATIONS' },
		{ key: '8651', value: '8651 POLITICAL ORGANIZATIONS' },
		{ key: '866', value: '866 RELIGIOUS ORGANIZATIONS' },
		{ key: '8661', value: '8661 RELIGIOUS ORGANIZATIONS' },
		{ key: '869', value: '869 MEMBERSHIP ORGANIZATIONS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '8699', value: '8699 MEMBERSHIP ORGANIZATIONS, NOT ELSEWHERE CLASSIFIED' },
		{ key: '87', value: '87 ENGINEERING, ACCOUNTING, RESEARCH, MANAGEMENT, AND RELATED SERVICES' },
		{ key: '871', value: '871 ENGINEERING, ARCHITECTURAL, AND SURVEYING SERVICES' },
		{ key: '8711', value: '8711 ENGINEERING SERVICES' },
		{ key: '8712', value: '8712 ARCHITECTURAL SERVICES' },
		{ key: '8713', value: '8713 SURVEYING SERVICES' },
		{ key: '872', value: '872 ACCOUNTING, AUDITING, AND BOOKKEEPING SERVICES' },
		{ key: '8721', value: '8721 ACCOUNTING, AUDITING, AND BOOKKEEPING SERVICES' },
		{ key: '873', value: '873 RESEARCH, DEVELOPMENT, AND TESTING SERVICES' },
		{ key: '8731', value: '8731 COMMERCIAL PHYSICAL AND BIOLOGICAL RESEARCH' },
		{ key: '8732', value: '8732 COMMERCIAL ECONOMIC, SOCIOLOGICAL, AND EDUCATIONAL RESEARCH' },
		{ key: '8733', value: '8733 NONCOMMERCIAL RESEARCH ORGANIZATIONS' },
		{ key: '8734', value: '8734 TESTING LABORATORIES' },
		{ key: '874', value: '874 MANAGEMENT AND PUBLIC RELATIONS SERVICES' },
		{ key: '8741', value: '8741 MANAGEMENT SERVICES' },
		{ key: '8742', value: '8742 MANAGEMENT CONSULTING SERVICES' },
		{ key: '8743', value: '8743 PUBLIC RELATIONS SERVICES' },
		{ key: '8744', value: '8744 FACILITIES SUPPORT MANAGEMENT SERVICES' },
		{ key: '8748', value: '8748 BUSINESS CONSULTING SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '88', value: '88 PRIVATE HOUSEHOLDS' },
		{ key: '881', value: '881 PRIVATE HOUSEHOLDS' },
		{ key: '8811', value: '8811 PRIVATE HOUSEHOLDS' },
		{ key: '89', value: '89 SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '899', value: '899 SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '8999', value: '8999 SERVICES, NOT ELSEWHERE CLASSIFIED' },
		{ key: '91', value: '91 EXECUTIVE, LEGISLATIVE, AND GENERAL GOVERNMENT, EXCEPT FINANCE' },
		{ key: '911', value: '911 EXECUTIVE OFFICES' },
		{ key: '9111', value: '9111 EXECUTIVE OFFICES' },
		{ key: '912', value: '912 LEGISLATIVE BODIES' },
		{ key: '9121', value: '9121 LEGISLATIVE BODIES' },
		{ key: '913', value: '913 EXECUTIVE AND LEGISLATIVE OFFICES COMBINED' },
		{ key: '9131', value: '9131 EXECUTIVE AND LEGISLATIVE OFFICES COMBINED' },
		{ key: '919', value: '919 GENERAL GOVERNMENT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '9199', value: '9199 GENERAL GOVERNMENT, NOT ELSEWHERE CLASSIFIED' },
		{ key: '92', value: '92 JUSTICE, PUBLIC ORDER, AND SAFETY' },
		{ key: '921', value: '921 COURTS' },
		{ key: '9211', value: '9211 COURTS' },
		{ key: '922', value: '922 PUBLIC ORDER AND SAFETY' },
		{ key: '9221', value: '9221 POLICE PROTECTION' },
		{ key: '9222', value: '9222 LEGAL COUNSEL AND PROSECUTION' },
		{ key: '9223', value: '9223 CORRECTIONAL INSTITUTIONS' },
		{ key: '9224', value: '9224 FIRE PROTECTION' },
		{ key: '9229', value: '9229 PUBLIC ORDER AND SAFETY, NOT ELSEWHERE CLASSIFIED' },
		{ key: '93', value: '93 PUBLIC FINANCE, TAXATION, AND MONETARY POLICY' },
		{ key: '931', value: '931 PUBLIC FINANCE, TAXATION, AND MONETARY POLICY' },
		{ key: '9311', value: '9311 PUBLIC FINANCE, TAXATION, AND MONETARY POLICY' },
		{ key: '94', value: '94 ADMINISTRATION OF HUMAN RESOURCE PROGRAMS' },
		{ key: '941', value: '941 ADMINISTRATION OF EDUCATIONAL PROGRAMS' },
		{ key: '9411', value: '9411 ADMINISTRATION OF EDUCATIONAL PROGRAMS' },
		{ key: '943', value: '943 ADMINISTRATION OF PUBLIC HEALTH PROGRAMS' },
		{ key: '9431', value: '9431 ADMINISTRATION OF PUBLIC HEALTH PROGRAMS' },
		{ key: '944', value: '944 ADMINISTRATION OF SOCIAL, HUMAN RESOURCE AND INCOME MAINTENANCE PR' },
		{ key: '9441', value: '9441 ADMINISTRATION OF SOCIAL, HUMAN RESOURCE AND INCOME MAINTENANCE P' },
		{ key: '945', value: '945 ADMINISTRATION OF VETERANS\' AFFAIRS, EXCEPT HEALTH AND INSURANCE' },
		{ key: '9451', value: '9451 ADMINISTRATION OF VETERANS\' AFFAIRS, EXCEPT HEALTH AND INSURANCE' },
		{ key: '95', value: '95 ADMINISTRATION OF ENVIRONMENTAL QUALITY AND HOUSING PROGRAMS' },
		{ key: '951', value: '951 ADMINISTRATION OF ENVIRONMENTAL QUALITY PROGRAMS' },
		{ key: '9511', value: '9511 AIR AND WATER RESOURCE AND SOLID WASTE MANAGEMENT' },
		{ key: '9512', value: '9512 LAND, MINERAL, WILDLIFE, AND FOREST CONSERVATION' },
		{ key: '953', value: '953 ADMINISTRATION OF HOUSING AND URBAN DEVELOPMENT PROGRAMS' },
		{ key: '9531', value: '9531 ADMINISTRATION OF HOUSING PROGRAMS' },
		{ key: '9532', value: '9532 ADMINISTRATION OF URBAN PLANNING AND COMMUNITY AND RURAL DEVELOPM' },
		{ key: '96', value: '96 ADMINISTRATION OF ECONOMIC PROGRAMS' },
		{ key: '961', value: '961 ADMINISTRATION OF GENERAL ECONOMIC PROGRAMS' },
		{ key: '9611', value: '9611 ADMINISTRATION OF GENERAL ECONOMIC PROGRAMS' },
		{ key: '962', value: '962 REGULATION AND ADMINISTRATION OF TRANSPORTATION PROGRAMS' },
		{ key: '9621', value: '9621 REGULATION AND ADMINISTRATION OF TRANSPORTATION PROGRAMS' },
		{ key: '963', value: '963 REGULATION AND ADMINISTRATION OF COMMUNICATIONS, ELECTRIC, GAS, AN' },
		{ key: '9631', value: '9631 REGULATION AND ADMINISTRATION OF COMMUNICATIONS, ELECTRIC, GAS, A' },
		{ key: '964', value: '964 REGULATION OF AGRICULTURAL MARKETING AND COMMODITIES' },
		{ key: '9641', value: '9641 REGULATION OF AGRICULTURAL MARKETING AND COMMODITIES' },
		{ key: '965', value: '965 REGULATION, LICENSING, AND INSPECTION OF MISCELLANEOUS COMMERCIAL' },
		{ key: '9651', value: '9651 REGULATION, LICENSING, AND INSPECTION OF MISCELLANEOUS COMMERCIAL' },
		{ key: '966', value: '966 SPACE RESEARCH AND TECHNOLOGY' },
		{ key: '9661', value: '9661 SPACE RESEARCH AND TECHNOLOGY' },
		{ key: '97', value: '97 NATIONAL SECURITY AND INTERNATIONAL AFFAIRS' },
		{ key: '971', value: '971 NATIONAL SECURITY' },
		{ key: '9711', value: '9711 NATIONAL SECURITY' },
		{ key: '972', value: '972 INTERNATIONAL AFFAIRS' },
		{ key: '9721', value: '9721 INTERNATIONAL AFFAIRS' },
		{ key: '99', value: '99 NONCLASSIFIABLE ESTABLISHMENTS' },
		{ key: '999', value: '999 NONCLASSIFIABLE ESTABLISHMENTS' },
		{ key: '9999', value: '9999 NONCLASSIFIABLE ESTABLISHMENTS' },
	],
	naicsCodes: [
		{ key: '11', value: 'Agriculture, Forestry, Fishing and Hunting' },
		{ key: '111', value: 'Crop Production' },
		{ key: '1111', value: 'Oilseed and Grain Farming' },
		{ key: '11111', value: 'Soybean Farming' },
		{ key: '111110', value: 'Soybean Farming' },
		{ key: '11112', value: 'Oilseed (except Soybean) Farming' },
		{ key: '111120', value: 'Oilseed (except Soybean) Farming' },
		{ key: '11113', value: 'Dry Pea and Bean Farming' },
		{ key: '111130', value: 'Dry Pea and Bean Farming' },
		{ key: '11114', value: 'Wheat Farming' },
		{ key: '111140', value: 'Wheat Farming' },
		{ key: '11115', value: 'Corn Farming' },
		{ key: '111150', value: 'Corn Farming' },
		{ key: '11116', value: 'Rice Farming' },
		{ key: '111160', value: 'Rice Farming' },
		{ key: '11119', value: 'Other Grain Farming' },
		{ key: '111191', value: 'Oilseed and Grain Combination Farming' },
		{ key: '111199', value: 'All Other Grain Farming' },
		{ key: '1112', value: 'Vegetable and Melon Farming' },
		{ key: '11121', value: 'Vegetable and Melon Farming' },
		{ key: '111211', value: 'Potato Farming' },
		{ key: '111219', value: 'Other Vegetable (except Potato) and Melon Farming' },
		{ key: '1113', value: 'Fruit and Tree Nut Farming' },
		{ key: '11131', value: 'Orange Groves' },
		{ key: '111310', value: 'Orange Groves' },
		{ key: '11132', value: 'Citrus (except Orange) Groves' },
		{ key: '111320', value: 'Citrus (except Orange) Groves' },
		{ key: '11133', value: 'Noncitrus Fruit and Tree Nut Farming' },
		{ key: '111331', value: 'Apple Orchards' },
		{ key: '111332', value: 'Grape Vineyards' },
		{ key: '111333', value: 'Strawberry Farming' },
		{ key: '111334', value: 'Berry (except Strawberry) Farming' },
		{ key: '111335', value: 'Tree Nut Farming' },
		{ key: '111336', value: 'Fruit and Tree Nut Combination Farming' },
		{ key: '111339', value: 'Other Noncitrus Fruit Farming' },
		{ key: '1114', value: 'Greenhouse, Nursery, and Floriculture Production' },
		{ key: '11141', value: 'Food Crops Grown Under Cover' },
		{ key: '111411', value: 'Mushroom Production' },
		{ key: '111419', value: 'Other Food Crops Grown Under Cover' },
		{ key: '11142', value: 'Nursery and Floriculture Production' },
		{ key: '111421', value: 'Nursery and Tree Production' },
		{ key: '111422', value: 'Floriculture Production' },
		{ key: '1119', value: 'Other Crop Farming' },
		{ key: '11191', value: 'Tobacco Farming' },
		{ key: '111910', value: 'Tobacco Farming' },
		{ key: '11192', value: 'Cotton Farming' },
		{ key: '111920', value: 'Cotton Farming' },
		{ key: '11193', value: 'Sugarcane Farming' },
		{ key: '111930', value: 'Sugarcane Farming' },
		{ key: '11194', value: 'Hay Farming' },
		{ key: '111940', value: 'Hay Farming' },
		{ key: '11199', value: 'All Other Crop Farming' },
		{ key: '111991', value: 'Sugar Beet Farming' },
		{ key: '111992', value: 'Peanut Farming' },
		{ key: '111998', value: 'All Other Miscellaneous Crop Farming' },
		{ key: '112', value: 'Animal Production and Aquaculture' },
		{ key: '1121', value: 'Cattle Ranching and Farming' },
		{ key: '11211', value: 'Beef Cattle Ranching and Farming, including Feedlots' },
		{ key: '112111', value: 'Beef Cattle Ranching and Farming' },
		{ key: '112112', value: 'Cattle Feedlots' },
		{ key: '11212', value: 'Dairy Cattle and Milk Production' },
		{ key: '112120', value: 'Dairy Cattle and Milk Production' },
		{ key: '11213', value: 'Dual-Purpose Cattle Ranching and Farming' },
		{ key: '112130', value: 'Dual-Purpose Cattle Ranching and Farming' },
		{ key: '1122', value: 'Hog and Pig Farming' },
		{ key: '11221', value: 'Hog and Pig Farming' },
		{ key: '112210', value: 'Hog and Pig Farming' },
		{ key: '1123', value: 'Poultry and Egg Production' },
		{ key: '11231', value: 'Chicken Egg Production' },
		{ key: '112310', value: 'Chicken Egg Production' },
		{ key: '11232', value: 'Broilers and Other Meat Type Chicken Production' },
		{ key: '112320', value: 'Broilers and Other Meat Type Chicken Production' },
		{ key: '11233', value: 'Turkey Production' },
		{ key: '112330', value: 'Turkey Production' },
		{ key: '11234', value: 'Poultry Hatcheries' },
		{ key: '112340', value: 'Poultry Hatcheries' },
		{ key: '11239', value: 'Other Poultry Production' },
		{ key: '112390', value: 'Other Poultry Production' },
		{ key: '1124', value: 'Sheep and Goat Farming' },
		{ key: '11241', value: 'Sheep Farming' },
		{ key: '112410', value: 'Sheep Farming' },
		{ key: '11242', value: 'Goat Farming' },
		{ key: '112420', value: 'Goat Farming' },
		{ key: '1125', value: 'Aquaculture' },
		{ key: '11251', value: 'Aquaculture' },
		{ key: '112511', value: 'Finfish Farming and Fish Hatcheries' },
		{ key: '112512', value: 'Shellfish Farming' },
		{ key: '112519', value: 'Other Aquaculture' },
		{ key: '1129', value: 'Other Animal Production' },
		{ key: '11291', value: 'Apiculture' },
		{ key: '112910', value: 'Apiculture' },
		{ key: '11292', value: 'Horses and Other Equine Production' },
		{ key: '112920', value: 'Horses and Other Equine Production' },
		{ key: '11293', value: 'Fur-Bearing Animal and Rabbit Production' },
		{ key: '112930', value: 'Fur-Bearing Animal and Rabbit Production' },
		{ key: '11299', value: 'All Other Animal Production' },
		{ key: '112990', value: 'All Other Animal Production' },
		{ key: '113', value: 'Forestry and Logging' },
		{ key: '1131', value: 'Timber Tract Operations' },
		{ key: '11311', value: 'Timber Tract Operations' },
		{ key: '113110', value: 'Timber Tract Operations' },
		{ key: '1132', value: 'Forest Nurseries and Gathering of Forest Products' },
		{ key: '11321', value: 'Forest Nurseries and Gathering of Forest Products' },
		{ key: '113210', value: 'Forest Nurseries and Gathering of Forest Products' },
		{ key: '1133', value: 'Logging' },
		{ key: '11331', value: 'Logging' },
		{ key: '113310', value: 'Logging' },
		{ key: '114', value: 'Fishing, Hunting and Trapping' },
		{ key: '1141', value: 'Fishing' },
		{ key: '11411', value: 'Fishing' },
		{ key: '114111', value: 'Finfish Fishing' },
		{ key: '114112', value: 'Shellfish Fishing' },
		{ key: '114119', value: 'Other Marine Fishing' },
		{ key: '1142', value: 'Hunting and Trapping' },
		{ key: '11421', value: 'Hunting and Trapping' },
		{ key: '114210', value: 'Hunting and Trapping' },
		{ key: '115', value: 'Support Activities for Agriculture and Forestry' },
		{ key: '1151', value: 'Support Activities for Crop Production' },
		{ key: '11511', value: 'Support Activities for Crop Production' },
		{ key: '115111', value: 'Cotton Ginning' },
		{ key: '115112', value: 'Soil Preparation, Planting, and Cultivating' },
		{ key: '115113', value: 'Crop Harvesting, Primarily by Machine' },
		{ key: '115114', value: 'Postharvest Crop Activities (except Cotton Ginning)' },
		{ key: '115115', value: 'Farm Labor Contractors and Crew Leaders' },
		{ key: '115116', value: 'Farm Management Services' },
		{ key: '1152', value: 'Support Activities for Animal Production' },
		{ key: '11521', value: 'Support Activities for Animal Production' },
		{ key: '115210', value: 'Support Activities for Animal Production' },
		{ key: '1153', value: 'Support Activities for Forestry' },
		{ key: '11531', value: 'Support Activities for Forestry' },
		{ key: '115310', value: 'Support Activities for Forestry' },
		{ key: '21', value: 'Mining, Quarrying, and Oil and Gas Extraction' },
		{ key: '211', value: 'Oil and Gas Extraction' },
		{ key: '2111', value: 'Oil and Gas Extraction' },
		{ key: '21112', value: 'Crude Petroleum Extraction' },
		{ key: '211120', value: 'Crude Petroleum Extraction' },
		{ key: '21113', value: 'Natural Gas Extraction' },
		{ key: '211130', value: 'Natural Gas Extraction' },
		{ key: '212', value: 'Mining (except Oil and Gas)' },
		{ key: '2121', value: 'Coal Mining' },
		{ key: '21211', value: 'Coal Mining' },
		{ key: '212111', value: 'Bituminous Coal and Lignite Surface Mining' },
		{ key: '212112', value: 'Bituminous Coal Underground Mining' },
		{ key: '212113', value: 'Anthracite Mining' },
		{ key: '2122', value: 'Metal Ore Mining' },
		{ key: '21221', value: 'Iron Ore Mining' },
		{ key: '212210', value: 'Iron Ore Mining' },
		{ key: '21222', value: 'Gold Ore and Silver Ore Mining' },
		{ key: '212221', value: 'Gold Ore Mining' },
		{ key: '212222', value: 'Silver Ore Mining' },
		{ key: '21223', value: 'Copper, Nickel, Lead, and Zinc Mining' },
		{ key: '212230', value: 'Copper, Nickel, Lead, and Zinc Mining' },
		{ key: '21229', value: 'Other Metal Ore Mining' },
		{ key: '212291', value: 'Uranium-Radium-Vanadium Ore Mining' },
		{ key: '212299', value: 'All Other Metal Ore Mining' },
		{ key: '2123', value: 'Nonmetallic Mineral Mining and Quarrying' },
		{ key: '21231', value: 'Stone Mining and Quarrying' },
		{ key: '212311', value: 'Dimension Stone Mining and Quarrying' },
		{ key: '212312', value: 'Crushed and Broken Limestone Mining and Quarrying' },
		{ key: '212313', value: 'Crushed and Broken Granite Mining and Quarrying' },
		{ key: '212319', value: 'Other Crushed and Broken Stone Mining and Quarrying' },
		{ key: '21232', value: 'Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying' },
		{ key: '212321', value: 'Construction Sand and Gravel Mining' },
		{ key: '212322', value: 'Industrial Sand Mining' },
		{ key: '212324', value: 'Kaolin and Ball Clay Mining' },
		{ key: '212325', value: 'Clay and Ceramic and Refractory Minerals Mining' },
		{ key: '21239', value: 'Other Nonmetallic Mineral Mining and Quarrying' },
		{ key: '212391', value: 'Potash, Soda, and Borate Mineral Mining' },
		{ key: '212392', value: 'Phosphate Rock Mining' },
		{ key: '212393', value: 'Other Chemical and Fertilizer Mineral Mining' },
		{ key: '212399', value: 'All Other Nonmetallic Mineral Mining' },
		{ key: '213', value: 'Support Activities for Mining' },
		{ key: '2131', value: 'Support Activities for Mining' },
		{ key: '21311', value: 'Support Activities for Mining' },
		{ key: '213111', value: 'Drilling Oil and Gas Wells' },
		{ key: '213112', value: 'Support Activities for Oil and Gas Operations' },
		{ key: '213113', value: 'Support Activities for Coal Mining' },
		{ key: '213114', value: 'Support Activities for Metal Mining' },
		{ key: '213115', value: 'Support Activities for Nonmetallic Minerals (except Fuels) Mining' },
		{ key: '22', value: 'Utilities' },
		{ key: '221', value: 'Utilities' },
		{ key: '2211', value: 'Electric Power Generation, Transmission and Distribution' },
		{ key: '22111', value: 'Electric Power Generation' },
		{ key: '221111', value: 'Hydroelectric Power Generation' },
		{ key: '221112', value: 'Fossil Fuel Electric Power Generation' },
		{ key: '221113', value: 'Nuclear Electric Power Generation' },
		{ key: '221114', value: 'Solar Electric Power Generation' },
		{ key: '221115', value: 'Wind Electric Power Generation' },
		{ key: '221116', value: 'Geothermal Electric Power Generation' },
		{ key: '221117', value: 'Biomass Electric Power Generation' },
		{ key: '221118', value: 'Other Electric Power Generation' },
		{ key: '22112', value: 'Electric Power Transmission, Control, and Distribution' },
		{ key: '221121', value: 'Electric Bulk Power Transmission and Control' },
		{ key: '221122', value: 'Electric Power Distribution' },
		{ key: '2212', value: 'Natural Gas Distribution' },
		{ key: '22121', value: 'Natural Gas Distribution' },
		{ key: '221210', value: 'Natural Gas Distribution' },
		{ key: '2213', value: 'Water, Sewage and Other Systems' },
		{ key: '22131', value: 'Water Supply and Irrigation Systems' },
		{ key: '221310', value: 'Water Supply and Irrigation Systems' },
		{ key: '22132', value: 'Sewage Treatment Facilities' },
		{ key: '221320', value: 'Sewage Treatment Facilities' },
		{ key: '22133', value: 'Steam and Air-Conditioning Supply' },
		{ key: '221330', value: 'Steam and Air-Conditioning Supply' },
		{ key: '23', value: 'Construction' },
		{ key: '236', value: 'Construction of Buildings' },
		{ key: '2361', value: 'Residential Building Construction' },
		{ key: '23611', value: 'Residential Building Construction' },
		{ key: '236115', value: 'New Single-Family Housing Construction (except For-Sale Builders)' },
		{ key: '236116', value: 'New Multifamily Housing Construction (except For-Sale Builders)' },
		{ key: '236117', value: 'New Housing For-Sale Builders' },
		{ key: '236118', value: 'Residential Remodelers' },
		{ key: '2362', value: 'Nonresidential Building Construction' },
		{ key: '23621', value: 'Industrial Building Construction' },
		{ key: '236210', value: 'Industrial Building Construction' },
		{ key: '23622', value: 'Commercial and Institutional Building Construction' },
		{ key: '236220', value: 'Commercial and Institutional Building Construction' },
		{ key: '237', value: 'Heavy and Civil Engineering Construction' },
		{ key: '2371', value: 'Utility System Construction' },
		{ key: '23711', value: 'Water and Sewer Line and Related Structures Construction' },
		{ key: '237110', value: 'Water and Sewer Line and Related Structures Construction' },
		{ key: '23712', value: 'Oil and Gas Pipeline and Related Structures Construction' },
		{ key: '237120', value: 'Oil and Gas Pipeline and Related Structures Construction' },
		{ key: '23713', value: 'Power and Communication Line and Related Structures Construction' },
		{ key: '237130', value: 'Power and Communication Line and Related Structures Construction' },
		{ key: '2372', value: 'Land Subdivision' },
		{ key: '23721', value: 'Land Subdivision' },
		{ key: '237210', value: 'Land Subdivision' },
		{ key: '2373', value: 'Highway, Street, and Bridge Construction' },
		{ key: '23731', value: 'Highway, Street, and Bridge Construction' },
		{ key: '237310', value: 'Highway, Street, and Bridge Construction' },
		{ key: '2379', value: 'Other Heavy and Civil Engineering Construction' },
		{ key: '23799', value: 'Other Heavy and Civil Engineering Construction' },
		{ key: '237990', value: 'Other Heavy and Civil Engineering Construction' },
		{ key: '238', value: 'Specialty Trade Contractors' },
		{ key: '2381', value: 'Foundation, Structure, and Building Exterior Contractors' },
		{ key: '23811', value: 'Poured Concrete Foundation and Structure Contractors' },
		{ key: '238110', value: 'Poured Concrete Foundation and Structure Contractors' },
		{ key: '23812', value: 'Structural Steel and Precast Concrete Contractors' },
		{ key: '238120', value: 'Structural Steel and Precast Concrete Contractors' },
		{ key: '23813', value: 'Framing Contractors' },
		{ key: '238130', value: 'Framing Contractors' },
		{ key: '23814', value: 'Masonry Contractors' },
		{ key: '238140', value: 'Masonry Contractors' },
		{ key: '23815', value: 'Glass and Glazing Contractors' },
		{ key: '238150', value: 'Glass and Glazing Contractors' },
		{ key: '23816', value: 'Roofing Contractors' },
		{ key: '238160', value: 'Roofing Contractors' },
		{ key: '23817', value: 'Siding Contractors' },
		{ key: '238170', value: 'Siding Contractors' },
		{ key: '23819', value: 'Other Foundation, Structure, and Building Exterior Contractors' },
		{ key: '238190', value: 'Other Foundation, Structure, and Building Exterior Contractors' },
		{ key: '2382', value: 'Building Equipment Contractors' },
		{ key: '23821', value: 'Electrical Contractors and Other Wiring Installation Contractors' },
		{ key: '238210', value: 'Electrical Contractors and Other Wiring Installation Contractors' },
		{ key: '23822', value: 'Plumbing, Heating, and Air-Conditioning Contractors' },
		{ key: '238220', value: 'Plumbing, Heating, and Air-Conditioning Contractors' },
		{ key: '23829', value: 'Other Building Equipment Contractors' },
		{ key: '238290', value: 'Other Building Equipment Contractors' },
		{ key: '2383', value: 'Building Finishing Contractors' },
		{ key: '23831', value: 'Drywall and Insulation Contractors' },
		{ key: '238310', value: 'Drywall and Insulation Contractors' },
		{ key: '23832', value: 'Painting and Wall Covering Contractors' },
		{ key: '238320', value: 'Painting and Wall Covering Contractors' },
		{ key: '23833', value: 'Flooring Contractors' },
		{ key: '238330', value: 'Flooring Contractors' },
		{ key: '23834', value: 'Tile and Terrazzo Contractors' },
		{ key: '238340', value: 'Tile and Terrazzo Contractors' },
		{ key: '23835', value: 'Finish Carpentry Contractors' },
		{ key: '238350', value: 'Finish Carpentry Contractors' },
		{ key: '23839', value: 'Other Building Finishing Contractors' },
		{ key: '238390', value: 'Other Building Finishing Contractors' },
		{ key: '2389', value: 'Other Specialty Trade Contractors' },
		{ key: '23891', value: 'Site Preparation Contractors' },
		{ key: '238910', value: 'Site Preparation Contractors' },
		{ key: '23899', value: 'All Other Specialty Trade Contractors' },
		{ key: '238990', value: 'All Other Specialty Trade Contractors' },
		{ key: '31-33', value: 'Manufacturing' },
		{ key: '311', value: 'Food Manufacturing' },
		{ key: '3111', value: 'Animal Food Manufacturing' },
		{ key: '31111', value: 'Animal Food Manufacturing' },
		{ key: '311111', value: 'Dog and Cat Food Manufacturing' },
		{ key: '311119', value: 'Other Animal Food Manufacturing' },
		{ key: '3112', value: 'Grain and Oilseed Milling' },
		{ key: '31121', value: 'Flour Milling and Malt Manufacturing' },
		{ key: '311211', value: 'Flour Milling' },
		{ key: '311212', value: 'Rice Milling' },
		{ key: '311213', value: 'Malt Manufacturing' },
		{ key: '31122', value: 'Starch and Vegetable Fats and Oils Manufacturing' },
		{ key: '311221', value: 'Wet Corn Milling' },
		{ key: '311224', value: 'Soybean and Other Oilseed Processing' },
		{ key: '311225', value: 'Fats and Oils Refining and Blending' },
		{ key: '31123', value: 'Breakfast Cereal Manufacturing' },
		{ key: '311230', value: 'Breakfast Cereal Manufacturing' },
		{ key: '3113', value: 'Sugar and Confectionery Product Manufacturing' },
		{ key: '31131', value: 'Sugar Manufacturing' },
		{ key: '311313', value: 'Beet Sugar Manufacturing' },
		{ key: '311314', value: 'Cane Sugar Manufacturing' },
		{ key: '31134', value: 'Nonchocolate Confectionery Manufacturing' },
		{ key: '311340', value: 'Nonchocolate Confectionery Manufacturing' },
		{ key: '31135', value: 'Chocolate and Confectionery Manufacturing' },
		{ key: '311351', value: 'Chocolate and Confectionery Manufacturing from Cacao Beans' },
		{ key: '311352', value: 'Confectionery Manufacturing from Purchased Chocolate' },
		{ key: '3114', value: 'Fruit and Vegetable Preserving and Specialty Food Manufacturing' },
		{ key: '31141', value: 'Frozen Food Manufacturing' },
		{ key: '311411', value: 'Frozen Fruit, Juice, and Vegetable Manufacturing' },
		{ key: '311412', value: 'Frozen Specialty Food Manufacturing' },
		{ key: '31142', value: 'Fruit and Vegetable Canning, Pickling, and Drying' },
		{ key: '311421', value: 'Fruit and Vegetable Canning' },
		{ key: '311422', value: 'Specialty Canning' },
		{ key: '311423', value: 'Dried and Dehydrated Food Manufacturing' },
		{ key: '3115', value: 'Dairy Product Manufacturing' },
		{ key: '31151', value: 'Dairy Product (except Frozen) Manufacturing' },
		{ key: '311511', value: 'Fluid Milk Manufacturing' },
		{ key: '311512', value: 'Creamery Butter Manufacturing' },
		{ key: '311513', value: 'Cheese Manufacturing' },
		{ key: '311514', value: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing' },
		{ key: '31152', value: 'Ice Cream and Frozen Dessert Manufacturing' },
		{ key: '311520', value: 'Ice Cream and Frozen Dessert Manufacturing' },
		{ key: '3116', value: 'Animal Slaughtering and Processing' },
		{ key: '31161', value: 'Animal Slaughtering and Processing' },
		{ key: '311611', value: 'Animal (except Poultry) Slaughtering' },
		{ key: '311612', value: 'Meat Processed from Carcasses' },
		{ key: '311613', value: 'Rendering and Meat Byproduct Processing' },
		{ key: '311615', value: 'Poultry Processing' },
		{ key: '3117', value: 'Seafood Product Preparation and Packaging' },
		{ key: '31171', value: 'Seafood Product Preparation and Packaging' },
		{ key: '311710', value: 'Seafood Product Preparation and Packaging' },
		{ key: '3118', value: 'Bakeries and Tortilla Manufacturing' },
		{ key: '31181', value: 'Bread and Bakery Product Manufacturing' },
		{ key: '311811', value: 'Retail Bakeries' },
		{ key: '311812', value: 'Commercial Bakeries' },
		{ key: '311813', value: 'Frozen Cakes, Pies, and Other Pastries Manufacturing' },
		{ key: '31182', value: 'Cookie, Cracker, and Pasta Manufacturing' },
		{ key: '311821', value: 'Cookie and Cracker Manufacturing' },
		{ key: '311824', value: 'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour' },
		{ key: '31183', value: 'Tortilla Manufacturing' },
		{ key: '311830', value: 'Tortilla Manufacturing' },
		{ key: '3119', value: 'Other Food Manufacturing' },
		{ key: '31191', value: 'Snack Food Manufacturing' },
		{ key: '311911', value: 'Roasted Nuts and Peanut Butter Manufacturing' },
		{ key: '311919', value: 'Other Snack Food Manufacturing' },
		{ key: '31192', value: 'Coffee and Tea Manufacturing' },
		{ key: '311920', value: 'Coffee and Tea Manufacturing' },
		{ key: '31193', value: 'Flavoring Syrup and Concentrate Manufacturing' },
		{ key: '311930', value: 'Flavoring Syrup and Concentrate Manufacturing' },
		{ key: '31194', value: 'Seasoning and Dressing Manufacturing' },
		{ key: '311941', value: 'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing' },
		{ key: '311942', value: 'Spice and Extract Manufacturing' },
		{ key: '31199', value: 'All Other Food Manufacturing' },
		{ key: '311991', value: 'Perishable Prepared Food Manufacturing' },
		{ key: '311999', value: 'All Other Miscellaneous Food Manufacturing' },
		{ key: '312', value: 'Beverage and Tobacco Product Manufacturing' },
		{ key: '3121', value: 'Beverage Manufacturing' },
		{ key: '31211', value: 'Soft Drink and Ice Manufacturing' },
		{ key: '312111', value: 'Soft Drink Manufacturing' },
		{ key: '312112', value: 'Bottled Water Manufacturing' },
		{ key: '312113', value: 'Ice Manufacturing' },
		{ key: '31212', value: 'Breweries' },
		{ key: '312120', value: 'Breweries' },
		{ key: '31213', value: 'Wineries' },
		{ key: '312130', value: 'Wineries' },
		{ key: '31214', value: 'Distilleries' },
		{ key: '312140', value: 'Distilleries' },
		{ key: '3122', value: 'Tobacco Manufacturing' },
		{ key: '31223', value: 'Tobacco Manufacturing' },
		{ key: '312230', value: 'Tobacco Manufacturing' },
		{ key: '313', value: 'Textile Mills' },
		{ key: '3131', value: 'Fiber, Yarn, and Thread Mills' },
		{ key: '31311', value: 'Fiber, Yarn, and Thread Mills' },
		{ key: '313110', value: 'Fiber, Yarn, and Thread Mills' },
		{ key: '3132', value: 'Fabric Mills' },
		{ key: '31321', value: 'Broadwoven Fabric Mills' },
		{ key: '313210', value: 'Broadwoven Fabric Mills' },
		{ key: '31322', value: 'Narrow Fabric Mills and Schiffli Machine Embroidery' },
		{ key: '313220', value: 'Narrow Fabric Mills and Schiffli Machine Embroidery' },
		{ key: '31323', value: 'Nonwoven Fabric Mills' },
		{ key: '313230', value: 'Nonwoven Fabric Mills' },
		{ key: '31324', value: 'Knit Fabric Mills' },
		{ key: '313240', value: 'Knit Fabric Mills' },
		{ key: '3133', value: 'Textile and Fabric Finishing and Fabric Coating Mills' },
		{ key: '31331', value: 'Textile and Fabric Finishing Mills' },
		{ key: '313310', value: 'Textile and Fabric Finishing Mills' },
		{ key: '31332', value: 'Fabric Coating Mills' },
		{ key: '313320', value: 'Fabric Coating Mills' },
		{ key: '314', value: 'Textile Product Mills' },
		{ key: '3141', value: 'Textile Furnishings Mills' },
		{ key: '31411', value: 'Carpet and Rug Mills' },
		{ key: '314110', value: 'Carpet and Rug Mills' },
		{ key: '31412', value: 'Curtain and Linen Mills' },
		{ key: '314120', value: 'Curtain and Linen Mills' },
		{ key: '3149', value: 'Other Textile Product Mills' },
		{ key: '31491', value: 'Textile Bag and Canvas Mills' },
		{ key: '314910', value: 'Textile Bag and Canvas Mills' },
		{ key: '31499', value: 'All Other Textile Product Mills' },
		{ key: '314994', value: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills' },
		{ key: '314999', value: 'All Other Miscellaneous Textile Product Mills' },
		{ key: '315', value: 'Apparel Manufacturing' },
		{ key: '3151', value: 'Apparel Knitting Mills' },
		{ key: '31511', value: 'Hosiery and Sock Mills' },
		{ key: '315110', value: 'Hosiery and Sock Mills' },
		{ key: '31519', value: 'Other Apparel Knitting Mills' },
		{ key: '315190', value: 'Other Apparel Knitting Mills' },
		{ key: '3152', value: 'Cut and Sew Apparel Manufacturing' },
		{ key: '31521', value: 'Cut and Sew Apparel Contractors' },
		{ key: '315210', value: 'Cut and Sew Apparel Contractors' },
		{ key: '31522', value: 'Men’s and Boys’ Cut and Sew Apparel Manufacturing' },
		{ key: '315220', value: 'Men’s and Boys’ Cut and Sew Apparel Manufacturing' },
		{ key: '31524', value: 'Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing' },
		{ key: '315240', value: 'Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing' },
		{ key: '31528', value: 'Other Cut and Sew Apparel Manufacturing' },
		{ key: '315280', value: 'Other Cut and Sew Apparel Manufacturing' },
		{ key: '3159', value: 'Apparel Accessories and Other Apparel Manufacturing' },
		{ key: '31599', value: 'Apparel Accessories and Other Apparel Manufacturing' },
		{ key: '315990', value: 'Apparel Accessories and Other Apparel Manufacturing' },
		{ key: '316', value: 'Leather and Allied Product Manufacturing' },
		{ key: '3161', value: 'Leather and Hide Tanning and Finishing' },
		{ key: '31611', value: 'Leather and Hide Tanning and Finishing' },
		{ key: '316110', value: 'Leather and Hide Tanning and Finishing' },
		{ key: '3162', value: 'Footwear Manufacturing' },
		{ key: '31621', value: 'Footwear Manufacturing' },
		{ key: '316210', value: 'Footwear Manufacturing' },
		{ key: '3169', value: 'Other Leather and Allied Product Manufacturing' },
		{ key: '31699', value: 'Other Leather and Allied Product Manufacturing' },
		{ key: '316992', value: 'Women\'s Handbag and Purse Manufacturing' },
		{ key: '316998', value: 'All Other Leather Good and Allied Product Manufacturing' },
		{ key: '321', value: 'Wood Product Manufacturing' },
		{ key: '3211', value: 'Sawmills and Wood Preservation' },
		{ key: '32111', value: 'Sawmills and Wood Preservation' },
		{ key: '321113', value: 'Sawmills' },
		{ key: '321114', value: 'Wood Preservation' },
		{ key: '3212', value: 'Veneer, Plywood, and Engineered Wood Product Manufacturing' },
		{ key: '32121', value: 'Veneer, Plywood, and Engineered Wood Product Manufacturing' },
		{ key: '321211', value: 'Hardwood Veneer and Plywood Manufacturing' },
		{ key: '321212', value: 'Softwood Veneer and Plywood Manufacturing' },
		{ key: '321213', value: 'Engineered Wood Member (except Truss) Manufacturing' },
		{ key: '321214', value: 'Truss Manufacturing' },
		{ key: '321219', value: 'Reconstituted Wood Product Manufacturing' },
		{ key: '3219', value: 'Other Wood Product Manufacturing' },
		{ key: '32191', value: 'Millwork' },
		{ key: '321911', value: 'Wood Window and Door Manufacturing' },
		{ key: '321912', value: 'Cut Stock, Resawing Lumber, and Planing' },
		{ key: '321918', value: 'Other Millwork (including Flooring)' },
		{ key: '32192', value: 'Wood Container and Pallet Manufacturing' },
		{ key: '321920', value: 'Wood Container and Pallet Manufacturing' },
		{ key: '32199', value: 'All Other Wood Product Manufacturing' },
		{ key: '321991', value: 'Manufactured Home (Mobile Home) Manufacturing' },
		{ key: '321992', value: 'Prefabricated Wood Building Manufacturing' },
		{ key: '321999', value: 'All Other Miscellaneous Wood Product Manufacturing' },
		{ key: '322', value: 'Paper Manufacturing' },
		{ key: '3221', value: 'Pulp, Paper, and Paperboard Mills' },
		{ key: '32211', value: 'Pulp Mills' },
		{ key: '322110', value: 'Pulp Mills' },
		{ key: '32212', value: 'Paper Mills' },
		{ key: '322121', value: 'Paper (except Newsprint) Mills' },
		{ key: '322122', value: 'Newsprint Mills' },
		{ key: '32213', value: 'Paperboard Mills' },
		{ key: '322130', value: 'Paperboard Mills' },
		{ key: '3222', value: 'Converted Paper Product Manufacturing' },
		{ key: '32221', value: 'Paperboard Container Manufacturing' },
		{ key: '322211', value: 'Corrugated and Solid Fiber Box Manufacturing' },
		{ key: '322212', value: 'Folding Paperboard Box Manufacturing' },
		{ key: '322219', value: 'Other Paperboard Container Manufacturing' },
		{ key: '32222', value: 'Paper Bag and Coated and Treated Paper Manufacturing' },
		{ key: '322220', value: 'Paper Bag and Coated and Treated Paper Manufacturing' },
		{ key: '32223', value: 'Stationery Product Manufacturing' },
		{ key: '322230', value: 'Stationery Product Manufacturing' },
		{ key: '32229', value: 'Other Converted Paper Product Manufacturing' },
		{ key: '322291', value: 'Sanitary Paper Product Manufacturing' },
		{ key: '322299', value: 'All Other Converted Paper Product Manufacturing' },
		{ key: '323', value: 'Printing and Related Support Activities' },
		{ key: '3231', value: 'Printing and Related Support Activities' },
		{ key: '32311', value: 'Printing' },
		{ key: '323111', value: 'Commercial Printing (except Screen and Books)' },
		{ key: '323113', value: 'Commercial Screen Printing' },
		{ key: '323117', value: 'Books Printing' },
		{ key: '32312', value: 'Support Activities for Printing' },
		{ key: '323120', value: 'Support Activities for Printing' },
		{ key: '324', value: 'Petroleum and Coal Products Manufacturing' },
		{ key: '3241', value: 'Petroleum and Coal Products Manufacturing' },
		{ key: '32411', value: 'Petroleum Refineries' },
		{ key: '324110', value: 'Petroleum Refineries' },
		{ key: '32412', value: 'Asphalt Paving, Roofing, and Saturated Materials Manufacturing' },
		{ key: '324121', value: 'Asphalt Paving Mixture and Block Manufacturing' },
		{ key: '324122', value: 'Asphalt Shingle and Coating Materials Manufacturing' },
		{ key: '32419', value: 'Other Petroleum and Coal Products Manufacturing' },
		{ key: '324191', value: 'Petroleum Lubricating Oil and Grease Manufacturing' },
		{ key: '324199', value: 'All Other Petroleum and Coal Products Manufacturing' },
		{ key: '325', value: 'Chemical Manufacturing' },
		{ key: '3251', value: 'Basic Chemical Manufacturing' },
		{ key: '32511', value: 'Petrochemical Manufacturing' },
		{ key: '325110', value: 'Petrochemical Manufacturing' },
		{ key: '32512', value: 'Industrial Gas Manufacturing' },
		{ key: '325120', value: 'Industrial Gas Manufacturing' },
		{ key: '32513', value: 'Synthetic Dye and Pigment Manufacturing' },
		{ key: '325130', value: 'Synthetic Dye and Pigment Manufacturing' },
		{ key: '32518', value: 'Other Basic Inorganic Chemical Manufacturing' },
		{ key: '325180', value: 'Other Basic Inorganic Chemical Manufacturing' },
		{ key: '32519', value: 'Other Basic Organic Chemical Manufacturing' },
		{ key: '325193', value: 'Ethyl Alcohol Manufacturing' },
		{ key: '325194', value: 'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing' },
		{ key: '325199', value: 'All Other Basic Organic Chemical Manufacturing' },
		{ key: '3252', value: 'Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing' },
		{ key: '32521', value: 'Resin and Synthetic Rubber Manufacturing' },
		{ key: '325211', value: 'Plastics Material and Resin Manufacturing' },
		{ key: '325212', value: 'Synthetic Rubber Manufacturing' },
		{ key: '32522', value: 'Artificial and Synthetic Fibers and Filaments Manufacturing' },
		{ key: '325220', value: 'Artificial and Synthetic Fibers and Filaments Manufacturing' },
		{ key: '3253', value: 'Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing' },
		{ key: '32531', value: 'Fertilizer Manufacturing' },
		{ key: '325311', value: 'Nitrogenous Fertilizer Manufacturing' },
		{ key: '325312', value: 'Phosphatic Fertilizer Manufacturing' },
		{ key: '325314', value: 'Fertilizer (Mixing Only) Manufacturing' },
		{ key: '32532', value: 'Pesticide and Other Agricultural Chemical Manufacturing' },
		{ key: '325320', value: 'Pesticide and Other Agricultural Chemical Manufacturing' },
		{ key: '3254', value: 'Pharmaceutical and Medicine Manufacturing' },
		{ key: '32541', value: 'Pharmaceutical and Medicine Manufacturing' },
		{ key: '325411', value: 'Medicinal and Botanical Manufacturing' },
		{ key: '325412', value: 'Pharmaceutical Preparation Manufacturing' },
		{ key: '325413', value: 'In-Vitro Diagnostic Substance Manufacturing' },
		{ key: '325414', value: 'Biological Product (except Diagnostic) Manufacturing' },
		{ key: '3255', value: 'Paint, Coating, and Adhesive Manufacturing' },
		{ key: '32551', value: 'Paint and Coating Manufacturing' },
		{ key: '325510', value: 'Paint and Coating Manufacturing' },
		{ key: '32552', value: 'Adhesive Manufacturing' },
		{ key: '325520', value: 'Adhesive Manufacturing' },
		{ key: '3256', value: 'Soap, Cleaning Compound, and Toilet Preparation Manufacturing' },
		{ key: '32561', value: 'Soap and Cleaning Compound Manufacturing' },
		{ key: '325611', value: 'Soap and Other Detergent Manufacturing' },
		{ key: '325612', value: 'Polish and Other Sanitation Good Manufacturing' },
		{ key: '325613', value: 'Surface Active Agent Manufacturing' },
		{ key: '32562', value: 'Toilet Preparation Manufacturing' },
		{ key: '325620', value: 'Toilet Preparation Manufacturing' },
		{ key: '3259', value: 'Other Chemical Product and Preparation Manufacturing' },
		{ key: '32591', value: 'Printing Ink Manufacturing' },
		{ key: '325910', value: 'Printing Ink Manufacturing' },
		{ key: '32592', value: 'Explosives Manufacturing' },
		{ key: '325920', value: 'Explosives Manufacturing' },
		{ key: '32599', value: 'All Other Chemical Product and Preparation Manufacturing' },
		{ key: '325991', value: 'Custom Compounding of Purchased Resins' },
		{ key: '325992', value: 'Photographic Film, Paper, Plate, and Chemical Manufacturing' },
		{ key: '325998', value: 'All Other Miscellaneous Chemical Product and Preparation Manufacturing' },
		{ key: '326', value: 'Plastics and Rubber Products Manufacturing' },
		{ key: '3261', value: 'Plastics Product Manufacturing' },
		{ key: '32611', value: 'Plastics Packaging Materials and Unlaminated Film and Sheet Manufacturing' },
		{ key: '326111', value: 'Plastics Bag and Pouch Manufacturing' },
		{ key: '326112', value: 'Plastics Packaging Film and Sheet (including Laminated) Manufacturing' },
		{ key: '326113', value: 'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing' },
		{ key: '32612', value: 'Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing' },
		{ key: '326121', value: 'Unlaminated Plastics Profile Shape Manufacturing' },
		{ key: '326122', value: 'Plastics Pipe and Pipe Fitting Manufacturing' },
		{ key: '32613', value: 'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing' },
		{ key: '326130', value: 'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing' },
		{ key: '32614', value: 'Polystyrene Foam Product Manufacturing' },
		{ key: '326140', value: 'Polystyrene Foam Product Manufacturing' },
		{ key: '32615', value: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing' },
		{ key: '326150', value: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing' },
		{ key: '32616', value: 'Plastics Bottle Manufacturing' },
		{ key: '326160', value: 'Plastics Bottle Manufacturing' },
		{ key: '32619', value: 'Other Plastics Product Manufacturing' },
		{ key: '326191', value: 'Plastics Plumbing Fixture Manufacturing' },
		{ key: '326199', value: 'All Other Plastics Product Manufacturing' },
		{ key: '3262', value: 'Rubber Product Manufacturing' },
		{ key: '32621', value: 'Tire Manufacturing' },
		{ key: '326211', value: 'Tire Manufacturing (except Retreading)' },
		{ key: '326212', value: 'Tire Retreading' },
		{ key: '32622', value: 'Rubber and Plastics Hoses and Belting Manufacturing' },
		{ key: '326220', value: 'Rubber and Plastics Hoses and Belting Manufacturing' },
		{ key: '32629', value: 'Other Rubber Product Manufacturing' },
		{ key: '326291', value: 'Rubber Product Manufacturing for Mechanical Use' },
		{ key: '326299', value: 'All Other Rubber Product Manufacturing' },
		{ key: '327', value: 'Nonmetallic Mineral Product Manufacturing' },
		{ key: '3271', value: 'Clay Product and Refractory Manufacturing' },
		{ key: '32711', value: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing' },
		{ key: '327110', value: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing' },
		{ key: '32712', value: 'Clay Building Material and Refractories Manufacturing' },
		{ key: '327120', value: 'Clay Building Material and Refractories Manufacturing' },
		{ key: '3272', value: 'Glass and Glass Product Manufacturing' },
		{ key: '32721', value: 'Glass and Glass Product Manufacturing' },
		{ key: '327211', value: 'Flat Glass Manufacturing' },
		{ key: '327212', value: 'Other Pressed and Blown Glass and Glassware Manufacturing' },
		{ key: '327213', value: 'Glass Container Manufacturing' },
		{ key: '327215', value: 'Glass Product Manufacturing Made of Purchased Glass' },
		{ key: '3273', value: 'Cement and Concrete Product Manufacturing' },
		{ key: '32731', value: 'Cement Manufacturing' },
		{ key: '327310', value: 'Cement Manufacturing' },
		{ key: '32732', value: 'Ready-Mix Concrete Manufacturing' },
		{ key: '327320', value: 'Ready-Mix Concrete Manufacturing' },
		{ key: '32733', value: 'Concrete Pipe, Brick, and Block Manufacturing' },
		{ key: '327331', value: 'Concrete Block and Brick Manufacturing' },
		{ key: '327332', value: 'Concrete Pipe Manufacturing' },
		{ key: '32739', value: 'Other Concrete Product Manufacturing' },
		{ key: '327390', value: 'Other Concrete Product Manufacturing' },
		{ key: '3274', value: 'Lime and Gypsum Product Manufacturing' },
		{ key: '32741', value: 'Lime Manufacturing' },
		{ key: '327410', value: 'Lime Manufacturing' },
		{ key: '32742', value: 'Gypsum Product Manufacturing' },
		{ key: '327420', value: 'Gypsum Product Manufacturing' },
		{ key: '3279', value: 'Other Nonmetallic Mineral Product Manufacturing' },
		{ key: '32791', value: 'Abrasive Product Manufacturing' },
		{ key: '327910', value: 'Abrasive Product Manufacturing' },
		{ key: '32799', value: 'All Other Nonmetallic Mineral Product Manufacturing' },
		{ key: '327991', value: 'Cut Stone and Stone Product Manufacturing' },
		{ key: '327992', value: 'Ground or Treated Mineral and Earth Manufacturing' },
		{ key: '327993', value: 'Mineral Wool Manufacturing' },
		{ key: '327999', value: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing' },
		{ key: '331', value: 'Primary Metal Manufacturing' },
		{ key: '3311', value: 'Iron and Steel Mills and Ferroalloy Manufacturing' },
		{ key: '33111', value: 'Iron and Steel Mills and Ferroalloy Manufacturing' },
		{ key: '331110', value: 'Iron and Steel Mills and Ferroalloy Manufacturing' },
		{ key: '3312', value: 'Steel Product Manufacturing from Purchased Steel' },
		{ key: '33121', value: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel' },
		{ key: '331210', value: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel' },
		{ key: '33122', value: 'Rolling and Drawing of Purchased Steel' },
		{ key: '331221', value: 'Rolled Steel Shape Manufacturing' },
		{ key: '331222', value: 'Steel Wire Drawing' },
		{ key: '3313', value: 'Alumina and Aluminum Production and Processing' },
		{ key: '33131', value: 'Alumina and Aluminum Production and Processing' },
		{ key: '331313', value: 'Alumina Refining and Primary Aluminum Production' },
		{ key: '331314', value: 'Secondary Smelting and Alloying of Aluminum' },
		{ key: '331315', value: 'Aluminum Sheet, Plate, and Foil Manufacturing' },
		{ key: '331318', value: 'Other Aluminum Rolling, Drawing, and Extruding' },
		{ key: '3314', value: 'Nonferrous Metal (except Aluminum) Production and Processing' },
		{ key: '33141', value: 'Nonferrous Metal (except Aluminum) Smelting and Refining' },
		{ key: '331410', value: 'Nonferrous Metal (except Aluminum) Smelting and Refining' },
		{ key: '33142', value: 'Copper Rolling, Drawing, Extruding, and Alloying' },
		{ key: '331420', value: 'Copper Rolling, Drawing, Extruding, and Alloying' },
		{ key: '33149', value: 'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and Alloying' },
		{ key: '331491', value: 'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding' },
		{ key: '331492', value: 'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)' },
		{ key: '3315', value: 'Foundries' },
		{ key: '33151', value: 'Ferrous Metal Foundries' },
		{ key: '331511', value: 'Iron Foundries' },
		{ key: '331512', value: 'Steel Investment Foundries' },
		{ key: '331513', value: 'Steel Foundries (except Investment)' },
		{ key: '33152', value: 'Nonferrous Metal Foundries' },
		{ key: '331523', value: 'Nonferrous Metal Die-Casting Foundries' },
		{ key: '331524', value: 'Aluminum Foundries (except Die-Casting)' },
		{ key: '331529', value: 'Other Nonferrous Metal Foundries (except Die-Casting)' },
		{ key: '332', value: 'Fabricated Metal Product Manufacturing' },
		{ key: '3321', value: 'Forging and Stamping' },
		{ key: '33211', value: 'Forging and Stamping' },
		{ key: '332111', value: 'Iron and Steel Forging' },
		{ key: '332112', value: 'Nonferrous Forging' },
		{ key: '332114', value: 'Custom Roll Forming' },
		{ key: '332117', value: 'Powder Metallurgy Part Manufacturing' },
		{ key: '332119', value: 'Metal Crown, Closure, and Other Metal Stamping (except Automotive)' },
		{ key: '3322', value: 'Cutlery and Handtool Manufacturing' },
		{ key: '33221', value: 'Cutlery and Handtool Manufacturing' },
		{ key: '332215', value: 'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing' },
		{ key: '332216', value: 'Saw Blade and Handtool Manufacturing' },
		{ key: '3323', value: 'Architectural and Structural Metals Manufacturing' },
		{ key: '33231', value: 'Plate Work and Fabricated Structural Product Manufacturing' },
		{ key: '332311', value: 'Prefabricated Metal Building and Component Manufacturing' },
		{ key: '332312', value: 'Fabricated Structural Metal Manufacturing' },
		{ key: '332313', value: 'Plate Work Manufacturing' },
		{ key: '33232', value: 'Ornamental and Architectural Metal Products Manufacturing' },
		{ key: '332321', value: 'Metal Window and Door Manufacturing' },
		{ key: '332322', value: 'Sheet Metal Work Manufacturing' },
		{ key: '332323', value: 'Ornamental and Architectural Metal Work Manufacturing' },
		{ key: '3324', value: 'Boiler, Tank, and Shipping Container Manufacturing' },
		{ key: '33241', value: 'Power Boiler and Heat Exchanger Manufacturing' },
		{ key: '332410', value: 'Power Boiler and Heat Exchanger Manufacturing' },
		{ key: '33242', value: 'Metal Tank (Heavy Gauge) Manufacturing' },
		{ key: '332420', value: 'Metal Tank (Heavy Gauge) Manufacturing' },
		{ key: '33243', value: 'Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing' },
		{ key: '332431', value: 'Metal Can Manufacturing' },
		{ key: '332439', value: 'Other Metal Container Manufacturing' },
		{ key: '3325', value: 'Hardware Manufacturing' },
		{ key: '33251', value: 'Hardware Manufacturing' },
		{ key: '332510', value: 'Hardware Manufacturing' },
		{ key: '3326', value: 'Spring and Wire Product Manufacturing' },
		{ key: '33261', value: 'Spring and Wire Product Manufacturing' },
		{ key: '332613', value: 'Spring Manufacturing' },
		{ key: '332618', value: 'Other Fabricated Wire Product Manufacturing' },
		{ key: '3327', value: 'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing' },
		{ key: '33271', value: 'Machine Shops' },
		{ key: '332710', value: 'Machine Shops' },
		{ key: '33272', value: 'Turned Product and Screw, Nut, and Bolt Manufacturing' },
		{ key: '332721', value: 'Precision Turned Product Manufacturing' },
		{ key: '332722', value: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing' },
		{ key: '3328', value: 'Coating, Engraving, Heat Treating, and Allied Activities' },
		{ key: '33281', value: 'Coating, Engraving, Heat Treating, and Allied Activities' },
		{ key: '332811', value: 'Metal Heat Treating' },
		{ key: '332812', value: 'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers' },
		{ key: '332813', value: 'Electroplating, Plating, Polishing, Anodizing, and Coloring' },
		{ key: '3329', value: 'Other Fabricated Metal Product Manufacturing' },
		{ key: '33291', value: 'Metal Valve Manufacturing' },
		{ key: '332911', value: 'Industrial Valve Manufacturing' },
		{ key: '332912', value: 'Fluid Power Valve and Hose Fitting Manufacturing' },
		{ key: '332913', value: 'Plumbing Fixture Fitting and Trim Manufacturing' },
		{ key: '332919', value: 'Other Metal Valve and Pipe Fitting Manufacturing' },
		{ key: '33299', value: 'All Other Fabricated Metal Product Manufacturing' },
		{ key: '332991', value: 'Ball and Roller Bearing Manufacturing' },
		{ key: '332992', value: 'Small Arms Ammunition Manufacturing' },
		{ key: '332993', value: 'Ammunition (except Small Arms) Manufacturing' },
		{ key: '332994', value: 'Small Arms, Ordnance, and Ordnance Accessories Manufacturing' },
		{ key: '332996', value: 'Fabricated Pipe and Pipe Fitting Manufacturing' },
		{ key: '332999', value: 'All Other Miscellaneous Fabricated Metal Product Manufacturing' },
		{ key: '333', value: 'Machinery Manufacturing' },
		{ key: '3331', value: 'Agriculture, Construction, and Mining Machinery Manufacturing' },
		{ key: '33311', value: 'Agricultural Implement Manufacturing' },
		{ key: '333111', value: 'Farm Machinery and Equipment Manufacturing' },
		{ key: '333112', value: 'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing' },
		{ key: '33312', value: 'Construction Machinery Manufacturing' },
		{ key: '333120', value: 'Construction Machinery Manufacturing' },
		{ key: '33313', value: 'Mining and Oil and Gas Field Machinery Manufacturing' },
		{ key: '333131', value: 'Mining Machinery and Equipment Manufacturing' },
		{ key: '333132', value: 'Oil and Gas Field Machinery and Equipment Manufacturing' },
		{ key: '3332', value: 'Industrial Machinery Manufacturing' },
		{ key: '33324', value: 'Industrial Machinery Manufacturing' },
		{ key: '333241', value: 'Food Product Machinery Manufacturing' },
		{ key: '333242', value: 'Semiconductor Machinery Manufacturing' },
		{ key: '333243', value: 'Sawmill, Woodworking, and Paper Machinery Manufacturing' },
		{ key: '333244', value: 'Printing Machinery and Equipment Manufacturing' },
		{ key: '333249', value: 'Other Industrial Machinery Manufacturing' },
		{ key: '3333', value: 'Commercial and Service Industry Machinery Manufacturing' },
		{ key: '33331', value: 'Commercial and Service Industry Machinery Manufacturing' },
		{ key: '333314', value: 'Optical Instrument and Lens Manufacturing' },
		{ key: '333316', value: 'Photographic and Photocopying Equipment Manufacturing' },
		{ key: '333318', value: 'Other Commercial and Service Industry Machinery Manufacturing' },
		{ key: '3334', value: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing' },
		{ key: '33341', value: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing' },
		{ key: '333413', value: 'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing' },
		{ key: '333414', value: 'Heating Equipment (except Warm Air Furnaces) Manufacturing' },
		{ key: '333415', value: 'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing' },
		{ key: '3335', value: 'Metalworking Machinery Manufacturing' },
		{ key: '33351', value: 'Metalworking Machinery Manufacturing' },
		{ key: '333511', value: 'Industrial Mold Manufacturing' },
		{ key: '333514', value: 'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing' },
		{ key: '333515', value: 'Cutting Tool and Machine Tool Accessory Manufacturing' },
		{ key: '333517', value: 'Machine Tool Manufacturing' },
		{ key: '333519', value: 'Rolling Mill and Other Metalworking Machinery Manufacturing' },
		{ key: '3336', value: 'Engine, Turbine, and Power Transmission Equipment Manufacturing' },
		{ key: '33361', value: 'Engine, Turbine, and Power Transmission Equipment Manufacturing' },
		{ key: '333611', value: 'Turbine and Turbine Generator Set Units Manufacturing' },
		{ key: '333612', value: 'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing' },
		{ key: '333613', value: 'Mechanical Power Transmission Equipment Manufacturing' },
		{ key: '333618', value: 'Other Engine Equipment Manufacturing' },
		{ key: '3339', value: 'Other General Purpose Machinery Manufacturing' },
		{ key: '33391', value: 'Pump and Compressor Manufacturing' },
		{ key: '333912', value: 'Air and Gas Compressor Manufacturing' },
		{ key: '333914', value: 'Measuring, Dispensing, and Other Pumping Equipment Manufacturing' },
		{ key: '33392', value: 'Material Handling Equipment Manufacturing' },
		{ key: '333921', value: 'Elevator and Moving Stairway Manufacturing' },
		{ key: '333922', value: 'Conveyor and Conveying Equipment Manufacturing' },
		{ key: '333923', value: 'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing' },
		{ key: '333924', value: 'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing' },
		{ key: '33399', value: 'All Other General Purpose Machinery Manufacturing' },
		{ key: '333991', value: 'Power-Driven Handtool Manufacturing' },
		{ key: '333992', value: 'Welding and Soldering Equipment Manufacturing' },
		{ key: '333993', value: 'Packaging Machinery Manufacturing' },
		{ key: '333994', value: 'Industrial Process Furnace and Oven Manufacturing' },
		{ key: '333995', value: 'Fluid Power Cylinder and Actuator Manufacturing' },
		{ key: '333996', value: 'Fluid Power Pump and Motor Manufacturing' },
		{ key: '333997', value: 'Scale and Balance Manufacturing' },
		{ key: '333999', value: 'All Other Miscellaneous General Purpose Machinery Manufacturing' },
		{ key: '334', value: 'Computer and Electronic Product Manufacturing' },
		{ key: '3341', value: 'Computer and Peripheral Equipment Manufacturing' },
		{ key: '33411', value: 'Computer and Peripheral Equipment Manufacturing' },
		{ key: '334111', value: 'Electronic Computer Manufacturing' },
		{ key: '334112', value: 'Computer Storage Device Manufacturing' },
		{ key: '334118', value: 'Computer Terminal and Other Computer Peripheral Equipment Manufacturing' },
		{ key: '3342', value: 'Communications Equipment Manufacturing' },
		{ key: '33421', value: 'Telephone Apparatus Manufacturing' },
		{ key: '334210', value: 'Telephone Apparatus Manufacturing' },
		{ key: '33422', value: 'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing' },
		{ key: '334220', value: 'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing' },
		{ key: '33429', value: 'Other Communications Equipment Manufacturing' },
		{ key: '334290', value: 'Other Communications Equipment Manufacturing' },
		{ key: '3343', value: 'Audio and Video Equipment Manufacturing' },
		{ key: '33431', value: 'Audio and Video Equipment Manufacturing' },
		{ key: '334310', value: 'Audio and Video Equipment Manufacturing' },
		{ key: '3344', value: 'Semiconductor and Other Electronic Component Manufacturing' },
		{ key: '33441', value: 'Semiconductor and Other Electronic Component Manufacturing' },
		{ key: '334412', value: 'Bare Printed Circuit Board Manufacturing' },
		{ key: '334413', value: 'Semiconductor and Related Device Manufacturing' },
		{ key: '334416', value: 'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing' },
		{ key: '334417', value: 'Electronic Connector Manufacturing' },
		{ key: '334418', value: 'Printed Circuit Assembly (Electronic Assembly) Manufacturing' },
		{ key: '334419', value: 'Other Electronic Component Manufacturing' },
		{ key: '3345', value: 'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing' },
		{ key: '33451', value: 'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing' },
		{ key: '334510', value: 'Electromedical and Electrotherapeutic Apparatus Manufacturing' },
		{ key: '334511', value: 'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing' },
		{ key: '334512', value: 'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use' },
		{ key: '334513', value: 'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables' },
		{ key: '334514', value: 'Totalizing Fluid Meter and Counting Device Manufacturing' },
		{ key: '334515', value: 'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals' },
		{ key: '334516', value: 'Analytical Laboratory Instrument Manufacturing' },
		{ key: '334517', value: 'Irradiation Apparatus Manufacturing' },
		{ key: '334519', value: 'Other Measuring and Controlling Device Manufacturing' },
		{ key: '3346', value: 'Manufacturing and Reproducing Magnetic and Optical Media' },
		{ key: '33461', value: 'Manufacturing and Reproducing Magnetic and Optical Media' },
		{ key: '334613', value: 'Blank Magnetic and Optical Recording Media Manufacturing' },
		{ key: '334614', value: 'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing' },
		{ key: '335', value: 'Electrical Equipment, Appliance, and Component Manufacturing' },
		{ key: '3351', value: 'Electric Lighting Equipment Manufacturing' },
		{ key: '33511', value: 'Electric Lamp Bulb and Part Manufacturing' },
		{ key: '335110', value: 'Electric Lamp Bulb and Part Manufacturing' },
		{ key: '33512', value: 'Lighting Fixture Manufacturing' },
		{ key: '335121', value: 'Residential Electric Lighting Fixture Manufacturing' },
		{ key: '335122', value: 'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing' },
		{ key: '335129', value: 'Other Lighting Equipment Manufacturing' },
		{ key: '3352', value: 'Household Appliance Manufacturing' },
		{ key: '33521', value: 'Small Electrical Appliance Manufacturing' },
		{ key: '335210', value: 'Small Electrical Appliance Manufacturing' },
		{ key: '33522', value: 'Major Household Appliance Manufacturing' },
		{ key: '335220', value: 'Major Household Appliance Manufacturing' },
		{ key: '3353', value: 'Electrical Equipment Manufacturing' },
		{ key: '33531', value: 'Electrical Equipment Manufacturing' },
		{ key: '335311', value: 'Power, Distribution, and Specialty Transformer Manufacturing' },
		{ key: '335312', value: 'Motor and Generator Manufacturing' },
		{ key: '335313', value: 'Switchgear and Switchboard Apparatus Manufacturing' },
		{ key: '335314', value: 'Relay and Industrial Control Manufacturing' },
		{ key: '3359', value: 'Other Electrical Equipment and Component Manufacturing' },
		{ key: '33591', value: 'Battery Manufacturing' },
		{ key: '335911', value: 'Storage Battery Manufacturing' },
		{ key: '335912', value: 'Primary Battery Manufacturing' },
		{ key: '33592', value: 'Communication and Energy Wire and Cable Manufacturing' },
		{ key: '335921', value: 'Fiber Optic Cable Manufacturing' },
		{ key: '335929', value: 'Other Communication and Energy Wire Manufacturing' },
		{ key: '33593', value: 'Wiring Device Manufacturing' },
		{ key: '335931', value: 'Current-Carrying Wiring Device Manufacturing' },
		{ key: '335932', value: 'Noncurrent-Carrying Wiring Device Manufacturing' },
		{ key: '33599', value: 'All Other Electrical Equipment and Component Manufacturing' },
		{ key: '335991', value: 'Carbon and Graphite Product Manufacturing' },
		{ key: '335999', value: 'All Other Miscellaneous Electrical Equipment and Component Manufacturing' },
		{ key: '336', value: 'Transportation Equipment Manufacturing' },
		{ key: '3361', value: 'Motor Vehicle Manufacturing' },
		{ key: '33611', value: 'Automobile and Light Duty Motor Vehicle Manufacturing' },
		{ key: '336111', value: 'Automobile Manufacturing' },
		{ key: '336112', value: 'Light Truck and Utility Vehicle Manufacturing' },
		{ key: '33612', value: 'Heavy Duty Truck Manufacturing' },
		{ key: '336120', value: 'Heavy Duty Truck Manufacturing' },
		{ key: '3362', value: 'Motor Vehicle Body and Trailer Manufacturing' },
		{ key: '33621', value: 'Motor Vehicle Body and Trailer Manufacturing' },
		{ key: '336211', value: 'Motor Vehicle Body Manufacturing' },
		{ key: '336212', value: 'Truck Trailer Manufacturing' },
		{ key: '336213', value: 'Motor Home Manufacturing' },
		{ key: '336214', value: 'Travel Trailer and Camper Manufacturing' },
		{ key: '3363', value: 'Motor Vehicle Parts Manufacturing' },
		{ key: '33631', value: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing' },
		{ key: '336310', value: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing' },
		{ key: '33632', value: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing' },
		{ key: '336320', value: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing' },
		{ key: '33633', value: 'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing' },
		{ key: '336330', value: 'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing' },
		{ key: '33634', value: 'Motor Vehicle Brake System Manufacturing' },
		{ key: '336340', value: 'Motor Vehicle Brake System Manufacturing' },
		{ key: '33635', value: 'Motor Vehicle Transmission and Power Train Parts Manufacturing' },
		{ key: '336350', value: 'Motor Vehicle Transmission and Power Train Parts Manufacturing' },
		{ key: '33636', value: 'Motor Vehicle Seating and Interior Trim Manufacturing' },
		{ key: '336360', value: 'Motor Vehicle Seating and Interior Trim Manufacturing' },
		{ key: '33637', value: 'Motor Vehicle Metal Stamping' },
		{ key: '336370', value: 'Motor Vehicle Metal Stamping' },
		{ key: '33639', value: 'Other Motor Vehicle Parts Manufacturing' },
		{ key: '336390', value: 'Other Motor Vehicle Parts Manufacturing' },
		{ key: '3364', value: 'Aerospace Product and Parts Manufacturing' },
		{ key: '33641', value: 'Aerospace Product and Parts Manufacturing' },
		{ key: '336411', value: 'Aircraft Manufacturing' },
		{ key: '336412', value: 'Aircraft Engine and Engine Parts Manufacturing' },
		{ key: '336413', value: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing' },
		{ key: '336414', value: 'Guided Missile and Space Vehicle Manufacturing' },
		{ key: '336415', value: 'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing' },
		{ key: '336419', value: 'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing' },
		{ key: '3365', value: 'Railroad Rolling Stock Manufacturing' },
		{ key: '33651', value: 'Railroad Rolling Stock Manufacturing' },
		{ key: '336510', value: 'Railroad Rolling Stock Manufacturing' },
		{ key: '3366', value: 'Ship and Boat Building' },
		{ key: '33661', value: 'Ship and Boat Building' },
		{ key: '336611', value: 'Ship Building and Repairing' },
		{ key: '336612', value: 'Boat Building' },
		{ key: '3369', value: 'Other Transportation Equipment Manufacturing' },
		{ key: '33699', value: 'Other Transportation Equipment Manufacturing' },
		{ key: '336991', value: 'Motorcycle, Bicycle, and Parts Manufacturing' },
		{ key: '336992', value: 'Military Armored Vehicle, Tank, and Tank Component Manufacturing' },
		{ key: '336999', value: 'All Other Transportation Equipment Manufacturing' },
		{ key: '337', value: 'Furniture and Related Product Manufacturing' },
		{ key: '3371', value: 'Household and Institutional Furniture and Kitchen Cabinet Manufacturing' },
		{ key: '33711', value: 'Wood Kitchen Cabinet and Countertop Manufacturing' },
		{ key: '337110', value: 'Wood Kitchen Cabinet and Countertop Manufacturing' },
		{ key: '33712', value: 'Household and Institutional Furniture Manufacturing' },
		{ key: '337121', value: 'Upholstered Household Furniture Manufacturing' },
		{ key: '337122', value: 'Nonupholstered Wood Household Furniture Manufacturing' },
		{ key: '337124', value: 'Metal Household Furniture Manufacturing' },
		{ key: '337125', value: 'Household Furniture (except Wood and Metal) Manufacturing' },
		{ key: '337127', value: 'Institutional Furniture Manufacturing' },
		{ key: '3372', value: 'Office Furniture (including Fixtures) Manufacturing' },
		{ key: '33721', value: 'Office Furniture (including Fixtures) Manufacturing' },
		{ key: '337211', value: 'Wood Office Furniture Manufacturing' },
		{ key: '337212', value: 'Custom Architectural Woodwork and Millwork Manufacturing' },
		{ key: '337214', value: 'Office Furniture (except Wood) Manufacturing' },
		{ key: '337215', value: 'Showcase, Partition, Shelving, and Locker Manufacturing' },
		{ key: '3379', value: 'Other Furniture Related Product Manufacturing' },
		{ key: '33791', value: 'Mattress Manufacturing' },
		{ key: '337910', value: 'Mattress Manufacturing' },
		{ key: '33792', value: 'Blind and Shade Manufacturing' },
		{ key: '337920', value: 'Blind and Shade Manufacturing' },
		{ key: '339', value: 'Miscellaneous Manufacturing' },
		{ key: '3391', value: 'Medical Equipment and Supplies Manufacturing' },
		{ key: '33911', value: 'Medical Equipment and Supplies Manufacturing' },
		{ key: '339112', value: 'Surgical and Medical Instrument Manufacturing' },
		{ key: '339113', value: 'Surgical Appliance and Supplies Manufacturing' },
		{ key: '339114', value: 'Dental Equipment and Supplies Manufacturing' },
		{ key: '339115', value: 'Ophthalmic Goods Manufacturing' },
		{ key: '339116', value: 'Dental Laboratories' },
		{ key: '3399', value: 'Other Miscellaneous Manufacturing' },
		{ key: '33991', value: 'Jewelry and Silverware Manufacturing' },
		{ key: '339910', value: 'Jewelry and Silverware Manufacturing' },
		{ key: '33992', value: 'Sporting and Athletic Goods Manufacturing' },
		{ key: '339920', value: 'Sporting and Athletic Goods Manufacturing' },
		{ key: '33993', value: 'Doll, Toy, and Game Manufacturing' },
		{ key: '339930', value: 'Doll, Toy, and Game Manufacturing' },
		{ key: '33994', value: 'Office Supplies (except Paper) Manufacturing' },
		{ key: '339940', value: 'Office Supplies (except Paper) Manufacturing' },
		{ key: '33995', value: 'Sign Manufacturing' },
		{ key: '339950', value: 'Sign Manufacturing' },
		{ key: '33999', value: 'All Other Miscellaneous Manufacturing' },
		{ key: '339991', value: 'Gasket, Packing, and Sealing Device Manufacturing' },
		{ key: '339992', value: 'Musical Instrument Manufacturing' },
		{ key: '339993', value: 'Fastener, Button, Needle, and Pin Manufacturing' },
		{ key: '339994', value: 'Broom, Brush, and Mop Manufacturing' },
		{ key: '339995', value: 'Burial Casket Manufacturing' },
		{ key: '339999', value: 'All Other Miscellaneous Manufacturing' },
		{ key: '42', value: 'Wholesale Trade' },
		{ key: '423', value: 'Merchant Wholesalers, Durable Goods' },
		{ key: '4231', value: 'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers' },
		{ key: '42311', value: 'Automobile and Other Motor Vehicle Merchant Wholesalers' },
		{ key: '423110', value: 'Automobile and Other Motor Vehicle Merchant Wholesalers' },
		{ key: '42312', value: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers' },
		{ key: '423120', value: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers' },
		{ key: '42313', value: 'Tire and Tube Merchant Wholesalers' },
		{ key: '423130', value: 'Tire and Tube Merchant Wholesalers' },
		{ key: '42314', value: 'Motor Vehicle Parts (Used) Merchant Wholesalers' },
		{ key: '423140', value: 'Motor Vehicle Parts (Used) Merchant Wholesalers' },
		{ key: '4232', value: 'Furniture and Home Furnishing Merchant Wholesalers' },
		{ key: '42321', value: 'Furniture Merchant Wholesalers' },
		{ key: '423210', value: 'Furniture Merchant Wholesalers' },
		{ key: '42322', value: 'Home Furnishing Merchant Wholesalers' },
		{ key: '423220', value: 'Home Furnishing Merchant Wholesalers' },
		{ key: '4233', value: 'Lumber and Other Construction Materials Merchant Wholesalers' },
		{ key: '42331', value: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers' },
		{ key: '423310', value: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers' },
		{ key: '42332', value: 'Brick, Stone, and Related Construction Material Merchant Wholesalers' },
		{ key: '423320', value: 'Brick, Stone, and Related Construction Material Merchant Wholesalers' },
		{ key: '42333', value: 'Roofing, Siding, and Insulation Material Merchant Wholesalers' },
		{ key: '423330', value: 'Roofing, Siding, and Insulation Material Merchant Wholesalers' },
		{ key: '42339', value: 'Other Construction Material Merchant Wholesalers' },
		{ key: '423390', value: 'Other Construction Material Merchant Wholesalers' },
		{ key: '4234', value: 'Professional and Commercial Equipment and Supplies Merchant Wholesalers' },
		{ key: '42341', value: 'Photographic Equipment and Supplies Merchant Wholesalers' },
		{ key: '423410', value: 'Photographic Equipment and Supplies Merchant Wholesalers' },
		{ key: '42342', value: 'Office Equipment Merchant Wholesalers' },
		{ key: '423420', value: 'Office Equipment Merchant Wholesalers' },
		{ key: '42343', value: 'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers' },
		{ key: '423430', value: 'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers' },
		{ key: '42344', value: 'Other Commercial Equipment Merchant Wholesalers' },
		{ key: '423440', value: 'Other Commercial Equipment Merchant Wholesalers' },
		{ key: '42345', value: 'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers' },
		{ key: '423450', value: 'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers' },
		{ key: '42346', value: 'Ophthalmic Goods Merchant Wholesalers' },
		{ key: '423460', value: 'Ophthalmic Goods Merchant Wholesalers' },
		{ key: '42349', value: 'Other Professional Equipment and Supplies Merchant Wholesalers' },
		{ key: '423490', value: 'Other Professional Equipment and Supplies Merchant Wholesalers' },
		{ key: '4235', value: 'Metal and Mineral (except Petroleum) Merchant Wholesalers' },
		{ key: '42351', value: 'Metal Service Centers and Other Metal Merchant Wholesalers' },
		{ key: '423510', value: 'Metal Service Centers and Other Metal Merchant Wholesalers' },
		{ key: '42352', value: 'Coal and Other Mineral and Ore Merchant Wholesalers' },
		{ key: '423520', value: 'Coal and Other Mineral and Ore Merchant Wholesalers' },
		{ key: '4236', value: 'Household Appliances and Electrical and Electronic Goods Merchant Wholesalers' },
		{ key: '42361', value: 'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers' },
		{ key: '423610', value: 'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers' },
		{ key: '42362', value: 'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers' },
		{ key: '423620', value: 'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers' },
		{ key: '42369', value: 'Other Electronic Parts and Equipment Merchant Wholesalers' },
		{ key: '423690', value: 'Other Electronic Parts and Equipment Merchant Wholesalers' },
		{ key: '4237', value: 'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers' },
		{ key: '42371', value: 'Hardware Merchant Wholesalers' },
		{ key: '423710', value: 'Hardware Merchant Wholesalers' },
		{ key: '42372', value: 'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers' },
		{ key: '423720', value: 'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers' },
		{ key: '42373', value: 'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers' },
		{ key: '423730', value: 'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers' },
		{ key: '42374', value: 'Refrigeration Equipment and Supplies Merchant Wholesalers' },
		{ key: '423740', value: 'Refrigeration Equipment and Supplies Merchant Wholesalers' },
		{ key: '4238', value: 'Machinery, Equipment, and Supplies Merchant Wholesalers' },
		{ key: '42381', value: 'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers' },
		{ key: '423810', value: 'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers' },
		{ key: '42382', value: 'Farm and Garden Machinery and Equipment Merchant Wholesalers' },
		{ key: '423820', value: 'Farm and Garden Machinery and Equipment Merchant Wholesalers' },
		{ key: '42383', value: 'Industrial Machinery and Equipment Merchant Wholesalers' },
		{ key: '423830', value: 'Industrial Machinery and Equipment Merchant Wholesalers' },
		{ key: '42384', value: 'Industrial Supplies Merchant Wholesalers' },
		{ key: '423840', value: 'Industrial Supplies Merchant Wholesalers' },
		{ key: '42385', value: 'Service Establishment Equipment and Supplies Merchant Wholesalers' },
		{ key: '423850', value: 'Service Establishment Equipment and Supplies Merchant Wholesalers' },
		{ key: '42386', value: 'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers' },
		{ key: '423860', value: 'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers' },
		{ key: '4239', value: 'Miscellaneous Durable Goods Merchant Wholesalers' },
		{ key: '42391', value: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers' },
		{ key: '423910', value: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers' },
		{ key: '42392', value: 'Toy and Hobby Goods and Supplies Merchant Wholesalers' },
		{ key: '423920', value: 'Toy and Hobby Goods and Supplies Merchant Wholesalers' },
		{ key: '42393', value: 'Recyclable Material Merchant Wholesalers' },
		{ key: '423930', value: 'Recyclable Material Merchant Wholesalers' },
		{ key: '42394', value: 'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers' },
		{ key: '423940', value: 'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers' },
		{ key: '42399', value: 'Other Miscellaneous Durable Goods Merchant Wholesalers' },
		{ key: '423990', value: 'Other Miscellaneous Durable Goods Merchant Wholesalers' },
		{ key: '424', value: 'Merchant Wholesalers, Nondurable Goods' },
		{ key: '4241', value: 'Paper and Paper Product Merchant Wholesalers' },
		{ key: '42411', value: 'Printing and Writing Paper Merchant Wholesalers' },
		{ key: '424110', value: 'Printing and Writing Paper Merchant Wholesalers' },
		{ key: '42412', value: 'Stationery and Office Supplies Merchant Wholesalers' },
		{ key: '424120', value: 'Stationery and Office Supplies Merchant Wholesalers' },
		{ key: '42413', value: 'Industrial and Personal Service Paper Merchant Wholesalers' },
		{ key: '424130', value: 'Industrial and Personal Service Paper Merchant Wholesalers' },
		{ key: '4242', value: 'Drugs and Druggists\' Sundries Merchant Wholesalers' },
		{ key: '42421', value: 'Drugs and Druggists\' Sundries Merchant Wholesalers' },
		{ key: '424210', value: 'Drugs and Druggists\' Sundries Merchant Wholesalers' },
		{ key: '4243', value: 'Apparel, Piece Goods, and Notions Merchant Wholesalers' },
		{ key: '42431', value: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers' },
		{ key: '424310', value: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers' },
		{ key: '42432', value: 'Men\'s and Boys\' Clothing and Furnishings Merchant Wholesalers' },
		{ key: '424320', value: 'Men\'s and Boys\' Clothing and Furnishings Merchant Wholesalers' },
		{ key: '42433', value: 'Women\'s, Children\'s, and Infants\' Clothing and Accessories Merchant Wholesalers' },
		{ key: '424330', value: 'Women\'s, Children\'s, and Infants\' Clothing and Accessories Merchant Wholesalers' },
		{ key: '42434', value: 'Footwear Merchant Wholesalers' },
		{ key: '424340', value: 'Footwear Merchant Wholesalers' },
		{ key: '4244', value: 'Grocery and Related Product Merchant Wholesalers' },
		{ key: '42441', value: 'General Line Grocery Merchant Wholesalers' },
		{ key: '424410', value: 'General Line Grocery Merchant Wholesalers' },
		{ key: '42442', value: 'Packaged Frozen Food Merchant Wholesalers' },
		{ key: '424420', value: 'Packaged Frozen Food Merchant Wholesalers' },
		{ key: '42443', value: 'Dairy Product (except Dried or Canned) Merchant Wholesalers' },
		{ key: '424430', value: 'Dairy Product (except Dried or Canned) Merchant Wholesalers' },
		{ key: '42444', value: 'Poultry and Poultry Product Merchant Wholesalers' },
		{ key: '424440', value: 'Poultry and Poultry Product Merchant Wholesalers' },
		{ key: '42445', value: 'Confectionery Merchant Wholesalers' },
		{ key: '424450', value: 'Confectionery Merchant Wholesalers' },
		{ key: '42446', value: 'Fish and Seafood Merchant Wholesalers' },
		{ key: '424460', value: 'Fish and Seafood Merchant Wholesalers' },
		{ key: '42447', value: 'Meat and Meat Product Merchant Wholesalers' },
		{ key: '424470', value: 'Meat and Meat Product Merchant Wholesalers' },
		{ key: '42448', value: 'Fresh Fruit and Vegetable Merchant Wholesalers' },
		{ key: '424480', value: 'Fresh Fruit and Vegetable Merchant Wholesalers' },
		{ key: '42449', value: 'Other Grocery and Related Products Merchant Wholesalers' },
		{ key: '424490', value: 'Other Grocery and Related Products Merchant Wholesalers' },
		{ key: '4245', value: 'Farm Product Raw Material Merchant Wholesalers' },
		{ key: '42451', value: 'Grain and Field Bean Merchant Wholesalers' },
		{ key: '424510', value: 'Grain and Field Bean Merchant Wholesalers' },
		{ key: '42452', value: 'Livestock Merchant Wholesalers' },
		{ key: '424520', value: 'Livestock Merchant Wholesalers' },
		{ key: '42459', value: 'Other Farm Product Raw Material Merchant Wholesalers' },
		{ key: '424590', value: 'Other Farm Product Raw Material Merchant Wholesalers' },
		{ key: '4246', value: 'Chemical and Allied Products Merchant Wholesalers' },
		{ key: '42461', value: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers' },
		{ key: '424610', value: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers' },
		{ key: '42469', value: 'Other Chemical and Allied Products Merchant Wholesalers' },
		{ key: '424690', value: 'Other Chemical and Allied Products Merchant Wholesalers' },
		{ key: '4247', value: 'Petroleum and Petroleum Products Merchant Wholesalers' },
		{ key: '42471', value: 'Petroleum Bulk Stations and Terminals' },
		{ key: '424710', value: 'Petroleum Bulk Stations and Terminals' },
		{ key: '42472', value: 'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)' },
		{ key: '424720', value: 'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)' },
		{ key: '4248', value: 'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers' },
		{ key: '42481', value: 'Beer and Ale Merchant Wholesalers' },
		{ key: '424810', value: 'Beer and Ale Merchant Wholesalers' },
		{ key: '42482', value: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers' },
		{ key: '424820', value: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers' },
		{ key: '4249', value: 'Miscellaneous Nondurable Goods Merchant Wholesalers' },
		{ key: '42491', value: 'Farm Supplies Merchant Wholesalers' },
		{ key: '424910', value: 'Farm Supplies Merchant Wholesalers' },
		{ key: '42492', value: 'Book, Periodical, and Newspaper Merchant Wholesalers' },
		{ key: '424920', value: 'Book, Periodical, and Newspaper Merchant Wholesalers' },
		{ key: '42493', value: 'Flower, Nursery Stock, and Florists\' Supplies Merchant Wholesalers' },
		{ key: '424930', value: 'Flower, Nursery Stock, and Florists\' Supplies Merchant Wholesalers' },
		{ key: '42494', value: 'Tobacco and Tobacco Product Merchant Wholesalers' },
		{ key: '424940', value: 'Tobacco and Tobacco Product Merchant Wholesalers' },
		{ key: '42495', value: 'Paint, Varnish, and Supplies Merchant Wholesalers' },
		{ key: '424950', value: 'Paint, Varnish, and Supplies Merchant Wholesalers' },
		{ key: '42499', value: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers' },
		{ key: '424990', value: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers' },
		{ key: '425', value: 'Wholesale Electronic Markets and Agents and Brokers' },
		{ key: '4251', value: 'Wholesale Electronic Markets and Agents and Brokers' },
		{ key: '42511', value: 'Business to Business Electronic Markets' },
		{ key: '425110', value: 'Business to Business Electronic Markets' },
		{ key: '42512', value: 'Wholesale Trade Agents and Brokers' },
		{ key: '425120', value: 'Wholesale Trade Agents and Brokers' },
		{ key: '44-45', value: 'Retail Trade' },
		{ key: '441', value: 'Motor Vehicle and Parts Dealers' },
		{ key: '4411', value: 'Automobile Dealers' },
		{ key: '44111', value: 'New Car Dealers' },
		{ key: '441110', value: 'New Car Dealers' },
		{ key: '44112', value: 'Used Car Dealers' },
		{ key: '441120', value: 'Used Car Dealers' },
		{ key: '4412', value: 'Other Motor Vehicle Dealers' },
		{ key: '44121', value: 'Recreational Vehicle Dealers' },
		{ key: '441210', value: 'Recreational Vehicle Dealers' },
		{ key: '44122', value: 'Motorcycle, Boat, and Other Motor Vehicle Dealers' },
		{ key: '441222', value: 'Boat Dealers' },
		{ key: '441228', value: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers' },
		{ key: '4413', value: 'Automotive Parts, Accessories, and Tire Stores' },
		{ key: '44131', value: 'Automotive Parts and Accessories Stores' },
		{ key: '441310', value: 'Automotive Parts and Accessories Stores' },
		{ key: '44132', value: 'Tire Dealers' },
		{ key: '441320', value: 'Tire Dealers' },
		{ key: '442', value: 'Furniture and Home Furnishings Stores' },
		{ key: '4421', value: 'Furniture Stores' },
		{ key: '44211', value: 'Furniture Stores' },
		{ key: '442110', value: 'Furniture Stores' },
		{ key: '4422', value: 'Home Furnishings Stores' },
		{ key: '44221', value: 'Floor Covering Stores' },
		{ key: '442210', value: 'Floor Covering Stores' },
		{ key: '44229', value: 'Other Home Furnishings Stores' },
		{ key: '442291', value: 'Window Treatment Stores' },
		{ key: '442299', value: 'All Other Home Furnishings Stores' },
		{ key: '443', value: 'Electronics and Appliance Stores' },
		{ key: '4431', value: 'Electronics and Appliance Stores' },
		{ key: '44314', value: 'Electronics and Appliance Stores' },
		{ key: '443141', value: 'Household Appliance Stores' },
		{ key: '443142', value: 'Electronics Stores' },
		{ key: '444', value: 'Building Material and Garden Equipment and Supplies Dealers' },
		{ key: '4441', value: 'Building Material and Supplies Dealers' },
		{ key: '44411', value: 'Home Centers' },
		{ key: '444110', value: 'Home Centers' },
		{ key: '44412', value: 'Paint and Wallpaper Stores' },
		{ key: '444120', value: 'Paint and Wallpaper Stores' },
		{ key: '44413', value: 'Hardware Stores' },
		{ key: '444130', value: 'Hardware Stores' },
		{ key: '44419', value: 'Other Building Material Dealers' },
		{ key: '444190', value: 'Other Building Material Dealers' },
		{ key: '4442', value: 'Lawn and Garden Equipment and Supplies Stores' },
		{ key: '44421', value: 'Outdoor Power Equipment Stores' },
		{ key: '444210', value: 'Outdoor Power Equipment Stores' },
		{ key: '44422', value: 'Nursery, Garden Center, and Farm Supply Stores' },
		{ key: '444220', value: 'Nursery, Garden Center, and Farm Supply Stores' },
		{ key: '445', value: 'Food and Beverage Stores' },
		{ key: '4451', value: 'Grocery Stores' },
		{ key: '44511', value: 'Supermarkets and Other Grocery (except Convenience) Stores' },
		{ key: '445110', value: 'Supermarkets and Other Grocery (except Convenience) Stores' },
		{ key: '44512', value: 'Convenience Stores' },
		{ key: '445120', value: 'Convenience Stores' },
		{ key: '4452', value: 'Specialty Food Stores' },
		{ key: '44521', value: 'Meat Markets' },
		{ key: '445210', value: 'Meat Markets' },
		{ key: '44522', value: 'Fish and Seafood Markets' },
		{ key: '445220', value: 'Fish and Seafood Markets' },
		{ key: '44523', value: 'Fruit and Vegetable Markets' },
		{ key: '445230', value: 'Fruit and Vegetable Markets' },
		{ key: '44529', value: 'Other Specialty Food Stores' },
		{ key: '445291', value: 'Baked Goods Stores' },
		{ key: '445292', value: 'Confectionery and Nut Stores' },
		{ key: '445299', value: 'All Other Specialty Food Stores' },
		{ key: '4453', value: 'Beer, Wine, and Liquor Stores' },
		{ key: '44531', value: 'Beer, Wine, and Liquor Stores' },
		{ key: '445310', value: 'Beer, Wine, and Liquor Stores' },
		{ key: '446', value: 'Health and Personal Care Stores' },
		{ key: '4461', value: 'Health and Personal Care Stores' },
		{ key: '44611', value: 'Pharmacies and Drug Stores' },
		{ key: '446110', value: 'Pharmacies and Drug Stores' },
		{ key: '44612', value: 'Cosmetics, Beauty Supplies, and Perfume Stores' },
		{ key: '446120', value: 'Cosmetics, Beauty Supplies, and Perfume Stores' },
		{ key: '44613', value: 'Optical Goods Stores' },
		{ key: '446130', value: 'Optical Goods Stores' },
		{ key: '44619', value: 'Other Health and Personal Care Stores' },
		{ key: '446191', value: 'Food (Health) Supplement Stores' },
		{ key: '446199', value: 'All Other Health and Personal Care Stores' },
		{ key: '447', value: 'Gasoline Stations' },
		{ key: '4471', value: 'Gasoline Stations' },
		{ key: '44711', value: 'Gasoline Stations with Convenience Stores' },
		{ key: '447110', value: 'Gasoline Stations with Convenience Stores' },
		{ key: '44719', value: 'Other Gasoline Stations' },
		{ key: '447190', value: 'Other Gasoline Stations' },
		{ key: '448', value: 'Clothing and Clothing Accessories Stores' },
		{ key: '4481', value: 'Clothing Stores' },
		{ key: '44811', value: 'Men\'s Clothing Stores' },
		{ key: '448110', value: 'Men\'s Clothing Stores' },
		{ key: '44812', value: 'Women\'s Clothing Stores' },
		{ key: '448120', value: 'Women\'s Clothing Stores' },
		{ key: '44813', value: 'Children\'s and Infants\' Clothing Stores' },
		{ key: '448130', value: 'Children\'s and Infants\' Clothing Stores' },
		{ key: '44814', value: 'Family Clothing Stores' },
		{ key: '448140', value: 'Family Clothing Stores' },
		{ key: '44815', value: 'Clothing Accessories Stores' },
		{ key: '448150', value: 'Clothing Accessories Stores' },
		{ key: '44819', value: 'Other Clothing Stores' },
		{ key: '448190', value: 'Other Clothing Stores' },
		{ key: '4482', value: 'Shoe Stores' },
		{ key: '44821', value: 'Shoe Stores' },
		{ key: '448210', value: 'Shoe Stores' },
		{ key: '4483', value: 'Jewelry, Luggage, and Leather Goods Stores' },
		{ key: '44831', value: 'Jewelry Stores' },
		{ key: '448310', value: 'Jewelry Stores' },
		{ key: '44832', value: 'Luggage and Leather Goods Stores' },
		{ key: '448320', value: 'Luggage and Leather Goods Stores' },
		{ key: '451', value: 'Sporting Goods, Hobby, Musical Instrument, and Book Stores' },
		{ key: '4511', value: 'Sporting Goods, Hobby, and Musical Instrument Stores' },
		{ key: '45111', value: 'Sporting Goods Stores' },
		{ key: '451110', value: 'Sporting Goods Stores' },
		{ key: '45112', value: 'Hobby, Toy, and Game Stores' },
		{ key: '451120', value: 'Hobby, Toy, and Game Stores' },
		{ key: '45113', value: 'Sewing, Needlework, and Piece Goods Stores' },
		{ key: '451130', value: 'Sewing, Needlework, and Piece Goods Stores' },
		{ key: '45114', value: 'Musical Instrument and Supplies Stores' },
		{ key: '451140', value: 'Musical Instrument and Supplies Stores' },
		{ key: '4512', value: 'Book Stores and News Dealers' },
		{ key: '45121', value: 'Book Stores and News Dealers' },
		{ key: '451211', value: 'Book Stores' },
		{ key: '451212', value: 'News Dealers and Newsstands' },
		{ key: '452', value: 'General Merchandise Stores' },
		{ key: '4522', value: 'Department Stores' },
		{ key: '45221', value: 'Department Stores' },
		{ key: '452210', value: 'Department Stores' },
		{ key: '4523', value: 'General Merchandise Stores, including Warehouse Clubs and Supercenters' },
		{ key: '45231', value: 'General Merchandise Stores, including Warehouse Clubs and Supercenters' },
		{ key: '452311', value: 'Warehouse Clubs and Supercenters' },
		{ key: '452319', value: 'All Other General Merchandise Stores' },
		{ key: '453', value: 'Miscellaneous Store Retailers' },
		{ key: '4531', value: 'Florists' },
		{ key: '45311', value: 'Florists' },
		{ key: '453110', value: 'Florists' },
		{ key: '4532', value: 'Office Supplies, Stationery, and Gift Stores' },
		{ key: '45321', value: 'Office Supplies and Stationery Stores' },
		{ key: '453210', value: 'Office Supplies and Stationery Stores' },
		{ key: '45322', value: 'Gift, Novelty, and Souvenir Stores' },
		{ key: '453220', value: 'Gift, Novelty, and Souvenir Stores' },
		{ key: '4533', value: 'Used Merchandise Stores' },
		{ key: '45331', value: 'Used Merchandise Stores' },
		{ key: '453310', value: 'Used Merchandise Stores' },
		{ key: '4539', value: 'Other Miscellaneous Store Retailers' },
		{ key: '45391', value: 'Pet and Pet Supplies Stores' },
		{ key: '453910', value: 'Pet and Pet Supplies Stores' },
		{ key: '45392', value: 'Art Dealers' },
		{ key: '453920', value: 'Art Dealers' },
		{ key: '45393', value: 'Manufactured (Mobile) Home Dealers' },
		{ key: '453930', value: 'Manufactured (Mobile) Home Dealers' },
		{ key: '45399', value: 'All Other Miscellaneous Store Retailers' },
		{ key: '453991', value: 'Tobacco Stores' },
		{ key: '453998', value: 'All Other Miscellaneous Store Retailers (except Tobacco Stores)' },
		{ key: '454', value: 'Nonstore Retailers' },
		{ key: '4541', value: 'Electronic Shopping and Mail-Order Houses' },
		{ key: '45411', value: 'Electronic Shopping and Mail-Order Houses' },
		{ key: '454110', value: 'Electronic Shopping and Mail-Order Houses' },
		{ key: '4542', value: 'Vending Machine Operators' },
		{ key: '45421', value: 'Vending Machine Operators' },
		{ key: '454210', value: 'Vending Machine Operators' },
		{ key: '4543', value: 'Direct Selling Establishments' },
		{ key: '45431', value: 'Fuel Dealers' },
		{ key: '454310', value: 'Fuel Dealers' },
		{ key: '45439', value: 'Other Direct Selling Establishments' },
		{ key: '454390', value: 'Other Direct Selling Establishments' },
		{ key: '48-49', value: 'Transportation and Warehousing' },
		{ key: '481', value: 'Air Transportation' },
		{ key: '4811', value: 'Scheduled Air Transportation' },
		{ key: '48111', value: 'Scheduled Air Transportation' },
		{ key: '481111', value: 'Scheduled Passenger Air Transportation' },
		{ key: '481112', value: 'Scheduled Freight Air Transportation' },
		{ key: '4812', value: 'Nonscheduled Air Transportation' },
		{ key: '48121', value: 'Nonscheduled Air Transportation' },
		{ key: '481211', value: 'Nonscheduled Chartered Passenger Air Transportation' },
		{ key: '481212', value: 'Nonscheduled Chartered Freight Air Transportation' },
		{ key: '481219', value: 'Other Nonscheduled Air Transportation' },
		{ key: '482', value: 'Rail Transportation' },
		{ key: '4821', value: 'Rail Transportation' },
		{ key: '48211', value: 'Rail Transportation' },
		{ key: '482111', value: 'Line-Haul Railroads' },
		{ key: '482112', value: 'Short Line Railroads' },
		{ key: '483', value: 'Water Transportation' },
		{ key: '4831', value: 'Deep Sea, Coastal, and Great Lakes Water Transportation' },
		{ key: '48311', value: 'Deep Sea, Coastal, and Great Lakes Water Transportation' },
		{ key: '483111', value: 'Deep Sea Freight Transportation' },
		{ key: '483112', value: 'Deep Sea Passenger Transportation' },
		{ key: '483113', value: 'Coastal and Great Lakes Freight Transportation' },
		{ key: '483114', value: 'Coastal and Great Lakes Passenger Transportation' },
		{ key: '4832', value: 'Inland Water Transportation' },
		{ key: '48321', value: 'Inland Water Transportation' },
		{ key: '483211', value: 'Inland Water Freight Transportation' },
		{ key: '483212', value: 'Inland Water Passenger Transportation' },
		{ key: '484', value: 'Truck Transportation' },
		{ key: '4841', value: 'General Freight Trucking' },
		{ key: '48411', value: 'General Freight Trucking, Local' },
		{ key: '484110', value: 'General Freight Trucking, Local' },
		{ key: '48412', value: 'General Freight Trucking, Long-Distance' },
		{ key: '484121', value: 'General Freight Trucking, Long-Distance, Truckload' },
		{ key: '484122', value: 'General Freight Trucking, Long-Distance, Less Than Truckload' },
		{ key: '4842', value: 'Specialized Freight Trucking' },
		{ key: '48421', value: 'Used Household and Office Goods Moving' },
		{ key: '484210', value: 'Used Household and Office Goods Moving' },
		{ key: '48422', value: 'Specialized Freight (except Used Goods) Trucking, Local' },
		{ key: '484220', value: 'Specialized Freight (except Used Goods) Trucking, Local' },
		{ key: '48423', value: 'Specialized Freight (except Used Goods) Trucking, Long-Distance' },
		{ key: '484230', value: 'Specialized Freight (except Used Goods) Trucking, Long-Distance' },
		{ key: '485', value: 'Transit and Ground Passenger Transportation' },
		{ key: '4851', value: 'Urban Transit Systems' },
		{ key: '48511', value: 'Urban Transit Systems' },
		{ key: '485111', value: 'Mixed Mode Transit Systems' },
		{ key: '485112', value: 'Commuter Rail Systems' },
		{ key: '485113', value: 'Bus and Other Motor Vehicle Transit Systems' },
		{ key: '485119', value: 'Other Urban Transit Systems' },
		{ key: '4852', value: 'Interurban and Rural Bus Transportation' },
		{ key: '48521', value: 'Interurban and Rural Bus Transportation' },
		{ key: '485210', value: 'Interurban and Rural Bus Transportation' },
		{ key: '4853', value: 'Taxi and Limousine Service' },
		{ key: '48531', value: 'Taxi Service' },
		{ key: '485310', value: 'Taxi Service' },
		{ key: '48532', value: 'Limousine Service' },
		{ key: '485320', value: 'Limousine Service' },
		{ key: '4854', value: 'School and Employee Bus Transportation' },
		{ key: '48541', value: 'School and Employee Bus Transportation' },
		{ key: '485410', value: 'School and Employee Bus Transportation' },
		{ key: '4855', value: 'Charter Bus Industry' },
		{ key: '48551', value: 'Charter Bus Industry' },
		{ key: '485510', value: 'Charter Bus Industry' },
		{ key: '4859', value: 'Other Transit and Ground Passenger Transportation' },
		{ key: '48599', value: 'Other Transit and Ground Passenger Transportation' },
		{ key: '485991', value: 'Special Needs Transportation' },
		{ key: '485999', value: 'All Other Transit and Ground Passenger Transportation' },
		{ key: '486', value: 'Pipeline Transportation' },
		{ key: '4861', value: 'Pipeline Transportation of Crude Oil' },
		{ key: '48611', value: 'Pipeline Transportation of Crude Oil' },
		{ key: '486110', value: 'Pipeline Transportation of Crude Oil' },
		{ key: '4862', value: 'Pipeline Transportation of Natural Gas' },
		{ key: '48621', value: 'Pipeline Transportation of Natural Gas' },
		{ key: '486210', value: 'Pipeline Transportation of Natural Gas' },
		{ key: '4869', value: 'Other Pipeline Transportation' },
		{ key: '48691', value: 'Pipeline Transportation of Refined Petroleum Products' },
		{ key: '486910', value: 'Pipeline Transportation of Refined Petroleum Products' },
		{ key: '48699', value: 'All Other Pipeline Transportation' },
		{ key: '486990', value: 'All Other Pipeline Transportation' },
		{ key: '487', value: 'Scenic and Sightseeing Transportation' },
		{ key: '4871', value: 'Scenic and Sightseeing Transportation, Land' },
		{ key: '48711', value: 'Scenic and Sightseeing Transportation, Land' },
		{ key: '487110', value: 'Scenic and Sightseeing Transportation, Land' },
		{ key: '4872', value: 'Scenic and Sightseeing Transportation, Water' },
		{ key: '48721', value: 'Scenic and Sightseeing Transportation, Water' },
		{ key: '487210', value: 'Scenic and Sightseeing Transportation, Water' },
		{ key: '4879', value: 'Scenic and Sightseeing Transportation, Other' },
		{ key: '48799', value: 'Scenic and Sightseeing Transportation, Other' },
		{ key: '487990', value: 'Scenic and Sightseeing Transportation, Other' },
		{ key: '488', value: 'Support Activities for Transportation' },
		{ key: '4881', value: 'Support Activities for Air Transportation' },
		{ key: '48811', value: 'Airport Operations' },
		{ key: '488111', value: 'Air Traffic Control' },
		{ key: '488119', value: 'Other Airport Operations' },
		{ key: '48819', value: 'Other Support Activities for Air Transportation' },
		{ key: '488190', value: 'Other Support Activities for Air Transportation' },
		{ key: '4882', value: 'Support Activities for Rail Transportation' },
		{ key: '48821', value: 'Support Activities for Rail Transportation' },
		{ key: '488210', value: 'Support Activities for Rail Transportation' },
		{ key: '4883', value: 'Support Activities for Water Transportation' },
		{ key: '48831', value: 'Port and Harbor Operations' },
		{ key: '488310', value: 'Port and Harbor Operations' },
		{ key: '48832', value: 'Marine Cargo Handling' },
		{ key: '488320', value: 'Marine Cargo Handling' },
		{ key: '48833', value: 'Navigational Services to Shipping' },
		{ key: '488330', value: 'Navigational Services to Shipping' },
		{ key: '48839', value: 'Other Support Activities for Water Transportation' },
		{ key: '488390', value: 'Other Support Activities for Water Transportation' },
		{ key: '4884', value: 'Support Activities for Road Transportation' },
		{ key: '48841', value: 'Motor Vehicle Towing' },
		{ key: '488410', value: 'Motor Vehicle Towing' },
		{ key: '48849', value: 'Other Support Activities for Road Transportation' },
		{ key: '488490', value: 'Other Support Activities for Road Transportation' },
		{ key: '4885', value: 'Freight Transportation Arrangement' },
		{ key: '48851', value: 'Freight Transportation Arrangement' },
		{ key: '488510', value: 'Freight Transportation Arrangement' },
		{ key: '4889', value: 'Other Support Activities for Transportation' },
		{ key: '48899', value: 'Other Support Activities for Transportation' },
		{ key: '488991', value: 'Packing and Crating' },
		{ key: '488999', value: 'All Other Support Activities for Transportation' },
		{ key: '491', value: 'Postal Service' },
		{ key: '4911', value: 'Postal Service' },
		{ key: '49111', value: 'Postal Service' },
		{ key: '491110', value: 'Postal Service' },
		{ key: '492', value: 'Couriers and Messengers' },
		{ key: '4921', value: 'Couriers and Express Delivery Services' },
		{ key: '49211', value: 'Couriers and Express Delivery Services' },
		{ key: '492110', value: 'Couriers and Express Delivery Services' },
		{ key: '4922', value: 'Local Messengers and Local Delivery' },
		{ key: '49221', value: 'Local Messengers and Local Delivery' },
		{ key: '492210', value: 'Local Messengers and Local Delivery' },
		{ key: '493', value: 'Warehousing and Storage' },
		{ key: '4931', value: 'Warehousing and Storage' },
		{ key: '49311', value: 'General Warehousing and Storage' },
		{ key: '493110', value: 'General Warehousing and Storage' },
		{ key: '49312', value: 'Refrigerated Warehousing and Storage' },
		{ key: '493120', value: 'Refrigerated Warehousing and Storage' },
		{ key: '49313', value: 'Farm Product Warehousing and Storage' },
		{ key: '493130', value: 'Farm Product Warehousing and Storage' },
		{ key: '49319', value: 'Other Warehousing and Storage' },
		{ key: '493190', value: 'Other Warehousing and Storage' },
		{ key: '51', value: 'Information' },
		{ key: '511', value: 'Publishing Industries (except Internet)' },
		{ key: '5111', value: 'Newspaper, Periodical, Book, and Directory Publishers' },
		{ key: '51111', value: 'Newspaper Publishers' },
		{ key: '511110', value: 'Newspaper Publishers' },
		{ key: '51112', value: 'Periodical Publishers' },
		{ key: '511120', value: 'Periodical Publishers' },
		{ key: '51113', value: 'Book Publishers' },
		{ key: '511130', value: 'Book Publishers' },
		{ key: '51114', value: 'Directory and Mailing List Publishers' },
		{ key: '511140', value: 'Directory and Mailing List Publishers' },
		{ key: '51119', value: 'Other Publishers' },
		{ key: '511191', value: 'Greeting Card Publishers' },
		{ key: '511199', value: 'All Other Publishers' },
		{ key: '5112', value: 'Software Publishers' },
		{ key: '51121', value: 'Software Publishers' },
		{ key: '511210', value: 'Software Publishers' },
		{ key: '512', value: 'Motion Picture and Sound Recording Industries' },
		{ key: '5121', value: 'Motion Picture and Video Industries' },
		{ key: '51211', value: 'Motion Picture and Video Production' },
		{ key: '512110', value: 'Motion Picture and Video Production' },
		{ key: '51212', value: 'Motion Picture and Video Distribution' },
		{ key: '512120', value: 'Motion Picture and Video Distribution' },
		{ key: '51213', value: 'Motion Picture and Video Exhibition' },
		{ key: '512131', value: 'Motion Picture Theaters (except Drive-Ins)' },
		{ key: '512132', value: 'Drive-In Motion Picture Theaters' },
		{ key: '51219', value: 'Postproduction Services and Other Motion Picture and Video Industries' },
		{ key: '512191', value: 'Teleproduction and Other Postproduction Services' },
		{ key: '512199', value: 'Other Motion Picture and Video Industries' },
		{ key: '5122', value: 'Sound Recording Industries' },
		{ key: '51223', value: 'Music Publishers' },
		{ key: '512230', value: 'Music Publishers' },
		{ key: '51224', value: 'Sound Recording Studios' },
		{ key: '512240', value: 'Sound Recording Studios' },
		{ key: '51225', value: 'Record Production and Distribution' },
		{ key: '512250', value: 'Record Production and Distribution' },
		{ key: '51229', value: 'Other Sound Recording Industries' },
		{ key: '512290', value: 'Other Sound Recording Industries' },
		{ key: '515', value: 'Broadcasting (except Internet)' },
		{ key: '5151', value: 'Radio and Television Broadcasting' },
		{ key: '51511', value: 'Radio Broadcasting' },
		{ key: '515111', value: 'Radio Networks' },
		{ key: '515112', value: 'Radio Stations' },
		{ key: '51512', value: 'Television Broadcasting' },
		{ key: '515120', value: 'Television Broadcasting' },
		{ key: '5152', value: 'Cable and Other Subscription Programming' },
		{ key: '51521', value: 'Cable and Other Subscription Programming' },
		{ key: '515210', value: 'Cable and Other Subscription Programming' },
		{ key: '517', value: 'Telecommunications' },
		{ key: '5173', value: 'Wired and Wireless Telecommunications Carriers' },
		{ key: '51731', value: 'Wired and Wireless Telecommunications Carriers' },
		{ key: '517311', value: 'Wired Telecommunications Carriers' },
		{ key: '517312', value: 'Wireless Telecommunications Carriers (except Satellite)' },
		{ key: '5174', value: 'Satellite Telecommunications' },
		{ key: '51741', value: 'Satellite Telecommunications' },
		{ key: '517410', value: 'Satellite Telecommunications' },
		{ key: '5179', value: 'Other Telecommunications' },
		{ key: '51791', value: 'Other Telecommunications' },
		{ key: '517911', value: 'Telecommunications Resellers' },
		{ key: '517919', value: 'All Other Telecommunications' },
		{ key: '518', value: 'Data Processing, Hosting, and Related Services' },
		{ key: '5182', value: 'Data Processing, Hosting, and Related Services' },
		{ key: '51821', value: 'Data Processing, Hosting, and Related Services' },
		{ key: '518210', value: 'Data Processing, Hosting, and Related Services' },
		{ key: '519', value: 'Other Information Services' },
		{ key: '5191', value: 'Other Information Services' },
		{ key: '51911', value: 'News Syndicates' },
		{ key: '519110', value: 'News Syndicates' },
		{ key: '51912', value: 'Libraries and Archives' },
		{ key: '519120', value: 'Libraries and Archives' },
		{ key: '51913', value: 'Internet Publishing and Broadcasting and Web Search Portals' },
		{ key: '519130', value: 'Internet Publishing and Broadcasting and Web Search Portals' },
		{ key: '51919', value: 'All Other Information Services' },
		{ key: '519190', value: 'All Other Information Services' },
		{ key: '52', value: 'Finance and Insurance' },
		{ key: '521', value: 'Monetary Authorities-Central Bank' },
		{ key: '5211', value: 'Monetary Authorities-Central Bank' },
		{ key: '52111', value: 'Monetary Authorities-Central Bank' },
		{ key: '521110', value: 'Monetary Authorities-Central Bank' },
		{ key: '522', value: 'Credit Intermediation and Related Activities' },
		{ key: '5221', value: 'Depository Credit Intermediation' },
		{ key: '52211', value: 'Commercial Banking' },
		{ key: '522110', value: 'Commercial Banking' },
		{ key: '52212', value: 'Savings Institutions' },
		{ key: '522120', value: 'Savings Institutions' },
		{ key: '52213', value: 'Credit Unions' },
		{ key: '522130', value: 'Credit Unions' },
		{ key: '52219', value: 'Other Depository Credit Intermediation' },
		{ key: '522190', value: 'Other Depository Credit Intermediation' },
		{ key: '5222', value: 'Nondepository Credit Intermediation' },
		{ key: '52221', value: 'Credit Card Issuing' },
		{ key: '522210', value: 'Credit Card Issuing' },
		{ key: '52222', value: 'Sales Financing' },
		{ key: '522220', value: 'Sales Financing' },
		{ key: '52229', value: 'Other Nondepository Credit Intermediation' },
		{ key: '522291', value: 'Consumer Lending' },
		{ key: '522292', value: 'Real Estate Credit' },
		{ key: '522293', value: 'International Trade Financing' },
		{ key: '522294', value: 'Secondary Market Financing' },
		{ key: '522298', value: 'All Other Nondepository Credit Intermediation' },
		{ key: '5223', value: 'Activities Related to Credit Intermediation' },
		{ key: '52231', value: 'Mortgage and Nonmortgage Loan Brokers' },
		{ key: '522310', value: 'Mortgage and Nonmortgage Loan Brokers' },
		{ key: '52232', value: 'Financial Transactions Processing, Reserve, and Clearinghouse Activities' },
		{ key: '522320', value: 'Financial Transactions Processing, Reserve, and Clearinghouse Activities' },
		{ key: '52239', value: 'Other Activities Related to Credit Intermediation' },
		{ key: '522390', value: 'Other Activities Related to Credit Intermediation' },
		{ key: '523', value: 'Securities, Commodity Contracts, and Other Financial Investments and Related Activities' },
		{ key: '5231', value: 'Securities and Commodity Contracts Intermediation and Brokerage' },
		{ key: '52311', value: 'Investment Banking and Securities Dealing' },
		{ key: '523110', value: 'Investment Banking and Securities Dealing' },
		{ key: '52312', value: 'Securities Brokerage' },
		{ key: '523120', value: 'Securities Brokerage' },
		{ key: '52313', value: 'Commodity Contracts Dealing' },
		{ key: '523130', value: 'Commodity Contracts Dealing' },
		{ key: '52314', value: 'Commodity Contracts Brokerage' },
		{ key: '523140', value: 'Commodity Contracts Brokerage' },
		{ key: '5232', value: 'Securities and Commodity Exchanges' },
		{ key: '52321', value: 'Securities and Commodity Exchanges' },
		{ key: '523210', value: 'Securities and Commodity Exchanges' },
		{ key: '5239', value: 'Other Financial Investment Activities' },
		{ key: '52391', value: 'Miscellaneous Intermediation' },
		{ key: '523910', value: 'Miscellaneous Intermediation' },
		{ key: '52392', value: 'Portfolio Management' },
		{ key: '523920', value: 'Portfolio Management' },
		{ key: '52393', value: 'Investment Advice' },
		{ key: '523930', value: 'Investment Advice' },
		{ key: '52399', value: 'All Other Financial Investment Activities' },
		{ key: '523991', value: 'Trust, Fiduciary, and Custody Activities' },
		{ key: '523999', value: 'Miscellaneous Financial Investment Activities' },
		{ key: '524', value: 'Insurance Carriers and Related Activities' },
		{ key: '5241', value: 'Insurance Carriers' },
		{ key: '52411', value: 'Direct Life, Health, and Medical Insurance Carriers' },
		{ key: '524113', value: 'Direct Life Insurance Carriers' },
		{ key: '524114', value: 'Direct Health and Medical Insurance Carriers' },
		{ key: '52412', value: 'Direct Insurance (except Life, Health, and Medical) Carriers' },
		{ key: '524126', value: 'Direct Property and Casualty Insurance Carriers' },
		{ key: '524127', value: 'Direct Title Insurance Carriers' },
		{ key: '524128', value: 'Other Direct Insurance (except Life, Health, and Medical) Carriers' },
		{ key: '52413', value: 'Reinsurance Carriers' },
		{ key: '524130', value: 'Reinsurance Carriers' },
		{ key: '5242', value: 'Agencies, Brokerages, and Other Insurance Related Activities' },
		{ key: '52421', value: 'Insurance Agencies and Brokerages' },
		{ key: '524210', value: 'Insurance Agencies and Brokerages' },
		{ key: '52429', value: 'Other Insurance Related Activities' },
		{ key: '524291', value: 'Claims Adjusting' },
		{ key: '524292', value: 'Third Party Administration of Insurance and Pension Funds' },
		{ key: '524298', value: 'All Other Insurance Related Activities' },
		{ key: '525', value: 'Funds, Trusts, and Other Financial Vehicles' },
		{ key: '5251', value: 'Insurance and Employee Benefit Funds' },
		{ key: '52511', value: 'Pension Funds' },
		{ key: '525110', value: 'Pension Funds' },
		{ key: '52512', value: 'Health and Welfare Funds' },
		{ key: '525120', value: 'Health and Welfare Funds' },
		{ key: '52519', value: 'Other Insurance Funds' },
		{ key: '525190', value: 'Other Insurance Funds' },
		{ key: '5259', value: 'Other Investment Pools and Funds' },
		{ key: '52591', value: 'Open-End Investment Funds' },
		{ key: '525910', value: 'Open-End Investment Funds' },
		{ key: '52592', value: 'Trusts, Estates, and Agency Accounts' },
		{ key: '525920', value: 'Trusts, Estates, and Agency Accounts' },
		{ key: '52599', value: 'Other Financial Vehicles' },
		{ key: '525990', value: 'Other Financial Vehicles' },
		{ key: '53', value: 'Real Estate and Rental and Leasing' },
		{ key: '531', value: 'Real Estate' },
		{ key: '5311', value: 'Lessors of Real Estate' },
		{ key: '53111', value: 'Lessors of Residential Buildings and Dwellings' },
		{ key: '531110', value: 'Lessors of Residential Buildings and Dwellings' },
		{ key: '53112', value: 'Lessors of Nonresidential Buildings (except Miniwarehouses)' },
		{ key: '531120', value: 'Lessors of Nonresidential Buildings (except Miniwarehouses)' },
		{ key: '53113', value: 'Lessors of Miniwarehouses and Self-Storage Units' },
		{ key: '531130', value: 'Lessors of Miniwarehouses and Self-Storage Units' },
		{ key: '53119', value: 'Lessors of Other Real Estate Property' },
		{ key: '531190', value: 'Lessors of Other Real Estate Property' },
		{ key: '5312', value: 'Offices of Real Estate Agents and Brokers' },
		{ key: '53121', value: 'Offices of Real Estate Agents and Brokers' },
		{ key: '531210', value: 'Offices of Real Estate Agents and Brokers' },
		{ key: '5313', value: 'Activities Related to Real Estate' },
		{ key: '53131', value: 'Real Estate Property Managers' },
		{ key: '531311', value: 'Residential Property Managers' },
		{ key: '531312', value: 'Nonresidential Property Managers' },
		{ key: '53132', value: 'Offices of Real Estate Appraisers' },
		{ key: '531320', value: 'Offices of Real Estate Appraisers' },
		{ key: '53139', value: 'Other Activities Related to Real Estate' },
		{ key: '531390', value: 'Other Activities Related to Real Estate' },
		{ key: '532', value: 'Rental and Leasing Services' },
		{ key: '5321', value: 'Automotive Equipment Rental and Leasing' },
		{ key: '53211', value: 'Passenger Car Rental and Leasing' },
		{ key: '532111', value: 'Passenger Car Rental' },
		{ key: '532112', value: 'Passenger Car Leasing' },
		{ key: '53212', value: 'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing' },
		{ key: '532120', value: 'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing' },
		{ key: '5322', value: 'Consumer Goods Rental' },
		{ key: '53221', value: 'Consumer Electronics and Appliances Rental' },
		{ key: '532210', value: 'Consumer Electronics and Appliances Rental' },
		{ key: '53228', value: 'Other Consumer Goods Rental' },
		{ key: '532281', value: 'Formal Wear and Costume Rental' },
		{ key: '532282', value: 'Video Tape and Disc Rental' },
		{ key: '532283', value: 'Home Health Equipment Rental' },
		{ key: '532284', value: 'Recreational Goods Rental' },
		{ key: '532289', value: 'All Other Consumer Goods Rental' },
		{ key: '5323', value: 'General Rental Centers' },
		{ key: '53231', value: 'General Rental Centers' },
		{ key: '532310', value: 'General Rental Centers' },
		{ key: '5324', value: 'Commercial and Industrial Machinery and Equipment Rental and Leasing' },
		{ key: '53241', value: 'Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing' },
		{ key: '532411', value: 'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing' },
		{ key: '532412', value: 'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing' },
		{ key: '53242', value: 'Office Machinery and Equipment Rental and Leasing' },
		{ key: '532420', value: 'Office Machinery and Equipment Rental and Leasing' },
		{ key: '53249', value: 'Other Commercial and Industrial Machinery and Equipment Rental and Leasing' },
		{ key: '532490', value: 'Other Commercial and Industrial Machinery and Equipment Rental and Leasing' },
		{ key: '533', value: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)' },
		{ key: '5331', value: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)' },
		{ key: '53311', value: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)' },
		{ key: '533110', value: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)' },
		{ key: '54', value: 'Professional, Scientific, and Technical Services' },
		{ key: '541', value: 'Professional, Scientific, and Technical Services' },
		{ key: '5411', value: 'Legal Services' },
		{ key: '54111', value: 'Offices of Lawyers' },
		{ key: '541110', value: 'Offices of Lawyers' },
		{ key: '54112', value: 'Offices of Notaries' },
		{ key: '541120', value: 'Offices of Notaries' },
		{ key: '54119', value: 'Other Legal Services' },
		{ key: '541191', value: 'Title Abstract and Settlement Offices' },
		{ key: '541199', value: 'All Other Legal Services' },
		{ key: '5412', value: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services' },
		{ key: '54121', value: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services' },
		{ key: '541211', value: 'Offices of Certified Public Accountants' },
		{ key: '541213', value: 'Tax Preparation Services' },
		{ key: '541214', value: 'Payroll Services' },
		{ key: '541219', value: 'Other Accounting Services' },
		{ key: '5413', value: 'Architectural, Engineering, and Related Services' },
		{ key: '54131', value: 'Architectural Services' },
		{ key: '541310', value: 'Architectural Services' },
		{ key: '54132', value: 'Landscape Architectural Services' },
		{ key: '541320', value: 'Landscape Architectural Services' },
		{ key: '54133', value: 'Engineering Services' },
		{ key: '541330', value: 'Engineering Services' },
		{ key: '54134', value: 'Drafting Services' },
		{ key: '541340', value: 'Drafting Services' },
		{ key: '54135', value: 'Building Inspection Services' },
		{ key: '541350', value: 'Building Inspection Services' },
		{ key: '54136', value: 'Geophysical Surveying and Mapping Services' },
		{ key: '541360', value: 'Geophysical Surveying and Mapping Services' },
		{ key: '54137', value: 'Surveying and Mapping (except Geophysical) Services' },
		{ key: '541370', value: 'Surveying and Mapping (except Geophysical) Services' },
		{ key: '54138', value: 'Testing Laboratories' },
		{ key: '541380', value: 'Testing Laboratories' },
		{ key: '5414', value: 'Specialized Design Services' },
		{ key: '54141', value: 'Interior Design Services' },
		{ key: '541410', value: 'Interior Design Services' },
		{ key: '54142', value: 'Industrial Design Services' },
		{ key: '541420', value: 'Industrial Design Services' },
		{ key: '54143', value: 'Graphic Design Services' },
		{ key: '541430', value: 'Graphic Design Services' },
		{ key: '54149', value: 'Other Specialized Design Services' },
		{ key: '541490', value: 'Other Specialized Design Services' },
		{ key: '5415', value: 'Computer Systems Design and Related Services' },
		{ key: '54151', value: 'Computer Systems Design and Related Services' },
		{ key: '541511', value: 'Custom Computer Programming Services' },
		{ key: '541512', value: 'Computer Systems Design Services' },
		{ key: '541513', value: 'Computer Facilities Management Services' },
		{ key: '541519', value: 'Other Computer Related Services' },
		{ key: '5416', value: 'Management, Scientific, and Technical Consulting Services' },
		{ key: '54161', value: 'Management Consulting Services' },
		{ key: '541611', value: 'Administrative Management and General Management Consulting Services' },
		{ key: '541612', value: 'Human Resources Consulting Services' },
		{ key: '541613', value: 'Marketing Consulting Services' },
		{ key: '541614', value: 'Process, Physical Distribution, and Logistics Consulting Services' },
		{ key: '541618', value: 'Other Management Consulting Services' },
		{ key: '54162', value: 'Environmental Consulting Services' },
		{ key: '541620', value: 'Environmental Consulting Services' },
		{ key: '54169', value: 'Other Scientific and Technical Consulting Services' },
		{ key: '541690', value: 'Other Scientific and Technical Consulting Services' },
		{ key: '5417', value: 'Scientific Research and Development Services' },
		{ key: '54171', value: 'Research and Development in the Physical, Engineering, and Life Sciences' },
		{ key: '541713', value: 'Research and Development in Nanotechnology' },
		{ key: '541714', value: 'Research and Development in Biotechnology (except Nanobiotechnology)' },
		{ key: '541715', value: 'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)' },
		{ key: '54172', value: 'Research and Development in the Social Sciences and Humanities' },
		{ key: '541720', value: 'Research and Development in the Social Sciences and Humanities' },
		{ key: '5418', value: 'Advertising, Public Relations, and Related Services' },
		{ key: '54181', value: 'Advertising Agencies' },
		{ key: '541810', value: 'Advertising Agencies' },
		{ key: '54182', value: 'Public Relations Agencies' },
		{ key: '541820', value: 'Public Relations Agencies' },
		{ key: '54183', value: 'Media Buying Agencies' },
		{ key: '541830', value: 'Media Buying Agencies' },
		{ key: '54184', value: 'Media Representatives' },
		{ key: '541840', value: 'Media Representatives' },
		{ key: '54185', value: 'Outdoor Advertising' },
		{ key: '541850', value: 'Outdoor Advertising' },
		{ key: '54186', value: 'Direct Mail Advertising' },
		{ key: '541860', value: 'Direct Mail Advertising' },
		{ key: '54187', value: 'Advertising Material Distribution Services' },
		{ key: '541870', value: 'Advertising Material Distribution Services' },
		{ key: '54189', value: 'Other Services Related to Advertising' },
		{ key: '541890', value: 'Other Services Related to Advertising' },
		{ key: '5419', value: 'Other Professional, Scientific, and Technical Services' },
		{ key: '54191', value: 'Marketing Research and Public Opinion Polling' },
		{ key: '541910', value: 'Marketing Research and Public Opinion Polling' },
		{ key: '54192', value: 'Photographic Services' },
		{ key: '541921', value: 'Photography Studios, Portrait' },
		{ key: '541922', value: 'Commercial Photography' },
		{ key: '54193', value: 'Translation and Interpretation Services' },
		{ key: '541930', value: 'Translation and Interpretation Services' },
		{ key: '54194', value: 'Veterinary Services' },
		{ key: '541940', value: 'Veterinary Services' },
		{ key: '54199', value: 'All Other Professional, Scientific, and Technical Services' },
		{ key: '541990', value: 'All Other Professional, Scientific, and Technical Services' },
		{ key: '55', value: 'Management of Companies and Enterprises' },
		{ key: '551', value: 'Management of Companies and Enterprises' },
		{ key: '5511', value: 'Management of Companies and Enterprises' },
		{ key: '55111', value: 'Management of Companies and Enterprises' },
		{ key: '551111', value: 'Offices of Bank Holding Companies' },
		{ key: '551112', value: 'Offices of Other Holding Companies' },
		{ key: '551114', value: 'Corporate, Subsidiary, and Regional Managing Offices' },
		{ key: '56', value: 'Administrative and Support and Waste Management and Remediation Services' },
		{ key: '561', value: 'Administrative and Support Services' },
		{ key: '5611', value: 'Office Administrative Services' },
		{ key: '56111', value: 'Office Administrative Services' },
		{ key: '561110', value: 'Office Administrative Services' },
		{ key: '5612', value: 'Facilities Support Services' },
		{ key: '56121', value: 'Facilities Support Services' },
		{ key: '561210', value: 'Facilities Support Services' },
		{ key: '5613', value: 'Employment Services' },
		{ key: '56131', value: 'Employment Placement Agencies and Executive Search Services' },
		{ key: '561311', value: 'Employment Placement Agencies' },
		{ key: '561312', value: 'Executive Search Services' },
		{ key: '56132', value: 'Temporary Help Services' },
		{ key: '561320', value: 'Temporary Help Services' },
		{ key: '56133', value: 'Professional Employer Organizations' },
		{ key: '561330', value: 'Professional Employer Organizations' },
		{ key: '5614', value: 'Business Support Services' },
		{ key: '56141', value: 'Document Preparation Services' },
		{ key: '561410', value: 'Document Preparation Services' },
		{ key: '56142', value: 'Telephone Call Centers' },
		{ key: '561421', value: 'Telephone Answering Services' },
		{ key: '561422', value: 'Telemarketing Bureaus and Other Contact Centers' },
		{ key: '56143', value: 'Business Service Centers' },
		{ key: '561431', value: 'Private Mail Centers' },
		{ key: '561439', value: 'Other Business Service Centers (including Copy Shops)' },
		{ key: '56144', value: 'Collection Agencies' },
		{ key: '561440', value: 'Collection Agencies' },
		{ key: '56145', value: 'Credit Bureaus' },
		{ key: '561450', value: 'Credit Bureaus' },
		{ key: '56149', value: 'Other Business Support Services' },
		{ key: '561491', value: 'Repossession Services' },
		{ key: '561492', value: 'Court Reporting and Stenotype Services' },
		{ key: '561499', value: 'All Other Business Support Services' },
		{ key: '5615', value: 'Travel Arrangement and Reservation Services' },
		{ key: '56151', value: 'Travel Agencies' },
		{ key: '561510', value: 'Travel Agencies' },
		{ key: '56152', value: 'Tour Operators' },
		{ key: '561520', value: 'Tour Operators' },
		{ key: '56159', value: 'Other Travel Arrangement and Reservation Services' },
		{ key: '561591', value: 'Convention and Visitors Bureaus' },
		{ key: '561599', value: 'All Other Travel Arrangement and Reservation Services' },
		{ key: '5616', value: 'Investigation and Security Services' },
		{ key: '56161', value: 'Investigation, Guard, and Armored Car Services' },
		{ key: '561611', value: 'Investigation Services' },
		{ key: '561612', value: 'Security Guards and Patrol Services' },
		{ key: '561613', value: 'Armored Car Services' },
		{ key: '56162', value: 'Security Systems Services' },
		{ key: '561621', value: 'Security Systems Services (except Locksmiths)' },
		{ key: '561622', value: 'Locksmiths' },
		{ key: '5617', value: 'Services to Buildings and Dwellings' },
		{ key: '56171', value: 'Exterminating and Pest Control Services' },
		{ key: '561710', value: 'Exterminating and Pest Control Services' },
		{ key: '56172', value: 'Janitorial Services' },
		{ key: '561720', value: 'Janitorial Services' },
		{ key: '56173', value: 'Landscaping Services' },
		{ key: '561730', value: 'Landscaping Services' },
		{ key: '56174', value: 'Carpet and Upholstery Cleaning Services' },
		{ key: '561740', value: 'Carpet and Upholstery Cleaning Services' },
		{ key: '56179', value: 'Other Services to Buildings and Dwellings' },
		{ key: '561790', value: 'Other Services to Buildings and Dwellings' },
		{ key: '5619', value: 'Other Support Services' },
		{ key: '56191', value: 'Packaging and Labeling Services' },
		{ key: '561910', value: 'Packaging and Labeling Services' },
		{ key: '56192', value: 'Convention and Trade Show Organizers' },
		{ key: '561920', value: 'Convention and Trade Show Organizers' },
		{ key: '56199', value: 'All Other Support Services' },
		{ key: '561990', value: 'All Other Support Services' },
		{ key: '562', value: 'Waste Management and Remediation Services' },
		{ key: '5621', value: 'Waste Collection' },
		{ key: '56211', value: 'Waste Collection' },
		{ key: '562111', value: 'Solid Waste Collection' },
		{ key: '562112', value: 'Hazardous Waste Collection' },
		{ key: '562119', value: 'Other Waste Collection' },
		{ key: '5622', value: 'Waste Treatment and Disposal' },
		{ key: '56221', value: 'Waste Treatment and Disposal' },
		{ key: '562211', value: 'Hazardous Waste Treatment and Disposal' },
		{ key: '562212', value: 'Solid Waste Landfill' },
		{ key: '562213', value: 'Solid Waste Combustors and Incinerators' },
		{ key: '562219', value: 'Other Nonhazardous Waste Treatment and Disposal' },
		{ key: '5629', value: 'Remediation and Other Waste Management Services' },
		{ key: '56291', value: 'Remediation Services' },
		{ key: '562910', value: 'Remediation Services' },
		{ key: '56292', value: 'Materials Recovery Facilities' },
		{ key: '562920', value: 'Materials Recovery Facilities' },
		{ key: '56299', value: 'All Other Waste Management Services' },
		{ key: '562991', value: 'Septic Tank and Related Services' },
		{ key: '562998', value: 'All Other Miscellaneous Waste Management Services' },
		{ key: '61', value: 'Educational Services' },
		{ key: '611', value: 'Educational Services' },
		{ key: '6111', value: 'Elementary and Secondary Schools' },
		{ key: '61111', value: 'Elementary and Secondary Schools' },
		{ key: '611110', value: 'Elementary and Secondary Schools' },
		{ key: '6112', value: 'Junior Colleges' },
		{ key: '61121', value: 'Junior Colleges' },
		{ key: '611210', value: 'Junior Colleges' },
		{ key: '6113', value: 'Colleges, Universities, and Professional Schools' },
		{ key: '61131', value: 'Colleges, Universities, and Professional Schools' },
		{ key: '611310', value: 'Colleges, Universities, and Professional Schools' },
		{ key: '6114', value: 'Business Schools and Computer and Management Training' },
		{ key: '61141', value: 'Business and Secretarial Schools' },
		{ key: '611410', value: 'Business and Secretarial Schools' },
		{ key: '61142', value: 'Computer Training' },
		{ key: '611420', value: 'Computer Training' },
		{ key: '61143', value: 'Professional and Management Development Training' },
		{ key: '611430', value: 'Professional and Management Development Training' },
		{ key: '6115', value: 'Technical and Trade Schools' },
		{ key: '61151', value: 'Technical and Trade Schools' },
		{ key: '611511', value: 'Cosmetology and Barber Schools' },
		{ key: '611512', value: 'Flight Training' },
		{ key: '611513', value: 'Apprenticeship Training' },
		{ key: '611519', value: 'Other Technical and Trade Schools' },
		{ key: '6116', value: 'Other Schools and Instruction' },
		{ key: '61161', value: 'Fine Arts Schools' },
		{ key: '611610', value: 'Fine Arts Schools' },
		{ key: '61162', value: 'Sports and Recreation Instruction' },
		{ key: '611620', value: 'Sports and Recreation Instruction' },
		{ key: '61163', value: 'Language Schools' },
		{ key: '611630', value: 'Language Schools' },
		{ key: '61169', value: 'All Other Schools and Instruction' },
		{ key: '611691', value: 'Exam Preparation and Tutoring' },
		{ key: '611692', value: 'Automobile Driving Schools' },
		{ key: '611699', value: 'All Other Miscellaneous Schools and Instruction' },
		{ key: '6117', value: 'Educational Support Services' },
		{ key: '61171', value: 'Educational Support Services' },
		{ key: '611710', value: 'Educational Support Services' },
		{ key: '62', value: 'Health Care and Social Assistance' },
		{ key: '621', value: 'Ambulatory Health Care Services' },
		{ key: '6211', value: 'Offices of Physicians' },
		{ key: '62111', value: 'Offices of Physicians' },
		{ key: '621111', value: 'Offices of Physicians (except Mental Health Specialists)' },
		{ key: '621112', value: 'Offices of Physicians, Mental Health Specialists' },
		{ key: '6212', value: 'Offices of Dentists' },
		{ key: '62121', value: 'Offices of Dentists' },
		{ key: '621210', value: 'Offices of Dentists' },
		{ key: '6213', value: 'Offices of Other Health Practitioners' },
		{ key: '62131', value: 'Offices of Chiropractors' },
		{ key: '621310', value: 'Offices of Chiropractors' },
		{ key: '62132', value: 'Offices of Optometrists' },
		{ key: '621320', value: 'Offices of Optometrists' },
		{ key: '62133', value: 'Offices of Mental Health Practitioners (except Physicians)' },
		{ key: '621330', value: 'Offices of Mental Health Practitioners (except Physicians)' },
		{ key: '62134', value: 'Offices of Physical, Occupational and Speech Therapists, and Audiologists' },
		{ key: '621340', value: 'Offices of Physical, Occupational and Speech Therapists, and Audiologists' },
		{ key: '62139', value: 'Offices of All Other Health Practitioners' },
		{ key: '621391', value: 'Offices of Podiatrists' },
		{ key: '621399', value: 'Offices of All Other Miscellaneous Health Practitioners' },
		{ key: '6214', value: 'Outpatient Care Centers' },
		{ key: '62141', value: 'Family Planning Centers' },
		{ key: '621410', value: 'Family Planning Centers' },
		{ key: '62142', value: 'Outpatient Mental Health and Substance Abuse Centers' },
		{ key: '621420', value: 'Outpatient Mental Health and Substance Abuse Centers' },
		{ key: '62149', value: 'Other Outpatient Care Centers' },
		{ key: '621491', value: 'HMO Medical Centers' },
		{ key: '621492', value: 'Kidney Dialysis Centers' },
		{ key: '621493', value: 'Freestanding Ambulatory Surgical and Emergency Centers' },
		{ key: '621498', value: 'All Other Outpatient Care Centers' },
		{ key: '6215', value: 'Medical and Diagnostic Laboratories' },
		{ key: '62151', value: 'Medical and Diagnostic Laboratories' },
		{ key: '621511', value: 'Medical Laboratories' },
		{ key: '621512', value: 'Diagnostic Imaging Centers' },
		{ key: '6216', value: 'Home Health Care Services' },
		{ key: '62161', value: 'Home Health Care Services' },
		{ key: '621610', value: 'Home Health Care Services' },
		{ key: '6219', value: 'Other Ambulatory Health Care Services' },
		{ key: '62191', value: 'Ambulance Services' },
		{ key: '621910', value: 'Ambulance Services' },
		{ key: '62199', value: 'All Other Ambulatory Health Care Services' },
		{ key: '621991', value: 'Blood and Organ Banks' },
		{ key: '621999', value: 'All Other Miscellaneous Ambulatory Health Care Services' },
		{ key: '622', value: 'Hospitals' },
		{ key: '6221', value: 'General Medical and Surgical Hospitals' },
		{ key: '62211', value: 'General Medical and Surgical Hospitals' },
		{ key: '622110', value: 'General Medical and Surgical Hospitals' },
		{ key: '6222', value: 'Psychiatric and Substance Abuse Hospitals' },
		{ key: '62221', value: 'Psychiatric and Substance Abuse Hospitals' },
		{ key: '622210', value: 'Psychiatric and Substance Abuse Hospitals' },
		{ key: '6223', value: 'Specialty (except Psychiatric and Substance Abuse) Hospitals' },
		{ key: '62231', value: 'Specialty (except Psychiatric and Substance Abuse) Hospitals' },
		{ key: '622310', value: 'Specialty (except Psychiatric and Substance Abuse) Hospitals' },
		{ key: '623', value: 'Nursing and Residential Care Facilities' },
		{ key: '6231', value: 'Nursing Care Facilities (Skilled Nursing Facilities)' },
		{ key: '62311', value: 'Nursing Care Facilities (Skilled Nursing Facilities)' },
		{ key: '623110', value: 'Nursing Care Facilities (Skilled Nursing Facilities)' },
		{ key: '6232', value: 'Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities' },
		{ key: '62321', value: 'Residential Intellectual and Developmental Disability Facilities' },
		{ key: '623210', value: 'Residential Intellectual and Developmental Disability Facilities' },
		{ key: '62322', value: 'Residential Mental Health and Substance Abuse Facilities' },
		{ key: '623220', value: 'Residential Mental Health and Substance Abuse Facilities' },
		{ key: '6233', value: 'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly' },
		{ key: '62331', value: 'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly' },
		{ key: '623311', value: 'Continuing Care Retirement Communities' },
		{ key: '623312', value: 'Assisted Living Facilities for the Elderly' },
		{ key: '6239', value: 'Other Residential Care Facilities' },
		{ key: '62399', value: 'Other Residential Care Facilities' },
		{ key: '623990', value: 'Other Residential Care Facilities' },
		{ key: '624', value: 'Social Assistance' },
		{ key: '6241', value: 'Individual and Family Services' },
		{ key: '62411', value: 'Child and Youth Services' },
		{ key: '624110', value: 'Child and Youth Services' },
		{ key: '62412', value: 'Services for the Elderly and Persons with Disabilities' },
		{ key: '624120', value: 'Services for the Elderly and Persons with Disabilities' },
		{ key: '62419', value: 'Other Individual and Family Services' },
		{ key: '624190', value: 'Other Individual and Family Services' },
		{ key: '6242', value: 'Community Food and Housing, and Emergency and Other Relief Services' },
		{ key: '62421', value: 'Community Food Services' },
		{ key: '624210', value: 'Community Food Services' },
		{ key: '62422', value: 'Community Housing Services' },
		{ key: '624221', value: 'Temporary Shelters' },
		{ key: '624229', value: 'Other Community Housing Services' },
		{ key: '62423', value: 'Emergency and Other Relief Services' },
		{ key: '624230', value: 'Emergency and Other Relief Services' },
		{ key: '6243', value: 'Vocational Rehabilitation Services' },
		{ key: '62431', value: 'Vocational Rehabilitation Services' },
		{ key: '624310', value: 'Vocational Rehabilitation Services' },
		{ key: '6244', value: 'Child Day Care Services' },
		{ key: '62441', value: 'Child Day Care Services' },
		{ key: '624410', value: 'Child Day Care Services' },
		{ key: '71', value: 'Arts, Entertainment, and Recreation' },
		{ key: '711', value: 'Performing Arts, Spectator Sports, and Related Industries' },
		{ key: '7111', value: 'Performing Arts Companies' },
		{ key: '71111', value: 'Theater Companies and Dinner Theaters' },
		{ key: '711110', value: 'Theater Companies and Dinner Theaters' },
		{ key: '71112', value: 'Dance Companies' },
		{ key: '711120', value: 'Dance Companies' },
		{ key: '71113', value: 'Musical Groups and Artists' },
		{ key: '711130', value: 'Musical Groups and Artists' },
		{ key: '71119', value: 'Other Performing Arts Companies' },
		{ key: '711190', value: 'Other Performing Arts Companies' },
		{ key: '7112', value: 'Spectator Sports' },
		{ key: '71121', value: 'Spectator Sports' },
		{ key: '711211', value: 'Sports Teams and Clubs' },
		{ key: '711212', value: 'Racetracks' },
		{ key: '711219', value: 'Other Spectator Sports' },
		{ key: '7113', value: 'Promoters of Performing Arts, Sports, and Similar Events' },
		{ key: '71131', value: 'Promoters of Performing Arts, Sports, and Similar Events with Facilities' },
		{ key: '711310', value: 'Promoters of Performing Arts, Sports, and Similar Events with Facilities' },
		{ key: '71132', value: 'Promoters of Performing Arts, Sports, and Similar Events without Facilities' },
		{ key: '711320', value: 'Promoters of Performing Arts, Sports, and Similar Events without Facilities' },
		{ key: '7114', value: 'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures' },
		{ key: '71141', value: 'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures' },
		{ key: '711410', value: 'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures' },
		{ key: '7115', value: 'Independent Artists, Writers, and Performers' },
		{ key: '71151', value: 'Independent Artists, Writers, and Performers' },
		{ key: '711510', value: 'Independent Artists, Writers, and Performers' },
		{ key: '712', value: 'Museums, Historical Sites, and Similar Institutions' },
		{ key: '7121', value: 'Museums, Historical Sites, and Similar Institutions' },
		{ key: '71211', value: 'Museums' },
		{ key: '712110', value: 'Museums' },
		{ key: '71212', value: 'Historical Sites' },
		{ key: '712120', value: 'Historical Sites' },
		{ key: '71213', value: 'Zoos and Botanical Gardens' },
		{ key: '712130', value: 'Zoos and Botanical Gardens' },
		{ key: '71219', value: 'Nature Parks and Other Similar Institutions' },
		{ key: '712190', value: 'Nature Parks and Other Similar Institutions' },
		{ key: '713', value: 'Amusement, Gambling, and Recreation Industries' },
		{ key: '7131', value: 'Amusement Parks and Arcades' },
		{ key: '71311', value: 'Amusement and Theme Parks' },
		{ key: '713110', value: 'Amusement and Theme Parks' },
		{ key: '71312', value: 'Amusement Arcades' },
		{ key: '713120', value: 'Amusement Arcades' },
		{ key: '7132', value: 'Gambling Industries' },
		{ key: '71321', value: 'Casinos (except Casino Hotels)' },
		{ key: '713210', value: 'Casinos (except Casino Hotels)' },
		{ key: '71329', value: 'Other Gambling Industries' },
		{ key: '713290', value: 'Other Gambling Industries' },
		{ key: '7139', value: 'Other Amusement and Recreation Industries' },
		{ key: '71391', value: 'Golf Courses and Country Clubs' },
		{ key: '713910', value: 'Golf Courses and Country Clubs' },
		{ key: '71392', value: 'Skiing Facilities' },
		{ key: '713920', value: 'Skiing Facilities' },
		{ key: '71393', value: 'Marinas' },
		{ key: '713930', value: 'Marinas' },
		{ key: '71394', value: 'Fitness and Recreational Sports Centers' },
		{ key: '713940', value: 'Fitness and Recreational Sports Centers' },
		{ key: '71395', value: 'Bowling Centers' },
		{ key: '713950', value: 'Bowling Centers' },
		{ key: '71399', value: 'All Other Amusement and Recreation Industries' },
		{ key: '713990', value: 'All Other Amusement and Recreation Industries' },
		{ key: '72', value: 'Accommodation and Food Services' },
		{ key: '721', value: 'Accommodation' },
		{ key: '7211', value: 'Traveler Accommodation' },
		{ key: '72111', value: 'Hotels (except Casino Hotels) and Motels' },
		{ key: '721110', value: 'Hotels (except Casino Hotels) and Motels' },
		{ key: '72112', value: 'Casino Hotels' },
		{ key: '721120', value: 'Casino Hotels' },
		{ key: '72119', value: 'Other Traveler Accommodation' },
		{ key: '721191', value: 'Bed-and-Breakfast Inns' },
		{ key: '721199', value: 'All Other Traveler Accommodation' },
		{ key: '7212', value: 'RV (Recreational Vehicle) Parks and Recreational Camps' },
		{ key: '72121', value: 'RV (Recreational Vehicle) Parks and Recreational Camps' },
		{ key: '721211', value: 'RV (Recreational Vehicle) Parks and Campgrounds' },
		{ key: '721214', value: 'Recreational and Vacation Camps (except Campgrounds)' },
		{ key: '7213', value: 'Rooming and Boarding Houses, Dormitories, and Workers\' Camps' },
		{ key: '72131', value: 'Rooming and Boarding Houses, Dormitories, and Workers\' Camps' },
		{ key: '721310', value: 'Rooming and Boarding Houses, Dormitories, and Workers\' Camps' },
		{ key: '722', value: 'Food Services and Drinking Places' },
		{ key: '7223', value: 'Special Food Services' },
		{ key: '72231', value: 'Food Service Contractors' },
		{ key: '722310', value: 'Food Service Contractors' },
		{ key: '72232', value: 'Caterers' },
		{ key: '722320', value: 'Caterers' },
		{ key: '72233', value: 'Mobile Food Services' },
		{ key: '722330', value: 'Mobile Food Services' },
		{ key: '7224', value: 'Drinking Places (Alcoholic Beverages)' },
		{ key: '72241', value: 'Drinking Places (Alcoholic Beverages)' },
		{ key: '722410', value: 'Drinking Places (Alcoholic Beverages)' },
		{ key: '7225', value: 'Restaurants and Other Eating Places' },
		{ key: '72251', value: 'Restaurants and Other Eating Places' },
		{ key: '722511', value: 'Full-Service Restaurants' },
		{ key: '722513', value: 'Limited-Service Restaurants' },
		{ key: '722514', value: 'Cafeterias, Grill Buffets, and Buffets' },
		{ key: '722515', value: 'Snack and Nonalcoholic Beverage Bars' },
		{ key: '81', value: 'Other Services (except Public Administration)' },
		{ key: '811', value: 'Repair and Maintenance' },
		{ key: '8111', value: 'Automotive Repair and Maintenance' },
		{ key: '81111', value: 'Automotive Mechanical and Electrical Repair and Maintenance' },
		{ key: '811111', value: 'General Automotive Repair' },
		{ key: '811112', value: 'Automotive Exhaust System Repair' },
		{ key: '811113', value: 'Automotive Transmission Repair' },
		{ key: '811118', value: 'Other Automotive Mechanical and Electrical Repair and Maintenance' },
		{ key: '81112', value: 'Automotive Body, Paint, Interior, and Glass Repair' },
		{ key: '811121', value: 'Automotive Body, Paint, and Interior Repair and Maintenance' },
		{ key: '811122', value: 'Automotive Glass Replacement Shops' },
		{ key: '81119', value: 'Other Automotive Repair and Maintenance' },
		{ key: '811191', value: 'Automotive Oil Change and Lubrication Shops' },
		{ key: '811192', value: 'Car Washes' },
		{ key: '811198', value: 'All Other Automotive Repair and Maintenance' },
		{ key: '8112', value: 'Electronic and Precision Equipment Repair and Maintenance' },
		{ key: '81121', value: 'Electronic and Precision Equipment Repair and Maintenance' },
		{ key: '811211', value: 'Consumer Electronics Repair and Maintenance' },
		{ key: '811212', value: 'Computer and Office Machine Repair and Maintenance' },
		{ key: '811213', value: 'Communication Equipment Repair and Maintenance' },
		{ key: '811219', value: 'Other Electronic and Precision Equipment Repair and Maintenance' },
		{ key: '8113', value: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance' },
		{ key: '81131', value: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance' },
		{ key: '811310', value: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance' },
		{ key: '8114', value: 'Personal and Household Goods Repair and Maintenance' },
		{ key: '81141', value: 'Home and Garden Equipment and Appliance Repair and Maintenance' },
		{ key: '811411', value: 'Home and Garden Equipment Repair and Maintenance' },
		{ key: '811412', value: 'Appliance Repair and Maintenance' },
		{ key: '81142', value: 'Reupholstery and Furniture Repair' },
		{ key: '811420', value: 'Reupholstery and Furniture Repair' },
		{ key: '81143', value: 'Footwear and Leather Goods Repair' },
		{ key: '811430', value: 'Footwear and Leather Goods Repair' },
		{ key: '81149', value: 'Other Personal and Household Goods Repair and Maintenance' },
		{ key: '811490', value: 'Other Personal and Household Goods Repair and Maintenance' },
		{ key: '812', value: 'Personal and Laundry Services' },
		{ key: '8121', value: 'Personal Care Services' },
		{ key: '81211', value: 'Hair, Nail, and Skin Care Services' },
		{ key: '812111', value: 'Barber Shops' },
		{ key: '812112', value: 'Beauty Salons' },
		{ key: '812113', value: 'Nail Salons' },
		{ key: '81219', value: 'Other Personal Care Services' },
		{ key: '812191', value: 'Diet and Weight Reducing Centers' },
		{ key: '812199', value: 'Other Personal Care Services' },
		{ key: '8122', value: 'Death Care Services' },
		{ key: '81221', value: 'Funeral Homes and Funeral Services' },
		{ key: '812210', value: 'Funeral Homes and Funeral Services' },
		{ key: '81222', value: 'Cemeteries and Crematories' },
		{ key: '812220', value: 'Cemeteries and Crematories' },
		{ key: '8123', value: 'Drycleaning and Laundry Services' },
		{ key: '81231', value: 'Coin-Operated Laundries and Drycleaners' },
		{ key: '812310', value: 'Coin-Operated Laundries and Drycleaners' },
		{ key: '81232', value: 'Drycleaning and Laundry Services (except Coin-Operated)' },
		{ key: '812320', value: 'Drycleaning and Laundry Services (except Coin-Operated)' },
		{ key: '81233', value: 'Linen and Uniform Supply' },
		{ key: '812331', value: 'Linen Supply' },
		{ key: '812332', value: 'Industrial Launderers' },
		{ key: '8129', value: 'Other Personal Services' },
		{ key: '81291', value: 'Pet Care (except Veterinary) Services' },
		{ key: '812910', value: 'Pet Care (except Veterinary) Services' },
		{ key: '81292', value: 'Photofinishing' },
		{ key: '812921', value: 'Photofinishing Laboratories (except One-Hour)' },
		{ key: '812922', value: 'One-Hour Photofinishing' },
		{ key: '81293', value: 'Parking Lots and Garages' },
		{ key: '812930', value: 'Parking Lots and Garages' },
		{ key: '81299', value: 'All Other Personal Services' },
		{ key: '812990', value: 'All Other Personal Services' },
		{ key: '813', value: 'Religious, Grantmaking, Civic, Professional, and Similar Organizations' },
		{ key: '8131', value: 'Religious Organizations' },
		{ key: '81311', value: 'Religious Organizations' },
		{ key: '813110', value: 'Religious Organizations' },
		{ key: '8132', value: 'Grantmaking and Giving Services' },
		{ key: '81321', value: 'Grantmaking and Giving Services' },
		{ key: '813211', value: 'Grantmaking Foundations' },
		{ key: '813212', value: 'Voluntary Health Organizations' },
		{ key: '813219', value: 'Other Grantmaking and Giving Services' },
		{ key: '8133', value: 'Social Advocacy Organizations' },
		{ key: '81331', value: 'Social Advocacy Organizations' },
		{ key: '813311', value: 'Human Rights Organizations' },
		{ key: '813312', value: 'Environment, Conservation and Wildlife Organizations' },
		{ key: '813319', value: 'Other Social Advocacy Organizations' },
		{ key: '8134', value: 'Civic and Social Organizations' },
		{ key: '81341', value: 'Civic and Social Organizations' },
		{ key: '813410', value: 'Civic and Social Organizations' },
		{ key: '8139', value: 'Business, Professional, Labor, Political, and Similar Organizations' },
		{ key: '81391', value: 'Business Associations' },
		{ key: '813910', value: 'Business Associations' },
		{ key: '81392', value: 'Professional Organizations' },
		{ key: '813920', value: 'Professional Organizations' },
		{ key: '81393', value: 'Labor Unions and Similar Labor Organizations' },
		{ key: '813930', value: 'Labor Unions and Similar Labor Organizations' },
		{ key: '81394', value: 'Political Organizations' },
		{ key: '813940', value: 'Political Organizations' },
		{ key: '81399', value: 'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)' },
		{ key: '813990', value: 'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)' },
		{ key: '814', value: 'Private Households' },
		{ key: '8141', value: 'Private Households' },
		{ key: '81411', value: 'Private Households' },
		{ key: '814110', value: 'Private Households' },
		{ key: '92', value: 'Public Administration' },
		{ key: '921', value: 'Executive, Legislative, and Other General Government Support' },
		{ key: '9211', value: 'Executive, Legislative, and Other General Government Support' },
		{ key: '92111', value: 'Executive Offices' },
		{ key: '921110', value: 'Executive Offices' },
		{ key: '92112', value: 'Legislative Bodies' },
		{ key: '921120', value: 'Legislative Bodies' },
		{ key: '92113', value: 'Public Finance Activities' },
		{ key: '921130', value: 'Public Finance Activities' },
		{ key: '92114', value: 'Executive and Legislative Offices, Combined' },
		{ key: '921140', value: 'Executive and Legislative Offices, Combined' },
		{ key: '92115', value: 'American Indian and Alaska Native Tribal Governments' },
		{ key: '921150', value: 'American Indian and Alaska Native Tribal Governments' },
		{ key: '92119', value: 'Other General Government Support' },
		{ key: '921190', value: 'Other General Government Support' },
		{ key: '922', value: 'Justice, Public Order, and Safety Activities' },
		{ key: '9221', value: 'Justice, Public Order, and Safety Activities' },
		{ key: '92211', value: 'Courts' },
		{ key: '922110', value: 'Courts' },
		{ key: '92212', value: 'Police Protection' },
		{ key: '922120', value: 'Police Protection' },
		{ key: '92213', value: 'Legal Counsel and Prosecution' },
		{ key: '922130', value: 'Legal Counsel and Prosecution' },
		{ key: '92214', value: 'Correctional Institutions' },
		{ key: '922140', value: 'Correctional Institutions' },
		{ key: '92215', value: 'Parole Offices and Probation Offices' },
		{ key: '922150', value: 'Parole Offices and Probation Offices' },
		{ key: '92216', value: 'Fire Protection' },
		{ key: '922160', value: 'Fire Protection' },
		{ key: '92219', value: 'Other Justice, Public Order, and Safety Activities' },
		{ key: '922190', value: 'Other Justice, Public Order, and Safety Activities' },
		{ key: '923', value: 'Administration of Human Resource Programs' },
		{ key: '9231', value: 'Administration of Human Resource Programs' },
		{ key: '92311', value: 'Administration of Education Programs' },
		{ key: '923110', value: 'Administration of Education Programs' },
		{ key: '92312', value: 'Administration of Public Health Programs' },
		{ key: '923120', value: 'Administration of Public Health Programs' },
		{ key: '92313', value: 'Administration of Human Resource Programs (except Education, Public Health, and Veterans\' Affairs Programs)' },
		{ key: '923130', value: 'Administration of Human Resource Programs (except Education, Public Health, and Veterans\' Affairs Programs)' },
		{ key: '92314', value: 'Administration of Veterans\' Affairs' },
		{ key: '923140', value: 'Administration of Veterans\' Affairs' },
		{ key: '924', value: 'Administration of Environmental Quality Programs' },
		{ key: '9241', value: 'Administration of Environmental Quality Programs' },
		{ key: '92411', value: 'Administration of Air and Water Resource and Solid Waste Management Programs' },
		{ key: '924110', value: 'Administration of Air and Water Resource and Solid Waste Management Programs' },
		{ key: '92412', value: 'Administration of Conservation Programs' },
		{ key: '924120', value: 'Administration of Conservation Programs' },
		{ key: '925', value: 'Administration of Housing Programs, Urban Planning, and Community Development' },
		{ key: '9251', value: 'Administration of Housing Programs, Urban Planning, and Community Development' },
		{ key: '92511', value: 'Administration of Housing Programs' },
		{ key: '925110', value: 'Administration of Housing Programs' },
		{ key: '92512', value: 'Administration of Urban Planning and Community and Rural Development' },
		{ key: '925120', value: 'Administration of Urban Planning and Community and Rural Development' },
		{ key: '926', value: 'Administration of Economic Programs' },
		{ key: '9261', value: 'Administration of Economic Programs' },
		{ key: '92611', value: 'Administration of General Economic Programs' },
		{ key: '926110', value: 'Administration of General Economic Programs' },
		{ key: '92612', value: 'Regulation and Administration of Transportation Programs' },
		{ key: '926120', value: 'Regulation and Administration of Transportation Programs' },
		{ key: '92613', value: 'Regulation and Administration of Communications, Electric, Gas, and Other Utilities' },
		{ key: '926130', value: 'Regulation and Administration of Communications, Electric, Gas, and Other Utilities' },
		{ key: '92614', value: 'Regulation of Agricultural Marketing and Commodities' },
		{ key: '926140', value: 'Regulation of Agricultural Marketing and Commodities' },
		{ key: '92615', value: 'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors' },
		{ key: '926150', value: 'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors' },
		{ key: '927', value: 'Space Research and Technology' },
		{ key: '9271', value: 'Space Research and Technology' },
		{ key: '92711', value: 'Space Research and Technology' },
		{ key: '927110', value: 'Space Research and Technology' },
		{ key: '928', value: 'National Security and International Affairs' },
		{ key: '9281', value: 'National Security and International Affairs' },
		{ key: '92811', value: 'National Security' },
		{ key: '928110', value: 'National Security' },
		{ key: '92812', value: 'International Affairs' },
		{ key: '928120', value: 'International Affairs' },
	] as { key: string; value: string }[],
	failureRating: [
		{ key: '1', value: 'Risk Level 1 (Least Risk)' },
		{ key: '2', value: 'Risk Level 2' },
		{ key: '3', value: 'Risk Level 3' },
		{ key: '4', value: 'Risk Level 4' },
		{ key: '5', value: 'Risk Level 5' },
		{ key: '6', value: 'Risk Level 6' },
		{ key: '7', value: 'Risk Level 7' },
		{ key: '8', value: 'Risk Level 8' },
		{ key: '9', value: 'Risk Level 9 (Most Risk)' },
		{ key: '0', value: 'Bankruptcy - 0' },
	],
	failureReasons: [
		{ key: 'C0022', value: 'Number of Non-Financial Trades Suggests Higher Risk' },
		{ key: 'C0091', value: 'Evidence of Non-Financial Trades that are Cycle 2+ Delinquent OR Charge-off' },
		{ key: 'C0094', value: 'Evidence of Non-Financial Trades that are Cycle 4+ Delinquent or ChargeOff' },
		{ key: 'C0101', value: 'Evidence of Telco Trades that are Cycle 4+ Delinquent or Charge-Off' },
		{ key: 'C0178', value: 'Evidence of Charged-off Non-Financial Accounts' },
		{ key: 'C0206', value: 'Evidence of Bankruptcy Filing' },
		{ key: 'C0207', value: 'Evidence of Lien(s) OR Judgment(s)' },
		{ key: 'C0210', value: 'Years Firm has Been in Business in Database Suggests Higher Risk' },
		{ key: 'C5007', value: 'Evidence of Non Financial Trades 2+ Cycle Historically' },
		{ key: 'C5008', value: 'Some Percent of Satisfactory Non-Financial Trades' },
	],
	creditRiskScore: [
		{ key: '101', value: '101' },
		{ key: '102', value: '102' },
		{ key: '103', value: '103' },
		{ key: '104', value: '104' },
		{ key: '105', value: '105' },
		{ key: '106', value: '106' },
		{ key: '107', value: '107' },
		{ key: '108', value: '108' },
		{ key: '109', value: '109' },
		{ key: '110', value: '110' },
		{ key: '111', value: '111' },
		{ key: '112', value: '112' },
		{ key: '113', value: '113' },
		{ key: '114', value: '114' },
		{ key: '115', value: '115' },
		{ key: '116', value: '116' },
		{ key: '117', value: '117' },
		{ key: '118', value: '118' },
		{ key: '119', value: '119' },
		{ key: '120', value: '120' },
		{ key: '121', value: '121' },
		{ key: '122', value: '122' },
		{ key: '123', value: '123' },
		{ key: '124', value: '124' },
		{ key: '125', value: '125' },
		{ key: '126', value: '126' },
		{ key: '127', value: '127' },
		{ key: '128', value: '128' },
		{ key: '129', value: '129' },
		{ key: '130', value: '130' },
		{ key: '131', value: '131' },
		{ key: '132', value: '132' },
		{ key: '133', value: '133' },
		{ key: '134', value: '134' },
		{ key: '135', value: '135' },
		{ key: '136', value: '136' },
		{ key: '137', value: '137' },
		{ key: '138', value: '138' },
		{ key: '139', value: '139' },
		{ key: '140', value: '140' },
		{ key: '141', value: '141' },
		{ key: '142', value: '142' },
		{ key: '143', value: '143' },
		{ key: '144', value: '144' },
		{ key: '145', value: '145' },
		{ key: '146', value: '146' },
		{ key: '147', value: '147' },
		{ key: '148', value: '148' },
		{ key: '149', value: '149' },
		{ key: '150', value: '150' },
		{ key: '151', value: '151' },
		{ key: '152', value: '152' },
		{ key: '153', value: '153' },
		{ key: '154', value: '154' },
		{ key: '155', value: '155' },
		{ key: '156', value: '156' },
		{ key: '157', value: '157' },
		{ key: '158', value: '158' },
		{ key: '159', value: '159' },
		{ key: '160', value: '160' },
		{ key: '161', value: '161' },
		{ key: '162', value: '162' },
		{ key: '163', value: '163' },
		{ key: '164', value: '164' },
		{ key: '165', value: '165' },
		{ key: '166', value: '166' },
		{ key: '167', value: '167' },
		{ key: '168', value: '168' },
		{ key: '169', value: '169' },
		{ key: '170', value: '170' },
		{ key: '171', value: '171' },
		{ key: '172', value: '172' },
		{ key: '173', value: '173' },
		{ key: '174', value: '174' },
		{ key: '175', value: '175' },
		{ key: '176', value: '176' },
		{ key: '177', value: '177' },
		{ key: '178', value: '178' },
		{ key: '179', value: '179' },
		{ key: '180', value: '180' },
		{ key: '181', value: '181' },
		{ key: '182', value: '182' },
		{ key: '183', value: '183' },
		{ key: '184', value: '184' },
		{ key: '185', value: '185' },
		{ key: '186', value: '186' },
		{ key: '187', value: '187' },
		{ key: '188', value: '188' },
		{ key: '189', value: '189' },
		{ key: '190', value: '190' },
		{ key: '191', value: '191' },
		{ key: '192', value: '192' },
		{ key: '193', value: '193' },
		{ key: '194', value: '194' },
		{ key: '195', value: '195' },
		{ key: '196', value: '196' },
		{ key: '197', value: '197' },
		{ key: '198', value: '198' },
		{ key: '199', value: '199' },
		{ key: '200', value: '200' },
		{ key: '201', value: '201' },
		{ key: '202', value: '202' },
		{ key: '203', value: '203' },
		{ key: '204', value: '204' },
		{ key: '205', value: '205' },
		{ key: '206', value: '206' },
		{ key: '207', value: '207' },
		{ key: '208', value: '208' },
		{ key: '209', value: '209' },
		{ key: '210', value: '210' },
		{ key: '211', value: '211' },
		{ key: '212', value: '212' },
		{ key: '213', value: '213' },
		{ key: '214', value: '214' },
		{ key: '215', value: '215' },
		{ key: '216', value: '216' },
		{ key: '217', value: '217' },
		{ key: '218', value: '218' },
		{ key: '219', value: '219' },
		{ key: '220', value: '220' },
		{ key: '221', value: '221' },
		{ key: '222', value: '222' },
		{ key: '223', value: '223' },
		{ key: '224', value: '224' },
		{ key: '225', value: '225' },
		{ key: '226', value: '226' },
		{ key: '227', value: '227' },
		{ key: '228', value: '228' },
		{ key: '229', value: '229' },
		{ key: '230', value: '230' },
		{ key: '231', value: '231' },
		{ key: '232', value: '232' },
		{ key: '233', value: '233' },
		{ key: '234', value: '234' },
		{ key: '235', value: '235' },
		{ key: '236', value: '236' },
		{ key: '237', value: '237' },
		{ key: '238', value: '238' },
		{ key: '239', value: '239' },
		{ key: '240', value: '240' },
		{ key: '241', value: '241' },
		{ key: '242', value: '242' },
		{ key: '243', value: '243' },
		{ key: '244', value: '244' },
		{ key: '245', value: '245' },
		{ key: '246', value: '246' },
		{ key: '247', value: '247' },
		{ key: '248', value: '248' },
		{ key: '249', value: '249' },
		{ key: '250', value: '250' },
		{ key: '251', value: '251' },
		{ key: '252', value: '252' },
		{ key: '253', value: '253' },
		{ key: '254', value: '254' },
		{ key: '255', value: '255' },
		{ key: '256', value: '256' },
		{ key: '257', value: '257' },
		{ key: '258', value: '258' },
		{ key: '259', value: '259' },
		{ key: '260', value: '260' },
		{ key: '261', value: '261' },
		{ key: '262', value: '262' },
		{ key: '263', value: '263' },
		{ key: '264', value: '264' },
		{ key: '265', value: '265' },
		{ key: '266', value: '266' },
		{ key: '267', value: '267' },
		{ key: '268', value: '268' },
		{ key: '269', value: '269' },
		{ key: '270', value: '270' },
		{ key: '271', value: '271' },
		{ key: '272', value: '272' },
		{ key: '273', value: '273' },
		{ key: '274', value: '274' },
		{ key: '275', value: '275' },
		{ key: '276', value: '276' },
		{ key: '277', value: '277' },
		{ key: '278', value: '278' },
		{ key: '279', value: '279' },
		{ key: '280', value: '280' },
		{ key: '281', value: '281' },
		{ key: '282', value: '282' },
		{ key: '283', value: '283' },
		{ key: '284', value: '284' },
		{ key: '285', value: '285' },
		{ key: '286', value: '286' },
		{ key: '287', value: '287' },
		{ key: '288', value: '288' },
		{ key: '289', value: '289' },
		{ key: '290', value: '290' },
		{ key: '291', value: '291' },
		{ key: '292', value: '292' },
		{ key: '293', value: '293' },
		{ key: '294', value: '294' },
		{ key: '295', value: '295' },
		{ key: '296', value: '296' },
		{ key: '297', value: '297' },
		{ key: '298', value: '298' },
		{ key: '299', value: '299' },
		{ key: '300', value: '300' },
		{ key: '301', value: '301' },
		{ key: '302', value: '302' },
		{ key: '303', value: '303' },
		{ key: '304', value: '304' },
		{ key: '305', value: '305' },
		{ key: '306', value: '306' },
		{ key: '307', value: '307' },
		{ key: '308', value: '308' },
		{ key: '309', value: '309' },
		{ key: '310', value: '310' },
		{ key: '311', value: '311' },
		{ key: '312', value: '312' },
		{ key: '313', value: '313' },
		{ key: '314', value: '314' },
		{ key: '315', value: '315' },
		{ key: '316', value: '316' },
		{ key: '317', value: '317' },
		{ key: '318', value: '318' },
		{ key: '319', value: '319' },
		{ key: '320', value: '320' },
		{ key: '321', value: '321' },
		{ key: '322', value: '322' },
		{ key: '323', value: '323' },
		{ key: '324', value: '324' },
		{ key: '325', value: '325' },
		{ key: '326', value: '326' },
		{ key: '327', value: '327' },
		{ key: '328', value: '328' },
		{ key: '329', value: '329' },
		{ key: '330', value: '330' },
		{ key: '331', value: '331' },
		{ key: '332', value: '332' },
		{ key: '333', value: '333' },
		{ key: '334', value: '334' },
		{ key: '335', value: '335' },
		{ key: '336', value: '336' },
		{ key: '337', value: '337' },
		{ key: '338', value: '338' },
		{ key: '339', value: '339' },
		{ key: '340', value: '340' },
		{ key: '341', value: '341' },
		{ key: '342', value: '342' },
		{ key: '343', value: '343' },
		{ key: '344', value: '344' },
		{ key: '345', value: '345' },
		{ key: '346', value: '346' },
		{ key: '347', value: '347' },
		{ key: '348', value: '348' },
		{ key: '349', value: '349' },
		{ key: '350', value: '350' },
		{ key: '351', value: '351' },
		{ key: '352', value: '352' },
		{ key: '353', value: '353' },
		{ key: '354', value: '354' },
		{ key: '355', value: '355' },
		{ key: '356', value: '356' },
		{ key: '357', value: '357' },
		{ key: '358', value: '358' },
		{ key: '359', value: '359' },
		{ key: '360', value: '360' },
		{ key: '361', value: '361' },
		{ key: '362', value: '362' },
		{ key: '363', value: '363' },
		{ key: '364', value: '364' },
		{ key: '365', value: '365' },
		{ key: '366', value: '366' },
		{ key: '367', value: '367' },
		{ key: '368', value: '368' },
		{ key: '369', value: '369' },
		{ key: '370', value: '370' },
		{ key: '371', value: '371' },
		{ key: '372', value: '372' },
		{ key: '373', value: '373' },
		{ key: '374', value: '374' },
		{ key: '375', value: '375' },
		{ key: '376', value: '376' },
		{ key: '377', value: '377' },
		{ key: '378', value: '378' },
		{ key: '379', value: '379' },
		{ key: '380', value: '380' },
		{ key: '381', value: '381' },
		{ key: '382', value: '382' },
		{ key: '383', value: '383' },
		{ key: '384', value: '384' },
		{ key: '385', value: '385' },
		{ key: '386', value: '386' },
		{ key: '387', value: '387' },
		{ key: '388', value: '388' },
		{ key: '389', value: '389' },
		{ key: '390', value: '390' },
		{ key: '391', value: '391' },
		{ key: '392', value: '392' },
		{ key: '393', value: '393' },
		{ key: '394', value: '394' },
		{ key: '395', value: '395' },
		{ key: '396', value: '396' },
		{ key: '397', value: '397' },
		{ key: '398', value: '398' },
		{ key: '399', value: '399' },
		{ key: '400', value: '400' },
		{ key: '401', value: '401' },
		{ key: '402', value: '402' },
		{ key: '403', value: '403' },
		{ key: '404', value: '404' },
		{ key: '405', value: '405' },
		{ key: '406', value: '406' },
		{ key: '407', value: '407' },
		{ key: '408', value: '408' },
		{ key: '409', value: '409' },
		{ key: '410', value: '410' },
		{ key: '411', value: '411' },
		{ key: '412', value: '412' },
		{ key: '413', value: '413' },
		{ key: '414', value: '414' },
		{ key: '415', value: '415' },
		{ key: '416', value: '416' },
		{ key: '417', value: '417' },
		{ key: '418', value: '418' },
		{ key: '419', value: '419' },
		{ key: '420', value: '420' },
		{ key: '421', value: '421' },
		{ key: '422', value: '422' },
		{ key: '423', value: '423' },
		{ key: '424', value: '424' },
		{ key: '425', value: '425' },
		{ key: '426', value: '426' },
		{ key: '427', value: '427' },
		{ key: '428', value: '428' },
		{ key: '429', value: '429' },
		{ key: '430', value: '430' },
		{ key: '431', value: '431' },
		{ key: '432', value: '432' },
		{ key: '433', value: '433' },
		{ key: '434', value: '434' },
		{ key: '435', value: '435' },
		{ key: '436', value: '436' },
		{ key: '437', value: '437' },
		{ key: '438', value: '438' },
		{ key: '439', value: '439' },
		{ key: '440', value: '440' },
		{ key: '441', value: '441' },
		{ key: '442', value: '442' },
		{ key: '443', value: '443' },
		{ key: '444', value: '444' },
		{ key: '445', value: '445' },
		{ key: '446', value: '446' },
		{ key: '447', value: '447' },
		{ key: '448', value: '448' },
		{ key: '449', value: '449' },
		{ key: '450', value: '450' },
		{ key: '451', value: '451' },
		{ key: '452', value: '452' },
		{ key: '453', value: '453' },
		{ key: '454', value: '454' },
		{ key: '455', value: '455' },
		{ key: '456', value: '456' },
		{ key: '457', value: '457' },
		{ key: '458', value: '458' },
		{ key: '459', value: '459' },
		{ key: '460', value: '460' },
		{ key: '461', value: '461' },
		{ key: '462', value: '462' },
		{ key: '463', value: '463' },
		{ key: '464', value: '464' },
		{ key: '465', value: '465' },
		{ key: '466', value: '466' },
		{ key: '467', value: '467' },
		{ key: '468', value: '468' },
		{ key: '469', value: '469' },
		{ key: '470', value: '470' },
		{ key: '471', value: '471' },
		{ key: '472', value: '472' },
		{ key: '473', value: '473' },
		{ key: '474', value: '474' },
		{ key: '475', value: '475' },
		{ key: '476', value: '476' },
		{ key: '477', value: '477' },
		{ key: '478', value: '478' },
		{ key: '479', value: '479' },
		{ key: '480', value: '480' },
		{ key: '481', value: '481' },
		{ key: '482', value: '482' },
		{ key: '483', value: '483' },
		{ key: '484', value: '484' },
		{ key: '485', value: '485' },
		{ key: '486', value: '486' },
		{ key: '487', value: '487' },
		{ key: '488', value: '488' },
		{ key: '489', value: '489' },
		{ key: '490', value: '490' },
		{ key: '491', value: '491' },
		{ key: '492', value: '492' },
		{ key: '493', value: '493' },
		{ key: '494', value: '494' },
		{ key: '495', value: '495' },
		{ key: '496', value: '496' },
		{ key: '497', value: '497' },
		{ key: '498', value: '498' },
		{ key: '499', value: '499' },
		{ key: '500', value: '500' },
		{ key: '501', value: '501' },
		{ key: '502', value: '502' },
		{ key: '503', value: '503' },
		{ key: '504', value: '504' },
		{ key: '505', value: '505' },
		{ key: '506', value: '506' },
		{ key: '507', value: '507' },
		{ key: '508', value: '508' },
		{ key: '509', value: '509' },
		{ key: '510', value: '510' },
		{ key: '511', value: '511' },
		{ key: '512', value: '512' },
		{ key: '513', value: '513' },
		{ key: '514', value: '514' },
		{ key: '515', value: '515' },
		{ key: '516', value: '516' },
		{ key: '517', value: '517' },
		{ key: '518', value: '518' },
		{ key: '519', value: '519' },
		{ key: '520', value: '520' },
		{ key: '521', value: '521' },
		{ key: '522', value: '522' },
		{ key: '523', value: '523' },
		{ key: '524', value: '524' },
		{ key: '525', value: '525' },
		{ key: '526', value: '526' },
		{ key: '527', value: '527' },
		{ key: '528', value: '528' },
		{ key: '529', value: '529' },
		{ key: '530', value: '530' },
		{ key: '531', value: '531' },
		{ key: '532', value: '532' },
		{ key: '533', value: '533' },
		{ key: '534', value: '534' },
		{ key: '535', value: '535' },
		{ key: '536', value: '536' },
		{ key: '537', value: '537' },
		{ key: '538', value: '538' },
		{ key: '539', value: '539' },
		{ key: '540', value: '540' },
		{ key: '541', value: '541' },
		{ key: '542', value: '542' },
		{ key: '543', value: '543' },
		{ key: '544', value: '544' },
		{ key: '545', value: '545' },
		{ key: '546', value: '546' },
		{ key: '547', value: '547' },
		{ key: '548', value: '548' },
		{ key: '549', value: '549' },
		{ key: '550', value: '550' },
		{ key: '551', value: '551' },
		{ key: '552', value: '552' },
		{ key: '553', value: '553' },
		{ key: '554', value: '554' },
		{ key: '555', value: '555' },
		{ key: '556', value: '556' },
		{ key: '557', value: '557' },
		{ key: '558', value: '558' },
		{ key: '559', value: '559' },
		{ key: '560', value: '560' },
		{ key: '561', value: '561' },
		{ key: '562', value: '562' },
		{ key: '563', value: '563' },
		{ key: '564', value: '564' },
		{ key: '565', value: '565' },
		{ key: '566', value: '566' },
		{ key: '567', value: '567' },
		{ key: '568', value: '568' },
		{ key: '569', value: '569' },
		{ key: '570', value: '570' },
		{ key: '571', value: '571' },
		{ key: '572', value: '572' },
		{ key: '573', value: '573' },
		{ key: '574', value: '574' },
		{ key: '575', value: '575' },
		{ key: '576', value: '576' },
		{ key: '577', value: '577' },
		{ key: '578', value: '578' },
		{ key: '579', value: '579' },
		{ key: '580', value: '580' },
		{ key: '581', value: '581' },
		{ key: '582', value: '582' },
		{ key: '583', value: '583' },
		{ key: '584', value: '584' },
		{ key: '585', value: '585' },
		{ key: '586', value: '586' },
		{ key: '587', value: '587' },
		{ key: '588', value: '588' },
		{ key: '589', value: '589' },
		{ key: '590', value: '590' },
		{ key: '591', value: '591' },
		{ key: '592', value: '592' },
		{ key: '593', value: '593' },
		{ key: '594', value: '594' },
		{ key: '595', value: '595' },
		{ key: '596', value: '596' },
		{ key: '597', value: '597' },
		{ key: '598', value: '598' },
		{ key: '599', value: '599' },
		{ key: '600', value: '600' },
		{ key: '601', value: '601' },
		{ key: '602', value: '602' },
		{ key: '603', value: '603' },
		{ key: '604', value: '604' },
		{ key: '605', value: '605' },
		{ key: '606', value: '606' },
		{ key: '607', value: '607' },
		{ key: '608', value: '608' },
		{ key: '609', value: '609' },
		{ key: '610', value: '610' },
		{ key: '611', value: '611' },
		{ key: '612', value: '612' },
		{ key: '613', value: '613' },
		{ key: '614', value: '614' },
		{ key: '615', value: '615' },
		{ key: '616', value: '616' },
		{ key: '617', value: '617' },
		{ key: '618', value: '618' },
		{ key: '619', value: '619' },
		{ key: '620', value: '620' },
		{ key: '621', value: '621' },
		{ key: '622', value: '622' },
		{ key: '623', value: '623' },
		{ key: '624', value: '624' },
		{ key: '625', value: '625' },
		{ key: '626', value: '626' },
		{ key: '627', value: '627' },
		{ key: '628', value: '628' },
		{ key: '629', value: '629' },
		{ key: '630', value: '630' },
		{ key: '631', value: '631' },
		{ key: '632', value: '632' },
		{ key: '633', value: '633' },
		{ key: '634', value: '634' },
		{ key: '635', value: '635' },
		{ key: '636', value: '636' },
		{ key: '637', value: '637' },
		{ key: '638', value: '638' },
		{ key: '639', value: '639' },
		{ key: '640', value: '640' },
		{ key: '641', value: '641' },
		{ key: '642', value: '642' },
		{ key: '643', value: '643' },
		{ key: '644', value: '644' },
		{ key: '645', value: '645' },
		{ key: '646', value: '646' },
		{ key: '647', value: '647' },
		{ key: '648', value: '648' },
		{ key: '649', value: '649' },
		{ key: '650', value: '650' },
		{ key: '651', value: '651' },
		{ key: '652', value: '652' },
		{ key: '653', value: '653' },
		{ key: '654', value: '654' },
		{ key: '655', value: '655' },
		{ key: '656', value: '656' },
		{ key: '657', value: '657' },
		{ key: '658', value: '658' },
		{ key: '659', value: '659' },
		{ key: '660', value: '660' },
	],
	creditRiskClass: [
		{ key: '1', value: 'Least Risk' },
		{ key: '2', value: 'Less Risk' },
		{ key: '3', value: 'Neutral' },
		{ key: '4', value: 'More Risk' },
		{ key: '5', value: 'Most Risk' },
		{ key: '0', value: 'Bankruptcy' },
	],

	creditRiskPercentile: [] as { key: string; value: string }[],
	creditRiskReasons: [
		{ key: 'C0066', value: 'C0066' },
		{ key: 'C0067', value: 'C0067' },
		{ key: 'C0392', value: 'C0392' },
		{ key: 'C1064', value: 'C1064' },
		{ key: 'C1065', value: 'C1065' },
		{ key: 'C1081', value: 'C1081' },
		{ key: 'C1175', value: 'C1175' },
		{ key: 'C1206', value: 'C1206' },
		{ key: 'C1209', value: 'C1209' },
		{ key: 'C1285', value: 'C1285' },
		{ key: 'C1324', value: 'C1324' },
		{ key: 'C1374', value: 'C1374' },
		{ key: 'C1381', value: 'C1381' },
		{ key: 'C1401', value: 'C1401' },
		{ key: 'C1500', value: 'C1500' },
		{ key: 'C3001', value: 'C3001' },
		{ key: 'C3007', value: 'C3007' },
		{ key: 'C3008', value: 'C3008' },
		{ key: 'G1209', value: 'G1209' },
		{ key: 'G4000', value: 'G4000' },
		{ key: 'G4001', value: 'G4001' },
		{ key: 'G4004', value: 'G4004' },
		{ key: 'H0661', value: 'H0661' },
		{ key: 'H0677', value: 'H0677' },
		{ key: 'H0819', value: 'H0819' },
		{ key: 'H0838', value: 'H0838' },
		{ key: 'H1195', value: 'H1195' },
		{ key: 'H1196', value: 'H1196' },
	],
	phoneScore: [] as { key: string; value: string }[],
	marketabilityScores: [
		{ key: 'M', value: 'Marketable' },
		{ key: 'A', value: 'Neutral' },
		{ key: 'OB', value: 'Non-Marketable' }
	],
};
export const equifaxBooleanValues = {
	minorityBusinessEnterprise: 'Minority Enterprise',
	womanBusinessEnterprise: 'Woman Enterprise',
	veteranBusiness: 'Veteran Business',
	governmentEntity: 'Government Entity',
	federalGovernmentEntity: 'Federal Government Entity',
	nonProfitEntity: 'Non-profit Organization',
	educationalInstitution: 'Educational Institution',
	foreignOwned: 'Foreign Owned',
	bankruptcy: 'Current or Historical Bankruptcy',
	sohoBusiness: 'SoHo Business',
	commercialLocation: 'Commercial Location',
	residentialLocation: 'Residential Location',
	dead: 'Out of Business',
	phoneVerified: 'Phone # Verified',
	phoneNotNull: 'Has Phone Number',
	emailNotNull: 'Has Email Address'
};

@Injectable({
	providedIn: 'root'
})
export class EquifaxSelectionService {

	public query = new EquifaxQuery();
	queryUpdates = new BehaviorSubject<EquifaxQuery>(new EquifaxQuery());
	addresses?: GooglePlaceResult[];
	addressUpdates = new BehaviorSubject<GooglePlaceResult[]>([]);
	constructor() { }

	getList(field: string) {
		return equifaxListValues[field as EquifaxListKey];
	}
	getSelections(field: string) {
		return this.query[field as EquifaxListKey];
	}

	getBooleanList(field: string) {
		return equifaxBooleanValues[field as EquifaxBooleanKey];
	}

	addToList(field: string, item: string) {
		if (!this.query[field as EquifaxListKey]) {
			this.query[field as EquifaxListKey] = [];
		}
		const index = this.query[field as EquifaxListKey]?.indexOf(item);
		if (index === undefined || index === -1) {
			this.query[field as EquifaxListKey]!.push(item);
			this.queryUpdates.next(this.query);
		}
	}

	addMultipleToList(field: string, item: string[]) {
		if (!this.query[field as EquifaxListKey]) {
			this.query[field as EquifaxListKey] = [];
		}
		let added = false;
		item.forEach(x => {
			if (this.query[field as EquifaxListKey]!.indexOf(x) === -1) {
				added = true;
				this.query[field as EquifaxListKey]!.push(x);
			}
		});
		if (added) {
			this.queryUpdates.next(this.query);
		}
	}

	removeFromList(field: string, item: string) {
		const list = this.query[field as EquifaxListKey];
		if (list !== undefined) {
			const indexToRemove = list.indexOf(item);
			if (indexToRemove !== -1) {
				list.splice(indexToRemove, 1);
				this.queryUpdates.next(this.query);
			}
		}
	}

	replaceList(field: string, item: string[]) {
		if (!this.query[field as EquifaxListKey]) {
			this.query[field as EquifaxListKey] = [];
		}
		this.query[field as EquifaxListKey] = [];
		this.query[field as EquifaxListKey]!.push(...item);
		this.queryUpdates.next(this.query);
	}

	setBoolean(field: string) {
		this.query[field as EquifaxBooleanKey] = true;
		this.queryUpdates.next(this.query);
	}

	removeBoolean(field: string) {
		this.query[field as EquifaxBooleanKey] = undefined;
		this.queryUpdates.next(this.query);
	}

	setAddressTradeArea(field: string, address: GooglePlaceResult, item: AddressTradeArea) {
		if (!this.query[field as EquifaxAddressKey]) {
			this.query[field as EquifaxAddressKey] = [];
		}
		if (this.addresses === undefined) {
			this.addresses = [];
		}
		const index = this.query[field as EquifaxAddressKey]?.findIndex(x => x.latitude === item.latitude && x.longitude === item.longitude
			&& x.areaType === item.areaType && x.areaSize === item.areaSize);
		if (index === undefined || index === -1) {
			this.query[field as EquifaxAddressKey]!.push(item);
			this.addresses.push(address);
			this.queryUpdates.next(this.query);
			this.addressUpdates.next(this.addresses);
		}
	}

	removeAddressTradeArea(field: string, placeId: string, tradeArea: AddressTradeArea) {
		let index = this.addresses?.findIndex(y => y.place_id === placeId);
		this.addresses?.splice(index!, 1);
		index = this.query[field as EquifaxAddressKey]?.findIndex(y => y.latitude === tradeArea.latitude && y.longitude === tradeArea.longitude &&
			y.areaSize === tradeArea.areaSize && y.areaType === tradeArea.areaType);
		this.query[field as EquifaxAddressKey]?.splice(index!, 1);
		this.queryUpdates.next(this.query);
		this.addressUpdates.next(this.addresses!);
	}
}
