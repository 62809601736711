import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PrintAndShipCategory } from '@taradel/web-api-client';
import { PrintAndShipService } from 'services/print-and-ship.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent {

	loading = false;
	submitted = false;
	categoryForm: UntypedFormGroup;
	constructor(
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		public printAndShipService: PrintAndShipService,
		private toastService: ToastService,
	) {
		this.categoryForm = this.formBuilder.group({
			name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
			channelPrefix: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
		});
	}

	async saveCategory(): Promise<void> {
		this.submitted = true;
		if (this.categoryForm.invalid) {
			return;
		}
		this.loading = true;
		try {
			const categoryId = await this.printAndShipService.addCategory(new PrintAndShipCategory({
				categoryId: 0,
				name: this.categoryForm.controls.name.value,
				channelPrefix: this.categoryForm.controls.channelPrefix.value,
			}));
			this.router.navigateByUrl(`printandship/categories/${categoryId}`);
		}
		catch (err: any) {
			this.toastService.showWarning(err.message, 'Error adding category');
		}
		finally {
			this.loading = false;
		}

	}

	get f() {
		return this.categoryForm.controls;
	}

}
