import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { USelectAddOns, USelectMethod } from '@taradel/web-api-client';
import { SitesService, DetailedUSelectAddOns } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-add-site-uselect',
	templateUrl: './add-site-uselect.component.html',
	styleUrls: ['./add-site-uselect.component.scss']
})

export class AddSiteUselectComponent implements OnInit, OnDestroy {

	allUSelectMethods: USelectMethod[] = [];
	newUSelectForm: UntypedFormGroup;
	newUSelectArray: USelectAddOns[] = [];
	newDetailedUSelectArray: DetailedUSelectAddOns[] = [];
	loading: boolean = false;
	siteId: number = 0;
	currentSite$ = new Subscription();

	constructor(private sitesService: SitesService,
		private formBuilder: UntypedFormBuilder,
		private toastService: ToastService,
		private router: Router) {
		this.newUSelectForm = this.formBuilder.group({
			uSelectId: [{ value: '', disabled: true }],
			uSelectName: ['', Validators.compose([Validators.required])],
			required: [false],
			sortOrder: [1]
		});
	}

	ngOnInit(): void {
		this.currentSite$ = this.sitesService.currentSite.subscribe(async site => {
			try {
				this.loading = true;
				await this.getAvailableUSelects(site!.siteId);
			}
			catch {
				this.toastService.showError('There was an error getting the USelects');
			}
			finally {
				this.loading = false;
			}

		});
	}

	ngOnDestroy(): void {
		this.currentSite$.unsubscribe();
	}

	async getAvailableUSelects(siteId: number) {
		let siteUSelectIds: any = [];
		const siteProducts = await this.sitesService.getSiteProducts(siteId);
		siteProducts.forEach(product => {
			product.baseProduct?.uSelectConfigurations?.forEach(config => {
				siteUSelectIds.push(config.uSelectId);
			});
		});
		this.allUSelectMethods = await this.sitesService.getAllUSelect();
		this.allUSelectMethods = this.allUSelectMethods.filter(x => siteUSelectIds.includes(x.uSelectId));
	}

	get formControls() {
		return this.newUSelectForm.controls;
	}

	async addNewUSelect() {
		try {
			this.loading = true;
			const newUSelect = {
				siteId: this.sitesService.currentSiteId,
				uSelectId: this.newUSelectForm.controls.uSelectId.value,
				addOnUSelectId: this.newUSelectForm.controls.uSelectId.value,
				required: this.newUSelectForm.controls.required.value,
				sortOrder: this.newUSelectForm.controls.sortOrder.value,
			} as USelectAddOns;

			await this.sitesService.addSiteUSelect(newUSelect);

			this.newUSelectForm.controls.uSelectId.setValue('');
			this.newUSelectForm.controls.uSelectName.setValue('');
			this.router.navigateByUrl(`sites/${this.sitesService.currentSiteId}/uselect-addons/${newUSelect.uSelectId}`);
		}
		catch (error: any) {
			if (error.status === 400) {
				this.toastService.showError('This USelect already exists for this site');
			}
			else {
				this.toastService.showError('There was an error adding the USelect');
			}
		}
		finally {
			this.loading = false;
		}
	}

	async getMethod(item: { id: number, name: string }) {
		try {
			this.loading = true;
			const siteUSelects = await this.sitesService.getSiteUSelectAddOns(this.sitesService.currentSiteId);
			const matchingUSelect = siteUSelects.find(x => x.uSelectId === item.id && x.addOnUSelectId === item.id && x.siteId === this.sitesService.currentSiteId);
			if (matchingUSelect) {
				this.toastService.showError('uSelect already exists for site');
				return;
			}
			this.newUSelectForm.controls.uSelectId.setValue(item.id);
			this.newUSelectForm.controls.uSelectName.setValue(item.name);
		}
		catch {
			this.toastService.showError('There was an error getting Site USelect AddOns');
		}
		finally {
			this.loading = false;
		}
	}
}
