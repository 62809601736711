import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Coupon, CouponClient } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

	private readonly couponClient: CouponClient;

	constructor(http: HttpClient) {
		this.couponClient = new CouponClient(http, environment.adminApiUrl);
	}

	getSiteCoupons(siteId: number): Promise<Coupon[]> {
		return lastValueFrom(this.couponClient.getSiteCoupons(siteId));
	}

	getCoupon(couponId: number): Promise<Coupon> {
		return lastValueFrom(this.couponClient.getCoupon(couponId));
	}

	async updateCoupon(coupon: Coupon): Promise<void> {
		await lastValueFrom(this.couponClient.updateCoupon(coupon));
	}

	createCoupon(coupon: Coupon): Promise<number> {
	  return lastValueFrom(this.couponClient.createCoupon(coupon));
	}

	async deleteCoupon(couponId: number): Promise<void> {
	  await lastValueFrom(this.couponClient.deleteCoupon(couponId));
	}
}
