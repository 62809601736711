<div id="sidebar" class="app-sidebar">
	<div class="app-sidebar-content" data-scrollbar="true" data-height="100%">
		<!-- BEGIN menu -->
		<div class="menu">
			<div class="menu-profile">
				<!-- <a href="javascript:;" class="menu-profile-link" data-toggle="app-sidebar-profile" data-target="#appSidebarProfileMenu"> -->
				<div class="menu-profile-cover with-shadow"></div>
				<div class="menu-profile-image">
					<img [src]="getGravatarLink()" />
				</div>
				<div class="menu-profile-info">
					<div class="d-flex align-items-center">
						<div class="flex-grow-1">
							{{ currUserProfile?.name }}
						</div>
						<!-- <div class="menu-caret ms-auto"></div> -->
					</div>
					<small>{{displayUserRoles(currUserProfile?.role)}}</small>
				</div>
				<!-- </a> -->
			</div>
			<!-- <div id="appSidebarProfileMenu" class="collapse">
				<div class="menu-item pt-5px">
					<a href="javascript:;" class="menu-link">
						<div class="menu-icon"><i class="fa fa-cog"></i></div>
						<div class="menu-text">Settings</div>
					</a>
				</div>
				<div class="menu-item">
					<a href="javascript:;" class="menu-link">
						<div class="menu-icon"><i class="fa fa-pencil-alt"></i></div>
						<div class="menu-text"> Send Feedback</div>
					</a>
				</div>
				<div class="menu-item pb-5px">
					<a href="javascript:;" class="menu-link">
						<div class="menu-icon"><i class="fa fa-question-circle"></i></div>
						<div class="menu-text"> Helps</div>
					</a>
				</div>
				<div class="menu-divider m-0"></div>
			</div> -->

			<!-- Render sideBarMenuItemTemplate (this is the main menu item wrapper that is rendered) -->
			<ng-template #sideBarMenuItemTemplate let-menu="menu">
				<div class="menu-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
					[ngClass]="{ 'expand': menu.state === 'expand', 'has-sub': hasVisibleSubmenu(menu), 'd-none': menu.hide }"
					#rla="routerLinkActive">
					<a class="menu-link" *ngIf="hasVisibleSubmenu(menu)"
						(click)="expandCollapseSubmenu(menu, menus, rla)">
						<ng-container *ngTemplateOutlet="sideBarMenuNavTemplate; context: {menu: menu}"></ng-container>
					</a>
					<a class="menu-link" *ngIf="!hasVisibleSubmenu(menu)" [routerLink]="menu.url">
						<ng-container *ngTemplateOutlet="sideBarMenuNavTemplate; context: {menu: menu}"></ng-container>
					</a>
					<div class="menu-submenu" *ngIf="hasVisibleSubmenu(menu)"
						[style.display]="(menu.state === 'expand') ? 'block' : (menu.state === 'collapse') ? 'none' : ''">
						<ng-container *ngTemplateOutlet="sideBarSubMenuNavTemplate; context: {menu: menu}">
						</ng-container>
					</div>
				</div>
			</ng-template>

			<!-- render sideBarMenuNavTemplate (This gets rendered INSIDE of the menu link) -->
			<ng-template #sideBarMenuNavTemplate let-menu="menu">
				<div class="menu-icon-img" *ngIf="menu.img"><img src="{{ menu.img }}" /></div>
				<div class="menu-icon" *ngIf="menu.icon"><i class="{{ menu.icon }}"></i></div>
				<div class="menu-text" *ngIf="menu.title">
					{{ menu.title }}
					<span class="menu-label" *ngIf="menu.label">{{ menu.label }}</span>
				</div>
				<div class="menu-badge" *ngIf="menu.badge">{{ menu.badge }}</div>
				<div class="menu-caret" *ngIf="hasVisibleSubmenu(menu)"></div>
			</ng-template>

			<!-- render sub-nav (This gets rendered for each sub-menu item) -->
			<ng-template #sideBarSubMenuNavTemplate let-menu="menu">
				<ng-container *ngFor="let subMenu of visibleSubmenus(menu)">
					<ng-container *ngTemplateOutlet="sideBarMenuItemTemplate; context: {menu: subMenu}"></ng-container>
				</ng-container>
			</ng-template>

			<!-- Start Navigation Sidebar Menu Rendering Loop (If all goes well, this will loop and render an indefinite series of levels deep) -->
			<ng-container *ngFor="let menu of menus">
				<ng-container *ngTemplateOutlet="sideBarMenuItemTemplate; context: {menu: menu}"></ng-container>
			</ng-container>

			<div class="menu-item" *ngIf="isSystemAdmin && auth.organizationId === 1">
				<button class="menu-link" (click)="launchSwagger(env?.webApiUrl)" target="_blank">
					<div class="menu-icon"><i class="fa fa-brackets-curly"></i></div>
					<div class="menu-text">Webapi Swagger</div>
				</button>
			</div>
            <div class="menu-item" *ngIf="isSystemAdmin && auth.organizationId === 1">
				<button class="menu-link" (click)="launchSwagger(env?.adminApiUrl)" target="_blank">
					<div class="menu-icon"><i class="fa fa-brackets-curly"></i></div>
					<div class="menu-text">AdminApi Swagger</div>
				</button>
			</div>
			<div class="menu-item" *ngIf="isSystemAdmin && auth.organizationId === 1">
				<button class="menu-link" (click)="launchHangfire()">
					<div class="menu-icon"><i class="fa fa-fire"></i></div>
					<div class="menu-text">Hangfire Dashboard</div>
				</button>
			</div>

			<!-- BEGIN minify-button -->
			<!-- BEGIN minify-button -->
			<div class="menu-item d-flex">
				<a href="javascript:;" class="app-sidebar-minify-btn ms-auto" (click)="toggleAppSidebarMinified()"><i
						class="fa fa-angle-double-left"></i></a>
			</div>
			<!-- END minify-button -->

			<!-- END menu -->

		</div>
	</div>
</div>
<div class="app-sidebar-bg"></div>
<div class="app-sidebar-mobile-backdrop"><a href="#" data-dismiss="app-sidebar-mobile" class="stretched-link"></a></div>
