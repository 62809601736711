import { Component, Input, OnInit } from '@angular/core';
import { EddmStatusReport } from '@taradel/admin-api-client';


@Component({
	selector: 'app-eddm-status-table',
	templateUrl: './eddm-status-table.component.html',
	styleUrls: ['./eddm-status-table.component.scss']
})
export class EddmStatusTableComponent {

	@Input() eddmStatus!: EddmStatusReport[];

	constructor() { }

	processNextDropDate(date: Date): string {
		let oneYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
		if (date < oneYearAgo) {
			return "";
		}
		else  {
			return date.toLocaleDateString("en-US");
		}
	}

}
