import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Printer, ProductOptionCategory, PrinterCostOfGoods, ProductPCOGSearch, PCOGKeyQuery, UpdatePCOGRequest } from '@taradel/admin-api-client';
import { ProductsService } from 'services/products.service';
import { PrintersService, PCOGSLayout, PCOGSLayoutItem } from 'services/printers.service';
import { ToastService } from 'services/toast.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-product-pcogs',
	templateUrl: './product-pcogs.component.html',
	styleUrls: ['./product-pcogs.component.scss']
})
export class ProductPcogsComponent implements OnInit {
	productId = 0;
	loading = false;
	pcogsSearchForm: UntypedFormGroup;
	pcogsLayout: PCOGSLayout[] = [];
	filterValues: { printerId: number, printerName: string }[] = [];
	printersToAddToProduct: Printer[] = [];
	newPrinterPCOG?: PrinterCostOfGoods;
	pcogToUpdate?: PrinterCostOfGoods;
	newPrinterSubmitted = false;
	productOptsByCat: ProductOptionCategory[] = [];
	editOptionsIndex: { printerId: number, index: number | undefined } = {
		printerId: 0,
		index: undefined
	};
	copyPCOGsQuery: PCOGKeyQuery[] = [];
	enableCopyPCOGs = false;
	printersForCopy: Printer[] = [];
	printerToReceivePCOGs?: number;
	modalRef?: NgbModalRef;

	constructor(private productsService: ProductsService,
		private printersService: PrintersService,
		private toastService: ToastService,
		private modalService: NgbModal,
		formBuilder: UntypedFormBuilder,
		route: ActivatedRoute) {
		route.paramMap.subscribe(params => {
			this.productId = parseInt(params.get('productId') ?? '0', 10);
		});

		this.pcogsSearchForm = formBuilder.group({
			productId: new FormControl<number>(this.productId),
			printerId: new FormControl<number>(0)
		});
	}

	get pcogSearch() {
		return this.pcogsSearchForm.controls;
	}

	getOptCatName(optCatId: number): string {
		return this.productOptsByCat.find(optCat => optCat.optCatId === optCatId)?.name ?? '';
	}

	getOptionName(optionId: number): string {
		const optCat = this.productOptsByCat.find(cat => cat.options?.some(opt => opt.optionId === optionId));
		return optCat?.options?.find(opt => opt.optionId === optionId)?.name ?? '';
	}

	async ngOnInit(): Promise<void> {
		await this.searchPCOGS();
		this.initializeNewPrinterPCOG();
		this.getFilterValues();
		this.productOptsByCat = await this.productsService.getProductOptionsByCategory(this.productId);
	}

	initializeNewPrinterPCOG() {
		this.newPrinterPCOG = {
			printerId: 0,
			minQty: 1000,
			maxQty: 2499,
			pricePerPiece: 0,
			prepPerPiece: 0,
			rangeMinCharge: undefined,
			standardManfufacturingTime: undefined,
			fastManufacturingTime: undefined,
			rushManufacturingTime: undefined,
			percentOfBudget: undefined,
			options: '',
			optionsList: undefined,
			postagePerPiece: undefined
		} as PCOGSLayoutItem;
	}

	async searchPCOGS() {
		this.loading = true;
		const search = {
			productId: this.pcogSearch.productId.value,
			printerId: this.pcogSearch.printerId.value
		} as ProductPCOGSearch;
		this.pcogsLayout = await this.printersService.getPCOGSLayoutForProduct(search);
		this.printersToAddToProduct = await this.printersService.getPrintersToAddToProduct(search);
		this.loading = false;
	}

	getFilterValues() {
		this.filterValues = [];
		if (this.pcogsLayout.length === 0) {
			return;
		}
		this.pcogsLayout.forEach(item => {
			const filterItem = {
				printerId: item.printerId,
				printerName: item.printerName
			};
			this.filterValues.push(filterItem);
		});
	}

	async updatePCOG(pCOG: PCOGSLayoutItem) {
		let success = true;
		if (!!this.pcogToUpdate && (pCOG.options !== this.pcogToUpdate?.options)) {
			try {
				const request = {
					newPCOG: {
						printerId: pCOG.printerId,
						productId: this.productId,
						minQty: pCOG.minQty,
						maxQty: pCOG.maxQty,
						pricePerPiece: pCOG.pricePerPiece ?? 0,
						prepPerPiece: pCOG.prepPerPiece ?? 0,
						rangeMinCharge: pCOG.rangeMinCharge,
						standardManfufacturingTime: pCOG.standardManfufacturingTime,
						fastManufacturingTime: pCOG.fastManufacturingTime,
						rushManufacturingTime: pCOG.rushManufacturingTime,
						percentOfBudget: pCOG.percentOfBudget,
						options: pCOG.options,
						postagePerPiece: pCOG.postagePerPiece
					} as PrinterCostOfGoods,
					existingPCOGQuery: {
						productId: this.pcogToUpdate.productId,
						printerId: this.pcogToUpdate.printerId,
						minQty: this.pcogToUpdate.minQty,
						maxQty: this.pcogToUpdate.maxQty,
						options: this.pcogToUpdate.options
					} as PCOGKeyQuery
				} as UpdatePCOGRequest;
				await this.printersService.updatePrinterCostOfGoods(request);
			}
			catch (error: any) {
				success = false;
				console.log(error);
				this.toastService.showError('There was an error updating the printer cost of goods');
			}
			finally {
				this.loading = false;
			}
			if (success) {
				this.pcogToUpdate = undefined;
			}
		}
		else {
			try {
				this.loading = true;
				const request = {
					newPCOG: {
					printerId: pCOG.printerId,
					productId: this.productId,
					minQty: pCOG.minQty,
					maxQty: pCOG.maxQty,
					pricePerPiece: pCOG.pricePerPiece ?? 0,
					prepPerPiece: pCOG.prepPerPiece ?? 0,
					rangeMinCharge: pCOG.rangeMinCharge,
					standardManfufacturingTime: pCOG.standardManfufacturingTime,
					fastManufacturingTime: pCOG.fastManufacturingTime,
					rushManufacturingTime: pCOG.rushManufacturingTime,
					percentOfBudget: pCOG.percentOfBudget,
					options: pCOG.options,
					postagePerPiece: pCOG.postagePerPiece
					} as PrinterCostOfGoods,
				existingPCOGQuery: undefined
				} as UpdatePCOGRequest;
				await this.printersService.updatePrinterCostOfGoods(request);
				this.toastService.showSuccess('The printer cost of goods was updated successfully');
			}
			catch {
				success = false;
				this.toastService.showError('There was an error updating the printer cost of goods');
			}
			finally {
				this.loading = false;
			}
		}
		if (success) {
			this.cancelEditOptions();
			await this.searchPCOGS();
		}
	}

	async removePCOG(pCOG: PrinterCostOfGoods) {
		try {
			const query = {
				printerId: pCOG.printerId,
				productId: pCOG.productId,
				minQty: pCOG.minQty,
				maxQty: pCOG.maxQty,
				options: pCOG.options ?? ''
			} as PCOGKeyQuery;
			this.loading = true;
			const res = await this.printersService.removePrinterCostOfGoods(query);
			if (res === 1) {
				this.toastService.showSuccess('The printer cost of goods was removed successfully');
				await this.searchPCOGS();
			}
		}
		catch {
			this.toastService.showError('There was an error removing the printer cost of goods');
		}
		finally {
			this.loading = false;
		}
	}

	async addNewPCOG(pcog: PCOGSLayoutItem) {
		if (pcog.minQty <= 0 || pcog.maxQty <= 0) {
			return;
		}
		try {
			this.loading = true;
			const newPCOG = {
				printerId: pcog.printerId,
				productId: pcog.productId,
				minQty: pcog.minQty,
				maxQty: pcog.maxQty,
				pricePerPiece: pcog.pricePerPiece ?? 0,
				prepPerPiece: pcog.prepPerPiece ?? 0,
				rangeMinCharge: pcog.rangeMinCharge,
				standardManfufacturingTime: pcog.standardManfufacturingTime,
				fastManufacturingTime: pcog.fastManufacturingTime,
				rushManufacturingTime: pcog.rushManufacturingTime,
				percentOfBudget: pcog.percentOfBudget,
				options: pcog.options,
				postagePerPiece: pcog.postagePerPiece
			} as PrinterCostOfGoods;
			const result = await this.printersService.addPrinterCostOfGoods(newPCOG);
			if (result > 0) {
				this.toastService.showSuccess('The printer cost of goods was added successfully');
				this.cancelEditOptions();
				await this.searchPCOGS();
			}
		}
		catch {
			this.toastService.showError('There was an error adding the printer cost of goods');
		}
		finally {
			this.loading = false;
		}
	}

	async addPrinterToProduct() {
		this.newPrinterSubmitted = true;
		let success = true;
		if (!!this.newPrinterPCOG && (!this.newPrinterPCOG.printerId ||this.newPrinterPCOG?.minQty <= 0 || this.newPrinterPCOG?.maxQty <= 0 || this.newPrinterPCOG?.prepPerPiece < 0 || this.newPrinterPCOG?.pricePerPiece < 0)) {
			return;
		}
		this.loading = true;
		try {
			const newPCOG = {
				printerId: this.newPrinterPCOG!.printerId,
				productId: this.productId,
				minQty: this.newPrinterPCOG!.minQty,
				maxQty: this.newPrinterPCOG!.maxQty,
				pricePerPiece: this.newPrinterPCOG!.pricePerPiece ?? 0,
				prepPerPiece: this.newPrinterPCOG!.prepPerPiece ?? 0,
				rangeMinCharge: this.newPrinterPCOG!.rangeMinCharge,
				standardManfufacturingTime: this.newPrinterPCOG!.standardManfufacturingTime,
				fastManufacturingTime: this.newPrinterPCOG!.fastManufacturingTime,
				rushManufacturingTime: this.newPrinterPCOG!.rushManufacturingTime,
				percentOfBudget: this.newPrinterPCOG!.percentOfBudget,
				options: this.newPrinterPCOG!.options,
				postagePerPiece: this.newPrinterPCOG!.postagePerPiece
			} as PrinterCostOfGoods;
			await this.printersService.addPrinterCostOfGoods(newPCOG);
		}
		catch (error: any) {
			console.log(error);
			success = false;
			this.toastService.showError('Printer with PCOG could not be added to product');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.toastService.showSuccess('New Printer with PCOG successfully added to product');
			this.cancelEditOptions();
			await this.searchPCOGS();
			this.initializeNewPrinterPCOG();
			this.getFilterValues();
		}
	}

	async removePrinterPCOGsFromProduct(printerId: number) {
		let success = true;
		this.loading = true;
		try {
			await this.printersService.removePrinterPCOGsFromProduct(this.productId, printerId);
		}
		catch (error: any) {
			success = false;
			console.log(error);
			this.toastService.showError('Printer PCOGs could not be removed from this product');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.toastService.showSuccess('Printer PCOGs successfully removed');
			this.cancelEditOptions();
			await this.searchPCOGS();
			this.initializeNewPrinterPCOG();
			this.getFilterValues();
		}
	}

	handleOptionSelected(optionsString: string, pcog: PrinterCostOfGoods) {
		pcog.options = optionsString;
	}

	displayPercentOfBudget(value: number | undefined): number | undefined {
		if (!!value && value < 1) {
			return value * 100;
		}
		else if (!!value && value > 1) {
			return value;
		}
		return undefined;
	}

	setPercentOfBudget(pcog: PrinterCostOfGoods, value: any) {
		pcog.percentOfBudget = parseInt(value);
	}

	validatePercentOfBudget(value: number): boolean {
		return value >= 1 && value <= 100;
	}

	enableEditOptions(pcog: PCOGSLayoutItem, index: number) {
		const printerId = pcog.printerId;
		this.editOptionsIndex = {
			printerId,
			index
		};
		if (!!pcog.optionsList) {
			this.pcogToUpdate = {
				printerId: pcog.printerId,
				productId: pcog.productId,
				minQty: pcog.minQty,
				maxQty: pcog.maxQty,
				pricePerPiece: pcog.pricePerPiece ?? 0,
				prepPerPiece: pcog.prepPerPiece ?? 0,
				rangeMinCharge: pcog.rangeMinCharge,
				standardManfufacturingTime: pcog.standardManfufacturingTime,
				fastManufacturingTime: pcog.fastManufacturingTime,
				rushManufacturingTime: pcog.rushManufacturingTime,
				percentOfBudget: pcog.percentOfBudget,
				options: pcog.options
			} as PrinterCostOfGoods;
		}
	}

	cancelEditOptions() {
		this.editOptionsIndex = {
			printerId: 0,
			index: undefined
		};
	}

	setEnableCopyPCOGs() {
		this.enableCopyPCOGs = !this.enableCopyPCOGs;
		if (this.enableCopyPCOGs === false) {
			this.copyPCOGsQuery = [];
			this.printerToReceivePCOGs = undefined;
		}
	}

	cancelCopyPCOGs() {
		this.enableCopyPCOGs = false;
		this.copyPCOGsQuery = [];
		this.printerToReceivePCOGs = undefined;
	}

	updateCopyPCOGsQuery(pcog: PCOGSLayoutItem) {
		const queryItem = {
			productId: this.productId,
			printerId: pcog.printerId,
			minQty: pcog.minQty,
			maxQty: pcog.maxQty,
			options: pcog.options
		} as PCOGKeyQuery;
		if (this.copyPCOGsQuery.some(item => item.printerId === queryItem.printerId && item.minQty === queryItem.minQty && item.maxQty === queryItem.maxQty && item.options === queryItem.options)) {
			const index = this.copyPCOGsQuery.findIndex(item => item === queryItem);
			this.copyPCOGsQuery.splice(index, 1);
		}
		else {
			this.copyPCOGsQuery.push(queryItem);
		}
	}

	async openCopyPCOGsModal(modal: any) {
		const printers = await this.printersService.getAllPrinters();
		this.printersForCopy = printers.filter(p => !this.copyPCOGsQuery.some(query => query.printerId === p.printerId));
		this.modalService.open(modal);
	}

	closeModal() {
		this.printerToReceivePCOGs = undefined;
		this.modalService.dismissAll();
	}

	async copyPCOGsToPrinter() {
		let success = true;
		this.loading = true;
		try {
			await this.printersService.copyPCOGsToPrinter(this.copyPCOGsQuery, this.printerToReceivePCOGs!);
		}
		catch (error: any) {
			success = false;
			console.log(error);
			this.toastService.showError('Could not copy selected PCOGs to the selected printer');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.toastService.showSuccess('PCOGs successfully copied to selected printer');
			this.closeModal();
			this.cancelCopyPCOGs();
			await this.searchPCOGS();
			this.getFilterValues();
		}
	}
}
