import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MapDataService } from 'services/map-data.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-map-paste-list',
	templateUrl: './map-paste-list.component.html',
	styleUrls: ['./map-paste-list.component.scss']
})

export class MapPasteListComponent implements OnInit {
	submitted: boolean = false;
	loading = false;
	customerId: number = 0;
	zipListForm: UntypedFormGroup;

	constructor(private route: ActivatedRoute,
		private router: Router,
		private formBuilder: UntypedFormBuilder,
		private mapDataService: MapDataService,
		private toastService: ToastService
		) {
			this.zipListForm = formBuilder.group({
				mapName: ['', Validators.required],
				residentialZips: new UntypedFormControl({value: true, disabled: true}),
				businessZips: [false],
				POBoxZips: [false],
				deliveryTypeChecked: [false, Validators.requiredTrue],
				zipCodesAndRoutesString: ['', Validators.compose([
					Validators.required
				])]
			});
		}

	ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			this.customerId = parseInt(params.get('customerId') ?? '0', 10);
		});
	}

	get form() {
		return this.zipListForm.controls;
	}

	async submitZips(): Promise<void> {
		try {
			this.submitted = true;
			let validDelivery = this.zipListForm.controls.residentialZips.value || this.zipListForm.controls.businessZips.value || this.zipListForm.controls.POBoxZips.value;
			this.zipListForm.controls['deliveryTypeChecked'].setValue(validDelivery);
			if (this.zipListForm.invalid) {
				return;
			}
			this.loading = true;
			const zipCodesAndRoutesArray = this.zipListForm.controls.zipCodesAndRoutesString.value.split(/,| |;|\r\n|\n/);
			await this.mapDataService.pasteRoutesFromAdmin(zipCodesAndRoutesArray, this.zipListForm.controls.businessZips.value, this.zipListForm.controls.POBoxZips.value, this.zipListForm.controls.mapName.value, this.customerId);
			this.zipListForm.reset();
			this.router.navigate(['/customers/' + this.customerId + '/distributions']);
		}
		catch (error) {
			this.toastService.showError('There was an error creating the map');
		}
		finally {
			this.loading = false;
			this.submitted = false;
		}
	}
}
