import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-printer-links',
  templateUrl: './printer-links.component.html',
  styleUrls: ['./printer-links.component.scss']
})
export class PrinterLinksComponent {

  @Input() printerId!: number;

  constructor() { }

}
