<ng-container *ngIf="customerId">
	<app-pagefullheader></app-pagefullheader>
	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="row">
			<div class="col-md-8">
				<div class="card p-3">
					<ng-container *ngIf="setPaymentAmount">
						<div class="card">
							<div class="card-header">
								<strong>Select Order(s) To Include In Schedule and Set Associated Payment Amount(s)</strong>
							</div>
							<div class="card-body">
								<ng-container *ngIf="!loading && !remainingOrderBalances; else ordersTable">
									<app-alert-box alertMode="info">
										No orders with balances remaining were found.
									</app-alert-box>
								</ng-container>
								<ng-template #ordersTable>
									<div class="form-check form-switch">
										<input class="form-check-input" type="checkbox" role="switch" id="checkAllBoxes" [ngModel]="selectAllOrders" (ngModelChange)="handleSelectAllOrders()" />
										<label class="form-check-label" for="checkAllBoxes"><strong>Select All Orders</strong></label>
									</div>
									<table class="table table-hover table-panel">
										<thead>
											<tr>
												<th>Include Order In Schedule</th>
												<th>Order Id</th>
												<th>Site</th>
												<th>Created Date</th>
												<th>Order Amount</th>
												<th class="text-danger">Order Balance</th>
												<th>Payment Amount</th>
											</tr>
										</thead>
										<tbody [formGroup]="paymentForm">
												<ng-container formArrayName="orderPayments">
													<ng-container *ngFor="let orderPayment of orderPayments; let i = index">
															<tr [formGroup]="orderPayment">
																<td>
																	<div class="form-check">
																		<input type="checkbox" id="'includeOrder' + balance.orderId" class="form-check-input" formControlName="selected" [checked]="orderPayment.get('selected')?.value" (change)="disablePaymentAmountInput(i)" />
																	</div>
																</td>
																<td>
																	{{ orderPayment.get('order')?.value.orderId }}
																</td>
																<td>
																	{{ orderPayment.get('order')?.value.affiliateId }}
																</td>
																<td>
																	{{ orderPayment.get('order')?.value.created | date:'short' }}
																</td>
																<td>
																	{{ orderPayment.get('order')?.value.orderAmt | currency }}
																</td>
																<td class="text-danger">
																	{{ orderPayment.get('order')?.value.orderBalance | currency }}
																</td>
																<td>
																	<div class="form-floating">
																		<input type="number" class="form-control" formControlName="paymentAmount" placeholder="orderPayment.get('paymentAmount')?.value" [readonly]="orderPayment.get('selected')?.value === false" />
																		<label>Set amount for payment</label>
																	</div>
																</td>
															</tr>
													</ng-container>
												</ng-container>
										</tbody>
										<tfoot>
											<tr>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
												<td>
													<strong>Total: {{ getTotalPaymentAmount() | currency }}</strong>
												</td>
											</tr>
										</tfoot>
									</table>
									<div class="row mt-3 d-flex justify-content-center">
										<div class="col col-auto">
											<button type="button" class="btn btn-small btn-primary" (click)="setPaymentAmount = false; definePaymentSchedule = true;" [disabled]="control.paymentTotal.value <= 0">
												Define Payment Schedule
											</button>
										</div>
									</div>
								</ng-template>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="definePaymentSchedule">
						<div class="card">
							<div class="card-header">
								<strong>Define Payment Schedule</strong>
							</div>
							<div class="card-body">
								<div class="row mb-3">
									<div class="col-lg-6 col mx-auto">
										<form [formGroup]="paymentScheduleForm">
											<div class="form-floating mb-2">
												<input type="date" class="form-control" id="startDate" formControlName="startDate"
													placeholder="Payment Start Date" min="{{ minDate }}"
													max="9999-12-31" [ngClass]="{ 'is-invalid': scheduleControl.startDate.dirty && scheduleControl.startDate.errors }">
												<label for="startDate">Payment Start Date</label>
												<div *ngIf="scheduleControl.startDate.dirty && scheduleControl.startDate.errors" class="input-error">
													Minimum start date is {{ minDate }}
												</div>
											</div>
											<div class="form-floating mb-2">
												<select class="form-select form-control" formControlName="interval">
													<option [value]="null" disabled>Set payment interval</option>
													<option [value]="'day'">Daily</option>
													<option [value]="'week'">Weekly</option>
													<option [value]="'month'">Monthly</option>
												</select>
											</div>
											<div class="form-floating mb-2">
												<input type="number" class="form-control" formControlName="frequency" placeholder="Set frequency of payments" />
												<label for="frequency">Set frequency of payments</label>
											</div>
											<div class="form-floating mb-2">
												<input type="number" class="form-control" formControlName="numberOfOccurrences" placeholder="Set total number of payments" />
												<label for="numberOfOccurrences">Set total number of payments</label>
											</div>
										</form>
									</div>
								</div>
								<div class="row mt-3 d-flex justify-content-center">
									<div class="col col-auto">
										<button type="button" class="btn btn-link" (click)="resetPaymentSchedule()">
											Go Back
										</button>
									</div>
									<div class="col col-auto">
										<button type="button" class="btn btn-small btn-primary" (click)="generateRecurringPaymentSchedule()" [disabled]="paymentScheduleForm.invalid">
											Review Payment Schedule
										</button>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="reviewPaymentSchedule">
						<div class="card">
							<div class="card-header">
								<strong>Review Payment Schedule</strong>
							</div>
							<div class="card-body">
								<div class="row mb-3">
									<div class="col-lg-6 col mx-auto">
										<ng-container *ngFor="let payment of paymentSchedule">
											<div class="row mb-3">
												<div class="col-auto mx-auto">
													{{ payment.description }} On {{ payment.dueOn }} For {{ payment.amount | currency }}
												</div>
											</div>
										</ng-container>
									</div>
								</div>
								<div class="row mb-3">
									<div class="col-lg-4 col mx-auto">
										<form [formGroup]="paymentForm">
											<div class="form-floating mb-3">
												<select type="select" formControlName="customerPaymentProfileId" class="form-select"
												[ngClass]="{ 'is-invalid': control.customerPaymentProfileId.dirty && control.customerPaymentProfileId.errors }">
													<option [value]="null" disabled>Select payment profile</option>
													<option *ngFor="let x of customerPaymentProfiles" value="{{ x.customerPaymentProfileId }}">
														{{ (x.creditCardMaskedData?.cardType ?? 'Checking') }} - {{ (x.creditCardMaskedData?.maskedCard ?? x.achMaskedData?.accountNumber) }} - {{x.fullname}}
													</option>
												</select>
												<div *ngIf="control.customerPaymentProfileId.errors && control.customerPaymentProfileId.dirty"
													class="input-error">
													<div *ngIf="control.customerPaymentProfileId.errors.required">
														Customer Payment Profile Id is required
													</div>
												</div>
											</div>
											<div class="form-floating mb-3">
												<input type="text" class="form-control" formControlName="note" maxlength="255" placeholder="Include a note" />
												<label>Include a note</label>
											</div>
											<div *ngIf="control.note.errors" class="input-error">
												Note should not exceed 255 characters
											</div>
										</form>
									</div>
								</div>
								<div class="row mt-3 d-flex justify-content-center">
									<div class="col col-auto">
										<button type="button" class="btn btn-link" (click)="definePaymentSchedule = true; reviewPaymentSchedule = false;">
											Go Back
										</button>
									</div>
									<div class="col col-auto">
										<button type="button" class="btn btn-small btn-primary" (click)="createRecurringPayment()" [disabled]="paymentForm.invalid">
											Create Recurring Payment
										</button>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</app-spinner-with-overlay>
</ng-container>
