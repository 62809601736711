<div class="container-fluid selectionsBackground">
	<app-spinner-with-overlay [showSpinner]="showSpinner">
		<div class="totalLeadsBox pt-3">
			<div *ngIf="totalLeads > 0">
				<h4 class="text-black">Total Leads</h4>
				<h2 class="text-black">{{ totalLeads | number }}</h2>
			</div>
			<div *ngIf="totalLeads === 0">
				<h4 class="text-black">Make a selection</h4>
				<h2 class="text-black">{{totalLeads}}</h2>
			</div>
			<button type="button" [disabled]="totalLeads === 0" class="btn btn-secondary adBuilderBtn"
			(click)="openModal(distributionModal)">Save and continue</button>
		</div>
	</app-spinner-with-overlay>
	<hr />

	<h3 class="text-black">Current Selections</h3>
	<app-list-selection [selectionService]="selectionService" title="States" queryField="states"></app-list-selection>
	<app-value-selection [selectionService]="selectionService" title="Zip Codes" queryField="zipCodes"></app-value-selection>
	<app-list-selection [selectionService]="selectionService" title="Metro Areas" queryField="metros"></app-list-selection>
	<app-address-selection [selectionService]="selectionService" title="Address" queryField="radiusQuery"></app-address-selection>
	<app-list-selection [selectionService]="selectionService" title="SIC Class" queryField="sicCodes" [showKeys]="true"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="NAICS Class" queryField="naicsCodes" [showKeys]="true"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Business Legal Status" queryField="businessLegalStatus"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Publicly Held" queryField="publicPrivateStatus"></app-list-selection>
	<app-toggle-selection [selectionService]="selectionService" title="Special Classification"
		queryFields="governmentEntity, federalGovernmentEntity, nonProfitEntity, educationalInstitution">
	</app-toggle-selection>
	<app-toggle-selection [selectionService]="selectionService" title="Business Ownership"
		queryFields="minorityBusinessEnterprise, womanBusinessEnterprise, veteranBusiness, foreignOwned">
	</app-toggle-selection>
	<app-list-selection [selectionService]="selectionService" title="Primary Contact Title" queryField="primaryContactTitle"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Primary Contact Gender" queryField="primaryContactGender"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Primary Contact Ethnicity" queryField="primaryContactEthnicity"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Business Size" queryField="businessSize"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Total Employees" queryField="corporateEmployeeCodes"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Location Employees" queryField="locationEmployeeCodes"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Overall Marketability Score" queryField="marketabilityScores"></app-list-selection>
	<app-slider-selection [selectionService]="selectionService" title="Credit Score" queryField="creditRiskScore"></app-slider-selection>
	<app-list-selection [selectionService]="selectionService" title="Business Credit Risk Class" queryField="creditRiskClass"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Business Failure Risk Rating" queryField="failureRating"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Supporting Reasons for Failure Risk Rating" queryField="failureReasons"></app-list-selection>
	<app-toggle-selection [selectionService]="selectionService" title="Business Failure" queryFields="bankruptcy, dead"></app-toggle-selection>
	<app-list-selection [selectionService]="selectionService" title="Corporate Revenue, Assets, or Sales" queryField="corporateAmountCodes"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Corporate Type of Amount Reported" queryField="corporateAmountType"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Corporate Precision of Amount Reported" queryField="corporateAmountPrecision"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Location Revenue, Assets, or Sales" queryField="locationAmountCodes"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Location Type of Amount Reported" queryField="locationAmountType"></app-list-selection>
	<app-list-selection [selectionService]="selectionService" title="Location Precision of Amount Reported" queryField="locationAmountPrecision"></app-list-selection>

	<app-list-preview (getSample)="getSample()" [query]="query" [totalLeads]="totalLeads"></app-list-preview>
</div>


<ng-template #distributionModal>
	<app-confirm-modal title="Save List" confirmText="Save List"
	(closeModal)="closeModals()" (confirmAction)="saveList()">
		<label>Map Name</label><br>
		<input type="text" [(ngModel)]='listName' class="form-control input-sm" maxlength="50" placeholder="List Name" />
	</app-confirm-modal>
</ng-template>
