<app-spinner-with-overlay [showSpinner]='loading'>
	<div class="panel panel-inverse">
		<div class="panel-heading">
			<h4 class="panel-title">Site Details</h4>
		</div>
		<div class="panel-body">
			<!-- Site Details Table-->
			<div class="table-responsive">
					<table class="table table-striped">
						<thead>
							<tr class="text-center">
								<th>Site Name</th>
								<th>BCC Order E-mails</th>
								<th>Hide Taradel Content</th>
							</tr>
						</thead>
						<tbody>
							<tr class="settingRow" *ngIf="siteDetailsFound">
								<td class="hoverHilight text-center" (click)="editModeSelected(updatedSiteDetails.siteDescription)">
									<div>
										<p *ngIf="!editModeOn || (editModeOn && valueToEdit !== siteDetails.siteDescription)">{{ updatedSiteDetails.siteDescription }}</p>
										<div *ngIf="editModeOn && valueToEdit === siteDetails.siteDescription">
											<input type="text" class="form-control" id="siteDescription" name="siteDescription" #siteDescription="ngModel" [(ngModel)]="updatedSiteDetails.siteDescription" >
										</div>
									</div>
								</td>
								<td class="hoverHilight text-center" (click)="editModeSelected(updatedSiteDetails.bccOrderEmails)">
									<div>
										<p *ngIf="!editModeOn || (editModeOn && valueToEdit !== siteDetails.bccOrderEmails)">{{ updatedSiteDetails.bccOrderEmails }}</p>
										<div *ngIf="editModeOn && valueToEdit === siteDetails.bccOrderEmails">
											<input type="text" class="form-control" id="siteBcc" name="siteBCC" #siteBcc="ngModel" [(ngModel)]="updatedSiteDetails.bccOrderEmails" >
										</div>
									</div>
								</td>
								<td class="hoverHilight text-center" (click)="editModeSelected(updatedSiteDetails.hideTaradelContent.toString())">
									<div>
										<p *ngIf="!editModeOn || (editModeOn && valueToEdit !== siteDetails.hideTaradelContent.toString())">{{ updatedSiteDetails.hideTaradelContent }}</p>
										<div *ngIf="editModeOn && valueToEdit === siteDetails.hideTaradelContent.toString()">
											<input type="checkbox" id="showTaradelContent" name="showTaradelContent" #showTaradelContent="ngModel" [(ngModel)]="updatedSiteDetails.hideTaradelContent" >
										</div>
									</div>
								</td>

							</tr>
							<tr>
								<td colspan="3" [hidden]="siteDetailsFound" class="alert alert-danger"> No Details found for this site.</td>
							</tr>
						</tbody>
					</table>
					<div *ngIf="editModeOn">
						<button class="btn btn-success" (click)="submitEditDetails()">Save</button>
						<button class="btn btn-danger" (click)="cancelEditDetails()">Cancel</button>
					</div>
			</div>
			<!-- End Site Config Table-->
		</div>
	</div>
</app-spinner-with-overlay>
