<div class="row mb-3">
	<div class="col">
		<h1 class="page-header m-0 p-0">
			<span class="fa-stack">
				<i class="fas fa-circle fa-stack-2x text-white"></i>
				<i class="fas fa-home fa-stack-1x text-success"></i>
			</span>
			Dashboard
		</h1>
	</div>
</div>

<div class="d-sm-flex align-items-center mb-3">
	<a class="btn btn-dark me-2 text-truncate" (click)="dp.toggle()">
		<i class="fa fa-calendar fa-fw text-white text-opacity-50 ms-n1"></i>
		<span>{{rangeStart | date:'longDate'}} - {{rangeEnd | date:'longDate'}}</span>
	</a>
	<input style="width: 0px; visibility: hidden;" type="text" ngbDatepicker #dp="ngbDatepicker" [firstDayOfWeek]="7" 
    	[autoClose]="false" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
	<ng-template #t let-date let-focused="focused">
	  <span class="custom-day"
			[class.focused]="focused"
			[class.range]="isRange(date)"
			[class.faded]="isHovered(date) || isInside(date)"
			(mouseenter)="hoveredDate = date"
			(mouseleave)="hoveredDate = null">
		{{ date.day }}
	  </span>
	</ng-template>

	<div class="text-gray-600 fw-bold mt-2 mt-sm-0">compared to <span id="daterange-prev-date">{{prevStart |
			date:'longDate'}} - {{prevEnd | date:'longDate'}}</span></div>
</div>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col-xl-3 col-md-6">
			<div class="widget widget-stats bg-teal">
				<div class="stats-icon stats-icon-lg"><i class="fa fa-user-friends fa-fw"></i></div>
				<div class="stats-content">
					<div class="stats-title">NEW ACCOUNTS</div>
					<div class="stats-number">{{curAccountsCreated | number}}</div>
					<div style="height: 36px;" class="mb-3">
						<app-chart-spark-line [chartOptions]="dailyAccountCreationsOpts"
							*ngIf="dailyAccountCreationsOpts"></app-chart-spark-line>
					</div>
					<div class="stats-desc">{{accountsCreatedPerf}} than last period ({{accountsCreatedPctChg |
						percent}})</div>
				</div>
			</div>
		</div>
		<div class="col-xl-3 col-md-6">
			<div class="widget widget-stats bg-blue">
				<div class="stats-icon stats-icon-lg"><i class="fa fa-map-marked-alt fa-fw"></i></div>
				<div class="stats-content">
					<div class="stats-title">DISTRIBUTIONS CREATED</div>
					<div class="stats-number">{{curDistCreated | number}}</div>
					<div style="height: 36px;" class="mb-3">
						<app-chart-spark-line [chartOptions]="dailyDistributionCreationsOpts"
							*ngIf="dailyDistributionCreationsOpts"></app-chart-spark-line>
					</div>
					<div class="stats-desc">{{distCreatedPerf}} than last period ({{distCreatedPctChg | percent}})</div>
				</div>
			</div>
		</div>
		<div class="col-xl-3 col-md-6">
			<div class="widget widget-stats bg-indigo">
				<div class="stats-icon stats-icon-lg"><i class="fa fa-shopping-cart fa-fw"></i></div>
				<div class="stats-content">
					<div class="stats-title">NEW ORDERS</div>
					<div class="stats-number">{{curOrderCreated | number}}</div>
					<div style="height: 36px;" class="mb-3">
						<app-chart-spark-line [chartOptions]="dailyOrderCountsOpts!" *ngIf="dailyOrderCounts">
						</app-chart-spark-line>
					</div>
					<div class="stats-desc">{{orderCreatedPerf}} than last period ({{orderCreatedPctChg | percent}})
					</div>
				</div>
			</div>
		</div>
		<div class="col-xl-3 col-md-6">
			<div class="widget widget-stats bg-green">
				<div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i></div>
				<div class="stats-content">
					<div class="stats-title">SALE AMOUNT</div>
					<div class="stats-number">{{curSaleAmount | currency}}</div>
					<div style="height: 36px;" class="mb-3">
						<app-chart-spark-line [chartOptions]="dailyOrderTotalsOpts" *ngIf="dailyOrderTotalsOpts">
						</app-chart-spark-line>
					</div>
					<div class="stats-desc">{{saleAmountPerf}} than last period ({{saleAmountPctChg | percent}})</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-lg-6">
			<div class="card border-0 mb-3 overflow-hidden bg-gray-800 text-white">
				<div class="card-body">
					<div class="row">
						<div class="col-xl-7 col-lg-8">
							<div class="mb-3 text-gray-500">
								<b>SALES OVERVIEW</b>
								<span class="ms-2">
									<i class="fa fa-info-circle" placement="top"
										ngbPopover="Gross sales (includes discounts, taxes and shipping). Includes orders from all sales channels."
										popoverTitle="Sales Overview" triggers="mouseenter:mouseleave"
										container="body"></i>
								</span>
							</div>

							<div class="row mb-1">
								<div class="col">
									<h2 class="mb-0">
										{{curSaleAmount | currency}}
									</h2>
									<div class="mb-3 text-gray-500">
										<i class="fa"
											[ngClass]="curSaleAmount > oldSaleAmount ? 'fa-caret-up' : 'fa-caret-down'"></i>
										{{saleAmountPctChgAbs | percent}} compared to previous
									</div>
								</div>
								<div class="col" style="height: 36px;">
									<app-chart-spark-line [chartOptions]="dailyOrderTotalsOpts"
										*ngIf="dailyOrderTotalsOpts"></app-chart-spark-line>
								</div>
							</div>

							<hr class="bg-white bg-opacity-50">

							<div class="row text-truncate">
								<div class="col-6">
									<div class=" text-gray-500">Total Orders</div>
									<div class="fs-18px mb-5px fw-bold">{{curOrderCreated | number}}</div>
									<div class="mb-3 text-gray-500">
										<i class="fa"
											[ngClass]="curOrderCreated >= oldOrderCreated ? 'fa-caret-up' : 'fa-caret-down'"></i>
										{{orderCreatedPctChgAbs | percent}} compared to previous
									</div>
									<div class="progress h-5px rounded-3 bg-gray-900 mb-5px">
										<div class="progress-bar progress-bar-striped rounded-right"
											[ngClass]="curOrderCreated > oldOrderCreated ? 'bg-teal' : 'bg-red'"
											[ngStyle]="{'width':orderCreatedPctChgAbs*100 + '%'}"></div>
									</div>
								</div>

								<div class="col-6">
									<div class=" text-gray-500">Average Order Value</div>
									<div class="fs-18px mb-5px fw-bold">{{curAov | currency}}</div>
									<div class="mb-3 text-gray-500">
										<i class="fa"
											[ngClass]="curAov >= oldAov ? 'fa-caret-up' : 'fa-caret-down'"></i>
										{{aovPctChgAbs | percent}} compared to previous
									</div>
									<div class="progress h-5px rounded-3 bg-gray-900 mb-5px">
										<div class="progress-bar progress-bar-striped rounded-right"
											[ngClass]="curAov > oldAov ? 'bg-teal' : 'bg-red'"
											[ngStyle]="{'width':aovPctChgAbs*100 + '%'}"></div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-xl-5 col-lg-4 align-items-center d-flex justify-content-center">
							<img src="../assets/total-sales-graphic.svg" height="150px" class="d-none d-lg-block">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg-6">
			<div class="row">
				<div class="col-md-6">

				</div>
				<div class="col-md-6">
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
