import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, FormGroup, FormArray } from '@angular/forms';
import { AdminPaymentRequest, OrderRemainingBalance, GetOrderBalanceQuery, OrderPaymentQuery, CustomerProfile, PaymentProfile, CreditCardPaymentRequest, PaymentRequest, AchPaymentRequest } from '@taradel/admin-api-client';
import { PaymentsService } from 'services/payments.service';
import { AuthenticationService } from 'services/authentication.service';
import { SalesApiService } from 'services/sales-api.service';
import { OrderService } from 'services/order.service';
import { ToastService } from 'services/toast.service';
import { PaymentType } from '@taradel/admin-api-client';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss']
})
export class CreatePaymentComponent implements OnInit {
	customerId?: number;
	loading = false;
	submitted = false;
	showInit? = true;
	setPaymentAmount = false;
	setBillingAddress = false;
	setPaymentType = false;
	createPaymentWithOrders?: boolean;
	beginWithOrders?: boolean;
	remainingOrderBalances?: OrderRemainingBalance[];
	paymentRequest?: AdminPaymentRequest;
	isSalesAdmin = false;
	customerProfile!: CustomerProfile;
	customerPaymentProfiles: PaymentProfile[] = [];
	paymentGatewaySiteId?: number;

	// all the forms
	paymentForm = this.formBuilder.group({
		paymentTotal: new FormControl<number | undefined>(0, Validators.required),
		includeOrders: new FormControl<boolean>(true),
		orderPayments: this.formBuilder.array([]),
		billingAddressId: new FormControl<number | undefined>(0, Validators.required),
		note: new FormControl<string | undefined>(undefined, Validators.maxLength(255)),
		paymentType: new FormControl<PaymentType | undefined>(undefined, Validators.required)
	});
	creditCardForm: FormGroup;
	customerProfileForm: FormGroup;
	paymentReferenceForm: FormGroup;
	achForm: FormGroup;
	orderId = 0;
	usePaymentProfile = false;
	PaymentType = PaymentType;
	creditCardYear: string[] = [];
	showCCNum = false;
	showCVV = false;
	paymentRefPlaceholder = '';
	selectAllOrders = true;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private paymentsService: PaymentsService,
		private authService: AuthenticationService,
		private salesApiService: SalesApiService,
		private orderService: OrderService,
		private toastService: ToastService,
		private formBuilder: UntypedFormBuilder,
		private location: Location
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);

		this.creditCardForm = this.formBuilder.group({
			creditCardNumber: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(13),
				Validators.maxLength(16)
			])),
			cvv: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(4)
			])),
			expirationMonth: new UntypedFormControl('', Validators.required),
			expirationYear: new UntypedFormControl('', Validators.required)
		});

		this.customerProfileForm = this.formBuilder.group({
			customerProfileId: new UntypedFormControl(undefined, Validators.required),
			customerPaymentProfileId: new UntypedFormControl(undefined, Validators.required)
		});

		this.paymentReferenceForm = this.formBuilder.group({
			paymentRef: new UntypedFormControl(undefined, Validators.required),
		});

		this.achForm = this.formBuilder.group({
			routingNumber: new UntypedFormControl(undefined, [Validators.required, Validators.minLength(9), Validators.maxLength(9)]),
			accountNumber: new UntypedFormControl(undefined, [Validators.required, Validators.minLength(5), Validators.maxLength(17)]),
			nameOnAccount: new UntypedFormControl(undefined, Validators.required),
			bankName: new UntypedFormControl(undefined, Validators.required),
		});
	}

	get control() {
		return this.paymentForm.controls;
	}

	get orderPayments() {
		return (this.paymentForm.controls['orderPayments'] as FormArray).controls as FormGroup[];
	}

	get ccControl() {
		return this.creditCardForm.controls;
	}
	get profileControl() {
		return this.customerProfileForm.controls;
	}
	get paymentRefControl() {
		return this.paymentReferenceForm.controls;
	}
	get achControl() {
		return this.achForm.controls;
	}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		this.isSalesAdmin = await this.authService.hasRole('SalesAdmin');
		const orderToExclude: number[] = [];
		const query = {
			customerId: this.customerId,
			ordersToExclude: orderToExclude
		} as GetOrderBalanceQuery;
		this.remainingOrderBalances = await this.orderService.getCustomerRemainingOrderBalance(query);

		let state: any;
		if (!!this.location.getState()) {
			state = this.location.getState();
		}
		else {
			state = undefined;
		}
		if (!!state  && !!state.orderId) {
			this.orderId = state.orderId;
		}
		if (this.orderId > 0) {
			this.showInit = false;
			this.createPaymentWithOrders = true;
			this.beginWithOrders = true;
			this.setPaymentAmount = true;
			this.setBillingAddress = false;
			this.remainingOrderBalances = this.remainingOrderBalances?.filter(x => x.orderId === this.orderId);
			this.initializePaymentAmount();
		}
		else {
			if (this.remainingOrderBalances) {
				this.createPaymentWithOrders = true;
			}
			else {
				this.createPaymentWithOrders = false;
			}
		}

		this.loading = false;
	}

	initializePaymentAmount() {
		if (this.createPaymentWithOrders) {
			if (this.orderPayments.length <= 0) {
				this.loading = true;
				this.remainingOrderBalances?.forEach(b => {
					const orderPayment = this.formBuilder.group({
						order: new UntypedFormControl(b, Validators.required),
						paymentAmount: new UntypedFormControl(b.orderBalance, Validators.compose([Validators.required, this.paymentAmountValidator(b.orderBalance)])),
						selected: new UntypedFormControl(true)
					});
					this.orderPayments.push(orderPayment);
				});
			}
			this.beginWithOrders = true;
			this.loading = false;
		}
		else {
			this.beginWithOrders = false;
			this.control.paymentTotal.setValue(0);
		}
		this.control.includeOrders.setValue(this.beginWithOrders);
		this.showInit = false;
		this.setPaymentAmount = true;
	}

	handleSelectAllOrders() {
		this.selectAllOrders = !this.selectAllOrders;
		if (this.selectAllOrders) {
			this.orderPayments.map(payment => payment.get('selected')?.setValue(true));
		}
		else {
			this.orderPayments.map(payment => payment.get('selected')?.setValue(false));
		}
	}

	getTotalPaymentAmount(): number {
		let total = 0;
		this.orderPayments.forEach(payment => {
			const orderPayment = payment as FormGroup;
			const selected = orderPayment.get('selected')?.value;
			const paymentAmount = orderPayment.get('paymentAmount')?.value;
			total += selected ? paymentAmount : 0;
		});
		this.control.paymentTotal.setValue(total);
		return total;
	}

	disablePaymentAmountInput(index: number) {
		const orderPayment = this.orderPayments.at(index) as FormGroup;
		if (orderPayment.get('selected')?.value === false) {
			orderPayment.get('paymentAmount')?.disable();
		}
		else {
			orderPayment.get('paymentAmount')?.enable();
		}
	}

	paymentAmountValidator(orderBalance: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			return (control.value <= orderBalance && control.value) > 0 ? null : { invalidPaymentAmount: true };
		};
	}

	selectBillingAddress(value: any) {
		this.control.billingAddressId.setValue(value);
	}

	async goToCreatePayment() {
		if (this.control.billingAddressId.value <= 0) {
			this.toastService.showError("Must select a billing address");
			return;
		}
		this.loading = true;
		this.setBillingAddress = false;
		this.setPaymentType = true;
		await this.getCustomerPaymentProfiles();
		if (this.customerPaymentProfiles.length) {
			this.setPaymentTypeToProfile();
		}
		else {
			this.selectPaymentType(PaymentType.CreditCard);
		}
		this.loading = false;
	}

	async getCustomerPaymentProfiles() {
		this.customerProfile = await this.salesApiService.getCustomerProfile(this.customerId!);

		if (!!this.customerProfile) {
			this.profileControl.customerProfileId.setValue(this.customerProfile.customerProfileId);
			this.customerPaymentProfiles = [];

			this.customerProfile.paymentProfiles?.forEach(x =>{
				if (this.customerPaymentProfiles.findIndex(a => a.creditCardMaskedData?.maskedCard === x.creditCardMaskedData?.maskedCard) === -1 ||
				this.customerPaymentProfiles.findIndex(a => a.achMaskedData?.accountNumber === x.achMaskedData?.accountNumber) === -1) {
					this.customerPaymentProfiles.push(x);
				}
			});
		}
	}

	selectPaymentType(type: PaymentType) {
		this.control.paymentType.setValue(type);
		if (type !== PaymentType.CreditCard && type !== PaymentType.ACH) {
			switch (type) {
				case PaymentType.Check:
					this.paymentRefPlaceholder = 'Enter check number';
				break;
				case PaymentType.CreditKeyPostAuth:
					this.paymentRefPlaceholder = 'Enter credit key order key';
				break;
				case PaymentType.MoneyOrder:
					this.paymentRefPlaceholder = 'Enter money order number';
				break;
				case PaymentType.Wire:
					this.paymentRefPlaceholder = 'Enter wire number';
				break;
				case PaymentType.Cash:
					this.paymentRefPlaceholder = '';
				break;
				case PaymentType.CustomerCredit:
					this.paymentRefPlaceholder = '';
				break;
				default:
					this.toastService.showError('Unaccepted payment type');
					break;
			}
		}
		else {
			this.paymentRefPlaceholder = '';
		}
		this.usePaymentProfile = false;
	}

	setPaymentTypeToProfile() {
		this.selectPaymentType(PaymentType.CreditCard);
		this.usePaymentProfile = true;
		if (this.customerPaymentProfiles.length <= 1) {
			const profileId = this.customerPaymentProfiles[0].customerPaymentProfileId;
			this.profileControl.customerPaymentProfileId.setValue(profileId);
		}
	}

	async createPayment() {
		this.submitted = true;
		const formIsValid = this.validatePaymentForm();
		if (!formIsValid) {
			this.toastService.showError('Payment form is invalid');
			return;
		}
		let queryArray: OrderPaymentQuery[] = [];
		if (this.control.includeOrders.value === true) {
			this.orderPayments.forEach((item: any) => {
				if (item.value.selected === true) {
					const query = {
						orderId: item.value.order.orderId,
						paymentAmount: item.value.paymentAmount
					} as OrderPaymentQuery;
					queryArray.push(query);
				}
			});
		}

		let request = new AdminPaymentRequest();
		const paymentType = this.control.paymentType.value;
		switch (paymentType) {
			case PaymentType.CreditCard:
				if (this.usePaymentProfile) {
					request = {
						customerId: this.customerId,
						paidAmount: this.control.paymentTotal.value,
						billingAddressId: this.control.billingAddressId.value,
						note: this.control.note.value !== '' ? this.control.note.value : undefined,
						paymentQuery: queryArray,
						paymentRequest: {
							paymentType: PaymentType.CreditCard,
							customerProfileId: this.profileControl.customerProfileId.value,
							customerPaymentProfileId: this.profileControl.customerPaymentProfileId.value,
							withTaradelFinancing: false,
						} as PaymentRequest
					} as AdminPaymentRequest;
				}
				else {
					request = {
						customerId: this.customerId,
						paidAmount: this.control.paymentTotal.value,
						billingAddressId: this.control.billingAddressId.value,
						note: this.control.note.value !== '' ? this.control.note.value : undefined,
						paymentQuery: queryArray,
						paymentRequest: {
							paymentType: PaymentType.CreditCard,
							creditCardPaymentRequest: {
								cardNumber: this.ccControl.creditCardNumber.value,
								cardCode: this.ccControl.cvv.value,
								expirationDate: this.ccControl.expirationMonth.value + this.ccControl.expirationYear.value.substring(2, 4)
							} as CreditCardPaymentRequest,
							withTaradelFinancing: false,
						} as PaymentRequest
					} as AdminPaymentRequest;
				}
			break;
			case PaymentType.ACH:

				request = {
					customerId: this.customerId,
					paidAmount: this.control.paymentTotal.value,
					billingAddressId: this.control.billingAddressId.value,
					note: this.control.note.value !== '' ? this.control.note.value : undefined,
					paymentQuery: queryArray,
					paymentRequest: {
						paymentType: PaymentType.ACH,
						achPaymentRequest: {
							accountNumber: this.achControl.accountNumber.value,
							bankName: this.achControl.bankName.value,
							nameOnAccount: this.achControl.nameOnAccount.value,
							routingNumber: this.achControl.routingNumber.value,
						} as AchPaymentRequest,
						withTaradelFinancing: false,
					} as PaymentRequest
				} as AdminPaymentRequest;
			break;
			case PaymentType.Check:
				request = {
					customerId: this.customerId,
					paidAmount: this.control.paymentTotal.value,
					billingAddressId: this.control.billingAddressId.value,
					note: this.control.note.value !== '' ? this.control.note.value : undefined,
					paymentQuery: queryArray,
					paymentRequest: {
						paymentType: PaymentType.Check,
						checkNumber: this.paymentRefControl.paymentRef.value,
						withTaradelFinancing: false,
					}
				} as AdminPaymentRequest;
			break;
			case PaymentType.CreditKeyPostAuth:
				request = {
					customerId: this.customerId,
					paidAmount: this.control.paymentTotal.value,
					billingAddressId: this.control.billingAddressId.value,
					note: this.control.note.value !== '' ? this.control.note.value : undefined,
					paymentQuery: queryArray,
					paymentRequest: {
						paymentType: PaymentType.CreditKeyPostAuth,
						creditKeyOrderKey: this.paymentRefControl.paymentRef.value,
						withTaradelFinancing: false,
					}
				} as AdminPaymentRequest;
			break;
			case PaymentType.MoneyOrder:
				request = {
					customerId: this.customerId,
					paidAmount: this.control.paymentTotal.value,
					billingAddressId: this.control.billingAddressId.value,
					note: this.control.note.value !== '' ? this.control.note.value : undefined,
					paymentQuery: queryArray,
					paymentRequest: {
						paymentType: PaymentType.MoneyOrder,
						moneyOrderNumber: this.paymentRefControl.paymentRef.value,
						withTaradelFinancing: false,
					}
				} as AdminPaymentRequest;
			break;
			case PaymentType.Wire:
				request = {
					customerId: this.customerId,
					paidAmount: this.control.paymentTotal.value,
					billingAddressId: this.control.billingAddressId.value,
					note: this.control.note.value !== '' ? this.control.note.value : undefined,
					paymentQuery: queryArray,
					paymentRequest: {
						paymentType: PaymentType.Wire,
						wireNumber: this.paymentRefControl.paymentRef.value,
						withTaradelFinancing: false,
					}
				} as AdminPaymentRequest;
			break;
			case PaymentType.Cash:
			request = {
				customerId: this.customerId,
				paidAmount: this.control.paymentTotal.value,
				billingAddressId: this.control.billingAddressId.value,
				note: this.control.note.value !== '' ? this.control.note.value : undefined,
				paymentQuery: queryArray,
				paymentRequest: {
					paymentType: PaymentType.Cash,
					withTaradelFinancing: false,
				}
			} as AdminPaymentRequest;
		break;
			default:
				this.toastService.showError('Unaccepted payment type');
				return;
		}
		this.loading = true;
		let newPaymentId = 0;
		try {
			newPaymentId = await this.paymentsService.createCustomerPayment(request);
		}
		catch (err: any) {
			console.log(err);
			this.toastService.showError(err.response);
		}
		finally {
			this.loading = false;
		}
		if (newPaymentId > 0) {
			this.router.navigate(['/customers', this.customerId, 'payments', newPaymentId]);
		}
	}

	validatePaymentForm(): boolean {
		if (!this.paymentForm.valid) {
			return false;
		}
		if (this.control.paymentType.value === PaymentType.CreditCard) {
			if (this.usePaymentProfile) {
				return this.customerProfileForm.valid;
			}
			else {
				return this.creditCardForm.valid;
			}
		}
		if (this.control.paymentType.value === PaymentType.ACH) {
			return this.achForm.valid;
		}
		if (this.control.paymentType.value === PaymentType.Cash) {
			return true;
		}
		return this.paymentReferenceForm.valid;
	}

	toggleShowCCNum() {
		this.showCCNum = !this.showCCNum;
	}

	toggleShowCVV() {
		this.showCVV = !this.showCVV;
	}

	public adjustYear(selectedMonth: string) {
		const month = new Date().getMonth();
		if (month >= parseInt(selectedMonth, 10)) {
			this.fillCreditCardYear(1);
		}
		else {
			this.fillCreditCardYear(0);
		}

	}

	private fillCreditCardYear(offset: number) {
		let year = new Date().getFullYear() + offset;
		this.creditCardYear = [];
		for (let i = 0; i < 10; i++) {
			this.creditCardYear.push(year.toString());
			year = year + 1;
		}
	}
}
