import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageSize, Template, TemplatesClient } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TemplateService {


	private readonly templatesClient: TemplatesClient;
	constructor(http: HttpClient) {
		this.templatesClient = new TemplatesClient(http, environment.adminApiUrl);
	}

	getPageSizes(): Promise<PageSize[]> {
		return lastValueFrom(this.templatesClient.getPageSizes());
	}

	getTemplate(artKey: number): Promise<Template> {
		return lastValueFrom(this.templatesClient.getTemplate(artKey));
	}
}
