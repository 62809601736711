import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CmsContentKey, CmsContentKeyWithSiteCounts, CmsContentType } from '@taradel/admin-api-client';
import { ContentService } from 'services/content.service';

@Component({
	selector: 'app-content-keys',
	templateUrl: './content-keys.component.html',
	styleUrls: ['./content-keys.component.scss']
})
export class ContentKeysComponent implements OnInit {
	loading: boolean = false;
	contentTypes = Object.entries(CmsContentType);
	allContentKeys: CmsContentKeyWithSiteCounts[] = [];
	filteredContentKeys: CmsContentKeyWithSiteCounts[] = [];
	keyFilterValue = '';
	contentKeyToDelete?: CmsContentKeyWithSiteCounts;
	contentKeyTypeToEdit?: CmsContentKeyWithSiteCounts;
	oldContentKeyTypeValue = '';
	newContentKeyTypeValue = '';
	originalContentKeys: CmsContentKeyWithSiteCounts[] = [];
	filterForNoContent = false;
	showNoContentFilter = false;

	constructor(private contentService: ContentService, private modalService: NgbModal) {

	}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		this.allContentKeys = await this.contentService.getContentKeysWithSiteCounts();
		this.originalContentKeys = JSON.parse(JSON.stringify(this.allContentKeys));
		this.filteredContentKeys = this.allContentKeys.filter(k => k.contentKey === k.contentKey);
		this.showNoContentFilter = this.filteredContentKeys.some(k => k.useCount <= 0);
		this.loading = false;
	}

	filterKeys($event: Event) {
		if (this.keyFilterValue.trim() !== '') {
			this.filteredContentKeys = this.allContentKeys.filter(k => k.contentKey!.toLowerCase().includes(this.keyFilterValue.toLowerCase()));
		}
		else {
			this.filteredContentKeys = this.allContentKeys.filter(k => k.contentKey === k.contentKey);
		}
	}

	filterKeysWithNoContent() {
		this.keyFilterValue = '';
		this.filterForNoContent = !this.filterForNoContent;
		if (this.filterForNoContent) {
			this.loading = true;
			this.filteredContentKeys = this.allContentKeys.filter(k => k.useCount <= 0);
			this.loading = false;
		}
		else {
			this.loading = true;
			this.filteredContentKeys = this.allContentKeys.filter(k => k.useCount <= 0 || k.useCount > 0);
			this.loading = false;
		}
	}

	async editContentKey() {
		await this.contentService.updateContentKey(new CmsContentKey({
			contentType: this.contentKeyTypeToEdit?.contentType!,
			contentKey: this.contentKeyTypeToEdit?.contentKey!
		}));
	}

	showEditSettingsModal(contentKey: CmsContentKeyWithSiteCounts, content: any) {
		this.contentKeyTypeToEdit = contentKey;
		this.newContentKeyTypeValue = contentKey.contentType!;
		this.oldContentKeyTypeValue = this.originalContentKeys.find(ck => ck.contentKey === contentKey.contentKey!)!.contentType;

		if (this.newContentKeyTypeValue.trim() !== this.oldContentKeyTypeValue.trim()) {
			this.modalOpen(content);
		}
	}

	modalOpen(content: any) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(async (result) => {
			if (result === 'edit') {
				await this.editContentKey();
			}
		});
	}
}
