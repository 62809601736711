<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="card">
			<form (ngSubmit)="search()">
				<div class="card-body">
					<h5 class="card-title">Search</h5>
					<div class="row">
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="date" class="form-control" id="startDate" name="startDate"
									[ngModel]="scheduledPaymentsQuery.startDate | date:'yyyy-MM-dd'"
									(ngModelChange)="parseDate($event, true)">
								<label for="startDate">Start Date</label>
							</div>
							<div class="form-floating mb-3">
								<input type="number" class="form-control" id="orderId" name="orderId"
									[(ngModel)]="scheduledPaymentsQuery.orderId">
								<label for="orderId">Order ID</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="date" class="form-control" id="toOrderDate" name="endDate"
									[ngModel]="scheduledPaymentsQuery.endDate | date:'yyyy-MM-dd'"
									(ngModelChange)="parseDate($event, false)">
								<label for="endDate">End Date</label>
							</div>
							<div class="form-floating mb-3">
								<input type="text" class="form-control" id="customerUsername" name="customerUsername"
									[(ngModel)]="scheduledPaymentsQuery.customerUsername">
								<label for="customerUsername">Customer Username</label>
							</div>
						</div>
						<div class="col-md-3">
							<strong>Payment Status</strong>
							<div class="row">
								<div class="col-auto">
									<div class="form-check">
										<input type="checkbox" id="success" class="form-check-input" #ngModel
										(click)="addPaymentStatus(cpaStatus.Success)">
										<label for="success" class="form-check-label success">Success</label>
									</div>
									<div class="form-check">
										<input type="checkbox" id="declined" class="form-check-input" #ngModel
										(click)="addPaymentStatus(cpaStatus.Declined)">
										<label for="declined" class="form-check-label declined">Declined</label>
									</div>
								</div>
								<div class="col-auto">
									<div class="form-check">
										<input type="checkbox" id="failed" class="form-check-input" #ngModel
										(click)="addPaymentStatus(cpaStatus.Failed)">
										<label for="failed" class="form-check-label failed">Failed</label>
									</div>
									<div class="form-check">
										<input type="checkbox" id="pending" class="form-check-input" #ngModel
										(click)="addPaymentStatus(cpaStatus.Pending)">
										<label for="pending" class="form-check-label pending">Pending</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-auto">
							<button class="btn btn-secondary" (click)="pastPaymentDue()">In the Past</button>
						</div>
						<div class="col-auto">
							<button class="btn btn-secondary" (click)="todaysPaymentDue()">Today</button>
						</div>
						<div class="col-auto">
							<button class="btn btn-secondary" (click)="futurePaymentDue()">In the Future</button>
						</div>
					</div>
					<button class="btn btn-primary mt-2">Search</button>
				</div>
			</form>
		</div>
		<div *ngIf="isSearchEnabled">
			<div class="d-flex justify-content-between align-items-end p-2">
				<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
					[pageSize]="scheduledPaymentsQuery.pagination!.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
					(pageChange)="search()"></ngb-pagination>
				<div class="form-floating">
					<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
						(change)="search()" aria-label="Items per page">
						<option [ngValue]="20">20 items per page</option>
						<option [ngValue]="50">50 items per page</option>
						<option [ngValue]="100">100 items per page</option>
					</select>
					<label for="pageSize">Items per page</label>
				</div>
			</div>
		</div>
		<ng-container *ngIf="scheduledPayments.length === 0 && !loading">
			<app-alert-box alertMode="info">
				No scheduled payments found.
			</app-alert-box>
		</ng-container>

		<ng-container *ngIf="scheduledPayments.length > 0">
			<div class="table-responsive">
				<table class="table table-hover table-panel">
					<thead>
						<tr>
							<th>Scheduled Payment Id</th>
							<th>Recurring Payment Id</th>
							<th>Amount</th>
							<th>Status</th>
							<th>Order Id</th>
							<th>Created By</th>
							<th>Payment Due Date</th>
							<th>Payment</th>
							<th>Payment Attempt</th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let scheduledPayment of scheduledPayments; let i = index;">
							<tr>
								<td>
									{{ scheduledPayment.scheduledPaymentId }}
								</td>
								<td>
									{{ scheduledPayment.recurringPaymentId }}
								</td>
								<td>
									{{ scheduledPayment.amount | currency }}
								</td>
								<td>
									<ng-container *ngIf="!!scheduledPayment.scheduledPaymentAttempts">
										<strong [ngClass]="{ 'success': scheduledPayment.status === cpaStatus.Success, 'declined': scheduledPayment.status === cpaStatus.Declined, 'failed': scheduledPayment.status === cpaStatus.Failed, 'pending': scheduledPayment.status === cpaStatus.Pending }">
											{{ scheduledPayment.status }}
										</strong>
									</ng-container>
								</td>
								<td>
									<a [routerLink]="['/orders', scheduledPayment.orderId, 'summary']" [target]="'_blank'" class="btn btn-link p-0">{{ scheduledPayment.orderId }}</a>
								</td>
								<td>
									{{ scheduledPayment.createdBy }}
								</td>
								<td>
									{{ scheduledPayment.paymentDueDate | date }}
								</td>
								<td>
									<ng-container *ngIf="scheduledPayment.status === cpaStatus.Success">
										<a [routerLink]="['/customers', scheduledPayment.customer!.customerID, 'payments', scheduledPayment.customerPaymentId]" [target]="'_blank'" class="btn btn-link p-0">{{ scheduledPayment.customerPaymentId }}</a>
									</ng-container>
								</td>
								<td>
									<ng-container *ngIf="(!!scheduledPayment.scheduledPaymentAttempts && scheduledPayment.scheduledPaymentAttempts.length > 0) && scheduledPayment.scheduledPaymentAttempts![0].customerPaymentAttempt">
										<a [routerLink]="['/customers', scheduledPayment.customer!.customerID, 'payment-attempts', scheduledPayment.scheduledPaymentAttempts![0].customerPaymentAttemptId]" [target]="'_blank'" class="btn btn-link p-0">{{ scheduledPayment.scheduledPaymentAttempts![0].customerPaymentAttemptId }}</a>
									</ng-container>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
		</ng-container>
	</div>
</app-spinner-with-overlay>
