<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card p-3 mb-3">
		<form [formGroup]="searchForm" (ngSubmit)="search()">
			<div class="row">
				<div class="col-md-4 col-8">
					<div class="form-floating">
						<input type="text" id="username" class="form-control"
							formControlName="username" placeholder="Search by username">
						<label for="username">Search by username</label>
					</div>
				</div>
				<div class="col-auto">
					<button class="btn btn-primary" [disabled]="loading">Search</button>
				</div>
				<div *ngIf="filtered" class="col-auto">
					<button class="btn btn-danger" (click)="resetFilter()">Reset Filter</button>
				</div>
			</div>
		</form>
		<ng-container *ngIf="(customers.length || users.length) && !loading">
			<div *ngIf="customers.length && !loading; else noCustomersFound" class="row mt-3">
				<div class="col">
					<div class="row">
						<div class="col">
							<div class="table-responsive">
								<table class="table table-hover table-panel caption-top">
									<caption>
										<strong>pnd_Customer results</strong>
									</caption>
									<thead>
										<tr>
											<th class="col-2">Username</th>
											<th class="col-2">Email Address</th>
											<th class="col-3">Customer Login Details</th>
											<th class="col-3">Identity User Details</th>
											<th class="col-2"></th>
										</tr>
									</thead>
									<tbody class="table-group-divider">
										<tr *ngFor="let customer of customers">
											<td>
												{{ customer.username }}
											</td>
											<td>
												{{ customer.emailAddress }}
											</td>
											<td>
												<div *ngIf="customer.login && (customer.login.customerId || customer.login.userId); else noLoginFound">
													<p>Customer ID: {{ customer.login?.customerId }}</p>
													<p>Identity User ID: {{ customer.login?.userId }} </p>
												</div>
											</td>
											<td>
												<div *ngIf="customer.login && customer.login.user; else noUserFound">
													<p>ID: {{ customer.login?.user?.id }}</p>
													<p>Username: {{ customer.login?.user?.userName }} <br /> ({{ customer.login?.user?.normalizedUserName }})</p>
													<p>Email Address: {{ customer.login?.user?.email }} <br /> ({{ customer.login?.user?.normalizedEmail }})</p>
												</div>
											</td>
											<td>
												<div class="row">
													<div class="col">
														<a [routerLink]="['/customers', customer.customerId]" target="_blank" role="button" class="btn btn-sm btn-primary mx-2" data-toggle="tooltip" data-placement="top" title="Go to customer page">
															<i class="fa fa-external-link"></i>
														</a>
													</div>
													<div class="col">
														<button class="btn btn-sm btn-success mx-2" data-toggle="tooltip" data-placement="top" title="Change Customer Email Address" (click)="openUpdateEmailAddressModal(customer, updateEmailAddressModal)">
															<i class="fa fa-edit"></i>
														</button>
													</div>
													<div class="col">
														<button *ngIf="!customer.login" class="btn btn-sm btn-success mx-2" data-toggle="tooltip" data-placement="top" title="Create Customer Login" (click)="openCreateLoginModal(customer, createLoginModal)">
															<i class="fa fa-plus"></i>
														</button>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="users.length && !loading; else noIdentityUsersFound" class="row mt-3">
				<div class="col">
					<div class="row">
						<div class="col">
							<div class="table-responsive">
								<table class="table table-hover table-panel caption-top">
									<caption>
										<strong>AspNetUsers results</strong>
									</caption>
									<thead>
										<tr>
											<th class="col-2">Username</th>
											<th class="col-2">Email Address</th>
											<th class="col-3">Customer Login Details</th>
											<th class="col-3">Customer Details</th>
											<th class="col-2"></th>
										</tr>
									</thead>
									<tbody class="table-group-divider">
										<tr *ngFor="let user of users">
											<td>
												<p>{{ user.userName }} <br />({{ user.normalizedUserName}})</p>
											</td>
											<td>
												<p>{{ user.email }} <br />({{ user.normalizedEmail }})</p>
											</td>
											<td>
												<div *ngIf="user.customer && user.customer.login && (user.customer.login.customerId || user.customer.login.userId); else noLoginFound">
													<p>Customer ID: {{ user.customer.login.customerId }}</p>
													<p>Identity User ID: {{ user.customer.login.userId }}</p>
												</div>
											</td>
											<td>
												<div *ngIf="user.customer; else noUserFound">
													<p>ID: {{ user.customer.customerId }} </p>
													<p>Username: {{ user.customer.username }}</p>
													<p>Email Address: {{ user.customer.emailAddress }}</p>
												</div>
											</td>
											<td>
												<div class="row">
													<div class="col">
														<a [routerLink]="['/customers', user.customer?.customerId]" target="_blank" role="button" class="btn btn-sm btn-primary mx-2" data-toggle="tooltip" data-placement="top" title="Go to customer page">
															<i class="fa fa-external-link"></i>
														</a>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="customers.length === 0 && users.length === 0 && !filtered && !loading">
			<div class="mt-3">
				<app-alert-box alertMode="default">
					Search for a customer/user by their username above
				</app-alert-box>
			</div>
		</ng-container>
		<ng-container *ngIf="customers.length === 0 && users.length === 0 && filtered && !loading">
			<div class="mt-3">
				<app-alert-box alertMode="default">
					No records were found in pnd_Customer or AspNetUsers with that username/email address
				</app-alert-box>
			</div>
		</ng-container>
	</div>
</app-spinner-with-overlay>

<ng-template #noCustomersFound>
	<small class="text-danger">
		No records were found in pnd_Customer with that username or email address
	</small>
</ng-template>
<ng-template #noLoginFound>
	<small class="text-danger">
		No CustomerLogin record was found with this username
	</small>
</ng-template>
<ng-template #noUserFound>
	<small class="text-danger">
		No Identity User record was found with this username
	</small>
</ng-template>
<ng-template #noIdentityUsersFound>
	<small class="text-danger">
		No records were found in AspNetUsers with that username or email address
	</small>
</ng-template>

<ng-template #updateEmailAddressModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Change Customer's Username and Email Address</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
			(click)="modal.close('Cross click')"></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="emailAddressForm">
			<div class="row mb-3">
				<div class="col-12">
					<div class="form-floating">
						<input type="text" class="form-control" formControlName="currentUsername" placeholder="Current Username" />
						<label for="currentUsername">Current Username</label>
					</div>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-12">
					<div class="form-floating">
						<input type="text" class="form-control" formControlName="currentEmailAddress" placeholder="Current Email Address" />
						<label for="currentEmailAddress">Current Email Address</label>
					</div>
				</div>
			</div>
			<hr />
			<div class="row mb-3">
				<div class="col-12">
					<div class="form-floating">
						<app-email-input
							[ngClass]="{ 'is-invalid': emailAddressForm.touched && ea.newEmailAddress.errors }"
							[showErrors]="true"
							[required]="true"
							[errorMessages]="{'required': 'Email is required', 'format': 'Enter valid email address'}"
							formControlName="newEmailAddress"
							placeholder="New Email Address"></app-email-input>
					</div>
				</div>
			</div>
			<div *ngIf="errorMessage !== ''" class="row mb-3">
				<div class="col-12 input-error">
					{{ errorMessage }}
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link" (click)="closeModal()">Cancel</button>
		<button type="button" class="btn btn-success btn-lg" [disabled]="emailAddressForm.invalid" (click)="updateEmailAddress()">Update</button>
	</div>
</ng-template>

<ng-template #createLoginModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Create Customer Login</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
			(click)="modal.close('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">Are you sure you want to create a Customer Login record for <span class="text-primary">"{{ cl.username.value }}"</span>?</p>
		<div *ngIf="errorMessage !== ''" class="row mb-3">
			<div class="col-12 input-error">
				{{ errorMessage }}
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link" (click)="closeModal()">Cancel</button>
		<button type="button" class="btn btn-success btn-lg" (click)="createCustomerLogin()">Create Login</button>
	</div>
</ng-template>
