import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-links',
  templateUrl: './product-links.component.html',
  styleUrls: ['./product-links.component.scss']
})
export class ProductLinksComponent {

  @Input() productId!: number;
  constructor() { }

}
