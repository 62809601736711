<div class="btn-group">
	<a type="button" class="btn btn-primary" [routerLink]="['/products', productId]">Edit</a>
	<button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
		<span class="visually-hidden">Toggle Dropdown</span>
	  </button>
	<ul class="dropdown-menu" role="menu">
		<li role="menuitem"><a routerLink="/products/{{ productId }}/options" class="dropdown-item">Options</a></li>
		<li role="menuitem"><a routerLink="/products/{{ productId }}/pricing" class="dropdown-item">Pricing</a></li>
		<li role="menuitem"><a routerLink="/products/{{ productId }}/printer-cost-of-goods" class="dropdown-item">Printer Cost Of Goods</a></li>
	</ul>
</div>
