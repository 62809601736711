import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer, ShoppingCart, Site, TransferCartQuery, SiteFilterRequest } from '@taradel/admin-api-client';
import { AuthenticationService } from 'services/authentication.service';
import { CustomerService } from 'services/customer.service';
import { USelect } from 'services/distributions.service';
import { SalesApiService } from 'services/sales-api.service';
import { SiteConfigService } from 'services/site-config.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';
import { environment } from 'environment';

@Component({
	selector: 'app-shopping-carts',
	templateUrl: './shopping-carts.component.html',
	styleUrls: ['./shopping-carts.component.scss']
})
export class ShoppingCartsComponent implements OnInit {

	@Input() customerId = 0;
	@Output() migrationStarted = new EventEmitter();
	@Output() migrationEnded = new EventEmitter();

	selectedSiteId = 0;
	customer!: Customer;
	userHasShoppingCarts = false;
	customerCarts: ShoppingCart[] = [];
	selectedCart?: ShoppingCart;
	loading = false;
	sites: Site[] = [];
	errorText = '';
	isSalesTeam = false;
	modalRef?: any;
	USelect = USelect;
	isCa = environment.instance === 'Canada';

	constructor(
		route: ActivatedRoute,
		private router: Router,
		private sitesService: SitesService,
		private salesService: SalesApiService,
		public authService: AuthenticationService,
		private toastService: ToastService,
		private modalService: NgbModal,
		private siteConfigService: SiteConfigService,
		private customerService: CustomerService) {
			this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		try {
			this.sites = await this.sitesService.getSitesForAdmin(new SiteFilterRequest({
				adminCheckout: true,
				showInactive: false
			}));
			this.customer = await this.customerService.getCustomer(this.customerId);
			await this.getCustomerCarts();
			this.isSalesTeam = await this.authService.hasRole('SalesTeam');
		}
		catch (error: any) {
			if (error.status !== 404) {
				this.toastService.showError('There was a problem loading the customer shopping carts', 'Load Error');
			}
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	getSiteName(siteId: number): string {
		return this.sites.find(x => x.siteId === siteId)!.name!;
	}

	async getCustomerCarts() {
		this.loading = true;
		this.customerCarts = await this.salesService.getCustomerShoppingCarts(this.customerId);
		if (this.customerCarts.length > 0) {
			this.userHasShoppingCarts = true;
			this.customerCarts.sort((a, b) => (b.modifiedDate?.valueOf() ?? 0) - (a.modifiedDate?.valueOf() ?? 0));
		}
		this.loading = false;
	}

	viewCart(cart: ShoppingCart) {
		const existingDistributionId = cart.cartData?.find(x => x.distributionId !== 0)?.distributionId ?? 0;
		if (existingDistributionId > 0) {
			return `/customers/${this.customerId}/shop/${cart.siteId}/${existingDistributionId}`;
		}
		return `/customers/${this.customerId}/shop/${cart.siteId}`;
	}

	getCartPrice(cart: ShoppingCart): number {
		return cart.cartData?.reduce((sum, current) => sum + (current.price + current.designFee + current.creativeSetupFee + current.dropFee - current.ventureOneDiscount), 0)!;
	}

	proceedToSiteSelection(siteModal: any): void {
		this.selectedSiteId = this.customer.affiliateID;
		this.modalService.open(siteModal, { windowClass: "updateModalClass" });
	}

	async startOrder(): Promise<void> {
		this.loading = true;
		this.modalService.dismissAll();
		await this.router.navigate([`/customers/${this.customerId}/shop/${this.selectedSiteId}`]);
	}

	siteChanged(siteId: number) {
		this.selectedSiteId = siteId;
	}

	openModal(modal: any, cart: ShoppingCart) {
		this.selectedCart = cart;
		this.modalRef = this.modalService.open(modal, { windowClass: "updateModalClass" });
	}

	closeModal() {
		this.modalService.dismissAll();
		this.modalRef.close();
	}

	async initiateTransfer(data: {customerId: number, deleteData: boolean}) {
		try {
			this.loading = true;
			this.migrationStarted.emit();
			// Make the api call to transfer
			await this.salesService.transferShoppingCart(new TransferCartQuery({
				originalCustomerId: this.customerId,
				siteId: this.selectedCart!.siteId,
				targetCustomerId: data.customerId,
				deleteData: data.deleteData
			}));
			this.toastService.showSuccess('Cart migrated successfully');
		}
		catch (error) {
			this.toastService.showError('There was a problem migrating the cart');
			console.log(error);
		}
		finally {
			this.closeModal();
			// refresh the list
			await this.getCustomerCarts();
			this.loading = false;
			this.migrationEnded.emit();
		}
	}
}
