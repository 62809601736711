import { Component } from '@angular/core';
import { Drop } from '@taradel/web-api-client';
import { OrderlayoutService } from 'services/orderlayout.service';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'services/toast.service';
import { environment } from 'environment';

@Component({
  selector: 'app-snap-delivery-drops',
  templateUrl: './snap-delivery-drops.component.html',
  styleUrls: ['./snap-delivery-drops.component.scss']
})
export class SnapDeliveryDropsComponent {
	loading: boolean = false;
	numberOfDrops = 1;
	today = new Date();
	apiUrl = environment.webApiUrl;
	selectedDropNum ?: number;
	disableBtn = false;

	constructor(
		public orderLayoutService: OrderlayoutService,
		public modalService: NgbModal,
		private toastService: ToastService) {
			if (this.orderLayoutService.selectedOrderItemDistributions?.length ?? 0 > 0) {
				this.numberOfDrops = this.orderLayoutService.selectedOrderItemDistributions.length;
			}
			else if (this.orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops?.length ?? 0 > 0) {
				this.numberOfDrops = this.orderLayoutService.selectedOrderItemAsCartProduct?.drops?.orderDrops?.length!;
			}
	}

	dropToBeFixed(dropNumber: number): boolean {
		return this.orderLayoutService.dropsToFix.some(num => num === dropNumber);
	}

	isInPast(drop: Drop): boolean {
		const now = new Date();
		if (drop.date !== undefined && drop.date < now) {
			return true;
		}
		return false;
	}

	async setDropDate(dropNumber: number, date: NgbDate) {
		try {
			this.loading = true;
			this.orderLayoutService.selectedOrderItemDropDateMap.set(dropNumber + 1, date);
			this.orderLayoutService.selectedOrderItemAsCartProduct!.drops!.orderDrops![dropNumber].date = new Date(date.year, date.month - 1, date.day);
			await this.orderLayoutService.getDropDeliveryRange();
		}
		catch (error) {
			this.toastService.showError(`Getting Drop delivery range failed - ${error}`);
		}
		finally {
			this.loading = false;
		}
    }

	getDropTotal(dropNumber: number): number {
		if (!this.orderLayoutService.snapAdMailDistributions[dropNumber]) {
			return 0;
		}
		const selections = this.orderLayoutService.snapAdMailDistributions[dropNumber].letterCarrierWalks;
		if (!selections || selections.length === 0) {
			return 0;
		}

		const dropTotals = selections.map(cur => {
			return cur.total;
		});

		return dropTotals.reduce((runningTotal: number = 0, curVal: number) => {
			return runningTotal + curVal;
		});
	}

	async updateDropDate(dropNumber: number) {
		if (!this.orderLayoutService.snapAdMailDistributions![dropNumber].letterCarrierWalks) {
			this.toastService.showWarning('I should be splitting the dist into drops, but I am not!');
		}
		this.disableBtn = true;
		const cartProduct = this.orderLayoutService.selectedOrderItemAsCartProduct!;
		const drops = cartProduct.drops;


		const missingDropDates = drops?.orderDrops?.some(x => x.number === dropNumber && (x.date === undefined || isNaN(x.date.getDate())));
		if (missingDropDates) {
			this.toastService.showError('Specify drop dates for your drop(s)');
			this.disableBtn = false;
			return;
		}

		try {
			this.loading = true;
			await this.orderLayoutService.updateOrderItemDrops(dropNumber);
			this.toastService.showSuccess(`Drop ${dropNumber} updated successfully`);
			this.loading = false;
		}
		catch (error) {
			this.toastService.showError(`Drop ${dropNumber} updated failed`);
		}
		finally {
			this.disableBtn = false;
			this.loading = false;
		}
	}

	async updateDropDistribution(distributionId: number) {
		try {
			this.loading = true;
			await this.orderLayoutService.updateOrderItemDropDistribution(distributionId, this.selectedDropNum!);
			this.toastService.showSuccess('Distribution updated successfully');
		}
		catch (error: any) {
			if (error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError('There was a problem updating the order', 'Load Error');
			}
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

	async sendDropRouteCsv(dropNumber: number) {
		try {
			this.loading = true;
			const success = await this.orderLayoutService.sendDropRouteCsv(dropNumber);
			if (success) {
				this.toastService.showSuccess('Drop Route CSV sent successfully');
			}
			else {
				this.toastService.showError('Unable to send Drop Route CSV');
			}
		}
		 catch (error) {
			this.toastService.showError('There was a problem sending the route csv', 'Load Error');
			console.log(error);
		}
		 finally {
			this.loading = false;
		}
	}
}
