<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<ng-container *ngIf="products && printers">
		<div class="row">
			<div class="card col-lg-8 col-12 p-3 mb-3">
				<div class="card-header">
					<ul class="nav nav-tabs card-header-tabs" role="tabList">
						<li class="nav-item">
							<button class="nav-link active" aria-current="true" data-bs-toggle="tab" data-bs-target="#exportPCOGsView">
								Export PCOGs
							</button>
						</li>
						<li class="nav-item">
							<button class="nav-link" aria-current="false" data-bs-toggle="tab" data-bs-target="#importPCOGsView">
								Import PCOGs
							</button>
						</li>
					</ul>
				</div>
				<div class="tab-content">
					<div class="card-body tab-pane fade show active" id="exportPCOGsView">
						<ng-container *ngIf="products && printers">
							<div class="row">
								<div class="col">
									NOTE: Select any products or printers below that you want included in the export. If none are selected, all PCOGs will be exported.
								</div>
								<div class="col-auto m-auto">
									<button role="button" class="btn btn-success" (click)="exportPCOGs()">Export PCOGs</button>
								</div>
							</div>
							<div class="row mt-2">
								<div class="col-10">
									<div class="card p-3 mb-3">
										<div class="card-body">
											<h5 class="card-title">Products</h5>
											<app-products-list [list]="products" (itemsSelected)="setProductIdsList($event)"></app-products-list>
										</div>
									</div>
								</div>
							</div>
							<div class="row mt-2">
								<div class="col-10">
									<div class="card p-3 mb-3">
										<div class="card-body">
											<h5 class="card-title">Printers</h5>
											<app-printers-list [list]="printers" (itemsSelected)="setPrinterIdsList($event)"></app-printers-list>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
					<div class="card-body tab-pane fade show" id="importPCOGsView">
						<div class="row">
							<div class="col">
								<div>
									<label for="uploadInput" class="form-label">PCOGs File</label>
									<input class="form-control" type="file" id="uploadInput" (change)="fileAdded($event.target)">
								</div>
							</div>
							<div class="col-auto m-auto">
								<button role="button" class="btn btn-success" [disabled]="!file" (click)="uploadPCOGs()">Upload</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</app-spinner-with-overlay>
