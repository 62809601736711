import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyPrintJson'
})

export class PrettyPrintJsonPipe implements PipeTransform {

	transform(value: any, ...args: any[]): any {
		try {
			return JSON.stringify(JSON.parse(value), null, '\t');
		}
		catch {
			return value;
		}
	  }
}
