import { Component, OnInit } from '@angular/core';
import { OrderService } from 'services/order.service';
import { SitesService } from 'services/sites.service';
import { CreateInvoiceRequest, Customer, OrderToInclude, Order, OrderSearch, Site, SiteFilterRequest, ApiException } from '@taradel/admin-api-client';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'services/customer.service';

@Component({
	selector: 'app-orders',
	templateUrl: './customer-orders.component.html',
	styleUrls: ['./customer-orders.component.scss']
})
export class CustomerOrdersComponent implements OnInit {

	isSearchEnabled = false;
	customerId = 0;
	customer?: Customer;
	emailAddresses = '';
	pageNo = 1;
	pageSize = 20;
	orderSearch: OrderSearch = new OrderSearch();
	invoiceOrders: OrderToInclude[] = [];

	orders: Order[] = [];
	total = 0;
	sites: Site[] = [];
	loading: boolean = false;

	constructor(
		public customerService: CustomerService,
		public orderService: OrderService,
		public siteService: SitesService,
		private route: ActivatedRoute,
		public modalService: NgbModal,
		public toastService: ToastService) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			this.customerId = parseInt(params.get('customerId') ?? '0', 10);
		});
		this.search();
	}

	async search() {
		this.loading = true;
		try {
			this.orderSearch.pageNo = this.pageNo = 1;
			this.orderSearch.pageSize = this.pageSize;
			this.orderSearch.customerId = this.customerId;
			this.sites = await this.siteService.getSitesForAdmin({ showInactive: true, adminCheckout: false } as SiteFilterRequest);
			this.customer = await this.customerService.getCustomer(this.customerId);
			this.emailAddresses = this.customer.emailAddress!;
			this.total = await this.orderService.getCustomerOrdersCount(this.orderSearch);
			this.orders = await this.orderService.getCustomerOrders(this.orderSearch);

			if (this.orders.length > 0) {
				this.isSearchEnabled = true;
			}
			else {
				this.isSearchEnabled = false;
			}
		}
		 catch (error) {
			 this.toastService.showError('There was a problem loading the component', 'Load Error');
			 console.log(error);
		}
		 finally {
			 this.loading = false;
		}
	}

	getSiteName(order: Order): string {
		let site = this.sites.find(s => s.siteId === order.affiliateId);
		if (site) {
			return `${site.name} (${order.affiliateId})`;
		}
		return order.affiliateId.toString();
	}

	async pageChanged() {
		this.loading = true;
		try {
			this.orderSearch.pageNo = this.pageNo;
			this.orderSearch.pageSize = this.pageSize;
			this.orders = await this.orderService.getCustomerOrders(this.orderSearch);
		}
		 catch (error) {
			 this.toastService.showError('There was a problem changing the page', 'Load Error');
			 console.log(error);
		}
		 finally {
			 this.loading = false;
		}
	}
	getPaymentTotal(order: Order): number {
		return order.orderPayments?.reduce((a, b) => {
			return a + b.amount;
		}, 0) ?? 0;
	}

	getBalance(order: Order) {
		const paidAmount = order.orderPayments?.reduce((a, b) => {
			return a + b.amount;
		}, 0) ?? 0;
		return order.orderAmount - paidAmount;
	}

	getJobName(order: Order): string | null {
		const parser = new DOMParser();
		let jobName: string | null = null;

		for (let item of order.items ?? []) {
			const xmlData = parser.parseFromString(item.xmlData!, 'text/xml');
			jobName = xmlData.children[0]?.getAttribute('JobName');
			if (jobName) {
				break;
			}
		}
		return jobName;
	}

	addOrRemoveFromInvoiceOrders(order: Order) {
		let ind = this.invoiceOrders.findIndex(o => o.orderId === order.orderId);
		if (ind >= 0) {
			this.invoiceOrders.splice(ind, 1);
		}
		else {
			this.invoiceOrders.push(new OrderToInclude({
				orderId: order.orderId,
				paymentAmount: Math.round((order.orderAmount - this.getPaymentTotal(order)) * 100) / 100,
				notes: ''
			}));
		}
	}
	getInvoiceTotal() {
		return this.invoiceOrders.reduce((total, e) => total + e.paymentAmount!, 0);
	}

	async createInvoice() {
		const emails = this.emailAddresses.split(/,| |;|\r\n|\n/);
		const request: CreateInvoiceRequest = new CreateInvoiceRequest({
			customerId: this.customerId,
			emailAddresses: emails,
			invoiceOrders: this.invoiceOrders
		});
		try {
			this.loading = true;
			this.closeModal();
			const invoiceSqid = await this.orderService.createInvoice(request);
			this.toastService.showSuccess(`Invoice ${invoiceSqid} created`);
		}
		catch (ex: any) {
			if (ApiException.isApiException(ex) && ex.status === 400) {
				this.toastService.showError(ex.response);
			}
			else {
				this.toastService.showError('There was an error creating the invoice.');
			}
	   	}
		finally {
			this.loading = false;
		}
	}

	closeModal() {
		this.modalService.dismissAll();
	}
}
