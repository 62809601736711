import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'services/customer.service';
import { SitesService } from 'services/sites.service';
import { BusinessClass, Customer, CustomerProperty, IdentityCustomer, Organization, OrganizationSearch, Site, SiteFilterRequest, UpdateCustomerSite } from '@taradel/admin-api-client';
import { CmsLanguage, RequestPasswordResetQuery } from '@taradel/web-api-client';
import { IdentityService } from 'services/identity.service';
import { ToastService } from 'services/toast.service';
import { COUNTRIES, Country, STATES } from '@taradel/white-label-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environment';
import { ContentService } from 'services/content.service';
import { AuthenticationService } from 'services/authentication.service';

@Component({
	selector: 'app-customer-details',
	templateUrl: './customer-details.component.html',
	styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

	isSalesAdmin = false;
	customerId = 0;
	cmsLanguages: CmsLanguage[] = [];
	organizations: Organization[] =[];
	loading = true;
	customer?: Customer;
	customers: IdentityCustomer[] =[];
	organizationName = '';
	state = '';
	isCanada = environment.instance === 'Canada';
	customerForm: UntypedFormGroup;
	customerPropertiesForm: UntypedFormGroup;
	primarySiteId = 0;
	submitted = false;
	customerProperties: string[] = ['Language', 'NonProfitAuthorizationNumber', 'PostalCRID', 'ReferralSource', 'AgentCode', 'Agreement_Number', 'VentureOne_Number', 'UspsRepresentative', 'GoogleAdsAccountID', 'EnablePO', 'TaxExemptExpirationDate'];
	customerPropsBool: string[] = ['EnablePO'];
	file?: File;
	isTaxExempt = false;
	businessClasses: BusinessClass[] = [];
	primarySites: Site[] = [];
	stateList = STATES;
	today = new Date(Date.now());

	get countryList(): Country[] {
		const us = COUNTRIES.find(i => i.code === 'US') as Country;
		const ca = COUNTRIES.find(i => i.code === 'CA') as Country;
		return [us, ca, ...COUNTRIES];
	}

	isLoginValid = true;
	loginStatusMessage = '';
	modalRef: any;
	errorMessage: string = '';

	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private customerService: CustomerService,
		private sitesService: SitesService,
		private modalService: NgbModal,
		private identityService: IdentityService,
		private contentService: ContentService,
		public authService: AuthenticationService,
		private toastService: ToastService
	) {
		this.customerForm = formBuilder.group({
			companyName: ['', Validators.maxLength(100)],
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			phone: [''],
			ext: [''],
			companyPhone: [''],
			companyExt: [''],
			mobile: [''],
			fax: ['', Validators.compose([
				Validators.minLength(10),
				Validators.maxLength(14)
			])],
		});

		this.customerPropertiesForm = formBuilder.group({
			language: [''],
			nonprofitauthorizationnumber: [''],
			postalcrid: [''],
			uspsrepresentative: [''],
			referralsource: [''],
			agentcode: [''],
			agreement_number: [''],
			ventureone_number: [''],
			googleadsaccountid: [''],
			enablepo: ['']
		});
		if (this.authService.organizationId !== 1) {
			this.customerPropertiesForm.disable();
		}
	}

	async ngOnInit() {
		this.route.paramMap.subscribe(async params => {
			try {
                this.loading = true;
				this.businessClasses = await this.sitesService.getAllBusinessClass();

				this.customerId = parseInt(params.get('customerId') ?? '0', 10);
                this.organizations = await this.sitesService.listOrganizationsAndSites(new OrganizationSearch({
                    siteName: ""
                }));
				await this.loadCustomer();
				if (this.customer) {
					// why is this.customer still possibly undefined, even when wrapped in this conditional??
					const organization = this.organizations.find(o => o.organizationId === this.customer?.organizationId);
					this.primarySites = organization?.sites ?? [];
				}
				this.cmsLanguages = await this.contentService.getCmsLanguages();
			}
			catch (error) {
				this.toastService.showError('There was a problem loading customer details.', 'Load Error');
				console.log(error);
			}
			finally {
				this.loading = false;
			}
		});
		this.isSalesAdmin = await this.authService.hasRole('SalesAdmin');
		if (this.isSalesAdmin) {
			this.customerPropertiesForm.addControl('taxexemptexpirationdate', new UntypedFormControl());
			this.customerPropertiesForm.addControl('taxexemptdoc', new UntypedFormControl());
			/*this.customerPropertiesForm.get('taxexemptexpirationdate')?.valueChanges.subscribe(val => {
				if ((val?.length ?? 0) > 0 && !this.isTaxExempt) {
					this.customerPropertiesForm.controls['taxexemptdoc'].setValidators([Validators.required]);
				}
				else {
					this.customerPropertiesForm.controls['taxexemptdoc'].setValidators([]);
				}
				this.customerPropertiesForm.controls['taxexemptdoc'].updateValueAndValidity();
			});*/
		}
		this.customerPropertiesForm.get('nonprofitauthorizationnumber')?.valueChanges.subscribe(val => {
			if (val?.length ?? 0 > 0) {
				this.customerPropertiesForm.controls['postalcrid'].setValidators([Validators.required]);
			}
			else {
			  	this.customerPropertiesForm.controls['postalcrid'].clearValidators();
			}
			this.customerPropertiesForm.controls['postalcrid'].updateValueAndValidity();
		});
	}

	async loadCustomer() {
		this.customer = await this.customerService.getCustomer(this.customerId);
		this.organizationName = this.getOrgName(this.customer.organizationId);
		this.isTaxExempt = this.customer?.customerProperties?.findIndex(p => p.propertyName?.toLowerCase() === 'taxexemptexpirationdate') !== -1;
		this.customers = await this.identityService.searchForCustomersByUsername(this.customer.username!);
		const users = await this.identityService.searchForUsersByUsername(this.customer.username!);
		if (this.customers.filter(c => c.organizationId === this.customer?.organizationId).length > 1) {
			this.loginStatusMessage = 'Multiple users found with same username';
			this.isLoginValid = false;
		}
		else if (users.filter(u => u.organizationId === this.customer?.organizationId).length === 0) {
			this.loginStatusMessage = 'No login record found';
			this.isLoginValid = false;
		}
		else if (!this.customer.username?.includes(this.customer.emailAddress!)) {
			this.loginStatusMessage = 'Mismatched username / email address';
			this.isLoginValid = false;
		}
		else {
			this.loginStatusMessage = 'Good';
			this.isLoginValid = true;
		}

		this.initializeCustomer();
	}

	initializeCustomer() {
		this.customerForm.controls.companyName.setValue(this.customer?.company!);
		this.customerForm.controls.firstName.setValue(this.customer?.firstName!);
		this.customerForm.controls.lastName.setValue(this.customer?.lastName!);
		this.customerForm.controls.phone.setValue(this.customer?.phoneNumber!);
		this.customerForm.controls.ext.setValue(this.customer?.extension!);
		this.customerForm.controls.companyPhone.setValue(this.customer?.companyPhoneNumbere!);
		this.customerForm.controls.companyExt.setValue(this.customer?.companyExtension!);
		this.customerForm.controls.mobile.setValue(this.customer?.mobilePhoneNumber!);
		this.customerForm.controls.fax.setValue(this.customer?.faxNumber!);

		this.customerProperties.forEach(prop => {
			if (!this.customerPropsBool.includes(prop)) {
				const temp = prop.replace('_', ' ');
				const property = this.customer?.customerProperties?.find(y => y.propertyName?.toLowerCase() === temp.toLowerCase());
				if (property) {
					this.customerPropertiesForm.controls[prop.toLowerCase()].setValue(property.propertyValue);
				}
			}
		});

		this.customerPropsBool.forEach(prop => {
			const property = this.customer?.customerProperties?.find(y => y.propertyName?.toLowerCase() === prop.toLowerCase());
			if (property) {
				const propEnabled = (property.propertyValue?.toLowerCase() === 'true');
				this.customerPropertiesForm.controls[prop.toLowerCase()].setValue(propEnabled);
			}
		});
		if (this.cp.taxexemptexpirationdate?.value?.length ?? 0 > 0) {

		}
		this.primarySiteId = this.customer?.affiliateID!;
	}

	getOrgName(orgId: number) {
		const organization = this.organizations.filter(x => x.organizationId === orgId);
		return organization[0].name ? `${orgId} - ${organization[0].name}` : "Name not found";
	}

	otherOrgCustomers(): IdentityCustomer[] {
		return this.customers.filter(x => x.organizationId !== this.customer?.organizationId) ?? [];
	}

	async saveCustomer() {
		this.submitted = true;
		if (this.customerForm.invalid) {
			return;
		}
		this.loading = true;

		try {
			this.customer!.company = this.customerForm.controls.companyName.value;
			this.customer!.firstName = this.customerForm.controls.firstName.value;
			this.customer!.lastName = this.customerForm.controls.lastName.value;
			this.customer!.companyPhoneNumbere = this.customerForm.controls.companyPhone.value;
			this.customer!.companyExtension = this.customerForm.controls.companyExt.value;
			this.customer!.mobilePhoneNumber = this.customerForm.controls.mobile.value;
			this.customer!.phoneNumber = this.customerForm.controls.phone.value;
			this.customer!.extension = this.customerForm.controls.ext.value;
			this.customer!.faxNumber = this.customerForm.controls.fax.value;

			await this.customerService.updateCustomer(this.customer!);
			this.toastService.showSuccess(`Successfully updated customer's basic information`);
		}
		 catch (error) {
			 this.toastService.showError('There was a problem updating the customer.', 'Load Error');
			 console.log(error);
		}
		 finally {
			 this.loading = false;
		}
	}

	async savePrimarySite() {
		this.submitted = true;
		this.loading = true;
		try {
			this.customer!.affiliateID = this.primarySiteId;
            await this.customerService.updateCustomerSite(new UpdateCustomerSite({
                customerId: this.customerId,
                siteId: this.primarySiteId
            }));
			this.toastService.showSuccess(`Successfully updated customer's primary site`);
		}
		 catch (error: any) {
            console.log(error);
            if (error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError('There was a problem saving the primary site', 'Load Error');
			}
		}
		 finally {
			 this.loading = false;
		}
	}

	// pnd_CustomerProperties mapping, vertical list of meta properties for customers
	// don't trim any values for compare before establishing changes, only trim when assigning the value to the insert or update object
	async saveCustomerProperties() {
		this.submitted = true;
		if (!this.isValidExemption()) {
			this.toastService.showError('To enable tax exemption, you need to set the date & upload the certificate as well');
			return;
		}
		this.loading = true;
		try {
			const insertCustomerProperties: CustomerProperty[] = [];
			const updateCustomerProperties: CustomerProperty[] = [];
			this.customerProperties.forEach(propName => {
				// Normalize the property name to lowercase for searching the form field setup
				const formFieldName = propName.toLowerCase();
				const formField = this.customerPropertiesForm.controls[formFieldName];
				// get the property for the existing customer property data
				const customerProperty = this.customer?.customerProperties?.find(y => y.propertyName?.toLowerCase() === formFieldName.replace('_', ' '));
				// normalize the value to string, and if the propName is in the bool list, run a different value conversion
				let fieldValue = formField.value?.toString() ?? '';
				let boolPropVal = this.customerPropsBool.includes(propName);
				if (boolPropVal) {
					// this is a boolean and should be transmitted in an agreeable format
					fieldValue = this.customerPropertiesForm.controls[formFieldName].value === true ? 'True' : 'False';
				}

				if (customerProperty) {
					let propValue = customerProperty.propertyValue ?? '';
					// property already exists, determine if we need to update it (because it has changed)
					if (fieldValue.toLowerCase() !== propValue.toLowerCase()) {
						updateCustomerProperties.push(new CustomerProperty({
							customerPropertyID: customerProperty.customerPropertyID,
							customerID: this.customerId,
							propertyName: propName.replace('_', ' '),
							propertyValue: fieldValue.trim() // trim this because we don't trust users
						}));
					}
				}
				else {
					// property does not exist, determine if we need to add it (because it is non-empty/false)
					if (!boolPropVal && fieldValue.trim() !== '' || (boolPropVal && fieldValue.trim() !== 'False')) {
						insertCustomerProperties.push(new CustomerProperty({
							customerPropertyID: 0,
							customerID: this.customerId,
							propertyName: propName.replace('_', ' '),
							propertyValue: fieldValue.trim()
						}));
					}
				}
			});

			if (updateCustomerProperties.length > 0) {
				await this.customerService.updateCustomerProperties(updateCustomerProperties);
			}

			if (insertCustomerProperties.length > 0) {
				await this.customerService.createCustomerProperties(insertCustomerProperties);
			}
			if (this.file) {
				// upload file to S3
				await this.customerService.uploadCustomerTaxExemptionDoc(this.customerId, { fileName: this.file!.name,	data: this.file! });
				this.file = undefined;
			}
			if (updateCustomerProperties.length > 0 || insertCustomerProperties.length > 0) {
				await this.loadCustomer();
			}

			this.toastService.showSuccess(`Successfully updated customer properties`);
		}
		 catch (error) {
			 this.toastService.showError('There was a problem updating customer properties', 'Load Error');
			 console.log(error);
		}
		 finally {
			 this.loading = false;
		}
	}

	async sendResetPasswordEmail() {
		try {
			this.loading = true;
			const site = this.primarySites.find(x => x.siteId === this.customer?.affiliateID)!;
			const url = site.urls?.find(x => !x.url?.includes('.local') && x.active)?.url;
			await this.identityService.requestPasswordReset(new RequestPasswordResetQuery({
				emailAddress: this.customer?.emailAddress,
				siteDomain: url
			}));
			this.toastService.showSuccess('The password reset email was sent successfully');
		}
		catch (error) {
			this.toastService.showError('There was a problem send the password reset email');
		}
		finally {
			this.loading = false;
		}
	}

	siteChanged(selected: number) {
		this.primarySiteId = selected;
	}

	get f() {
		return this.customerForm.controls;
	}
	get cp() {
		return this.customerPropertiesForm.controls;
	}

	fileHandler(file: any) {
		this.file = file.files[0];
		console.log(file);
	}
	async downloadExemptionCertificate() {
		try {
			this.loading = true;
			await this.customerService.getCustomerTaxExemptionDoc(this.customerId);
		}
		catch (error) {
			this.toastService.showError('There was a problem send the password reset email');
		}
		finally {
			this.loading = false;
		}
	}

	isValidExemption(): boolean {
		if (this.isTaxExempt) {
			return true;
		}
		else {
			if ((this.cp.taxexemptexpirationdate?.value?.length ?? 0) > 0 && !this.file) {
				return false;
			}
			if ((this.cp.taxexemptexpirationdate?.value?.length ?? 0) === 0 && this.file) {
				return false;
			}
			return true;
		}
	}
}
