import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'services/reports.service';
import { EddmStatusReport } from '@taradel/admin-api-client';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-eddm-status-report',
	templateUrl: './eddm-status-report.component.html',
	styleUrls: ['./eddm-status-report.component.scss']
})
export class EddmStatusReportComponent implements OnInit {
	loading = false;

	eddmStatusWaitingDesign?: EddmStatusReport[];
	eddmStatusWaitingCustFiles?: EddmStatusReport[];
	eddmStatusMissingDist?: EddmStatusReport[];
	eddmStatusReady?: EddmStatusReport[];

	constructor(
		private reportsApiService: ReportsService,
		private toastService: ToastService
	) { }

	ngOnInit() {
		this.loadWaitingDesign();
		this.loadWaitingCustFiles();
		this.loadMissingDist();
		this.loadReady();
	}

	async loadWaitingDesign(): Promise<void> {
		try {
			this.eddmStatusWaitingDesign = await this.reportsApiService.getEddmStatus("awaitingDesign");
		}
		catch {
			this.toastService.showError('There was an error loading Eddm Status report');
		}
	}

	async loadWaitingCustFiles(): Promise<void> {
		try {
			this.eddmStatusWaitingCustFiles = await this.reportsApiService.getEddmStatus("awaitingCustFiles");
		}
		catch {
			this.toastService.showError('There was an error loading Waiting Client Files report');
		}
	}

	async loadMissingDist(): Promise<void> {
		try {
			this.eddmStatusMissingDist = await this.reportsApiService.getEddmStatus("missingDistribution");
		}
		catch {
			this.toastService.showError('There was an error loading Missing Distribution report');
		}

	}

	async loadReady(): Promise<void> {
		try {
			this.eddmStatusReady = await this.reportsApiService.getEddmStatus("readyToCreatePO");
		}
		catch {
			this.toastService.showError('There was an error loading Ready to Create PO report');
		}
	}

}
