import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { Bundle, BundleDisplay } from '@taradel/admin-api-client';

@Component({
  selector: 'app-bundle-details-customization',
  templateUrl: './bundle-details-customization.component.html',
  styleUrls: ['./bundle-details-customization.component.scss']
})
export class BundleDetailsCustomizationComponent implements OnChanges {
	bundleForm: UntypedFormGroup;
	bundleId?: number;
	selectedBundle?: BundleDisplay;

	@Input() bundle?: BundleDisplay;

	@Output() bundleUpdated = new EventEmitter<Bundle>();
	@Output() goBack = new EventEmitter();

	constructor(
		formBuilder: UntypedFormBuilder
	) {
		this.bundleForm = formBuilder.group({
			name: new FormControl<string | undefined>(undefined, Validators.compose([Validators.required, Validators.maxLength(50)])),
			description: new FormControl<string | undefined>(undefined, Validators.maxLength(250)),
			deleted: new FormControl<boolean>(false)
		});
	}

	get controls() {
		return this.bundleForm.controls;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!!this.bundle) {
			this.selectedBundle = this.bundle;
			this.populateForm(this.selectedBundle);
		}
	}

	populateForm(bundle: BundleDisplay) {
		this.controls.name.setValue(bundle.name);
		this.controls.description.setValue(bundle.description);
		this.controls.deleted.setValue(bundle.deleted);
	}

	emitGoBack() {
		this.goBack.emit();
	}

	emitUpdatedBundle() {
		const updatedBundle = {
			bundleId: this.bundle ? this.bundle.bundleId : 0,
			name: this.controls.name.value,
			description: this.controls.description.value,
			deleted: false
		} as Bundle;
		this.bundleUpdated.emit(updatedBundle);
		this.bundleForm.reset();
	}
}
