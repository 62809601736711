import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Product, ProductsSearch, USelectMethod, UselectProductClass } from '@taradel/admin-api-client';
import { ProductsService } from 'services/products.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-uselect-details',
  templateUrl: './uselect-details.component.html',
  styleUrls: ['./uselect-details.component.scss']
})

export class UselectDetailsComponent implements OnInit {
	submitted = false;
	loading = true;
	uSelectId = 0;
	uSelectForm: UntypedFormGroup;
	productClasses = Object.values(UselectProductClass);
	uSelect = new USelectMethod();
	baseProducts: Product[] = [];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private productService: ProductsService,
		public siteService: SitesService,
		private toastService: ToastService
	) {
		this.uSelectForm = this.formBuilder.group({
			name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
			description: [''],
			shortDescription: [''],
			productClass: ['', Validators.required],
			isDirectShippable: [''],
			featuredProduct: ['']
		});
	}

	ngOnInit(): void {
		this.route.paramMap.subscribe(async params => {
			try {
				this.uSelectId = parseInt(params.get('uselectId') ?? '0', 10);
				await this.loadUselect();
				this.baseProducts = await this.productService.getAllProducts(new ProductsSearch({
					pageNo: 1,
					pageSize: 1000,
					uSelectId: this.uSelectId,
					name: '',
					productId: 0
				}));
			}
			catch {
				this.toastService.showError('There was an error loading the uselect');
			}
			finally {
				this.loading = false;
			}

		});
	}

	async loadUselect() {
		this.uSelect = await this.siteService.getUSelect(this.uSelectId);
		this.uSelectForm.controls.isDirectShippable.setValue(this.uSelect.isDirectShippable);
		this.uSelectForm.controls.name.setValue(this.uSelect.name);
		this.uSelectForm.controls.productClass.setValue(this.uSelect.productClass);
		this.uSelectForm.controls.description.setValue(this.uSelect.description);
		this.uSelectForm.controls.shortDescription.setValue(this.uSelect.shortDescription);
		this.uSelectForm.controls.featuredProduct.setValue(this.uSelect.featuredProductId);
	}

	async saveUselect(): Promise<void> {
		this.submitted = true;
		if (this.uSelectForm.invalid) {
			return;
		}

		try {
			this.loading = true;
			await this.siteService.updateUSelect(new USelectMethod({
				uSelectId: this.uSelect.uSelectId,
				name: this.uSelectForm.controls.name.value,
				shortDescription: this.uSelectForm.controls.shortDescription.value,
				description: this.uSelectForm.controls.description.value,
				configurationUrl: this.uSelect.configurationUrl,
				reviewUrl: this.uSelect.reviewUrl,
				countUrl: this.uSelect.countUrl,
				productClass: this.uSelectForm.controls.productClass.value,
				isDirectShippable: this.uSelectForm.controls.isDirectShippable.value,
				featuredProductId: this.uSelectForm.controls.featuredProduct.value
			}));
			this.toastService.showSuccess('U-select value updated.');
		}
		catch (err) {
			this.toastService.showError('Error updating U-select value');
		}
		finally {
			this.loading = false;
		}
	}

	get f() {
		return this.uSelectForm.controls;
	}

}

