<app-spinner-with-overlay [showSpinner]="loading">
    <div class="row">
        <div class="col-md-5 col-sm-12">
            <div class="card p-3 mb-3">
                <form [formGroup]="newUSelectForm">
                    <div class="form-group mb-3">
                        <app-uselect-search [array]="allUSelectMethods" (selectedMethod)="getMethod($event)"></app-uselect-search>
                    </div>
                    <div class="form-group mb-3">
                        <label for="uSelectIdInput">
                            USelect ID
                        </label>
                        <input type="number" id="uSelectIdInput" formControlName="uSelectId" class="form-control disabled"/>
                    </div>
                    <div class="form-group mb-3">
                        <label for="uSelectInput">Choose a Design</label>
						<select type="select" class="form-select" formControlName="productDesignTypeId">
							<option *ngFor="let x of designTypes" value="{{x}}">
								{{x}}
							</option>
						</select>
                    </div>
                    <button type="button" class="btn btn-primary" [disabled]="newUSelectForm.invalid" (click)="addNewUSelect()">Add</button>
                </form>
            </div>
        </div>
    </div>

</app-spinner-with-overlay>
