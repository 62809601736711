import { Component, EventEmitter, Output, Signal, effect } from '@angular/core';
import { MapfireService, PanelType } from 'services/mapfire.service';

type SortDir = 'asc' | 'desc';

@Component({
	selector: 'app-map-table',
	templateUrl: './map-table.component.html',
	styleUrl: './map-table.component.scss'
})
export class MapTableComponent {
	panel: Signal<PanelType>;
	tableData: Signal<any[]>;
	sortedTableData: any[] = [];
	tableDataMapByGeocoderef: Signal<Map<string, any>>;
	tableSortKey: string = 'carrierroute';
	tableSortDirection: SortDir = 'desc';
	tableLoading: boolean = false;
	heatmapData: Signal<any[]>;

	@Output() rowSelectionToggled = new EventEmitter<string>();
	@Output() mapPanelChanged = new EventEmitter<PanelType>();

	constructor(
		private mapfireService: MapfireService,
	) {
		this.panel = this.mapfireService.select("panel");
		this.tableData = this.mapfireService.select("tableData");
		this.tableDataMapByGeocoderef = this.mapfireService.select("tableDataMapByGeocoderef");
		this.heatmapData = this.mapfireService.select("heatmapData");

		effect(() => {
			this.updateSortedTableData(this.tableData());
		});
	}

	setPanel(panel: PanelType) {
		this.mapfireService.setPanel(panel);
		this.mapPanelChanged.emit(panel);
	}

	setSortKey(key: string, dir?: SortDir) {
		// if we're already sorting by this key, then flip the direction
		if (this.tableSortKey === key) {
			this.tableSortDirection = this.tableSortDirection === 'asc' ? 'desc' : 'asc';
		}
		else {
			// or if it's a new key, reset the direction
			this.tableSortDirection = 'desc';
		}
		this.tableSortKey = key;
		this.updateSortedTableData(this.tableData());
	}

	updateSortedTableData(tableData: any[]) {
		if (!tableData || !tableData.length) {
			return;
		}
		const key = this.tableSortKey;
		const sortedTableData = [...tableData];
		sortedTableData.sort((a, b) => a[key] - b[key]);
		if (this.tableSortDirection === 'desc') {
			sortedTableData.reverse();
		}
		// always sort selected items to the top of the table
		// this doesn't take effect on every selection to prevent a jarring user experience, only when switching Panels or re-sorting the table
		sortedTableData.sort((a, b) => {
			if (a.selected > b.selected) {
				return -1;
			}
			return 0;
		});
		this.sortedTableData = sortedTableData;
}

	toggleRowSelected(row: any) {
		this.rowSelectionToggled.emit(row.geocoderef as string);
	}


}
