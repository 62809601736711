import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import { IdentityClient as WebIdentityClient, RequestPasswordResetQuery } from '@taradel/web-api-client';
import { IdentityClient, IdentityRole, PaginationQuery, UserSearch, IdentityCustomer, PaginatedUserSearch, SalesRepTagItem, RegisterUserQuery, IdentityUserRecord, IdentityResult } from '@taradel/admin-api-client';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class IdentityService {

	identityClient: IdentityClient;
	webIdentityClient: WebIdentityClient;

	constructor(http: HttpClient) {
		this.identityClient = new IdentityClient(http, environment.adminApiUrl);
		this.webIdentityClient = new WebIdentityClient(http, environment.webApiUrl);
	}

	getRoles(): Promise<IdentityRole[]> {
		return lastValueFrom(this.identityClient.getRoles());
	}

	async createRole(name: string): Promise<void> {
		await lastValueFrom(this.identityClient.createRole(name));
	}

	async getSalesReps(): Promise<SalesRepTagItem[]> {
		return await lastValueFrom(this.identityClient.getSalesReps());
	}

	async getAllUsers(pageNo: number, pageSize: number, adminsOnly: boolean = false, username?: string): Promise<IdentityCustomer[]> {
		return await lastValueFrom(this.identityClient.getAllUsers(new PaginatedUserSearch({
			pagination: new PaginationQuery({
				pageNo,
				pageSize
			}),
			search: new UserSearch({
				adminsOnly,
				username
			})
		})));
	}

	async countAllUsers(adminsOnly: boolean = false, username?: string): Promise<number> {
		return await lastValueFrom(this.identityClient.countAllUsers(new UserSearch({
			adminsOnly,
			username
		})));
	}

	async requestPasswordReset (query: RequestPasswordResetQuery): Promise<void> {
		await lastValueFrom(this.webIdentityClient.requestPasswordReset(query));
	}

	async getUserRoles(userId: string): Promise<string[]> {
		return await lastValueFrom(this.identityClient.getUserRoles(userId));
	}

	async getCustomer(customerId: number): Promise<IdentityCustomer> {
		return await lastValueFrom(this.identityClient.getCustomer(customerId));
	}

	async registerCustomer(query: RegisterUserQuery): Promise<number> {
		return await lastValueFrom(this.identityClient.registerUserFromAdmin(query));
	}

	async addRoleToUser(userId: string, roleName: string) {
		await lastValueFrom(this.identityClient.addRoleToUser(userId, roleName));
	}

	async removeRoleFromUser(userId: string, roleName: string) {
		await lastValueFrom(this.identityClient.removeUserFromRole(userId, roleName));
	}

	async searchForCustomersByUsername(username: string): Promise<IdentityCustomer[]> {
		return await lastValueFrom(this.identityClient.searchForCustomersWithUsername(username));
	}

	async searchForUsersByUsername(username: string): Promise<IdentityUserRecord[]> {
		return await lastValueFrom(this.identityClient.searchForUsersWithUsername(username));
	}

	async createAndLinkIdentityUser(customerId: number): Promise<number> {
		return await lastValueFrom(this.identityClient.createAndLinkIdentityUser(customerId));
	}

	async updateUsernameAndEmailAddress(customerId: number, username: string): Promise<IdentityResult> {
		return await lastValueFrom(this.identityClient.updateUsernameAndEmailAddress(customerId, username));
	}
}
