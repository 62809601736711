<app-customer-header [customerId]="customerId"></app-customer-header>

<app-spinner-with-overlay [showSpinner]="loading">
	<p *ngIf="!isCa" class="text-end">
		<a (click)="proceedToSiteSelection(siteModal)"
			class="btn btn-success btn-rounded px-4 rounded-pill">
			<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Create Print And Ship Cart</a>
	</p>
	<div class="card border-0 p-3">
		<div *ngIf="userHasShoppingCarts">
			<h3>Shopping Carts</h3>
			<div class="table-responsive">
				<table class="table table-striped">
					<thead>
						<tr>
							<th>Site</th>
							<th class="text-end">Total</th>
							<th>Modified Date</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let cart of customerCarts">
							<td>
								<div class="lead">
									<b>{{getSiteName(cart.siteId)}}</b>
								</div>
								<ul>
									<li *ngFor="let product of cart.cartData">
										<span *ngIf="!product.budget">
											{{ product.quantity | number }} <i class="far fa fa-times"></i> {{ product.name }}
										</span>
										<span *ngIf="product.uSelectId !== USelect.customFee && product.budget">
											{{ product.budget | currency }}/day for {{ product.quantity }} days
										</span>
										<span *ngIf="product.uSelectId === USelect.customFee && product.budget">
											{{ product.budget | currency }}
										</span>
									</li>
								</ul>
							</td>
							<td class="text-end">
								<div class="lead">
									<b>{{ getCartPrice(cart) | currency }}</b>
								</div>
							</td>
							<td>
								<span *ngIf="cart.modifiedDate">
									{{ cart.modifiedDate|date: 'MM/dd/yyyy hh:mm a' }}
								</span>
							</td>
							<td>
								<ul class="list-inline">
									<li class="list-inline-item"><a [routerLink]="viewCart(cart)"
										class="btn btn-sm btn-primary">Edit Cart</a></li>
									<li *ngIf="customerId === authService.customerId && isSalesTeam" class="list-inline-item"><a (click)="openModal(customersModal, cart)"
										class="btn btn-sm btn-primary">Migrate Cart</a></li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<app-alert-box *ngIf="!userHasShoppingCarts && !loading" alertMode="info">
			The customer has no shopping carts.
		</app-alert-box>




	</div>
</app-spinner-with-overlay>

<ng-template #customersModal let-modal>
	<app-customers [deleteMessage]="'Delete cart and related addresses from source account'" (initiateTransfer)="initiateTransfer($event)"></app-customers>
</ng-template>

<ng-template #siteModal let-modal>
	<app-confirm-modal title="Select a site to place the order from" confirmText="Proceed" cancelText="Cancel" (confirmAction)="startOrder()"
		(closeModal)="closeModal();" [disabled]="selectedSiteId === 0">
		<ng-template #rt let-r="result" let-t="term">
			<ngb-highlight [result]="r.siteId" [term]="t"></ngb-highlight>
		</ng-template>
		<div>
			<label for="site">Select Site</label>
			<app-sites-list [startingSiteId]="customer.affiliateID" (siteSelected)="siteChanged($event.siteId)" [sites]="sites"></app-sites-list>
		</div>
	</app-confirm-modal>
</ng-template>
