
<app-spinner-with-overlay [showSpinner]="showSpinner">
	<div class="card-body">
		<form [formGroup]="cartForm">
			<div formArrayName="products">
				<div *ngFor="let product of products?.controls; let i=index">
					<div [formGroupName]="i">
						<div *ngIf="!isExtraCopies(0)">
							<div class="form-floating mb-3">
								<select type="select" formControlName="distributionId" (change)="openSelectedDistributionModal(i, $any($event.target).value, selectedDistributionModal)" class="form-select">
									<option [ngValue]="null" disabled>Select a distribution</option>
									<option *ngFor="let x of selectableDistributions" value="{{x.distributionId}}">
										{{x.name}}
									</option>
								</select>
								<label for="distributionId">Select a distribution</label>
							</div>
						</div>
						<ng-container *ngIf="hasDistribution(0)">
							<div class="form-floating mb-3">
								<select type="select" formControlName="productId" (change)="productSelected(i)" class="form-select">
									<option [ngValue]="null" disabled>Select a product</option>
									<option *ngFor="let x of allowedProducts" value="{{x.baseProductId}}">
										{{getProductName(x.baseProductId!)}}
									</option>
								</select>
								<label for="productId">Choose Product</label>
							</div>
							<div *ngIf="submitted && products.at(0).get('productId')?.errors" class="input-error">
								Product must be selected
							</div>
						</ng-container>
						<div *ngIf="productAdded && getProductOptions(i).length > 0">
							<div><b>Product Options</b></div>
							<div class="row">
								<div class="col" formGroupName="options" *ngFor="let option of getProductOptions(i); let j=index">
									<div class="form-floating mb-3">
										<select type="select" [formControlName]="option" class="form-select" (change)="optionSelected(i, option)" [ngClass]="{ 'is-invalid': product.get('options')?.get(option)?.errors}">
											<option *ngFor="let x of getOptions(i, option)" [ngValue]="x.option?.optionId">
												{{x.option?.name}}
											</option>
										</select>
										<label>{{option}}</label>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="(isDigitalProduct(i) && !isDailyBudgetProduct(i) ) || isDirectMailProduct(i)">
							<div class="row">
								<div class="col-6">
									<div class="form-floating mb-3">
										<input type="number" class="form-control" formControlName="quantity"
										(input)="quantityUpdated(i)" appShowInvalid placeholder="Quantity">
										<label for="quantity">Enter Quantity</label>
									</div>

								</div>
								<div *ngIf="isPMProduct(i)" class="col-6">
									<div class="row">
										<div class="col">
											<b>Minimum Quantity: 1</b>
										</div>
									</div>
									<div class="row">
										<div class="col">
											<b>Maximum Quantity: {{distribution?.totalDeliveries}}</b>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="isDailyBudgetProduct(i) || isCustomFeeProduct(i)">
							<div *ngIf="isDailyBudgetProduct(i)" class="row">
								<div class="col-6">
									<div class="form-floating mb-3">
										<input type="text" class="form-control" formControlName="digitalImpressions" [attr.disabled]="true" placeholder="Total Number of Impressions">
										<label for="digitalImpressions">Total Number of Impressions</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<div class="form-floating mb-3">
										<input type="number" class="form-control" formControlName="budget" appShowInvalid placeholder="Daily Budget" (input)="updateDigitalForm(i, false)" [ngClass]="{ 'is-invalid': product.get('budget')?.errors }">
										<label for="budget">Enter Daily Budget</label>
									</div>
								</div>
								<div *ngIf="isDailyBudgetProduct(i)" class="col-6">
									<div class="form-floating mb-3">
										<input type="number" class="form-control" formControlName="totalBudget" placeholder="Total Budget" (input)="updateDigitalForm(i, true)" [ngClass]="{ 'is-invalid': product.get('totalBudget')?.errors }">
										<label for="totalBudget">Total Budget</label>
									</div>
									<div>
										Minimum budget: {{minimumDigitalBudget}}</div>
								</div>
							</div>
						</div>
						<div *ngIf="(isDigitalProduct(i) || isDirectMailProduct(i)) && showDesign">
							<div><b>Design Options</b></div>
							<div>
								<div class="form-floating mb-3">
									<select type="select" formControlName="design" (change)="designSelected(i)" class="form-select" [ngClass]="{ 'is-invalid': product.get('design')?.errors }">
										<option value="{{USelectDesignType.Upload}}">Upload</option>
										<option value="{{USelectDesignType.Omitted}}">Omitted</option>
										<option value="{{USelectDesignType.ProfessionalDesign}}">Professional Design</option>
									</select>
									<label for="design">Choose Design Option</label>
								</div>
								<ng-container *ngIf="newOrderItem">
									<div *ngIf="uploadDesignSelected(i)">
										<div class="form-group mb-3">
											<label for="front">Front File</label>
											<span *ngIf="!!cartItemToEdit && !!cartItemToEdit.design?.front?.fileName">&nbsp;({{ cartItemToEdit?.design?.front?.fileName }})</span>
											<input type="file" class="form-control" formControlName="front" (change)="handleFrontFileInput(i, $event.target)">
										</div>

										<div class="form-group mb-3">
											<label for="back">Back File</label>
											<span *ngIf="!!cartItemToEdit && !!cartItemToEdit.design?.back?.fileName">&nbsp;({{ cartItemToEdit?.design?.back?.fileName}})</span>
											<input type="file" class="form-control" formControlName="back" (change)="handleBackFileInput(i, $event.target)">
										</div>

										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" formControlName="proof">
											<label class="form-check-label" for="proof">Request a PDF proof before
												printing?</label>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
						<div *ngIf="isCallTrackingProduct(i)">
							<div class="row" *ngIf="product.get('options')?.get('Call Tracking Type')?.value === 89">
								<div class="col-6">
									<div class="form-floating mb-3">
										<input type="number" class="form-control" formControlName="areaCode" onKeyPress="if(this.value.length==3) return false;" [ngClass]="{ 'is-invalid': product.get('areaCode')?.errors }" min="201" max="999" />
										<label>Area Code</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<div class="form-floating mb-3">
										<input required type="text" class="form-control" formControlName="forwardingNumber" [minlength]="14" [maxlength]="14" (keyup)="formatPhoneNumber(i, 'forwardingNumber')" [ngClass]="{ 'is-invalid': product.get('forwardingNumber')?.errors }" />
										<label>Call Forwarding Number</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<div class="form-floating mb-3">
										<select required class="form-select" formControlName="trackingDuration" [ngClass]="{ 'is-invalid': product.get('trackingDuration')?.errors }" [attr.disabled]="true">
											<option value="1" selected>1 Year</option>
										</select>
										<label>Tracking Duration</label>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="isInformedDeliveryProduct(i)">
							<div class="row">
								<div class="col-6">
									<div class="form-floating mb-3">
										<input required type="text" class="form-control" formControlName="webLink" pattern="(http(s)?:\/\/)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$" [ngClass]="{ 'is-invalid': product.get('webLink')?.errors }" />
										<label>Web Link</label>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="isQrCodeProduct(i)">
							<div *ngIf="(product.get('productId')?.value.toString() === '363' && isUS) || (product.get('productId')?.value.toString() === '262' && !isUS)" class="row">
								<div class="col-6">
									<div class="form-floating mb-3">
										<input required type="text" class="form-control" formControlName="webLink" pattern="(http(s)?:\/\/)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$" [ngClass]="{ 'is-invalid': submitted && product.get('webLink')?.errors }" />
										<label>Web Link</label>
									</div>
								</div>
							</div>
							<div *ngIf="(product.get('productId')?.value.toString() === '364' && isUS) || (product.get('productId')?.value.toString() === '263' && !isUS)" class="row">
								<div class="col-6">
									<div class="form-floating mb-3">
										<input required type="text" class="form-control" formControlName="phoneNumber" [minlength]="14" [maxlength]="14" (keyup)="formatPhoneNumber(i, 'phoneNumber')" [ngClass]="{ 'is-invalid': submitted && product.get('phoneNumber')?.errors }" />
										<label>Phone Number</label>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="isDigitalProduct(i) || isDirectMailProduct(i)">
							<div><b>Delivery Options</b></div>
							<div>
								<div *ngIf="isDigitalProduct(i)">
									<div class="row mb-3">
										<div class="col-md-3">
											<div class="form-floating">
												<input type="date" formControlName="startDate"
												(change)="startDateSelected(i)" class="form-control" max="9999-12-31"
												[ngClass]="{ 'is-invalid': product.get('startDate')?.errors }">
												<label for="startDate">Start Date</label>
											</div>
										</div>
										<div class="col-md-3" *ngIf="!isEmail(i)">
											<div class="form-floating">
												<input type="number" formControlName="campaignDuration" [min]="today" (input)="updateDigitalForm(i, false)" class="form-control" [ngClass]="{ 'is-invalid': product.get('campaignDuration')?.errors }">
												<label for="campaignDuration">Campaign Duration</label>
											</div>
										</div>
										<div class="col-md-3" *ngIf="!isEmail(i)">
											<div class="form-floating">
												<input type="date" [attr.disabled]="true" formControlName="endDate" max="9999-12-31"
												required [min]="endDateStart" class="form-control" (input)="updateDigitalForm(i, false, true)">
												<label for="endDate">End Date</label>
												<div *ngIf="product.get('endDate')?.errors" class="invalid-feedback">
													<div *ngIf="product.get('endDate')?.errors?.invalidEndDate">
														End date cannot be before start date
													</div>
												</div>
											</div>
										</div>
									</div>
									<div *ngIf="isEmail(i)" class="row mb-3">
										<div class="col-md-4">
											<div class="form-floating">
												<select type="select" formControlName="impressions" class="form-select" (change)="impressionsChanged(i)">
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
												</select>
												<label for="impressions">Impressions</label>
											</div>
										</div>
										<div class="col-md-4" *ngIf="products.at(i).get('impressions')?.value !== '1' || products.at(i).get('drops')?.value !== '1'">
											<div class="form-floating">
												<select type="select" formControlName="frequency" class="form-select">
													<option value="1">1 Week</option>
													<option value="2">2 Weeks</option>
													<option value="3">3 Weeks</option>
													<option value="4">4 Weeks</option>
												</select>
												<label for="frequency">Frequency</label>
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="isDirectMailProduct(i)">
									<ng-container *ngIf="newOrderItem">
										<div *ngIf="minDate !== undefined && products.at(i).get('design')?.value">
											<div class="form-floating mb-3">
												<div class="form-inline text-center mb-1">
													<div class="input-group">
														<ngb-datepicker #dp formControlName="deliveryWindow" [dayTemplate]="t" required
														[minDate]="minDate" [maxDate]="maxDate" [firstDayOfWeek]="0" [ngClass]="{ 'is-invalid': product.get('deliveryWindow')?.errors }"
														(dateSelect)="onDateSelection($event)" [markDisabled]="isDisabled" [startDate]="startDate"
														></ngb-datepicker>

														<ng-template #t let-date let-focused="focused" let-disabled="disabled">
															<span
																class="custom-day"
																[class.focused]="focused"
																[class.range]="isRange(date)"
																[class.faded]="isHovered(date) || isInside(date)"
																[class.text-muted]="disabled"
																(mouseenter)="hoveredDate = date"
																(mouseleave)="hoveredDate = undefined"
															>
																{{ date.day }}
															</span>
														</ng-template>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
									<div *ngIf="!isPMProduct(i)" class="row mb-3">
										<div class="col-md-4">
											<div class="form-floating">
												<select type="select" formControlName="impressions" class="form-select" (change)="impressionsChanged(i)">
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option *ngIf="isUS" value="5">5</option>
													<option *ngIf="isUS" value="6">6</option>
													<option *ngIf="isUS" value="7">7</option>
													<option *ngIf="isUS" value="8">8</option>
													<option *ngIf="isUS" value="9">9</option>
													<option *ngIf="isUS" value="10">10</option>
												</select>
												<label for="impressions">Impressions</label>
											</div>
										</div>
										<div class="col-md-4" *ngIf="products.at(i).get('impressions')?.value === '1'">
											<div class="form-floating">
												<select type="select" formControlName="drops" class="form-select" (change)="dropsChanged(i)">
													<option *ngFor="let item of [].constructor(numberOfDrops); let i = index" value="{{i+1}}">
														{{i+1}}
													</option>
												</select>
												<label for="drops">Drops</label>
											</div>
										</div>
										<div class="col-md-4" *ngIf="products.at(i).get('impressions')?.value !== '1' || products.at(i).get('drops')?.value !== '1'">
											<div class="form-floating">
												<select type="select" formControlName="frequency" class="form-select" [ngClass]="{ 'is-invalid': product.get('frequency')?.errors}">
													<option value="1">1 Week</option>
													<option value="2">2 Weeks</option>
													<option value="3">3 Weeks</option>
													<option value="4">4 Weeks</option>
												</select>
												<label for="frequency">Frequency</label>
											</div>
											<div *ngIf="submitted && products.at(i).get('frequency')?.errors" class="input-error">
												Frequency is required
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<ng-container *ngIf="isDirectMailProduct(i) || isExtraCopies(i)">
							<app-extra-copies-customization [countryCode]="countryCode" [siteId]="siteId" [baseProductId]="products.at(i).get('productId')?.value"
							[cartData]="cartData" [messaging]="''"
							[siteProducts]="siteProducts" [onlyExtraCopies]="addExtraCopiesOnly" [submitted]="submitted" (extraCopiesSelected)="extraCopiesSelected($event)">
						</app-extra-copies-customization>
						</ng-container>
						<ng-container *ngIf="(isDirectMailProduct(i) || isExtraCopies(i)) && (products.at(i).get('extraCopiesQuantity')?.value !== '' && products.at(i).get('extraCopiesQuantity')?.value !== 0)">
							<div class="row mb-3">
								<div class="col-6">
									<app-customer-addresses [submitted]="submitted" [addressType]="'Shipping'" [customerId]="customerId!" [cartAddressId]="products.at(i).get('shippingAddress')?.value" [disableChangeAddress]="!!extraCopiesCartProduct" (addressSelected)="selectShippingAddress($event, i)">
									</app-customer-addresses>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="isDirectMailProduct(i) || isDigitalProduct(i)">
							<div class="row">
								<div class="col-6">
									<div class="form-floating mb-3">
										<input required type="text" class="form-control" formControlName="rushFee" [ngClass]="{ 'is-invalid': product.get('rushFee')?.errors}" />
										<label>Rush Fee</label>
									</div>
								</div>
								<div *ngIf="submitted && products.at(i).get('rushFee')?.errors" class="input-error">
									Rush Fee must not be less than 0
								</div>
							</div>
						</ng-container>
						<div class="row" *ngIf="isDirectMailProduct(i) && !isSnapAdMailProduct(i) && !showSpinner">
							<div class="col-6">
								<app-customer-addresses [submitted]="submitted" [addressType]="'Return'" [customerId]="customerId!" [cartAddressId]="products.at(i).get('returnAddress')?.value" [disableChangeAddress]="!!orderItemCartProduct" (addressSelected)="selectReturnAddress($event, i)">
								</app-customer-addresses>
							</div>
						</div>
						<ng-container *ngIf="newOrderItem">
							<div class="row mb-3">
								<div class="col-md-12">
									<div class="form-floating">
										<input type="text" formControlName="jobComments" class="form-control">
										<label for="startDate">Job Comments</label>
									</div>
								</div>
							</div>
						</ng-container>

						<div class="row mt-3">
							<div class="col">
								<div class="row d-flex justify-content-end">
									<div class="col-auto me-2">
										<button class="btn btn-sm btn-danger" data-toggle="tooltip" data-placement="top" title="Cancel Update"
										(click)="emitCancelUpdate()">
											Cancel Update
										</button>
									</div>
									<div *ngIf="!newOrderItem; else newOrderItemButton" class="col-auto me-2">
										<button class="btn btn-sm btn-primary" data-toggle="tooltip" data-placement="top" title="Update Order Item"
										(click)="saveProduct(0)">
											Update Order Item
										</button>
									</div>
									<ng-template #newOrderItemButton>
										<div class="col-auto me-2">
											<button class="btn btn-sm btn-primary" data-toggle="tooltip" data-placement="top" title="Add Order Item"
											(click)="saveProduct(0)">
												Add Order Item
											</button>
										</div>
									</ng-template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</app-spinner-with-overlay>

<ng-template #selectedDistributionModal>
	<app-confirm-modal title="Update Order Item Distribution Confirmation" confirmText="confirm" (confirmAction)="distributionSelected(0, newDistId!)"
		(closeModal)="cancelChangeDistribution()">
		{{ selectedDistModalMessage }}
	</app-confirm-modal>
</ng-template>
