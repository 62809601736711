<app-customer-header [customerId]="customerId"></app-customer-header>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card p-3">
		<div class="d-flex justify-content-between p-2" *ngIf="total > pageSize">
			<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10" [pageSize]="orderSearch.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Choose Items per Page</label>
			</div>
		</div>
		<div class="row">
			<div class="col text-end">
				<span *ngIf="getInvoiceTotal() > 0"><span>New Invoice Balance</span> {{getInvoiceTotal() | currency }}&nbsp;&nbsp;&nbsp;</span>
				<button class="btn btn-primary" [disabled]="invoiceOrders.length === 0" (click)="modalService.open(confirmInvoiceModal)">Create Invoice</button>
			</div>
		</div>

		<div class="table-responsive" *ngIf="total > 0">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Order Id</th>
						<th>Order Date</th>
						<th>Job Name</th>
						<th>Order Total</th>
						<th>Payment Total</th>
						<th>Order Balance</th>
						<th>Order Site</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let order of orders" [ngClass]="{'table-danger': order.deleted}">
						<td>
							<a [routerLink]="['/orders/',order.orderId]" class="btn btn-link">{{ order.orderId}}</a>
						</td>
						<td>
							{{order.created | date: 'MM/dd/yyyy HH:mm:ss'}}
						</td>
						<td>
							{{getJobName(order)}}
						</td>
						<td>
							{{order.orderAmount | currency}}
						</td>
						<td>
							{{getPaymentTotal(order) | currency}}
							<a [routerLink]="['/orders/', order.orderId, 'payments']" class="btn btn-small" data-toggle="tooltip" data-placement="top" title="View payment history">
								<i class="text-muted fas fa-link"></i>
							</a>
						</td>
						<td>
							<span *ngIf="getBalance(order) > 0" class="text-danger">{{getBalance(order) | currency}}</span><br />
							<div *ngIf="getBalance(order) > 0" class="form-check">
								<input class="form-check-input" type="checkbox" value="" id="invoiceCheck + {{order.orderId}}" (click)="addOrRemoveFromInvoiceOrders(order)">
								<label class="form-check-label fw-bold" for="invoiceCheck + {{order.orderId}}">
									Add to invoice
								</label>
							</div>
						</td>
						<td>
							{{getSiteName(order)}}
						</td>
						<td>
							<ul class="list-inline">
								<li class="list-inline-item"><a [routerLink]="['/orders/',order.orderId]" class="btn btn-primary btn-sm">View</a></li>
								<li class="list-inline-item" *ngIf="order.deleted"><span class="text-danger">DELETED</span></li>
							</ul>


						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<app-alert-box *ngIf="!loading && total === 0" alertMode="default">
			This customer has no orders.
		</app-alert-box>

		<div class="d-flex justify-content-between p-2" *ngIf="total > pageSize">
			<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10" [pageSize]="orderSearch.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Choose Items per Page</label>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>

<ng-template #confirmInvoiceModal let-modal>
	<app-confirm-modal confirmText="confirm" title="Confirm Order Amounts" (confirmAction)="createInvoice()" (closeModal)="closeModal()">
		<strong>Send Invoice Emails (seperate by ;):</strong>
		<textarea rows="2" class="form-control"
			placeholder="Email addresses separated by a comma, semi-colon or line break."
			[(ngModel)]="emailAddresses"></textarea>
		<br/>
		<strong>Confirm the order amount to be paid:</strong>
        <div class="table-responsive" *ngIf="invoiceOrders.length !== 0" >
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Order Id</th>
						<th>Payment Amount</th>
						<th>Note</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let order of invoiceOrders" class="settingRow">
						<td>{{ order.orderId }}</td>
						<td>
							<input type="number" name="paymentAmount" class="form-control"
							[(ngModel)]="order.paymentAmount">
						</td>
						<td>
							<input type="text" name="notes" class="form-control" [(ngModel)]="order.notes">
						</td>
					</tr>
					<tr>
						<td><strong>Total:</strong></td>
						<td><strong>{{getInvoiceTotal() | currency}}</strong></td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-confirm-modal>
</ng-template>
