import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import {
	AddItemToCartQuery, BaseDesign, CartProduct, Customer,
	Design, DropsKind, DropsQuery, Order, PostageType,
	ProductOptionCategory, ProductOptionIdentifier, ProductPrintMethodOption,
	Site, USelectDesignType, USelectMethod, WLProduct, Calendar, USelectInductionTurnaround, GetCalendarRequest, GetCaCalendarRequest
} from '@taradel/web-api-client';
import { CustomerService } from 'services/customer.service';
import { SitesService } from 'services/sites.service';
import { DistributionsService, USelect } from 'services/distributions.service';
import { SalesApiService } from 'services/sales-api.service';
import { ToastService } from 'services/toast.service';
import { OrderService } from 'services/order.service';
import { ShoppingCartService } from 'services/shopping-cart.service';
import { ProductCategories, ProductCategoryOptions, ProductsService } from 'services/products.service';
import { SiteConfigService } from 'services/site-config.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { filter, take } from 'rxjs/operators';
import { numDaysBetween } from 'app/utils/num-days-between';
import { environment } from 'environment';
import { isItemLine } from 'services/orderlayout.service';
import { SortPipe } from 'components/shared/sort/sort.pipe';
import { BuildMyCartQuery, Bundle, BundleItem, Distribution, EddmDistribution, ShoppingCart, SnapAdMailDistribution } from '@taradel/admin-api-client';

export interface DigitalProduct {
	productId: number;
	excluded?: boolean;
	name: string;
	startDate?: Date;
	endDate?: Date;
	dailySpend: number;
	maxDays: number;
	required?: boolean;
}


@Component({
selector: 'app-customize-bundle',
templateUrl: './customize-bundle.component.html',
styleUrls: ['./customize-bundle.component.scss']
})
export class CustomizeBundleComponent  implements OnInit {
	USelectDesignType = USelectDesignType;
	showSpinner = true;
	isUS = environment.instance === 'US';
	customerId = 0;
	customer!: Customer;
	baseProductId = 0;
	customerLastOrder?: Order;
	hoveredDate?: NgbDateStruct = undefined;
	fromDate?: NgbDateStruct = undefined;
	toDate?: NgbDateStruct = undefined;
	minDate!: NgbDateStruct;
	maxDate!: NgbDateStruct;
	startDate!: NgbDateStruct;
	calendar?: Calendar;
	inductionTurnaroundTime?: USelectInductionTurnaround;
	standardMailDeliveryTime = 0;
	firstClassDeliveryTime = 0;
	isDisabled = (ngbDate: NgbDateStruct) => {
		const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
		const isHoliday = this.calendar?.holidays?.find(x => x.toDateString() === date.toDateString()) !== undefined;
		const designType: USelectDesignType = this.bundleForm.get('design')?.value;
		const designDeliveryOption = this.calendar?.designDeliveryOption?.find(x => x.designType === designType)?.standardDeliveryOptions;
		const hasDate = designDeliveryOption?.findIndex(x => x.firstInHomeDate.toISOString() === date.toISOString()) !== -1;
		return !hasDate || isHoliday;
	};
	today: string = '';
	siteId?: number;
	minimumDigitalBudget: number = 0;
	site!: Site;
	allUSelects: USelectMethod[] = [];
	distributionId = 0;
	distribution?: Distribution;
	cart?: ShoppingCart;
	bundle?: Bundle;
	eddmDistribution?: EddmDistribution;
	snapAdmailDistribution?: SnapAdMailDistribution;
	siteProducts: WLProduct[] = [];
	allowedProducts: WLProduct[] = [];
	allProductOptions: ProductOptionCategory[] = [];
	printMethodOptions = new Map<number, ProductPrintMethodOption[]>();
	productOptions: ProductPrintMethodOption[] = [];
	printDeliveryDate?: Date;
	numberOfDrops = 1;
	bundleForm: UntypedFormGroup;
	submitted = false;
	uploadedFrontFile?: File;
	uploadedBackFile?: File;
	productAdded = false;
	disableSaveButton = false;
	extraCopiesProducts?: WLProduct[];
	extraCopiesCartProduct?: CartProduct;
	countryCode: 'US' | 'Canada' = 'US';
	bundleId: number = 0;
	callTrackingBundleItem?: BundleItem;
	qrCodeBundleItem?: BundleItem;
	digitalProducts: DigitalProduct[] = [];
	minTargetedEmailQuantity = 0;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		private customerService: CustomerService,
		private sitesService: SitesService,
		private distributionsService: DistributionsService,
		private toastService: ToastService,
		private orderService: OrderService,
		private salesApiService: SalesApiService,
		private shoppingCartService: ShoppingCartService,
		private productsService: ProductsService,
		private siteConfigService: SiteConfigService,
		private sortPipe: SortPipe) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		this.siteId = parseInt(route.snapshot.paramMap.get('siteId') ?? '0', 10);
		this.distributionId = parseInt(route.snapshot.paramMap.get('distributionId') ?? '0', 10);
		this.bundleId = parseInt(route.snapshot.paramMap.get('bundleId') ?? '0', 10);

		this.route.data.pipe(
			filter((data) => !!data),
			take(1)
		).subscribe(data => this.countryCode = data.countryCode);

		this.bundleForm = this.formBuilder.group({
			productId: new UntypedFormControl('', Validators.required),
			design: new UntypedFormControl('', Validators.required),
			front: new UntypedFormControl(''),
			back: new UntypedFormControl(''),
			proof: new UntypedFormControl(''),
			returnAddress: new UntypedFormControl('', Validators.required),
			deliveryWindow: new UntypedFormControl('', Validators.required),
			impressions: new UntypedFormControl('1', Validators.required),
			drops: new UntypedFormControl(''),
			frequency: new UntypedFormControl(''),
			extraCopiesProductId: new UntypedFormControl(''),
			extraCopiesQuantity: new UntypedFormControl(''),
			shippingAddress: new UntypedFormControl(''),
			excludedCallTracking: new UntypedFormControl(false),
			callTrackingType: new UntypedFormControl(''),
			areaCode: new UntypedFormControl(''),
			forwardingNumber: new UntypedFormControl(''),
			excludedQrCode: new UntypedFormControl(false),
			webLink: new UntypedFormControl(''),
		});
	}

	async ngOnInit(): Promise<void> {
		try {
			[this.customer, this.site, this.distribution, this.bundle, this.allUSelects, this.allProductOptions] = await Promise.all([
				this.customerService.getCustomer(this.customerId),
				this.sitesService.getSite(this.siteId!),
				this.distributionsService.getDistribution(this.distributionId),
				this.sitesService.getBundle(this.bundleId),
				this.sitesService.getAllUSelect(),
				this.productsService.getAllProductOptions()
			]);

			try {
				this.cart = await this.shoppingCartService.getShoppingCart(this.customerId, this.siteId!);
			}
			catch (error: any) {
				this.cart = undefined;
			}
			this.initializeStartDate();
			await this.loadInitialData();
			this.allowedProducts = this.siteProducts.filter(x => USelect.IsDirectMail(x.baseProduct?.uSelectConfigurations![0].uSelectId!));
			const targetedEmailProduct = this.siteProducts.find(sp => sp.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.targetedEmail);
			this.minTargetedEmailQuantity = targetedEmailProduct && targetedEmailProduct.baseProduct?.priceMatrix ? targetedEmailProduct.baseProduct?.priceMatrix[0].minQty : 0;

			if(this.cart && (this.cart.bundles?.length ?? 0) > 0) {
				// load cart bundle
				await this.loadBundleFormFromCart();
			}
		}
		catch {
			this.toastService.showError('There was a problem loading the component', 'Load Error');
		}
		finally {
			this.showSpinner = false;
		}
	}

	async loadBundleFormFromCart() {
		const printProduct = this.cart?.cartData?.find(p => USelect.IsDirectMail(p.uSelectId!));
		this.bundleForm.controls.productId.setValue(printProduct?.baseProductId);
		await this.productSelected();
		// populated options
		const productOptions = this.bundleForm?.get('options');
		if (productOptions) {
			const options = productOptions as UntypedFormArray;
			Object.keys(options.controls).forEach(async key => {
				const selectedValue = printProduct?.attributes?.find(a => a.name === key);
				productOptions.get(key)?.setValue(parseInt(selectedValue?.value!));
				await this.optionSelected(key);
			});
		}
		//populated design
		this.bundleForm.controls.design.setValue(printProduct?.design?.uSelectDesign);
		this.designSelected();
		this.bundleForm.controls.front.setValue(printProduct?.design?.front?.fileName);
		this.bundleForm.controls.back.setValue(printProduct?.design?.back?.fileName);
		//populate return address
		if (printProduct?.drops?.returnAddressId) {
			this.bundleForm.controls.returnAddress.setValue(printProduct?.drops?.returnAddressId);
		}
		// populate drop
		const firstDrop = printProduct?.drops?.orderDrops![0].date!;
		const deliveryOptions = this.calendar?.designDeliveryOption?.find(d => d.designType === this.bundleForm.get('design')!.value)?.standardDeliveryOptions;
		const foundDeliveryOption = deliveryOptions?.find(o => {
			const formattedDate = new Date(o.firstAtPostOfficeDate.getFullYear(), o.firstAtPostOfficeDate.getMonth(), o.firstAtPostOfficeDate.getDate());
			if (formattedDate.toISOString() === firstDrop.toISOString()) {
				return o;
			}
			else {
				return undefined;
			}
		});
		const date = new NgbDate(foundDeliveryOption?.firstInHomeDate.getFullYear()!, foundDeliveryOption?.firstInHomeDate.getMonth()! + 1, foundDeliveryOption?.firstInHomeDate.getDate()!);
		//this.bundleForm.controls.deliveryWindow.setValue(date);
		this.onDateSelection(date);
		const impressions = printProduct?.drops?.orderDrops![0].multiple ?? 1;
		this.bundleForm.controls.impressions.setValue(impressions.toString());
		this.impressionsChanged();
		const numberOfDrops = impressions > 1 ? 1 : printProduct?.drops?.orderDrops?.length;
		this.bundleForm.controls.drops.setValue(numberOfDrops?.toString());
		this.dropsChanged();
		if ((printProduct?.drops?.orderDrops?.length ?? 0) > 1) {
			const secondDropDate = printProduct?.drops?.orderDrops![1].date!;
			const diffDays = this.getDaysBetweenDrops(firstDrop, secondDropDate);
			const weeksBetweenDrops = diffDays / 7;
			this.bundleForm.controls.frequency.setValue(weeksBetweenDrops);
		}
		else {
			this.bundleForm.controls.frequency.setValue(1);
		}

		// populated exta copies
		const ecProduct = this.cart?.cartData?.find(p => USelect.extraCopies === p.uSelectId!);
		if (ecProduct) {
			this.bundleForm.controls.extraCopiesProductId.setValue(ecProduct.baseProductId);
			this.bundleForm.controls.extraCopiesQuantity.setValue(ecProduct.quantity);
			this.extraCopiesSelected({productSelected: ecProduct.baseProductId, quantitySelected: ecProduct.quantity});
			this.bundleForm.controls.shippingAddress.setValue(ecProduct.shippingAddressId);
		}
		//populated digital
		this.digitalProducts = [];
		const digitalCartProducts = this.cart?.cartData?.filter(p => USelect.IsPartOfBundle(p.uSelectId!));
		digitalCartProducts?.forEach(p => {
			if ((p.budget ?? 0) > 0) {
				this.bundleForm.get(`${p.baseProductId}campaignDuration`)?.setValue(p.quantity);
				this.bundleForm.get(`${p.baseProductId}budget`)?.setValue(p.budget);
				this.bundleForm.get(`${p.baseProductId}totalSpend`)?.setValue((p.budget ?? 0) * p.quantity);
			}
			const required = this.bundle?.bundleItems?.find(bi => bi.productConfiguration?.includes(p.baseProductId))?.required ?? false;
			const digitalProduct = {
				productId: p.baseProductId!,
				excluded: false,
				name: p.name!,
				startDate: p.drops?.orderDrops![0].date,
				endDate: p.drops?.orderDrops![0].endDate,
				dailySpend: p.budget!,
				maxDays: p.quantity,
				required
			};
			this.digitalProducts.push(digitalProduct);
			this.updateDigitalSpend(digitalProduct);
			this.startDateSelected(digitalProduct);
		});

		//populate tracking
		const callTrackingProduct = this.cart?.cartData?.find(p => USelect.callTracking === p.uSelectId!);
		if (callTrackingProduct) {
			const areaCode = callTrackingProduct?.meta!['AreaCode'].toString();
			const callTrackingType = areaCode.length > 0 ? ProductCategoryOptions.LocalAreaCode : ProductCategoryOptions.TollFree;
			this.bundleForm.controls.callTrackingType.setValue(callTrackingType.toString());
			this.trackingTypeSelected();
			this.bundleForm.controls.areaCode.setValue(callTrackingProduct.meta!['AreaCode'].toString());
			this.bundleForm.controls.forwardingNumber.setValue(callTrackingProduct.meta!['ForwardingNumber'].toString());
		}
		else {
			this.bundleForm.controls.excludedCallTracking.setValue(true);
			this.excludeCallTracking();
		}
		const qrCodeProduct = this.cart?.cartData?.find(p => USelect.qrCode === p.uSelectId!);
		if (qrCodeProduct) {
			this.bundleForm.controls.webLink.setValue(qrCodeProduct.meta!['WebLink'].toString());
		}
		else {
			this.bundleForm.controls.excludedQrCode.setValue(true);
			this.excludeQrCode();
		}
	}
	initializeStartDate() {
		const today = new Date();
		const newDate = today.setDate(today.getDate() + 3);
		this.today = formatDate(newDate, 'yyyy-MM-dd', 'en');
	}

	async loadInitialData() {
		this.siteProducts = await this.sitesService.getSiteProducts(this.siteId!);
		this.siteProducts.map(x => {
			x.baseProduct!.priceMatrix = this.sortPipe.transform(x.baseProduct?.priceMatrix ?? [], "asc", "minQty");
		});

		await this.initializeFormBasedonBundle();
	}

	selectReturnAddress(value: any) {
		this.bundleForm.controls.returnAddress.setValue(value);
	}

	selectShippingAddress(value: any) {
		this.bundleForm.controls.shippingAddress.setValue(value);
	}

	addressFormatter = (x: { address1: string }) => x.address1;

	async initializeFormBasedonBundle() {
		await this.filterProductsBasedOnBundle();
		this.callTrackingBundleItem = this.bundle?.bundleItems?.find(i => {
			return this.siteProducts.findIndex(p => p.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.callTracking && i.productConfiguration?.includes(p.baseProductId!)) !== -1;
		});
		if (this.callTrackingBundleItem && this.callTrackingBundleItem.required) {
			this.bundleForm.get('callTrackingType')!.setValidators([Validators.required]);
			this.bundleForm.get('callTrackingType')!.updateValueAndValidity();
			this.bundleForm.get('forwardingNumber')!.setValidators([Validators.required]);
			this.bundleForm.get('forwardingNumber')!.updateValueAndValidity();
		}
		this.qrCodeBundleItem = this.bundle?.bundleItems?.find(i => {
			return this.siteProducts.findIndex(p => p.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.qrCode && i.productConfiguration?.includes(p.baseProductId!)) !== -1;
		});
		if (this.qrCodeBundleItem && this.qrCodeBundleItem.required) {
			this.bundleForm.get('webLink')!.setValidators([Validators.required]);
			this.bundleForm.get('webLink')!.updateValueAndValidity();
		}

		if (this.distribution!.uSelectId === USelect.eddm) {
			this.eddmDistribution = await this.distributionsService.getEddmDistribution(this.customerId, this.distributionId);
			this.numberOfDrops = this.eddmDistribution.carrierRoutes?.length! > 10 ? 10 : this.eddmDistribution.carrierRoutes?.length!;
		}
		if (this.distribution!.uSelectId === USelect.snapAdmail) {
			this.snapAdmailDistribution = await this.distributionsService.getSnapAdmailDistribution(this.customerId, this.distributionId);
			this.numberOfDrops = this.snapAdmailDistribution.letterCarrierWalks?.length! > 4 ? 4 : this.snapAdmailDistribution.letterCarrierWalks?.length!;
		}
	}

	getProductNameWithOrderDate(wlProduct: WLProduct): string {
		const uSelectName = this.allUSelects.find(x => x.uSelectId === wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId)!.name;
		const product = this.customerLastOrder?.items?.find(oi => oi.productId === wlProduct.baseProductId!);
		return product ? `(${uSelectName}) ${wlProduct.name} - (Last ordered ${this.customerLastOrder?.created.toLocaleDateString()})` : `(${uSelectName}) ${wlProduct.name}`;
	}

	getLastOrderedExtraCopies(): string {
		const baseProductId = parseInt(this.bundleForm.controls.productId.value, 10);
		const wlProduct = this.siteProducts.find(x => x.baseProductId === baseProductId)!;
		const extraCopiesProducts = this.siteProducts.filter(x => x.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies);
		const selectedExtraCopiesProduct = extraCopiesProducts.find(x => x.baseProduct?.paperWidth === wlProduct.baseProduct?.paperWidth && x.baseProduct?.paperHeight === wlProduct.baseProduct?.paperHeight);
		const ecOrderItem = this.customerLastOrder?.items?.find(oi => oi.productId === selectedExtraCopiesProduct?.baseProductId);
		const quantity = ecOrderItem?.lines?.reduce((a, o) => (ecOrderItem?.lines?.find(line => isItemLine(line.lineType) && line.orderItemLineId === o.orderItemLineId)?.quantity ?? 0) + a, 0) ?? 0;
		return ecOrderItem ? `Last ordered ${quantity} copies on ${this.customerLastOrder?.created.toLocaleDateString()}` : '';
	}

	get f() {
		return this.bundleForm.controls;
	}

	get options() {
		const opt = ((this.bundleForm.controls.products as UntypedFormArray).get('options') as UntypedFormArray);
		return opt.controls;
	}

	getProductOptions(): string[] {
		const options = this.bundleForm.get('options') as UntypedFormArray;
		if (options !== null) {
			return Object.keys(options.controls);
		}
		else {
			return [];
		}
	}

	async filterProductsBasedOnBundle() {
		let products: WLProduct[] = [];
		const bundleUSelects: number[] = [];
		this.bundle?.bundleItems?.map(x => {
			const bundleItemProducts = this.siteProducts.filter(p => x.productConfiguration?.includes(p.baseProductId!))!;
			bundleUSelects.push(bundleItemProducts[0].baseProduct?.uSelectConfigurations![0].uSelectId!);
			products.push(...bundleItemProducts);
		});
		if (!bundleUSelects.some(u => u === this.distribution?.uSelectId)) {
			this.toastService.showError('Bundle not allowed with selected distribution');
			return;
		}
		products = products.concat(this.siteProducts.filter(p => p.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies));

		this.siteProducts = products;
		const dmProducts = this.siteProducts
			.filter(p => USelect.IsDirectMail(p.baseProduct?.uSelectConfigurations![0].uSelectId!))
			.map(p => p.baseProductId!);
		try {
			this.customerLastOrder = await this.orderService.getCustomerLastOrder(this.customerId, this.siteId!, dmProducts);
		}
		catch (error: any) {
			if (error.status !== 404) {
				this.toastService.showError('Something went wrong trying to retrieve last order product.');
			}
		}
	}

	deliveryDateSelected(value: string) {
		this.printDeliveryDate = new Date(value);
		this.digitalProducts.forEach(x => {
			this.bundleForm.removeControl(`${x.productId!.toString()}budget`);
			this.bundleForm.removeControl(`${x.productId!.toString()}campaignDuration`);
			this.bundleForm.removeControl(`${x.productId!.toString()}totalSpend`);
			this.bundleForm.removeControl(`${x.productId!.toString()}startDate`);
			this.bundleForm.removeControl(`${x.productId!.toString()}endDate`);
			this.bundleForm.removeControl(`${x.productId!.toString()}excluded`);
		});
		this.digitalProducts = [];
		this.bundle?.bundleItems?.forEach(bundleItem => {
			const product = this.siteProducts.find(p => bundleItem.productConfiguration!.includes(p.baseProductId!) && USelect.IsPartOfBundle(p.baseProduct?.uSelectConfigurations![0].uSelectId!));
			if (product) {
				const name = product.name!;
				const firstDropDate = new Date(this.printDeliveryDate!);
				const recommendedEndDate = new Date(firstDropDate);
				recommendedEndDate.setDate(firstDropDate.getDate() + bundleItem.quantity - 1);
				this.digitalProducts.push({
					productId: product.baseProductId!,
					excluded: false,
					name,
					startDate: firstDropDate,
					endDate: recommendedEndDate,
					dailySpend: bundleItem.dailySpend,
					maxDays: bundleItem.quantity,
					required: bundleItem.required
				});
			}
		});

		this.digitalProducts.forEach(x => {
			if (x.dailySpend > 0) {
				this.bundleForm.addControl(`${x.productId!.toString()}budget`, new UntypedFormControl({ value: x.dailySpend, disabled: false }, [Validators.required, Validators.min(x.dailySpend)]));
				this.bundleForm.addControl(`${x.productId!.toString()}campaignDuration`, new UntypedFormControl({ value: x.maxDays, disabled: false }, Validators.required));
				this.bundleForm.addControl(`${x.productId!.toString()}totalSpend`, new UntypedFormControl({ value: x.dailySpend * x.maxDays, disabled: false }, [Validators.required, Validators.min(x.dailySpend * x.maxDays)]));
			}

			this.bundleForm.addControl(`${x.productId!.toString()}startDate`, new UntypedFormControl({ value: formatDate(x.startDate!, 'yyyy-MM-dd', 'en'), disabled: false }, x.required ? Validators.required : undefined));
			this.bundleForm.addControl(`${x.productId!.toString()}endDate`, new UntypedFormControl({ value: x.endDate, disabled: true }));
			this.bundleForm.addControl(`${x.productId!.toString()}excluded`, new UntypedFormControl({ value: false, disabled: false }));
		});

	}

	getDaysBetweenDrops(firstDropDate: Date, secondDropDate: Date): number {
		return Math.round(numDaysBetween(secondDropDate, firstDropDate));
	}

	buildProductOptions(): ProductOptionIdentifier[] {
		const productOptions: ProductOptionIdentifier[] = [];
		const selectedOptions = this.bundleForm?.get('options');
		if (selectedOptions) {
			const options = selectedOptions as UntypedFormArray;
			Object.keys(options.controls).forEach(key => {
				const optionValue = selectedOptions.get(key)?.value;
				const productOptionCategory = this.allProductOptions.find(x => x.options?.findIndex(o => o.optionId === optionValue) !== -1)!;
				productOptions.push(new ProductOptionIdentifier({
					optCatId: productOptionCategory.optCatId,
					optionId: optionValue
				}));
			});
		}
		return productOptions;
	}

	async buildDesign(): Promise<Design | undefined> {
		let design: Design | undefined;

		if (this.bundleForm.controls.design !== undefined && (this.bundleForm.controls.design.value as USelectDesignType).length > 1) {
			switch (this.bundleForm.controls.design.value) {
				case USelectDesignType.Upload:
					const maxFileSize = 25 * 1024 * 1024; // 25MB Max file size
					let frontFileServerName = '';
					let backFileServerName = '';
					if (this.uploadedFrontFile !== undefined && this.uploadedFrontFile.size <= maxFileSize) {
						frontFileServerName = await this.salesApiService.uploadTemporaryFile({
							fileName: this.uploadedFrontFile.name,
							data: this.uploadedFrontFile
						});
					}
					if (this.uploadedBackFile !== undefined && this.uploadedBackFile.size <= maxFileSize) {
						backFileServerName = await this.salesApiService.uploadTemporaryFile({
							fileName: this.uploadedBackFile.name,
							data: this.uploadedBackFile
						});
					}
					design = new Design({
						isProofRequired: true,
						front: new BaseDesign({
							fileName: this.uploadedFrontFile ? this.uploadedFrontFile.name : '',
							fileType: this.uploadedFrontFile ? '.' + this.uploadedFrontFile.name.split('.').pop() : '',
							realFileName: frontFileServerName !== '' ? frontFileServerName : '',
							thumb: '',
							designSelectionType: USelectDesignType.Upload
						}),
						back: new BaseDesign({
							fileName: this.uploadedBackFile ? this.uploadedBackFile.name : '',
							fileType: this.uploadedBackFile ? '.' + this.uploadedBackFile.name.split('.').pop() : '',
							realFileName: backFileServerName !== '' ? backFileServerName : '',
							thumb: '',
							designSelectionType: USelectDesignType.Upload
						}),
						uSelectDesign: USelectDesignType.Upload
					});
					break;

				case USelectDesignType.Omitted:
					design = new Design({
						isProofRequired: false,
						front: new BaseDesign({
							designSelectionType: USelectDesignType.Omitted
						}),
						uSelectDesign: USelectDesignType.Omitted
					});
					break;
				case USelectDesignType.ProfessionalDesign:
					design = new Design({
						isProofRequired: false,
						front: new BaseDesign({
							designSelectionType: USelectDesignType.ProfessionalDesign
						}),
						uSelectDesign: USelectDesignType.ProfessionalDesign
					});
					break;
				default:
					design = new Design({
						isProofRequired: false,
						front: new BaseDesign({
							designSelectionType: USelectDesignType.ProfessionalDesign
						}),
						uSelectDesign: USelectDesignType.ProfessionalDesign
					});
					break;
			}
		}

		return design;
	}
	async buildPrintProductQuery(wlProduct: WLProduct): Promise<AddItemToCartQuery> {
		const baseProductId = parseInt(this.bundleForm.controls.productId.value, 10);

		const isMultipleImpressions = this.bundleForm.controls.impressions.value !== '1';
		let numberOfDrops = 1;
		if (isMultipleImpressions && this.bundleForm.controls.impressions.value.length > 0) {
			numberOfDrops = parseInt(this.bundleForm.controls.impressions.value, 10);
		}
		else if (!isMultipleImpressions && this.bundleForm.controls.drops.value.length > 0) {
			numberOfDrops = parseInt(this.bundleForm.controls.drops.value, 10);
		}

		const dropFrequency = this.bundleForm.controls.frequency.value === '' ? 0 : parseInt(this.bundleForm.controls.frequency.value, 10);


		let quantity = this.distribution?.totalDeliveries!;
		if (wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.eddm) {
			quantity = this.distribution?.totalDeliveries!;
		}
		else {
			quantity = quantity > 0 ? quantity : this.distribution?.totalDeliveries!;
		}

		const returnAddress = parseInt(this.bundleForm.controls.returnAddress?.value ?? '0');
		const printProductQuery: AddItemToCartQuery = new AddItemToCartQuery({
			cartProductId: undefined,
			baseProductId,
			quantity,
			distributionId: this.distributionId,
			shippingAddressId: 0,
			design: await this.buildDesign(),
			drops: new DropsQuery({
				firstDropDate: this.printDeliveryDate!,
				kind: isMultipleImpressions ? DropsKind.MultipleImpressions : DropsKind.SplitDrops,
				numberOfDrops,
				weeksBetweenDrops: dropFrequency,
				endDate: undefined as unknown as Date,
				returnAddressId: returnAddress
			}),
			jobComments: '',
			productOptions: this.buildProductOptions()
		});

		return printProductQuery;
	}

	async buildExtraCopiesProductQuery(): Promise<AddItemToCartQuery> {
		const baseProductId = parseInt(this.bundleForm.controls.extraCopiesProductId.value, 10);
		let quantity = parseInt(this.bundleForm.controls.extraCopiesQuantity.value);
		quantity = quantity > 0 ? quantity : this.distribution?.totalDeliveries!;
		const shippingAddressId = this.bundleForm.controls.shippingAddress.value;

		const printOnlyProductQuery: AddItemToCartQuery = new AddItemToCartQuery({
			cartProductId: undefined,
			baseProductId,
			quantity,
			distributionId: this.distributionId > 0 ? this.distributionId : 0,
			shippingAddressId: shippingAddressId,
			jobComments: '',
			productOptions: await this.buildExtraCopiesProductOptions(baseProductId),
			design: undefined
		});

		return printOnlyProductQuery;
	}

	async buildExtraCopiesProductOptions(baseProductId: number) {
		const wlProduct = this.siteProducts.find(sp => sp.baseProductId === baseProductId);
		let printMethodOptions = await this.productsService.getPrintMethodOptions(baseProductId);
		printMethodOptions = printMethodOptions.filter(x => x.printMethodId !== undefined && x.printMethodId === (wlProduct!.baseProduct?.priceMatrix![0].printMethodId ?? 1));
		this.printMethodOptions.set(baseProductId, printMethodOptions);
		let selectedOptions = new Map<number, ProductPrintMethodOption[]>();
		selectedOptions.set(0, printMethodOptions.filter((value, idx, self) =>
			self.findIndex(a => a.option?.optCatId === value.option?.optCatId) === idx));
		const productOptions: ProductOptionIdentifier[] = [];
		if (selectedOptions) {
			Object.keys(selectedOptions).forEach(key => {
				const productOptionCategory = this.allProductOptions.find(x => x.options?.findIndex(o => o.optionId === parseInt(key)) !== -1)!;
				productOptions.push(new ProductOptionIdentifier({
					optCatId: productOptionCategory.optCatId,
					optionId: parseInt(key)
				}));
			});
		}
		return productOptions;
	}

	async buildDigitalProductQuery(digitalProduct: DigitalProduct): Promise<AddItemToCartQuery> {
		const t = this.bundleForm.get(`${digitalProduct.productId}startDate`)?.value!;
		let startDate = new Date(t);
		const u = this.bundleForm.get(`${digitalProduct.productId}endDate`)?.value!;
		let endDate = new Date(u);

		const digitalProductQuery = new AddItemToCartQuery({
			cartProductId: undefined,
			baseProductId: digitalProduct.productId,
			budget: digitalProduct.dailySpend,
			distributionId: this.distributionId,
			jobComments: '',
			quantity: digitalProduct.maxDays,
			design: await this.buildDesign(),
			drops: new DropsQuery({
				firstDropDate: startDate,
				kind: DropsKind.MultipleImpressions,
				numberOfDrops: 1,
				weeksBetweenDrops: 1,
				endDate
			}),
		});

		return digitalProductQuery;
	}

	async buildEmailProduct(wlProduct: WLProduct, digitalProduct: DigitalProduct): Promise<AddItemToCartQuery> {
		const emailDropDate = new Date(digitalProduct.startDate!);

		const emailProduct = new AddItemToCartQuery({
			cartProductId: undefined,
			baseProductId: wlProduct.baseProductId!,
			distributionId: this.distributionId,
			jobComments: '',
			quantity: this.minTargetedEmailQuantity,
			design: await this.buildDesign(),
			drops: new DropsQuery({
				firstDropDate: emailDropDate,
				kind: DropsKind.MultipleImpressions,
				numberOfDrops: 1,
				weeksBetweenDrops: 1,
			})
		});
		return emailProduct;
	}

	buildCallTrackingProduct(wlProduct: WLProduct): AddItemToCartQuery {
		const areaCode = this.bundleForm.controls.areaCode.value.toString();
		const meta = {
			"AreaCode": this.bundleForm.controls.areaCode.value.toString(),
			"ForwardingNumber": this.bundleForm.controls.forwardingNumber.value
		};
		const productOptions: ProductOptionIdentifier[] = [];
		productOptions.push(new ProductOptionIdentifier({optCatId: ProductCategories.CallTrackingNumberType, optionId: areaCode.length > 0 ? ProductCategoryOptions.LocalAreaCode : ProductCategoryOptions.TollFree}));
		return new AddItemToCartQuery({
			cartProductId: undefined,
			baseProductId: wlProduct.baseProductId!,
			distributionId: this.distributionId,
			jobComments: '',
			quantity: 1,
			meta: meta,
			productOptions
		});
	}

	buildQrCodeProduct(wlProduct: WLProduct): AddItemToCartQuery {
		const meta = {
			"WebLink": this.bundleForm.controls.webLink.value.toString()
		};

		return new AddItemToCartQuery({
			cartProductId: undefined,
			baseProductId: wlProduct.baseProductId!,
			distributionId: this.distributionId,
			jobComments: '',
			quantity: 1,
			meta: meta
		});
	}

	async buildCart() {
		this.submitted = true;
		if (!this.bundleForm.valid || this.disableSaveButton) {
			return;
		}
		this.showSpinner = true;
		if (this.bundleForm.controls.extraCopiesQuantity.value === '') {
			this.showSpinner = false;
			return;
		}
		let success = true;
		const productsToAdd: AddItemToCartQuery[] =[];
		const wlProduct = this.siteProducts.find(p => p.baseProductId === parseInt(this.bundleForm.controls.productId.value, 10))!;
		const printProduct = await this.buildPrintProductQuery(wlProduct);
		productsToAdd.push(printProduct);
		if (USelect.IsDirectMail(wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId!) && this.bundleForm.get('extraCopiesQuantity')?.value > 0) {
			const extraCopiesCartProduct = await this.buildExtraCopiesProductQuery();
			productsToAdd.push(extraCopiesCartProduct);
		}

		for (let index = 0; index < this.digitalProducts.length; index++) {
			const product = this.digitalProducts[index];
			if (product.excluded) {
				continue;
			}
			const siteProduct = this.siteProducts.find(x => x.baseProductId === product.productId!)!;
			if (siteProduct.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.targetedEmail) {
				const cartProduct = await this.buildEmailProduct(siteProduct, product);
				productsToAdd.push(cartProduct);
			}
			else {
				const cartProduct = await this.buildDigitalProductQuery(product);
				productsToAdd.push(cartProduct);
			}
		}
		if (!!this.callTrackingBundleItem && !this.bundleForm.get('excludedCallTracking')?.value) {
			const siteProduct = this.siteProducts.find(p => p.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.callTracking)!;
			const cartProduct = this.buildCallTrackingProduct(siteProduct);
			productsToAdd.push(cartProduct);
		}
		if (!!this.qrCodeBundleItem && !this.bundleForm.get('excludedQrCode')?.value) {
			const siteProduct = this.siteProducts.find(p => p.baseProductId === this.qrCodeBundleItem?.productConfiguration![0])!;
			const cartProduct = this.buildQrCodeProduct(siteProduct);
			productsToAdd.push(cartProduct);
		}

		let query: BuildMyCartQuery | null = null;

		try {
			query = new BuildMyCartQuery({
				bundleId: this.bundleId,
				customerId: this.customerId,
				siteId: this.siteId!,
				items: productsToAdd
			});
			await this.shoppingCartService.buildCart(query);
		}
		catch (err: any) {
			success = false;
			if (err?.response) {
				this.toastService.showError(`Bundle could not be added, server response: ${err.response}`);
			}
			else {
				this.toastService.showError('Bundle could not be added');
			}
			console.error(err?.response, { query, err });
		}
		finally {
			this.showSpinner = false;
		}
		if (success) {
			this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId]);
		}
	}

	getPostageValue(cartProduct: AddItemToCartQuery): PostageType {
		let postageType = PostageType.StandardMail;
		if (cartProduct.productOptions) {
			const postageOption = cartProduct.productOptions.find(x => x.optCatId === ProductCategories.PostageClass);
			if (postageOption && postageOption.optionId === ProductCategoryOptions.FirstClass) {
				postageType = PostageType.FirstClass;
			}
			else if (postageOption && postageOption.optionId === ProductCategoryOptions.NonProfit) {
				postageType = PostageType.NonProfit;
			}
		}
		return postageType;
	}

	cancelCustomization() {
		this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId]);
	}

	async productSelected() {
		this.showSpinner = true;
		this.baseProductId = parseInt(this.bundleForm.get('productId')!.value, 10);
		// show product related options
		this.printMethodOptions.clear();
		this.productOptions = [];
		this.bundleForm.removeControl('options');
		this.bundleForm.controls.design.setValue('');
		this.bundleForm.controls.design.enable();
		const wlProduct = this.siteProducts.find(p => p.baseProductId === this.baseProductId)!;
		let printMethodOptions = await this.productsService.getPrintMethodOptions(this.baseProductId);
		printMethodOptions = printMethodOptions.filter(x => x.printMethodId !== undefined && x.printMethodId === (wlProduct.baseProduct?.priceMatrix![0].printMethodId ?? 1));
		const nonProfitVal = this.customer.customerProperties?.find(x => x.propertyName?.includes('NonProfitAuthorizationNumber'))?.propertyValue;
		const isCustomerNonProfit = !!nonProfitVal && nonProfitVal.length > 0;
		if (isCustomerNonProfit) {
			printMethodOptions = printMethodOptions.filter(x => x.optionId !== ProductCategoryOptions.StandardMail);
		}
		else {
			printMethodOptions = printMethodOptions.filter(x => x.optionId !== ProductCategoryOptions.NonProfit);
		}
		this.printMethodOptions.set(this.baseProductId, printMethodOptions);
		this.productOptions = printMethodOptions.filter((value, idx, self) =>
			self.findIndex(a => a.option?.optCatId === value.option?.optCatId) === idx);

		const optionsFB = this.formBuilder.group({});
		this.productOptions.forEach(x => {
			let optionName = this.allProductOptions.find(y => y.optCatId === x.option?.optCatId)!.name!;
			const options = this.getOptions(optionName);
			if (options.length === 1) {
				optionsFB.addControl(optionName, new UntypedFormControl({ value: options[0].option?.optionId, disabled: true }, Validators.required));
			}
			else {
				optionsFB.addControl(optionName, new UntypedFormControl({ value: '', disabled: false }, Validators.required));
			}
		});
		this.bundleForm.addControl('options', optionsFB);

		this.bundleForm.get('design')!.setValidators([
			Validators.required
		]);
		this.bundleForm.get('design')!.updateValueAndValidity();

		const quantity = this.distribution?.totalDeliveries!;
		let postageType = PostageType.StandardMail;
		if (!!this.bundleForm.get('options')?.get('Postage Class')) {
			const selectedPostage = this.bundleForm.get('options')?.get('Postage Class')!.value;
			if (selectedPostage === ProductCategoryOptions.FirstClass) {
				postageType = PostageType.FirstClass;
			}
			else if (selectedPostage === ProductCategoryOptions.NonProfit) {
				postageType = PostageType.NonProfit;
			}
		}
		if (this.isUS) {
			this.calendar = await this.salesApiService.getUsDates(new GetCalendarRequest({
				siteId: this.siteId!,
				customerId: this.customerId,
				productId: wlProduct?.baseProductId!,
				quantity: quantity > 0 ? quantity : this.distribution?.totalDeliveries!,
				postageType
			}));
		}
		else {
			this.calendar = await this.salesApiService.getCaDates(this.customerId, new GetCaCalendarRequest({
				siteId: this.siteId!,
				productId: wlProduct?.baseProductId!,
				quantity: quantity > 0 ? quantity : this.distribution?.totalDeliveries!,
				postageType,
				distributionId: this.distributionId
			}));
		}

		this.inductionTurnaroundTime = await this.salesApiService.getUSelectInductionTurnaround(wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId!);
		this.standardMailDeliveryTime = await this.siteConfigService.getNumber(this.siteId!, 'Site', 'StandardMailDeliveryTime');
		this.firstClassDeliveryTime = await this.siteConfigService.getNumber(this.siteId!, 'Site', 'FirstClassDeliveryTime');
		this.bundleForm.get('deliveryWindow')!.setValidators([
			Validators.required
		]);
		this.bundleForm.get('deliveryWindow')!.updateValueAndValidity();
		if (this.countryCode !== 'US') {
			this.bundleForm.removeControl('returnAddress');
		}
		else {
			this.bundleForm.get('returnAddress')!.setValidators([
				Validators.required
			]);
		}
		this.bundleForm.get('returnAddress')!.updateValueAndValidity();

		const hasExtraCopiesProduct = this.siteProducts.some(sp => sp.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies && sp.baseProduct.paperHeight === wlProduct?.baseProduct?.paperHeight && sp.baseProduct.paperWidth === wlProduct?.baseProduct?.paperWidth);
		if (!hasExtraCopiesProduct) {
			this.extraCopiesSelected({ productSelected: 0, quantitySelected: 0 });
		}

		this.productAdded = true;
		this.showSpinner = false;
	}
	getResidentialCount(): number {
		let residentialCount = 0;
		this.eddmDistribution?.carrierRoutes?.forEach(s => residentialCount += s.residential);
		return residentialCount;
	}

	getCaResidentialCount(): number {
		let residentialCount = 0;
		this.snapAdmailDistribution?.letterCarrierWalks?.forEach(s => residentialCount += s.apartments + s.farms + s.houses);
		return residentialCount;
	}

	async optionSelected(name: string) {
		if (name === 'Postage Class') {
			const designValue = this.bundleForm.get('design')!.value;
			let postageType = PostageType.StandardMail;
			if (!!this.bundleForm.get('options')!.get('Postage Class')) {
				const selectedPostage = this.bundleForm.get('options')?.get('Postage Class')!.value;
				if (selectedPostage === ProductCategoryOptions.FirstClass) {
					postageType = PostageType.FirstClass;
				}
				else if (selectedPostage === ProductCategoryOptions.NonProfit) {
					postageType = PostageType.NonProfit;
				}
			}
			if (this.isUS) {
				this.calendar = await this.salesApiService.getUsDates(new GetCalendarRequest({
					siteId: this.siteId!,
					customerId: this.customerId,
					productId: this.baseProductId,
					quantity: this.bundleForm.get('quantity')!.value > 0 ? this.bundleForm.get('quantity')!.value : this.distribution?.totalDeliveries!,
					postageType
				}));
			}
			else {
				this.calendar = await this.salesApiService.getCaDates(this.customerId, new GetCaCalendarRequest({
					siteId: this.siteId!,
					productId: this.baseProductId,
					quantity: this.bundleForm.get('quantity')!.value > 0 ? this.bundleForm.get('quantity')!.value : this.distribution?.totalDeliveries!,
					postageType,
					distributionId: this.distributionId
				}));
			}
			if (designValue !== undefined && designValue !== '') {
				this.designSelected();
			}
		}
	}

	designSelected() {
		this.bundleForm.get('front')!.setValue('');
		this.bundleForm.get('back')!.setValue('');
		const designType = this.bundleForm.get('design')!.value;
		this.bundleForm.get('deliveryWindow')!.setValue(undefined);
		if (this.calendar) {
			const designCalendar = this.calendar?.designDeliveryOption?.find(x => x.designType === designType);
			const standardDeliveryOptions = designCalendar?.standardDeliveryOptions;
			this.minDate = {
				day: standardDeliveryOptions![0].firstInHomeDate.getDate()!,
				month: standardDeliveryOptions![0].firstInHomeDate.getMonth()! + 1,
				year: standardDeliveryOptions![0].firstInHomeDate.getFullYear()!
			};
			this.maxDate = {
				day: standardDeliveryOptions![standardDeliveryOptions!.length - 1].firstInHomeDate.getDate()!,
				month: standardDeliveryOptions![standardDeliveryOptions!.length - 1].firstInHomeDate.getMonth()! + 1,
				year: standardDeliveryOptions![standardDeliveryOptions!.length - 1].firstInHomeDate.getFullYear()!
			};
		}
		this.fromDate = undefined;
		this.toDate = undefined;
		this.startDate = this.minDate;
	}
	uploadDesignSelected(): boolean {
		if (this.bundleForm.controls.design.value === USelectDesignType.Upload) {
			return true;
		}
		else {
			return false;
		}
	}

	handleFrontFileInput(event: any) {
		this.uploadedFrontFile = event.files[0];
	}

	handleBackFileInput(event: any) {
		this.uploadedBackFile = event.files[0];
	}

	impressionsChanged() {
		if (this.bundleForm.controls.impressions.value === '1') {
			this.bundleForm.controls.drops.setValidators([
				Validators.required
			]);
			this.bundleForm.controls.drops.updateValueAndValidity();
		}
		else {
			this.bundleForm.controls.drops.setValue('');
			this.bundleForm.controls.drops.setErrors(null);
			this.bundleForm.controls.drops.removeValidators(Validators.required);
			this.bundleForm.controls.frequency.setValidators([
				Validators.required
			]);
			this.bundleForm.controls.drops.updateValueAndValidity();
			this.bundleForm.controls.frequency.updateValueAndValidity();
		}
	}

	dropsChanged() {
		if (this.bundleForm.controls.drops.value === '1') {
			this.bundleForm.controls.frequency.setValue('');
			this.bundleForm.controls.frequency.setErrors(null);
			this.bundleForm.controls.frequency.removeValidators(Validators.required);
		}
		else {
			this.bundleForm.controls.frequency.setValidators([
				Validators.required
			]);
		}
		this.bundleForm.controls.frequency.updateValueAndValidity();
	}

	getProductName(product: AddItemToCartQuery): string {
		return this.siteProducts.find(p => p.baseProductId === product.baseProductId)!.name!;
	}

	getOptions(name: string): ProductPrintMethodOption[] {
		let options: ProductPrintMethodOption[] = [];
		let productOptionCategory: ProductOptionCategory | undefined;
		const productId = parseInt(this.bundleForm.controls.productId.value, 10);
		const printMethodOptions = this.printMethodOptions.get(productId);
		if (this.allProductOptions.length > 0 && printMethodOptions !== undefined && printMethodOptions.length > 0) {
			productOptionCategory = this.allProductOptions.find(x => x.name === name);
			options = printMethodOptions.filter(x => x.option?.optCatId === productOptionCategory?.optCatId);
		}
		return options;
	}

	formatPhoneNumber(controlName: string) {
		let inputValue = this.bundleForm.get(controlName)?.value;;
		let temp = inputValue.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		if (temp) {
			inputValue = !temp[2] ? temp[1] : '(' + temp[1] + ') ' + temp[2] + (temp[3] ? '-' + temp[3] : '');
			this.bundleForm.get(controlName)?.setValue(inputValue);
		}
	}

	excludeDigital(digitalProduct: DigitalProduct) {
		digitalProduct.excluded = !digitalProduct.excluded;
	}

	excludeCallTracking() {
		if (this.bundleForm.get('excludedCallTracking')?.value ?? true) {
			this.bundleForm.get('callTrackingType')!.clearValidators();
			this.bundleForm.get('callTrackingType')!.updateValueAndValidity();
			this.bundleForm.get('areaCode')!.clearValidators();
			this.bundleForm.get('areaCode')!.updateValueAndValidity();
			this.bundleForm.get('forwardingNumber')!.clearValidators();
			this.bundleForm.get('forwardingNumber')!.updateValueAndValidity();
		}
		else {
			this.bundleForm.get('callTrackingType')!.setValidators(Validators.required);
			this.bundleForm.get('callTrackingType')!.updateValueAndValidity();
			this.bundleForm.get('areaCode')!.setValidators([Validators.required, Validators.min(201), Validators.max(999)]);
			this.bundleForm.get('areaCode')!.updateValueAndValidity();
			this.bundleForm.get('forwardingNumber')!.setValidators([Validators.required, Validators.minLength(14), Validators.maxLength(14)]);
			this.bundleForm.get('forwardingNumber')!.updateValueAndValidity();
		}
	}

	excludeQrCode() {
		if (this.bundleForm.get('excludedQrCode')?.value ?? true) {
			this.bundleForm.get('webLink')!.clearValidators();
			this.bundleForm.get('webLink')!.updateValueAndValidity();
		}
		else {
			this.bundleForm.get('webLink')!.setValidators([Validators.required, Validators.pattern("(http(s)?:\/\/)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$")]);
			this.bundleForm.get('webLink')!.updateValueAndValidity();
		}
	}

	updateDigitalSpend(digitalProduct: DigitalProduct) {
		const budget = parseFloat(this.bundleForm.get(`${digitalProduct.productId}budget`)?.value ?? 0);
		const duration = parseInt(this.bundleForm.get(`${digitalProduct.productId}campaignDuration`)?.value ?? 0);
		const totalSpend = budget * duration;
		this.bundleForm.get(`${digitalProduct.productId}totalSpend`)?.setValue(totalSpend);
		digitalProduct.dailySpend = budget;
		digitalProduct.maxDays = duration;
		this.startDateSelected(digitalProduct);
	}
	startDateSelected(digitalProduct: DigitalProduct) {
		this.disableSaveButton = true;
		const startDate = this.bundleForm.get(`${digitalProduct.productId}startDate`)?.value;
		if (startDate.length > 0) {
			const year = parseInt(startDate.substr(0, 4), 10);
			const month = parseInt(startDate.substr(5, 2), 10) - 1;
			const day = parseInt(startDate.substr(8, 2), 10);
			const selectedDate = new Date(year, month, day);
			digitalProduct.startDate = selectedDate;
			if (digitalProduct.productId === 236) {
				const endDate = formatDate(selectedDate, 'yyyy-MM-dd', 'en');
				this.bundleForm.get(`${digitalProduct.productId}endDate`)?.setValue(endDate);
			}
			else {
				const endDate = new Date(selectedDate);
				endDate.setDate(selectedDate.getDate() + digitalProduct.maxDays - 1);
				digitalProduct.endDate = endDate;
				const endDateStr = formatDate(endDate, 'yyyy-MM-dd', 'en');
				this.bundleForm.get(`${digitalProduct.productId}endDate`)?.setValue(endDateStr);
			}
		}

		this.disableSaveButton = false;
	}

	formatDate(date: Date): string {
		return date.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' });
	}

	trackingTypeSelected() {
		this.bundleForm.controls.areaCode.setValue('');
		const callTrackingType = parseInt(this.bundleForm.controls.callTrackingType.value);
		if (callTrackingType === ProductCategoryOptions.LocalAreaCode) {
			this.bundleForm.controls.areaCode.setValidators([
				Validators.required,
				Validators.min(201),
				Validators.max(999)
			]);
		}
		else {
			this.bundleForm.controls.areaCode.clearValidators();
		}
		this.bundleForm.controls.areaCode.updateValueAndValidity();
	}

	onDateSelection(date: NgbDateStruct) {
		this.fromDate = date;
		const selectedDate = new Date(date.year, date.month - 1, date.day);
		const deliveryOptions = this.calendar?.designDeliveryOption?.find(d => d.designType === this.bundleForm.get('design')!.value)?.standardDeliveryOptions;
		const foundDeliveryOption = deliveryOptions?.find(o => {
			const formattedDate = new Date(o.firstInHomeDate.getFullYear(), o.firstInHomeDate.getMonth(), o.firstInHomeDate.getDate());
			if (formattedDate.toISOString() === selectedDate.toISOString()) {
				return o;
			}
			else {
				return undefined;
			}
		});
		const dropDate = foundDeliveryOption?.firstAtPostOfficeDate;
		this.bundleForm.controls.deliveryWindow.setValue(dropDate!.toISOString());
		this.deliveryDateSelected(dropDate!.toISOString());
		this.toDate = new NgbDate(foundDeliveryOption?.lastInHomeDate.getFullYear()!, foundDeliveryOption?.lastInHomeDate.getMonth()! + 1, foundDeliveryOption?.lastInHomeDate.getDate()!);
		this.startDate = date;
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	extraCopiesSelected(value: { productSelected: number | undefined, quantitySelected: number | undefined } | undefined) {
		if (!!value) {
			this.bundleForm.get('extraCopiesProductId')?.setValue(value.productSelected);
			this.bundleForm.get('extraCopiesQuantity')?.setValue(value.quantitySelected);

			if (value.quantitySelected! > 0) {
				this.bundleForm.get('shippingAddress')!.setValidators([Validators.required, Validators.min(1)]);
				this.bundleForm.get('shippingAddress')!.updateValueAndValidity();
			}
			else if (value.quantitySelected === 0) {
				this.bundleForm.get('shippingAddress')!.removeValidators([Validators.required, Validators.min(1)]);
				this.bundleForm.get('shippingAddress')!.updateValueAndValidity();
			}
			else {
				this.bundleForm.get('extraCopiesProductId')?.setValue(value.productSelected);
				this.bundleForm.get('extraCopiesQuantity')?.setValue('');
				this.bundleForm.get('shippingAddress')!.setValidators([Validators.required, Validators.min(1)]);
				this.bundleForm.get('shippingAddress')!.updateValueAndValidity();
				this.bundleForm.get('shippingAddress')!.setValue('');
			}
		}
		else {
			this.bundleForm.get('extraCopiesProductId')?.setValue('');
			this.bundleForm.get('extraCopiesQuantity')?.setValue('');

			this.bundleForm.get('shippingAddress')!.removeValidators([Validators.required, Validators.min(1)]);
			this.bundleForm.get('shippingAddress')!.updateValueAndValidity();
		}
	}
	getMinimumDigitalProductCost(productId: number) {
		const bundleItem = this.bundle?.bundleItems?.find(x => x.productConfiguration?.includes(productId));
		return bundleItem?.minimumCost;
	}
}
