import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Organization, OrganizationSearch } from '@taradel/admin-api-client';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-organizations',
	templateUrl: './organizations.component.html',
	styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {
	orgSearchForm: UntypedFormGroup;
	allOrganizations: Organization[] = [];
	organizations: Organization[] = [];
	filtered = false;
	loading = false;

	constructor(private formBuilder: UntypedFormBuilder,
		private sitesService: SitesService,
		private toastService: ToastService) {
		this.orgSearchForm = formBuilder.group({
			organizationId: new UntypedFormControl(),
			siteName: new UntypedFormControl('')
		});
	}

	get controls() {
		return this.orgSearchForm.controls;
	}

	async ngOnInit(): Promise<void> {
		await this.searchOrganizations();
		this.allOrganizations = this.organizations;
	}

	async searchOrganizations(): Promise<void> {
		if (this.controls.organizationId.value !== null || (!!this.controls.siteName.value && this.controls.siteName.value !== '')) {
			this.filtered = true;
		}
		else {
			this.filtered = false;
		}

		const orgSearch = {
			organizationId: this.controls.organizationId.value,
			siteName: this.controls.siteName.value
		} as OrganizationSearch;

		this.loading = true;

		try {
			this.organizations = await this.sitesService.listOrganizationsAndSites(orgSearch);
		}
		catch (ex: any) {
			this.toastService.showError('There was an error loading organizations');
			console.log(ex);
		}
		finally {
			this.loading = false;
		}
	}

	async resetFilter() {
		this.filtered = false;
		this.controls.organizationId.setValue(null);
		this.controls.siteName.setValue('');
		await this.searchOrganizations();
	}
}
