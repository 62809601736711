<app-spinner-with-overlay [showSpinner]="loading">
    <div class="row">
        <div class="col-5">
            <div class="card p-3 mb-3">
                <form [formGroup]="updatePostageForm">
                    <div class="form-group mb-3">
                        <app-uselect-search [array]="allUSelectMethods" (selectedMethod)="getMethod($event)"></app-uselect-search>
                    </div>
                    <div class="form-group mb-3">
                        <label for="uSelectIdInput">
                            USelect Id
                        </label>
                        <input type="number" id="uSelectIdInput" formControlName="uSelectId" class="form-control"/>
                    </div>
                    <div class="form-group mb-3">
                        <label for="standardPostageInput">
                            Standard Postage to update
                        </label>
                        <input type="number" id="standardPostageInput" formControlName="standardPostage" class="form-control"/>
                    </div>
                    <div class="col-6 form-group mb-3">
                        <label for="firstClassPostageInput">
                            First Class Postage to update
                        </label>
                        <input type="number" id="firstClassPostageInput" formControlName="firstClassPostage" class="form-control"/>
                    </div>
                    <button type="button" class="btn btn-primary" [disabled]="updatePostageForm.invalid" (click)="updatePostage()">Update Postage</button>
                </form>
            </div>
        </div>
		<div class="col-7">
			<div class="card p-3 mb-3">
				<div class="text-center">
					<h3>Selected USelect Products</h3>
					<table class="table table-hover table-panel">
						<thead>
							<tr>
								<th>WL Product Id</th>
								<th>Base Product Id</th>
								<th>WL Product Name</th>
								<th>Standard Postage</th>
								<th>First Class Postage</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let product of selectedUselectProducts">
								<td>
									{{product.productId}}
								</td>
								<td>
									{{product.baseProductId}}
								</td>
								<td>
									{{product.name}}
								</td>
								<td>
									{{(product.standardPostage ?? 0) | currency:'USD':'symbol':'1.2-4'}}
								</td>
								<td>
									{{(product.firstClassPostage ?? 0) | currency:'USD':'symbol':'1.2-4'}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
    </div>

</app-spinner-with-overlay>
