import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-placeholder',
	templateUrl: './placeholder.component.html',
	styleUrls: ['./placeholder.component.scss']
})
export class PlaceholderComponent {
	@Input() dependsOn: any;
	@Input() bgColor?: string;

	constructor() { }

	getBgColor(): string {
		if (this.bgColor && this.bgColor !== '') {
			let t = this.bgColor.toLowerCase();
			if (t.startsWith('bg-')) {
				return t;
			}
			return 'bg-' + t;
		}
		return '';
	}
}
