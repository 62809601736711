import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RecommendedPrinter } from '@taradel/admin-api-client';
import { OrderlayoutService } from 'services/orderlayout.service';

@Component({
	selector: 'app-vendor-recommendation',
	templateUrl: './vendor-recommendation.component.html',
	styleUrls: ['./vendor-recommendation.component.scss']
})
export class VendorRecommendationComponent implements OnInit {
	collapseRecs = true;

	@Output() printerSet: EventEmitter<RecommendedPrinter> = new EventEmitter<RecommendedPrinter>();

	constructor(public orderLayoutService: OrderlayoutService) { }

	ngOnInit(): void {
		if (this.orderLayoutService.isDesignTeam &&
			this.orderLayoutService.printerRecommendations &&
			this.orderLayoutService.printerRecommendations.length &&
			!this.orderLayoutService.purchaseOrder) {
			this.collapseRecs = false;
		}
	}

	setPrinter(vendor: RecommendedPrinter) {
		this.printerSet.emit(vendor);
	}

	getContainerCount(containerName: string): number {
		let printerRecs = this.orderLayoutService.printerRecommendations;
		if (!printerRecs) {
			return 0;
		}
		let config = printerRecs[0]?.pmodSolution?.pmodItems?.find(p => p.binDescription! === containerName);
		if (!config) {
			return 0;
		}
		return config.binCount;
	}
}
