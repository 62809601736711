<app-pagefullheader></app-pagefullheader>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card p-3 mb-3">
		<div class="row">
			<div class="col">
				<div class="input-group mb-3">
					<div class="input-group-text">Filter by Name</div>
					<div class="flex-fill position-relative">
						<div class="input-group">
							<div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
								style="z-index: 1;">
								<i class="fa fa-search opacity-5"></i>
							</div>
							<input type="text" id="printerSearchBox" class="form-control px-35px bg-light" #companyNameInput
										(keyup)="companyNameSearch.next(companyNameInput.value)" placeholder="Search vendors...">
							<button *ngIf="companyNameInput.value.length > 0" class="btn btn-secondary" (click)="clearFilter()"
											type="button"><span class="far fa-times-circle"></span></button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-2 text-center">
				<a type="button" class="btn btn-success" (click)="openAddPrinterModal(addPrinterModal)">Add new printer</a>
			</div>
		</div>
		
	
		<div class="d-flex justify-content-between p-2">
			<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10" [pageSize]="printersSearch.pageSize"
				[rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()"
					aria-label="Items per page">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Items per page</label>
			</div>
		</div>
	
		<div class="table-responsive mb-3">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Id</th>
						<th>Company Name</th>
						<th>Phone/Email</th>
						<th>Enabled</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let printer of printers">
						<td>
							{{printer.printerId}}
						</td>
						<td>
							{{printer.companyName}}
						</td>
						<td>
							{{printer.primaryPhone}}
							<br />
							<a href="mailto:{{printer.primaryEmail}}">{{printer.primaryEmail}}</a>
						</td>
						<td>
							<div class="form-check">
								<input type="checkbox" role="button" class="form-check-input" [ngModel]="printer.enabled"
									(change)="openUpdatePrinterModal(updatePrinterModal, printer)" />
							</div>
						</td>
						<td>
							<app-printer-links [printerId]="printer.printerId"></app-printer-links>
						</td>
					</tr>
				</tbody>
			</table>
	
			<div class="d-flex justify-content-between p-2">
				<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10" [pageSize]="printersSearch.pageSize"
					[rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged()"></ngb-pagination>
				<div class="form-floating">
					<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()"
						aria-label="Items per page">
						<option [ngValue]="20">20 items per page</option>
						<option [ngValue]="50">50 items per page</option>
						<option [ngValue]="100">100 items per page</option>
					</select>
					<label for="pageSize">Items per page</label>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>

<ng-template #updatePrinterModal let-modal>
	<app-confirm-modal title="Update Printer" confirmText="confirm" (confirmAction)="togglePrinterEnabled(printerToUpdate!.printerId)" (closeModal)="closeUpdatePrinterModal()">
		<div class="card">
			<div class="card-body">
				Do you want to {{ printerEnabledString }} printer {{ printerToUpdate?.printerId }}?
			</div>
		</div>
	</app-confirm-modal>
</ng-template>

<ng-template #addPrinterModal let-modal>
	<app-confirm-modal title="Add New Printer" confirmText="confirm" (confirmAction)="addNewPrinter()" (closeModal)="closeNewPrinterModal()">
		<div class="card">
			<div class="card-body">
				<div class="form-floating mb-2">
                    <input type="string" id="companyName" class="form-control" [(ngModel)]="newPrinter.companyName" placeholder="Company Name" 
					[ngClass]="{ 'is-invalid': submitted && !newPrinter.companyName }" />
                    <label for="companyName">Company Name</label>
					<div *ngIf="submitted && !newPrinter.companyName" class="input-error">
						Company Name is required
					</div>
                </div>
				<div class="form-check mb-2">
					<input type="checkbox" id="newPrinterEnabled" role="button" class="form-check-input" [ngModel]="newPrinter.enabled"
						(ngModelChange)="newPrinter.enabled = !newPrinter.enabled" />
					<label for="newPrinterEnabled" class="form-check-label">
						Enabled
					</label>
				</div>
				<div class="form-check mb-2">
					<input type="checkbox" id="newPrinterIsTaradel" role="button" class="form-check-input" [ngModel]="newPrinter.isTaradel"
						(ngModelChange)="newPrinter.isTaradel = !newPrinter.isTaradel" />
					<label for="newPrinterIsTaradel" class="form-check-label">
						Is Taradel
					</label>
				</div>
			</div>
		</div>
	</app-confirm-modal>
</ng-template>

