<ng-container *ngIf="productId > 0; else pagefullHeader">
	<app-product-info [productId]="productId"></app-product-info>
</ng-container>
<ng-template #pagefullHeader>
	<app-pagefullheader></app-pagefullheader>
</ng-template>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col-md-7">
			<div class="card p-3 mb-3">
				<form [formGroup]="productForm">
					<div class="card-body">
						<h5 class="card-title">Basic Product Information</h5>
						<div class="row mb-3">
							<div class="col-md-9">
								<div class="form-floating">
									<input type="text" formControlName="name" class="form-control" [maxlength]="100"
									 [ngClass]="{ 'is-invalid': productFormSubmitted && f.name.errors }">
									 <label for="name">Name </label>
								</div>
								<div *ngIf="productFormSubmitted && f.name.errors" class="invalid-feedback">
									<div *ngIf="f.name.errors.required">Name is required</div>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-floating">
									<input type="text" formControlName="sku" class="form-control" [maxlength]="50">
									<label for="sku">SKU</label>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-md-4">
								<div class="form-floating">
									<input type="number" formControlName="paperWidth" class="form-control" min="0" max="99999"
									[ngClass]="{ 'is-invalid': productFormSubmitted && f.paperWidth.errors }">
									<label for="paperWidth">Paper/Media Width</label>
								</div>
								<div *ngIf="productFormSubmitted && f.paperWidth.errors" class="invalid-feedback">
									Enter valid paper width
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-floating">
									<input type="number" formControlName="paperHeight" class="form-control" min="0" max="99999"
									[ngClass]="{ 'is-invalid': productFormSubmitted && f.paperHeight.errors }">
									<label for="paperHeight">Paper/Media Height</label>
								</div>
								<div *ngIf="productFormSubmitted && f.paperHeight.errors" class="invalid-feedback">
									Enter valid paper height
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-floating">
									<input type="number" formControlName="pageCount" class="form-control" min="1" max="99999"
									[ngClass]="{ 'is-invalid': productFormSubmitted && f.pageCount.errors }">
									<label for="pageCount">Page Count</label>
								</div>
								<div *ngIf="productFormSubmitted && f.pageCount.errors" class="invalid-feedback">
									Enter valid paper count
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-md-4">
								<div class="form-floating">
									<select type="select" formControlName="designTemplate" class="form-select">
										<option *ngFor="let x of designTemplates" [value]="x.name" >
											{{x.name!}}
										</option>
									</select>
									<label for="designTemplate">Design Template</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-floating">
									<input type="number" formControlName="designFee" class="form-control" min="0" max="999">
									<label for="designFee">Design Fee</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-floating">
									<select type="select" formControlName="templateSize" class="form-select"
									[ngClass]="{ 'is-invalid' : productFormSubmitted && f.templateSize.errors }">
										<option *ngFor="let x of pageSizes" [value]="x.pageSizeId">
											{{x.name!}}
										</option>
									</select>
									<div *ngIf="productFormSubmitted && f.templateSize.errors" class="invalid-feedback">
										Select a template size
									</div>
									<label for="templateSize">Template Size</label>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-md-6">
								<div class="form-floating">
									<input type="number" formControlName="creativeSetupFee" class="form-control">
									<label for="creativeSetupFee">Creative Setup Fee</label>
								</div>
							</div>
						</div>

						<div class="form-floating mb-3">
							<textarea formControlName="shortDescription" [maxlength]="260" class="form-control" style="height:75px"></textarea>
							<label for="shortDescription">Short Description</label>
						</div>

						<div class="form-floating">
							<textarea formControlName="fullDescription" class="form-control" style="height:300px"></textarea>
							<label for="fullDescription">Full Description</label>
						</div>
					</div>
					<div class="card-body">
						<button class="btn btn-primary btn-lg" (click)="saveProduct()">Save Changes</button>
					</div>
				</form>
			</div>
		</div>
		<div *ngIf="productId > 0" class="col-md-5">
			<div class="card p-3 mb-3">
				<form [formGroup]="productConfigurationForm">
					<div class="card-body">
						<h5 class="card-title">USelect Configuration</h5>

						<div class="form-floating mb-3">
							<select class="form-control" id="productType" formControlName="uSelect" class="form-select">
								<option *ngFor="let x of uSelects" value="{{x.uSelectId}}">
									{{x.name}}
								</option>
							</select>
							<label for="uSelect">USelect Method</label>
						</div>

						<div class="row">
							<div class="col-md-4">
								<div class="form-floating">
									<input type="number" formControlName="pricePerPiece" class="form-control" min="0" max="99"
									[ngClass]="{ 'is-invalid': uSelectFormSubmitted && pcf.pricePerPiece.errors }" placeholder="Mail Price Per Piece">
									<label for="pricePerPiece">Mail Price Per Piece</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-floating">
									<input type="number" formControlName="dropFee" class="form-control" min="0" max="999"
									[ngClass]="{ 'is-invalid': uSelectFormSubmitted && pcf.dropFee.errors }" placeholder="Extra Drop Fee">
									<label for="dropFee">Extra Drop Fee</label>
								</div>
							</div>
							<div class="col-md-4">
								<div class="form-floating">
									<input type="number" formControlName="postageFee" class="form-control" min="0" max="99"
									[ngClass]="{ 'is-invalid': uSelectFormSubmitted && pcf.postageFee.errors }" placeholder="Postage Fee">
									<label for="postageFee">Postage Fee</label>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<button class="btn btn-primary" (click)="updateProductConfiguration()">Update</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
