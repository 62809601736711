import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProductOption } from '@taradel/web-api-client';
import { ProductsService } from 'services/products.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-category-option-details',
	templateUrl: './category-option-details.component.html',
	styleUrls: ['./category-option-details.component.scss']
})
export class CategoryOptionDetailsComponent implements OnInit {
	loading = false;
	submitted = false;
	optionId = 0;
	categoryOptionForm: UntypedFormGroup;
	categoryOption?: ProductOption;
	constructor(
		public formBuilder: UntypedFormBuilder,
		private productsService: ProductsService,
		private toastService: ToastService,
		private route: ActivatedRoute) {
			this.optionId = parseInt(route.snapshot.paramMap.get('optionId') ?? '0', 10);
			this.categoryOptionForm = this.formBuilder.group({
				name: new UntypedFormControl('', Validators.compose([
					Validators.required,
					Validators.maxLength(50)
				])),
				modelNumber: new UntypedFormControl(''),
				description: new UntypedFormControl(''),
				markupPercent: new UntypedFormControl(''),
				markup: new UntypedFormControl(''),
				optionWidth: new UntypedFormControl(''),
				optionHeight: new UntypedFormControl(''),
				optionQuantity: new UntypedFormControl(''),
				optionWeight: new UntypedFormControl(''),
				weightPerThousand: new UntypedFormControl(''),
				fourOverProductCodeSlug: new UntypedFormControl(''),
				fourOverOptionId: new UntypedFormControl('')
			});
	}

	async ngOnInit() {
		try {
			this.loading = true;
			this.categoryOption = await this.productsService.getProductOption(this.optionId);
			this.categoryOptionControls.description.setValue(this.categoryOption.description);
			this.categoryOptionControls.fourOverOptionId.setValue(this.categoryOption.fourOverOptionId);
			this.categoryOptionControls.fourOverProductCodeSlug.setValue(this.categoryOption.fourOverProductCodeSlug);
			this.categoryOptionControls.markup.setValue(this.categoryOption.markup);
			this.categoryOptionControls.markupPercent.setValue(this.categoryOption.markupPercent);
			this.categoryOptionControls.modelNumber.setValue(this.categoryOption.modelNumber);
			this.categoryOptionControls.name.setValue(this.categoryOption.name);
			this.categoryOptionControls.weightPerThousand.setValue(this.categoryOption.weightPerUnit);
		}
		catch {
			this.toastService.showError('There was an error loading the product option');
		}
		finally {
			this.loading = false;
		}

	}

	get categoryOptionControls() { return this.categoryOptionForm.controls; }

	async saveCurrentOption(): Promise<void> {
		this.submitted = true;
		if (!this.categoryOptionForm.valid) {
			return;
		}

		try {
			this.loading = true;
			this.categoryOption!.description = this.categoryOptionControls.description.value;
			this.categoryOption!.fourOverOptionId = this.categoryOptionControls.fourOverOptionId.value;
			this.categoryOption!.fourOverProductCodeSlug = this.categoryOptionControls.fourOverProductCodeSlug.value;
			this.categoryOption!.markup = this.categoryOptionControls.markup.value;
			this.categoryOption!.markupPercent = this.categoryOptionControls.markupPercent.value;
			this.categoryOption!.modelNumber = this.categoryOptionControls.modelNumber.value;
			this.categoryOption!.name = this.categoryOptionControls.name.value;
			this.categoryOption!.weightPerUnit = this.categoryOptionControls.weightPerThousand.value;

			await this.productsService.updateProductOption(this.categoryOption!);
			this.toastService.showSuccess('Product Option updated successfully');
		}
		catch {
			this.toastService.showError('There was an error updating the category option');
		}
		finally {
			this.loading = false;
		}
	}

	calculateWeight(): void {
		const optionWeight = parseFloat(this.categoryOptionControls.optionWeight.value);
		const optionWidth = parseFloat(this.categoryOptionControls.optionWidth.value);
		const optionHeight = parseFloat(this.categoryOptionControls.optionHeight.value);
		const optionQuantity = parseFloat(this.categoryOptionControls.optionQuantity.value);

		this.categoryOptionControls.weightPerThousand.setValue((optionWeight * 1000) / (optionWidth * optionHeight * optionQuantity));
	}
}
