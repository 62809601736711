<app-customer-header [customerId]="customerId"></app-customer-header>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="card col-lg-8 col-12 p-3 mb-3">
			<div class="card-header">
				<ul class="nav nav-tabs card-header-tabs" role="tabList">
					<li class="nav-item">
						<button class="nav-link active" aria-current="true" data-bs-toggle="tab" data-bs-target="#paymentDetailsView">
							Customer Payment Details
						</button>
					</li>
					<li *ngIf="!!customerPayment && customerPayment.paymentAmount > 0 && authService.organizationId === 1" class="nav-item">
						<button class="nav-link" aria-current="false" data-bs-toggle="tab" data-bs-target="#orderPaymentsView">
							Associated Order Payments
						</button>
					</li>
				</ul>
			</div>
			<div class="tab-content">
				<div class="card-body tab-pane fade show active" id="paymentDetailsView">
					<div class="row mb-3">
						<div class="col-md-7">
							<div class="card">
								<div class="card-body">
									<table class="table table-sm table-hover" *ngIf="customerPayment">
										<tbody>
											<tr>
												<td *ngIf="customerPayment.paymentAmount > 0"><strong>Payment Status:</strong></td>
												<td *ngIf="customerPayment.paymentAmount <= 0"><strong>Refund Status:</strong></td>
												<td class="text-end"
													[ngClass]="{'text-success': customerPayment.customerPaymentAttempt?.status === cpaStatus.Success,
															'text-danger': customerPayment.customerPaymentAttempt?.status === cpaStatus.Failed,
															'text-warning': customerPayment.customerPaymentAttempt?.status === cpaStatus.Declined || customerPayment.customerPaymentAttempt?.status === cpaStatus.Pending }">
													<b>{{ customerPayment.customerPaymentAttempt?.status ?? '' }}</b>
												</td>
											</tr>
											<tr>
												<td>
													<strong *ngIf="customerPayment.paymentAmount > 0">Payment Amount:</strong>
													<strong *ngIf="customerPayment.paymentAmount <= 0">Refund Amount:</strong>
												</td>
												<td class="text-end">{{ customerPayment.paymentAmount | currency }}</td>
											</tr>
											<tr>
												<td>
													<strong>Surcharge Amount:</strong>
												</td>
												<td class="text-end">{{ (customerPayment.surchargeAmount ?? 0) | currency }}</td>
											</tr>
											<tr>
												<td>
													<strong *ngIf="customerPayment.paymentAmount > 0">Payment Amount with Surcharge:</strong>
													<strong *ngIf="customerPayment.paymentAmount <= 0">Refund Amount with Surcharge:</strong>
												</td>
												<td class="text-end">{{ (customerPayment.paymentAmount + (customerPayment.surchargeAmount ?? 0)) | currency }}</td>
											</tr>
											<tr>
												<td>
													<strong>Applied Amount:</strong>
												</td>
												<td class="text-end">{{ customerPayment.paymentAppliedAmount | currency }}</td>
											</tr>
											<tr>
												<td><strong>Payment Date and Time:</strong></td>
												<td class="text-end">{{ customerPayment.createdDate | date:'short' }}</td>
											</tr>
											<tr>
												<td><strong>Payment Created By:</strong></td>
												<td class="text-end">{{ customerPayment.createdBy }}</td>
											</tr>
											<tr>
												<td><strong>Payment Type:</strong></td>
												<td class="text-end">{{ customerPayment.paymentType }}</td>
											</tr>
											<tr>
												<td><strong>Last 4 digits</strong></td>
												<td class="text-end">{{ customerPayment.customerPaymentAttempt?.maskedPaymentData }}</td>
											</tr>
											<tr>
												<td><strong>Transaction ID:</strong></td>
												<td class="text-end">{{ customerPayment.customerPaymentAttempt?.paymentRef }}</td>
											</tr>
											<tr>
												<td><strong>Note:</strong></td>
												<td class="text-end">{{ customerPayment.note }}</td>
											</tr>
											<tr *ngIf="customerPayment.customerPaymentAttempt?.status && customerPayment.customerPaymentAttempt?.status !== cpaStatus.Success">
												<td><strong>Decline Reason:</strong></td>
												<td class="text-end">{{ customerPayment.customerPaymentAttempt?.responseCode }} -- {{ customerPayment.customerPaymentAttempt?.responseSubCode }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							&nbsp;<br>

							<h3 *ngIf="customerPayment?.hasRefunds">Refunds Issued:</h3>
							<div *ngFor="let payment of customerPayment?.referencingCustomerPaymentSummaries" class="card">
								<div class="card-body">
									<table class="table table-sm table-hover">
										<tbody>
											<tr>
												<td><strong>Payment ID:</strong></td>
												<td class="text-end"><a [routerLink]="['/customers', customerId, 'payments', payment.customerPaymentId]" class="btn btn-link p-0">{{ payment.customerPaymentId }}</a></td>
											</tr>
											<tr>
												<td>
													<strong *ngIf="payment.paymentAmount > 0">Payment Amount:</strong>
													<strong *ngIf="payment.paymentAmount <= 0">Refund Amount:</strong>
												</td>
												<td class="text-end">{{ payment?.paymentAmount | currency }}</td>
											</tr>
											<tr>
												<td><strong>Payment Date and Time:</strong></td>
												<td class="text-end">{{ payment?.createdDate | date:'short' }}</td>
											</tr>
											<tr *ngIf="payment?.note">
												<td><strong>Note:</strong></td>
												<td class="text-end">{{ payment?.note }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

						</div>
						<div class="col-md-5">
							<strong>Billing Address:</strong>
							<br>
							<ng-container *ngIf="paymentAddress?.firstName !== '' || paymentAddress?.lastName !== ''">
								{{ paymentAddress?.firstName }} {{ paymentAddress?.lastName }}
								<br />
							</ng-container>
							<ng-container *ngIf="paymentAddress?.company !== ''">
								{{ paymentAddress?.company }}
								<br />
							</ng-container>
							<ng-container *ngIf="paymentAddress?.phoneNumber !== ''">
								{{ paymentAddress?.phoneNumber }} <br />
							</ng-container>
							{{ paymentAddress?.address1 }} {{ paymentAddress?.address2 }} <br />
							{{ paymentAddress?.city }}, {{ paymentAddress?.state }} {{ paymentAddress?.zipCode }}
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-md-7">
							<button class="btn btn-primary mx-2" *ngIf="refundAllowed(true)" (click)="openModal(fullRefundModal)">
								Full Refund
							</button>
							<button class="btn btn-primary mx-2" *ngIf="refundAllowed(false)" routerLink="/customers/{{ customerId }}/payments/{{ customerPaymentId }}/refund-payment">
								Partial Refund
							</button>
							<button class="btn btn-danger mx-2" *ngIf="voidAllowed()" (click)="openModal(voidRefundModal)">
								Void Refund
							</button>
						</div>
					</div>
					<div *ngIf="customerPayment?.referenceCustomerPaymentId" class="row mb-3">
						<div class="col">
							<strong>This payment is a refunded payment. To create order payments against the original payment, go here: <a [routerLink]="['/customers/', customerPayment?.customerId, 'payments', customerPayment?.referenceCustomerPaymentId]" class="btn btn-link p-0">Payment {{ customerPayment?.referenceCustomerPaymentId }}</a></strong>
						</div>
					</div>
				</div>
				<div class="card-body tab-pane fade show" id="orderPaymentsView">
					<div *ngIf="!!associatedOrders && associatedOrders!.length" class="row mb-3">
						<div class="col">
							<div *ngFor="let order of associatedOrders" class="card">
								<div class="card-header">
									<div class="row d-flex justify-content-between">
										<div class="col-md-3">
											<a [routerLink]="['/orders/', order.orderId, 'summary']" target="_blank" class="btn btn-link p-0" [ngClass]="order.deleted ? 'text-danger' : ''">{{ order.orderId + (order.deleted ? ' (deleted)' : '')}}</a>
										</div>
										<div class="col-md-3">
											{{ order.siteName }}
										</div>
										<div class="col-md-3">
											Order Total: {{ order.orderAmount | currency }}
										</div>
										<div class="col-md-3">
											<span class="text-danger" *ngIf="order.orderBalance > 0">Balance: ({{ order.orderBalance | currency }})</span>
										</div>
									</div>
								</div>
								<div class="card-body">
									<table class="table table-panel table-hover">
										<thead>
											<th>Order Payment Id</th>
											<th>Payment Transaction Id</th>
											<th>Method</th>
											<th>Created Date</th>
											<th>Created By</th>
											<th>Payment Amount</th>
										</thead>
										<tbody>
											<tr *ngFor="let orderPayment of order?.orderPayments">
												<td>{{ orderPayment.orderPaymentId }}</td>
												<td>{{ customerPayment?.customerPaymentAttempt?.paymentRef }}</td>
												<td>
													{{ customerPayment?.paymentType }}
												</td>
												<td>{{ orderPayment.createdDate | date:'short' }}</td>
												<td>{{ orderPayment.createdBy }}</td>
												<td>{{ orderPayment.amount | currency }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col">
							<div class="card">
								<div class="card-header">
									Create Order Payment Records
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-md-8 m-auto">
											<div *ngIf="selectOrder" class="card mt-3">
												<div class="card-header">
													Select an order to create a payment
												</div>
												<div class="card-body">
													<div class="row mt-2">
														<div class="col-md-6">
															<div class="input-group">
																<span class="input-group-text">
																	<span class="fas fa-search"></span>
																</span>
																<input type="number" #orderIdInput class="form-control" placeholder="Search orders by order number" aria-label="Search orders by order number" (keyup)="orderIdSearch.next(orderIdInput.value)" />
																<button *ngIf="orderIdInput.value.length > 0" class="btn" (click)="clearFilter()" type="button"><span class="far fa-times-circle"></span></button>
															</div>
														</div>
													</div>
													<div class="row mt-3">
														<div class="col">
															<app-spinner-with-overlay [showSpinner]="loadingOrders">
																<div class="d-flex justify-content-between p-2" *ngIf="total > pageSize">
																	<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10" [pageSize]="query.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged()"></ngb-pagination>
																</div>
																<table class="table table-sm">
																	<tbody>
																		<tr *ngFor="let order of pageOrders" [ngClass]="{ 'bg-danger': order.deleted === true }">
																			<td>
																				{{ order.orderId }}
																			</td>
																			<td>
																				{{ order.created | date:'short' }}
																			</td>
																			<td>
																				Order Amount: {{ order.orderAmount| currency }}
																			</td>
																			<td>
																				<span *ngIf="order.orderAmount - order.paidAmount > 0" [ngClass]="{ 'text-danger': !order.deleted, 'text-light': order.deleted }">
																					Balance: {{ order.orderAmount - order.paidAmount | currency }}
																				</span>
																			</td>
																			<td>
																				<button type="button" class="btn btn-small btn-primary" (click)="orderSelected(order)">
																					<i class="fa fa-plus"></i>
																				</button>
																			</td>
																		</tr>
																	</tbody>
																</table>
																<div class="row mt-3">
																	<div class="col-auto m-auto">
																		<button *ngIf="newOrderPayments.length > 0" type="button" class="btn btn-small btn-primary" (click)="reviewNewOrderPayments = true; selectOrder = false">
																			Review New Order Payments
																		</button>
																	</div>
																</div>
															</app-spinner-with-overlay>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="setPaymentAmount && !!control.order?.value" class="card mt-3">
												<div class="card-header">
													Set amount for payment
												</div>
												<div class="card-body">
													<small>
														Note: To decrease the payment on an order, set a negative amount
													</small>
													<div class="row mt-2 mb-5">
														<div class="col-6 m-auto">
															<p>
																Order: <a [routerLink]="['/orders/', control.order.value.orderId, 'summary']" class="btn btn-link p-0">{{ control.order.value.orderId }}</a>
															</p>
															<p>Order total: {{ control.order.value.orderAmount | currency }}</p>
															<p class="text-danger">Remaining order balance: {{ control.order.value.orderAmount - control.order.value.paidAmount | currency }}</p>
															<div class="row my-3">
																<div class="col">
																	<form [formGroup]="newOrderPaymentForm">
																		<div class="form-floating">
																			<input type="number" formControlName="paymentAmount" class="form-control"
																				[ngClass]="{ 'is-invalid': submitted && control.paymentAmount.errors }" placeholder="Order payment amount">
																			<label for="paymentAmount">Order payment amount</label>
																		</div>
																		<div *ngIf="submitted && control.paymentAmount?.errors?.required" class="text-danger">
																			Must enter a payment amount
																		</div>
																		<div *ngIf="submitted && control.paymentAmount?.errors?.invalidPaymentAmount" class="text-danger">
																			The sum of all new order payment amounts must be less than or equal to the customer payment total
																		</div>
																	</form>
																</div>
															</div>
														</div>
													</div>
													<div class="row d-flex justify-content-center">
														<div class="col col-auto">
															<button type="button" class="btn btn-link" (click)="deselectOrder()">
																Go Back
															</button>
														</div>
														<div class="col col-auto">
															<button type="button" class="btn btn-small btn-primary" (click)="formatNewOrderPayment()">
																Next
															</button>
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="reviewNewOrderPayments" class="card mt-3">
												<div class="card-header">
													Review Orders and Amounts
												</div>
												<div class="card-body">
													<div class="row mt-3">
														<div class="col-md-10 m-auto">
															<table class="table table-sm table-hover">
																<thead class="mb-3">
																	<th width="40%">Order</th>
																	<th width="40%">Amount</th>
																</thead>
																<tbody class="table-group-divider">
																	<tr *ngFor="let item of newOrderPayments">
																		<td>{{ item.orderPayment.orderId }}</td>
																		<td>
																			<span *ngIf="orderIdToEdit !== item.order.orderId; else editPaymentAmount">{{ item.orderPayment.amount | currency }}</span>
																			<ng-template #editPaymentAmount>
																				<span>
																					<form [formGroup]="editPaymentForm" (submit)="editPayment()">

																						<div class="input-group">
																							<input type="number" class="form-control" aria-label="editPaymentAmount" formControlName="newAmount"
																								[ngClass]="{ 'is-invalid': submitted && editControl.newAmount.errors }"
																								aria-describedby="Edit Payment Amount" [placeholder]="editControl.orderPayment.value.amount" />
																								<button type="submit" class="btn btn-primary">
																									<i class="fa fa-save mx-2" data-toggle="tooltip" data-placement="top" title="Save"></i>
																								</button>
																								<button class="btn btn-warning mx-2" type="button"
																									data-toggle="tooltip" data-placement="top" title="Cancel" (click)="cancelEdit()">
																									<i class="fa fa-times"></i>
																								</button>
																						</div>
																						<div *ngIf="submitted && editControl.newAmount?.errors?.invalidPaymentAmount" class="text-danger">
																							The sum of all new order payment amounts must be less than or equal to the customer payment total
																						</div>
																					</form>
																				</span>
																			</ng-template>
																		</td>
																		<td *ngIf="orderIdToEdit !== item.order.orderId">
																			<button type="button" class="btn btn-info btn-sm rounded-0 mx-1" type="button" title="Edit payment" (click)="setPaymentToEdit(item)">
																				<i class="fa fa-edit"></i>
																			</button>
																			<button type="button" class="btn btn-danger btn-sm rounded-0 mx-1" type="button" title="Remove payment"
																			   (click)="removePayment(item.order.orderId)">
																				<i class="fa fa-trash"></i>
																			</button>
																		</td>
																	</tr>
																</tbody>
															</table>
															<div class="row">
																<div class="col">
																	<span *ngIf="showError" class="text-danger">
																		The selected order payment total exceeds the amount of this customer payment
																	</span>
																</div>
															</div>
														</div>
													</div>
													<div class="row pt-5 d-flex justify-content-center">
														<div class="col col-auto">
															<button type="button" class="btn btn-link" (click)="deselectOrder()">
																Go Back To Orders
															</button>
														</div>
														<div class="col col-auto">
															<button type="button" class="btn btn-small btn-primary" (click)="addOrderPayments()">
																Create Payments
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-3">
		<div class="col-auto" *ngIf="customerId > 0">
			<a [routerLink]="['/customers', this.customerId , 'payments']" class="btn btn-link p-0">See all customer payments</a>
		</div>
		<div class="col-auto"  *ngIf="customerId === 0">
			<a [routerLink]="['/payments']" class="btn btn-link p-0">See all payments</a>
		</div>
		<div class="col-auto"  *ngIf="customerId === 0">
			<a [routerLink]="['/customers', this.customerPayment?.customerId]" class="btn btn-link p-0">See customer details</a>
		</div>

	</div>
</app-spinner-with-overlay>

<ng-template #fullRefundModal>
	<app-confirm-modal title="Full Refund confirmation" confirmText="refund" (confirmAction)="refundEntirePayment()"
		(closeModal)="closeModal()">
		<p>Are you sure you want to refund this payment?</p>
	</app-confirm-modal>
</ng-template>

<ng-template #voidRefundModal>
	<app-confirm-modal title="Void Refund confirmation" confirmText="void" (confirmAction)="voidPayment()"
		(closeModal)="closeModal()">
		<p>Are you sure you want to void this refund?</p>
	</app-confirm-modal>
</ng-template>
