<app-customer-header [customerId]="customerId"></app-customer-header>
<div class="row mt-2">
	<div class="col">
		<div class="card">
			<div class="card-header">
				<ul class="nav nav-tabs card-header-tabs">
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#customerPaymentsTab" [ngClass]="activeTab === 'customerPaymentsTab' ? 'active' : null">Customer Payments</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#paymentAttemptsTab" [ngClass]="activeTab === 'paymentAttemptsTab' ? 'active' : null">Payment Attempts</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#recurringPaymentsTab" [ngClass]="activeTab === 'recurringPaymentsTab' ? 'active' : null">Recurring Payments</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#paymentProfilesTab" [ngClass]="activeTab === 'paymentProfilesTab' ? 'active' : null">Payment Profiles</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#customerInvoicesTab" [ngClass]="activeTab === 'customerInvoicesTab' ? 'active' : null">Customer Invoices</button>
					</li>
				</ul>
			</div>
			<div class="tab-content">
				<div class="card-body tab-pane" id="customerPaymentsTab" role="tabpanel" [ngClass]="activeTab === 'customerPaymentsTab' ? 'active' : null">
					<div class="accordion" id="customerPaymentsAccordion">
						<app-customer-payments></app-customer-payments>
					</div>
				</div>
				<div class="card-body tab-pane" id="paymentAttemptsTab" role="tabpanel" [ngClass]="activeTab === 'paymentAttemptsTab' ? 'active' : null">
					<div class="accordion" id="paymentAttemptsAccordion">
						<app-payment-attempts></app-payment-attempts>
					</div>
				</div>
				<div class="card-body tab-pane" id="recurringPaymentsTab" role="tabpanel" [ngClass]="activeTab === 'recurringPaymentsTab' ? 'active' : null">
					<div class="accordion" id="recurringPaymentsAccordion">
						<app-recurring-payments></app-recurring-payments>
					</div>
				</div>
				<div class="card-body tab-pane" id="paymentProfilesTab" role="tabpanel" [ngClass]="activeTab === 'paymentProfilesTab' ? 'active' : null">
					<div class="accordion" id="paymentProfilesAccordion">
						<app-payment-profiles></app-payment-profiles>
					</div>
				</div>
				<div class="card-body tab-pane" id="customerInvoicesTab" role="tabpanel" [ngClass]="activeTab === 'customerInvoicesTab' ? 'active' : null">
					<div class="accordion" id="customerInvoicesAccordion">
						<app-customer-invoices></app-customer-invoices>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
