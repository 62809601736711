<app-customer-header [customerId]="customerId">
	<ng-container altnav>
		<ul class="nav nav-pills justify-content-end">
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'distributions']">Back to Distributions</a>
			</li>
			<li class="nav-item">
				<a class="nav-link active" [routerLink]="['/customers', customerId, 'business']">Build Business List</a>
			</li>
		</ul>
	</ng-container>
</app-customer-header>
<app-spinner-with-overlay [showSpinner]="loading">
<div class="row mt-2">
    <div class="col-md-8">
        <div class="card">
            <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item">
                        <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#industryTab">Industry</button>
                    </li>
                    <li class="nav-item">
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#geographyTab">Geography</button>
                    </li>
                    <li class="nav-item">
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#businessTab">Business</button>
                    </li>
                    <li class="nav-item">
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#financeTab">Finance</button>
                    </li>
                </ul>
            </div>
            <div class="tab-content">
                <div class="card-body tab-pane active" id="industryTab" role="tabpanel">
                    <div class="accordion" id="industryAccordion">
                        <app-industry></app-industry>
                    </div>
                </div>
                <div class="card-body tab-pane" id="geographyTab" role="tabpanel">
                    <div class="accordion" id="geographyAccordion">
                        <app-geography></app-geography>
                    </div>
                </div>
                <div class="card-body tab-pane" id="businessTab" role="tabpanel">
                    <div class="accordion" id="businessAccordion">
                        <app-business></app-business>
                    </div>
                </div>
                <div class="card-body tab-pane" id="financeTab" role="tabpanel">
                    <div class="accordion" id="financeAccordion">
                        <app-finance></app-finance>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <app-b2b-selection-summary [equifaxDataService]="equifaxDataService" [selectionService]="equifaxSelectionService" (listSaved)="completeSaveList($event)"></app-b2b-selection-summary>
    </div>
</div>
</app-spinner-with-overlay>
