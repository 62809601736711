<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-6">
							<div *ngIf="displayDeliveryInfoButton" class="row mb-3">
								<div class="col-auto">
									<button class="btn btn-primary" (click)="loadDeliveryData()">
										Load Delivery Information
									</button>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<div class="mb-3">
										<div>
											<label for="campaignNameField" class="form-label">Campaign Name</label>
											<button *ngIf="dataLoaded" type="button" class="btn btn-link" (click)="copyToClipboard(campaignName, 'Campaign Name')"><i class="far fa fa-copy"></i></button>
										</div>
										<input type="input" class="form-control" id="campaignNameField" [ngModel]="campaignName">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<div class="mb-3">
										<div>
											<label for="zipCodes" class="form-label">Zip Codes</label>
											<button *ngIf="dataLoaded" type="button" class="btn btn-link" (click)="copyToClipboard(zipCodesList, 'Zip Codes')"><i class="far fa fa-copy"></i></button>
										</div>
										<textarea class="form-control" id="zipCodes" rows="5" [ngModel]="zipCodesList"></textarea>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 mb-3">
									<div>
										<label for="demographics" class="form-label">Demographic Filters</label>
										<button *ngIf="dataLoaded" type="button" class="btn btn-link" (click)="copyToClipboard(demographicsString, 'Demographics')"><i class="far fa fa-copy"></i></button>
									</div>
									<textarea class="form-control" id="demographics" rows="5" [ngModel]="demographicsString"></textarea>
								</div>
							</div>
							<div class="row">
								<div class="col-12 mb-3">
									<div>
										<label for="radiiAddresses" class="form-label">Addresses (1-mile radius targeting)</label>
										<button *ngIf="dataLoaded" type="button" class="btn btn-link" (click)="copyToClipboard(radiusTargeting, 'Addresses (1-mile radius targeting)')"><i class="far fa fa-copy"></i></button>
									</div>
									<textarea class="form-control" id="radiiAddresses" rows="5" [ngModel]="radiusTargeting"></textarea>
								</div>
							</div>
							<div class="row" *ngIf="startingAddress !== ''">
								<div class="col mb-3">
									<div>
										<label for="startingAddressField" class="form-label">Starting Address</label>
										<button *ngIf="dataLoaded" type="button" class="btn btn-link" (click)="copyToClipboard(startingAddress, 'Starting Address')"><i class="far fa fa-copy"></i></button>
									</div>
									<input type="input" class="form-control mb-3" id="startingAddressField" [ngModel]="startingAddress">
								</div>
								<div class="col mb-3" *ngIf="startingZip !== ''">
									<div>
										<label for="startingZipField" class="form-label">Starting Zip</label>
										<button *ngIf="dataLoaded" type="button" class="btn btn-link" (click)="copyToClipboard(startingZip, 'Starting Zip')"><i class="far fa fa-copy"></i></button>
									</div>
									<input type="input" class="form-control mb-3" id="startingZipField" [ngModel]="startingZip">
								</div>
							</div>
							<div *ngIf="dataLoaded" class="row">
								<div class="col mb-3">
									<div>
										<label for="startingLatField" *ngIf="startingPoint" class="form-label">Centroid Lat</label>
										<button type="button" class="btn btn-link" (click)="copyToClipboard(startingPoint.latitude.toString(), 'Map Centroid Latitude')"><i class="far fa fa-copy"></i></button>
									</div>
									<input type="input" *ngIf="startingPoint" class="form-control mb-3" id="startingLatField" [ngModel]="startingPoint.latitude">
								</div>
								<div class="col mb-3">
									<div>
										<label for="startingLongField" *ngIf="startingPoint" class="form-label">Centroid Long</label>
										<button type="button" class="btn btn-link" (click)="copyToClipboard(startingPoint.longitdue.toString(), 'Map Centroid Longitude')"><i class="far fa fa-copy"></i></button>
									</div>
									<input type="input" *ngIf="startingPoint" class="form-control mb-3" id="startingLongField" [ngModel]="startingPoint.longitdue">
								</div>
								<div class="col mb-3">
									<div>
										<label for="startingRadiusField" *ngIf="startingPoint" class="form-label">Radius</label>
										<button type="button" class="btn btn-link" (click)="copyToClipboard(startingPoint.radius.toString(), 'Map Selections Radius')"><i class="far fa fa-copy"></i></button>
									</div>
									<input type="input" *ngIf="startingPoint" class="form-control mb-3" id="startingRadiusField" [ngModel]="startingPoint.radius">
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<a type="button" target="_blank" class="btn btn-primary" target="blank" [routerLink]="['/customers', orderLayoutService.selectedCustomer?.customerID, 'distributions', orderLayoutService.selectedOrderItemAsCartProduct!.distributionId!]">Distribution Details</a>
								</div>
							</div>
						</div>
						<div class="col-6">
							<figure class="figure" *ngIf="usAreas.length > 0">
								<img [attr.src]="mapImageSrc" class="figure-img img-fluid rounded" alt="Map of Distribution Selections">
								<figcaption class="figure-caption">Map of Distribution Selections.</figcaption>
							</figure>
							<figure class="figure" *ngIf="caAreas.length > 0">
								<img [attr.src]="mapImageSrc" class="figure-img img-fluid rounded" alt="Map of Distribution Selections">
								<figcaption class="figure-caption">Map of Distribution Selections.</figcaption>
							</figure>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
