<ng-container *ngIf="!!bundle">
	<div *ngIf="!!bundleContent; else addContentTemplate">
		<ng-container *ngIf="!!bundle.bundleContent">
			<div class="row mb-3">
				<div class="col-md-6 col-12">
					<strong>Bundle Color: </strong>{{ bundle.bundleContent.bundleColor }}
				</div>
			</div>
			<div class="row mt-4 mb-2">
				<div class="col">
					<div class="row mb-2">
						<div class="col">
							<strong>Bundle Details Content Key: </strong>{{ bundle.bundleContent.detailsContentKey }}
						</div>
					</div>
					<div class="row">
						<div class="col">
							<ng-container *ngFor="let item of detailsContentItems">
								<div class="row mb-2">
									<div class="col">
										Site: {{ getSiteName(item.siteId) }}
									</div>
								</div>
								<div class="row mb-2">
									<div class="col">
										Language: {{ item.language }}
									</div>
								</div>
								<div class="row">
									<div class="col">
										<p>Content: <br />{{ item.content }}</p>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<hr />
			<div class="row mt-4 mb-2">
				<div class="col">
					<div class="row mb-2">
						<div class="col">
							<strong>Bundle Description Content Key: </strong>{{ bundle.bundleContent.descriptionContentKey }}
						</div>
					</div>
					<div class="row">
						<div class="col">
							<ng-container *ngFor="let item of descriptionContentItems">
								<div class="row mb-2">
									<div class="col">
										Site: {{ getSiteName(item.siteId) }}
									</div>
								</div>
								<div class="row mb-2">
									<div class="col">
										Language: {{ item.language }}
									</div>
								</div>
								<div class="row">
									<div class="col">
										<p>Content: <br />{{ item.content }}</p>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<hr />
			<div class="row mt-4 mb-2">
				<div class="col">
					<div class="row mb-2">
						<div class="col">
							<strong>Bundle Name Content Key: </strong>{{ bundle.bundleContent.nameContentKey }}
						</div>
					</div>
					<div class="row">
						<div class="col">
							<ng-container *ngFor="let item of descriptionContentItems">
								<div class="row mb-2">
									<div class="col">
										Site: {{ getSiteName(item.siteId) }}
									</div>
								</div>
								<div class="row mb-2">
									<div class="col">
										Language: {{ item.language }}
									</div>
								</div>
								<div class="row">
									<div class="col">
										<p>Content: <br />
										{{ item.content }}
									</p>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
	<ng-template #addContentTemplate></ng-template>
</ng-container>
