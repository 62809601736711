<div *ngIf="!!listOfItems" class="row">
	<div class="row">
		<div class="col-5">
			<h5 class="my-2">Select Product(s)</h5>
			<input type="text" class="form-control m-b-3" placeholder="Search by name"
			[(ngModel)]="filterKey" (ngModelChange)="filterItems()">
			<select [(ngModel)]="selectedItems" name="" id="" #c class="form-select" multiple size="10">
				<option *ngFor="let item of listOfItems" [value]="item">
					({{ item.productId }}) {{ item.name }}
				</option>
			</select>
		</div>
		<div class="col-sm-2 py-4 m-auto">
			<div class="row my-1">
				<button id="add" class="btn btn-small btn-default btn-block" (click)="selectItemsFromList()">
					<small>
						Add&nbsp;
						<span class="fa fa-arrow-right text-success"></span>
					</small>
				</button>
			</div>
			<div class="row my-1">
				<button id="remove" class="btn btn-small btn-default btn-block" (click)="removeItemsFromList()">
					<small>
						<span class="fa fa-arrow-left text-danger"></span>
						&nbsp;Remove</small>
				</button>
			</div>
			&nbsp;
		</div>
		<div class="col-sm-5">
			<h5 class="my-2">Selected Product(s)</h5>
			<select [(ngModel)]="selectedItems" name="" id="" #d class="form-select" multiple size="10">
				<option *ngFor="let item of listOfSelectedItems" [value]="item">
					{{ item.name }}
				</option>
			</select>
		</div>
	</div>
</div>
