import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { USelectMethod, Product } from '@taradel/web-api-client';
import { USelect } from 'services/distributions.service';
import { SitesService } from 'services/sites.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environment';

export interface ProductsList {
	name: string,
	productsList: USelectProductsList[]
}
export interface USelectProductsList {
	uSelect: USelect,
	productIdsList: USelectProductIds[]
};

export interface USelectProductIds {
	baseProductId: number,
	name: string,
	selected: boolean
};

@Component({
	selector: 'app-bulk-add-products',
	templateUrl: './bulk-add-products.component.html',
	styleUrls: ['./bulk-add-products.component.scss']
})
export class BulkAddProductsComponent implements OnInit {
	uSelects: USelectMethod[] = [];
	bulkProductForm: UntypedFormGroup;
	selectedList?: ProductsList;
	productsToAdd: Product[] = [];
	modalRef: any;
	isUS: boolean = environment.instance === 'US' ? true : false;

	// lists of base product ids tied to uselects
	//US
	eddmProductIds: USelectProductsList = {
		uSelect: USelect.eddm,
		productIdsList: [
			{ baseProductId: 207, name: '11x17 Flyer', selected: true },
			{ baseProductId: 216, name: '8.5x11 Postcard', selected: true },
			{ baseProductId: 225, name: '6.25x9 Postcard', selected: true },
			{ baseProductId: 229, name: '4.25x11 Postcard', selected: true },
			{ baseProductId: 252, name: '6.25x11 Postcard', selected: true }
		]
	};

	pmBuiltListProductIds: USelectProductsList = {
		uSelect: USelect.buildYourList,
		productIdsList: [
			{ baseProductId: 240, name: '4x6 Postcard', selected: true },
			{ baseProductId: 242, name: '5.5x8.5 Postcard', selected: true },
			{ baseProductId: 244, name: '6x11 Postcard', selected: true }
		]
	};

	pmUploadListProductIds: USelectProductsList = {
		uSelect: USelect.uploadYourList,
		productIdsList: [
			{ baseProductId: 239, name: '4x6 Postcard', selected: true },
			{ baseProductId: 241, name: '5.5x8.5 Postcard', selected: true },
			{ baseProductId: 243, name: '6x11 Postcard', selected: true },
		]
	};

	b2bProductIds: USelectProductsList = {
		uSelect: USelect.equifaxB2B,
		productIdsList: [
			{ baseProductId: 346, name: '4x6 Postcard', selected: true },
			{ baseProductId: 347, name: '5.5x8.5 Postcard', selected: true },
			{ baseProductId: 348, name: '6x11 Postcard', selected: true },
		]
	};

	targetedEmailProductIds: USelectProductsList = {
		uSelect: USelect.targetedEmail,
		productIdsList: [
			{ baseProductId: 236, name: 'Targeted Email', selected: true }
		]
	};

	displayAdsProductIds: USelectProductsList = {
		uSelect: USelect.displayAds,
		productIdsList: [
			{ baseProductId: 260, name: 'Display Ad', selected: true }
		]
	};

	facebookAdsProductIds: USelectProductsList = {
		uSelect: USelect.facebookAds,
		productIdsList: [
			{ baseProductId: 272, name: 'Facebook Ad - Single Image', selected: true },
			{ baseProductId: 273, name: 'Facebook - Carousel Ad', selected: true },
		]
	};

	instagramAdsProductIds: USelectProductsList = {
		uSelect: USelect.instagram,
		productIdsList: [
			{ baseProductId: 358, name: 'Instagram Ad - Single Image', selected: false },
			{ baseProductId: 359, name: 'Instagram Ad - Carousel', selected: false }
		]
	};

	spotifyAdsProductIds: USelectProductsList = {
		uSelect: USelect.spotify,
		productIdsList: [
			{ baseProductId: 362, name: 'Spotify - Audio Ad', selected: false }
		]
	};

	huluAdsProductIds: USelectProductsList = {
		uSelect: USelect.hulu,
		productIdsList: [
			{ baseProductId: 361, name: 'Hulu - TV Ad', selected: false }
		]
	};

	nextdoorAdsProductIds: USelectProductsList = {
		uSelect: USelect.nextdoorAds,
		productIdsList: [
			{ baseProductId: 1413, name: 'Nextdoor Ad', selected: false }
		]
	};

	linkedInProductIds: USelectProductsList = {
		uSelect: USelect.linkedinAds,
		productIdsList: [
			{ baseProductId: 331, name: 'In Feed Image', selected: false },
			{ baseProductId: 332, name: 'Desktop Text Ads', selected: false }
		]
	};

	callTrackingProductIds: USelectProductsList = {
		uSelect: USelect.callTracking,
		productIdsList: [
			{ baseProductId: 357, name: 'Call Tracking', selected: true }
		]
	};

	qrCodesProductIds: USelectProductsList = {
		uSelect: USelect.qrCode,
		productIdsList: [
			{ baseProductId: 363, name: 'Web Link', selected: true },
			{ baseProductId: 364, name: 'Phone Call', selected: true },
			{ baseProductId: 365, name: 'Digital Business Card', selected: true }
		]
	};

	//Canada
	snapProductIds: USelectProductsList = {
		uSelect: USelect.snapAdmail,
		productIdsList: [
			{ baseProductId: 207, name: '11" x 17" Tri-Fold Flyer (Folds to 5.66" x 11")', selected: true },
			{ baseProductId: 216, name: '6" x 12" Postcard', selected: true },
			{ baseProductId: 225, name: '6" x 9" Postcard', selected: true },
			{ baseProductId: 229, name: '8.5" x 11" Half-Fold Flyer (Folds to 5.5" x 8.5")', selected: true },
			{ baseProductId: 244, name: '5" x 7" Postcard', selected: true },
			{ baseProductId: 245, name: '4" x 5.5" Mini Postcard', selected: true }
		]
	};

	caDisplayAdsProductIds: USelectProductsList = {
		uSelect: USelect.displayAds,
		productIdsList: [
			{ baseProductId: 234, name: 'Display Ad', selected: true }
		]
	};

	caFacebookAdsProductIds: USelectProductsList = {
		uSelect: USelect.facebookAds,
		productIdsList: [
			{ baseProductId: 242, name: 'Facebook - Carousel Ad', selected: true },
			{ baseProductId: 243, name: 'Facebook Ad - Single Image', selected: true },
		]
	};

	caCallTrackingProductIds: USelectProductsList = {
		uSelect: USelect.callTracking,
		productIdsList: [
			{ baseProductId: 265, name: 'Call Tracking', selected: true }
		]
	};

	caQrCodesProductIds: USelectProductsList = {
		uSelect: USelect.qrCode,
		productIdsList: [
			{ baseProductId: 262, name: 'Web Link', selected: true },
			{ baseProductId: 263, name: 'Phone Call', selected: true },
			{ baseProductId: 264, name: 'Digital Business Card', selected: true }
		]
	};

	// lists of uselect product lists
	// US
	digitalProducts: ProductsList = {
		name: 'Digital Products',
		productsList: [
			this.displayAdsProductIds,
			this.facebookAdsProductIds,
			this.targetedEmailProductIds,
			this.instagramAdsProductIds,
			this.huluAdsProductIds,
			this.spotifyAdsProductIds,
			this.linkedInProductIds,
			this.nextdoorAdsProductIds,
		]
	};

	trackingProducts: ProductsList = {
		name: 'Tracking Products',
		productsList: [
			this.callTrackingProductIds,
			this.qrCodesProductIds
		]
	};

	eddmProducts: ProductsList = {
		name: 'EDDM Products',
		productsList: [
			this.eddmProductIds
		]
	};

	pmProducts: ProductsList = {
		name: 'PM Products',
		productsList: [
			this.pmBuiltListProductIds,
			this.pmUploadListProductIds
		]
	};

	b2bProducts: ProductsList = {
		name: 'B2B Products',
		productsList: [
			this.b2bProductIds
		]
	};

	// Canada
	snapProducts: ProductsList = {
		name: 'Snap Admail Products',
		productsList: [
			this.snapProductIds
		]
	};

	caDigitalProducts: ProductsList = {
		name: 'Digital Products',
		productsList: [
			this.caDisplayAdsProductIds,
			this.caFacebookAdsProductIds,
		]
	};

	caTrackingProducts: ProductsList = {
		name: 'Tracking Products',
		productsList: [
			this.caCallTrackingProductIds,
			this.caQrCodesProductIds
		]
	};

	// lists to display, based on environment instance
	productsDisplayList: ProductsList[] =  this.isUS ? [
		this.eddmProducts,
		this.pmProducts,
		this.b2bProducts,
		this.digitalProducts,
		this.trackingProducts
	] : [
		this.snapProducts,
		this.caDigitalProducts,
		this.caTrackingProducts
	];

	@Input() allProducts: Product[] = [];

	@Output() productsSelected = new EventEmitter<USelectProductIds[]>();

	constructor(private formBuilder: UntypedFormBuilder,
		private sitesService: SitesService,
		private modalService: NgbModal) {
		this.bulkProductForm = this.formBuilder.group({
			productsList: new FormControl<USelectProductsList[]>([])
		});
	}

	get bulk() { return this.bulkProductForm.controls; }
	get products() { return this.bulk.productsList.value; }

	async ngOnInit(): Promise<void> {
		this.uSelects = await this.sitesService.getAllUSelect();
	}

	getUSelectName(uSelect: USelect) {
		return this.uSelects.find(u => u.uSelectId === uSelect)?.name;
	}

	openModal(modal: any) {
		this.populateForm();
		this.modalRef = this.modalService.open(modal);
	}

	closeModal() {
		this.selectedList = undefined;
		this.productsToAdd = [];
		this.modalService.dismissAll();
	}

	populateForm() {
		this.bulkProductForm.reset();
		let updatedList = this.selectedList;
		let updatedProductsList: USelectProductIds[] = [];
		updatedList!.productsList.forEach(list => {
			const newList = list.productIdsList.filter(i => i.selected === true);
			newList.forEach(item => {
				updatedProductsList.push(item);
			});
		});
		this.bulk.productsList.setValue(updatedProductsList);
	}

	emitProductsSelected() {
		const selectedProducts = this.products;
		this.closeModal();
		this.productsSelected.emit(selectedProducts);
	}
}
