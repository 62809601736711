import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ShoppingQuoteService } from 'services/shopping-quote.service';
import { ShoppingQuote, Receipt, Customer, CustomerProfile, PaymentProfile, PaymentRequest, LineTypeEnum, CreditCardPaymentRequest, CustomerAddress, ApiException, AchPaymentRequest, PaymentType, LineItem, GetSurchargeRequest } from '@taradel/admin-api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CustomerService } from 'services/customer.service';
import { SalesApiService } from 'services/sales-api.service';
import { SiteConfigService } from 'services/site-config.service';
import { ToastService } from 'services/toast.service';
import { CustomerAddressService } from 'services/customer-address.service';
import { OrderService } from 'services/order.service';
import { PaymentsService } from 'services/payments.service';
import { isTaxLine } from 'services/orderlayout.service';

@Component({
	selector: 'app-quote-checkout',
	templateUrl: './quote-checkout.component.html',
	styleUrls: ['./quote-checkout.component.scss']
})
export class QuoteCheckoutComponent implements OnInit {

	customerId = 0;
	customer!: Customer;
	quote!: ShoppingQuote;
	quoteLineItems!: Receipt;
	quoteItems?: LineItem[];
	bundleName?: string;
	bundleItems?: LineItem[];
	loading = true;
	gettingSurcharge = false;
	submitted = false;
	showCouponCode = false;
	salesTaxMessage = '';
	createOrderErrorMessage = '';
	creditCardForm: UntypedFormGroup;
	achForm: UntypedFormGroup;
	customerProfileForm: UntypedFormGroup;
	purchaseOrderForm: UntypedFormGroup;
	creditKeyPostAuthForm: UntypedFormGroup;
	customerProfile?: CustomerProfile;
	customerPaymentProfiles: PaymentProfile[] = [];
	paymentType?: 'Credit Card' | 'ACH' | 'Payment Profile' | 'Purchase Order' | 'CreditKeyPostAuth';
	creditCardPaymentType = '';
	achPaymentType = '';
	profilePaymentType = '';
	creditCardYear: string[] = [];
	disablePaymentPlan = false;
	creditKeyActive = false;
	billingAddress?: CustomerAddress;
	useBalance = false;
	totalBalance = 0;
	showCCNum = false;
	showCVV = false;
	surchargePercent = 0;
	surchargeAmount = 0;
	totalAmount = 0;
	financeFee = 0;

	constructor(private quoteService: ShoppingQuoteService,
		private route: ActivatedRoute,
		private router: Router,
		private customerService: CustomerService,
		private customerAddressService: CustomerAddressService,
		public formBuilder: UntypedFormBuilder,
		private orderService: OrderService,
		private paymentsService: PaymentsService,
		private salesApiService: SalesApiService,
		private siteConfigService: SiteConfigService,
		private toastService: ToastService) {

		this.creditCardForm = this.formBuilder.group({
			creditCardNumber: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(13),
				Validators.maxLength(16)
			])),
			cvv: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(4)
			])),
			expirationMonth: new UntypedFormControl('', Validators.compose([
				Validators.required
			])),
			expirationYear: new UntypedFormControl('', Validators.compose([
				Validators.required
			]))
		});

		this.achForm = this.formBuilder.group({
			routingNumber: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(9),
				Validators.maxLength(9)
			])),
			accountNumber: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(17)
			])),
			nameOnAccount: new UntypedFormControl('', Validators.compose([
				Validators.required
			])),
			bankName: new UntypedFormControl('', Validators.compose([
				Validators.required
			])),
			accountType: new UntypedFormControl('', Validators.compose([
				Validators.required
			])),
		});
		this.customerProfileForm = this.formBuilder.group({
			customerPaymentProfileId: new UntypedFormControl('', Validators.compose([
				Validators.required
			]))
		});

		this.purchaseOrderForm = this.formBuilder.group({
			purchaseOrderNumber: new UntypedFormControl('', Validators.compose([
				Validators.required
			]))
		});

		this.creditKeyPostAuthForm = this.formBuilder.group({
			creditKeyPostAuthRefNumber: new UntypedFormControl('', Validators.compose([
				Validators.required
			]))
		});
	}

	get creditCardControls() { return this.creditCardForm.controls; }
	get achControls() { return this.achForm.controls; }
	get customerProfileControls() { return this.customerProfileForm.controls; }
	get purchaseOrderControls() { return this.purchaseOrderForm.controls; }
	get ckControls() { return this.creditKeyPostAuthForm.controls; }

	ngOnInit(): void {
		this.route.paramMap.pipe(take(1)).subscribe(async params => {
			try {
				this.customerId = parseInt(params.get('customerId') ?? '0', 10);
				const quoteId = params.get('quoteId') ?? '0';

				this.customer = await this.customerService.getCustomer(this.customerId);
				this.quote = await this.quoteService.getQuoteByGuid(this.customerId, quoteId);
				this.billingAddress = await this.customerAddressService.getCustomerAddress(this.customerId, this.quote.billingAddressId);
				this.quoteLineItems = await this.quoteService.getQuoteLineItems(quoteId);
				this.quoteItems = this.quoteLineItems.categories![0].lineItems;
				if (this.quoteLineItems.categories!![0].bundleName && this.quoteLineItems.categories![0].bundleName !== '') {
					this.bundleName = this.quoteLineItems.categories![0].bundleName;
					this.bundleItems = this.quoteLineItems.categories![0].lineItems?.filter(l => l.bundleDiscount < 0);
					this.quoteItems = this.quoteLineItems.categories![0].lineItems?.filter(l => l.bundleDiscount >= 0);
				}
				this.disablePaymentPlan = await this.siteConfigService.getBoolean(this.quote.siteId, 'Payment', 'DisablePaymentPlan');
				if (this.quote.paymentSchedule!.length <= 1) {
					this.disablePaymentPlan = true;
				}
				await this.getCustomerPaymentProfiles();
				const customerBalance = await this.paymentsService.getCustomerBalance(this.customerId);

				customerBalance.map(x => {
					this.totalBalance += x.balance;
				});
				this.creditKeyActive = await this.siteConfigService.getBoolean(this.quote.siteId, 'Payment', 'EnableCreditKey');
			}
			catch {
				this.toastService.showError('There was a problem loading the component', 'Load Error');
			}
			finally {
				this.loading = false;
			}
		});
	}

	async getCustomerPaymentProfiles() {
		try {
			this.customerProfile = await this.salesApiService.getCustomerProfile(this.customer.customerID);
		}
		catch (err: any) {
			if (ApiException.isApiException(err) && err.status === 404) {
				this.customerProfile = undefined;
			}
			else {
				this.toastService.showError('Error reading customer profile');
			}
		}

		if (!!this.customerProfile) {
			this.customerPaymentProfiles = [];

			this.customerProfile.paymentProfiles?.forEach(x =>{
				if (this.customerPaymentProfiles.findIndex(a => a.creditCardMaskedData?.maskedCard === x.creditCardMaskedData?.maskedCard) === -1 ||
				this.customerPaymentProfiles.findIndex(a => a.achMaskedData?.accountNumber === x.achMaskedData?.accountNumber) === -1) {
					this.customerPaymentProfiles.push(x);
				}
			});
		}
	}

	selectPaymentPlan(planType: string) {
		this.paymentType = 'Credit Card';
		this.creditCardPaymentType = planType;
		this.achPaymentType = '';
		this.profilePaymentType = '';
		this.creditCardForm.reset();
		this.achForm.reset();
		this.customerProfileForm.reset();
		this.purchaseOrderForm.reset();
		this.creditKeyPostAuthForm.reset();
		this.surchargeAmount = 0;
		this.surchargePercent = 0;
		this.totalAmount = 0;
		
	}
	selectACHPaymentPlan(planType: string): void {
		this.paymentType = 'ACH';
		this.achPaymentType = planType;
		this.creditCardPaymentType = '';
		this.profilePaymentType = '';
		this.achForm.reset();
		this.creditCardForm.reset();
		this.customerProfileForm.reset();
		this.purchaseOrderForm.reset();
		this.creditKeyPostAuthForm.reset();
		this.surchargeAmount = 0;
	}
	selectProfilePaymentPlan(planType: string) {
		this.paymentType = 'Payment Profile';
		this.profilePaymentType = planType;
		this.achPaymentType = '';
		this.creditCardPaymentType = '';
		this.customerProfileForm.reset();
		this.creditCardForm.reset();
		this.achForm.reset();
		this.purchaseOrderForm.reset();
		this.creditKeyPostAuthForm.reset();
		this.surchargeAmount = 0;
		this.surchargePercent = 0;
		this.totalAmount = 0;
	}

	purchaseOrderSelected() {
		this.creditCardForm.reset();
		this.achForm.reset();
		this.customerProfileForm.reset();
		this.creditKeyPostAuthForm.reset();
		this.paymentType = 'Purchase Order';
		this.creditCardPaymentType = '';
		this.profilePaymentType = '';
		this.achPaymentType = '';
		this.surchargeAmount = 0;
	}

	creditKeyPostAuthSelected() {
		this.purchaseOrderForm.reset();
		this.creditCardForm.reset();
		this.achForm.reset();
		this.customerProfileForm.reset();
		this.paymentType = 'CreditKeyPostAuth';
		this.creditCardPaymentType = '';
		this.profilePaymentType = '';
		this.achPaymentType = '';
		this.surchargeAmount = 0;
	}

	public adjustYear(selectedMonth: string) {
		const month = new Date().getMonth();
		if (month >= parseInt(selectedMonth, 10)) {
			this.fillCreditCardYear(1);
		}
		else {
			this.fillCreditCardYear(0);
		}

	}

	async creditCardNumberUpdated() {
		if (this.creditCardControls.creditCardNumber.errors) {
			return;
		}
		this.gettingSurcharge = true;
		let amount = this.quote.total;
		if (this.creditCardPaymentType === 'paymentPlan') {
			amount += this.quote.financeFee + (this.quote.gstOnFinanceFee ?? 0) +(this.quote.hstOnFinanceFee ?? 0) +(this.quote.pstOnFinanceFee ?? 0);
		}
		try {
			const surchargeRequest = new GetSurchargeRequest({
				amount,
				customerId: this.customerId,
				card: this.creditCardControls.creditCardNumber.value
			});
			const response = await this.salesApiService.getSurcharge(this.quote.siteId, surchargeRequest);
			this.surchargePercent = response.surchargeRate;
			this.surchargeAmount = response.surchargeRate * amount / 100;
			this.totalAmount = amount + this.surchargeAmount;
		}
		catch(error) {
			this.toastService.showError('Unable to get surcharge');
		}
		finally {
			this.gettingSurcharge = false;
		}
	}
	async paymentProfileSelected() {
		if (this.customerProfileControls.customerPaymentProfileId.errors) {
			return;
		}
		const profile = this.customerPaymentProfiles.find(x => x.customerPaymentProfileId === this.customerProfileControls.customerPaymentProfileId.value);
		if (profile?.achMaskedData !== undefined) {
			this.surchargeAmount = 0;
			return;
		}
		this.gettingSurcharge = true;
		let amount = this.quote.total;
		if (this.profilePaymentType === 'paymentPlanUsingCustomerProfile') {
			amount += this.quote.financeFee + (this.quote.gstOnFinanceFee ?? 0)+ (this.quote.hstOnFinanceFee ?? 0) + (this.quote.pstOnFinanceFee ?? 0);
		}
		try {			
			const surchargeRequest = new GetSurchargeRequest({
				amount,
				customerId: this.customerId,
				paymentProfileId: this.customerProfileControls.customerPaymentProfileId.value
			});
			const response = await this.salesApiService.getSurcharge(this.quote.siteId, surchargeRequest);
			this.surchargePercent = response.surchargeRate;
			this.surchargeAmount = response.surchargeRate * amount / 100;
			this.totalAmount = amount + this.surchargeAmount;
		}
		catch(error) {
			this.toastService.showError('Unable to get surcharge');
		}
		finally {
			this.gettingSurcharge = false;
		}
	}
	private fillCreditCardYear(offset: number) {
		let year = new Date().getFullYear() + offset;
		this.creditCardYear = [];
		for (let i = 0; i < 10; i++) {
			this.creditCardYear.push(year.toString());
			year = year + 1;
		}
	}

	getSalesTax() {
		return this.quote.quoteData?.reduce((a, b) => {
			return a + (b.quoteLines?.filter(x => isTaxLine(x.lineType))?.reduce((total, line) => total + line.amount, 0) ?? 0);
		}, 0) ?? 0;
	}

	getGST() {
		return this.quote.quoteData?.reduce((a, b) => {
			return a + (b.quoteLines?.find(x => x.lineType === LineTypeEnum.GST)?.amount ?? 0);
		}, 0) ?? 0;
	}

	getPST() {
		return this.quote.quoteData?.reduce((a, b) => {
			return a + (b.quoteLines?.find(x => x.lineType === LineTypeEnum.PST)?.amount ?? 0);
		}, 0) ?? 0;
	}

	getHST() {
		return this.quote.quoteData?.reduce((a, b) => {
			return a + (b.quoteLines?.find(x => x.lineType === LineTypeEnum.HST)?.amount ?? 0);
		}, 0) ?? 0;
	}

	async sendQuoteToCustomer() {
		this.loading = true;
		try {
			await this.salesApiService.emailQuotes([this.quote.quoteId]);
			this.toastService.showSuccess('Email sent successfully');
		}
		catch (error) {
			this.toastService.showError('There was an error emailing quote to customer.', 'Send Error');
		}
		finally {
			this.loading = false;
		}
	}

	async placeOrder() {
		this.submitted = true;
        if (this.quote.total > 0) {
            if (this.useBalance && this.totalBalance < this.quote.total && this.paymentType === undefined) {
                this.createOrderErrorMessage = 'Balance is less than payment amount. Please pay remaining using other payment methods';
                return;
            }
            if (!this.useBalance && this.paymentType === undefined) {
                // show error message that no payment selected
                this.createOrderErrorMessage = 'Please select a payment method';
                return;
            }
            if ((this.paymentType === 'Credit Card' && !this.creditCardForm.valid) ||
            (this.paymentType === 'ACH' && !this.achForm.valid) ||
            (this.paymentType === 'Payment Profile' && !this.customerProfileForm.valid) ||
            (this.paymentType === 'Purchase Order' && !this.purchaseOrderForm.valid) ||
            (this.paymentType === 'CreditKeyPostAuth' && !this.creditKeyPostAuthForm.valid)) {
                return;
            }
        }
        if (this.quote.total === 0) {
            this.paymentType = 'Purchase Order';
        }

		this.loading = true;
		let createOrderSuccessful = false;
		let orderId = 0;
		const withTaradelFinancing = (this.creditCardForm.valid && this.creditCardPaymentType === 'paymentPlan') ||
		(this.achForm.valid && this.achPaymentType === 'achPaymentPlan') ||
		(this.customerProfileForm.valid && this.profilePaymentType === 'paymentPlanUsingCustomerProfile');

		let paymentRequest = new PaymentRequest();
		switch (this.paymentType) {
			case 'Purchase Order':
				paymentRequest = new PaymentRequest({
					paymentType: PaymentType.PurchaseOrder,
					poNumber: this.purchaseOrderControls.purchaseOrderNumber.value,
					useBalance: false,
					withTaradelFinancing: false,
				});
				break;
			case 'Credit Card':
				paymentRequest = new PaymentRequest({
					paymentType: PaymentType.CreditCard,
					creditCardPaymentRequest: new CreditCardPaymentRequest({
						cardNumber: this.creditCardControls.creditCardNumber.value,
						cardCode: this.creditCardControls.cvv.value,
						expirationDate: this.creditCardControls.expirationMonth.value + this.creditCardControls.expirationYear.value.substring(2, 4)
					}),
					useBalance: false,
					withTaradelFinancing,
				});
				break;
			case 'ACH':
				paymentRequest = new PaymentRequest({
					paymentType: PaymentType.ACH,
					achPaymentRequest: new AchPaymentRequest({
						routingNumber: this.achControls.routingNumber.value,
						accountNumber: this.achControls.accountNumber.value,
						nameOnAccount: this.achControls.nameOnAccount.value,
						bankName: this.achControls.bankName.value,
						accountType: this.achControls.accountType.value
					}),
					useBalance: false,
					withTaradelFinancing,
				});
				break;
			case 'Payment Profile':
				paymentRequest = new PaymentRequest({
					paymentType: PaymentType.ACH,
					customerProfileId: this.customerProfile?.customerProfileId,
					customerPaymentProfileId: this.customerProfileControls.customerPaymentProfileId.value,
					useBalance: false,
					withTaradelFinancing,
				});
				break;
			case 'CreditKeyPostAuth':
				paymentRequest = new PaymentRequest({
					paymentType: PaymentType.CreditKeyPostAuth,
					creditKeyOrderKey: this.ckControls.creditKeyPostAuthRefNumber.value,
					useBalance: false,
					withTaradelFinancing: false,
				});
				break;
			default:
				if (!this.useBalance || this.totalBalance < this.quote.total) {
					throw new Error('Payment type not selected');
				}
		}

		try {
			createOrderSuccessful = true;
			paymentRequest.useBalance = this.useBalance;
			orderId = await this.quoteService.placeOrder(this.quote.quoteId, paymentRequest);
		}
		catch (error: any) {
			console.log(error);
			if (error.status === 400) {
				const errorResponse = JSON.parse(error.response);
				if (errorResponse[0].code) {
					this.createOrderErrorMessage = `Order placement failed: ${errorResponse[0].code}: ${errorResponse[0].message}`;
				}
				else {
					this.createOrderErrorMessage = `Order placement failed: ${errorResponse}`;
				}
			}
			else {
				this.createOrderErrorMessage = `${error} ${this.paymentType} order placement failed`;
			}
			createOrderSuccessful = false;

		}
		finally {
			this.loading = false;
		}
		if (createOrderSuccessful) {
			this.router.navigate(['/orders', orderId, 'summary']);
		}
	}

	return(type: 'quote' | 'customerQuotes') {
		/*if (type === 'quote') {
			this.router.navigate(['/customers', this.customerId, 'shop', 'quotes']);
		}*/
		if (type === 'customerQuotes') {
			this.router.navigate(['/customers', this.customerId, 'shop', 'quotes']);
		}
	}

	toggleShowCCNum() {
		this.showCCNum = !this.showCCNum;
	}

	toggleShowCVV() {
		this.showCVV = !this.showCVV;
	}
	updateForms() {
		if (this.useBalance && this.totalBalance >= this.quote.total) {
			this.paymentType = undefined;
			this.creditKeyPostAuthForm.disable();
			this.creditCardForm.disable();
			this.achForm.disable();
			this.customerProfileForm.disable();
			this.purchaseOrderForm.disable();
			this.purchaseOrderForm.reset();
			this.creditCardForm.reset();
			this.achForm.reset();
			this.creditKeyPostAuthForm.reset();
			this.customerProfileForm.reset();
			this.creditCardPaymentType = '';
		}
		else {
			this.creditKeyPostAuthForm.enable();
			this.creditCardForm.enable();
			this.achForm.enable();
			this.customerProfileForm.enable();
			this.purchaseOrderForm.enable();
		}
	}

	getDiscount(item: LineItem): number {
		return item.bundleDiscount + item.couponDiscount;
	}

	getBundleDiscount() {
		const totalDiscount = this.quote?.bundles?.reduce((total, b) => total + b.totalDiscount, 0);
		return totalDiscount ?? 0;
	}
}
