<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="card">
			<form (ngSubmit)="search()">
				<div class="card-body">
					<h5 class="card-title">Search</h5>
					<div class="row">
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="date" class="form-control" id="startDate" name="startDate"
									[ngModel]="customerPaymentsQuery.fromDate | date:'yyyy-MM-dd'"
									(ngModelChange)="parseDate($event, true)">
								<label for="startDate">Start Date</label>
							</div>
							<div class="form-floating mb-3">
								<input type="date" class="form-control" id="toOrderDate" name="endDate"
									[ngModel]="customerPaymentsQuery.toDate | date:'yyyy-MM-dd'"
									(ngModelChange)="parseDate($event, false)">
								<label for="endDate">End Date</label>
							</div>
							<div class="form-floating mb-3" *ngIf="!customerId">
								<input type="number" id="customerId" name="customerId" class="form-control"
									[(ngModel)]="customerPaymentsQuery.customerId">
								<label for="customerId">Customer Id</label>
							</div>

						</div>
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="number" id="customerPaymentId" name="customerPaymentId" class="form-control"
									[(ngModel)]="customerPaymentsQuery.customerPaymentId">
								<label for="customerPaymentId">Customer Payment Id</label>
							</div>
							<div class="form-floating mb-3">
								<input type="number" id="customerPaymentProfileId" name="customerPaymentProfileId" class="form-control"
									[(ngModel)]="customerPaymentsQuery.customerPaymentProfileId">
								<label for="customerPaymentProfileId">Customer Payment Profile Id</label>
							</div>
							<div class="form-floating mb-3">
								<app-email-input
									[(ngModel)]="customerPaymentsQuery.billingEmailAddress"
									name="billingEmailAddress"
									label="Billing Email"
									placeholder="Billing E-mail"></app-email-input>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="text" id="paymentType" name="paymentType" class="form-control"
									[(ngModel)]="customerPaymentsQuery.paymentType">
								<label for="paymentType">Payment Type</label>
							</div>
							<div class="form-floating mb-3">
								<input type="text" id="paymentRef" name="paymentRef" class="form-control"
									[(ngModel)]="customerPaymentsQuery.paymentRef">
								<label for="paymentRef">Payment Reference (Transaction Id)</label>
							</div>
							<div class="form-floating mb-3">
								<input type="text" id="maskedPaymentData" name="maskedPaymentData" class="form-control"
									[(ngModel)]="customerPaymentsQuery.maskedPaymentData">
								<label for="maskedPaymentData">Masked Payment Data</label>
							</div>
						</div>
					</div>
					<button class="btn btn-primary" [disabled]="loading" type="submit">Search</button>
					<a *ngIf="!!customerId && customerId > 0 && authService.organizationId === 1" [routerLink]="['/customers/', customerId, 'payments', 'create']" class="btn btn-primary ms-3">Create Payment</a>
				</div>
				<div class="row mx-2" *ngIf="customerId > 0">
					<div class="col">
						<span [ngClass]="'balance ' + (totalBalance >= 0 ? 'positive' : 'negative')" for="balance">Customer Balance: {{totalBalance | currency}}</span>
					</div>
				</div>
			</form>
		</div>
		<div *ngIf="isSearchEnabled">
			<div class="d-flex justify-content-between align-items-end p-2">
				<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
					[pageSize]="customerPaymentsQuery.pagination!.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
					(pageChange)="pageChanged()"></ngb-pagination>
				<div class="form-floating">
					<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
						(change)="pageChanged()" aria-label="Items per page">
						<option [ngValue]="20">20 items per page</option>
						<option [ngValue]="50">50 items per page</option>
						<option [ngValue]="100">100 items per page</option>
					</select>
					<label for="pageSize">Items per page</label>
				</div>
			</div>
		</div>
		<div class="table-responsive" *ngIf="customerPayments.length > 0">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Customer Payment Id</th>
						<th>Created Date</th>
						<th>Created By</th>
						<th>Payment Amount</th>
						<th>Applied Amount</th>
						<th>Refunded Amount</th>
						<th>Remaining Balance</th>
						<th>Payment Type</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let customerPayment of customerPayments">
						<td>
							<a [routerLink]="['/customers', customerPayment.customerId, 'payments', customerPayment.customerPaymentId]" class="btn btn-link p-0">{{ customerPayment.customerPaymentId }}</a>
						</td>
						<td>
							{{ customerPayment.createdDate | date:'short' }}
						</td>
						<td>
							{{ customerPayment.createdBy }}
						</td>
						<td>
							{{ customerPayment.paymentAmount | currency }}
						</td>
						<td>
							{{ customerPayment.paymentAppliedAmount | currency }}
						</td>
						<td>
							{{ customerPayment.paymentRefundedAmount | currency }}
						</td>
						<td [ngClass]="(customerPayment.paymentRemainingBalance >= 0 ? 'positive' : 'negative')">
							{{ customerPayment.paymentRemainingBalance | currency }}
						</td>
						<td>
							{{ customerPayment.paymentType }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="isSearchEnabled">
			<div class="d-flex justify-content-between align-items-end p-2">
				<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
					[pageSize]="customerPaymentsQuery.pagination!.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
					(pageChange)="pageChanged()"></ngb-pagination>
				<div class="form-floating">
					<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
						(change)="pageChanged()" aria-label="Items per page">
						<option [ngValue]="20">20 items per page</option>
						<option [ngValue]="50">50 items per page</option>
						<option [ngValue]="100">100 items per page</option>
					</select>
					<label for="pageSize">Items per page</label>
				</div>
			</div>
		</div>

		<app-alert-box *ngIf="customerPayments.length === 0 && !loading" alertMode="info">
			No payments found.
		</app-alert-box>


	</div>
</app-spinner-with-overlay>
