import { environment } from "environment";
import { first } from "lodash";

export enum IdentityRedirectType {
	Login,
	LoginSilent,
	PostLogout,
}

type IdentityClientId = `taradel-admin-${string}` | `${string}-taradel-partner-admin-${string}`

export function getIdentityClientId(): IdentityClientId {
	const subdomains = getHostSubdomains(window.location.hostname);
	const subdomain = first(subdomains);
	if (!subdomain || subdomain === 'admin') {
		return `taradel-admin-${environment.identity.clientEnvironment}`;
	}
	return `${subdomain}-taradel-partner-admin-${environment.identity.clientEnvironment}`;
}

export function getIdentityRedirect(redirectType: IdentityRedirectType): string {
	let uri = `${environment.identity.uri.scheme}${getIdentityHost()}`;
	if (redirectType === IdentityRedirectType.Login) {
		uri += environment.identity.uri.callbackRoute;
	}
	else if (redirectType === IdentityRedirectType.LoginSilent) {
		uri += environment.identity.uri.silentRoute;
	}
	else if (redirectType === IdentityRedirectType.PostLogout) {
		uri += '/';
	}
	return uri;
}

function getIdentityHost(): string {
	if (window.location.host === environment.identity.uri.adminHostSlug || window.location.hostname === environment.identity.uri.adminHostSlug) { // handle with and without port
		const host = environment.identity.uri.adminHostSlug;
		return host;
	}
	const subdomains = getHostSubdomains(window.location.hostname);
	console.debug(`IdentityHost subdomains:`, {subdomains});
	if (subdomains.length) {
		const host = `${subdomains[0]}.${environment.identity.uri.partnerHostSlug}`;
		return host;
	}
	throw new Error('Invalid hostname, could not detect admin nor valid subdomain');
}

function getHostSubdomains(hostname: string) {
	return hostname.split('.').slice(0, -2);
}
