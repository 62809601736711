import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import {
	DeliveryDatesResponse, CartsClient as WebCartsClient,
	DeliveryDatesClient as WebDeliveryDatesClient, CouponClient as WebCouponClient,
	PersonalizedMailFieldGroup,
	FieldGroupsClient as WebFieldGroupsClient,
	OrderAttribution,
	GetCalendarRequest,
} from '@taradel/web-api-client';
import {
	AttributionClient, DeliveryDatesClient, FileParameter,
	ReceiptClient,
	OrdersClient,
	CustomerClient,
	Customer,
	CouponClient,
	Coupon,
	FileResponse,
	PDFReceiptClient,
	CustomerProfile,
	PaymentClient,
	ValidateCoupon,
	ArtOrientation,
	ShoppingClient,
	AdminQuoteFromCartQuery,
	ShoppingCart,
	TransferCartQuery,
	USelectInductionTurnaround,
	Calendar,
	GetCaCalendarRequest,
	GetInHomeDateRange,
	DropDeliveryRange,
	DeletePaymentProfileQuery,
	CreatePaymentProfileQuery
} from '@taradel/admin-api-client';
import { USelect } from './distributions.service';
import { lastValueFrom } from 'rxjs';

export let DirectMailProducts = [
	USelect.eddm,
	USelect.snapAdmail,
	USelect.b2B,
	USelect.buildPoliticalList,
	USelect.buildYourList,
	USelect.equifaxB2B,
	USelect.uploadPoliticalList,
	USelect.uploadYourList,
	USelect.printAndShip
];
export let PMProducts = [
	USelect.b2B,
	USelect.buildPoliticalList,
	USelect.buildYourList,
	USelect.equifaxB2B,
	USelect.uploadPoliticalList,
	USelect.uploadYourList
];
export let PrintProductTypes = [
	'EDDM',
	'SnapAdmail',
	'B2BMail',
	'AddressedList',
	'PoliticalMail',
	'EquifaxB2b'
];

export let ProductsWithDailyBudget = [
	USelect.displayAds,
	USelect.facebookAds,
	USelect.linkedinAds,
	USelect.hulu,
	USelect.instagram,
	USelect.spotify,
	USelect.tiktok,
	USelect.nextdoorAds
];

export let DigitalProducts = [
	USelect.displayAds,
	USelect.facebookAds,
	USelect.linkedinAds,
	USelect.targetedEmail,
	USelect.linkedinAds,
	USelect.hulu,
	USelect.instagram,
	USelect.spotify,
	USelect.tiktok,
	USelect.nextdoorAds
];
export let DigitalProductsTypes = [
	'Display Ads',
	'Facebook Ads',
	'LinkedIn Ads',
	'Targeted Emails'
];

export enum DaysOfWeek {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6
}

@Injectable({
	providedIn: 'root'
})
export class SalesApiService {
	private readonly customerClient: CustomerClient;
	private readonly attributionClient: AttributionClient;
	private readonly deliveryDatesClient: DeliveryDatesClient;
	private readonly receiptClient: ReceiptClient;
	private readonly shoppingClient: ShoppingClient;
	private readonly ordersClient: OrdersClient;
	private readonly paymentClient: PaymentClient;
	private readonly couponClient: CouponClient;
	private readonly pdfReceiptClient: PDFReceiptClient;

	private readonly webDeliveryDatesClient: WebDeliveryDatesClient;
	private readonly webCartsClient: WebCartsClient;
	private readonly webCouponClient: WebCouponClient;
	private readonly webFieldGroupsClient: WebFieldGroupsClient;

	constructor(http: HttpClient) {
		this.customerClient = new CustomerClient(http, environment.adminApiUrl);
		this.attributionClient = new AttributionClient(http, environment.adminApiUrl);
		this.deliveryDatesClient = new DeliveryDatesClient(http, environment.adminApiUrl);
		this.receiptClient = new ReceiptClient(http, environment.adminApiUrl);
		this.shoppingClient = new ShoppingClient(http, environment.adminApiUrl);
		this.ordersClient = new OrdersClient(http, environment.adminApiUrl);
		this.paymentClient = new PaymentClient(http, environment.adminApiUrl);
		this.couponClient = new CouponClient(http, environment.adminApiUrl);
		this.pdfReceiptClient = new PDFReceiptClient(http, environment.adminApiUrl);

		this.webDeliveryDatesClient = new WebDeliveryDatesClient(http, environment.webApiUrl);
		this.webCartsClient = new WebCartsClient(http, environment.webApiUrl);
		this.webCouponClient = new WebCouponClient(http, environment.webApiUrl);
		this.webFieldGroupsClient = new WebFieldGroupsClient(http, environment.webApiUrl);
	}

	getOrderAttribution(orderId: number): Promise<OrderAttribution> {
		return lastValueFrom(this.attributionClient.getOrderAttribution(orderId));
	}

	exportAttributionPdf(orderId: number, reportData: string): Promise<FileResponse | null> {
		return lastValueFrom(this.attributionClient.exportDashboardToPdf(orderId, reportData));
	}

	getEddmDeliveryDates(): Promise<DeliveryDatesResponse> {
		return lastValueFrom(this.webDeliveryDatesClient.getEddmDeliveryDates());
	}

	getPmDeliveryDates(): Promise<DeliveryDatesResponse> {
		return lastValueFrom(this.webDeliveryDatesClient.getPmDeliveryDates());
	}

	async getUSelectInductionTurnaround(uSelectId: number) : Promise<USelectInductionTurnaround> {
		return await lastValueFrom(this.webDeliveryDatesClient.getUSelectInductionTurnaround(uSelectId));
	}

	getUsDates(query: GetCalendarRequest): Promise<Calendar> {
		return lastValueFrom(this.webDeliveryDatesClient.getUsCalendar(query));
	}

	getInHomeDateRange(query: GetInHomeDateRange): Promise<DropDeliveryRange[]> {
		return lastValueFrom(this.ordersClient.getInHomeDateRange(query));
	}

	getCaDates(customerId: number, query: GetCaCalendarRequest): Promise<Calendar> {
		return lastValueFrom(this.deliveryDatesClient.getCaCalendar(customerId, query));
	}

	uploadTemporaryFile(uploadedFile: FileParameter): Promise<any> {
		return lastValueFrom(this.webCartsClient.uploadTemporaryFile(uploadedFile));
	}

	async generateQuote(customerId: number, siteId: number, billingAddressId: number, couponCode: string, productDiscounts?: { [key: string]: number }): Promise<string> {
		return await lastValueFrom(this.shoppingClient.quoteFromCustomerCart(new AdminQuoteFromCartQuery({
			customerId,
			siteId,
			billingAddressId,
			couponCode,
			productDiscounts
		})));
	}

	async emailCart(siteId: number, customerId: number): Promise<boolean> {
		return await lastValueFrom(this.receiptClient.emailShoppingCart(siteId, customerId));
	}

	async emailQuotes(quoteIds: string[]): Promise<void> {
		await lastValueFrom(this.receiptClient.emailShoppingQuotes(quoteIds));
	}

	async getCustomerProfile(customerId: number): Promise<CustomerProfile> {
		return await lastValueFrom(this.paymentClient.getCustomerProfile(customerId));
	}

	async createCustomerPaymentProfile(query: CreatePaymentProfileQuery): Promise<void> {
		await lastValueFrom(this.paymentClient.createPaymentProfile(query));
	}

	async deleteCustomerProfile(customerId: number, customerPaymentProfileId: string): Promise<void> {
		await lastValueFrom(this.paymentClient.deletePaymentProfile(new DeletePaymentProfileQuery({
			customerId,
			paymentProfileId: customerPaymentProfileId
		})));
	}

	hasActiveCoupons(siteId: number): Promise<boolean> {
		return lastValueFrom(this.webCouponClient.hasActiveCoupons(siteId));
	}

	validateShoppingCartCoupon(customerId: number, validateCoupon: ValidateCoupon): Promise<Coupon> {
		return lastValueFrom(this.couponClient.validateCustomerShoppingCartCoupon(customerId, validateCoupon));
	}

	async createCoupon(coupon: Coupon): Promise<number> {
		return await lastValueFrom(this.couponClient.createCoupon(coupon));
	}

	getCustomer(customerId: number): Promise<Customer> {
		return lastValueFrom(this.customerClient.getCustomer(customerId));
	}

	async getCustomerShoppingCarts(customerId: number): Promise<ShoppingCart[]> {
		return await lastValueFrom(this.shoppingClient.getShoppingCarts(customerId));
	}

	async transferShoppingCart(query: TransferCartQuery) {
		await lastValueFrom(this.shoppingClient.transferShoppingCart(query));
	}

	getUSelectFieldGroups(uSelectId: number): Promise<{ [id: number]: PersonalizedMailFieldGroup }> {
		return lastValueFrom(this.webFieldGroupsClient.getUSelectFieldGroups(uSelectId));
	}

	async artOrientation(artOrientation: ArtOrientation): Promise<FileResponse | null> {
		return await lastValueFrom(this.ordersClient.artOrientation(artOrientation, artOrientation.orderItemId.toString()));
	}

	async uploadFrontDesign(orderItemId: number, uploadedFile: FileParameter): Promise<FileResponse | null> {
		return await lastValueFrom(this.ordersClient.uploadFrontDesign(orderItemId, uploadedFile));
	}

	async uploadBackDesign(orderItemId: number, uploadedFile: FileParameter): Promise<FileResponse | null> {
		return await lastValueFrom(this.ordersClient.uploadBackDesign(orderItemId, uploadedFile));
	}

	async downloadFrontDesign(orderItemId: number): Promise<FileResponse | null> {
		return await lastValueFrom(this.ordersClient.downloadFrontDesign(orderItemId));
	}

	async downloadBackDesign(orderItemId: number): Promise<FileResponse | null> {
		return await lastValueFrom(this.ordersClient.downloadBackDesign(orderItemId));
	}

	async getPdf(orderId: number): Promise<void> {
		const fileResponse = await lastValueFrom(this.pdfReceiptClient.adminGet(orderId));
		const downloadURL = window.URL.createObjectURL(fileResponse?.data!);
		const link = document.createElement('a');
		link.href = downloadURL;
		link.download = `Order-${orderId}.pdf`;
		link.click();
	}
}
