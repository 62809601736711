import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { USelectAddOns, USelectMethod } from '@taradel/web-api-client';
import { SitesService, DetailedUSelectAddOns } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-site-uselect-details',
	templateUrl: './site-uselect-details.component.html',
	styleUrls: ['./site-uselect-details.component.scss']
})
export class SiteUselectDetailsComponent implements OnInit, OnDestroy {

	loading: boolean = true;
	siteId: number = 0;
	uSelectId: number = 0;
	detailedUSelectAddOns: DetailedUSelectAddOns[] = [];
	newUSelectAddOn = new DetailedUSelectAddOns();
	uSelectMethodList: USelectMethod[] = [];
	disableAddBtn: boolean = true;
	currentSite$ = new Subscription();

	@ViewChild('uSelectMethodListInput') uSelectMethodListInput!: ElementRef;

	constructor(private router: Router,
		private route: ActivatedRoute,
		private toastService: ToastService,
		private sitesService: SitesService) { }

	ngOnInit() {
		this.currentSite$ = this.sitesService.currentSite.subscribe(async site => {
			if (site) {
				try {
					this.loading = true;
					this.uSelectId = parseInt(this.route.snapshot.params['uSelectId'], 10);
					this.siteId = site.siteId;
					await this.getAddOnsByUSelectId(this.siteId, this.uSelectId);
					this.uSelectMethodList = await this.sitesService.getAllUSelect();
					this.initializeNewUSelectAddOn();
				}
				catch {
					this.toastService.showError('There was an error while getting the USelect Addons');
				}
				finally {
					this.loading = false;
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.currentSite$.unsubscribe();
	}

	initializeNewUSelectAddOn() {
		this.newUSelectAddOn = new DetailedUSelectAddOns();
		this.newUSelectAddOn.siteId = this.sitesService.currentSiteId;
		this.newUSelectAddOn.uSelectId = this.uSelectId;
		this.newUSelectAddOn.uSelectName = this.sitesService.getUSelectName(this.uSelectId);
		this.newUSelectAddOn.required = false;
	}

	async getAddOnsByUSelectId(siteId: number, uSelectId: number) {
		const uSelectAddOns = await this.sitesService.getSiteUSelectAddOnsByUSelectId(siteId, uSelectId);
		this.detailedUSelectAddOns = await this.sitesService.setListOfSiteUSelectAddOns(uSelectAddOns);
	}

	async updateUSelectAddOnsOrder() {
		if (this.detailedUSelectAddOns.some(addon => !addon.sortOrder)) {
			this.toastService.showError('Please check that each add on has a sort order');
			return;
		}
		try {
			this.loading = true;
			const updatedAddOnsArray: USelectAddOns[] = [];

			this.detailedUSelectAddOns.map(addon => {
				if (addon.siteId === this.siteId) {
					updatedAddOnsArray.push({
							siteId: addon.siteId,
							uSelectId: addon.uSelectId,
							addOnUSelectId: addon.addOnUSelectId,
							required: addon.required,
							sortOrder: addon.sortOrder
						} as USelectAddOns
					);
				}
			});
			if (updatedAddOnsArray.length > 0) {
				await this.sitesService.updateSiteUSelectAddOnsByUSelectId(updatedAddOnsArray);
			}
			else {
				await this.sitesService.removeSiteUSelect(this.siteId, this.uSelectId);
			}

			this.initializeNewUSelectAddOn();
			await this.getAddOnsByUSelectId(this.sitesService.currentSiteId, this.uSelectId);
			this.uSelectMethodList = await this.sitesService.getAllUSelect();
			this.toastService.showSuccess('USelect add-ons successfully saved');
		}
		catch (error: any) {
			if (error.status === 400) {
				this.toastService.showError('USelect add-ons did not save');
			}
			else {
				this.toastService.showError(error.message);
			}
		}
		finally {
			this.loading = false;
		}
	}

	removeSelectedAddOn(index: number) {
		this.detailedUSelectAddOns.splice(index, 1);
	}

	addNewAddOn() {
		this.detailedUSelectAddOns.push(this.newUSelectAddOn);
		this.initializeNewUSelectAddOn();
	}

	selectAddOn(id: string) {
		const selectedMethod = this.uSelectMethodList.find(x => x.uSelectId === parseInt(id));
		this.newUSelectAddOn.addOnUSelectId = selectedMethod!.uSelectId;
		this.newUSelectAddOn.addOnUSelectName = selectedMethod!.name;
	}

	setNewAddOnRequired() {
		if (this.newUSelectAddOn.required === true) {
			this.newUSelectAddOn.required = false;
		}
		else {
			this.newUSelectAddOn.required = true;
		}
	}

	updateAddOnRequired(addOn: DetailedUSelectAddOns) {
		if (addOn!.required === true) {
			addOn.required = false;
		}
		else {
			addOn!.required = true;
		}
	}

	async deleteUSelectFromSite() {
		try {
			this.loading = true;
			await this.sitesService.removeSiteUSelect(this.sitesService.currentSiteId, this.uSelectId);
			this.router.navigateByUrl(`sites/${this.sitesService.currentSiteId}/uselect-addons`);
		}
		catch {
			this.toastService.showError('There was an error deleting the USelect from the site');
		}
		finally {
			this.loading = false;
		}
	}

	getMethod(item: { id: number, name: string }) {
		const matchingUSelect = this.detailedUSelectAddOns.find(x => x.addOnUSelectId === item.id && x.siteId === this.siteId);
		if (matchingUSelect) {
			this.toastService.showError('uSelect add on already has an editable row');
			this.disableAddBtn = true;
			return;
		}
		this.newUSelectAddOn.addOnUSelectId = item.id;
		this.newUSelectAddOn.addOnUSelectName = item.name;
		this.disableAddBtn = false;
	}
}
