import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OrderService } from 'services/order.service';
import { SitesService } from 'services/sites.service';
import { ExtendedOrderItem, OrdersQuery, PaginationQuery, Site, SalesRepTagItem, Printer, Product, ProductsSearch, SiteFilterRequest } from '@taradel/admin-api-client';
import { ToastService } from 'services/toast.service';
import { IdentityService } from 'services/identity.service';
import { ProductsService } from 'services/products.service';
import { PrintersService } from 'services/printers.service';

@Component({
	selector: 'app-order-main',
	templateUrl: './order-main.component.html',
	styleUrls: ['./order-main.component.scss']
})
export class OrderMainComponent implements OnInit {
	loading = false;
	isSearchEnabled = false;
	pageNo = 1;
	pageSize = 20;
	total = 0;
	fromDate?: Date;
	toDate?: Date;
	ordersQuery: OrdersQuery = new OrdersQuery();
	sites: Site[] = [];
	salesRepsList!: SalesRepTagItem[];
	products!: Product[];
	printers!: Printer[];
	orders?: ExtendedOrderItem[];

	@ViewChild('productInput') productInput!: ElementRef;

	constructor(
		private orderService: OrderService,
		private sitesService: SitesService,
		private toastService: ToastService,
		private identityService: IdentityService,
		private productService: ProductsService,
		private printersService: PrintersService) { }

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;
			this.salesRepsList = await this.identityService.getSalesReps();
			const productsSearch = new ProductsSearch;
			productsSearch.name = '';
			productsSearch.pageNo = 1;
			productsSearch.pageSize = 1000;

			this.ordersQuery.fromDate = new Date(Date.now());
			this.ordersQuery.fromDate.setMonth(this.ordersQuery.fromDate.getMonth() - 3);
			this.ordersQuery.printerStatus = '';
			this.fromDate = this.ordersQuery.fromDate;

			[this.salesRepsList, this.products, this.printers, this.sites, ] = await Promise.all([
				this.identityService.getSalesReps(),
				this.productService.getAllProducts(productsSearch),
				this.printersService.getAllPrinters(),
				this.sitesService.getSitesForAdmin({ showInactive: true, adminCheckout: false } as SiteFilterRequest),
				this.search()
			]);
		}
		catch {
			this.toastService.showError('Unable to load orders');
		}
		finally {
			this.loading = false;
		}
	}

	async search() {
		this.loading = true;
		this.pageNo = 1;
		this.ordersQuery.fromDate = this.fromDate;
		this.ordersQuery.toDate = this.toDate;
		this.ordersQuery.pagination = new PaginationQuery({
			pageNo: this.pageNo,
			pageSize: this.pageSize
		});

		try {
			[this.total, this.orders] = await Promise.all([
				this.orderService.getOrdersCount(this.ordersQuery),
				this.orderService.getOrders(this.ordersQuery)
			]);

			if (this.orders.length > 0) {
				this.isSearchEnabled = true;
			}
			else {
				this.isSearchEnabled = false;
			}
		}
		catch (err: any) {
			this.toastService.showError(err.toString());
		}
		this.loading = false;
	}

	async pageChanged() {
		try {
			this.loading = true;
			this.ordersQuery.pagination = new PaginationQuery({
				pageNo: this.pageNo,
				pageSize: this.pageSize
			});
			this.orders = await this.orderService.getOrders(this.ordersQuery);
		}
		catch {
			this.toastService.showError('Unable to load orders');
		}
		finally {
			this.loading = false;
		}
	}

	resetDropdown(inputValue: any) {
		Object.entries(this.ordersQuery).find(([key, value]) => {
			if (value === inputValue) {
				(this.ordersQuery as any)[key] = undefined;
				return true;
			}
			return false;
		});
	}

	getProduct(item: {id?: number, name?: string}) {
	    this.ordersQuery.productId = item.id;
	}

	getDate(value: string) {
		const [year, month, day] = value.split('-');
		return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
	}
}
