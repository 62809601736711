<div *ngIf="uSelects.length && allProducts.length" class="row my-3">
	<div class="col">
		<div class="form-floating">
			<select type="select" [(ngModel)]="selectedList" class="form-select">
				<option *ngFor="let list of productsDisplayList" [ngValue]="list">
					{{ list.name }}
				</option>
			</select>
			<label>Choose a group of products to add</label>
		</div>
		<ng-container *ngIf="selectedList">
			<div class="row">
				<div class="col">
					<hr />
					<ng-container *ngFor="let productsList of selectedList.productsList">
						<strong>{{ getUSelectName(productsList.uSelect) }}</strong>
						<div *ngFor="let product of productsList.productIdsList" class="form-check my-3">
							<input type="checkbox" class="form-check-input" [(ngModel)]="product.selected" id="{{ 'product' + product.baseProductId }}">
							<label class="form-check-label" for="{{ 'product' +  product.baseProductId }}">
								{{ product.name }} ({{ product.baseProductId }})
							</label>
						</div>
					</ng-container>
				</div>
				<div class="row mt-2">
					<div class="col">
						<button role="button" class="btn btn-primary" (click)="openModal(addProductsModal)">
							Add All Selected Products
						</button>
					</div>
				</div>
			</div>

		</ng-container>
	</div>
</div>


<ng-template #addProductsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Products To Be Added</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
				(click)="modal.close('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">The following products will be added:</p>
		<ul>
			<li *ngFor="let product of products">
				{{ product.name }} ({{ product.baseProductId }})
			</li>
		</ul>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link" (click)="closeModal()">Cancel</button>
		<button type="button" class="btn btn-success btn-lg" (click)="emitProductsSelected()" [disabled]="products.length <= 0">Add</button>
	</div>
</ng-template>
