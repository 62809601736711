import { Component, OnInit } from '@angular/core';
import { arrow } from '@popperjs/core';
import { LineItemCategory, ProductOption, ProductOptionCategory } from '@taradel/web-api-client';
import { ProductsService } from 'services/products.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'services/toast.service';
import { SortPipe } from 'components/shared/sort/sort.pipe';



@Component({
  selector: 'app-product-option-categories',
  templateUrl: './product-option-categories.component.html',
  styleUrls: ['./product-option-categories.component.scss']
})
export class ProductOptionCategoriesComponent implements OnInit {

	loading = false;
	showEditCategories = false;
	showAllCategories = true;
	showAddOptionCategory = false;
	showEditOptionCategory = false;
	allProductOptionCategories: ProductOptionCategory[] = [];
	productOptionCategoryToEdit: ProductOptionCategory;
	newCategory: ProductOptionCategory;
	optionToEdit: ProductOption;
	optionToDelete: ProductOption;
	modalRef: any;
	newOption = false;

	optionWidth = 0;
	optionHeight = 0;
	optionQuantity = 0;
	optionWeight = 0;

  constructor(
	  private productsService: ProductsService,
	  private modalService: NgbModal,
	  private toastService: ToastService,
	  private sortPipe: SortPipe
	  ) {
	  this.productOptionCategoryToEdit = new ProductOptionCategory();
	  this.newCategory = new ProductOptionCategory();
	  this.optionToDelete = new ProductOption();
	  this.optionToEdit = new ProductOption();
  }

  async ngOnInit(): Promise<void> {
	try {
		this.loading = true;
		await this.loadOptions();
	}
	catch {
		this.toastService.showError('There was an error loading the product options');
	}
	finally {
		this.loading = false;
	}
  }

  async loadOptions() {
	this.allProductOptionCategories = await this.productsService.getAllProductOptionCategories();
	this.allProductOptionCategories = this.sortPipe.transform(this.allProductOptionCategories, 'asc', 'optCatId');
  }

  addCategory(): void {
	  this.showAllCategories = false;
	  this.showAddOptionCategory = true;
  }

  addOption(): void {
	  this.newOption = true;
	  this.optionToEdit = new ProductOption();
	  this.showAllCategories = false;
	  this.showEditCategories = false;
	  this.showEditOptionCategory = true;
  }

  async saveNewCategory() {
	try {
		this.loading = true;
		await this.productsService.createProductOptionCategory(this.newCategory);
		this.showAddOptionCategory = false;
		this.showEditCategories = false;
		await this.loadOptions();
		this.showAllCategories = true;
	}
	catch {
		this.toastService.showError('There was an error adding the new category');
	}
	finally {
		this.loading = false;
	}
  }


  editCategory(category: ProductOptionCategory): void {
	  this.productOptionCategoryToEdit = category;
	  this.showAllCategories = false;
	  this.showEditCategories = true;
  }

  editOptionCategory(categoryOption: ProductOption): void {
	  this.optionToEdit = categoryOption;
	  this.showEditCategories = false;
	  this.showEditOptionCategory = true;
  }

  showDeleteOptionModal(categoryOption: ProductOption, deleteModal: any) {
		this.modalRef = this.modalService.open(deleteModal);
		this.optionToDelete = categoryOption;
  }

  async deleteCurrentOption() {
	try {
		this.loading = true;
		this.productsService.deleteProductOption(this.optionToDelete.optionId);
		this.closeModal();
		this.showEditCategories = false;
		await this.loadOptions();
		this.showAllCategories = true;

	}
	catch {
		this.toastService.showError('There was an error deleting the option');
	}
	finally {
		this.loading = false;
	}
  }

  closeModal() {
	this.modalService.dismissAll();
	this.modalRef.close();
  }

	calculateWeight(): void {
		this.optionToEdit.weightPerUnit = (this.optionWeight * 1000) / (this.optionWidth * this.optionHeight * this.optionQuantity);
	}

	async saveCurrentOption(): Promise<void> {
		try {
			this.loading = true;
			this.optionToEdit.optCatId = this.productOptionCategoryToEdit.optCatId;
			if (this.newOption) {
				await this.productsService.createProductOption(this.optionToEdit);
				this.newOption = false;
			}
			else {
				await this.productsService.updateProductOption(this.optionToEdit);
			}
		}
		catch (error) {
			this.toastService.showError('There was error saving the product option');
		}
		finally {
			this.loading = false;
			this.showEditOptionCategory = false;
			await this.loadOptions();
			this.showAllCategories = true;
		}

	}

  showAllCategoriesFunction() {
	  this.showEditCategories = false;
	  this.showEditOptionCategory = false;
	  this.showAddOptionCategory = false;
	  this.newOption = false;
	  this.showAllCategories = true;
  }
}
