import { Component, Input, OnInit } from '@angular/core';
import { Printer } from '@taradel/web-api-client';
import { PrintersService } from 'services/printers.service';

@Component({
  selector: 'app-printer-info',
  templateUrl: './printer-info.component.html',
  styleUrls: ['./printer-info.component.scss']
})
export class PrinterInfoComponent implements OnInit {

  @Input() printerId!: number;
  printer?: Printer;

  constructor(public printersService: PrintersService) { }

  async ngOnInit(): Promise<void> {
    if (!isNaN(this.printerId)) {
      this.printer = await this.printersService.getPrinter(this.printerId);
    }
  }

}
