import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogEntry, LogsClient, LogSearch } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LogsService {

	private logsClient: LogsClient;

	constructor(http: HttpClient) {
		this.logsClient = new LogsClient(http, environment.adminApiUrl);
	}

	async getLogs(search: LogSearch): Promise<LogEntry[]> {
		return await lastValueFrom(this.logsClient.getLogs(search));
	}

	async getLogsCount(search: LogSearch): Promise<number> {
		return await lastValueFrom(this.logsClient.getLogsCount(search));
	}
}
