import { Component, OnInit } from '@angular/core';
import { ReportsService } from 'services/reports.service';
import { McNallyQuery } from '@taradel/admin-api-client';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-mcnally-report',
	templateUrl: './mcnally-report.component.html',
	styleUrls: ['./mcnally-report.component.scss']
})
export class McnallyReportComponent implements OnInit {
	loading = false;
	mcNallyStartDate: Date = new Date();
	mcNallyEndDate: Date = new Date();
	mcNallyQuery: McNallyQuery = new McNallyQuery;
	filterByDesign: boolean = false;

	constructor(
		private reportsApiService: ReportsService,
		private toastService: ToastService
	) { }

	ngOnInit(): void {
		this.mcNallyStartDate = new Date(this.mcNallyStartDate.getFullYear(), this.mcNallyStartDate.getMonth(), 1);
		this.mcNallyEndDate = new Date(this.mcNallyEndDate.getFullYear(), this.mcNallyEndDate.getMonth() + 1, 0);
		this.mcNallyQuery.startDate = this.mcNallyStartDate;
		this.mcNallyQuery.endDate = this.mcNallyEndDate;
	}

	async loadMcNally() {
		try {
			this.loading = true;
			this.mcNallyQuery.startDate = this.mcNallyStartDate;
			this.mcNallyQuery.endDate = this.mcNallyEndDate;
			if (this.mcNallyQuery.printerPOFilter === null) {
				this.mcNallyQuery.printerPOFilter = 3;
			}
			if (!this.filterByDesign) {
				this.mcNallyQuery.filterByDesignService = 0;
			}
			else {
				if (this.mcNallyQuery.filterByDesignService === undefined) {
					this.mcNallyQuery.filterByDesignService = 1;
				}
			}
			await this.reportsApiService.getMcNallyReport(this.mcNallyQuery);
		}
		catch {
			this.toastService.showError('There was error downloading the McNally Report');
		}
		finally {
			this.loading = false;
		}
	}

	onDesignChange(value: string) {
		this.mcNallyQuery.filterByDesignService = +value;
	}

	onPOChange(value: string) {
		this.mcNallyQuery.printerPOFilter = +value;;
	}

	getDate(input: string): Date {
		const [year, month, day] = input.split('-');
		return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
	}
}
