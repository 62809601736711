import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';

interface Scripts {
	name: string;
	src: string;
  }

  export const ScriptStore: Scripts[] = [
	{ name: 'designhuddle', src: `https://cdn.designhuddle.com/editor/v1/lib.js`}
  ];

@Injectable({
	providedIn: 'root'
})
export class ScriptInjectorService {

	private scripts: any = {};

	constructor(@Inject(DOCUMENT) private dom: any) {
		ScriptStore.forEach((script: any) => {
			this.scripts[script.name] = {
			  loaded: false,
			  src: script.src
			};
		  });
	}

	loadScript(name: string) {
		return new Promise((resolve, reject) => {
		  if (!this.scripts[name].loaded) {
			let script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = this.scripts[name].src;

			script.onload = () => {
				this.scripts[name].loaded = true;
				resolve({script: name, loaded: true, status: 'Loaded'});
			};

			script.onerror = () => resolve({script: name, loaded: false, status: 'Loaded'});
			document.getElementsByTagName('head')[0].appendChild(script);
		  }
 else {
			resolve({ script: name, loaded: true, status: 'Already Loaded' });
		  }
		});
	  }

	public injectWebScriptHead(scriptUrl: string) {
		try {
			const script = document.createElement('script');
			script.async = true;
			script.src = scriptUrl;
			document.head.appendChild(script);
		}
		catch (ex) {
			console.log('error injecting scriptURL' + scriptUrl);
		}
	}

	public injectWebScriptBody(scriptUrl: string) {
		try {
			const script = document.createElement('script');
			script.async = true;
			script.src = scriptUrl;
			document.body.appendChild(script);
		}
		catch (ex) {
			console.log('error injecting scriptURL' + scriptUrl);
		}
	}

	public injectRawHTMLHead(scriptCode: string) {
		try {
			const scriptInHTML = document.createElement('script');
			scriptInHTML.innerHTML = scriptCode;
			document.head.appendChild(scriptInHTML);
		}
		catch (ex) {
			console.log('error injecting <script> element' + scriptCode);
		}
	}

	public injectRawHTMLBody(scriptCode: string) {
		try {
			const scriptInHTML = document.createElement('script');
			scriptInHTML.innerHTML = scriptCode;
			document.body.appendChild(scriptInHTML);
		}
		catch (ex) {
			console.log('error injecting <script> element' + scriptCode);
		}
	}

	public injectNoScript(scriptCode: string) {
		try {
			const scriptInHTML = document.createElement('noscript');
			scriptInHTML.innerHTML = scriptCode;
			document.body.appendChild(scriptInHTML);
		}
		catch (ex) {
			console.log('error injecting <noscript> element' + scriptCode);
		}
	}
}

