<app-pagefullheader [subTitle]="customer.username + ' (' + customer.customerId + ')'" *ngIf="customer">
</app-pagefullheader>

<app-spinner-with-overlay [showSpinner]="loading">
	<div *ngIf="customer" class="row">
		<div class="col-md-6 card border-0 p-3 m-3">
			<h2 class="mb-2"><strong>Manage Email Aliases</strong></h2>
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Email Alias</th>
						<th>Customer ID</th>
						<th>Organization ID</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let alias of allEmailAliases">
						<td>{{ alias.emailAlias }}</td>
						<td>{{ alias.customerId }}</td>
						<td>{{ alias.organizationId }}</td>
						<td>
							<button class="btn btn-danger" (click)="deleteEmailAlias(alias)" [disabled]="emailAliasFormSubmitting">Delete</button>
						</td>
					</tr>
					<tr *ngIf="!allEmailAliases">
						<td colspan="4">No aliases have been configured.</td>
					</tr>
				</tbody>
			</table>
			<form [formGroup]="addEmailAliasForm">
				<table class="table table-panel mt-3">
					<tbody>
						<tr>
							<td colspan="4"><h3>Add New Alias</h3></td>
						</tr>
						<tr *ngIf="addEmailAliasForm.controls as fc">
							<td *ngIf="emailAliasFormSubmitting" colspan="4" class="position-absolute w-100 mt-3" style="border-bottom-width: 0px;">
								<div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
									<div class="spinner spinner-border"></div>
								</div>
							</td>
							<td>
								<label for="emailAlias" class="form-label">Email Alias<span class="text-red">*</span></label>
								<input id="emailAlias" formControlName="emailAlias" class="form-control" type="email" [disabled]="emailAliasFormSubmitting" [ngClass]="{ 'is-invalid': fc.emailAlias.errors && fc.emailAlias.touched }" />
							</td>
							<td>
								<label for="customerId" class="form-label">Customer ID</label>
								<input id="customerId" formControlName="customerId" class="form-control" type="number" [disabled]="emailAliasFormSubmitting" [ngClass]="{ 'is-invalid': fc.customerId.errors && fc.customerId.touched }" />
							</td>
							<td>
								<label for="organizationId" class="form-label">Organization ID</label>
								<input id="organizationId" formControlName="organizationId" class="form-control" type="number" [disabled]="emailAliasFormSubmitting" [ngClass]="{ 'is-invalid': fc.organizationId.errors && fc.organizationId.touched }" />
							</td>
							<td class="align-bottom">
								<button class="btn btn-primary" (click)="addEmailAlias()" [disabled]="addEmailAliasForm.invalid || emailAliasFormSubmitting">Add</button>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
		<div class="col-md-3 card border-0 p-3 m-3">
			<div class="row">
				<div class="col-md-6">
					<h2 class="mb-2"><strong>Assigned Roles</strong></h2>
					<ul class="list-unstyled">
						<li *ngFor="let role of userRoles" class="pb-2">
							<button class="btn btn-primary w-100" (click)="removeRole(role)">{{role}} <span class="fas fa-times float-end"></span></button>
						</li>
					</ul>
				</div>
				<div class="col-md-6">
					<h2 class="mb-2"><strong>Add New Role</strong></h2>
					<ul class="list-unstyled">
						<li *ngFor="let role of possibleRoles" class="pb-2">
							<button class="btn btn-primary w-100" (click)="addRole(role.id!)">
								{{role.name}} <span class="fas fa-plus float-end"></span>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
