import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquifaxSelectionService } from 'services/equifax-selection.service';
import { EquifaxDataService } from 'services/equifax-data.service';
import { B2bSelectionSummaryComponent } from './b2b-selection-summary/b2b-selection-summary.component';
import { ToastService } from 'services/toast.service';
import { MapDataService } from 'services/map-data.service';
import { DistributionsService } from 'services/distributions.service';
import { AddressTradeArea, EquifaxQuery } from '@taradel/web-api-client';
import { GooglePlaceResult, mapAddressTradeAreaToGooglePlaceAddress } from 'app/lib/google-maps';

@Component({
	selector: 'app-equifax-b2b',
	templateUrl: './equifax-b2b.component.html',
	styleUrls: ['./equifax-b2b.component.scss']
})
export class EquifaxB2bComponent implements OnInit {

	loading: boolean = false;
	customerId!: number;
	distributionId: number = 0;

	@ViewChild(B2bSelectionSummaryComponent) selectionsComponent!: B2bSelectionSummaryComponent;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private toastService: ToastService,
		public distributionsService: DistributionsService,
		public equifaxSelectionService: EquifaxSelectionService,
		public equifaxDataService: EquifaxDataService,
		public mapDataService: MapDataService
	) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(async params => {
			this.customerId = parseInt(params.get('customerId') ?? '0', 10);
			this.distributionId = parseInt(params.get('distributionId') ?? '0', 10);if (this.distributionId > 0) {
				await this.loadFilters();
			}
		});
	}

	async loadFilters(): Promise<void> {
		const distribution = await this.equifaxDataService.getBusinessList(this.distributionId);
		this.equifaxSelectionService.query = Object.assign(new EquifaxQuery(), distribution.filters);
		// For some reason the above JSON.parse is not parsing the radiusQuery as AddressTradeArea, so forcing it here
		const radiusQuery: AddressTradeArea[] = [];
		this.equifaxSelectionService.query.radiusQuery?.forEach(x => radiusQuery.push(Object.assign(new AddressTradeArea(), JSON.parse(JSON.stringify(x)))));
		this.equifaxSelectionService.query.radiusQuery = radiusQuery;
		const addresses: GooglePlaceResult[] = this.equifaxSelectionService.query.radiusQuery?.map(mapAddressTradeAreaToGooglePlaceAddress) ?? [];
		this.equifaxSelectionService.addressUpdates.next(addresses);
		this.equifaxSelectionService.queryUpdates.next(this.equifaxSelectionService.query);
	}

	async completeSaveList(name: string) {
		this.loading = true;
		try {
			const distributionId = await this.equifaxDataService.saveBusinessList(this.customerId, name, this.selectionsComponent.query);
			await this.router.navigateByUrl(`/customers/${this.customerId}/distributions/${distributionId}`);
		}
		catch (error) {
			this.toastService.showError('There was a problem saving the list', 'Load Error');
			console.log(error);
		}
		finally {
			this.loading = false;
		}
	}

}
