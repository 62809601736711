<app-customer-header [customerId]="customerId"></app-customer-header>
<app-spinner-with-overlay [showSpinner]="loading">
	<div *ngIf="customer" class="card p-3">
		<p class="text-end">
			<a [routerLink]="['/customers', customerId, 'addresses', 'new-address']" class="btn btn-success btn-rounded px-4 rounded-pill">
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add New Address
			</a>
		</p>

		<!-- filters! -->
		<div *ngIf="isSearchEnabled" class="card mb-3">
			<div class="card-body">
				<h5 class="card-title">Search</h5>

				<form [formGroup]="addressSearchForm" (ngSubmit)="searchAddresses()">
					<div class="row align-items-center">
						<div class="col">
							<div class="form-floating">
								<input type="text" id="address1" class="form-control"
									formControlName="address1" placeholder="Address 1">
								<label for="address1">Address 1</label>
							</div>
						</div>
						<div class="col">
							<div class="form-floating">
								<input type="text" id="city" class="form-control"
									formControlName="city" placeholder="City">
								<label for="city">City</label>
							</div>
						</div>
						<div class="col">
							<app-state-list [isDisabled]="false" [state]="state" [isUS]="countryCode === 'US'" (selected)="stateSelected($event)"></app-state-list>
						</div>
						<div class="col">
							<div class="form-floating">
								<input type="text" id="zipCode" class="form-control"
									formControlName="zipCode" placeholder="Zip Code">
								<label for="zipCode">Zip Code</label>
							</div>
						</div>
					</div>
					<div class="row mt-2">
						<div class="col-auto">
							<button class="btn btn-primary" [disabled]="loading">Search</button>
						</div>
						<div *ngIf="filtered" class="col-auto">
							<button class="btn btn-danger" (click)="resetFilter()">Reset Filter</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="d-flex justify-content-between p-2" *ngIf="total > 0">
			<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
				[pageSize]="pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
				(pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Choose Items per Page</label>
			</div>
		</div>

		<div class="table-responsive" *ngIf="total > 0">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Company</th>
						<th>Phone Number</th>
						<th>Email Address</th>
						<th>Address</th>
						<th>Default Address Type</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let address of customerAddresses">
						<td>
							{{ address.customerAddress.firstName }}
						</td>
						<td>
							{{ address.customerAddress.lastName }}
						</td>
						<td>
							{{ address.customerAddress.company }}
						</td>
						<td>
							{{ address.customerAddress.phoneNumber }}
						</td>
						<td>
							{{ address.customerAddress.emailAddress }}
						</td>
						<td>
							{{ address.customerAddress.address1 }}<span *ngIf="!!address.customerAddress.address2">, {{ address.customerAddress.address2 }}</span><br />
							{{ address.customerAddress.city }}, {{ address.customerAddress.state }} {{ address.customerAddress.zipCode }}
						</td>
						<td>
							<ng-container *ngFor="let type of address.defaultTypes">
								<div class="d-flex flex-row align-items-center">
										Default {{ type }} address
										<button type="button" class="btn" data-toggle="tooltip" data-placement="top" title="Remove default" (click)="openRemoveDefaultModal(removeDefaultModal, address.customerAddress, type)">
											<i class="fas fa-times-circle text-danger"></i>
										</button>
								</div>
							</ng-container>
						</td>
						<td>
							<ul class="list-inline">
								<li class="list-inline-item"><button class="btn btn-danger btn-sm"
										(click)="openDeleteModal(deleteModal, address.customerAddress.addressId)">Delete Address</button></li>
							</ul>
							<ul *ngIf="!address.hasAllDefaults" class="list-inline">
								<li class="list-inline-item"><button class="btn btn-success btn-sm"
										(click)="openSetDefaultModal(setDefaultModal, address)">Set As Default</button></li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<app-alert-box *ngIf="total === 0 && !loading" alertMode="info">
			No addresses found in the customer's address book.
		</app-alert-box>

		<div class="d-flex justify-content-between p-2" *ngIf="total > 0">
			<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
				[pageSize]="pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
				(pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Choose Items per Page</label>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>

<ng-template #deleteModal>
	<app-confirm-modal title="Delete confirmation" confirmText="delete" (confirmAction)="deleteAddress()"
		(closeModal)="closeModal()">
		<p>Are you sure you want to delete:</p>
			<small>
				<ng-container *ngIf="addressToUpdate?.firstName !== ''">
					{{ addressToUpdate?.firstName }} {{ addressToUpdate?.lastName }} <br/>
				</ng-container>
				<ng-container *ngIf="addressToUpdate?.company !== ''">
					{{ addressToUpdate?.company }} <br />
				</ng-container>
				<ng-container *ngIf="addressToUpdate?.phoneNumber !== ''">
					{{ addressToUpdate?.phoneNumber }} <br />
				</ng-container>
				<ng-container *ngIf="addressToUpdate?.emailAddress !== ''">
					{{ addressToUpdate?.emailAddress }} <br />
				</ng-container>
				{{ addressToUpdate?.address1 }} {{ addressToUpdate?.address2 }} <br />
				{{ addressToUpdate?.city }}, {{ addressToUpdate?.state }} {{ addressToUpdate?.zipCode }}
			</small>
	</app-confirm-modal>
</ng-template>

<ng-template #setDefaultModal let-modal>
	<app-confirm-modal title="Select Default Address Type" confirmText="confirm" (confirmAction)="setDefault()" (closeModal)="closeModal()" [disabled]="typeToUpdate === undefined">
		<div class="form-floating">
            <select class="form-select" (change)="setNewDefault($any($event).target.value)">
                <option [value]="undefined" selected disabled>Address Type</option>
                <option *ngFor="let type of addressTypes" [value]="type">{{ type }}</option>
            </select>
        </div>
    </app-confirm-modal>
</ng-template>

<ng-template #removeDefaultModal let-modal>
	<app-confirm-modal confirmText="confirm" (confirmAction)="removeDefault()" (closeModal)="closeModal()">
		<p>By clicking confirm, the following address will no longer be this customer's default {{ typeToUpdate }} address:</p>
        <p>
			<small>
				<ng-container *ngIf="addressToUpdate?.firstName !== ''">
					{{ addressToUpdate?.firstName }} {{ addressToUpdate?.lastName }} <br/>
				</ng-container>
				<ng-container *ngIf="addressToUpdate?.company !== ''">
					{{ addressToUpdate?.company }} <br />
				</ng-container>
				<ng-container *ngIf="addressToUpdate?.phoneNumber !== ''">
					{{ addressToUpdate?.phoneNumber }} <br />
				</ng-container>
				<ng-container *ngIf="addressToUpdate?.emailAddress !== ''">
					{{ addressToUpdate?.emailAddress }} <br />
				</ng-container>
				{{ addressToUpdate?.address1 }} {{ addressToUpdate?.address2 }} <br />
				{{ addressToUpdate?.city }}, {{ addressToUpdate?.state }} {{ addressToUpdate?.zipCode }}
			</small>
        </p>
	</app-confirm-modal>
</ng-template>
