import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environment';
import { Receipt, ShoppingClient, ShoppingQuote, ReceiptClient, PlaceOrderRequest, PaymentRequest, OrdersClient, OrderInfo, QuoteSearchResponse,
	QuoteSearchQuery, UpdateQuoteExpirationQuery } from '@taradel/admin-api-client';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ShoppingQuoteService {
	private readonly shoppingClient: ShoppingClient;
	private readonly receiptClient: ReceiptClient;
	private readonly ordersClient: OrdersClient;
	constructor(http: HttpClient) {
		this.shoppingClient = new ShoppingClient(http, environment.adminApiUrl);
		this.receiptClient = new ReceiptClient(http, environment.adminApiUrl);
		this.ordersClient = new OrdersClient(http, environment.adminApiUrl);
	}

	async getQuoteByGuid(customerId: number, quoteId: string): Promise<ShoppingQuote> {
		return await lastValueFrom(this.shoppingClient.getShoppingQuote(customerId, quoteId));
	}

	async getQuoteLineItems(quoteId: string): Promise<Receipt> {
		return await lastValueFrom(this.receiptClient.getShoppingQuoteLineItems(quoteId));
	}

	async placeOrder(quoteId: string, paymentRequest: PaymentRequest): Promise<number> {
		return await lastValueFrom(this.shoppingClient.placeOrder(new PlaceOrderRequest({
			quoteId: quoteId,
			paymentRequest: new PaymentRequest({
				useBalance: paymentRequest.useBalance,
				paymentType: paymentRequest.paymentType,
				customerProfileId: paymentRequest.customerProfileId ?? undefined,
				customerPaymentProfileId: paymentRequest.customerPaymentProfileId ?? undefined,
				creditCardPaymentRequest: paymentRequest.creditCardPaymentRequest,
				achPaymentRequest: paymentRequest.achPaymentRequest,
				withTaradelFinancing: paymentRequest.withTaradelFinancing,
				poNumber: paymentRequest.poNumber ?? undefined,
				creditKeyOrderKey: paymentRequest.creditKeyOrderKey ?? undefined
			}),
		})));
	}

	async getOrderInfo(orderId: number): Promise<OrderInfo> {
		return await lastValueFrom(this.ordersClient.getOrderInfo(orderId));
	}

	async getOrderShoppingQuote(orderId: number): Promise<ShoppingQuote> {
		return await lastValueFrom(this.shoppingClient.getOrderShoppingQuote(orderId));
	}

	async searchCustomerQuotes(search: QuoteSearchQuery): Promise<QuoteSearchResponse[]> {
		return await lastValueFrom(this.shoppingClient.searchCustomerShoppingQuotes(search));
	}

	async getCustomerQuotesCount(search: QuoteSearchQuery): Promise<number> {
		return await lastValueFrom(this.shoppingClient.getCustomerQuotesSearchCount(search));
	}

	async getQuoteCheckoutLink(quoteId: string): Promise<string> {
		return await lastValueFrom(this.receiptClient.generateQuoteCheckoutLink(quoteId));
	}

	async updateShoppingQuoteExpiration(quoteId: string,expirationDate: Date): Promise<void> {
		await lastValueFrom(this.shoppingClient.updateShoppingQuoteExpiration(new UpdateQuoteExpirationQuery({
			expirationDate,
			quoteId
		})));
	}
}
