import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductOptionCategory } from '@taradel/web-api-client';
import { ProductsService } from 'services/products.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-add-option-category',
	templateUrl: './add-option-category.component.html',
	styleUrls: ['./add-option-category.component.scss']
})
export class AddOptionCategoryComponent {

	loading = false;
	submitted = false;
	categoryForm: UntypedFormGroup;
  	constructor(
		public formBuilder: UntypedFormBuilder,
		private router: Router,
		private productsService: ProductsService,
		private toastService: ToastService) {

		this.categoryForm = this.formBuilder.group({
			name: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.maxLength(50)
			])),
			description: new UntypedFormControl(''),
			displayType: new UntypedFormControl('')
		});
	}

	get categoryControls() { return this.categoryForm.controls; }

	async saveNewCategory() {
		this.submitted = true;
		if (!this.categoryForm.valid) {
			return;
		}

		try {
			this.loading = true;

			const newCategory = new ProductOptionCategory({
				description: this.categoryControls.description.value,
				displayType: this.categoryControls.displayType.value,
				name: this.categoryControls.name.value,
				optCatId: 0
			});
			const optCatId = await this.productsService.createProductOptionCategory(newCategory);
			await this.router.navigate(['/option-categories', optCatId]);
		}
		catch {
			this.toastService.showError('There was an error adding the category');
		}
		finally {
			this.loading = false;
		}

	}
}
