import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AddressTradeArea, TradeAreaType } from '@taradel/web-api-client';
import { GooglePlaceResult } from 'app/lib/google-maps';
import { Subscription } from 'rxjs';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, AfterViewInit, OnDestroy {

	loading = false;
	selections?: AddressTradeArea[];
	selectedAddress?: GooglePlaceResult;
	selectedAddresses?: GooglePlaceResult[];
	radius = 5;
	radiusVal: number =  5;
	radiusMin: number = 5;
	radiusOptions: Options = {
		floor: 1,
		ceil: 100
	};
	radiusType = 'Miles';
	addressText!: string;
	tradeAreaType = TradeAreaType;
	@Input() disabled = false;
	@Input() selectionService!: any;
	@Input() queryField!: any;
	@Output() updateAddress = new EventEmitter<void>();
	@ViewChild('placesRef') placesRef!: NgxGpAutocompleteDirective;
	queryUpdatesSubscription?: Subscription;
	addressUpdatesSubscription?: Subscription;

	constructor(
		private toastService: ToastService
	) {}

	ngOnInit(): void {
		this.queryUpdatesSubscription = this.selectionService.queryUpdates.subscribe((query: any) => this.selections = query[this.queryField]);
		this.addressUpdatesSubscription = this.selectionService.addressUpdates.subscribe((addresses: GooglePlaceResult[]) => {
			this.selectedAddresses = addresses;
			this.updateAddress.emit();
		});

	}
	ngAfterViewInit() {

		this.placesRef.options.types = ['address'];
		this.placesRef.options.componentRestrictions = {
			country: 'US',
		} as google.maps.places.ComponentRestrictions;
		this.placesRef.reset();
	}

	ngOnDestroy() {
		this.queryUpdatesSubscription?.unsubscribe();
		this.addressUpdatesSubscription?.unsubscribe();
	}
	removeSelection(index: number) {
		const address = this.selectedAddresses![index];
		const tradeArea = this.selections![index!];
		this.selectionService.removeAddressTradeArea(this.queryField, address.place_id, tradeArea);
	}
	clearSelectedAddress() {
		this.selectedAddress = undefined;
	}
	handleAddressChange(address: GooglePlaceResult) {
		this.selectedAddress = address;
	}
	radiusChanged() {
		if (this.selectedAddress !== undefined) {
			this.handleAddressChange(this.selectedAddress);
		}
	}
	addAddress() {
		if (this.selectedAddress !== undefined) {
			let streetAddress = '';
			let city = '';
			let state = '';
			let zipCode = '';
			this.selectedAddress?.address_components?.forEach(x => {
				switch (x.types[0]) {
					case 'street_number': {
						streetAddress = x.long_name;
						break;
					}
					case 'route': {
						streetAddress = streetAddress + ' ' + x.long_name;
						break;
					}
					case 'locality': {
						city = x.long_name;
						break;
					}
					case 'administrative_area_level_1': {
						state = x.short_name;
						break;
					}
					case 'postal_code': {
						zipCode = x.long_name;
						break;
					}
				}
			});
			try {
				this.loading = true;
				this.selectionService.setAddressTradeArea(this.queryField, this.selectedAddress!, new AddressTradeArea({
					address: streetAddress,
					zipCode,
					latitude: this.selectedAddress.geometry?.location?.lat()!,
					longitude: this.selectedAddress.geometry?.location?.lng()!,
					areaSize: this.radiusVal,
					areaType: (this.radiusType === 'Miles' ? TradeAreaType.Radius : TradeAreaType.DriveTime)
				}));
			}
			catch {
				this.toastService.showError('There was an error getting the latitude & longitude');
			}
			finally {
				this.loading = false;
			}

		}
		this.addressText = '';
	}
}
