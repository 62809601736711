<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card p-3 mb-3">
		<div class="table-responsive">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Role Name</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let role of roles">
						<td>{{ role.name }}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="card mt-3" style="max-width:18rem">
			<div class="card-body">
				<h5 class="card-title">Add New Role</h5>
				<div class="form-floating">
					<input [(ngModel)]="newRoleName" type="text" class="form-control" id="newRoleName" placeholder="Role Name" />
					<label for="newRoleName">Role Name</label>
				</div>
				<div class="card-body">
					<button class="btn btn-primary" (click)="createRole()">Add Role</button>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>

