import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer, PersonalizedUploadListResponse, PMCertifyUploadedListRequest, PMIdentifyListRequest, PMIdentifyListResponse, PMSaveUploadedListQuery, UploadedListCountsDetail } from '@taradel/web-api-client';
import { UploadedListFieldMappingComponent } from '@taradel/white-label-common';
import { PmDataService } from 'services/pm-data.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-upload-your-list',
  templateUrl: './upload-your-list.component.html',
  styleUrls: ['./upload-your-list.component.scss']
})
export class UploadYourListComponent implements OnInit {

	showSpinner = false;
	customerId = 0;
	customer!: Customer;
	hideUpload = false;
	hideIdentify = true;
	disableCertify = true;
	validateCertifyDetail = {
		hasNamePair: false,
		hasAddress: false,
		hasCity: false,
		hasState: false,
		hasZip: false,
	};
	hideSaveList = true;
	allColumnsFromList: string[] = [];
	file?: File;
	uploadListresponse?: PersonalizedUploadListResponse;
	identifyListResponse?: PMIdentifyListResponse;
	certifyListResponse?: UploadedListCountsDetail;
	listName = '';

	@ViewChild(UploadedListFieldMappingComponent) uploadedListFieldMappingComponent!: UploadedListFieldMappingComponent;

	constructor(
		private route: ActivatedRoute,
		private pmDataService: PmDataService,
		private router: Router,
		private toastService: ToastService
	) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			this.customerId = parseInt(params.get('customerId') ?? '0', 10);
		});
	}
	onFileDropped(files: any) {
		if (files && files.length > 0) {
			this.file = files[0];
		}
	}

	fileHandler($event: any) {
		const files = $event.files;
		this.onFileDropped(files);
	}

	goToUploadSlide() {
		this.uploadedListFieldMappingComponent.autoSelectedColumns = [];
		this.hideUpload = false;
		this.hideIdentify = true;
	}

	async uploadYourlist() {
		try {
			this.showSpinner = true;
			this.uploadListresponse = await this.pmDataService.storeUploadedList({
				fileName: this.file!.name,
				data: this.file!
			});

			await this.goToIdentifySlide();
		}
		catch (err) {
			this.toastService.showError('There was an error uploading the list');
			console.error(err);
		}
		finally {
			this.showSpinner = false;
		}
	}

	async goToIdentifySlide() {
		this.showSpinner = true;
		this.uploadedListFieldMappingComponent.selectedMap = [];
		const filenameSplit = this.file?.name.split('.')!;
		const extension = filenameSplit[filenameSplit?.length - 1];
		this.identifyListResponse = await this.pmDataService.identifyUploadedListColumns(new PMIdentifyListRequest({
			extension: extension,
			fileName: this.uploadListresponse?.filename
		}));
		this.uploadedListFieldMappingComponent.records = this.identifyListResponse.records!;
		this.allColumnsFromList = Object.keys(this.identifyListResponse.records![0]);
		this.uploadedListFieldMappingComponent.initialColumns = this.allColumnsFromList;
		this.uploadedListFieldMappingComponent.autoSelectColumns();
		const { isValid, validationDetail } = this.uploadedListFieldMappingComponent.validateRequiredColumns();
		this.disableCertify = !isValid;
		this.validateCertifyDetail = validationDetail;
		this.hideUpload = true;
		this.hideSaveList = true;
		this.hideIdentify = false;
		this.showSpinner = false;
	}

	columnSelected() {
		const { isValid, validationDetail } = this.uploadedListFieldMappingComponent.validateRequiredColumns();
		this.disableCertify = !isValid;
		this.validateCertifyDetail = validationDetail;
	}

	async certifyList() {
		try {
			this.showSpinner = true;

			this.certifyListResponse = await this.pmDataService.certifyUploadedList(new PMCertifyUploadedListRequest({
				guid: this.identifyListResponse?.guid,
				hasHeader: !this.uploadedListFieldMappingComponent.headerAutoDetected && this.uploadedListFieldMappingComponent.hasHeader,
				columnMap: this.uploadedListFieldMappingComponent.selectedMap
			}));
			this.hideIdentify = true;
			this.hideSaveList = false;
			this.listName = 'New List: ' + new Date().toLocaleString();
		}
		catch {
			this.toastService.showError('There was an error certifying the list');
		}
		finally {
			this.showSpinner = false;
		}
	}

	async saveList() {
		try {

			this.showSpinner = true;
			const distributionId = await this.pmDataService.saveUploadedList(this.customerId, new PMSaveUploadedListQuery({
				guid: this.identifyListResponse?.guid,
				fileName: this.file?.name,
				name: this.listName
			}));
			await this.router.navigateByUrl(`/customers/${this.customerId}/distributions/${distributionId}`);
		}
		catch {
			this.toastService.showError('There was an error saving the list');
		}
		finally {
			this.showSpinner = false;
		}
	}
}
