import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { USelectMethod } from '@taradel/web-api-client';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-uselect-search',
  templateUrl: './uselect-search.component.html',
  styleUrls: ['./uselect-search.component.scss']
})

export class UselectSearchComponent {

  @Input() uSelect?: USelectMethod;
  @Input() array: USelectMethod[] = [];
  @Output() selectedMethod = new EventEmitter<{id: number, name: string}>();

  @ViewChild('instance', {static: true}) instance: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search: OperatorFunction<string, readonly USelectMethod[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term =>
        term === '' ? this.array
        : this.array.filter(v => v.name!.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
      )
    );
  };

  formatter = (x: {name: string}) => x.name;

  select(event: any) {
    this.selectedMethod.emit({id: event.item.uSelectId, name: event.item.name});
  }
}
