<ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>
<div class="mb-3 row">
    <div class="col">
        <label for="designTypeListInput">Choose a Design Type</label>
        <input id="designTypeInput" #designTypeInput type="text" class="form-control" [ngbTypeahead]="search" (focus)="focus$.next($any($event).target.value)"
            [(ngModel)]="designType" (selectItem)="select($event)" #instance="ngbTypeahead" [resultTemplate]="rt" [inputFormatter]="formatter"
        />
    </div>
</div>
