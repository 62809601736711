<app-pagefullheader></app-pagefullheader>

<div class="card border-0 p-0">
	<div class="card-header">
		<ul class="nav nav-tabs card-header-tabs" role="tablist">
			<li class="nav-item">
				<button class="nav-link active" aria-current="true" data-bs-toggle="tab"
					data-bs-target="#waitingOnDesign">Waiting on Design
					<i class="far fa-spinner fa-spin text-blue" *ngIf="!eddmStatusWaitingDesign"></i>
					<span *ngIf="eddmStatusWaitingDesign" class="text-blue">({{eddmStatusWaitingDesign.length | number}})</span>
				</button>
			</li>
			<li class="nav-item">
				<button class="nav-link" data-bs-toggle="tab" data-bs-target="#waitingClientFiles">Waiting Client Files
					<i class="far fa-spinner fa-spin text-blue" *ngIf="!eddmStatusWaitingCustFiles"></i>
					<span *ngIf="eddmStatusWaitingCustFiles" class="text-blue">({{eddmStatusWaitingCustFiles.length | number}})</span>
				</button>
			</li>
			<li class="nav-item">
				<button class="nav-link" data-bs-toggle="tab" data-bs-target="#missingDistribution">Missing Distribution
					<i class="far fa-spinner fa-spin text-blue" *ngIf="!eddmStatusMissingDist"></i>
					<span *ngIf="eddmStatusMissingDist" class="text-blue">({{eddmStatusMissingDist.length | number}})</span>
				</button>
			</li>
			<li class="nav-item">
				<button class="nav-link" data-bs-toggle="tab" data-bs-target="#readyToCreatePO">Ready to Create Purchase
					Order
					<i class="far fa-spinner fa-spin text-blue" *ngIf="!eddmStatusReady"></i>
					<span *ngIf="eddmStatusReady" class="text-blue">({{eddmStatusReady.length | number}})</span>
				</button>
			</li>
		</ul>
	</div>
	<div class="tab-content">
		<div class="tab-pane fade show active card-body" id="waitingOnDesign">
			<app-spinner-with-overlay [showSpinner]="!eddmStatusWaitingDesign">
				<div class="ratio ratio-21x9" *ngIf="!eddmStatusWaitingDesign"></div>
				<app-eddm-status-table *ngIf="eddmStatusWaitingDesign" [eddmStatus]="eddmStatusWaitingDesign">
				</app-eddm-status-table>
			</app-spinner-with-overlay>
		</div>
		<div class="tab-pane fade card-body" id="waitingClientFiles">
			<app-spinner-with-overlay [showSpinner]="!eddmStatusWaitingCustFiles">
				<div class="ratio ratio-21x9" *ngIf="!eddmStatusWaitingCustFiles"></div>
				<app-eddm-status-table *ngIf="eddmStatusWaitingCustFiles" [eddmStatus]="eddmStatusWaitingCustFiles">
				</app-eddm-status-table>
			</app-spinner-with-overlay>
		</div>
		<div class="tab-pane fade card-body" id="missingDistribution">
			<app-spinner-with-overlay [showSpinner]="!eddmStatusMissingDist">
				<div class="ratio ratio-21x9" *ngIf="!eddmStatusMissingDist"></div>
				<app-eddm-status-table *ngIf="eddmStatusMissingDist" [eddmStatus]="eddmStatusMissingDist">
				</app-eddm-status-table>
			</app-spinner-with-overlay>
		</div>
		<div class="tab-pane fade card-body" id="readyToCreatePO">
			<app-spinner-with-overlay [showSpinner]="!eddmStatusReady">
				<div class="ratio ratio-21x9" *ngIf="!eddmStatusReady"></div>
				<app-eddm-status-table *ngIf="eddmStatusReady" [eddmStatus]="eddmStatusReady"></app-eddm-status-table>
			</app-spinner-with-overlay>

		</div>
	</div>
</div>
