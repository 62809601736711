<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="row mb-3 ms-auto">
			<a href="#" class="btn btn-success btn-rounded px-4 rounded-pill" [routerLink]="['add']"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Print And Ship Category</a>
		</div>
		<div class="card mb-3">
			<div class="card-body">
				<h5 class="card-title">Search</h5>

				<div class="row row-cols-lg-auto g-3 align-items-center">
					<div class="col-12">
						<div class="form-floating mb-3">
							<input type="text" id="categorySearchBox" class="form-control" [(ngModel)]="categorySearch.name"
								placeholder="Search by Category Name" />
							<label for="categorySearchBox">Search by Category Name</label>
						</div>
					</div>

					<div class="col-12">
						<button class="btn btn-primary" [disabled]="loading" (click)="search()">Search</button>
					</div>
				</div>
			</div>
		</div>

		<app-spinner-with-overlay [showSpinner]="loading">
			<div class="d-flex justify-content-between p-2" *ngIf="isSearchEnabled">
				<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
					[pageSize]="categorySearch.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
					(pageChange)="pageChanged()"></ngb-pagination>
				<div class="form-floating">
					<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()"
						aria-label="Items per page">
						<option [ngValue]="20">20 items per page</option>
						<option [ngValue]="50">50 items per page</option>
						<option [ngValue]="100">100 items per page</option>
					</select>
					<label for="pageSize">Items per page</label>
				</div>
			</div>

			<div class="table-responsive">
				<table class="table table-hover table-panel">
					<thead>
						<tr>
							<th>Id</th>
							<th>Category Name</th>
							<th>Channel Prefix</th>
							<th>&nbsp;</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let category of categories">
							<td>
								{{category.categoryId}}
							</td>
							<td>
								{{category.name}}
							</td>
							<td>
								{{category.channelPrefix}}
							</td>
							<td>
								<a routerLink="/printandship/categories/{{ category.categoryId }}" class="dropdown-item"><i class="fa fa-edit"></i></a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="d-flex justify-content-between p-2" *ngIf="isSearchEnabled">
				<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
					[pageSize]="categorySearch.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
					(pageChange)="pageChanged()"></ngb-pagination>
				<div class="form-floating">
					<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()"
						aria-label="Items per page">
						<option [ngValue]="20">20 items per page</option>
						<option [ngValue]="50">50 items per page</option>
						<option [ngValue]="100">100 items per page</option>
					</select>
					<label for="pageSize">Items per page</label>
				</div>
			</div>
		</app-spinner-with-overlay>
	</div>
</app-spinner-with-overlay>
