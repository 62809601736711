export interface ISparkLineData {
	x: Date | number;
	y: number;
}

export class SparkLineData implements ISparkLineData {
	public x: Date | number;
	public y: number;
	constructor(x: Date | number, y: number) {
		this.x = x;
		this.y = y;
	}
}
