import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { USelectDesignOption, USelectDesignType, USelectMethod } from '@taradel/web-api-client';
import { Subscription } from 'rxjs';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-add-uselect-design',
  templateUrl: './add-uselect-design.component.html',
  styleUrls: ['./add-uselect-design.component.scss']
})
export class AddUselectDesignComponent implements OnInit, OnDestroy {

	allUSelectMethods: USelectMethod[] = [];
	newUSelectForm: UntypedFormGroup;
	siteDesignOptions: USelectDesignOption[] = [];
	designTypes: USelectDesignType[] = [];
	loading: boolean = false;
	siteId: number = 0;
	currentSite$ = new Subscription();

	constructor(private sitesService: SitesService,
	  private route: ActivatedRoute,
	  private formBuilder: UntypedFormBuilder,
	  private toastService: ToastService,
	  private router: Router) {
	  this.newUSelectForm = this.formBuilder.group({
		uSelectId: [{value: '', disabled: true}],
		uSelectName: ['', Validators.compose([Validators.required])],
		productDesignTypeId: ['', Validators.compose([Validators.required])]
	  });
	}

	ngOnInit(): void {
	  this.loading = true;
	  this.siteId = parseInt(this.route.snapshot.params['siteId'], 10);
	  this.currentSite$ = this.sitesService.currentSite.subscribe(async site => {
			if (site) {
				try {
					await this.getAvailableUSelects(site!.siteId);
					this.siteDesignOptions = await this.sitesService.getSiteDesignOptions(site!.siteId);
				}
				catch {
					this.toastService.showError('There was an error getting site design options');
				}
				finally {
					this.loading = false;
				}
			}
	  });

	  this.designTypes = Object.values(USelectDesignType);
	}

	ngOnDestroy(): void {
	  this.currentSite$.unsubscribe();
	}

	async getAvailableUSelects(siteId: number) {
	  let siteUSelectIds: any = [];
	  const siteProducts = await this.sitesService.getSiteProducts(siteId);
	  siteProducts.forEach(product => {
		product.baseProduct?.uSelectConfigurations?.forEach(config => {
		  siteUSelectIds.push(config.uSelectId);
		});
	  });
	  this.allUSelectMethods = await this.sitesService.getAllUSelect();
	  this.allUSelectMethods = this.allUSelectMethods.filter(x => siteUSelectIds.includes(x.uSelectId));
	}

	get formControls() {
	  return this.newUSelectForm.controls;
	}

	async addNewUSelect() {
		try {
			this.loading = true;
			const newUSelect = {
				siteId: this.sitesService.currentSiteId,
				uSelectId: this.newUSelectForm.controls.uSelectId.value,
				productDesignTypeId: this.newUSelectForm.controls.productDesignTypeId.value
			} as USelectDesignOption;
			await this.sitesService.addSiteUSelectDesignOption(newUSelect);
			this.newUSelectForm.controls.uSelectId.setValue('');
			this.newUSelectForm.controls.uSelectName.setValue('');
			this.router.navigateByUrl(`sites/${this.sitesService.currentSiteId}/uselect-designs/${newUSelect.uSelectId}`);
		}
		catch (error: any) {
			if (error.status === 400) {
				this.toastService.showError('This USelect design already exists for this site');
			}
			else {
				this.toastService.showError('There was an error adding the USelect design');
			}
		}
		finally {
			this.loading = false;
		}
	}

	getMethod(item: {id: number, name: string}) {
	  const matchingUSelect = this.siteDesignOptions.find(x => x.siteId === this.siteId && x.uSelectId === item.id);
	  if (matchingUSelect) {
		this.toastService.showError('uSelect already exists for site');
		return;
	  }
	  this.newUSelectForm.controls.uSelectId.setValue(item.id);
	  this.newUSelectForm.controls.uSelectName.setValue(item.name);
	}
}
