<app-spinner-with-overlay [showSpinner]="orderLayoutService.loadingOrder || loading">
	<app-alert-box  *ngIf="!orderLayoutService.loading && !orderLayoutService.selectedOrder" alertMode="danger">
		No attribution activity has been recorded for the selected order.
	</app-alert-box>

	<div *ngIf="!orderLayoutService.loading && orderLayoutService.selectedOrder">
		<div class="text-end my-2">
			<button class="btn btn-primary" (click)="exportPdf()" [disabled]="exportingPdf || !(orderAttribution?.hasActivity ?? false)"><i *ngIf="exportingPdf" class="fas fa-cog fa-spin"></i>Export as PDF</button>
		</div>
		<app-order-dashboard [orderId]="orderLayoutService.orderId" [isCanada]="isCanada" [content]="content" [orderAttribution]="orderAttribution" *ngIf="orderAttribution"></app-order-dashboard>
	</div>
</app-spinner-with-overlay>
