<app-customer-header [customerId]="customerId"></app-customer-header>
<app-spinner-with-overlay [showSpinner]="showSpinner">
	<div class="row">
		<!-- left pane -->
		<div class="col-md-3">
			<ng-container *ngIf="(customerId > 0 && !!customer) && (!!siteId && siteId > 0)">
				<app-info-panel [customer]="customer" [site]="site" [distribution]="distribution"></app-info-panel>
			</ng-container>
		</div>

		<div class="col-md-9">
			<div class="row">
				<div class="col">
					<div class="card">
						<div class="card-body">

							<!-- cart creation -->
							<form [formGroup]="cartForm">
								<div formArrayName="products">
									<div *ngFor="let product of products?.controls; let i=index">
										<div [formGroupName]="i">
											<div class="form-floating mb-3">
												<select type="select" formControlName="categoryId" (change)="selectCategory(i)" class="form-select">
													<option *ngFor="let x of printAndShipCategories" value="{{x.categoryId}}">
														{{x.name}}
													</option>
												</select>
												<label for="productId">Choose Category</label>
											</div>
											<div *ngIf="categorySelected" class="form-floating mb-3">
												<select type="select" formControlName="productId" (change)="productSelected(i)" class="form-select">
													<option [ngValue]="null" disabled>Select a product</option>
													<option *ngFor="let x of allowedProducts" value="{{x.baseProductId}}">
														{{getProductNameWithOrderDate(x)}}
													</option>
												</select>
												<label for="productId">Choose Product</label>
											</div>
											<div *ngIf="productAdded && getProductOptions(i).length > 0">
												<div><b>Product Options</b></div>
												<div class="row">
													<div class="col" formGroupName="options" *ngFor="let option of getProductOptions(i); let j=index">
														<div class="form-floating mb-3">
															<select type="select" [formControlName]="option" class="form-select" (change)="optionSelected(i, option)" [ngClass]="{ 'is-invalid': product.get('options')?.get(option)?.errors}">
																<option *ngFor="let x of getOptions(i, option)" [ngValue]="x.option?.optionId">
																	{{x.option?.name}}
																</option>
															</select>
															<label>{{option}}</label>
														</div>
													</div>
												</div>
												<div>
													<div class="row">
														<div class="col-6">
															<div class="form-floating mb-3">
																<select type="select" formControlName="quantity" class="form-select" [ngClass]="{ 'is-invalid': product.get('quantity')?.errors }">
																	<option *ngFor="let quantity of priceMatrixQuantities" value="{{quantity}}">
																		{{ quantity }}
																	</option>
																</select>
																<label for="quantity">Select Quantity</label>
															</div>
														</div>
													</div>
												</div>
												<div *ngIf="showDesign">
													<div><b>Design Options</b></div>
													<div>
														<div class="form-floating mb-3">
															<select type="select" formControlName="design" (change)="designSelected(i)" class="form-select" [ngClass]="{ 'is-invalid': product.get('design')?.errors }">
																<option value="{{USelectDesignType.Upload}}">Upload</option>
																<option value="{{USelectDesignType.Template}}">Template</option>
																<option value="{{USelectDesignType.ProfessionalDesign}}">Professional Design</option>
															</select>
															<label for="design">Choose Design Option</label>
														</div>

														<div *ngIf="products.at(i).get('design')?.value === USelectDesignType.Template">
															<div class="form-floating mb-3">
																<input type="text" class="form-control" formControlName="template" placeholder="Template Id" [ngClass]="{ 'is-invalid': product.get('template')?.errors }">
																<label for="template">Template Id</label>
															</div>
														</div>

														<div *ngIf="uploadDesignSelected(i)">
															<div class="form-group mb-3">
																<label for="front">Front File</label>
																<span *ngIf="!!cartItemToEdit && !!cartItemToEdit.design?.front?.fileName">&nbsp;({{ cartItemToEdit?.design?.front?.fileName }})</span>
																<input type="file" class="form-control" formControlName="front" (change)="handleFrontFileInput(i, $event.target)">
															</div>

															<div class="form-group mb-3">
																<label for="back">Back File</label>
																<span *ngIf="!!cartItemToEdit && !!cartItemToEdit.design?.back?.fileName">&nbsp;({{ cartItemToEdit?.design?.back?.fileName}})</span>
																<input type="file" class="form-control" formControlName="back" (change)="handleBackFileInput(i, $event.target)">
															</div>

															<div class="form-check mb-3">
																<input class="form-check-input" type="checkbox" formControlName="proof">
																<label class="form-check-label" for="proof">Request a PDF proof before
																	printing?</label>
															</div>
														</div>
													</div>
												</div>
												<div class="row mb-3">
													<div class="col-6">
														<app-customer-addresses [submitted]="submitted" [addressType]="'Shipping'" [customerId]="customerId" [cartAddressId]="products.at(i).get('shippingAddress')?.value" (addressSelected)="selectShippingAddress($event, i)">
														</app-customer-addresses>
													</div>
												</div>
												<div>
													<div><b>Delivery Options: </b></div>
													<div class="row mb-3">
														<div class="col-md-3">
															<div class="form-floating">
																<input type="text" [attr.disabled]="true" formControlName="deliveryWindow" class="form-control">
																<label for="startDate">Turnaround Time</label>
															</div>
														</div>
													</div>
												</div>
												<div class="row mb-3">
													<div class="col-md-12">
														<div class="form-floating">
															<input type="text" formControlName="jobComments" class="form-control">
															<label for="startDate">Job Comments</label>
														</div>
													</div>
												</div>
											</div>

											<div class="row mt-2 mb-2">
												<div class="col">
													<div class="hstack">
														<button class="btn btn-primary btn-lg" [disabled]="!this.cartForm.valid" (click)="saveProduct(i)">Save</button>
														<button class="btn btn-danger ms-auto" (click)="cancelCustomization()">Cancel</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</app-spinner-with-overlay>
