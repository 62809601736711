import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BundleDisplay, GetBundlesRequest, PaginationQuery } from '@taradel/admin-api-client';
import { AuthenticationService } from 'services/authentication.service';
import { BundlesService } from 'services/bundles.service';
import { SitesService } from 'services/sites.service';

export interface BundleObject {
	bundleDisplay: BundleDisplay;
	selected: boolean;
}

@Component({
  selector: 'app-bundles-display',
  templateUrl: './bundles-display.component.html',
  styleUrls: ['./bundles-display.component.scss']
})

export class BundlesDisplayComponent implements OnInit {
	siteId = -1;
	bundles?: BundleObject[];
	selectedBundle?: BundleDisplay;
	count = 0;
	loading = false;
	isSalesAdmin = false;
	request = new GetBundlesRequest();
	paginationQuery = new PaginationQuery();

	constructor(
		public authService: AuthenticationService,
		private route: ActivatedRoute,
		private bundlesService: BundlesService,
		private sitesService: SitesService
	) {
		this.siteId = parseInt(this.route.snapshot.paramMap.get('siteId') ?? '-1', 10);
	}

	async ngOnInit(): Promise<void> {
		this.isSalesAdmin = await this.authService.hasRole('SalesAdmin');
		await this.sitesService.setCurrentSiteId(this.siteId);
		this.sitesService.currentSite.subscribe(async site => {
			this.selectedBundle = undefined;
			this.bundles = [];
			this.loading = true;
			this.request.name = '';
			this.request.includeDeleted = false;
			this.request.products = [];
			this.request.siteIds = [];
			this.paginationQuery.pageNo = 1;
			this.paginationQuery.pageSize = 20;
			this.request.pagination = this.paginationQuery;
			await this.getBundles(this.siteId);
			this.loading = false;
		});
	}

	async getBundles(siteId: number) {
		if (siteId > 0) {
			this.request.siteIds = [siteId];
		}
		const bundleDisplays = await this.bundlesService.searchForSiteBundles(this.request);
		this.bundles = [];
		bundleDisplays.forEach(bundle => {
			const bundleObject = {
				bundleDisplay: bundle,
				selected: false
			} as BundleObject;
			this.bundles?.push(bundleObject);
		});

		this.count = await this.bundlesService.searchForSiteBundlesCount(this.request);
	}

	selectBundle(bundle: BundleObject) {
		if (bundle.selected) {
			this.deselectBundle();
		}
		else {
			this.deselectBundle();
			if (!!this.bundles) {
				const index = this.bundles.findIndex(b => b.bundleDisplay.bundleId === bundle.bundleDisplay.bundleId);
				this.bundles[index].selected = true;
				this.selectedBundle = bundle.bundleDisplay;
			}
		}
	}

	deselectBundle() {
		if (!!this.bundles) {
			this.bundles.forEach(b => b.selected = false);
			this.selectedBundle = undefined;
		}
	}
}
