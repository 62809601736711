<div id="app" class="app app-header-fixed app-sidebar-fixed" [ngClass]="{
	'app-sidebar-minified': themeSettings.settings.appSidebarMinified,
	'app-sidebar-moble-toggled': themeSettings.settings.appTopMenuMobileToggled
	}">
	<app-header></app-header>
	<app-sidebar></app-sidebar>
	<div id="app-content" class="app-content">
		<router-outlet></router-outlet>
	</div>
</div>
