<app-pagefullheader></app-pagefullheader>


<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<p *ngIf="authService.organizationId === 1" class="text-end">
			<a [routerLink]="['/customers', 'create']" class="btn btn-success btn-rounded px-4 mx-2 rounded-pill">
				<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
				Create Customer
			</a>
		</p>
		<div class="card mb-3">
			<div class="card-body">
				<h5 class="card-title">Search</h5>

				<form (ngSubmit)="search(false)">
					<div class="row align-items-center">
						<div class="col-md-2">
							<div class="form-floating">
								<input type="number" id="customerSearchBox" name="customerSearchBox" class="form-control"
									[(ngModel)]="customerSearch.customerId" placeholder="Customer Id">
								<label for="customerSearchBox">Customer Id </label>
							</div>
						</div>
						<div class="col">
							<div>
								<app-email-input
									[(ngModel)]="customerSearch.emailAddress"
									[showErrors]="false"
									name="email"
									label="Email"
									placeholder="Email Address"></app-email-input>
							</div>
						</div>
						<div class="col">
							<div class="form-floating">
								<input type="text" id="firstName" name="firstName" class="form-control"
									[(ngModel)]="customerSearch.firstName" placeholder="First Name">
								<label for="firstName">First Name</label>
							</div>
						</div>
						<div class="col">
							<div class="form-floating">
								<input type="text" id="lastName" name="lastName" class="form-control"
									[(ngModel)]="customerSearch.lastName" placeholder="Last Name">
								<label for="lastName">Last Name</label>
							</div>
						</div>
						<div class="col">
							<div class="form-floating">
								<input type="text" id="companyName" name="companyName" class="form-control"
									[(ngModel)]="customerSearch.company" placeholder="Company Name">
								<label for="companyName">Company Name</label>
							</div>
						</div>
					</div>
					<div class="row mt-2">
						<div class="col-md-2">
							<app-sites-list [startingSiteId]="selectedSiteId" [sites]="sites" (siteSelected)="setSiteValue($event.siteId!)" class="sites-list"></app-sites-list>
						</div>
						<div class="col-md-2">
							<div class="form-floating">
								<input type="number" id="lifetimeRevenue" class="form-control" [(ngModel)]="customerSearch.lifetimeRevenue" name="lifetimeRevenue" placeholder="Lifetime Revenue" />
								<label for="lifetimeRevenue">Lifetime Revenue</label>
							</div>
						</div>
						<div class="col-xl-2">
							<div class="form-floating">
								<select class="form-select" [(ngModel)]="customerSearch.lastTaggedSalesRep" name="lastTaggedSalesRep" id="repsDropdown">
									<option disabled>Select a Sales Rep</option>
									<option *ngFor="let rep of salesRepsList | sort: 'asc': 'value'" [value]="rep.value">
										{{rep.text}}</option>
								</select>
								<label for="repsDropdown">Select a Sales Rep</label>
							</div>
						</div>
					</div>
					<div class="row mt-2">
						<div class="col-auto">
							<button class="btn btn-primary" [disabled]="loading">Search</button>
						</div>
						<div *ngIf="filtered" class="col-auto">
							<button class="btn btn-danger" (click)="resetFilter()">Reset Filter</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="ratio ratio-21x9" *ngIf="loading && !isSearchEnabled"></div>

		<div class="py-3">
			<div *ngIf="isSearchEnabled">
				<div class="d-flex justify-content-between p-2">
					<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
						[pageSize]="customerSearch.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
						(pageChange)="pageChanged()"></ngb-pagination>
					<div class="form-floating">
						<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
							(change)="pageChanged()">
							<option [ngValue]="20">20 items per page</option>
							<option [ngValue]="50">50 items per page</option>
							<option [ngValue]="100">100 items per page</option>
						</select>
						<label for="pageSize">Choose Items per Page</label>
					</div>
				</div>
				<table class="table table-striped">
					<thead>
						<tr>
							<th>Id</th>
							<th>Site</th>
							<th>Company</th>
							<th>Name</th>
							<th>Username (email)</th>
							<th>Phone Number</th>
							<th>Created</th>
							<th>Order Total</th>
							<th>Last Order</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let customer of customers">
							<td>{{customer.customerId}}</td>
							<td>{{customer.site}}</td>
							<td>{{customer.company}}</td>
							<td>{{customer.firstName}} {{customer.lastName}}</td>
							<td>{{customer.username}}</td>
							<td>{{customer.phoneNumber}}</td>
							<td>
								<span *ngIf="customer.createdDate">
									{{ customer.createdDate|date: 'MM/dd/yyyy h:mm a' }}</span>
							</td>
							<td>{{customer.orderTotal | currency }}</td>
							<td>
								<span *ngIf="customer.lastOrderDate && customer.lastOrderAmount">
									{{customer.lastOrderAmount | currency }} on {{customer.lastOrderDate | date: 'MM/dd/yyyy'}}
								</span>
							</td>
							<td>
								<app-customer-links [customerId]="customer.customerId"></app-customer-links>
							</td>
						</tr>
					</tbody>
				</table>

				<div class="d-flex justify-content-between p-2">
					<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
						[pageSize]="customerSearch.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
						(pageChange)="pageChanged()"></ngb-pagination>
					<div class="form-floating">
						<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
							(change)="pageChanged()">
							<option [ngValue]="20">20 items per page</option>
							<option [ngValue]="50">50 items per page</option>
							<option [ngValue]="100">100 items per page</option>
						</select>
						<label for="pageSize">Choose Items per Page</label>
					</div>
				</div>
			</div>
			<app-alert-box alertType="info" *ngIf="!loading && this.customers && !this.customers.length">
				No customers were found that match your search criteria.</app-alert-box>
		</div>
	</div>
</app-spinner-with-overlay>
