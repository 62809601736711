import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiException, CustomerProperty } from '@taradel/web-api-client';
import { OrderlayoutService } from 'services/orderlayout.service';
import { SalesApiService } from "services/sales-api.service";
import { ToastService } from 'services/toast.service';
import { CustomerService } from 'services/customer.service';
import { environment } from 'environment';
import { AuthenticationService } from 'services/authentication.service';

@Component({
	selector: 'app-order-summary',
	templateUrl: './order-summary.component.html',
	styleUrls: ['./order-summary.component.scss']
})

export class OrderSummaryComponent {

	loading = false;
	isCanada = environment.instance === 'Canada';
	ventureOneNumberInvalid = false;

	constructor(
		public authService: AuthenticationService,
		public orderLayoutService: OrderlayoutService,
		public salesApiService: SalesApiService,
		public modalService: NgbModal,
		private toastService: ToastService,
		private customerService: CustomerService
	) { }

	async getPDFReceipt(orderId: number) {
		try {
			this.loading = true;
			await this.salesApiService.getPdf(orderId);
		}
		catch (error: any) {
			this.toastService.showError('There was a problem getting the receipt.');
			if (ApiException.isApiException(error)) {
				console.log(error.response);
			}
			else {
				console.log(error);
			}
		}
		finally {
			this.loading = false;
		}
	}

	orderHasPO() {
		return this.orderLayoutService.selectedOrderItems?.findIndex(x => x.poNumber.length > 0) !== -1;
	}

	async deleteOrder() {
		this.loading = true;
		try {
			await this.orderLayoutService.deleteOrder();
			this.orderLayoutService.selectedOrder!.deleted = true;
			this.toastService.showSuccess('The order was deleted successfully');
		}
		catch (error: any) {
			if (error.isApiException && error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError('There was a problem deleting the order.');
			}
			console.log(error);
		}
		finally {
			this.orderLayoutService.loadOrder(this.orderLayoutService.orderId);
			this.loading = false;
			this.modalService.dismissAll();
		}
	}

	async restoreOrder() {
		this.loading = true;
		try {
			await this.orderLayoutService.restoreOrder();
			this.toastService.showSuccess('The order was restored successfully');
		}
		catch (error) {
			this.toastService.showError('There was a problem restoring the order.');
		}
		finally {
			this.loading = false;
			this.modalService.dismissAll();
		}
	}

	async reGenerateList() {
		try {
			this.loading = true;
			await this.orderLayoutService.reGenerateList();
		}
		catch {
			this.toastService.showError('There was an error re-generating the list');
		}
		finally {
			this.loading = false;
		}
	}

	updateVentureOneNumber() {
		this.ventureOneNumberInvalid = false;
		if (this.orderLayoutService.ventureOneNumber === undefined || this.orderLayoutService.ventureOneNumber === '') {
			return;
		}
		if (this.orderLayoutService.ventureOneNumber.length < 7 || this.orderLayoutService.ventureOneNumber.length > 20) {
			this.ventureOneNumberInvalid = true;
			return;
		}
		this.loading = true;
		let customerPropertyList: CustomerProperty[] = [];
		const ventureOneProperty = {
			customerPropertyID: 0,
			customerID: this.orderLayoutService.selectedCustomer?.customerID,
			propertyName: 'VentureOne Number',
			propertyValue: this.orderLayoutService.ventureOneNumber
		} as CustomerProperty;
		customerPropertyList.push(ventureOneProperty);
		let success = true;
		try {
			if (this.orderLayoutService.hasVentureOneNumber) {
				this.customerService.updateCustomerProperties(customerPropertyList);
			}
			else {
				this.customerService.createCustomerProperties(customerPropertyList);
			}
		}
		catch (err: any) {
			success = false;
			console.log(err);
			this.toastService.showError('VentureOne Number could not be updated');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.toastService.showSuccess('VentureOne Number updated');
		}
	}
}
