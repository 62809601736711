import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderlayoutService } from 'services/orderlayout.service';
import { PrinterType } from '../purchase-order.component';

@Component({
	selector: 'app-eligible-printers',
	templateUrl: './eligible-printers.component.html',
	styleUrls: ['./eligible-printers.component.scss']
})
export class EligiblePrintersComponent {
	@Input() printerType = 'taradel';
	@Input() edit = false;
	@Output() affiliateOverride: EventEmitter<void> = new EventEmitter<void>();
	@Output() overrideCancel: EventEmitter<void> = new EventEmitter<void>();
	@Output() selectPrinterType: EventEmitter<PrinterType> = new EventEmitter<PrinterType>();
	overrideAllowed = false;
	constructor(public orderLayoutService: OrderlayoutService) { }

	overrideAffiliate() {
		this.overrideAllowed = true;
		this.affiliateOverride.emit();
	}
	cancelOverride() {
		this.overrideAllowed = false;
		this.overrideCancel.emit();
	}
	printerTypeSelected(printerType: PrinterType) {
		this.selectPrinterType.emit(printerType);
	}
}
