import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CmsContent, CmsContentRequest, Site } from '@taradel/admin-api-client';
import { Subscription } from 'rxjs';
import { ContentService } from 'services/content.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-content-key-for-sites',
	templateUrl: './content-key-for-sites.component.html',
	styleUrls: ['./content-key-for-sites.component.scss']
})
export class ContentKeyForSitesComponent implements OnInit, OnDestroy {
	contentKey = '';
	contentForSites: CmsContent[] = [];
	sites: Site[] = [];
	contentToEdit?: CmsContent;
	contentToDelete?: CmsContent;
	subscription!: Subscription;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private contentService: ContentService,
		private siteService: SitesService,
		private modalService: NgbModal,
		private toastService: ToastService) { }

	async ngOnInit(): Promise<void> {
		this.subscription = this.route.paramMap.subscribe(params => {
			this.contentKey = params.get('contentKey')!;
		});
		this.contentForSites = await this.contentService.getContentKeyForAllSites(this.contentKey);
		this.sites = await this.siteService.getAllSites();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	getSiteName(siteId: number) {
		return this.sites.find( site => siteId === site.siteId)?.name || '';
	}

	async editContent() {
		let contentEdited = true;
		try {
			await this.contentService.saveSiteContent(new CmsContentRequest({
				siteId: this.contentToEdit?.siteId!,
				content: this.contentToEdit?.content,
				contentKey: this.contentToEdit?.contentKey?.contentKey,
				language: this.contentToEdit?.language
			}));
		}
		catch (err: any) {
			contentEdited = false;
			this.toastService.showError(`Content for key ${this.contentToDelete?.contentKey?.contentKey} for ${this.getSiteName(this.contentToDelete?.siteId!)} could not be edited`);
			console.log(err);
		}
		if (contentEdited) {
			this.toastService.showSuccess('Content was successfully updated');
			this.contentForSites = await this.contentService.getContentKeyForAllSites(this.contentKey);
			this.contentToEdit = undefined;
		}
	}

	async deleteContent() {
		let contentDeleted = true;
		try {
			await this.contentService.deleteSiteContent(this.contentToDelete!.siteId, this.contentToDelete!.contentKey!.contentKey!);
		}
		catch (err: any) {
			contentDeleted = false;
			this.toastService.showError(`Content for key ${this.contentToDelete?.contentKey?.contentKey} for ${this.getSiteName(this.contentToDelete?.siteId!)} could not be deleted`);
			console.log(err);
		}
		if (contentDeleted) {
			await this.deleteContentKeyCheck();
		}
	}

	showEditSettingsModal(cmsContent: CmsContent, content: any) {
		this.contentToEdit = cmsContent;
		this.modalOpen(content);
	}

	showDeleteContentModal(cmsContent: CmsContent, modal: any) {
		this.contentToDelete = cmsContent;
		this.modalOpen(modal);
	}

	modalOpen(content: any) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(async result => {
			if (result === 'edit') {
				await this.editContent();
			}
			if (result === 'delete') {
				await this.deleteContent();
			}
		});
	}

	async deleteContentKeyCheck() {
		this.contentForSites = await this.contentService.getContentKeyForAllSites(this.contentKey);
		if (this.contentForSites.length >= 1) {
			this.contentToDelete = undefined;
			return;
		}
		await this.contentService.deleteContentKey(this.contentToDelete?.contentKey?.contentKey!);
		await this.router.navigate(['/sites/content-keys']);
	}
}
