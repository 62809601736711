import { Component, OnInit } from '@angular/core';
import { IdentityService } from 'services/identity.service';
import { AddUserEmailAliasQuery, IdentityCustomer, IdentityRole, UserEmailAlias } from '@taradel/admin-api-client';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'services/toast.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { createEmailValidator } from '@taradel/white-label-common';
import { CustomerService } from 'services/customer.service';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

	customer!: IdentityCustomer;
	allRoles!: IdentityRole[];
	userRoles!: string[];
	possibleRoles!: IdentityRole[];
	allEmailAliases: UserEmailAlias[] = [];
	addEmailAliasForm: UntypedFormGroup;
	emailAliasFormSubmitting: boolean = false;
	selectedRoleToAdd = '';
	loading = false;

	constructor(
		private identityService: IdentityService,
		private customerService: CustomerService,
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private toastService: ToastService) {
			this.addEmailAliasForm = formBuilder.group({
				emailAlias: ['', Validators.compose([Validators.required, createEmailValidator({ 'required': true })])],
				customerId: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{5,8}$')])],
				organizationId: ['', Validators.required],
			});
		}


	ngOnInit(): void {
		this.route.paramMap.subscribe(async params => {
			try {
				const customerId = parseInt(params.get('customerId') ?? '0', 10);
				this.loading = true;
				this.allRoles = await this.identityService.getRoles();
				this.customer = await this.identityService.getCustomer(customerId);
				[this.userRoles, this.allEmailAliases, this.allRoles] = await Promise.all([
					this.identityService.getUserRoles(this.customer.login?.userId!),
					this.customerService.getUserEmailAliases(this.customer.customerId),
					this.identityService.getRoles(),
				]);

				this.setPossibleRoles();
			}
			catch {
				this.toastService.showError('There was an error loading the user');
			}
			finally {
				this.loading = false;
			}
		});

	}

	async removeRole(roleName: string) {
		try {
			this.loading = true;
			const role = this.allRoles.find(r => r.name === roleName)!;
			await this.identityService.removeRoleFromUser(this.customer.login?.userId!, role.id!);
			this.userRoles.splice(this.userRoles.indexOf(roleName), 1);
			this.possibleRoles.push(new IdentityRole({
				name: roleName,
				id: role.id
			}));
			this.toastService.showSuccess('Role was removed successfully');
		}
		catch {
			this.toastService.showError('There was an error removing the role');
		}
		finally {
			this.loading = false;
		}
	}

	async addRole(roleId: string) {
		try {
			this.loading = true;
			await this.identityService.addRoleToUser(this.customer.login?.userId!, roleId);
			const i = this.possibleRoles.findIndex(role => role.id === roleId);
			if (i !== -1) {
				this.userRoles.push(this.possibleRoles[i].name!);
				this.possibleRoles.splice(i, 1);
			}
			this.toastService.showSuccess('Role was added successfully');
		}
		catch {
			this.toastService.showError('There was an error adding the role');
		}
		finally {
			this.loading = false;
		}
	}

	setPossibleRoles() {
		this.possibleRoles = Array.from(this.allRoles);
		this.userRoles.forEach(userRoleName => {
			const i = this.possibleRoles.findIndex(role => role.name === userRoleName);
			if (i !== -1) {
				this.possibleRoles.splice(i, 1);
			}
		});
		console.groupEnd();
	}

	async getEmailAliases() {
		this.allEmailAliases = await this.customerService.getUserEmailAliases(this.customer.customerId);
	}

	async deleteEmailAlias(alias: UserEmailAlias) {
		this.emailAliasFormSubmitting = true;
		try {
			await this.customerService.deleteUserEmailAlias(this.customer.customerId, alias.organizationId);
			await this.getEmailAliases();
		}
		catch (ex) {
			console.error(ex);
			this.toastService.showError('Unable to delete email alias.');
		}
		finally {
			this.emailAliasFormSubmitting = false;
		}
	}

	async addEmailAlias() {
		if (this.addEmailAliasForm.invalid) {
			return;
		}
		const query = new AddUserEmailAliasQuery({
			emailAlias: this.addEmailAliasForm.controls.emailAlias.value,
			customerId: this.addEmailAliasForm.controls.customerId.value,
			organizationId: this.addEmailAliasForm.controls.organizationId.value,
		});
		try {
			this.emailAliasFormSubmitting = true;
			await this.customerService.addUserEmailAlias(query);
			await this.getEmailAliases();
			this.emailAliasFormSubmitting = false;
			this.addEmailAliasForm.reset();
		}
		catch (ex) {
			console.error(ex);
			this.toastService.showError('Unable to add email alias.');
		}
		finally {
			this.emailAliasFormSubmitting = false;
		}
	}
}
