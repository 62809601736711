import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Organization, OrganizationSearch } from '@taradel/admin-api-client';
import { SiteFilterRequest } from '@taradel/admin-api-client';
import { Site } from '@taradel/admin-api-client';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-create-site',
	templateUrl: './create-site.component.html',
	styleUrls: ['./create-site.component.scss']
})
export class CreateSiteComponent implements OnInit {

	createSiteForm: FormGroup;
	submitted = false;
	siteId = 0;
	organizationId = 0;
	organizations: Organization[] = [];

	constructor(
		private formBuilder: FormBuilder,
		private toastService: ToastService,
		private sitesService: SitesService) {
		this.createSiteForm = this.formBuilder.group({
			siteName: ['', Validators.compose([Validators.required])],
			//   localUrl: ['', Validators.compose([Validators.required])],
			//   devUrl: ['', Validators.compose([Validators.required])],
			active: [Boolean],
			signIn: [Boolean],
			signUp: [Boolean],
			email: ['', Validators.compose([Validators.required])],
			supportEmail: ['', Validators.compose([Validators.required])],
			adminEmail: ['', Validators.compose([Validators.required])],
			phone: ['', Validators.compose([Validators.required])],
			tollFreePhone: ['', Validators.compose([Validators.required])],
			address1: ['', Validators.compose([Validators.required])],
			address2: [''],
			city: ['', Validators.compose([Validators.required])],
			state: ['', Validators.compose([Validators.required])],
			zipCode: ['', Validators.compose([Validators.required])],

		});
	}

	get f() {
		return this.createSiteForm.controls;
	}

	setState(value: string) {
		this.createSiteForm.controls.state.setValue(value);
	}

	async ngOnInit() {
		this.organizations = await this.sitesService.listOrganizationsAndSites(new OrganizationSearch({
			organizationId: undefined,
			siteName: ''
		}));
	}

	async createSite() {
		if (!this.createSiteForm.invalid) {
			const site = new Site({
				siteId: 0,
				name: this.createSiteForm.controls.siteName.value,
				address1: this.createSiteForm.controls.address1.value,
				address2: this.createSiteForm.controls.address2.value,
				city: this.createSiteForm.controls.city.value,
				state: this.createSiteForm.controls.state.value,
				zipCode: this.createSiteForm.controls.zipCode.value,
				phoneNumber: this.createSiteForm.controls.tollFreePhone.value,
				active: this.createSiteForm.controls.active.value,
				enableSignin: this.createSiteForm.controls.signIn.value,
				emailAddress: this.createSiteForm.controls.email.value,
				organizationId: this.organizationId
			});
			await this.sitesService.createSite(site).then(
				(res) => {
					this.toastService.showInfo(`Site "${site.name}" has been created successfully. And has been given ths siteId of ${res}`);
					this.siteId = res;
					console.log(res);
				},
				(err) => this.toastService.showError(`There was an error createing the site. Error ${err}`)
			);
		}
	}

	imageCopySH() {
		navigator.clipboard
			.writeText(`aws s3 cp s3://taradel-us-assets-dev/sites/Generic_White_Label/  s3://taradel-us-assets-dev/sites/${this.siteId}/ --recursive --dryrun`)
			// remove --dryrun to make commit changes
			.then(
				() => this.toastService.showInfo('The script has been copied to your clipboard.'),
				() => this.toastService.showError('There was an error copying the script to your clipboard. This should never happen...')
			)
			.then(() => setTimeout(() => {
				window.open('https://us-east-1.console.aws.amazon.com/cloudshell');
			}, 500));
	}

	selectOrg(value: any) {
		this.organizationId = value;
		console.log(this.organizationId);
	}

}
