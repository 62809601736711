<hr>

<app-spinner-with-overlay [showSpinner]="listIsLoading">
	<div class="preview-area">
		<div class="button-div" *ngIf="totalLeads > 0">
			<button class="btn btn-primary list-preview-button" type="button" (click)="getAddressList()">
				{{ buttonText }}
			</button>
		</div>
		<div class="list-preview mt-3" *ngIf="listIsPopulated && totalLeads > 0">
			<table class="table">
				<tr>
					<th>
						<h4 class="text-black">Name</h4>
					</th>
					<th>
						<h4 class="text-black">Address</h4>
					</th>
					<th>
						<h4 class="text-black">City</h4>
					</th>
					<th>
						<h4 class="text-black">State</h4>
					</th>
				</tr>
				<tr *ngFor="let address of (addresses ? addresses.slice(0, 9): [])">
					<td>{{ (address.name ?? address.firstName + ' ' + address.lastName) | titlecase }}</td>
					<td>{{ address.address | titlecase }}</td>
					<td>{{ address.city | titlecase }}</td>
					<td>{{ address.state }}</td>
				</tr>
			</table>
		</div>
	</div>
</app-spinner-with-overlay>
