import { Component, OnInit } from '@angular/core';
import { SiteConfigCount } from '@taradel/admin-api-client';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-config',
	templateUrl: './all-config-counts.component.html',
	styleUrls: ['./all-config-counts.component.scss']
})
export class ConfigComponent implements OnInit {
	loading = true;
	settings: SiteConfigCount[] = [];

	constructor(
		private siteService: SitesService,
		private toastService: ToastService) { }

	async ngOnInit(): Promise<void> {
		try {
			this.settings = await this.siteService.getSettingCounts();
		}
		catch {
			this.toastService.showError('Unable to load setting counts');
		}
		finally {
			this.loading = false;
		}
	}
}
