import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserManager } from 'oidc-client';
import { environment } from 'environment';
import { AuthenticationService } from 'services/authentication.service';

@Component({
	selector: 'app-login-callback',
	templateUrl: './login-callback.component.html',
	styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent implements OnInit {

	error = false;
	claims: {key: string; value: string}[] = [];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private authService: AuthenticationService) { }

	async ngOnInit(): Promise<void> {
		if (this.route.snapshot.fragment!.indexOf('error') >= 0) {
			this.error = true;
			return;
		}

		const userManager = new UserManager(environment.oidc);
		await userManager.signinRedirectCallback();
		await this.authService.init();

		const redirect = sessionStorage.getItem('signinRedirect') ?? '/';

		this.router.navigateByUrl(redirect);

		// Uncomment this if you want to inspect the user claims
		// if (user !== null) {
		// 	this.claims = [];
		// 	for (const [key, value] of Object.entries(user.profile)) {
		// 		this.claims.push({ key, value });
		// 	}
		// }
	}
}
