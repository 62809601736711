import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-spinner-with-overlay',
	templateUrl: './spinner-with-overlay.component.html',
	styleUrls: ['./spinner-with-overlay.component.scss']
})
export class SpinnerWithOverlayComponent {

	@Input() showSpinner: boolean = false;

	@Input() message?: string;
	@Input() messageHeading?: string;
	constructor() { }

}
