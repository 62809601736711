import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomerAddress, AddressSearchQuery, AddressTypes, Customer, DefaultAddressQuery } from '@taradel/web-api-client';
import { CustomerAddressService } from 'services/customer-address.service';
import { CustomerService } from 'services/customer.service';
import { ToastService } from 'services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, take } from 'rxjs/operators';
import { formatCAPostalCode } from 'app/utils/format-ca-postal-code';

export interface AddressItem {
	customerAddress: CustomerAddress;
	isDefault: boolean;
	hasAllDefaults: boolean;
	defaultTypes?: AddressTypes[];
}

@Component({
	selector: 'app-customer-address-list',
	templateUrl: './customer-address-list.component.html',
	styleUrls: ['./customer-address-list.component.scss']
})
export class CustomerAddressListComponent implements OnInit {
	loading = false;
	customerId = 0;
	isSearchEnabled = true;
	pageNo = 1;
	pageSize = 20;
	total = 0;
	customerAddresses: AddressItem[] = [];
	state: string = '';
	addressSearchForm: UntypedFormGroup;
	filtered = false;
	modalRef: any;
	addressToUpdate?: CustomerAddress;
	typeToUpdate?: AddressTypes;
	addressTypes: string[] = [];
	customer!: Customer;
	countryCode: 'US' | 'Canada' = 'US';

	constructor(private customerAddressService: CustomerAddressService,
		private customerService: CustomerService,
		private toastService: ToastService,
		private formBuilder: UntypedFormBuilder,
		private modalService: NgbModal,
		private route: ActivatedRoute,
	) {
		this.route.paramMap.subscribe(params => {
			this.customerId = parseInt(params.get('customerId') ?? '0', 10);
		});
		this.route.data.pipe(
			filter((data) => !!data),
			take(1)
		).subscribe(data => this.countryCode = data.countryCode);

		this.addressSearchForm = formBuilder.group({
			address1: new UntypedFormControl(''),
			city: new UntypedFormControl(''),
			state: new UntypedFormControl(''),
			zipCode: new UntypedFormControl('')
		});
	}

	async ngOnInit(): Promise<void> {
		this.customerId = this.customerId;
		this.customer = await this.customerService.getCustomer(this.customerId);
		await this.searchAddresses();
		if (this.total > 0) {
			this.isSearchEnabled = true;
		}
		this.addressTypes = Object.values(AddressTypes).map(value => value);
	}

	get controls() {
		return this.addressSearchForm.controls;
	}

	async searchAddresses(): Promise<void> {
		if (!!this.controls.address1.value || !!this.controls.city.value || !!this.controls.state.value || !!this.controls.zipCode.value) {
			this.filtered = true;
		}

		const addressSearch = {
			customerId: this.customerId,
			address: this.controls.address1.value,
			city: this.controls.city.value,
			state: this.controls.state.value,
			zipCode: this.countryCode === 'US' ? this.controls.zipCode.value : (!!this.controls.zipCode.value && this.controls.zipCode.value !== '' ? formatCAPostalCode(this.controls.zipCode.value) : ''),
			getDefaultAddresses: false,
			pageNo: this.pageNo,
			pageSize: this.pageSize
		} as AddressSearchQuery;

		this.customerAddresses = [];

		this.loading = true;
		try {
			const addresses = await this.customerAddressService.searchCustomerAddresses(addressSearch);
			this.total = await this.customerAddressService.getCustomerAddressCount(addressSearch);
			if (this.total === 0) {
				this.loading = false;
				return;
			}
			addresses.map(address => {
				let addressItem = {
					customerAddress: address,
					isDefault: false,
					hasAllDefaults: false,
					defaultTypes: []
				} as AddressItem;
				if (address.addressId === this.customer.defaultBillingAddressId) {
					addressItem.isDefault = true;
					addressItem.defaultTypes?.push(AddressTypes.Billing);
				}
				if (address.addressId === this.customer.defaultMailingAddressId) {
					addressItem.isDefault = true;
					addressItem.defaultTypes?.push(AddressTypes.Mailing);
				}
				if (address.addressId === this.customer.defaultReturnAddressId) {
					addressItem.isDefault = true;
					addressItem.defaultTypes?.push(AddressTypes.Return);
				}
				if (address.addressId === this.customer.defaultShippingAddressId) {
					addressItem.isDefault = true;
					addressItem.defaultTypes?.push(AddressTypes.Shipping);
				}
				if (address.addressId === this.customer.defaultBillingAddressId && address.addressId === this.customer.defaultMailingAddressId && address.addressId === this.customer.defaultReturnAddressId && address.addressId === this.customer.defaultShippingAddressId) {
					addressItem.hasAllDefaults = true;
				}
				this.customerAddresses.push(addressItem);
			});
		}
		catch (ex: any) {
			this.toastService.showError('There was an error loading customer addresses');
		}
		finally {
			this.loading = false;
		}
	}

	async pageChanged() {
		await this.searchAddresses();
	}

	openDeleteModal(modal: any, addressId: number) {
		const addressToUpdate = this.customerAddresses.find(address => address.customerAddress.addressId === addressId);
		this.addressToUpdate = addressToUpdate?.customerAddress;
		this.openModal(modal);
	}

	openSetDefaultModal(modal: any, address: AddressItem) {
		this.addressToUpdate = address.customerAddress;
		const newArray = this.addressTypes.filter(type => !address.defaultTypes?.some((x => AddressTypes[x] === type)));
		this.addressTypes = newArray;
		this.openModal(modal);
	}

	openRemoveDefaultModal(modal: any, address: CustomerAddress, addressType: AddressTypes) {
		this.addressToUpdate = address;
		this.typeToUpdate = addressType;
		this.openModal(modal);
	}

	setNewDefault(selectedDefault: AddressTypes) {
		this.typeToUpdate = selectedDefault;
	}

	async deleteAddress() {
		let success = true;
		if (!!this.addressToUpdate) {
			this.loading = true;
			try {
				await this.customerAddressService.deleteCustomerAddress(this.addressToUpdate!.addressId, this.customerId);
			}
			catch (error) {
				success = false;
				console.log(error);
				this.toastService.showError('Delete address failed');
			}
			finally {
				this.loading = false;
			}
		}
		else {
			this.toastService.showError('Delete address failed');
		}
		if (success) {
			this.closeModal();
			await this.searchAddresses();
		}
	}

	async removeDefault() {
		this.loading = true;
		let success = true;
		try {
			const query = {
				addressId: undefined,
				addressType: this.typeToUpdate
			} as DefaultAddressQuery;
			await this.customerAddressService.updateDefaultAddress(this.customerId, query);
		}
		catch (error) {
			success = false;
			console.log(error);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.closeModal();
			this.customer = await this.customerService.getCustomer(this.customerId);
			await this.searchAddresses();
		}
	}

	async setDefault() {
		this.loading = true;
		let success = true;
		try {
			const query = {
				addressId: this.addressToUpdate?.addressId,
				addressType: this.typeToUpdate
			} as DefaultAddressQuery;
			await this.customerAddressService.updateDefaultAddress(this.customerId, query);
		}
		catch (error) {
			success = false;
			console.log(error);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.closeModal();
			this.customer = await this.customerService.getCustomer(this.customerId);
			await this.searchAddresses();
		}
	}

	openModal(modal: any) {
		this.modalRef = this.modalService.open(modal, { beforeDismiss: () => {
			this.addressToUpdate = undefined;
			this.typeToUpdate = undefined;
			this.addressTypes = Object.values(AddressTypes).map(value => value);
			return true;
		} });
	}

	closeModal() {
		this.modalService.dismissAll();
	}

	resetFilter() {
		this.state = '';
		this.filtered = false;
		this.addressSearchForm.reset();
		this.searchAddresses();
	}

	stateSelected(value: string) {
		this.controls.state.setValue(value);
	}
}
