import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'services/authentication.service';
import { HubService } from './hub.service';

export interface NewDistResponse {
	distributionId: number;
	customerId: number;
	createdById: number;
}

@Injectable({
  providedIn: 'root'
})
export class NewDistributionCreatedHubService extends HubService {
	public newDistributionCreated = new Subject<NewDistResponse>();

	constructor(authService: AuthenticationService) {
		super('new-distribution-created', authService);

		this.connection.on('newDistributionCreated', dist => this.newDistributionCreated.next(dist));

		this.connection.start();
	}
}
