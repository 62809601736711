import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Printer } from '@taradel/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintersService } from 'services/printers.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-printers',
	templateUrl: './printers.component.html',
	styleUrls: ['./printers.component.scss']
})
export class PrintersComponent implements OnInit {

	pageNo = 1;
	pageSize = 20;

	printersSearch: any = {
		companyName: '',
		pageNo: this.pageNo,
		pageSize: this.pageSize
	};
	companyNameSearch = new Subject<string>();

	loading: boolean = false;
	isSearchEnabled = false;
	total = 0;
	printers: Printer[] = [];
	printerToUpdate: Printer | null = null;
	printerEnabledString = '';
	newPrinter = new Printer();
	newPrinterId = 0;
	modalRef: any;
	submitted = false;

	@ViewChild('companyNameInput') companyNameInput!: ElementRef;

	constructor(private printersService: PrintersService,
		private modalService: NgbModal,
		private router: Router,
		private toastService: ToastService) { }

	async ngOnInit(): Promise<void> {
		await this.loadPrinters();
		this.newPrinter.enabled = true;
		this.newPrinter.isTaradel = false;

		this.companyNameSearch.pipe(
			debounceTime(300),
			distinctUntilChanged()
		).subscribe(async (value: string) => {
			this.printersSearch.companyName = value;
			await this.loadPrinters();
		});
	}

	async loadPrinters() {
		try {
			this.loading = true;
			this.total = await this.printersService.getAllPrintersWithPaginationCount(this.printersSearch);
			this.printers = await this.printersService.getAllPrintersWithPagination(this.printersSearch);
		}
		catch {
			this.toastService.showError('There was an error loading the printers');
		}
		finally {
			this.loading = false;
		}
	}

	async togglePrinterEnabled(printerId: number) {
		try {
			this.loading = true;
			await this.printersService.disableEnablePrinter(printerId);
			this.closeUpdatePrinterModal();
		}
		catch {
			this.toastService.showError('There was an error enabling/disabling the printer');
		}
		finally {
			this.loading = false;
		}
	}

	async addNewPrinter() {
		this.submitted = true;
		if (this.newPrinter.companyName === '') {
			return;
		}
		this.loading = true;
		try {
			this.newPrinterId = await this.printersService.addPrinter(this.newPrinter!);
		}
		catch (error) {
			this.toastService.showError('There was an error adding the printer');
			console.log(error);
		}
		finally {
			this.loading = false;
			if (this.newPrinterId > 0) {
				this.closeNewPrinterModal();
				this.router.navigate([`/printers/${this.newPrinterId}`]);
			}
		}
	}

	async pageChanged() {
		try {
			this.loading = true;
			this.printersSearch.pageNo = this.pageNo;
			this.printersSearch.pageSize = this.pageSize;
			this.printers = await this.printersService.getAllPrintersWithPagination(this.printersSearch);

		}
		catch {
			this.toastService.showError('There was an error loading the printers');
		}
		finally {
			this.loading = false;
		}
	}

	openUpdatePrinterModal(updatePrinterModal: any, printer: Printer) {
		this.printerToUpdate = printer;
		if (this.printerToUpdate.enabled) {
			this.printerEnabledString = 'disable';
		}
		else if (!this.printerToUpdate.enabled) {
			this.printerEnabledString = 'enable';
		}

		this.modalRef = this.modalService.open(updatePrinterModal, { windowClass: "updateModalClass" });
	}

	openAddPrinterModal(addPrinterModal: any) {
		this.modalRef = this.modalService.open(addPrinterModal, { windowClass: "updateModalClass" });
	}

	async closeModal() {
		await this.loadPrinters();
		this.modalRef.close();
	}

	async closeUpdatePrinterModal() {
		this.printerToUpdate = null;
		this.printerEnabledString = '';
		await this.closeModal();
	}

	async closeNewPrinterModal() {
		this.newPrinter = new Printer();
		this.submitted = false;
		this.newPrinter.enabled = true;
		this.newPrinter.isTaradel = false;
		await this.closeModal();
	}

	async clearFilter() {
		this.companyNameInput.nativeElement.value = '';
		this.printersSearch.companyName = '';
		await this.loadPrinters();
	}
}
