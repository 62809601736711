<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="row mb-3">
			<div class="col-sm-12 col-md-3">
				<div class="form-floating">
					<input type="date" id="startDate" class="form-control"
						[ngModel]="apiQuery.fromDate | date: 'yyyy-MM-dd'" max="9999-12-31"
						(change)="paramStartDate = getDate($any($event).target.value)" placeholder="Start Date">
					<label for="startDate">Start Date</label>
				</div>
			</div>

			<div class="col-sm-12 col-md-3">
				<div class="form-floating">
					<input type="date" id="endDate" class="form-control" [ngModel]="apiQuery.toDate | date: 'yyyy-MM-dd'"
						max="9999-12-31" (change)="paramEndDate = getDate($any($event).target.value)"
						placeholder="End Date">
					<label for="endDate">End Date</label>
				</div>
			</div>

			<div class="col-sm-12 col-md-2">
				<button class="btn btn-info" style="height: 100%;" (click)="submit()">Load Report</button>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<ag-grid-angular style="width: 100%; height: 700px;"
					[class]="agThemeClass"
					[defaultColDef]="agDefaultColDef"
					[columnTypes]="agColumnTypes"
					[columnDefs]="agColumnDefs"
					[rowData]="rowData"
					[rowGroupPanelShow]="'always'"
					[groupDisplayType]="'singleColumn'"
					[groupRemoveSingleChildren]="true"
					[autoGroupColumnDef]="agAutoGroupColumnDef"
					[suppressAggFuncInHeader]="true"
					[sideBar]="agSideBar"
					[statusBar]="agStatusBar"
					[autoSizeStrategy]="agAutoSizeStrategy"
					(gridReady)="onGridReady($event)"
					(firstDataRendered)="onFirstDataRendered($event)"
					>

				</ag-grid-angular>
			</div>
		</div>

	</div>
</app-spinner-with-overlay>
