import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { USelectDesignOption, USelectDesignType } from '@taradel/web-api-client';
import { Subscription } from 'rxjs';
import { DetailedUSelectDesignOption, SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-site-uselect-design-details',
	templateUrl: './site-uselect-design-details.component.html',
	styleUrls: ['./site-uselect-design-details.component.scss']
})
export class SiteUselectDesignDetailsComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	siteId: number = 0;
	designTypes: USelectDesignType[] = [];
	uSelectId: number = 0;
	uSelectName?: string;
	defaultUSelectDesigns = '';
	detailedUSelectDesignOption: DetailedUSelectDesignOption[] = [];
	newUSelectDesignOption = new DetailedUSelectDesignOption();
	disableAddBtn: boolean = true;
	currentSite$ = new Subscription();

	constructor(private router: Router,
		private route: ActivatedRoute,
		private toastService: ToastService,
		private sitesService: SitesService) { }

	ngOnInit(): void {
		this.loading = true;
		this.siteId = parseInt(this.route.snapshot.params['siteId'], 10);
		this.uSelectId = parseInt(this.route.snapshot.params['uSelectId'], 10);
		this.currentSite$ = this.sitesService.currentSite.subscribe(async site => {
			if (site) {
				try {
					this.loading = true;
					await this.getSiteUSelectDesignOptions(site!.siteId);
					this.initializeNewUSelectDesignOption();
				}
				catch {
					this.toastService.showError('There was an error getting USelect design options');
				}
				finally {
					this.loading = false;
				}
			}
		});
		this.designTypes = Object.values(USelectDesignType);
	}

	ngOnDestroy(): void {
		this.currentSite$.unsubscribe();
	}

	initializeNewUSelectDesignOption() {
		this.newUSelectDesignOption = new DetailedUSelectDesignOption();
		this.newUSelectDesignOption.siteId = this.sitesService.currentSiteId;
		this.newUSelectDesignOption.uSelectId = this.uSelectId;
		this.newUSelectDesignOption.uSelectName = this.sitesService.getUSelectName(this.uSelectId);
	}

	async getSiteUSelectDesignOptions(siteId: number) {
		const uSelectDesignOptions = await this.sitesService.getSiteUSelectDesignOptions(siteId, this.uSelectId);
		let defaultDetailedUSelectDesigns: DetailedUSelectDesignOption[] = [];
		const defaultUSelectDesignOptions = await this.sitesService.getSiteUSelectDesignOptions(0, this.uSelectId);
		defaultDetailedUSelectDesigns = await this.sitesService.setListOfSiteUSelectDesignOptions(defaultUSelectDesignOptions);
		this.detailedUSelectDesignOption = await this.sitesService.setListOfSiteUSelectDesignOptions(uSelectDesignOptions);
		this.uSelectName = defaultDetailedUSelectDesigns?.length > 0 ? defaultDetailedUSelectDesigns[0].uSelectName : '';
		this.defaultUSelectDesigns = defaultDetailedUSelectDesigns.map(o => o.productDesignTypeId).join(', ');
	}

	async updateUSelectDesigns() {
		try {
			this.loading = true;
			const updatedDesignOptionsArray = this.detailedUSelectDesignOption.map(designOption => {
				delete designOption.uSelectName;
				let newDesignOption = {
					siteId: designOption.siteId,
					uSelectId: designOption.uSelectId,
					productDesignTypeId: designOption.productDesignTypeId
				} as USelectDesignOption;
				return newDesignOption;
			});
			await this.sitesService.updateSiteUSelectDesigns(this.siteId, this.uSelectId, updatedDesignOptionsArray);
			this.initializeNewUSelectDesignOption();
			await this.getSiteUSelectDesignOptions(this.sitesService.currentSiteId);
			this.toastService.showSuccess('USelect add-ons successfully saved');
		}
		catch (error: any) {
			this.toastService.showError('USelect add-ons did not save');
		}
		finally {
			this.loading = false;
		}
	}

	removeSelectedDesignOption(index: number) {
		this.detailedUSelectDesignOption.splice(index, 1);
	}

	addNewUSelectDesign() {
		this.disableAddBtn = true;
		this.detailedUSelectDesignOption.push(this.newUSelectDesignOption);
		this.initializeNewUSelectDesignOption();
	}

	async deleteUSelectFromSite() {
		this.loading = true;
		let success = true;
		await this.sitesService.removeSiteUSelectDesign(this.sitesService.currentSiteId, this.uSelectId).catch((error: HttpErrorResponse) => {
			this.loading = false;
			success = false;
			this.toastService.showError(error.message);
		});
		if (success) {
			this.loading = false;
			this.router.navigateByUrl(`sites/${this.sitesService.currentSiteId}/uselect-designs`);
		}
	}

	getMethod(item: { id: number, name: string }) {
		const matchingUSelect = this.detailedUSelectDesignOption.find(x => x.uSelectId === item.id);
		if (matchingUSelect) {
			this.toastService.showError('uSelect add on already has an editable row');
			return;
		}
		this.newUSelectDesignOption.uSelectId = item.id;
		this.newUSelectDesignOption.uSelectName = item.name;
		this.disableAddBtn = false;
	}

}
