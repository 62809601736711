<div class="card p-3 mb-3">
	<app-spinner-with-overlay [showSpinner]="loading">
        <div class="row">
            <div class="col-sm-5">
                <div class="input-group mb-3">
					<select #uSelectIdDropdown class="form-select" aria-label="Select a USelect Id to filter by" (change)="routeToEditUSelect($any($event.target).value)">
						<option selected>Filter by USelect ID</option>
						<option *ngFor="let uSelect of uSelectIdsList" value="{{uSelect.id}}">{{uSelect.name}}</option>
					</select>
                </div>
            </div>
			<div class="col-sm-7">
				Filter by a USelect in order to start editing or <span><a [routerLink]="['/sites', siteId, 'uselect-designs', 'add']">Add a new USelect</a></span>
			</div>
        </div>

		<div class="card border-0 p-3">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th width="10%">USelect ID</th>
						<th>USelect Name</th>
						<th>Design Type</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let designUSelect of designUSelects">
						<td width="10%">
							{{designUSelect.uSelect.id}}
						</td>
						<td>
							{{designUSelect.uSelect.name}}
						</td>
						<td>
							<small style="background-color: orange;" *ngIf="designUSelect.siteId === 0">(default) </small>{{designUSelect.designTypes.join(', ')}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-spinner-with-overlay>
</div>
