<ng-container>
	<app-spinner-with-overlay [showSpinner]="loading || orderLayoutService.loadingOrder || orderLayoutService.loadingOrderItem">
		<ng-container *ngIf="order">
			<div *ngIf="!modifyingOrder" class="row">
				<div class="col-lg-7 col-12">
					<div class="card p-3">
						<div class="card-body">
							<div class="row d-flex justify-content-end mb-3">
								<div class="col-auto">
									<button *ngIf="distributionId > 0" type="button" class="btn btn-primary" (click)="initiateAddNewOrderItem()">
										Add Order Item
									</button>
								</div>
								<div *ngIf="hasPNSProducts" class="col-auto ms-2">
									<button type="button" class="btn btn-primary" (click)="initiateAddNewPNSOrderItem()">
										Add Print And Ship Item
									</button>
								</div>
							</div>

							<ng-container *ngFor="let item of modifiedOrderItems">
								<div class="row mb-3">
									<div class="col-12">
										<app-change-order-item [orderItem]="item" (removeOrderItemLines)="removeOrderItemLines($event)" (addOrderItemLines)="addOrderItemLines($event)"
										(itemMarkedAsDeleted)="handleMarkingItemAsDeleted($event)" (updateOrderItem)="initiateUpdateOrderItem($event)"></app-change-order-item>
									</div>
								</div>
							</ng-container>

							<div class="row mt-3">
								<div class="col-12">
									<div class="row d-flex justify-content-end">
										<div class="col-auto">
											<button class="btn btn-sm btn-success" data-toggle="tooltip" data-placement="top" title="Save Changes"
											(click)="saveChanges()">
												Save Changes
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="!modifyingOrder" class="col-lg-5 col-12">
					<div class="row">
						<div class="card p-3">
							<app-update-order-coupon [order]="order" (updateChangeOrderForm)="orderUpdated()"></app-update-order-coupon>
						</div>
					</div>
					<div *ngIf="this.siteBundles.length" class="row mt-2">
						<div class="card p-3">
							<app-update-order-bundle [order]="order" [siteBundles]="siteBundles" (updateOrderBundle)="orderUpdated()"></app-update-order-bundle>
						</div>
					</div>
				</div>
			</div>
			<ng-container *ngIf="modifyingOrder">
				<div class="row">
					<div class="col-lg-8 col-12">
						<div class="card p-3">
							<div *ngIf="addNewPNSOrderItem; else updateDMOrderItem" class="card-body">
								<app-update-pns-order-item [orderItemId]="orderItemToUpdate" [order]="order" [newOrderItem]="addNewOrderItem" [hasLines]="orderItemToUpdateHasLines" (orderItemProcessed)="orderUpdated()" (cancelUpdate)="cancelUpdateOrderItem()"></app-update-pns-order-item>
							</div>
							<ng-template #updateDMOrderItem>
								<div class="card-body">
									<app-update-order-item [orderItemId]="orderItemToUpdate" [order]="order" [newOrderItem]="addNewOrderItem" [hasLines]="orderItemToUpdateHasLines" (orderItemProcessed)="orderUpdated()" (cancelUpdate)="cancelUpdateOrderItem()"></app-update-order-item>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</app-spinner-with-overlay>
</ng-container>

<ng-template #deleteOrderItemsModal>
	<app-confirm-modal title="Delete order items confirmation" confirmText="confirm" (confirmAction)="confirmDeleteOrderItem()"
		(closeModal)="closeModal()">
		<p>Are you sure you want to delete the following order item(s):</p>
		<ng-container *ngFor="let itemId of orderItemIdsToDelete">
			<div class="row">
				<div class="col">
					<small>
						{{ itemId }} - {{ getProductName(itemId) }} <span *ngIf="isRequiredPartOfBundle(itemId)">(Also removes the <strong>{{getBundleName(itemId)}}</strong> bundle )</span>
					</small>
				</div>
			</div>
		</ng-container>
        <div class="row mt-2" *ngIf="order?.orderBundles &&
            (order?.orderBundles?.length ?? 0) > 0 &&
            !isOrderBundleValid &&
            applicableBundles.length > 0">
            <div class="col">
                <small>
                    Choose from other available bundles:
                    <div class="form-floating">
                        <select type="select" id="newOrderBundle" class="form-select" [(ngModel)]="newBundleId">
                            <option *ngFor="let x of getApplicableBundles()" value="{{x.bundleId}}">
                                {{x.bundleId}} - {{x.bundle?.name}}
                            </option>
                        </select>
                        <label for="newOrderBundle">New Order Bundle</label>
                    </div>
                </small>
            </div>
        </div>
	</app-confirm-modal>
</ng-template>
