<div class="container-fluid selectionsBackground">
	<app-spinner-with-overlay [showSpinner]="showSpinner">
		<div class="totalLeadsBox pt-3">
			<div *ngIf="totalLeads > 0">
				<h4 class="text-black">Total Leads</h4>
				<h2 class="text-black">{{ totalLeads | number }}</h2>
			</div>
			<div *ngIf="totalLeads === 0">
				<h4 class="text-black">Make a selection</h4>
				<h2 class="text-black">{{totalLeads}}</h2>
			</div>
			<div class="row">
				<label (click)="audienceTypeChanged('addresses')">
					<input class="yes-no-radio"	checked="true" name="audienceType" type="radio" />
					One per mailing address (best practice)
				</label>
				<label (click)="audienceTypeChanged('households')">
					<input class="yes-no-radio" name="audienceType" type="radio" />
					One per household per mailing address
				</label>
				<label (click)="audienceTypeChanged('individuals')">
					<input class="yes-no-radio" name="audienceType" type="radio" />
					All individuals per mailing address
				</label>
			</div>
			<button type="button" [disabled]="totalLeads === 0" class="btn btn-secondary adBuilderBtn"
			(click)="openModal(distributionModal)">Save and Continue</button>
		</div>
	</app-spinner-with-overlay>
	<hr />

	<div class="row">
		<div class="col">
			<h3 class="text-black">Current Selections</h3>
		</div>
		<div class="col">
			<button type="button" class="btn btn-primary"
			(click)="clearSelections()">Clear Selections</button>
		</div>
	</div>

	<app-value-selection [selectionService]="pmSelectionService" title="Zip Codes" queryField="zipCodes"></app-value-selection>
	<app-address-selection [selectionService]="pmSelectionService" title="Address" queryField="radiusQuery"></app-address-selection>
	<app-slider-selection [selectionService]="pmSelectionService" title="Age" queryField="age"></app-slider-selection>
	<app-list-selection [selectionService]="pmSelectionService" title="Children" queryField="children_Present"></app-list-selection>
	<app-list-selection [selectionService]="pmSelectionService" title="Gender" queryField="gender"></app-list-selection>
	<app-list-selection [selectionService]="pmSelectionService" title="Home Owner" queryField="home_Owner_Renter"></app-list-selection>
	<app-list-selection [selectionService]="pmSelectionService" title="Marital Status" queryField="marital_Status"></app-list-selection>
	<app-list-selection [selectionService]="pmSelectionService" title="Education Individual Status" queryField="education_Individual"></app-list-selection>
	<app-slider-selection [selectionService]="pmSelectionService" title="Length Of Residence" queryField="length_Of_Residence"></app-slider-selection>
	<app-slider-selection [selectionService]="pmSelectionService" title="Home Built Year" queryField="home_Built_Year"></app-slider-selection>
	<app-list-selection [selectionService]="pmSelectionService" title="Dwelling Type" queryField="dwelling_Type"></app-list-selection>
	<app-slider-selection [selectionService]="pmSelectionService" title="Income" queryField="income_Code"></app-slider-selection>
	<app-slider-selection [selectionService]="pmSelectionService" title="Net Worth" queryField="networth_Code"></app-slider-selection>
	<app-slider-selection [selectionService]="pmSelectionService" title="Estimated Home Value" queryField="home_Value_Code"></app-slider-selection>
	<app-toggle-selection [selectionService]="pmSelectionService" title="Credit Card" queryFields="credit_Card_User"></app-toggle-selection>
	<app-toggle-selection [selectionService]="pmSelectionService" title="Charity Donor" queryFields="donor_Charitable"></app-toggle-selection>
	<app-toggle-selection [selectionService]="pmSelectionService" title="Book Reader" queryFields="reading_General"></app-toggle-selection>
	<app-toggle-selection [selectionService]="pmSelectionService" title="Technology" queryFields="high_Tech_General"></app-toggle-selection>

	<app-toggle-selection [selectionService]="pmSelectionService" title="Home, Garden & Crafts"
		queryFields="gardening,photography,sewing_Knitting_Needlework,woodworking"></app-toggle-selection>

	<app-toggle-selection [selectionService]="pmSelectionService" title="Travel"
		queryFields="travel_Cruises,travel_Domestic,travel_Grouping,travel_International"></app-toggle-selection>

	<app-toggle-selection [selectionService]="pmSelectionService" title="Fitness and Health"
		queryFields="exercise_Running_Jogging,exercise_Walking,exercise_Aerobic"></app-toggle-selection>

	<app-toggle-selection [selectionService]="pmSelectionService" title="Outdoor"
		queryFields="outdoor_Enthusiast_General,sports_Skiing,outdoor_Scuba_Diving,outdoor_Hunting_Shooting,outdoor_Fishing"></app-toggle-selection>

	<app-toggle-selection [selectionService]="pmSelectionService" title="Food & Cooking"
		queryFields="cooking_Gourmet,food_Wines,cooking_General,foods_Natural"></app-toggle-selection>

	<app-toggle-selection [selectionService]="pmSelectionService" title="Health, Diet & Self-Improvement"
		queryFields="health_Medical,dieting_Weight_Loss,self_Improvement"></app-toggle-selection>

	<app-toggle-selection [selectionService]="pmSelectionService" title="Sports"
		queryFields="spectator_Sports_General,spectator_Sports_Football,spectator_Sports_Baseball,sports_Golf,sports_Tennis,spectator_NASCAR">
	</app-toggle-selection>

	<app-toggle-selection [selectionService]="pmSelectionService" title="Home Improvement"
		queryFields="home_Improvement,home_Improvement_Diy"></app-toggle-selection>
	<app-list-preview (getSample)="getSample()" [query]="query" [totalLeads]="totalLeads"></app-list-preview>
</div>


<ng-template #distributionModal>
	<app-confirm-modal title="Save List" confirmText="Save List"
	(closeModal)="closeModals()" cancelText="Cancel" (confirmAction)="saveList()">
		<label>Map Name</label><br>
		<input type="text" [(ngModel)]='listName' class="form-control input-sm" maxlength="50" placeholder="List Name" />
	</app-confirm-modal>
</ng-template>

