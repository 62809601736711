import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import pageMenus, { MenuItem, NavSource } from 'app/config/page-menus';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'services/authentication.service';

@Component({
	selector: 'app-pagefullheader',
	templateUrl: './pagefullheader.component.html',
	styleUrls: ['./pagefullheader.component.scss']
})

export class PagefullheaderComponent implements OnInit, OnDestroy {
	@Input() subTitle?: string;
	@Input() subTitleClass?: string;
	history: string[] = [];
	menus = pageMenus;
	activeMenu: MenuItem | undefined;
	parentMenu: MenuItem | undefined;
	breadCrumbPath: MenuItem[] | undefined = [];
	subNavItems: MenuItem[] | undefined = [];
	routeParams!: Params;
	routePath: string | undefined;
	routeParsed = false;
	routeSub: Subscription;

	constructor(
		private authService: AuthenticationService,
		private router: Router,
		private route: ActivatedRoute,
		private location: Location
	) {
		this.routeSub = router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.routeParsed = false;
				this.parseRoute(route.snapshot);
			}
		});
	}

	ngOnInit() {
		this.parseRoute(this.route.snapshot);
	}

	ngOnDestroy() {
		this.routeSub.unsubscribe();
	}

	parseRoute(routeSnapshot: ActivatedRouteSnapshot) {
		if (this.routeParsed) {
			return;
		}
		this.routeParsed = true;
		this.routePath = routeSnapshot.routeConfig?.path ?? "";
		let curRouteSnap = routeSnapshot.firstChild;
		do {
			if (curRouteSnap?.routeConfig) {
				this.routePath += '/' + curRouteSnap.routeConfig.path;
			}
			else {
				break;
			}

			curRouteSnap = curRouteSnap.firstChild;
		} while (curRouteSnap && curRouteSnap.firstChild && curRouteSnap.firstChild.routeConfig);

		this.routeParams = routeSnapshot.params;

		// wanted to implement back button behavior on sub-sub-sub dead-end routes
		// this.router.events.subscribe((e) => {
		// 	if (e instanceof NavigationEnd) {
		// 		this.history.push(e.urlAfterRedirects);
		// 	}
		// });

		if (this.routePath) {
			if (!this.routePath.startsWith('/')) {
				this.routePath = '/' + this.routePath;
			}

			this.activeMenu = this.getActiveMenu(this.routePath!, this.menus);

			this.breadCrumbPath = this.getBreadcrumbPath(this.routePath!, this.menus);
			const homeMenu = this.getActiveMenu('/', this.menus);
			if (homeMenu) {
				this.breadCrumbPath?.unshift(homeMenu);
			}

			if (this.activeMenu && this.activeMenu.navsrc) {
				switch (this.activeMenu.navsrc) {
					case NavSource.Siblings:
						this.parentMenu = this.getParentmenu(this.routePath, this.menus);
						if (this.parentMenu) {
							this.subNavItems = JSON.parse(JSON.stringify(this.parentMenu?.submenu?.filter(m => !m.hidesubnav && ((m.organizations ?? []).length === 0 || m.organizations?.includes(this.authService.organizationId)))));
							this.subNavItems = this.subNavItems?.filter(i => (i.organizations ?? []).length === 0 || i.organizations?.includes(this.authService.organizationId));
							if (!this.subNavItems?.some(i => i.routeUrl === this.parentMenu?.routeUrl)) {
								this.subNavItems?.unshift(this.parentMenu!);
							}
						}

						break;
					case NavSource.Children:
						this.subNavItems = JSON.parse(JSON.stringify(this.activeMenu?.submenu?.filter(m => !m.hidesubnav && ((m.organizations ?? []).length === 0 || m.organizations?.includes(this.authService.organizationId)))));
						this.subNavItems = this.subNavItems?.filter(i => (i.organizations ?? []).length === 0 || i.organizations?.includes(this.authService.organizationId));
						if (this.subNavItems) {
							this.subNavItems.unshift(this.activeMenu);
						}
						break;
				}
			}
		}
	}

	back(): void {
		this.history.pop();
		if (this.history.length > 0) {
			this.location.back();
		}
		else {
			this.router.navigateByUrl('/');
		}
	}

	/// Starting with the activeRoute (ex. /customers/:customerId/distribution) and the full tree of menu items,
	/// recursively searches the menus, returning the item where routeUrl matches the value of activeRoute
	getActiveMenu(activeRoute: string, menuSet: MenuItem[] | undefined): MenuItem | undefined {
		if (menuSet === undefined) {
			return undefined;
		}
		for (let menu of menuSet) {
			const routeMatch = (menu.routeUrl.toLowerCase() === activeRoute.toLowerCase());
			if (routeMatch) {
				return menu;
			}
			const menuSubs = menu.submenu;
			if (menuSubs !== undefined && menuSubs.length > 0) {
				const matchMenu = this.getActiveMenu(activeRoute, menuSubs);
				if (matchMenu !== undefined) {
					return matchMenu;
				}
			}
		}
		return undefined;
	}

	getBreadcrumbPath(activeRoute: string, menuSet: MenuItem[]): MenuItem[] | undefined {
		for (const menu of menuSet) {
            if ((menu.organizations ?? []).length > 0 && !menu.organizations?.includes(this.authService.organizationId)) {
                continue;
            }
			const exactMatch = menu.routeUrl?.toLowerCase() === activeRoute.toLowerCase();
			if (!exactMatch) {
				if (menu.submenu) {
					const path = this.getBreadcrumbPath(activeRoute, menu.submenu);
					if (path) {
						path.unshift(menu);
						return path;
					}
				}
			}
			else {
				return [menu];
			}
		}
		return;
	}

	getParentmenu(activeRoute: string, menuSet: MenuItem[]): MenuItem | undefined {
		for (const menu of menuSet) {
            if ((menu.organizations ?? []).length > 0 && !menu.organizations?.includes(this.authService.organizationId)) {
                continue;
            }
			let ret = this.determineParent(activeRoute, menu, menu);
			if (ret) {
				return ret;
			}
		}
		return;
	}

	determineParent(activeRoute: string, curNode: MenuItem, lastParent: MenuItem): MenuItem | undefined {
		const exactMatch = (curNode.routeUrl.toLowerCase() === activeRoute.toLowerCase());
		if (exactMatch) {
			return lastParent;
		}
		if (curNode.submenu) {
			for (const node of curNode.submenu) {
				let retNode = this.determineParent(activeRoute, node, curNode);
				if (retNode !== undefined) {
					return retNode;
				}
			}
		}
		return;
	}

	resolveRouteUrl(item: MenuItem): string {
		if (item.routeUrl) {
			let routeUrl = item.routeUrl;
			for (let param of Object.keys(this.routeParams)) {
				routeUrl = routeUrl.replace('/:' + param, '/' + this.route.snapshot.params[param]);
			}
			return routeUrl;
		}
		return '#';
	}
}
