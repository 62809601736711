<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="row mb-3">
			<div class="col-sm-12 col-md-3">
				<div class="form-floating mb-3">
					<input type="date" id="startDate" class="form-control"
						[ngModel]="apiQuery.startDate | date: 'yyyy-MM-dd'" max="9999-12-31"
						(change)="paramStartDate = getDate($any($event).target.value)" placeholder="Start Date">
					<label for="startDate">Start Date</label>
				</div>
			</div>

			<div class="col-sm-12 col-md-3">
				<div class="form-floating mb-3">
					<input type="date" id="endDate" class="form-control" [ngModel]="apiQuery.endDate | date: 'yyyy-MM-dd'"
						max="9999-12-31" (change)="paramEndDate = getDate($any($event).target.value)"
						placeholder="End Date">
					<label for="endDate">End Date</label>
				</div>
			</div>
		</div>

		<div class="row mb-3">
			<div class="col-sm-12 col-md-3">
				<div class="form-group">
					<label for="companyName" data-toggle="tooltip" data-placement="top" title="Matches partial names, case insensitive">
						Company Name
						<i class="fa fa-info-circle"></i>
					</label>
					<input type="text" id="paramPartialCompanyName" class="form-control" [(ngModel)]="paramPartialCompanyName">
				</div>
			</div>
			<div class="col-sm-12 col-md-3">
				<div class="form-group">
					<label for="veritcalFilter">
						Vertical Filter
						<span data-toggle="tooltip" data-placement="top" title="Non-functional placeholder">
							(WIP<i class="fa fa-wrench"></i>)
						</span>
					</label>
					<select id="veritcalFilter" class="form-select">
						<option value="Dental">Dental</option>
						<option value="Restaurant">Restaurant</option>
						<option value="TouristAttraction">Tourist Attraction</option>
					</select>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<button class="btn btn-info" (click)="submit()">Load Report</button>
				<button class="btn btn-info" (click)="downloadReport()">Download Report</button>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<ag-grid-angular style="width: 100%; height: 700px;"
					[class]="agThemeClass"
					[defaultColDef]="agDefaultColDef"
					[columnTypes]="agColumnTypes"
					[columnDefs]="agColumnDefs"
					[rowData]="rowData"
					[rowGroupPanelShow]="'always'"
					[groupDisplayType]="'singleColumn'"
					[autoGroupColumnDef]="agAutoGroupColumnDef"
					[sideBar]="agSideBar"
					[statusBar]="agStatusBar"
					[autoSizeStrategy]="agAutoSizeStrategy"
					>

				</ag-grid-angular>
			</div>
		</div>

		<div class="row">
			<div class="col-6" style="margin-top: 20px;">
				<h2>Taralytics Table Tips</h2>
				<ul>
					<li>Sort by: Click a column header to sort by that column.</li>
					<li>Group by: Drag a column header into the top row to group by that column.</li>
					<li>Group by multiple: Drag multiple column headers for advanced grouping.</li>
					<li>Show/hide columns: In the right sidebar, toggle columns to show or hide them.</li>
					<li>Sum or average data: In the right sidebar "Values" section, click a column to change how that data is aggregated.</li>
					<li>Filter: In the right sidebar "Filters" tab, expand a column then filter out data you don't want to see.</li>
				</ul>
				<h2>Taralytics Data Analysis Tips</h2>
				<ul>
					<li>See performance by day-of-week: Group by "Day" then "Company" to see how different companies perform on different days of the week.</li>
					<li>See average performance across a week: Group by "Week", then change columns to average (avg) to see how campaigns performed across entire weeks.</li>
					<li>Compare specific companies: First, group by "Company", then in the right sidebar "Filters" tab, expand "Company" then deselect all, and select the specific companies you want to see.</li>
				</ul>
			</div>
		</div>

	</div>
</app-spinner-with-overlay>
