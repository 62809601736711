<app-pagefullheader></app-pagefullheader>
<div class="card p-3 mb-3">
	<app-spinner-with-overlay [showSpinner]="loading">
        <div class="row">
            <div class="col-12 text-end">
                <button class="btn btn-danger mx-1" (click)="deleteAllDesignTurnaround()" type="button">
                    Remove All Design Turnaround
                </button>
                <button class="btn btn-primary mx-1" (click)="updateAllDesignTurnaround()" type="button">
                    Update All Design Turnaround
                </button>
            </div>
        </div>

		<div class="card border-0 p-3">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th width="10%">USelect Id</th>
						<th width="10%">USelect Name</th>
						<th>Design Type</th>
						<th>Turnaround Time</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let designTurnaround of designTurnarounds; let i = index;">
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="productUSelectId" min="1" class="form-control"
								[(ngModel)]="designTurnaround.uSelectId" disabled>
							</div>
						</td>
						<td width="30%">
							<div class="form-floating mb-3">
								<input type="text" id="productUSelectName" class="form-control"
								value="{{getUSelectName(designTurnaround.uSelectId)}}" disabled>
							</div>
						</td>
						<td width="10%">
							<div class="form-floating mb-3">
								<app-design-type  [designType]="getDesignType(designTurnaround.designType)" [array]="designTypes" (selectedMethod)="getUpdateMethod($event, i)"></app-design-type>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="number" id="turnaround" class="form-control"
								[(ngModel)]="designTurnaround.turnaroundDays">
							</div>
						</td>
                        <td>
                            <li class="list-inline-item mt-3">
                                <button class="btn btn-danger btn-sm" (click)="removeSelectedDesignTurnaround(i)" data-toggle="tooltip"
                                    data-placement="top" Title="Delete">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </li>
                        </td>
					</tr>
					<tr *ngIf="newDesignTurnaround !== undefined">
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="productUSelectId" min="1" class="form-control"
								[(ngModel)]="newDesignTurnaround!.uSelectId" disabled>
							</div>
						</td>
						<td width="25%">
							<div class="form-floating mb-3">
								<input type="text" id="newProductUSelectName" class="form-control"
								value="{{getUSelectName(productUSelectId)}}" disabled>
							</div>
						</td>
						<td width="25%">
							<div class="form-floating mb-3">
								<app-design-type #designTypeListInput [array]="designTypes" (selectedMethod)="getMethod($event)"></app-design-type>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="number" id="turnaround" class="form-control"
								[(ngModel)]="newDesignTurnaround.turnaroundDays">
							</div>
						</td>
						<td>
							<ul class="list-inline">
								<li class="list-inline-item  mt-3">
									<button class="btn btn-success" (click)="addNewDesignTurnaround()"
									[disabled]="(newDesignTurnaround!.designType === 0)">Add</button>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-spinner-with-overlay>
</div>

