import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RecurringPayment, RecurringPaymentDisplay, ScheduledPayment, GetOrderBalanceQuery, CpaStatus, PaymentProfile } from '@taradel/admin-api-client';
import { OrderService } from 'services/order.service';
import { ToastService } from 'services/toast.service';
import { PaymentsService } from 'services/payments.service';
import { SalesApiService } from 'services/sales-api.service';
import { AuthenticationService } from 'services/authentication.service';

@Component({
  selector: 'app-recurring-payments',
  templateUrl: './recurring-payments.component.html',
  styleUrls: ['./recurring-payments.component.scss']
})
export class RecurringPaymentsComponent implements OnInit {
	loading = false;
	@Input() customerId = 0;
	today = new Date(Date.now());
	recurringPayments: RecurringPaymentDisplay[] = [];
	customerPaymentProfiles: PaymentProfile[] = [];
	viewIndex?: number;
	hasRemainingOrderBalances = false;

	constructor(
		public authService: AuthenticationService,
		private salesApiService: SalesApiService,
		private orderService: OrderService,
		private route: ActivatedRoute,
		private toastService: ToastService,
		private paymentsService: PaymentsService
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
	}

	async ngOnInit() {

		try {
			this.loading = true;
			await this.getCustomerPaymentProfiles();
			this.recurringPayments = await this.paymentsService.getRecurringPayments(this.customerId);
			const orderToExclude: number[] = [];
			const query = {
				customerId: this.customerId,
				ordersToExclude: orderToExclude
			} as GetOrderBalanceQuery;
			const balances = await this.orderService.getCustomerRemainingOrderBalance(query);
			this.hasRemainingOrderBalances = !!balances;
		}
		catch {
			this.toastService.showError('There was an error loading customer recurrimg payments');
		}
		finally {
			this.loading = false;
		}

	}

	async updateRecurringPayment(recurringPayment: RecurringPayment) {
		try {
			this.loading = true;
			// update recurring payment
			await this.paymentsService.updateRecurringPayment(recurringPayment.recurringPaymentId, recurringPayment.paymentProfileId, recurringPayment.status!);
			this.toastService.showSuccess('Recurring payment status updated successfully');
		}
		catch {
			this.toastService.showError('There was an error updating recurring payment status');
		}
		finally {
			this.loading = false;
		}
	}

	async updateScheduledPayment(scheduledPayment: ScheduledPayment) {
		try {
			this.loading = true;
			// update scheduled payment
			await this.paymentsService.updateScheduledPayment(scheduledPayment.scheduledPaymentId, scheduledPayment.paymentDueDate);
			this.toastService.showSuccess('Scheduled payment due date updated successfully');
		}
		catch {
			this.toastService.showError('There was an error updating Scheduled payment due date');
		}
		finally {
			this.loading = false;
		}
	}


	dueDateSelected(scheduledPayment: ScheduledPayment, startDate: string) {
		if (startDate.length > 0) {
			const year = parseInt(startDate.substring(0, 4), 10);
			const month = parseInt(startDate.substring(5, 7), 10) - 1;
			const day = parseInt(startDate.substring(8, 10), 10);
			scheduledPayment.paymentDueDate = new Date(year, month, day);
		}
	}
	getSchedulePaymentAttempt(scheduledPayment: ScheduledPayment) {

		if ((scheduledPayment.scheduledPaymentAttempts?.length ?? 0) === 0) {
			return undefined;
		}

		const success = scheduledPayment.scheduledPaymentAttempts?.find(x => x.customerPaymentAttempt?.status === CpaStatus.Success);
		const declined = scheduledPayment.scheduledPaymentAttempts?.find(x => x.customerPaymentAttempt?.status === CpaStatus.Declined);
		const failed = scheduledPayment.scheduledPaymentAttempts?.find(x => x.customerPaymentAttempt?.status === CpaStatus.Failed);

		if (!!success) {
			return success;
		}
		else if (!!declined) {
			return declined;
		}
		else if (!!failed) {
			return failed;
		}

		return undefined;
	}

	async getCustomerPaymentProfiles() {
		const customerProfile = await this.salesApiService.getCustomerProfile(this.customerId);

		if (!!customerProfile) {
			this.customerPaymentProfiles = [];

			customerProfile.paymentProfiles?.forEach(x => this.customerPaymentProfiles.push(x));
		}
	}
}
