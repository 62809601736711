<app-pagefullheader></app-pagefullheader>

<div class="card border-0 p-3">
	<div class="input-group mb-3">
		<span class="input-group-text">Filter</span>
		<input type="text" class="form-control" #usernameInput (keyup)="usernameSearch.next(usernameInput.value)"
			placeholder="Search by username" />
		<div class="input-group-text">
			<div class="form-check">
				<input type="checkbox" id="adminCheckBox" class="form-check-input" [(ngModel)]="adminsOnly"
					(ngModelChange)="refreshUsers()">
				<label for="adminCheckBox" class="form-check-label">Admins only</label>
			</div>
		</div>
	</div>

	<app-spinner-with-overlay [showSpinner]="loading">
		<ngb-pagination [collectionSize]="usersCount" [(page)]="pageNo" [maxSize]="10" [pageSize]="pageSize"
			[rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged()">
		</ngb-pagination>

		<div class="table-responsive">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Customer ID</th>
						<th>Username</th>
						<th>E-mail Address</th>
						<th>Organization</th>
						<th class="text-center">Failed Login Attempts</th>
						<th class="text-center">Account Locked</th>
						<th class="text-center">&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let user of usersList">
						<td>{{user.customerId}}</td>
						<td>{{user.username}}</td>
						<td>{{user.login!.user!.email}}</td>
						<td>{{getOrgName(user.organizationId)}}</td>
						<td class="text-center">{{user.login!.user!.accessFailedCount}}</td>
						<td class="text-center"><span class="fa"
								[ngClass]="{'fa-lock text-danger': lockedOut(user), 'fa-unlock': !lockedOut(user)}"></span>
						</td>
						<td class="text-center"><a [routerLink]="['/users/', user.customerId!]"
								class="btn btn-primary">Edit</a></td>
					</tr>
				</tbody>
			</table>
		</div>

		<ngb-pagination [collectionSize]="usersCount" [(page)]="pageNo" [maxSize]="10" [pageSize]="pageSize"
			[rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged()">
		</ngb-pagination>
	</app-spinner-with-overlay>
</div>

<!-- Some random crap that was floating around in here-->
<div class="form-group" *ngIf="showAddCustomer">
	<label for="usernameInput">Username:</label>
	<input [ngModel]="addUsername" type="text" id="usernameInput" class="form-control">
	<label for="firstNameInput">First Name:</label>
	<input [ngModel]="addFirstName" type="text" id="firstNameInput" class="form-control">
	<label for="lastNameInput">Last Name:</label>
	<input [ngModel]="addLastName" type="text" id="lastNameInput" class="form-control">
	<label for="emailInput">E-mail Address:</label>
	<input [ngModel]="addEmailAddress" type="text" id="emailInput" class="form-control">
	<label for="passwordInput">Password: </label>
	<input [ngModel]="addPassword" type="text" id="passwordInput" class="form-control">
</div>
<!-- <p>
	<button class="btn btn-primary" (click) = "showAddUserButtonEvent()"> Add User </button>
</p> -->
