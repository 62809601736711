<button class="btn btn-primary btn-sm" [disabled]="disableChangeDistribution" [ngClass]="buttonClass" [disabled]="loading" (click)="changeDist()">Change Distribution</button>

<ng-template #changeDistribution let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Change Distribution</h4>
		<button type="button" class="btn-close" aria-label="Close" [disabled]="loading"
			(click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<app-alert-box *ngIf="changeDistMsg" alertMode="danger">
			{{changeDistMsg}}
		</app-alert-box>

		<p>Changing the distribution will remove all areas currently allocated to drops and reassign them from the newly
			selected distribution.</p>
		<p>You should not change the distribution after job has started dropping.</p>
		<table class="table table-hover" *ngIf="!selectedDistribution">
			<thead>
				<tr>
					<th>Name</th>
					<th>Created Date</th>
					<th>Total Deliveries</th>
					<th>Difference</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let dist of distributions">
					<td>{{ dist.name }}</td>
					<td>{{ dist.createdDate.toLocaleDateString() }}</td>
					<td>{{ dist.totalDeliveries | number }}</td>
					<td>{{ (dist.totalDeliveries - orderLayoutService.getDistributionTotalDeliveries(dropNumber) >
						0 ? '+' : '') }}{{ (dist.totalDeliveries -
							orderLayoutService.getDistributionTotalDeliveries(dropNumber)) | number}}</td>
					<td>
						<button class="btn btn-primary btn-sm" (click)="setDistribution(dist)">Select</button>
					</td>
				</tr>
			</tbody>
		</table>
		<div *ngIf="selectedDistribution">
			<p>You have selected <b>{{selectedDistribution.name}}</b>, which has {{selectedDistribution.totalDeliveries
				| number}} total deliveries ({{(Math.abs(selectedDistribution.totalDeliveries -
					orderLayoutService.getDistributionTotalDeliveries(dropNumber))) | number}} <b>{{
					(selectedDistribution.totalDeliveries - orderLayoutService.getDistributionTotalDeliveries(dropNumber) > 0 ?
					'MORE' : 'FEWER') }}</b> deliveries). </p>
			<p class="text-right"><button class="btn btn-link" (click)="selectedDistribution = undefined">Choose a
					different distribution</button></p>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link" [disabled]="loading" (click)="modal.dismiss('Cancel Click'); selectedDistribution = undefined;">Cancel</button>
		<button class="btn btn-primary" [disabled]="loading" *ngIf="selectedDistribution"
			(click)="selectDistribution()">Apply Distribution Change</button>
	</div>
</ng-template>
