import { Pipe, PipeTransform } from '@angular/core';

export type SortOrder = 'asc' | 'desc';

@Pipe({
  name: 'sort'
})

export class SortPipe implements PipeTransform {

	transform(values: any[], sortOrder: SortOrder = 'asc', sortKey?: string): any {
		if ((values?.length ?? 0) === 0) {
			return values;
		}

		let numberArray = [];
		let stringArray = [];

		if (!sortKey) {
			if (values.some(x => typeof x === 'number')) {
				numberArray = values.sort();
			}
			else if (values.some(x => typeof x === 'string')) {
				stringArray = values.sort((a, b) => {
					if (a.toUpperCase() < b.toUpperCase()) return -1;
					else if (a.toUpperCase() > b.toUpperCase()) return 1;
					else return 0;
				});
			}
		}
		else {
			if (values.some(x => typeof x[sortKey] === 'number')) {
				numberArray = values.sort((a, b) => a[sortKey] - b[sortKey]);
			}
			if (values.some(x => typeof x[sortKey] === 'string')) {
				stringArray = values.sort((a, b) => {
					if (a[sortKey].toUpperCase() < b[sortKey].toUpperCase()) return -1;
					else if (a[sortKey].toUpperCase() > b[sortKey].toUpperCase()) return 1;
					else return 0;
				});
			}
		}
		if (numberArray.length > 0) {
			return sortOrder === 'asc' ? numberArray : numberArray.reverse();
		}
		else if (stringArray.length > 0) {
			return sortOrder === 'asc' ? stringArray : stringArray.reverse();
		}

		return values;
	}
}
