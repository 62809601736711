<!-- begin page-header -->
<div class="row align-items-end mb-3" *ngIf="routePath">
	<div class="col-12">
		<div class="breadcrumb" *ngIf="this.activeMenu">
			<li *ngFor="let item of breadCrumbPath; let last = last;" class="breadcrumb-item"
				[ngClass]="{'active': last}">
				<a [routerLink]="resolveRouteUrl(item)" *ngIf="!last && item.url && item.url !== ''">{{ item.title }}</a>
				<span *ngIf="last || !item.url || item.url === ''">{{ item.title }}</span>
			</li>
		</div>
	</div>
	<div class="col-auto">
		<h1 class="page-header m-0 p-0">
			<span class="fa-stack" *ngIf="activeMenu?.icon && activeMenu?.icon !== ''">
				<i class="fas fa-circle fa-stack-2x fa-inverse"></i>
				<i class="fa-stack-1x text-success" [ngClass]="[activeMenu?.icon]"></i>
			</span>
			{{ activeMenu?.title! }}<br />
			<small [ngClass]="subTitleClass ? subTitleClass : ''" *ngIf="subTitle">{{ subTitle }}</small>
		</h1>
	</div>
	<div class="col-12 col-lg">
		<div class="ms-auto d-none d-lg-block">
			<ul class="nav nav-pills justify-content-end" *ngIf="subNavItems">
				<li class="nav-item" *ngFor="let sub of subNavItems">
					<a class="nav-link" [ngClass]="{'active': sub.url === activeMenu?.url}"
						[routerLink]="resolveRouteUrl(sub)">{{ sub.title }}</a>
				</li>
				<ng-content select="[addnav]"></ng-content>
			</ul>
			<ng-content select="[altnav]"></ng-content>
		</div>
		<div class="dropdown d-block d-lg-none mt-2" *ngIf="subNavItems">
			<a class="btn btn-secondary dropdown-toggle" type="button" id="sectionNavigationDropDown"
				data-bs-toggle="dropdown" aria-expanded="false">
				Section Navigation
			</a>
			<ul class="dropdown-menu" aria-labelledby="sectionNavigationDropDown">
				<li *ngFor="let sub of subNavItems">
					<a class="dropdown-item" [ngClass]="{'active': sub.url === activeMenu?.url}"
						[routerLink]="resolveRouteUrl(sub)">{{ sub.title }}</a>
				</li>
			</ul>
		</div>
	</div>
</div>
<hr />
<!-- end page-header -->
