import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EquifaxClient as WebEquifaxClient, EquifaxMatchedAddress } from '@taradel/web-api-client';
import { BuiltEquifaxB2BDistribution, EquifaxClient, EquifaxQuery, SaveListQuery } from '@taradel/admin-api-client';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EquifaxDataService {

	private equifaxClient: EquifaxClient;
	private webEquifaxClient: WebEquifaxClient;

	constructor(http: HttpClient) {
		this.equifaxClient = new EquifaxClient(http, environment.adminApiUrl);
		this.webEquifaxClient = new WebEquifaxClient(http, environment.webApiUrl);
	}

	getCount(query: EquifaxQuery): Promise<number> {
		return lastValueFrom(this.webEquifaxClient.getCount(query));
	}

	getBusinessList(distributionId: number): Promise<BuiltEquifaxB2BDistribution> {
		return lastValueFrom(this.equifaxClient.getBuiltList(distributionId));
	}

	saveBusinessList(customerId: number, name: string, query: EquifaxQuery): Promise<number> {
		return lastValueFrom(this.equifaxClient.saveBusinessList(new SaveListQuery({
			customerId: customerId,
			name,
			query
		})));
	}

	getAddressPreview(query: EquifaxQuery): Promise<EquifaxMatchedAddress[]> {
		return lastValueFrom(this.webEquifaxClient.getAddressPreview(query));
	}
}
