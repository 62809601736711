import { Component, OnInit } from '@angular/core';
import { LogEntry, LogSearch, PaginationQuery } from '@taradel/admin-api-client';
import { LogsService } from 'services/logs.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-logs',
	templateUrl: './logs.component.html',
	styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

	loading = false;
	logEntries: LogEntry[] = [];
	total = 0;
	pageSize = 20;
	from = new Date(new Date().valueOf() - 1000*60*60*24);
	to = new Date();
	hideExceptions: boolean[] = [];

	logSearch = new LogSearch({
		from: this.from,
		to: this.to,
		levels: ['Info', 'Warn', 'Error'],
		logger: '',
		requestUrl: '',
		referer: '',
		message: '',
		exception: '',
		pagination: new PaginationQuery({
			pageNo: 1,
			pageSize: this.pageSize
		})
	});

	constructor(
		private logsService: LogsService,
		private toastService: ToastService
	) { }

	async ngOnInit(): Promise<void> {
		await this.search();
	}

	getDate(value: string) {
		const [year, month, day] = value.split('-');
		return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
	}
	async search(): Promise<void> {
		try {
			this.loading = true;
			this.logSearch.from = this.from;
			this.logSearch.to = this.to;
			this.logEntries = await this.logsService.getLogs(this.logSearch);
			this.hideExceptions = new Array(this.logEntries.length).fill(true);
			this.total = await this.logsService.getLogsCount(this.logSearch);
		}
		catch (error) {
			this.toastService.showError('Getting logs failed');
		}
		finally {
			this.loading = false;
		}
	}

	async pageChanged(): Promise<void> {
		try {
			this.loading = true;
			this.logSearch.pagination!.pageSize = this.pageSize;
			this.logEntries = await this.logsService.getLogs(this.logSearch);
			this.hideExceptions = new Array(this.logEntries.length).fill(true);
		}
		catch (error) {
			this.toastService.showError('Getting logs failed');
		}
		finally {
			this.loading = false;
		}
	}
}
