<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card p-3 mb-3">
		<div class="ms-auto">
			<a href="#" class="btn btn-success btn-rounded px-4 rounded-pill" [routerLink]="['add']"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add USelect</a>
		</div>

		<div class="table-responsive">
			<table class="table table-hover table-panel caption-top">
				<thead>
					<tr>
						<th width="7%">USelect Id</th>
						<th>Name</th>
						<th>Display Name</th>
						<th *ngIf="countryCode === 'Canada'" width="16%">Translated Name</th>
						<th>Description</th>
						<th>Product Class</th>
						<th>Is Direct Shippable</th>
						<th>Featured Product Id</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let uSelectMethod of uSelectMethods">
						<td>
							{{uSelectMethod.uSelectId}}
						</td>
						<td>
							{{uSelectMethod.name}}
						</td>
						<td>
							{{uSelectMethod.shortDescription}}
						</td>
						<td *ngIf="countryCode === 'Canada'">
							<app-uselects-languages [uSelectId]="uSelectMethod.uSelectId" [languagesList]="uSelectLanguages"></app-uselects-languages>
						</td>
						<td>
							{{uSelectMethod.description}}
						</td>
						<td>
							{{uSelectMethod.productClass}}
						</td>
						<td>
							{{uSelectMethod.isDirectShippable}}
						</td>
						<td>
							{{getProductName(uSelectMethod.featuredProductId)}}
						</td>
						<td>
							<div class="btn-group">
								<a type="button" class="btn btn-primary" [routerLink]="['/products', 'uselects', uSelectMethod.uSelectId]">Edit</a>
								<button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
									<span class="visually-hidden">Toggle Dropdown</span>
								</button>
								<ul class="dropdown-menu" role="menu">
									<li role="menuitem"><a [routerLink]="['/products', 'uselects', uSelectMethod.uSelectId, 'update-postage']" class="dropdown-item">Update USelect Products Postage</a></li>
									<li role="menuitem"><a [routerLink]="['/products', 'uselects', uSelectMethod.uSelectId, 'powerups']" class="dropdown-item">Product PowerUps</a></li>
									<li role="menuitem"><a [routerLink]="['/products', 'uselects', uSelectMethod.uSelectId, 'design-turnaround']" class="dropdown-item">Design Turnaround</a></li>
								</ul>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</app-spinner-with-overlay>
