<app-spinner-with-overlay [showSpinner]="orderLayoutService.loadingOrder || loading">
	<div class="row mb-3">
		<div class="col-md-4">
			<div class="card h-100">
				<div class="card-body">
					<h5 class="card-title">Charges</h5>
					<table class="table table-sm">
						<tbody>
							<tr>
								<td>Order SubTotal</td>
								<td class="text-end">{{ orderLayoutService.selectedOrder?.subtotal | currency}}</td>
							</tr>
							<tr *ngIf="orderLayoutService.selectedOrder?.discount ?? 0 > 0">
								<td>Order Discount</td>
								<td class="text-end">{{ orderLayoutService.selectedOrder?.discount | currency}}</td>
							</tr>
							<tr *ngIf="orderLayoutService.selectedOrder?.shipping ?? 0 > 0">
								<td>Shipping</td>
								<td class="text-end">{{ orderLayoutService.selectedOrder?.shipping | currency}}</td>
							</tr>
							<tr *ngIf="orderLayoutService.selectedOrder?.shippingDiscount ?? 0 > 0">
								<td>Shipping Discount</td>
								<td class="text-end">{{ orderLayoutService.selectedOrder?.shippingDiscount | currency}}</td>
							</tr>
							<tr *ngIf="orderLayoutService.couponDiscount.value !== 0">
								<td>Coupon Discount ({{orderLayoutService.selectedOrder?.couponCode}})</td>
								<td class="text-end">{{ orderLayoutService.couponDiscount.value | currency}}</td>
							</tr>
							<tr *ngIf="orderLayoutService.bundleDiscount.value !== 0">
								<td>Bundle Discount</td>
								<td class="text-end">{{ orderLayoutService.bundleDiscount.value | currency}}</td>
							</tr>
							<tr>
								<td>Sales Tax</td>
								<td class="text-end">{{ orderLayoutService.selectedOrder?.salesTax | currency}}</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td>Total</td>
								<td class="text-end">{{ orderLayoutService.selectedOrder?.orderAmount | currency }}</td>
							</tr>
							<tr>
								<td>Paid Amount</td>
								<td class="text-end">{{ orderLayoutService.totalPayment.format() }}</td>
							</tr>
							<tr [ngClass]="{'text-danger': orderLayoutService.orderBalance.value > 0}">
								<td><span *ngIf="orderLayoutService.orderBalance.value > 0">Balance</span></td>
								<td class="text-end">
									<span *ngIf="orderLayoutService.orderBalance.value > 0">{{ orderLayoutService.orderBalance.format() }}</span>
									<span *ngIf="orderLayoutService.orderBalance.value === 0"><b class="text-green-500">PAID IN FULL</b></span>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card h-100">
				<div class="card-body">
					<h5 class="card-title">Billing Contact</h5>
					<div class="mb-3">
						<span *ngIf="orderLayoutService.selectedOrder?.fullName !== ''">{{orderLayoutService.selectedOrder?.fullName}} </span><br />
						<span *ngIf="orderLayoutService.selectedOrder?.companyName !== ''">{{orderLayoutService.selectedOrder?.companyName}} </span><br />
						{{orderLayoutService.selectedOrder?.address}}<br />
						{{orderLayoutService.selectedOrder?.city}} {{orderLayoutService.selectedOrder?.state}}, {{orderLayoutService.selectedOrder?.zipCode}}</div>

					<div>
						<div><b>Phone: </b> {{orderLayoutService.selectedOrder?.phoneNumber}}</div>
						<div *ngIf="orderLayoutService.selectedOrder?.emailAddress && orderLayoutService.selectedOrder?.emailAddress!.length > 0">
							<b>Email: </b> <a [href]="'mailto:' + orderLayoutService.selectedOrder?.emailAddress">{{orderLayoutService.selectedOrder?.emailAddress}}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-4">
			<div class="card h-100">
				<div class="card-body">
					<h5 class="card-title">Order Actions</h5>
					<div class="row mb-1">
						<div class="col-6">
							<button class="btn btn-primary" (click)="getPDFReceipt(orderLayoutService.orderId)">Download PDF Receipt <i class="fa fa-download"></i></button>
						</div>
					</div>
					<div class="row">
						<div class="col-6" *ngIf="authService.organizationId === 1 && !orderHasPO() && !orderLayoutService.selectedOrder?.deleted">
							<button class="btn btn-danger" (click)="modalService.open(confirmDelete)">Delete Order <i class="fa fa-trash"></i></button>
						</div>
						<div class="col-6" *ngIf="authService.organizationId === 1 && !orderHasPO() && orderLayoutService.selectedOrder?.deleted">
							<button class="btn btn-success" (click)="modalService.open(confirmRestore)">Restore Order <i class="fa fa-flask"></i></button>
						</div>
						<div class="col-6">
							<button class="btn btn-primary" *ngIf="this.orderLayoutService.orderHasPurchasedList && !orderLayoutService.isListAvailable" (click)="reGenerateList()">Regenerate List <i class="fa fa-redo"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<ng-container *ngIf="isCanada">
		<div class="row mb-3">
			<div class="col-md-4 col-12">
				<div class="card mb-3">
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<h5 class="card-title">Update VentureOne Number</h5>
								<div class="form-floating my-3">
									<input type="text" [(ngModel)]="orderLayoutService.ventureOneNumber" name="ventureone_number" [minlength]="7" [maxlength]="20"
									[disabled]="authService.organizationId !== 1"
									[ngClass]="{ 'is-invalid': ventureOneNumberInvalid }" class="form-control">
									<label for="ventureOne">Venture One</label>
								</div>
								<div *ngIf="ventureOneNumberInvalid" class="input-error">
									VentureOne Number should be between 7 and 20 characters in length
								</div>
								<div *ngIf="authService.organizationId === 1" class="row d-flex justify-content-end mt-2">
									<div class="col col-auto">
										<button type="button" class="btn btn-primary" (click)="updateVentureOneNumber()">
											Update
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<div class="row mb-3">
		<div class="col">
			<div class="card mb-3" *ngFor="let orderItem of orderLayoutService.selectedOrderItems" [ngClass]="{ 'bg-danger': orderItem.orderItem.deleted }">
				<div class="card-body">
					<div class="row">
						<div class="col-auto">
							<h5 class="card-title">
								{{orderItem.productName}} x {{orderItem.quantity | number}} (Item: {{orderItem.orderItemId}})
								<span *ngIf="orderItem.orderItem.deleted"> - Deleted</span>
							</h5>
						</div>
						<div class="col text-end">
							<a class="btn btn-primary" [routerLink]="['/orders', orderLayoutService.orderId, 'details', orderItem.orderItemId]">View Item</a>
						</div>
					</div>

					<div class="row">
						<div class="col-md-6"><b>Job Name: </b> {{orderItem.jobName}} <br />
							<b>Comments: </b>{{orderItem.jobComments}}
						</div>
						<div class="col-md-6">
							<div *ngFor="let attribute of orderItem.orderItem.attributes">
								<b>{{attribute.attributeName}}: </b> {{attribute.attributeValue}}
							</div>
							<div>
								<b>{{orderLayoutService.getDesignName(orderItem.designType)}}: </b> {{orderLayoutService.getDesignFee(orderItem.orderItem) | currency}}
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</app-spinner-with-overlay>

<ng-template #confirmDelete let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Confirm Order Delete</h4>
		<button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p>This will delete the order.</p>
		<p>Please note that completing this action will also delete the round robin selection & avalara transaction.</p>

		<div class="row">
			<h5>What is the delete reason?</h5>
			<div class="col-8 p-0 form-floating">
				<select class="form-select" name="deleteReason" (change)="deleteReasonChange($event)"
				[ngClass]="{ 'is-invalid': submitted && deleteReason.length === 0 }">
					<option disabled selected>Select a delete reason</option>
					<option value="CustomerRequest">Customer Requested Cancellation</option>
					<option value="Disaster">Natural Disaster/Global Pandemic</option>
					<option value="NonPayment">Non Payment</option>
					<option value="NoResponse">No Response from Customer</option>
					<option value="Replacement">Other Changes Requiring Replacement</option>
					<option value="SpecAd">Spec Ad</option>
					<option value="TestOrder">Test Order</option>
					<option value="Other">Other</option>
				</select>
				<label for="deleteReason">Choose Delete Reason</label>
			</div>
			<div class="row">
				<div *ngIf="deleteReason === 'Other'" class="col-8 p-0 mt-2 form-floating">
					<input type="text" id="otherReason" name="otherReason" class="form-control"
						[ngClass]="{ 'is-invalid': submitted && otherReason.length === 0 }"
						[(ngModel)]="otherReason">
					<label for="otherReason">Other Reason</label>
				</div>
			</div>
		</div>

		<div class="row mt-2" *ngIf="orderLayoutService.totalPayment.value > 0">
			<h5>What would you like to do with the payment(s)?</h5>
			<span *ngIf="hasOtherPaymentTypes">
				As the order has payments other than Credit Card/ACH, the refund will have to be settled by reaching the customer service team.
				If you don't want to leave as credit then, select 'No Action' as option
			</span>
			<br/>
			<span *ngIf="!canRefund">
				One or more payment(s) could not be partially refunded as the payment was created today.
				If you don't want to leave as credit then, select 'No Action' as option
			</span>
			<div class="col-8 p-0 form-floating">
				<select class="form-select w-auto" name="paymentAction" [(ngModel)]="paymentAction"
				[ngClass]="{ 'is-invalid': submitted && paymentAction === undefined }">
					<option *ngIf="hasOtherPaymentTypes || !canRefund" [value]='0'>No Action</option>
					<option *ngIf="!hasOtherPaymentTypes && canRefund" [value]='2'>Refund payments</option>
					<option [value]='1'>Leave Payment as Credit</option>
				</select>
				<label for="paymentAction">Refund Option</label>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link" [disabled]="loading" (click)="modal.dismiss('Cancel Click')">Cancel</button>
		<button type="button" class="btn btn-primary" [disabled]="loading" (click)="deleteOrder()">Delete</button>
	</div>
</ng-template>


<ng-template #confirmRestore let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Confirm Order Restore</h4>
		<button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p>This will restore the order.</p>
		<p>Please note that completing this action will restore the round robin selection but NOT the avalara transaction.</p>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link" [disabled]="loading" (click)="modal.dismiss('Cancel Click')">Cancel</button>
		<button type="button" class="btn btn-primary" [disabled]="loading" (click)="restoreOrder()">Restore</button>
	</div>
</ng-template>
