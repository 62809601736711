import { Component, OnInit } from '@angular/core';
import { CustomerService  } from 'services/customer.service';
import { SitesService } from 'services/sites.service';
import { CustomerSearch, CustomerSearchResult, SiteFilterRequest, Site, SalesRepTagItem } from '@taradel/admin-api-client';
import { ToastService } from 'services/toast.service';
import { IdentityService } from 'services/identity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthenticationService } from 'services/authentication.service';

@Component({
	selector: 'app-customer-main',
	templateUrl: './customer-main.component.html',
	styleUrls: ['./customer-main.component.scss']
})

export class CustomerMainComponent implements OnInit {
	isSearchEnabled = false;
	pageNo = 1;
	pageSize = 20;
	customerSearch: CustomerSearch = new CustomerSearch();
	customers?: CustomerSearchResult[];
	total = 0;
	loading = false;
	sites: Site[] = [];
	lifetimeRevenue = 0;
	filtered = false;
	salesRepsList!: SalesRepTagItem[];
	selectedSiteId = -1;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private customerService: CustomerService,
		private sitesService: SitesService,
		private toastService: ToastService,
		private identityService: IdentityService,
		public authService: AuthenticationService) { }

	async ngOnInit(): Promise<void> {
		this.route.queryParams
		.pipe(take(1))
		.subscribe(async params => {
			const data = params['data'];
			if (data && data.length > 2) {
				this.customerSearch = JSON.parse(data);
				if (this.customerSearch.siteId ?? 0 > 0) {
					this.selectedSiteId = this.customerSearch.siteId!;
				}
				await this.search(true);
			}
		});
		try {
			this.loading = true;
			this.sites = await this.getSites();
			this.salesRepsList = await this.getSalesReps();
		}
		catch {
			this.toastService.showError('There was a problem loading the component', 'Load Error');
		}
		finally {
			this.loading = false;
		}
	}

	async getSites(): Promise<Site[]> {
		let request = new SiteFilterRequest({
			adminCheckout: false,
			showInactive: true
		});
		return await this.sitesService.getSitesForAdmin(request);
	}

	async getSalesReps(): Promise<SalesRepTagItem[]> {
		return await this.identityService.getSalesReps();
	}

	async search(initialLoad: boolean) {
		if (!initialLoad) {
			await this.router.navigate(['/customers'], { queryParams: { data: JSON.stringify(this.customerSearch) },  skipLocationChange: false });
		}
		this.loading = true;
		console.log(this.customerSearch);
		if (this.customerSearch.company
			|| this.customerSearch.customerId
			|| this.customerSearch.emailAddress
			|| this.customerSearch.firstName
			|| this.customerSearch.lastName
			|| this.customerSearch.lifetimeRevenue
			|| this.customerSearch.siteId
			|| this.customerSearch.lastTaggedSalesRep) {
			this.filtered = true;
		}
		else {
			this.filtered = false;
		}
		try {
			this.customerSearch.pageNo = this.pageNo = 1;
			this.customerSearch.pageSize = this.pageSize;
			this.total = await this.customerService.getCustomersCount(this.customerSearch);
			this.customers = await this.customerService.getAllCustomers(this.customerSearch);
			if (this.total > 0) {
				this.isSearchEnabled = true;
			}
			else {
				this.isSearchEnabled = false;
			}
		}
			catch (error) {
				this.toastService.showError('There was a problem loading the component', 'Load Error');
				console.log(error);
		}
			finally {
				this.loading = false;
		}
	}

	async resetFilter() {
		this.customerSearch = new CustomerSearch();
		this.selectedSiteId = -1;
		this.customerSearch.pageNo = 1;
		this.customerSearch.pageSize = this.pageSize;
		await this.router.navigate(['/customers']);
		await this.search(true);
	}

	setSiteValue(id: number) {
		this.selectedSiteId = id;
		this.customerSearch.siteId = id;
	}

	async pageChanged() {
		this.loading = true;
		try {
			this.customerSearch.pageNo = this.pageNo;
			this.customerSearch.pageSize = this.pageSize;
			this.customers = await this.customerService.getAllCustomers(this.customerSearch);
		}
		 catch (error) {
			 this.toastService.showError('There was a problem changing the page', 'Load Error');
			 console.log(error);
		}
		 finally {
			 this.loading = false;
		}
	}
}
