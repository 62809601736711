<div *ngIf="authService.organizationId !== 1">
	<app-pagefullheader></app-pagefullheader>
</div>
<app-spinner-with-overlay [showSpinner]="loading">
	<ng-container *ngIf="wlProduct === null">
		<div class="row">
			<div class="col">
				<app-alert-box alertMode="info">
					This product has not been assigned to current site.
				</app-alert-box>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="!!wlProduct">
		<div class="row">
			<div class="col-md-4">
				<div class="card mb-3 p-3">
					<form [formGroup]="wlProductForm">
						<div class="panel-body">
							<div class="row mb-2">
								<div class="col">
									<label for="baseProduct" class="form-label">Base Product:</label>
									<a *ngIf="authService.organizationId === 1" id="baseProduct" class="m-2" [routerLink]="['/products/', baseProductId]">{{wlProduct?.baseProduct?.name}}</a>
								</div>
							</div>
							<div class="form-floating mb-3">
								<input type="text" formControlName="name" class="form-control" [maxlength]="100"
									[ngClass]="{ 'is-invalid': submitted && f.name.errors }">
								<label for="name">Name </label>
							</div>
							<div *ngIf="submitted && f.name.errors" class="invalid-feedback">
								<div *ngIf="f.name.errors.required">Name is required</div>
							</div>

							<hr />

							<div class="form-floating mb-3">
								<input type="text" formControlName="fullName" class="form-control" [maxlength]="100"
									[ngClass]="{ 'is-invalid': submitted && f.fullName.errors }">
								<label for="fullName">Full Name </label>
							</div>
							<div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
								<div *ngIf="f.fullName.errors.required">Name is required</div>
							</div>

							<div class="form-floating mb-3">
								<select type="select" formControlName="markupType" class="form-select">
									<option value="Percent">Percent</option>
									<option value="Dollars">Dollars</option>
								</select>
								<label for="markupType">Markup Type</label>
							</div>

							<div class="mb-3">
								<label for="markup" class="form-label">Markup Amount</label>
								<div class="input-group">
									<span class="input-group-text"
										*ngIf="wlProductForm.get('markupType')?.value === 'Dollars'">$</span>
									<input type="number" formControlName="markup" class="form-control"
										[ngClass]="{ 'is-invalid': submitted && f.markup.errors }">
									<span class="input-group-text"
										*ngIf="wlProductForm.get('markupType')?.value === 'Percent'">%</span>
								</div>
								<div *ngIf="submitted && f.markup.errors" class="invalid-feedback">
									Enter valid markup
								</div>
							</div>

							<div class="mb-3">
								<label for="designFee" class="form-label">Design Fee</label>
								<div class="input-group">
									<input type="number" [readonly]="f.useBaseDesignFee.value" formControlName="designFee" class="form-control">
								</div>
							</div>
							<div class="form-check mb-3">
								<input type="checkbox" class="form-check-input" formControlName="useBaseDesignFee" id="useBaseDesignFee" (click)="f.designFee.setValue(null)">
								<label class="form-check-label" for="useBaseDesignFee">
									Use Design Fee from base product ({{wlProduct.baseProduct?.designFee | currency: 'USD'}})
								</label>
							</div>
							<div class="mb-3">
								<label for="creativeSetupFee" class="form-label">Creative Setup Fee</label>
								<div class="input-group">
									<input type="number" formControlName="creativeSetupFee" class="form-control">
								</div>
							</div>
							<div class="mb-3">
								<label for="nonProfitStandardPostage" class="form-label">Non Profit Standard Postage</label>
								<div class="input-group">
									<input type="text" formControlName="nonProfitStandardPostage" class="form-control">
								</div>
							</div>
							<div class="form-check mb-3">
								<input type="checkbox" class="form-check-input" formControlName="repOnly" id="repOnly">
								<label class="form-check-label" for="repOnly">
									Rep Only Visible
								</label>
							</div>
							<p>
								<button [disabled]="authService.organizationId !== 1" class="btn btn-primary btn-lg" (click)="updateWLProduct()">Save</button>
							</p>
						</div>
					</form>
				</div>
			</div>
			<div class="col-md-8">
				<div class="card p-3 mb-3">
					<div class="table-responsive">
						<table class="table table-hover table-panel">
							<thead>
								<tr>
									<th>Quantity Tier</th>
									<th>Price/Thousand</th>
									<th>Price/Piece</th>
									<th>Adjustment/Thousand</th>
									<th>Price/Piece<br/>(after Adjustment)</th>
									<th>Price/Piece<br>(after Markup & Adjustment)</th>
									<th>&nbsp;</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let tier of wlProduct?.baseProduct?.priceMatrix; let i = index">
									<td>
										<label>{{tier.minQty | number }} to {{tier.maxQty | number }}</label>
									</td>
									<td>
										<label>{{tier.pricePerThousand | currency}}</label>
									</td>
									<td>
										<label>{{tier.pricePerThousand!/1000 | currency}}</label>
									</td>
									<td>
										<input type="number" id="discount" min="0" class="form-control"
										[disabled]="authService.organizationId !== 1" [(ngModel)]="tierDiscount[i]">
									</td>
									<td>
										{{(tier.pricePerThousand! - tierDiscount[i]) / 1000 | currency}}
									</td>
									<td>
										{{getDiscountedPrice(tier, tierDiscount[i]) | currency}}
									</td>
									<td>
										<button [disabled]="authService.organizationId !== 1" class="btn btn-primary btn-sm" (click)="updateDiscount(tier, tierDiscount[i])">Save</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</app-spinner-with-overlay>
