<app-pagefullheader [subTitle]="'#' + orderLayoutService.orderId + ' on ' + orderLayoutService.selectedOrder?.created?.toLocaleString()">
	<ng-container addnav>
		<li class="nav-item dropdown">
			<a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Order Items</a>
			<ul class="dropdown-menu">
				<li><a class="dropdown-item" *ngFor="let item of orderLayoutService.selectedOrderItems" [routerLink]="['/orders/', orderLayoutService.orderId, 'details', item.orderItemId]">{{item.productName}}</a>
				</li>
			</ul>
		</li>
	</ng-container>
</app-pagefullheader>

<div class="row">
	<div class="col-lg-2">
		<div class="widget-card rounded mb-2">
			<div class="widget-card-cover rounded"></div>
			<div class="widget-card-content">
				<div class="my-2">
					<b>Site</b><br />
					<app-placeholder [dependsOn]="orderLayoutService.selectedSite">
						{{orderLayoutService.selectedSite?.name}}
					</app-placeholder>
				</div>
				<div class="my-2">
					<b>Customer</b><br />
					<app-placeholder [dependsOn]="orderLayoutService.selectedCustomer">
						<a [routerLink]="['/customers', orderLayoutService.selectedCustomer?.customerID]" target="_blank">{{orderLayoutService.selectedCustomer?.company}}</a> <i class="text-muted fa-fw fa fa-external-link-alt"></i>
					</app-placeholder>
				</div>
				<div class="my-2">
					<b>Email</b><br />
					<a href="mailto:{{orderLayoutService.selectedCustomer?.emailAddress}}">{{orderLayoutService.selectedCustomer?.emailAddress}}</a>
					<i class="text-muted fa-fw fa fa-external-link-alt"></i>
				</div>
				<div *ngIf="!!orderLayoutService.orderSalesRep" class="mt-2 mb-3">
					<b>Placed By</b><br />
					<a href="mailto:{{orderLayoutService.orderSalesRep}}">{{orderLayoutService.orderSalesRep}}</a>
					<i class="text-muted fa-fw fa fa-external-link-alt"></i>
				</div>
				<div class="my-2">
					<div class="hstack gap-3">
						<button style="width: 100%; background-color: orange;" class="btn btn-secondary position-relative" data-bs-toggle="offcanvas" data-bs-target="#notesOffCanvas" aria-controls="notesOffCanvas">
							Notes
							<span *ngIf="(orderLayoutService.orderNotes?.length ?? 0) > 0" class="position-absolute top-0 start-100 translate-middle badge bg-danger rounded-pill bg-info">{{
								orderLayoutService.orderNotes?.length ?? 0 }}</span>
							<span *ngIf="(orderLayoutService.orderNotes?.length ?? 0) === 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">{{
								orderLayoutService.orderNotes?.length ?? 0 }}</span>
						</button>
					</div>
				</div>
				<div class="my-2 mt-1">
					<div class="hstack gap-3">
						<button class="btn btn-default btn-sm position-relative" data-bs-toggle="offcanvas" [disabled]="!numberOfFiles" data-bs-target="#docsOffCanvas" aria-controls="docsOffCanvas">
							Docs
							<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">{{
								numberOfFiles }}</span>
						</button>
						<a class="btn btn-default btn-sm position-relative" [routerLink]="['/customers', orderLayoutService.selectedCustomer?.customerID, 'orders']">
							Customer Orders
							<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">{{
								total }}</span>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="widget widget-stats bg-red-500 mb-2" *ngIf="orderLayoutService.selectedOrder?.deleted">
			<div class="stats-icon stats-icon-lg"><i class="fa fa-hand-paper fa-fw"></i></div>
			<div class="stats-content">
				<div class="stats-title">Status</div>
				<div class="stats-number">DELETED</div>
			</div>
		</div>

		<div class="widget widget-stats bg-blue-500 mb-2">
			<div class="stats-icon stats-icon-lg"><i class="fa fa-calendar fa-fw"></i></div>
			<div class="stats-content">
				<div class="stats-title">Order Date</div>
				<div class="stats-number">
					<app-placeholder [dependsOn]="orderLayoutService.selectedOrder">
						{{orderLayoutService.selectedOrder?.created?.toLocaleDateString()}}
					</app-placeholder>
				</div>
			</div>
		</div>

		<div class="widget widget-stats mb-2" [ngClass]="{'bg-green-500':orderLayoutService.orderBalance.value <= 0, 'bg-red-500': orderLayoutService.orderBalance.value > 0 && !orderLayoutService.hasCreditKeyPayment, 'bg-orange-500': orderLayoutService.hasCreditKeyPayment && orderLayoutService.orderBalance.value > 0}">
			<div class="stats-icon stats-icon-lg"><i class="fa fa-dollar-sign fa-fw"></i></div>
			<div class="stats-content">
				<section *ngIf="orderLayoutService.hasCreditKeyPayment && orderLayoutService.orderBalance.value > 0" class="credit-key-section mb-2">
					<img class="mw-100 mh-100" src="assets/creditKeyLogo.png" />
				</section>
				<div class="stats-title">Order Amount</div>
				<div class="stats-number">
					<app-placeholder [dependsOn]="orderLayoutService.selectedOrder">
						{{orderLayoutService.selectedOrder?.orderAmount | currency}}
					</app-placeholder>
				</div>
				<div class="stats-progress progress">
					<div class="progress-bar" [ngStyle]="{'width': orderLayoutService.paidPercent + '%'}"></div>
				</div>
				<div class="stats-desc mt-2" *ngIf="orderLayoutService.totalPayment.value > 0">
					Total payments received: {{orderLayoutService.totalPayment.format()}}
				</div>
				<div *ngIf="orderLayoutService.orderBalance.value > 0" class="stats-desc mt-2">
					<p>Current balance: {{orderLayoutService.orderBalance.format()}}</p>
					<button class="btn btn-primary" (click)="modalService.open(confirmInvoiceModal)">Send Invoice</button>
				</div>
			</div>
		</div>

		<div class="widget-list rounded mb-2">
			<div class="widget-list-item" *ngFor="let item of orderLayoutService.selectedOrderItems">
				<div class="widget-list-content">
					<h4 class="widget-list-title">
						{{item.productName}} <br />
						(Item: {{item.orderItemId}})
					</h4>
					<p class="widget-list-desc">Qty: {{item.quantity | number}}</p>
					<p class="widget-list-desc">PO:
						<span class="text-danger" *ngIf="item.poNumber === ''"><b>NOT CREATED</b></span>
						<span *ngIf="item.poNumber !== ''"><b>TAR{{item.poNumber}}</b></span>
					</p>
				</div>
				<div class="widget-list-action">
					<a [routerLink]="['/orders/', orderLayoutService.orderId, 'details', item.orderItemId]" class="text-gray-500"><i class="fa fa-ellipsis-h fs-14px"></i></a>
				</div>
			</div>
		</div>

		<div *ngIf="(orderLayoutService.orderNotes?.length ?? 0) > 0" class="widget widget-stats bg-orange-500 mb-2">
			<div class="stats-icon stats-icon-lg"><i class="fa fa-sharp fa-notes fa-fw"></i></div>
			<div class="stats-content">
				<div class="stats-title">
					Notes
					<button style="background-color: #fff3cd;" class="btn btn-xs btn-warning note-small-add-button position-absolute bottom-0 end-0" data-bs-toggle="offcanvas" data-bs-target="#notesOffCanvas" aria-controls="notesOffCanvas">&nbsp;+&nbsp;</button>
				</div>
				<div class="list-group list-group-flush" *ngIf="orderLayoutService.orderNotes && orderLayoutService.orderNotes.length">
					<div *ngFor="let note of orderLayoutService.orderNotes | slice:0:widgetNoteMaxCount; let i = index" class="list-group-item note-list-group-item" aria-current="true">
						<div><small>{{ note.createdDate | date:'medium'}} by {{ note.createdBy}}</small></div>
						<div class="mt-1">{{ note.note }}</div>
					</div>
					<div *ngIf="orderLayoutService.orderNotes.length > widgetNoteMaxCount" class="list-group-item note-list-group-item" aria-current="true">
						<div><small><button class="btn btn-xs btn-link" data-bs-toggle="offcanvas" data-bs-target="#notesOffCanvas" aria-controls="notesOffCanvas">And {{ orderLayoutService.orderNotes.length - widgetNoteMaxCount }} more note(s).</button></small></div>
					</div>
				</div>
			</div>
		</div>

		<div class="widget-list rounded mb-2">
			<app-spinner-with-overlay [showSpinner]="orderLayoutService.savingTag">
				<div class="widget-list-item" *ngIf="salesRepTag">
					<div class="widget-list-content">
						<div class="form-group">
							<label>{{ salesRepTag.orderTagGroup }}</label>
							<select class="form-select" [(ngModel)]="salesRepId" [disabled]="(!isSalesAdmin || authService.organizationId !== 1)" [ngClass]="{'is-invalid': !isTagInRepList(salesRepTag.tag)}">
								<option [value]="null">Choose a sales rep</option>
								<option *ngFor="let rep of orderLayoutService.salesReps | sort: 'asc' : 'value'" [ngValue]="rep.salesRepId">
									{{rep.text}}</option>
							</select>
						</div>
					</div>
					<div class="widget-list-action text-nowrap">
						<button class="btn btn-link m-0 p-0 text-gray-600 fw-bold text-decoration-none" (click)="saveSalesRepTag(salesRepId ?? 0)" [disabled]="!isSalesAdmin || !salesRepId || orderLayoutService.savingTag || authService.organizationId !== 1">
							<i class="fa fa-save fa-lg ms-1 text-gray-500"></i>
						</button>
					</div>
				</div>
				<div class="widget-list-item" *ngIf="storeNumberTag">
					<div class="widget-list-content">
						<div class="form-group">
							<label>{{ storeNumberTag.orderTagGroup }}</label>
							<input type="text" class="form-control" [attr.placeholder]="storeNumberTag.orderTagGroup" [(ngModel)]="storeNumberTag.tag" [disabled]="authService.organizationId !== 1"/>
						</div>
					</div>
					<div class="widget-list-action text-nowrap">
						<button class="btn btn-link m-0 p-0 text-gray-600 fw-bold text-decoration-none" (click)="orderLayoutService.saveStoreNumberTag(storeNumberTag)" [disabled]="storeNumberTag.tag === null || orderLayoutService.savingTag || authService.organizationId !== 1">
							<i class="fa fa-save fa-lg ms-1 text-gray-500"></i>
						</button>
					</div>
				</div>
				<div class="widget-list-item" *ngIf="orderLayoutService.ventureOneNumber">
					<div class="widget-list-content">
						<div class="form-group">
							<label>VentureOne Number</label>
							<input type="text" class="form-control" disabled [attr.placeholder]="'VentureOne Number'" [(ngModel)]="orderLayoutService.ventureOneNumber" [disabled]="authService.organizationId !== 1"/>
						</div>
					</div>
					<div class="widget-list-action text-nowrap">
						<button type="button" class="btn btn-primary mt-3" (click)="copyToClipboard(orderLayoutService.ventureOneNumber)"
                        [disabled]="authService.organizationId !== 1"
                        data-toggle="tooltip" data-placement="top" title="Copy VentureOne number to clipboard">
							<span class="fas fa-copy"></span>
						</button>
					</div>
				</div>
				<div class="widget-list-item" *ngIf="customerRewardNumberTag">
					<div class="widget-list-content">
						<div class="form-group">
							<label>{{ customerRewardNumberTag.orderTagGroup }}</label>
							<input type="text" class="form-control" [attr.placeholder]="customerRewardNumberTag.orderTagGroup" [(ngModel)]="customerRewardNumberTag.tag" [disabled]="authService.organizationId !== 1"/>
						</div>
					</div>
					<div class="widget-list-action text-nowrap">
						<button class="btn btn-link m-0 p-0 text-gray-600 fw-bold text-decoration-none" (click)="orderLayoutService.saveCustomerRewardsNumberTag(customerRewardNumberTag)" [disabled]="customerRewardNumberTag.tag === null || orderLayoutService.savingTag || authService.organizationId !== 1">
							<i class="fa fa-save fa-lg ms-1 text-gray-500"></i>
						</button>
					</div>
				</div>
			</app-spinner-with-overlay>
		</div>
	</div>
	<div class="col-lg-10">
		<router-outlet></router-outlet>
	</div>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="notesOffCanvas" aria-labelledby="notesOffCanvasLabel">
	<div class="offcanvas-header">
		<h5 id="notesOffCanvasLabel">Notes</h5>
		<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
	</div>
	<div class="offcanvas-body">
		<div class="form-floating mb-3">
			<textarea class="form-control" placeholder="Enter a new order note" name="orderNotes" id="orderNotes" style="height:100px" [(ngModel)]="orderLayoutService.newNote" [disabled]="authService.organizationId !== 1"></textarea>
			<label for="floatingTextarea">Enter a new order note</label>
		</div>
		<div class="col text-start">
			<button class="btn btn-primary" (click)="orderLayoutService.addOrderNote()" [disabled]="orderLayoutService.loadingNotes || authService.organizationId !== 1">Save Note</button>
		</div>
		<hr />

		<div class="list-group list-group-flush" *ngIf="orderLayoutService.orderNotes && orderLayoutService.orderNotes.length">
			<div *ngFor="let note of orderLayoutService.orderNotes" class="list-group-item" aria-current="true">
				<div><small>{{ note.createdDate | date:'medium'}} by {{ note.createdBy}}</small></div>
				<div class="mt-1">{{ note.note }}</div>
			</div>
		</div>
	</div>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="docsOffCanvas" aria-labelledby="docsOffCanvasLabel">
	<app-spinner-with-overlay [showSpinner]="downloadingFile">
		<div style="display:flex;flex-direction: column;max-height:100vh">
			<div class="offcanvas-header">
				<h5 id="docsOffCanvasLabel">Order Files</h5>
				<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div>
			<div class="offcanvas-body">
				<div class="list-group list-group-flush">
					<div class="list-group-item" *ngFor="let file of getFiles()">
						<div *ngIf="orderLayoutService.selectedOrderFiles[file].length > 1" class="dropdown">
							<a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								{{file}}
							</a>
							<ul class="dropdown-menu">
								<li *ngFor="let x of orderLayoutService.selectedOrderFiles[file]"><a class="dropdown-item" (click)="downloadFile(file, x.versionId)">{{x.lastModified.toLocaleString('en-US')}}</a></li>
							</ul>
						</div>
						<button *ngIf="orderLayoutService.selectedOrderFiles[file].length === 1" class="btn btn-link text-decoration-none" (click)="downloadFile(file, orderLayoutService.selectedOrderFiles[file][0].versionId)">{{file}}</button>
					</div>
				</div>
			</div>
		</div>
	</app-spinner-with-overlay>
</div>
<ng-template #confirmInvoiceModal let-modal>
	<app-confirm-modal confirmText="confirm" title="Confirm Invoice" (confirmAction)="sendInvoice()" (closeModal)="closeModal()">
		<strong>Send Invoice Emails (seperate by ;):</strong>
		<textarea rows="2" class="form-control"
			placeholder="Email addresses separated by a comma, semi-colon or line break."
			[(ngModel)]="emailAddresses"></textarea>
		<br/>
		<strong>Confirm the order amount to be paid:</strong>
        <div class="table-responsive">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Payment Amount</th>
						<th>Note</th>
					</tr>
				</thead>
				<tbody>
					<tr class="settingRow">
						<td>
							<input type="number" name="paymentAmount" class="form-control"
							[(ngModel)]="orderLayoutService.orderBalance.value">
						</td>
						<td>
							<input type="text" name="notes" class="form-control" [(ngModel)]="invoiceNote">
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-confirm-modal>
</ng-template>
