<ng-container *ngIf="!!extraCopiesSiteProduct && !!extraCopiesPricing">
	<div class="row">
		<div class="col-6">
			<div class="form-floating mb-3">
				<select type="select" name="quantity" [(ngModel)]="selectedQuantity" class="form-select" (ngModelChange)="handlePricingSelected()" [ngClass]="{ 'is-invalid': submitted && !selectedQuantity }">
					<option value="undefined" disabled>Select Extra Copies</option>
					<option *ngIf="!onlyExtraCopies" value="0">No extra copies</option>
					<option *ngFor="let tier of extraCopiesPricing" value="{{tier.minimumQuantity}}">
						{{ 'Yes, ship (' + tier.minimumQuantity +') extra copies (+' + getPriceForQuantity((tier.pricePerPiece! + tier.standardPostage!), tier.minimumQuantity) + ')' }}
					</option>
				</select>
				<label for="quantity">Select Quantity</label>
			</div>
			<div *ngIf="submitted && selectedQuantity === undefined">
				<p class="input-error">
					Extra copies quantity or "No extra copies" must be selected
				</p>
			</div>
		</div>
		<div *ngIf="messaging !== ''" class="col-6 mt-3">
			<b class="bg-warning">{{messaging}}</b>
		</div>
	</div>
</ng-container>
