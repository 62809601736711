<ng-container>
    <app-spinner-with-overlay [showSpinner]="loading">
        <label><strong>{{ addressType }} Address</strong></label>
        <div class="row mb-3">
            <div class="col ms-1">
                <div *ngIf="!selectedAddress" class="form-group">
                    <div class="row mt-3">
                        <div class="col">
                            <ng-template #rt let-r="result" let-t="term">
                                {{ r.address1 }} {{ r.address2 }}, {{ r.city }}, {{ r.state }} {{ r.zipCode }}
                            </ng-template>
                            <div class="row">
                                <div class="col">
                                    <div class="form-floating">
                                        <input id="addressSearch" type="text" class="form-control address-box" [ngbTypeahead]="searchAddresses" (focus)="focus$.next($any($event).target.value); focused = true;"
                                            (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead" (blur)="focused = false;" [resultTemplate]="rt" [inputFormatter]="addressesFormatter" (selectItem)="getAddressId($event)"
                                            [ngClass]="{ 'is-invalid': submitted && !selectedAddress }" />
                                            <label>{{ addressPlaceholder }}</label>
                                    </div>
                               </div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-auto mx-3">
                                    <strong [hidden]="focused" [ngStyle]="{'position': 'relative', 'top': addressPlaceholder !== '' ? '-30px' : '-40px'}"><i class="fas fa-search"></i></strong>&nbsp;
                                </div>
                                <div class="col-auto me-3">
                                    <a role="button" class="color-primary text-decoration-none new-address-link align-self-end" (click)="openModal(newAddressModal)">
                                        <small>Add New</small>
                                    </a>
                                </div>
                            </div>
                            <div *ngIf="submitted && !selectedAddress" class="input-error address-error">
                                Address is required
                            </div>
                        </div>
                    </div>
                </div>
                <!-- display default or selected address -->
                <div *ngIf="!!selectedAddress" class="row mt-3">
                    <div class="col ">
                        <div class="address-box p-4 d-flex justify-content-between">
                            <section>
                                <ng-container *ngIf="selectedAddress.firstName !== '' || selectedAddress.lastName !== ''">
                                    {{ selectedAddress.firstName }} {{ selectedAddress.lastName }}
                                    <br />
                                </ng-container>
                                <ng-container *ngIf="selectedAddress.company !== ''">
                                    {{ selectedAddress.company }}
                                    <br />
                                </ng-container>
								<ng-container *ngIf="selectedAddress.phoneNumber !== '' && this.addressType === AddressTypes.Shipping">
									{{ selectedAddress.phoneNumber }} <br />
								</ng-container>
                                {{ selectedAddress.address1 }} {{ selectedAddress.address2 }} <br />
                                {{ selectedAddress.city }}, {{ selectedAddress.state }} {{ selectedAddress.zipCode }}
                            </section>
                            <section class="d-flex flex-column justify-content-evenly">
                                <a *ngIf="!disableChangeAddress" role="button" class="color-primary text-decoration-none" (click)="resetAddressField()">
                                    <small>Change</small>
                                </a>
                                <a *ngIf="allowCopyEdit" role="button" class="color-primary text-decoration-none" (click)="handleCopyEditAddress()">
                                    <small>Copy and Edit</small>
                                </a>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-spinner-with-overlay>
</ng-container>

<ng-template #newAddressModal let-modal>
    <div class="modal-content shopping-modal">
        <div class="modal-header">
            <div class="modal-title">Add New {{ addressType }} Address</div>
            <a type="button" class="close" (click)="closeModal()"><span class="fas fa-times"></span></a>
        </div>
        <div class="modal-body">
			<ng-container *ngIf="addressIsInvalid">
				<div class="row my-3">
					<div class="col-12 mx-auto d-flex justify-content-center">
						<small class="text-danger">
							Your default or selected address is missing one or more required fields. <br /> Please review the pre-filled address below and update any missing information.
						</small>
					</div>
				</div>
			</ng-container>
            <app-new-address [customerId]="customerId" [addressType]="addressType" [addressToPrefill]="addressToPrefill" [customer]="customer" (addressSubmitted)="setAddressId($event)" (cancelInput)="closeModal()"></app-new-address>
        </div>
    </div>
</ng-template>
