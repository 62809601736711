
<div class="row mt-3">
	<div class="col-md-10 col-12">
		<h5>Bundle Details</h5>
		<form [formGroup]="bundleForm" class="mt-3">
			<div class="form-floating mb-3">
				<input type="text" class="form-control" formControlName="name" placeholder="Name" />
				<label>Name</label>
			</div>
			<div class="form-floating mb-3">
				<input type="text" class="form-control" formControlName="description" placeholder="Description" />
				<label>Description</label>
			</div>
			<div class="form-floating mb-3">
				<div class="form-check">
					<input type="checkbox" formControlName="deleted" id="deleted" class="form-check-input" />
					<label for="deleted" class="form-check-label">Deleted</label>
				</div>
			</div>
		</form>
		<div class="row mt-3 d-flex justify-content-center">
			 <div class="col-auto mx-auto">
				<button class="btn btn-link text-danger" (click)="emitGoBack()">Cancel</button>
			 </div>
			<div class="col-auto mx-auto">
				<button class="btn btn-success" (click)="emitUpdatedBundle()">Go to bundle items</button>
			</div>
		</div>
	</div>
</div>
