import { Component, OnInit } from '@angular/core';
import { CpaStatus, PaginationQuery, ScheduledPaymentDisplay, ScheduledPaymentsQuery } from '@taradel/admin-api-client';
import { PaymentsService } from 'services/payments.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-scheduled-payments',
  templateUrl: './scheduled-payments.component.html',
  styleUrls: ['./scheduled-payments.component.scss']
})
export class ScheduledPaymentsComponent implements OnInit {
	loading = false;
	isSearchEnabled = true;
	pageNo = 1;
	pageSize = 20;
	total = 0;
	scheduledPaymentsQuery = new ScheduledPaymentsQuery();
	cpaStatus = CpaStatus;
	scheduledPayments: ScheduledPaymentDisplay[] = [];

	constructor(
		private paymentsService: PaymentsService,
		private toastService: ToastService
	) {	}

	async ngOnInit() {
		this.scheduledPaymentsQuery.paymentStatuses = [];
		this.todaysPaymentDue();
		await this.search();
	}

	async search() {
		this.loading = true;
		this.scheduledPaymentsQuery.pagination = new PaginationQuery({
			pageNo: this.pageNo,
			pageSize: this.pageSize
		});

		try {
			this.total = await this.paymentsService.getScheduledPaymentsCount(this.scheduledPaymentsQuery);
			this.scheduledPayments = await this.paymentsService.getScheduledPayments(this.scheduledPaymentsQuery);

			if (this.scheduledPayments.length > 0) {
				this.isSearchEnabled = true;
			}
			else {
				this.isSearchEnabled = false;
			}
		}
		catch (err: any) {
			this.toastService.showError(err.toString());
		}
		finally {
			this.loading = false;
		}

	}

	parseDate(date: Date, isFromDate: boolean): void {
		if (isFromDate) {
			this.scheduledPaymentsQuery.startDate = new Date(date);
		}
		else {
			this.scheduledPaymentsQuery.endDate = new Date(date);
		}
	}
	async pastPaymentDue() {
		this.scheduledPaymentsQuery.startDate = undefined;
		this.scheduledPaymentsQuery.endDate = new Date(Date.now());
		await this.search();
		return false;
	}
	async todaysPaymentDue() {
		this.scheduledPaymentsQuery.startDate = new Date(Date.now());
		this.scheduledPaymentsQuery.endDate = new Date(Date.now());
		await this.search();
		return false;
	}
	async futurePaymentDue() {

		this.scheduledPaymentsQuery.startDate = new Date(Date.now());
		this.scheduledPaymentsQuery.endDate = undefined;
		await this.search();
		return false;
	}
	addPaymentStatus(status: CpaStatus) {
		const idx = this.scheduledPaymentsQuery.paymentStatuses!.findIndex(x => x === status);
		if (idx !== -1) {
			this.scheduledPaymentsQuery.paymentStatuses!.splice(idx, 1);
		}
		else {
			this.scheduledPaymentsQuery.paymentStatuses!.push(status);
		}
	}
}
