import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PriceCalculatorRequest, PriceCalculatorResponse, PricingClient as WebPricingClient, ProductPricingRequest,
	ProductPricingResponse } from '@taradel/web-api-client';
import { PmodContainerCapacity, PmodContainerZoneCost, PriceMatrix, PriceMatrixSiteMod, PricingClient, UpdateUSelectProductsPostageRequest } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

	private readonly pricingClient: PricingClient;
	private readonly webPricingClient: WebPricingClient;

	constructor(http: HttpClient) {
	  this.pricingClient = new PricingClient(http, environment.adminApiUrl);
	  this.webPricingClient = new WebPricingClient(http, environment.webApiUrl);
	}

	getProductPriceTiers(productId: number): Promise<PriceMatrix[]> {
		return lastValueFrom(this.pricingClient.getProductPriceTiers(productId));
	}

	addProductPriceTier(tier: PriceMatrix): Promise<number> {
		return lastValueFrom(this.pricingClient.addProductPriceTier(tier));
	}

	updateProductPriceTiers(tiers: PriceMatrix[]): Promise<number> {
		return lastValueFrom(this.pricingClient.updateProductPriceTiers(tiers));
	}

	removeProductPriceTier(tier: PriceMatrix): Promise<number> {
		return lastValueFrom(this.pricingClient.removeProductPriceTier(tier));
	}

	updateTierDiscount(priceMatrixSiteMod: PriceMatrixSiteMod): Promise<number> {
		return lastValueFrom(this.pricingClient.addOrUpdateTierDiscount(priceMatrixSiteMod));
	}

	getPriceCalculator(request: PriceCalculatorRequest): Promise<PriceCalculatorResponse> {
		return lastValueFrom(this.webPricingClient.calculator(request));
	}

	addPmodContainerCapacity(pmodContainerCapacity: PmodContainerCapacity): Promise<number> {
		return lastValueFrom(this.pricingClient.addPmodContainerCapacity(pmodContainerCapacity));
	}

	editPmodContainerCapacity(pmodContainerCapacity: PmodContainerCapacity): Promise<number> {
		return lastValueFrom(this.pricingClient.editPmodContainerCapacity(pmodContainerCapacity));
	}

	getPmodContainerCapacity(): Promise<PmodContainerCapacity[]> {
		return lastValueFrom(this.pricingClient.getPmodContainerCapacity());
	}

	getPmodContainerZoneCost(): Promise<PmodContainerZoneCost[]> {
		return lastValueFrom(this.pricingClient.getPmodContainerZoneCost());
	}

	editPmodContainerZoneCost(pmodContainerZoneCost: PmodContainerZoneCost): Promise<number> {
		return lastValueFrom(this.pricingClient.editPmodContainerZoneCost(pmodContainerZoneCost));
	}

	addPmodContainerZoneCost(pmodContainerZoneCost: PmodContainerZoneCost): Promise<number> {
		return lastValueFrom(this.pricingClient.addPmodContainerZoneCost(pmodContainerZoneCost));
	}

	getProductPricing(siteId: number, customerId: number, productId: number): Promise<ProductPricingResponse[]> {
		return lastValueFrom(this.webPricingClient.getProductPricing(new ProductPricingRequest( {
			siteId,
			customerId,
			baseProductId: productId
		})));
	}

	updateUSelectProductsPostage(request: UpdateUSelectProductsPostageRequest) {
		return lastValueFrom(this.pricingClient.updateUSelectProductsPostage(request));
	}
}
