import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Product, Site, WLProduct } from '@taradel/web-api-client';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';
import { USelectProductIds } from 'components/shared/bulk-add-products/bulk-add-products.component';

@Component({
  selector: 'app-bulk-add-products-to-site',
  templateUrl: './bulk-add-products-to-site.component.html',
  styleUrls: ['./bulk-add-products-to-site.component.scss']
})
export class BulkAddProductsToSiteComponent {

	filteredProducts: Product[] = [];
	@Input() allProducts: Product[] = [];
	@Input() site?: Site;

	constructor(private sitesService: SitesService,
		private toastService: ToastService,
		private router: Router
	) { }

	async addProducts(products: USelectProductIds[]) {
		let success = true;
		let productIds: number[] = [];
		products.forEach(async (product: USelectProductIds) => {
			const wlProduct = new WLProduct({
				siteId: this.site!.siteId,
				name: product.name,
				baseProductId: product.baseProductId,
				markup: 0,
				markupType: 'Percent',
				productId: 0,
				deleted: false,
				designFee: 0,
				creativeSetupFee: 0,
				repOnly: false
			});

			try {
				const id = await this.sitesService.addSiteProduct(wlProduct);
				productIds.push(id);
			}
			catch (ex: any) {
				success = false;
				console.log(ex);
				this.toastService.showError('There was an error adding the product');
			}
		});
		await Promise.all(productIds);
		if (success) {
			this.toastService.showSuccess("All products were successfully added");
			this.router.navigate(['/sites', this.sitesService.currentSiteId, 'products']);
		}
	}
}
