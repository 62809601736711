import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'services/authentication.service';
import { HubService } from './hub.service';

export interface OrderCreated {
	orderId: number;
	customer: string;
	salesRep: string;
	amount: number;
	site: string;
}

@Injectable({
	providedIn: 'root'
})
export class OrderHubService extends HubService {

	public orderCreated = new Subject<OrderCreated>();

	constructor(authService: AuthenticationService) {
		super('orders', authService);

		this.connection.on('orderCreated', order => this.orderCreated.next(order));

		this.connection.start();
	}
}
