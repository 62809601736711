<app-pagefullheader></app-pagefullheader>
<div class="card p-3 mb-3">
	<app-spinner-with-overlay [showSpinner]="loading">
        <div class="row">
            <div class="col-12 text-end">
                <button class="btn btn-danger mx-1" (click)="deleteProductPowerUps()" type="button">
                    Remove All Product PowerUps
                </button>
                <button class="btn btn-primary mx-1" (click)="updateProductPowerUps()" type="button">
                    Update Product PowerUps
                </button>
            </div>
        </div>

		<div class="card border-0 p-3">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th width="10%">Product USelect Id</th>
						<th>Product USelect Name</th>
						<th width="10%">Powerup USelect Id</th>
						<th>Powerup USelect Name</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let powerUp of productPowerUps; let i = index;">
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="productUSelectId" min="1" class="form-control"
								[(ngModel)]="powerUp.productUSelectId" disabled>
							</div>
						</td>
						<td width="30%">
							<div class="form-floating mb-3">
								<input type="text" id="productUSelectName" class="form-control"
								value="{{getUSelectName(productUSelectId)}}" disabled>
							</div>
						</td>
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="powerupUSelectId" min="1" class="form-control"
								[(ngModel)]="powerUp.powerupUSelectId" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<app-uselect-search  [uSelect]="getUSelectMethod(powerUp.powerupUSelectId)" [array]="uSelectMethods" (selectedMethod)="getUpdateMethod($event, i)"></app-uselect-search>
							</div>
						</td>
                        <td>
                            <li class="list-inline-item mt-3">
                                <button class="btn btn-danger btn-sm" (click)="removeSelectedPowerUps(i)" data-toggle="tooltip"
                                    data-placement="top" Title="Delete">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </li>
                        </td>
					</tr>
					<tr *ngIf="newPowerUp !== undefined">
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="productUSelectId" min="1" class="form-control"
								[(ngModel)]="newPowerUp!.productUSelectId" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="text" id="newProductUSelectName" class="form-control"
								value="{{getUSelectName(productUSelectId)}}" disabled>
							</div>
						</td>
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="uSelectId" min="1" class="form-control"
								[(ngModel)]="newPowerUp!.powerupUSelectId" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<app-uselect-search #uSelectMethodListInput [array]="uSelectMethods" (selectedMethod)="getMethod($event)"></app-uselect-search>
							</div>
						</td>
						<td>
							<ul class="list-inline">
								<li class="list-inline-item  mt-3">
									<button class="btn btn-success" (click)="addNewPowerUp()"
									[disabled]="!(newPowerUp!.powerupUSelectId > 0)">Add</button>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-spinner-with-overlay>
</div>

