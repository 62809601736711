import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiException, PriceMatrix, PrintMethod, Product } from '@taradel/web-api-client';
import { PricingService } from 'services/pricing.service';
import { ProductsService } from 'services/products.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-product-pricing',
	templateUrl: './product-pricing.component.html',
	styleUrls: ['./product-pricing.component.scss']
})
export class ProductPricingComponent implements OnInit {

	productId = 0;
	product!: Product;
	productTiers: PriceMatrix[] = [];
	newProductTier: PriceMatrix = new PriceMatrix();
	printMethods: PrintMethod[] = [];

	loading = false;
	submitted = false;

	constructor(
		private route: ActivatedRoute,
		public pricingService: PricingService,
		public productsService: ProductsService,
		private toastService: ToastService) {
		this.productId = parseInt(this.route.snapshot.paramMap.get('productId') ?? '0', 10);
	}

	async ngOnInit(): Promise<void> {
		await this.loadPriceTiers();
	}

	async loadPriceTiers() {
		this.loading = true;
		try {
			this.product = await this.productsService.getProduct(this.productId);
			this.newProductTier.productId = this.productId;
			this.productTiers = await this.pricingService.getProductPriceTiers(this.productId);
			this.printMethods = await this.productsService.getPrintMethods();
		}
		catch (err: any) {
			if (ApiException.isApiException(err)) {
				this.toastService.showError(`Error loading price tiers: ${err.message}`);
			}
		}
		finally {
			this.loading = false;
		}
	}

	async addTier() {
		this.submitted = true;
		if ((this.newProductTier.minQty ?? 0)  === 0 ||
			(this.newProductTier.maxQty ?? 0) === 0 ||
			(this.newProductTier.minQty > (this.newProductTier.maxQty ?? 0)) ||
			!this.newProductTier.pricePerThousand) {
			return;
		}
		this.loading = true;
		try {
			await this.pricingService.addProductPriceTier(this.newProductTier);
			await this.loadPriceTiers();

			this.newProductTier = new PriceMatrix();
			this.newProductTier.productId = this.productId;
			this.submitted = false;
			this.toastService.showSuccess(`Tier added successfully`);
		}
		catch (err: any) {
			if (ApiException.isApiException(err)) {
				this.toastService.showError(`Error adding price tier: ${err.message}`);
			}
		}
		finally {
			this.loading = false;
		}
	}

	async updateTier(tier: PriceMatrix) {
		if (tier.minQty  === 0 || tier.maxQty === 0 || (tier.minQty > tier.maxQty!) || !tier.pricePerThousand) {
			return;
		}
		this.loading = true;
		try {
			await this.pricingService.updateProductPriceTiers([tier]);
			this.toastService.showSuccess(`Tier updated successfully`);
		}
		catch (err: any) {
			if (ApiException.isApiException(err)) {
				this.toastService.showError(`Error updating price tier: ${err.message}`);
			}
		}
		finally {
			this.loading = false;
		}
	}

	async removeTier(tier: PriceMatrix) {
		this.loading = true;
		try {
			await this.pricingService.removeProductPriceTier(tier);
			await this.loadPriceTiers();
			this.toastService.showSuccess(`Tier removed successfully`);
		}
		catch (err: any) {
			if (ApiException.isApiException(err)) {
				this.toastService.showError(`Error removing price tier: ${err.message}`);
			}
		}
		finally {
			this.loading = false;
		}
	}

	async updateAllPricing() {
		const invalidTier = this.productTiers.find(x => x.minQty === 0 || x.maxQty === 0 || (x.minQty > (x.maxQty ?? 0)) || (x.pricePerThousand ?? 0) <= 0);
		if (invalidTier) {
			return;
		}
		this.loading = true;
		try {
			await this.pricingService.updateProductPriceTiers(this.productTiers);
			this.toastService.showSuccess(`All tiers updated successfully`);
		}
		catch (err: any) {
			if (ApiException.isApiException(err)) {
				this.toastService.showError(`Error updating price tiers: ${err.message}`);
			}
		}
		finally {
			this.loading = false;
		}

	}
}
