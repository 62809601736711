import { Component, Input } from '@angular/core';
import { Audit } from '@taradel/admin-api-client';

@Component({
	selector: 'app-audit',
	templateUrl: './audit.component.html',
	styleUrls: ['./audit.component.scss']
})

export class AuditComponent {
    @Input() heading: string = '';
    @Input() audit: Audit[] | undefined = [];
	constructor() { }
}
