<div class="accordion-item">
    <h2 class="accordion-header" id="statesHeading">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#statesCollapse" 
            aria-expanded="true" aria-controls="statesCollapse">
            States
        </button>
    </h2>
    <div id="statesCollapse" class="accordion-collapse collapse show" aria-labelledby="statesHeading" 
        data-bs-parent="#geographyAccordion">
        <div class="accordion-body">
            <app-select [selectionService]="equifaxSelectionService" [searchable]="true" queryField="states" 
                placeholder="Type here to search">
            </app-select>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="metrosHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#metrosCollapse" 
            aria-expanded="false" aria-controls="metrosCollapse">
            Metro Areas
        </button>
    </h2>
    <div id="metrosCollapse" class="accordion-collapse collapse" aria-labelledby="metrosHeading" 
        data-bs-parent="#geographyAccordion">
        <div class="accordion-body">
            <app-select [selectionService]="equifaxSelectionService"
                [searchable]="true" queryField="metros" placeholder="Type here to search">
            </app-select>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="zipsHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#zipsCollapse" 
            aria-expanded="false" aria-controls="zipsCollapse">
            Zip Codes
        </button>
    </h2>
    <div id="zipsCollapse" class="accordion-collapse collapse" aria-labelledby="zipsHeading" 
        data-bs-parent="#geographyAccordion">
        <div class="accordion-body">
            <app-textarea [selectionService]="equifaxSelectionService"
                queryField="zipCodes" validationRegex="^\d{5}$"  validationMessage="Your entries must be in 5-digit zip code format, e.g. 12345."
                placeholder="Enter a list of values, e.g. 10010, 10023, 10027">
            </app-textarea>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="addressHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#addressCollapse" 
            aria-expanded="false" aria-controls="addressCollapse">
            Address
        </button>
    </h2>
    <div id="addressCollapse" class="accordion-collapse collapse" aria-labelledby="addressHeading" 
        data-bs-parent="#geographyAccordion">
        <div class="accordion-body">
            <app-address [selectionService]="equifaxSelectionService"
                queryField="radiusQuery">
            </app-address>
        </div>
    </div>
</div>