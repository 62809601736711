import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { USelectMethod, UpdateSiteProductsPostageRequest, WLProduct } from '@taradel/admin-api-client';
import { Subscription } from 'rxjs';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-update-postage',
  templateUrl: './update-postage.component.html',
  styleUrls: ['./update-postage.component.scss']
})
export class UpdatePostageComponent implements OnInit, OnDestroy {
	siteId = 0;
	allSiteProducts: WLProduct[] =[];
	allUSelectMethods: USelectMethod[] = [];
	selectedUselectProducts: WLProduct[] =[];
	loading = true;
	updatePostageForm: UntypedFormGroup;
	currentSite$ = new Subscription();

	constructor(private formBuilder: UntypedFormBuilder,
		private sitesService: SitesService,
		private toastService: ToastService
	) {
		this.updatePostageForm = this.formBuilder.group({
			uSelectId: [{value: '', disabled: true}],
			uSelectName: ['', Validators.required],
			standardPostage: [''],
			firstClassPostage: ['']
		});
	}

	ngOnInit(): void {
		this.currentSite$ = this.sitesService.currentSite.subscribe(async site => {
			if (site) {
				try {
					this.siteId = site!.siteId;
					await this.getAvailableUSelects();
				}
				catch {
					this.toastService.showError('There was an error getting site uselects');
				}
				finally {
					this.loading = false;
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.currentSite$.unsubscribe();
	}

	getMethod(item: {id: number, name: string}) {
		this.updatePostageForm.controls.uSelectId.setValue(item.id);
		this.updatePostageForm.controls.uSelectName.setValue(item.name);
		this.selectedUselectProducts = this.allSiteProducts.filter(x => x.baseProduct?.uSelectConfigurations![0].uSelectId === item.id);
	}

	async getAvailableUSelects() {
		let siteUSelectIds: any = [];
		this.allSiteProducts = await this.sitesService.getSiteProducts(this.siteId);
		this.allSiteProducts.forEach(product => {
		  product.baseProduct?.uSelectConfigurations?.forEach(config => {
			siteUSelectIds.push(config.uSelectId);
		  });
		});
		this.allUSelectMethods = await this.sitesService.getAllUSelect();
		this.allUSelectMethods = this.allUSelectMethods.filter(x => siteUSelectIds.includes(x.uSelectId));
	}

	async updatePostage() {
		try {
			this.loading = true;
			const standardPostage = this.updatePostageForm.controls.standardPostage.value?.toString() ?? '';
			const firstClassPostage = this.updatePostageForm.controls.firstClassPostage.value?.toString() ?? '';
			const request = new UpdateSiteProductsPostageRequest({
				siteId: this.siteId,
				uSelectId: this.updatePostageForm.controls.uSelectId.value,
				firstClassPostage: firstClassPostage.length > 0 ? parseFloat(firstClassPostage) : undefined,
				standardPostage: standardPostage.length > 0 ? parseFloat(standardPostage) : undefined
			});
			await this.sitesService.updateWLProductsPostage(request);
			await this.getAvailableUSelects();
			this.selectedUselectProducts = this.allSiteProducts.filter(x => x.baseProduct?.uSelectConfigurations![0].uSelectId === request.uSelectId);
			this.toastService.showSuccess('The WLProduct postage values were updated successfully');
		}
		catch {
			this.toastService.showError('There was an error updating the postage values');
		}
		finally {
			this.loading = false;
		}
	}
}
