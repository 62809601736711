<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row mt-3">
		<div class="col-12">			
			<div class="card">
				<div class="card-header">
					<h5>Order Bundle</h5>
				</div>
				<div class="card-body">
					<div class="px-md-5 px-2 mt-3">
						<div class="input-group mb-3">
							<div class="form-floating">
								<select type="select" id="orderBundle" class="form-select" [(ngModel)]="bundleId">
									<option *ngFor="let x of siteBundles" value="{{x.bundleId}}">
										{{x.bundleId}} - {{x.bundle?.name}}
									</option>
								</select>
								<label form="orderBundle">Order Bundle</label>
							</div>
							<button class="btn btn-primary px-4" type="button" (click)="validateBundle()">Validate Bundle</button>
						</div>							
						<!--<div *ngIf="!coupon?.couponDiscount?.isValid; else clickSaveBtn">
							<p class="input-error">
								{{ coupon?.couponDiscount?.couponMessage }}
							</p>
						</div>
						<ng-template #clickSaveBtn>
							<p *ngIf="c.newCouponCode.value.length > 0" class="text-success">
								{{ coupon?.couponDiscount?.couponMessage }}
								<br />
								Click save button to save this bundle to the order
							</p>
						</ng-template>-->
					</div>

					<div class="row mt-3">
						<div class="col-12">
							<div class="row d-flex justify-content-end">
								<div class="col-auto">
									<button class="btn btn-sm btn-success" data-toggle="tooltip" data-placement="top" title="Save Changes"
									(click)="saveChanges()">
										Save Changes
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>			
		</div>
	</div>
</app-spinner-with-overlay>
