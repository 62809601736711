import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { CmsClient as WebCmsClient, CmsContent, CmsContentKey, CmsLanguage, FileParameter, FileResponse, SiteLanguage } from '@taradel/web-api-client';
import { CmsClient, CmsContentKeyWithSiteCounts, CmsContentRequest, FilterCmsContentRequest } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ContentService {
	private readonly cmsClient: CmsClient;
	private readonly webCmsClient: WebCmsClient;

	constructor(http: HttpClient,
		private sanitizer: DomSanitizer) {
		this.cmsClient = new CmsClient(http, environment.adminApiUrl);
		this.webCmsClient = new WebCmsClient(http, environment.webApiUrl);
	}

	addContentKey(contentKey: CmsContentKey) {
		return lastValueFrom(this.cmsClient.createCmsContentKey(contentKey));
	}

	getAllKeys(): Promise<CmsContentKey[]> {
		return lastValueFrom(this.cmsClient.getCmsContentKeys());
	}

	getContentKeysWithSiteCounts(): Promise<CmsContentKeyWithSiteCounts[]> {
		return lastValueFrom(this.cmsClient.getContentKeysWithSiteCounts());
	}

	getContentKeyForAllSites(contentKey: string): Promise<CmsContent[]> {
		return lastValueFrom(this.cmsClient.getContentKeyForAllSites(contentKey));
	}

	updateContentKey(contentKey: CmsContentKey) {
		return lastValueFrom(this.cmsClient.updateCmsContentKey(contentKey));
	}

	deleteContentKey(contentKey: string) {
		return lastValueFrom(this.cmsClient.deleteCmsContentKey(contentKey));
	}

	getCmsLanguages(): Promise<CmsLanguage[]> {
		return lastValueFrom(this.webCmsClient.getCmsLanguages());
	}

	getSupportedLanguages(siteId: number): Promise<SiteLanguage[]> {
		return lastValueFrom(this.webCmsClient.getAllSupportedLanguages(siteId));
	}

	getSiteContent(siteId: number): Promise<CmsContent[]> {
		return lastValueFrom(this.cmsClient.getAllCmsContentForSite(siteId));
	}

	async getSectionContent(siteId: number, language: string, contentPrefix: string): Promise<Map<string, SafeValue>> {
		const content = new Map<string, SafeValue>();
		const contentSet = await lastValueFrom(this.webCmsClient.searchCmsContent(siteId, language, contentPrefix));
		for (let key of contentSet) {
			content.set(key.contentKey?.contentKey!, this.sanitizer.sanitize(SecurityContext.HTML, key.content ?? '') ?? '' as SafeValue);
		}
		return content;
	}

	filterSiteContent(request: FilterCmsContentRequest): Promise<CmsContent[]> {
		return lastValueFrom(this.cmsClient.filterCmsContent(request));
	}

	deleteSiteContent(siteId: number, contentKey: string) {
		return lastValueFrom(this.cmsClient.deleteCmsContent(siteId, contentKey));
	}

	saveSiteContent(content: CmsContentRequest) {
		return lastValueFrom(this.cmsClient.upsertCmsContent(content));
	}

	async exportSiteContent(siteId: number): Promise<FileResponse | null> {
		return await lastValueFrom(this.cmsClient.exportCmsContent(siteId));
	}

	async importSiteContent(siteId: number, file: FileParameter): Promise<void> {
		await lastValueFrom(this.cmsClient.importCmsContent(siteId, file));
	}
}
