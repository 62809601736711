import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserManager } from 'oidc-client';
import { environment } from 'environment';
import { AuthenticationService } from 'services/authentication.service';

@Component({
	selector: 'app-login-silent',
	templateUrl: './login-silent.component.html',
	styleUrls: ['./login-silent.component.scss']
})
export class LoginSilentComponent implements OnInit {

	error = false;
	claims: {key: string; value: string}[] = [];

	constructor(private route: ActivatedRoute, private authService: AuthenticationService) { }

	async ngOnInit(): Promise<void> {
		if (this.route.snapshot.fragment!.indexOf('error') >= 0) {
			this.error = true;
			return;
		}

		const userManager = new UserManager(environment.oidc);
		await this.authService.refresh(userManager.signinSilentCallback());

		// Uncomment this if you want to inspect the user claims
		// if (user !== null) {
		// 	this.claims = [];
		// 	for (const [key, value] of Object.entries(user.profile)) {
		// 		this.claims.push({ key, value });
		// 	}
		// }
	}
}
