<app-customer-header [customerId]="customerId"></app-customer-header>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="row mt-3 page-row font-primary">
			<div class="col-md-1"></div>
			<div class="col-md-2">
				<button type="button" class="btn btn-primary round" (click)="previousPage()" [disabled]="currentPage < 1">Previous Page</button>
			</div>
			<div class="col-md-6">Page {{currentPage + 1}} of {{pageCount}}</div>
			<div class="col-md-2">
				<button type="button" class="btn btn-primary round" (click)="nextPage()" [disabled]="currentPage >= pageCount - 1">Next Page</button>
			</div>
			<div class="col-md-1"></div>
		</div>
		<div class="row project-container-row font-primary">
			<div class="col-md-1"></div>
			<div class="col-md-10 project-browser-container">
				<div class="row">
					<div class="col-4" *ngFor="let project of pageProjects">
						<div class="font-primary" style="background-color:#ffffff;padding:10px; min-height:200px">
							<div class="row">
								<div [ngClass]="projectClass(project)" class="col-sm-12 mt-2">
									<div class="row">
										<div class="col text-center">
											<strong>Size:</strong> {{project.height - .25}} x {{project.width - .25}}<br />
											<strong>Created:</strong> {{project.createdDate | date: 'medium'}} <br />
											<strong>Last Updated:</strong> {{project.lastEditedDate | date: 'medium'}}<br /><br />
										</div>
									</div>
									<div class="text-center img-wrapper">
										<img class="mw-100 mh-100" [src]="project.thumbnail">
									</div>
									<div class="text-center mt-2">
										<div style="overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">
											{{project.title}}
										</div>
									</div>
									<div class="text-center my-2">
										<a class="btn btn-xs round" [ngClass]="selectButtonProjectClass(project)" (click)="selectProject(project)">
											<span class="fas fa-check"></span>
											&nbsp;Select Project
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="showEditor" class="row mb-5">
			<hr />
			<div class="col-1">
				<button *ngIf="isDesignTeam" class="btn btn-primary" (click)="allowEdit = !allowEdit"><span *ngIf="!allowEdit">Allow</span><span *ngIf="allowEdit">Disable</span> Edit</button>
			</div>
			<div class="col-10 text-center">
				<button class="btn btn-primary" (click)="changePage()">Change Editor Page</button> &nbsp;&nbsp;
				<button  *ngIf="isDesignTeam" class="btn btn-primary" (click)="getProjectAssets()">Get Assets</button>
			</div>
		</div>
		<div [hidden]="!showEditor" class="fw-bold">
			Note: This editor is not interactive to combat accidental changes to the customer's project.
			If you'd like to make edits to the project, click the Allow Edit button (if you cannot see it, ask the design or development team).
		</div>
		<div class="row" [hidden]="!showEditor">
			<div class="col-12">
				<div class="text-center position-relative">
					<div id="designHuddleFrame" class="ratio ratio-4x3">
					</div>
					<!-- This is so the sales doesn't accidentally change a customer's project with a misclick -->
					<div *ngIf="!allowEdit" class="position-absolute top-0 bottom-0 left-0 right-0 h-100 w-100">

					</div>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>

<ng-template #assetsModal let-modal>
	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">{{selectedProjectId}} Asset Download</h4>
				<button type="button" class="btn-close" (click)="closeModals()"></button>
			</div>
			<div class="modal-body">
				<div class="lead">
					<div class="row">
						<div class="col-3" *ngFor="let asset of assets">
							<a type="button" (click)="downloadAsset(asset)"><img class="mw-100" [src]="asset.data!.thumbnail_url"></a>
						</div>
					</div>
					<div class="row">
						<div class="col ms-auto">
							<button (click)="downloadAllAssets()" class="btn btn-primary">Download All Assets</button>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer text-end">
				<button type="button" class="btn btn-danger" (click)="closeModals()">Close</button>
			</div>
		</div>
	</app-spinner-with-overlay>
</ng-template>
