import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AchPaymentRequest, CreatePaymentProfileQuery, CreditCardPaymentRequest, PaymentType } from '@taradel/admin-api-client';
import { SalesApiService } from 'services/sales-api.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-create-payment-profile',
  templateUrl: './create-payment-profile.component.html',
  styleUrls: ['./create-payment-profile.component.scss']
})
export class CreatePaymentProfileComponent {
	customerId: number;
	loading = false;
	paymentType = PaymentType;
	selectedPayment?: PaymentType;
	billingForm: FormGroup;
	creditCardForm: FormGroup;
	achForm: FormGroup;
	creditCardYear: string[] = [];
	showCCNum = false;
	showCVV = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private salesService: SalesApiService,
		private toastService: ToastService,
		private formBuilder: UntypedFormBuilder
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);

		this.billingForm = this.formBuilder.group({
			billingAddressId: new UntypedFormControl('', Validators.required)
		});

		this.creditCardForm = this.formBuilder.group({
			creditCardNumber: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(13),
				Validators.maxLength(16)
			])),
			cvv: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(4)
			])),
			expirationMonth: new UntypedFormControl('', Validators.required),
			expirationYear: new UntypedFormControl('', Validators.required)
		});

		this.achForm = this.formBuilder.group({
			routingNumber: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(9),
				Validators.maxLength(9)
			])),
			accountNumber: new UntypedFormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(17)
			])),
			nameOnAccount: new UntypedFormControl('', Validators.required),
			bankName: new UntypedFormControl('', Validators.required),
			accountType: new UntypedFormControl('', Validators.required),
		});
	}

	get billingControl() {
		return this.billingForm.controls;
	}

	get creditCardControl() {
		return this.creditCardForm.controls;
	}

	get achControl() {
		return this.achForm.controls;
	}

	async createPaymentProfile() {
		if (this.billingForm.invalid) {
			this.toastService.showError('Please select billing address');
			return;
		}
		if (!this.selectedPayment) {
			this.toastService.showError('Please select a payment type');
			return;
		}
		if (this.selectedPayment === PaymentType.CreditCard && this.creditCardForm.invalid) {
			this.toastService.showError('Credit Card form is invalid');
			return;
		}
		if (this.selectedPayment === PaymentType.ACH && this.achForm.invalid) {
			this.toastService.showError('ACH form is invalid');
			return;
		}
		try {
			let creditCardDetails: CreditCardPaymentRequest | undefined = undefined;
			if (this.selectedPayment === PaymentType.CreditCard) {
				creditCardDetails = new CreditCardPaymentRequest({
					cardCode: this.creditCardControl.cvv.value,
					cardNumber: this.creditCardControl.creditCardNumber.value,
					expirationDate: this.creditCardControl.expirationMonth.value + this.creditCardControl.expirationYear.value.substring(2, 4)
				});
			}
			let achPaymentRequest: AchPaymentRequest | undefined = undefined;
			if (this.selectedPayment === PaymentType.ACH) {
				achPaymentRequest = new AchPaymentRequest({
					accountNumber: this.achControl.accountNumber.value,
					bankName: this.achControl.bankName.value,
					nameOnAccount: this.achControl.nameOnAccount.value,
					routingNumber: this.achControl.routingNumber.value,
					accountType: this.achControl.accountType.value
				});
			}

			this.loading = true;
			await this.salesService.createCustomerPaymentProfile(new CreatePaymentProfileQuery({
				customerId: this.customerId,
				billingAddressId: this.billingControl.billingAddressId.value,
				creditCardDetails,
				achPaymentRequest
			}));
			this.router.navigateByUrl(`/customers/${this.customerId}/payments`, { state: { tabId: 'paymentProfilesTab' } });
		}
		catch (error: any) {
			console.log(error);
			if (error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError(`${error} ${this.selectedPayment} profile creation failed`);
			}

		}
		finally {
			this.loading = false;
		}
	}

	selectBillingAddress(value: any) {
		this.billingControl.billingAddressId.setValue(value);
	}

	toggleShowCCNum() {
		this.showCCNum = !this.showCCNum;
	}

	toggleShowCVV() {
		this.showCVV = !this.showCVV;
	}

	public adjustYear(selectedMonth: string) {
		const month = new Date().getMonth();
		if (month >= parseInt(selectedMonth, 10)) {
			this.fillCreditCardYear(1);
		}
		else {
			this.fillCreditCardYear(0);
		}

	}

	private fillCreditCardYear(offset: number) {
		let year = new Date().getFullYear() + offset;
		this.creditCardYear = [];
		for (let i = 0; i < 10; i++) {
			this.creditCardYear.push(year.toString());
			year = year + 1;
		}
	}

	selectPayment(paymentType: PaymentType) {
		this.selectedPayment = paymentType;
	}

}
