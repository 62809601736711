import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { EquifaxSelectionService } from 'services/equifax-selection.service';


@Component({
	selector: 'app-finance',
	templateUrl: './finance.component.html',
	styleUrls: ['./finance.component.scss']
})
export class FinanceComponent {

	valueMap = new Map<number, string>([
		[1, '$1-$499'],
		[500, '$500-$999'],
		[1000, '$1000-$2499'],
		[2500, '$2500-$4999'],
		[5000, '$5000-$9999'],
		[10000, '$10000-$19999'],
		[20000, '$20000-$49999'],
		[50000, '$50000-$99999'],
		[100000, '$100000-$499999'],
		[500000, '$500000-$999999'],
		[1000000, '$1000000+']
	]);
	totalRevenueVal: number = 1;
	totalRevenueMin: number = 1;
	totalRevenueMax: number = 1000000;
	totalRevenueOptions: Options = {
		floor: 1,
		ceil: 1000000,
		stepsArray: [
			{ value: 1 },
			{ value: 500 },
			{ value: 1000 },
			{ value: 2500 },
			{ value: 5000 },
			{ value: 10000 },
			{ value: 20000 },
			{ value: 50000 },
			{ value: 100000 },
			{ value: 500000 },
			{ value: 1000000 }
		],
		translate: (value: number): string => {
			return this.valueMap.get(value)!!;
		}
	};

	localRevenueVal: number = 1;
	localRevenueMin: number = 1;
	localRevenueMax: number = 1000000;
	localRevenueOptions: Options = {
		floor: 1,
		ceil: 1000000,
		stepsArray: [
			{ value: 1 },
			{ value: 500 },
			{ value: 1000 },
			{ value: 2500 },
			{ value: 5000 },
			{ value: 10000 },
			{ value: 20000 },
			{ value: 50000 },
			{ value: 100000 },
			{ value: 500000 },
			{ value: 1000000 }
		],
		translate: (value: number): string => {
			return this.valueMap.get(value)!!;
		}
	};

	creditScoreMin: number = 101;
	creditScoreMax: number = 660;
	creditScoreOptions: Options = {
		step: 10,
		floor: 101,
		ceil: 660
	};

	creditPercentileVal: number = 0;
	creditPercentileMin: number = 0;
	creditPercentileMax: number = 50;
	creditPercentileOptions: Options = {
		floor: 0,
		ceil: 100
	};


	constructor(public equifaxSelectionService: EquifaxSelectionService) { }
}
