<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="row mb-3">
			<div class="col-sm-12 col-md-3">
				<div class="form-floating mb-3">
					<input type="date" id="startDate" class="form-control" [ngModel]="mcNallyQuery.startDate | date: 'yyyy-MM-dd'"
					max="9999-12-31" (change)="mcNallyStartDate = getDate($any($event).target.value)" placeholder="Start Date">
					<label for="startDate">Start Date</label>
				</div>
			</div>
			<div class="col-sm-12 col-md-3">
				<div class="form-floating mb-3">
					<input type="date" id="endDate" class="form-control" [ngModel]="mcNallyQuery.endDate | date: 'yyyy-MM-dd'"
					max="9999-12-31" (change)="mcNallyEndDate = getDate($any($event).target.value)" placeholder="End Date">
					<label for="endDate">End Date</label>
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-sm-12 col-md-3">
				<div class="form-group">
					<label for="mcNallyPrinterPOs">Printer PO Filter</label>
					<select id="mcNallyPrinterPOs" class="form-select" #poSelect (change)="onPOChange(poSelect.value)">
						<option selected value="3">All Records</option>
						<option value="1">With Printer POs</option>
						<option value="2">Without Printer POs</option>
					</select>
				</div>
			</div>
			<div class="col-sm-12 col-md-3">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" id="filterDesignService" [(ngModel)]="filterByDesign">
					<label class="form-check-label" for="filterDesignService">
						Filter by Design Service
					</label>
				</div>
				<div *ngIf="filterByDesign">
					<select id="mcNallyPrinterPOs" class="form-select" #designSelect
						(change)="onDesignChange(designSelect.value)">
						<option selected value="1">My Design</option>
						<option value="2">Template/Update</option>
						<option value="3">Professional</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<button class="btn btn-info" (click)="loadMcNally()">Download Report</button>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
