import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CpaStatus, CustomerPaymentAttemptDisplay, PaginationQuery, PaymentAttemptsQuery } from '@taradel/admin-api-client';
import { PaymentsService } from 'services/payments.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-payment-attempts',
  templateUrl: './payment-attempts.component.html',
  styleUrls: ['./payment-attempts.component.scss']
})
export class PaymentAttemptsComponent implements OnInit {
	loading = false;
	customerId = 0;
	isSearchEnabled = true;
	pageNo = 1;
	pageSize = 20;
	total = 0;
	cpaStatus = CpaStatus;
	paymentAttemptsQuery = new PaymentAttemptsQuery();
	paymentAttempts: CustomerPaymentAttemptDisplay[] = [];

	constructor(
		private paymentService: PaymentsService,
		private route: ActivatedRoute,
		private toastService: ToastService
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
	}

	async ngOnInit() {
		await this.search();
		this.loading = false;
	}

	async search() {
		this.loading = true;
		this.paymentAttemptsQuery.pagination = new PaginationQuery({
			pageNo: this.pageNo,
			pageSize: this.pageSize
		});

		try {
			if (this.customerId > 0) {
				this.total = await this.paymentService.getCustomerPaymentAttemptsCount(this.customerId, this.paymentAttemptsQuery);
				this.paymentAttempts = await this.paymentService.getCustomerPaymentAttempts(this.customerId, this.paymentAttemptsQuery);
			}
			else {
				this.total = await this.paymentService.getPaymentAttemptsCount(this.paymentAttemptsQuery);
				this.paymentAttempts = await this.paymentService.getPaymentAttempts(this.paymentAttemptsQuery);
			}
			if (this.paymentAttempts.length > 0) {
				this.isSearchEnabled = true;
			}
			else {
				this.isSearchEnabled = false;
			}
		}
		catch (err: any) {
			this.toastService.showError(err.toString());
		}
		finally {
			this.loading = false;
		}
	}

	async pageChanged() {
		this.paymentAttemptsQuery.pagination = new PaginationQuery({
			pageNo: this.pageNo,
			pageSize: this.pageSize
		});
		await this.search();
	}
}
