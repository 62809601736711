import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PmodContainerCapacity, PmodContainerZoneCost } from '@taradel/admin-api-client';
import { PricingService } from 'services/pricing.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-pmod',
	templateUrl: './pmod.component.html',
	styleUrls: ['./pmod.component.scss']
})
export class PMODComponent implements OnInit {

	loading = true;
	pmodCapacity: PmodContainerCapacity[] = [];
	pmodZoneCost: PmodContainerZoneCost[] = [];
	pmodCapacityForm: UntypedFormGroup;
	pmodZoneCostForm: UntypedFormGroup;
	tableValue = 0;
	capacityContainerTypes: (string | undefined)[] = [];
	zoneCostContainerTypes: (string | undefined)[] = [];

	constructor(
		public pricingService: PricingService,
		private formBuilder: UntypedFormBuilder,
		private toastService: ToastService
	) {
		this.pmodCapacityForm = this.formBuilder.group({
			itemSize: ['', Validators.compose([Validators.required])],
			capacity: ['', Validators.compose([Validators.required])],
			container: ['', Validators.compose([Validators.required])],
			isEdit: [false]
		});
		this.pmodZoneCostForm = this.formBuilder.group({
			zone: ['', Validators.compose([Validators.required])],
			container: ['', Validators.compose([Validators.required])],
			cost: ['', Validators.compose([Validators.required])],
			isEdit: [false]
		});
	}

	get capacityForm() {
		return this.pmodCapacityForm.controls;
	}

	get zoneForm() {
		return this.pmodZoneCostForm.controls;
	}

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;
			await this.initalizeContainerCapacity();
			await this.initializeContainerZoneCost();
		}
		catch {
			this.toastService.showError('There was an error loading the PMODs');
		}
		finally {
			this.loading = false;
		}
	}

	async initalizeContainerCapacity() {
		this.pmodCapacity = await this.pricingService.getPmodContainerCapacity();
		this.capacityContainerTypes = [...new Set(this.pmodCapacity.map(item => item.container))];
	}

	async initializeContainerZoneCost() {
		this.pmodZoneCost = await this.pricingService.getPmodContainerZoneCost();
		this.zoneCostContainerTypes = [...new Set(this.pmodZoneCost.map(item => item.container))];
	}

	setPmodCapacity(pmodContainerCapacity: PmodContainerCapacity, isEdit: boolean) {
		this.capacityForm.itemSize.setValue(pmodContainerCapacity.itemSize);
		this.capacityForm.capacity.setValue(pmodContainerCapacity.capacity);
		this.capacityForm.container.setValue(pmodContainerCapacity.container);
		this.capacityForm.isEdit.setValue(isEdit);
	}

	setPmodZoneCost(pmodContainerZoneCost: PmodContainerZoneCost, isEdit: boolean) {
		this.zoneForm.zone.setValue(pmodContainerZoneCost.zone);
		this.zoneForm.container.setValue(pmodContainerZoneCost.container);
		this.zoneForm.cost.setValue(pmodContainerZoneCost.cost);
		this.zoneForm.isEdit.setValue(isEdit);
	}

	async updateCapacity() {
		this.loading = true;
		let success = true;
		const pmodCapacityToUpdate = {
			itemSize: this.capacityForm.itemSize.value,
			capacity: this.capacityForm.capacity.value,
			container: this.capacityForm.container.value
		} as PmodContainerCapacity;
		try {
			if (this.capacityForm.isEdit.value === true) {
				await this.pricingService.editPmodContainerCapacity(pmodCapacityToUpdate);
			}
			else {
				await this.pricingService.addPmodContainerCapacity(pmodCapacityToUpdate);
			}
			await this.initalizeContainerCapacity();
		}
		catch (err) {
			success = false;
			this.toastService.showError("Error updating PMOD value.");
			console.log(err);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.clearForms();
			this.toastService.showSuccess("PMOD value has been updated.");
		}
	}

	clearForms() {
		this.pmodCapacityForm.reset();
		this.pmodZoneCostForm.reset();
	}

	async updateZoneCost() {
		this.loading = true;
		let success = true;
		const pmodZoneCostToUpdate = {
			zone: this.zoneForm.zone.value,
			container: this.zoneForm.container.value,
			cost: this.zoneForm.cost.value
		} as PmodContainerZoneCost;
		try {
			if (this.zoneForm.isEdit.value === true) {
				await this.pricingService.editPmodContainerZoneCost(pmodZoneCostToUpdate);
			}
			else {
				await this.pricingService.addPmodContainerZoneCost(pmodZoneCostToUpdate);
			}
			await this.initializeContainerZoneCost();
		}
		catch (err) {
			success = false;
			this.toastService.showError("Error updating PMOD value.");
			console.log(err);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.clearForms();
			this.toastService.showSuccess("PMOD value has been updated.");
		}
	}
}
