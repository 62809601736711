import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Demographics, DistributionCentroid, EddmDistribution, BuiltPersonalizedMailDistribution, BuiltEquifaxB2BDistribution } from '@taradel/admin-api-client';
import { USelect } from 'services/distributions.service';
import { EquifaxSelectionService } from 'services/equifax-selection.service';
import { MapDataService } from 'services/map-data.service';
import { OrderlayoutService } from 'services/orderlayout.service';
import { PmSelectionService } from 'services/pm-selection.service';
import { ToastService } from 'services/toast.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environment';
import { Subscription } from 'rxjs';
import { CarrierRoute, LetterCarrierWalk } from '@taradel/web-api-client';
import { GooglePlaceResult, mapAddressTradeAreaToGooglePlaceAddress } from 'app/lib/google-maps';


@Component({
	selector: 'app-digital-delivery',
	templateUrl: './digital-delivery.component.html',
	styleUrls: ['./digital-delivery.component.scss']
})
export class DigitalDeliveryComponent  implements OnInit, OnDestroy {

	loading: boolean = false;
	campaignName: string = '';
	zipCodesList: string = '';
	zipsArray: string[] = [];
	demographics!: Demographics;
	eddmDistribution!: EddmDistribution;
	filters: Map<string, string[]> = new Map<string, string[]>();
	mapImageSrc?: string;
	demographicsString: string = '';
	startingPoint!: DistributionCentroid;
	radiusTargeting: string = '';
	startingAddress: string = '';
	startingZip: string = '';
	usAreas: CarrierRoute[] = [];
	caAreas: LetterCarrierWalk[] = [];
	displayDeliveryInfoButton = true;
	dataLoaded = false;
	caDemographics?: Demographics;
	caDemographicsString: string = '';
	isCanada = environment.instance === 'Canada';
	orderItemLoaded$ = new Subscription();

	@Input() digitalType: string = "";

	getCampaignName(): string {
		let campaignName = '';
		switch (this.digitalType) {
			case 'display ads':
				let googleName = this.orderLayoutService.selectedCustomer?.company!.replace(/[^a-zA-Z\d]/g, "");
				googleName = googleName?.replace(/[\s]/g, "");
				campaignName = (this.isCanada ? "CA" : "US") + this.orderLayoutService.orderId + "_" + this.orderLayoutService.orderItemId + '_' + googleName;
				break;
			case 'targeted emails':
				let emailName = this.orderLayoutService.selectedCustomer?.company!.replace(/[^a-zA-Z ]/g, "");
				emailName = emailName?.replace(/[\s]/g, "");
				campaignName = this.orderLayoutService.orderId + '_' + emailName;
				break;
			case 'facebook ads':
			case 'linkedin ads':
			case 'instagram':
			case 'spotify':
			case 'hulu':
			case 'qr code':
			case 'tik tok':
				campaignName = (this.isCanada ? "CA" : "US") + this.orderLayoutService.orderId + "_" + this.orderLayoutService.selectedCustomer?.company;
				break;
		}
		return campaignName;
	}
	constructor(
		public aRoute: ActivatedRoute,
		public orderLayoutService: OrderlayoutService,
		private mapDataService: MapDataService,
		public pmSelectionService: PmSelectionService,
		public equifaxSelectionService: EquifaxSelectionService,
		private toastService: ToastService) {
	}

	ngOnInit(): void {
		this.loading = true;
		this.orderItemLoaded$ = this.orderLayoutService.selectedOrderItemLoaded.subscribe(async orderItemSummary => {
			if (orderItemSummary) {
				try {
					this.campaignName = this.getCampaignName();
					if ((this.orderLayoutService.selectedOrderItemDistributions?.length ?? 0) === 0) {
						return;
					}
					const distribution = this.orderLayoutService.selectedOrderItemDistributions![0].distribution;
					switch (distribution?.uSelectId) {
						case USelect.eddm:
							// get eddm saved selections
							this.eddmDistribution = await this.mapDataService.getCustomerDistribution(this.orderLayoutService.selectedCustomer?.customerID!, distribution.distributionId);
							this.startingAddress = this.eddmDistribution.startAddress !== undefined ? this.eddmDistribution.startAddress : '';
							this.startingZip = this.eddmDistribution.startZipCode !== undefined ? this.eddmDistribution.startZipCode : '';
							this.startingPoint = await this.mapDataService.getRouteCentroid(this.orderLayoutService.selectedCustomer!.customerID!, distribution.distributionId);

							try {
								const mapImageResponse = await this.mapDataService.getMapImage(distribution.distributionId);
								if (mapImageResponse) {
									const reader = new FileReader();
									reader.readAsDataURL(mapImageResponse.data);
									this.mapImageSrc = await new Promise<string>(resolve => reader.onloadend = () => resolve(<string>reader.result));
								}
							}
							catch (ex) {
							}

							try {
								const radiusResult = await this.mapDataService.getDistributionCoverageRadii(distribution.distributionId);
								this.radiusTargeting = radiusResult.filter(r => r.freeformAddress && r.freeformAddress !== '').map(r => r.freeformAddress!).join('\r\n');
							}
							catch (err: any) {
								this.toastService.showError(err.message.toString(), 'Problem loading Radii Addresses');
							}

							this.usAreas = this.eddmDistribution.carrierRoutes!.sort(this.zipsort);
							this.eddmDistribution.carrierRoutes?.forEach(e => {
								this.zipsArray.push(e.name!);
							});

							for (let i = 0; i < this.zipsArray.length; i++) {
								this.zipsArray[i] = this.zipsArray[i].slice(0, 5);
							}

							this.zipCodesList = this.zipsArray.toString();

							this.demographics = this.eddmDistribution.demographics!;
							this.viewDemographicFilters();
							break;
						case USelect.buildYourList:
							// show personalized mail built list filters
							const pmDistribution = distribution as BuiltPersonalizedMailDistribution;
							if (!pmDistribution.suppressedByDistributionId) {
								this.viewPersonalizedMailFilters(pmDistribution);
							}
							break;
						case USelect.uploadYourList:
							break;
						case USelect.equifaxB2B:
							// show equifax list filters
							const equifaxDistribution = distribution as BuiltEquifaxB2BDistribution;
							if (!equifaxDistribution.suppressedByDistributionId) {
								this.viewEquifaxFilters(equifaxDistribution);
							}
							break;
					}


				}
				 catch (error) {
					 this.toastService.showError('There was a problem loading the component', 'Load Error');
					 console.log(error);
				}
				 finally {
					 this.loading = false;
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.orderItemLoaded$.unsubscribe();
	}

	copyToClipboard(value: string, description: string) {
		navigator.clipboard
			.writeText(value)
			.then(
				() => this.toastService.showInfo(`The ${description} value has been copied to your clipboard.`),
				() => this.toastService.showError(`There was a problem copying the ${description} value to your clipboard`)
			);
	}

	viewDemographicFilters(): void {
		this.filters.clear();
		if (this.demographics?.hhIncome !== undefined && this.demographics?.hhIncome.length > 0) {
			this.filters.set('Household Income', [this.incomeRange(this.demographics?.hhIncome)]);
		}
		if (this.demographics?.ages !== undefined && this.demographics?.ages.length > 0) {
			this.filters.set('Ages', [this.ageRange(this.demographics?.ages)]);
		}
		if (this.demographics?.children !== undefined && this.demographics?.children.length > 0) {
			this.filters.set('Children Present', [this.childrenPresent(this.demographics?.children)]);
		}
		if (this.demographics?.gender !== undefined && this.demographics?.gender.length > 0) {
			this.filters.set('Gender', [this.selectedGender(this.demographics?.gender)]);
		}
		if (this.demographics?.homeOwnership !== undefined && this.demographics?.homeOwnership.length > 0) {
			this.filters.set('Home Ownership', [this.homeowner(this.demographics?.homeOwnership)]);
		}
		if (this.demographics?.lengthOfResidence !== undefined && this.demographics?.lengthOfResidence.length > 0) {
			this.filters.set('Length Of Residence', [this.getLengthOfResidence(this.demographics?.lengthOfResidence)]);
		}

		for (let [key, value] of this.filters) {
			this.demographicsString = this.demographicsString + key + ': ' + value + '\n';
		}
	}
	viewCaDemographicFilters(): void {
		this.filters.clear();

		if (this.caDemographics?.hhIncome !== undefined && this.caDemographics?.hhIncome.length > 0) {
			this.filters.set('Household Income', [this.caIncomeRange(this.caDemographics?.hhIncome)]);
		}
		if (this.caDemographics?.ages !== undefined && this.caDemographics?.ages.length > 0) {
			this.filters.set('Ages', [this.caAgeRange(this.caDemographics?.ages)]);
		}
		if (this.caDemographics?.children !== undefined && this.caDemographics?.children.length > 0) {
			this.filters.set('Children Present', [this.childrenPresent(this.caDemographics?.children)]);
		}
		if (this.caDemographics?.gender !== undefined && this.caDemographics?.gender.length > 0) {
			this.filters.set('Gender', [this.selectedGender(this.caDemographics?.gender)]);
		}
		if (this.caDemographics?.homeOwnership !== undefined && this.caDemographics?.homeOwnership.length > 0) {
			this.filters.set('Home Ownership', [this.homeowner(this.caDemographics?.homeOwnership)]);
		}

		for (let [key, value] of this.filters) {
			this.demographicsString = this.demographicsString + key + ': ' + value + '\n';
		}
	}

	incomeRange(selections: string[]): string {
		const hhIncomeMap = new Map<string, string>([
			['1', '<25k'],
			['5', '25k-50k'],
			['A', '50k-75k'],
			['E', '75k-100k'],
			['F', '100k-150k'],
			['G', '150k-200k'],
			['I', '200k-250k'],
			['J', '250k+']
		]);
		let values: string[] = [];
		selections.map(x => {
			const range = hhIncomeMap.get(x);
			if (range) {
				values.push(range);
			}
		});
		return values.join(', ');
	}

	caIncomeRange(selections: string[]): string {
		const hhIncomeMap = new Map<string, string>([
			['A', '<20k'],
			['B', '20k-40k'],
			['C', '40k-60k'],
			['D', '60k-80k'],
			['E', '80k-100k'],
			['F', '100k+'],
			/*['F', '100k-150k'],
			['G', '150k-200k'],
			['I', '200k-250k'],
			['J', '250k+']*/
		]);
		let values: string[] = [];
		selections.map(x => {
			const range = hhIncomeMap.get(x);
			if (range) {
				values.push(range);
			}
		});
		return values.join(', ');
	}

	ageRange(selections: string[]): string {
		const ageMap = new Map<string, string>([
			['Age18_24', '18'],
			['Age25_34', '25'],
			['Age35_44', '35',],
			['Age45_54', '45'],
			['Age55_64', '55'],
			['Age65_', '65+']
		]);
		return `${ageMap.get(selections[0])!} - ${ageMap.get(selections[selections.length - 1])!}`;
	}

	caAgeRange(selections: string[]): string {
		const ageMap = new Map<string, string>([
			['A', '0'],
			['B', '5'],
			['C', '10'],
			['D', '15'],
			['E', '20'],
			['F', '25'],
			['G', '30'],
			['H', '35'],
			['I', '40'],
			['J', '45'],
			['K', '50'],
			['L', '55'],
			['M', '60'],
			['N', '65'],
			['O', '70'],
			['P', '75'],
			['Q', '80'],
			['R', '85+']
		]);
		return `${ageMap.get(selections[0])!} - ${ageMap.get(selections[selections.length - 1])!}`;
	}
	childrenPresent(selections: string[]): string {
		return (selections[0].toLowerCase() === 'children') ? 'Yes' : 'No';
	}
	selectedGender(selections: string[]): string {
		return (selections[0].toLowerCase() === 'm' || selections[0].toLowerCase() === 'male') ? 'Male' : 'Female';
	}
	homeowner(selections: string[]): string {
		return (selections[0].toLowerCase() === 'o' || selections[0].toLowerCase() === 'owner') ? 'Yes' : 'No';
	}
	getLengthOfResidence(selections: number[]): string {
		return `${selections[0]} - ${selections[selections.length - 1]} years`;
	}

	viewPersonalizedMailFilters(distribution: BuiltPersonalizedMailDistribution) {
		this.pmSelectionService.query = distribution.filters!;
		this.pmSelectionService.viewOnly = true;
		const addresses: GooglePlaceResult[] = this.pmSelectionService.query.radiusQuery?.map(mapAddressTradeAreaToGooglePlaceAddress) ?? [];
		this.pmSelectionService.queryUpdates.next(this.pmSelectionService.query);
		this.pmSelectionService.addressUpdates.next(addresses);
	}

	viewEquifaxFilters(distribution: BuiltEquifaxB2BDistribution) {
		this.equifaxSelectionService.query = distribution.filters!;
		this.filters.clear();
		const addresses: GooglePlaceResult[] = this.equifaxSelectionService.query.radiusQuery?.map(mapAddressTradeAreaToGooglePlaceAddress) ?? [];
		this.equifaxSelectionService.queryUpdates.next(this.equifaxSelectionService.query);
		this.equifaxSelectionService.addressUpdates.next(addresses);
	}

	zipsort(area1: CarrierRoute, area2: CarrierRoute): number {
		let area1Name = area1.name ?? "";
		let area2Name = area2.name ?? "";
		if (area1Name < area2Name) {
			return -1;
		}
		if (area1Name > area2Name) {
			return 1;
		}
		return 0;
	}

	caZipSort(area1: LetterCarrierWalk, area2: LetterCarrierWalk): number {
		let area1Name = area1.name ?? "";
		let area2Name = area2.name ?? "";
		if (area1Name < area2Name) {
			return -1;
		}
		if (area1Name > area2Name) {
			return 1;
		}
		return 0;
	}

	async loadDeliveryData() {
		let success = true;
		this.loading = true;
		this.campaignName = this.getCampaignName();
		try {
			const distribution = this.orderLayoutService.selectedOrderItemDistributions![0]?.distribution;
			switch (distribution?.uSelectId) {
				case USelect.eddm:
					// get eddm saved selections
					this.eddmDistribution = await this.mapDataService.getCustomerDistribution(
						this.orderLayoutService.selectedCustomer!.customerID!, this.orderLayoutService.selectedOrderItemAsCartProduct!.distributionId);
					this.startingAddress = this.eddmDistribution.startAddress !== undefined ? this.eddmDistribution.startAddress : '';
					this.startingZip = this.eddmDistribution.startZipCode !== undefined ? this.eddmDistribution.startZipCode : '';
					this.startingPoint = await this.mapDataService.getRouteCentroid(this.orderLayoutService.selectedCustomer!.customerID!, this.orderLayoutService.selectedOrderItemAsCartProduct!.distributionId);


					try {
						const mapImageResponse = await this.mapDataService.getMapImage(this.orderLayoutService.selectedOrderItemAsCartProduct!.distributionId);
						if (mapImageResponse) {
							const reader = new FileReader();
							reader.readAsDataURL(mapImageResponse.data);
							this.mapImageSrc = await new Promise<string>(resolve => reader.onloadend = () => resolve(<string>reader.result));
						}
					}
					catch (ex) {
						console.log(ex);
						this.toastService.showError('There was an error loading the map image');
					}

					try {
						const radiusResult = await this.mapDataService.getDistributionCoverageRadii(this.orderLayoutService.selectedOrderItemAsCartProduct!.distributionId);
						this.radiusTargeting = radiusResult.filter(r => r.freeformAddress && r.freeformAddress !== '').map(r => r.freeformAddress!).join('\r\n');
					}
					catch (err: any) {
						console.log(err);
						this.toastService.showError(err.message.toString(), 'Problem loading Radii Addresses');
					}

					this.usAreas = this.eddmDistribution.carrierRoutes!.sort(this.zipsort);
					this.eddmDistribution.carrierRoutes?.forEach(e => {
						this.zipsArray.push(e.name!);
					});

					for (let i = 0; i < this.zipsArray.length; i++) {
						this.zipsArray[i] = this.zipsArray[i].slice(0, 5);
					}

					this.zipCodesList = this.zipsArray.toString();

					this.demographics = this.eddmDistribution.demographics!;
					this.viewDemographicFilters();
					break;
				case USelect.snapAdmail:
					const snapDistribution = await this.mapDataService.getSnapAdMailDistribution(
						this.orderLayoutService.selectedCustomer!.customerID!, this.orderLayoutService.selectedOrderItemAsCartProduct!.distributionId);

						this.startingAddress = snapDistribution.startAddress !== undefined ? snapDistribution.startAddress : '';
						this.startingZip = snapDistribution.startZipCode !== undefined ? snapDistribution.startZipCode : '';
						this.startingPoint = await this.mapDataService.getCaRouteCentroid(this.orderLayoutService.selectedCustomer!.customerID!, this.orderLayoutService.selectedOrderItemAsCartProduct!.distributionId);

						try {
							const mapImageResponse = await this.mapDataService.getCaMapImage(this.orderLayoutService.selectedOrderItemAsCartProduct!.distributionId);
							if (mapImageResponse) {
								const reader = new FileReader();
								reader.readAsDataURL(mapImageResponse.data);
								this.mapImageSrc = await new Promise<string>(resolve => reader.onloadend = () => resolve(<string>reader.result));
							}
						}
						catch (ex) {
							console.log(ex);
							this.toastService.showError('There was an error loading the map image');
						}

						try {
							const radiusResult = await this.mapDataService.getCaDistributionCoverageRadii(this.orderLayoutService.selectedCustomer!.customerID!, this.orderLayoutService.selectedOrderItemAsCartProduct!.distributionId);
							this.radiusTargeting = radiusResult.filter(r => r.freeformAddress && r.freeformAddress !== '').map(r => r.freeformAddress!).join('\r\n');
						}
						catch (err: any) {
							console.log(err);
							this.toastService.showError(err.message.toString(), 'Problem loading Radii Addresses');
						}

						this.caAreas = snapDistribution.letterCarrierWalks!.sort(this.caZipSort);
						snapDistribution.letterCarrierWalks?.forEach(e => {
							this.zipsArray.push(e.name!);
						});

						snapDistribution.letterCarrierWalks?.forEach(e => {
							this.zipsArray.push(e.name!);
						});
						for (let i = 0; i < this.zipsArray.length; i++) {
							this.zipsArray[i] = this.zipsArray[i].slice(0, 15);
						}

						this.zipCodesList = this.zipsArray.toString();
						this.caDemographics = snapDistribution.demographics!;
						this.viewCaDemographicFilters();
					break;
				case USelect.buildYourList:
					// show personalized mail built list filters
					const pmDistribution = distribution as BuiltPersonalizedMailDistribution;
					if (!pmDistribution?.suppressedByDistributionId) {
						this.viewPersonalizedMailFilters(pmDistribution);
					}
					break;
				case USelect.uploadYourList:
					break;
				case USelect.equifaxB2B:
					// show equifax list filters
					const equifaxDistribution = distribution as BuiltEquifaxB2BDistribution;
					this.viewEquifaxFilters(equifaxDistribution);
					break;
			}


		}
		catch (error) {
			success = false;
			console.log(error);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.dataLoaded = true;
			this.displayDeliveryInfoButton = false;
		}
	}
}
