<!-- BEGIN #header -->
<div id="header" class="app-header">
	<!-- BEGIN navbar-header -->
	<div class="navbar-header">
		<a href="/" class="navbar-brand"> <img *ngIf="!isCanada" src="/assets/header-logo.png"/>
			<img *ngIf="isCanada" src="/assets/taradel-maple.png"/></a>
		<span class="badge rounded-pill environment" [ngClass]="environmentClass">{{environmentName}}</span>
		<button type="button" class="navbar-mobile-toggler" data-toggle="app-sidebar-mobile" (click)="toggleAppSidebarMobile()">
			<span class="icon-bar"></span>
			<span class="icon-bar"></span>
			<span class="icon-bar"></span>
		</button>
	</div>
	<!-- END navbar-header -->

	<!-- BEGIN header-nav -->
	<div class="navbar-nav">
		<!-- <div class="navbar-item navbar-form">
			<form action="" method="POST" name="search">
				<div class="form-group">
					<input type="text" class="form-control" placeholder="Enter keyword" />
					<button type="submit" class="btn btn-search"><i class="fa fa-search"></i></button>
				</div>
			</form>
		</div> -->
		<!-- <div class="navbar-item dropdown">
			<a href="#" data-bs-toggle="dropdown" class="navbar-link dropdown-toggle fs-14px">
				<i class="fa fa-bell"></i>
				<span class="badge">0</span>
			</a>
			<div class="dropdown-menu media-list dropdown-menu-end">
				<div class="dropdown-header">NOTIFICATIONS (0)</div>
				<div class="text-center w-300px py-3">
					No notification found
				</div>
			</div>
		</div> -->
		<div class="navbar-item navbar-user dropdown">
			<span href="#" class="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
				<img [src]="getGravatarLink()" />
				<span class="d-none d-md-inline">{{ currUserProfile?.name }}</span>
			</span>
			<div class="dropdown-menu dropdown-menu-end me-1">
				<!--
				<a href="javascript:;" class="dropdown-item">Edit Profile</a>
				<a href="javascript:;" class="dropdown-item d-flex align-items-center">
					Inbox
					<span class="badge bg-danger rounded-pill ms-auto pb-4px">2</span>
				</a>
				<a href="javascript:;" class="dropdown-item">Calendar</a>
				<a href="javascript:;" class="dropdown-item">Setting</a>
				<div class="dropdown-divider"></div>
				-->
				<a (click)="logout()" class="dropdown-item">Log Out</a>
			</div>
		</div>
	</div>
	<!-- END header-nav -->
</div>
<!-- END #header -->
