import { Injectable } from '@angular/core';
import { SitesService } from './sites.service';

@Injectable({
	providedIn: 'root'
})
export class SiteConfigService {

	constructor(private sitesService: SitesService) { }

	async getString(siteId: number, configCat: string, configName: string): Promise<string> {
		const allConfigs = await this.sitesService.getSettingsWithDefaults(siteId);
		const config = allConfigs.find(c => c.configCat === configCat && c.configName === configName);
		const value = config?.configVal ?? config?.defaultConfigVal;

		return value ?? '';
	}

	async getNumber(siteId: number, configCat: string, configName: string): Promise<number> {
		const value = await this.getString(siteId, configCat, configName);

		return value === '' ? 0 : parseInt(value, 10);
	}

	async getBoolean(siteId: number, configCat: string, configName: string): Promise<boolean> {
		const value = (await this.getString(siteId, configCat, configName)).toLowerCase();

		return value === 'true' || value === 'yes' || value === '1';
	}
}
