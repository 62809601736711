import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isNumber } from 'lodash';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
	parse(value: string): NgbDateStruct | null {
		if (value) {
			const dateParts = value.trim().split('-');
			if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
				return {day: +dateParts[0], month: +dateParts[1], year: +dateParts[2]};
			}
		}
		return null;
	}

	format(date: NgbDateStruct): string {
		return date ? `${('0'+ date.month).slice(-2)}/${('0'+date.day).slice(-2)}/${date.year}` : '';
	}
}
