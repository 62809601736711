import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
	loading = false;
	activeTab = 'customerPaymentsTab';
	constructor(private route: ActivatedRoute,
		private location: Location) {
		route.paramMap.subscribe(params => {
			let state: any;
			let tab: string | undefined;
			if (!!location.getState()) {
				state = location.getState();
			}
			else {
				state = undefined;
			}
			if (!!state) {
				tab = state.tabId;
			}
			else {
				tab = undefined;
			}
			if (!!tab) {
				this.activeTab = tab;
			}
			location.replaceState(`/payments`);
		});
	}

	ngOnInit() {
		this.loading = false;
	}

}
