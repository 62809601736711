import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { USelectProductConfiguration } from '@taradel/admin-api-client';
import { Coupon, CouponProduct, WLProduct } from '@taradel/web-api-client';
import { HttpErrorBody } from 'app/utils/http-error';
import { environment } from 'environment';
import { AuthenticationService } from 'services/authentication.service';
import { CouponService } from 'services/coupon.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-coupon-details',
	templateUrl: './coupon-details.component.html',
	styleUrls: ['./coupon-details.component.scss']
})
export class CouponDetailsComponent implements OnInit {
	loading = false;
	isCanada = environment.instance === 'Canada';
	couponId = 0;
	coupon: Coupon = new Coupon();
	couponProducts: WLProduct[] = [];
	filteredCouponProducts: WLProduct[] = [];
	couponForm!: UntypedFormGroup;
	productUSelects: USelectProductConfiguration[] = [];
	activeUSelect: number = 0;
	from = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
	to = new Date();
	today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
	endDateStart = formatDate(new Date(), 'yyyy-MM-dd', 'en');
	submitted = false;
	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		public authService: AuthenticationService,
		private couponSerivce: CouponService,
		public siteService: SitesService,
		private toastService: ToastService
	) {
		this.couponForm = formBuilder.group({
			couponCode: ['', Validators.required],
			couponDescription: [''],
			startDate: ['', Validators.required],
			endDate: ['', Validators.required],
			discountAmount: ['', Validators.required],
			discountPercent: [false],
			applyTo: ['', Validators.required],
			minimumOrderAmout: ['', Validators.required],
			singleUse: [false],
			selectedProducts: [''],
			uSelect: ['']
		});
		if (authService.organizationId !== 1) {
			this.couponForm.disable();
		}
	}

	ngOnInit() {
		this.route.paramMap.subscribe(async params => {
			const siteId = parseInt(params.get('siteId') ?? '0', 10);
			this.couponProducts = await this.siteService.getSiteProducts(siteId);
			this.filteredCouponProducts = this.couponProducts;
			this.getUSelectsFromProducts(this.couponProducts);
			const couponId = parseInt(params.get('couponId') ?? '0', 10);
			this.couponId = !isNaN(couponId) ? couponId : 0;
			if (this.couponId > 0) {
				try {
					this.loading = true;
					this.coupon = await this.couponSerivce.getCoupon(this.couponId);
					this.initializeCouponForm();

				}
				catch {
					this.toastService.showError('There was an error getting the coupon');
				}
				finally {
					this.loading = false;
				}
			}
		});
	}

	initializeCouponForm() {
		this.couponForm.controls.couponCode.setValue(this.coupon?.couponCode!);
		this.couponForm.controls.couponDescription.setValue(this.coupon?.description!);
		this.couponForm.controls.startDate.setValue(formatDate(this.coupon?.startDate!.toLocaleDateString(), 'yyyy-MM-dd', 'en'));
		this.couponForm.controls.endDate.setValue(formatDate(this.coupon?.endDate!.toLocaleDateString(), 'yyyy-MM-dd', 'en'));
		this.from = this.coupon.startDate!;
		this.to = this.coupon.endDate!;
		this.couponForm.controls.discountAmount.setValue(this.coupon?.discountAmount!);
		this.couponForm.controls.discountPercent.setValue(this.coupon?.discountPercent!);
		this.couponForm.controls.applyTo.setValue(this.coupon?.applyDiscount!);
		this.couponForm.controls.minimumOrderAmout.setValue(this.coupon?.minimumOrderAmt!);
		this.couponForm.controls.singleUse.setValue(this.coupon?.singleUse!);
		this.couponForm.controls.selectedProducts.patchValue(this.coupon.allowedProducts?.map(x => x.productId));
	}

	get f() {
		return this.couponForm.controls;
	}

	async saveCoupon() {
		this.submitted = true;
		if (this.couponForm.invalid) {
			return;
		}

		try {
			this.loading = true;
			this.coupon.couponCode = this.couponForm.controls.couponCode.value;
			this.coupon.description = this.couponForm.controls.couponDescription.value;
			this.coupon.discountAmount = this.couponForm.controls.discountAmount.value;
			this.coupon.discountPercent = this.couponForm.controls.discountPercent.value;
			this.coupon.applyDiscount = this.couponForm.controls.applyTo.value;
			this.coupon.minimumOrderAmt = this.couponForm.controls.minimumOrderAmout.value;
			this.coupon.singleUse = this.couponForm.controls.singleUse.value;
			this.coupon.startDate = this.from;
			this.coupon.endDate = this.to;
			this.coupon.allowedProducts = [];
			if (this.couponForm.controls.selectedProducts.value.length > 0 && this.couponForm.controls.applyTo.value === "AllowedProductTotal") {
				this.couponForm.controls.selectedProducts.value.forEach((productId: number) =>
					this.coupon.allowedProducts?.push(
						new CouponProduct({
							couponProductId: 0,
							couponId: this.couponId,
							productId
						})));
			}

			await this.couponSerivce.updateCoupon(this.coupon);
			this.toastService.showSuccess('Coupon updated successfully');
		}
		catch (error: any) {
			if (error.status === 400) {
				const response = JSON.parse(error.response) as HttpErrorBody;
				this.toastService.showError(response.detail);
			}
			else {
				this.toastService.showError('There was an error updating the coupon. Check your network tab for more info.');
			}
		}
		finally {
			this.loading = false;
		}
	}

	async createCoupon() {
		this.submitted = true;
		if (this.couponForm.invalid) {
			return;
		}

		try {
			this.loading = true;
			this.coupon.affiliateId = this.siteService.currentSiteId;
			this.coupon.couponCode = this.couponForm.controls.couponCode.value;
			this.coupon.description = this.couponForm.controls.couponDescription.value;
			this.coupon.discountAmount = this.couponForm.controls.discountAmount.value;
			this.coupon.discountPercent = this.couponForm.controls.discountPercent.value;
			this.coupon.applyDiscount = this.couponForm.controls.applyTo.value;
			this.coupon.minimumOrderAmt = this.couponForm.controls.minimumOrderAmout.value;
			this.coupon.singleUse = this.couponForm.controls.singleUse.value;
			this.coupon.startDate = this.from;
			this.coupon.endDate = this.to;
			this.coupon.allowedProducts = [];
			if (this.couponForm.controls.selectedProducts.value.length > 0 && this.couponForm.controls.applyTo.value === "AllowedProductTotal") {
				this.couponForm.controls.selectedProducts.value.forEach((productId: number) =>
					this.coupon.allowedProducts?.push(
						new CouponProduct({
							couponProductId: 0,
							couponId: this.couponId,
							productId
						})));
			}

			const couponId = await this.couponSerivce.createCoupon(this.coupon);
			this.router.navigateByUrl(`sites/${this.siteService.currentSiteId}/coupons/${couponId}`);
		}
		catch (error: any) {
			if (error.status === 400) {
				const response = JSON.parse(error.response) as HttpErrorBody;
				this.toastService.showError(response.detail);
			}
			else {
				this.toastService.showError('There was an error creating the coupon. Check your network tab for more info.');
			}
		}
		finally {
			this.loading = false;
		}
	}

	startDateSelected(startDate: string) {
		// const startDate = this.couponForm.get('startDate')?.value;
		if (startDate.length === 0) {
			this.couponForm.get('endDate')!.disable();
		}
		else {
			const [year, month, day] = startDate.split('-');
			this.from = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
			this.endDateStart = formatDate(this.from, 'yyyy-MM-dd', 'en');
			this.couponForm.get('endDate')!.enable();
		}
	}

	endDateSelected(endDate: string) {
		// const endDate = this.couponForm.get('endDate')?.value;
		if (endDate.length > 0) {
			const [year, month, day] = endDate.split('-');
			this.to = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
		}
	}

	getUSelectsFromProducts(products: WLProduct[]) {
		products.forEach(p => {
			if (p.baseProduct?.uSelectConfigurations?.[0]) {
				let uSelectFound = this.productUSelects.find(pu => pu.uSelectId === p.baseProduct?.uSelectConfigurations?.[0].uSelectId);
				if (!uSelectFound) {
					this.productUSelects.push(p.baseProduct?.uSelectConfigurations[0]);
				}
			}
		});
	}

	getUSelectName(id: number) {
		return this.siteService.getUSelectName(id);
	}

	filterProductsByUSelect(uSelectId: string) {
		if (uSelectId === "0") {
			this.filteredCouponProducts = this.couponProducts;
		}
		else {
			this.filteredCouponProducts = this.couponProducts.filter(e => e.baseProduct?.uSelectConfigurations?.[0]?.uSelectId?.toString() === uSelectId);
		}
	}

}
