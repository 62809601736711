import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PrintAndShipCategory, PrintAndShipCategoryProduct, Product, ProductsSearch } from '@taradel/admin-api-client';
import { SortPipe } from 'components/shared/sort/sort.pipe';
import { PrintAndShipService } from 'services/print-and-ship.service';
import { ProductsService } from 'services/products.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-category-products',
  templateUrl: './category-products.component.html',
  styleUrls: ['./category-products.component.scss']
})
export class CategoryProductsComponent implements OnInit {

	loading = true;
	submitted = false;
	categoryForm: UntypedFormGroup;
	categoryId = 0;
	category!: PrintAndShipCategory;
	filterKey = '';
	masterListOfProducts: Product[] =[];
	listOfProducts: Product[] =[];
	selectedProducts: Product[] = [];
	listOfSelectedProducts: Product[] = [];
	constructor(
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private productService: ProductsService,
		private printAndShipService: PrintAndShipService,
		private toastService: ToastService,
		private sortPipe: SortPipe
	) {
		this.categoryId = parseInt(route.snapshot.params?.categoryId, 10);
		this.categoryForm = this.formBuilder.group({
			name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
			channelPrefix: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
		});
	}

	async ngOnInit(): Promise<void> {
		try {
			this.category = await this.printAndShipService.getCategory(this.categoryId);

			this.masterListOfProducts = await this.productService.getAllProducts(new ProductsSearch({
				name: '',
				pageNo: 1,
				productId: 0,
				pageSize: 1000,
				uSelectId: 0
			}));
			this.populateData();
		}
		catch (err: any) {
			this.toastService.showWarning('There was an error loading the category data');
		}
		finally {
			this.loading = false;
		}
	}

	populateData() {
		this.f.name.setValue(this.category.name);
		this.f.channelPrefix.setValue(this.category.channelPrefix);
		this.setSelectedProductsList();
	}

	setSelectedProductsList(): void {
		let selectedList: Product[] = [];
		const selectedProductIds: number[] = [];
		this.category.categoryProducts?.map(p => selectedProductIds.push(p.productId));
		this.setUnSelectedProductsList(selectedProductIds);

		selectedProductIds.map(x => {
			selectedList.push(this.masterListOfProducts.find(p => p.productId === x)!);
		});
		this.listOfSelectedProducts = selectedList;
	}
	setUnSelectedProductsList(selectedProductIds: number[]) {
		let unselected = Array.from(this.masterListOfProducts);
		this.masterListOfProducts.forEach(x => {
			if (selectedProductIds.includes(x.productId)) {
				const index = unselected.indexOf(x);
				unselected.splice(index, 1);
			}
		});

		this.listOfProducts = unselected;
	}

	filterProducts() {
		this.setUnSelectedProductsList(this.listOfSelectedProducts.map(x => x.productId));
		this.listOfProducts = this.listOfProducts.filter(x => x.name?.includes(this.filterKey));
	}

	async saveCategory(): Promise<void> {
		this.submitted = true;
		if (this.categoryForm.invalid) {
			return;
		}
		this.loading = true;
		const categoryProducts: PrintAndShipCategoryProduct[] = [];
		this.listOfSelectedProducts.map(x => categoryProducts.push(new PrintAndShipCategoryProduct({
			categoryId: this.categoryId,
			productId: x.productId
		})));

		try {
			await this.printAndShipService.updateCategory(new PrintAndShipCategory({
				categoryId: this.categoryId,
				name: this.categoryForm.controls.name.value,
				channelPrefix: this.categoryForm.controls.channelPrefix.value,
				categoryProducts
			}));
			this.toastService.showSuccess('Category was updated successfully');
		}
		catch (err: any) {
			this.toastService.showWarning(err.message, 'Error saving category');
		}
		finally {
			this.loading = false;
		}
	}

	selectProductsFromList(): void {
		this.listOfSelectedProducts.push(...this.selectedProducts);
		this.listOfSelectedProducts = this.sortPipe.transform(this.listOfSelectedProducts, 'asc', 'name');
		this.listOfProducts = this.listOfProducts.filter(p => this.selectedProducts.findIndex(x => x.productId === p.productId) === -1);
		this.listOfProducts= this.sortPipe.transform(this.listOfProducts, 'asc', 'name');
	}

	removeProductsFromList(): void {
		this.listOfProducts.push(...this.selectedProducts);
		this.listOfSelectedProducts = this.sortPipe.transform(this.listOfSelectedProducts, 'asc', 'name');
		this.listOfSelectedProducts = this.listOfSelectedProducts.filter(p => this.selectedProducts.findIndex(x => x.productId === p.productId) === -1);
		this.listOfProducts = this.sortPipe.transform(this.listOfProducts, 'asc', 'name');
	}

	get f() {
		return this.categoryForm.controls;
	}
}
