import { Polygon, MultiPolygon, Feature } from "geojson";
import { centroid, polygon, feature, intersect, featureCollection, area } from "@turf/turf";

export function calculatePolygonArea(poly: Polygon | MultiPolygon) {
	let s = 0.0;
	let ring = poly.coordinates[0] as any;
	for (let i = 0; i < (ring.length - 1); i++) {
		const [cx,cy] = ring[i];
		const [nx,ny] = ring[i + 1];
		if (isNaN(s)) {
			throw new Error('NaN detected');
		}
		s += (cx * ny - nx * cy);
		if (isNaN(s)) {
			throw new Error('NaN detected');
		}
	}
	return 0.5 * s;
}

export function calculatePolygonCentroid(poly: Polygon) {
	let c = [0, 0];
	let ring = poly.coordinates[0];
	for (let i = 0; i < (ring.length - 1); i++) {
		c[0] += (ring[i][0] + ring[i + 1][0]) * (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
		c[1] += (ring[i][1] + ring[i + 1][1]) * (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
		if (isNaN(c[0]) || isNaN(c[1])) {
			throw new Error('NaN detected');
		}
	}
	let a = calculatePolygonArea(poly);
	c[0] /= a * 6;
	c[1] /= a * 6;
	return c;
}

export function calculatePolygonCentroidTurf(feature: Feature) {
	const result = centroid(feature);
	return result;
}

export function convertGoogleMapsPolygonToGeoJsonFeature(poly: google.maps.Polygon): Feature<Polygon> {
	const polyCoords = poly.getPath().getArray().map((latLng) => [latLng.lng(), latLng.lat()]);
	const polyGeoJson = polygon([polyCoords]);
	return polyGeoJson;
}

export function calculateIntersectionPercentage(userPolygonGeoJson: Feature<Polygon>, featureToCheck: Feature): number {
	if (!featureToCheck.geometry) {
		return 0;
	}
	if (featureToCheck.geometry.type !== 'Polygon' && featureToCheck.geometry.type !== 'MultiPolygon') {
		return 0;
	}
	const featureGeoJson = feature(featureToCheck.geometry);
	const coll = featureCollection([userPolygonGeoJson, featureGeoJson]);
	const intersectionPolygon = intersect(coll);
	if (!intersectionPolygon) {
		return 0;
	}
	const intersectionArea = area(intersectionPolygon);
	const featureArea = area(featureGeoJson);
	const overlap = intersectionArea / featureArea;

	return overlap;
}
