<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="d-flex justify-content-between p-2">
			<div class="form-floating">
				<select class="form-select w-auto" name="pmodConfig" [(ngModel)]="tableValue"
					aria-label="PMOD Config">
					<option [ngValue]="0">PMOD Container Capacity</option>
					<option [ngValue]="1">PMOD Container Zone Cost</option>
				</select>
				<label for="pmodConfig">PMOD Config</label>
			</div>
			<button class="btn btn-success" *ngIf="tableValue === 0" data-bs-toggle="modal" data-bs-target="#addPmodContainer">
				Add Container
			</button>
			<button class="btn btn-success" *ngIf="tableValue === 1" data-bs-toggle="modal" data-bs-target="#addPmodZoneCost">
				Add Zone Cost
			</button>
		</div>

		<div class="table-responsive" *ngIf="tableValue === 0">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Index</th>
						<th>Item Size</th>
						<th>Capacity</th>
						<th>Container</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let capacity of pmodCapacity; let i = index"
					 data-bs-toggle="modal" data-bs-target="#editPmodContainer"
					 role="button" (click)="setPmodCapacity(capacity, true)">
						<td>
							{{ i }}
						</td>
						<td>
							{{capacity.itemSize}}
						</td>
						<td>
							{{capacity.capacity}}
						</td>
						<td>
							{{capacity.container}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="table-responsive" *ngIf="tableValue === 1">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Index</th>
						<th>Zone</th>
						<th>Container</th>
						<th>Cost</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let zoneCost of pmodZoneCost;let i = index"
					data-bs-toggle="modal" data-bs-target="#editPmodZoneCost"
					role="button" (click)="setPmodZoneCost(zoneCost, true)">
						<td>
							{{ i }}
						</td>
						<td>
							{{zoneCost.zone}}
						</td>
						<td>
							{{zoneCost.container}}
						</td>
						<td>
							{{zoneCost.cost}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</app-spinner-with-overlay>

<!-- Modal Content -->
<div class="modal fade" id="editPmodContainer" tabindex="-1" aria-labelledby="editPmodContainerLabel" aria-hidden="true">
	<form [formGroup]="pmodCapacityForm">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="editPmodContainerLabel">Edit PMOD Capacity</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearForms()"></button>
				</div>
				<div class="modal-body">
					<div class="form-floating my-3">
						<input type="text" class="form-control" id="itemSize" formControlName="itemSize" readonly>
						<label for="itemSize">Item Size</label>
					</div>
					<div class="form-floating my-3">
						<input type="text" class="form-control" id="capacity" formControlName="capacity">
						<label for="capacity">Capacity</label>
					</div>
					<div class="form-floating my-3">
						<input type="text" class="form-control" id="container" formControlName="container" readonly>
							<label for="container">Container</label>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="clearForms()">Close</button>
						<button type="button" class="btn btn-primary" [disabled]="pmodCapacityForm.invalid" (click)="updateCapacity()" data-bs-dismiss="modal">Save changes</button>
					</div>
				</div>
			</div>
			</div>
	</form>
</div>

<div class="modal fade" id="addPmodContainer" tabindex="-1" aria-labelledby="addPmodContainerLabel" aria-hidden="true">
	<form [formGroup]="pmodCapacityForm">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="addPmodContainerLabel">Add PMOD Capacity</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearForms()"></button>
				</div>
				<div class="modal-body">
					<div class="form-floating my-3">
						<input type="text" class="form-control" id="itemSize" formControlName="itemSize">
						<label for="itemSize">Item Size</label>
					</div>
					<div class="form-floating my-3">
						<input type="text" class="form-control" id="capacity" formControlName="capacity">
						<label for="capacity">Capacity</label>
					</div>
					<div class="form-floating my-3">
						<select type="select" formControlName="container" class="form-select">
							<option value="" disabled>Select a container type</option>
							<option *ngFor="let type of capacityContainerTypes" value="{{ type }}">
								{{ type }}
							</option>
						</select>
						<label for="container">Container</label>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						<button type="button" class="btn btn-primary" [disabled]="pmodCapacityForm.invalid" (click)="updateCapacity()" data-bs-dismiss="modal">Save changes</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<div class="modal fade" id="editPmodZoneCost" tabindex="-1" aria-labelledby="editPmodZoneCostLabel" aria-hidden="true">
	<form [formGroup]="pmodZoneCostForm">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="editPmodZoneCostLabel">Edit PMOD Zone Cost</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearForms()"></button>
				</div>
				<div class="modal-body">
					<div class="form-floating my-3">
						<input type="text" class="form-control" id="zone" formControlName="zone" readonly>
							<label for="zone">Zone</label>
					</div>
					<div class="form-floating my-3">
						<input type="text" class="form-control" id="container" formControlName="container" readonly>
							<label for="container">Container</label>
					</div>
					<div class="form-floating my-3">
						<input type="text" class="form-control" id="cost" formControlName="cost">
							<label for="cost">Cost</label>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"  (click)="clearForms()">Close</button>
						<button type="button" class="btn btn-primary" [disabled]="pmodZoneCostForm.invalid" (click)="updateZoneCost()" data-bs-dismiss="modal">Save changes</button>
					</div>
				</div>
			</div>
		</div>
	</form>
	</div>

<div class="modal fade" id="addPmodZoneCost" tabindex="-1" aria-labelledby="addPmodZoneCostLabel" aria-hidden="true">
	<form [formGroup]="pmodZoneCostForm">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="addPmodZoneCostLabel">Add PMOD Zone Cost</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearForms()"></button>
				</div>
				<div class="modal-body">
					<div class="form-floating my-3">
						<input type="text" class="form-control" id="zone" formControlName="zone">
						<label for="zone">Zone</label>
					</div>
					<div class="form-floating my-3">
						<select type="select" formControlName="container" class="form-select">
							<option value="" disabled>Select a container type</option>
							<option *ngFor="let type of zoneCostContainerTypes" value="{{ type }}">
								{{ type }}
							</option>
						</select>
						<label for="container">Container</label>
					</div>
					<div class="form-floating my-3">
						<input type="text" class="form-control" id="cost" formControlName="cost">
						<label for="cost">Cost</label>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal"  (click)="clearForms()">Close</button>
					<button type="button" class="btn btn-primary" [disabled]="pmodZoneCostForm.invalid" (click)="updateZoneCost()" data-bs-dismiss="modal">Save changes</button>
				</div>
			</div>
		</div>
	</form>
</div>

