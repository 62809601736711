<app-customer-header [customerId]="customerId">
	<ng-container altnav>
		<ul class="nav nav-pills justify-content-end">
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'distributions']">Back to Distributions</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'map']">Build Map</a>
			</li>
			<li class="nav-item">
				<a class="nav-link active" [routerLink]="['/customers', customerId, 'paste-list']">Paste Routes</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'upload-list']">Upload File</a>
			</li>
		</ul>
	</ng-container>
</app-customer-header>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col">
			<div class="card">
				<form [formGroup]="zipListForm" class="card-body">
					<div class="col-sm-12">
						<div class="form-floating mb-3">
							<input type="text" class="form-control" placeholder="Enter Map Name"
								[ngClass]="{ 'is-invalid': submitted && form.mapName.errors }" formControlName="mapName" />
							<label>Enter Map Name</label>
							<div *ngIf="submitted && form.mapName.errors" class="invalid-feedback">
								<div *ngIf="form.mapName.errors.required">Map Name is required</div>
							</div>
						</div>
						<div class="mb-3">
							<div><b>Delivery Types</b></div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" name="residential"
									formControlName="residentialZips" id="residentialCheck" value="false">
								<label class="form-check-label" for="residentialCheck">Residential</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" name="business" formControlName="businessZips"
									id="businessCheck" value="false">
								<label class="form-check-label" for="businessCheck">Business</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" name="POBox" formControlName="POBoxZips"
									id="POBoxCheck" value="false">
								<label class="form-check-label" for="POBoxCheck">PO Box</label>
							</div>
							<div *ngIf="submitted && form.deliveryTypeChecked.errors" class="invalid-text">
								<div>Delivery Type is required</div>
							</div>
						</div>

						<div class="form-floating mb-3">
							<textarea
								placeholder="Paste Zip Codes (12345) and/or Carrier Routes (12345C001) separated by a comma, semi-colon or line break."
								rows="5"  style="height: 200px" cols="20" class="form-control"
								[ngClass]="{ 'is-invalid' : submitted && form.zipCodesAndRoutesString.invalid }"
								formControlName="zipCodesAndRoutesString"></textarea>
							<label>
								Paste Zip Codes (12345) and/or Carrier Routes (12345C001) separated by a comma, semi-colon or
								line break.
							</label>
							<div *ngIf="submitted && form.zipCodesAndRoutesString.errors" class="invalid-feedback">
								<div *ngIf="form.zipCodesAndRoutesString.errors.required">Zip Codes are required</div>
							</div>
						</div>

						<p>
							<button (click)="submitZips()" class="btn btn-primary"> Submit </button>
						</p>
					</div>
				</form>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
