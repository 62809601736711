import { Component, OnInit } from '@angular/core';
import { SitesService } from 'services/sites.service';
import { Site, SiteFilterRequest } from '@taradel/admin-api-client';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-sites-header',
	templateUrl: './sites-header.component.html',
	styleUrls: ['./sites-header.component.scss']
})
export class SitesHeaderComponent implements OnInit {

	allSites: Site[] = [];
	currentSite?: Site;
	hideContainer = false;

	constructor(
		public siteService: SitesService,
		public aRoute: ActivatedRoute,
		private router: Router
	) {}

	async ngOnInit() {
		this.aRoute.paramMap.subscribe(params => {
			const siteId = parseInt(params.get('siteId') ?? '-1', 10);
			this.siteService.setCurrentSiteId(siteId);
		});

		this.siteService.currentSite.subscribe(site => {
			if (site) {
				this.currentSite = site;
			}
			else {
				this.currentSite = undefined;
			}
		});

		this.allSites = await this.siteService.getSitesForAdmin(new SiteFilterRequest({
			adminCheckout: false,
			showInactive: true
		}));
	}

	changeCurrentSite(siteId: number) {
		if (!isNaN(siteId)) {
			let url = 'sites';
			const routeData = this.router.url.substring(1).split('/');
			if (routeData.length > 2) {
				routeData[1] = siteId.toString();
				url = routeData.join('/');
			}
			this.router.navigateByUrl(url);
		}
	}

}
