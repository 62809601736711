import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiException, USelectInductionTurnaround, USelectMethod } from '@taradel/web-api-client';
import { UselectSearchComponent } from 'components/site/site-uselect-addons/uselect-search/uselect-search.component';
import { DeliveryService } from 'services/delivery.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-induction-turnaround',
	templateUrl: './induction-turnaround.component.html',
	styleUrls: ['./induction-turnaround.component.scss']
})
export class InductionTurnaroundComponent implements OnInit {
	loading = true;
	uSelectMethods: USelectMethod[] = [];
	inductionTurnarounds: USelectInductionTurnaround[] = [];
	newInductionTurnaround?: USelectInductionTurnaround;
	@ViewChild('uSelectMethodListInput') uselectSearchComponent!: UselectSearchComponent;

	constructor(
		private deliveryService: DeliveryService,
		private sitesService: SitesService,
		private toastService: ToastService
	) {	}

	async ngOnInit() {
		try {
			this.uSelectMethods = await this.sitesService.getAllUSelect();
			this.inductionTurnarounds = await this.deliveryService.getUSelectInductionTurnaround();
		}
		catch (error) {
			this.toastService.showError('There was an error getting the uselect induction turnaround');
		}
		finally {
			this.loading = false;
		}
		this.initializeNewInductionTurnaround();
	}

	initializeNewInductionTurnaround() {
		this.newInductionTurnaround = new USelectInductionTurnaround();
		if (this.uselectSearchComponent) {
			this.uselectSearchComponent.uSelect = undefined;
		}
	}

	getUSelectMethod(uSelectId: number) {
		return this.uSelectMethods.find(t => t.uSelectId === uSelectId);
	}

	getUSelectName(uSelectId?: number) {
		const temp = this.uSelectMethods.find(t => t.uSelectId === uSelectId);
		return temp?.name;
	}

	async updateAllInductionTurnaround() {
		try {
			this.loading = true;
			await this.deliveryService.updateUSelectInductionTurnaround(this.inductionTurnarounds);
			this.initializeNewInductionTurnaround();
			this.toastService.showSuccess('Induction Turnaround successfully saved');
		}
		catch {
			this.toastService.showError('There was an error saving induction turnaround');
		}
		finally {
			this.loading = false;
		}
	}

	addNewInductionTurnaround() {
		this.inductionTurnarounds.push(this.newInductionTurnaround!);
		this.initializeNewInductionTurnaround();
	}

	removeSelectedInductionTurnaround(index: number) {
		this.inductionTurnarounds.splice(index, 1);
	}

	async deleteAllInductionTurnaround() {
		this.loading = true;
		try {
			await this.deliveryService.updateUSelectInductionTurnaround([]);
			this.inductionTurnarounds = [];
			this.toastService.showSuccess('Induction Turnaround deleted successfully');
		}
		catch (error: any) {
			if (ApiException.isApiException(error)) {
				this.toastService.showError(error.message);
			}
		}
		finally {
			this.loading = false;
		}
	}


	getUpdateMethod(item: {id: number, name: string}, i: number) {
		const matchingDesign = this.inductionTurnarounds.find(x => x.uSelectId === item.id);
		if (matchingDesign) {
			this.inductionTurnarounds[i].uSelectId = 0;
			this.uselectSearchComponent.uSelect = undefined;
			this.toastService.showError('Induction Turnaround has already has been added');
			return;
		}
		this.inductionTurnarounds[i].uSelectId = item.id;
	}

	getMethod(item : {id: number, name: string}) {
		const matchingDesign = this.inductionTurnarounds.find(x => x.uSelectId === item.id);
		if (matchingDesign) {
			this.uselectSearchComponent.uSelect = undefined;
			this.toastService.showError('Induction Turnaround has already has been added');
			return;
		}
		this.newInductionTurnaround!.uSelectId = item.id;
	}

}
