import { Injectable } from "@angular/core";

export type ToastType = 'error' | 'warning' | 'success' | 'info';

export interface Toast {
	textOrTpl: string | undefined,
	options: Options,
	data?: any
}

export interface Options {
	header?: string,
	body?: string,
	type: ToastType | string,
	delay: number | 10000
}

@Injectable({ providedIn: 'root' })
export class ToastService {
	toasts: Toast[] = [];

	showError(body: string, header: string = 'Error', delay?: number, textOrTpl: string | undefined = undefined): void {
		this.show(textOrTpl, {
			header,
			body,
			delay: delay || 10000,
			type: 'error'
		});
	}

	showSuccess(body: string, header: string = 'Success', delay?: number, textOrTpl: string | undefined = undefined): void {
		this.show(textOrTpl, {
			header,
			body,
			delay: delay || 10000,
			type: 'success'
		});
	}

	showWarning(body: string, header: string = 'Warning', delay?: number, textOrTpl: string | undefined = undefined): void {
		this.show(textOrTpl, {
			header,
			body,
			delay: delay || 10000,
			type: 'warning'
		});
	}

	showInfo(body: string, header: string = 'Information', delay?: number, textOrTpl: string | undefined = undefined): void {
		this.show(textOrTpl, {
			header,
			body,
			delay: delay || 10000,
			type: 'info'
		});
	}

	showCustom(textOrTpl: string, type: string, data: any, delay?: number, header?: string) {
		this.show(textOrTpl, {
			delay: delay || 10000,
			header,
			type: type
		}, data);

	}

	remove(toast: Toast): void {
		this.toasts = this.toasts.filter(t => t !== toast);
	}

	private show(textOrTpl: string | undefined, options: Options, data?: any): void {
		this.toasts.push({
			textOrTpl,
			options,
			data
		});
	}

}
