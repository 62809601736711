<app-spinner-with-overlay [showSpinner]="loading">
	<div *ngIf="!!bundle" class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<ng-container *ngIf="showBundle">
						<app-bundle-details-customization [bundle]="bundle" (goBack)="goBackToBundleDetails()" (bundleUpdated)="handleUpdatingBundle($event)"></app-bundle-details-customization>
					</ng-container>
					<ng-container *ngIf="showBundleItems">
						<app-bundle-items [bundle]="bundle" (itemToEdit)="goToEditBundleItem($event)" (itemToRemove)="removeBundleItem($event)" (goBack)="goBackToEditBundle()" (goToBundleContent)="goToBundleContent()"></app-bundle-items>
					</ng-container>
					<ng-container *ngIf="showBundleItem && bundleItemToEdit">
						<app-bundle-item-customization [bundle]="bundle" [bundleItem]="bundleItemToEdit" [index]="bundleItemToEditIndex" (goBack)="goBackToListOfBundleItems()" (bundleItemEdited)="handleUpdatingBundleItem($event)"></app-bundle-item-customization>
					</ng-container>
					<ng-container *ngIf="showBundleContent">
						<app-bundle-content-customization [bundle]="bundle" [contentResponse]="contentResponse" [selectedBundleContent]="selectedBundleContent" (bundleContentUpdated)="handleUpdatingBundleContent($event)" (bundleCmsContentUpdated)="handleUpdatingBundleCmsContent($event)" (goBack)="goBackToListOfBundleItems()"></app-bundle-content-customization>
					</ng-container>
					<ng-container *ngIf="reviewBundle">
						<app-bundle-details [bundle]="bundle" [contentResponse]="contentResponse" [bundleCmsContent]="selectedBundleContent" [inReviewMode]="true" (beginEditingBundle)="goToEditBundle()" (saveBundle)="saveBundle($event)"></app-bundle-details>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
