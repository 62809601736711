import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PrintAndShipPricingClient as WebPrintAndShipPricingClient, PrintAndShipClient as WebPrintAndShipClient, ShippingAndTimeRequest, ShippingAndTimeResponse } from '@taradel/web-api-client';
import { CategorySearch, PrintAndShipCategory, PrintAndShipClient, PrintAndShipPricingClient } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintAndShipService {
	private readonly printAndShipClient: PrintAndShipClient;
	private readonly printAndShipPricingClient: PrintAndShipPricingClient;


	private readonly webPrintAndShipClient: WebPrintAndShipClient;
	private readonly webPrintAndShipPricingClient: WebPrintAndShipPricingClient;

  	constructor(http: HttpClient) {
		this.printAndShipClient = new PrintAndShipClient(http, environment.adminApiUrl);
		this.printAndShipPricingClient = new PrintAndShipPricingClient(http, environment.adminApiUrl);

		this.webPrintAndShipClient = new WebPrintAndShipClient(http, environment.webApiUrl);
		this.webPrintAndShipPricingClient = new WebPrintAndShipPricingClient(http, environment.webApiUrl);
	}

	async getShippingAndTime(request: ShippingAndTimeRequest): Promise<ShippingAndTimeResponse> {
		return await lastValueFrom(this.webPrintAndShipPricingClient.getShippingAndTime(request));
	}

	getCategoriesCount(query: CategorySearch): Promise<number> {
		return lastValueFrom(this.printAndShipClient.getCategoriesCount(query));
	}

	getCategories(query: CategorySearch): Promise<PrintAndShipCategory[]> {
		return lastValueFrom(this.printAndShipClient.getCategories(query));
	}

	getSiteCategories(siteId: number): Promise<PrintAndShipCategory[]> {
		return lastValueFrom(this.webPrintAndShipClient.getSiteCategories(siteId));
	}

	getCategory(categoryId: number): Promise<PrintAndShipCategory> {
		return lastValueFrom(this.printAndShipClient.getCategory(categoryId));
	}

	addCategory(category: PrintAndShipCategory): Promise<number> {
		return lastValueFrom(this.printAndShipClient.addCategory(category));
	}

	updateCategory(category: PrintAndShipCategory): Promise<number> {
		return lastValueFrom(this.printAndShipClient.updateCategory(category));
	}
}
