<app-pagefullheader subTitle="All sites setting {{ configCat }}.{{ configName }}"></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card p-3 mb-3">
		<!-- Site Config Table-->
		<div class="table-responsive" *ngIf="settings.length !== 0">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Site Id</th>
						<th>Site Name</th>
						<th>Configured Value</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let eachConfig of settings" class="settingRow">
						<td>{{ eachConfig.siteId }}</td>
						<td>{{ getSiteName(eachConfig.siteId) }}</td>
						<td>{{ eachConfig.configVal }}</td>
						<td>
							<ul>
								<li class="list-inline-item">
									<button (ngModel)="eachConfig" button class="btn btn-info btn-sm" type="button"
										data-toggle="tooltip" data-placement="top" title="Edit"
										(click)="editSetting(eachConfig)"><i class="fa fa-edit"></i></button>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</app-spinner-with-overlay>





