<div class="row">
	<div class="card col-6 p-3" *ngIf="siteId === 0">
		<form [formGroup]="createSiteForm">
			<div class="card-body">
				<h5 class="card-title">New Site</h5>
				<div class="mb-3 form-floating">
					<input type="text" formControlName="siteName" class="form-control" [maxlength]="50"
						[ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Site Name">
					<label for="siteName"> Site Name </label>
				</div>
				<!-- <div class="row">
					<div class="mb-3 form-floating col-6">
						<input type="text" formControlName="localUrl" class="form-control" [maxlength]="50"
						[ngClass]="{ 'is-invalid': submitted && f.localUrl.errors }" placeholder="Local Url">
						<label for="localUrl"> Local Url </label>
						<div *ngIf="submitted && f.localUrl.errors" class="invalid-feedback">
							<div *ngIf="f.localUrl.errors.required">Local Url is required</div>
						</div>
					</div>
					<div class="mb-3 form-floating col-6">
						<input type="text" formControlName="devUrl" class="form-control" [maxlength]="50"
						[ngClass]="{ 'is-invalid': submitted && f.devUrl.errors }" placeholder="Dev Url">
						<label for="devUrl"> Dev Url </label>
						<div *ngIf="submitted && f.devUrl.errors" class="invalid-feedback">
							<div *ngIf="f.devUrl.errors.required">Dev Url is required</div>
						</div>
					</div>
				</div> -->

				<div class="row mb-3">
					<div class="col-md-3">
						<div class="form-floating">
							<select class="form-select" id="orgId" #orgSelect (change)="selectOrg(orgSelect.value)">
								<option *ngFor="let org of organizations" [value]="org.organizationId" [selected]="org.organizationId === 0">{{org.organizationId}} - {{org.name}}</option>
							</select>
							<label>Organization</label>
						</div>
						<small>If none, leave 0</small>
					</div>
					<div class="col-md-3">
						<div class="form-check">
							<input type="checkbox" name="active" formControlName="active" class="form-check-input">
							<label for="active" class="form-check-label">Active</label>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-check">
							<input type="checkbox" name="signIn" formControlName="signIn" class="form-check-input">
							<label for="signIn" class="form-check-label">Enable Sign In</label>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-check">
							<input type="checkbox" name="signUp" formControlName="signUp" class="form-check-input">
							<label for="signUp" class="form-check-label">Enable Sign Up</label>
						</div>
					</div>
				</div>
				<fieldset>
					<legend>Contact Information</legend>
					<div class="row">
						<div class="mb-3 col-4">
							<app-email-input
								formControlName="email"
								label="Email Address"
								placeholder="Email Address"
								[required]="true"></app-email-input>
						</div>
						<div class="mb-3 col-4">
							<app-email-input
								formControlName="supportEmail"
								label="Support Email"
								placeholder="Support Email"
								[required]="true"></app-email-input>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-floating mb-3">
								<app-phone-input
									formControlName="phone"
									[required]="true"
									label="Phone Number"
									placeholder="Phone Number">
								</app-phone-input>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-floating mb-3">
								<app-phone-input
									formControlName="tollFreePhone"
									label="Toll Free Number"
									placeholder="Toll Free Number"
									[required]="true">
								</app-phone-input>
							</div>
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend>Address Information</legend>
					<div class="row">
						<div class="col-md-6">
							<div class="form-floating mb-3">
								<input
									formControlName="address1"
									class="form-control"
									id="siteAddress1"
									placeholder="Address 1">
								<label for="siteAddress1">Address 1</label>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-floating mb-3">
								<input
									formControlName="address2"
									class="form-control"
									id="siteAddress2"
									placeholder="Address 2">
								<label for="siteAddress2">Address 2</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="form-floating mb-3">
								<input
									formControlName="city"
									class="form-control"
									id="siteCity"
									placeholder="City"
									required="true">
								<label for="siteCity">City</label>
							</div>
						</div>
						<div class="col-md-4">
							<app-state-list
							(selected)="setState($event)"
							>
							</app-state-list>
						</div>
						<div class="col-md-4">
							<div class="form-floating mb-3">
								<input
									formControlName="zipCode"
									class="form-control"
									id="siteZip"
									placeholder="Zip Code">
								<label for="siteZip">Zip Code</label>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
			<div class="card-body">
				<button
					class="btn btn-primary btn-lg"
				 	(click)="createSite()"
					 [disabled]="createSiteForm.invalid">Save</button>
			</div>
		</form>
	</div>

	<ng-container *ngIf="siteId !== 0">
		<div class="col-5">
			<div class="row my-2">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">
							Add site images
						</h5>
						<div class="row my-1">
							<h3>1.</h3>
							<p>
								In order to add site images first run the following command in the <a href="https://us-east-1.console.aws.amazon.com/cloudshell" target="_blank">S3 Cloud Shell</a>
							</p>
							<button class="btn btn-info col-4" (click)="imageCopySH()">
								Copy image script
							</button>
						</div>
						<div class="row my-2">
							<h3>2.</h3>
							<p>
								Add custom images using the <a href='https://s3.console.aws.amazon.com/s3/buckets/taradel-us-assets-dev?region=us-east-1&prefix=sites/{{ siteId }}/&showversions=false' target="_blank">this link</a>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row my-2">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">
							Add site themes
						</h5>
						<div class="row">
							<ol>
								<li>Select a primary and secondary color</li>
								<li>Navigate to <a href="https://git.taradel.com/-/ide/project/taradel/asset-server/tree/development/-/Taradel.Service.AssetServer/themes/" target="_blank">Asset Server</a></li>
								<li>Make a folder titled "{{ siteId }}"</li>
								<li>Copy the contents of the Generic White Label folder to folder {{ siteId }}</li>
								<li>Add your colors into {{ siteId }}/Variables.scss</li>
								<li>Clone your branch locally to make any custom changes.</li>
								<li>Create a PR for your changes.</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">
							Add Site Products
						</h5>
						<div class="row">
							<p>
								<a [routerLink]="['/sites/',siteId,'dashboard']">Add products and modify site settings.</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

</div>
