<div class="accordion-item">
    <h2 class="accordion-header" id="industryClassificationHeading">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#industryClassificationCollapse" 
            aria-expanded="true" aria-controls="industryClassificationCollapse">
            Industry Classification
        </button>
    </h2>
    <div id="industryClassificationCollapse" class="accordion-collapse collapse show" aria-labelledby="industryClassificationHeading" 
        data-bs-parent="#industryAccordion">
        <div class="accordion-body">
            <app-select-textarea-combo [selectionService]="equifaxSelectionService" [searchable]="true" title="SIC Class" queryField="sicCodes" 
                validationRegex="^\d{2,5}$" validationMessage="Only numeric values allowed" placeholderSelect="Enter a keyword or SIC code" 
                placeholderTextarea="Enter a list of values, e.g. 1400, 1411, 1422">
            </app-select-textarea-combo>
            <app-select-textarea-combo [selectionService]="equifaxSelectionService" [searchable]="true" title="NAICS Class" queryField="naicsCodes" 
                validationRegex="^\d{2,9}$" validationMessage="Only number values allowed" placeholderSelect="Enter a keyword" 
                placeholderTextarea="Enter a list of values, e.g. 55, 3133, 921000">
            </app-select-textarea-combo>
        </div>
    </div>
</div>
<div class="accordion-item">
    <h2 class="accordion-header" id="specialClassificationHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#specialClassificationCollapse" 
            aria-expanded="false" aria-controls="specialClassificationCollapse">
            Special Classification
        </button>
    </h2>
    <div id="specialClassificationCollapse" class="accordion-collapse collapse" aria-labelledby="specialClassificationHeading" data-bs-parent="#industryAccordion">
        <div class="accordion-body">
            <app-toggle [selectionService]="equifaxSelectionService" name="Government Entity" queryField="governmentEntity"></app-toggle>
            <app-toggle [selectionService]="equifaxSelectionService" name="Federal Government Entity" queryField="federalGovernmentEntity"></app-toggle>
            <app-toggle [selectionService]="equifaxSelectionService" name="Non-profit Organization" queryField="nonProfitEntity"></app-toggle>
            <app-toggle [selectionService]="equifaxSelectionService" name="Education Institution" queryField="educationalInstitution"></app-toggle>
        </div>
    </div>
</div>