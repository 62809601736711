import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { WLProductLanguages } from '@taradel/web-api-client';
import { HttpErrorBody } from 'app/utils/http-error';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-wlproduct-languages',
  templateUrl: './wlproduct-languages.component.html',
  styleUrls: ['./wlproduct-languages.component.scss']
})
export class WLProductLanguagesComponent implements OnInit {
	@Input() siteId?: number;
	@Input() baseProductId?: number;
	@Input() languagesList?: WLProductLanguages[];

	loading = false;
	wlProductLanguage?: WLProductLanguages;
	editTranslatedNameForm: UntypedFormGroup;
	allowEdit = false;

	constructor(private sitesService: SitesService,
		private toastService: ToastService,
		formBuilder: FormBuilder) {
			this.editTranslatedNameForm = formBuilder.group({
				siteId: new FormControl<number | undefined>(undefined, Validators.required),
				baseProductId: new FormControl<number | undefined>(undefined, Validators.required),
				language: new FormControl<string | undefined>(undefined, Validators.required),
				wlProductName: new FormControl<string | undefined>(undefined, Validators.compose([
					Validators.required,
					Validators.maxLength(100)
				])),
			});
	}

	get form() {
		return this.editTranslatedNameForm.controls;
	}

	ngOnInit(): void {
		this.wlProductLanguage = this.languagesList?.find(l => l.baseProductId === this.baseProductId);
		this.initializeForm();
	}

	initializeForm() {
		this.form.siteId.setValue(this.siteId);
		this.form.baseProductId.setValue(this.baseProductId);
		this.form.language.setValue('fr');
		this.form.wlProductName.setValue(undefined);
	}

	async handleFormSubmission() {
		if (!this.wlProductLanguage) {
			await this.addTranslatedName();
		}
		else {
			await this.updateTranslatedName();
		}
	}

	cancelEdit() {
		this.initializeForm();
		this.allowEdit = false;
	}

	async addTranslatedName() {
		this.loading = true;
		if (!this.editTranslatedNameForm.valid) {
			this.loading = false;
			return;
		}
		const query = {
			siteId: this.form.siteId.value,
			baseProductId: this.form.baseProductId.value,
			language: this.form.language.value,
			wlProductName: this.form.wlProductName.value
		} as WLProductLanguages;
		try {
			this.wlProductLanguage = await this.sitesService.addWLProductTranslatedName(query);
		}
		catch (err: any) {
			console.log(err);
			const response = JSON.parse(err.response) as HttpErrorBody;
			this.toastService.showError(response.detail);
		}
		finally {
			this.initializeForm();
			this.allowEdit = false;
			this.loading = false;
		}
	}

	async updateTranslatedName() {
		this.loading = true;
		let success = true;
		if (!this.editTranslatedNameForm.valid) {
			this.loading = false;
			return;
		}
		const query = {
			siteId: this.form.siteId.value,
			baseProductId: this.form.baseProductId.value,
			language: this.form.language.value,
			wlProductName: this.form.wlProductName.value
		} as WLProductLanguages;
		try {
			this.wlProductLanguage = await this.sitesService.updateWLProductTranslatedName(query);
		}
		catch (err: any) {
			success = false;
			console.log(err);
			const response = JSON.parse(err.response) as HttpErrorBody;
			this.toastService.showError(response.detail);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.initializeForm();
			this.allowEdit = false;
		}
	}

	async removeTranslatedName() {
		this.loading = true;
		let success = true;
		try {
			await this.sitesService.removeWLProductLanguagesRecord(this.siteId!, this.wlProductLanguage!.baseProductId, this.wlProductLanguage?.language!);
		}
		catch (err: any) {
			success = false;
			console.log(err);
			const response = JSON.parse(err.response) as HttpErrorBody;
			this.toastService.showError(response.detail);
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.wlProductLanguage = undefined;
			this.initializeForm();
			this.allowEdit = false;
		}
	}
}
