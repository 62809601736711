import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SitesService } from 'services/sites.service';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ApiException, SiteConfig, SiteConfigWithDefaults } from '@taradel/admin-api-client';
import { Observable, OperatorFunction, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import saveAs from 'file-saver';
import { ToastService } from 'services/toast.service';
import { Subject, merge } from 'rxjs';
import { SortPipe } from 'components/shared/sort/sort.pipe';

@Component({
	selector: 'app-site-settings',
	templateUrl: './site-settings.component.html',
	styleUrls: ['./site-settings.component.scss'],
})
export class SiteSettingsComponent implements OnInit, OnDestroy {

	cmsSettings: SiteConfigWithDefaults[] = [];
	filteredSettings: SiteConfigWithDefaults[] = [];
	currentSetting: SiteConfigWithDefaults;
	configCats: string[] = [];
	searchConfigNames: string[] = [];
	configNames: string[] = [];
	searchConfigCat = '';
	searchConfigName = '';
	inputBoxToShow = '';
	hideEditButtons = false;
	settingToDelete = '';
	selectedConfigCat = '';
	settingToEdit = '';
	siteId!: number;
	newSettingValue = '';
	oldSettingValue = '';
	showAddNewSetting = false;
	addNewConfigModel = new SiteConfig();
	addNewConfigConfigCat = '';
	addNewConfigConfigName = '';
	addNewConfigConfigVal = '';
	addNewConfigWindowShown = false;
	loading = false;
	subscription!: Subscription;
	catFocus$ = new Subject<string>();
	catClick$ = new Subject<string>();
	nameFocus$ = new Subject<string>();
	nameClick$ = new Subject<string>();

	@ViewChild('configCat', { static: true }) configCat: NgbTypeahead | undefined;
	@ViewChild('configName', { static: true }) configName: NgbTypeahead | undefined;

	constructor(
		route: ActivatedRoute,
		private modalService: NgbModal,
		private siteService: SitesService,
		private toastService: ToastService,
		public sortPipe: SortPipe
	) {
		this.siteId = parseInt(route.snapshot.params?.siteId, 10);
		this.currentSetting = new SiteConfig();
	}

	ngOnInit() {
		this.subscription = this.siteService.currentSite.subscribe(
			async site => {
				this.siteId = site?.siteId ?? 0;
				await this.getSettings(site?.siteId as number || 0);
			});
	}

	async getSettings(siteId: number) {
		try {
			this.loading = true;
			if (siteId !== undefined) {
				this.cmsSettings = await this.siteService.getSettingsWithDefaults(siteId);
				this.filteredSettings = this.cmsSettings;

				this.cmsSettings.forEach(a => {
					if (this.configCats.findIndex(b => b.toLocaleLowerCase() === a.configCat!.toLocaleLowerCase()) === -1) {
						this.configCats.push(a.configCat!);
					}
				});
				this.filterSettings();
			}
		}
		catch {
			this.toastService.showError('There was an error getting the site settings');
		}
		finally {
			this.loading = false;
		}
	}

	async handleFileInput(event: any) {
		if (event.files.length > 0) {
			await this.importSiteSettings(event.files[0]);
		}
	}

	async exportSiteSettings(): Promise<void> {
		this.loading = true;
		try {
			const download = await this.siteService.exportSiteConfigs(this.siteId);
			if (download) {
				saveAs(download.data, download.fileName);
			}
		}
		catch (err) {
			this.toastService.showError('Could not export settings');
		}
		finally {
			this.loading = false;
		}
	}

	async importSiteSettings(file: File): Promise<void> {
		this.loading = true;
		try {
			await this.siteService.importSiteConfigs(this.siteId, {
				fileName: file.name,
				data: file
			});
			await this.getSettings(this.siteId);
			this.toastService.showSuccess(`Settings imported successfully`);
		}
		catch (err) {
			this.toastService.showError('Could not import settings');
		}
		finally {
			this.loading = false;
		}
	}

	toggleAddNewConfigWindow() {
		this.showAddNewSetting = !this.showAddNewSetting;
		this.addNewConfigWindowShown = !this.addNewConfigWindowShown;
	}

	editSetting(config: SiteConfigWithDefaults) {
		this.inputBoxToShow = config.configName || '';
		this.hideEditButtons = !this.hideEditButtons;
	}

	cancelEdit() {
		this.inputBoxToShow = '';
		this.hideEditButtons = !this.hideEditButtons;
	}

	showDeleteSettingModal(config: SiteConfigWithDefaults, content: any) {
		this.currentSetting = config;
		this.settingToDelete = config.configName!;
		this.selectedConfigCat = config.configCat!;

		this.modalOpen(content);
	}

	showEditSettingsModal($event: any, config: SiteConfigWithDefaults, content: any) {
		this.currentSetting = config;
		this.settingToEdit = config.configName!;
		this.newSettingValue = $event.target[0].value!;
		this.oldSettingValue = config.configVal!;

		if (this.newSettingValue.trim() !== '') {
			this.modalOpen(content);
		}
	}

	async submitNewConfig() {
		try {
			this.loading = true;
			this.addNewConfigModel.siteId = this.siteId;
			await this.siteService.createCMSSetting(this.addNewConfigModel);
			this.toastService.showSuccess('Setting created successfully');
			this.showAddNewSetting = !this.showAddNewSetting;
			this.resetForm();
		}
		catch {
			this.toastService.showError('There was an error adding the new config');
		}
		finally {
			this.loading = false;
		}
	}

	async editSettingAPI() {
		try {
			this.loading = true;
			this.currentSetting.configVal = this.newSettingValue;
			let tempConfig = new SiteConfig({
				configId: 0,
				siteId: this.siteService.currentSiteId,
				configCat: this.currentSetting.configCat,
				configName: this.currentSetting.configName,
				configVal: this.currentSetting.configVal
			});

			await this.siteService.updateCMSSetting(tempConfig);
			this.settingToEdit = '';
			this.newSettingValue = '';
			this.oldSettingValue = '';
			this.editSetting(new SiteConfigWithDefaults());
			await this.getSettings(this.siteId);
			this.toastService.showSuccess('Setting updated successfully');
		}
		catch (ex: any) {
			if (ApiException.isApiException(ex) && ex.status === 400) {
				this.toastService.showError(ex.message);
			}
			else {
				this.toastService.showError('There was an error updating the setting');
			}
		}
		finally {
			this.loading = false;
		}
	}

	async deleteSettingAPI() {
		try {
			this.loading = true;
			await this.siteService.deleteCMSSetting(this.siteId, this.selectedConfigCat, this.settingToDelete);
			this.settingToDelete = '';
			await this.getSettings(this.siteId);
			this.toastService.showSuccess('Setting deleted successfully');
		}
		catch {
			this.toastService.showError('There was an error deleting the setting');
		}
		finally {
			this.loading = false;
		}
	}
	filterSettings() {
		if (this.searchConfigCat.length > 0 && this.searchConfigName.length > 0) {
			this.filteredSettings = this.cmsSettings.filter(x =>
				x.configCat?.toLowerCase() === this.searchConfigCat.toLowerCase() &&
				x.configName?.toLowerCase() === this.searchConfigName.toLowerCase());
		}
		else if (this.searchConfigCat.length > 0) {
			this.filteredSettings = this.cmsSettings.filter(x =>
				x.configCat?.toLowerCase() === this.searchConfigCat.toLowerCase());
		}
		else if (this.searchConfigName.length > 0) {
			this.filteredSettings = this.cmsSettings.filter(x =>
				x.configName?.toLowerCase() === this.searchConfigName.toLowerCase());
		}
	}
	buildSearchConfigNames() {
		this.searchConfigNames = [];
		const selectedCategorySettings = this.cmsSettings.filter(x =>
			x.configCat?.toLowerCase() === this.searchConfigCat.toLowerCase());
		selectedCategorySettings.forEach(a => {
			if (this.configNames.findIndex(b => b.toLocaleLowerCase() === a.configName!.toLocaleLowerCase()) === -1) {
				this.searchConfigNames.push(a.configName!);
			}
		});
		this.sortPipe.transform(this.configNames);
	}
	buildConfigNames() {
		this.configNames = [];
		const selectedCategorySettings = this.cmsSettings.filter(x =>
			x.configCat?.toLowerCase() === this.addNewConfigModel.configCat?.toLowerCase());
		selectedCategorySettings.forEach(a => {
			if (this.configNames.findIndex(b => b.toLocaleLowerCase() === a.configName!.toLocaleLowerCase()) === -1) {
				this.configNames.push(a.configName!);
			}
		});
		this.sortPipe.transform(this.configNames);
	}

	trackRow(index: number, item: SiteConfigWithDefaults) {
		return item.configName;
	}

	async resetForm() {
		this.addNewConfigModel.configCat = '';
		this.addNewConfigModel.configName = '';
		this.searchConfigCat = '';
		this.searchConfigName = '';
		await this.getSettings(this.siteId);
	}

	modalOpen(content: any) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
			if (result === 'deleteSetting') {
				this.deleteSettingAPI();
			}
			else if (result === 'editSetting') {
				this.editSettingAPI();
			}
		}, (reason) => {
			if (reason === 'cancelEditSetting') {
				this.cancelEdit();
			}
		});
	}

	lookupConfigCat: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.catClick$.pipe(filter(() => !this.configCat?.isPopupOpen()));
		const inputFocus$ = this.catFocus$;
		return merge(debouncedText$, clicksWithClosedPopup$, inputFocus$).pipe(
			map(term => (
				term === ''
					? this.configCats
					: this.configCats.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10)
			)
		);
	};

	lookupConfigName: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.nameClick$.pipe(filter(() => !this.configName?.isPopupOpen()));
		const inputFocus$ = this.nameFocus$;
		return merge(debouncedText$, clicksWithClosedPopup$, inputFocus$).pipe(
			map(term => (
				term === ''
					? this.configNames
					: this.configNames.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10)
			)
		);
	};

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
