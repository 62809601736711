<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row pb-3">
		<fieldset [disabled]="disabled">
			<div class="row">
				<div class="col-sm-4 padding-top-15">
					<input type="search" autocomplete="thisisntafieldtoautofill" ngx-gp-autocomplete
						#placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" [(ngModel)]='addressText'
						(change)="clearSelectedAddress()" class="form-control" placeholder="Enter address" />
				</div>
				<div class="col-sm-2 padding-top-15">
					<select id="radiusType" class="form-select" (change)="radiusChanged()" [(ngModel)]='radiusType'>
						<option value="Miles">Miles</option>
						<option value="Minutes">(Drive Time) Minutes</option>
					</select>
				</div>
				<div class="col-sm-4 custom-slider text-center">
					<ngx-slider style="width:80%" [options]='radiusOptions' (userChangeEnd)='radiusChanged()'
						[(value)]='radiusVal'></ngx-slider>
				</div>
				<div class="col-sm-2 padding-top-15">
					<button type="button" class="button btn btn-default" [disabled]="selectedAddress === undefined"
						(click)="addAddress()">Add Address</button>
				</div>
			</div>
		</fieldset>
		<div class="selection-pip">
			<ul>
				<li *ngFor="let address of selectedAddresses; let i = index">
					{{address?.formatted_address}} ({{selections![i].areaSize}} {{selections![i].areaType ===
					tradeAreaType.Radius ? "miles" : "minutes"}})
					<button type="button" class="close" aria-label="Close" (click)="removeSelection(i)">
						<span aria-hidden="true">&times;</span>
					</button>
				</li>
			</ul>
		</div>
	</div>
</app-spinner-with-overlay>
