import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SitesService } from 'services/sites.service';
import { Site, SiteUrl, USelectMethod, WLProduct } from '@taradel/web-api-client';
import { Subscription } from 'rxjs';
import { ToastService } from 'services/toast.service';
import { filter, take } from 'rxjs/operators';

export interface SiteAttributes {
	hasUSelectAddOns: boolean;
	hasUSelectDesigns: boolean;
	hasPowerUps: boolean;
	hasImages: { hasNav: boolean, hasBackground: boolean, hasReceipt: boolean, hasFooter: boolean},
	hasCSS: boolean;
}

@Component({
	selector: 'app-site-dashboard',
	templateUrl: './site-dashboard.component.html',
	styleUrls: ['./site-dashboard.component.scss']
})
export class SiteDashboardComponent implements OnInit, OnDestroy {
	loading = true;
	site = new Site();
	siteUrls: SiteUrl[] = [];
	editModeOn = false;
	productGroups: Map<string, Array<WLProduct>> = new Map<string, Array<WLProduct>>();
	subscription!: Subscription;
	countryCode: 'US' | 'Canada' = 'US';
	siteAttributes: SiteAttributes = {
		hasCSS: false,
		hasImages: { hasNav: false, hasBackground: false, hasFooter: false, hasReceipt: false },
		hasPowerUps: false,
		hasUSelectAddOns: false,
		hasUSelectDesigns: false
	};

	constructor(
		private route: ActivatedRoute,
		private sitesService: SitesService,
		private toastService: ToastService
	) {
		this.route.data.pipe(
			filter((data) => !!data),
			take(1)
		).subscribe(data => this.countryCode = data.countryCode);
	}

	ngOnInit() {
		this.subscription = this.sitesService.currentSite.subscribe(async site => await this.getCurrentSite(site));
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	async getCurrentSite(site: any) {
		if (site?.siteId !== this.site?.siteId) {
			this.site = site;
			if (site !== undefined) {
				await this.getSiteProducts();
				this.getSiteParams();
			}
		}
	}

	async getSiteProducts() {
		try {
			this.loading = true;
			this.productGroups = new Map<string, WLProduct[]>();
			let tempList = new Array<WLProduct>();
			let uSelectMethods = new Array<USelectMethod>();
			await this.sitesService.getAllUSelect().then(m => { uSelectMethods = m; });
			await this.sitesService.getSiteProducts(this.site.siteId).then(products => { tempList = products; });

			for (let method of uSelectMethods) {
				let products = tempList.filter(p => p.baseProduct?.uSelectConfigurations?.some(u => u.uSelectId === method.uSelectId));
				if (products && products.length > 0) {
					this.productGroups.set(method.name ?? method.uSelectId.toString(), products);
				}
			}
		}
		catch {
			this.toastService.showError('There was an error loading site products');
		}
		finally {
			this.loading = false;
		}
	}

	async updateSite() {
		try {
			this.loading = true;
			await this.sitesService.updateSite(this.site);
			this.toastService.showSuccess('Site details updated successfully');
		}
		catch {
			this.toastService.showError('There was an error updating site details');
		}
		finally {
			this.loading = false;
		}


	}

	async getSiteParams() {
		(await this.sitesService.getSiteUSelectAddOns(this.site.siteId)).length && (this.siteAttributes.hasUSelectAddOns = true);
		if ((await this.sitesService.getSiteDesignOptions(this.site.siteId)).find(x => x.siteId !== 0)) {
			this.siteAttributes.hasUSelectDesigns = true;
		}
		if ((await this.sitesService.getSitePowerUps(this.site.siteId)).find(x => x.siteId !== 0)) {
			this.siteAttributes.hasPowerUps = true;
		}
		this.siteUrls = (await this.sitesService.getSite(this.sitesService.currentSiteId)).urls!;
		let activeSiteUrl = this.siteUrls.find(e => e.active && !e.url?.endsWith('.local'));
		if (activeSiteUrl) {
			this.checkSiteHasImages(activeSiteUrl);
		}

		let css: HTMLLinkElement = document.createElement('link');
		css.onload = () => {
			this.siteAttributes.hasCSS = true;
		};
		css.rel = 'preload';
		css.as = 'style';
		css.href = 'https://' + activeSiteUrl?.url + '/themes/theme.css';
		document.head.appendChild(css);
	}


	checkSiteHasImages(activeSiteUrl: SiteUrl) {
		let navImage = new Image();
		let backgroundImage = new Image();
		let receiptImage = new Image();
		let footerImage = new Image();
		navImage.onload = () => {
			this.siteAttributes.hasImages.hasNav = true;
		};
		backgroundImage.onload = () => {
			this.siteAttributes.hasImages.hasBackground = true;
		};
		receiptImage.onload = () => {
			this.siteAttributes.hasImages.hasReceipt = true;
		};
		footerImage.onload = () => {
			this.siteAttributes.hasImages.hasFooter = true;
		};

		navImage.src = 'https://' + activeSiteUrl?.url + '/assets/sites/nav-logo.png';
		backgroundImage.src = 'https://' + activeSiteUrl?.url + '/assets/sites/homepage-background.jpg';
		receiptImage.src = 'https://' + activeSiteUrl?.url + '/assets/sites/receipt-logo.jpg';
		footerImage.src = 'https://' + activeSiteUrl?.url + '/assets/sites/large-logo.png';
	}

	stateSelected(value: string) {
		this.site.state = value;
	}

}
