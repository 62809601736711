import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CreatePORequest, Printer, PrinterPO, RecommendedPrinter, UpdatePORequest, OrderStatus, OrderMailTracking } from '@taradel/admin-api-client';
import { OrderlayoutService } from 'services/orderlayout.service';
import { PurchaseordersService } from 'services/purchaseorders.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintersService } from 'services/printers.service';
import { formatDate } from '@angular/common';
import { saveAs } from 'file-saver';
import { ToastService } from 'services/toast.service';
import { AuthenticationService } from 'services/authentication.service';

export type PrinterType = 'taradel' | 'nonTaradel';

@Component({
	selector: 'app-purchase-order',
	templateUrl: './purchase-order.component.html',
	styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {
	poForm: UntypedFormGroup;
	editPoForm: UntypedFormGroup;
	submitted = false;
	jobCost: number | undefined;
	createPoMsg: string | undefined;
	loading: boolean = false;
	resendMsg: string | undefined = undefined;
	editPo: boolean = false;
	overrideAllowed = false;
	printerType: PrinterType = 'taradel';
	updatePoMsg: string | undefined;
	purchaseOrderStatuses: OrderStatus[] = [];
	imbSerialStatuses: OrderStatus[] = [];
	orderMailTracking?: OrderMailTracking;
	transmitPOToVendor = false;

	get poResendCooldown(): boolean {
		if (!this.orderLayoutService.purchaseOrder) {
			return false;
		}

		if (this.orderLayoutService.purchaseOrderStatus?.vendorOrderRef ?? '' !== '') {
			return false;
		}

		const timeout = 1000 * 60 * 10; // 10 minutes
		const now = new Date();
		const diff = now.getTime() - this.orderLayoutService.purchaseOrder.createdDate.getTime();
		return diff < timeout;
	}

	@Input() printers: Printer[] | undefined = [];

	constructor(private route: ActivatedRoute,
		private authService: AuthenticationService,
		public modalService: NgbModal,
		public orderLayoutService: OrderlayoutService,
		private poService: PurchaseordersService,
		private printerService: PrintersService,
		formBuilder: UntypedFormBuilder,
		private toastService: ToastService) {
		this.poForm = formBuilder.group({
			overrideAffiliateReason: [''],
			vendor: [undefined, Validators.required],
			vendorRate: ['', Validators.required],
			deliveryDate: ['', Validators.compose([
				Validators.required,
				dateValidator()
			])],
			comments: ['', Validators.compose([])],
			allowEarlierDate: [{ value: false }]
		});

		this.editPoForm = formBuilder.group({
			overrideAffiliateReason: [''],
			vendor: [undefined, Validators.required],
			vendorRate: [{ value: '', disabled: true }, Validators.required],
			deliveryDate: [{ value: '', disabled: true }, Validators.compose([
				Validators.required,
				dateValidator()
			])],
			comments: ['', Validators.compose([])],
			allowEarlierDate: [{ value: false }],
			vendorReference: ['']
		});

		this.poForm.get('vendorRate')?.valueChanges.subscribe(x => {
			const newRate = parseFloat(x);
			this.jobCost = this.getJobCost(newRate);
		});

		this.editPoForm.get('vendorRate')?.valueChanges.subscribe(x => {
			const newRate = parseFloat(x);
			this.jobCost = this.getJobCost(newRate);
		});
	}


	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			// clear any poForm values on route change
			this.submitted = false;
			this.poForm.reset();
			this.poForm.controls['allowEarlierDate'].setValue(false);
		});

		this.orderLayoutService.eligiblePrinter.subscribe(response => {
			this.setVendorFromEligiblePrinters();
		});
		this.orderLayoutService.purchaseOrderLoaded.subscribe(response => {
			this.loadEditPoForm();
			this.purchaseOrderStatuses = this.orderLayoutService.purchaseOrderStatus?.statuses?.filter(s => s.statusId !== 'IMBSerialMax' && s.statusId !== 'IMBSerialMin') ?? [];
			this.orderMailTracking  = this.orderLayoutService.purchaseOrderStatus?.mailTracking;
			this.imbSerialStatuses = this.orderLayoutService.purchaseOrderStatus?.statuses?.filter(s => s.statusId === 'IMBSerialMin' || s.statusId === 'IMBSerialMax') ?? [];
		});
	}

	revalidateDate() {
		this.poForm.controls['deliveryDate'].updateValueAndValidity();
		if (this.poForm.controls['allowEarlierDate'].enabled) {
			this.editPoForm.controls['deliveryDate'].updateValueAndValidity();
		}
	}

	setVendorFromEligiblePrinters() {
		const suggestedPrinter = this.orderLayoutService.eligiblePrinterResponse?.eligiblePrinters?.find(x => x.suggestedPrinter);
		if (suggestedPrinter !== undefined) {
			if (suggestedPrinter.printerId === 0) {
				this.printerType = 'taradel';
				this.poForm.controls['vendor'].enable();
				this.showTaradelPrinters();
			}
			else {
				this.printerType = 'nonTaradel';
				this.poForm.controls['vendor'].disable();
				this.showNonTaradelPrinters();
			}
			this.poForm.controls['vendor'].setValue(suggestedPrinter.printerId);
		}
	}

	async loadEditPoForm() {
		if (!!this.orderLayoutService.purchaseOrder) {
			const suggestedPrinter = this.orderLayoutService.eligiblePrinterResponse?.eligiblePrinters?.find(x => x.suggestedPrinter);
			if (suggestedPrinter !== undefined) {
				const rrPrinterSelection = await this.printerService.getPrinterSelection(this.orderLayoutService.selectedOrderItemAsCartProduct?.drops?.roundRobinSelectionId!);
				if (rrPrinterSelection !== undefined && rrPrinterSelection.printerId !== 0) {
					this.printerType = 'nonTaradel';
					this.editPoForm.controls['vendor'].disable();
					this.showNonTaradelPrinters();
				}
				else {
					this.printerType = 'taradel';
					this.editPoForm.controls['vendor'].enable();
					this.showTaradelPrinters();
				}
			}
			else {
				this.printerType = 'taradel';
				this.editPoForm.controls['vendor'].enable();
				this.showTaradelPrinters();
			}

			this.editPoForm.controls['vendor'].setValue(this.orderLayoutService.purchaseOrder?.printerId);
			this.editPoForm.controls['vendorRate'].disable();
			this.editPoForm.controls['vendorRate'].setValue(this.orderLayoutService.purchaseOrder?.printerRatePerM);
			this.editPoForm.controls['deliveryDate'].disable();
			this.editPoForm.controls['deliveryDate'].setValue(formatDate(this.orderLayoutService.purchaseOrder!.deliverDate, 'yyyy-MM-dd', 'en'));
			this.editPoForm.controls['allowEarlierDate'].disable();
			this.editPoForm.controls['vendorReference'].setValue(this.orderLayoutService.purchaseOrderStatus?.vendorOrderRef);
		}
	}

	//Apply the selected printer from the printer recommendation list to the Create PO form
	//Address the change to both the poForm and editPoForm controls
	setPrinter(vendor: RecommendedPrinter) {
		this.poForm.controls['vendor'].setValue(vendor.printerId);
		this.editPoForm.controls['vendor'].setValue(vendor.printerId);

		// adjust out mail prep when necessary
		let qty = this.orderLayoutService.selectedOrderItem?.quantity ?? 0;
		let vendorRate = vendor.printCost / qty;
		let jobRate = vendorRate * 1000;
		this.poForm.controls['vendorRate'].setValue(jobRate.toFixed(2));
		this.editPoForm.controls['vendorRate'].setValue(jobRate.toFixed(2));
	}

	overrideAffiliate() {
		this.overrideAllowed = true;
		this.poForm.controls['vendor'].enable();
		this.editPoForm.controls['vendor'].enable();
		this.poForm.controls['overrideAffiliateReason'].addValidators(Validators.required);
		this.editPoForm.controls['overrideAffiliateReason'].addValidators(Validators.required);
		switch (this.printerType) {
			case 'taradel':
				this.showTaradelPrinters();
				break;

			case 'nonTaradel':
				this.showNonTaradelPrinters();
				break;
		}
		switch (this.printerType) {
			case 'taradel':
				this.showTaradelPrinters();
				break;

			case 'nonTaradel':
				this.showNonTaradelPrinters();
				break;
		}
	}

	async cancelOverride() {
		this.overrideAllowed = false;
		this.poForm.controls['overrideAffiliateReason'].removeValidators(Validators.required);
		this.editPoForm.controls['overrideAffiliateReason'].removeValidators(Validators.required);
		if (!!this.orderLayoutService.purchaseOrder) {
			await this.loadEditPoForm();
		}
		else {
			this.setVendorFromEligiblePrinters();
		}
	}

	printerTypeSelected(printerType: PrinterType) {
		this.printerType = printerType;
		switch (printerType) {
			case 'taradel':
				this.showTaradelPrinters();
				break;
			case 'nonTaradel':
				this.showNonTaradelPrinters();
				break;
		}
	}

	showTaradelPrinters() {
		this.printers = this.orderLayoutService.printers;
	}

	showNonTaradelPrinters() {
		this.printers = this.orderLayoutService.affiliatePrinters;
	}

	getFirstDeliveryDate() {
		const drops = this.orderLayoutService.selectedOrderItemAsCartProduct?.drops;

		if (drops !== undefined && drops.orderDrops !== undefined && drops.orderDrops.length > 0) {
			for (let drop of drops.orderDrops) {
				if (drop.date !== undefined) {
					return drop.date;
				}
			}
		}
		return null;
	}

	//Recalculate the job cost based on the new Rate value
	getJobCost(rate: number) {
		const qty = this.orderLayoutService.selectedOrderItem?.quantity ?? 0;
		return (qty * (rate / 1000));
	}

	getDate(input: string): Date {
		const [year, month, day] = input.split('-');
		return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
	}

	async cancelPo(): Promise<void> {
		this.loading = true;
		this.modalService.dismissAll();
		await this.orderLayoutService.cancelPurchaseOrder();
		this.loading = false;
	}

	async updateVendorReference(): Promise<void> {
		this.loading = true;
		await this.orderLayoutService.updateVendorReference(this.editPoForm.get('vendorReference')?.value);
		this.loading = false;
	}

	setModifyPo(): void {
		this.editPo = !this.editPo;
		this.editPoForm.controls['vendor'].enable();
		this.editPoForm.controls['vendorRate'].enable();
		this.editPoForm.controls['deliveryDate'].enable();
		this.editPoForm.controls['allowEarlierDate'].enable();
		this.editPoForm.controls['allowEarlierDate'].setValue(false);
	}


	cancelPoUpdate(): void {
		this.cancelOverride();
		this.editPo = !this.editPo;
		this.editPoForm.controls['vendor'].disable();
		this.editPoForm.controls['vendorRate'].disable();
		this.editPoForm.controls['deliveryDate'].disable();
		this.editPoForm.controls['allowEarlierDate'].disable();
		this.editPoForm.controls['overrideAffiliateReason'].setValue('');
		this.transmitPOToVendor = false;
	}

	async updatePo(): Promise<void> {
		this.loading = true;
		try {
			const request = new UpdatePORequest({
				purchaseOrderId: this.orderLayoutService.purchaseOrder?.purchaseOrderId!,
				printerId: this.editPoForm.get('vendor')?.value,
				deliverDate: this.getDate(this.editPoForm.get('deliveryDate')?.value),
				printerRatePerM: this.editPoForm.get('vendorRate')?.value,
				overrideAffiliateReason: this.editPoForm.controls['overrideAffiliateReason'].value,
				transmitPO: this.transmitPOToVendor
			});
			const updatedPo = await this.poService.updatePurchaseOrder(request);

			if (!updatedPo) {
				this.updatePoMsg = 'There was a problem updating this purchase order.';
			}
			else {
				this.orderLayoutService.setPurchaseOrder();
				this.cancelPoUpdate();
				this.toastService.showSuccess('Purchase order updated successfully');
			}
		}
		catch (error) {
			console.log(error);
			this.updatePoMsg = 'There was a problem updating this purchase order.';
		}
		finally {
			this.modalService.dismissAll();
			this.loading = false;
		}
	}

	async createPo(): Promise<void> {
		this.submitted = true;
		if (this.poForm.invalid) {
			return;
		}
		this.loading = true;
		const newPo = new CreatePORequest({
			orderId: this.orderLayoutService.selectedOrder?.orderId!,
			isTaradelPrinter: this.printerType === 'taradel',
			overrideAffiliateReason: this.poForm.controls['overrideAffiliateReason'].value,
			printerPO: new PrinterPO({
				purchaseOrderId: 0,
				printerId: this.poForm.get('vendor')?.value,
				createdDate: new Date(),
				orderItemId: this.orderLayoutService.selectedOrderItem?.orderItemId!,
				shipMethodId: 0,
				deliverDate: this.getDate(this.poForm.get('deliveryDate')?.value),
				printerRatePerM: this.poForm.get('vendorRate')?.value,
				printerTotal: this.jobCost!
			})
		});

		try {
			const purchaseOrderId = await this.poService.createPurchaseOrder(newPo);
			if (purchaseOrderId > 0) {
				await this.orderLayoutService.setPurchaseOrder();
				this.createPoMsg = undefined;
				this.toastService.showSuccess('Purchase order was created successfully');
			}
			else {
				// looks like there was a problem :(
				this.createPoMsg = 'No purchase order id was returned from your create PO call. You can try again, or reload to see if there was some other problem.';
			}
		}
		catch (error: any) {
			this.createPoMsg = JSON.parse(error.response);
		}
		finally {
			this.submitted = false;
			this.loading = false;
		}
	}

	async resendPurchaseOrder(): Promise<void> {
		this.loading = true;

		try {
			const sent = await this.poService.transmitPurchaseOrderToVendor(this.orderLayoutService.purchaseOrder?.purchaseOrderId!);

			if (!sent) {
				this.resendMsg = "There was a problem resending the documents to the vendor.";
			}
			else {
				this.modalService.dismissAll();
				this.toastService.showSuccess('Purchase order was resent successfully');
			}
		}
		catch (error) {
			console.log(error);
			this.resendMsg = "There was an error resending the documents. Please check with Tech Support.";
		}

		this.loading = false;
	}

	async downloadPo(): Promise<void> {
		this.loading = true;
		const purchaseOrderId = this.orderLayoutService.purchaseOrder?.purchaseOrderId!;
		const poFileName = `TAR${purchaseOrderId}.pdf`;
		try {
			const download = await this.poService.downloadPurchaseOrder(purchaseOrderId);
			if (download) {
				saveAs(download.data, poFileName);
			}
		}
		catch (err) {
			this.toastService.showError(`Could not download purchase order ${poFileName}`);
		}
		finally {
			this.loading = false;
		}
	}
}

export function dateValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const value = control.value;

		if (!value) {
			return null;
		}

		let convertToDate = new Date(value).getTime();
		let dateNow = Date.now();
		let val = convertToDate <= dateNow && !control.parent?.get('allowEarlierDate')!.value ? { invalidDate: 'false' } : null;
		return val;
	};
}
