<app-product-info [productId]="productId"></app-product-info>

<app-spinner-with-overlay [showSpinner]="loading">
	<div *ngIf="pcogsLayout.length">
		<div class="card p-3 mb-3">
			<div class="row d-flex justify-content-between align-items-center">
				<div class="col">
					<div *ngIf="filterValues.length > 1">
						<strong class="mb-1">Filter By Printer</strong>
						<form [formGroup]="pcogsSearchForm">
							<div class="row align-items-center">
								<div class="col-md-3">
									<div class="form-floating">
										<select class="form-select" formControlName="printerId" (change)="searchPCOGS()">
											<option [ngValue]="0" selected>All Assigned Printers</option>
											<option *ngFor="let item of filterValues" [ngValue]="item.printerId">
												{{ item.printerName }} ({{ item.printerId }})
											</option>
										</select>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="col-auto">
					<button *ngIf="!enableCopyPCOGs; else copyPCOGsBtnTemplate" type="button" class="btn btn-warning" (click)="setEnableCopyPCOGs()">
						Copy PCOGs
					</button>
					<ng-template #copyPCOGsBtnTemplate>
						<button type="button" [disabled]="copyPCOGsQuery.length <= 0" class="btn btn-success mx-1" (click)="openCopyPCOGsModal(copyPCOGsModal)">
							Choose Printer to receive PCOGs
						</button>
						<button type="button" class="btn btn-danger mx-1" (click)="cancelCopyPCOGs()">
							Cancel Copying
						</button>
					</ng-template>
				</div>
			</div>
		</div>
		<div *ngFor="let item of pcogsLayout; let i = index" class="card p-3 mb-3">
			<div class="row d-flex align-items-center mb-1">
				<div class="col-auto">
					<strong>{{ item.printerName }} ({{ item.printerId }})</strong>
				</div>
				<div class="col">
					<button type="button" class="btn btn-danger" (click)="removePrinterPCOGsFromProduct(item.printerId)">
						Remove this printer's PCOGS
					</button>
				</div>
			</div>
			<div class="table-responsive">
				<table class="table table-hover table-responsive">
					<thead>
						<tr>
							<th *ngIf="enableCopyPCOGs"></th>
							<th width="8%">Min <br />Quantity</th>
							<th width="8%">Max <br /> Quantity</th>
							<th width="8%">Price Per <br /> Piece</th>
							<th width="8%">Prep Per <br /> Piece</th>
							<th width="8%">Postage Per <br /> Piece</th>
							<th width="8%">Range Min <br /> Charge</th>
							<th width="8%">Standard <br /> Manufacturing <br /> Time</th>
							<th width="8%">Fast <br /> Manufacturing <br /> Time</th>
							<th width="8%">Rush <br /> Manufacturing <br /> Time</th>
							<th width="8%">Percent <br />Of <br />Budget</th>
							<th width="12%">Options</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let pCOG of item.pcogs; let i = index">
							<td *ngIf="enableCopyPCOGs" data-title="Select PCOGs To Copy">
								<div class="form-check">
									<input class="form-check-input" type="checkbox" value="pCOG" (change)="updateCopyPCOGsQuery(pCOG)">
								</div>
							</td>
							<td data-title="Minimum Quantity">
								<div class="form-floating">
									<input type="number" id="minQty" min="1" max="999999999" class="form-control" disabled
										[(ngModel)]="pCOG.minQty">
									<label>Minimum Quantity</label>
								</div>
							</td>
							<td data-title="Maximum Quantity">
								<div class="form-floating">
									<input type="number" id="maxQty" min="1" max="999999999" class="form-control" disabled
										[(ngModel)]="pCOG.maxQty">
									<label>Maximum Quantity</label>
								</div>
							</td>
							<td data-title="Price Per Piece">
								<div class="form-floating">
									<input type="number" id="pricePerPiece" min="1" max="999999999" class="form-control"
										[(ngModel)]="pCOG.pricePerPiece">
									<label>Price Per Piece</label>
								</div>
							</td>
							<td data-title="Prep Per Piece">
								<div class="form-floating">
									<input type="number" id="prepPerPiece" min="1" max="999999999" class="form-control"
										[(ngModel)]="pCOG.prepPerPiece">
									<label>Prep Per Piece</label>
								</div>
							</td>
							<td data-title="Postage Per Piece">
								<div class="form-floating">
									<input type="number" id="postagePerPiece" min="1" max="999999999" class="form-control"
										[(ngModel)]="pCOG.postagePerPiece">
									<label>Postage Per Piece</label>
								</div>
							</td>
							<td data-title="Minimum Charge Range">
								<div class="form-floating">
									<input type="number" id="rangeMinCharge" min="0" max="999999999" class="form-control"
										[(ngModel)]="pCOG.rangeMinCharge">
									<label>Range Min. Charge</label>
								</div>
							</td>
							<td data-title="Standard Manufacturing Time">
								<div class="form-floating">
									<input type="number" id="standardManfufacturingTime" min="0" max="999999999" class="form-control"
										[(ngModel)]="pCOG.standardManfufacturingTime">
									<label>Standard Mfg. Time</label>
								</div>
							</td>
							<td data-title="Fast Manufacturing Time">
								<div class="form-floating">
									<input type="number" id="fastManufacturingTime" min="0" max="999999999" class="form-control"
										[(ngModel)]="pCOG.fastManufacturingTime">
									<label>Fast Mfg. Time</label>
								</div>
							</td>
							<td data-title="Rush Manufacturing Time">
								<div class="form-floating">
									<input type="number" id="rushManufacturingTime" min="0" max="999999999" class="form-control"
										[(ngModel)]="pCOG.rushManufacturingTime">
									<label>Rush Mfg. Time</label>
								</div>
							</td>
							<td data-title="Percent Of Budget">
								<div class="input-group">
									<div class="form-floating">
										<input type="number" id="percentOfBudget" min="0" max="100" class="form-control" (change)="setPercentOfBudget(pCOG, $any($event.target).value)"
											[ngModel]="displayPercentOfBudget(pCOG.percentOfBudget)">
										<label>Percent Of Budget</label>
									</div>
									<div class="input-group-text">%</div>
								</div>
							</td>
							<td data-title="Options">
								<div *ngIf="productOptsByCat.length > 0 && editOptionsIndex.index === i && editOptionsIndex.printerId === pCOG.printerId; else initialOptionsTemplate">
									<app-pcog-options [pcogOptions]="pCOG.options!" [prodOptCats]="productOptsByCat" (optionSelected)="handleOptionSelected($event, pCOG)"></app-pcog-options>
								</div>
								<ng-template #initialOptionsTemplate>
									<div *ngIf="pCOG.options !== ''; else noOptionsSelected">
										<div *ngFor="let opt of pCOG.optionsList">
											<strong>{{ getOptCatName(opt.optCatId) }}: </strong>{{ getOptionName(opt.optionId) }}
										</div>
									</div>
								</ng-template>
								<button *ngIf="editOptionsIndex.index === i && editOptionsIndex.printerId === pCOG.printerId; else offerEditOptions" class="btn btn-sm btn-warning mt-2"
									(click)="cancelEditOptions()">
									Cancel Edit
								</button>
								<ng-template #offerEditOptions>
									<button  class="btn btn-sm btn-success mt-2" (click)="enableEditOptions(pCOG, i)"
										[disabled]="!!editOptionsIndex.index && (editOptionsIndex.printerId !== pCOG.printerId || (editOptionsIndex.printerId === pCOG.printerId && editOptionsIndex.index !== i))">
										Add/Edit Options
									</button>
								</ng-template>
							</td>
							<td>
								<ul class="list-inline">
									<li class="list-inline-item">
										<button class="btn btn-primary btn-sm" (click)="updatePCOG(pCOG)" data-toggle="tooltip"
											data-placement="top" Title="Save Changes">
											<i class="fas fa-save"></i>
										</button>
									</li>
									<li class="list-inline-item">
										<button class="btn btn-danger btn-sm" (click)="removePCOG(pCOG)" data-toggle="tooltip"
											data-placement="top" Title="Delete">
											<i class="fa fa-trash"></i>
										</button>
									</li>
								</ul>
							</td>
						</tr>

						<tr>
							<td *ngIf="enableCopyPCOGs"></td>
							<td data-title="Minimum Quantity">
								<div class="form-floating">
									<input type="number" id="minQty" min="1" max="999999999" class="form-control"
										[(ngModel)]="item.newPCOG.minQty" placeholder="Minimum Quantity">
									<label>Minimum Quantity</label>
								</div>
							</td>
							<td data-title="Maximum Quantity">
								<div class="form-floating">
									<input type="number" id="maxQty" min="1" max="999999999" class="form-control"
										[(ngModel)]="item.newPCOG.maxQty" placeholder="Maximum Quantity">
									<label>Maximum Quantity</label>
								</div>
							</td>
							<td data-title="Price Per Piece">
								<div class="form-floating">
									<input type="number" id="pricePerPiece" min="1" max="999999999" class="form-control"
										[(ngModel)]="item.newPCOG.pricePerPiece" placeholder="Price Per Piece">
									<label>Price Per Piece</label>
								</div>
							</td>
							<td data-title="Prep Per Piece">
								<div class="form-floating">
									<input type="number" id="prepPerPiece" min="1" max="999999999" class="form-control"
										[(ngModel)]="item.newPCOG.prepPerPiece" placeholder="Prep Per Piece">
									<label>Prep Per Piece</label>
								</div>
							</td>
							<td data-title="Postage Per Piece">
								<div class="form-floating">
									<input type="number" id="postagePerPiece" min="1" max="999999999" class="form-control"
										[(ngModel)]="item.newPCOG.postagePerPiece" placeholder="Prep Per Piece">
									<label>Postage Per Piece</label>
								</div>
							</td>
							<td data-title="Minimum Charge Range">
								<div class="form-floating">
									<input type="number" id="rangeMinCharge" min="1" max="999999999" class="form-control"
										[(ngModel)]="item.newPCOG.rangeMinCharge" placeholder="Range Min. Charge">
									<label>Range Min. Charge</label>
								</div>
							</td>
							<td data-title="Standard Manufacturing Time">
								<div class="form-floating">
									<input type="number" id="standardManfufacturingTime" min="0" max="999999999" class="form-control"
										[(ngModel)]="item.newPCOG.standardManfufacturingTime" placeholder="Standard Mfg. Time">
									<label>Standard Mfg. Time</label>
								</div>
							</td>
							<td data-title="Fast Manufacturing Time">
								<div class="form-floating">
									<input type="number" id="fastManufacturingTime" min="0" max="999999999" class="form-control"
										[(ngModel)]="item.newPCOG.fastManufacturingTime" placeholder="Fast Mfg. Time">
									<label>Fast Mfg. Time</label>
								</div>
							</td>
							<td data-title="Rush Manufacturing Time">
								<div class="form-floating">
									<input type="number" id="rushManufacturingTime" min="0" max="999999999" class="form-control"
										[(ngModel)]="item.newPCOG.rushManufacturingTime" placeholder="Rush Mfg. Time">
									<label>Rush Mfg. Time</label>
								</div>
							</td>
							<td data-title="Percent Of Budget">
								<div class="input-group">
									<div class="form-floating">
										<input type="number" id="percentOfBudget" min="0" max="100" class="form-control"
											[(ngModel)]="item.newPCOG.percentOfBudget" placeholder="Percent Of Budget">
										<label>Percent Of Budget</label>
									</div>
									<div class="input-group-text">%</div>
								</div>
							</td>
							<td data-title="Options">
								<div *ngIf="productOptsByCat.length > 0 && editOptionsIndex.index === -1 && editOptionsIndex.printerId === item.newPCOG.printerId; else noOptionsSelected">
									<app-pcog-options [pcogOptions]="item.newPCOG.options!" [prodOptCats]="productOptsByCat" (optionSelected)="handleOptionSelected($event, item.newPCOG)"></app-pcog-options>
								</div>
								<ng-template #noOptsNewPCOG>
								</ng-template>
								<div *ngIf="item.newPCOG.options === ''">
									<button *ngIf="!!editOptionsIndex.index && editOptionsIndex.index === -1 && editOptionsIndex.printerId === item.newPCOG.printerId; else offerEditOptions" class="btn btn-sm btn-warning"
										(click)="cancelEditOptions()">
										Cancel Edit
									</button>
									<ng-template #offerEditOptions>
										<button  class="btn btn-sm btn-success" (click)="enableEditOptions(item.newPCOG, -1)">
											Add Options
										</button>
									</ng-template>
								</div>
							</td>
							<td>
								<ul class="list-inline">
									<li class="list-inline-item">
										<button class="btn btn-success" [disabled]="item.newPCOG.minQty <= 0 || item.newPCOG.maxQty <= 0 || item.newPCOG.pricePerPiece < 0 || item.newPCOG.prepPerPiece < 0" (click)="addNewPCOG(item.newPCOG)">Add</button>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div *ngIf="!!newPrinterPCOG" class="card p-3 mb-3">
		<strong class="mb-1">Add New Printer/PCOGs to Product</strong>
			<div class="row mt-3">
				<div class="col-auto form-floating">
					<select type="select" class="form-select" name="newPrinterId" [(ngModel)]="newPrinterPCOG!.printerId">
						<option [ngValue]="0" default>Select a printer to set PCOGs</option>
						<option *ngFor="let printer of printersToAddToProduct" [ngValue]="printer.printerId">
							{{ printer.companyName }} ({{ printer.printerId }})
						</option>
					</select>
				</div>
				<div *ngIf="!!newPrinterPCOG && newPrinterPCOG.printerId > 0" class="col-auto">
					<button type="button" class="btn btn-danger" (click)="initializeNewPrinterPCOG()">
						Cancel Add New Printer/PCOG
					</button>
				</div>
			</div>
			<div *ngIf="newPrinterPCOG!.printerId > 0" class="table-responsive">
				<table class="table table-hover table-responsive">
					<thead>
						<tr>
							<th width="8%">Min <br />Quantity</th>
							<th width="8%">Max <br /> Quantity</th>
							<th width="8%">Price Per <br /> Piece</th>
							<th width="8%">Prep Per <br /> Piece</th>
							<th width="8%">Postage Per <br /> Piece</th>
							<th width="8%">Range Min <br /> Charge</th>
							<th width="8%">Standard <br /> Manufacturing <br /> Time</th>
							<th width="8%">Fast <br /> Manufacturing <br /> Time</th>
							<th width="8%">Rush <br /> Manufacturing <br /> Time</th>
							<th width="8%">Percent <br />Of <br />Budget</th>
							<th width="12%">Options</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td data-title="Minimum Quantity">
								<div class="form-floating">
									<input type="number" id="minQty" min="1" max="999999999" class="form-control"
										name="newMinQty" [(ngModel)]="newPrinterPCOG!.minQty" placeholder="Minimum Quantity">
									<label>Minimum Quantity</label>
								</div>
							</td>
							<td data-title="Maximum Quantity">
								<div class="form-floating">
									<input type="number" id="maxQty" min="1" max="999999999" class="form-control"
										name="newMaxQty" [(ngModel)]="newPrinterPCOG!.maxQty" placeholder="Maximum Quantity">
									<label>Maximum Quantity</label>
								</div>
							</td>
							<td data-title="Price Per Piece">
								<div class="form-floating">
									<input type="number" id="pricePerPiece" min="1" max="999999999" class="form-control"
										name="newPricePerPiece" [(ngModel)]="newPrinterPCOG!.pricePerPiece" placeholder="Price Per Piece">
									<label>Price Per Piece</label>
								</div>
							</td>
							<td data-title="Prep Per Piece">
								<div class="form-floating">
									<input type="number" id="prepPerPiece" min="1" max="999999999" class="form-control"
										name="newPrepPerPiece" [(ngModel)]="newPrinterPCOG!.prepPerPiece" placeholder="Prep Per Piece">
									<label>Prep Per Piece</label>
								</div>
							</td>
							<td data-title="Postage Per Piece">
								<div class="form-floating">
									<input type="number" id="postagePerPiece" min="1" max="999999999" class="form-control"
										name="newPostagePerPiece" [(ngModel)]="newPrinterPCOG!.postagePerPiece" placeholder="Postage Per Piece">
									<label>Postage Per Piece</label>
								</div>
							</td>
							<td data-title="Minimum Charge Range">
								<div class="form-floating">
									<input type="number" id="rangeMinCharge" min="1" max="999999999" class="form-control"
										name="rangeMinCharge" [(ngModel)]="newPrinterPCOG!.rangeMinCharge" placeholder="Range Min. Charge">
									<label>Range Min. Charge</label>
								</div>
							</td>
							<td data-title="Standard Manufacturing Time">
								<div class="form-floating">
									<input type="number" id="standardManufacturingTime" min="0" max="999999999" class="form-control"
										name="newStandardManufacturingTime" [(ngModel)]="newPrinterPCOG!.standardManfufacturingTime" placeholder="Standard Mfg. Time">
									<label>Standard Mfg. Time</label>
								</div>
							</td>
							<td data-title="Fast Manufacturing Time">
								<div class="form-floating">
									<input type="number" id="fastManufacturingTime" min="0" max="999999999" class="form-control"
										name="fastManufacturingTime" [(ngModel)]="newPrinterPCOG!.fastManufacturingTime" placeholder="Fast Mfg. Time">
									<label>Fast Mfg. Time</label>
								</div>
							</td>
							<td data-title="Rush Manufacturing Time">
								<div class="form-floating">
									<input type="number" id="rushManufacturingTime" min="0" max="999999999" class="form-control"
										name="rushManufacturingTime" [(ngModel)]="newPrinterPCOG!.rushManufacturingTime" placeholder="Rush Mfg. Time">
									<label>Rush Mfg. Time</label>
								</div>
							</td>
							<td data-title="Percent Of Budget">
								<div class="input-group">
									<div class="form-floating">
										<input type="number" id="percentOfBudget" min="0" max="100" class="form-control"
											name="percentOfBudget" [(ngModel)]="newPrinterPCOG!.percentOfBudget" placeholder="Percent Of Budget">
										<label>Percent Of Budget</label>
									</div>
									<div class="input-group-text">%</div>
								</div>
							</td>
							<td data-title="Options">
								<div *ngIf="productOptsByCat.length > 0 && editOptionsIndex.index === -1 && editOptionsIndex.printerId === newPrinterPCOG!.printerId; else noOptionsSelected">
									<app-pcog-options [pcogOptions]="newPrinterPCOG!.options!" [prodOptCats]="productOptsByCat" (optionSelected)="handleOptionSelected($event, newPrinterPCOG)"></app-pcog-options>
								</div>
								<ng-template #noOptsNewPrinter>
								</ng-template>
								<div>
									<button *ngIf="editOptionsIndex.index === -1 && editOptionsIndex.printerId === newPrinterPCOG!.printerId; else offerEditOptions" class="btn btn-sm btn-warning"
										(click)="cancelEditOptions()">
										Cancel Edit
									</button>
									<ng-template #offerEditOptions>
										<button  class="btn btn-sm btn-success" (click)="enableEditOptions(newPrinterPCOG!, -1)">
											Add Options
										</button>
									</ng-template>
								</div>
							</td>
							<td>
								<ul class="list-inline">
									<li class="list-inline-item">
										<button class="btn btn-success" [disabled]="newPrinterPCOG!.minQty <= 0 || newPrinterPCOG!.maxQty <= 0 || newPrinterPCOG!.prepPerPiece < 0 || newPrinterPCOG!.pricePerPiece < 0" (click)="addPrinterToProduct()">Add</button>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
	</div>
</app-spinner-with-overlay>

<ng-template #noOptionsSelected>
	No options selected
</ng-template>

<ng-template #copyPCOGsModal>
	<app-confirm-modal title="Select Printer To Receive PCOGs" confirmText="Copy PCOGs" (confirmAction)="copyPCOGsToPrinter()"
		(closeModal)="closeModal()" [disabled]="!printerToReceivePCOGs">
		<div class="form-floating">
			<select type="select" class="form-select" name="newPrinterId" [(ngModel)]="printerToReceivePCOGs">
				<option [ngValue]="undefined" default>Select a printer to set PCOGs</option>
				<option *ngFor="let printer of printersForCopy" [ngValue]="printer.printerId">
					{{ printer.companyName }} ({{ printer.printerId }})
				</option>
			</select>
		</div>
	</app-confirm-modal>
</ng-template>
