import { Component,  OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerProfile, PaymentProfile } from '@taradel/admin-api-client';
import { AuthenticationService } from 'services/authentication.service';
import { SalesApiService } from 'services/sales-api.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-payment-profiles',
  templateUrl: './payment-profiles.component.html',
  styleUrls: ['./payment-profiles.component.scss']
})
export class PaymentProfilesComponent implements OnInit {
	loading = false;
	customerId = 0;
	customerProfile?: CustomerProfile;
	paymentProfile?: PaymentProfile;

	constructor(
		public authService: AuthenticationService,
		private modalService: NgbModal,
		private salesService: SalesApiService,
		private route: ActivatedRoute,
		private toastService: ToastService
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
	}

	async ngOnInit() {
		try {
			this.loading = true;
			this.customerProfile = await this.salesService.getCustomerProfile(this.customerId);
		}
		catch {
			this.toastService.showError('There was an error getting the customer profile');
		}
		finally {
			this.loading = false;
		}
	}

	async deletePaymentProfile() {
		try {
			this.loading = true;
			this.customerProfile!.paymentProfiles = this.customerProfile?.paymentProfiles?.filter(x => x.customerPaymentProfileId !== this.paymentProfile?.customerPaymentProfileId);
			this.closeModal();
			await this.salesService.deleteCustomerProfile(this.customerId!, this.paymentProfile?.customerPaymentProfileId!);
			this.paymentProfile = undefined;
			this.toastService.showSuccess('Customer payment profile deleted successfully');
		}
		catch {
			this.toastService.showError('There was an error deleting customer payment profile');
		}
		finally {
			this.loading = false;
		}
	}

	openModal(modal: any, paymentProfile: PaymentProfile) {
		this.paymentProfile = paymentProfile;
		this.modalService.open(modal);
	}

	closeModal() {
		this.modalService.dismissAll();
	}

}

