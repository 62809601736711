import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormArray, FormGroup, UntypedFormArray } from '@angular/forms';
import { BundleDisplay, BundleContent, BundleContentResponse, CmsContentType, CmsContent, BundleCmsContent, Site, SiteFilterRequest,
	SiteLanguage, CmsContentKey, UpdateBundleRequest
 } from '@taradel/admin-api-client';
import { BundlesService } from 'services/bundles.service';
import { SitesService } from 'services/sites.service';

@Component({
  selector: 'app-bundle-content-customization',
  templateUrl: './bundle-content-customization.component.html',
  styleUrls: ['./bundle-content-customization.component.scss']
})
export class BundleContentCustomizationComponent implements OnInit {
	contentForm: UntypedFormGroup;
	loading = false;
	submitted = false;
	sites: Site[] = [];

	ContentType = CmsContentType;

	@Input() bundle?: BundleDisplay;
	@Input() contentResponse?: BundleContentResponse;
	@Input() selectedBundleContent?: CmsContent[];

	@Output() bundleContentUpdated = new EventEmitter<BundleContent>();
	@Output() bundleCmsContentUpdated = new EventEmitter<CmsContent[]>();
	@Output() goBack = new EventEmitter();

	constructor(
		private formBuilder: UntypedFormBuilder,
		private bundlesService: BundlesService,
		private sitesService: SitesService
	) {
		this.contentForm = this.formBuilder.group({
			// need some kind of regex validator for hex color code
			bundleColor: new FormControl<string | undefined>(undefined),
			contentItems: new FormArray([])
		});
	}

	get controls() {
		return this.contentForm.controls;
	}

	get items() {
		return (this.contentForm.controls['contentItems'] as FormArray).controls as FormGroup[];
	}

	async ngOnInit(): Promise<void> {
		this.contentForm.reset();
		if (!!this.bundle) {
			this.populateForm();
		}
		let request = new SiteFilterRequest({
			adminCheckout: false,
			showInactive: false
		});
		this.sites = await this.sitesService.getSitesForAdmin(request);
	}

	populateForm() {
		const trimmedName = this.bundle?.name?.replace(/[^a-zA-Z ]/g, "").replace(/\s+/g,'').trim();
		if (this.bundle?.bundleContent?.bundleColor) {
			this.controls.bundleColor.setValue(this.bundle?.bundleContent?.bundleColor);
		}
		let detailsContentKey = '';
		let descriptionContentKey = '';
		let nameContentKey = '';
		if (this.bundle?.bundleContent?.detailsContentKey) {
			detailsContentKey = this.bundle?.bundleContent?.detailsContentKey;
		}
		else {
			detailsContentKey = `Order.Bundles.${trimmedName}Detail`;
		}
		if (this.bundle?.bundleContent?.descriptionContentKey) {
			descriptionContentKey = this.bundle.bundleContent.descriptionContentKey;
		}
		else {
			descriptionContentKey = `Order.Bundles.${trimmedName}Desc`;
		}
		if (this.bundle?.bundleContent?.nameContentKey) {
			nameContentKey = this.bundle?.bundleContent?.nameContentKey;
		}
		else {
			nameContentKey = `Order.Bundles.${trimmedName}Name`;
		}

		if (this.selectedBundleContent?.some(c => c.contentKey?.contentKey === detailsContentKey)) {
			const cmsContent: CmsContent[] = this.selectedBundleContent.filter(c => c.contentKey?.contentKey === detailsContentKey);
			const contentType = cmsContent![0].contentKey!.contentType;
			let contentArray: FormArray = new FormArray<any>([]);
			cmsContent?.forEach(c => {
				const contentItem = this.formBuilder.group({
					siteId: new FormControl<number>(c.siteId),
					language: new FormControl<string>(c.language ?? 'en'),
					content: new FormControl<string>(c.content ?? '')
				});
				contentArray.push(contentItem);
			});
			const item = this.formBuilder.group({
				bundleContentType: 'Details Content Key',
				contentKey: detailsContentKey,
				contentType,
				contentArray
			});
			this.items.push(item);
		}
		else {
			const contentItem = this.formBuilder.group({
				siteId: new FormControl<number>(0),
				language: new FormControl<string>('en'),
				content: new FormControl<string>('')
			});
			const item = this.formBuilder.group({
				bundleContentType: 'Details Content Key',
				contentKey: new FormControl<string | undefined>(detailsContentKey, Validators.required),
				contentType: new FormControl<CmsContentType | undefined>(undefined, Validators.required),
				contentArray: new FormArray([])
			});
			(item.get('contentArray') as FormArray).controls.push(contentItem);
			this.items.push(item);
		}

		if (this.selectedBundleContent?.some(c => c.contentKey?.contentKey === descriptionContentKey)) {
			const cmsContent: CmsContent[] = this.selectedBundleContent?.filter(c => c.contentKey?.contentKey === descriptionContentKey);
			const contentType = cmsContent![0].contentKey!.contentType;
			let contentArray: FormArray = new FormArray<any>([]);
			cmsContent?.forEach(c => {
				const contentItem = this.formBuilder.group({
					siteId: new FormControl<number>(c.siteId),
					language: new FormControl<string>(c.language ?? 'en'),
					content: new FormControl<string>(c.content ?? '')
				});
				contentArray.push(contentItem);
			});
			const item = this.formBuilder.group({
				bundleContentType: 'Description Content Key',
				contentKey: descriptionContentKey,
				contentType,
				contentArray
			});
			this.items.push(item);
		}
		else {
			const contentItem = this.formBuilder.group({
				siteId: new FormControl<number>(0),
				language: new FormControl<string>('en'),
				content: new FormControl<string>('')
			});
			const item = this.formBuilder.group({
				bundleContentType: 'Description Content Key',
				contentKey: new FormControl<string | undefined>(descriptionContentKey, Validators.required),
				contentType: new FormControl<CmsContentType | undefined>(undefined, Validators.required),
				contentArray: new FormArray([])
			});
			(item.get('contentArray') as FormArray).controls.push(contentItem);
			this.items.push(item);
		}

		if (this.selectedBundleContent?.some(c => c.contentKey?.contentKey === nameContentKey)) {
			const cmsContent: CmsContent[] = this.selectedBundleContent.filter(c => c.contentKey?.contentKey === nameContentKey)!;
			const contentType = cmsContent![0].contentKey!.contentType;
			let contentArray: FormArray = new FormArray<any>([]);
			cmsContent?.forEach(c => {
				const contentItem = this.formBuilder.group({
					siteId: new FormControl<number>(c.siteId),
					contentKey: new FormControl<CmsContentKey | undefined>(c.contentKey),
					language: new FormControl<string>(c.language ?? 'en'),
					content: new FormControl<string>(c.content ?? '')
				});
				contentArray.push(contentItem);
			});
			const item = this.formBuilder.group({
				bundleContentType: 'Name Content Key',
				contentKey: nameContentKey,
				contentType,
				contentArray
			});
			this.items.push(item);
		}
		else {
			const contentItem = this.formBuilder.group({
				siteId: new FormControl<number>(0),
				language: new FormControl<string>('en'),
				content: new FormControl<string>('')
			});
			const item = this.formBuilder.group({
				bundleContentType: 'Name Content Key',
				contentKey: new FormControl<string | undefined>(nameContentKey, Validators.required),
				contentType: new FormControl<CmsContentType | undefined>(undefined, Validators.required),
				contentArray: new FormArray([])
			});
			(item.get('contentArray') as FormArray).controls.push(contentItem);
			this.items.push(item);
		}
	}

	getContentItems(index: number): FormGroup[] {
		return (this.items.at(index)?.controls['contentArray'] as FormArray).controls as FormGroup[];
	}

	setContentSiteId(siteId: number, bundleContentType: string, index: number) {
		const item = this.items.find(i => i.get('bundleContentType')?.value === bundleContentType);
		if (!!item) {
			const contentFormArray = (item.get('contentArray') as FormArray).controls as FormGroup[];
			contentFormArray.at(index)?.get('siteId')?.setValue(siteId);
		}
	}

	setContentLanguage(bundleContentType: string, language: string, index: number) {
		const item = this.items.find(i => i.get('bundleContentType')?.value === bundleContentType);
		if (!!item) {
			const contentFormArray = (item.get('contentArray') as FormArray).controls as FormGroup[];
			contentFormArray.at(index)?.get('language')?.setValue(language);
		}
	}

	setContent(bundleContentType: string, content: string, index: number) {
		const item = this.items.find(i => i.get('bundleContentType')?.value === bundleContentType);
		if (!!item) {
			const contentFormArray = (item.get('contentArray') as FormArray).controls as FormGroup[];
			contentFormArray.at(index)?.get('content')?.setValue(content);
		}
	}

	saveContent() {
		this.submitted = true;

		let nameContentKey = '';
		let descriptionContentKey = '';
		let detailsContentKey = '';

		let updatedContent: CmsContent[] = [];
		for (let i = 0; i < this.items.length; i++) {
			const item = this.items[i];
			if (item.get('bundleContentType')?.value === 'Name Content Key') {
				nameContentKey = item.get('contentKey')?.value;
			}
			else if (item.get('bundleContentType')?.value === 'Details Content Key') {
				detailsContentKey = item.get('contentKey')?.value;
			}
			else if (item.get('bundleContentType')?.value === 'Description Content Key') {
				descriptionContentKey = item.get('contentKey')?.value;
			}
			const contentArray = (item.get('contentArray') as FormArray).controls;
			if (!!contentArray && contentArray.length) {
				for (let j = 0; j < contentArray.length; j++) {
					const arrayItem = contentArray[j];
					const contentItem = {
						siteId: arrayItem.get('siteId')?.value,
						contentKey: {
							contentKey: item.get('contentKey')?.value,
							contentType: item.get('contentType')?.value
						} as CmsContentKey,
						language: arrayItem.get('language')?.value,
						content: arrayItem.get('content')?.value
					} as CmsContent;
					updatedContent.push(contentItem);
				}
			}
		}

		const newContent = {
			nameContentKey: nameContentKey.length > 0 ? nameContentKey : undefined,
			descriptionContentKey: descriptionContentKey.length > 0 ? descriptionContentKey : undefined,
			detailsContentKey: detailsContentKey.length > 0 ? detailsContentKey : undefined,
			bundleColor: (!!this.controls.bundleColor.value && this.controls.bundleColor.value.length > 0) ? this.controls.bundleColor.value : undefined
		} as BundleContent;

		this.bundleContentUpdated.emit(newContent);
		this.bundleCmsContentUpdated.emit(updatedContent);
	}

	emitGoBack() {
		this.goBack.emit();
	}
}
