<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row mb-3">
		<div class="col-md-6">
			<div class="card p-3">
				<div class="card-body">
					<h5 class="card-title">Add Product Option</h5>
					<form [formGroup]="categoryOptionForm">
						<fieldset>
							<div class="form-floating mb-3">
								<input type="text" formControlName='name' id="name" class="form-control" maxlength="50" required
								[ngClass]="{ 'is-invalid': submitted && categoryOptionControls.name.errors }" />
								<label for="name">Name</label>
							</div>
							<div class="form-floating mb-3">
								<input formControlName='modelNumber' id="modelNumber" class="form-control" maxlength="50">
								<label for="modelNumber">Model Number (Must match CRM/Quickbooks)</label>
							</div>
							<div class="form-floating mb-3">
								<textarea  formControlName='description' id="description" class="form-control" >
								</textarea>
								<label for="description">Description:</label>
							</div>
							<div class="form-check mb-3">
								<input formControlName='markupPercent' id="markupPercent" class="form-check-input" type="checkbox">
								<label for="markupPercent" class="form-check-label">Markup Percent</label>
							</div>
							<div class="form-floating mb-3">
								<input formControlName='markup' id="markup" class="form-control" type="number" step="0.1">
								<label for="markup">Markup</label>
							</div>
							<div class="d-flex justify-content-evenly mb-3">
								<div class="form-floating mb-3 col-md-2">
									<input class="form-control" type="number" step="0.1" formControlName='optionWidth' id="optionWidth">
									<label for="optionWidth"><small>Width (inches)</small></label>
								</div>
								<div class="form-floating mb-3 col-md-2">
									<input class="form-control" type="number" step="0.1" formControlName='optionHeight' id="optionHeight">
									<label for="optionHeight"><small>Height (inches)</small></label>
								</div>
								<div class="form-floating mb-3 col-md-2">
									<input class="form-control" type="number" step="0.1" formControlName='optionQuantity' id="optionQuantity">
									<label for="optionQuantity"><small>Quantity</small></label>
								</div>
								<div class="form-floating mb-3 col-md-2">
									<input class="form-control" type="number" step="0.1" formControlName='optionWeight' id="optionWeight">
									<label for="optionWeight"><small>Weight (lbs)</small></label>
								</div>
								<div class="form-floating mb-3  mt-3 col-md-2">
									<button class="btn btn-primary btn-sm" (click)="calculateWeight()">Calculate</button>
								</div>
							</div>
							<div class="form-floating mb-3">
								<input formControlName='weightPerThousand' id="weightPerThousand"  class="form-control"
								type="number" step="0.111">
								<label for="weightPerThousand">Weight per thousand/sq in</label>
							</div>
							<div class="form-floating mb-3">
								<input formControlName='fourOverProductCodeSlug' id="fourOverProductCodeSlug"  class="form-control"
								[ngClass]="{ 'is-invalid': submitted && categoryOptionControls.fourOverProductCodeSlug.errors }"
								type="text" maxlength="100">
								<label for="fourOverProductCodeSlug">Four Over Product Code Slug</label>
							</div>
							<div class="form-floating mb-3">
								<input formControlName='fourOverOptionId' id="fourOverOptionId"  class="form-control"
								[ngClass]="{ 'is-invalid': submitted && categoryOptionControls.fourOverOptionId.errors }"
								type="text" minlength="36" maxlength="36">
								<label for="fourOverOptionId">Four Over Option Id</label>
							</div>
						</fieldset>
					</form>
				</div>
				<div class="card-body">
					<button class="btn btn-primary btn-lg" (click)="saveNewOption()">Save</button>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
