<app-pagefullheader></app-pagefullheader>
<div class="card border-0 p-3">
	<div class="row mt-3">
		<app-spinner-with-overlay [showSpinner]="loading">
			<div class="ratio ratio-21x9" *ngIf="loading"></div>
			<div class="col-sm-12" *ngIf="!loading">
				<div class="table-responsive">
					<table class="table table-hover table-panel">
						<thead>
							<tr>
								<th>
									Indicator
								</th>
								<th>
									Period
								</th>
								<th>
									Dates
								</th>
								<th>
									Quantity
								</th>
								<th>
									Total
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td><strong>Weekly Sales</strong></td>
								<td>This Week</td>
								<td>{{salesIndicators.thisWeekStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.thisWeekEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.thisWeek!.quantity | number}}</td>
								<td>{{salesIndicators.thisWeek!.total! | currency: 'USD'}}</td>
							</tr>
							<tr>
								<td></td>
								<td>Last Week</td>
								<td>{{salesIndicators.lastWeekStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.lastWeekEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.lastWeek!.quantity | number}}</td>
								<td>{{salesIndicators.lastWeek!.total! | currency: 'USD'}}</td>
							</tr>
							<tr>
								<td></td>
								<td>This Week Last Year</td>
								<td>{{salesIndicators.thisWeekLastYearStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.thisWeekLastYearEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.thisWeekLastYear!.quantity | number}}</td>
								<td>{{salesIndicators.thisWeekLastYear!.total! | currency: 'USD'}}</td>
							</tr>
							<tr>
								<td></td>
								<td>Last Week Last Year</td>
								<td>{{salesIndicators.lastWeekLastYearStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.lastWeekLastYearEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.lastWeekLastYear!.quantity | number}}</td>
								<td>{{salesIndicators.lastWeekLastYear!.total! | currency: 'USD'}}</td>
							</tr>
							<tr></tr>
							<tr>
								<td><strong>Monthly Sales</strong></td>
								<td>This Month</td>
								<td>{{salesIndicators.thisMonthStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.thisMonthEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.thisMonth!.quantity | number}}</td>
								<td>{{salesIndicators.thisMonth!.total! | currency: 'USD'}}</td>
							</tr>
							<tr>
								<td></td>
								<td>Last Month</td>
								<td>{{salesIndicators.lastMonthStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.lastMonthEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.lastMonth!.quantity | number}}</td>
								<td>{{salesIndicators.lastMonth!.total! | currency: 'USD'}}</td>
							</tr>
							<tr>
								<td></td>
								<td>This Month Last Year</td>
								<td>{{salesIndicators.thisMonthLastYearStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.thisMonthLastYearEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.thisMonthLastYear!.quantity | number}}</td>
								<td>{{salesIndicators.thisMonthLastYear!.total! | currency: 'USD'}}</td>
							</tr>
							<tr>
								<td></td>
								<td>Last Month Last Year</td>
								<td>{{salesIndicators.lastMonthLastYearStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.lastMonthLastYearEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.lastMonthLastYear!.quantity | number}}</td>
								<td>{{salesIndicators.lastMonthLastYear!.total! | currency: 'USD'}}</td>
							</tr>
							<tr></tr>
							<tr>
								<td><strong>Quarterly Sales</strong></td>
								<td>This Quarter</td>
								<td>{{salesIndicators.thisQuarterStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.thisQuarterEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.thisQuarter!.quantity | number}}</td>
								<td>{{salesIndicators.thisQuarter!.total! | currency: 'USD'}}</td>
							</tr>
							<tr>
								<td></td>
								<td>Last Quarter</td>
								<td>{{salesIndicators.lastQuarterStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.lastQuarterEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.lastQuarter!.quantity | number}}</td>
								<td>{{salesIndicators.lastQuarter!.total! | currency: 'USD'}}</td>
							</tr>
							<tr>
								<td></td>
								<td>This Quarter Last Year</td>
								<td>{{salesIndicators.thisQuarterLastYearStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.thisQuarterLastYearEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.thisQuarterLastYear!.quantity | number}}</td>
								<td>{{salesIndicators.thisQuarterLastYear!.total! | currency: 'USD'}}</td>
							</tr>
							<tr>
								<td></td>
								<td>Last Quarter Last Year</td>
								<td>{{salesIndicators.lastQuarterLastYearStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.lastQuarterLastYearEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.lastQuarterLastYear!.quantity | number}}</td>
								<td>{{salesIndicators.lastQuarterLastYear!.total! | currency: 'USD'}}</td>
							</tr>
							<tr></tr>
							<tr>
								<td><strong>YTD Sales</strong></td>
								<td>This Year</td>
								<td>{{salesIndicators.thisYearStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.thisYearEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.thisYear!.quantity | number}}</td>
								<td>{{salesIndicators.thisYear!.total! | currency: 'USD'}}</td>
							</tr>
							<tr>
								<td></td>
								<td>Last Year</td>
								<td>{{salesIndicators.lastYearStart | date: 'MM/dd/yyyy' }} -
									{{salesIndicators.lastYearEnd | date: 'MM/dd/yyyy' }}</td>
								<td>{{salesIndicators.lastYear!.quantity | number}}</td>
								<td>{{salesIndicators.lastYear!.total! | currency: 'USD'}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</app-spinner-with-overlay>
	</div>
</div>
