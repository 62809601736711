<app-spinner-with-overlay [showSpinner]="loading">
	<ng-container *ngIf="!!bundles">
		<div class="row mt-3">
			<div class="col-md-3 col-12">
				<div class="card">
					<div class="card-body">
						<h5>Bundles</h5>
						<div *ngFor="let bundle of bundles" class="row">
							<div class="col">
								<button class="btn btn-link" (click)="selectBundle(bundle)">{{ bundle.bundleDisplay.name }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="selectedBundle" class="col-md-9 col-12">
				<app-bundle-details [siteId]="siteId" [bundle]="selectedBundle"></app-bundle-details>
			</div>
		</div>
	</ng-container>
</app-spinner-with-overlay>
