import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment } from 'environment';
import { AuthenticationService } from './services/authentication.service';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthenticationService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.handle(req, next));
	}

	private async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
		if (req.url.startsWith(environment.webApiUrl) || req.url.startsWith(environment.adminApiUrl)) {
			// Make sure we're authenticated for API calls.  These promises will already be complete if we are.
			if (!(await this.authService.isAuthenticated())) {
				await this.authService.login();
			}

			// We're now logged in, if we possibly can be.
			const accessToken = await this.authService.token();
			if (accessToken) {
				const cloned = req.clone({
					headers: req.headers.set('Authorization', accessToken)
				});

				return await lastValueFrom(next.handle(cloned));
			}
		}

		// We'll fall back to here if we couldn't get logged in, and try the request anyway.
		return await lastValueFrom(next.handle(req));
	}
}
