<ng-container *ngIf="!!languagesList && !!baseProductId && !!siteId">
	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="d-flex justify-content-between w-50">
			<span *ngIf="!!wlProductLanguage && !allowEdit; else editName">{{ wlProductLanguage?.wlProductName ?? 'null' }}</span>
			<ng-template #editName>
				<span>
					<form [formGroup]="editTranslatedNameForm" (submit)="handleFormSubmission()">
						<div class="input-group">
							<input type="text" class="form-control" aria-label="translatedWLProductName" formControlName="wlProductName"
								aria-describedby="Translated WL Product Name" [placeholder]="wlProductLanguage?.wlProductName ?? 'null'" />
								<button type="submit" class="btn btn-primary" [disabled]="editTranslatedNameForm.invalid">
									<i class="fa fa-save mx-2" data-toggle="tooltip" data-placement="top"
										title="Save"></i></button>
								<button class="btn btn-warning mx-2" type="button"
									data-toggle="tooltip" data-placement="top" title="Cancel" (click)="cancelEdit()"><i
										class="fa fa-times"></i></button>
						</div>
					</form>
				</span>
			</ng-template>
			<ng-container *ngIf="!!wlProductLanguage && !allowEdit">
				<button class="btn btn-sm btn-success mx-1" title="Edit Translated WL Product Name"
				(click)="allowEdit = true;">
					<i class="fa fa-edit"></i>
				</button>
				<button class="btn btn-sm btn-danger" title="Remove Translated WL Product Name"
				(click)="removeTranslatedName()">
					<i class="fa fa-trash"></i>
				</button>
			</ng-container>
		</div>
	</app-spinner-with-overlay>
</ng-container>
