<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row" *ngFor="let number of [].constructor(numberOfDrops); let dropNumber = index;">
		<div class="col-8">
			<div class="card mb-3">
				<div class="card-header"><b>Drop {{dropNumber + 1 }}</b><span> of {{numberOfDrops}}</span></div>
				<div class="card-body">
					<div class="row">
						<div class="col-xl-6 col-lg-12">
							<div class="row">
								<div class="col-12">
									<div class="form-floating style-dates">
										<input class="form-control" [id]="'drop' + dropNumber + 'deliveryDate'" (dateSelect)="setDropDate(dropNumber, $event)" [firstDayOfWeek]="7"
											[ngModel]="orderLayoutService.selectedOrderItemDropDateMap.get(dropNumber + 1)"
											[minDate]="{year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()}"
											name="dp" ngbDatepicker #d="ngbDatepicker" readonly>
										<label [for]="'drop' + dropNumber + 'deliveryDate'">Delivery Date</label>
										<div class="d-flex justify-content-end">
											<span class="fa fa-calendar" (click)="d.toggle()"></span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-lg-12">
							<div class="form-floating">
								<input type="text" [ngModel]="getDropTotal(dropNumber + 1) | number" class="form-control" readonly disabled />
								<label>Total Deliveries</label>
							</div>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col-auto">
							<div><strong>In Home Range: {{orderLayoutService.getDropInHomeRange(dropNumber + 1)}}</strong></div>
						</div>
						<div class="col-auto" *ngIf="orderLayoutService.purchaseOrder">
							<button class="btn btn-primary btn-sm ms-auto me-1" (click)="sendDropRouteCsv(dropNumber + 1)">Send Route CSV</button>
						</div>
					</div>
					<div class="row">
						<div class="col-xl-6 col-lg-12">
							<div class="form-floating mb-3">
								<input type="text" [ngModel]="orderLayoutService.getDistributionName(dropNumber)" class="form-control" readonly disabled />
								<label>Distribution</label>
							</div>
						</div>
						<div class="col-xl-6 col-lg-12">
							<div class="row">
								<div class="col">
									<app-change-distribution [dropNumber]="dropNumber" (updateDistribution)="selectedDropNum=dropNumber + 1; updateDropDistribution($event)"></app-change-distribution>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<button class="btn btn-primary btn-sm me-1 mt-1" [disabled]="orderLayoutService.getDistributionId(dropNumber) === 0"
										routerLink="/customers/{{orderLayoutService.selectedCustomer?.customerID!}}/distributions/{{orderLayoutService.getDistributionId(dropNumber)}}">View Distribution</button>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<button class="btn btn-primary btn-sm ms-auto me-1" (click)="updateDropDate(dropNumber + 1)" [disabled]="disableBtn">Update Drop {{dropNumber + 1}}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
