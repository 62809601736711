<app-customer-header [customerId]="customerId">
	<ng-container altnav>
		<ul class="nav nav-pills justify-content-end">
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'distributions']">Back to Distributions</a>
			</li>
			<li class="nav-item">
				<a class="nav-link active" [routerLink]="['/customers', customerId, 'snapmap']">Build Map</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'snap-paste-list']">Paste Routes</a>
			</li>
		</ul>
	</ng-container>
</app-customer-header>

<div class="row">
	<div class="col">
		<div class="card">
			<div class="card-body">
				<div class="input-group">
					<input appAutocompleteOff type="search" class="form-control" placeholder="Enter Address/Postal Code" [options]="options" ngx-gp-autocomplete #placesRef="ngx-places" #placesInput (onAddressChange)="handleAddressChange($event)" (keyup.enter)="selectRoutesBasedOnInput('loadRoute')">
					<button class="btn btn-primary" *ngIf="!mapLoaded" (click)="selectRoutesBasedOnInput('loadRoute')">Set Address</button>
					<button class="btn btn-primary" *ngIf="mapLoaded" (click)="selectRoutesBasedOnInput('droppin')" title="Add another pin to the map for location perspective">Drop Pin</button>
				</div>
				<div class="mt-3 text-end fw-bold text-danger" *ngIf="snapMinimum > 0">
					Minimum of {{snapMinimum}} addresses required by Gilmore
				</div>
			</div>
		</div>
	</div>
	<div class="col-auto">
		<div class="card">
			<div class="card-body">
				<button class="btn btn-primary" [disabled]="!mapLoaded || totalPrintSelections < snapMinimum || totalPrintSelections === 0 || loading" (click)="openModal(saveMapModal)">Save Map</button>
			</div>
		</div>
	</div>
</div>
<div class="row mt-2" *ngIf="mapLoaded">
	<div class="col-12">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-auto">
						<strong>Hover route to view details around {{mapAddress}},
							{{mapZipCode}}</strong>
					</div>
					<div class="col text-end">
						<div *ngIf="hovering && selectedTab === 'map'">
							<ul class="list-inline m-0">
								<li class="list-inline-item"><strong>{{hoveringFeature?.properties?.geocoderef}}</strong></li>
								<li class="list-inline-item"><strong>{{hoveringFeature?.properties?.friendlyName}}</strong></li>
								<li class="list-inline-item" *ngIf="hoveringRoutePercentMatch"><strong>{{hoveringRoutePercentMatch |
										percent}} match</strong></li>
								<li class="list-inline-item"><b>Total Mail: </b>{{hoveringFeature?.properties?.houseCount + hoveringFeature?.properties?.apartmentCount
									+ hoveringFeature?.properties?.businessCount + hoveringFeature?.properties?.farmCount |
									number}}</li>
								<li class="list-inline-item"><b>House: </b>{{hoveringFeature?.properties?.houseCount |
									number}}</li>
								<li class="list-inline-item"><b>Apt: </b>{{hoveringFeature?.properties?.apartmentCount |
									number}}</li>
								<li class="list-inline-item"><b>Farm: </b>{{hoveringFeature?.properties?.farmCount |
									number}}</li>
								<li class="list-inline-item"><b>Biz: </b>{{hoveringFeature?.properties?.businessCount |
									number}}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row mt-2">
	<div class="col-md-3">
		<div class="card" *ngIf="mapLoaded">
			<div class="card-header">
				<ul class="nav nav-tabs card-header-tabs">
					<li class="nav-item">
						<button class="nav-link active" data-bs-toggle="tab" data-bs-target="#selectionTab">Selections</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#estimateTab">Estimate</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#demographicsTab">Demographics</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#radiusTab">Radius</button>
					</li>
				</ul>
			</div>
			<div class="tab-content">
				<div class="card-body tab-pane active" id="selectionTab" role="tabpanel">
					<h5 class="card-title">Selections</h5>
					<div class="widget widget-stats bg-teal mb-7px">
						<div class="stats-icon stats-icon-lg"><i class="fa fa-tally fa-fw"></i></div>
						<div class="stats-content">
							<div class="stats-title">Total Selections</div>
							<div class="stats-number">{{totalPrintSelections | number}}<span *ngIf="match"><br>
									({{ match }}% match)
								</span></div>
						</div>
					</div>

					<div class="mb-3">
						<div><b>Delivery Types:</b></div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" value="" id="resCheck" (click)="useHouseAdresses()" [(ngModel)]="useHouse" checked>
							<label class="form-check-label" for="resCheck">
								House
							</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="apartmentCheck" (click)="useApartmentAddresses()" value="option1" [(ngModel)]="useApartment" checked>
							<label class="form-check-label" for="apartmentCheck">Apartment</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="farmCheck" (click)="useFarmAdresses()" value="option1" [(ngModel)]="useFarm" checked>
							<label class="form-check-label" for="farmCheck">Farm</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="businessCheck" (click)="useBusinessAdresses()" value="option1" [(ngModel)]="useBusiness" checked>
							<label class="form-check-label" for="businessCheck">Business</label>
						</div>

					</div>

					<div class="row mb-3">
						<div class="col-12">
							<b>Breakdown</b>
						</div>
						<div class="col-sm-6">
							<div *ngIf="useHouse">Houses: {{totalHousesSelected | number}}</div>
							<div *ngIf="useApartment">Apartment: {{totalApartmentsSelected | number}}</div>
							<div *ngIf="useFarm">Farm: {{totalFarmsSelected | number}}</div>
							<div *ngIf="useBusiness">Business: {{totalBusinessesSelected | number}}</div>
						</div>
					</div>

					<div class="mb-3" *ngIf="pins.length > 0">
						<div><b>Additional Pins</b></div>
						<ul class="list-group">
							<li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let pin of pins">
								{{pin.getTitle()}}
								<button class="btn btn-xs btn-danger" (click)="removePin(pin)"><i class="fa fa-map-marker-slash"></i></button>
							</li>
						</ul>
					</div>

					<div class="mb-3 hstack gap-2">
						<button class="btn btn-danger" (click)="clearAllSelections()">Clear All Selections</button>
						<button class="btn btn-danger" (click)="reloadRoute()">Reset Map</button>
					</div>
					<div class="mb-3" *ngIf="previousSelectedFeatures && previousSelectedFeatures.length > 0">
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" id="savedSpatials" [(ngModel)]="showSavedSpatials" (ngModelChange)="loadSavedSpatials()">
							<label class="form-check-label" for="savedSpatials">Load Saved Spatials (<b>Total Previous Selections - {{totalPreviousPrintSelections}}</b>)</label>
						</div>
					</div>
				</div>
				<div class="card-body tab-pane" id="estimateTab" role="tabpanel">
					<h5 class="card-title">Estimate</h5>
					<app-spinner-with-overlay [showSpinner]="estimateLoading">
						<app-sites-list [startingSiteId]="selectedSiteId" (siteSelected)="loadSiteProducts($event.siteId)" [sites]="activeSites"></app-sites-list>

						<div *ngIf="snapProducts.length > 0">
							<div class="form-floating mb-3">
								<select class="form-select" id="inputGroupSelect01" #y (change)="loadProductDetails(y.value)">
									<option selected>Choose Product</option>
									<option *ngFor="let product of snapProducts" [value]="product.baseProductId">
										{{product.name}}</option>
								</select>
								<label>Choose Product</label>
							</div>

							<h1>
								Estimated Cost
							</h1>
							<div class="lead" *ngIf="underMinimumSelections">
								Not enough addresses, select more routes to exceed minimum required selections
							</div>
							<div class="lead" *ngIf="!underMinimumSelections">Basic Package: {{basicPrice | currency}}</div>
						</div>
					</app-spinner-with-overlay>
				</div>
				<div class="card-body tab-pane" id="demographicsTab" role="tabpanel">
					<h5 class="card-title">Demographics</h5>
					<div class="accordion" id="demographicsAccordion">
						<div class="accordion-item">
							<h2 class="accordion-header" id="householdIncomeHeading">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#householdIncomeCollapse" aria-expanded="true" aria-controls="householdIncomeCollapse">
									Household Income
								</button>
							</h2>
							<div id="householdIncomeCollapse" class="accordion-collapse collapse show" aria-labelledby="householdIncomeHeading" data-bs-parent="#demographicsAccordion">
								<div class="accordion-body">
									<div class="row">
										<div class="col-6" *ngFor="let incomeVal of householdIncome">
											<div class="form-check form-check-inline">
												<input class="form-check-input" type="checkbox" name="incomeOption" [id]="'incomeOpt' + incomeVal.key" [(ngModel)]="incomeVal.value">
												<label class="form-check-label" [for]="'incomeOpt' + incomeVal.key">{{incomeVal.key}}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<h2 class="accordion-header" id="ageHeading">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#ageCollapse" aria-expanded="true" aria-controls="ageCollapse">
									Median Age
								</button>
							</h2>
							<div id="ageCollapse" class="accordion-collapse collapse show" aria-labelledby="ageHeading" data-bs-parent="#demographicsAccordion">
								<div class="accordion-body">
									<div class="row">
										<div class="col-4" *ngFor="let age of medianAge">
											<div class="form-check form-check-inline">
												<input class="form-check-input" type="checkbox" name="ageOption" [id]="'ageOpt' + age.key" [(ngModel)]="age.value">
												<label class="form-check-label" [for]="'ageOpt' + age.key">{{ age.key !== '85' ? age.key : age.key + '+' }}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<h2 class="accordion-header" id="childrenHeading">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#childrenCollapse" aria-expanded="true" aria-controls="childrenCollapse">
									Presence of Children
								</button>
							</h2>
							<div id="childrenCollapse" class="accordion-collapse collapse show" aria-labelledby="childrenHeading" data-bs-parent="#demographicsAccordion">
								<div class="accordion-body">
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="childrenOpt" id="allChildrenRadio" value="option1" [checked]="children === ''" (click)="children = ''">
										<label class="form-check-label" for="allChildrenRadio">All</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="childrenOpt" id="noChildreRadio" value="option2" [checked]="children === 'NOCHILDREN'" (click)="children = 'NOCHILDREN'">
										<label class="form-check-label" for="noChildreRadio">None</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="childrenOpt" id="childrenRadio" value="option3" [checked]="children === 'CHILDREN'" (click)="children = 'CHILDREN'">
										<label class="form-check-label" for="childrenRadio">Children</label>
									</div>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<h2 class="accordion-header" id="homeOwnershipHeading">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#homeOwnershipCollapse" aria-expanded="true" aria-controls="homeOwnershipCollapse">
									Home Ownership
								</button>
							</h2>
							<div id="homeOwnershipCollapse" class="accordion-collapse collapse show" aria-labelledby="homeOwnershipHeading" data-bs-parent="#demographicsAccordion">
								<div class="accordion-body">
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="homeOpt" id="allOwnershipRadio" value="option1" [checked]="homeOwnership === ''" (click)="homeOwnership = ''">
										<label class="form-check-label" for="allOwnershipRadio">All</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="homeOpt" id="renterOwnershipRadio" value="option2" [checked]="homeOwnership === 'RENTER'" (click)="homeOwnership = 'R'">
										<label class="form-check-label" for="renterOwnershipRadio">Renter</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="homeOpt" id="homeownerOwnershipRadio" value="option3" [checked]="homeOwnership === 'OWNER'" (click)="homeOwnership = 'O'">
										<label class="form-check-label" for="homeownerOwnershipRadio">Homeowner</label>
									</div>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<h2 class="accordion-header" id="genderHeading">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#genderCollapse" aria-expanded="true" aria-controls="genderCollapse">
									Gender
								</button>
							</h2>
							<div id="genderCollapse" class="accordion-collapse collapse show" aria-labelledby="genderHeading" data-bs-parent="#demographicsAccordion">
								<div class="accordion-body">
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="genderOpt" id="allGenderRadio" value="option1" [checked]="gender === ''" (click)="gender = ''">
										<label class="form-check-label" for="allGenderRadio">All</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="genderOpt" id="maleGenderRadio" value="option2" [checked]="gender === 'MALE'" (click)="gender = 'MALE'">
										<label class="form-check-label" for="maleGenderRadio">Male</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="genderOpt" id="femaleGenderRadio" value="option3" [checked]="gender === 'FEMALE'" (click)="gender = 'FEMALE'">
										<label class="form-check-label" for="femaleGenderRadio">Female</label>
									</div>
								</div>
							</div>
						</div>

						<div class="input-group mt-3" *ngIf="demographicsSelected">
							<label class="form-check-label" for="childrenChildrenRadio">Select All Routes With Below Demographic Match</label>
							<div class="input-group">
								<input type="text" class="form-control mb-1" placeholder="Demographic Match Percentage" [(ngModel)]="selectAllDemosFloor">
								<button class="btn btn-secondary" type="button" (click)="selectRoutesBasedOnInput('selectDemographics')">Apply</button>
							</div>
						</div>
					</div>
					<div class="mt-3">
						<button class="btn btn-primary" [disabled]="loading" (click)="selectRoutesBasedOnInput('demographics')">Apply
							Demographics</button>
						<button class="btn btn-warning" *ngIf="demographicsSelected" (click)="clearHeatmap()">Clear
							Heatmap</button>
					</div>
				</div>
				<div class="card-body tab-pane" id="radiusTab" role="tabpanel">
					<h5 class="card-title">Radius/Drivetime</h5>
					<div class="mb-3">
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="inlineRadioOptions" id="radiusRadio" value="option1" [checked]="radiusDtSelected === 'radius'" (click)="radiusDtSelected = 'radius'">
							<label class="form-check-label" for="radiusRadio">Radius</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="inlineRadioOptions" id="drivetimeRadio" value="option2" [checked]="radiusDtSelected === 'drivetime'" (click)="radiusDtSelected = 'drivetime'">
							<label class="form-check-label" for="drivetimeRadio">Drivetime</label>
						</div>
					</div>
					<div class="input-group mb-3">
						<input type="text" class="form-control" [placeholder]="radiusDtSelected === 'radius' ? 'Radius in kilometers' : 'Drivetime in minutes'" [(ngModel)]="radiusDtVal">
						<span class="input-group-text" *ngIf="radiusDtSelected ==='radius'">kilometers</span>
						<span class="input-group-text" *ngIf="radiusDtSelected !=='radius'">minutes</span>
					</div>
					<button class="btn btn-primary" (click)="selectRoutesBasedOnInput(radiusDtSelected)">Apply
						Radius/Drivetime</button>

					<div class="mt-4 row" *ngIf="radiusActive">
						<div class="col-sm-12"><button class="btn btn-secondary" (click)="selectRoutesBasedOnInput('selectWithin')">
								Select All Routes in Radius
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card" *ngIf="!mapLoaded">
			<div class="card-body">
				<h5 class="card-title">Start mapping</h5>
				<p>Start building a map by first entering an address and clicking the Set Address button.</p>
			</div>
		</div>
	</div>
	<div class="col-md-9">
		<app-spinner-with-overlay [showSpinner]="loading">
			<div class="ratio ratio-21x9">
				<google-map width="100%" height="100%" [center]="center" [zoom]="zoom" [options]="mapOptions">
				</google-map>
			</div>
		</app-spinner-with-overlay>
	</div>
</div>

<ng-template #saveMapModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Save Map</h4>
		<button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div class="form-floating">
			<input type="text" class="form-control" placeholder="Map Name" [(ngModel)]="mapName" (keyup.enter)="saveMap()" />
			<label>Map Name</label>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link" [disabled]="loading" (click)="modal.close('Cancel Click')">Cancel</button>
		<button type="button" class="btn btn-outline-dark" [disabled]="mapName === '' || loading" (click)="saveMap()">Save</button>
	</div>
</ng-template>
