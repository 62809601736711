<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="row mb-3 d-flex justify-content-end" *ngIf="authService.organizationId === 1">
			<div class="col-auto">
				<a class="btn btn-success" [routerLink]="['/customers', customerId, 'payment-profiles', 'create-payment-profile']">Create Payment Profile</a>
			</div>
		</div>
		<ng-container *ngIf="(customerProfile?.paymentProfiles?.length ?? 0) === 0 && loading === false">
			<app-alert-box alertMode="info">
				No customer payment profiles found.
			</app-alert-box>
		</ng-container>

		<ng-container *ngIf="(customerProfile?.paymentProfiles?.length ?? 0) > 0">
			<div class="table-responsive">
				<table class="table table-hover table-panel">
					<thead>
						<tr>
							<th>Customer Payment Profile Id</th>
                            <th>Full name</th>
							<th>Payment Type</th>
							<th>Masked Card/Account#</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let paymentProfile of customerProfile?.paymentProfiles; let i = index;">
							<tr>
								<td>
									{{ paymentProfile.customerPaymentProfileId }}
								</td>
								<td>
									{{ paymentProfile.fullname }}
								</td>
								<td>
									{{ paymentProfile.creditCardMaskedData?.cardType ?? 'Checking'}}
								</td>
								<td>
									{{ paymentProfile.creditCardMaskedData?.maskedCard ?? paymentProfile.achMaskedData?.accountNumber }}
								</td>
								<td>
									<div class="row" >
										<div class="col-auto">
											<button type="button" class="btn btn-primary" (click)="openModal(deletePaymentProfileModal, paymentProfile)"
											[disabled]="authService.organizationId !== 1"
											data-toggle="tooltip" data-placement="top" title="Delete">
												Delete
											</button>
										</div>
									</div>
								</td>
							</tr>
						</ng-container>

					</tbody>
				</table>
			</div>
		</ng-container>
	</div>
</app-spinner-with-overlay>

<ng-template #deletePaymentProfileModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Delete Payment Profile '{{ paymentProfile?.customerPaymentProfileId }}'</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
			(click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">Are you sure you want to delete the payment profile <span class="text-danger">"{{ paymentProfile?.customerPaymentProfileId
				}}"</span>?</p>
		<p class="lead text-danger"><strong>This operation can not be undone.</strong></p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-text" (click)="closeModal()">Cancel</button>
		<button type="button" class="btn btn-primary btn-lg" (click)="deletePaymentProfile()">Delete
			Payment Profile</button>
	</div>
</ng-template>
