import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payments-section',
  templateUrl: './payments-section.component.html',
  styleUrls: ['./payments-section.component.scss']
})
export class PaymentsSectionComponent {
	customerId = 0;
	activeTab = 'customerPaymentsTab';
	constructor(private route: ActivatedRoute,
		private location: Location) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		route.paramMap.subscribe(params => {
			let state: any;
			let tab: string | undefined;
			if (!!location.getState()) {
				state = location.getState();
			}
			else {
				state = undefined;
			}
			if (!!state) {
				tab = state.tabId;
			}
			else {
				tab = undefined;
			}
			if (!!tab) {
				this.activeTab = tab;
			}
			if (this.customerId > 0) {
				location.replaceState(`/customers/${this.customerId}/payments`);
			}
			else {
				location.replaceState(`/payments`);
			}

		});
	}
}
