<app-printer-info [printerId]="printerId"></app-printer-info>

<app-spinner-with-overlay [showSpinner]="loading">
    <div class="card p-3 mb-3">
        <div class="row">
            <div class="col-sm-5">
                <div class="input-group mb-3">
                    <div class="input-group-text">Filter by Min Zip</div>
                    <div class="flex-fill position-relative">
                        <div class="input-group">
                            <div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style="z-index: 1;">
                                <i class="fa fa-search opacity-5"></i>
                            </div>
                            <input type="number" min="0" id="printerSearchBox" class="form-control px-35px bg-light" #minZipInput 
                            (keyup)="minZipSearch.next(minZipInput.value)" >
                            <button *ngIf="minZipInput.value.length > 0" class="btn btn-secondary" (click)="clearMinZipFilter()" type="button">
                                <span class="far fa-times-circle"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="col-sm-5">
                <select #zoneDropdown class="form-select" aria-label="Select a zone for this printer" (change)="getZonesByPrinterAndZone($any($event.target).value)">
                    <option selected>Filter by zone</option>
                    <option *ngFor="let zone of zoneArray" value="{{zone}}">{{zone}}</option>
                </select>
                
            </div>
            <div class="col-sm-2">
                <button *ngIf="selectedZone > 0" class="btn btn-danger" (click)="clearFilter()" type="button">
                    Clear All Filters
                </button>
            </div>
        </div>
    </div>
    <div class="card p-3">
        <div class="table-responsive">
            <table class="table table-hover table-responsive">
                <thead>
                    <tr>
                        <th width="25%">Min Zip</th>
                        <th width="25%">Max Zip</th>
                        <th width="25%">Zone</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let zone of printerZones">
                        <td data-title="Min Zip">
                            <div class="form-floating">
                                <input type="number" id="minZip3" min="1" max="999999999" class="form-control" disabled 
                                    [(ngModel)]="zone.minZip3">
                                <label>Min Zip</label>
                            </div>
                        </td>
                        <td data-title="Max Zip">
                            <div class="form-floating">
                                <input type="number" id="maxZip3" min="1" max="999999999" class="form-control" disabled 
                                    [(ngModel)]="zone.maxZip3">
                                <label>Max Zip</label>
                            </div>
                        </td>
                        <td data-title="Printer Zone">
                            <div class="form-floating">
                                <input type="number" id="zone" min="1" max="999999999" class="form-control"
                                    [(ngModel)]="zone.zone">
                                <label>Printer Zone</label>
                            </div>
                        </td>
                        <td>
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                    <button class="btn btn-primary" (click)="updatePrinterZone(zone)">Save</button>
                                </li>
                                <li class="list-inline-item">
                                    <button class="btn btn-danger" (click)="removePrinterZone(zone)">Delete</button>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    
                    <tr>
                        <td data-title="Min Zip">
                            <div class="form-floating">
                                <input type="number" id="minZip3" min="1" max="999999999" class="form-control"
                                    [(ngModel)]="newPrinterZone!.minZip3">
                                <label>Min Zip</label>
                            </div>
                        </td>
                        <td data-title="Max Zip">
                            <div class="form-floating">
                                <input type="number" id="maxZip3" min="1" max="999999999" class="form-control"
                                    [(ngModel)]="newPrinterZone!.maxZip3">
                                <label>Max Zip</label>
                            </div>
                        </td>
                        <td data-title="Printer Zone">
                            <div class="form-floating">
                                <input type="number" id="zone" min="1" max="999999999" class="form-control"
                                    [(ngModel)]="newPrinterZone!.zone">
                                <label>Printer Zone</label>
                            </div>
                        </td>
                        <td>
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                    <button class="btn btn-success" (click)="addNewPrinterZone()">Add</button>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</app-spinner-with-overlay>
