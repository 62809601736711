<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="card mb-3">
			<div class="card-body">
				<h5 class="card-title">Search</h5>
				<div class="row">
					<div class="col">
						<div class="form-floating mb-3">
							<input type="date" class="form-control" name="startDate" [ngModel]="logSearch.from | date:'yyyy-MM-dd'"
							max="9999-12-31" (change)="from = getDate($any($event.target).value)">
							<label for="startDate">From Date</label>
						</div>
						<div class="form-floating mb-3">
							<input type="date" id="toOrderDate" class="form-control" [ngModel]="logSearch.to | date:'yyyy-MM-dd'"
							max="9999-12-31" (change)="to = getDate($any($event.target).value)">
							<label for="endDate">To Date</label>
						</div>
					</div>
					<div class="col">
						<div class="form-floating mb-3">
							<input type="text" id="logger" class="form-control" [(ngModel)]="logSearch.logger"
								placeholder="Logger">
							<label for="logger">Logger</label>
						</div>
						<div class="form-floating mb-3">
							<input type="text" id="requestUrl" class="form-control" [(ngModel)]="logSearch.requestUrl"
								placeholder="Request Url">
							<label for="requestUrl">Request Url</label>
						</div>
						<div class="form-floating mb-3">
							<input type="text" id="referer" class="form-control" [(ngModel)]="logSearch.referer"
								placeholder="Referer">
							<label for="referer">Referer</label>
						</div>
					</div>
					<div class="col">
						<div class="form-floating mb-3">
							<input type="text" id="message" class="form-control" [(ngModel)]="logSearch.message"
								placeholder="Message">
							<label for="message">Message</label>
						</div>
						<div class="form-floating mb-3">
							<input type="text" id="exception" class="form-control" [(ngModel)]="logSearch.exception"
								placeholder="Exception">
							<label for="exception">Exception</label>
						</div>
					</div>
					<div class="col">
						<div class="form-group">
							<label for="levels">Levels</label>
							<select multiple id="levels" class="form-control" size="6" [(ngModel)]="logSearch.levels">
								<option value="Debug">Debug</option>
								<option value="Info">Info</option>
								<option value="Warn">Warn</option>
								<option value="Error">Error</option>
								<option value="Fatal">Fatal</option>
							</select>
						</div>
					</div>
				</div>
				<button class="btn btn-primary" (click)="search()">Search</button>
			</div>
		</div>

		<div class="d-flex justify-content-between p-2">
			<ngb-pagination [collectionSize]="total" [(page)]="logSearch.pagination!.pageNo" [maxSize]="10"
				[pageSize]="logSearch.pagination!.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
				(pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
					(change)="pageChanged()">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Choose Items per Page</label>
			</div>
		</div>

		<div class="table-responsive">
			<table class="table table-striped">
				<thead>
					<tr>
						<th width="160">DateTime</th>
						<th width="50">Level</th>
						<th>Logger</th>
						<th>RequestUrl</th>
						<th>Referer</th>
						<th>Message</th>
						<th width="80">Exception</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let logEntry of logEntries; index as i">
						<td>
							{{logEntry.dateTime | date: 'yyyy-MM-dd HH:mm:ss.SSS'}}
						</td>
						<td>
							{{logEntry.level}}
						</td>
						<td>
							{{logEntry.logger}}
						</td>
						<td>
							{{logEntry.requestUrl}}
						</td>
						<td>
							{{logEntry.referer}}
						</td>
						<td>
							{{logEntry.message}}
						</td>
						<td>
							<!-- <button class="btn btn-sm btn-primary exception"
								(click)="hideExceptions[i] = !hideExceptions[i];">View</button> -->
							<div *ngIf="logEntry.exception !== ''">
								<a class="btn btn-primary btn-sm" data-bs-toggle="offcanvas"
									[href]="'#offcanvas' + logEntry.id">View</a>
								<div class="offcanvas offcanvas-end" tabindex="-1" [id]="'offcanvas' + logEntry.id">
									<div class="form-group h-100">
										<label>Exception</label>
										<textarea rows="10" class="form-control h-75">{{logEntry.exception}}</textarea>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="d-flex justify-content-between p-2">
			<ngb-pagination [collectionSize]="total" [(page)]="logSearch.pagination!.pageNo" [maxSize]="10"
				[pageSize]="logSearch.pagination!.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
				(pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
					(change)="pageChanged()">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Choose Items per Page</label>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
