import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "services/authentication.service";

export const canActivateRoute: CanActivateFn =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		const router = inject(Router);
		const authService = inject(AuthenticationService);
		if (authService.organizationId === 1) {
			return true;
		}
		router.navigateByUrl("/");
    	return false;
    };
