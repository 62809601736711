<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="ms-auto" *ngIf="sitesService.currentSiteId !== -1 && authService.organizationId === 1">
			<p>
				<a class="btn btn-primary" [routerLink]="['add']">Add Coupon</a>
			</p>
		</div>

		<table class="table table-hover table-panel">
			<thead>
				<tr>
					<th width="20%">Coupon Code</th>
					<th width="30%">Description</th>
					<th width="10%">Start Date</th>
					<th width="10%">End Date</th>
					<th width="10%">Discount</th>
					<th width="10%">Apply To</th>
					<th width="10%"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let coupon of siteCoupons">
					<td>
						{{coupon.couponCode}}
					</td>
					<td style="word-wrap: break-word">
						{{coupon.description}}
					</td>
					<td>
						{{coupon.startDate?.toLocaleDateString()}}
					</td>
					<td>
						{{coupon.endDate?.toLocaleDateString()}}
					</td>
					<td>
						<span *ngIf="!coupon.discountPercent">{{coupon.discountAmount | currency}}</span>
						<span *ngIf="coupon.discountPercent">{{coupon.discountAmount}}%</span>
					</td>
					<td>
						{{coupon.applyDiscount?.toString()}}
					</td>
					<td>
						<ul *ngIf="isSalesAdmin" class="list-inline">
							<li class="list-inline-item"><a class="btn btn-primary btn-sm" [routerLink]="[coupon.couponId]">Edit</a></li>
							<li class="list-inline-item"><button class="btn btn-sm btn-danger" (click)="showDeleteCouponModal(coupon, deleteModal)">Delete</button>
							</li>
						</ul>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</app-spinner-with-overlay>

<ng-template #deleteModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Delete Coupon '{{ couponToDelete?.couponCode }}'</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
			(click)="closeModal()"></button>
	</div>
	<div class="modal-body">
		<p class="lead">
			Are you sure you want to delete the Coupon
			<span class="text-danger">"{{ couponToDelete?.couponCode}}"</span>?
		</p>
		<p class="lead text-danger"><strong>This operation can not be undone.</strong></p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-text" (click)="closeModal()">Cancel</button>
		<button type="button" class="btn btn-primary btn-lg" (click)="deleteCoupon()">Delete Coupon</button>
	</div>
</ng-template>
