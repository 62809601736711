<div class="container">
	<div class="row justify-content-center" *ngIf="message !== undefined || messageHeading !== undefined">
		<div class="col-md-6 col-md-offset-3 text-center message-box">
			<h2>{{messageHeading}}</h2>
			<p>{{message}}</p>
		</div>
	</div>
    <div class="row">
        <div id="loader">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="loading"></div>
        </div>
    </div>
</div>
