<div *ngIf="authService.organizationId !== 1">
	<app-pagefullheader></app-pagefullheader>
</div>
<app-spinner-with-overlay [showSpinner]="loading">
	<div *ngIf="authService.organizationId !== 1">
		<app-sites-list [startingSiteId]="siteId" (siteSelected)="changeCurrentSite($event.siteId)" [sites]="allSites"></app-sites-list>
	</div>
	<div class="card p-3 mb-3">
		<div *ngIf="sitesService.currentSiteId !== -1 && authService.organizationId === 1" class="ms-auto">
			<a href="#" class="btn btn-success btn-rounded px-4 rounded-pill" [routerLink]="['add']"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Product</a>
		</div>

		<div class="table-responsive" *ngFor="let productGroup of groupedProducts | keyvalue">
			<h5 class="m-0 p-0">{{productGroup.key}}</h5>
			<table class="table table-hover table-panel caption-top">
				<thead>
					<tr>
						<th width="10%">Base Product Id</th>
						<th width="25%">Base Product Name</th>
						<th width="25%">Product Name</th>
						<th *ngIf="languages.length > 0" width="30%">Translated Name</th>
						<th width="10%">&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let wlProduct of productGroup.value">
						<td>
							<a *ngIf="authService.organizationId === 1" [routerLink]="['/products', wlProduct.baseProductId]">{{wlProduct.baseProductId}}</a>
							<span *ngIf="authService.organizationId !== 1">{{wlProduct.baseProductId}}</span>
						</td>
						<td>
							{{wlProduct.baseProduct?.name ?? ''}}
						</td>
						<td>
							<div class="d-flex justify-content-between w-50">
								<span *ngIf="nameToEditId !== wlProduct.productId; else editName">{{wlProduct.name}}</span>
								<ng-template #editName>
									<span>
										<form [formGroup]="editProductNameForm" (submit)="openModal(editProductNameModal)">
											<div class="input-group">
												<input type="text" class="form-control" aria-label="wlProductName" formControlName="newProductName"
													aria-describedby="WL Product Name" [placeholder]="editControls.wlProduct!.value.name" />
													<button type="submit" class="btn btn-primary" [disabled]="editProductNameForm.invalid">
														<i class="fa fa-save mx-2" data-toggle="tooltip" data-placement="top"
															title="Save"></i></button>
													<button class="btn btn-warning mx-2" type="button"
														data-toggle="tooltip" data-placement="top" title="Cancel" (click)="cancelEdit()"><i
															class="fa fa-times"></i></button>
											</div>
										</form>
									</span>
								</ng-template>
								<button *ngIf="nameToEditId !== wlProduct.productId" class="btn btn-sm btn-success" title="Edit Product Name"
								(click)="initiateEditProductName(wlProduct)" [disabled]="disableEditName">
									<i class="fa fa-edit"></i>
								</button>
							</div>
						</td>
						<td *ngIf="languages.length > 0">
							<app-wlproduct-languages [siteId]="siteId" [baseProductId]="wlProduct.baseProductId" [languagesList]="wlProductTranslations"></app-wlproduct-languages>
						</td>
						<td>
							<ul class="list-inline m-0 p-0">
								<li *ngIf="authService.organizationId === 1" class="list-inline-item"><a [routerLink]="[wlProduct.baseProductId]" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></a></li>
								<li *ngIf="authService.organizationId !== 1" class="list-inline-item"><a [routerLink]="['/siteproducts', wlProduct.baseProductId]" [state]="{ siteId: siteId }" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></a></li>
								<li class="list-inline-item"><button class="btn btn-danger btn-sm" [disabled]="authService.organizationId !== 1" (click)="setupDelete(wlProduct, deleteModal)"><i
									class="fa fa-trash"></i></button></li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</app-spinner-with-overlay>

<ng-template #deleteModal>
	<app-confirm-modal title="Delete confirmation" confirmText="delete" (confirmAction)="deleteWlProduct()"
		(closeModal)="closeModal()">
		<p>Are you sure you want to delete WLProduct - <strong>{{wlProductToDelete.name}}({{wlProductToDelete.productId}})</strong> ?</p>
	</app-confirm-modal>
</ng-template>

<ng-template #editProductNameModal>
	<app-confirm-modal title="Edit WL Product Name" confirmText="update" (confirmAction)="editWLProductName()"
		(closeModal)="closeModal()">
		<p>Please review changes below before updating </p>
		<strong>
			{{editControls.wlProduct!.value.name}} => {{editControls.newProductName.value}}
		</strong>
	</app-confirm-modal>
</ng-template>
