<app-product-info [productId]="productId"></app-product-info>

<div class="card p-3">
	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="table-responsive">
			<table class="table table-hover table-responsive">
				<thead>
					<tr>
						<th width="10%">Minimum Quantity</th>
						<th width="10%">Maximum Quantity</th>
						<th width="10%">Price/Thousand</th>
						<th width="10%">MOQ<br />(order by amount)</th>
						<th width="10%">Shipping Method</th>
						<th width="10%">Production Method</th>
						<th width="10%">Standard Postage</th>
						<th width="10%">First Class Postage</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let tier of productTiers">
						<td data-title="Minimum Quantity">
							<div class="form-floating">
								<input type="number" id="minQty" required min="1" max="999999999" class="form-control"
									[(ngModel)]="tier.minQty" #minQty="ngModel"
									[ngClass]="{ 'is-invalid': minQty.touched && !minQty.valid }">
								<label><small>Quantity</small></label>
							</div>
						</td>
						<td data-title="Maximum Quantity">
							<div class="form-floating">
								<input type="number" id="maxQty" required min="1" max="999999999" class="form-control"
									[(ngModel)]="tier.maxQty" #maxQty="ngModel"
									[ngClass]="{ 'is-invalid': maxQty.touched && (!maxQty.valid || (maxQty.value < minQty.value))}">
								<label><small>Maximum Quantity</small></label>
							</div>
						</td>
						<td data-title="Price/Thousand">
							<div class="form-floating">
								<input type="number" id="pricePerThousand" required min="0" class="form-control"
									[(ngModel)]="tier.pricePerThousand" #pricePerThousand="ngModel"
									[ngClass]="{ 'is-invalid': pricePerThousand.touched && pricePerThousand.value <=0 }">
								<label><small>Price/Thousand</small></label>
							</div>
						</td>
						<td data-title="MOQ">
							<div class="form-floating">
								<input type="number" id="moq" min="1" max="9999" class="form-control"
									[(ngModel)]="tier.moq">
								<label><small>MOQ</small></label>
							</div>
						</td>
						<td data-title="Shipping Method">
							<div class="form-floating">
								<select type="select" class="form-select" [(ngModel)]="tier.shipMethodId">
									<option value="1">Flat Rate</option>
									<option value="2">FedEx</option>
								</select>
								<label><small>Shipping Method</small></label>
							</div>
						</td>
						<td data-title="Production Method">
							<div class="form-floating">
								<select type="select" class="form-select" [(ngModel)]="tier.printMethodId">
									<option *ngFor="let x of printMethods" [ngValue]="x.printMethodId">
										{{x.name!}}
									</option>
								</select>
								<label><small>Production Method</small></label>
							</div>
						</td>
						<td data-title="Standard Postage">
							<div class="form-floating">
								<input type="number" id="standardPostageFee" min="0" class="form-control"
									[(ngModel)]="tier.standardPostageFee">
								<label><small>Standard Postage</small></label>
							</div>
						</td>
						<td data-title="First Class Postage">
							<div class="form-floating">
								<input type="number" id="firstClassPostageFee" min="0" class="form-control"
									[(ngModel)]="tier.firstClassPostageFee">
								<label><small>First Class Postage</small></label>
							</div>
						</td>
						<td data-title="Tier Options">
							<ul class="list-inline">
								<li class="list-inline-item"><button class="btn btn-primary"
										(click)="updateTier(tier)">Save</button></li>
								<li class="list-inline-item"><button class="btn btn-danger"
										(click)="removeTier(tier)">Delete</button></li>
							</ul>
						</td>
					</tr>
					<tr>
						<td data-title="Minimum Quantity">
							<div class="form-floating">
								<input type="number" id="newMinQty" required min="1" max="999999999" class="form-control"
									[(ngModel)]="newProductTier.minQty" #newMinQty="ngModel"
									[ngClass]="{ 'is-invalid': submitted && !newMinQty.valid }">
								<label><small>Minimum Quantity</small></label>
							</div>
						</td>
						<td data-title="Maximum Quantity">
							<div class="form-floating">
								<input type="number" id="maxQty" required min="1" class="form-control"
									[(ngModel)]="newProductTier.maxQty" #newMaxQty="ngModel"
									[ngClass]="{ 'is-invalid': submitted && (!newMaxQty.valid || (newMaxQty.value < newMinQty.value)) }">
								<label><small>Maximum Quantity</small></label>
							</div>
						</td>
						<td data-title="Price/Thousand">
							<div class="form-floating">
								<input type="number" id="newPPP" required min="0" class="form-control"
									[(ngModel)]="newProductTier.pricePerThousand"  #newPPP="ngModel"
									[ngClass]="{ 'is-invalid': submitted && !newPPP.valid }">
								<label><small>Price/Thousand</small></label>
							</div>
						</td>
						<td data-title="MOQ">
							<div class="form-floating">
								<input type="number" id="moq" min="1" class="form-control" [(ngModel)]="newProductTier.moq">
								<label><small>MOQ</small></label>
							</div>
						</td>
						<td data-title="Shipping Method">
							<div class="form-floating">
								<select type="select" class="form-select" [(ngModel)]="newProductTier.shipMethodId">
									<option value="1">Flat Rate</option>
									<option value="2">FedEx</option>
								</select>
								<label><small>Shipping Method</small></label>
							</div>
						</td>
						<td data-title="Production Method">
							<div class="form-floating">
								<select type="select" class="form-select" [(ngModel)]="newProductTier.printMethodId">
									<option *ngFor="let x of printMethods" value="{{x.printMethodId}}">
										{{x.name!}}
									</option>
								</select>
								<label><small>Production Method</small></label>
							</div>
						</td>
						<td data-title="Standard Postage">
							<div class="form-floating">
								<input type="number" id="standardPostageFee" min="0" class="form-control"
									[(ngModel)]="newProductTier.standardPostageFee">
								<label><small>Standard Postage</small></label>
							</div>
						</td>
						<td data-title="First Class Postage">
							<div class="form-floating">
								<input type="number" id="firstClassPostageFee" min="0" class="form-control"
									[(ngModel)]="newProductTier.firstClassPostageFee">
								<label><small>First Class Postage</small></label>
							</div>
						</td>
						<td data-title="">
							<button class="btn btn-primary" (click)="addTier()">Add Tier</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<p>
			<button class="btn btn-primary" (click)="updateAllPricing()">Save All Changes</button>
		</p>
	</app-spinner-with-overlay>
</div>
