import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Coupon } from '@taradel/web-api-client';
import { AuthenticationService } from 'services/authentication.service';
import { CouponService } from 'services/coupon.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-coupons',
	templateUrl: './coupons.component.html',
	styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {
	siteId= - 1;
	siteCoupons: Coupon[] = [];
	loading = false;
	couponToDelete?: Coupon;
	isSalesAdmin = false;
	constructor(
		public authService: AuthenticationService,
		private route: ActivatedRoute,
		public couponService: CouponService,
		public sitesService: SitesService,
		private modalService: NgbModal,
		private toastService: ToastService
	) {
		this.siteId = parseInt(route.snapshot.paramMap.get('siteId') ?? '-1', 10);
	}

	async ngOnInit() {
		this.isSalesAdmin = await this.authService.hasRole('SalesAdmin');
		await this.sitesService.setCurrentSiteId(this.siteId);
		this.sitesService.currentSite.subscribe(site => {
			if (site) {
				this.getSiteCoupons(site.siteId!);
			}
			else {
				this.siteCoupons = [];
			}
		});
	}

	async getSiteCoupons(siteId: number) {
		try {
			this.loading = true;
			this.siteCoupons = await this.couponService.getSiteCoupons(siteId);
		}
		catch {
			this.toastService.showError('There was an error getting the site coupons');
		}
		finally {
			this.loading = false;
		}
	}
	async deleteCoupon() {
		try {
			this.closeModal();
			this.loading = true;
			this.siteCoupons = this.siteCoupons.filter(x => x.couponId !== this.couponToDelete?.couponId);
			await this.couponService.deleteCoupon(this.couponToDelete?.couponId!);
			this.toastService.showSuccess('Coupon deleted successfully');
		}
		catch {
			this.toastService.showError('There was an error deleting the coupon');
		}
		finally {
			this.loading = false;
		}
	}

	showDeleteCouponModal(coupon: Coupon, deleteModal: any) {
		this.couponToDelete = coupon;
		this.modalService.open(deleteModal);
	}

	closeModal() {
		this.modalService.dismissAll();
	}

}
