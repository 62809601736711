<app-spinner-with-overlay [showSpinner]='loading'>
	<div class="card p-3 mb-3">
		<div class="ms-auto mb-3">
			<button class="btn btn-success btn-rounded px-4 rounded-pill"
					(click)="exportSiteSettings()">
					<i class="fa-lg me-2 ms-n2 text-success-900"></i> Export Settings
			</button>
			<input hidden type="file" #fileInput class="form-control" accept=".json" (click)="fileInput.value = ''" (change)="handleFileInput($event.target)">
			<button class="btn btn-success btn-rounded px-4 ms-2 rounded-pill"
					(click)="fileInput.click()">
					<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Import Settings
			</button>
			<button class="btn btn-success btn-rounded px-4 ms-2 rounded-pill"
					*ngIf="siteId === 0"
					(click)="toggleAddNewConfigWindow()">
					<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add New Setting
			</button>
		</div>
		<div *ngIf="showAddNewSetting">
			<form (ngSubmit)="submitNewConfig()" #newConfigForm="ngForm">
				<div class="row mb-3">
					<div class="col-md-4">
						<div class="form-floating">
							<input name="configCat"
								id="configCat"
								type="text"
								class="form-control"
								placeholder="Search for or input a configuration category"
								required
								(blur)="buildConfigNames()"
								(focus)="catFocus$.next($any($event).target.value)"
								(click)="catClick$.next($any($event).target.value)"
								[(ngModel)]="addNewConfigModel.configCat"
								[ngbTypeahead]="lookupConfigCat"
								#configCat="ngbTypeahead" />
							<label for="configCat">Search for or input a configuration category</label>
						</div>
						<p>
							<small></small>
						</p>
					</div>
					<div class="col-md-4">
						<div class="form-floating">
							<input name="configName"
							id="configName"
							type="text"
							class="form-control"
							placeholder="Configuration Name"
							required
							(focus)="nameFocus$.next($any($event).target.value)"
							(click)="nameClick$.next($any($event).target.value)"
							[(ngModel)]="addNewConfigModel.configName"
							[ngbTypeahead]="lookupConfigName"
							#configName="ngbTypeahead" />
							<label for="configName">Configuration Name</label>
						</div>
					</div>
					<div class="col-md-4">
						<div class="form-floating">
							<input type="text" class="form-control" id="configVal" name="configVal" #configVal="ngModel"
								required [(ngModel)]="addNewConfigModel.configVal" placeholder="Configuration Value">
							<label for="configVal">Configuration Value</label>
						</div>
						<div [hidden]="configVal.valid ||configVal.pristine" class="alert alert-danger"> ConfigVal is
							required</div>
					</div>
				</div>
				<p>
					<button type="submit" class="btn btn-primary btn-lg" [disabled]="!newConfigForm.valid">Save New
						Configuration</button>
					<button type="reset" class="btn btn-link" (click)="toggleAddNewConfigWindow()">Cancel</button>
				</p>
			</form>
		</div>
			<div class="input-group mb-3" *ngIf="!showAddNewSetting">
				<label class="input-group-text">Filter Settings</label>
				<select type="select" class="form-select" id="dd1" [(ngModel)]="searchConfigCat"
					(change)="buildSearchConfigNames();filterSettings()">
					<option value="">By Category</option>
					<option *ngFor="let x of configCats | sort" value="{{x}}">
						{{x}}
					</option>
				</select>
				<select type="select" class="form-select" [(ngModel)]="searchConfigName" (change)="filterSettings()">
					<option value="">By Name</option>
					<option *ngFor="let x of searchConfigNames" value="{{x}}">
						{{x}}
					</option>
				</select>
				<button type="reset" class="btn btn-default px-4 col-lg-1" (click)="resetForm()">
					<i class="fas fa-undo fa-lg me-2 ms-n2"></i>Reset Filter
				</button>
			</div>

		<div class="table-responsive" *ngIf="filteredSettings.length > 0 && !showAddNewSetting">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Config Cat</th>
						<th>Config Name</th>
						<th width="25%">Default Value</th>
						<th width="25%">Site Value</th>
						<th width="15%">&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let eachConfig of filteredSettings; let i=index; trackBy: trackRow;" class="settingRow">
						<td>{{ eachConfig.configCat }}</td>
						<td>{{ eachConfig.configName }}</td>
						<td><span class="text-muted">{{ eachConfig.defaultConfigVal }}</span></td>
						<td>
							<span *ngIf="inputBoxToShow !== eachConfig.configName">{{ eachConfig.configVal }}</span>
							<form (submit)="showEditSettingsModal($event, eachConfig, editContent)"
								*ngIf="inputBoxToShow === eachConfig.configName">
								<div class="input-group">
									<input type="text" class="form-control" aria-label="ConfigVal"
										aria-describedby="CMS Value" [placeholder]="eachConfig.configVal">
									<button type="submit" (ngModel)="eachConfig" class="btn btn-primary">
										<i class="fa fa-save mx-2" data-toggle="tooltip" data-placement="top"
											title="Save"></i></button>
									<button (ngModel)="eachConfig" class="btn btn-warning mx-2" type="button"
										data-toggle="tooltip" data-placement="top" title="Cancel" (click)="cancelEdit()"><i
											class="fa fa-times"></i></button>
								</div>
							</form>
						</td>
						<td>
							<ul class="list-inline p-0 m-0" [style.visibility]="hideEditButtons ? 'hidden' : 'visible'">
								<li class="list-inline-item">
									<a [routerLink]="['/sites/config/', eachConfig.configCat, eachConfig.configName]"
										(ngModel)="eachConfig" class="btn btn-default btn-sm" data-toggle="tooltip"
										data-placement="top" title="View All Site Settings"><i class="fa fa-eye"></i></a>
								</li>
								<li class="list-inline-item">
									<button (ngModel)="eachConfig" button class="btn btn-info btn-sm" type="button"
										data-toggle="tooltip" data-placement="top" title="Edit"
										(click)="editSetting(eachConfig)"><i class="fa fa-edit"></i></button>
								</li>
								<li class="list-inline-item" *ngIf="eachConfig.configVal">
									<button (ngModel)="eachConfig" class="btn btn-danger btn-sm" type="button"
										data-toggle="tooltip" data-placement="top" title="Delete"
										(click)="showDeleteSettingModal(eachConfig, deleteContent)"><i
											class="fa fa-trash"></i></button>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</app-spinner-with-overlay>

<!-- Delete Modal -->
<ng-template #deleteContent let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Delete Setting '{{ settingToDelete }}'</h4>
		<button type="button" class="btn-close" aria-describedby="modal-title"
			(click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">Are you sure you want to delete the setting <span class="text-danger">"{{ settingToDelete
				}}"</span> from site {{ siteId }}?</p>
		<p class="lead text-danger"><strong>This operation can not be undone.</strong></p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-text" (click)="modal.dismiss('cancel click')">Cancel</button>
		<button type="button" class="btn btn-primary btn-lg" (click)="modal.close('deleteSetting')">Delete
			Setting</button>
	</div>
</ng-template>
<!-- End Delete Modal -->

<!-- Edit Modal -->
<ng-template #editContent let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit Setting '{{ settingToEdit }}'</h4>
		<button type="button" class="close" aria-describedby="modal-title"
			(click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<p class="lead">Are you sure you want to change the setting <span class="text-primary">"{{ settingToEdit
				}}"</span> from {{ oldSettingValue }} to {{ newSettingValue }} on site {{ siteId }}?</p>
		<p class="lead text-danger">This operation can not be undone.</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-link" (click)="modal.dismiss('cancelEditSetting')">Cancel</button>
		<button type="button" class="btn btn-primary btn-lg" (click)="modal.close('editSetting')">Save Setting</button>
	</div>
</ng-template>
<!-- End Edit Modal -->
