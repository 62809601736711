<div class="btn-group">
	<a routerLink="/sites/{{siteId}}/dashboard" class="btn btn-primary">Site Options</a>
	<button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
		<span class="visually-hidden">Toggle Dropdown</span>
	</button>
	<ul class="dropdown-menu" role="menu">
		<li role="menuitem"><a routerLink="/sites/{{siteId}}/settings/" class="dropdown-item">Settings</a></li>
		<li role="menuitem"><a routerLink="/sites/{{siteId}}/urls/" class="dropdown-item">Urls</a></li>
		<li role="menuitem"><a routerLink="/sites/{{siteId}}/details/" class="dropdown-item">Details</a></li>
		<li role="menuitem"><a routerLink="/sites/{{siteId}}/content/" class="dropdown-item">Content</a></li>
		<li role="menuitem"><a routerLink="/sites/{{siteId}}/products" class="dropdown-item">Products</a></li>
		<li role="menuitem"><a routerLink="/sites/{{siteId}}/coupons" class="dropdown-item">Coupons</a></li>
		<li role="menuitem"><a routerLink="/sites/{{siteId}}/update-postage" class="dropdown-item">Update Postage</a></li>
		<li role="menuitem"><a routerLink="/sites/{{siteId}}/uselect-addons" class="dropdown-item">USelect Add Ons</a></li>
		<li role="menuitem"><a routerLink="/sites/{{siteId}}/uselect-designs" class="dropdown-item">USelect Designs</a></li>
		<li role="menuitem"><a routerLink="/sites/{{siteId}}/uselect-power-ups" class="dropdown-item">Power Ups</a></li>
	</ul>
</div>
