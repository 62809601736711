<app-spinner-with-overlay [showSpinner]="orderLayoutService.loadingDist || loading">
	<div class="row mb-3" *ngIf="orderLayoutService.selectedOrderItemAsCartProduct && !isDigital() && orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId !== USelect.printAndShip">
		<div class="col-12">
			<div class="card">
				<div *ngIf="!(orderLayoutService.selectedOrderItemAsCartProduct.drops?.returnAddressId || orderLayoutService.selectedOrderItemAsCartProduct.drops?.returnAddressId === 0)" class="card-body">
					<h5 class="card-title">Return Address</h5>
					<div class="form-floating mb-3">
						<input type="text" class="form-control"
							[(ngModel)]="orderLayoutService.selectedOrderItemAsCartProduct.drops!.returnCompany"
							placeholder="Company Name" />
						<label>Company Name</label>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="form-floating mb-3">
								<input type="text" class="form-control"
									[(ngModel)]="orderLayoutService.selectedOrderItemAsCartProduct.drops!.returnAddress1"
									placeholder="Address 1" />
								<label>Address 1</label>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-floating mb-3">
								<input type="text" class="form-control"
									[(ngModel)]="orderLayoutService.selectedOrderItemAsCartProduct.drops!.returnAddress2"
									placeholder="Address 2" />
								<label>Address 2</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="text" class="form-control"
									[(ngModel)]="orderLayoutService.selectedOrderItemAsCartProduct.drops!.returnCity"
									placeholder="City" />
								<label>City</label>
							</div>
						</div>
						<div class="col-md-3">
							<app-state-list [state]="orderLayoutService.selectedOrderItemAsCartProduct.drops!.returnState!" [isUS]="countryCode === 'US'"
								(selected)="stateSelected($event)">
							</app-state-list>
						</div>
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="text" class="form-control"
									[(ngModel)]="orderLayoutService.selectedOrderItemAsCartProduct.drops!.returnZip"
									placeholder="Zip/Postal Code" maxlength="6" />
								<label>Zip/Postal Code</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="text" class="form-control" [disabled]="authService.organizationId !== 1"
								placeholder="Postal CRID" />
								<label>Postal CRID</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="text" class="form-control" placeholder="Mailing Printer" readonly
									disabled [ngModel]="getMailingPrinter()"/>
								<label>Mailing Printer</label>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-floating mb-6">
								<input type="text" class="form-control" [disabled]="authService.organizationId !== 1"
									[(ngModel)]="orderLayoutService.selectedOrderItemAsCartProduct!.jobName"
									placeholder="Job Name" />
								<label>Job Name</label>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="!orderLayoutService.loadingOrder && !orderLayoutService.loadingOrderItem && (orderLayoutService.selectedOrderItemAsCartProduct.drops?.returnAddressId || orderLayoutService.selectedOrderItemAsCartProduct.drops?.returnAddressId === 0)" class="card-body">
					<div class="row">
						<div class="col-6">
							<app-customer-addresses [submitted]="submitted" [addressType]="'Return'"
								[customerId]="orderLayoutService.selectedCustomer?.customerID!"
							[cartAddressId]="orderLayoutService.selectedOrderItemAsCartProduct.drops?.returnAddressId"
							(addressSelected)="setReturnAddress($event)">
							</app-customer-addresses>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="text" class="form-control" [disabled]="authService.organizationId !== 1"
								placeholder="Postal CRID" />
								<label>Postal CRID</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="text" class="form-control" placeholder="Mailing Printer" readonly
									disabled [ngModel]="getMailingPrinter()"/>
								<label>Mailing Printer</label>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-floating mb-6">
								<input type="text" class="form-control" [disabled]="authService.organizationId !== 1"
									[(ngModel)]="orderLayoutService.selectedOrderItemAsCartProduct!.jobName"
									placeholder="Job Name" />
								<label>Job Name</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="(isEddmDrops() || isSnapDrops()) && authService.organizationId === 1">
		<button class="btn btn-primary btn-sm ms-auto m-1" (click)="modalService.open(changeNumberOfDrops)">Split</button>
	</div>
	<div *ngIf="!orderLayoutService.loadingDist">
		<app-eddm-delivery-drops *ngIf="isEddmDrops()"></app-eddm-delivery-drops>
		<app-eddm-delivery-impressions *ngIf="isEddmImpressions()"></app-eddm-delivery-impressions>
		<app-snap-delivery-drops *ngIf="isSnapDrops()"></app-snap-delivery-drops>
		<app-snap-delivery-impressions *ngIf="isSnapImpressions()"></app-snap-delivery-impressions>
		<app-addressed-mail-delivery-drops *ngIf="isPersonalizedMail()"></app-addressed-mail-delivery-drops>
		<div class="row mb-3">
			<div class="col-4">
				<div *ngIf="orderLayoutService.selectedOrderItemAsCartProduct!.uSelectId === USelect.displayAds && urlQuery" class="mb-3">
					<div class="card">
						<div class="card-header">
							<strong>Click Through URL</strong>
						</div>
						<div class="card-body">
							<div class="form-floating">
								<input type="text" name="url" [(ngModel)]="urlQuery.url" class="form-control" placeholder="URL" [disabled]="authService.organizationId !== 1">
								<label for="url">URL</label>
							</div>
							<button type="button" class="btn btn-primary mt-2" (click)="updateURL()" [disabled]="urlQuery.url === null || authService.organizationId !== 1">
								Update URL
							</button>
						</div>
					</div>
				</div>
				<div *ngIf="orderLayoutService.selectedOrderItemAsCartProduct!.uSelectId === USelect.qrCode && !!qrCodeMetaInfo" class="mb-3">
					<div class="card">
						<div class="card-header">
							<strong>QR Code {{ qrCodePlaceholder }}</strong>
						</div>
						<div class="card-body">
							<div *ngIf="qrCodeMetaInfo.qrCodeMetaType === QRCodePhoneNumber; else webLinkTemplate">
								<div class="form-floating">
									<input type="text" class="form-control" name="qrCode" [(ngModel)]="qrCodeValue"  [minlength]="14" [maxlength]="14" (keyup)="formatPhoneNumber(qrCodeValue!)" placeholder="{{ qrCodePlaceholder }}" [disabled]="authService.organizationId !== 1" [ngClass]="{ 'is-invalid': qrCodeInvalidMessage.length > 0 }" />
									<label for="qrCode">{{ qrCodePlaceholder }}</label>
								</div>
							</div>
							<ng-template #webLinkTemplate>
								<div class="form-floating">
									<input type="text" name="qrCode" [(ngModel)]="qrCodeValue" pattern="(http(s)?:\/\/)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$" class="form-control" placeholder="{{ qrCodePlaceholder }}" [disabled]="authService.organizationId !== 1" [ngClass]="{ 'is-invalid': qrCodeInvalidMessage.length > 0 }" />
									<label for="qrCode">{{ qrCodePlaceholder }}</label>
								</div>
							</ng-template>
							<button type="button" class="btn btn-primary mt-2" (click)="updateQRCodeOrderMeta()" [disabled]="qrCodeValue === '' || authService.organizationId !== 1">
								Update
							</button>
							<ng-container *ngIf="qrCodeInvalidMessage.length > 0">
								<p class="input-error">
									{{ qrCodeInvalidMessage }}
								</p>
							</ng-container>
						</div>
					</div>
				</div>
				<app-digital-delivery-drops *ngIf="isDigital()"></app-digital-delivery-drops>
				<app-pns-delivery-drops *ngIf="orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId === USelect.printAndShip"></app-pns-delivery-drops>
			</div>
			<div class="col-8">
				<app-digital-delivery *ngIf="isDigital() && orderLayoutService.selectedOrderItemAsCartProduct?.uSelectId !== USelect.qrCode" [digitalType]="digitalType"></app-digital-delivery>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
<ng-template #changeNumberOfDrops let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Change Number of Drops</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">

		<p>Adding drops will auto distribute the routes betweeen the drops.</p>
		<p>You should not change the drops after job has started dropping.</p>
		<div class="row">
			<div class="col-md-4">
				<label>Number of Drops</label>
				<select type="select" class="form-select" [(ngModel)]="numberOfDrops">
					<option  *ngFor="let item of [].constructor(orderLayoutService.numberOfDropsAllowedForDistribution); let i = index" value="{{i+1}}">
						{{i+1}}
					</option>
				</select>
			</div>
			<div class="col-md-4">
				<label>Frequency of Drops</label>
				<select type="select" class="form-select" [(ngModel)]="weekBetweenDrops">
					<option  *ngFor="let item of [].constructor(4); let i = index" value="{{i+1}}">
						{{i+1}}
					</option>
				</select>
			</div>
			<div class="col-md-4"></div>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link" (click)="modal.dismiss('Cancel Click')">Cancel</button>
		<button class="btn btn-primary" (click)="splitDrops()">Apply Number of Drops Change</button>
	</div>
</ng-template>
