import { environment } from "environment";
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function zipCodeValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if ((control.value?.length ?? 0) === 0) {
			return null;
		}
		const countryCode = environment.instance;
		let pattern = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$');
		if (countryCode === 'Canada') {
			pattern = new RegExp('^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy][0-9][ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz] ?[0-9][ABCEGHJKLMNPRSTVWXYZabceghjklmnprstwxyz][0-9]$');
		}
		if (!!control.value && control.value !== '') {
			return !pattern.test(control.value) ? { invalidZipCode: true } : null;
		}
		return { invalidZipCode: true };
	};
}
