import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Printer } from '@taradel/web-api-client';
import { SortPipe } from 'components/shared/sort/sort.pipe';

@Component({
  selector: 'app-printers-list',
  templateUrl: './printers-list.component.html',
  styleUrls: ['./printers-list.component.scss']
})
export class PrintersListComponent implements OnInit {
	@Input() list?: Printer[];

	@Output() itemsSelected = new EventEmitter<any[]>();

	listOfItems?: Printer[];
	selectedItems: Printer[] = [];
	listOfSelectedItems: Printer[] = [];
	selectedItemIds: number[] = [];
	filterKey = '';

	constructor(private sortPipe: SortPipe) {}

	ngOnInit(): void {
		this.listOfItems = this.list;
	}

	setUnSelectedList(selectedIds: number[]) {
		let unselected = Array.from(this.list!);
		this.list!.forEach(x => {
			if (selectedIds.includes(x.printerId)) {
				const index = unselected.indexOf(x);
				unselected.splice(index, 1);
			}
		});

		this.listOfItems = unselected;
	}

	filterItems() {
		this.setUnSelectedList(this.listOfSelectedItems.map(x => x.printerId));
		this.listOfItems = this.listOfItems!.filter(x => x.companyName?.includes(this.filterKey));
	}

	selectItemsFromList(): void {
		this.listOfSelectedItems.push(...this.selectedItems);
		this.listOfSelectedItems = this.sortPipe.transform(this.listOfSelectedItems, 'asc', 'companyName');
		this.listOfItems = this.listOfItems!.filter(p => this.selectedItems.findIndex(x => x.printerId === p.printerId) === -1);
		this.listOfItems = this.sortPipe.transform(this.listOfItems, 'asc', 'companyName');
		this.handleEmittingSelectedItems();
	}

	removeItemsFromList(): void {
		this.listOfItems!.push(...this.selectedItems);
		this.listOfItems = this.sortPipe.transform(this.listOfItems!, 'asc', 'companyName');
		this.listOfSelectedItems = this.listOfSelectedItems.filter(p => this.selectedItems.findIndex(x => x.printerId === p.printerId) === -1);
		this.listOfSelectedItems = this.sortPipe.transform(this.listOfSelectedItems, 'asc', 'companyName');
		this.handleEmittingSelectedItems();
	}

	handleEmittingSelectedItems(): void {
		const listToBeEmitted = this.list?.filter(l => this.listOfSelectedItems.find(i => i.printerId === l.printerId)) ?? [];
		this.itemsSelected.emit(listToBeEmitted);
	}

	resetComponent() {
		this.selectedItems = [];
		this.listOfSelectedItems = [];
		this.selectedItemIds = [];
		this.filterKey = '';
		this.listOfItems = this.list;
	}
}
