<div class="card my-2">
	<div *ngIf="!edit && !orderLayoutService.loadingOrder &&
			!orderLayoutService.loadingOrderItem &&
			orderLayoutService.eligiblePrinterResponse" class="card-body">
		<h5 class="card-title">Round Robin</h5>
		<div class="card-text">
			{{orderLayoutService.eligiblePrinterResponse?.infoMessage}}
		</div>
		<div *ngIf="orderLayoutService.eligiblePrinterResponse &&
				orderLayoutService.eligiblePrinterResponse.eligiblePrinters &&
				orderLayoutService.eligiblePrinterResponse.eligiblePrinters.length > 0" class="table-responsive">
			<table class="table table-hover caption-top">
				<caption>Eligible Round Robin Printers</caption>
				<thead>
					<tr>
						<th>Vendor Name</th>
						<th>Sent</th>
						<th>Last Order Date</th>
						<th>Suggested Printer</th>
					</tr>
				</thead>
				<tbody>
					<tr
						*ngFor="let eligiblePrinter of orderLayoutService.eligiblePrinterResponse.eligiblePrinters; let i=index">
						<td>
							{{eligiblePrinter.vendor}}
						</td>
						<td>
							{{eligiblePrinter.sent}}
						</td>
						<td>
							{{eligiblePrinter.lastOrder?.toLocaleString()}}
						</td>
						<td>
							{{eligiblePrinter.suggestedPrinter}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="card-body" *ngIf="orderLayoutService.eligiblePrinterResponse">
		<div class="form-check form-check-inline">
			<input class="form-check-input" type="radio" value="taradel" id="taradelPrinters" name="printerType"
				[(ngModel)]="printerType" (click)="printerTypeSelected('taradel')" [disabled]="!overrideAllowed">
			<label class="form-check-label" for="taradelPrinters">Taradel</label>
		</div>
		<div class="form-check form-check-inline">
			<input class="form-check-input" type="radio" value="nonTaradel" [(ngModel)]="printerType"
				id="nonTaradelPrinters" name="printerType" (click)="printerTypeSelected('nonTaradel')" [disabled]="!overrideAllowed">
			<label class="form-check-label" for="nonTaradelPrinters">Affiliate</label>
		</div>

			<button class="btn btn-xs btn-primary" *ngIf="!overrideAllowed" (click)="overrideAffiliate()">Override
				Affiliate</button>
			<button class="btn btn-xs btn-primary" *ngIf="overrideAllowed" (click)="cancelOverride()">Cancel</button>
	</div>

</div>
