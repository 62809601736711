import { Component, OnInit } from '@angular/core';
import { AttributionReportQuery, CompanyAttribution } from '@taradel/admin-api-client';
import { ColDef, ColGroupDef, SideBarDef, SizeColumnsToContentStrategy } from 'ag-grid-enterprise';
import moment from 'moment';
import { ReportsService } from 'services/reports.service';
import { ToastService } from 'services/toast.service';


interface CompanyAttributionComputedColumns {
	dayOfWeek: string
	isWeekend: boolean
	weekOfYear: number
}

type CompanyAttributionComputed = CompanyAttribution & CompanyAttributionComputedColumns

@Component({
  selector: 'app-attribution-report',
  templateUrl: './attribution-report.component.html',
  styleUrls: ['./attribution-report.component.scss'],
})
export class AttributionReportComponent implements OnInit {
	loading = false;
	paramStartDate: Date = new Date();
	paramEndDate: Date = new Date();
	paramPartialCompanyName: string = '';
	apiQuery: AttributionReportQuery = new AttributionReportQuery();

	agThemeClass = 'ag-theme-quartz';

	rowData: CompanyAttributionComputed[] = [];

	/* ag-grid configuration */
	agDefaultColDef: any = {
		flex: 1,
		allowedAggFuncs: ['sum', 'avg', 'max', 'min', 'count'],
	};
	agColumnTypes = {
		currency: {
			valueFormatter: currencyFormatter,
		},
	};
	agColumnDefs: ColDef[] | ColGroupDef[] | any = [
		{ field: 'orderId', enableRowGroup: true, filter: true, hide: false },
		{ field: 'customerId', enableRowGroup: true, hide: true },
		{ field: 'company', filter: true, enableRowGroup: true, hide: false },
		{ field: 'created', enableRowGroup: true, hide: false },
		{ field: 'dayOfWeek', headerName: 'Day', enableRowGroup: true, filter: true, hide: false },
		{ field: 'weekOfYear', headerName: 'Week', enableRowGroup: true, filter: true, hide: false },
		{ field: 'isWeekend', enableRowGroup: true, filter: true, hide: true },
		{ field: 'region', headerName: 'Region (WIP)', enableRowGroup: true, filter: true, hide: true },
		{ field: 'vertical', headerName: 'Vertical (WIP)', enableRowGroup: true, filter: true, hide: true },

		// metric fields
		{ field: 'mailDrops', headerName: 'Total Mail Drops', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalMailImpressions', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalCalls', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalSeconds', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalEmailImpressions', type: 'numericColumn', aggFunc: 'avg' },
		{ field: 'totalEmailDelivered', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalEmailClicks', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalEmailOpens', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalGoogleImpressions', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalGoogleClicks', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalFacebookImpressions', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalFacebookClicks', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalFacebookEngagement', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalQRCodeNotifications', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalQRCodeIMpressions', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalQRCodeCtr', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalInstagramImpressions', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalInstagramClicks', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalInstagramEngagement', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalSpotifyImpressions', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalSpotifyClicks', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalHuluReach', type: 'numericColumn', aggFunc: 'sum' },
		{ field: 'totalHuluImpressions', type: 'numericColumn', aggFunc: 'sum' },
	];
	agAutoGroupColumnDef = {
		headerName: 'Group By',
		minWidth: 240,
	};
	agSideBar: SideBarDef = {
		toolPanels: ["columns", "filters"],
		defaultToolPanel: "columns",
		hiddenByDefault: false,
		position: "right",
	};
	agColumnsToolPanel = {
		suppressRowGroups: true,
	};
	agStatusBar = {
		statusPanels: [
			{ statusPanel: 'agTotalRowCountComponent' },
			{ statusPanel: 'agFilteredRowCountComponent' },
		]
	};
	agAutoSizeStrategy: SizeColumnsToContentStrategy = {
		type: 'fitCellContents',
		skipHeader: false,
		colIds: undefined,
	};
	/* end ag-grid */

	constructor(
		private reportsApiService: ReportsService,
		private toastService: ToastService,
	) {}

	ngOnInit(): void {
		this.rowData.splice(0, this.rowData.length);
		this.paramStartDate = new Date(this.paramStartDate.getFullYear(), this.paramStartDate.getMonth(), 1);
		this.paramEndDate = new Date(this.paramEndDate.getFullYear(), this.paramEndDate.getMonth() + 1, 0);
		this.apiQuery.startDate = this.paramStartDate;
		this.apiQuery.endDate = this.paramEndDate;
	}

	async getReport(endpoint: (query: AttributionReportQuery) => Promise<void | CompanyAttribution[]>): Promise<void | CompanyAttribution[]> {
		try {
			this.loading = true;
			this.apiQuery.startDate = this.paramStartDate;
			this.apiQuery.endDate = this.paramEndDate;
			this.apiQuery.partialCompanyName = this.paramPartialCompanyName;
			return await endpoint.call(this.reportsApiService, this.apiQuery);
		}
		catch (err) {
			this.toastService.showError('There was an error generating and downloading the Attribution Report');
		}
		finally {
			this.loading = false;
		}
	}

	annotateWithComputedColumns(data: CompanyAttribution[]): CompanyAttributionComputed[] {
		const computedData: CompanyAttributionComputed[] = data.map((origRow: CompanyAttribution) => {
			const row = origRow as CompanyAttributionComputed;
			const date = moment(row.created);
			row.dayOfWeek = date.format("ddd");
			row.isWeekend = (row.dayOfWeek === "Sun" || row.dayOfWeek === "Sat");
			row.weekOfYear = date.week();
			return row;
		});
		return computedData;
	}

	async downloadReport() {
		return await this.getReport(this.reportsApiService.downloadCompanyAttributionReport);
	}

	async submit() {
		const data = await this.getReport(this.reportsApiService.getCompanyAttributionReport) as CompanyAttribution[];
		this.rowData = this.annotateWithComputedColumns(data);
	}

	getDate(input: string): Date {
		const [year, month, day] = input.split('-');
		return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
	}

}

function currencyFormatter(params: any) {
	const value = params.value;
	if (isNaN(value)) {
		return "";
	}
	return "$" + value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
