<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
    <div class="row">
        <div class="col-4">
            <div class="card p-3 mb-3">
                <form [formGroup]="updatePostageForm">
                    <div class="form-group mb-3">
                        <label for="uSelectIdInput">
                            USelect Id
                        </label>
                        <input type="number" id="uSelectIdInput" formControlName="uSelectId" class="form-control"/>
                    </div>
					<div class="form-group mb-3">
                        <label for="uSelectNameInput">
                            USelect Name
                        </label>
                        <input type="text" id="uSelectNameInput" formControlName="uSelectName" class="form-control"/>
                    </div>
                    <div class="form-group mb-3">
                        <label for="standardPostageInput">
                            Standard Postage to update
                        </label>
                        <input type="number" id="standardPostageInput" formControlName="standardPostage" class="form-control"/>
                    </div>
                    <div class="col-6 form-group mb-3">
                        <label for="firstClassPostageInput">
                            First Class Postage to update
                        </label>
                        <input type="number" id="firstClassPostageInput" formControlName="firstClassPostage" class="form-control"/>
                    </div>
                    <button type="button" class="btn btn-primary" [disabled]="updatePostageForm.invalid" (click)="updatePostage()">Update Postage</button>
                </form>
            </div>
        </div>
		<div class="col-8">
			<div class="card p-3 mb-3">
				<div>
					<h3 class="text-center">Selected USelect Base Products (Price Matrix)</h3>
					<table class="table table-hover table-panel">
						<thead>
							<tr>
								<th>Base Product Id</th>
								<th>Product Name</th>
								<th>Min Qty</th>
								<th>Max Qty</th>
								<th>Price per k</th>
								<th>Standard Postage</th>
								<th>First Class Postage</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let pm of priceMatrices">
								<td>
									{{pm.productId}}
								</td>
								<td>
									{{getProductName(pm.productId)}}
								</td>
								<td>
									{{pm.minQty}}
								</td>
								<td>
									{{pm.maxQty}}
								</td>
								<td>
									{{pm.pricePerThousand}}
								</td>
								<td>
									{{(pm.standardPostageFee ?? 0) | currency: 'USD':'symbol-narrow':'1.2-3'}}
								</td>
								<td>
									{{(pm.firstClassPostageFee ?? 0) | currency: 'USD':'symbol-narrow':'1.2-3'}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
    </div>

</app-spinner-with-overlay>
