<app-pagefullheader></app-pagefullheader>

<div class="card border-0 p-3">
	<div class="card mb-3">
		<div class="row mb-3 ms-auto">
			<a href="#" class="btn btn-success btn-rounded px-4 rounded-pill" [routerLink]="['add']"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Product Option Category</a>
		</div>
	</div>

	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="table-responsive">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Category Id</th>
						<th>Name</th>
						<th>Total Options</th>
						<th>Description</th>
						<th>Display Type</th>
						<th>Sort Order</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let category of allProductOptionCategories; let i = index" [attr.data-index]="i">
						<td>{{ category.optCatId }}</td>
						<td>
							{{category.name}}
						</td>
						<td>{{category.options?.length}}</td>
						<td>
							{{category.description}}
						</td>
						<td>
							{{category.displayType}}
						</td>
						<td>
							{{category.sortOrder}}
						</td>
						<td>
							<div class="btn-group">
								<a type="button" class="btn btn-primary" [routerLink]="['/option-categories', category.optCatId]">Edit</a>
								<button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
									<span class="visually-hidden">Toggle Dropdown</span>
								  </button>
								<ul class="dropdown-menu" role="menu">
									<li role="menuitem"><a [routerLink]="['/option-categories', category.optCatId, 'options']" class="dropdown-item">Category Options</a></li>
									<li role="menuitem"><a [routerLink]="['/option-categories', category.optCatId, 'options', 'add']" class="dropdown-item">Add Category Option</a></li>
								</ul>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-spinner-with-overlay>
</div>
