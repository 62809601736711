import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductOption } from '@taradel/web-api-client';
import { ProductsService } from 'services/products.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-add-category-option',
	templateUrl: './add-category-option.component.html',
	styleUrls: ['./add-category-option.component.scss']
})
export class AddCategoryOptionComponent {
	loading = false;
	submitted = false;
	categoryId = 0;
	categoryOptionForm: UntypedFormGroup;

	constructor(
		public formBuilder: UntypedFormBuilder,
		private router: Router,
		private productsService: ProductsService,
		private toastService: ToastService,
		private route: ActivatedRoute) {
			this.categoryId = parseInt(route.snapshot.paramMap.get('categoryId') ?? '0', 10);
			this.categoryOptionForm = this.formBuilder.group({
				name: new UntypedFormControl('', Validators.compose([
					Validators.required,
					Validators.maxLength(50)
				])),
				modelNumber: new UntypedFormControl(''),
				description: new UntypedFormControl(''),
				markupPercent: new UntypedFormControl(''),
				markup: new UntypedFormControl(''),
				optionWidth: new UntypedFormControl(''),
				optionHeight: new UntypedFormControl(''),
				optionQuantity: new UntypedFormControl(''),
				optionWeight: new UntypedFormControl(''),
				weightPerThousand: new UntypedFormControl(''),
				fourOverProductCodeSlug: new UntypedFormControl(''),
				fourOverOptionId: new UntypedFormControl('')
			});
	}

	get categoryOptionControls() { return this.categoryOptionForm.controls; }

	async saveNewOption(): Promise<void> {
		this.submitted = true;
		if (!this.categoryOptionForm.valid) {
			return;
		}

		try {
			this.loading = true;
			const newOption = new ProductOption({
				deleted: false,
				optCatId: this.categoryId,
				optionId: 0,
				description: this.categoryOptionControls.description.value.length > 0 ? this.categoryOptionControls.description.value : undefined,
				fourOverOptionId: this.categoryOptionControls.fourOverOptionId.value.length > 0 ? this.categoryOptionControls.fourOverOptionId.value : undefined,
				fourOverProductCodeSlug: this.categoryOptionControls.fourOverProductCodeSlug.value.length > 0 ? this.categoryOptionControls.fourOverProductCodeSlug.value : undefined,
				markup: this.categoryOptionControls.markup.value.length > 0 ? this.categoryOptionControls.markup.value : undefined,
				markupPercent: this.categoryOptionControls.markupPercent.value.length > 0 ? this.categoryOptionControls.markupPercent.value : undefined,
				modelNumber: this.categoryOptionControls.modelNumber.value.length > 0 ? this.categoryOptionControls.modelNumber.value : undefined,
				name: this.categoryOptionControls.name.value.length > 0 ? this.categoryOptionControls.name.value : undefined,
				weightPerUnit: this.categoryOptionControls.weightPerThousand.value.length > 0 ? this.categoryOptionControls.weightPerThousand.value : undefined,
			});

			const optionId = await this.productsService.createProductOption(newOption);
			await this.router.navigate(['/option-categories', this.categoryId, 'options', optionId]);
		}
		catch {
			this.toastService.showError('There was an error creating the category option');
		}
		finally {
			this.loading = false;
		}
	}

	calculateWeight(): void {
		const optionWeight = parseFloat(this.categoryOptionControls.optionWeight.value);
		const optionWidth = parseFloat(this.categoryOptionControls.optionWidth.value);
		const optionHeight = parseFloat(this.categoryOptionControls.optionHeight.value);
		const optionQuantity = parseFloat(this.categoryOptionControls.optionQuantity.value);

		this.categoryOptionControls.weightPerThousand.setValue((optionWeight * 1000) / (optionWidth * optionHeight * optionQuantity));
	}
}
