import { Component, OnInit } from '@angular/core';
import { IdentityService } from 'services/identity.service';
import { IdentityCustomer, Organization, OrganizationSearch } from '@taradel/admin-api-client';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastService } from 'services/toast.service';
import { SitesService } from 'services/sites.service';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {

	usernameSearch = new Subject<string>();
	username = '';
	adminsOnly = false;
	loading = false;

	showAddCustomer = false;
	usersList: IdentityCustomer[] = [];
	organizations: Organization[] = [];

	addUsername = '';
	addFirstName = '';
	addLastName = '';
	addEmailAddress = '';
	addPassword = '';

	pageNo = 1;
	pageSize = 25;
	usersCount = 0;

	constructor(
		private identityService: IdentityService,
		private sitesService: SitesService,
		private router: Router,
		private toastService: ToastService) {
			this.usernameSearch.pipe(
				debounceTime(300),
				distinctUntilChanged()
			).subscribe(async value => {
				this.username = value;
				await this.refreshUsers();
			});
		}

	async ngOnInit(): Promise<void> {
		await this.refreshUsers();
		this.organizations = await this.sitesService.listOrganizationsAndSites(new OrganizationSearch({
			organizationId: undefined,
			siteName: ''
		}));
	}

	async refreshUsers(): Promise<void> {
		try {
			this.loading = true;
			this.usersList = await this.identityService.getAllUsers(this.pageNo, this.pageSize, this.adminsOnly, this.username);
			this.usersCount = await this.identityService.countAllUsers(this.adminsOnly, this.username);
		}
		catch {
			this.toastService.showError('There was an error getting the users');
		}
		finally {
			this.loading = false;
		}
	}

	lockedOut(customer: IdentityCustomer): boolean {
		const lockoutEnd = customer.login?.user?.lockoutEnd;
		return lockoutEnd !== undefined && lockoutEnd > new Date();
	}

	editUser(id: number) {
		this.router.navigateByUrl('user/' + id);
	}

	getOrgName(orgId: number) {
		const organization = this.organizations.find(x => x.organizationId === orgId);
		return organization?.name !== undefined ? `${orgId} - ${organization?.name}` : "Name not found";
	}

	async pageChanged() {
		await this.refreshUsers();
	}

}
