import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomerPaymentDisplay, CustomerAddress, OrderTracking,
	OrderPayment, Site, GroupedOrderPaymentDisplay, CpaStatus, OrderSearch, Order, OrderPaymentDisplay,
	VoidRefundRequest} from '@taradel/admin-api-client';
import { OrderService } from 'services/order.service';
import { PaymentsService } from 'services/payments.service';
import { ToastService } from 'services/toast.service';
import { CustomerAddressService } from 'services/customer-address.service';
import { environment } from 'environment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import Currency from 'currency.js';
import { AuthenticationService } from 'services/authentication.service';
import { CurrencySum } from 'app/utils/currency.monkey-patch';

export interface NewOrderPaymentItem {
	orderPayment: OrderPayment;
	order: OrderTracking;
}

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnDestroy {
	loading = false;
	customerId = 0;
	customerPaymentId = 0;
	customerPayment?: CustomerPaymentDisplay;
	associatedOrders?: GroupedOrderPaymentDisplay[];
	paymentAddress?: CustomerAddress;
	countryCode: 'US' | 'Canada' = 'US';

	public get cpaStatus(): typeof CpaStatus {
		return CpaStatus;
	  }
	//maybe refactor this into another component
	loadingOrders = false;
	orderIdSearch = new Subject<string>();
	@ViewChild('orderIdInput') orderIdInput!: ElementRef;
	pageNo = 1;
	pageSize = 10;
	query: OrderSearch = new OrderSearch({
		customerId: 0,
		orderId: 0,
		pageNo: 1,
		pageSize: 10
	 });
	sites: Site[] = [];
	siteIds: number[] = [];
	selectedSiteId = 1;
	pageOrders!: Order[];
	total = 0;
	newOrderPayments: NewOrderPaymentItem[] = [];
	newOrderPaymentToEdit?: OrderPayment;
	refPaymentsOrderPayments: GroupedOrderPaymentDisplay[] =[];
	newOrderPaymentForm: UntypedFormGroup;
	editPaymentForm: UntypedFormGroup;
	orderIdToEdit = 0;
	selectOrder = true;
	setPaymentAmount = false;
	reviewNewOrderPayments = false;
	submitted = false;
	showError = false;
	subscriptions: Subscription[] = [];

	constructor(
		public authService: AuthenticationService,
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private orderService: OrderService,
		private paymentsService: PaymentsService,
		private toastService: ToastService,
		private customerAddressService: CustomerAddressService,
	) {
		this.countryCode = environment.instance;

		this.newOrderPaymentForm = formBuilder.group({
			order: new FormControl<OrderTracking | undefined>(undefined, Validators.required),
			paymentAmount: new FormControl<number | undefined>(undefined, Validators.compose([Validators.required, this.paymentAmountValidator()]))
		});

		this.editPaymentForm = formBuilder.group({
			orderPayment: new FormControl<OrderPayment | undefined>(undefined, Validators.required),
			order: new FormControl<OrderTracking | undefined>(undefined, Validators.required),
			newAmount: new FormControl<number | undefined>(undefined, Validators.compose([Validators.required, this.editedPaymentAmountValidator()]))
		});

		this.subscriptions.push(this.route.paramMap.subscribe(async (params) => {
			this.submitted = false;
			this.showError = false;
			this.newOrderPaymentForm.reset();
			this.editPaymentForm.reset();

			this.customerId = parseInt(params.get('customerId') ?? '0', 10);
			this.customerPaymentId = parseInt(params.get('customerPaymentId') ?? '0', 10);

			await this.initializeWithRouteData();
		}));

		this.subscriptions.push(this.orderIdSearch.pipe(
			debounceTime(500),
			distinctUntilChanged()
		).subscribe(async value => {
			if (value.length > 0) {
				this.query.orderId = parseFloat(value);
			}
			else {
				this.query.orderId = 0;
			}
			await this.initializeAssociatedCustomerOrders();
		}));
	}

	get control() {
		return this.newOrderPaymentForm?.controls;
	}

	get editControl() {
		return this.editPaymentForm?.controls;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => {
			sub.unsubscribe();
		});
	}

	async initializeWithRouteData() {
		try {
			this.loading = true;
			this.customerPayment = await this.paymentsService.getCustomerPayment(this.customerPaymentId);
			this.associatedOrders = await this.orderService.getAssociatedOrderPayments(this.customerPaymentId);
			if (this.customerPayment.customerPaymentAttempt && this.customerPayment.customerPaymentAttempt?.billingAddressId) {
				this.paymentAddress = await this.customerAddressService.getCustomerAddress(this.customerPayment.customerId, this.customerPayment.customerPaymentAttempt.billingAddressId);
			}
			this.refPaymentsOrderPayments = await this.orderService.getReferencingOrderPayments(this.customerPaymentId);
			this.associatedOrders.forEach((order) => {
				this.refPaymentsOrderPayments.forEach((refOrder) => {
					if (refOrder.orderId === order.orderId) {
						if (refOrder.orderPayments && refOrder.orderPayments.length) {
							order.orderPayments?.push(...refOrder.orderPayments);
						}
					}
				});
			});
		}
		catch {
			this.toastService.showError('There was an error loading customer payment details');
		}
		finally {
			this.loading = false;
		}

		await this.initializeAssociatedCustomerOrders();
	}

	async initializeAssociatedCustomerOrders() {
		try {
			this.loadingOrders = true;
			this.query.customerId = this.customerId;
			this.total = await this.orderService.getCustomerOrdersCount(this.query);

			if (this.total <= 0) {
				this.pageOrders = [];
			}
			else {
				this.pageOrders = await this.orderService.getCustomerOrders(this.query);
			}
		}
		catch {
			this.toastService.showError('There was an error loading customer orders associated with this payment');
		}
		finally {
			this.loadingOrders = false;
		}
	}

	async clearFilter() {
		this.orderIdInput.nativeElement.value = '';
		this.query.orderId = 0;
		await this.initializeAssociatedCustomerOrders();
	}

	orderSelected(order: Order) {
		this.control.order.setValue(order);
		this.selectOrder = false;
		this.setPaymentAmount = true;
	}

	async deselectOrder() {
		this.newOrderPaymentForm.reset();
		this.submitted = false;
		this.setPaymentAmount = false;
		this.reviewNewOrderPayments = false;
		this.selectOrder = true;
		this.query.orderId = 0;
		await this.initializeAssociatedCustomerOrders();
	}

	async formatNewOrderPayment() {
		this.submitted = true;
		if (!this.newOrderPaymentForm.valid) {
			return;
		}
		const newPayment = {
			orderId: this.control.order?.value.orderId,
			customerPaymentId: this.customerPaymentId,
			amount: this.control.paymentAmount?.value,
		} as OrderPayment;
		const newOrderPaymentItem = {
			orderPayment: newPayment,
			order: this.control.order?.value
		} as NewOrderPaymentItem;
		this.newOrderPayments?.push(newOrderPaymentItem);
		this.submitted = false;
		this.setPaymentAmount = false;
		this.selectOrder = false;
		this.reviewNewOrderPayments = true;
		this.newOrderPaymentForm.reset();
		this.query.orderId = 0;
		await this.initializeAssociatedCustomerOrders();
	}

	setPaymentToEdit(item: NewOrderPaymentItem) {
		this.editControl.orderPayment.setValue(item.orderPayment);
		this.editControl.order.setValue(item.order);
		this.editControl.newAmount.setValue(item.orderPayment.amount);
		this.orderIdToEdit = item.order.orderId;
	}

	editPayment() {
		this.submitted = true;
		if (!this.editPaymentForm.valid) {
			return;
		}
		const newArray = this.newOrderPayments.filter(p => p.order.orderId !== this.editControl.order?.value.orderId);
		const newOrderPayment = this.editControl.orderPayment?.value;
		newOrderPayment.amount = this.editControl.newAmount?.value;
		const newOrderPaymentItem = {
			order: this.editControl.order?.value,
			orderPayment: newOrderPayment
		} as NewOrderPaymentItem;
		newArray.push(newOrderPaymentItem);
		this.newOrderPayments = newArray;
		this.cancelEdit();
	}

	cancelEdit() {
		this.orderIdToEdit = 0;
		this.editPaymentForm.reset();
	}

	async removePayment(orderId: number) {
		const index = this.newOrderPayments.findIndex(p => p.order.orderId === orderId);
		if (index > -1) {
			this.newOrderPayments.splice(index, 1);
		}
		this.query.orderId = 0;
		await this.initializeAssociatedCustomerOrders();
	}

	async addOrderPayments() {
		this.showError = false;
		const customerPaymentTotal = this.getCustomerPaymentTotal();
		let orderPaymentTotal = 0;
		let newOrderPaymentTotal = 0;
		if (!!this.associatedOrders && this.associatedOrders?.length > 0) {
			let orderPayments: OrderPaymentDisplay[] = [];
			this.associatedOrders.forEach(order => {
				if (!!order.orderPayments && order.orderPayments?.length > 0) {
					order.orderPayments.forEach(payment => orderPayments.push(payment));
				}
			});
			orderPaymentTotal = CurrencySum(orderPayments.map(p => p.amount), true);
		}
		newOrderPaymentTotal = CurrencySum(this.newOrderPayments.map(p => p.orderPayment.amount), true);
		if (orderPaymentTotal + newOrderPaymentTotal > customerPaymentTotal) {
			this.showError = true;
			return;
		}

		const orderPayments: OrderPayment[] = this.newOrderPayments.map(p => p.orderPayment);
		let orderIds: number[] = [];
		orderPayments.forEach(async orderPayment => {
			this.loading = true;
			let success = true;
			try {
				await this.orderService.addOrderPayments(orderPayment);
			}
			catch (ex: any) {
				success = false;
				console.log(ex);
				this.toastService.showError(`Payment could not be created for order ${orderPayment.orderId}`);
			}
			finally {
				this.loading = false;
				orderIds.push(orderPayment.orderId);
			}

			if (success) {
				this.toastService.showSuccess(`Payment successfully created for ${orderPayment.orderId}`);
			}
		});
		await Promise.all(orderIds);
		this.resetForms();
		this.reviewNewOrderPayments = false;
		this.customerPayment = await this.paymentsService.getCustomerPayment(this.customerPaymentId);
		this.associatedOrders = await this.orderService.getAssociatedOrderPayments(this.customerPaymentId);
		this.refPaymentsOrderPayments = await this.orderService.getReferencingOrderPayments(this.customerPaymentId);
		this.query.orderId = 0;
		await this.initializeAssociatedCustomerOrders();
		this.selectOrder = true;
	}

	resetForms() {
		this.loading = true;
		this.newOrderPayments = [];
		this.editPaymentForm.reset();
		this.newOrderPaymentForm.reset();
	}

	async pageChanged() {
		this.loading = true;
		try {
			this.query.pageNo = this.pageNo;
			this.query.pageSize = this.pageSize;
			await this.initializeAssociatedCustomerOrders();
		}
		 catch (error) {
			 this.toastService.showError('There was a problem changing the page', 'Load Error');
			 console.log(error);
		}
		 finally {
			 this.loading = false;
		}
	}

	paymentAmountValidator(): ValidatorFn {
		return (control: AbstractControl<number>): ValidationErrors | null => {
			if (!!this.control?.order?.value) {
				let newOrderPaymentTotal = this.newOrderPayments.length <= 0 ? 0 : CurrencySum(this.newOrderPayments.map(p => p.orderPayment.amount));
				newOrderPaymentTotal += control.value;
				const customerPaymentTotal = this.getCustomerPaymentTotal();
				// only allow offsetting the total of the customer payment
				const valid = newOrderPaymentTotal <= customerPaymentTotal && newOrderPaymentTotal >= (customerPaymentTotal * -1);
				return valid ? null : { invalidPaymentAmount: true };
			}
			return null;
		};
	}

	editedPaymentAmountValidator(): ValidatorFn {
		return (control: AbstractControl<number>): ValidationErrors | null => {
			if (!!this.editControl?.order?.value) {
				let newOrderPaymentTotal = this.newOrderPayments.length <= 0 ? 0 : CurrencySum(this.newOrderPayments.map(p => p.orderPayment.amount));
				newOrderPaymentTotal += control.value;
				const customerPaymentTotal = this.getCustomerPaymentTotal();
				// only allow offsetting the total of the customer payment
				const valid = newOrderPaymentTotal <= customerPaymentTotal && newOrderPaymentTotal >= (customerPaymentTotal * -1);
				return valid ? null : { invalidPaymentAmount: true };
			}
			return null;
		};
	}

	getCustomerPaymentTotal(): number {
		let customerPaymentAmounts = [this.customerPayment?.paymentAmount ?? 0];
		const referencingCustomerPaymentAmounts = (this.customerPayment?.referencingCustomerPaymentSummaries ?? []).map(summary => summary.paymentAmount);
		customerPaymentAmounts.push(...referencingCustomerPaymentAmounts);
		return CurrencySum(customerPaymentAmounts);
	}

	async refundEntirePayment() {
		try {
			this.loading = true;
			this.closeModal();
			await this.paymentsService.fullRefund(this.customerPaymentId);
			this.refPaymentsOrderPayments = await this.orderService.getReferencingOrderPayments(this.customerPaymentId);
			this.toastService.showSuccess('Entire payment refunded successfully');
		}
		catch (error: any) {
			console.log(error);
			if (error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError('There was a problem refunding the payment');
			}
		}
		finally {
			this.loading = false;
		}

	}

	async voidPayment() {
		if (!this.voidAllowed() || !this.customerPayment || !this.customerPayment.referenceCustomerPaymentId || !this.customerPayment.customerPaymentId) {
			this.modalService.dismissAll();
			return;
		}

		try {
			this.loading = true;
			this.closeModal();
			await this.paymentsService.voidCustomerPaymentRefund(new VoidRefundRequest({
				originalCustomerPaymentId: this.customerPayment.referenceCustomerPaymentId,
				refundCustomerPaymentId: this.customerPayment.customerPaymentId,
				note: '',
			}));

			this.toastService.showSuccess(`Refund #${this.customerPayment.customerPaymentId} voided successfully`);
		}
		catch (error: any) {
			console.log(error);
			if (error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError(`There was a problem voiding refund #${this.customerPayment.customerPaymentId}`);
			}
		}
		finally {
			this.loading = false;
		}
	}

	refundAllowed(fullRefund: boolean): boolean {
		if (this.authService.organizationId !== 1) {
			return false;
		}
		if (this.customerPayment) {
			if (this.customerPayment.paymentAmount <= 0) {
				return false;
			}
		}

		// Need to add other checks to see if the payment has already been refunded
		let refPaymentTotal = 0;
		this.refPaymentsOrderPayments.map(x => {
			refPaymentTotal += x.orderPayments?.reduce((p, current) => p + current.amount, 0) ?? 0;
		});
		if (fullRefund && refPaymentTotal ===  0) {
			return true;
		}
		if (!fullRefund && this.customerPayment?.paymentAmount! > -refPaymentTotal) {
			return true;
		}

		return false;
	}

	voidAllowed(): boolean {
		if (this.authService.organizationId !== 1) {
			return false;
		}
		if (!this.customerPayment) {
			return false;
		}

		if (this.customerPayment.paymentAmount > 0) {
			return false;
		}

		const prior24Hours = moment().subtract(1, 'day').toDate();
		if (this.customerPayment.createdDate < prior24Hours) {
			return false;
		}

		return true;
	}

	openModal(modal: any) {
		this.modalService.open(modal, { windowClass: "updateModalClass" });
	}

	closeModal() {
		this.modalService.dismissAll();
	}
}
