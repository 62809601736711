<ng-container>
	<app-pagefullheader></app-pagefullheader>
	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="row">
			<div class="col-md-8">
				<div class="card p-3">
					<ng-container>
						<div class="card">
							<div class="card-header">
								<strong>Select Payment Details</strong>
							</div>
							<div class="card-body">
								<div class="row mt-3">
									<div class="col-md-8 col mx-auto">
										<ng-container>
											<form [formGroup]="billingForm" id="billingForm">
												<div class="form-group">
													<div class="col-12 mb-2">
														<app-customer-addresses [submitted]="false" [addressType]="'Billing'"
															[customerId]="customerId"
															[cartAddressId]="billingControl.billingAddressId.value"
															(addressSelected)="selectBillingAddress($event)">
														</app-customer-addresses>
													</div>
												</div>
											</form>
											<div class="row">
												<div class="col-6">
													<div class="form-check">
														<input class="form-check-input" type="radio"
														[checked]="selectedPayment === paymentType.ACH"
														id="achPayment" (click)='selectedPayment = paymentType.ACH' />
														<label for="achPayment" class="form-check-label">
															Create profile using ACH
														</label>
													</div>
												</div>
												<div class="col-6">
													<div class="form-check">
														<input class="form-check-input" type="radio" id="creditCardPayment"
														[checked]="selectedPayment === paymentType.CreditCard"
														(click)='selectedPayment = paymentType.CreditCard' />
														<label for="creditCardPayment" class="form-check-label">
															Create profile using CC
														</label>
													</div>
												</div>
											</div>
											<form [formGroup]="creditCardForm" id="creditCardForm" *ngIf="selectedPayment === paymentType.CreditCard">
												<div class="form-group">
													<div class="col-12 mb-2">
														<div class="row">
															<div class="col-md-9">
																<div class="input-group">
																	<input [type]="showCCNum ? 'text' : 'password'" id="creditCardNumber" formControlName='creditCardNumber'
																		id="creditCardNumber" class="form-control mt-2" minlength="13"
																		maxlength="16" placeholder="Credit Card Number"
																		data-validate="required" />
																	<span class="input-group-text mt-2" (click)="toggleShowCCNum()">
																		<i [ngClass]="showCCNum ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
																	</span>
																</div>
																<div *ngIf="creditCardControl.creditCardNumber.errors"
																	class="input-error">
																	<div
																		*ngIf="creditCardControl.creditCardNumber.errors.required && creditCardControl.creditCardNumber.touched">
																		Credit Card is required</div>
																	<div
																		*ngIf="creditCardControl.creditCardNumber.errors.minlength">
																		Minimum Credit Card length is 13</div>
																</div>

															</div>
															<div class="col-md-3">
																<div class="input-group">
																	<input [type]="showCVV ? 'text' : 'password'"  formControlName='cvv' id="cvv"
																		class="form-control mt-2" placeholder="CVV" minlength="3"
																		maxlength="4" data-validate="required" />
																		<span class="input-group-text mt-2" (click)="toggleShowCVV()">
																			<i [ngClass]="showCVV ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
																		</span>
																</div>
																<div *ngIf="creditCardControl.cvv.errors && creditCardControl.cvv.touched"
																	class="input-error">
																	<div *ngIf="creditCardControl.cvv.errors.required">
																		CVV is required</div>
																	<div *ngIf="creditCardControl.cvv.errors.minlength">
																		Minimum length is 3</div>
																</div>
															</div>
														</div>
														<div class="row mt-2">
															<div class="col-6">
																<select formControlName='expirationMonth' id="expirationMonth"
																	class="form-select"
																	(change)="adjustYear($any($event.target).value)">
																	<option value="" selected disabled>Expiration
																		Month
																		<span class="caret"></span>
																	</option>
																	<option value="01">January</option>
																	<option value="02">February</option>
																	<option value="03">March</option>
																	<option value="04">April</option>
																	<option value="05">May</option>
																	<option value="06">June</option>
																	<option value="07">July</option>
																	<option value="08">August</option>
																	<option value="09">September</option>
																	<option value="10">October</option>
																	<option value="11">November</option>
																	<option value="12">December</option>
																</select>
																<div *ngIf="creditCardControl.expirationMonth.errors && creditCardControl.expirationMonth.touched"
																	class="input-error">
																	<div *ngIf="creditCardControl.expirationMonth.errors.required">
																		Expiration Month is required</div>
																</div>
															</div>
															<div class="col-6">
																<select formControlName='expirationYear' id="expirationYear"
																	class="form-select">
																	<option value="" selected disabled>Expiration
																		Year <span class="caret"></span></option>
																	<option *ngFor="let year of creditCardYear" value="{{year}}">
																		{{year}}</option>
																</select>
																<div *ngIf="creditCardControl.expirationYear.errors && creditCardControl.expirationYear.touched"
																	class="input-error">
																	<div *ngIf="creditCardControl.expirationYear.errors.required">
																		Expiration Year is required</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</form>
											<form [formGroup]="achForm" id="achForm" *ngIf="selectedPayment === paymentType.ACH">
												<div class="form-group">
													<div class="col-12 mb-2">
														<div class="row">
															<div class="col-md-9">
																<div class="input-group">
																	<input type="text" id="routingNumber" formControlName='routingNumber'
																		id="routingNumber" class="form-control mt-2" minlength="9"
																		maxlength="9" placeholder="Routing Number"
																		data-validate="required" />
																</div>
																<div *ngIf="achControl.routingNumber.errors"
																	class="input-error">
																	<div
																		*ngIf="achControl.routingNumber.errors.required && achControl.routingNumber.touched">
																		Routing Number is required</div>
																	<div
																		*ngIf="achControl.routingNumber.errors.minlength || achControl.routingNumber.errors.maxlength">
																		Routing Number is 9 digits</div>
																</div>
															</div>
														</div>
														<div class="row">
															<div class="col-md-9">
																<div class="input-group">
																	<input type="text" id="accountNumber" formControlName='accountNumber'
																		id="accountNumber" class="form-control mt-2" minlength="8"
																		maxlength="12" placeholder="Account Number"
																		data-validate="required" />
																</div>
																<div *ngIf="achControl.accountNumber.errors"
																	class="input-error">
																	<div
																		*ngIf="achControl.accountNumber.errors.required && achControl.accountNumber.touched">
																		Account Number is required</div>
																	<div
																		*ngIf="achControl.accountNumber.errors.minlength || achControl.accountNumber.errors.maxlength">
																		Account Number is 8-12 digits</div>
																</div>
															</div>
														</div>
														<div class="row">
															<div class="col-md-9">
																<div class="input-group">
																	<input type="text" id="nameOnAccount" formControlName='nameOnAccount'
																		id="nameOnAccount" class="form-control mt-2" placeholder="Enter Name on Account"
																		data-validate="required" />
																</div>
																<div *ngIf="achControl.nameOnAccount.errors"
																	class="input-error">
																	<div
																		*ngIf="achControl.nameOnAccount.errors.required && achControl.nameOnAccount.touched">
																		Name is required</div>
																</div>
															</div>
														</div>
														<div class="row">
															<div class="col-md-9">
																<div class="input-group">
																	<input type="text" id="bankName" formControlName='bankName'
																		id="bankName" class="form-control mt-2" placeholder="Bank Name"
																		data-validate="required" />
																</div>
																<div *ngIf="achControl.bankName.errors"
																	class="input-error">
																	<div
																		*ngIf="achControl.bankName.errors.required && achControl.bankName.touched">
																		Bank Name is required</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</form>
										</ng-container>
									</div>
								</div>
								<div class="row mt-3 d-flex justify-content-center">
									<div class="col col-auto">
										<button type="button" class="btn btn-small btn-primary" (click)="createPaymentProfile()">
											Create Payment Profile
										</button>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</app-spinner-with-overlay>
</ng-container>
