<div class="form-floating mb-3">
	<select name="siteSelect" class="form-select" (change)="siteChanged()" [(ngModel)]="startingSiteId" [disabled]="disabled">
		<option disabled [ngValue]="-1">Select a site</option>
		<option *ngFor="let x of sites | sort: 'asc': 'name'" [ngValue]="x.siteId">
			{{x.name!}}
		</option>
	</select>
	<label for="siteId">Site Name</label>
	<div *ngIf="showError" class="input-error">
		<div>Site is required</div>
	</div>
</div>
