import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountsDetail, PersonalizedMailQuery } from '@taradel/web-api-client';
import { ListPreviewComponent } from 'components/shared/list-preview/list-preview.component';
import { Subscription } from 'rxjs';
import { PmDataService } from 'services/pm-data.service';
import { PmSelectionService } from 'services/pm-selection.service';


@Component({
  selector: 'app-selection-summary',
  templateUrl: './selection-summary.component.html',
  styleUrls: ['./selection-summary.component.scss']
})
export class SelectionSummaryComponent implements OnInit, OnDestroy {

	@Output() totalLeads!: number;
	@Output() query!: PersonalizedMailQuery;
	@Output() listSaved = new EventEmitter<string>();

	showSpinner: boolean = false;
	listName!: string;
	countResponse: CountsDetail = new CountsDetail({
		addresses: 0,
		households: 0,
		individuals:0
	});
	queryUpdatesSubscription?: Subscription;

	@ViewChild(ListPreviewComponent) listPreviewComponent!: ListPreviewComponent;

	constructor(
		public pmSelectionService: PmSelectionService,
		private modalService: NgbModal,
		private pmDataService: PmDataService) {
	}

	ngOnInit(): void {
		this.queryUpdatesSubscription = this.pmSelectionService.queryUpdates.subscribe(query => this.updateLeads(query));
		this.listName = 'New List: ' + new Date().toLocaleString();
	}

	ngOnDestroy() {
		this.queryUpdatesSubscription?.unsubscribe();
	}

	async updateLeads(query: PersonalizedMailQuery): Promise<void> {
		this.query = query;
		if (!this.query.outputType?.length) {
			this.query.outputType = 'addresses';
		}
		this.showSpinner = true;
		this.countResponse = await this.pmDataService.getCount(query);
		this.updateCounts(query.outputType!);
		this.showSpinner = false;
		this.query = query;
	}
	updateCounts(outputType: string) {
		switch (outputType) {
			case 'addresses':
				this.totalLeads = this.countResponse.addresses;
				break;
			case 'households':
				this.totalLeads = this.countResponse.households;
				break;
			case 'individuals':
				this.totalLeads = this.countResponse.individuals;
				break;
		}
	}
	async getSample() {
		this.listPreviewComponent.listIsLoading = true;
		this.listPreviewComponent.addresses = await this.pmDataService.getAddressPreview(this.query);
		this.listPreviewComponent.listIsLoading = false;
		this.listPreviewComponent.listIsPopulated = true;
		this.listPreviewComponent.buttonText = 'Close List';
	}

	saveList() {
		this.closeModals();
		this.listSaved.emit(this.listName);
	}
	clearSelections() {
		this.pmSelectionService.clear();
	}

	audienceTypeChanged(type: string) {
		this.query.outputType = type;
		this.updateCounts(type);
	}

	openModal(currentModal: any) {
		this.modalService.open(currentModal, {
			size: 'lg'
		});
	}

	closeModals() {
		this.modalService.dismissAll();
	}

}
