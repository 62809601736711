import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer, InvoiceDisplay } from '@taradel/web-api-client';
import { CustomerService } from 'services/customer.service';
import { OrderService } from 'services/order.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

export interface InvoiceExtended {
	invoice: InvoiceDisplay;
	amountDue: number;
	paidInFull: boolean
}

@Component({
	selector: 'app-customer-invoices',
	templateUrl: './customer-invoices.component.html',
	styleUrls: ['./customer-invoices.component.scss']
})


export class CustomerInvoicesComponent implements OnInit {

	showSpinner: boolean = true;
	payInFull: boolean = true;
	formIsValid = false;
	customerId: number = 1;
	customer?: Customer;
	invoices: InvoiceExtended[] = [];
	affiliateId: number = 0;
	invoiceLink: string = '';

	constructor(
		private route: ActivatedRoute,
		public orderApiService: OrderService,
		private customerService: CustomerService,
		private sitesApiService: SitesService,
		private toastService: ToastService) {
			this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
	}

	async ngOnInit(): Promise<void> {
		this.showSpinner = true;
		let siteIds: number[] = [];
		let found = 0;
		let invoicesBasic = await this.orderApiService.getCustomerInvoices(this.customerId);
		invoicesBasic.forEach(i => {
			this.invoices.push({
				invoice: i,
				amountDue: this.calculateTotals(i),
				paidInFull: i.paid ?? false
			});
			i.invoiceOrders?.forEach(io => {
				found = siteIds.findIndex(e => e === io.affiliateId);
				if (found < 0) {
					siteIds.push(io.affiliateId);
				}
			});
		});
		this.invoices.sort((a, b) => a.invoice.createdDate! < b.invoice.createdDate! ? 1 : -1);

		this.customer = await this.customerService.getCustomer(this.customerId);
		this.affiliateId = this.customer.affiliateID;
		this.generateInvoiceLink();
		this.showSpinner = false;
	}

	calculateTotals(invoice: InvoiceDisplay): number {
		return invoice.invoiceOrders?.reduce((a, b) => {
			return a + b.paymentAmount;
		}, 0) ?? 0;
	}

	async generateInvoiceLink() {
		let site = await this.sitesApiService.getSite(this.affiliateId);
		let langPrefix: string | undefined = '';
		if ((site.languages?.length ?? 0) > 1) {
			const lang = this.customer?.customerProperties?.find(x => x.propertyName?.includes('Language'))?.propertyValue;
			if ((lang?.length ?? 0) > 0) {
				if (lang!.includes("fr")) {
					langPrefix = site.languages?.find(x => x.language?.includes('fr'))?.urlLanguagePrefix;
				}
				else {
					langPrefix = site.languages?.find(x => x.language?.includes('en'))?.urlLanguagePrefix;
				}
				if ((langPrefix?.length ?? 0) > 0) {
					langPrefix = '/' + langPrefix;
				}
			}
			else {
				langPrefix = '/' + site.languages![0].urlLanguagePrefix;
			}
		}
		let siteUrls = site.urls?.filter(e => !e.url!.includes('local') && e.active);
		this.invoiceLink = 'https://' + siteUrls![0].url + (langPrefix ?? '') + '/account/invoice/';
	}

	copyToClipboard(invoiceLink: string): void {
		if (invoiceLink !== '') {
			navigator.clipboard.writeText(invoiceLink).then(() => {
				/* clipboard successfully set */
				this.toastService.showInfo('The invoice link was copied to your clipboard', 'Invoice Link');
			}, (error) => {
				/* clipboard write failed */
				this.toastService.showError('There was an error copying the invoice link to your clipboard', 'Invoice Link');
				console.log(error);
			});
		}
	}
}
