import { Injectable } from '@angular/core';
import { OrderService } from 'services/order.service';
import { SitesService } from 'services/sites.service';
import { CustomerService } from 'services/customer.service';
import {
	Customer, Order, Site, Printer, PrinterPO,
	OrderTag, USelectDesignType, OrderItemDrop,
	ApiException, OrderItem, LineTypeEnum, PostageType, OrderItemLineDisplay
} from '@taradel/web-api-client';
import {
	RecommendedPrinter, OrderNote, CartProduct, EligiblePrinterSelectionResponse, EligiblePrinterSelectionRequest,
	CustomerProfile, POStatusResponse, SalesRepTagItem, DropDistribution, S3ObjectVersion, GetInHomeDateRange, DropDate,
	DropDeliveryRange, UpdateOrderItemDrop, OrderPaymentDisplay,
	EddmDistribution,
	SnapAdMailDistribution,
    GroupedOrderItemLines,
    OrderItemAudit,
    Audit
} from '@taradel/admin-api-client';
import { OrderItemSummary } from './orderitemsummary';
import { ProductCategories, ProductCategoryOptions, ProductsService } from './products.service';
import { PrintersService } from './printers.service';
import { PurchaseordersService } from './purchaseorders.service';
import { DistributionsService, USelect } from './distributions.service';
import { MapDataService } from './map-data.service';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from 'services/toast.service';
import { IdentityService } from './identity.service';
import { AuthenticationService } from './authentication.service';
import { SiteConfigService } from './site-config.service';
import { ShoppingQuoteService } from './shopping-quote.service';
import Currency from 'currency.js';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { SalesApiService } from './sales-api.service';
import { environment } from 'environment';



export function isItemLine(lineType: LineTypeEnum): boolean {
	return lineType === LineTypeEnum.Print ||
	lineType === LineTypeEnum.PrintAndShip ||
	lineType === LineTypeEnum.Digital ||
	lineType === LineTypeEnum.Tracking ||
	lineType === LineTypeEnum.CustomFee;
}
export function isTaxLine(lineType: LineTypeEnum): boolean {
	return lineType === LineTypeEnum.SalesTax ||
	lineType === LineTypeEnum.GST ||
	lineType === LineTypeEnum.PST ||
	lineType === LineTypeEnum.HST;
}

@Injectable({
	providedIn: 'root'
})
export class OrderlayoutService {
	public loading: boolean = false;
	public loadingOrder: boolean = false;
	public loadingOrderItem: boolean = false;
	public loadingPO = false;
	public loadingDist = true;
	public salesReps: SalesRepTagItem[] = [];
	public orderId: number = 0;
	public orderItemId: number = 0;
	public selectedOrder: Order | undefined;
    public selectedOrderHistory: Audit[] = [];
	public selectedOrderCartProducts?: CartProduct[];
	public selectedOrderFiles: { [key: string]: S3ObjectVersion[]; } = {};
	public selectedOrderTags: OrderTag[] | undefined;
	public selectedOrderItem: OrderItemSummary | undefined;
	public selectedOrderItemAsCartProduct: CartProduct | undefined;
    public selectedOrderItemHistory?: OrderItemAudit;
    public numberOfTimesChanged = 0;
	public selectedOrderItemDropDateMap: Map<number, NgbDate> = new Map<number, NgbDate>();
	public selectedOrderItemEndDateMap: Map<number, NgbDate> = new Map<number, NgbDate>();
	public selectedOrderItemDistributions: DropDistribution[] = [];
	public numberOfDropsAllowedForDistribution = 5;
	public eddmDistributions: {[key: string]: EddmDistribution} = {};
	public snapAdMailDistributions: {[key: string]: SnapAdMailDistribution} = {};
	public selectedOrderItems: OrderItemSummary[] | undefined;
	public orderHasPurchasedList = false;
	public isListAvailable = true;
	public selectedCustomer: Customer | undefined;
	public selectedSite: Site | undefined;
	public totalPayment = Currency(0);
	public orderBalance = Currency(0);
	public couponDiscount = Currency(0);
	public bundleDiscount = Currency(0);
	public paidPercent: string = '1';
	public printers: Printer[] | undefined;
	public affiliatePrinters: Printer[] | undefined;
	public printerRecommendations: RecommendedPrinter[] | undefined;
	public eligiblePrinterResponse: EligiblePrinterSelectionResponse | undefined;
	public isEligiblePrinterTaradel = false;
	public purchaseOrder: PrinterPO | undefined;
	public purchaseOrderStatus: POStatusResponse | undefined;
	public purchaseOrderVendorName: string = '';
	public purchaseOrderStatusMessage = '';
	public isTaradel = false;

	public customerProfile: CustomerProfile | undefined;

	public savingTag = false;
	public loadingNotes = false;
	public orderNotes: OrderNote[] | undefined;
	public newNote = '';

	public orderSalesRep?: string;

	public isDesignTeam = false;
	public isSystemAdmin = false;
	public isOrderFromQuote = false;
	public hasCreditKeyPayment = false;

	public ventureOneNumber?: string;
	public hasVentureOneNumber = false;

	public dropDeliveryRanges: DropDeliveryRange[] = [];
	public isUS = environment.instance === 'US';
	public orderItemDrops?: OrderItemDrop[];
	public dropsToFix: number[] = [];

	public orderItemLinesDisplay?: OrderItemLineDisplay[];
	public orderPayments: OrderPaymentDisplay[] = [];

	public purchaseOrderLoaded = new BehaviorSubject<PrinterPO | undefined>(null as unknown as PrinterPO);
	public eligiblePrinter = new BehaviorSubject<EligiblePrinterSelectionResponse | undefined>(null as unknown as EligiblePrinterSelectionResponse);
	public selectedOrderLoaded = new BehaviorSubject<Order | undefined>(null as unknown as Order);
	public selectedOrderItemAsCartProductLoaded = new BehaviorSubject<CartProduct | undefined>(null as unknown as CartProduct);
	public selectedOrderItemLoaded = new BehaviorSubject<OrderItemSummary | undefined>(null as unknown as OrderItemSummary);

	constructor(
		private orderService: OrderService,
		private customerService: CustomerService,
		private siteService: SitesService,
		private siteConfigService: SiteConfigService,
		private productService: ProductsService,
		private printerService: PrintersService,
		private purchaseOrderService: PurchaseordersService,
		public salesApiService: SalesApiService,
		private distributionService: DistributionsService,
		private mapDataService: MapDataService,
		private identityService: IdentityService,
		private toastService: ToastService,
		private authService: AuthenticationService,
		private quoteService: ShoppingQuoteService) { }

	public async loadOrder(loadOrderId: number) {
		try {
			this.isDesignTeam = await this.authService.hasRole('Design');
			this.isSystemAdmin = await this.authService.hasRole('SystemAdmin');
			this.isTaradel = this.authService.organizationId === 1;

			if (loadOrderId !== this.orderId) {
				this.orderId = loadOrderId;
				await this.loadNewOrderData();
			}
			else {
				this.selectedOrderLoaded.next(this.selectedOrder);
			}

			if (!this.salesReps.length) {
				this.loading = true;
				this.salesReps = await this.identityService.getSalesReps();
			}
		}
		catch {
			this.toastService.showError('There was an error loading the order');
		}
		finally {
			this.loading = false;
			this.loadingOrder = false;
		}

	}

	async loadOrderFiles() {
		this.selectedOrderFiles = await this.orderService.listOrderVersionedFiles(this.orderId);
	}

	async loadNewOrderData() {
		// do I need to clear out all existing stuff when I call this? maybe... ?
		this.loadingOrder = true;
		this.selectedOrderItem = undefined;
		this.selectedOrderTags = undefined;
		this.printers = undefined;
		this.orderSalesRep = undefined;
		this.selectedOrderCartProducts = [];

		this.selectedOrder = await this.orderService.getOrder(this.orderId);
		if (!this.selectedOrder) {
			this.loadingOrder = false;
			this.toastService.showError('There was a problem loading the data for order ' + this.orderId, 'Load Order Error');
			return;
		}

		if (!this.selectedOrder.items?.every(item => !!item.lines && item.lines.length > 0)) {
			this.selectedOrderCartProducts = await Promise.all(
				this.selectedOrder.items!.map(item => this.orderService.getOrderItemCartProduct(item.orderItemId)));
		}
		
        [this.selectedCustomer, this.selectedSite, this.selectedOrderHistory] = await Promise.all([
            await this.customerService.getCustomer(this.selectedOrder.customerId),
            await this.siteService.getSite(this.selectedOrder.affiliateId),
            await this.orderService.getOrderHistory(this.orderId)
        ]);
        this.ventureOneNumber = this.getVentureOneNumber();
		this.hasCreditKeyPayment = this.selectedOrder.orderPayments?.some(x => x.customerPayment?.paymentType === 'CreditKey' || x.customerPayment?.paymentType === 'CreditKeyPostAuth') ?? false;
		let purchasedListItem = this.selectedOrder.items?.find(x => x.xmlData?.includes('PurchasedList'));
		if (purchasedListItem !== undefined) {
			this.orderHasPurchasedList = true;
			this.isListAvailable = await this.orderService.isListAvailable(this.orderId);
		}
		else {
			this.isListAvailable = false;
		}
		if (!!this.selectedOrder.createdBy) {
			this.orderSalesRep = this.selectedOrder.createdBy;
		}
		else {
			this.orderSalesRep = this.selectedCustomer.emailAddress;
		}

		if (!this.selectedOrder.items?.every(item => !!item.lines && item.lines?.length > 0)) {
			this.totalPayment = this.selectedOrder.oldOrderPayments
				?.reduce((sum, current) => sum.add(current.paymentAmt), Currency(0))
				?? Currency(0);
		}
		else {
			this.totalPayment = this.selectedOrder.orderPayments
				?.reduce((sum, current) => sum.add(current.amount), Currency(0))
				?? Currency(0);
		}
		this.couponDiscount = this.selectedOrder.items?.reduce((sum, current) => sum.add(current.couponDiscounts), Currency(0)) ?? Currency(0);
		this.bundleDiscount = this.selectedOrder.items?.reduce((sum, current) => sum.add(current.bundleDiscounts), Currency(0)) ?? Currency(0);
		this.orderNotes = await this.orderService.getOrderNotes(this.orderId);
		this.orderBalance = Currency(this.selectedOrder.orderAmount ?? 0).subtract(this.totalPayment);
		if (this.totalPayment.value > 0) {
			this.paidPercent = ((this.totalPayment.value / this.selectedOrder.orderAmount) * 100).toFixed(2);
		}

		this.orderPayments = await this.orderService.getOrderPayments(this.orderId);

		const orderPos = await this.purchaseOrderService.getPurchaseOrdersForOrder(this.orderId);

		this.selectedOrderItems = new Array<OrderItemSummary>();
		if (this.selectedOrder.items) {
			this.selectedOrderItems = await Promise.all(this.selectedOrder.items.map(async oi => {
				let product = await this.productService.getProduct(oi.productId);
				let po = orderPos.find(p => p.orderItemId === oi.orderItemId);
				let cartItem = await this.orderService.getOrderItemCartProduct(oi.orderItemId);
				let quantity = 0;
				if (oi.lines && oi.lines.length > 0) {
					let itemsWithLineType = oi.lines?.reduce((a, o) => (oi.lines?.find(line => isItemLine(line.lineType) && line.orderItemLineId === o.orderItemLineId)?.quantity ?? 0) + a, 0);
					if (itemsWithLineType > 0) {
						quantity = itemsWithLineType;
					}
					else {
						quantity = 1;
					}
				}

				return {
					orderItemId: oi.orderItemId,
					productName: product?.name ?? '',
					quantity: quantity,
					poNumber: ((po !== undefined && po.status !== 'Cancelled') ? po.purchaseOrderId.toString() : ''),
					designType: cartItem.design?.uSelectDesign ?? cartItem.design?.front?.designSelectionType,
					jobName: cartItem.jobName,
					jobComments: cartItem.jobComments,
					orderItem: oi
				};
			}));
		}

		try {
			this.selectedOrderTags = await this.orderService.getOrderTags(this.orderId);
		}
		catch (ex) {
			console.log(ex);
		}

		try {
			this.isOrderFromQuote = await this.quoteService.getOrderShoppingQuote(this.selectedOrder.orderId) !== null ? true : false;
		}
		catch (ex) {
			this.isOrderFromQuote = false;
		}
		this.selectedOrderLoaded.next(this.selectedOrder);
		this.loadingOrder = false;
	}

	async loadNewOrderItemData(orderItemId: number, reloadOrderItem: boolean) {
		try {
			this.loadingOrderItem = true;
			this.orderItemId = orderItemId;
			this.selectedOrderItemDropDateMap.clear();
			this.selectedOrderItemEndDateMap.clear();
			if (reloadOrderItem) {
				const orderItem = await this.orderService.getOrderItem(orderItemId);
				let idx = this.selectedOrder?.items?.findIndex(x => x.orderItemId === orderItemId)!;
				this.selectedOrder!.items![idx] = orderItem;
				idx = this.selectedOrderItems?.findIndex(x => x.orderItemId === orderItemId)!;
				this.selectedOrderItems![idx].orderItem = orderItem;
			}
            this.selectedOrderItem = this.selectedOrderItems?.find(oi => oi.orderItemId === this.orderItemId);
            [this.orderItemLinesDisplay, this.selectedOrderItemAsCartProduct, this.selectedOrderItemHistory] = await Promise.all([
                await this.orderService.getOrderItemLinesDisplay(orderItemId),
                await this.orderService.getOrderItemCartProduct(this.orderItemId),
                await this.orderService.getOrderItemHistory(this.orderItemId)
            ]);
            const x = (this.selectedOrderItemHistory?.auditData ?? []).length > 0;
            this.numberOfTimesChanged = Math.max(...this.selectedOrderItemHistory?.orderItemLines!.map((h) => (h.lines?.length ?? 0))); 
		}
		catch {
			this.toastService.showError('There was an error loading order item');
		}
		finally {
			this.loadingOrderItem = false;
		}
		await this.getPurchaseOrder();
		await this.getOrderItemDistribution();

		this.selectedOrderItemAsCartProductLoaded.next(this.selectedOrderItemAsCartProduct);
		this.selectedOrderItemLoaded.next(this.selectedOrderItem);
	}

    async reloadOrderItemCartProduct() {
        this.selectedOrderItemAsCartProduct = await this.orderService.getOrderItemCartProduct(this.orderItemId);
        this.selectedOrderItemAsCartProductLoaded.next(this.selectedOrderItemAsCartProduct);
    }

	async getDropDeliveryRange() {
		const quantity = this.selectedOrderItem!.quantity;
		let postageType = PostageType.StandardMail;
		if (this.selectedOrderItemAsCartProduct?.attributes) {
			const postageOption = this.selectedOrderItemAsCartProduct?.attributes.find(x => parseInt(x.optCatId!, 10) === ProductCategories.PostageClass);
			if (postageOption && parseInt(postageOption.value!, 10) === ProductCategoryOptions.FirstClass) {
				postageType = PostageType.FirstClass;
			}
		}

		const dropDateList: DropDate[] = [];
		this.selectedOrderItemAsCartProduct?.drops?.orderDrops?.map(x => {
			dropDateList.push(new DropDate({
				dropNumber: x.number,
				date: new Date(x.date!)
			}));
		});

		this.dropDeliveryRanges = await this.salesApiService.getInHomeDateRange(new GetInHomeDateRange({
			orderItemId: this.orderItemId,
			dropDateList
		}));

	}
	getDropInHomeRange(dropNumber: number) {
		return this.dropDeliveryRanges.find(x => x.dropNumber === dropNumber)?.deliveryRange;
	}
	async getPOPrinters() {
		this.printers = await this.printerService.getAllPrinters();
		this.affiliatePrinters = await this.printerService.getAffiliatePrinters();
		try {
			this.printerRecommendations = await this.purchaseOrderService.recommendPrinters(this.orderItemId);
		}
		catch (err: any) {
			this.toastService.showWarning(err.message, 'Error loading Vendor Recommendations');
		}
		// Round robin enabled
		const enableRoundRobin = await this.siteConfigService.getBoolean(this.selectedSite!.siteId, 'Printer', 'EnablePrinterRoundRobin');
        const hasRoundRobinSelection = (this.selectedOrderItemAsCartProduct!.drops &&
            this.selectedOrderItemAsCartProduct!.drops.roundRobinSelectionId && this.selectedOrderItemAsCartProduct!.drops.roundRobinSelectionId > 0);
		if (enableRoundRobin && hasRoundRobinSelection && this.isDesignTeam) {
			this.eligiblePrinterResponse = await this.printerService.getEligiblePrinters(new EligiblePrinterSelectionRequest({
				productId: this.selectedOrderItemAsCartProduct!.baseProductId,
				customerId: this.selectedCustomer?.customerID!,
				quantity: this.selectedOrderItemAsCartProduct!.quantity,
				siteId: this.selectedOrderItemAsCartProduct!.siteId
			}));
			if (this.eligiblePrinterResponse !== undefined) {
				const suggestedPrinter = this.eligiblePrinterResponse.eligiblePrinters?.find(x => x.suggestedPrinter);
				if (suggestedPrinter !== undefined) {
					this.isEligiblePrinterTaradel = suggestedPrinter.printerId === 0;
				}
				this.eligiblePrinter.next(this.eligiblePrinterResponse);
			}
		}
	}

	async getPurchaseOrder() {
		try {
			this.loadingPO = true;
			await this.getPOPrinters();
			this.purchaseOrder = await this.purchaseOrderService.getPurchaseOrderForOrderItem(this.orderItemId);
		}
		catch {
			this.toastService.showError('There was an error loading purchase order');
		}

		try {
			if (this.purchaseOrder) {
				try {
					this.purchaseOrderStatus = await this.purchaseOrderService.getPurchaseOrderStatus(this.purchaseOrder.purchaseOrderId);
				}
				catch (ex) {
					if (ApiException.isApiException(ex)) {
						this.toastService.showError(ex.message);
					}
					else {
						this.toastService.showError('There was a problem loading the purchase order status, reach out to the dev team.');
					}
				}
				const poVendor = this.printers!.find(p => p.printerId === this.purchaseOrder?.printerId);
				this.purchaseOrderVendorName = poVendor?.companyName!;
				this.purchaseOrderLoaded.next(this.purchaseOrder);
			}
		}
		catch {
			this.purchaseOrderStatusMessage = 'Unable to get purchase order status. Please try again after some time';
		}
		finally {
			this.loadingPO = false;
		}
	}

	async getOrderItemDistribution() {
		this.dropsToFix = [];
		try {
			this.loadingDist = true;
			this.selectedOrderItemDistributions = await this.orderService.getOrderItemDistributions(this.orderItemId);
			this.orderItemDrops = await this.orderService.getOrderItemDrops(this.orderItemId);

			this.orderItemDrops.forEach(drop => {
				let cartDrop = this.selectedOrderItemAsCartProduct?.drops?.orderDrops![drop.dropNumber-1];
				if (cartDrop?.number !== drop.dropNumber) {
					this.dropsToFix.push(drop.dropNumber);
				}
				if (!!cartDrop) {
					cartDrop.number = drop.dropNumber;
					cartDrop.date = new Date(drop.dropDate);
					cartDrop.endDate = !!drop.endDate ? new Date(drop.endDate) : undefined;
				}
			});

			try {
				if (this.selectedOrderItemDistributions && this.selectedOrderItemDistributions.length > 0 && this.selectedCustomer) {
					this.eddmDistributions = {};
					this.snapAdMailDistributions = {};
					this.selectedOrderItemDistributions.forEach(async x => {
						switch (x.distribution?.uSelectId) {
							case USelect.eddm:
								this.eddmDistributions[x.dropNumber] = await this.mapDataService.getCustomerDistribution(this.selectedCustomer?.customerID!, x.distribution?.distributionId!);
								break;
							case USelect.snapAdmail:
								this.snapAdMailDistributions[x.dropNumber] = await this.mapDataService.getSnapAdMailDistribution(this.selectedCustomer?.customerID!, x.distribution?.distributionId!);
								break;
						}
						const drop = this.selectedOrderItemAsCartProduct!.drops!.orderDrops![x.dropNumber - 1];
						if (drop.date !== undefined) {
							const start = new Date(drop.date);
							const startDate = new NgbDate(start.getFullYear(), start.getMonth() + 1, start.getDate());
							this.selectedOrderItemDropDateMap?.set(drop.number, startDate);
						}

						if (drop.endDate !== undefined) {
							const end = new Date(drop.endDate);
							const endDate = new NgbDate(end.getFullYear(), end.getMonth() + 1, end.getDate());
							this.selectedOrderItemEndDateMap?.set(drop.number, endDate);
						}
					});
				}
				else if (this.selectedCustomer && this.selectedOrderItemAsCartProduct?.drops !== undefined &&
				this.selectedOrderItemAsCartProduct.drops.orderDrops !== undefined &&
				this.selectedOrderItemAsCartProduct.drops.orderDrops.length > 0) {
					this.eddmDistributions = {};
					this.snapAdMailDistributions = {};
					const distribution = await this.distributionService.getDistribution(this.selectedOrderItemAsCartProduct.distributionId);
					switch (distribution.uSelectId) {
						case USelect.eddm:
							this.selectedOrderItemDistributions.push(
								new DropDistribution({
									dropNumber: 1,
									distribution
								})
							);
							const eddmDistribution = await this.mapDataService.getCustomerDistribution(this.selectedCustomer?.customerID!, distribution.distributionId!);
							for (let drop of this.selectedOrderItemAsCartProduct.drops.orderDrops) {
								const tempDist = new EddmDistribution({
									createdById: eddmDistribution.createdById,
									createdDate: new Date(Date.now()),
									totalDeliveries: drop.total,
									useBusiness: eddmDistribution.useBusiness,
									usePOBoxes: eddmDistribution.usePOBoxes,
									uSelectId: eddmDistribution.uSelectId,
									distributionId: 0,
									customerId: eddmDistribution.customerId,
									deleted: false,
                                    carrierRoutes: eddmDistribution.carrierRoutes?.filter(s => drop.areas!.findIndex(a => a.name!.includes(s.name!)) !== -1)
								});
								this.eddmDistributions[drop.number] = tempDist;
							}

							break;
						case USelect.snapAdmail:
							this.selectedOrderItemDistributions.push(
								new DropDistribution({
									dropNumber: 1,
									distribution
								})
							);
							const snapAdMailDistribution = await this.mapDataService.getSnapAdMailDistribution(this.selectedCustomer?.customerID!, distribution.distributionId!);
							for (let drop of this.selectedOrderItemAsCartProduct.drops.orderDrops) {
                                const tempDist = new SnapAdMailDistribution({
									createdById: snapAdMailDistribution.createdById,
									createdDate: new Date(Date.now()),
									totalDeliveries: drop.total,
									useBusiness: snapAdMailDistribution.useBusiness,
                                    useApartment: snapAdMailDistribution.useApartment,
                                    useFarm: snapAdMailDistribution.useFarm,
                                    useHouse: snapAdMailDistribution.useHouse,
									uSelectId: snapAdMailDistribution.uSelectId,
									distributionId: 0,
									customerId: snapAdMailDistribution.customerId,
									deleted: false,
                                    letterCarrierWalks: snapAdMailDistribution.letterCarrierWalks?.filter(s => drop.areas!.findIndex(a => a.name!.includes(s.name!)) !== -1)
								});
								this.snapAdMailDistributions[drop.number] = tempDist;
							}
							break;
							case USelect.b2B:
							case USelect.buildYourList:
							case USelect.uploadYourList:
							case USelect.equifaxB2B:
								this.selectedOrderItemDistributions.push(
									new DropDistribution({
										dropNumber: 1,
										distribution: await this.distributionService.getDistribution(this.selectedOrderItemAsCartProduct.distributionId)
									})
								);
								break;

					}

					for (let drop of this.selectedOrderItemAsCartProduct.drops.orderDrops) {
						if (drop.date !== undefined) {
							const startDate = new NgbDate(drop.date.getFullYear(), drop.date.getMonth() + 1, drop.date.getDate());
							this.selectedOrderItemDropDateMap?.set(drop.number, startDate);
						}
						if (drop.endDate !== undefined) {
							const endDate = new NgbDate(drop.endDate.getFullYear(), drop.endDate.getMonth() + 1, drop.endDate.getDate());
							this.selectedOrderItemEndDateMap?.set(drop.number, endDate);
						}
					}
				}
			}
			catch (ex) {
			}

			if (USelect.IsDirectMail(this.selectedOrderItemAsCartProduct?.uSelectId!)) {
				await this.getDropDeliveryRange();
			}

		}
		catch {
			this.toastService.showError('There was an error loading order item distributions');
		}
		finally {
			this.loadingDist = false;
		}
	}
	getDistributionId(dropNumber: number) {
		if (this.selectedOrderItemDistributions?.length ?? 0 > 0) {
			return this.selectedOrderItemDistributions[dropNumber].distribution?.distributionId;
		}
		else {
			return 0;
		}
	}
	getDistributionName(dropNumber: number) {
		if (this.selectedOrderItemDistributions?.length ?? 0 > 0) {
			return this.selectedOrderItemDistributions[dropNumber].distribution?.name ?? '';
		}
		else {
			return 'NA';
		}
	}
	getDistributionTotalDeliveries(dropNumber: number) {
		if (this.selectedOrderItemDistributions?.length ?? 0 > 0) {
			return this.selectedOrderItemDistributions[dropNumber].distribution?.totalDeliveries!;
		}
		else {
			const drop = this.selectedOrderItemAsCartProduct?.drops?.orderDrops![dropNumber];
			return drop!.total;
		}
	}
	getDistributionUSelect(dropNumber: number) {
		if (this.selectedOrderItemDistributions?.length ?? 0 > 0) {
			return this.selectedOrderItemDistributions[dropNumber].distribution?.uSelectId!;
		}
		else {
			return this.selectedOrderItemAsCartProduct?.uSelectId!;
		}
	}

	async cancelPurchaseOrder(): Promise<void> {
		if (!this.purchaseOrder) {
			this.toastService.showError('You attempted to cancel a purchase order, but there is no PO available.');
			return;
		}

		try {
			this.purchaseOrderStatus = await this.purchaseOrderService.cancelPurchaseOrder(this.purchaseOrder.purchaseOrderId);
			this.toastService.showSuccess('Purchase order cancelled successfully.');
		}
		catch (err) {
			this.toastService.showError('There was a problem cancelling the purchase order.');
		}
	}

	async updateOrderItemDropDistribution(distributionId: number, dropNumber: number) {
		await this.orderService.updateOrderItemDropDistribution(this.selectedOrderItem?.orderItemId!, distributionId, dropNumber);

		this.selectedOrderItem!.orderItem = await this.orderService.getOrderItem(this.orderItemId);
		await this.loadNewOrderItemData(this.orderItemId, true);
	}

	async updateVendorReference(vendorReference: string): Promise<void> {
		try {
			await this.purchaseOrderService.updateVendorReference(this.purchaseOrder?.purchaseOrderId!, vendorReference);
			await this.getPurchaseOrder();
			this.toastService.showSuccess('Vendor Reference updated successfully.');
		}
		catch (err) {
			this.toastService.showError('There was a problem updating the vendor reference.');
		}
	}

	async sendDropRouteCsv(dropNumber: number): Promise<boolean> {
		return await this.purchaseOrderService.sendSnapAdmailRoutesDoc(this.purchaseOrder?.purchaseOrderId!, dropNumber);
	}

	async updateOrderItemDrops(dropNumber: number) {
		const drops = this.selectedOrderItemAsCartProduct?.drops!;
		const drop = drops.orderDrops?.find(x => x.number === dropNumber);
		const dropDate = this.selectedOrderItemDropDateMap.get(dropNumber)!;
		const endDate = this.selectedOrderItemEndDateMap.get(dropNumber) ?? undefined;
		const query: UpdateOrderItemDrop = new UpdateOrderItemDrop({
			dropNumber,
			startDate: new Date(dropDate.year, dropDate.month-1, dropDate.day),
			endDate: endDate ? new Date(endDate.year, endDate.month-1, endDate.day) : undefined,
			returnAddressId: drops.returnAddressId ?? 0,
			returnAddress1: drops.returnAddress1,
			returnAddress2: drops.returnAddress2,
			returnCity: drops.returnCity,
			returnCompany: drops.returnCompany,
			returnState: drops.returnState,
			returnZip: drops.returnZip
		});
		let success = true;
		try {
			success = await this.orderService.updateOrderItemDrops(this.selectedOrder?.orderId!, this.selectedOrderItem?.orderItemId!, query);
		}
		catch (ex: any) {
			success = false;
			this.toastService.showError(`Drop Information update failed - ${ex}`);
		}
		if (success) {
			this.dropsToFix = [];
			this.orderItemDrops = await this.orderService.getOrderItemDrops(this.selectedOrderItem?.orderItemId!);
			this.orderItemDrops!.forEach(itemDrop => {
				let cartDrop = this.selectedOrderItemAsCartProduct?.drops?.orderDrops![itemDrop.dropNumber-1];
				if (cartDrop?.number !== itemDrop.dropNumber) {
					this.dropsToFix.push(itemDrop.dropNumber);
				}
				if (!!cartDrop) {
					cartDrop.number = itemDrop.dropNumber;
					cartDrop.date = new Date(itemDrop.dropDate);
					cartDrop.endDate = !!itemDrop.endDate ? new Date(itemDrop.endDate) : undefined;
				}
			});
		}
	}

	async deleteOrder() {
		await this.orderService.deleteOrder(this.orderId);
	}

	async restoreOrder() {
		await this.orderService.restoreOrder(this.orderId);
		this.selectedOrder!.deleted = false;
	}

	async addOrderNote() {
		this.loadingNotes = true;
		try {
			await this.orderService.addOrderNote(this.orderId, this.newNote);
			this.orderNotes = await this.orderService.getOrderNotes(this.orderId);
			this.newNote = '';
			this.toastService.showSuccess('Note added');
		}
		catch (err) {
			this.toastService.showError('Failed adding order note');
		}
		this.loadingNotes = false;
	}

	async saveSalesRepTag(tag: OrderTag, salesRepId?: number) {
		this.savingTag = true;
		try {
			await this.orderService.saveSalesRepTag(this.orderId, salesRepId);
			this.toastService.showSuccess(`Set ${tag.orderTagGroup!} to ${tag.tag ?? ''}`);
		}
		catch (err) {
			this.toastService.showError(`Failed setting ${tag.orderTagGroup!} to ${tag.tag ?? ''}`);
		}
		this.savingTag = false;
	}

	async saveStoreNumberTag(tag: OrderTag) {
		this.savingTag = true;
		try {
			await this.orderService.saveStoreNumberTag(this.orderId, tag.tag ?? '');
			this.toastService.showSuccess(`Set ${tag.orderTagGroup!} to ${tag.tag ?? ''}`);
		}
		catch (err) {
			this.toastService.showError(`Failed setting ${tag.orderTagGroup!} to ${tag.tag ?? ''}`);
		}
		this.savingTag = false;
	}

	async saveCustomerRewardsNumberTag(tag: OrderTag) {
		this.savingTag = true;
		try {
			await this.orderService.saveCustomerRewardsNumberTag(this.orderId, tag.tag ?? '');
			this.toastService.showSuccess(`Set ${tag.orderTagGroup!} to ${tag.tag ?? ''}`);
		}
		catch (err) {
			this.toastService.showError(`Failed setting ${tag.orderTagGroup!} to ${tag.tag ?? ''}`);
		}
		this.savingTag = false;
	}

	async reGenerateList() {
		await this.orderService.regeneratePurchasedList(this.orderId);
	}

	async setPurchaseOrder() {
		this.purchaseOrder = await this.purchaseOrderService.getPurchaseOrderForOrderItem(this.orderItemId);
		this.selectedOrderItem!.poNumber = this.purchaseOrder.purchaseOrderId.toString();
		const poVendor = this.printers?.find(p => p.printerId === this.purchaseOrder?.printerId);
		this.purchaseOrderVendorName = poVendor?.companyName!;
		this.purchaseOrderLoaded.next(this.purchaseOrder);
	}

	getDesignName(designType?: string): string {
		switch (designType) {
			case USelectDesignType.Upload:
				return 'Upload';

			case USelectDesignType.Template:
				return 'Template';

			case USelectDesignType.DesignHuddle:
				return 'DesignHuddle';

			case USelectDesignType.ProfessionalDesign:
				return 'ProfessionalDesign';

			case USelectDesignType.Omitted:
			default:
				return 'Omitted';
		}
	}

	getDesignFee(orderItem: OrderItem): number {
		return orderItem.lines?.find(x => x.lineType === LineTypeEnum.DesignFee)?.amount ?? 0;
	}

	public formatDate(date: Date): string {
		return date.toLocaleDateString('en-US', { timeZone: 'UTC' });
	}

	unsubscribe() {
		this.purchaseOrderLoaded.next(undefined);
		this.eligiblePrinter.next(undefined);
		this.selectedOrderLoaded.next(undefined);
		this.selectedOrderItemAsCartProductLoaded.next(undefined);
		this.selectedOrderItemLoaded.next(undefined);
	}

	getVentureOneNumber(): string | undefined {
		if (!this.selectedCustomer) {
			return;
		}
		const ventureOneProp = 'ventureone number';
		const customer = this.selectedCustomer;
		const customerProperties = customer.customerProperties;
		if (!customerProperties) {
			return;
		}
		const property = customerProperties.find(p => p.propertyName?.toLowerCase() === ventureOneProp);
		if (!!property) {
			this.hasVentureOneNumber = true;
			return property.propertyValue;
		}
		this.hasVentureOneNumber = false;
		return;
	}
}
