<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="row mb-1">
			<div class="col-sm-12 col-md-3">
				<div class="form-floating">
					<input type="date" id="startDate" class="form-control"
						[ngModel]="paramStartDate | date: 'yyyy-MM-dd'" max="9999-12-31"
						(change)="paramStartDate = $any($event).target.value" placeholder="Start Date">
					<label for="startDate">Start Date</label>
				</div>
			</div>

			<div class="col-sm-12 col-md-3">
				<div class="form-floating">
					<input type="date" id="endDate" class="form-control" [ngModel]="paramEndDate | date: 'yyyy-MM-dd'"
						max="9999-12-31" (change)="paramEndDate = $any($event).target.value" placeholder="End Date">
					<label for="endDate">End Date</label>
				</div>
			</div>
		</div>

		<div class="row mb-3">
			<div class="col-12">
				<button class="btn btn-info my-3 me-3" (click)="submit()">Load Report</button>
				<!-- <button class="btn btn-info my-3" (click)="downloadReport()">Download Report</button> -->
			</div>
		</div>

		<div class="row">

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-green">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-shopping-cart fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">TOTAL ORDERS BY COUNTRY</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartTotalOrdersByCountry
								[series]="reportTotalOrdersByCountry.series!"
								[chart]="reportTotalOrdersByCountry.chart!"
								[stroke]="reportTotalOrdersByCountry.stroke!"
								[yaxis]="reportTotalOrdersByCountry.yaxis!" [xaxis]="reportTotalOrdersByCountry.xaxis!"
								[fill]="reportTotalOrdersByCountry.fill!"
								[tooltip]="reportTotalOrdersByCountry.tooltip!"
								[colors]="reportTotalOrdersByCountry.colors!"
								[theme]="reportTotalOrdersByCountry.theme!"
								[dataLabels]="reportTotalOrdersByCountry.dataLabels!"></apx-chart>
							<apx-chart #chartTotalOrdersByCountryBrush
								[series]="reportTotalOrdersByCountryBrush.series!"
								[chart]="reportTotalOrdersByCountryBrush.chart!"
								[stroke]="reportTotalOrdersByCountryBrush.stroke!"
								[yaxis]="reportTotalOrdersByCountryBrush.yaxis!"
								[xaxis]="reportTotalOrdersByCountryBrush.xaxis!"
								[fill]="reportTotalOrdersByCountryBrush.fill!"
								[tooltip]="reportTotalOrdersByCountryBrush.tooltip!"
								[colors]="reportTotalOrdersByCountryBrush.colors!"
								[theme]="reportTotalOrdersByCountryBrush.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-indigo">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-shopping-cart fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">TOTAL REVENUE BY COUNTRY</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartTotalRevenueByCountry
								[series]="reportTotalRevenueByCountry.series!"
								[chart]="reportTotalRevenueByCountry.chart!"
								[stroke]="reportTotalRevenueByCountry.stroke!"
								[yaxis]="reportTotalRevenueByCountry.yaxis!"
								[xaxis]="reportTotalRevenueByCountry.xaxis!" [fill]="reportTotalRevenueByCountry.fill!"
								[tooltip]="reportTotalRevenueByCountry.tooltip!"
								[colors]="reportTotalRevenueByCountry.colors!"
								[theme]="reportTotalRevenueByCountry.theme!"
								[dataLabels]="reportTotalRevenueByCountry.dataLabels!"></apx-chart>
							<apx-chart #chartTotalRevenueByCountryBrush
								[series]="reportTotalRevenueByCountryBrush.series!"
								[chart]="reportTotalRevenueByCountryBrush.chart!"
								[stroke]="reportTotalRevenueByCountryBrush.stroke!"
								[yaxis]="reportTotalRevenueByCountryBrush.yaxis!"
								[xaxis]="reportTotalRevenueByCountryBrush.xaxis!"
								[fill]="reportTotalRevenueByCountryBrush.fill!"
								[tooltip]="reportTotalRevenueByCountryBrush.tooltip!"
								[colors]="reportTotalRevenueByCountryBrush.colors!"
								[theme]="reportTotalRevenueByCountryBrush.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-teal">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-shopping-cart fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">AVERAGE ORDER AMOUNT BY COUNTRY</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartAverageOrderAmountByCountryPie
								[chart]="reportAverageOrderAmountByCountryPie.chart!"
								[stroke]="reportAverageOrderAmountByCountryPie.stroke!"
								[yaxis]="reportAverageOrderAmountByCountryPie.yaxis!"
								[xaxis]="reportAverageOrderAmountByCountryPie.xaxis!"
								[fill]="reportAverageOrderAmountByCountryPie.fill!"
								[tooltip]="reportAverageOrderAmountByCountryPie.tooltip!"
								[colors]="reportAverageOrderAmountByCountryPie.colors!"
								[theme]="reportAverageOrderAmountByCountryPie.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-green">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-shopping-cart fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">TOTAL ORDERS BY LINE OF BUSINESS</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartTotalOrdersByLineOfBusinessPie
								[chart]="reportTotalOrdersByLineOfBusinessPie.chart!"
								[stroke]="reportTotalOrdersByLineOfBusinessPie.stroke!"
								[yaxis]="reportTotalOrdersByLineOfBusinessPie.yaxis!"
								[xaxis]="reportTotalOrdersByLineOfBusinessPie.xaxis!"
								[fill]="reportTotalOrdersByLineOfBusinessPie.fill!"
								[tooltip]="reportTotalOrdersByLineOfBusinessPie.tooltip!"
								[colors]="reportTotalOrdersByLineOfBusinessPie.colors!"
								[theme]="reportTotalOrdersByLineOfBusinessPie.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-blue">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-shopping-cart fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">TOTAL REVENUE BY LINE OF BUSINESS</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartTotalRevenueByLineOfBusinessPie
								[chart]="reportTotalRevenueByLineOfBusinessPie.chart!"
								[stroke]="reportTotalRevenueByLineOfBusinessPie.stroke!"
								[yaxis]="reportTotalRevenueByLineOfBusinessPie.yaxis!"
								[xaxis]="reportTotalRevenueByLineOfBusinessPie.xaxis!"
								[fill]="reportTotalRevenueByLineOfBusinessPie.fill!"
								[tooltip]="reportTotalRevenueByLineOfBusinessPie.tooltip!"
								[colors]="reportTotalRevenueByLineOfBusinessPie.colors!"
								[theme]="reportTotalRevenueByLineOfBusinessPie.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-teal">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-shopping-cart fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">AVERAGE ORDER AMOUNT BY LINE OF BUSINESS</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartAverageOrderAmountByLineOfBusinessPie
								[chart]="reportAverageOrderAmountByLineOfBusinessPie.chart!"
								[stroke]="reportAverageOrderAmountByLineOfBusinessPie.stroke!"
								[yaxis]="reportAverageOrderAmountByLineOfBusinessPie.yaxis!"
								[xaxis]="reportAverageOrderAmountByLineOfBusinessPie.xaxis!"
								[fill]="reportAverageOrderAmountByLineOfBusinessPie.fill!"
								[tooltip]="reportAverageOrderAmountByLineOfBusinessPie.tooltip!"
								[colors]="reportAverageOrderAmountByLineOfBusinessPie.colors!"
								[theme]="reportAverageOrderAmountByLineOfBusinessPie.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-green">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-money fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">NEW VS. EXISTING CUSTOMER ORDERS</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartNewVsExistingCustomerOrders
								[series]="reportNewVsExistingCustomerOrders.series!"
								[chart]="reportNewVsExistingCustomerOrders.chart!"
								[stroke]="reportNewVsExistingCustomerOrders.stroke!"
								[yaxis]="reportNewVsExistingCustomerOrders.yaxis!"
								[xaxis]="reportNewVsExistingCustomerOrders.xaxis!"
								[fill]="reportNewVsExistingCustomerOrders.fill!"
								[tooltip]="reportNewVsExistingCustomerOrders.tooltip!"
								[colors]="reportNewVsExistingCustomerOrders.colors!"
								[theme]="reportNewVsExistingCustomerOrders.theme!"></apx-chart>
							<apx-chart #chartNewVsExistingCustomerOrdersBrush
								[series]="reportNewVsExistingCustomerOrdersBrush.series!"
								[chart]="reportNewVsExistingCustomerOrdersBrush.chart!"
								[stroke]="reportNewVsExistingCustomerOrdersBrush.stroke!"
								[yaxis]="reportNewVsExistingCustomerOrdersBrush.yaxis!"
								[xaxis]="reportNewVsExistingCustomerOrdersBrush.xaxis!"
								[fill]="reportNewVsExistingCustomerOrdersBrush.fill!"
								[tooltip]="reportNewVsExistingCustomerOrdersBrush.tooltip!"
								[colors]="reportNewVsExistingCustomerOrdersBrush.colors!"
								[theme]="reportNewVsExistingCustomerOrdersBrush.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-green">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-money fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">NEW VS. EXISTING CUSTOMER ORDERS BY COUNTRY</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartNewVsExistingCustomerOrdersByCountryPie
								[chart]="reportNewVsExistingCustomerOrdersByCountryPie.chart!"
								[stroke]="reportNewVsExistingCustomerOrdersByCountryPie.stroke!"
								[yaxis]="reportNewVsExistingCustomerOrdersByCountryPie.yaxis!"
								[xaxis]="reportNewVsExistingCustomerOrdersByCountryPie.xaxis!"
								[fill]="reportNewVsExistingCustomerOrdersByCountryPie.fill!"
								[tooltip]="reportNewVsExistingCustomerOrdersByCountryPie.tooltip!"
								[colors]="reportNewVsExistingCustomerOrdersByCountryPie.colors!"
								[theme]="reportNewVsExistingCustomerOrdersByCountryPie.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-green">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-money fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">NEW VS. EXISTING CUSTOMER ORDERS BY LINE OF BUSINESS</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartNewVsExistingCustomerOrdersByLineOfBusinessPie
								[chart]="reportNewVsExistingCustomerOrdersByLineOfBusinessPie.chart!"
								[stroke]="reportNewVsExistingCustomerOrdersByLineOfBusinessPie.stroke!"
								[yaxis]="reportNewVsExistingCustomerOrdersByLineOfBusinessPie.yaxis!"
								[xaxis]="reportNewVsExistingCustomerOrdersByLineOfBusinessPie.xaxis!"
								[fill]="reportNewVsExistingCustomerOrdersByLineOfBusinessPie.fill!"
								[tooltip]="reportNewVsExistingCustomerOrdersByLineOfBusinessPie.tooltip!"
								[colors]="reportNewVsExistingCustomerOrdersByLineOfBusinessPie.colors!"
								[theme]="reportNewVsExistingCustomerOrdersByLineOfBusinessPie.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-blue">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-money fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">NEW VS. EXISTING CUSTOMER REVENUE</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartNewVsExistingCustomerRevenue
								[series]="reportNewVsExistingCustomerRevenue.series!"
								[chart]="reportNewVsExistingCustomerRevenue.chart!"
								[stroke]="reportNewVsExistingCustomerRevenue.stroke!"
								[yaxis]="reportNewVsExistingCustomerRevenue.yaxis!"
								[xaxis]="reportNewVsExistingCustomerRevenue.xaxis!"
								[fill]="reportNewVsExistingCustomerRevenue.fill!"
								[tooltip]="reportNewVsExistingCustomerRevenue.tooltip!"
								[colors]="reportNewVsExistingCustomerRevenue.colors!"
								[theme]="reportNewVsExistingCustomerRevenue.theme!"></apx-chart>
							<apx-chart #chartNewVsExistingCustomerRevenueBrush
								[series]="reportNewVsExistingCustomerRevenueBrush.series!"
								[chart]="reportNewVsExistingCustomerRevenueBrush.chart!"
								[stroke]="reportNewVsExistingCustomerRevenueBrush.stroke!"
								[yaxis]="reportNewVsExistingCustomerRevenueBrush.yaxis!"
								[xaxis]="reportNewVsExistingCustomerRevenueBrush.xaxis!"
								[fill]="reportNewVsExistingCustomerRevenueBrush.fill!"
								[tooltip]="reportNewVsExistingCustomerRevenueBrush.tooltip!"
								[colors]="reportNewVsExistingCustomerRevenueBrush.colors!"
								[theme]="reportNewVsExistingCustomerRevenueBrush.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-blue">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-money fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">NEW VS. EXISTING CUSTOMER REVENUE</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartNewVsExistingCustomerRevenuePie
								[chart]="reportNewVsExistingCustomerRevenuePie.chart!"
								[stroke]="reportNewVsExistingCustomerRevenuePie.stroke!"
								[yaxis]="reportNewVsExistingCustomerRevenuePie.yaxis!"
								[xaxis]="reportNewVsExistingCustomerRevenuePie.xaxis!"
								[fill]="reportNewVsExistingCustomerRevenuePie.fill!"
								[tooltip]="reportNewVsExistingCustomerRevenuePie.tooltip!"
								[colors]="reportNewVsExistingCustomerRevenuePie.colors!"
								[theme]="reportNewVsExistingCustomerRevenuePie.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-blue">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-money fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">NEW VS. EXISTING CUSTOMER REVENUE BY COUNTRY</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartNewVsExistingCustomerRevenueByCountryPie
								[chart]="reportNewVsExistingCustomerRevenueByCountryPie.chart!"
								[stroke]="reportNewVsExistingCustomerRevenueByCountryPie.stroke!"
								[yaxis]="reportNewVsExistingCustomerRevenueByCountryPie.yaxis!"
								[xaxis]="reportNewVsExistingCustomerRevenueByCountryPie.xaxis!"
								[fill]="reportNewVsExistingCustomerRevenueByCountryPie.fill!"
								[tooltip]="reportNewVsExistingCustomerRevenueByCountryPie.tooltip!"
								[colors]="reportNewVsExistingCustomerRevenueByCountryPie.colors!"
								[theme]="reportNewVsExistingCustomerRevenueByCountryPie.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-md-6">
				<div class="widget widget-stats bg-blue">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-money fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">NEW VS. EXISTING CUSTOMER REVENUE BY LINE OF BUSINESS</div>
						<div style="min-height: 160px;" class="mb-3">
							<apx-chart #chartNewVsExistingCustomerRevenueByLineOfBusinessPie
								[chart]="reportNewVsExistingCustomerRevenueByLineOfBusinessPie.chart!"
								[stroke]="reportNewVsExistingCustomerRevenueByLineOfBusinessPie.stroke!"
								[yaxis]="reportNewVsExistingCustomerRevenueByLineOfBusinessPie.yaxis!"
								[xaxis]="reportNewVsExistingCustomerRevenueByLineOfBusinessPie.xaxis!"
								[fill]="reportNewVsExistingCustomerRevenueByLineOfBusinessPie.fill!"
								[tooltip]="reportNewVsExistingCustomerRevenueByLineOfBusinessPie.tooltip!"
								[colors]="reportNewVsExistingCustomerRevenueByLineOfBusinessPie.colors!"
								[theme]="reportNewVsExistingCustomerRevenueByLineOfBusinessPie.theme!"></apx-chart>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="row">
			<div class="col-12">
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
