<app-pagefullheader></app-pagefullheader>

<div class="card border-0 p-3">
	<div class="row mb-3 ms-auto">
		<a href="#" class="btn btn-success btn-rounded px-4 rounded-pill" [routerLink]="['add']"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Base Product</a>
	</div>
	<div class="card mb-3">
		<div class="card-body">
			<h5 class="card-title">Search</h5>

			<div class="row row-cols-lg-auto g-3 align-items-center">
				<div class="col-12">
					<div class="form-floating mb-3">
						<input type="text" id="productSearchBox" class="form-control" [(ngModel)]="productsSearch.name"
							placeholder="Search by Product Name" />
						<label for="productSearchBox">Search by Product Name</label>
					</div>
				</div>

				<div class="col-12">
					<div class="form-floating mb-3">
						<input type="number" step="1" id="productIdSearchBox" class="form-control" [(ngModel)]="productsSearch.productId" placeholder="Search by Product ID" />
						<label for="productIdSearchBox">Search by Product ID</label>
					</div>
				</div>

				<div class="col-12">
					<div class="form-floating mb-3">
						<select class="form-select" id="productType" [(ngModel)]="productsSearch.uSelectId"
							aria-label="Search by Product Type">
							<option [value]="undefined" selected>Search by Product Type</option>
							<option *ngFor="let x of uSelects" value="{{x.uSelectId}}">
								{{x.name}}
							</option>
						</select>
						<label for="productType">Search by Product Type</label>
					</div>
				</div>

				<div class="col-12">
					<button class="btn btn-primary" [disabled]="loading" (click)="search()">Search</button>
					<button *ngIf="filtered" class="btn btn-danger ms-2" (click)="resetFilter()">Reset Filter</button>
				</div>
			</div>
		</div>
	</div>

	<app-spinner-with-overlay [showSpinner]="loading">
		<div class="d-flex justify-content-between p-2" *ngIf="isSearchEnabled">
			<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
				[pageSize]="productsSearch.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
				(pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()"
					aria-label="Items per page">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Items per page</label>
			</div>
		</div>

		<div class="table-responsive">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Id</th>
						<th>Name</th>
						<th>SKU</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let product of products">
						<td>
							{{product.productId}}
						</td>
						<td>
							{{product.name}}
						</td>
						<td>
							{{product.sku}}
						</td>
						<td>
							<app-product-links [productId]="product.productId"></app-product-links>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="d-flex justify-content-between p-2" *ngIf="isSearchEnabled">
			<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
				[pageSize]="productsSearch.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
				(pageChange)="pageChanged()"></ngb-pagination>
			<div class="form-floating">
				<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize" (change)="pageChanged()"
					aria-label="Items per page">
					<option [ngValue]="20">20 items per page</option>
					<option [ngValue]="50">50 items per page</option>
					<option [ngValue]="100">100 items per page</option>
				</select>
				<label for="pageSize">Items per page</label>
			</div>
		</div>
	</app-spinner-with-overlay>
</div>
