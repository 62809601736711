<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="card border-0 p-3">
		<div class="card">
			<form (ngSubmit)="search()">
				<div class="card-body">
					<h5 class="card-title">Search</h5>

					<div class="row">
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="date" class="form-control" id="startDate" name="startDate"
									placeholder="Start Date" [ngModel]="ordersQuery.fromDate | date:'yyyy-MM-dd'"
									max="9999-12-31"
									(change)="fromDate = getDate($any($event).target.value)">
								<label for="startDate">Start Date</label>
							</div>
							<div class="form-floating mb-3">
								<input type="date" class="form-control" id="toOrderDate" name="endDate"
									placeholder="End Date" [ngModel]="ordersQuery.toDate | date:'yyyy-MM-dd'"
									max="9999-12-31"
									(change)="toDate = getDate($any($event).target.value)">
								<label for="endDate">End Date</label>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-floating mb-3">
								<input type="number" id="orderNumber" name="orderNumber" class="form-control"
									[(ngModel)]="ordersQuery.orderId" placeholder="Order Number">
								<label for="orderNumber">Order Number</label>
							</div>
							<div class="form-floating mb-3">
								<input type="number" id="orderItemId" name="orderItemId" class="form-control"
									[(ngModel)]="ordersQuery.orderItemID" placeholder="Order Item Number">
								<label for="orderItemId">Order Item Number</label>
							</div>
							<div class="form-floating mb-3">
								<input type="number" id="printerPO" name="printerPO" class="form-control"
									[(ngModel)]="ordersQuery.printerPO" placeholder="Purchase Order Number">
								<label for="printerPO">Purchase Order Number</label>
							</div>
							<div class="form-floating mb-3">
								<app-email-input
									[(ngModel)]="ordersQuery.username"
									name="email"
									label="Customer Email"
									placeholder="Customer E-mail"></app-email-input>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group mb-3">
								<label for="status">Status</label>
								<select class="form-control" name="orderStatus" size="4"
									[(ngModel)]="ordersQuery.printerStatus">
									<option value="NotSentToPrint">Not Sent to Print</option>
									<option value="SentToPrint">Sent to Print</option>
									<option value="">All</option>
								</select>
							</div>
							<div class="input-group mb-3">
								<label for="sitesDropdown" class="input-group-text">Site</label>
								<select class="form-select" name="sitesDropdown" id="sitesDropdown"
									[(ngModel)]="ordersQuery.siteId">
									<option disabled>Select a site</option>
									<option *ngFor="let site of sites | sort: 'asc' : 'name'" [value]="site.siteId"> {{ site.name }} </option>
								</select>
								<button class="btn btn-default" type="button" (click)="resetDropdown(ordersQuery.siteId)"
									*ngIf="ordersQuery.siteId"><i class="far fa-times-circle"></i></button>
							</div>
							<div class="input-group mb-3">
								<label for="repsDropdown" class="input-group-text">Sales Reps</label>
								<select class="form-select" [(ngModel)]="ordersQuery.salesRep" name="repsDropdown" id="repsDropdown">
									<option disabled>Select a Sales Rep</option>
									<option [value]="'untagged'">Untagged</option>
									<option *ngFor="let rep of salesRepsList | sort: 'asc' : 'value'" [value]="rep.value">
										{{rep.text}}</option>
								</select>
								<button class="btn btn-default" type="button" (click)="resetDropdown(ordersQuery.salesRep)"
									*ngIf="ordersQuery.salesRep"><i class="far fa-times-circle"></i></button>
							</div>
							<div class="input-group mb-3">
								<label for="printersDropdown" class="input-group-text">Printers</label>
								<select class="form-select" [(ngModel)]="ordersQuery.printerID" name="printersDropdown" id="printersDropdown">
									<option disabled>Select a Printer</option>
									<option *ngFor="let printer of printers" [value]="printer.printerId">
										{{printer.companyName}}</option>
								</select>
								<button class="btn btn-default" type="button" (click)="resetDropdown(ordersQuery.printerID)"
									*ngIf="ordersQuery.printerID"><i class="far fa-times-circle"></i></button>
							</div>
							<app-custom-list-search [array]="products" (selected)="getProduct($event)"></app-custom-list-search>
						</div>
						<div class="col-md-2">
							<label>Flags</label>
							<div class="form-check">
								<input class="form-check-input" type="checkbox" id="preFlightComplete"
									name="preFlightComplete" [(ngModel)]="ordersQuery.preFlightComplete">
								<label class="form-check-label" for="preFlightComplete">Pre-Flight Complete</label>
							</div>
							<div class="form-check">
								<input class="form-check-input" type="checkbox" id="directMail" name="directMail"
									[(ngModel)]="ordersQuery.directMail">
								<label class="form-check-label" for="directMail">Direct Mail</label>
							</div>
							<div class="form-check">
								<input class="form-check-input" type="checkbox" id="hasMap" name="hasMap"
									[(ngModel)]="ordersQuery.hasMap">
								<label class="form-check-label" for="hasMap">Has Map</label>
							</div>
							<div class="form-check">
								<input class="form-check-input" type="checkbox" id="updateArt" name="updateArt"
									[(ngModel)]="ordersQuery.updateArt">
								<label class="form-check-label" for="updateArt">Update Art</label>
							</div>
							<div class="form-check">
								<input class="form-check-input" type="checkbox" id="designService" name="designService"
									[(ngModel)]="ordersQuery.designService">
								<label class="form-check-label" for="designService">Design Service</label>
							</div>
							<div class="form-check">
								<input class="form-check-input" type="checkbox" id="deleted" name="deleted"
									[(ngModel)]="ordersQuery.deleted">
								<label class="form-check-label" for="deleted">Deleted</label>
							</div>
						</div>
					</div>

					<button class="btn btn-primary" [disabled]="loading" type="submit">Search</button>
				</div>
			</form>
		</div>

		<div class="ratio ratio-21x9" *ngIf="loading && !isSearchEnabled"></div>
		<div class="py-3">
			<div *ngIf="isSearchEnabled">
				<div class="d-flex justify-content-between align-items-end p-2">
					<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
						[pageSize]="ordersQuery.pagination!.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
						(pageChange)="pageChanged()"></ngb-pagination>
					<div class="form-floating">
						<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
							(change)="pageChanged()" aria-label="Items per page">
							<option [ngValue]="20">20 items per page</option>
							<option [ngValue]="50">50 items per page</option>
							<option [ngValue]="100">100 items per page</option>
						</select>
						<label for="pageSize">Items per page</label>
					</div>
				</div>
				<div class="table-responsive mb-3">
					<table class="table table-hover table-panel">
						<thead>
							<tr>
								<th>Order</th>
								<th>Item</th>
								<th>Date</th>
								<th>Amount</th>
								<th>Balance</th>
								<th>Customer</th>
								<th>Product</th>
								<th>Printer PO</th>
								<th>Flags</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let order of orders" [ngClass]="{'table-danger':order.deleted || order.deletedItem}">
								<td>
									<div class="btn-group" [id]="'dropdownMenuLink' + order.orderID">
										<a class="btn btn-primary" [routerLink]="['/orders/', order.orderID, 'summary']">{{order.orderID}}</a>
										<button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
											<span class="visually-hidden">Toggle Dropdown</span>
										</button>
										<ul class="dropdown-menu" [attr.aria.labelledby]="'dropdownMenuLink' + order.orderID">
											<li><a class="dropdown-item" [routerLink]="['/orders/', order.orderID, 'summary']">Summary</a></li>
											<li><a class="dropdown-item" [routerLink]="['/orders/', order.orderID, 'payments']">Payments</a></li>
											<li><a class="dropdown-item" [routerLink]="['/orders/', order.orderID, 'change-order']">Change Order</a></li>
											<li><hr class="dropdown-divider"></li>
											<li><a class="dropdown-item" [routerLink]="['/orders/', order.orderID, 'attribution-dashboard']">Attribution Dashboard</a></li>
										</ul>
									</div>
								</td>
								<td>
									<a
										[routerLink]="['/orders', order.orderID, 'details', order.orderItemId]">{{order.orderItemId}}</a>
								</td>
								<td>
									{{order.orderDate | date: 'MM/dd/yyyy HH:mm:ss'}}
								</td>
								<td>
									{{order.orderAmt | currency: 'USD'}}
								</td>
								<td [ngClass]="{'text-danger':order.balanceDue > 0}">
									{{order.balanceDue | currency: 'USD'}}
								</td>
								<td>
									{{order.customerName}}
								</td>
								<td>
									{{order.productName}}
								</td>
								<td>
									<span *ngIf="(order.printerPO ?? 0) > 0">{{order.printerPO}}</span>
									<span *ngIf="order.printerPO === 0">&mdash;</span>
								</td>
								<td>
									<ul class="list-inline m-0">
										<li class="list-inline-item" data-bs-placement="top" data-bs-toggle="tooltip" title="Design Service" *ngIf="order.flagDesignService"><i class="fas fa-palette"></i></li>
										<li class="list-inline-item link-danger" data-bs-placement="top" data-bs-toggle="tooltip" title="Deleted" *ngIf="order.deleted"><i class="fas fa-minus-circle"></i></li>
										<li class="list-inline-item" data-bs-placement="top" data-bs-toggle="tooltip" title="Direct Mail" *ngIf="order.flagDirectMail"><i class="fas fa-mail-bulk"></i></li>
										<li class="list-inline-item" data-bs-placement="top" data-bs-toggle="tooltip" title="Has Map" *ngIf="order.flagHasMap"><i class="fas fa-globe-americas"></i></li>
										<li class="list-inline-item" data-bs-placement="top" data-bs-toggle="tooltip" title="Pre-Flight Complete" *ngIf="order.flagPreFlightComplete"><i class="far fa-paper-plane"></i></li>
										<li class="list-inline-item" data-bs-placement="top" data-bs-toggle="tooltip" title="Update Art" *ngIf="order.flagUpdateArt"><i class="fas fa-paint-brush"></i></li>
									</ul>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="d-flex justify-content-between p-2">
					<ngb-pagination [collectionSize]="total" [(page)]="pageNo" [maxSize]="10"
						[pageSize]="ordersQuery.pagination!.pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
						(pageChange)="pageChanged()"></ngb-pagination>
					<div class="form-floating">
						<select class="form-select w-auto" name="pageSize" [(ngModel)]="pageSize"
							(change)="pageChanged()" aria-label="Items per page">
							<option [ngValue]="20">20 items per page</option>
							<option [ngValue]="50">50 items per page</option>
							<option [ngValue]="100">100 items per page</option>
						</select>
						<label for="pageSize">Items per page</label>
					</div>
				</div>
			</div>
			<app-alert-box alertType="info" *ngIf="!loading && this.orders && !this.orders.length">No items were found
				that match your search criteria.</app-alert-box>
		</div>
	</div>
</app-spinner-with-overlay>
