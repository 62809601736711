import { Injectable } from '@angular/core';
import { User, UserManager } from 'oidc-client';
import { environment } from 'environment';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	user!: Promise<User | null>;
	private userManager: UserManager;
	customerId = 0;
	organizationId = 0;

	constructor() {
		this.userManager = new UserManager(environment.oidc);
	}

	async init() {
		await this.getUser();
	}

	async getUser(): Promise<User | null> {
		this.user = this.userManager.getUser();

		let user = await this.user;
		if (user === null || user.expired) {
			await this.login();
			this.user = this.userManager.getUser();
			user = await this.user;
		}
		let base64 = user!.access_token.split('.')[1].replace('-', '+').replace('_', '/');
		const profile = JSON.parse(atob(base64));
		this.customerId = +profile.customerId;
		this.organizationId = +profile.organizationId;

		return user;
	}

	login(): Promise<void> {
		sessionStorage.setItem('signinRedirect', window.location.pathname);
		return this.userManager.signinRedirect();
	}

	logout(): Promise<void> {
		return this.userManager.signoutRedirect();
	}

	refresh(user: Promise<User | undefined>): Promise<void> {
		this.user = user.then(u => u ?? null);
		return this.user.then(() => { });
	}

	clean(): Promise<void> {
		return this.userManager.clearStaleState();
	}

	async isAuthenticated(): Promise<boolean> {
		const user = await this.user;
		return user?.expired === false;
	}

	async token(): Promise<string> {
		let user = await this.user;
		if (user?.expired === true) {
			this.user = this.userManager.getUser();
			user = await this.user;
		}

		return `${user?.token_type} ${user?.access_token}`;
	}

	async hasRole(role: string): Promise<boolean> {
		const user = await this.user;
		const userRoles = user?.profile.role;
		if (Array.isArray(userRoles)) {
			return userRoles.includes(role);
		}
		else {
			return userRoles === role;
		}
	}

	async hasAnyRole(roles: string[]): Promise<boolean> {
		const user = await this.user;
		const userRoles = user?.profile.role;
		if (Array.isArray(userRoles)) {
			return userRoles.some(role => roles.includes(role));
		}
		else {
			return roles.includes(userRoles);
		}
	}
}
