import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeliveryDatesClient, USelectDesignTurnaround, USelectInductionTurnaround } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
	private readonly deliveryDatesClient: DeliveryDatesClient;

	constructor(http: HttpClient) {
		this.deliveryDatesClient = new DeliveryDatesClient(http, environment.adminApiUrl);
	}

	getUSelectDesignTurnaround(uSelectId: number): Promise<USelectDesignTurnaround[]> {
		return lastValueFrom(this.deliveryDatesClient.getUSelectDesignTurnaround(uSelectId));
	}

	updateUSelectDesignTurnaround(uSelectId: number, uSelectDesignTurnarounds: USelectDesignTurnaround[]): Promise<boolean> {
		return lastValueFrom(this.deliveryDatesClient.updateUSelectDesignTurnaround(uSelectId, uSelectDesignTurnarounds));
	}

	getUSelectInductionTurnaround(): Promise<USelectInductionTurnaround[]> {
		return lastValueFrom(this.deliveryDatesClient.getAllUSelectInductionTurnaround());
	}

	updateUSelectInductionTurnaround(uSelectInductionTurnarounds: USelectInductionTurnaround[]): Promise<boolean> {
		return lastValueFrom(this.deliveryDatesClient.updateUSelectInductionTurnaround(uSelectInductionTurnarounds));
	}
}
