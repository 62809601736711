<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row mb-3">
		<div class="col-12">
			<p class="ms-auto" *ngIf="isSystemAdmin && siteId === 0 && !enableAddContentKey">
				<button class="btn btn-primary" (click)="enableAddContentKey=true">Add New Content Key</button>
			</p>

			<div class="row mb-2 align-items-center">
				<div class="col-md-6">
					<div class="input-group mb-3">
						<input type="text" id="contentFilterBox" name="contentFilterBox" class="form-control"
							[(ngModel)]="contentValueFilter" placeholder="Filter by Content">
						<button class="btn btn-default px-4 ms-2 col-lg-2"
							(click)="filterSiteContent(siteId!)">Appy Filter</button>
						<button *ngIf="isSystemAdmin" class="btn btn-success btn-rounded px-4 ms-2 rounded-pill"
							(click)="exportContentSettings()">
							<i class="fa-lg me-2 ms-n2 text-success-900"></i> Export Content
						</button>
						<input hidden type="file" #fileInput class="form-control" accept=".json" (click)="fileInput.value = ''" (change)="handleFileInput($event.target)">
						<button *ngIf="isSystemAdmin" class="btn btn-success btn-rounded px-4 ms-2 rounded-pill"
								(click)="fileInput.click()">
								<i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Import Content
						</button>
					</div>
				</div>
			</div>

			<div class="card mt-3 mb-3" *ngIf="enableAddContentKey">
				<app-spinner-with-overlay [showSpinner]="loading">
					<form [formGroup]="addContentKeyForm" (ngSubmit)="addContentKey()">
						<div class="card-body">
							<h5 class="card-title">Add New Content Key</h5>
							<div class="row">
								<div class="col-md-6">
									<div class="form-floating">
										<input type="text" class="form-control" formControlName="contentKey" name="contentKeyInput" id="contentKeyInput" #contentKeyInput
											[ngClass]="{ 'is-invalid': submitted && addContentKeyForm.controls.contentKey.errors }"
											placeholder="Content Key in . notation" />
										<label>Content Key in . notation</label>
									</div>
									<div *ngIf="submitted && addContentKeyForm.controls.contentKey.errors"
										class="invalid-feedback">
										<div *ngIf="addContentKeyForm.controls.contentKey.errors.required">The Content Key
											is required</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-floating">
										<select class="form-select" formControlName="contentType"
											[ngClass]="{ 'is-invalid': submitted && addContentKeyForm.controls.contentType.errors }">
											<option [ngValue]="''" selected>Choose One</option>
											<option *ngFor="let val of contentTypeValues" [ngValue]="val">
												{{val}}
											</option>
										</select>
										<label>Content Type</label>
									</div>
									<div *ngIf="submitted && addContentKeyForm.controls.contentType.errors"
										class="invalid-feedback">
										<div *ngIf="addContentKeyForm.controls.contentType.errors.required">A Content Type
											is required</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card-body">
							<button class="btn btn-primary" [disabled]="loading" type="submit">Add Content
								Key</button>
							<button class="btn btn-link" (click)="enableAddContentKey=false">Cancel</button>
						</div>
					</form>
				</app-spinner-with-overlay>
			</div>

			<div class="card">
				<!-- Start Content Tree Rendering Loop (If all goes well, this will loop and render an indefinite series of levels deep) -->
				<div class="list-group list-group-root" *ngIf="nodes.length > 0">
					<ng-container *ngFor="let node of nodes">
						<ng-container *ngTemplateOutlet="treeNodeTemplate; context: {node: node}"></ng-container>
					</ng-container>
				</div>

				<!-- Render treeNodeTemplate (this is the cms node template that renders each leaf) -->
				<ng-template #treeNodeTemplate let-node="node">
					<div class="row p-0">
						<div class="col">
							<button class="list-group-item list-group-item-action stretched-link"
								[ngClass]="{'fw-bold text-primary': !node.collapsed}"
								[attr.data-toggle]="node.children && node.children.length > 0 ? 'collapse' : null"
								(click)="nodeClicked(node)">
								<span>
									<i class="far"
										[ngClass]="{'fa-caret-right': node.collapsed, 'fa-caret-down': !node.collapsed}"></i>
									{{node.name}}
								</span>
							</button>
						</div>
						<div class="col-8" *ngIf="node.id === selectedNode?.id && node.showContent && !node.collapsed">
							<div class="card">
								<div class="card-body">
									<h5 class="card-title">Edit {{node.id}} - {{node.contentType}}</h5>
									<p class="card-text" *ngIf="siteId! > 0">Unless overridden in Site Content field, Base
										Content value will be used.</p>
									<p class="card-text" *ngIf="siteId! === 0">This is the base content for all sites that
										do not override this content value.</p>
									<div class="row">
										<div class="col-md-5">
											<div *ngFor="let supportedLanguage of supportedLanguages; let i = index;" class="form-floating mb-3">
												<textarea class="form-control" readonly rows="3"
													style="height:100px">{{getLanguageContent(node.baseContent, supportedLanguage.language!)?.content ?? ''}}</textarea>
												<label>Base Content ({{supportedLanguage.language}})</label>
											</div>
										</div>
										<div class="col-md-7">
											<app-spinner-with-overlay [showSpinner]="loading">
												<form [formGroup]="editSiteContentForm">
													<div formArrayName="languageContent">
														<div *ngFor="let supportedLanguage of supportedLanguages; let i=index">
															<div [formGroupName]="i">
																<div class="row">
																	<div class="col-9">
																		<div class="form-floating mb-3">
																			<textarea class="form-control" rows="3" style="height:100px"
																				formControlName="content"
																				[ngClass]="{ 'is-invalid': languageContent.at(i).get('content')?.errors}"></textarea>
																			<label>Site Content ({{supportedLanguage.language}})</label>
																			<small *ngIf="(getLanguageContent(node.siteContent, supportedLanguage.language!)?.modifiedBy ?? '') !== ''">
																				Modified by {{ getLanguageContent(node.siteContent, supportedLanguage.language!)?.modifiedBy }} on {{ getLanguageContent(node.siteContent, supportedLanguage.language!)?.modifiedDate | date:'medium' }}
																			</small>

																			<div *ngIf="languageContent.at(i)?.get('content')?.errors"
																				class="invalid-feedback">
																				<div
																					*ngIf="languageContent.at(i)?.get('content')?.errors?.required">
																					A
																					content
																					value is required.
																					<div *ngIf="siteId! > 0">If you wish to remove the content
																						override for
																						this
																						content key, you should use Actions -> Delete Site
																						Content instead.</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class="col-3">
																		<button class="btn btn-primary btn-lg ms-auto"
																			[disabled]="languageContent.at(i).get('content')?.invalid"
																			(click)="saveContent(node, i, supportedLanguage.language!)">Save</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="hstack gap-3 mt-3">
														<div class="btn-group">
															<button *ngIf="(node.content !== '' && siteId! > 0) || siteId! === 0" type="button" class="btn btn-default dropdown-toggle"
																data-bs-toggle="dropdown"
																aria-expanded="false">Actions</button>
															<ul class="dropdown-menu">
																<li *ngIf="node.content !== '' && siteId! > 0">
																	<button class="dropdown-item text-danger"
																		(click)="deleteContent(node)">
																		Delete Site Content
																	</button>
																</li>
																<li *ngIf="siteId! === 0">
																	<a class="dropdown-item text-danger"
																		routerLink="/sites/content-keys/{{ node.id }}">
																		View All Content
																	</a>
																</li>
															</ul>
														</div>
													</div>
												</form>
											</app-spinner-with-overlay>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="list-group collapse" [ngClass]="{'show':!node.collapsed}"
						*ngIf="node.children && node.children.length > 0">
						<ng-container *ngFor="let childNode of node.children">
							<ng-container *ngTemplateOutlet="treeNodeTemplate; context: {node: childNode}"></ng-container>
						</ng-container>
					</div>
				</ng-template>

				<!-- <app-content-treeview [data]="treeNodes" (contentSaved)="contentSave($event)"
					(contentDeleted)="contentDelete($event)" (contentSelected)="contentSelected($event)" [loading]="loading"
					[stateData]="stateData">
				</app-content-treeview> -->
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
