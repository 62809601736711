<app-spinner-with-overlay [showSpinner]="loading">
	<form [formGroup]="addressForm" class="my-2">
		<div *ngIf="preFillText" class="row d-flex justify-content-center mb-3">
			<div class="col col-auto">
				{{ preFillText }}
			</div>
		</div>
		<div class="row mb-3">
			<div class="col">
				<div class="form-floating">
					<input appAutocompleteOff type="search" [options]="options" ngx-gp-autocomplete
						#placesRef="ngx-places" id="googlePlacesSearch"
						(onAddressChange)="handleAddressChange($event)"
						formControlName="googleAddress"
						class="form-control border-primary bg-light"
						placeholder="Search for an address" />
					<label><i class="text-blue fab fa-google"></i>&nbsp;&nbsp;Search for an address</label>
				</div>
			</div>
		</div>
		<hr class="hr-margin">
		<ng-container *ngIf="addressType !== 'Return'">
			<div class="row mb-3">
				<div class="col">
					<div class="form-floating">
						<input autocomplete="off" type="text" formControlName='firstName' id="firstName" class="form-control" placeholder="First Name"
							[ngClass]="{ 'is-invalid': submitted && addressControls.firstName.errors }" />
						<label for="firstName">First Name</label>
						<div *ngIf="submitted && addressControls.firstName.errors && addressType === 'Billing'" class="input-error">
							First Name is required
						</div>
					</div>
				</div>
				<div class="col">
					<div class="form-floating">
						<input autocomplete="off" type="text" formControlName='lastName' id="lastName" class="form-control" placeholder="Last Name"
							[ngClass]="{ 'is-invalid': submitted && addressControls.lastName.errors }" />
						<label for="lastName">Last Name</label>
						<div *ngIf="submitted && addressControls.lastName.errors && addressType === 'Billing'" class="input-error">
							Last Name is required
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="row mb-3">
			<div class="col">
				<div class="form-floating">
					<input autocomplete="off" type="text" formControlName='company' id="company" class="form-control" placeholder="Company"
					[ngClass]="{ 'is-invalid': submitted && addressControls.company.errors }" />
					<label for="company">Company</label>
					<div *ngIf="submitted && addressControls.company.errors && addressType === 'Return'" class="input-error">
						Company is required and must be less than 100 characters long
					</div>
					<div *ngIf="submitted && addressControls.company.errors" class="input-error">
						Company must be less than 100 characters long
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-auto m-auto">
				<div *ngIf="submitted && (addressControls.company.errors || addressControls.firstName.errors || addressControls.lastName.errors) && (addressType === 'Shipping' || addressType === 'Mailing')" class="input-error">
					Company (100 character maximum) or First Name/Last Name is required
				</div>
			</div>
		</div>
		<ng-container *ngIf="addressType !== 'Return'">
			<div class="row mb-3">
				<div class="col">
					<app-phone-input formControlName="phoneNumber" id="phoneNumber" (input)="formatPhoneNumber('phoneNumber')"
						[ngClass]="{ 'is-invalid': submitted && addressControls.phoneNumber.errors }"
						[showErrors]="true" class="form-floating phone-class" label="Phone Number"
						placeholder="Phone Number">
					</app-phone-input>
				</div>
				<div class="col">
					<div class="form-floating">
						<input autocomplete="off" type="text" formControlName='emailAddress' id="emailAddress" class="form-control" placeholder="Email Address"
							[ngClass]="{ 'is-invalid': submitted && addressControls.emailAddress.errors }" />
						<label for="emailAddress">Email Address</label>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="row mb-3">
			<div class="col">
				<div class="form-floating">
					<input type="text" formControlName='address1' id="address1" class="form-control" maxlength="100" data-validate="required"
						[ngClass]="{ 'is-invalid': submitted && addressControls.address1.errors }" placeholder="Address 1" />
					<label>Address 1</label>
					<div *ngIf="submitted && addressControls.address1.errors" class="input-error">
						Address 1 is required
					</div>
				</div>
			</div>
			<div class="col">
				<div class="form-floating">
					<input type="text" formControlName='address2' id="address2" placeholder="Address 2"
						class="form-control" maxlength="50" />
					<label>Address 2</label>
				</div>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-md-5 col-12 mb-md-0 mb-3">
				<div class="form-floating">
					<input type="text" formControlName='city' id="city" class="form-control" maxlength="50" data-validate="required"
						[ngClass]="{ 'is-invalid': submitted && addressControls.city.errors }" placeholder="City" />
					<label>City</label>
					<div *ngIf="submitted && addressControls.city.errors" class="input-error">
						City is required
					</div>
				</div>
			</div>
			<div class="col-md-3 col-7">
				<div class="form-floating">
					<app-state-list [isDisabled]=false [state]="state" [isUS]="countryCode === 'US'" (selected)="stateSelected($event)"></app-state-list>
					<div *ngIf="submitted && addressControls.state.errors" class="input-error">
						State is required
					</div>
				</div>
			</div>
			<div class="col-md-4 col-5">
				<div class="form-floating">
					<input type="text" formControlName='zipCode' id="zipCode" class="form-control" data-validate="required"
						[ngClass]="{ 'is-invalid': submitted && addressControls.zipCode.errors }" placeholder="ZIP Code" />
					<label>ZIP Code</label>
					<div *ngIf="submitted && addressControls.zipCode.errors" class="input-error">
						ZIP Code is required
					</div>
				</div>
			</div>
		</div>
		<div class="row my-3">
			<div class="col ms-3">
				<div class="row">
					<div class="col">
						<div class="form-check">
							<input class="form-check-input" type="checkbox" formControlName="setAsDefault" id="default" />
							<label class="form-check-label" for="default">
								Set as default {{ addressType }} Address
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row my-3">
			<div class="col me-3">
				<div class="d-flex justify-content-end mt-2">
					<button role="button" class="btn btn-danger rounded-0" (click)="cancelEditing()">Cancel</button>
					<button role="button" class="btn btn-primary rounded-0" (click)="submit()">
						Save Address
					</button>
				</div>
			</div>
		</div>
	</form>
</app-spinner-with-overlay>
