import { Injectable } from '@angular/core';
import { ChangeOrdersClient, AddOrderItemQuery, Coupon, RefundOrderItemLinesQuery } from '@taradel/admin-api-client';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ChangeOrdersService {

	private readonly changeOrdersClient: ChangeOrdersClient;

	constructor(http: HttpClient) {
		this.changeOrdersClient = new ChangeOrdersClient(http, environment.adminApiUrl);
	}

	async refundOrderItemLines(query: RefundOrderItemLinesQuery): Promise<number> {
		return await lastValueFrom(this.changeOrdersClient.refundOrderItemLines(query));
	}

	async addOrderItem(query: AddOrderItemQuery): Promise<number> {
		return await lastValueFrom(this.changeOrdersClient.addOrderItem(query));
	}

	async updateOrderItem(query: AddOrderItemQuery): Promise<boolean> {
		return await lastValueFrom(this.changeOrdersClient.updateOrderItem(query));
	}

	async deleteOrderItem(orderItemId: number): Promise<boolean> {
		return await lastValueFrom(this.changeOrdersClient.deleteOrderItem(orderItemId));
	}

	async validateOrderCoupon(orderid: number, couponCode: string): Promise<Coupon> {
		return await lastValueFrom(this.changeOrdersClient.validateOrderCoupon(orderid, couponCode));
	}

	async updateOrderCoupon(orderId: number, couponCode: string): Promise<boolean> {
		return await lastValueFrom(this.changeOrdersClient.updateOrderCoupon(orderId, couponCode));
	}

	async validateOrderBundle(orderid: number, bundleId: number): Promise<boolean> {
		return await lastValueFrom(this.changeOrdersClient.validateOrderBundle(orderid, bundleId));
	}

	async updateOrderBundle(orderId: number, bundleId: number): Promise<boolean> {
		return await lastValueFrom(this.changeOrdersClient.bundleOrderItems(orderId, bundleId));
	}

	async removeCouponFromOrder(orderId: number, couponCode: string): Promise<boolean> {
		return await lastValueFrom(this.changeOrdersClient.removeCouponFromOrder(orderId, couponCode));
	}
}
