import { Injectable, signal } from "@angular/core";
import { StatefulService } from "./stateful.service";

export type PanelType = 'hybrid' | 'map' | 'table';

export interface MapfireState {
	panel: PanelType;
	mapFeatureData: any[];
	// mapData: any[];
	tableData: any[];
	tableDataMapByGeocoderef: Map<string, any>;
	heatmapData: any[];
}

@Injectable({
	providedIn: 'root'
})
export class MapfireService extends StatefulService<MapfireState> {
	state = signal({
		panel: 'map',
		mapFeatureData: [],
		// mapData: [], //TODO: port mapData out of the map component and into this service
		tableData: [],
		tableDataMapByGeocoderef: new Map(),
		heatmapData: [],
	} as MapfireState);

	constructor() {
		super();
		this.initialState = this.state();
		this.resetState();
	}

	setPanel(panel: PanelType) {
		this.setState({
			panel: panel
		});
	}
}

