import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Site, SiteConfig } from '@taradel/web-api-client';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-config-for-sites',
  templateUrl: './config-for-sites.component.html',
  styleUrls: ['./config-for-sites.component.scss']
})
export class ConfigForSitesComponent implements OnInit {
	loading = true;
	configCat = '';
	configName = '';
	settings: SiteConfig[] = [];
	sites: Site[] = [];

	constructor(
		private route: ActivatedRoute,
		private siteService: SitesService,
		private router: Router,
		private toastService: ToastService
		) { }

	async ngOnInit(): Promise<void> {
		try {
			this.route.paramMap.subscribe(params => {
				this.configCat = params.get('configCat')!;
				this.configName = params.get('configName')!;
			  });
			this.settings = await this.siteService.getSettingForAllSites(this.configCat, this.configName);
			this.sites = await this.siteService.getAllSites();
		}
		catch {
			this.toastService.showError('Unable to load config for sites');
		}
		finally {
			this.loading = false;
		}
	}

	getSiteName(siteId: number) {
		return this.sites.find( site => siteId === site.siteId)?.name || '';
	}

	editSetting(config: SiteConfig) {
		this.router.navigateByUrl(`sites/${config.siteId}/settings`);
	}
}
