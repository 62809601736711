import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { EquifaxQuery } from '@taradel/web-api-client';
import { EquifaxDataService } from 'services/equifax-data.service';
import { EquifaxSelectionService } from 'services/equifax-selection.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListPreviewComponent } from 'components/shared/list-preview/list-preview.component';

@Component({
  selector: 'app-b2b-selection-summary',
  templateUrl: './b2b-selection-summary.component.html',
  styleUrls: ['./b2b-selection-summary.component.scss']
})
export class B2bSelectionSummaryComponent implements OnInit, OnDestroy {

	@Input() selectionService!: EquifaxSelectionService;
	@Input() equifaxDataService!: EquifaxDataService;
	@Output() totalLeads!: number;
	@Output() query!: EquifaxQuery;
	@Output() listSaved = new EventEmitter<string>();

	showSpinner: boolean = false;
	listName!: string;
	modalRef: any;

	@ViewChild(ListPreviewComponent) listPreviewComponent!: ListPreviewComponent;

	constructor(
		private modalService: NgbModal) {
	}

	ngOnInit(): void {
		this.selectionService.clear();
		this.selectionService.queryUpdates.subscribe(query => this.updateLeads(query));
		this.listName = 'New List: ' + new Date().toLocaleString();
	}

	ngOnDestroy() {
		this.selectionService.clear();
	}

	async updateLeads(query: EquifaxQuery): Promise<void> {
		this.query = query;

		this.showSpinner = true;
		this.totalLeads = await this.equifaxDataService.getCount(query);
		this.showSpinner = false;
		this.query = query;
	}

	async getSample() {
		this.listPreviewComponent.listIsLoading = true;
		this.listPreviewComponent.addresses = await this.equifaxDataService.getAddressPreview(this.query);
		this.listPreviewComponent.listIsLoading = false;
		this.listPreviewComponent.listIsPopulated = true;
		this.listPreviewComponent.buttonText = 'Close List';
	}

	saveList() {
		this.closeModals();
		this.listSaved.emit(this.listName);
	}
	openModal(currentModal: any) {
		this.modalService.open(currentModal, {
			size: 'lg'
		});
	}

	closeModals() {
		this.modalService.dismissAll();
	}

}
