import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ScriptInjectorService } from '../services/script-injector.service';

@Injectable({
  providedIn: 'root'
})
export class DesignHuddleResolver implements Resolve<any> {
  constructor(private injectorService: ScriptInjectorService) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    await this.injectorService.loadScript('designhuddle');
    return;
  }
}
