<div class="card my-2">
	<div class="card-header"><button class="btn btn-link p-0" (click)="collapseRecs = !collapseRecs">Vendor
			Recommendations</button></div>
	<div class="card-body"
		*ngIf="!collapseRecs && this.orderLayoutService.printerRecommendations && this.orderLayoutService.printerRecommendations.length">
		<table class="table table-hover table-sm">
			<thead>
				<tr>
					<th>Printer</th>
					<th class="text-center">Zone</th>
					<th class="text-end">Print/Prep</th>
					<th class="text-end">PMOD</th>
					<th class="text-end">Postage</th>
					<th class="text-end">Total</th>
					<th *ngIf="orderLayoutService.isDesignTeam">&nbsp;</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let rec of orderLayoutService.printerRecommendations" [ngClass]="{'table-danger': !rec.enabledForJob}">
					<td>{{rec.name}}</td>
					<td class="text-center">{{rec.zone}}</td>
					<td class="text-end">{{rec.printCost | currency}}</td>
					<td class="text-end">{{rec.pmodCost | currency}}</td>
					<td class="text-end">{{rec.postageCost | currency}}</td>
					<td class="text-end">{{rec.totalCost | currency}}</td>
					<td class="text-center" *ngIf="orderLayoutService.isDesignTeam">
						<button class="btn btn-xs btn-primary" (click)="setPrinter(rec)" *ngIf="rec.enabledForJob">Use</button>
						<button *ngIf="!rec.enabledForJob" type="button" class="btn btn-link text-danger" data-bs-toggle="tooltip" [title]="rec.disabledMessage"><i class="far fa-handshake-alt-slash"></i></button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="card-body"
		*ngIf="!collapseRecs && (!this.orderLayoutService.printerRecommendations || !this.orderLayoutService.printerRecommendations.length)">
		<app-alert-box alertType="warning">
			Vendor recommendations are not available for this item.
		</app-alert-box>
	</div>
</div>
