import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { USelectMethod, UselectProductClass } from '@taradel/web-api-client';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-add-uselect',
  templateUrl: './add-uselect.component.html',
  styleUrls: ['./add-uselect.component.scss']
})
export class AddUselectComponent {
	loading = false;
	submitted = false;
	uSelectForm: UntypedFormGroup;
	productClasses = Object.values(UselectProductClass);

	constructor(
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		public siteService: SitesService,
		private toastService: ToastService
	) {
		this.uSelectForm = this.formBuilder.group({
			name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
			description: [''],
			productClass: ['', Validators.required],
			isDirectShippable: ['']
		});
	}

	async saveUselect(): Promise<void> {
		this.submitted = true;
		if (this.uSelectForm.invalid) {
			return;
		}
		try {
			this.loading = true;
			const isDirectShippable = this.uSelectForm.controls.isDirectShippable.value === true;
			await this.siteService.addUSelect(new USelectMethod({
				uSelectId: 0,
				isDirectShippable,
				name: this.uSelectForm.controls.name.value,
				productClass: this.uSelectForm.controls.productClass.value,
				description: this.uSelectForm.controls.description.value
			}));
			this.router.navigateByUrl(`uselects`);
		}
		catch {
			this.toastService.showError('There was an error adding the USelect');
		}
		finally {
			this.loading = false;
		}

	}

	get f() {
		return this.uSelectForm.controls;
	}

}
