import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProductOptionCategory } from '@taradel/web-api-client';
import { ProductsService } from 'services/products.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-option-category-details',
	templateUrl: './option-category-details.component.html',
  	styleUrls: ['./option-category-details.component.scss']
})
export class OptionCategoryDetailsComponent implements OnInit {

	loading = false;
	submitted = false;
	categoryId = 0;
	productOptionCategory?: ProductOptionCategory;
	categoryForm: UntypedFormGroup;

  	constructor(
		public formBuilder: UntypedFormBuilder,
		private productsService: ProductsService,
		private toastService: ToastService,
		private route: ActivatedRoute) {

			this.categoryId = parseInt(route.snapshot.paramMap.get('categoryId') ?? '0', 10);

			this.categoryForm = this.formBuilder.group({
				name: new UntypedFormControl('', Validators.compose([
					Validators.required,
					Validators.maxLength(50)
				])),
				description: new UntypedFormControl(''),
				displayType: new UntypedFormControl('')
			});
	}

	async ngOnInit() {
		try {
			this.loading =  true;
			this.productOptionCategory = await this.productsService.getProductOptionCategory(this.categoryId);
			this.categoryControls.description.setValue(this.productOptionCategory!.description);;
			this.categoryControls.displayType.setValue(this.productOptionCategory!.displayType);
			this.categoryControls.name.setValue(this.productOptionCategory!.name);
		}
		catch {
			this.toastService.showError('There was an error getting product option category');
		}
		finally {
			this.loading =  false;
		}
	}

	get categoryControls() { return this.categoryForm.controls; }

	async updateCategory() {
		this.submitted = true;
		if (!this.categoryForm.valid) {
			return;
		}

		try {
			this.loading = true;
			this.productOptionCategory!.description =  this.categoryControls.description.value;
			this.productOptionCategory!.displayType = this.categoryControls.displayType.value;
			this.productOptionCategory!.name = this.categoryControls.name.value;
			await this.productsService.updateProductOptionCategory(this.productOptionCategory!);
			this.toastService.showSuccess('Category updated successfully');
		}
		catch {
			this.toastService.showError('There was an error updating the category');
		}
		finally {
			this.loading = false;
		}
	}
}
