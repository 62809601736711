import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteUrl } from '@taradel/web-api-client';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-urls',
  templateUrl: './urls.component.html',
  styleUrls: ['./urls.component.scss']
})

export class UrlsComponent implements OnInit {

	siteUrls: SiteUrl[] = [];
	configCats: string[] = [];
	inputBoxToShow = 0;
	hideEditButtons = false;
	urlToDelete = 0;
	siteId = 0;
	closeResult = '';
	currentUrl = new SiteUrl();
	showAddNewUrl = false;
	addNewSiteUrlModel = new SiteUrl();
	addNewConfigConfigCat = '';
	addNewConfigConfigName = '';
	addNewConfigConfigVal = '';
	loading = false;


	constructor(
		private siteService: SitesService,
		private modalService: NgbModal,
		private toastService: ToastService
	) { }

	ngOnInit() {
		this.siteService.currentSite.subscribe(site => {
			if (site) {
				this.getSiteUrls();
			}
		});
	}

	async getSiteUrls() {
		try {
			this.loading = true;
			this.siteUrls = (await this.siteService.getSite(this.siteService.currentSiteId)).urls!;
		}
		catch {
			this.toastService.showError('There was an error getting site urls');
		}
		finally {
			this.loading = false;
		}
	}


	showAddNewUrlWindow() {
		this.showAddNewUrl = !this.showAddNewUrl;
		this.addNewSiteUrlModel = new SiteUrl();
	}

	async submitNewSiteUrl() {
		const presentUrl = this.siteUrls.find(x => x.url === this.addNewSiteUrlModel.url);

		if (presentUrl === undefined) {
			try {
				this.loading = true;
				this.addNewSiteUrlModel.siteId = this.siteService.currentSiteId;
				this.addNewSiteUrlModel.urlId = await this.siteService.addSiteUrl(this.addNewSiteUrlModel);
				await this.getSiteUrls();
				this.showAddNewUrl = !this.showAddNewUrl;
				this.addNewSiteUrlModel = new SiteUrl();
				this.toastService.showSuccess('Url added successfully');
			}
			catch {
				this.toastService.showError('There was an error adding the url');
			}
			finally {
				this.loading = false;
			}
		}

	}

	showDeleteUrlModal(url: SiteUrl, content: any) {
		this.currentUrl = url;
		this.urlToDelete = url.urlId || 0;
		this.modalOpen(content);
	}

	async deleteUrlAPI() {
		try {
			this.loading = true;
			this.siteUrls = this.siteUrls.filter(x => x.urlId !== this.urlToDelete);
			await this.siteService.deleteSiteUrl(this.urlToDelete);
			this.urlToDelete = 0;
			this.toastService.showSuccess('Url deleted successfully');
		}
		catch {
			this.toastService.showError('There was an error deleting the url');
		}
		finally {
			this.loading = false;
		}
	}


	editUrl(url: SiteUrl) {
		this.inputBoxToShow = url.urlId || 0;
		this.hideEditButtons = !this.hideEditButtons;
	}

	showEditUrlModal(url: SiteUrl, content: any) {
		this.currentUrl = url;
		this.siteId = this.siteService.currentSiteId;

		// checks form blank input text
		if (this.currentUrl.url !== undefined && this.currentUrl.url.trim()) {
			this.modalOpen(content);
		}
	}

	async editUrlAPI() {
		try {
			this.loading = true;
			await this.siteService.updateSiteUrl(new SiteUrl({
				urlId: this.currentUrl.urlId,
				forceSsl: this.currentUrl.forceSsl,
				siteId: this.currentUrl.siteId,
				active: this.currentUrl.active,
				url: this.currentUrl.url
			}));
			this.editUrl(new SiteUrl());
			this.toastService.showSuccess('Url updated successfully');
		}
		catch {
			this.toastService.showError('There was an error updating the url');
		}
		finally {
			this.loading = false;
		}
	}

	cancelEdit() {
		this.inputBoxToShow = 0;
		this.hideEditButtons = !this.hideEditButtons;
	}
	trackRow(index: number, item: SiteUrl) {
		return item;
	}

	modalOpen(content: any) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
			if (result === 'deleteUrl') {
				this.deleteUrlAPI();
			}
			else if (result === 'editUrl') {
				this.editUrlAPI();
			}
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			if (reason === 'cancelEditUrl') {
				this.cancelEdit();
			}
		});
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		}
		else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		}
		else {
			return `with: ${reason}`;
		}
	}
}
