import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderlayoutService } from 'services/orderlayout.service';
import { OrderService, QRCodePhoneNumber, QRCodeWebLink } from 'services/order.service';
import { ArtOrientation, CartProduct, FileParameter, GetCustomerProjectRequest, ListProjectsResponse, ReExportProjectRequest, Template, UpdateOrderProjectIdRequest,
	USelectDesignType } from '@taradel/admin-api-client';
import { DigitalProducts, SalesApiService } from 'services/sales-api.service';
import { ToastService } from 'services/toast.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { environment } from 'environment';
import { Subscription } from 'rxjs';
import { CustomerService } from 'services/customer.service';
import { USelect } from 'services/distributions.service';
import { CustomerAddressService } from 'services/customer-address.service';
import { DesignHuddleService } from 'services/design-huddle.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, take } from 'rxjs/operators';

export interface Project {
	thumbnail: string;
	title: string;
	isSelected: boolean;
	projectId: string;
	sourceTemplateCode: string;
	height: number;
	width: number;
	createdDate: Date;
	lastEditedDate: Date;
}

const ProjectsPerPage = 6;
import { ActivatedRoute, Router } from '@angular/router';
import { DesignHuddleHubService } from 'hubs/design-huddle-hub.service';
import { TemplateService } from 'services/template.service';

@Component({
	selector: 'app-order-item-details',
	templateUrl: './order-item-details.component.html',
	styleUrls: ['./order-item-details.component.scss']
})

export class OrderItemDetailsComponent implements OnInit, OnDestroy {
	uSelect = USelect;
	orderItemId = 0;
	orderItemCart?: CartProduct;
	template?: Template;
	frontFilePath = '';
	backFilePath = '';
	frontFile: File | undefined;
	backFile: File | undefined;
	newFrontIsPDF: boolean = false;
	newBackIsPDF: boolean = false;
	frontOrientation = 'Horizontal';
	backOrientation = 'Horizontal';
	uploadButtonText = 'Set Orientation';
	showDesignForDigital = false;
	digitalDesigns: string[] = [];
	frontPreview: SafeUrl | undefined;
	frontFileName: string | undefined = '';
	frontExtension: string | undefined = '';
	frontIsPDF: boolean = false;
	backPreview: SafeUrl | undefined;
	backFileName: string | undefined = '';
	backExtension: string | undefined = '';
	backIsPDF: boolean = false;
	designFileToDelete?: string;
	designFileTypeToDelete = '';
	loading: boolean = false;
	extraCopiesQty: number = 0;
	designHuddleOrder: boolean = false;
	reExportLoading: boolean = false;
	projectId: string = '';
	projectsList!: ListProjectsResponse;
	currentPage: number = 0;
	pageCount: number = 0;
	pageProjects: Project[] = [];
	newProjectsList: Project[] = [];
	token: string = "";
	loadingDesignHuddle: boolean = false;
	shippingAddressDeleted: boolean = false;
	shippingAddressDeletedDate?: Date;
	shippingAddressMissingPhoneNumber = false;
	countryCode: 'US' | 'Canada' = 'US';
	isLegacyDigital = false;
	seeAllOrderItemLines = false;
	QRCodePhoneNumber = QRCodePhoneNumber;
	QRCodeWebLink = QRCodeWebLink;
	qrCodeMetaInfo?: { qrCodeMetaType: string, qrCodeMetaValue: string };

	route$ = new Subscription();
	orderLoaded$ = new Subscription();
	orderItemLoaded$ = new Subscription();

	@ViewChild('deleteDesignModal') deleteDesignModal!: ElementRef;

	get isUnmappedQuoteItem(): boolean {
		return this.orderLayoutService.selectedOrderItemAsCartProduct?.baseProductId === 0;
	}

	get orderItemName(): string {
		let name = this.orderLayoutService.selectedOrderItem?.productName;
		if (!name) {
			name = this.orderLayoutService.selectedOrderItemAsCartProduct?.name!;
		}
		return name;
	}

	@ViewChild('artFrontInput') artFrontInput!: ElementRef;
	@ViewChild('artBackInput') artBackInput!: ElementRef;
	@ViewChild('designHuddleProjectsModal') designHuddleProjectsModal!: ElementRef;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public orderLayoutService: OrderlayoutService,
		public orderService: OrderService,
		private salesApiService: SalesApiService,
		private customerService: CustomerService,
		private domSanitizer: DomSanitizer,
		private toastService: ToastService,
		private customerAddressService: CustomerAddressService,
		private designHuddleService: DesignHuddleService,
		private modalService: NgbModal,
		private designHuddleHub: DesignHuddleHubService,
		private templateService: TemplateService) {
		this.route.data.pipe(
			filter((data) => !!data),
			take(1)
		).subscribe(data => this.countryCode = data.countryCode);
	}

	ngOnInit(): void {
		this.orderLoaded$ = this.orderLayoutService.selectedOrderLoaded.subscribe(async x => {
			if (x) {
				this.route$ = this.route.paramMap.subscribe(async params => {
					this.orderItemId = parseInt(params.get('orderItemId') ?? '0', 10);
					if (!this.orderLayoutService.loadingOrder) {
						await this.orderLayoutService.loadNewOrderItemData(this.orderItemId, false);
					}
				});
				await this.orderLayoutService.loadNewOrderItemData(this.orderItemId, false);
			}
		});
		this.orderItemLoaded$ = this.orderLayoutService.selectedOrderItemAsCartProductLoaded
			.pipe(
				filter((response) => !!response)
			)
			.subscribe(async response => {
				this.extraCopiesQty = 0;
				await this.getJobArtFiles();
				if (!!response?.shippingAddressId || (!!response?.shipments && response?.shipments.length > 0)) {
					await this.checkForShipping();
				}

				if (this.countryCode === 'Canada' && !response?.drops && !!response?.type) {
					const digitalId = new Map([
						['facebook ads', USelect.facebookAds],
						['targeted emails', USelect.targetedEmail],
						['display ads', USelect.displayAds],
						['linkedin ads', USelect.linkedinAds],
						['instagram', USelect.instagram],
						['spotify', USelect.spotify],
						['hulu', USelect.hulu],
						['qr code', USelect.qrCode],
						['tik tok', USelect.tiktok],
						['nextdoor ads', USelect.nextdoorAds]
					]);
					const cartProductType = response.type.toLowerCase();
					const digitalItem = digitalId.get(cartProductType);
					if (digitalItem) {
						this.isLegacyDigital = true;
					}
				}

				if (response?.uSelectId === USelect.qrCode) {
					const qrCodeMeta = response?.meta;
					const keys = this.getMetaKeys();
					if (!(!!qrCodeMeta && keys.length > 0)) {
						return;
					}
					for (let i = 0; i < keys.length; i++) {
						const key = keys[i];
						if (key === 'PhoneNumber' && qrCodeMeta[key] !== '') {
							this.qrCodeMetaInfo = {
								qrCodeMetaType: QRCodePhoneNumber,
								qrCodeMetaValue: qrCodeMeta[key]
							};
							break;
						}
						else {
							this.qrCodeMetaInfo = {
								qrCodeMetaType: QRCodeWebLink,
								qrCodeMetaValue: qrCodeMeta[key]
							};
						}
					}
				}
			});
		this.designHuddleHub.projectExported.subscribe(async projectId => {
			if (projectId === this.orderItemCart?.design?.designHuddleProjectId) {
				this.reExportLoading = false;
				this.toastService.showSuccess('Project re-exported successfully', 'Success');
				await this.orderLayoutService.loadNewOrderItemData(this.orderItemId, false);
			}
		});
	}

	ngOnDestroy(): void {
		this.route$.unsubscribe();
		this.orderLoaded$.unsubscribe();
		this.orderItemLoaded$.unsubscribe();
	}

	previewCartProduct(): string {
		return JSON.stringify(this.orderLayoutService.selectedOrderItemAsCartProduct, undefined, 2);
	}

	getMetaKeys(): string[] {
		return Object.keys(this.orderLayoutService.selectedOrderItemAsCartProduct?.meta!);
	}

	async uploadArtFiles(): Promise<void> {
		try {
			this.loading = true;
			const orderItemId = this.orderLayoutService.selectedOrderItem?.orderItemId;

			if (this.frontFile && orderItemId !== undefined) {

				await this.salesApiService.uploadFrontDesign(orderItemId, { data: this.frontFile, fileName: this.frontFile.name });
				await this.salesApiService.artOrientation(new ArtOrientation({ orderItemId: orderItemId, frontOrBack: 'Front', orientation: this.frontOrientation }));
				this.toastService.showSuccess('Front file uploaded successfully');
			}

			if (this.backFile && orderItemId !== undefined) {

				await this.salesApiService.uploadBackDesign(orderItemId, { data: this.backFile, fileName: this.backFile.name });
				await this.salesApiService.artOrientation(new ArtOrientation({ orderItemId: orderItemId, frontOrBack: 'Back', orientation: this.backOrientation }));
				this.toastService.showSuccess('Back file uploaded successfully');
			}
		}
		catch (error) {
			this.toastService.showError('There was an error uploading the file(s)');
		}
		finally {
			this.loading = false;
		}

	}

	async setShippingAddress(addressId: number): Promise<void> {
		const currentShippingAddressId = this.orderLayoutService.selectedOrderItemAsCartProduct!.shippingAddressId;
		if (addressId !== currentShippingAddressId && addressId !== 0) {
			this.orderLayoutService.selectedOrderItemAsCartProduct!.shippingAddressId = addressId;
			await this.updateShippingAddress();
		}
	}

	async updateShippingAddress() {
		try {
			this.loading = true;
			const isUpdateSuccessful = await this.orderService.updateOrderItemShipping(this.orderLayoutService.selectedOrderItem?.orderItemId!, this.orderLayoutService.selectedOrderItemAsCartProduct?.shippingAddressId!);
			if (isUpdateSuccessful) {
				this.toastService.showSuccess('Shipping address updated successfully');
				this.shippingAddressDeleted = false;
				this.shippingAddressMissingPhoneNumber = false;
			}
			else {
				this.toastService.showError('Shipping address update failed');
			}
		}
		catch (error) {
			this.toastService.showError('Shipping address update failed');
		}
		finally {
			this.loading = false;
		}
	}

	async setOrientation(): Promise<void> {
		try {
			this.loading = true;
			const orderItemId = this.orderLayoutService.selectedOrderItem?.orderItemId;

			if (this.frontOrientation !== '' && orderItemId !== undefined) {
				await this.salesApiService.artOrientation(new ArtOrientation({ orderItemId: orderItemId, frontOrBack: 'Front', orientation: this.frontOrientation }));
			}
			if (this.backOrientation !== '' && orderItemId !== undefined) {
				await this.salesApiService.artOrientation(new ArtOrientation({ orderItemId: orderItemId, frontOrBack: 'Back', orientation: this.backOrientation }));
			}
			if (this.frontOrientation !== '' || this.backOrientation !== '') {
				this.toastService.showSuccess('Orientation set successfully');
			}

		}
		catch (error) {
			this.toastService.showError('Setting orientation failed');
		}
		finally {
			this.loading = false;
		}


	}
    getCreatedDate(index: number) {
        const t = this.orderLayoutService.selectedOrderItemHistory?.orderItemLines?.find(h => !!h.lines && !!h.lines[index]?.createdDate)?.lines!;
        return t[index].createdDate;
    }

    getCreatedBy(index: number) {
        const t = this.orderLayoutService.selectedOrderItemHistory?.orderItemLines?.find(h => !!h.lines && !!h.lines[index]?.createdBy)?.lines!;        
        return `${t[index].createdBy?.firstName} ${t[index].createdBy?.lastName}`;
    }

	async submit(): Promise<void> {
		this.loading = true;
		let success = true;
		if (this.uploadButtonText === 'Upload File') {
			await this.uploadArtFiles().catch((error: HttpErrorResponse) => {
				success = false;
				if (error.status === 404) {
					this.toastService.showError('Something went wrong while uploading the file.', 'Error');
				}
				else {
					this.toastService.showError(error.message, 'Error', 5000);
				}
			});
			if (success) {
				await this.getJobArtFiles()
					.then(result => {
						this.resetJobArtSection();
						this.artFrontInput.nativeElement.value = '';
						this.artBackInput.nativeElement.value = '';
						this.toastService.showSuccess('File upload was successful!', 'Success');
					});
			}
		}
		else {
			this.setOrientation();
		}
		this.loading = false;
	}

	allowUpload(): boolean {
		if (this.frontFile === undefined && this.backFile === undefined && this.frontOrientation === '' && this.backOrientation === '') {
			this.uploadButtonText = 'Set Orientation';
			return true;
		}
		if (this.frontFile !== undefined && this.frontOrientation === '') {
			this.uploadButtonText = 'Upload File';
			return true;
		}
		else if (this.backFile !== undefined && this.backOrientation === '') {
			this.uploadButtonText = 'Upload File';
			return true;
		}
		else if (this.frontOrientation === '' && this.backOrientation === '') {
			this.uploadButtonText = 'Set Orientation';
			return true;
		}
		if (this.frontFile !== undefined || this.backFile !== undefined) {
			this.uploadButtonText = 'Upload File';
		}
		return false;
	}

	previewFile(fileInput: any, isFront: boolean): void {
		const reader = new FileReader();

		if (isFront) {
			this.frontFile = <File>fileInput.target.files[0];
			if (this.frontFile.type === 'application/pdf') {
				this.newFrontIsPDF = true;
			}
			else {
				this.newFrontIsPDF = false;
			}
			if (this.newFrontIsPDF) {
				reader.onload = () => {
					const result = reader.result as ArrayBuffer;
					const resultAsBlob = new Blob([result], { type: 'application/pdf' });
					const resultingURL = URL.createObjectURL(resultAsBlob);
					this.frontFilePath = this.domSanitizer.bypassSecurityTrustResourceUrl(resultingURL) as string;
				};
				reader.readAsArrayBuffer(this.frontFile!);
			}
			else {
				reader.onload = () => {
					this.frontFilePath = reader.result as string;
				};
				reader.readAsDataURL(this.frontFile);
			}
		}
		else {
			this.backFile = <File>fileInput.target.files[0];
			if (this.backFile.type === 'application/pdf') {
				this.newBackIsPDF = true;
			}
			else {
				this.newBackIsPDF = false;
			}
			if (this.newBackIsPDF) {
				reader.onload = () => {
					const result = reader.result as ArrayBuffer;
					const resultAsBlob = new Blob([result], { type: 'application/pdf' });
					const resultingURL = URL.createObjectURL(resultAsBlob);
					this.backFilePath = this.domSanitizer.bypassSecurityTrustResourceUrl(resultingURL) as string;
				};
				reader.readAsArrayBuffer(this.backFile);
			}
			else {
				reader.onload = () => {
					this.backFilePath = reader.result as string;
				};
				reader.readAsDataURL(this.backFile);
			}
		}
	}

	async getJobArtFiles() {
		this.loading = true;
		const orderItemCart = this.orderLayoutService.selectedOrderItemAsCartProduct;
		this.orderItemCart = orderItemCart;
		if (orderItemCart?.design?.designHuddleProjectId) {
			this.designHuddleOrder = true;
		}
		this.resetJobArtSection();
		if (!orderItemCart) {
			this.loading = false;
			return;
		}
		this.showDesignForDigital = DigitalProducts.findIndex(x => x === orderItemCart.uSelectId) !== -1;
		if (this.showDesignForDigital) {
			this.digitalDesigns = [];
			const designFilePath = `${this.orderLayoutService.orderId}/${this.orderItemId}/design`;
			if (Object.keys(this.orderLayoutService.selectedOrderFiles).length <= 0) {
				await this.orderLayoutService.loadOrderFiles();
			}
			Object.keys(this.orderLayoutService.selectedOrderFiles).map(x => {
				if (x.includes(designFilePath)) {
					this.digitalDesigns.push(x.substring(designFilePath.length + 1));
				}
			});
		}
		if (orderItemCart.design?.uSelectDesign === USelectDesignType.Template ||
			orderItemCart.design?.front?.designSelectionType === (USelectDesignType.Template || 'template')) {
			const artKey = orderItemCart.design?.front?.artKey;
			let templateId = 0;
			if (artKey) {
				templateId = +artKey;
			}
			this.frontFileName = `Template ${templateId}-front.jpg`;
			this.backFileName = `Template ${templateId}-back.jpg`;
			try {
				this.template = await this.templateService.getTemplate(templateId);
				if (this.template) {
					this.frontFileName = `Template ${this.template.stShortId!}`;
					this.backFileName = this.frontFileName;
				}
			}
			catch (error) {
				// just don't blow an error when trying this
			}
			this.frontOrientation = await this.getFileOrientationFromMeta('Front');
			this.backOrientation = await this.getFileOrientationFromMeta('Back');
			const frontThumbUrl = `${environment.templateServerUrl}/templates/thumb/${templateId}-front.jpg`;
			const backThumbUrl = `${environment.templateServerUrl}/templates/thumb/${templateId}-back.jpg`;
			this.frontPreview = this.domSanitizer.bypassSecurityTrustResourceUrl(frontThumbUrl);
			this.backPreview = this.domSanitizer.bypassSecurityTrustResourceUrl(backThumbUrl);
			this.loading = false;
			return;
		}
		if (orderItemCart.design?.front?.fileName && orderItemCart.design?.front?.fileType) {
			try {
				this.frontIsPDF = false;
				const frontFile = await this.orderService.getFrontImage(this.orderLayoutService.orderItemId);
				if (frontFile) {
					this.frontOrientation = await this.getFileOrientationFromMeta('Front');
					this.frontIsPDF = frontFile.type === 'application/pdf';
					this.frontFileName = orderItemCart.design?.front?.fileName;

					if (this.frontIsPDF) {
						const result = new Blob([frontFile], { type: 'application/pdf' });
						const resultingURL = URL.createObjectURL(result);
						this.frontPreview = this.domSanitizer.bypassSecurityTrustResourceUrl(resultingURL);
					}
					else {
						this.getFilePreview(frontFile).then((data) => {
							this.frontPreview = this.domSanitizer.bypassSecurityTrustUrl(data);
						});
					}
				}
				else {
					this.frontPreview = undefined;
				}
			}
			catch (error) {
				this.frontPreview = undefined;
			}
		}

		if (orderItemCart.design?.back?.fileName && orderItemCart.design?.back?.fileType) {
			try {
				this.backIsPDF = false;
				const backFile = await this.orderService.getBackImage(this.orderLayoutService.orderItemId);
				if (backFile) {
					this.backOrientation = await this.getFileOrientationFromMeta('Back');
					this.backIsPDF = backFile.type === 'application/pdf';
					this.backFileName = orderItemCart.design?.back?.fileName;

					if (this.backIsPDF) {
						const result = new Blob([backFile], { type: 'application/pdf' });
						const resultingURL = URL.createObjectURL(result);
						this.backPreview = this.domSanitizer.bypassSecurityTrustResourceUrl(resultingURL);
					}
					else {
						this.getFilePreview(backFile).then((data) => {
							this.backPreview = this.domSanitizer.bypassSecurityTrustUrl(data);
						});
					}
				}
				else {
					this.backPreview = undefined;
				}
			}
			catch (error) {
				this.backPreview = undefined;
			}
		}

		this.loading = false;
	}

	async uploadDigitalDesigns(newDesignFiles: any[]) {
		let success = true;
		try {
			this.loading = true;
			const images: FileParameter[] = [];
			newDesignFiles.map(d => images.push({
				data: d as unknown as File,
				fileName: d.name
			}));

			await this.orderService.uploadDesigns(this.orderItemId, images);
		}
		catch (error) {
			success = false;
			this.toastService.showError('Unable to upload file(s)');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			this.toastService.showSuccess('Files uploaded successfully');
		}
	}

	async onFileDropped(files: any) {
		const newDesignFiles = [];
		if (files && files.length > 0) {
			for (let index = 0; index < files.length; index++) {
				const file = files[index];
				if (this.digitalDesigns.includes(file.name)) {
					this.toastService.showWarning(`${file.name} has the same name as an existing file. Remove existing file to add new file`);
				}
				else {
					newDesignFiles.push(file);
					this.digitalDesigns.push(file.name);
				}
			}
			if (newDesignFiles.length > 0) {
				await this.uploadDigitalDesigns(newDesignFiles);
			}
		}
	}

	async fileHandler($event: any) {
		const files = $event.files;
		await this.onFileDropped(files);
	}

	getFilePreview(file: Blob): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = error => reject(error);
		});
	}

	async download(filename: string) {
		try {
			this.loading = true;
			const key = `${this.orderLayoutService.orderId}/${this.orderItemId}/design/${filename}`;
			const download = await this.orderService.getOrderFile(this.orderLayoutService.orderId, key);
			if (download) {
				saveAs(download.data, filename);
			}
			this.toastService.showSuccess('File downloaded successfully');
		}
		catch (error) {
			this.toastService.showError('Unable to download file');
		}
		finally {
			this.loading = false;
		}
	}

	openDeleteModal(type: string) {
		this.designFileTypeToDelete = type;
		if (type === 'front') {
			this.designFileToDelete = this.frontFileName;
		}
		else if (type === 'back') {
			this.designFileToDelete = this.backFileName;
		}

		this.modalService.open(this.deleteDesignModal);
	}
	async deleteDesignFile() {
		try {
			this.modalService.dismissAll();
			this.loading = true;
			await this.orderService.deleteDesignImage(this.orderItemId, this.designFileToDelete!);
			await this.orderLayoutService.reloadOrderItemCartProduct();
			this.toastService.showSuccess('File deleted successfully');
		}
		catch (error) {
			this.toastService.showError('Unable to delete file');
		}
		finally {
			this.loading = false;
		}
	}
	closeModal() {
		this.modalService.dismissAll();
	}
	async deleteDigitalDesign(filename: string) {
		try {
			this.loading = true;
			await this.orderService.deleteDesignImage(this.orderItemId, filename);
			this.digitalDesigns = this.digitalDesigns.filter(d => !d.includes(filename));
			this.toastService.showSuccess('File deleted successfully');
		}
		catch (error) {
			this.toastService.showError('Unable to delete file');
		}
		finally {
			this.loading = false;
		}
	}

	async downloadFile(side: string) {
		try {
			this.loading = true;
			switch (side) {
				case 'front':
					if (this.orderItemCart?.design?.uSelectDesign === USelectDesignType.Template ||
						this.orderItemCart?.design?.front?.designSelectionType?.toLowerCase() === (USelectDesignType.Template || 'template')) {
						const frontFileUrl = `${environment.templateServerUrl}/templates/Full/${this.template?.frontImage}`;
						saveAs(frontFileUrl, this.frontFileName);
					}
					else {
						const frontFile = await this.orderService.getFrontImage(this.orderLayoutService.orderItemId);
						if (frontFile) {
							saveAs(frontFile, this.frontFileName);
						}
					}
					break;
				case 'back':
					if (this.orderItemCart?.design?.uSelectDesign === USelectDesignType.Template ||
						this.orderItemCart?.design?.front?.designSelectionType?.toLowerCase() === (USelectDesignType.Template || 'template')) {
						const backFileUrl = `${environment.templateServerUrl}/templates/Full/${this.template?.backImage}`;
						saveAs(backFileUrl, this.backFileName);
					}
					else {
						const backFile = await this.orderService.getBackImage(this.orderLayoutService.orderItemId);
						if (backFile) {
							saveAs(backFile, this.backFileName);
						}
					}

					break;
				default:
					this.toastService.showWarning('File does not exist', 'Warning');
			}
		}
		catch (error) {
			this.toastService.showError('Unable to download file');
		}
		finally {
			this.loading = false;
		}
	}

	async getFileOrientationFromMeta(artSide: string) {
		try {
			const returnedValue = await this.orderService.getFileOrientation(this.orderLayoutService.orderItemId, artSide);
			if (!!returnedValue) {
				return this.setOrientationValueFromMeta(returnedValue);
			}
		}
		catch (error) {
			console.log(error);
		}
		return 'Horizontal';
	}

	setOrientationValueFromMeta(returnedValue: string): string {
		switch (parseInt(returnedValue)) {
			case 1:
				return 'Horizontal';
				break;
			case 2:
				return 'Vertical';
				break;
			default:
				return 'Horizontal';
		}
	}

	resetJobArtSection() {
		this.frontFilePath = '';
		this.backFilePath = '';
		this.frontPreview = undefined;
		this.backPreview = undefined;
		this.frontFile = undefined;
		this.backFile = undefined;
		this.uploadButtonText = 'Set Orientation';
		this.newFrontIsPDF = false;
		this.newBackIsPDF = false;
		this.frontFileName = undefined;
		this.backFileName = undefined;
	}

	async checkForShipping() {
		if (!!this.orderItemCart) {
			if (this.orderItemCart?.shipments?.length) {
				const ecShipment = this.orderItemCart.shipments.find(shipment => shipment.type === 'ExtraCopies');
				if (!ecShipment) {
					return;
				}
				const addressId = ecShipment.addressId;
				this.extraCopiesQty = ecShipment.quantity;
				this.shippingAddressDeleted = (await this.customerService.getCustomerShippingAddress(addressId)).deleted ?? false;
			}
			else {
				const shippingAddress = await this.customerAddressService.getCustomerAddress(this.orderLayoutService.selectedCustomer?.customerID!, this.orderItemCart.shippingAddressId!);
				this.shippingAddressDeleted = shippingAddress.deleted;
				if (this.shippingAddressDeleted) {
					this.shippingAddressDeletedDate = shippingAddress.deletedDate!;
				}
				if (shippingAddress.phoneNumber === '' || !(!!shippingAddress.phoneNumber)) {
					this.shippingAddressMissingPhoneNumber = true;
				}
				if (this.orderItemCart.uSelectId !== USelect.printAndShip) {
					this.extraCopiesQty = this.orderItemCart.quantity;
				}
			}
		}
	}

	reExportDesignHuddleProject() {
		this.reExportLoading = true;
		this.designHuddleService.reExportCustomerProject(new ReExportProjectRequest({
			orderId: this.orderLayoutService.orderId,
			orderItemId: this.orderLayoutService.orderItemId,
			projectId: this.orderItemCart?.design?.designHuddleProjectId
		}));
	}

	changeDesignHuddleProject() {
		this.reExportLoading = true;
		if (this.projectId) {
			this.designHuddleService.updateProjectIdForReExport(new UpdateOrderProjectIdRequest({
				orderId: this.orderLayoutService.orderId,
				orderItemId: this.orderLayoutService.orderItemId,
				newProjectId: this.projectId
			}));
		}
		this.orderItemCart!.design!.designHuddleProjectId! = this.projectId;
		this.closeModals();
	}

	openChangeDesignHuddleProjectModal() {
		this.modalService.open(this.designHuddleProjectsModal);
		this.loadingDesignHuddle = true;
		this.initializeAllProjects();
	}

	closeModals() {
		this.modalService.dismissAll();
	}

	async getToken() {
		this.token = await this.designHuddleService.getCustomerUserToken(this.orderLayoutService.selectedCustomer?.customerID!);
	}

	async initializeAllProjects() {
		this.loadingDesignHuddle = true;
		this.newProjectsList = [];
		this.projectsList = await this.designHuddleService.getCustomerProjects(new GetCustomerProjectRequest(
			{
				customerId: this.orderLayoutService.selectedCustomer?.customerID!,
				height: this.orderLayoutService.selectedOrderItemAsCartProduct?.paperHeight!,
				width: this.orderLayoutService.selectedOrderItemAsCartProduct?.paperWidth!,
				limit: 1000,
				page: 1,
				sourceTemplateId: 0,
				projectId: '',
				siteId: 0
			}
		));

		let projects = this.projectsList.data!.items!;
		projects.forEach(e => {
			this.newProjectsList.push({
				thumbnail: e.thumbnail_url!,
				title: e.project_title!,
				isSelected: false,
				projectId: e.project_id!,
				sourceTemplateCode: e.source_template?.source_template_code!,
				height: e.dimensions?.height!,
				width: e.dimensions?.width!,
				createdDate: e.date_created!,
				lastEditedDate: e.last_edited!
			}
			);
		});
		this.initializePageProjects();
	}

	initializePageProjects() {
		this.pageProjects = [];
		this.pageCount = Math.ceil(this.newProjectsList.length / ProjectsPerPage);
		let start = 0;
		let end = 0;

		start = this.currentPage * ProjectsPerPage;
		end = (this.currentPage + 1) * ProjectsPerPage;
		if (end > this.newProjectsList.length) {
			end = this.newProjectsList.length;
		}
		for (let i = start; i < end; i++) {
			this.pageProjects.push(this.newProjectsList[i]);
		}
		this.loadingDesignHuddle = false;
	}

	nextPage() {
		this.currentPage++;
		this.initializePageProjects();
	}

	previousPage() {
		this.currentPage--;
		this.initializePageProjects();
	}

	projectClass(project: Project): string {
		return project.isSelected ? 'project-wrapper-selected projectWrapperSelected' : 'projectWrapper';
	}

	selectButtonProjectClass(project: Project): string {
		return project.isSelected ? ' btn-success' : 'btn-primary';
	}

	selectProject(project: Project) {
		this.newProjectsList.forEach(e => {
			e.isSelected = false;
		});
		this.projectId = project.projectId;
		project.isSelected = true;
		this.initializePageProjects();
	}

	navigateToDownloadAssets() {
		this.router.navigateByUrl(`customers/${this.orderLayoutService.selectedCustomer?.customerID}/designs/${this.orderItemCart?.design?.designHuddleProjectId}`);
	}
}
