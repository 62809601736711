import { Component, OnInit } from '@angular/core';
import { SitesService } from 'services/sites.service';
import { SiteDetails } from '@taradel/admin-api-client';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-site-details',
	templateUrl: './site-details.component.html',
	styleUrls: ['./site-details.component.scss']
})
export class SiteDetailsComponent implements OnInit {

	loading = true;
	siteDetails = new SiteDetails();
	updatedSiteDetails = new SiteDetails();
	siteId?: number;
	valueToEdit = '';
	siteDetailsFound = false;
	editModeOn = false;

	constructor(
		private siteService: SitesService,
		private toastService: ToastService
	) { }

	ngOnInit() {
		this.siteService.currentSite.subscribe(async site => await this.getDetails(site?.siteId));
	}

	async getDetails(siteId?: number) {
		if (siteId) {
			try {
				this.loading = true;
				this.siteDetails = await this.siteService.getSiteDetails(siteId);
				if (this.siteDetails !== null) {
					this.siteId = siteId;
					this.updatedSiteDetails = Object.assign({}, this.siteDetails);
					this.siteDetailsFound = true;
				}
				else {
					this.siteDetailsFound = false;
					this.siteId = siteId;
					this.updatedSiteDetails = Object.assign({}, this.siteDetails);
				}
			}
			catch {
				this.toastService.showError('There was an error loading site details');
			}
			finally {
				this.loading = false;
			}
		}
	}

	editModeSelected(valueToEdit?: string) {
		if (!this.editModeOn) {
			this.editModeOn = true;
			this.valueToEdit = valueToEdit ?? '';
		}
	}

	async submitEditDetails() {
		try {
			this.loading = true;
			this.editModeOn = false;
			await this.siteService.updateSiteDetails(this.updatedSiteDetails);
			this.toastService.showSuccess('Site details updated successfully');
			await this.getDetails(this.siteId);
		}
		catch {
			this.toastService.showError('There was an error updating the site details');
		}
		finally {
			this.loading = false;
		}
	}

	cancelEditDetails() {
		this.editModeOn = false;
		this.updatedSiteDetails = Object.assign({}, this.siteDetails);
	}
}
