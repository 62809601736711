<div class="mb-4 row">
    <div class="col">
		<label for="distSearch">Suppress Distribution</label>
		<div class="form-floating">
			<input id="distSearch" type="text" class="form-control" [ngbTypeahead]="search" (focus)="focus$.next($any($event).target.value)"
				(selectItem)="select($event)" #instance="ngbTypeahead" [resultTemplate]="rt" [inputFormatter]="formatter"
			/>
		</div>
    </div>
</div>
<div class="mb-3 row">
	<div class="col">
		<label>Enter a name for the new distribution</label>
		<div class="form-floating">
			<input type="text" name="newDistName" class="form-control" (input)="setNewDistName($any($event.target).value)" />
		</div>
	</div>
</div>

<ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>
