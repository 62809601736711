import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductOptionCategory } from '@taradel/web-api-client';
import { ProductsService } from 'services/products.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-category-options',
	templateUrl: './category-options.component.html',
	styleUrls: ['./category-options.component.scss']
})
export class CategoryOptionsComponent implements OnInit {
	loading = false;
	submitted = false;
	categoryId = 0;
	productOptionCategory?: ProductOptionCategory;

	constructor(
		private productsService: ProductsService,
		private toastService: ToastService,
		private route: ActivatedRoute) {
			this.categoryId = parseInt(route.snapshot.paramMap.get('categoryId') ?? '0', 10);
	}

	async ngOnInit() {
		try {
			this.loading =  true;
			this.productOptionCategory = await this.productsService.getProductOptionCategory(this.categoryId);
		}
		catch {
			this.toastService.showError('There was an error getting product option category');
		}
		finally {
			this.loading =  false;
		}
	}
}
