<div class="card p-3 mb-3">
	<app-spinner-with-overlay [showSpinner]="loading">
        <div class="row">
            <div class="col-12 text-end">
                <button class="btn btn-danger mx-1" (click)="deleteUSelectFromSite()" type="button">
                    Remove from Site
                </button>
                <button class="btn btn-primary mx-1" (click)="updateUSelectAddOnsOrder()" type="button">
                    Update USelect
                </button>
            </div>
        </div>

		<div class="card border-0 p-3">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th width="10%">USelect Add On ID</th>
						<th>USelect Add On Name</th>
						<th width="10%">USelect ID</th>
						<th>USelect Name</th>
						<th>Required</th>
						<th>Sort Order</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let addOn of detailedUSelectAddOns; let i = index;">
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="addOnUSelectId" min="1" class="form-control"
								[(ngModel)]="addOn.addOnUSelectId" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="string" id="addOnUSelectName" class="form-control"
								[(ngModel)]="addOn.addOnUSelectName" disabled>
							</div>
						</td>
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="uSelectId" min="1" class="form-control"
								[(ngModel)]="addOn.uSelectId" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="string" id="uSelectName" class="form-control"
								[(ngModel)]="addOn.uSelectName" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<div class="input-group">
									<div class="form-check">
										<input type="checkbox" role="button" class="form-check-input" [disabled]="addOn.siteId !== this.siteId"
											[ngModel]="addOn.required" (change)="updateAddOnRequired(addOn)" />
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="number" id="pricePerPiece" min="1" class="form-control" [disabled]="addOn.siteId !== this.siteId"
								[(ngModel)]="addOn.sortOrder">
							</div>
						</td>
                        <td>
                            <li class="list-inline-item" *ngIf="addOn.siteId === this.siteId">
                                <button class="btn btn-danger btn-sm" (click)="removeSelectedAddOn(i)" data-toggle="tooltip"
                                    data-placement="top" Title="Delete">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </li>
							<span *ngIf="addOn.siteId !== this.siteId">Site 0 setting</span>
                        </td>
					</tr>
					<tr>
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="string" id="addOnUSelectId" class="form-control"
								[(ngModel)]="newUSelectAddOn!.addOnUSelectId" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<app-uselect-search #uSelectMethodListInput [array]="uSelectMethodList" (selectedMethod)="getMethod($event)"></app-uselect-search>
							</div>
						</td>
						<td width="10%">
							<div class="form-floating mb-3">
								<input type="number" id="uSelectId" min="1" class="form-control"
								[(ngModel)]="newUSelectAddOn!.uSelectId" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="string" id="uSelectName" class="form-control"
								[(ngModel)]="newUSelectAddOn!.uSelectName" disabled>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<div class="input-group">
									<div class="form-check">
										<input type="checkbox" role="button" class="form-check-input" [ngModel]="newUSelectAddOn!.required"
											(change)="setNewAddOnRequired()"/>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="form-floating mb-3">
								<input type="number" min="1" class="form-control"
								[(ngModel)]="newUSelectAddOn!.sortOrder">
							</div>
						</td>
						<td>
							<ul class="list-inline">
								<li class="list-inline-item">
									<button class="btn btn-success" (click)="addNewAddOn()" [disabled]="disableAddBtn">Add</button>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-spinner-with-overlay>
</div>
