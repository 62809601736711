import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderlayoutService } from 'services/orderlayout.service';
import { OrderService } from 'services/order.service';
import { ActivatedRoute, } from '@angular/router';
import { saveAs } from 'file-saver';
import { ToastService } from 'services/toast.service';
import { CreateInvoiceRequest, OrderSearch, OrderTag, OrderToInclude } from '@taradel/admin-api-client';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-order-layout',
	templateUrl: './order-layout.component.html',
	styleUrls: ['./order-layout.component.scss']
})

export class OrderLayoutComponent implements OnInit, OnDestroy {
	orderId: number = 0;
	downloadingFile = false;
	total = 0;
	salesRepId?: number;
	salesRepTag?: OrderTag;
	storeNumberTag?: OrderTag;
	customerRewardNumberTag?: OrderTag;
	widgetNoteMaxCount: number = 5;
	isSalesAdmin = false;
	route$ = new Subscription();
	numberOfFiles = 0;
	emailAddresses = '';
	invoiceNote = '';

	constructor(private route: ActivatedRoute,
		public modalService: NgbModal,
		public orderLayoutService: OrderlayoutService,
		public authService: AuthenticationService,
		private orderService: OrderService,
		private toastService: ToastService) {
	}

	async ngOnInit(): Promise<void> {
		this.route$ = this.route.paramMap.subscribe(async params => {
			this.orderId = parseInt(params.get('orderId') ?? '0', 10);
			await this.loadOrderFromService();
			this.emailAddresses = this.orderLayoutService.selectedCustomer?.emailAddress! + ';';
			this.numberOfFiles = Object.keys(this.orderLayoutService.selectedOrderFiles).length;
		});
		this.isSalesAdmin = await this.authService.hasRole('SalesAdmin');
	}

	ngOnDestroy(): void {
		this.route$.unsubscribe();
		this.orderLayoutService.unsubscribe();
	}

	getFiles(): string[] {
		return Object.keys(this.orderLayoutService.selectedOrderFiles);
	}

	async loadOrderFromService() {
		try {
			await this.orderLayoutService.loadOrder(this.orderId);
			await this.loadOrderTotals();
			this.salesRepId = this.orderLayoutService.selectedOrder?.salesRepId;
			this.salesRepTag = this.orderLayoutService.selectedOrderTags?.find(x => x.orderTagGroupId === 1);
			this.storeNumberTag = this.orderLayoutService.selectedOrderTags?.find(x => x.orderTagGroupId === 3);
			this.customerRewardNumberTag = this.orderLayoutService.selectedOrderTags?.find(x => x.orderTagGroupId === 4);
			await this.orderLayoutService.loadOrderFiles();
		}
		catch (error: any) {
			this.orderLayoutService.loading = false;
			this.toastService.showError('Error from OrderLayoutComponent: There was an error while loading order');
		}
	}

	async loadOrderTotals() {
		const search = new OrderSearch();
		search.pageNo = 1;
		search.pageSize = 20;
		search.customerId = this.orderLayoutService.selectedOrder!.customerId;
		this.total = await this.orderService.getCustomerOrdersCount(search);
	}

	async sendInvoice() {
		try {
			const emails = this.emailAddresses.split(/,| |;|\r\n|\n/);
			this.closeModal();
			this.orderLayoutService.loadingOrder = true;
			const request = new CreateInvoiceRequest({
				customerId: this.orderLayoutService.selectedOrder!.customerId,
				emailAddresses: emails,
				invoiceOrders: [new OrderToInclude({
					orderId: this.orderLayoutService.selectedOrder!.orderId,
					paymentAmount: this.orderLayoutService.orderBalance.value,
					notes: this.invoiceNote
				})]
			});
			await this.orderService.createInvoice(request);
			this.toastService.showSuccess('Invoice sent successfully');
		}
		catch {
			this.toastService.showError('Invoice sent failed');
		}
		finally {
			this.orderLayoutService.loadingOrder = false;
		}
	}

	async downloadFile(file: string, versionId?: string) {
		const fileName = file.substring(file.lastIndexOf('/') + 1);
		try {
			this.downloadingFile = true;
			const download = await this.orderService.getOrderVersionedFile(file, versionId);
			if (download) {
				saveAs(download.data, fileName);
			}
		}
		catch (err) {
			this.toastService.showError(`Could not download file ${fileName}`);
		}
		finally {
			this.downloadingFile = false;
		}
	}

	isTagInRepList(tag: string | undefined): boolean {
		if (!tag) {
			return true;
		}
		return this.orderLayoutService.salesReps.some(r => r.value === tag);
	}

	copyToClipboard(ventureOneNumber: string): void {
		if (ventureOneNumber !== '') {
			navigator.clipboard.writeText(ventureOneNumber).then(() => {
				/* clipboard successfully set */
				this.toastService.showInfo('VentureOne Number copied to clipboard', 'Success');
			}, (error) => {
				/* clipboard write failed */
				this.toastService.showError('There was an error copying the VentureOne Number to your clipboard', 'Error');
				console.log(error);
			});
		}
	}
	closeModal() {
		this.modalService.dismissAll();
	}
}
