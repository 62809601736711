import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SparkLineOptions } from 'components/shared/chart-spark-line/chart-spark-line.component';
import { groupBy, maxBy, merge, minBy, sumBy } from 'lodash';
import { ApexAxisChartSeries, ApexTheme, ChartComponent } from 'ng-apexcharts';
import { ReportsService } from 'services/reports.service';
import { ToastService } from 'services/toast.service';
import { ApexChartIntegerFormatter, ApexChartNumberFormatter, ApexChartNumberThousandFormatter } from 'app/third-party-integration/apexcharts';
import { PerformanceTimer } from '@taradel/white-label-common';
import { KpiDashboardReport } from '@taradel/admin-api-client';

const COLOR_BLUE = '#008FFB';
const COLOR_BLUE_ALT = '#2EC6F0';
const COLOR_GREEN = '#00E396';
const COLOR_GREEN_ALT = '#39F02E';
const COLOR_GOLD = '#FEB019';
const COLOR_GOLD_ALT = '#F07D1E';
const COLOR_PINK = '#FF4560';
const COLOR_PINK_ALT = '#F02EBF';
const COLOR_PURPLE = '#775DD0';
const COLOR_PURPLE_ALT = '#482EF0';

type ReportPartial = Partial<SparkLineOptions> & { theme?: ApexTheme}
type KpiDashboardReportAnnotated = KpiDashboardReport & { lineOfBusiness: 'Direct' | 'Strategic' | 'Other WL' | 'Canada', organizationId: number }

const reportAreaDefaults: Partial<SparkLineOptions> = {
	stroke: {
		curve: 'smooth',
		width: 3,
	},
	chart: {
		type: 'area',
		height: 220,
		toolbar: {
			show: false,
		},
	},
	tooltip: {
		enabled: true,
		fixed: {
			enabled: false
		},
		x: {
			show: true,
			format: 'dd MMM yyyy'
		},
		y: {
			title: {
				formatter: function (seriesName) {
					return "";
				}
			},
			formatter: function (value, { series, seriesIdx, dataPtIdx, w }) {
				return value.toFixed(0);
			}
		},
		marker: {
			show: true,
		}
	},
	colors: undefined,
};

const reportBarDefaults: Partial<SparkLineOptions> = {
	chart: {
		type: 'bar',
		height: 220,
		stacked: true,
		toolbar: {
			show: false,
		},
	},
	xaxis: {
		type: 'datetime'
	},
	stroke: {
		curve: 'smooth',
		width: 3,
	},
	colors: undefined,
};

const reportPieDefaults: Partial<SparkLineOptions> = {
	chart: {
		type: 'donut',
		height: 400,
		toolbar: {
			show: false,
		},
	},
};

const reportBrushDefaults: Partial<SparkLineOptions> = {
	chart: {
		type: 'area',
		height: 120,
		brush: {
			enabled: true,
			target: 'NOT SET',
			autoScaleYaxis: true,
		},
		selection: {
			enabled: true,
			fill: {
				color: "#fff",
				opacity: 0.4,
			},
		},
	},
	tooltip: {
		enabled: false,
	},
};

@Component({
	selector: 'app-kpi-report',
	templateUrl: './kpi-report.component.html',
	styleUrls: ['./kpi-report.component.scss'],
})
export class KPIReportComponent implements OnInit, AfterViewInit {
	@ViewChild('chartTotalOrdersByCountry') chartTotalOrdersByCountry!: ChartComponent;
	@ViewChild('chartTotalOrdersByCountryBrush') chartTotalOrdersByCountryBrush!: ChartComponent;
	@ViewChild('chartTotalRevenueByCountry') chartTotalRevenueByCountry!: ChartComponent;
	@ViewChild('chartTotalRevenueByCountryBrush') chartTotalRevenueByCountryBrush!: ChartComponent;
	@ViewChild('chartAverageOrderAmountByCountryPie') chartAverageOrderAmountByCountryPie!: ChartComponent;
	@ViewChild('chartTotalOrdersByLineOfBusinessPie') chartTotalOrdersByLineOfBusinessPie!: ChartComponent;
	@ViewChild('chartTotalRevenueByLineOfBusinessPie') chartTotalRevenueByLineOfBusinessPie!: ChartComponent;
	@ViewChild('chartAverageOrderAmountByLineOfBusinessPie') chartAverageOrderAmountByLineOfBusinessPie!: ChartComponent;
	@ViewChild('chartNewVsExistingCustomerOrders') chartNewVsExistingCustomerOrders!: ChartComponent;
	@ViewChild('chartNewVsExistingCustomerOrdersBrush') chartNewVsExistingCustomerOrdersBrush!: ChartComponent;
	@ViewChild('chartNewVsExistingCustomerOrdersByCountryPie') chartNewVsExistingCustomerOrdersByCountryPie!: ChartComponent;
	@ViewChild('chartNewVsExistingCustomerOrdersByLineOfBusinessPie') chartNewVsExistingCustomerOrdersByLineOfBusinessPie!: ChartComponent;
	@ViewChild('chartNewVsExistingCustomerRevenue') chartNewVsExistingCustomerRevenue!: ChartComponent;
	@ViewChild('chartNewVsExistingCustomerRevenueBrush') chartNewVsExistingCustomerRevenueBrush!: ChartComponent;
	@ViewChild('chartNewVsExistingCustomerRevenuePie') chartNewVsExistingCustomerRevenuePie!: ChartComponent;
	@ViewChild('chartNewVsExistingCustomerRevenueByCountryPie') chartNewVsExistingCustomerRevenueByCountryPie!: ChartComponent;
	@ViewChild('chartNewVsExistingCustomerRevenueByLineOfBusinessPie') chartNewVsExistingCustomerRevenueByLineOfBusinessPie!: ChartComponent;
	loading = false;
	paramStartDate: Date = new Date();
	paramStartDateZoom: Date = new Date();
	paramEndDate: Date = new Date();
	datasetMinDate?: Date;
	datasetMaxDate?: Date;

	reportTotalOrdersByCountry: ReportPartial = {
		series: [
			// {
			// 	name: 'Orders (US)',
			// 	type: 'area',
			// 	data: [],
			// },
			// {
			// 	name: 'Orders (CA)',
			// 	type: 'area',
			// 	data: [],
			// },
		],
		dataLabels: {
			enabled: true,
			formatter: ApexChartIntegerFormatter,
		},
		chart: {
			id: 'reportTotalOrdersByCountry',
			type: 'area',
			height: 220,
		},
		xaxis: {
			type: 'datetime'
		},
		yaxis: {
			labels: {
				formatter: ApexChartIntegerFormatter,
			},
		},
		stroke: {
			curve: 'smooth',
			width: 3,
		},
		colors: [COLOR_GOLD, COLOR_PINK],
	};
	reportTotalOrdersByCountryBrush: ReportPartial = {
		series: [],
		chart: {
			type: 'area',
			height: 120,
			brush: {
				target: 'reportTotalOrdersByCountry',
			},
		},
		tooltip: {
			enabled: false,
		},
		colors: [COLOR_GOLD, COLOR_PINK],
	};
	reportTotalRevenueByCountry: ReportPartial = {
		series: [
			{
				name: 'Revenue (US)',
				type: 'area',
				data: [],
			},
			{
				name: 'Revenue (CA)',
				type: 'area',
				data: [],
			},
		],
		dataLabels: {
			enabled: true,
			formatter: ApexChartNumberThousandFormatter,
		},
		chart: {
			id: 'reportTotalRevenueByCountry',
			type: 'area',
			height: 220,
		},
		xaxis: {
			type: 'datetime',
		},
		yaxis: {
			labels: {
				formatter: ApexChartNumberThousandFormatter,
			},
		},
		stroke: {
			curve: 'smooth',
			width: 3,
		},
		colors: [COLOR_GOLD, COLOR_PINK],
	};
	reportTotalRevenueByCountryBrush: ReportPartial = {
		series: [],
		chart: {
			type: 'area',
			height: 120,
			brush: {
				target: 'reportTotalRevenueByCountry',
			},
		},
		tooltip: {
			enabled: false,
		},
		colors: [COLOR_GOLD, COLOR_PINK],
	};
	reportNewVsExistingCustomerOrders: ReportPartial = {
		series: [
			{
				name: 'New Customer Orders',
				type: 'area',
				data: [],
			},
			{
				name: 'Existing Customer Orders',
				type: 'area',
				data: [],
			},
		],
		chart: {
			id: 'reportNewVsExistingCustomerOrders',
			type: 'area',
			height: 220,
			stacked: true,
		},
		dataLabels: {
			enabled: true,
			formatter: ApexChartNumberFormatter,
		},
		xaxis: {
			type: 'datetime',
		},
		yaxis: {
			labels: {
				formatter: ApexChartNumberFormatter,
			},
		},
		stroke: {
			curve: 'smooth',
			width: 3,
		},
		colors: [COLOR_BLUE, COLOR_GOLD, COLOR_PINK],
	};
	reportNewVsExistingCustomerOrdersBrush: ReportPartial = {
		series: [],
		chart: {
			type: 'area',
			height: 120,
			brush: {
				target: 'reportNewVsExistingCustomerOrders',
			},
		},
		tooltip: {
			enabled: false,
		},
		colors: [COLOR_BLUE, COLOR_GOLD, COLOR_PINK],
	};
	reportNewVsExistingCustomerRevenue: ReportPartial = {
		series: [
			{
				name: 'New Customer Revenue',
				type: 'area',
				data: [],
			},
			{
				name: 'Existing Customer Revenue',
				type: 'area',
				data: [],
			},
		],
		chart: {
			id: 'reportNewVsExistingCustomerRevenue',
			type: 'area',
			height: 220,
			stacked: true,
		},
		dataLabels: {
			enabled: true,
			formatter: ApexChartNumberFormatter,
		},
		xaxis: {
			type: 'datetime',
		},
		yaxis: {
			labels: {
				formatter: ApexChartNumberFormatter,
			},
		},
		stroke: {
			curve: 'smooth',
			width: 3,
		},
		colors: [COLOR_GREEN, COLOR_GOLD, COLOR_PINK],
	};
	reportNewVsExistingCustomerRevenueBrush: ReportPartial = {
		series: [],
		chart: {
			type: 'area',
			height: 120,
			brush: {
				target: 'reportNewVsExistingCustomerRevenue',
			},
		},
		tooltip: {
			enabled: false,
		},
		colors: [COLOR_GREEN, COLOR_GOLD, COLOR_PINK],
	};
	reportNewVsExistingCustomerOrdersByCountryPie: ReportPartial = {
		chart: {
			type: 'donut',
			height: 400,
		},
		series: [],
		labels: ['Label'], // set in data
		colors: [COLOR_BLUE, COLOR_GOLD, COLOR_PINK, COLOR_BLUE_ALT, COLOR_GOLD_ALT, COLOR_PINK_ALT],
	};
	reportNewVsExistingCustomerOrdersByLineOfBusinessPie: ReportPartial = {
		chart: {
			type: 'donut',
			height: 400,
		},
		series: [],
		labels: ['Label'], // set in data
		colors: [COLOR_BLUE, COLOR_GOLD, COLOR_PINK, COLOR_PURPLE, COLOR_BLUE_ALT, COLOR_GOLD_ALT, COLOR_PINK_ALT, COLOR_PURPLE_ALT],
	};
	reportNewVsExistingCustomerRevenuePie: ReportPartial = {
		chart: {
			type: 'donut',
			height: 400,
		},
		series: [],
		labels: ['Label'], // set in data
		colors: [COLOR_GREEN, COLOR_GOLD, COLOR_PINK],
	};
	reportNewVsExistingCustomerRevenueByCountryPie: ReportPartial = {
		chart: {
			type: 'donut',
			height: 400,
		},
		series: [],
		labels: ['Label'], // set in data
		colors: [COLOR_GREEN, COLOR_GOLD, COLOR_PINK, COLOR_GREEN_ALT, COLOR_GOLD_ALT, COLOR_PINK_ALT],
	};
	reportNewVsExistingCustomerRevenueByLineOfBusinessPie: ReportPartial = {
		chart: {
			type: 'donut',
			height: 400,
		},
		series: [],
		labels: ['Label'], // set in data
		colors: [COLOR_GREEN, COLOR_GOLD, COLOR_PINK, COLOR_PURPLE, COLOR_GREEN_ALT, COLOR_GOLD_ALT, COLOR_PINK_ALT, COLOR_PURPLE_ALT],
	};
	reportTotalOrdersByLineOfBusinessPie: ReportPartial = {
		chart: {
			type: 'donut',
			height: 400,
		},
		series: [],
		labels: ['Label'], // set in data
		colors: [COLOR_GREEN, COLOR_GOLD, COLOR_PINK, COLOR_PURPLE],
	};
	reportTotalRevenueByLineOfBusinessPie: ReportPartial = {
		chart: {
			type: 'donut',
			height: 400,
		},
		series: [],
		labels: ['Label'], // set in data
		colors: [COLOR_GREEN, COLOR_GOLD, COLOR_PINK, COLOR_PURPLE],
	};
	reportAverageOrderAmountByCountryPie: ReportPartial = {
		chart: {
			type: 'donut',
			height: 400,
		},
		series: [],
		labels: ['Revenue (US)', 'Revenue (CA)'],
		colors: [COLOR_GOLD, COLOR_PINK],
	};
	reportAverageOrderAmountByLineOfBusinessPie: ReportPartial = {
		chart: {
			type: 'donut',
			height: 400,
		},
		series: [],
		labels: ['Label'], // set in data
		colors: [COLOR_GREEN, COLOR_GOLD, COLOR_PINK, COLOR_PURPLE],
	};


	constructor(
		private reportsService: ReportsService,
		private toastService: ToastService,
	) {}

	ngOnInit() {
		this.paramStartDate = new Date(this.paramStartDate.getFullYear(), 0, 1);
		this.paramStartDateZoom = new Date(this.paramStartDate.getFullYear(), this.paramStartDate.getMonth(), 1);
		this.paramEndDate = new Date(this.paramEndDate.getFullYear(), this.paramEndDate.getMonth() + 1, 0);
	}

	ngAfterViewInit() {
		this.submit();
	}

	annotateLineOfBusiness(data: KpiDashboardReport[]): KpiDashboardReportAnnotated[] {
		const annotatedData: KpiDashboardReportAnnotated[] = data.map((origRow) => {
			const row = origRow as KpiDashboardReportAnnotated;
			if (row.instance === 'CA') {
				row.lineOfBusiness = 'Canada';
			}
			else if (row.organizationId === 1) {
				row.lineOfBusiness = 'Direct';
			}
			else if ([91,97,95,20,78,166,93,1210,165,41,39,178,194,143,132,36,112].includes(row.siteId)) { // hardcoded "Strategic" category, sorted by chaos
				row.lineOfBusiness = 'Strategic';
			}
			else {
				row.lineOfBusiness = 'Other WL';
			}
			return row;
		});
		return annotatedData;
	}

	initializeTotalOrdersByCountry(data: KpiDashboardReportAnnotated[], timer: PerformanceTimer) {
		timer.addCheckpoint('reportTotalOrdersByCountry', 'pregroup');
		this.reportTotalOrdersByCountry = merge({}, reportAreaDefaults, this.reportTotalOrdersByCountry);
		this.reportTotalOrdersByCountryBrush = merge({}, reportAreaDefaults, reportBrushDefaults, this.reportTotalOrdersByCountry, this.reportTotalOrdersByCountryBrush);
		const dataGrouped = groupBy(data, 'instance');
		const dataUS = dataGrouped['US']?.map((row) => {
			return {
				x: row.localDateTime,
				y: row.orderCount,
			};
		});
		const dataCA = dataGrouped['CA']?.map((row) => {
			return {
				x: row.localDateTime,
				y: row.orderCount,
			};
		});
		const dataJoined: ApexAxisChartSeries = [];
		if (dataUS) {
			dataJoined.push({ name: 'Orders (US)', type: 'area', data: dataUS });
		}
		if (dataCA) {
			dataJoined.push({ name: 'Orders (CA)', type: 'area', data: dataCA });
		}
		timer.addCheckpoint('reportTotalOrdersByCountry', 'postgroup');
		if (dataJoined.length) {
			this.reportTotalOrdersByCountry.series = dataJoined;
			this.reportTotalOrdersByCountryBrush.series = dataJoined;
			this.chartTotalOrdersByCountry.updateSeries(dataJoined);
			timer.addCheckpoint('reportTotalOrdersByCountry', 'chartTotalOrdersByCountry');
			this.chartTotalOrdersByCountry.zoomX(this.paramStartDateZoom.getTime(), this.paramEndDate.getTime());
			this.chartTotalOrdersByCountryBrush.updateSeries(dataJoined);
			timer.addCheckpoint('reportTotalOrdersByCountry', 'chartTotalOrdersByCountryBrush');
			if (this.datasetMinDate && this.datasetMaxDate) {
				this.chartTotalOrdersByCountryBrush.zoomX(this.datasetMinDate.getTime(), this.datasetMaxDate.getTime());
			}
		}
		console.log('initializeTotalOrdersByCountry', { report: this.reportTotalOrdersByCountry, chart: this.chartTotalOrdersByCountry});
	}

	initializeTotalRevenueByCountry(data: KpiDashboardReportAnnotated[], timer: PerformanceTimer) {
		timer.addCheckpoint('reportTotalRevenueByCountry', 'pregroup');
		this.reportTotalRevenueByCountry = merge({}, reportAreaDefaults, this.reportTotalRevenueByCountry);
		this.reportTotalRevenueByCountryBrush = merge({}, reportAreaDefaults, reportBrushDefaults, this.reportTotalRevenueByCountry, this.reportTotalRevenueByCountryBrush);
		const dataGrouped = groupBy(data, 'instance');
		const dataUS = dataGrouped['US']?.map((row) => {
			return {
				x: row.localDateTime,
				y: row.orderRevenue,
			};
		});
		const dataCA = dataGrouped['CA']?.map((row) => {
			return {
				x: row.localDateTime,
				y: row.orderRevenue,
			};
		});
		const dataJoined: ApexAxisChartSeries = [];
		if (dataUS) {
			dataJoined.push({ name: 'Revenue (US)', type: 'area', data: dataUS });
		}
		if (dataCA) {
			dataJoined.push({ name: 'Revenue (CA)', type: 'area', data: dataCA });
		}
		timer.addCheckpoint('reportTotalRevenueByCountry', 'postgroup');
		if (dataJoined.length) {
			this.reportTotalRevenueByCountry.series = dataJoined;
			this.reportTotalRevenueByCountryBrush.series = dataJoined;
			this.chartTotalRevenueByCountry.updateSeries(dataJoined);
			timer.addCheckpoint('reportTotalRevenueByCountry', 'chartTotalRevenueByCountry');
			this.chartTotalRevenueByCountry.zoomX(this.paramStartDateZoom.getTime(), this.paramEndDate.getTime());
			this.chartTotalRevenueByCountryBrush.updateSeries(dataJoined);
			timer.addCheckpoint('reportTotalRevenueByCountry', 'chartTotalRevenueByCountryBrush');
			this.chartTotalRevenueByCountryBrush.zoomX(this.datasetMinDate!.getTime(), this.datasetMaxDate!.getTime());
		}
		console.log('initializeTotalRevenueByCountry', { report: this.reportTotalRevenueByCountry, chart: this.chartTotalRevenueByCountry});
	}

	initializeNewVsExistingCustomerOrders(data: KpiDashboardReportAnnotated[], timer: PerformanceTimer) {
		timer.addCheckpoint('reportNewVsExistingCustomerOrders', 'pregroup');
		this.reportNewVsExistingCustomerOrders = merge({}, reportAreaDefaults, this.reportNewVsExistingCustomerOrders);
		this.reportNewVsExistingCustomerOrdersBrush = merge({}, reportAreaDefaults, reportBrushDefaults, this.reportNewVsExistingCustomerOrders, this.reportNewVsExistingCustomerOrdersBrush);
		const newCustomerOrders = data.map((row) => {
			return {
				x: row.localDateTime,
				y: row.newCustomerOrders,
			};
		});
		const newCustomerWindowOrders = data.map((row) => {
			return {
				x: row.localDateTime,
				y: row.newCustomerWindowOrders,
			};
		});
		const existingCustomerOrders = data.map((row) => {
			return {
				x: row.localDateTime,
				y: row.existingCustomerOrders,
			};
		});
		const dataJoined: ApexAxisChartSeries = [];
		if (newCustomerOrders) {
			dataJoined.push({ name: 'New Customers', data: newCustomerOrders });
		}
		if (newCustomerWindowOrders) {
			dataJoined.push({ name: 'New Customer Window', data: newCustomerWindowOrders });
		}
		if (existingCustomerOrders) {
			dataJoined.push({ name: 'Existing Customers', data: existingCustomerOrders });
		}
		timer.addCheckpoint('reportNewVsExistingCustomerOrders', 'postgroup');
		if (dataJoined.length) {
			this.reportNewVsExistingCustomerOrders.series = dataJoined;
			this.reportNewVsExistingCustomerOrdersBrush.series = dataJoined;
			this.chartNewVsExistingCustomerOrders.updateSeries(dataJoined);
			timer.addCheckpoint('reportNewVsExistingCustomerOrders', 'chartNewVsExistingCustomerOrders');
			this.chartNewVsExistingCustomerOrders.zoomX(this.paramStartDateZoom.getTime(), this.paramEndDate.getTime());
			this.chartNewVsExistingCustomerOrdersBrush.updateSeries(dataJoined);
			timer.addCheckpoint('reportNewVsExistingCustomerOrders', 'chartNewVsExistingCustomerOrdersBrush');
			if (this.datasetMinDate && this.datasetMaxDate) {
				this.chartNewVsExistingCustomerOrdersBrush.zoomX(this.datasetMinDate.getTime(), this.datasetMaxDate.getTime());
			}
		}
		console.log('initializeNewVsExistingCustomerOrders', { report: this.reportNewVsExistingCustomerOrders, chart: this.chartNewVsExistingCustomerOrders});
	}

	initializeNewVsExistingCustomerRevenue(data: KpiDashboardReportAnnotated[], timer: PerformanceTimer) {
		timer.addCheckpoint('reportNewVsExistingCustomerRevenue', 'pregroup');
		this.reportNewVsExistingCustomerRevenue = merge({}, reportAreaDefaults, this.reportNewVsExistingCustomerRevenue);
		this.reportNewVsExistingCustomerRevenueBrush = merge({}, reportAreaDefaults, reportBrushDefaults, this.reportNewVsExistingCustomerRevenue, this.reportNewVsExistingCustomerRevenueBrush);
		const newCustomerRevenue = data.map((row) => {
			return {
				x: row.localDateTime,
				y: row.newCustomerRevenue,
			};
		});
		const newCustomerWindowRevenue = data.map((row) => {
			return {
				x: row.localDateTime,
				y: row.newCustomerWindowRevenue,
			};
		});
		const existingCustomerRevenue = data.map((row) => {
			return {
				x: row.localDateTime,
				y: row.existingCustomerRevenue,
			};
		});
		const dataJoined: ApexAxisChartSeries = [];
		if (newCustomerRevenue) {
			dataJoined.push({ name: 'New Customers', data: newCustomerRevenue });
		}
		if (newCustomerWindowRevenue) {
			dataJoined.push({ name: 'New Customer Window', data: newCustomerWindowRevenue });
		}
		if (existingCustomerRevenue) {
			dataJoined.push({ name: 'Existing Customers', data: existingCustomerRevenue });
		}
		timer.addCheckpoint('reportNewVsExistingCustomerRevenue', 'postgroup');
		if (dataJoined.length) {
			this.reportNewVsExistingCustomerRevenue.series = dataJoined;
			this.reportNewVsExistingCustomerRevenueBrush.series = dataJoined;
			this.chartNewVsExistingCustomerRevenue.updateSeries(dataJoined);
			timer.addCheckpoint('reportNewVsExistingCustomerRevenue', 'chartNewVsExistingCustomerRevenue');
			this.chartNewVsExistingCustomerRevenue.zoomX(this.paramStartDateZoom.getTime(), this.paramEndDate.getTime());
			this.chartNewVsExistingCustomerRevenueBrush.updateSeries(dataJoined);
			timer.addCheckpoint('reportNewVsExistingCustomerRevenue', 'chartNewVsExistingCustomerRevenueBrush');
			if (this.datasetMinDate && this.datasetMaxDate) {
				this.chartNewVsExistingCustomerRevenueBrush.zoomX(this.datasetMinDate.getTime(), this.datasetMaxDate.getTime());
			}
		}
		console.log('initializeNewVsExistingCustomerRevenue', { report: this.reportNewVsExistingCustomerRevenue, chart: this.chartNewVsExistingCustomerRevenue});
	}

	initializeNewVsExistingCustomerRevenuePie(data: KpiDashboardReportAnnotated[]) {
		this.reportNewVsExistingCustomerRevenuePie = merge({}, reportPieDefaults, this.reportNewVsExistingCustomerRevenuePie);
		const newCustomerRevenue = sumBy(data, 'newCustomerRevenue');
		const newCustomerWindowRevenue = sumBy(data, 'newCustomerWindowRevenue');
		const existingCustomerRevenue = sumBy(data, 'existingCustomerRevenue');
		setTimeout(() => { // stupid hack because updating immediately doesn't reflect and we're already onNgAfterViewInit
			/**
			 * Alright there's a ton of ways to update chart data.
			 * 1. We could bind the [series] attribute on the <apx-chart> element
			 * 2. We can call .updateSeries() with an array of plain values
			 * 3. We can call .updateSeries() with an object { name: string, data: any[] }
			 * 4. We can call .appendSeries() with both forms of #2 or #3
			 * 5. We can call .updateOptions() passing a { series: [#3] }
			 * 6. We can call .appendData() with both forms of #2 or #3
			 *
			 * after significant trial and error, the method below seems to be one of the *only methods* that *actually works*
			 * on a donut or pie chart. I don't know why and I've wasted too much time to continue investigating, so let's just
			 * pray this all stays working.
			 */
			this.chartNewVsExistingCustomerRevenuePie.updateOptions({ labels: ['New Customers', 'New Customer Window', 'Existing Customers'] });
			this.chartNewVsExistingCustomerRevenuePie.updateSeries([newCustomerRevenue, newCustomerWindowRevenue, existingCustomerRevenue]);
			console.log('initializeNewVsExistingCustomerRevenuePie', { report: this.reportNewVsExistingCustomerRevenuePie, chart: this.chartNewVsExistingCustomerRevenuePie});
		}, 10);
	}

	initializeNewVsExistingCustomerRevenueByCountryPie(data: KpiDashboardReportAnnotated[]) {
		this.reportNewVsExistingCustomerRevenueByCountryPie = merge({}, reportPieDefaults, this.reportNewVsExistingCustomerRevenueByCountryPie);
		const dataUS = data.filter((row) => row.instance === 'US');
		const newCustomerRevenueUS = sumBy(dataUS, 'newCustomerRevenue');
		const newCustomerWindowRevenueUS = sumBy(dataUS, 'newCustomerWindowRevenue');
		const existingCustomerRevenueUS = sumBy(dataUS, 'existingCustomerRevenue');

		const dataCA = data.filter((row) => row.instance === 'CA');
		const newCustomerRevenueCA = sumBy(dataCA, 'newCustomerRevenue');
		const newCustomerWindowRevenueCA = sumBy(dataCA, 'newCustomerWindowRevenue');
		const existingCustomerRevenueCA = sumBy(dataCA, 'existingCustomerRevenue');

		setTimeout(() => { // stupid hack because updating immediately doesn't reflect and we're already onNgAfterViewInit
			this.chartNewVsExistingCustomerRevenueByCountryPie.updateOptions({ labels: [
				'[US] New Customers',
				'[US] New Customer Window',
				'[US] Existing Customers',
				'[CA] New Customers',
				'[CA] New Customer Window',
				'[CA] Existing Customers',
			] });
			this.chartNewVsExistingCustomerRevenueByCountryPie.updateSeries([
				newCustomerRevenueUS,
				newCustomerWindowRevenueUS,
				existingCustomerRevenueUS,
				newCustomerRevenueCA,
				newCustomerWindowRevenueCA,
				existingCustomerRevenueCA,
			]);
			console.log('initializeNewVsExistingCustomerRevenueByCountryPie', { report: this.reportNewVsExistingCustomerRevenueByCountryPie, chart: this.chartNewVsExistingCustomerRevenueByCountryPie});
		}, 10);
	}

	initializeNewVsExistingCustomerRevenueByLineOfBusinessPie(data: KpiDashboardReportAnnotated[]) {
		this.reportNewVsExistingCustomerRevenueByLineOfBusinessPie = merge({}, reportPieDefaults, this.reportNewVsExistingCustomerRevenueByLineOfBusinessPie);
		const seriesData: number[] = [];
		const seriesLabels: string[] = [];
		const dataGrouped = groupBy(data, 'lineOfBusiness');
		for (const lob of ['Direct', 'Strategic', 'Canada', 'Other WL']) {
			const newCustomerRevenue = sumBy(dataGrouped[lob], 'newCustomerRevenue');
			const newCustomerWindowRevenue = sumBy(dataGrouped[lob], 'newCustomerWindowRevenue');
			const existingCustomerRevenue = sumBy(dataGrouped[lob], 'existingCustomerRevenue');
			seriesData.push(newCustomerRevenue, newCustomerWindowRevenue, existingCustomerRevenue);
			seriesLabels.push(`${lob} New`, `${lob} New Window`, `${lob} Existing`);
		}
		setTimeout(() => { // stupid hack because updating immediately doesn't reflect and we're already onNgAfterViewInit
			this.chartNewVsExistingCustomerRevenueByLineOfBusinessPie.updateOptions({ labels: seriesLabels });
			this.chartNewVsExistingCustomerRevenueByLineOfBusinessPie.updateSeries(seriesData);
			console.log('initializeNewVsExistingCustomerRevenueByLineOfBusinessPie', { report: this.reportNewVsExistingCustomerRevenueByLineOfBusinessPie, chart: this.chartNewVsExistingCustomerRevenueByLineOfBusinessPie});
		}, 10);
	}

	initializeNewVsExistingCustomerOrdersByCountryPie(data: KpiDashboardReportAnnotated[]) {
		this.reportNewVsExistingCustomerOrdersByCountryPie = merge({}, reportPieDefaults, this.reportNewVsExistingCustomerOrdersByCountryPie);
		const dataUS = data.filter((row) => row.instance === 'US');
		const newCustomerOrdersUS = sumBy(dataUS, 'newCustomerOrders');
		const newCustomerWindowOrdersUS = sumBy(dataUS, 'newCustomerWindowOrders');
		const existingCustomerOrdersUS = sumBy(dataUS, 'existingCustomerOrders');

		const dataCA = data.filter((row) => row.instance === 'CA');
		const newCustomerOrdersCA = sumBy(dataCA, 'newCustomerOrders');
		const newCustomerWindowOrdersCA = sumBy(dataCA, 'newCustomerWindowOrders');
		const existingCustomerOrdersCA = sumBy(dataCA, 'existingCustomerOrders');

		setTimeout(() => { // stupid hack because updating immediately doesn't reflect and we're already onNgAfterViewInit
			this.chartNewVsExistingCustomerOrdersByCountryPie.updateOptions({ labels: [
				'[US] New Customers',
				'[US] New Customer Window',
				'[US] Existing Customers',
				'[CA] New Customers',
				'[CA] New Customer Window',
				'[CA] Existing Customers',
			] });
			this.chartNewVsExistingCustomerOrdersByCountryPie.updateSeries([
				newCustomerOrdersUS,
				newCustomerWindowOrdersUS,
				existingCustomerOrdersUS,
				newCustomerOrdersCA,
				newCustomerWindowOrdersCA,
				existingCustomerOrdersCA,
			]);
			console.log('initializeNewVsExistingCustomerOrdersByCountryPie', { report: this.reportNewVsExistingCustomerOrdersByCountryPie, chart: this.chartNewVsExistingCustomerOrdersByCountryPie});
		}, 10);
	}

	initializeNewVsExistingCustomerOrdersByLineOfBusinessPie(data: KpiDashboardReportAnnotated[]) {
		this.reportNewVsExistingCustomerOrdersByLineOfBusinessPie = merge({}, reportPieDefaults, this.reportNewVsExistingCustomerOrdersByLineOfBusinessPie);
		const seriesData: number[] = [];
		const seriesLabels: string[] = [];
		const dataGrouped = groupBy(data, 'lineOfBusiness');
		for (const lob of ['Direct', 'Strategic', 'Canada', 'Other WL']) {
			const newCustomerOrders = sumBy(dataGrouped[lob], 'newCustomerOrders');
			const newCustomerWindowOrders = sumBy(dataGrouped[lob], 'newCustomerWindowOrders');
			const existingCustomerOrders = sumBy(dataGrouped[lob], 'existingCustomerOrders');
			seriesData.push(newCustomerOrders, newCustomerWindowOrders, existingCustomerOrders);
			seriesLabels.push(`${lob} New`, `${lob} New Window`, `${lob} Existing`);
		}
		setTimeout(() => { // stupid hack because updating immediately doesn't reflect and we're already onNgAfterViewInit
			this.chartNewVsExistingCustomerOrdersByLineOfBusinessPie.updateOptions({ labels: seriesLabels });
			this.chartNewVsExistingCustomerOrdersByLineOfBusinessPie.updateSeries(seriesData);
			console.log('initializeNewVsExistingCustomerOrdersByLineOfBusinessPie', { report: this.reportNewVsExistingCustomerOrdersByLineOfBusinessPie, chart: this.chartNewVsExistingCustomerOrdersByLineOfBusinessPie});
		}, 10);
	}

	initializeTotalOrdersByLineOfBusinessPie(data: KpiDashboardReportAnnotated[]) {
		this.reportTotalOrdersByLineOfBusinessPie = merge({}, reportPieDefaults, this.reportTotalOrdersByLineOfBusinessPie);
		const dataGrouped = groupBy(data, 'lineOfBusiness');
		const dataDirect = sumBy(dataGrouped['Direct'], 'orderCount');
		const dataStrategic = sumBy(dataGrouped['Strategic'], 'orderCount');
		const dataCanada = sumBy(dataGrouped['Canada'], 'orderCount');
		const dataOtherWL = sumBy(dataGrouped['Other WL'], 'orderCount');
		setTimeout(() => { // stupid hack because updating immediately doesn't reflect and we're already onNgAfterViewInit
			this.chartTotalOrdersByLineOfBusinessPie.updateOptions({ labels: ['Direct', 'Strategic', 'Canada', 'Other WL'] });
			this.chartTotalOrdersByLineOfBusinessPie.updateSeries([dataDirect, dataStrategic, dataCanada, dataOtherWL]);
			console.log('initializeTotalOrdersByLineOfBusinessPie', { report: this.reportTotalOrdersByLineOfBusinessPie, chart: this.chartTotalOrdersByLineOfBusinessPie});
		}, 10);
	}

	initializeTotalRevenueByLineOfBusinessPie(data: KpiDashboardReportAnnotated[]) {
		this.reportTotalRevenueByLineOfBusinessPie = merge({}, reportPieDefaults, this.reportTotalRevenueByLineOfBusinessPie);
		const dataGrouped = groupBy(data, 'lineOfBusiness');
		const dataDirect = sumBy(dataGrouped['Direct'], 'orderRevenue');
		const dataStrategic = sumBy(dataGrouped['Strategic'], 'orderRevenue');
		const dataCanada = sumBy(dataGrouped['Canada'], 'orderRevenue');
		const dataOtherWL = sumBy(dataGrouped['Other WL'], 'orderRevenue');
		setTimeout(() => { // stupid hack because updating immediately doesn't reflect and we're already onNgAfterViewInit
			this.chartTotalRevenueByLineOfBusinessPie.updateOptions({ labels: ['Direct', 'Strategic', 'Canada', 'Other WL'] });
			this.chartTotalRevenueByLineOfBusinessPie.updateSeries([dataDirect, dataStrategic, dataCanada, dataOtherWL]);
			console.log('initializeTotalRevenueByLineOfBusinessPie', { report: this.reportTotalRevenueByLineOfBusinessPie, chart: this.chartTotalRevenueByLineOfBusinessPie});
		}, 10);
	}

	initializeAverageOrderAmountByCountryPie(data: KpiDashboardReportAnnotated[]) {
		this.reportAverageOrderAmountByCountryPie = merge({}, reportPieDefaults, this.reportAverageOrderAmountByCountryPie);
		const dataGrouped = groupBy(data, 'instance');
		const dataUS = sumBy(dataGrouped['US'], 'orderRevenue') / Math.max(1, sumBy(dataGrouped['US'], 'orderCount'));
		const dataCA = sumBy(dataGrouped['CA'], 'orderRevenue') / Math.max(1, sumBy(dataGrouped['CA'], 'orderCount'));
		setTimeout(() => { // stupid hack because updating immediately doesn't reflect and we're already onNgAfterViewInit
			this.chartAverageOrderAmountByCountryPie.updateOptions({ labels: ['US', 'Canada'] });
			this.chartAverageOrderAmountByCountryPie.updateSeries([dataUS, dataCA]);
			console.log('initializeAverageOrderAmountByCountryPie', { report: this.reportAverageOrderAmountByCountryPie, chart: this.chartAverageOrderAmountByCountryPie});
		}, 10);
	}

	initializeAverageOrderAmountByLineOfBusinessPie(data: KpiDashboardReportAnnotated[]) {
		this.reportAverageOrderAmountByLineOfBusinessPie = merge({}, reportPieDefaults, this.reportAverageOrderAmountByLineOfBusinessPie);
		const dataGrouped = groupBy(data, 'lineOfBusiness');
		const dataDirect = sumBy(dataGrouped['Direct'], 'orderRevenue') / Math.max(1, sumBy(dataGrouped['Direct'], 'orderCount'));
		const dataStrategic = sumBy(dataGrouped['Strategic'], 'orderRevenue') / Math.max(1, sumBy(dataGrouped['Strategic'], 'orderCount'));
		const dataCanada = sumBy(dataGrouped['Canada'], 'orderRevenue') / Math.max(1, sumBy(dataGrouped['Canada'], 'orderCount'));
		const dataOtherWL = sumBy(dataGrouped['Other WL'], 'orderRevenue') / Math.max(1, sumBy(dataGrouped['Other WL'], 'orderCount'));
		setTimeout(() => { // stupid hack because updating immediately doesn't reflect and we're already onNgAfterViewInit
			this.chartAverageOrderAmountByLineOfBusinessPie.updateOptions({ labels: ['Direct', 'Strategic', 'Canada', 'Other WL'] });
			this.chartAverageOrderAmountByLineOfBusinessPie.updateSeries([dataDirect, dataStrategic, dataCanada, dataOtherWL]);
			console.log('initializeAverageOrderAmountByLineOfBusinessPie', { report: this.reportAverageOrderAmountByLineOfBusinessPie, chart: this.chartAverageOrderAmountByLineOfBusinessPie});
		}, 10);
	}

	async submit() {
		const timer = new PerformanceTimer('init');
		timer.start();
		this.loading = true;

		const data = await this.reportsService.getKPIDashboard(this.paramStartDate, this.paramEndDate);

		const minDate = minBy(data, 'localDateTime')?.localDateTime;
		if (minDate) {
			this.datasetMinDate = new Date(minDate);
		}
		const maxDate = maxBy(data, 'localDateTime')?.localDateTime;
		if (maxDate) {
			this.datasetMaxDate = new Date(maxDate);
		}

		if (reportBrushDefaults.chart?.selection?.xaxis && this.datasetMinDate && this.datasetMaxDate) {
			reportBrushDefaults.chart.selection.xaxis.min = this.datasetMinDate.getTime();
			reportBrushDefaults.chart.selection.xaxis.max = this.datasetMaxDate.getTime();
		}

		const annotatedData = this.annotateLineOfBusiness(data);
		timer.addCheckpoint('init', 'annotateLineOfBusiness');
		timer.addCheckpoint('init', 'before chart inits');
		this.initializeTotalOrdersByCountry(annotatedData, timer);
		timer.addCheckpoint('init', 'initializeTotalOrdersByCountry');
		this.initializeTotalRevenueByCountry(annotatedData, timer);
		timer.addCheckpoint('init', 'initializeTotalRevenueByCountry');
		this.initializeNewVsExistingCustomerOrders(annotatedData, timer);
		timer.addCheckpoint('init', 'initializeNewVsExistingCustomerOrders');
		this.initializeNewVsExistingCustomerRevenue(annotatedData, timer);
		timer.addCheckpoint('init', 'initializeNewVsExistingCustomerRevenue');
		this.initializeNewVsExistingCustomerRevenuePie(annotatedData);
		timer.addCheckpoint('init', 'initializeNewVsExistingCustomerRevenuePie');
		this.initializeNewVsExistingCustomerRevenueByCountryPie(annotatedData);
		timer.addCheckpoint('init', 'initializeNewVsExistingCustomerRevenueByCountryPie');
		this.initializeNewVsExistingCustomerRevenueByLineOfBusinessPie(annotatedData);
		timer.addCheckpoint('init', 'initializeNewVsExistingCustomerRevenueByLineOfBusinessPie');
		this.initializeNewVsExistingCustomerOrdersByCountryPie(annotatedData);
		timer.addCheckpoint('init', 'initializeNewVsExistingCustomerOrdersByCountryPie');
		this.initializeNewVsExistingCustomerOrdersByLineOfBusinessPie(annotatedData);
		timer.addCheckpoint('init', 'initializeNewVsExistingCustomerOrdersByLineOfBusinessPie');
		this.initializeTotalOrdersByLineOfBusinessPie(annotatedData);
		timer.addCheckpoint('init', 'initializeTotalOrdersByLineOfBusinessPie');
		this.initializeTotalRevenueByLineOfBusinessPie(annotatedData);
		timer.addCheckpoint('init', 'initializeTotalRevenueByLineOfBusinessPie');
		this.initializeAverageOrderAmountByCountryPie(annotatedData);
		timer.addCheckpoint('init', 'initializeAverageOrderAmountByCountryPie');
		this.initializeAverageOrderAmountByLineOfBusinessPie(annotatedData);
		timer.addCheckpoint('init', 'initializeAverageOrderAmountByLineOfBusinessPie');
		timer.end(true);

		this.loading = false;
	}
}
