<app-customer-header [customerId]="customerId">
	<ng-container altnav>
		<ul class="nav nav-pills justify-content-end">
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'distributions']">Back to Distributions</a>
			</li>
			<li class="nav-item">
				<a class="nav-link active" [routerLink]="['/customers', customerId, 'map']">Build Map</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'paste-list']">Paste Routes</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'upload-list']">Upload File</a>
			</li>
		</ul>
	</ng-container>
</app-customer-header>

<div class="row">
	<div class="col">
		<div class="card">
			<div class="card-body">
				<div class="input-group">
					<input appAutocompleteOff type="search" class="form-control" placeholder="Enter Address/Zip Code" ngx-gp-autocomplete #placesRef="ngx-places" #placesInput (onAddressChange)="handleAddressChange($event)" (keyup.enter)="selectRoutesBasedOnInput('loadRoute')">
					<button class="btn btn-primary" *ngIf="!mapLoaded" (click)="selectRoutesBasedOnInput('loadRoute')">Set Address</button>
					<button class="btn btn-primary" *ngIf="mapLoaded" (click)="selectRoutesBasedOnInput('droppin')" title="Add another pin to the map for location perspective">Drop Pin</button>
				</div>
			</div>
		</div>
	</div>
	<div class="col-auto">
		<div class="card">
			<div class="card-body">
				<button class="btn btn-primary" [disabled]="!mapLoaded || totalPrintSelections === 0 || loading" (click)="openModal(saveMapModal)">Save Map</button>
			</div>
		</div>
	</div>
</div>
<div class="row mt-2" *ngIf="mapLoaded">
	<div class="col-12">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-auto">
						<strong>Hover route to view details around {{mapAddress}},
							{{mapZipCode}}</strong>
					</div>
					<div class="col text-end">
						<div *ngIf="hovering && selectedTab === 'map'">
							<ul class="list-inline m-0">
								<li class="list-inline-item"><strong>{{hoveringFeature?.properties?.geocoderef}}</strong></li>
								<li class="list-inline-item"><strong>{{hoveringFeature?.properties?.city}},
										{{hoveringFeature?.properties?.state}}</strong></li>
								<li class="list-inline-item" *ngIf="hoveringRoutePercentMatch"><strong>{{hoveringRoutePercentMatch |
										percent}} match</strong></li>
								<li class="list-inline-item"><b>Total Mail: </b>{{hoveringFeature?.properties?.ressfdu + hoveringFeature?.properties?.resmfdu
									+ hoveringFeature?.properties?.bizcount + hoveringFeature?.properties?.boxcount |
									number}}</li>
								<li class="list-inline-item"><b>Res: </b>{{hoveringFeature?.properties?.ressfdu |
									number}}</li>
								<li class="list-inline-item"><b>Apt: </b>{{hoveringFeature?.properties?.resmfdu |
									number}}</li>
								<li class="list-inline-item"><b>Biz: </b>{{hoveringFeature?.properties?.bizcount |
									number}}</li>
								<li class="list-inline-item"><b>Box: </b>{{hoveringFeature?.properties?.boxcount |
									number}}</li>
								<li class="list-inline-item"><b>Avg HHIncome: </b>${{hoveringFeature?.properties?.avghhi |
									number:'1.0-0':'en'}}</li>
								<li class="list-inline-item"><b>Avg HHSize: </b>{{hoveringFeature?.properties?.avghhsize |
									number:'1.1-1':'en'}}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row mt-2">
	<div class="col-md-3">
		<div class="card" *ngIf="mapLoaded">
			<div class="card-header">
				<ul class="nav nav-tabs card-header-tabs">
					<li class="nav-item">
						<button class="nav-link active" data-bs-toggle="tab" data-bs-target="#selectionTab">Selections</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#estimateTab">Estimate</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#demographicsTab">Demographics</button>
					</li>
					<li class="nav-item">
						<button class="nav-link" data-bs-toggle="tab" data-bs-target="#radiusTab">Radius</button>
					</li>
				</ul>
			</div>
			<div class="tab-content">
				<div class="card-body tab-pane active" id="selectionTab" role="tabpanel">
					<h5 class="card-title">Selections</h5>
					<div class="widget widget-stats bg-teal mb-7px">
						<div class="stats-icon stats-icon-lg"><i class="fa fa-tally fa-fw"></i></div>
						<div class="stats-content">
							<div class="stats-title">Total Current Selections</div>
							<div class="stats-number">{{totalPrintSelections | number}}
								<span *ngIf="match"><br>({{ match }}% match)</span>
							</div>
							<div class="stats-desc">Includes residential<span *ngIf="useBusiness"> +
									business</span><span *ngIf="usePOBoxes"> + po box</span> deliveries</div>
						</div>
					</div>

					<div class="mb-3">
						<div><b>Delivery Types:</b></div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" value="" id="resCheck" disabled checked>
							<label class="form-check-label" for="resCheck">
								Residential
							</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="businessCheck" (click)="useBusinessAdresses()" value="option1" [(ngModel)]="useBusiness">
							<label class="form-check-label" for="businessCheck">Business</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="poBoxesCheck" (click)="hideShowPOBoxes()" value="option1" [(ngModel)]="usePOBoxes">
							<label class="form-check-label" for="poBoxesCheck">PO Boxes</label>
						</div>
					</div>

					<div class="row mb-3">
						<div class="col-12">
							<b>Breakdown</b>
						</div>
						<div class="col-sm-6">
							<div>Residential: {{totalHomesSelected | number}}</div>
							<div>Apartments: {{totalApartmentsSelected | number}}</div>
							<div *ngIf="useBusiness">Business: {{totalBusinessesSelected | number}}</div>
							<div *ngIf="usePOBoxes">PO Box: {{totalPOBoxesSelected | number}}</div>
						</div>
						<div class="col-sm-6"></div>
					</div>

					<div class="mb-3" *ngIf="pins.length > 0">
						<div><b>Additional Pins</b></div>
						<ul class="list-group">
							<li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let pin of pins">
								{{pin.getTitle()}}
								<button class="btn btn-xs btn-danger" (click)="removePin(pin)"><i class="fa fa-map-marker-slash"></i></button>
							</li>
						</ul>
					</div>

					<div class="mb-3 hstack gap-2">
						<button class="btn btn-danger" (click)="clearAllSelections()">Clear All Selections</button>
						<button class="btn btn-danger" (click)="reloadRoute()">Reset Map</button>
					</div>
					<div class="mb-3" *ngIf="previousSelectedFeatures && previousSelectedFeatures.length > 0">
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="checkbox" id="savedSpatials" [(ngModel)]="showSavedSpatials" (ngModelChange)="loadSavedSpatials()">
							<label class="form-check-label" for="savedSpatials">Load Saved Spatials (<b>Total Previous Selections - {{totalPreviousPrintSelections}}</b>)</label>
						</div>
					</div>
				</div>
				<div class="card-body tab-pane" id="estimateTab" role="tabpanel">
					<h5 class="card-title">Estimate</h5>
					<app-spinner-with-overlay [showSpinner]="estimateLoading">
						<!-- <div class="form-floating mb-3">
							<select class="form-select" id="inputGroupSelect01" #t (change)="loadSiteProducts(t.value)"
								[(ngModel)]="selectedSiteId">
								<option selected>Select Site</option>
								<option *ngFor="let site of activeSites" [value]="site.siteId">{{site.name}}</option>
							</select>
							<label>Select Site</label>
						</div> -->
						<app-sites-list [startingSiteId]="selectedSiteId" (siteSelected)="loadSiteProducts($event.siteId)" [sites]="activeSites"></app-sites-list>

						<div *ngIf="eddmProducts.length > 0">
							<div class="form-floating mb-3">
								<select class="form-select" id="inputGroupSelect01" #y (change)="loadProductDetails(y.value)">
									<option selected>Choose Product</option>
									<option *ngFor="let product of eddmProducts" [value]="product.baseProductId">
										{{getProductNameWithOrderDate(product)}}</option>
								</select>
								<label>Choose Product</label>
							</div>

							<h1>
								Estimated Cost
							</h1>
							<div class="lead" *ngIf="underMinimumSelections">
								Not enough addresses, select more routes to exceed minimum required selections
							</div>
							<div class="lead" *ngIf="!underMinimumSelections">Basic Package: {{basicPrice | currency}}</div>
							<div class="lead" *ngIf="!underMinimumSelections">Premium Package: {{premiumPrice | currency}}</div>
						</div>
					</app-spinner-with-overlay>
				</div>
				<div class="card-body tab-pane" id="demographicsTab" role="tabpanel">
					<h5 class="card-title">Demographics</h5>
					<div class="accordion" id="demographicsAccordion">
						<div class="accordion-item">
							<h2 class="accordion-header" id="householdIncomeHeading">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#householdIncomeCollapse" aria-expanded="true" aria-controls="householdIncomeCollapse">
									Household Income
								</button>
							</h2>
							<div id="householdIncomeCollapse" class="accordion-collapse collapse show" aria-labelledby="householdIncomeHeading" data-bs-parent="#demographicsAccordion">
								<div class="accordion-body">
									<div class="row">
										<div class="col-6" *ngFor="let incomeVal of householdIncome">
											<div class="form-check form-check-inline">
												<input class="form-check-input" type="checkbox" name="incomeOption" [id]="'incomeOpt' + incomeVal.key" [(ngModel)]="incomeVal.value">
												<label class="form-check-label" [for]="'incomeOpt' + incomeVal.key">{{incomeVal.key}}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<h2 class="accordion-header" id="ageHeading">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#ageCollapse" aria-expanded="true" aria-controls="ageCollapse">
									Median Age
								</button>
							</h2>
							<div id="ageCollapse" class="accordion-collapse collapse show" aria-labelledby="ageHeading" data-bs-parent="#demographicsAccordion">
								<div class="accordion-body">
									<div class="row">
										<div class="col-4" *ngFor="let age of medianAge">
											<div class="form-check form-check-inline">
												<input class="form-check-input" type="checkbox" name="ageOption" [id]="'ageOpt' + age.key" [(ngModel)]="age.value">
												<label class="form-check-label" [for]="'ageOpt' + age.key">{{age.key}}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<h2 class="accordion-header" id="homeOwnershipHeading">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#homeOwnershipCollapse" aria-expanded="true" aria-controls="homeOwnershipCollapse">
									Home Ownership
								</button>
							</h2>
							<div id="homeOwnershipCollapse" class="accordion-collapse collapse show" aria-labelledby="homeOwnershipHeading" data-bs-parent="#demographicsAccordion">
								<div class="accordion-body">
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="homeOpt" id="allOwnershipRadio" value="option1" [checked]="homeOwnership === ''" (click)="homeOwnership = ''">
										<label class="form-check-label" for="allOwnershipRadio">All</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="homeOpt" id="renterOwnershipRadio" value="option2" [checked]="homeOwnership === 'R'" (click)="homeOwnership = 'R'">
										<label class="form-check-label" for="renterOwnershipRadio">Renter</label>
									</div>
									<div class="form-check form-check-inline">
										<input class="form-check-input" type="radio" name="homeOpt" id="homeownerOwnershipRadio" value="option3" [checked]="homeOwnership === 'O'" (click)="homeOwnership = 'O'">
										<label class="form-check-label" for="homeownerOwnershipRadio">Homeowner</label>
									</div>
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<h2 class="accordion-header" id="lorHeading">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#lorCollapse" aria-expanded="true" aria-controls="lorCollapse">
									Length of Residence
								</button>
							</h2>
							<div id="lorCollapse" class="accordion-collapse collapse show" aria-labelledby="lorHeading" data-bs-parent="#demographicsAccordion">
								<div class="accordion-body">
									<div class="row">
										<div class="col-4" *ngFor="let lorVal of lengthOfResidence">
											<div class="form-check form-check-inline">
												<input class="form-check-input" type="checkbox" name="lorOption" [id]="'lorOpt' + lorVal.key" [(ngModel)]="lorVal.value">
												<label class="form-check-label" [for]="'lorOpt' + lorVal.key">{{lorVal.key}}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="input-group mt-3" *ngIf="demographicsSelected">
							<label class="form-check-label" for="childrenChildrenRadio">Select All Routes With Below Demographic Match</label>
							<div class="input-group">
								<input type="text" class="form-control mb-1" placeholder="Demographic Match Percentage" [(ngModel)]="selectAllDemosFloor">
								<button class="btn btn-secondary" type="button" (click)="selectRoutesBasedOnInput('selectDemographics')">Apply</button>
							</div>
						</div>
					</div>
					<div class="mt-3">
						<button class="btn btn-primary" [disabled]="loading" (click)="selectRoutesBasedOnInput('demographics')">Apply
							Demographics</button>
						<button class="btn btn-warning" *ngIf="demographicsSelected" (click)="clearHeatmap()">Clear
							Heatmap</button>
					</div>
				</div>
				<div class="card-body tab-pane" id="radiusTab" role="tabpanel">
					<h5 class="card-title">Radius/Drivetime</h5>
					<div class="mb-3">
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="inlineRadioOptions" id="radiusRadio" value="option1" [checked]="radiusDtSelected === 'radius'" (click)="radiusDtSelected = 'radius'">
							<label class="form-check-label" for="radiusRadio">Radius</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="inlineRadioOptions" id="drivetimeRadio" value="option2" [checked]="radiusDtSelected === 'drivetime'" (click)="radiusDtSelected = 'drivetime'">
							<label class="form-check-label" for="drivetimeRadio">Drivetime</label>
						</div>
					</div>
					<div class="input-group mb-3">
						<input type="text" class="form-control" [placeholder]="radiusDtSelected === 'radius' ? 'Radius in miles' : 'Drivetime in minutes'" [(ngModel)]="radiusDtVal">
						<span class="input-group-text" *ngIf="radiusDtSelected ==='radius'">miles</span>
						<span class="input-group-text" *ngIf="radiusDtSelected !=='radius'">minutes</span>
					</div>
					<button class="btn btn-primary" (click)="selectRoutesBasedOnInput(radiusDtSelected)">Apply
						Radius/Drivetime</button>

					<div class="mt-4 row" *ngIf="radiusActive">
						<div class="col-sm-12"><button class="btn btn-secondary" (click)="selectRoutesBasedOnInput('selectWithin')">
								Select All Routes in Radius
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card" *ngIf="!mapLoaded">
			<div class="card-body">
				<h5 class="card-title">Start mapping</h5>
				<p>Start building a map by first entering an address and clicking the Set Address button.</p>
			</div>
		</div>
	</div>
	<div class="col-md-9">
		<app-spinner-with-overlay [showSpinner]="loading">
			<div class="ratio ratio-21x9">
				<google-map width="100%" height="100%" [center]="center" [zoom]="zoom" [options]="mapOptions">
				</google-map>
			</div>
		</app-spinner-with-overlay>
	</div>
</div>

<ng-template #saveMapModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Save Map</h4>
		<button type="button" class="btn-close" aria-label="Close" [disabled]="loading" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div class="form-floating">
			<input type="text" class="form-control" placeholder="Map Name" [(ngModel)]="mapName" (keyup.enter)="saveMap()" />
			<label>Map Name</label>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-link" [disabled]="loading" (click)="modal.close('Cancel Click')">Cancel</button>
		<button type="button" class="btn btn-outline-dark" [disabled]="mapName === '' || loading" (click)="saveMap()">Save</button>
	</div>
</ng-template>
