import { Component, EventEmitter, Output, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerSearch, CustomerSearchResult } from '@taradel/admin-api-client';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CustomerService } from 'services/customer.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent {

	customers: CustomerSearchResult[] = [];
	selectedCustomer?: CustomerSearchResult;
	deleteAfterTransfer = false;
	focus$ = new Subject<string>();
	click$ = new Subject<string>();

	@Output() initiateTransfer = new EventEmitter<{ customerId: number, deleteData: boolean }>();
	@Input() deleteMessage: string = '';

	constructor(
		public modalService: NgbModal,
		private customerService: CustomerService
	) {	 }

	closeModal() {
		this.modalService.dismissAll();
	}

	searchCustomers: OperatorFunction<string, readonly CustomerSearchResult[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(async term => {
				let query = {
					emailAddress: term,
					pageNo: 1,
					pageSize: 50
				} as CustomerSearch;
				this.customers = await this.customerService.getAllCustomers(query);
				return this.customers;
			})
		);

	customersFormatter = (x: { username: string }) => x.username;
	setCustomer(customer: any) {
		this.selectedCustomer = customer.item;
	}

	transfer() {
		this.initiateTransfer.emit({customerId: this.selectedCustomer!.customerId, deleteData: this.deleteAfterTransfer});
	}
}
