import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environment';
import { AddItemToCartQuery, FileResponse, ShoppingCart, ShoppingClient, BuildMyCartQuery } from '@taradel/admin-api-client';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

	private readonly shoppingClient: ShoppingClient;
	constructor(http: HttpClient) {
		this.shoppingClient = new ShoppingClient(http, environment.adminApiUrl);
	}

	async getShoppingCart(customerId: number, siteId: number): Promise<ShoppingCart> {
		return await lastValueFrom(this.shoppingClient.getShoppingCart(customerId, siteId));
	}

	async addShoppingCart(customerId: number, siteId: number, query: AddItemToCartQuery): Promise<void> {
		await lastValueFrom(this.shoppingClient.addItemToShoppingCart(customerId, siteId, query));

	}

	async removeShoppingCart(customerId: number, siteId: number, itemId: string): Promise<FileResponse | null> {
		return await lastValueFrom(this.shoppingClient.removeItemFromShoppingCart(customerId, siteId, itemId));
	}

	async emptyShoppingCart(customerId: number, siteId: number): Promise<FileResponse | null> {
		return await lastValueFrom(this.shoppingClient.emptyShoppingCart(customerId, siteId));
	}

	emptyDirectMailCart(siteId: number, customerId: number): Promise<FileResponse | null> {
		return lastValueFrom(this.shoppingClient.removeItemsWithDistributionFromCustomerCart(siteId, customerId));
	}

	buildCart(query: BuildMyCartQuery): Promise<FileResponse | null> {
		return lastValueFrom(this.shoppingClient.buildCustomerShoppingCart(query));
	}

	buildCustomerShoppingCart(query: BuildMyCartQuery) {
		return lastValueFrom(this.shoppingClient.buildCustomerShoppingCart(query));
	}

	async removeBundleFromCustomerCart(customerId: number, siteId: number, bundleId: number) {
		return await lastValueFrom(this.shoppingClient.removeBundleFromCustomerCart(customerId, siteId, bundleId));
	}
}
