<app-customer-header [customerId]="customerId"></app-customer-header>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="card col-lg-8 col-12 p-3 mb-3">
			<div class="card-header">
				Customer Payment Attempt Details
			</div>
			<div class="card-body tab-pane fade show active" id="paymentDetailsView">
				<div class="row mb-3">
					<div class="col-md-6">
						<div class="card h-100">
							<div class="card-body">
								<table class="table table-sm table-hover">
									<tbody>
										<tr>
											<td><strong>Status:</strong></td>
											<td class="text-end"
											[ngClass]="{'text-success': customerPaymentAttempt?.status === cpaStatus.Success,
														'text-danger': customerPaymentAttempt?.status === cpaStatus.Failed,
														'text-warning': customerPaymentAttempt?.status === cpaStatus.Declined || customerPaymentAttempt?.status === cpaStatus.Pending }">
												<b>{{ customerPaymentAttempt?.status ?? '' }}</b>
											</td>
										</tr>
										<tr>
											<td><strong>Amount:</strong></td>
											<td class="text-end">{{ customerPaymentAttempt?.amount | currency }}</td>
										</tr>
										<tr>
											<td><strong>Created Date:</strong></td>
											<td class="text-end">{{ customerPaymentAttempt?.createdDate | date:'short' }}</td>
										</tr>
										<tr>
											<td><strong>Created By:</strong></td>
											<td class="text-end">{{ customerPaymentAttempt?.createdByName }}</td>
										</tr>
										<tr>
											<td><strong>Transaction Type:</strong></td>
											<td class="text-end">{{ customerPaymentAttempt?.transactionType }}</td>
										</tr>
										<tr>
											<td><strong>Last 4 digits</strong></td>
											<td class="text-end">{{ customerPaymentAttempt?.maskedPaymentData }}</td>
										</tr>
										<tr>
											<td><strong>Payment Ref:</strong></td>
											<td class="text-end">{{ customerPaymentAttempt?.paymentRef }}</td>
										</tr>
										<tr *ngIf="customerPaymentAttempt?.status && customerPaymentAttempt?.status === cpaStatus.Success">
											<td><strong>Response Code:</strong></td>
											<td class="text-end">{{ customerPaymentAttempt?.responseCode }}</td>
										</tr>
										<tr *ngIf="customerPaymentAttempt?.status && customerPaymentAttempt?.status === cpaStatus.Success">
											<td><strong>Response Sub-Code:</strong></td>
											<td class="text-end">{{ customerPaymentAttempt?.responseSubCode }}</td>
										</tr>
										<tr>
											<td><strong>Payment Profile:</strong></td>
											<td class="text-end">{{ customerPaymentAttempt?.customerPaymentProfileId }}</td>
										</tr>
										<tr *ngIf="customerPaymentAttempt?.status && customerPaymentAttempt?.status !== cpaStatus.Success">
											<td><strong>Decline Reason:</strong></td>
											<td class="text-end">{{ customerPaymentAttempt?.responseCode }} -- {{ customerPaymentAttempt?.responseSubCode }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="col-md-6" *ngIf="customerPaymentAttempt?.billingAddress">
						<strong>Billing Address:</strong>
						<br>
						<ng-container *ngIf="customerPaymentAttempt?.billingAddress?.firstName !== '' || customerPaymentAttempt?.billingAddress?.lastName !== ''">
							{{ customerPaymentAttempt?.billingAddress?.firstName }} {{ customerPaymentAttempt?.billingAddress?.lastName }}
							<br />
						</ng-container>
						<ng-container *ngIf="customerPaymentAttempt?.billingAddress?.company !== ''">
							{{ customerPaymentAttempt?.billingAddress?.company }}
							<br />
						</ng-container>
						<ng-container *ngIf="customerPaymentAttempt?.billingAddress?.phoneNumber !== ''">
							{{ customerPaymentAttempt?.billingAddress?.phoneNumber }} <br />
						</ng-container>
						{{ customerPaymentAttempt?.billingAddress?.address1 }} {{ customerPaymentAttempt?.billingAddress?.address2 }} <br />
						{{ customerPaymentAttempt?.billingAddress?.city }}, {{ customerPaymentAttempt?.billingAddress?.state }} {{ customerPaymentAttempt?.billingAddress?.zipCode }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-3">
		<div *ngIf="this.customerId > 0" class="col">
			<a [routerLink]="['/customers', this.customerId, 'payments']" [state]="{ tabId:'paymentAttemptsTab'}" class="btn btn-link p-0">See all customer payment attempts</a>
		</div>

		<div *ngIf="this.customerId === 0" class="col">
			<a [routerLink]="['/payments']" [state]="{ tabId:'paymentAttemptsTab'}" class="btn btn-link p-0">See all payment attempts</a>
		</div>
	</div>
</app-spinner-with-overlay>
