import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProductOption } from '@taradel/web-api-client';

@Component({
  selector: 'app-pcog-option',
  templateUrl: './pcog-option.component.html',
  styleUrls: ['./pcog-option.component.scss']
})
export class PcogOptionComponent {
	@Input() option!: ProductOption;
	@Output() removeOption = new EventEmitter<ProductOption>();

	emitRemoveOption() {
		this.removeOption.emit(this.option);
	}
}
