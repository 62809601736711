import { Component, Input } from '@angular/core';
import { SitesService } from 'services/sites.service';

@Component({
  selector: 'app-site-options-dropdown',
  templateUrl: './site-options-dropdown.component.html',
  styleUrls: ['./site-options-dropdown.component.scss']
})
export class SiteOptionsDropdownComponent {

	@Input() siteId = -1;
	constructor(public siteService: SitesService) {
	}

}
