<app-alert-box alertMode="info" *ngIf="!eddmStatus.length">
	<p>No matching orders found.</p>
</app-alert-box>
<div class="table-responsive" *ngIf="eddmStatus.length">
	<table class="table table-hover">
		<thead>
			<tr>
				<th>
					Rep
				</th>
				<th>
					Order Id
				</th>
				<th>
					Order Date
				</th>
				<th>
					Customer
				</th>
				<th>
					Job Name
				</th>
				<th>
					Product
				</th>
				<th>
					Total Drops
				</th>
				<th>
					Missing Drop Date
				</th>
				<th>
					Missing Distribution
				</th>
				<th>
					Next Drop
				</th>
				<th>
					Number of Pieces
				</th>
				<th>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let status of eddmStatus">
				<td>{{status.salesRep}}</td>
				<td>
					<a [routerLink]="['../orders/', status.orderID ,'summary']">{{status.orderID}}</a>
				</td>
				<td>{{status.orderDate | date: 'MM/dd/YYYY'}}</td>
				<td><a [routerLink]="['../customers/', status.customerID]">{{status.customerName}}</a></td>
				<td>{{status.jobDescription}}</td>
				<td>{{status.quantity | number}} X {{status.productName}}</td>
				<td>{{status.numberOfDrops}}</td>
				<td>{{status.numberOfDropsWithoutDate}}</td>
				<td>{{status.futureDropsWithoutDistribution}}</td>
				<td>{{processNextDropDate(status.nextDropDate)}}</td>
				<td>{{status.quantity | number}}</td>
				<td>
					<a class="btn btn-info" [routerLink]="['../orders/', status.orderID, 'details', status.orderItemID]"
						target="_blank" title="View Order"><i class="far fa-search"></i></a>
				</td>
			</tr>
		</tbody>
	</table>
</div>
