import { Component } from '@angular/core';
import { Drop } from '@taradel/web-api-client';
import { OrderlayoutService } from 'services/orderlayout.service';
import { NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'services/toast.service';
import { DirectMailProducts, PrintProductTypes } from 'services/sales-api.service';
import { NgbDateCustomParserFormatter } from 'app/ngb-date-custom-parser-formatter';
import { AuthenticationService } from 'services/authentication.service';

@Component({
  selector: 'app-addressed-mail-delivery-drops',
  templateUrl: './addressed-mail-delivery-drops.component.html',
  styleUrls: ['./addressed-mail-delivery-drops.component.scss'],
  providers:[
	  {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})
export class AddressedMailDeliveryDropsComponent {
	loading: boolean = false;
	numberOfDrops = 1;
	today = new Date();
	selectedDropNum ?: number;
	disableBtn = false;

	constructor(
		public orderLayoutService: OrderlayoutService,
		public modalService: NgbModal,
		public authService: AuthenticationService,
		private toastService: ToastService
	) { }

	getTotal(dropNumber: number): number {
		return this.orderLayoutService.selectedOrderItemDistributions[dropNumber - 1].distribution?.totalDeliveries ?? 0;
	}
	isInPast(drop: Drop): boolean {
		const now = new Date();
		if (drop.date !== undefined && drop.date < now) {
			return true;
		}
		return false;
	}

	async setDropDate(dropNumber: number, date: NgbDate) {

		try {
			this.loading = true;
			this.orderLayoutService.selectedOrderItemDropDateMap.set(dropNumber + 1, date);
			this.orderLayoutService.selectedOrderItemAsCartProduct!.drops!.orderDrops![dropNumber].date = new Date(date.year, date.month - 1, date.day);
			await this.orderLayoutService.getDropDeliveryRange();
		}
		catch (error) {
			this.toastService.showError(`Getting Drop delivery range failed - ${error}`);
		}
		finally {
			this.loading = false;
		}
    }

	async updateDropDate(dropNumber: number) {
		this.disableBtn = true;
		const cartProduct = this.orderLayoutService.selectedOrderItemAsCartProduct!;
		const drops = cartProduct.drops;
		if (drops) {
			if (cartProduct.uSelectId) {
				if (DirectMailProducts.findIndex(a => a === cartProduct.uSelectId) !== -1) {
					if ((drops.returnAddressId ?? 0) === 0 && (
							(drops.returnAddress1?.length ?? 0) === 0 ||
							(drops.returnCity?.length ?? 0) === 0 ||
							(drops.returnState?.length ?? 0) === 0 ||
							(drops.returnZip?.length ?? 0) === 0
						)) {
						this.toastService.showError('Please specify a return address');
						this.disableBtn = false;
						return;
					}
				}
			}
			else {
				if (PrintProductTypes.findIndex(a => a === cartProduct.type) !== -1) {
					if (
						(drops.returnAddress1?.length ?? 0) === 0 ||
						(drops.returnCity?.length ?? 0) === 0 ||
						(drops.returnState?.length ?? 0) === 0 ||
						(drops.returnZip?.length ?? 0) === 0
					) {
						this.toastService.showError('Fix your return address');
						this.disableBtn = false;
						return;
					}
				}
			}
		}

		const missingDropDates = drops?.orderDrops?.some(x => x.number === dropNumber && (x.date === undefined || isNaN(x.date.getDate())));
		if (missingDropDates) {
			this.toastService.showError('Specify drop dates for your drop(s)');
			this.disableBtn = false;
			return;
		}

		try {
			this.loading = true;
			await this.orderLayoutService.updateOrderItemDrops(dropNumber);
			this.toastService.showSuccess(`Drop ${dropNumber} updated successfully`);
			this.loading = false;
		}
		catch (error) {
			this.toastService.showError(`Drop ${dropNumber} updated failed`);
		}
		finally {
			this.disableBtn = false;
			this.loading = false;
		}
	}

	async updateDropDistribution(distributionId: number) {
		try {
			this.loading = true;
			await this.orderLayoutService.updateOrderItemDropDistribution(distributionId, this.selectedDropNum!);
			this.toastService.showSuccess('Distribution updated successfully');
		}
		catch (error: any) {
			if (error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError('There was a problem updating the order', 'Load Error');
			}
			console.log(error);
		}
		 finally {
			this.loading = false;
		}
	}

}
