import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CpaStatus, CustomerPaymentAttemptDisplay } from '@taradel/admin-api-client';
import { environment } from 'environment';
import { PaymentsService } from 'services/payments.service';
import { ToastService } from 'services/toast.service';

@Component({
  selector: 'app-payment-attempt-details',
  templateUrl: './payment-attempt-details.component.html',
  styleUrls: ['./payment-attempt-details.component.scss']
})
export class PaymentAttemptDetailsComponent implements OnInit {
	loading = false;
	customerId = 0;
	customerPaymentAttemptId = 0;
	customerPaymentAttempt?: CustomerPaymentAttemptDisplay;
	countryCode: 'US' | 'Canada' = 'US';
	public get cpaStatus(): typeof CpaStatus {
		return CpaStatus;
	}

	constructor(
		private route: ActivatedRoute,
		private paymentsService: PaymentsService,
		private toastService: ToastService
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		this.customerPaymentAttemptId = parseInt(route.snapshot.paramMap.get('customerPaymentAttemptId') ?? '0', 10);
		this.countryCode = environment.instance;
	}

	async ngOnInit() {
		try {
			this.loading = true;
			this.customerPaymentAttempt = await this.paymentsService.getPaymentAttempt(this.customerPaymentAttemptId);
		}
		catch {
			this.toastService.showError('There was an error loading customer payment attempt detais');
		}
		finally {
			this.loading = false;
		}
	}
}
