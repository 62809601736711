import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, FormGroup, FormArray } from '@angular/forms';
import { OrderRemainingBalance, GetOrderBalanceQuery, OrderPaymentQuery, RecurringPaymentsRequest, FinancePayment, PaymentProfile } from '@taradel/admin-api-client';
import { PaymentsService } from 'services/payments.service';
import { OrderService } from 'services/order.service';
import { ToastService } from 'services/toast.service';
import { SalesApiService } from 'services/sales-api.service';

@Component({
  selector: 'app-create-recurring-payment',
  templateUrl: './create-recurring-payment.component.html',
  styleUrls: ['./create-recurring-payment.component.scss']
})
export class CreateRecurringPaymentComponent implements OnInit {
	customerId?: number;
	loading = false;
	setPaymentAmount = true;
	definePaymentSchedule = false;
	reviewPaymentSchedule = false;
	createPaymentWithOrders?: boolean;
	remainingOrderBalances?: OrderRemainingBalance[];
	currentDate = new Date();
	minDate?: string;
	paymentSchedule?: FinancePayment[];
	paymentForm = this.formBuilder.group({
		paymentTotal: new FormControl(0, Validators.required),
		orderPayments: this.formBuilder.array([]),
		note: new FormControl(undefined, Validators.maxLength(255)),
		customerPaymentProfileId: new FormControl<string | undefined>(undefined, Validators.required)
	});
	paymentScheduleForm: FormGroup;
	customerPaymentProfiles: PaymentProfile[] = [];
	paymentGatewaySiteId?: number;
	selectAllOrders = true;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private paymentsService: PaymentsService,
		private orderService: OrderService,
		private salesApiService: SalesApiService,
		private toastService: ToastService,
		private formBuilder: UntypedFormBuilder) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);

		this.paymentScheduleForm = this.formBuilder.group({
			startDate: new FormControl<Date | undefined>(undefined, Validators.compose([Validators.required, this.dateValidator()])),
			frequency: new FormControl<number | undefined>(undefined, Validators.compose([Validators.required, Validators.min(1)])),
			interval: new FormControl<'day' | 'week' | 'month' | undefined>(undefined, Validators.required),
			numberOfOccurrences: new FormControl<number | undefined>(undefined, Validators.compose([Validators.required, Validators.min(1)]))
		});

		let tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		const tomorrowString = tomorrow.toISOString();
		const year = tomorrowString.substring(0, 4);
		const month = tomorrowString.substring(5, 7);
		const day = tomorrowString.substring(8, 10);
		this.minDate = `${year}-${month}-${day}`;
	}

	get control() {
		return this.paymentForm.controls;
	}

	get orderPayments() {
		return (this.paymentForm.controls['orderPayments'] as FormArray).controls as FormGroup[];
	}

	get scheduleControl() {
		return this.paymentScheduleForm.controls;
	}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		const orderToExclude: number[] = [];
		const query = {
			customerId: this.customerId,
			ordersToExclude: orderToExclude
		} as GetOrderBalanceQuery;
		this.remainingOrderBalances = await this.orderService.getCustomerRemainingOrderBalance(query);
		this.initializePaymentAmount();
		this.loading = false;
	}

	initializePaymentAmount() {
		if (!this.remainingOrderBalances) {
			return;
		}
		if (this.orderPayments.length <= 0) {
			this.loading = true;
			this.remainingOrderBalances?.forEach(b => {
				const orderPayment = this.formBuilder.group({
					order: new UntypedFormControl(b, Validators.required),
					paymentAmount: new UntypedFormControl(b.orderBalance, Validators.compose([Validators.required, this.paymentAmountValidator(b.orderBalance)])),
					selected: new UntypedFormControl(true)
				});
				this.orderPayments.push(orderPayment);
			});
		}
		this.loading = false;
		this.setPaymentAmount = true;
	}

	handleSelectAllOrders() {
		this.selectAllOrders = !this.selectAllOrders;
		if (this.selectAllOrders) {
			this.orderPayments.map(payment => payment.get('selected')?.setValue(true));
		}
		else {
			this.orderPayments.map(payment => payment.get('selected')?.setValue(false));
		}
	}

	getTotalPaymentAmount(): number {
		let total = 0;
		this.orderPayments.forEach(payment => {
			const orderPayment = payment as FormGroup;
			const selected = orderPayment.get('selected')?.value;
			const paymentAmount = orderPayment.get('paymentAmount')?.value;
			total += selected ? paymentAmount : 0;
		});
		this.control.paymentTotal.setValue(total);
		return total;
	}

	disablePaymentAmountInput(index: number) {
		const orderPayment = this.orderPayments.at(index) as FormGroup;
		if (orderPayment.get('selected')?.value === false) {
			orderPayment.get('paymentAmount')?.disable();
		}
		else {
			orderPayment.get('paymentAmount')?.enable();
		}
	}

	paymentAmountValidator(orderBalance: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			return (control.value <= orderBalance && control.value) > 0 ? null : { invalidPaymentAmount: true };
		};
	}

	resetPaymentSchedule() {
		this.paymentScheduleForm.reset();
		this.definePaymentSchedule = false;
		this.setPaymentAmount = true;
	}

	async generateRecurringPaymentSchedule() {
		if (!this.paymentScheduleForm.valid) {
			return;
		}
		let orderPayments: OrderPaymentQuery[] = [];
		this.orderPayments.forEach(payment => {
			if (payment.controls.selected.value === true) {
				const query = {
					orderId: payment.controls.order.value.orderId,
					paymentAmount: payment.controls.paymentAmount.value
				} as OrderPaymentQuery;
				orderPayments.push(query);
			}
		});
		const request = {
			customerId: this.customerId,
			startDate: this.scheduleControl.startDate.value,
			paymentFrequency: this.scheduleControl.frequency.value,
			interval: this.scheduleControl.interval.value,
			numberOfOccurrences: this.scheduleControl.numberOfOccurrences.value,
			paymentTotal: this.control.paymentTotal.value,
			paymentQuery: orderPayments
		} as RecurringPaymentsRequest;
		this.loading = true;
		let success = true;
		try {
			this.paymentSchedule = await this.paymentsService.getPaymentSchedule(request);
		}
		catch (err: any) {
			console.log(err);
			success = false;
			this.toastService.showError('Recurring payment schedule could not be generated');
		}
		finally {
			this.loading = false;
		}
		if (success) {
			await this.getCustomerPaymentProfiles();
			this.definePaymentSchedule = false;
			this.reviewPaymentSchedule = true;
		}
	}

	async createRecurringPayment() {
		if (!this.paymentForm.valid) {
			return;
		}
		let orderPayments: OrderPaymentQuery[] = [];
		this.orderPayments.forEach(payment => {
			if (payment.controls.selected.value === true) {
				const query = {
					orderId: payment.controls.order.value.orderId,
					paymentAmount: payment.controls.paymentAmount.value
				} as OrderPaymentQuery;
				orderPayments.push(query);
			}
		});
		const request = {
			customerId: this.customerId,
			startDate: this.scheduleControl.startDate.value,
			paymentFrequency: this.scheduleControl.frequency.value,
			interval: this.scheduleControl.interval.value,
			numberOfOccurrences: this.scheduleControl.numberOfOccurrences.value,
			paymentTotal: this.control.paymentTotal.value,
			paymentQuery: orderPayments,
			note: this.control.note.value,
			customerPaymentProfileId: this.control.customerPaymentProfileId.value
		} as RecurringPaymentsRequest;
		this.loading = true;
		let paymentId = 1;
		try {
			paymentId = await this.paymentsService.createRecurringPayment(request);
		}
		catch (err: any) {
			console.log(err);
			this.toastService.showError('Scheduled payments could not be created');
		}
		finally {
			this.loading = false;
		}
		if (paymentId > 0) {
			this.router.navigateByUrl(`/customers/${this.customerId}/payments`, { state: { tabId: 'recurringPaymentsTab' } });
		}
	}

	async getCustomerPaymentProfiles() {
		let paymentGatewaySiteId = 0;
		let siteIds: number[] = [];
		this.orderPayments.forEach(payment => {
			if (payment.get('selected')?.value === true) {
				const siteId = this.remainingOrderBalances?.find(b => b.orderId === payment.get('order')?.value.orderId)?.affiliateId;
				siteIds.push(siteId!);
			}
		});
		siteIds = [...new Set(siteIds.flat(1))];
		if (siteIds.length > 1) {
			this.toastService.showError('Only orders for sites that share a payment gateway can be placed');
			return;
		}
		paymentGatewaySiteId = siteIds[0];

		const customerProfile = await this.salesApiService.getCustomerProfile(this.customerId!);

		if (!customerProfile) {
			this.toastService.showError('Customer must have a valid payment profile in order to create a recurring payment');
			return;
		}

		this.customerPaymentProfiles = [];

		customerProfile.paymentProfiles?.forEach(x =>{
			if (this.customerPaymentProfiles.findIndex(a => a.creditCardMaskedData?.maskedCard === x.creditCardMaskedData?.maskedCard) === -1 ||
			this.customerPaymentProfiles.findIndex(a => a.achMaskedData?.accountNumber === x.achMaskedData?.accountNumber) === -1) {
				this.customerPaymentProfiles.push(x);
			}
		});
	}

	dateValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const value = control.value;

			if (!value) {
				return null;
			}

			let validMinDate = new Date();
			validMinDate.setDate(validMinDate.getDate() + 1);
			validMinDate.setHours(0, 0, 0, 0);

			const year = parseInt(value.substring(0, 4), 10);
			const month = parseInt(value.substring(5, 7), 10) - 1;
			const day = parseInt(value.substring(8, 10), 10);
			const selectedDate = new Date(year, month, day);

			let val = selectedDate < validMinDate ? { invalidDate: 'false' } : null;
			return val;
		};
	}
}
