import { Component, OnInit } from '@angular/core';
import { Product, ProductsSearch, USelectMethod } from '@taradel/admin-api-client';
import { ProductsService } from 'services/products.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';

@Component({
	selector: 'app-all-products',
	templateUrl: './all-products.component.html',
	styleUrls: ['./all-products.component.scss']
})
export class AllProductsComponent implements OnInit {
	pageNo = 1;
	pageSize = 100;
	isSearchEnabled = false;
	loading = false;
	total = 0;
	productsSearch: ProductsSearch = new ProductsSearch();
	uSelects: USelectMethod[] = [];
	products: Product[] = [];
	filtered = false;

	constructor(
		public productService: ProductsService,
		public sitesService: SitesService,
		private toastService: ToastService
	) { }

	async ngOnInit(): Promise<void> {
		try {
			this.loading = true;
			this.uSelects = await this.sitesService.getAllUSelect();
			this.productsSearch.name = '';
			await this.search();
		}
		catch {
			this.toastService.showError('There was an error loading the products');
		}
		finally {
			this.loading = false;
		}
	}

	async search() {
		this.loading = true;
		if (this.productsSearch.name !== '' || this.productsSearch.productId > 0 || this.productsSearch.uSelectId) {
			this.filtered = true;
		}
		else {
			this.filtered = false;
		}
		this.productsSearch.pageNo = this.pageNo = 1;
		this.productsSearch.pageSize = this.pageSize;
		this.total = await this.productService.getProductsCount(this.productsSearch);
		this.products = await this.productService.getAllProducts(this.productsSearch);
		if (this.total > 0) {
			this.isSearchEnabled = true;
		}
		else {
			this.isSearchEnabled = false;
		}
		this.loading = false;
	}

	resetFilter() {
		this.productsSearch = new ProductsSearch();
		this.productsSearch.name = '';
		this.search();
	}

	async pageChanged() {
		try {
			this.loading = true;
			this.productsSearch.pageNo = this.pageNo;
			this.productsSearch.pageSize = this.pageSize;
			this.products = await this.productService.getAllProducts(this.productsSearch);
		}
		catch {
			this.toastService.showError('There was an error loading the products');
		}
		finally {
			this.loading = false;
		}
	}
}
