import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiException, Bundle, SiteBundle } from '@taradel/admin-api-client';
import { Order } from '@taradel/web-api-client';
import { ChangeOrdersService } from 'services/change-orders.service';
import { OrderlayoutService } from 'services/orderlayout.service';
import { ToastService } from 'services/toast.service';


@Component({
  selector: 'app-update-order-bundle',
  templateUrl: './update-order-bundle.component.html',
  styleUrls: ['./update-order-bundle.component.scss']
})
export class UpdateOrderBundleComponent implements OnInit {
	loading = false;
	submitted = false;
	orderId?: number;
    bundleId?: number;
    bundle?: Bundle;
    originalBundle? : number;

	@Input() order?: Order;
	@Input() siteBundles: SiteBundle[] = [];
	@Output() updateOrderBundle = new EventEmitter<boolean>();

	constructor(private changeOrdersService: ChangeOrdersService,
		public orderLayoutService: OrderlayoutService,
		private toastService: ToastService
	) {	}

	ngOnInit(): void {
        this.orderLayoutService.selectedOrderLoaded.subscribe(order => {
            this.orderId = order?.orderId;
            if (order?.orderBundles && order.orderBundles.length > 0) {
				this.bundleId = order.orderBundles[0].bundleId;
			}
        });
	}

	async validateBundle() {
		this.loading = true;
		try {
			await this.changeOrdersService.validateOrderBundle(this.orderId!, this.bundleId!);
			this.toastService.showSuccess("Bundle is valid");
		}
		catch (ex: any) {
			if (ApiException.isApiException(ex)) {
				this.toastService.showError(ex.response);
			}
			else {
    	        this.toastService.showError("Error validating bundle");
			}
        }
		finally {
			this.loading = false;
		}
	}

	async updateBundle() {
		this.loading = true;
		try {
			await this.changeOrdersService.updateOrderBundle(this.orderId!, this.bundleId!);
			this.updateOrderBundle.emit(true);
		}
		catch (ex: any) {
			console.log(ex);
			this.toastService.showError(`Bundle ${this.bundleId} could not be added to this order`);
		}
		finally {
			this.loading = false;
		}
	}

	async saveChanges() {
		try {
            await this.updateBundle();
		}
		catch (ex: any) {
			console.log(ex);
		}
	}
}
