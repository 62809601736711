import { Component, OnInit } from '@angular/core';
import { MapDataService } from 'services/map-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'services/toast.service';
import { environment } from 'environment';

@Component({
	selector: 'app-map-upload-list',
	templateUrl: './map-upload-list.component.html',
	styleUrls: ['./map-upload-list.component.scss']
})
export class MapUploadListComponent implements OnInit {
	loading = false;
	customerId: number = 0;
	mapName: string = '';
	file?: File;
	isCanada = environment.instance === 'Canada';

	constructor(private route: ActivatedRoute,
		private router: Router,
		private mapdataApiService: MapDataService,
		private modalService: NgbModal,
		private toastService: ToastService) { }

	ngOnInit(): void {
		this.route.paramMap.subscribe(params => {
			this.customerId = parseInt(params.get('customerId') ?? '0', 10);
		});
	}

	onFileDropped(files: any) {
		if (files && files.length > 0) {
			this.file = files[0];
		}
	}

	fileHandler($event: any) {
		const files = $event.files;
		this.onFileDropped(files);
	}

	async upload(): Promise<void> {
		try {
			if (this.file !== undefined && this.mapName !== '') {
				this.loading = true;
				await this.mapdataApiService.uploadRoutesFromAdmin(this.mapName, this.file, this.customerId);

				this.mapName = '';
				this.file = undefined;
				this.router.navigate(['/customers/' + this.customerId + '/distributions']);
			}
		}
		catch (error: any) {
			if (error.status === 400) {
				this.toastService.showError(JSON.parse(error.response));
			}
			else {
				this.toastService.showError(`Upload file failed. Please check the file format`);
			}
		}
		finally {
			this.loading = false;
		}

	}

	openModal(modal: any) {
		this.modalService.open(modal, { windowClass: '', size: 'xl modal-dialog-scrollable' });
	}

	closeModals() {
		this.modalService.dismissAll();
	}
}
