import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiException, USelectMethod, SitePowerUp } from '@taradel/web-api-client';
import { Subscription } from 'rxjs';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';
import { UselectSearchComponent } from '../site-uselect-addons/uselect-search/uselect-search.component';

class NamedPowerUp {
	uSelectId!: number;
	uSelectName: string | undefined;
	sortOrder!: number;
}

@Component({
	selector: 'app-uselect-power-ups',
	templateUrl: './site-power-ups.component.html',
	styleUrls: ['./site-power-ups.component.scss']
})
export class SitePowerUpsComponent implements OnInit, OnDestroy {

	loading: boolean = false;
	sitePowerUps: NamedPowerUp[] = [];
	newPowerUp?: NamedPowerUp;
	uSelectMethodList: USelectMethod[] = [];
	currentSite$ = new Subscription();
	@ViewChild('uSelectMethodListInput') uselectSearchComponent!: UselectSearchComponent;

	constructor(
		private toastService: ToastService,
		private sitesService: SitesService) { }

	ngOnInit(): void {
		this.currentSite$ = this.sitesService.currentSite.subscribe(async site => {
			if (site !== undefined && site.siteId > 0) {
				try {
					this.loading = true;
					await this.getPowerUpsForSite(site.siteId);
					this.uSelectMethodList = [];

					const products = await this.sitesService.getSiteProducts(site.siteId);
					const uSelects = await this.sitesService.getAllUSelect();
					products.forEach(p => {
						const uSelect = uSelects.find(u => u.uSelectId === p.baseProduct?.uSelectConfigurations![0].uSelectId)!;
						if (this.uSelectMethodList.findIndex(t => t.uSelectId === uSelect.uSelectId) === -1) {
							this.uSelectMethodList.push(uSelect);
						}
					});
				}
				catch (error) {
					this.toastService.showError('There was an error getting the site power-ups');
					console.log(error);
				}
				finally {
					this.loading = false;
				}
			}
		});
		this.initializeNewPowerUp();
	}

	ngOnDestroy(): void {
		this.currentSite$.unsubscribe();
	}

	initializeNewPowerUp() {
		this.newPowerUp = new NamedPowerUp();
		if (this.uselectSearchComponent) {
			this.uselectSearchComponent.uSelect = undefined;
		}
	}

	getUSelectMethod(uSelectId: number) {
		return this.uSelectMethodList.find(t => t.uSelectId === uSelectId);
	}

	async getPowerUpsForSite(siteId: number) {
		this.sitePowerUps = [];
		const powerUps = await this.sitesService.getSitePowerUps(siteId);
		powerUps.map(x => this.sitePowerUps.push({
			uSelectId: x.uSelectId,
			uSelectName: this.sitesService.getUSelectName(x.uSelectId),
			sortOrder: x.sortOrder
		}));
	}

	async updatePowerUpsForSite() {
		if (this.sitePowerUps.some(powerUp => !powerUp.sortOrder)) {
			this.toastService.showError('Please check that each power up has a sort order');
			return;
		}

		try {
			this.loading = true;
			const updatedPowerUpsArray = this.sitePowerUps.map(powerUp => {
				delete powerUp.uSelectName;
				return {
					siteId: this.sitesService.currentSiteId,
					uSelectId: powerUp.uSelectId,
					sortOrder: powerUp.sortOrder
				} as SitePowerUp;
			});
			await this.sitesService.updateSitePowerUps(this.sitesService.currentSiteId, updatedPowerUpsArray);
			this.initializeNewPowerUp();
			await this.getPowerUpsForSite(this.sitesService.currentSiteId);
			this.toastService.showSuccess('Power ups successfully saved');
		}
		catch {
			this.toastService.showError('Power ups did not save');
		}
		finally {
			this.loading = false;
		}
	}

	removeSelectedPowerUps(index: number) {
		this.sitePowerUps.splice(index, 1);
	}

	addNewPowerUp() {
		this.sitePowerUps.push(this.newPowerUp!);
		this.initializeNewPowerUp();
	}

	async deletePowerUpsForSite() {
		this.loading = true;
		try {
			await this.sitesService.updateSitePowerUps(this.sitesService.currentSiteId, []);
			this.sitePowerUps = [];
		}
		catch (error: any) {
			if (ApiException.isApiException(error)) {
				this.toastService.showError(error.message);
			}
		}
		finally {
			this.loading = false;
		}
	}

	getUpdateMethod(item: { id: number, name: string }, i: number) {
		const matchingUSelect = this.sitePowerUps.find(x => x.uSelectId === item.id);
		if (matchingUSelect) {
			this.sitePowerUps[i].uSelectId = 0;
			this.toastService.showError('uSelect has already has been added');
			return;
		}
		this.sitePowerUps[i].uSelectId = item.id;
		this.sitePowerUps[i].uSelectName = item.name;
	}


	getMethod(item: { id: number, name: string }) {
		const matchingUSelect = this.sitePowerUps.find(x => x.uSelectId === item.id);
		if (matchingUSelect) {
			this.toastService.showError('uSelect has already has been added');
			return;
		}
		this.newPowerUp!.uSelectId = item.id;
		this.newPowerUp!.uSelectName = item.name;
	}
}
