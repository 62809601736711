<app-pagefullheader></app-pagefullheader>

<div class="card border-0 p-3">
	<div class="card mb-3">
		<div class="card-body">
			<div class="col-12">
				<ul class="list-inline p-0 m-0">
					<li class="list-inline-item">
						<button *ngIf="!showAllCategories" class="btn btn-primary" [disabled]="loading" (click)="showAllCategoriesFunction()">All Categories</button>
					</li>
					<li class="list-inline-item">
						<button *ngIf="showAllCategories" class="btn btn-primary" [disabled]="loading" (click)="addCategory()">Add Option Category</button>
					</li>
					<li class="list-inline-item">
						<button *ngIf="showEditCategories" class="btn btn-primary" [disabled]="loading" (click)="addOption()">Add Option</button>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<app-spinner-with-overlay [showSpinner]="loading">
		<div *ngIf="showAllCategories" class="table-responsive">
			<table class="table table-hover table-panel">
				<thead>
					<tr>
						<th>Number</th>
						<th>Name</th>
						<th>Total Items</th>
						<th>Description</th>
						<th>Display Type</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let category of allProductOptionCategories; let i = index" [attr.data-index]="i">
						<td>{{ category.optCatId }}</td>
						<td>
							{{category.name}}
						</td>
						<td>{{category.options?.length}}</td>
						<td>
							{{category.description}}
						</td>
						<td>
							{{category.displayType}}
						</td>
						<td>
							<ul class="list-inline p-0 m-0">
								<li class="list-inline-item">
									<button (click)="editCategory(category)" class="btn btn-info btn-sm"
									type="button" data-toggle="tooltip" data-placement="top" title="Edit"
									><i class="fa fa-edit"></i></button>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</app-spinner-with-overlay>

	<div *ngIf="showEditCategories" class="table-responsive">
		<table class="table table-hover table-panel">
			<thead>
				<tr>
					<th>Option Id</th>
					<th>Name</th>
					<th>Description</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let categoryOption of productOptionCategoryToEdit.options">
					<td>{{ categoryOption.optionId }}</td>
					<td>{{ categoryOption.name }}</td>
					<td>{{ categoryOption.description }}</td>
					<td>
						<ul class="list-inline p-0 m-0">
							<li class="list-inline-item">
								<button class="btn btn-info btn-sm" type="button"
								data-toggle="tooltip" data-placement="top" title="Edit Option"
								(click)="editOptionCategory(categoryOption)">
								<i class="fa fa-edit"></i>
								</button>
							</li>
							<li class="list-inline-item">
								<button class="btn btn-danger btn-sm" type="button Option"
								data-toggle="tooltip" data-placement="top" title="Delete"
								(click)="showDeleteOptionModal(categoryOption, deleteModal)">
								<i class="fa fa-trash"></i>
								</button>
							</li>
						</ul>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div *ngIf="showAddOptionCategory">
		<div class="row mb-3">
			<div class="col-md-12">
				<div class="card p-3">
					<div class="card-body">
						<h5 class="card-title">Add Option Category</h5>
						<fieldset>
							<div class="form-floating mb-3">
								<input [ngModel]="newCategory?.name" (ngModelChange)="newCategory.name = $event"
									class="form-control" id="name" placeholder="Name">
								<label for="name">Name</label>
							</div>
							<div class="form-floating mb-3">
								<input [ngModel]="newCategory?.description" (ngModelChange)="newCategory.description = $event"
									class="form-control" id="description" placeholder="description">
								<label for="description">Description</label>
							</div>
							<div class="form-floating mb-3">
								<select [ngModel]="newCategory?.displayType" (ngModelChange)="newCategory.displayType = $event"
									class="form-control" id="displayType" placeholder="Display Type">
									<option value="null" selected disabled hidden>Choose here</option>
									<option value="DropDown">Drop Down</option>
									<option value="HorizontalRadio">Horizontal Radio Group</option>
									<option value="VerticalRadioWPreview">Vertical Radio with Preview Image</option>
									</select>
								<label for="displayType">Display Type</label>
							</div>
						</fieldset>
					</div>
					<div class="card-body">
						<button class="btn btn-primary btn-lg" [disabled]="!newCategory?.name" (click)="saveNewCategory()">Save</button>
					</div>
				</div>
			</div>
		</div>

	</div>

	<div *ngIf="showEditOptionCategory">
		<div class="row mb-3">
			<div class="col-md-12">
				<div class="card p-3">
					<div class="card-body">
						<h5 class="card-title">{{ newOption ? 'New Product Option' : 'Edit Product Option'}}</h5>
						<fieldset>
							<div class="form-floating mb-3">
								<input [ngModel]="optionToEdit?.name" (ngModelChange)="optionToEdit.name = $event"
									class="form-control">
								<label>Name:</label>
							</div>
							<div class="form-floating mb-3">
								<input [ngModel]="optionToEdit?.modelNumber" (ngModelChange)="optionToEdit.modelNumber = $event"
									class="form-control">
								<label>Model Number: (Must match CRM/Quickbooks)</label>
							</div>
							<div class="form-floating mb-3">
								<textarea [ngModel]="optionToEdit?.description" (ngModelChange)="optionToEdit.description = $event" class="form-control">
								</textarea>
								<label>Description:</label>
							</div>
							<div class="form-check mb-3">
								<input [ngModel]="optionToEdit?.markupPercent" (ngModelChange)="optionToEdit.markupPercent = $event"
								class="form-check-input" type="checkbox">
								<label class="form-check-label">Markup Percent:</label>
							</div>
							<div class="form-floating mb-3">
								<input [ngModel]="optionToEdit?.markup" (ngModelChange)="optionToEdit.markup = $event" class="form-control"
								type="number" step="0.1">
								<label>Markup:</label>
							</div>
							<div class="d-flex justify-content-evenly mb-3">
								<div class="form-floating mb-3 col-md-2">
									<input class="form-control" type="number" step="0.1" [(ngModel)]="optionWidth">
									<label>Width (inches):</label>
								</div>
								<div class="form-floating mb-3 col-md-2">
									<input class="form-control" type="number" step="0.1" [(ngModel)]="optionHeight">
									<label>Height (inches):</label>
								</div>
								<div class="form-floating mb-3 col-md-2">
									<input class="form-control" type="number" step="0.1" [(ngModel)]="optionQuantity">
									<label>Quantity:</label>
								</div>
								<div class="form-floating mb-3 col-md-2">
									<input class="form-control" type="number" step="0.1" [(ngModel)]="optionWeight">
									<label>Weight (lbs):</label>
								</div>
								<button class="btn btn-primary btn-sm" (click)="calculateWeight()">Calculate</button>
							</div>
							<div class="form-floating mb-3">
								<input [ngModel]="optionToEdit?.weightPerUnit" (ngModelChange)="optionToEdit.weightPerUnit = $event" class="form-control"
								type="number" step="0.111">
								<label>Weight per thousand/sq in:</label>
							</div>
						</fieldset>
					</div>
					<div class="card-body">
						<button class="btn btn-primary btn-lg" [disabled]="!optionToEdit?.name" (click)="saveCurrentOption()">Save</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Delete Modal -->
	<ng-template #deleteModal>
		<app-confirm-modal title="Delete Category Confirmation" confirmText="Delete" (confirmAction)="deleteCurrentOption()"
			(closeModal)="closeModal()" [isDeleteModal]="true">
			<p>Are you sure you want to delete option - <strong>{{optionToDelete.name}}</strong> ?</p>
	</app-confirm-modal>
	<!-- End Delte Modal -->

