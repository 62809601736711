import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OrderService } from 'services/order.service';
import { MyOrdersQuery, Order, OrderItemDetail, OrderSearch, OrderTracking, Organization, OrganizationSearch, PaginationQuery, Site, SiteFilterRequest } from '@taradel/admin-api-client';
import { SalesApiService } from 'services/sales-api.service';
import { SitesService } from 'services/sites.service';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { ActivatedRoute } from '@angular/router';

export interface Proof {
	url: string;
	name?: string
}
@Component({
	selector: 'app-delivery',
	templateUrl: './delivery.component.html',
	styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

	total = 0;
	reorderAllowedDate = new Date(
		new Date().getFullYear(),
		new Date().getMonth() - 3,
		new Date().getDate()
	);
	orderIdSearch = new Subject<string>();
	@ViewChild('orderIdInput') orderIdInput!: ElementRef;
	pageNo = 1;
	pageSize = 10;
	query: MyOrdersQuery = new MyOrdersQuery({
		siteId: 0,
		organizationId: 0,
		orderId: 0,
		includeDeleted: false,
		pagination: new PaginationQuery({
			pageNo: 1,
			pageSize: 10
		})
	});
	pageOrders!: OrderTracking[];
	showSpinner: boolean = false;
	hasOrders = false;
	proofs: Map<number, Proof[]> = new Map<number, Proof[]>();
	customerId = 0;
	sites: Site[] = [];
	siteIds: number[] = [];
	organizations: Organization[] = [];
	selectedSiteId = 1;
	selectedOrganization: Organization = new Organization;

	@ViewChild('modalBody') modalBody!: ElementRef;

	constructor(
		private orderService: OrderService,
		private route: ActivatedRoute,
		private salesApiService: SalesApiService,
		private siteService: SitesService
	) {
	}

	async ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.customerId = parseInt(params.get('customerId') ?? '0', 10);
		});
		await this.initializeComponent();

		if (this.total > 0) {
			this.hasOrders = true;
		}
		this.orderIdSearch.pipe(
			debounceTime(500),
			distinctUntilChanged()
		).subscribe(async value => {
			if (value.length > 0) {
				this.query.orderId = parseInt(value);
			}
			else {
				this.query.orderId = 0;
			}
			await this.initializeComponent();
		});
	}

	async initializeComponent() {
		this.showSpinner = true;
		await this.findSitesWithOrders();
		this.query.siteId = this.siteIds[0];
		this.total = await this.orderService.getCustomerOrdersTrackingCount(this.customerId, this.query);

		if (this.total <= 0) {
			this.showSpinner = false;
		}
		else {
			this.pageOrders = await this.orderService.getCustomerOrdersTracking(this.customerId, this.query);
			this.proofs = new Map<number, Proof[]>();
			this.pageOrders.map(o => this.proofs.set(o.orderId, this.getOrderImages(o.orderItemDetails ?? [])));
			this.showSpinner = false;
		}
	}

	getOrderImages(orderItemDetails: OrderItemDetail[]) {
		let images: Proof[] = [];
		orderItemDetails.forEach(oi => oi.proofs?.forEach(p => {
			images.push({ name: oi.name, url: p });
		}));
		return images;
	}

	async downloadList(orderId: number): Promise<void> {
		await this.orderService.downloadList(orderId);
	}

	reOrderLink(order: OrderTracking): string {
		if (order.orderItemDetails && order.orderItemDetails.length > 0) {
			const productWithDistribution = order.orderItemDetails.find(x => x.distributionId > 0);
			if (productWithDistribution) {
				return `/shop/${productWithDistribution.distributionId}/start`;
			}
		}

		return '';
	}

	async clearFilter() {
		this.orderIdInput.nativeElement.value = '';
		this.query.orderId = 0;
		await this.initializeComponent();
	}

	async pageChanged() {
		this.showSpinner = true;
		this.query.pagination!.pageNo = this.pageNo;
		this.query.pagination!.pageSize = this.pageSize;
		this.pageOrders = await this.orderService.getCustomerOrdersTracking(this.customerId, this.query);
		this.proofs = new Map<number, Proof[]>();
		this.pageOrders.map(o => this.proofs.set(o.orderId, this.getOrderImages(o.orderItemDetails ?? [])));
		this.showSpinner = false;
	}

	getPdf(orderId: number) {
		this.salesApiService.getPdf(orderId);
	}

	getDropDate(dropDates?: string[]): string {
		if (dropDates) {
			const temp: string[] = [];
			dropDates.map(x => {
				if (x) {
					temp.push(x);
				}
			});
			return ' - ' + temp.join(', ');
		}
		return '';
	}

	async findSitesWithOrders() {
		let orderSearch = new OrderSearch({
			customerId: this.customerId,
			orderId: 0,
			pageNo: 1,
			pageSize: 10
		});
		let orders: Order[] = [];
		let orgIds: number[] = [];

		this.sites = await this.siteService.getSitesForAdmin({ showInactive: true, adminCheckout: false } as SiteFilterRequest);
		let ordersCount = await this.orderService.getCustomerOrdersCount(orderSearch);
		if (ordersCount <= 0) {
			return;
		}
		orderSearch.pageSize = ordersCount;
		orders = await this.orderService.getCustomerOrders(orderSearch);

		orders.forEach(o => {
			if (!this.siteIds.includes(o.affiliateId)) {
				this.siteIds.push(o.affiliateId);
			}
		});

		this.siteIds.forEach(s => {
			let site = this.sites.find(e => e.siteId === s);
			if (site && !orgIds.includes(site.organizationId)) {
				orgIds.push(site.organizationId);
			}
		});

		let allOrganizations = await this.siteService.listOrganizationsAndSites(new OrganizationSearch({
			organizationId: undefined,
			siteName: ''
		}));

		this.organizations = allOrganizations.filter(org => orgIds.some(id => id === org.organizationId));
		this.selectedOrganization = this.organizations[0];
	}

	getSiteName(siteId: number): string {
		let siteName = this.sites.find(s => s.siteId === siteId)?.name ?? '';
		return siteName;
	}

	async siteChanged(organizationId: number) {
		this.query.organizationId = organizationId;
		await this.pageChanged();
	}
}
