import { Environment } from './environment-type';
import { getIdentityClientId, getIdentityRedirect, IdentityRedirectType } from './identity-helpers';

export const environment: Environment = {
	production: false,
	kind: 'Development',
	instance: 'US',
	adminApiUrl: 'https://admin-api.dev-us.taradel.com',
	webApiUrl: 'https://web-api.dev-us.taradel.com',
	templateServerUrl: 'https://templateserver.eddmsite.com',
	identity: {
		uri: {
			scheme: 'https://',
			adminHostSlug: 'admin.dev-us.taradel.com',
			partnerHostSlug: 'partner.dev-us.taradel.com',
			callbackRoute: '/login-callback',
			silentRoute: '/login-silent',
		},
		clientEnvironment: `dev-us`,
	},
	oidc: {
		authority: 'https://identity.dev-us.taradel.com',
		automaticSilentRenew: true,
		response_type: 'id_token token',
		scope: 'openid profile taradel-web-api admin.profile',
		get client_id() {
			return getIdentityClientId();
		},
		get redirect_uri() {
			return getIdentityRedirect(IdentityRedirectType.Login);
		},
		get silent_redirect_uri() {
			return getIdentityRedirect(IdentityRedirectType.LoginSilent);
		},
		get post_logout_redirect_uri() {
			return getIdentityRedirect(IdentityRedirectType.PostLogout);
		},
	}
};
