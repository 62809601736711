<app-customer-header [customerId]="customerId">
	<ng-container altnav>
		<ul class="nav nav-pills justify-content-end">
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'distributions']">Back to Distributions</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'snapmap']">Build Map</a>
			</li>
			<li class="nav-item">
				<a class="nav-link active" [routerLink]="['/customers', customerId, 'snap-paste-list']">Paste Routes</a>
			</li>
		</ul>
	</ng-container>
</app-customer-header>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col">
			<div class="card">
				<form [formGroup]="postalListForm" class="card-body">
					<div class="col-sm-12">
						<div class="form-floating mb-3">
							<input type="text" class="form-control" placeholder="Enter Map Name"
								[ngClass]="{ 'is-invalid': submitted && form.mapName.errors }" formControlName="mapName" />
							<label>Enter Map Name</label>
							<div *ngIf="submitted && form.mapName.errors" class="invalid-feedback">
								<div *ngIf="form.mapName.errors.required">Map Name is required</div>
							</div>
						</div>
						<div class="mb-3">
							<div><b>Delivery Types</b></div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" name="house"
									formControlName="housePostals" id="houseCheck" value="false">
								<label class="form-check-label" for="houseCheck">Houses</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" name="apartment" formControlName="apartmentPostals"
									id="apartmentCheck" value="false">
								<label class="form-check-label" for="apartmentCheck">Apartments</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" name="farm" formControlName="farmPostals"
									id="farmCheck" value="false">
								<label class="form-check-label" for="farmCheck">Farms</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="checkbox" name="business" formControlName="businessPostals"
									id="businessCheck" value="false">
								<label class="form-check-label" for="businessCheck">Business</label>
							</div>
							<div *ngIf="submitted && form.deliveryTypeChecked.errors" class="invalid-text">
								<div>Delivery Type is required</div>
							</div>
						</div>

						<div class="form-floating mb-3">
							<textarea
								placeholder="Paste Postal Codes (B4N 4N4) and/or Carrier Routes (B0J1A0B0JLB0001) separated by a comma, semi-colon or line break."
								rows="5"  style="height: 200px" cols="20" class="form-control"
								[ngClass]="{ 'is-invalid' : submitted && form.postalCodesAndRoutesString.invalid }"
								formControlName="postalCodesAndRoutesString"></textarea>
							<label>
								Paste Postal Codes (B4N 4N4) and/or Carrier Routes (B0J1A0B0JLB0001) separated by a comma, semi-colon or
								line break.
							</label>
							<div *ngIf="submitted && form.postalCodesAndRoutesString.errors" class="invalid-feedback">
								<div *ngIf="form.postalCodesAndRoutesString.errors.required">Postal codes are required</div>
							</div>
						</div>

						<p>
							<button (click)="submitPostals()" class="btn btn-primary"> Submit </button>
						</p>
					</div>
				</form>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
