import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EquifaxQuery, PersonalizedMailQuery } from '@taradel/web-api-client';

@Component({
	selector: 'app-list-preview',
	templateUrl: './list-preview.component.html',
	styleUrls: ['./list-preview.component.scss']
})
export class ListPreviewComponent {
	@Input() totalLeads!: number;
	@Input() query!: EquifaxQuery | PersonalizedMailQuery;
	@Output() getSample = new EventEmitter<void>();

	addresses!: Array<any>;
	listIsPopulated!: boolean;
	listIsLoading!: boolean;
	buttonText = 'View List Preview';

	constructor() {}

	getAddressList(): void {
		if (this.buttonText === 'View List Preview') {
			this.getSample.emit();
		}
		else {
			this.listIsPopulated = false;
			this.addresses = new Array<any>();
			this.buttonText = 'View List Preview';
		}
	}
}
