<app-pagefullheader></app-pagefullheader>

<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col-md-7">
			<div class="card p-3 mb-3">
				<form [formGroup]="uSelectForm">
					<div class="card-body">
						<h5 class="card-title">Uselect Information</h5>
						<div class="row mb-3">
							<div class="col-md-9">
								<div class="form-floating">
									<input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
									<label for="name">Name</label>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-md-9">
								<div class="form-floating">
									<input type="text" formControlName="shortDescription" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.shortDescription.errors }">
									<label for="shortDescription">Display Name (Short Description)</label>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-md-9">
								<div class="form-floating">
									<input type="text" formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
									<label for="description">Description</label>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-md-6">
								<div class="form-floating">
									<select type="select" formControlName="productClass" class="form-select">
										<option *ngFor="let x of productClasses" value="{{x}}">
											{{x}}
										</option>
									</select>
									<label for="productClass">Product Class</label>
								</div>
							</div>
						</div>
						<div class="row mb-3">
							<div class="col-md-4">
								<div class="form-check">
									<input type="checkbox" formControlName="isDirectShippable" class="form-check-input"
										[ngClass]="{ 'is-invalid': submitted && f.isDirectShippable.errors }">
									<label for="isDirectShippable" class="form-check-label">Is Direct Shippable?</label>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-md-6">
								<div class="form-floating">
									<select type="select" formControlName="featuredProduct" class="form-select">
										<option value="undefined">None</option>
										<option *ngFor="let x of baseProducts" value="{{x.productId}}">
											{{x.productId}} - {{x.name!}}
										</option>
									</select>
									<label for="featuredProduct">Featured Product</label>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<button class="btn btn-primary btn-lg" (click)="saveUselect()">Save</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
