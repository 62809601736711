<div class="card">
	<div class="card-body">
		<h5 class="card-title">Create Cart</h5>
			<div class="panel-body">
				<form [formGroup]="cartInfoForm">
					<div class="form-floating mb-3">
						<input type="text" formControlName="customer" class="form-control"
							[ngClass]="{ 'is-invalid': c.customer.errors }">
						<label for="customer">Customer</label>
					</div>
					<div class="form-floating mb-3">
						<input type="text" formControlName="site" class="form-control"
							[ngClass]="{ 'is-invalid': c.site.errors }">
						<label for="site">Site</label>
					</div>
					<div class="form-floating mb-3" [hidden]="distributionId === 0">
						<input type="text" formControlName="distribution" class="form-control"
							[ngClass]="{ 'is-invalid': c.distribution.errors }">
						<label for="distribution">Distribution</label>
					</div>
					<div *ngIf="distributionId === 0" class="form-floating mb-3">
						<span class="pe-2">
							<button class="btn btn-primary" (click)="routeToCustomerDistributions()">Use A Distribution</button>
						</span>
					</div>
					<div *ngIf="hasCart" class="form-floating mb-3">
						<span class="pe-2">
                            <div class="row">
                                <div class="col-auto" *ngIf="distributionId > 0">
                                    <button class="btn btn-danger" (click)="requestClearCart()">Clear Cart</button>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-primary" (click)="modalService.open(emailCartModal)">Email Cart to Customer</button>
                                </div>
                            </div>							
						</span>
					</div>
				</form>
			</div>
	</div>
</div>

<ng-template #emailCartModal let-modal>
	<app-confirm-modal title="Email Cart to Customer" confirmText="Send Email" cancelText="Cancel and create quote" (confirmAction)="emailCart()" (closeModal)="closeModal()">
		<p>Please note that Carts do not include billing addresses, or taxes. Customers can only have one cart per site at a time.</p>
    </app-confirm-modal>
</ng-template>
