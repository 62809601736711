import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BusinessClass, RegisterUserQuery, Site, SiteFilterRequest } from '@taradel/admin-api-client';
import { COUNTRIES, Country, STATES } from '@taradel/white-label-common';
import { IdentityService } from 'services/identity.service';
import { SitesService } from 'services/sites.service';
import { ToastService } from 'services/toast.service';
import { createEmailValidator } from '@taradel/white-label-common';

@Component({
	selector: 'app-create',
	templateUrl: './create-customer.component.html',
	styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {
	loading = false;
	customerForm: UntypedFormGroup;
	businessClasses: BusinessClass[] = [];
	submitted = false;
	state = '';
	stateList = STATES;
	siteId = -1;
	sites: Site[] = [];

	get countryList(): Country[] {
		const us = COUNTRIES.find(i => i.code === 'US') as Country;
		const ca = COUNTRIES.find(i => i.code === 'CA') as Country;
		return [us, ca, ...COUNTRIES];
	}

	constructor(
		private sitesService: SitesService,
		private identityService: IdentityService,
		private router: Router,
		private toastService: ToastService,
		private formBuilder: UntypedFormBuilder) {
		this.customerForm = this.formBuilder.group({
			companyName: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			mobile: ['', Validators.required],
			emailAddress: ['', createEmailValidator({'required': true})],
			password: ['', Validators.compose([
				Validators.required,
				Validators.pattern('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^\sA-Za-z0-9]).{8,}$')
			])],
			confirmPassword: ['', Validators.required],
		},
			{ validators: this.checkPasswords });
	}

	async ngOnInit(): Promise<void> {
		this.loading = true;
		try {
			this.businessClasses = await this.sitesService.getAllBusinessClass();
			let request = new SiteFilterRequest({
				adminCheckout: false,
				showInactive: false
			});
			this.sites = await this.sitesService.getSitesForAdmin(request);
		}
		catch (error) {
			this.toastService.showError('There was a problem loading the component.', 'Load Error');
			console.log(error);
		}
		 finally {
			this.loading = false;
		}
	}

	async saveCustomer() {
		this.submitted = true;
		if (this.customerForm.invalid || this.siteId === -1) {
			return;
		}
		this.loading = true;
		const registerCustomerQuery = new RegisterUserQuery();

		registerCustomerQuery.company = this.customerForm.controls.companyName.value;
		registerCustomerQuery.firstName = this.customerForm.controls.firstName.value;
		registerCustomerQuery.lastName = this.customerForm.controls.lastName.value;
		registerCustomerQuery.phoneNumber = this.customerForm.controls.mobile.value;
		registerCustomerQuery.emailAddress = this.customerForm.controls.emailAddress.value;
		registerCustomerQuery.password = this.customerForm.controls.password.value;
		registerCustomerQuery.siteId = this.siteId;

		try {
			const customerId = await this.identityService.registerCustomer(registerCustomerQuery);
			await this.router.navigate(['/customers/' + customerId]);
		}
		catch (error: any) {
			if (error.status === 400) {
				const response = JSON.parse(error.response);
				this.toastService.showError(response.detail);
			}
			else {
				this.toastService.showError('There was an error creating the user. Check your network tab for more info.');
			}
		}
		finally {
			this.loading = false;
		}
	}

	checkPasswords(formGroup: AbstractControl) {
		if (formGroup) {
			return formGroup.get('password')?.value === formGroup.get('confirmPassword')?.value ? null : { passwordMisMatch: true };
		}

		return null;
	}

	siteChanged(selected: number) {
		this.siteId = selected;
	}


	passwordMisMatch(): boolean {
		if (this.customerForm && this.customerForm.errors) {
			return this.customerForm.errors.passwordMisMatch;
		}
		return false;
	}

	get f() {
		return this.customerForm.controls;
	}
}
