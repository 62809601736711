import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BundleDisplay, BundleContentResponse, CmsContentType, CmsContent, Site, SiteFilterRequest } from '@taradel/admin-api-client';
import { SitesService } from 'services/sites.service';

export interface BundleContentItem {
	bundleContentKeyType?: 'Details Content Key' | 'Description Content Key' | 'Name Content Key';
	contentKey?: string;
	contentType?: CmsContentType;
	siteId: number;
	language: string;
	content?: string;
}

@Component({
  selector: 'app-bundle-content',
  templateUrl: './bundle-content.component.html',
  styleUrls: ['./bundle-content.component.scss']
})
export class BundleContentComponent implements OnInit {
	loading = false;
	submitted = false;
	detailsContentItems: BundleContentItem[] = [];
	descriptionContentItems: BundleContentItem[] = [];
	nameContentItems: BundleContentItem[] = [];
	sites: Site[] = [];

	ContentType = CmsContentType;

	@Input() bundle?: BundleDisplay;
	@Input() bundleContent?: CmsContent[];

	@Output() bundleContentSelected = new EventEmitter<CmsContent>();
	@Output() goBack = new EventEmitter();

	constructor(
		private sitesService: SitesService
	) { }

	async ngOnInit(): Promise<void> {
		if (!!this.bundle) {
			if (this.bundleContent && this.bundleContent.length) {
				this.bundleContent.forEach(item => {
					const contentItem = {
						bundleContentKeyType: !!item.contentKey && !!item.contentKey.contentKey ? this.setBundleContentKeyType(item.contentKey.contentKey) : undefined,
						contentKey: !!item.contentKey?.contentKey ? item.contentKey.contentKey : undefined,
						contentType: !!item.contentKey?.contentType ? item.contentKey?.contentType : undefined,
						siteId: !!item.siteId ? item.siteId : 0,
						language: !!item.language ? item.language : 'en',
						content: !!item.content ? item.content : undefined
					} as BundleContentItem;
					if (contentItem.bundleContentKeyType === 'Details Content Key') {
						this.detailsContentItems.push(contentItem);
					}
					else if (contentItem.bundleContentKeyType === 'Description Content Key') {
						this.descriptionContentItems.push(contentItem);
					}
					else if (contentItem.bundleContentKeyType === 'Name Content Key') {
						this.nameContentItems.push(contentItem);
					}
					// else??
				});
			}
		}
		let request = new SiteFilterRequest({
			adminCheckout: false,
			showInactive: false
		});
		this.sites = await this.sitesService.getSitesForAdmin(request);
	}

	setBundleContentKeyType(contentKey: string): 'Details Content Key' | 'Description Content Key' | 'Name Content Key' | undefined {
		if (!this.bundle) {
			return undefined;
		}
		if (!this.bundle.bundleContent) {
			return undefined;
		}
		if (!!this.bundle.bundleContent.detailsContentKey) {
			if (contentKey === this.bundle.bundleContent.detailsContentKey) {
				return 'Details Content Key';
			}
		}
		if (!!this.bundle.bundleContent.descriptionContentKey) {
			if (contentKey === this.bundle.bundleContent.descriptionContentKey) {
				return 'Description Content Key';
			}
		}
		if (!!this.bundle.bundleContent.nameContentKey) {
			if (contentKey === this.bundle.bundleContent.nameContentKey) {
				return 'Name Content Key';
			}
		}
		return undefined;
	}

	getSiteName(siteId: number): string {
		return this.sites.find(s => s.siteId === siteId)?.name ?? '';
	}
}
