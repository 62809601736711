export function ApexChartIntegerFormatter(val: number, _opts: any) {
	return `${val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function ApexChartNumberFormatter(val: number, _opts: any) {
	return `${val.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function ApexChartNumberThousandFormatter(val: number, _opts: any) {
	return `${(val / 1000).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}k`;
}
