<app-customer-header [customerId]="customerId">
	<ng-container altnav>
		<ul class="nav nav-pills justify-content-end">
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'distributions']">Back to Distributions</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'map']">Build Map</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [routerLink]="['/customers', customerId, 'paste-list']">Paste Routes</a>
			</li>
			<li class="nav-item">
				<a class="nav-link active" [routerLink]="['/customers', customerId, 'upload-list']">Upload File</a>
			</li>
		</ul>
	</ng-container>
</app-customer-header>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col">
			<div class="row">
				<div class="col-6">
					<div class="card">
						<div class="upload-container mt-2 card-body">
							<div class="row">
								<div class="col">
									<div class="form-floating mb-3">
										<input type="text" class="form-control" id="mapName" [(ngModel)]="mapName"
											placeholder="Enter Map Name" />
										<label for="mapName" class="form-label">Enter Map Name</label>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col">
									<div class="ratio" style="--bs-aspect-ratio: 20%;">
										<div appDragAndDrop class="dropzone text-primary d-flex align-items-center" (fileDropped)="onFileDropped($event)">
											<input type="file" #fileInput id="fileDropRef" accept=".xlsx"
												(change)="fileHandler($event.target)" />
											<div class="m-auto">
												<div *ngIf="file === undefined">
													<span class="fa fa-2x fa-upload"></span>&nbsp;Drop file or click here to upload
												</div>
												<div *ngIf="file !== undefined">
													<span class="fa fa-file-excel-o"></span>&nbsp;{{file.name}}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<p class="mt-3">
								<button class="btn btn-primary btn-lg" (click)='upload()'>Upload</button>
							</p>

						</div>
					</div>
				</div>
				<div class="col-6">
					<div class="card">
						<div class="upload-container mt-2 card-body">

					<h4 class="modal-title" id="modal-title">Explanation of valid file format</h4>
					<ol>
						<li>Data in rows before <strong>Row 2</strong> in the spreadsheet will NOT be imported.</li>
						<li>The ZIP Code must be in column <strong>A</strong> and must be formatted as a zip code. (<a
								href="https://support.office.com/en-us/article/display-numbers-as-postal-codes-61b55c9f-6fe3-4e54-96ca-9e85c38a5a1d" target="_blank">how
								to</a>)</li>
						<li>The carrier route must be in column <strong>B</strong>.</li>
						<li>Rows without data in the ZIP Code (<strong>A</strong>) and carrier route (<strong>B</strong>) columns
							will be ignored.</li>
						<li *ngIf="isCanada">The total apartment deliveries must be in column <strong>C</strong>.</li>
						<li *ngIf="isCanada">The total farm deliveries must be in column <strong>D</strong>.</li>
						<li *ngIf="isCanada">The total house deliveries must be in column <strong>E</strong>.</li>
						<li *ngIf="isCanada">The total business deliveries must be in column <strong>F</strong>.</li>
						<li *ngIf="!isCanada">The total residential deliveries must be in column <strong>C</strong>.</li>
						<li *ngIf="!isCanada">The business delivery total must be in column <strong>D</strong>.</li>
						<li *ngIf="!isCanada">The PO box delivery total must be in column <strong>E</strong>.</li>
						<li>Data import will run until 10 rows are encountered without a ZIP Code and a carrier route.</li>
					</ol>
					<h3>Example of valid file format</h3>
					<table class="upload-example" width="100%">
						<tr class="even">
							<th>Row\Col</th>
							<th>A</th>
							<th>B</th>
							<th>C</th>
							<th>D</th>
							<th>E</th>
							<th *ngIf="isCanada">F</th>
							<th>Description</th>
						</tr>
						<tr class="not-imported">
							<th>0</th>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td *ngIf="isCanada"></td>
							<td>Not imported</td>
						</tr>
						<tr class="even">
							<th>1</th>
							<td>Zip</td>
							<td>CR</td>
							<td *ngIf="isCanada">Apt</td>
							<td *ngIf="isCanada">Frm</td>
							<td *ngIf="isCanada">Hse</td>
							<td *ngIf="!isCanada">Res</td>
							<td>Biz</td>
							<td *ngIf="!isCanada">PO Box</td>
							<td>Start import at row 2</td>
						</tr>
						<tr>
							<th>2</th>
							<td>Zip</td>
							<td>CR</td>
							<td *ngIf="isCanada">Apt</td>
							<td *ngIf="isCanada">Frm</td>
							<td *ngIf="isCanada">Hse</td>
							<td *ngIf="!isCanada">Res</td>
							<td>Biz</td>
							<td *ngIf="!isCanada">PO Box</td>
							<td>Start import at row 2</td>
						</tr>
						<tr class="even">
							<th>3</th>
							<td>Zip</td>
							<td>CR</td>
							<td *ngIf="isCanada">Apt</td>
							<td *ngIf="isCanada">Frm</td>
							<td *ngIf="isCanada">Hse</td>
							<td *ngIf="!isCanada">Res</td>
							<td>Biz</td>
							<td *ngIf="!isCanada">PO Box</td>
							<td></td>
						</tr>
						<tr>
							<th>4</th>
							<td>Zip</td>
							<td>CR</td>
							<td *ngIf="isCanada">Apt</td>
							<td *ngIf="isCanada">Frm</td>
							<td *ngIf="isCanada">Hse</td>
							<td *ngIf="!isCanada">Res</td>
							<td>Biz</td>
							<td *ngIf="!isCanada">PO Box</td>
							<td></td>
						</tr>
						<tr class="even">
							<th>5</th>
							<td>Zip</td>
							<td>CR</td>
							<td *ngIf="isCanada">Apt</td>
							<td *ngIf="isCanada">Frm</td>
							<td *ngIf="isCanada">Hse</td>
							<td *ngIf="!isCanada">Res</td>
							<td>Biz</td>
							<td *ngIf="!isCanada">PO Box</td>
							<td></td>
						</tr>
						<tr>
							<th>6</th>
							<td>Zip</td>
							<td>CR</td>
							<td *ngIf="isCanada">Apt</td>
							<td *ngIf="isCanada">Frm</td>
							<td *ngIf="isCanada">Hse</td>
							<td *ngIf="!isCanada">Res</td>
							<td>Biz</td>
							<td *ngIf="!isCanada">PO Box</td>
							<td></td>
						</tr>
						<tr class="even">
							<th>7</th>
							<td>Zip</td>
							<td>CR</td>
							<td *ngIf="isCanada">Apt</td>
							<td *ngIf="isCanada">Frm</td>
							<td *ngIf="isCanada">Hse</td>
							<td *ngIf="!isCanada">Res</td>
							<td>Biz</td>
							<td *ngIf="!isCanada">PO Box</td>
							<td></td>
						</tr>
					</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
