import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CustomerPaymentAttemptDisplay, PaginationQuery, PaymentAttemptsQuery } from "@taradel/admin-api-client";
import { PaymentsService } from "services/payments.service";
import { ToastService } from "services/toast.service";


@Component({
  selector: 'app-customer-payment-attempts',
  templateUrl: './customer-payment-attempts.component.html',
  styleUrls: ['./customer-payment-attempts.component.scss']
})
export class CustomerPaymentAttemptsComponent implements OnInit {
	loading = false;
	customerId = 0;
	isSearchEnabled = true;
	pageNo = 1;
	pageSize = 20;
	total = 0;
	paymentAttemptsQuery = new PaymentAttemptsQuery();
	paymentAttempts: CustomerPaymentAttemptDisplay[] = [];

	constructor(
		private paymentService: PaymentsService,
		private route: ActivatedRoute,
		private toastService: ToastService
	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
	}

	async ngOnInit() {
		this.pageNo = 1;
		await this.search();
		this.loading = false;
	}

	async search() {
		this.loading = true;
		this.paymentAttemptsQuery.pagination = new PaginationQuery({
			pageNo: this.pageNo,
			pageSize: this.pageSize
		});

		try {
			if (this.customerId > 0) {
				this.total = await this.paymentService.getCustomerPaymentAttemptsCount(this.customerId, this.paymentAttemptsQuery);
				this.paymentAttempts = await this.paymentService.getCustomerPaymentAttempts(this.customerId, this.paymentAttemptsQuery);
			}
			else {
				this.total = await this.paymentService.getPaymentAttemptsCount(this.paymentAttemptsQuery);
				this.paymentAttempts = await this.paymentService.getPaymentAttempts(this.paymentAttemptsQuery);
			}


			if (this.paymentAttempts.length > 0) {
				this.isSearchEnabled = true;
			}
			else {
				this.isSearchEnabled = false;
			}
		}
		catch (err: any) {
			this.toastService.showError(err.toString());
		}
		this.loading = false;
	}

	async pageChanged() {
		try {
			this.loading = true;
			this.paymentAttemptsQuery.pagination = new PaginationQuery({
				pageNo: this.pageNo,
				pageSize: this.pageSize
			});
			await this.search();

		}
		catch {
			this.toastService.showError('Unable to load payment attempts');
		}
		finally {
			this.loading = false;
		}

	}
}
