import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Site } from '@taradel/web-api-client';

@Component({
	selector: 'app-sites-list',
	templateUrl: './sites-list.component.html',
	styleUrls: ['./sites-list.component.scss']
})
export class SitesListComponent {

	showSpinner: boolean = false;

	@Input() sites: Site[] = [];
	@Input() startingSiteId?: number;
	@Input() showError: boolean = false;
	@Input() disabled: boolean = false;
	@Output() siteSelected = new EventEmitter<Site>();

	constructor() { }

	siteChanged() {
		this.showSpinner = true;
		let siteConv = this.startingSiteId;
		let selection = this.sites.find(e =>
			e.siteId === siteConv
		);
		if (selection !== undefined) {
			this.siteSelected.emit(selection);
		}
		this.showSpinner = false;
	}
}
