<app-pagefullheader></app-pagefullheader>
<app-spinner-with-overlay [showSpinner]="loading">
	<div class="row">
		<div class="col-md-7">
			<div class="card p-3 mb-3">
				<form [formGroup]="categoryForm">
					<div class="card-body">
						<h5 class="card-title">Print And Ship Category Information</h5>
						<div class="row mb-3">
							<div class="col">
								<div class="form-floating">
									<input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
									<label for="name">Category Name </label>
								</div>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col">
								<div class="form-floating">
									<input type="text" formControlName="channelPrefix" class="form-control" min="1" max="99999"
									[ngClass]="{ 'is-invalid': submitted && f.channelPrefix.errors }">
									<label for="paperWidth">Channel Prefix</label>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<button class="btn btn-primary btn-lg" (click)="saveCategory()">Add Category</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</app-spinner-with-overlay>
