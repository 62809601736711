import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

	@Input() title = '';
	@Input() confirmText = 'ok';
	@Input() cancelText = 'cancel';
	@Input() disabled: any;
	@Input() isDeleteModal = false;

	@Output() confirmAction = new EventEmitter<void>();
	@Output() closeModal = new EventEmitter<void>();

	constructor() { }

}
